import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FormCreate = (props) => {
    
    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <div className="card mb-3">
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label>Url</label>
                                <input 
                                    type="text" 
                                    name="url"
                                    className='form form-control'
                                    value={props.form.url}
                                    onChange={props.onChangeForm}
                                />
                            </div>

                            <div className="col-md-12">
                                <label>payload</label>
                                <textarea 
                                    type="text" 
                                    name="payload"
                                    className='form form-control'
                                    value={props.form.payload}
                                    onChange={props.onChangeForm}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className="btn-shadow btn btn-info mr-2" onClick={props.submitForm}>{props.nameBnt}</button>  
                        <label className="btn-shadow btn btn-danger mt-2" onClick={props.toggle}>Cerrar</label>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}


export default FormCreate;

