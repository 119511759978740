import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from '../../../GeneralComponents/LoadingComponen'

class IndexSegmentacion extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            modeEdit: false,
            id: "",

            form: {
                name: "",
                total_type: "moneda",
                table_type: "diario",
                variable_1: "",
                variable_2: "",
                actual_year: "",
                initial_variables: "",
                indicator_type: this.props.type,

                analyzed_position_id: "",
                reviewed_position_id: "",
                approved_position_id: "",
                sub_category_indicator_id: this.props.sub_category_indicator != null ? this.props.sub_category_indicator.id : "",
                category_indicator_id: this.props.category_indicator.id,
                description: "",

                first_custom_view_id: "",
                first_question_date_id: "",
                first_question_value_id: "",
                first_type: "",

                second_custom_view_id: "",
                second_question_date_id: "",
                second_question_value_id: "",
                second_type: "",
                has_fordata: false,
                has_fordata_second: false,
            },

            selectedOptionFormatCustomView1: {
                first_custom_view_id: "",
                label: "Vista personalizada"
            },

            selectedOptionFormatCustomView2: {
                second_custom_view_id: "",
                label: "Vista personalizada"
            },

            selectedOptionFirstQuestionDate: {
                first_question_date_id: "",
                label: "Primera pregunta fecha"
            },

            selectedOptionFirstQuestionValue: {
                first_question_value_id: "",
                label: "Primera pregunta número"
            },

            selectedOptionSecondQuestionDate: {
                second_question_date_id: "",
                label: "Segunda pregunta fecha"
            },

            selectedOptionSecondQuestionValue: {
                second_question_value_id: "",
                label: "Segunda pregunta número"
            },

            questions_date1: [],
            questions_number1: [],

            questions_date2: [],
            questions_number2: [],

            selectedOptionPosition1: {
                analyzed_position_id: "",
                label: "Responsable"
            },

            selectedOptionSubCategory: {
                sub_category_indicator_id: "",
                label: "Sub categoría"
            },

            selectedOptionPosition2: {
                reviewed_position_id: "",
                label: "Analiza"
            },

            selectedOptionPosition3: {
                approved_position_id: "",
                label: "Aprueba"
            },

            position: [],
        }
    }


    //selects 

    handleChangeAutocomplete1 = selectedOptionPosition1 => {
        this.setState({
          selectedOptionPosition1,
            form: {
                ...this.state.form,
                analyzed_position_id: selectedOptionPosition1.value
            }
        });

    };

    handleChangeAutocomplete2 = selectedOptionPosition2 => {
        this.setState({
          selectedOptionPosition2,
            form: {
                ...this.state.form,
                reviewed_position_id: selectedOptionPosition2.value
            }
        });

    };

    handleChangeAutocomplete3 = selectedOptionPosition3 => {
        this.setState({
          selectedOptionPosition3,
            form: {
                ...this.state.form,
                approved_position_id: selectedOptionPosition3.value
            }
        });

    };

    componentDidMount = () => {
        this.positionSelect();
    }

    positionSelect = () => {
        let array = [];

        this.props.cargos.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            position: array
        })
    }

    validationForm = () => {
        if (this.state.form.name != "" &&  
            this.state.form.actual_year != "" && 
            this.state.form.initial_variables != (this.props.type == "SEGV" && this.state.modeEdit == false ? "" : "*") && 

            this.state.form.variable_1 != (this.props.type != "SEGV" ? "" : "*") && 
            this.state.form.variable_2 != (this.props.type != "SEGV" ? "" : "*") && 

            this.state.form.first_custom_view_id != (this.state.form.has_fordata ? "" : "*") &&
            this.state.form.first_question_date_id != (this.state.form.has_fordata ? "" : "*") &&
            this.state.form.first_question_value_id != (this.state.form.has_fordata ? "" : "*") &&
            this.state.form.first_type != (this.state.form.has_fordata ? "" : "*") &&


            this.state.form.second_custom_view_id != (this.state.form.has_fordata_second ? "" : "*") &&
            this.state.form.second_question_date_id != (this.state.form.has_fordata_second ? "" : "*") &&
            this.state.form.second_question_value_id != (this.state.form.has_fordata_second ? "" : "*") &&
            this.state.form.second_type != (this.state.form.has_fordata_second ? "" : "*") &&

            
            this.state.form.analyzed_position_id != ""
        ) {
          this.setState({ ErrorValues: true })
          return true
        }else{
          this.setState({ ErrorValues: false })
          return false
          
        }
    }    

    //   this.state.modeEdit == false ? this.state.form.password != "" : "",
    //   this.state.modeEdit == false ? this.state.form.password_confirmation != "" : "",

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                variable_1: "",
                variable_2: "",
                actual_year: "",
                total_type: "moneda",
                table_type: "diario",
                initial_variables: "",
                indicator_type: this.props.type,

                analyzed_position_id: "",
                reviewed_position_id: "",
                approved_position_id: "",
                sub_category_indicator_id: this.props.sub_category_indicator != null ? this.props.sub_category_indicator.id : "",
                category_indicator_id: this.props.category_indicator.id,
                description: "",

                first_custom_view_id: "",
                first_question_date_id: "",
                first_question_value_id: "",
                first_type: "",

                second_custom_view_id: "",
                second_question_date_id: "",
                second_question_value_id: "",
                second_type: "",
                has_fordata: false,
                has_fordata_second: false,
            },

            selectedOptionFormatCustomView1: {
                first_custom_view_id: "",
                label: "Vista personalizada"
            },

            selectedOptionFormatCustomView2: {
                second_custom_view_id: "",
                label: "Vista personalizada"
            },

            selectedOptionFirstQuestionDate: {
                first_question_date_id: "",
                label: "Primera pregunta fecha"
            },

            selectedOptionFirstQuestionValue: {
                first_question_value_id: "",
                label: "Primera pregunta número"
            },

            selectedOptionSecondQuestionDate: {
                second_question_date_id: "",
                label: "Segunda pregunta fecha"
            },

            selectedOptionSecondQuestionValue: {
                second_question_value_id: "",
                label: "Segunda pregunta número"
            },

            questions_date1: [],
            questions_number1: [],

            questions_date2: [],
            questions_number2: [],

            selectedOptionPosition1: {
                analyzed_position_id: "",
                label: ""
            },

            selectedOptionSubCategory: {
                sub_category_indicator_id: "",
                label: "Sub categoría"
            },

            selectedOptionPosition2: {
                reviewed_position_id: "",
                label: ""
            },

            selectedOptionPosition3: {
                approved_position_id: "",
                label: ""
            },


            modeEdit: false,
            ErrorValues: true,
            id: "",
        })
    }

    edit = (indicator) => {
        if (indicator.has_fordata){
            this.LoadFormatCustomView1(indicator.first_custom_view_id)
        }

        if (indicator.has_fordata_second){
            this.LoadFormatCustomView2(indicator.second_custom_view_id)
        }

        this.setState({
            form: {
                ...this.state.form,
                name: indicator.name,
                total_type: indicator.total_type,
                variable_1: indicator.variable_1,
                variable_2: indicator.variable_2,
                table_type: indicator.table_type,
                actual_year: indicator.actual_year,

                analyzed_position_id: indicator.analyzed_position != null ? indicator.analyzed_position.id : "",
                approved_position_id: indicator.approved_position != null ?indicator.approved_position.id : "",
                reviewed_position_id: indicator.reviewed_position != null ? indicator.reviewed_position.id : "",
                description: indicator.description,

                first_custom_view_id: indicator.first_custom_view_id,
                first_question_date_id: indicator.first_question_date_id,
                first_question_value_id: indicator.first_question_value_id,
                first_type: indicator.first_type,

                second_custom_view_id: indicator.second_custom_view_id,
                second_question_date_id: indicator.second_question_date_id,
                second_question_value_id: indicator.second_question_value_id,
                second_type: indicator.second_type,
                has_fordata: indicator.has_fordata,
                has_fordata_second: indicator.has_fordata_second,
            },

            selectedOptionFormatCustomView1: {
                first_custom_view_id: indicator.first_custom_view_id,
                label: indicator.first_custom_view ? indicator.first_custom_view.name : "Vista personalizada"
            },

            selectedOptionFormatCustomView2: {
                second_custom_view_id: indicator.second_custom_view_id,
                label: indicator.second_custom_view ? indicator.second_custom_view.name : "Vista personalizada"
            },

            selectedOptionFirstQuestionDate: {
                first_question_date_id: indicator.first_question_date_id,
                label: indicator.first_question_date ? indicator.first_question_date.question : "Primera pregunta fecha"
            },

            selectedOptionFirstQuestionValue: {
                first_question_value_id: indicator.first_question_value_id,
                label: indicator.first_question_value ? indicator.first_question_value.question : "Primera pregunta número"
            },

            selectedOptionSecondQuestionDate: {
                second_question_date_id: indicator.second_question_date_id,
                label: indicator.second_question_date ? indicator.second_question_date.question : "Segunda pregunta fecha"
            },

            selectedOptionSecondQuestionValue: {
                second_question_value_id: indicator.second_question_value_id,
                label: indicator.second_question_value ? indicator.second_question_value.question : "Segunda pregunta número"
            },

            selectedOptionPosition1: {
                analyzed_position_id: indicator.analyzed_position != null ? indicator.analyzed_position.id : "",
                label: `${indicator.analyzed_position != null ? indicator.analyzed_position.name : ""}`
            },

            selectedOptionPosition2: {
                reviewed_position_id: indicator.approved_position != null ?indicator.approved_position.id : "",
                label: `${indicator.approved_position != null ?indicator.approved_position.name : ""}`
            },

            selectedOptionPosition3: {
                approved_position_id: indicator.reviewed_position != null ? indicator.reviewed_position.id : "",
                label: `${indicator.reviewed_position != null ? indicator.reviewed_position.name : ""}`
            },

            id: indicator.id,
            modal: true,
            modeEdit: true,
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    getNameTitle = (name) => {
        const indicadores = "Indicadores"

        if (name == "SACLI") {
            return `${this.props.category_indicator.name}: ${indicadores} Clinicos`
        } else if (name == "SAADM") {
            return `${this.props.category_indicator.name}: ${indicadores} Administrativos`
        } else if (name == "SAOP") {
            return `${this.props.category_indicator.name}: ${indicadores} Operativos`
        } else if (name == "SAMEJ") {
            return `${this.props.category_indicator.name}: ${indicadores} Mejora`
        }else if(name == "SEGV"){
            return `${this.props.category_indicator.name}: ${indicadores} de Segmentación de ventas`
        }else if(name == "PDT"){
            return `${this.props.category_indicator.name}: ${indicadores} de productividad`
        }
        
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,

                first_question_value_id: (e.target.name == "second_type" && e.target.value == "suma" ? this.state.form.first_question_value_id : ""),
                second_question_value_id: (e.target.name == "first_type" && e.target.value == "suma" ? this.state.form.first_question_value_id : ""),
            },

            selectedOptionFirstQuestionValue: {
                value: (e.target.name == "first_type" && e.target.value == "suma" ? this.state.selectedOptionFirstQuestionValue.value : ""),
                label: (e.target.name == "first_type" && e.target.value == "suma" ? this.state.selectedOptionFirstQuestionValue.label : "Primera pregunta número"),
            },

            selectedOptionSecondQuestionValue: {
                value: (e.target.name == "second_type" && e.target.value == "suma" ? this.state.selectedOptionSecondQuestionValue.value : ""),
                label: (e.target.name == "second_type" && e.target.value == "suma" ? this.state.selectedOptionSecondQuestionValue.label : "Segunda pregunta número"),
            }
        });
    }

    HandleClick = () => {
        if (this.validationForm() == true){
            this.props.updateStateLoad(false)
            if (this.state.modeEdit == false)
                fetch(`/indicator/category_indicators/${this.props.category_indicator.id}/indicators`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.props.loadData();
                        this.clearValues();
                        this.setState({ modal: false })
                        this.props.updateStateLoad(true)
                        this.messageSuccess(data);
                    });
            else {
                fetch(`/indicator/category_indicators/${this.props.category_indicator.id}/indicators/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.props.loadData();
                        this.clearValues();
                        this.setState({ modal: false })
                        this.props.updateStateLoad(true)
                        this.messageSuccess(data);
                    });
            }
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/indicator/category_indicators/${this.props.category_indicator.id}/indicators/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };


    getMonthText = (fecha) => {
        let months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
        return months[fecha]
    }

    showIndicator = (value) => {
        let month = new Date().getMonth();
        
    

        let cumplimiento = ["Sin información", 0 , this.getMonthText(month)];

        value.indicator_data_tables.filter(data=>data.total_column == false).map(data=>{
            if (data.percentage > cumplimiento[1])
            {
                cumplimiento = [`${data.name} tiene el mayor % de ${value.name} al mes de `, data.percentage , this.getMonthText(month)];
            }
        })

      
        return cumplimiento

    }
    numberToCurrency = (amount) => {
        amount = amount ? amount : 0
        var thousandsSeparator = ","
        var currencyNum = "";
        var amountString = amount.toString();
        var digits = amountString.split("");

        var countDigits = digits.length;
        var revDigits = digits.reverse();

        for (var i = 0; i < countDigits; i++) {
            if ((i % 3 == 0) && (i != 0)) {
                currencyNum += thousandsSeparator + revDigits[i];
            } else {
                currencyNum += digits[i];
            }
        };

        var revCurrency = currencyNum.split("").reverse().join("");

        var finalCurrency = "$" + revCurrency;

        return finalCurrency;
    }

    openIndicator = (value) => {
        window.location.href = `/indicator/category_indicators/${this.props.category_indicator.id}/indicators/${value}`;
    }


    handleChangeAutocompleteSubCategory = selectedOptionSubCategory => {
        this.setState({
            selectedOptionSubCategory,
                form: {
                    ...this.state.form,
                    sub_category_indicator_id: selectedOptionSubCategory.value
                }
        });
    };

    //formdata funcionality

    handleChangeSecondChecked = (e) => {
        const isChecked = e.target.checked;
        
        this.setState({
            form: {
                ...this.state.form,
                second_custom_view_id: this.state.modeEdit ? (this.state.form.second_custom_view_id != null ? this.state.form.second_custom_view_id : "") : "",
                second_question_date_id: this.state.modeEdit ? (this.state.form.second_question_date_id != null ? this.state.form.second_question_date_id : "") : "",
                second_question_value_id: this.state.modeEdit ? (this.state.form.second_question_value_id != null ? this.state.form.second_question_value_id : "") : "",
                second_type: this.state.modeEdit ? this.state.form.second_type : "",
                has_fordata_second: isChecked,
            },

            selectedOptionFormatCustomView2: {
                second_custom_view_id: this.state.modeEdit ? this.state.selectedOptionFormatCustomView2.second_custom_view_id : "",
                label: this.state.modeEdit ? this.state.selectedOptionFormatCustomView2.label : "Vista personalizada",
            },

            selectedOptionSecondQuestionDate: {
                second_question_date_id: this.state.modeEdit ? this.state.selectedOptionSecondQuestionDate.second_question_date_id : "",
                label: this.state.modeEdit ? this.state.selectedOptionSecondQuestionDate.label : "Segunda pregunta fecha",
            },

            selectedOptionSecondQuestionValue: {
                second_question_value_id: this.state.modeEdit ? this.state.selectedOptionSecondQuestionValue.second_question_value_id : "",
                label: this.state.modeEdit ? this.state.selectedOptionSecondQuestionValue.label : "Segunda pregunta número",
            },

            questions_date2: this.state.modeEdit ? this.state.questions_date2 : [],
            questions_number2: this.state.modeEdit ? this.state.questions_number2 : [],
        })
    }

    handleChangeChecked = (e) => {
        const isChecked = e.target.checked;

        this.setState({
            form: {
                ...this.state.form,
                has_fordata: isChecked,
                first_custom_view_id: this.state.modeEdit ? (this.state.form.first_custom_view_id != null ? this.state.form.first_custom_view_id : "") : "",
                first_question_date_id: this.state.modeEdit ? (this.state.form.first_question_date_id != null ? this.state.form.first_question_date_id : "") : "",
                first_question_value_id: this.state.modeEdit ? (this.state.form.first_question_value_id != null ? this.state.form.first_question_value_id : "") : "",
                first_type: this.state.modeEdit ? this.state.form.first_type : "",
            },

            selectedOptionFormatCustomView1: {
                first_custom_view_id: this.state.modeEdit ? this.state.selectedOptionFormatCustomView1.first_custom_view_id : "",
                label: this.state.modeEdit ? this.state.selectedOptionFormatCustomView1.label : "Vista personalizada",
            },

            selectedOptionFirstQuestionDate: {
                first_question_date_id: this.state.modeEdit ? this.state.selectedOptionFirstQuestionDate.first_question_date_id : "",
                label: this.state.modeEdit ? this.state.selectedOptionFirstQuestionDate.label : "Primera pregunta fecha"
            },

            selectedOptionFirstQuestionValue: {
                first_question_value_id: this.state.modeEdit ? this.state.selectedOptionFirstQuestionValue.first_question_value_id : "",
                label: this.state.modeEdit ? this.state.selectedOptionFirstQuestionValue.label : "Primera pregunta número"
            },

            questions_date1: this.state.modeEdit ? this.state.questions_date1 : [],
            questions_number1: this.state.modeEdit ? this.state.questions_number1 : [],
        })
    }

    LoadFormatCustomView1 = (format_custom_view_id) => {
        fetch(`/formatos/format_custom_views/${format_custom_view_id}/get_format_custom_view_format_values`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.setState({
                questions_date1: data.questions_date,
                questions_number1: data.questions_number,
            });
        });
    }

    LoadFormatCustomView2 = (format_custom_view_id) => {
        fetch(`/formatos/format_custom_views/${format_custom_view_id}/get_format_custom_view_format_values`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.setState({
                questions_date2: data.questions_date,
                questions_number2: data.questions_number,
            });
        });
    }

    handleChangeAutocompleteFormatCustomView1 = (selectedOptionFormatCustomView1) => { 
        this.LoadFormatCustomView1(selectedOptionFormatCustomView1.value)

        this.setState({
            selectedOptionFormatCustomView1,
            form: {
                ...this.state.form,
                first_custom_view_id: selectedOptionFormatCustomView1.value
            },
        });
    }

    handleChangeAutocompleteFormatCustomView2 = (selectedOptionFormatCustomView2) => {
        this.LoadFormatCustomView2(selectedOptionFormatCustomView2.value)

        this.setState({
            selectedOptionFormatCustomView2,
            form: {
                ...this.state.form,
                second_custom_view_id: selectedOptionFormatCustomView2.value
            },
        });
    }

    handleChangeAutocompleteFirstQuestionDate = selectedOptionFirstQuestionDate => {
        this.setState({
            selectedOptionFirstQuestionDate,
            form: {
                ...this.state.form,
                first_question_date_id: selectedOptionFirstQuestionDate.value
            }
        });
    };

    handleChangeAutocompleteFirstQuestionValue = selectedOptionFirstQuestionValue => {
        this.setState({
            selectedOptionFirstQuestionValue,
            form: {
                ...this.state.form,
                first_question_value_id: selectedOptionFirstQuestionValue.value
            }
        });
    };

    handleChangeAutocompleteSecondQuestionDate = selectedOptionSecondQuestionDate => {
        this.setState({
            selectedOptionSecondQuestionDate,
            form: {
                ...this.state.form,
                second_question_date_id: selectedOptionSecondQuestionDate.value
            }
        });
    };

    handleChangeAutocompleteSecondQuestionValue = selectedOptionSecondQuestionValue => {
        this.setState({
            selectedOptionSecondQuestionValue,
            form: {
                ...this.state.form,
                second_question_value_id: selectedOptionSecondQuestionValue.value
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.category_indicator.name}`}
                    subTitle={`Gestión de Indicadores`}
                    titleNew={"Nuevo indicador"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Editar Indicador" : "Agregar nuevo Indicador"}
                        modeEdit={this.state.modeEdit}

                        //form props
                        formValues={this.state.form}
                        errorValues={this.state.ErrorValues}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}
                        isLoaded={this.props.isLoaded}

                        //type
                        type={this.props.type}

                        //select1

                        selectedOptionPosition1={this.state.selectedOptionPosition1}
                        handleChangeAutocomplete1={this.handleChangeAutocomplete1}

                        //select2

                        selectedOptionPosition2={this.state.selectedOptionPosition2}
                        handleChangeAutocomplete2={this.handleChangeAutocomplete2}

                        //select3

                        selectedOptionPosition3={this.state.selectedOptionPosition3}
                        handleChangeAutocomplete3={this.handleChangeAutocomplete3}

                        //select sub category
                        selectedOptionSubCategory={this.state.selectedOptionSubCategory}
                        handleChangeAutocompleteSubCategory={this.handleChangeAutocompleteSubCategory}
                        sub_category_indicators_select={this.props.sub_category_indicators_select}

                        position={this.state.position}
                        format_custom_views={this.props.format_custom_views}

                        handleChangeChecked={this.handleChangeChecked}
                        handleChangeSecondChecked={this.handleChangeSecondChecked}

                        //select format custom view 1
                        selectedOptionFormatCustomView1={this.state.selectedOptionFormatCustomView1}
                        handleChangeAutocompleteFormatCustomView1={this.handleChangeAutocompleteFormatCustomView1}

                        //select format custom view 2
                        selectedOptionFormatCustomView2={this.state.selectedOptionFormatCustomView2}
                        handleChangeAutocompleteFormatCustomView2={this.handleChangeAutocompleteFormatCustomView2}

                        // First question date
                        handleChangeAutocompleteFirstQuestionDate={this.handleChangeAutocompleteFirstQuestionDate}
                        selectedOptionFirstQuestionDate={this.state.selectedOptionFirstQuestionDate}
                        questions_date1={this.state.questions_date1}

                        // First question value
                        questions_number1={this.state.questions_number1}
                        handleChangeAutocompleteFirstQuestionValue={this.handleChangeAutocompleteFirstQuestionValue}
                        selectedOptionFirstQuestionValue={this.state.selectedOptionFirstQuestionValue}

                        // Second question date
                        questions_date2={this.state.questions_date2}
                        handleChangeAutocompleteSecondQuestionDate={this.handleChangeAutocompleteSecondQuestionDate}
                        selectedOptionSecondQuestionDate={this.state.selectedOptionSecondQuestionDate}

                        // Second question value
                        handleChangeAutocompleteSecondQuestionValue={this.handleChangeAutocompleteSecondQuestionValue}
                        selectedOptionSecondQuestionValue={this.state.selectedOptionSecondQuestionValue}
                        questions_number2={this.state.questions_number2}
                    />
                )}

                {this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="row">
                            {this.props.category_indicator_view && (
                                <div className="col-md-12 mb-3">
                                    <div className="row">
                                        {this.props.sub_category_indicators.map((sub_category_indicator, index) => (
                                            <div className={`col-md-2 text-center ${index == 0 ? "pr-0" : ""}`} key={sub_category_indicator.id}>
                                                <div className="card">
                                                    <a 
                                                        href={`/indicator/sub_category_indicators/${sub_category_indicator.id}`}
                                                        target="_blank"
                                                        data-turbolinks="true"
                                                    >
                                                        {sub_category_indicator.name}
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        {this.props.data.length >= 1 ? (
                            <div className="row indicators-wp">
                                {this.props.data.map(indicator => (
                                    <div className="col-md-4 mb-4">
                                        <div className="card">
                                            <div className="card-body card-ventas-gerencia">



                                                <p className="card-ventas-name">{indicator.name}</p>
                                                <hr />
                                                <p className="card-ventas-value"> {this.showIndicator(indicator) != undefined ? this.showIndicator(indicator)[1] : ""}%</p>
                                                <p className="card-ventas-desc">{this.showIndicator(indicator) != undefined ? this.showIndicator(indicator)[0] : ""} <br /><span>{this.showIndicator(indicator) != undefined ? this.showIndicator(indicator)[2] : ""}</span></p>
                                               {/*  <p>{indicator.description}</p> */}
                                            </div>

                                            <div className="card-footer">

                                                {this.props.estados.editar && (
                                                    <a
                                                        className="btn"
                                                        onClick={() => this.edit(indicator)}
                                                    >
                                                        <i className="fas fa-pen"></i>
                                                    </a>
                                                )}

                                                {this.props.estados.eliminar && (
                                                    <a
                                                        className="btn"
                                                        onClick={() => this.delete(indicator.id)}
                                                    >
                                                        <i className="fas fa-trash-alt"></i>
                                                    </a>
                                                )}

                                                {this.props.estados.gestionar && (
                                                    <div className="text-right" style={{ width: "100%" }}>
                                                        <button className="btn btn-primary" onClick={() => this.openIndicator(indicator.id)}> Ver Indicador</button>
                                                    </div>
                                                )}  

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        ) : (
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h4>No hay indicadores</h4>
                                    </div>
                                </div>
                            )}
                    </React.Fragment>
                ) : (
                        <div className="col-md-12 text-center">
                            <Preloader />
                        </div>
                    )}
            </React.Fragment>
        );
    }
}

export default IndexSegmentacion;