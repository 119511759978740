import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormSelectColumn extends Component {
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    {/*<ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>*/}
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <input
                                        type="hidden"
                                        name="column_id"
                                        value={this.props.selectedOption.column_id}
                                    />

                                    <Select
                                        onChange={this.props.handleChangeAutocomplete}
                                        options={this.props.columns}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.props.selectedOption}
                                    />
                                </div>
                            </div>
                        </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormSelectColumn;
