import React, { Component } from 'react';
import HeaderTitle from '../../GeneralComponents/HeaderTitle'
import Preloader from '../../GeneralComponents/LoadingComponen'

class Index extends Component {
    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Tutoriales"}
                    subTitle={"Centro de ayudas"}                    
                    icon="fas fa-info-circle"                    
                />               

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="card ui-knowledge_base-indexContainer--all">
                            <div className="ui-knowledge_base-indexHeader">
                                <div className="ui-knowledge_base-indexHeader-left">
                                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/1656/Question_2.png" alt="Knowlege_img_1" />

                                </div>
                                <div className="ui-knowledge_base-indexHeader-center">
                                    <h3>Centro de Ayuda</h3>
                                    <h4>¿Cómo podemos ayudarte?</h4>
                                </div>
                                <div className="ui-knowledge_base-indexHeader-right">
                                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/1655/Question_1.png" alt="Knowlege_img_2" />

                                </div>
                            </div>
                            <div className="card-body ui-knowledge_base-indexBody--container">
                                {/* Fila 1 */}
                                <div className="ui-knowledge_base-indexBody-items-left">
                                    <a href="#" className='ui-knowledge_base-indexBody-items-link'>
                                        <div className="ui-knowledge_base-indexBody-items-icon">
                                            <i className="fas fa-shoe-prints"></i>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-title">
                                            <h5>Primeros Pasos</h5>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-description">
                                            <p>
                                                Conoce la plataforma digital empezando desde su configuración inicial y aprende como funciona beewo.
                                            </p>
                                        </div>
                                    </a>
                                </div>

                                <div className="ui-knowledge_base-indexBody-items-center">
                                    <a href="#" className='ui-knowledge_base-indexBody-items-link'>
                                        <div className="ui-knowledge_base-indexBody-items-icon">
                                            <i className="far fa-lightbulb"></i>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-title">
                                            <h5>Fordata</h5>
                                        </div>
                                            
                                        <div className="ui-knowledge_base-indexBody-items-description">
                                            <p>
                                                Administra tu empresa creando formularios, evaluaciones, control de procesos y gestión de datos y generando informes y gráficas que te facilita el análisis y la toma de decisiones oportunas.
                                            </p>
                                        </div>
                                    </a>
                                </div>

                                <div className="ui-knowledge_base-indexBody-items-right">
                                    <a href="#" className='ui-knowledge_base-indexBody-items-link'>
                                        <div className="ui-knowledge_base-indexBody-items-icon">
                                            <i className="fas fa-thumbtack"></i>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-title">
                                            <h5>Tareas</h5>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-description">
                                            <p>
                                                Realiza trabajo colaborativo gestionando las tareas y responsabilidades de los equipos en tiempo real . Gestiona proyectos con la metodología basada en Gantt.
                                            </p>
                                        </div>
                                    </a>
                                </div>
                                
                                {/* Fila 2 */}
                                <div className="ui-knowledge_base-indexBody-items-left">
                                    <a href="#" className='ui-knowledge_base-indexBody-items-link'>
                                        <div className="ui-knowledge_base-indexBody-items-icon">
                                            <i className="fas fa-cog"></i>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-title">
                                            <h5>Estrategia</h5>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-description">
                                            <p>
                                                Plantea tus objetivos e indicadores claves empresariales y visualízalos en graficas.
                                            </p>
                                        </div>
                                    </a>
                                </div>

                                <div className="ui-knowledge_base-indexBody-items-center">
                                    <a href="#" className='ui-knowledge_base-indexBody-items-link'>
                                        <div className="ui-knowledge_base-indexBody-items-icon">
                                            <i className="fas fa-tools"></i>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-title">
                                            <h5>Gestión documental</h5>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-description">
                                            <p>
                                                Crea, revisa y aprueba tus documentos con editor en línea . Controla las versiones y su disponibilidad de consultarlos con tu equipo de trabajo.
                                            </p>
                                        </div>
                                    </a>
                                </div>

                                <div className="ui-knowledge_base-indexBody-items-right">
                                    <a href="#" className='ui-knowledge_base-indexBody-items-link'>
                                        <div className="ui-knowledge_base-indexBody-items-icon">
                                            <i className="far fa-building"></i>
                                        </div>
                                            <div className="ui-knowledge_base-indexBody-items-title">
                                                <h5>Administración</h5>
                                            </div>
                                            <div className="ui-knowledge_base-indexBody-items-description">
                                                <p>
                                                    Administra tu cuenta empresarial de beewo con información relevante del uso, almacenamiento y fechas de renovación además de estadísticas resumen de la plataforma digital. 
                                                </p>
                                            </div>
                                    </a>
                                </div>

                                {/* Fila 3 */}
                                <div className="ui-knowledge_base-indexBody-items-left">
                                    <a href="#" className='ui-knowledge_base-indexBody-items-link'>
                                        <div className="ui-knowledge_base-indexBody-items-icon">
                                            <i className="fas fa-copy"></i>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-title">
                                            <h5>Beetree</h5>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-description">
                                            <p>
                                             Crea páginas y comparte en un solo enlace tus propios links o formatos desde beewo.
                                            </p>
                                        </div>
                                    </a>
                                </div>

                                <div className="ui-knowledge_base-indexBody-items-center">
                                    <a href="#" className='ui-knowledge_base-indexBody-items-link'>
                                        <div className="ui-knowledge_base-indexBody-items-icon">
                                            <i className="fas fa-trophy"></i>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-title">
                                            <h5>Trucos</h5>
                                        </div>
                                        <div className="ui-knowledge_base-indexBody-items-description">
                                            <p>
                                            Usa beewo de forma hábil y con rápidez para mejorar tu rendimiento.
                                            </p>
                                        </div>
                                    </a>
                                </div>

                                <div className="ui-knowledge_base-indexBody-items-right">
                                    <a href="#" className='ui-knowledge_base-indexBody-items-link'>
                                        <div className="ui-knowledge_base-indexBody-items-icon">
                                            <i className="far fa-question-circle"></i>
                                        </div>
                                            <div className="ui-knowledge_base-indexBody-items-title">
                                                <h5>Tickets</h5>
                                            </div>
                                            <div className="ui-knowledge_base-indexBody-items-description">
                                                <p>
                                                Genera tickets si tienes algún inconveniete y requieres soporte de la plataforma beewo o si deseas sugerir alguna mejora para próximas versiones.
                                                </p>
                                            </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <Preloader />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Index;