import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            showTable: false,
            format_exel_report_id: "",
            nameFile: "",
            form: {
                format_id: this.props.format.id,
                name: "",
                file: {},
            },
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    //files 

    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    file: file
                },

                nameFile: file.path,
                sizeFile: file.size
            })
        ));
    };

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
                if (format.id === item.id) {
                    return {
                        ...item,
                        name: format.name,
                        file: format.file
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            nameFile: "",

            form: {
                ...this.state.form,
                name: "",
                file: "",
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_excel_reports/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/formatos/get_format_excel_reports/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }

    HandleClick = () => {
        const formData = new FormData();
        formData.append("format_id", this.state.form.format_id)
        formData.append("name", this.state.form.name)
        formData.append("file", this.state.form.file)

        if (!this.state.modeEdit) {
            fetch("/formatos/format_excel_reports", {
                method: 'POST', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateData(data.register)
                    this.clearValues();
                    this.setState({ modal: false, showTable: true })
                });
        } else {
            fetch(`/formatos/format_excel_reports/${this.state.format_exel_report_id}`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateItem(data.register)
                    this.clearValues();
                    this.setState({ modal: false })
                });
        }
    }


    edit = (format_exel_report) => {
        this.setState({
            modal: true,
            modeEdit: true,
            format_exel_report_id: format_exel_report.id,

            form: {
                ...this.state.form,
                name: format_exel_report.name,
                file: format_exel_report.file,
            },
        })
    }


    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar excel" : "Crear excel"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}

                        //files 
                        handleFile={this.handleFile}
                        nameFile={this.state.nameFile}
                    />
                )}

                <div className="card mb-3">
                    <div className="card-header" style={{ display: "flow-root", paddingTop: 12 }}>
                        Configuración de excel

                        <button
                            className="btn-shadow btn btn-info float-right"
                            onClick={() => this.toogle("new")}
                        >
                            Crear
                        </button>

                        <a className="float-right mr-3" data-toggle="collapse" href="#collapseExampleExel" role="button" aria-expanded="false" aria-controls="collapseExampleExel">
                            <i className="fas fa-angle-down"></i>
                        </a>
                    </div>

                    <div className="card-body p-3">
                        <div className="row">

                            <div className={`collapse ${this.state.showTable ? "show" : ""} w-100`} id="collapseExampleExel">
                                <div className="col-md-12">
                                    <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Archivo</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.data.length >= 1 ? (
                                                this.state.data.map(format_exel_report => (
                                                    <tr key={format_exel_report.id}>
                                                        <td>{format_exel_report.name}</td>
                                                        <td>
                                                            {format_exel_report.file.url != null ? (
                                                                <React.Fragment>
                                                                    <a href={`${format_exel_report.file.url}`}>
                                                                        <i className="fas fa-file-download"></i>
                                                                    </a>
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <span>No hay archivos</span>
                                                                </React.Fragment>
                                                            )}
                                                        </td>
                                                        {true && (
                                                            <td className="text-right" style={{ width: "10px" }}>
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(format_exel_report)}
                                                                                        
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (  
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(format_exel_report.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
