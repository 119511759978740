import React, { Component } from 'react';
import Table from '../components/settings/Plans/Index'
import WebpackerReact from 'webpacker-react';

class PlanIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            companies: [],
            isLoaded: true,
        }
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    updateAllData = (data) => {
        this.setState({ data: data })
    }

    componentDidMount = () => {
        this.defaultValues();
        this.loadData();
    }

    defaultValues = () => {
        let array = []

        this.props.companies.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            companies: array,
        })
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = plan => {
        this.setState({
            data: this.state.data.map(item => {
            if (plan.id === item.id) {
              return { ...item, 
                name: plan.name, 
                nick_name: plan.nick_name, 
                users_plan: plan.users_plan, 
                size_plan: plan.size_plan,  
                records_plan: plan.records_plan, 
                tasks_board_plan: plan.tasks_board_plan, 
                price: plan.price, 
                description: plan.description, 
                link: plan.link, 
                background: plan.background, 
                show: plan.show, 
                sign_up_title: plan.sign_up_title, 
                watermark: plan.watermark, 
                has_watermark_demo: plan.has_watermark_demo, 
                has_watermark_always: plan.has_watermark_always, 
                channel_state: plan.channel_state, 
                payment_year: plan.payment_year, 
                link_payment_year: plan.link_payment_year, 
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/settings/get_plans`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    imageUpload={this.props.imageUpload}
                    key_text={this.props.key_text}
                    companies={this.state.companies}
                    updateAllData={this.updateAllData}
                />
            </React.Fragment>
        );
    }
}


export default PlanIndex;
WebpackerReact.setup({ PlanIndex });

