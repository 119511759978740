import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';

class TableIndexWeb extends Component {
    render() {
        const sytleFalse = {
            fontSize: "32px",
            color: "#ff0000",
            cursor: "pointer"
        }

        const sytleTrue = {
            fontSize: "32px",
            color: "#589000",
            cursor: "pointer"
        }

        return (
            <React.Fragment>
                <div className="content main-card mb-3 card card-style">
                    <div className="card-body ui-tableIndexWeb-users-register-card-body">
                        <div className="ui-tableIndexWeb-users-options__container">
                            <div className="ui-tableIndexWeb-users-count-register">
                                <p><span>{this.props.dataUsers.length} Registros</span></p>
                            </div>

                            <div className="ui-tableIndexWeb-users-button-twoFactAut">
                                {/* this.props.current_user.is_admin */}
                                {false && (
                                    <React.Fragment>
                                        <div className="ui-tableIndexWeb-users-info">
                                            <UncontrolledTooltip target='ui-tableIndexWeb-users-message-info' placement='left'>
                                                {this.props.factor_authentication ? (
                                                    'La verificación de 2 pasos está activa para todos los usuarios de tu organización y ahora son parte del esfuerzo por mantener tus datos seguros.'
                                                ) : (
                                                    'Al habilitar la opción de verificación de 2 pasos para todos los usuarios de tu empresa, estás promoviendo la seguridad de la información y previniendo hackeos de las cuentas de los usuarios de tu organización.'
                                                )}
                                            </UncontrolledTooltip>
                                            <i className="fas fa-info-circle mr-3" id='ui-tableIndexWeb-users-message-info' style={this.props.factor_authentication ? {color: "#16aafb" } : { color: "#b81f44" }}></i>
                                        </div>

                                        <button
                                            className={`btn btn-${this.props.factor_authentication ? "info" : "danger"} mt-2 btn-shadow`}
                                            onClick={this.props.changeAuthentication}
                                        >
                                            {this.props.factor_authentication ? "Deshabilitar verificación de 2 pasos" : "Habilitar verificación de 2 pasos"}
                                        </button>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>

                        <div className="ui-tableIndexWeb-users-table__container">
                            <table style={{ tableLayout: "fixed" }} className="table ui-tables_layout-table-header__container" id="sampleTable">
                                <thead className='ui-tables_layout-table-header'>
                                    <tr className='ui-tables_layout-__container-title-table'>
                                        <th style={{ width: "75px" }}>Imagen</th>
                                        <th>Nombre de usuario</th>
                                        <th>Correo electrónico</th>
                                        <th>Nombre</th>
                                        <th>Cargos</th>
                                        {this.props.estados.uploadFile && (
                                            <th>Adjuntos</th>
                                        )}

                                        {this.props.estados.change_state && (
                                            <th style={{ width: "8%" }}>Estado</th>
                                        )}

                                        {(this.props.estados.editar || this.props.estados.eliminar) && (
                                            <th className="text-center button-options" style={{ width: "89px" }}>Opciones</th>
                                        )}
                                    </tr>
                                </thead>
                            
                                <tbody className='ui-tables_layout-data__container'>
                                    {this.props.dataUsers.length >= 1 ? (
                                        this.props.dataUsers.map(user => (
                                            <tr key={user.id}>

                                                <td><img className="rounded-circle" src={user.avatar.includes("undefined") == false ? user.avatar : "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRuEG5mQKFGi9hNgrdBJnEyBfw2Kl0JNXwoHiXjVeKQemKOQqFe"} width="40" height="40" alt={`${user.first_name} ${user.first_last_name}`} /></td>
                                                <td>{user.username}</td>
                                                <td>{user.second_email}</td>
                                                <td>{user.first_name} {user.first_last_name}</td>
                                                <td>{user.position != undefined ? user.position.name : "Sin Cargo"}</td>


                                                {this.props.estados.uploadFile && (
                                                    <td>
                                                        <p> <span className="link-line" onClick={() => this.props.addFiles(user)}>{user.general_files.length} Archivos adjuntos </span> </p>
                                                    </td>
                                                )}

                                                {this.props.estados.change_state && (
                                                    <td className="text-left">
                                                        {user.user_state ? (
                                                            <React.Fragment>
                                                                <UncontrolledTooltip target={`active${user.id}`}>{'Activo'}</UncontrolledTooltip>
                                                                <i className="ml-2 fas fa-check" id={`active${user.id}`} onClick={() => this.props.updateState(user.id, false)} style={sytleTrue}></i> 
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <UncontrolledTooltip target={`active${user.id}`}>{'Inactivo'}</UncontrolledTooltip>
                                                                <i className=" ml-2 fas fa-times" id={`active${user.id}`} onClick={() => this.props.updateState(user.id, true)} style={sytleFalse}></i>
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                )}

                                                {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                    <td className="text-center" style={{ width: "10px" }}>
                                                        <UncontrolledDropdown direction="left" className='btn-group ui-tables_layout-dropdown__container'>
                                                            <DropdownToggle>
                                                                <i className="fas fa-bars"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="ui-tables_layout-dropdown_header dropdown-menu-right">
                                                                {(this.props.estados.editar) && (
                                                                    <DropdownItem
                                                                        onClick={() => this.props.edit(user)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </DropdownItem>
                                                                )}
                                                                {this.props.estados.eliminar && !user.is_tenant && (
                                                                    <DropdownItem
                                                                        onClick={() => this.props.delete(user.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </DropdownItem>
                                                                )}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>

                                                )}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                <div className="text-center mt-4 mb-4">
                                                    <h4>No hay registros con el criterio de búsqueda</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TableIndexWeb;