import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>Característica</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.props.formValues.name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control`}
                                            autocomplete="off"
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "203px" }}>Características</th>
                                                    {this.props.plants.map(plant => (
                                                        <th key={plant.id} className="text-center"><b>{plant.name}</b></th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody>

                                                <tr>
                                                    <td>
                                                        {this.props.formValues.name}
                                                    </td>
                                                                
                                                    {this.props.plants.map(plan => (
                                                        <td className="text-center">
                                                            <input 
                                                                type="checkbox" 
                                                                value={plan.id}
                                                                checked={this.props.formValues.plan_ids.includes(plan.id)}
                                                                onChange={() => this.props.handleChangeCheckBox(plan.id)}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBnt}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;