import React, { useState, useEffect } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const ShowDriverReview = (props) => {
    const [openIndexes, setOpenIndexes] = useState([]); //rotate icon principal accordion
    const [isMobile, setIsMobile] = useState(false); // control size screen in <= 768 or 992 && 1269

    // Constant that receives props as an object to be traversed
    const form = {
        first_name: props.trs_driver_review.first_name,
        second_name: props.trs_driver_review.second_name,
        first_last_name: props.trs_driver_review.first_last_name,
        second_last_name: props.trs_driver_review.second_last_name,
        document_type: props.trs_driver_review.document_type,
        document: props.trs_driver_review.document,
        expedition_date: props.trs_driver_review.expedition_date,
        expedition_location: props.trs_driver_review.expedition_location,
        date_of_birth: props.trs_driver_review.date_of_birth,
        gender: props.trs_driver_review.gender,
        blood_type: props.trs_driver_review.blood_type,
        civil_status: props.trs_driver_review.civil_status,
        department: props.trs_driver_review.department,
        city: props.trs_driver_review.city,
        address: props.trs_driver_review.address,
        phone: props.trs_driver_review.phone,
        mobile: props.trs_driver_review.mobile,
        email: props.trs_driver_review.email,
        driving_license: props.trs_driver_review.driving_license,
        license_category: props.trs_driver_review.license_category,
        license_expiration: props.trs_driver_review.license_expiration,
        license_restrictions: props.trs_driver_review.license_restrictions,
        enterprise: 'Cosmo Legal',
        init_date: '01-01-202',
        due_date: '31-12-2022',
        type_vehicule: props.trs_driver_review.vehicle_type_experience,
        transport_type: props.trs_driver_review.transport_type_experience,
        trs_driver_internal_reports: props.trs_driver_review.trs_driver_internal_reports,
        trs_driver_external_reports: props.trs_driver_review.trs_driver_external_reports
    };

    //control size screen by table or list item subaccordion
    useEffect(() => {
        function handleWindowSizeChange() {
            const windowWidth = window.innerWidth;
            const mobileThreshold = 768;
            console.log(windowWidth);
            if ((windowWidth <= mobileThreshold) || (windowWidth >= 992 && windowWidth <= 1269)) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }

            console.log(isMobile);
        }

        window.addEventListener('resize', handleWindowSizeChange);
        handleWindowSizeChange();

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    // Array of objects that allows you to create the first accordion is made this way to facilitate the visualization 
    // of the data and to go through it in an orderly way, both the titles and the data of the constant form of where the props are.
    const itemsAccordionDataDrivers = [
        {
            title: 'Datos Personales', //title of accordion
            data_group: [ //data accordion inside
                { tittle_accordion: 'Primer Nombre', data_accordion: form.first_name },
                { tittle_accordion: 'Segundo Nombre', data_accordion: form.second_name },
                { tittle_accordion: 'Primer Apellido', data_accordion: form.first_last_name },
                { tittle_accordion: 'Segundo Apellido', data_accordion: form.second_last_name },
                { tittle_accordion: 'Tipo de Documento', data_accordion: form.document_type },
                { tittle_accordion: 'Número de Documento', data_accordion: form.document },
                { tittle_accordion: 'Fecha de Expedición', data_accordion: form.expedition_date },
                { tittle_accordion: 'Lugar de Expedición', data_accordion: form.expedition_location },
                { tittle_accordion: 'Fecha de Nacimiento', data_accordion: form.date_of_birth },
                { tittle_accordion: 'Género', data_accordion: form.gender },
                { tittle_accordion: 'Tipo de Sangre', data_accordion: form.blood_type },
                { tittle_accordion: 'Estado Civil', data_accordion: form.civil_status }
            ]
        },
        {
            title: 'Residencia', //title of accordion
            data_group: [ //data accordion inside
                { tittle_accordion: 'Departamento', data_accordion: form.department },
                { tittle_accordion: 'Municipio', data_accordion: form.city },
                { tittle_accordion: 'Dirección', data_accordion: form.address },
                { tittle_accordion: 'Teléfono', data_accordion: form.phone },
                { tittle_accordion: 'Celular', data_accordion: form.mobile },
                { tittle_accordion: 'Correo', data_accordion: form.email },
            ]
        },
        {
            title: 'Licencia', //title of accordion
            data_group: [ //data accordion inside
                { tittle_accordion: 'Número', data_accordion: form.driving_license },
                { tittle_accordion: 'Categoría', data_accordion: form.license_category },
                { tittle_accordion: 'Vencimiento', data_accordion: form.license_expiration },
                { tittle_accordion: 'Restricciones', data_accordion: form.license_restrictions },
            ]
        },
        {
            title: 'Experiencia', //title of accordion
            data_group: [ //data accordion inside
                { tittle_accordion: 'Empresa', data_accordion: form.enterprise },
                { tittle_accordion: 'Fecha Inicio', data_accordion: form.init_date },
                { tittle_accordion: 'Fecha Fin', data_accordion: form.due_date },
                { tittle_accordion: 'Tipo Vehículo', data_accordion: form.type_vehicule },
                { tittle_accordion: 'Tipo Transporte', data_accordion: form.transport_type },
            ]
        },
        {
            title: 'Reportes Empresa', //title of accordion
            data_group: [ //data accordion inside
                { tittle_accordion: 'Negativos', data_accordion: form.trs_driver_internal_reports },
                { tittle_accordion: 'Positivos', data_accordion: form.trs_driver_internal_reports }
            ]
        },
        {
            title: 'Reportes Usuario', //title of accordion
            data_group: [ //data accordion inside
                { tittle_accordion: 'Negativos', data_accordion: form.trs_driver_external_reports },
                { tittle_accordion: 'Positivos', data_accordion: form.trs_driver_external_reports }
            ]
        },
        {
            title: 'Infracciones', //title of accordion
            data_group: [ //data accordion inside
                { tittle_accordion: 'Actuales', data_accordion: '' },
                { tittle_accordion: 'Anteriores', data_accordion: '' }]
        },
    ];

    // Function that allows you to render the table of the internal sub-accordions of Company Report, 
    // User Report, Infractions, this function receives trs_driver_external_reports and trs_driver_internal_reports in addition to the type of report
    const itemsSubAccordionData = (type_report, report) => {
        let itemsSubAccordion;
        if (type_report === 'Reportes Empresa') { //type report
            if (report[0]) {
                itemsSubAccordion = [ // data type report
                    { tittle_accordion: 'Empresa', data_accordion: report[0].company },
                    { tittle_accordion: 'Fecha', data_accordion: report[0].report_date },
                    { tittle_accordion: 'Motivo', data_accordion: report[0].cause },
                    { tittle_accordion: 'Descripción', data_accordion: report[0].description }
                ];
            } else {
                itemsSubAccordion = [];
            }

        } else if (type_report === 'Reportes Usuario') { //type report
            if (report[0]) {
                itemsSubAccordion = [ // data type report
                    { tittle_accordion: 'Empresa', data_accordion: report[0].company },
                    { tittle_accordion: 'Fecha', data_accordion: report[0].report_date },
                    { tittle_accordion: 'Motivo', data_accordion: report[0].cause },
                    { tittle_accordion: 'Descripción', data_accordion: report[0].description },
                    { tittle_accordion: 'Calificación', data_accordion: report[0].value }
                ];
            } else {
                itemsSubAccordion = [];
            }
        } else {
            itemsSubAccordion = [  // data type report fines           
                { tittle_accordion: 'Resolución', data_accordion: '125s' },
                { tittle_accordion: 'Fecha', data_accordion: '14-02-2021' },
                { tittle_accordion: 'Comparendo', data_accordion: '12546-2145863' },
                { tittle_accordion: 'Fecha', data_accordion: '02-10-2023' },
                { tittle_accordion: 'Secretaria', data_accordion: 'Medellín' },
                { tittle_accordion: 'Estado', data_accordion: 'Pendiente' },
                { tittle_accordion: 'Infracción', data_accordion: 'Exceso de velocidad' },
                { tittle_accordion: 'Valor', data_accordion: '$850.000' }
            ];
        }
        return (
            isMobile ? (
                itemsSubAccordion.map((item, index) => (
                    <React.Fragment key={index}>
                        <div className='ui-drivers_show_accordion-items_title_subtitle'>
                            {item.tittle_accordion}
                        </div>
                        <div className='ui-drivers_show_accordion-items_title_subtxt'>
                            {item.data_accordion}
                        </div>
                    </React.Fragment>
                ))
            ) : (
                <table style={{ width: '100%' }} className='ui-drivers_show_accordion-items-table__container'>
                    <thead className='ui-drivers_show_accordion-items-table_thead'>
                        <tr className='ui-drivers_show_accordion-items-table_thead_tr'>
                            {itemsSubAccordion.map((item, index) => (
                                <th key={index} className='ui-drivers_show_accordion-items_title_subtitle'>{item.tittle_accordion}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='ui-drivers_show_accordion-items-table_tbody'>
                        <tr className='ui-drivers_show_accordion-items-table_tbody_tr'>
                            {itemsSubAccordion.map((item, index) => (
                                <td key={index} className='ui-drivers_show_accordion-items_title_subtxt'>{item.data_accordion}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            )
        );
    }

    const typeItemAccordionTitle = (type) => {
        if (type === 'Reportes Empresa' || type === 'Reportes Usuario' || type === 'Infracciones') {
            return 'ui-drivers_show_accordion-items__container_accordion'
        } else if (type === 'Experiencia') {
            return 'ui-drivers_show_accordion-items__container-table-view';
        } else {
            return 'ui-drivers_show_accordion-items__container';
        }
    }

    const renderDataTypeItem = (type_report, item) => {
        if (type_report === 'Reportes Empresa' || type_report === 'Reportes Usuario' || type_report === 'Infracciones') {
            return (
                item.map((item_accordion, subIndex) => (
                    <AccordionItemPanel className='ui-drivers_show_subaccordion-items_title' key={subIndex}>
                        <div className="ui-driver_show_subaccordion">
                            <Accordion allowMultipleExpanded preExpanded={['element1Uuid']}>
                                <AccordionItem uuid="element1Uuid"> {/** id open subaccordion */}
                                    <AccordionItemHeading>
                                        <AccordionItemButton>{item_accordion.tittle_accordion}</AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className='ui-drivers_show_subaccordion__container-accordion'>
                                        {item_accordion.data_accordion && item_accordion.data_accordion.length > 0 ? (
                                            // Renderizar los datos si existen
                                            itemsSubAccordionData(type_report, item_accordion.data_accordion)
                                        ) : (
                                            // Mostrar un mensaje si no hay datos
                                            <p className='p-2'>No hay datos disponibles para {type_report}</p>
                                        )}
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </AccordionItemPanel>
                ))
            );
        } else if (type_report === 'Experiencia') {
            return (
                <AccordionItemPanel className='ui-drivers_show_accordion-items_title'>
                    {isMobile ? (
                        item.map((item, subIndex) => (
                            <React.Fragment key={subIndex}>
                                <div className='ui-drivers_show_accordion-items_title_subtitle'>
                                    {item.tittle_accordion}
                                </div>
                                <div className='ui-drivers_show_accordion-items_title_subtxt'>
                                    {item.data_accordion}
                                </div>
                            </React.Fragment>
                        ))
                    ) : (
                        <table style={{ width: '100%' }} className='ui-drivers_show_accordion-items-table__container'>
                            <thead>
                                <tr className='ui-drivers_show_accordion-items__container_table'>
                                    {item.map((item, index) => (
                                        <th key={index} className='ui-drivers_show_accordion-items_title_subtitle ui-drivers_show_accordion-items-table_thead_tr'>
                                            {item.tittle_accordion}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='ui-drivers_show_accordion-items__container_table ui-drivers_show_accordion-items-table_tbody_tr'>
                                    <td className='ui-drivers_show_accordion-items_title_subtxt'>{form.enterprise}</td>
                                    <td className='ui-drivers_show_accordion-items_title_subtxt'>{form.init_date}</td>
                                    <td className='ui-drivers_show_accordion-items_title_subtxt'>{form.due_date}</td>
                                    <td className='ui-drivers_show_accordion-items_title_subtxt'>{form.type_vehicule}</td>
                                    <td className='ui-drivers_show_accordion-items_title_subtxt'>{form.transport_type}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </AccordionItemPanel>
            );
        } else {
            return (
                item.map((item, subIndex) => (
                    <AccordionItemPanel className='ui-drivers_show_accordion-items_title'>
                        <React.Fragment key={subIndex}>
                            <div className='ui-drivers_show_accordion-items_title_subtitle'>
                                {item.tittle_accordion}
                            </div>
                            <div className='ui-drivers_show_accordion-items_title_subtxt'>
                                {item.data_accordion}
                            </div>
                        </React.Fragment>
                    </AccordionItemPanel>
                ))
            );
        }
    };

    const handleChangeIcon = (index) => {
        if (openIndexes.includes(index)) {
            // Si el elemento está abierto, ciérralo
            setOpenIndexes(openIndexes.filter((item) => item !== index));
        } else {
            // Si el elemento está cerrado, ábrelo
            setOpenIndexes([...openIndexes, index]);
        }
    }

    return (
        <div className='card'>
            <div className="card-body">
                {Object.keys(props.trs_driver_review).length != 0 ? (
                    <React.Fragment>
                        <div className="ui-drivers_show__container-title">
                            <div className="ui-drivers_show_title__container">
                                <span className='ui-drivers_show_title-first'>Consulta de</span>
                                <span className='ui-drivers_show_title-second'>Conductores</span>
                            </div>
                            <div className="ui-drivers_show-buttons_container-search">
                                <a href='./search_format' className='btn btn-outline-info ui-drivers_show_buttons_back mr-2' data-turbolinks="true">
                                    VOLVER
                                </a>
                                {/* <UncontrolledDropdown className='btn-group'>
                                    <DropdownToggle className='btn-info ui-drivers_show_buttons mr-2'>
                                        REPORTES
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                        {true && (
                                            <DropdownItem
                                                className="dropdown-item"
                                            >
                                                REPORTES EMPRESA
                                            </DropdownItem>
                                        )}

                                        {true && (
                                            <DropdownItem
                                                className="dropdown-item"
                                            >
                                                REPORTES USUARIO
                                            </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown> */}

                                {/* <button className='btn btn-info  ui-drivers_show_buttons'>
                                    INFRACCIONES
                                </button> */}

                            </div>
                        </div>
                        <div className="ui-drivers_show_subaccordion-data-driver__container">
                            <div className="ui-drivers_show_subaccordion-data-driver_name ml-2">
                                <label className='ui-drivers_show_accordion-items_title_subtitle'>Nombre Completo</label>
                                <span>{props.trs_driver_review.full_name}</span>
                            </div>
                            <div className="ui-drivers_show_subaccordion-data-driver-cc">
                                <label className='ui-drivers_show_accordion-items_title_subtitle'>Número de Documento</label>
                                <span>{props.trs_driver_review.document}</span>
                            </div>
                        </div>
                        <div>
                            <Accordion allowZeroExpanded allowMultipleExpanded>
                                {itemsAccordionDataDrivers.map((data, index) => (
                                    <AccordionItem onClick={() => handleChangeIcon(index)} key={index}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span className="ui-drivers_show_accordion-title">{data.title} <i className={`fas ${(openIndexes.includes(index)) ? 'fa-caret-up' : 'fa-caret-right'} ui-drivers_show_accordion-title-icons`}></i></span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <div className={typeItemAccordionTitle(data.title)} >
                                            {renderDataTypeItem(data.title, data.data_group)}
                                        </div>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </div>
                    </React.Fragment>
                ) : (
                    <div
                        className='text-center'
                    >
                        <h4>No hay registros con el criterio de búsqueda</h4>
                        <a className='btn-shadow btn btn-info mt-3' href="/formatos/search_format" data-turbolinks="true">Realizar otra búsqueda</a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShowDriverReview;