import React, { Component } from 'react';
import HeaderTitle from '../GeneralComponents/HeaderTitle';
import Dropzone from "react-dropzone";
import Table from '../components/settings/Tenants/Table';
import FormFilter from '../components/settings/Tenants/FormFilter';
import { consolidateStreamedStyles } from 'styled-components';
import Preloader from '../GeneralComponents/LoadingComponen';

class TenantIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            //pagination 

            activePage: 1,
            countPage: 60,
            total: 0,

            data: [],
            states: [],
            cities: [],
            showFilter: false,
            isLoaded: false,
            plan_category_id: this.props.plan_categories[0].id,
            plan_id: null,

            selectedOptionCountry: {
                country_field: "",
                label: "Pais"
            },

            selectedOptionState: {
                state_field: "",
                label: "Departamento"
            },

            selectedOptionCity: {
                city_field: "",
                label: "Ciudad"
            },

            form: {
                company_name: "",
                username: "",
                email: "",
                contact_number: "",
                state_records: "",
                tenant_state: "",
                start_date: "",
                end_date: "",
                country_field: "",
                state_field: "",
                city_field: "",
                expiration_start_date: "",
                expiration_end_date: ""
            }
        }
    }

    loadData = (plan_id) => {
        fetch(`/settings/get_info_index/${plan_id}/${this.props.active}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                total: data.total,
                plan_id: plan_id,
                isLoaded: false
            });
        });
    }

    loadStates = (country) => {
        const form = {
            country: country
        }

        function removeLastWord(name) {
            if (name.indexOf('Department') > -1 ){
            const lastIndexOfSpace = name.lastIndexOf(' ');

            if (lastIndexOfSpace === -1) {
                return name;
            }
                return name.substring(0, lastIndexOfSpace);
            } else {
                return name;
            }
        }
        
       
        fetch(`https://countriesnow.space/api/v0.1/countries/states`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(form),
            headers:{
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
            const array = [];

            response.data.states.map((name, index) => (
                ((name.name == 'Archipelago of Saint Andréws, Providence and Saint Catalina') ? (                    
                    array.push({label: 'Archipiélago San Andrés, Providencia y Santa Catalina', 
                    value: 'Archipiélago San Andrés, Providencia y Santa Catalina'})
                ):(
                    array.push({ label: removeLastWord(name.name), value: removeLastWord(name.name)})
                )
            )))
            

            this.setState({
                states: array
            })

        });
    }

    loadCities = (states) => {
        let form2 = {};
        if((this.state.form.country_field == 'Colombia') || 
            (this.state.form.country_field== 'Bolivia') ||
            (this.state.form.country_field== 'El Salvador') ||
            (this.state.form.country_field== 'Guatemala') ||
            (this.state.form.country_field== 'Honduras')||
            (this.state.form.country_field== 'Nicaragua')||
            (this.state.form.country_field== 'Paraguay')||
            (this.state.form.country_field== 'Perú')||
            (this.state.form.country_field== 'Uruguay')||
            (this.state.form.country_field== 'Benin')||
            (this.state.form.country_field== 'Haiti')||
            (this.state.form.country_field== 'Congo')||
            (this.state.form.country_field== 'Republic of the Congo')||
            (this.state.form.country_field== 'Argentina')||
            (this.state.form.country_field== 'Burkina Faso')||
            (this.state.form.country_field== 'Cameroon')||
            (this.state.form.country_field== 'Chad')||
            (this.state.form.country_field== 'Ivory Coast')||
            (this.state.form.country_field== 'France')||
            (this.state.form.country_field== 'Gabon')||
            (this.state.form.country_field== 'Mauritania')||
            (this.state.form.country_field== 'Ivory Coast')||
            (this.state.form.country_field== 'Niger')||
            (this.state.form.country_field== 'Senegal')) {

            form2 = {
                country: this.state.form.country_field,
                state: `${states} Department`
            }

        } else {

            form2 = {
                country: this.state.form.country_field,
                state: states
            }
        }
        
        console.log(form2);
        

        fetch(`https://countriesnow.space/api/v0.1/countries/state/cities`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(form2),
            headers:{
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
            console.log(response);
            const array = [];

            response.data.map((name) => (
                array.push({ label: name, value: name })
            ))

            this.setState({
                cities: array
            })
        });
    }


    updateItem = tenant => {
        this.setState({
            data: this.state.data.map(item => {
              if (tenant.id === item.id) {
                return { ...item, 
                        count_days: tenant.count_days,
                        info_license_change: tenant.info_license_change,
                        company_name: tenant.company_name,
                        nit: tenant.nit,
                        admin: tenant.admin,
                        task_board_count: tenant.task_board_count,
                        formats: tenant.formats,
                        pages_count: tenant.pages_count,
                        state_records: tenant.state_records,
                        tenant_state: tenant.tenant_state,
                        start_demo: tenant.start_demo,
                        end_demo: tenant.end_demo,
                        plan: tenant.plan,
                        type_user: tenant.type_user,
                        is_base: tenant.is_base,
                        contract: tenant.contract,
                        url_name: tenant.url_name,
                    }
                }
                return item;
            })
        });
    }

    showFilter = () => {
        this.setState({
            showFilter: true
        })
    }

    handleClickFilter = () => {
        fetch(`/settings/get_info_index/${this.state.plan_id}/${this.props.active}?company_name=${this.state.form.company_name}&username=${this.state.form.username}&email=${this.state.form.email}&state_records=${this.state.form.state_records}&tenant_state=${this.state.form.tenant_state}&start_date=${this.state.form.start_date}&end_date=${this.state.form.end_date}&country_field=${this.state.form.country_field}&state_field=${this.state.form.state_field}&city_field=${this.state.form.city_field}&expiration_start_date=${this.state.form.expiration_start_date}&expiration_end_date=${this.state.form.expiration_end_date}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                total: data.total
            })
        });
    }

    //pagination 

    handlePageChange = pageNumber => {
        this.setState({ activePage: pageNumber }); 
        fetch(`/settings/get_info_index/${this.state.plan_id}/${this.props.active}?page=${pageNumber}&company_name=${this.state.form.company_name}&username=${this.state.form.username}&email=${this.state.form.email}&state_records=${this.state.form.state_records}&tenant_state=${this.state.form.tenant_state}&start_date=${this.state.form.start_date}&end_date=${this.state.form.end_date}&country_field=${this.state.form.country_field}&state_field=${this.state.form.state_field}&city_field=${this.state.form.city_field}&expiration_start_date=${this.state.form.expiration_start_date}&expiration_end_date=${this.state.form.expiration_end_date}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({ 
                data: data.data,
                total: data.total,
            });
        });     
    };  

    clearValues = () => {
        this.setState({
            cities: [],
            states: [],

            selectedOptionCountry: {
                country_field: "",
                label: "Pais"
            },

            selectedOptionState: {
                state_field: "",
                label: "Departamento"
            },

            selectedOptionCity: {
                city_field: "",
                label: "Ciudad"
            },

            form: {
                company_name: "",
                username: "",
                email: "",
                contact_number: "",
                state_records: "",
                tenant_state: "",
                start_date: "",
                end_date: "",
                country_field: "",
                state_field: "",
                city_field: "",
                expiration_start_date: "",
                expiration_end_date: ""
            }
        })
    }

    handleClickCancel = () => {
        this.loadData(this.state.plan_id);
        this.clearValues();
        this.setState({
            showFilter: !this.state.showFilter
        })
    }

    handleChangeFilter = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    handleChangeAutocompleteCountry = selectedOptionCountry => {
        this.loadStates(selectedOptionCountry.value);
        this.setState({
            selectedOptionCountry,
            form: {
                ...this.state.form,
                country_field: selectedOptionCountry.value
            }
        });
    };

    handleChangeAutocompleteState = selectedOptionState => {
        this.loadCities(selectedOptionState.value);
        this.setState({
            selectedOptionState,
            form: {
                ...this.state.form,
                state_field: selectedOptionState.value
            }
        });
    };

    handleChangeAutocompleteCity = selectedOptionCity => {
        this.setState({
            selectedOptionCity,
            form: {
                ...this.state.form,
                city_field: selectedOptionCity.value
            }
        });
    };


    changePlanCategory = (plan_category_id) => {
        this.setState({ plan_category_id: plan_category_id })
    }

    changePlan = (plan_id) => {
        this.setState({ isLoaded: true })
        this.loadData(plan_id)
    }

    removeTenant = (tenant_id) => {
        this.setState({ 
            data: this.state.data.filter((e) => e.id != tenant_id),
            total: this.state.total -1 
        })
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle 
                    title={`Clientes ${this.props.active == "true" ? "activos" : "inactivos"}`} 
                    subTitle={`Todos los clientes de gestión salud`}
                    titleNew={"Nuevo módulo"}
                    titleFilter={"Filtros"}
                    icon={"fas fa-user-tie"}
                    onClickNew={this.toogle}
                    onClickFilter={this.showFilter}
                    showNew={false}
                    showFilter={this.state.plan_id ? true : false}
                />

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {this.props.plan_categories.map((plan_category, index) =>
                        <li className="nav-item" role="presentation" key={index}>
                            <button className={`nav-link ${this.state.plan_category_id == plan_category.id ? 'active' : ''}`} type="button" onClick={() => this.changePlanCategory(plan_category.id)}>{plan_category.name}</button>
                        </li>
                    )}

                    <li className="nav-item" role="presentation">
                        <a 
                            className={`nav-link`} 
                            href='/settings/download_file_tenant_users'
                            target='_blank'
                            
                            type="button"
                        >
                            Descargar exel
                        </a>
                    </li>
                </ul>

                {this.state.plan_category_id && (
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {this.props.plan_categories.filter((e) => e.id == this.state.plan_category_id).map((plan_category, index) =>
                            plan_category.plans.map((plan, index) =>
                                <li className="nav-item" role="presentation" key={index}>
                                    <button className={`nav-link ${this.state.plan_id == plan.id ? 'active' : ''} ui-table_tenant_client_li_plans`} type="button" onClick={() => this.changePlan(plan.id)}>
                                        <span className='ui-table_tenant_client-names-plans-tab'>{plan.name}</span>
                                        <span className='ui-table_tenant_client-count-plans'><i className="far fa-eye"></i> {plan.count_plans} reg.</span>
                                    </button>
                                </li>
                            )
                        )}
                    </ul>
                )}

                {this.state.showFilter && (
                    <FormFilter 
                        handleClickFilter={this.handleClickFilter}
                        handleClickCancel={this.handleClickCancel}
                        formValues={this.state.form}
                        onChangeForm={this.handleChangeFilter}
                        plans={this.props.plans}
                        //states
                        isFiltering={this.state.isFiltering}

                        selectedOptionCountry={this.state.selectedOptionCountry}
                        handleChangeAutocompleteCountry={this.handleChangeAutocompleteCountry}
                        countries={this.props.countries}

                        selectedOptionState={this.state.selectedOptionState}
                        handleChangeAutocompleteState={this.handleChangeAutocompleteState}
                        states={this.state.states}

                        selectedOptionCity={this.state.selectedOptionCity}
                        handleChangeAutocompleteCity={this.handleChangeAutocompleteCity}
                        cities={this.state.cities}
                    />
                )}

                {!this.state.isLoaded ? (
                    <Table
                        data={this.state.data}
                        plans={this.props.plans}
                        base_models={this.props.base_models}
                        updateItem={this.updateItem}
                        loadData={this.loadData}
                        form={this.state.form}
                        plan_categories={this.props.plan_categories}
                        key_text={this.props.key_text}
                        imageUpload={this.props.imageUpload}
                        plan_category_id={this.state.plan_category_id}
                        plan_id={this.state.plan_id}

                        //pagination props
                        totalData={this.state.total}
                        activePage={this.state.activePage}
                        countPage={this.state.countPage}
                        handlePageChange={this.handlePageChange}
                        removeTenant={this.removeTenant}
                        active={this.props.active}
                    />
                ) : (
                    <div className='card'>
                        <div className='card-body'>
                            <Preloader />
                        </div>
                    </div>
                )}
            
            </React.Fragment>
        );
    }
}


export default TenantIndex;



/*

{this.state.tenant_id == tenant.id ?  <i className="fas fa-times float-right" onClick={() => this.clearValues()}></i> : <i onClick={() => this.edit(tenant)} className="fas fa-pencil-alt float-right"></i>}

*/