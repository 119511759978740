import React, { useState, useEffect } from 'react';
import Select from "react-select";

const FormFilter = (props) => {
    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [selectedOption, setSelectedOption] = useState({ label: "Usuarios", user_delete_id: "" });
    const [form, setForm] = useState({ name: "", user_delete_id: "", date1: "", date2: "" });

    const token = document.querySelector("[name='csrf-token']").content;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        fetch(`/api/v1/users/get_filter_users/${props.tenant.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "authentication": "c4d351165843d80a394ab2bb025b6fd2"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setIsLoaded(false);
        });
    }

    const handleChangeAutocomplete = (selectedOption) => {
        setSelectedOption(selectedOption);
        setForm({ ...form, ["user_delete_id"]: selectedOption.value })
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <div className="row mt-3 mb-3">
                <div className="col-md-12">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Nombre</label>
                                        <input 
                                            type="text" 
                                            name="name"
                                            className='form form-control'
                                            onChange={handleChange}
                                            value={form.name}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label>Seleccionar usuario</label>
                                        <input
                                            type="hidden"
                                            name="user_delete_id"
                                            value={selectedOption.user_delete_id}
                                        />

                                        <Select
                                            onChange={handleChangeAutocomplete}
                                            options={data}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={selectedOption}
                                        />
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <label>Fecha desde</label>
                                        <input 
                                            type="date" 
                                            name="date1"
                                            className='form form-control'
                                            onChange={handleChange}
                                            value={form.date1}
                                        />
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <label>Fecha hasta</label>
                                        <input 
                                            type="date" 
                                            name="date2"
                                            className='form form-control'
                                            onChange={handleChange}
                                            value={form.date2}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="card-footer">
                                <button disabled={props.isLoadedFilter} onClick={() => props.handleClickFilter(form)} className='btn-shadow btn btn-info ml-2'>{props.isLoadedFilter ? "Cargando.." : "Aplicar filtro"}</button>
                                <button onClick={() => props.toogleFilter()} className='btn-shadow btn btn-danger ml-2'>Cancelar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default FormFilter;

