import React, { Component } from 'react';
import ModalReadQr from './ModalReadQr'
import { isMobile } from 'react-device-detect';

class QrReader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modaReadQr: false,
            modalQrActive: true,
            modalQrData: [],
            scanned:false
        }
    }

    toogleReadQr = (from) => {
        if (from == "new") {
            this.setState({ modaReadQr: true})
        } else {
            this.setState({ modaReadQr: false, modalQrActive: true })
        }
    }

    onResult = (result) => {

           if (!!result && !this.state.scanned) {
            this.setState({
                scanned: true,
            })
            console.log("onResult", result)
            console.log(`${result}/${this.props.format_id}`)
            fetch( `${result}/${this.props.format_id}`, {
                method: 'get', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if (data.status === "success") {
                        this.setState({
                            modalQrActive: false,
                            modalQrData: data.response,
                            scanned: false,
                        })
                    }

                });

        } 
    }

    actvieQr = () => {
        this.setState({
            modalQrActive: true,
        })
    }


    render() {
        return (
            <React.Fragment>
                <a 
                tabindex="0"
                role="menuitem"
                className={this.props.className}
                onClick={() => this.toogleReadQr("new")}>
                    {isMobile ? (<React.Fragment><span className="material-symbols-outlined mr-2 ui-indexSurvey-icons_materials ui-icon_qr">&#xef6b;</span><span className="ui-indexSurvey-label">Leer QR</span></React.Fragment>) : (<React.Fragment><span className="material-symbols-outlined mr-2 ui-indexSurvey-icons_materials ui-icon_qr">&#xef6b;</span><span className="ui-indexSurvey-label">Leer QR</span></React.Fragment>)}
                </a>
                {this.state.modaReadQr && (
                    <ModalReadQr
                        backdrop={"static"}
                        modal={this.state.modaReadQr}
                        toggle={this.toogleReadQr}
                        onResult={this.onResult}
                        modalQrActive={this.state.modalQrActive}
                        modalQrData={this.state.modalQrData}
                        actvieQr={this.actvieQr}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default QrReader;