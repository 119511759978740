import React, { useState, useEffect } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

const FormCreate = (props) => {
    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <div className="mb-4 card">
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <div className="row">

                            <div className="col-md-4">
                                <label>Columna</label>
                                <select
                                    name="value_column"
                                    className={`form form-control ${!props.errorValues && props.form.value_column == "" ? "error-class" : ""}`}
                                    value={props.form.value_column}
                                    onChange={props.handleChange}
                                >
                                    <option value="">Seleccione un valor</option>

                                    {props.columns.map((columna, index) => (
                                        <option value={columna} key={index}>{columna}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-4">
                                <label>Condicion</label>
                                <select
                                    name="condition"
                                    className={`form form-control ${!props.errorValues && props.form.condition == "" ? "error-class" : ""}`}
                                    value={props.form.condition}
                                    onChange={props.handleChange}
                                >
                                    <option value="">Seleccione un valor</option>
                                    <option value="=">Igual</option>
                                    <option value="!=">Diferente</option>
                                </select>
                            </div>

                            <div className="col-md-4">
                                <label>Valor</label>
                                <input
                                    type="number"
                                    name="value"
                                    className={`form form-control ${!props.errorValues && props.form.value == "" ? "error-class" : ""}`}
                                    value={props.form.value}
                                    onChange={props.handleChange}
                                />
                            </div>

                            {!props.errorValues && (
                                <div className="col-md-12 mt-4">
                                    <div className="alert alert-danger text-center" role="alert">
                                        <b>Debes de completar todos los campos requeridos</b>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>

                    <div className="card-footer">
                        <div className="col-md-4 pl-0">
                            <button
                                type="button"
                                className='btn-shadow btn btn-info'
                                onClick={() => props.handleClick()}
                            >
                                {props.question_matrix_item_codition_id ? "Actualizar" : "Crear"}
                            </button>

                            {(props.form.value_column || props.form.condition || props.form.value) && (
                                <a
                                    className='btn-shadow btn btn-danger ml-3'
                                    style={{ color: "#ffff" }}
                                    onClick={() => props.clearValues()}
                                >
                                    Cancelar
                                </a>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}


export default FormCreate;

