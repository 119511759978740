import React, { Component } from 'react';

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-3">

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    Configuración de la imagen del campo
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 mb-4 text-center">
                                            <img 
                                                src={this.props.question.question_type == "answer_file" ? "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/39629/Ejemplos_imagenes_fordata.png" : "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/38228/Firma_ejemplo.png"} 
                                                alt="imagen" 
                                                style={{ width: (this.props.formValues.pdf_widh == "auto" ?  this.props.formValues.pdf_widh : `${this.props.formValues.pdf_widh}px`), height: (this.props.formValues.pdf_height == "auto" ? this.props.formValues.pdf_height : `${this.props.formValues.pdf_height}px`) }} 
                                            />
                                        </div>

                                        
                                        <div className="col-md-6 mb-3">
                                            {this.props.formValues.pdf_widh != "auto" && (
                                                <React.Fragment>
                                                    <p>Ancho <b>{this.props.formValues.pdf_widh}px</b></p>
                                                    <input 
                                                        style={{ width: "100%" }} 
                                                        type="range" 
                                                        name="pdf_widh" 
                                                        min="0" 
                                                        max="500"
                                                        value={this.props.formValues.pdf_widh}
                                                        onChange={this.props.onChangeForm}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>
                                        
                                        
                                        <div className="col-md-6 mb-3">
                                            {this.props.formValues.pdf_height != "auto" && (
                                                <React.Fragment>
                                                    <p>Altura <b>{this.props.formValues.pdf_height}px</b></p>
                                                    <input 
                                                        style={{ width: "100%" }} 
                                                        type="range" 
                                                        name="pdf_height" 
                                                        min="0" 
                                                        max="250"
                                                        value={this.props.formValues.pdf_height}
                                                        onChange={this.props.onChangeForm}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>
                                        

                                        <div className="col-md-6 mt-2">
                                            <label className="mr-2" htmlFor="checkbox1">Añadir ancho automatico</label>
                                            <input 
                                                type="checkbox" 
                                                id="checkbox1"
                                                name="pdf_widh"
                                                onChange={(e) => this.props.handleChangeCheckBox(e, (this.props.formValues.pdf_widh == "auto" ? 50 : "auto"))}
                                            />
                                        </div>

                                        <div className="col-md-6 mt-2">
                                            <label className="mr-2" htmlFor="checkbox2">Añadir altura automatico</label>
                                            <input 
                                                type="checkbox" 
                                                id="checkbox2"
                                                name="pdf_height"
                                                onChange={(e) => this.props.handleChangeCheckBox(e, (this.props.formValues.pdf_height == "auto" ? 100 : "auto"))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3 mb-3">
                            <div className="card">
                                <div className="card-header">
                                    Configuración de la imagen del sub formulario
                                </div>
                          
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 mb-4 text-center">
                                            <img 
                                                src={this.props.question.question_type == "answer_file" ? "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/39632/Ejemplos_tablas_fordata.png" : "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/38228/Firma_ejemplo.png"} 
                                                alt="imagen" 
                                                style={{ width: (this.props.formValues.pdf_subform_widh == "auto" ? this.props.formValues.pdf_subform_widh : `${this.props.formValues.pdf_subform_widh}px`), height: (this.props.formValues.pdf_subform_height == "auto" ? this.props.formValues.pdf_subform_height : `${this.props.formValues.pdf_subform_height}px`)  }} 
                                            />
                                        </div>  
                                        
                                        
                                        <div className="col-md-6 mb-3">
                                            {this.props.formValues.pdf_subform_widh != "auto" && (
                                                <React.Fragment>
                                                    <p>Ancho <b>{this.props.formValues.pdf_subform_widh}px</b></p>
                                                    <input 
                                                        style={{ width: "100%" }} 
                                                        type="range" 
                                                        name="pdf_subform_widh" 
                                                        min="0" 
                                                        max="500"
                                                        value={this.props.formValues.pdf_subform_widh}
                                                        onChange={this.props.onChangeForm}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>
                                        
                                        <div className="col-md-6 mb-3">
                                            {this.props.formValues.pdf_subform_height != "auto" && (
                                                <React.Fragment>
                                                    <p>Altura <b>{this.props.formValues.pdf_subform_height}px</b></p>
                                                    <input 
                                                        style={{ width: "100%" }} 
                                                        type="range" 
                                                        name="pdf_subform_height" 
                                                        min="0" 
                                                        max="250"
                                                        value={this.props.formValues.pdf_subform_height}
                                                        onChange={this.props.onChangeForm}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>
                                        

                                        <div className="col-md-6 mt-2">
                                            <label className="mr-2" htmlFor="checkbox3">Añadir ancho automatico</label>
                                            <input 
                                                type="checkbox" 
                                                id="checkbox3"
                                                name="pdf_subform_widh"
                                                onChange={(e) => this.props.handleChangeCheckBox(e, (this.props.formValues.pdf_subform_widh == "auto" ? 50 : "auto"))}
                                            />
                                        </div>

                                        <div className="col-md-6 mt-2">
                                            <label className="mr-2" htmlFor="checkbox4">Añadir altura automatico</label>
                                            <input 
                                                type="checkbox" 
                                                id="checkbox4"
                                                name="pdf_subform_height"
                                                onChange={(e) => this.props.handleChangeCheckBox(e, (this.props.formValues.pdf_subform_height == "auto" ? 100 : "auto"))}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="card-footer">
                                    <button
                                        className="btn-shadow btn btn-info"
                                        onClick={() => this.props.submitForm()}
                                    >
                                        {this.props.nameBnt}
                                    </button>
                                
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;