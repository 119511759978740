import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import LineChartIndicator from "../../../GeneralComponents/Charts/LineChartIndicator"
import LineChartIndicatorMonth from "../../../GeneralComponents/Charts/LineChartIndicatorMonth"
import LayoutVertical from './layouts/layoutVertical'
import LayoutHorizontal from './layouts/layoutHorizontal'


class ShowHorizontal extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            formValues: {
                start_month: new Date().getMonth()
            },

            formUpdate: {
                real: 0,
                target: 0,
            },

            idIndex: 260,
            id: "",
            postion: 0,
            dataLine: [],
            dataLineMonth: [],
            typeLayout: "false",
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleChange = (e) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [e.target.name]: parseInt(e.target.value)

            },
        });
        this.props.loadData();
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                [e.target.name]: e.target.value
            }
        });
    }

    updateFiels = (dia, index, idRegister) => {
        this.setState({
            formUpdate: {
                real: dia.real,
                target: dia.target,
            },

            idIndex: index,
            id: idRegister,
            postion: index,
        })
    }

    componentWillReceiveProps(nextProps) {

        if (this.props !== nextProps) {
            this.dataLine(nextProps.data)
            this.dataLineMonth(nextProps.data)
            console.log(nextProps.data)
            console.log("extProps.data")

            this.setState({
                typeLayout: "true",
            })
        }
    }


    HandleClickUpdate = () => {
        fetch(`/indicator/update_json/${this.state.id}/0/${this.state.formValues.start_month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.setState({
                    idIndex: 260,
                })
                /*               this.messageSuccess(data); */
            });
    }

    dataLine = (nextProps) => {

        let target = nextProps.target
        let array = [['x', 'datos', { role: "annotation", type: "string" }, '%'], [0, 0, "", target]]


        let value = this.state.formValues.start_month


        nextProps.data[value].data.data.map((data, index) => {

            let data_percent = data.percent + "%"
            let data_percent_num = data.percent
            if (data.percent == 0) {
                data_percent = ""
            }
            if (!data.state) {
                data_percent_num = null
            }

            array.push([index + 1, data_percent_num, data_percent, target])



        })


        this.setState((state, props) => ({
            dataLine: array
        }));
    }

    dataLineMonth = (nextProps) => {

        let target = nextProps.target
        let array = [['x', 'datos', { role: "annotation", type: "string" }, '%']]


        let value = this.state.formValues.start_month


        nextProps.data.map((data, index) => {

            let data_percent = data.total_percent + "%"
            let data_percent_num = data.total_percent
            if (data.target == 0) {
                data_percent = ""
                data_percent_num = null
            }
            /*                     if ( !data.state )
                                {
                                    data_percent_num = null
                                } */

            array.push([data.month_text, data_percent_num, data_percent, target])



        })


        this.setState((state, props) => ({
            dataLineMonth: array
        }));
    }

    whishIs = (value) => {
        console.log("aquiii")
        console.log(value)
        if ("Vertical" == "Vertical") {
            console.log("dsfasfdsfas")
            return (<LayoutVertical data={this.props.dataNew} loadData={() => this.props.loadData()} month={this.state.formValues.start_month} />);

        }
        else {
            return <LayoutHorizontal data={this.props.dataNew} loadData={() => this.props.loadData()} month={this.state.formValues.start_month} />;
        }

    }
    render() {
        const value = this.state.formValues.start_month;

        return (
            <React.Fragment>

                <nav className="" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={`/indicator/category_indicators/${this.props.category_indicator.token}/indicators?type=SACLI`}
                                data-turbolinks="true"
                            >
                                Indicadores
                            </a>
                        </li>
                        <li className="breadcrumb-item">
                            <a 
                                // href="javascript:void(0);"
                             >
                                {this.props.data.name}
                            </a>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    {this.props.dataNew != undefined ? (
                        <div className="col-md-12">
                            <LayoutHorizontal data={this.props.dataNew} loadData={() => this.props.loadData()} month={this.state.formValues.start_month} />

                        </div>
                    ) : (
                            <div></div>
                        )}

                </div>
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="card col-md-6">

                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 table-indicator">
                        <div className="card">
                            <div className="card-body">
                                <table style={{ tableLayout: "fixed" }} className="table-values table table-hover table-striped table-bordered">
                                    <thead className="background-theader">
                                        <tr>
                                            <th colSpan="4" className="text-center">
                                                Datos

                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">Día</th>
                                            <th className="text-center">V1</th>
                                            <th className="text-center">V2</th>
                                            <th className="text-center">%</th>
                                        </tr>


                                        {/*  (variable 1 / variable 2) * 100   */}

                                    </thead>

                                    <tbody>
                                        {this.props.data.data != undefined ? (

                                            this.props.data.data[value].data.data.map((dias, index) => (
                                                <React.Fragment>
                                                    <tr key={dias.id}>
                                                        <th className="text-center pt-0 pb-0 item-table">{index + 1}</th>

                                                        <th className="text-center pt-0 pb-0 item-table">
                                                            {index == this.state.idIndex ? (
                                                                <input
                                                                    type="text"
                                                                    name="real"
                                                                    value={this.state.formUpdate.real}
                                                                    onChange={this.HandleChangeUpdate}
                                                                    className="form form-control"
                                                                />
                                                            ) : (
                                                                    <p onClick={() => this.updateFiels(dias, index, this.props.data.data[value].id)}>{dias.real}</p>
                                                                )}
                                                        </th>

                                                        <th className="text-center pt-0 pb-0 item-table">
                                                            {index == this.state.idIndex ? (
                                                                <input
                                                                    type="text"
                                                                    name="target"
                                                                    value={this.state.formUpdate.target}
                                                                    onChange={this.HandleChangeUpdate}
                                                                    className="form form-control"
                                                                />
                                                            ) : (
                                                                    <p onClick={() => this.updateFiels(dias, index, this.props.data.data[value].id)}> {dias.target}</p>
                                                                )}
                                                        </th>

                                                        <th className="text-center pt-0 pb-0 item-table"><p>{dias.percent}%</p>
                                                            <div className="text-center">
                                                                {index == this.state.idIndex && (
                                                                    <React.Fragment>
                                                                        <i
                                                                            className="fas fa-check-circle true-btn ml-1"
                                                                            onClick={() => this.HandleClickUpdate()}
                                                                            style={{ marginRight: "0px" }}
                                                                        >
                                                                        </i>
                                                                        <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ idIndex: 260 })}></i>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </React.Fragment>
                                            ))



                                        ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros con el criterio de búsqueda</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}

                                        {this.props.data.data != undefined && (
                                            <tr>
                                                <th></th>
                                                <th className="text-center">{this.props.data.data[value].real}</th>
                                                <th className="text-center">{this.props.data.data[value].target}</th>
                                                <th className="text-center">{this.props.data.data[value].total_percent}%</th>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="card" >
                            <div className="card-body">
                                <table style={{ tableLayout: "fixed" }} className="table-values table table-hover table-striped table-bordered">
                                    <thead className="background-theader">
                                        <tr>
                                            <th colSpan="4" className="text-center">
                                                Datos

                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">Día</th>
                                            <th className="text-center">V1</th>
                                            <th className="text-center">V2</th>
                                            <th className="text-center">%</th>
                                        </tr>


                                        {/*  (variable 1 / variable 2) * 100   */}

                                    </thead>

                                    <tbody>
                                        {this.props.data.data != undefined ? (
                                            this.props.data.data.map((dias, index) => (
                                                <React.Fragment>
                                                    <tr key={dias.id}>
                                                        <th className="text-center pt-0 pb-0 item-table">{dias.month_text}</th>

                                                        <th className="text-center pt-0 pb-0 item-table">
                                                            {index == this.state.idIndex ? (
                                                                <input
                                                                    type="text"
                                                                    name="real"
                                                                    value={this.state.formUpdate.real}
                                                                    onChange={this.HandleChangeUpdate}
                                                                    className="form form-control"
                                                                />
                                                            ) : (
                                                                    <p onClick={() => this.updateFiels(dias, index, this.props.data.data[value].id)}>{dias.real}</p>
                                                                )}
                                                        </th>

                                                        <th className="text-center pt-0 pb-0 item-table">
                                                            {index == this.state.idIndex ? (
                                                                <input
                                                                    type="text"
                                                                    name="target"
                                                                    value={this.state.formUpdate.target}
                                                                    onChange={this.HandleChangeUpdate}
                                                                    className="form form-control"
                                                                />
                                                            ) : (
                                                                    <p onClick={() => this.updateFiels(dias, index, this.props.data.data[value].id)}> {dias.target}</p>
                                                                )}
                                                        </th>

                                                        <th className="text-center pt-0 pb-0 item-table"><p>{dias.total_percent}%</p>
                                                            <div className="text-center">
                                                                {index == this.state.idIndex && (
                                                                    <React.Fragment>
                                                                        <i
                                                                            className="fas fa-check-circle true-btn ml-1"
                                                                            onClick={() => this.HandleClickUpdate()}
                                                                            style={{ marginRight: "0px" }}
                                                                        >
                                                                        </i>
                                                                        <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ idIndex: 260 })}></i>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </th>

                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros con el criterio de búsqueda</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>



                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5>Periodo</h5>
                                            </div>

                                            <div className="col-md-6 text-right">
                                                <select
                                                    name="start_month"
                                                    value={this.state.formValues.start_month}
                                                    onChange={this.HandleChange}
                                                    className="form form-control"
                                                >
                                                    <option value="">Seleccione un mes</option>
                                                    <option value="0">Enero</option>
                                                    <option value="1">Febrero</option>
                                                    <option value="2">Marzo</option>
                                                    <option value="3">Abril</option>
                                                    <option value="4">Mayo</option>
                                                    <option value="5">Junio</option>
                                                    <option value="6">Julio</option>
                                                    <option value="7">Agosto</option>
                                                    <option value="8">Septiembre</option>
                                                    <option value="9">Octubre</option>
                                                    <option value="10">Noviembre</option>
                                                    <option value="11">Diciembre</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12">
                                                <hr />
                                            </div>
                                            <div className="col-md-12 item-target">
                                                <div className="row">
                                                    <div className="col-md-6"> <h5>Target</h5></div>

                                                    <div className="col-md-6">{this.props.data.target}%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-body" style={{ paddingTop: "32px" }}>
                                        <table className="table table-hover table-striped table-bordered">
                                            <thead className="background-theader">
                                                <th colSpan="2" className="text-center">Formula</th>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <th className="text-center">
                                                        <div>{this.props.data.variable_1} / {this.props.data.variable_2}</div>

                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <LineChartIndicator data={this.state.dataLine} />
                                    </div>
                                </div>
                                <br />
                                <div className="card">
                                    <div className="card-body">
                                        <LineChartIndicatorMonth data={this.state.dataLineMonth} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <label>Análisis</label>
                                        <textarea
                                            rows="5"
                                            className="form form-control"
                                        >

                                        </textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-5">
                                <div className="card">
                                    <div className="card-body">
                                        <table className="table table-hover table-striped table-bordered">
                                            <thead className="background-theader">
                                                <th className="text-center">Actividades</th>
                                                <th className="text-center">Fecha</th>
                                                <th className="text-center">Responsable</th>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <th className="text-center">Crear módulos</th>
                                                    <th className="text-center">12/23/2020</th>
                                                    <th className="text-center">Estiven Salazar</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default ShowHorizontal;
