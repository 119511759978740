import React, { useState, useEffect } from 'react';
import WebpackerReact from 'webpacker-react';
import { UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledCollapse } from 'reactstrap';

const BeetreeShowPage = (props) => {
    const token = document.querySelector("[name='csrf-token']").content;
    const [register, setRegister] = useState({});

    const typeOfUrl = (link) => {
        const youtube = 'www.youtube.com/embed/';

        if(link.url.includes(youtube)){
            return(
                <React.Fragment>
                    <a className="btn btn-outline-light shake ui-btn-link-btree" id={`collapseExample${link.id}`}>
                        <i className={`mr-3 ${link.icon ? link.icon.html_name : 'fas fa-film'}`}></i>
                        {link.title_link == "" ? "YOUTUBE" : link.title_link}
                    </a>
                    <UncontrolledCollapse toggler={`#collapseExample${link.id}`} style={{textAlign: 'center'}}>
                        <iframe 
                            width="880" 
                            height="315" 
                            src={link.url}
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen
                            className='ui-beetreeShowPage-iframe'
                        ></iframe>
                    </UncontrolledCollapse>
                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    <a
                        href={link.disabled ? "" : link.url} 
                        target='_blank'
                        type="button" 
                        className={`btn btn-outline-light shake ui-btn-link-btree ${link.disabled ? "ui-disabled-link" : null}`}
                    >   
                        <i className={`mr-3 ${link.icon ? link.icon.html_name : 'fab fa-reddit'}`}></i>
                        {link.disabled ? "Formato no disponible" : link.title_link}
                    </a>
                </React.Fragment>
            )
        }
    }

    return (
        <React.Fragment>
            {props.page.active ? (
                <div style={{display: 'flex', flexDirection: 'column', width:'100%'}}>
                    <div className="container">
                        <div className="col-xs-12">
                            <div className="text-center header-info" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                <div className="ui-show-page_linktree__container-img">
                                    <span className="linktree"></span>
                                </div>
                                <h2 style={{ paddingTop: "20px" }}>{props.page.enterprise.name}</h2>
                                <p>{props.page.enterprise.aditional_info}</p>
                            </div>
                        </div>
                    </div>

                    <div className="container ui-btn-link-btree__container_alls">
                        <div className="col-xs-12">
                            <div className="ui-pruebasd p-2">
                                {props.page.links.map(link => (
                                    <div style={{ paddingBottom: "30px" }} className='ui-pruebas-de'>
                                        {typeOfUrl(link)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-5">
                    <div className="col-md-6 offset-md-3">
                        <div className="card">
                            <div className="card-body text-center">
                                <p style={{ color: "#000", fontSize: "40px"}}>Pagina desactivada</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}


export default BeetreeShowPage;
WebpackerReact.setup({ BeetreeShowPage });