import React, { useState, useEffect, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import Preloader from '../../../GeneralComponents/LoadingComponen';
import FormCreateFlowChart from '../FlowChart/FormCreate'
import FormCreateFlowChartItem from '../FlowChart/FlowChartItems/FormCreate';
import FormReadFlowItem from './FlowChartItems/FormReadFlowItem';
import Swal from "sweetalert2/dist/sweetalert2.js";

const Card = (props) => {
    return (
        <React.Fragment>
            {props.data.length >= 1 && (
                <ul className='ui-flow-charts__container-fragments'>
                    {props.data.map((item) => (
                        <React.Fragment key={item.id}>
                            <li ref={props.references}>
                                <div className="ui-flow-charts__container-card">
                                    <div className="ui-flow-charts__container-card-body">
                                        {item.description.length > 0 ? (
                                            <p
                                                className="name" onClick={() => props.toogleReadFlowChartItem('new', item)}
                                                style={{ cursor: 'pointer', userSelect: 'none' }}
                                            >
                                                {item.title}
                                            </p>
                                        ) : (
                                            <p className="name">{item.title}</p>
                                        )}
                                        <div className="position">
                                            {item.description.length > 0 ? (
                                                <span
                                                    onClick={() => props.toogleReadFlowChartItem('new', item)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {item.description}
                                                </span>
                                            ) : (
                                                <span> {item.description}</span>
                                            )}
                                            <hr />
                                            <div className="ui-flow-charts__container-icons">
                                                <div className="ui-flow-charts__icon">
                                                    <i className="fas fa-plus-circle" onClick={() => props.setItem(item)}></i>
                                                </div>

                                                <div className="ui-flow-charts__icon">
                                                    <i className="fas fa-pen" onClick={() => props.editItem(item)}></i>
                                                </div>

                                                <div className="ui-flow-charts__icon">
                                                    <i className="fas fa-trash-alt" onClick={() => props.deleteItem(item.id)}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                                {item.children.length >= 0 ? <Card data={item.children} setItem={props.setItem} editItem={props.editItem} deleteItem={props.deleteItem} toogleReadFlowChartItem={props.toogleReadFlowChartItem} /> : null}
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
            )}
        </React.Fragment>
    );
}

const Chart = (props) => {
    const [data, setData] = useState([]);
    const [modalFlowChart, setModalFlowChart] = useState(false);
    const [flowChartItemId, setFlowChartItemId] = useState("");
    const [itemSelect, setItemSelect] = useState({});
    const [modalFlowChartItem, setModalFlowChartItem] = useState(false);
    const [modalReadFlowChartItem, setModalReadFlowChartItem] = useState(false);
    const [flowChart, setFlowChart] = useState({ description: props.flow_chart.description });
    const [formFlowChart, setFormFlowChart] = useState({ description: props.flow_chart.description });
    const [formFlowChartItem, setFormFlowChartItem] = useState({ title: "", description: "", flow_chart_id: props.flow_chart.id, flow_chart_item_id: "" });
    const [isLoaded, setIsLoaded] = useState(true);
    const token = document.querySelector("[name='csrf-token']").content;
    const miElementoWidth = useRef(null);
    const miItemWidth = useRef(null);

    const [marginLeftTree, setMarginLeftTree] = useState(0);
    const [widthTree, setWidthTree] = useState(null);
    const [containerTree, setContainerTree] = useState(null);

    useEffect(() => {
        loadData();
        if (miElementoWidth.current) {
            const tree = miElementoWidth.current?.offsetWidth;
            setWidthTree(tree);

            const container = miItemWidth.current?.offsetWidth;
            setContainerTree(container);
        }
    }, [miElementoWidth.current, miItemWidth.current]);

    useEffect(() => {
        console.log("Tamaño del contenedor", widthTree);
        console.log("Tamaño del arbol contenedot", containerTree);
        if (containerTree > 3000 && containerTree <= 4999) {
            setMarginLeftTree(parseInt(containerTree / 80));
            console.log("MArginLeft mayor a 3000 es:", marginLeftTree)
        } else if (containerTree > widthTree && containerTree <= 3000) {
            setMarginLeftTree(parseInt((containerTree / 100)));
            console.log("MArginLeft mayor a el contedor y menor a 3000:", marginLeftTree)
        } else if (containerTree <= widthTree) {
            setMarginLeftTree((0));
            console.log("MArginLeft menor al contenedor:", marginLeftTree)
        }
    }, [widthTree, marginLeftTree]);

    const updateItem = (page) => {
        setData(data.map(item => {
            if (item.id === page.id) {
                return {
                    ...item,
                    active: page.active,
                    background_color: page.background_color,
                    background_complement_color: page.background_complement_color,
                    background_complement_img: page.background_complement_img,
                    page_name: page.page_name,
                    private: page.private,
                    url: page.url,
                }
            }
            return item;
        }));
    }

    const updateData = (new_item) => {
        setData([...data, new_item])
    }



    const loadData = () => {
        fetch(`/formatos/get_flow_chart_items/${props.flow_chart.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                setIsLoaded(false);
                setData(data.data);
            });
    }

    const handleChangeFlowChart = (e) => {
        setFormFlowChart({ ...formFlowChart, [e.target.name]: e.target.value })
    }

    const handleChangeFlowChartItem = (e) => {
        setFormFlowChartItem({ ...formFlowChartItem, [e.target.name]: e.target.value })
    }

    const toogleFlowChart = (from) => {
        if (from == "new") {
            setModalFlowChart(true);
        } else {
            setModalFlowChart(false);
            clearValues();
        }
    }

    const toogleFlowChartItem = (from) => {
        if (from == "new") {
            setModalFlowChartItem(true);
        } else {
            setModalFlowChartItem(false);
            clearValues();
        }
    }

    const toogleReadFlowChartItem = (from, item) => {
        if (from == "new") {
            setModalReadFlowChartItem(true);
            setItemSelect(item);
        } else {
            setModalReadFlowChartItem(false);
            setItemSelect({});
        }
    }

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const handleClickFlowChart = () => {
        fetch(`/formatos/flow_charts/${props.flow_chart.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(formFlowChart), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                messageSuccess(data);
                setModalFlowChart(false);
                setFlowChart({ description: data.register.description })
            });
    }

    const validationForm = () => {
        return false
    }

    const handleClickFlowChartItem = () => {
        if (true) {
            if (flowChartItemId) {
                fetch(`/formatos/flow_chart_items/${flowChartItemId}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(formFlowChartItem), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        setModalFlowChartItem(false);
                        setFlowChartItemId("");
                        loadData();
                    });
            } else {
                fetch(`/formatos/flow_chart_items`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(formFlowChartItem), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        setModalFlowChartItem(false);
                        loadData();
                    });
            }
        }
    }

    const setItem = (flow_chart_item) => {
        setModalFlowChartItem(true);
        setFlowChartItemId("");
        setFormFlowChartItem({ ...formFlowChartItem, flow_chart_item_id: flow_chart_item.id, title: "", description: "" })
    }

    const editItem = (flow_chart_item) => {
        setModalFlowChartItem(true);
        setFormFlowChartItem({ ...formFlowChartItem, title: flow_chart_item.title, description: flow_chart_item.description })
        setFlowChartItemId(flow_chart_item.id);
    }

    const deleteItem = (flow_chart_item_id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/flow_chart_items/${flow_chart_item_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        loadData();
                        messageSuccess(response);
                    });
            }
        });
    }

    if (isLoaded) {
        return (
            <div className="card">
                <div className="card-body">
                    <Preloader />
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>

            {modalFlowChart && (
                <FormCreateFlowChart
                    backdrop={"static"}
                    modal={modalFlowChart}
                    toggle={toogleFlowChart}
                    title={true ? "Actualizar arbol de fallo" : "Crear arbol de fallo"}
                    nameBnt={true ? "Actualizar" : "Crear"}

                    //form props
                    formValues={formFlowChart}
                    onChangeForm={handleChangeFlowChart}
                    submitForm={handleClickFlowChart}
                />
            )}

            {modalFlowChartItem && (
                <FormCreateFlowChartItem
                    backdrop={"static"}
                    modal={modalFlowChartItem}
                    toggle={toogleFlowChartItem}
                    title={flowChartItemId ? "Actualizar Item" : "Crear Item"}
                    nameBnt={flowChartItemId ? "Actualizar" : "Crear"}

                    //form props
                    formValues={formFlowChartItem}
                    onChangeForm={handleChangeFlowChartItem}
                    submitForm={handleClickFlowChartItem}
                    errorValues={true}
                />
            )}

            {modalReadFlowChartItem && (
                <FormReadFlowItem
                    backdrop={"static"}
                    modal={modalReadFlowChartItem}
                    read={itemSelect}
                    toggle={toogleReadFlowChartItem}
                />
            )}


            <div className="row">
                <div className="col-md-10 text-center">
                    {/*  <h3 style={{fontWeight: 'bolder',}}>{flowChart.description}</h3> */}
                </div>

                <div className="col-md-2 text-right">
                    <UncontrolledTooltip target="ui-surveyShowTask-flowCharts_icon-edit" placement='left'>{'EDITAR TÍTULO ÁRBOL DE FALLOS'}</UncontrolledTooltip>
                    {data.length == 0 && (
                        <button
                            className='btn-shadow btn btn-info mr-2 ml-2'
                            onClick={() => toogleFlowChartItem("new")}
                        >
                            Crear Item
                        </button>
                    )}
                    <button
                        className='btn-shadow btn btn-outline-info mr-2 ml-2'
                        onClick={() => toogleFlowChart("new")}
                        id='ui-surveyShowTask-flowCharts_icon-edit'
                    >
                        <i className="fas fa-pen"></i>
                    </button>

                    <UncontrolledTooltip target="ui-surveyShowTask-flowCharts_icon-delete" placement='left'>{'ELIMINAR ÁRBOL DE FALLOS'}</UncontrolledTooltip>
                    <button
                        className='btn-shadow btn btn-outline-danger mr-2 ml-2'
                        onClick={() => props.deleteFlowChart()}
                        id='ui-surveyShowTask-flowCharts_icon-delete'
                    >
                        <i className="fas fa-trash-alt"></i>
                    </button>
                </div>
            </div>


            <div className="org-tree" id='bw_org_tree' style={{ display: 'flex', overflow: 'auto', scrollSnapType: 'x mandatory', justifyContent: 'center' }}>
                <div className='ui-surveyShowTask-flowCharts_data' ref={miElementoWidth} style={{ marginLeft: marginLeftTree + `rem`, minWidth: '100%', scrollSnapAlign: 'center' }} >
                    <Card
                        data={data}
                        setItem={setItem}
                        editItem={editItem}
                        deleteItem={deleteItem}
                        references={miItemWidth}
                        toogleReadFlowChartItem={toogleReadFlowChartItem}
                    />
                </div>
            </div>
        </React.Fragment>
    );


};

export default Chart;
