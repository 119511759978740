import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle';
import FormCreate from './FormCreate';
import ShowChildren from './ShowChildren';
import ShowModalVideo from './ShowModalVideo'
import Preloader from '../../../GeneralComponents/LoadingComponen';
import Ckeditor from '../../formatos/Formats/Ckeditor'
import ReactHtmlParser from 'react-html-parser';

class Table extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modalShowInfo: false,
            modalShowVideo: false,
            ErrorValues: true,

            modeEditParent: false,
            modeEditChildren: false,

            addingChildrens: false,
            titleForm: "",
            id: "",
            numeral_child_id: "",

            file: null,
            submitBtnFile: false,
            parentId: "",

            form: {
                name: "",
                patient_safety_standar: "",
                measurable_element: "",
                implementation_guide: "",
                service_area: "",
                iso_clause: "",
                video_url: "",
                normativity_id: this.props.normativity.id,
                proceso_ids: []
            },

            formChildren: {
                name: "",
                patient_safety_standar: "",
                measurable_element: "",
                implementation_guide: "",
                service_area: "",
                iso_clause: "",
                numeral_parent_id: "",
            },

            formUpdate: {
                measurable_element: "",
            },

            selectedOptionProcesos: {
                proceso_ids: "",
                label: "Procesos"
            },

            selectedOptionAreas: {
                service_area: "",
                label: "Área/servicio"
            },

            dataProcesos: [],
            dataProcesosEdit: [],
            dataAreas: [],
            subnumeralRegister: {},
            videUrl: "",

        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValuesForm = () => {
        this.setState({
            form: {
                name: "",
                patient_safety_standar: "",
                measurable_element: "",
                implementation_guide: "",
                service_area: "",
                iso_clause: "",
                video_url: "",
                normativity_id: this.props.normativity.id,
                proceso_ids: []
            },

            selectedOptionAreas: {
                service_area: "",
                label: "Área/servicio"
            },

            id: "",
            modeEditParent: false,
            addingChildrens: false,
            dataProcesosEdit: [],
        })
    }

    clearValuesFormChildren = () => {
        this.setState({
            formChildren: {
                name: "",
                patient_safety_standar: "",
                measurable_element: "",
                implementation_guide: "",
                service_area: "",
                iso_clause: "",
                numeral_parent_id: "",
            },

            id: "",
            addingChildrens: false,
            modeEditChildren: false,
        })
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true, titleForm: "Agregar Numeral", })
            this.clearValuesForm();
            this.clearValuesFormChildren()
        } else {
            this.setState({ modal: false })
            this.clearValuesForm();
            this.clearValuesFormChildren()
        }
    }

    HandleClick = () => {
        if (this.state.modeEditParent == false)
            fetch(`/health/normativity/${this.props.normativity.id}/numeral_parents`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    this.clearValuesForm();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        else {
            fetch(`/health/normativity/${this.props.normativity.id}/numeral_parents/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.updateItem(data.register);
                    this.clearValuesForm();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        }
    }

    editParent = (parent) => {
        let arrayPositonEdit = []

        parent.procesos.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id })
        ))

        console.log(arrayPositonEdit)

        this.setState({
            form: {
                ...this.state.form,
                name: parent.name,
                patient_safety_standar: parent.patient_safety_standar,
                measurable_element: parent.measurable_element,
                implementation_guide: parent.implementation_guide,
                iso_clause: parent.iso_clause,
                video_url: parent.video_url,
                normativity_id: this.props.normativity.id,
                proceso_ids: parent.procesos,
                service_area: parent.service_area
            },

            selectedOptionAreas: {
                service_area: parent.service_area,
                label: `${parent.service_area}`
            },

            id: parent.id,
            titleForm: "Editar Numeral",
            modeEditParent: true,
            modeEditChildren: false,
            addingChildrens: false,
            modal: true,
            dataProcesosEdit: arrayPositonEdit
        })
    }



    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/health/normativity/${this.props.normativity.id}/numeral_parents/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };


    //Add childrens 

    addChildren = (father) => {
        this.setState({
            titleForm: "Agregar Subnumeral",
            modal: true,
            addingChildrens: true,
            modeEditChildren: false,
            id: "",

            formChildren: {
                ...this.state.formChildren,
                name: "",
                patient_safety_standar: "",
                measurable_element: "",
                implementation_guide: "",
                service_area: "",
                iso_clause: "",
                numeral_parent_id: father.id
            }
        })
    }

    HandleChangeChildrens = (e) => {
        this.setState({
            formChildren: {
                ...this.state.formChildren,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleClickChildrens = () => {
        if (this.state.modeEditChildren == false)
            fetch(`/health/numeral_child`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formChildren), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    this.clearValuesFormChildren();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        else {
            fetch(`/health/numeral_child/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formChildren), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    this.clearValuesFormChildren();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        }
    }

    editChildren = (children) => {
        this.setState({
            formChildren: {
                ...this.state.formChildren,
                name: children.name,
                patient_safety_standar: children.patient_safety_standar,
                measurable_element: children.measurable_element,
                implementation_guide: children.implementation_guide,
                service_area: children.service_area,
                iso_clause: children.iso_clause,
                numeral_parent_id: children.numeral_parent_id
            },

            id: children.id,
            titleForm: "Editar Subnumeral",
            modeEditChildren: true,
            modal: true,
            addingChildrens: true,
        })
    }

    deleteChildrents = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/health/numeral_child/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    //muchos a muchos


    componentDidMount() {
        let arrayProcesos = []

        this.props.procesos.map((item) => (
            arrayProcesos.push({ label: item.name, value: item.id })
        ))

        this.setState({
            dataProcesos: arrayProcesos
        })


        let arrayAreas = []

        this.props.areas.map((item) => (
            arrayAreas.push({ label: item.name, value: item.id })
        ))

        this.setState({
            dataAreas: arrayAreas
        })
    }


    handleChangeAutocomplete = selectedOption => {
        let array = []

        selectedOption.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            form: {
                ...this.state.form,
                proceso_ids: array
            }
        })
    };

    handleChangeAutocompleteAreas = selectedOptionAreas => {
        this.setState({
            selectedOptionAreas,
            form: {
                ...this.state.form,
                service_area: selectedOptionAreas.label
            }
        });
    };

    // subir padres

    uploadParents = (e) => {
        this.setState({
            file: e.target.files[0],
            submitBtnFile: (this.state.submitBtnFile == false ? true : false)
        });
    }

    uploadChildrents = (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0])
        fetch(`/health/import_numeral_children/${this.state.parentId}`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.setState({ parentId: "" })
                this.messageSuccess(data);
            });

        console.log("uploadChildrentsuploadChildrentsuploadChildrentsuploadChildrents")
    }


    handleClickUpload = () => {
        const formData = new FormData();
        formData.append("file", this.state.file)
        fetch(`/health/import_numeral_parent/${this.props.normativity.id}`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.setState({ file: null, submitBtnFile: false })
                this.messageSuccess(data);
            });
    }


    getColor = (calification) => {
        if (calification == 11)
        {
            return "sin-color"
        }
        else if (calification < 6) {
            return "color-red"
        } else if (calification >= 6 && calification < 8) {
            return "color-orange"
        } else if (calification >= 8) {
            return "color-green"
        }
    }

    getScore = (value) => {
        if (value.length >= 0 && value[0] != undefined) {
            return <div> <span className={this.getColor(value[0].score)}> {value[0].score == 11 ? "N/A" : value[0].score}</span> <span>{value[0].creat_at}</span></div>;
        }
        else {
            return <div><span style={{ padding: "0px" }}></span><span className="no-data-small">Sin calificación</span></div>;
        }
    }

    //modal show subnumral

    toogleShow = (show, register) => {
        if (show == "show") {
            this.setState({ modalShowInfo: true, subnumeralRegister: register })
        } else {
            this.setState({ modalShowInfo: false, subnumeralRegister: {} })
        }
    }

    getBack = () => {
        let url = "/health/normativity?type=" + this.props.normativity.type
        Turbolinks.visit(url, "_self")
    }


    getNameTitle = (name) => {
        if (name == "PCH" || name == "NMH") {
            return "Hospitales"
        } else if (name == "PCCAP") {
            return "Atención Primaria"
        } else if (name == "PCISO9001") {
            return "ISO 9001:2015 Salud"
        } else if (name == "PCISO15189") {
            return "ISO 15189:2012"
        } else if (name == "PCHEM") {
            return "Hemodiálisis"
        } else if (name == "NCON") {
            return "Consultorios"
        } else if (name == "NFAR") {
            return "Farmacias"
        } else if (name == "NNOM") {
            return "Normas Oficiales MXN"
        }
    }

    HandleChangeInplementionGuide = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                implementation_guide: e,
            },
        })
    }

    editSon = (son) => {
        this.setState({ 
            formUpdate: {
                measurable_element: son.measurable_element,
            },

            numeral_child_id: son.id 
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                measurable_element: e,
            },
        })
    }

    HandleClickUpdate = () => {
        fetch(`/health/numeral_child/${this.state.numeral_child_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.messageSuccess(data);
            this.setState({ 
                numeral_child_id: "",
                formUpdate: {
                    measurable_element: "",
                },
            })
        });
    }

    toogleShowVideo = (show, register) => {
        if (show == "show") {
            this.setState({ modalShowVideo: true, videUrl: register.video_url })
        } else {
            this.setState({ modalShowVideo: false, videUrl: "" })
        }
    }

    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={"Estándares y guías " + this.props.normativity.name}
                    subTitle={this.props.normativity.description}
                    titleNew={"Nuevo numeral"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    normativity={this.props.normativity}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />
                
                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.titleForm}

                        //form props
                        formValues={this.state.addingChildrens == false ? this.state.form : this.state.formChildren}
                        errorValues={this.state.ErrorValues}
                        onChangeForm={this.state.addingChildrens == false ? this.HandleChange : this.HandleChangeChildrens}
                        submitForm={this.state.addingChildrens == false ? this.HandleClick : this.HandleClickChildrens}

                        //muchos a muchos
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        procesos={this.state.dataProcesos}
                        selectedOptionProcesos={this.state.selectedOptionProcesos}
                        editValuesProcesos={this.state.dataProcesosEdit}

                        //areas select
                        selectedOptionAreas={this.state.selectedOptionAreas}
                        onChangeAutocomplete={this.handleChangeAutocompleteAreas}

                        //ckaditor

                        HandleChangeInplementionGuide={this.HandleChangeInplementionGuide}
                        areas={this.props.areas}
                    />
                )}
                
                {this.state.modalShowInfo && (
                    <ShowChildren
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalShowInfo}
                        toggle={this.toogleShow}
                        numeral_children={this.state.subnumeralRegister}
                        title={"Información de numeral"}
                    />
                )}

                {this.state.modalShowVideo && (
                    <ShowModalVideo
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalShowVideo}
                        toggle={this.toogleShowVideo}
                        video_url={this.state.videUrl}
                    />
                )}

                <nav className="" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0);" onClick={() => this.getBack()}>{this.getNameTitle(this.props.normativity.type)}</a></li>
                        <li className="active breadcrumb-item" aria-current="page"> Estándares y guías {this.props.normativity.name}</li>
                    </ol>
                </nav>

                {this.props.isLoaded? (
                    <div className="content main-card mb-3 card">

                        {this.props.estados.importFiles && (
                            <div className="card-header text-right">

                                <label
                                    htmlFor="fathers"
                                    className="btn-shadow btn btn-info mt-2 mr-2"
                                >
                                    Cargar Padres
                                </label>

                                <input
                                    type="file"
                                    id="fathers"
                                    onChange={(e) => this.uploadParents(e)}
                                    style={{ display: "none" }}
                                />

                                <input
                                    type="file"
                                    id="childrents"
                                    onChange={(e) => this.uploadChildrents(e)}
                                    style={{ display: "none" }}
                                />

                                {this.state.submitBtnFile && (
                                    <button
                                        onClick={() => this.handleClickUpload()}
                                        className="btn-shadow btn btn-primary"
                                    >
                                        Subir <i className="fas fa-file-upload"></i>
                                    </button>
                                )}

                            </div>
                        )}

                        <div className="card-body normativity-body">
                            <div className="">

                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(normativity => (
                                        <React.Fragment>

                                            <table className="table table-hover table-bordered normativity-body-table" id="sampleTable" style={{ tableLayout: "fixed", marginBottom: "20px" }}>
                                                <tbody>
                                                    <tr key={normativity.id} style={{ background: "#d8d8d8" }}>


                                                        <td style={{ width: "40px" }}>
                                                            <a data-toggle="collapse" href={`#collapseExample${normativity.id}`} role="button" aria-expanded="false" aria-controls="collapseExample">
                                                                <i className="fa fa-angle-down ml-2 opacity-8"></i>
                                                            </a>
                                                        </td>

                                                        <td className="title-numeral-parent">
                                                            {normativity.patient_safety_standar} {normativity.video_url != "" ? <i className="fas fa-video show-video" onClick={() => this.toogleShowVideo("show", normativity)}></i> : ""}
                                                        </td>
                                                        
                                                        <td className="last-score" style={{ width: "150px" }}>  {this.getScore(normativity.numeral_parent_scores)}</td>
                                                        {(true || true) && (
                                                            <td className="text-right" style={{ width: "8%" }}>
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {this.props.estados.editar && (
                                                                            <DropdownItem
                                                                                onClick={() => this.editParent(normativity)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {false && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(normativity.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.toogleShow("show", normativity)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Ver Información
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>
                                                        )}

                                                    </tr>
                                                    <tr style={{ background: "#f1f1f1" }}>
                                                        {(true || true) && (
                                                            <th className="text-center" style={{ width: "40px" }}>   
                                                                {false && (
                                                                    <a href="javascript:void(0);"
                                                                        onClick={() => this.addChildren(normativity)}
                                                                        className="button-link-line"
                                                                    >
                                                                        <i className="fas fa-plus"></i>
                                                                    </a>
                                                                )}
                                                            </th>
                                                        )}


                                                        
                                                        <th >Elemento medible (Requisito)</th>

                                                        <th style={{ width: "150px" }}>Última calificación</th>
                                                        <th></th>
                                                    </tr>

                                                    {normativity.numeral_children.map(son => (
                                                        <tr key={son.id} className="collapse show color-childrens" id={`collapseExample${normativity.id}`}>

                                                            <td></td>
                                                          
                                                            <td>
                                                                {son.id == this.state.numeral_child_id ? (
                                                                    <Ckeditor 
                                                                        HandleChange={this.HandleChangeUpdate}
                                                                        formValues={this.state.formUpdate.measurable_element}
                                                                    />
                                                                ) : (
                                                                    <p className="text-two-lines" onClick={() => this.props.estados.importFiles ? this.editSon(son) : console.log("") }>{ReactHtmlParser(son.measurable_element)}</p>
                                                                )}
                                                            </td>

                                                            <td className="last-score" style={{ width: "150px" }}> {this.getScore(son.numeral_child_scores)}</td>

                                                           
                                                            <td className="text-right" style={{ width: "10px" }}>
                                                                {(false) && (
                                                                    <UncontrolledDropdown className='btn-group'>
                                                                        <DropdownToggle className='btn btn-info'>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {false && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.editChildren(son)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {false && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.deleteChildrents(son.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                )}

                                                                {son.id == this.state.numeral_child_id && (
                                                                    <React.Fragment>
                                                                        <i className="fas fa-check-circle update-section" onClick={() => this.HandleClickUpdate()}></i> 
                                                                        <i className="fas fa-times-circle update-false" onClick={() => this.setState({ numeral_child_id: ""})}></i>
                                                                    </React.Fragment>
                                                                )}
                                                            </td>

                                                        </tr>

                                                    ))}
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    ))
                                ) : (
                                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="14" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}

                            </div>
                        </div>
                    </div>
                ) : (

                        <div className="col-md-12 text-center">
                            <Preloader />
                        </div>
                    )
                }
            </React.Fragment>
        );
    }
}

export default Table;