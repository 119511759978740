import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FormCreate = (props) => {
    
    const handleSubmit = e => {
        e.preventDefault();
    };
    const maxLength = 18;
    
    const [titleLength, setTitleLength] = useState(props.formValues.title.length);

    const remainingChars = maxLength - titleLength;

    const handleTitleChange = (event) => {
        const value = event.target.value;
        if (value.length <= maxLength) {
            props.onChangeForm(event);
            setTitleLength(value.length);
        }
    };

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>

                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-12">
                                    <label>Titulo</label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={props.formValues.title}
                                        onChange={handleTitleChange}
                                        className={`form form-control ${!props.errorValues && props.formValues.title == "" ? "error-class" : ""}`}
                                        placeholder="Nombre"
                                        maxLength={maxLength}
                                    />
                                    <p className='mt-1' style={{color: '#16aaff', textAlign: 'right', fontWeight: 500}}> {remainingChars} caracteres restantes</p>
                                </div>

                                <div className="col-md-12 mt-3">
                                    <label>Descripción</label>
                                    <textarea
                                        name="description"
                                        value={props.formValues.description}
                                        onChange={props.onChangeForm}
                                        className={`form form-control ${!props.errorValues && props.formValues.description == "" ? "error-class" : ""}`}
                                        rows="10"
                                    />
                                </div>

                                {!props.errorValues && (
                                    <div className="col-md-12 mt-4">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={props.submitForm}>{props.nameBnt}</button>
                        </ModalFooter>
                    </form>
            </Modal>
        </React.Fragment>
    );
}


export default FormCreate;

