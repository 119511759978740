import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            questions_target: [],
            questions_source: [],
        }
    }

    componentDidMount = () => {
        this.loadDataQuestionTarget();
        this.loadDataQuestionSource();
    }

    loadDataQuestionTarget = () => {
        fetch(`/formatos/get_questions/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            let array = [];

            data.register.map((item) => (
                array.push({ label: item.question, value: item.id, type: item.question_type, options_values: item.options })
            ))

            this.setState({
                questions_target: array
            })
        });
    }

    loadDataQuestionSource = () => {
        fetch(`/formatos/get_questions/${this.props.format_qr.format_target.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            let array = [];

            data.register.map((item) => (
                array.push({ label: item.question, value: item.id, type: item.question_type, options_values: item.options })
            ))

            this.setState({
                questions_source: array
            })
        });
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-3 mt-3">

                        <div className={`col-md-6`}>
                            <label>Pregunta Fuente</label>
                            <input
                                type="hidden"
                                name="question_source_id"
                                value={this.props.selectedOptionQuestionSource.question_source_id}
                            />

                            <Select
                                onChange={this.props.handleChangeAutocompleteQuestionSource}
                                options={this.state.questions_target}
                                autoFocus={false}
                                className={`link-form`}
                                value={this.props.selectedOptionQuestionSource}
                            />
                        </div>

                        <div className={`col-md-6`}>
                            <label>Pregunta Objetiva</label>
                            <input
                                type="hidden"
                                name="question_target_id"
                                value={this.props.selectedOptionQuestionTarget.question_target_id}
                            />
                                
                            <Select
                                onChange={this.props.handleChangeAutocompleteQuestionTarget}
                                options={this.state.questions_source}
                                autoFocus={false}
                                className={`link-form`}
                                value={this.props.selectedOptionQuestionTarget}
                            />
                        </div>

                        <div className="col-md-12 text-right mt-3">
                            <button
                                className="btn btn-outline-danger mr-2"
                                onClick={() => this.props.toggle()}
                            >
                                Cancelar
                            </button>

                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.props.submitForm()}
                            >
                                {this.props.nameBnt}
                            </button>

                            <hr />
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;