import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            showTable: false,
            ErrorValues: true,
            format_pdf_sub_form_id: "",

            form: {
                format_association_id: this.props.format_association.id, 
                format_pdf_id: this.props.format_pdf_id,
                format_pdf_association_id: "",
            },

            selectedOption: {
                label: "Selecione un pdf",
                format_pdf_association_id: "",
            },

            format_pdfs: []
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format_pdf_sub_form => {
        this.setState({
            data: this.state.data.map(item => {
                if (format_pdf_sub_form.id === item.id) {
                    return {
                        ...item,
                        format_pdf_association: format_pdf_sub_form.format_pdf_association, 
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            form: {
                ...this.state.form,
                format_pdf_association_id: "", 
            },

            selectedOption: {
                label: "Selecione un pdf",
                format_pdf_association: ""
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_pdf_sub_forms/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
        this.loadDataFormatPdfs();
    }

    loadData = () => {
        fetch(`/formatos/get_format_pdf_sub_forms/${this.props.format_association.id}/${this.props.format_pdf_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    loadDataFormatPdfs = () => {
        fetch(`/formatos/get_format_pdfs/${this.props.format_association.format_select.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            const array = []

            data.data.map((format_pdf) => (
                array.push({ label: format_pdf.name, value: format_pdf.id })
            ))

            this.setState({
                format_pdfs: array,
            })
        });
    }

    validationForm = () => {
        if (this.state.form.format_select_id != "" &&
            this.state.form.question_asociation_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.validationForm()) {
            if (this.state.modeEdit) {
                fetch(`/formatos/format_pdf_sub_forms/${this.state.format_pdf_sub_form_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            } else {
                fetch(`/formatos/format_pdf_sub_forms`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateData(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            }
        }
    }


    edit = (format_pdf_sub_form) => {
        this.setState({
            modal: true,
            modeEdit: true,
            format_pdf_sub_form_id: format_pdf_sub_form.id,

            form: {
                ...this.state.form,
                format_pdf_association_id: format_pdf_sub_form.format_pdf_association.id,
            },

            selectedOption: {
                label: format_pdf_sub_form.format_pdf_association.name,
                format_pdf_association_id: format_pdf_sub_form.format_pdf_association.id
            },
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                format_pdf_association_id: selectedOption.value
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <ModalBody>

                        {this.state.modal && (
                            <FormCreate
                                toggle={this.toogle}
                                title={this.state.modeEdit ? "Actualizar" : "Crear"}
                                nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                                //form props
                                formValues={this.state.form}
                                onChangeForm={this.HandleChange}
                                submitForm={this.HandleClick}
                                errorValues={this.state.ErrorValues}

                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                selectedOption={this.state.selectedOption}
                                format_pdfs={this.state.format_pdfs}
                            />
                        )}


                        {this.state.data.length == 0 && (
                            <div className="col-md-12 mb-3 text-right pr-0">
                                {!this.state.modal && (
                                    <button
                                        className="btn-shadow btn btn-info"
                                        onClick={() => this.toogle("new")}
                                    >
                                        Abrir formulario
                                    </button>
                                )}
                            </div>
                        )}

                        <div className="card">
                            <div className="background-gray">
                                <table className="table " id="sampleTable">
                                    <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                        <tr>
                                            <th>Pdf</th>
                                            <th className="text-right">Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map((format_pdf_sub_form, index) => (
                                                <React.Fragment>
                                                    {index >= 1 ? <div className="mb-2"></div> : null}
                                                    <tr key={format_pdf_sub_form.id} className="tr-table-task" style={{ borderLeft: `4px solid green` }}>
                                                        <td>{format_pdf_sub_form.format_pdf_association.name}</td>
                                                        <td className="text-right">
                                                            {true && (
                                                                <UncontrolledDropdown direction="left" className='btn-group'>
                                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(format_pdf_sub_form)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}
                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(format_pdf_sub_form.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;