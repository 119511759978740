import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd'
import Preloader from '../../GeneralComponents/LoadingComponen';
import { UncontrolledTooltip } from 'reactstrap';
import initialData from './initial-data'
import Column from './column'
import FormFilter from '../tasks/tasksCard/FormFilter'
import { array } from 'prop-types';
import column from './column';
import ShowTask from '../tasks/tasksCard/ShowTask'
import Swal from "sweetalert2/dist/sweetalert2.js";
import GoogleFontLoader from 'react-google-font-loader';
import BreadcrumbTasks from '../../GeneralComponents/Breadcrumb/BreadcrumbTasks';

class index extends Component {
  constructor(props) {
    super(props);
    this.fontConfig = {
      fonts: [
        {
          font: 'Material Symbols Outlined',
          weights: [400],
        },
      ],
      async: true,
      defer: true,
    };
    this.state = {
      columnOrder: [],
      popoverColumnId: "",
      columns: [],
      tasks: {},
      isLoaded: true,
      isLoadedTask: true,
      current_column_id: "",
      prueba: {},
      url: this.props.url,
      first_value: {},
      isFiltering: false,
      estados: {},
      modal: this.props.task ? Object.keys(this.props.task).length != 0 : false,
      task: this.props.task,
      grag_task_id: ""
    }
  }

  handleClickShow = (id) => {
    fetch(`/task/get_show_task/${id}`, {
      method: 'GET', // or 'PUT'
      headers: {
        "X-CSRF-Token": this.token,
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          task: data.task,
          modal: true,
        })
      });
  }

  closePopoversColumnFilter = (column_id) => {
    this.setState({
      popoverColumnId: column_id
    })
  }

  updatePosition = (result) => {
    let token = document.querySelector("[name='csrf-token']").content;
    let columnStart = result.source.droppableId.replace("column-", "");
    let columnFinish = result.destination.droppableId.replace("column-", "");
    let finishPosition = (result.destination.index + 1);
    let taskId = result.draggableId.replace("task-", "");

    let form = {
      column_start_id: columnStart,
      column_finish_id: columnFinish,
      finish_position: finishPosition,
      task_id: taskId,
    };

    this.setState({ grag_task_id: taskId });

    fetch(`/task/update_position_task`, {
      method: 'PATCH', // or 'PUT'
      body: JSON.stringify(form), // data can be `string` or {object}!
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json"
      }
    })

      .then(res => res.json())
      .catch(error => console.error("Error:", error))
      .then(data => {
        this.setState({ grag_task_id: "" })
        if (data.register.column.type_column == "done") {
          this.loadData();
        }
      });
  }

  onDragEnd = result => {
    const { destination, source, draggableId } = result
    const taskId = result.draggableId.replace("task-", "");

    this.updatePosition(result)

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const start = this.state.columns[source.droppableId]
    const finish = this.state.columns[destination.droppableId]

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds
      }

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      }

      this.setState(newState)
      return
    }

    // Mueve de una lista a otra
    const startTaskIds = Array.from(start.taskIds)
    startTaskIds.splice(source.index, 1)
    const newStart = {
      ...start,
      taskIds: startTaskIds
    }

    const finishTaskIds = Array.from(finish.taskIds)
    finishTaskIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    }

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }

    this.setState(newState)
  }

  componentDidMount = () => {
    this.loadData()
  }

  messageSuccess = (response) => {
    if(response.type != "error"){
      Swal.fire({
        position: "center",
        type: `${response.type}`,
        title: `${response.success}`,
        showConfirmButton: false,
        timer: 1500,
      });
    }else{
      Swal.fire({
        position: "center",
        type: `${response.type}`,
        title: `${response.success}`,
        showConfirmButton: true,
      });
    }
  };


  loadData = (isLoaded = false, messageSuccess = {}) => {
    fetch(this.state.url, {
      method: 'GET', // or 'PUT'
      headers: {
        "X-CSRF-Token": this.token,
        "Content-Type": "application/json"
      }
    })

      .then(response => response.json())
      .then(data => {
        if (isLoaded == true) {
          this.messageSuccess(messageSuccess)
        }

        if(messageSuccess.type != "error"){
          this.setState({
            tasks: data.data.tasks,
            columns: data.data.columns,
            columnOrder: data.data.columnOrder,
            first_value: data.data,
            estados: data.data.estados,
            isLoaded: false,
            isLoadedTask: false,
          })
        }else{
          this.setState({
            isLoaded: false,
            isLoadedTask: false,
          })
        }
      });
  }

  updateState = (newState) => {
    this.setState(newState)
  }


  updateStateLoad = (state, current_column_id) => {
    this.setState({
      isLoadedTask: state,
      current_column_id: current_column_id,
    })
  }



  updateDataFilter = (data) => {
    this.setState(data.data)
  }

  updateStateIsLoaded = (state) => {
    this.setState({
      isLoaded: state,
    })
  }

  cancelFilter = () => {
    this.setState({
      isFiltering: false,
      isLoaded: false,
      showFilter: false,
      url: this.props.url,

      tasks: this.state.first_value.tasks,
      columns: this.state.first_value.columns,
      columnOrder: this.state.first_value.columnOrder,
    });
  }

  handleClickShowFilter = () => {
    this.setState({
      showFilter: !this.state.showFilter
    })
  }

  urlFilter = (url) => {
    this.setState({
      url: url,
    })
  }

  updateData = (register) => {
    let hola = Object.entries(this.state.columns).map(el => (
      Object.entries(el).map(array => (
        array[1].id == register.column_id ? { ...array[1], taskIds: [...array[1].taskIds, register.task_reference_id] } : array[1]
      ))
    ))

    const values = []

    Object.entries(hola).map(el => (
      Object.entries(el).filter(e => e[1][1] != undefined).map(function (cell, i) {
        values.push({ name: [cell[1][1].id][0], value: [cell[1][1]][0] })
      })
    ))

    const arrayToObject1 = (arr, key) => {
      return arr.reduce((obj, item) => {
        obj[item[key]] = item.value
        return obj
      }, {})
    }

    this.setState({
      tasks: { ...this.state.tasks, [register.task_reference_id]: register },
      columns: arrayToObject1(values, "name"),
    })

  }

  updateTaskOrderColumn = (register) => {
    this.setState({
      columns: register
    })
  }

  removeTask = (task) => {
    const column_id = `column-${task.column_id}`;
    const task_id = task.id;
    const filteredTasks = Object.fromEntries(Object.entries(this.state.tasks).filter(([key, value]) => key != task_id))

    const values = Object.entries(this.state.columns).map(function (column, i) {
      if (column[0] == column_id) {
        column[1].taskIds = column[1].taskIds.filter(e => e != task_id)
      }

      return column
    })

    this.setState({
      tasks: filteredTasks,
      columns: values.reduce((obj, cur) => ({ ...obj, [cur[0]]: cur[1] }), {}),
    })
  }

  toogle = (from) => {
    if (from == "new") {
      this.setState({ modal: true })
    } else {
      this.setState({ modal: false, task: {} })
    }
  }



  render() {
    if (this.state.isLoaded) {
      return (
        <div className="card">
          <div className="card-body">
            <Preloader />
          </div>
        </div>
      )
    }

    return (
      <div className="ui-index-taskTrello__container-all">
        {<GoogleFontLoader {...this.fontConfig} />}

        {this.state.modal && (
          <ShowTask
            //modal props
            backdrop={"static"}
            modal={this.state.modal}
            toggle={this.toogle}
            title={"Crear tarea"}

            //form props
            task={this.state.task}

            loadTasks={this.loadDataShow}
            users={this.props.users}

            //load show
            isLoadedShow={false}

            //current_user
            user={this.props.user}

            //load tasks
            loadData={this.loadData}

            //test load data

            updateItem={this.props.updateItem}
            updateData={this.props.updateData}

            //procesos 

            procesos={this.props.procesos}
            company={this.props.company}
            imageUpload={this.props.imageUpload}
            key_text={this.props.key_text}
          />
        )}

        {this.state.showFilter && (
          <FormFilter
            handleClickFilter={this.HandleClickFilter}
            handleClickCancel={this.handleClickCancel}
            handleChangeFilter={this.handleChangeFilter}
            from={this.props.from}
            index={true}
            url={this.props.url}

            //values data
            users={this.props.users}
            labels={this.props.labels}
            tasks={this.props.tasks}
            procesos={this.props.procesos}
            task_sources={this.props.task_sources}

            //update states
            updateStateIsLoaded={this.updateStateIsLoaded}
            updateStates={this.updateStates}
            updateDataFilter={this.updateDataFilter}
            cancelFilter={this.cancelFilter}
            task_board={this.props.task_board}

            //states
            isFiltering={this.state.isFiltering}
            urlFilter={this.urlFilter}
          />
        )}

        <BreadcrumbTasks 
          handleClickShowFilter={this.handleClickShowFilter}
          updateView={this.props.updateView}
          listView={this.props.listView}
        />


        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="ui-scroll-horizontal-task">
            {this.state.columnOrder.map(columnId => {
              const column = this.state.columns[columnId]
              const tasks = column.taskIds.map(
                taskId => this.state.tasks[taskId]
              )

              return (
                <Column
                  key={column.id}
                  column={column}
                  tasks={tasks}
                  company={this.props.company}

                  updateStateLoad={this.updateStateLoad}
                  isLoadedTask={this.state.isLoadedTask}
                  current_column_id={this.state.current_column_id}
                  popoverColumnId={this.state.popoverColumnId}
                  closePopoversColumnFilter={this.closePopoversColumnFilter}

                  loadData={this.loadData}
                  data={this.props.data}
                  isLoaded={false}
                  user={this.props.user}
                  users={this.props.users}
                  from={this.props.from}
                  estados={this.state.estados}
                  updateState={this.updateState}
                  task={this.props.task}
                  toogle={this.toogle}
                  handleClickShow={this.handleClickShow}
                  updateTaskOrderColumn={this.updateTaskOrderColumn}

                  //test data 

                  updateData={this.updateData}
                  updateItem={this.props.updateItem}
                  task_board={this.props.task_board}

                  //parametros 

                  task_id={this.props.task_id}
                  procesos={this.props.procesos}
                  handleClickFilter={this.loadDataFilter}
                  isFiltering={this.props.isFiltering}
                  imageUpload={this.props.imageUpload}
                  key_text={this.props.key_text}
                  removeTask={this.removeTask}
                  grag_task_id={this.state.grag_task_id}

                />
              )
            })}
          </div>
        </DragDropContext>
      </div>
    )
  }
}

export default index;