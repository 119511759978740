import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
    }

    handleSubmit = e => {
        e.preventDefault();
    };


    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-6">
                                    <input
                                        type="hidden"
                                        name="tenant_source_id"
                                        value={this.props.selectedOptionCompany.tenant_source_id}
                                    />
                                    <label>Empresa</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteCompany}
                                        options={this.props.companies}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.props.selectedOptionCompany}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label>Tipo duplicación</label>
                                    <select 
                                        className='form-control'
                                        name="duplication_type"
                                        value={this.props.formValues.duplication_type}
                                        onChange={this.props.onChangeForm} 
                                    >
                                        <option value=''> Seleccione Valor</option>
                                        <option value='duplicate_format'> Duplicar Formato</option>
                                        <option value='duplicate_module'> Duplicar Módulo</option>
                                    </select>
                                </div>

                                {(this.props.formats.length >= 1 && this.props.formValues.duplication_type == 'duplicate_format') && (
                                    <div className="col-md-6 mt-3">
                                        <input
                                            type="hidden"
                                            name="format_source_id"
                                            value={this.props.selectedOptionFormat.format_source_id}
                                        />
                                        <label>Formato</label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteFormat}
                                            options={this.props.formats}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionFormat}
                                        />
                                    </div>
                                )}

                                {(this.props.source_categories.length >= 1 && this.props.formValues.duplication_type == 'duplicate_module') && (
                                    <div className="col-md-6 mt-3">
                                        <input
                                            type="hidden"
                                            name="format_category_source_id"
                                            value={this.props.selectedOptionFormatCategoryTarget.format_category_source_id}
                                        />
                                        <label>Categoría Objetivo</label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteFormatCategoryTarget}
                                            options={this.props.source_categories}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionFormatCategoryTarget}
                                        />
                                    </div>
                                )}

                                {true && (
                                    <div className="col-md-6 mt-3">
                                        <input
                                            type="hidden"
                                            name="tenant_target_id"
                                            value={this.props.selectedOptionTenant.tenant_target_id}
                                        />
                                        <label>Empresa a duplicar el formato</label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteTenant}
                                            options={this.props.companies}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionTenant}
                                        />
                                    </div>
                                )}

                                {this.props.showCategories && (
                                    <div className="col-md-6 mt-3">
                                        <input
                                            type="hidden"
                                            name="format_category_id"
                                            value={this.props.selectedOptionCategory.format_category_id}
                                        />
                                        <label>Categoría a duplicar</label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteCategory}
                                            options={this.props.categories}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionCategory}
                                        />
                                    </div>
                                )}

                                {false && (
                                    <div className="col-md-12 mt-4">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBnt}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;