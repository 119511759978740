import React, { Component } from 'react';
import LineChartIndicator from "../../../../GeneralComponents/Charts/LineChartIndicator"
import LineChartIndicatorMonth from "../../../../GeneralComponents/Charts/LineChartIndicatorMonth"
import NumberFormat from "react-number-format";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class layoutTableIndicator extends Component {

    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            formUpdate: [],
            formUpdateMonth: [],
            formUpdateHorizontal: [],
            formUpdateHorizontalMonth: [],
            matrix: [],
            index: 500,
            id: "",
            indicator_id: "",
            indexMonth: 500,
            indexHorizontal: 500,
            indexHorizontalMonth: 500,
            dataLine: [],
            dataLineAccumulated: [],
            dataLineMonth: [],
            dataLineMonthAccumulated: [],
            dataToday: [],
            dataMonth: [],
            todayMonth: "",
            todayDay: this.date(),
            formCreateVariable: {
                name: "",
                id: this.props.indicator.id,
            },
            formUpdateVariable: {
                name: "",
                id: "",
            },
            newVariable: false,
            updateName: ""
        }
    }

    HandleChangeCreateVariable = (e) => {
        this.setState({
            formCreateVariable: {
                ...this.state.formCreateVariable,
                [e.target.name]: e.target.value

            },
        });

    }

    HandleChangeUpdateVariable = (e) => {
        this.setState({
            formUpdateVariable: {
                ...this.state.formUpdateVariable,
                [e.target.name]: e.target.value

            },
        });

    }

    componentWillReceiveProps(nextProps) {
        console.log("recive procps loayot")
        if (this.props !== nextProps) {
            this.renderAgain(nextProps)
        }

    }


    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    renderAgain = (nextProps) => {

        this.dataLine(nextProps.data)
        this.dataLineMonth(nextProps.data)

        let today = new Date().getDate();
        if (today = !1) {
            this.setState({
                data: nextProps.data,
                matrix: this.setMatrix(nextProps),
                todayMonth: this.getMonthText(nextProps.month),
                dataToday: [
                    nextProps.data[0].data_indicators[(new Date().getMonth())].data.data[((new Date().getDate()) - 2)].real,
                    nextProps.data[1].data_indicators[(new Date().getMonth())].data.data[((new Date().getDate()) - 2)].real,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].data.data[((new Date().getDate()) - 2)].percentege,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].percentege,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].target,
                    this.getPercentage(nextProps.data[2].data_indicators[(new Date().getMonth())].accumulated, nextProps.data[2].data_indicators[(new Date().getMonth())].target),
                    nextProps.indicator.variable_1,
                    nextProps.indicator.variable_2,


                ],
                dataMonth: [
                    nextProps.data[0].data_indicators[nextProps.month].real,
                    nextProps.data[1].data_indicators[nextProps.month].real,
                    nextProps.data[2].data_indicators[nextProps.month].real,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].percentege,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].target,
                    this.getPercentage(nextProps.data[2].data_indicators[nextProps.month].accumulated, nextProps.data[2].target_year),
                    nextProps.indicator.variable_1,
                    nextProps.indicator.variable_2,
                ]

            })
        } else {
            this.setState({
                data: nextProps.data,
                matrix: this.setMatrix(nextProps),
                todayMonth: this.getMonthText(nextProps.month),
                dataToday: [
                    nextProps.data[0].data_indicators[(new Date().getMonth())].data.data[((new Date().getDate()) - 1)].real,
                    nextProps.data[1].data_indicators[(new Date().getMonth())].data.data[((new Date().getDate()) - 1)].real,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].data.data[((new Date().getDate()) - 1)].percentege,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].percentege,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].target,
                    this.getPercentage(nextProps.data[2].data_indicators[(new Date().getMonth())].accumulated, nextProps.data[2].data_indicators[(new Date().getMonth())].target),
                    nextProps.indicator.variable_1,
                    nextProps.indicator.variable_2,


                ],
                dataMonth: [
                    nextProps.data[0].data_indicators[nextProps.month].real,
                    nextProps.data[1].data_indicators[nextProps.month].real,
                    nextProps.data[2].data_indicators[nextProps.month].real,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].percentege,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].target,
                    this.getPercentage(nextProps.data[2].data_indicators[nextProps.month].accumulated, nextProps.data[2].target_year),
                    nextProps.indicator.variable_1,
                    nextProps.indicator.variable_2,
                ]

            })
        }
    }

    componentDidMount = () => {
        this.renderAgain(this.props)
    }

    date = (fecha) => {
        let day = ((new Date().getDate()) - 1);
        let month = (new Date().getMonth());
        let year = (new Date().getFullYear());
        var d = new Date(year, month, day),
            months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[month] + " " + day + " " + 'del' + " " + year;
    }

    getMonthText = (fecha) => {
        let months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
        return months[fecha]
    }


    getPercentage = (val1, val2) => {
        let percentege = 0
        if (val1 != 0) {
            return percentege = val2 = !0 ? Math.round((val1 / val2) * 100, 0) : 0

        }
        else {
            return 0
        }

    }
    setMatrix = (nextProps) => {

        let matrix = []
        let vector = []
        nextProps.data.map((data) => {
            data.data_indicators[this.props.month].data.data.map((data, index) => {
                vector.push(data)
            })
            matrix.push(vector)
            vector = []
        })
        return matrix
    }


    setHorizontal = (index) => {




        let array = []
        this.state.data.filter((data) => data.total_column === false).sort((a, b) => b.data_indicators[this.props.month].real - a.data_indicators[this.props.month].real).map(data => {

            let getMonth = data.data_indicators[this.props.month].data.data[index]
            getMonth.id = data.id
            array.push(getMonth)


        })

        return array

    }

    setMonth = (index) => {

        console.log(index)
        let array = []
        console.log(this.state.data[index].data_indicators)
        let getMonth = [...this.state.data[index].data_indicators]
        getMonth.map(data => {

            let value = { real: data.real }
            array.push(value)


        })
        console.log(this.state.data[index].data_indicators)
        console.log(array)
        return array


    }
    setHorizontalMonth = (index) => {

        let array = []
        this.state.data.filter((data) => data.total_column === false).sort((a, b) => b.accumulated - a.accumulated).map(data => {

            let getMonth = { real: data.data_indicators[index].real, first_variable: data.data_indicators[index].first_variable, second_variable: data.data_indicators[index].second_variable }
            getMonth.id = data.id
            array.push(getMonth)


        })

        return array


    }



    updateFiels = (index, id, indicator_id) => {
        this.setState({
            index: index,
            indexHorizontal: 200,
            indexMonth: 200,
            indexHorizontalMonth: 200,
            id: id,
            indicator_id: indicator_id,
            formUpdate: this.state.matrix[index]
        })
    }

    updateFielsHorizontal = (index, id, indicator_id) => {
        this.setState({
            indexHorizontal: index,
            index: 200,
            indexMonth: 200,
            indexHorizontalMonth: 200,
            id: id,
            indicator_id: indicator_id,
            formUpdateHorizontal: this.setHorizontal(index)
        })
    }

    updateFielsMonth = (index, id, indicator_id) => {
        console.log("indexMOnth")
        this.setState({
            indexMonth: index,
            index: 200,
            indexHorizontal: 200,
            indexHorizontalMonth: 200,
            id: id,
            formUpdateMonth: this.setMonth(index)

        })
    }
    updateFielsHorizontalMonth = (index, id, indicator_id) => {
        this.setState({
            indexHorizontalMonth: index,
            index: 200,
            indexHorizontal: 200,
            indexMonth: 200,
            id: id,
            formUpdateHorizontalMonth: this.setHorizontalMonth(index)

        })
    }



    HandleChangeUpdateHorizontal = value => (e) => {
        const values = this.state.formUpdateHorizontal;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }

    HandleChangeUpdateMonth = value => (e) => {
        const values = this.state.formUpdateMonth;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }

    HandleChangeUpdateHorizontalMonth = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }
    HandleChangeUpdateHorizontalMonthFirstVariable = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].first_variable = e.target.value;
        values[value].real = parseInt(e.target.value) + parseInt(values[value].second_variable);
        this.setState({
            values
        });
    }

    HandleChangeUpdateHorizontalMonthSecondVariable = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].second_variable = e.target.value;
        values[value].real = parseInt(e.target.value) + parseInt(values[value].first_variable);;
        this.setState({
            values
        });
    }






    HandleClickUpdate = () => {
        let data = { data: this.state.formUpdate }
        fetch(`/indicator/update_json_new/${this.state.id}/0/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260
                })
                /*               this.messageSuccess(data); */
            });
    }

    HandleClickUpdateHorizontal = () => {
        let data = { data: this.state.formUpdateHorizontal }
        fetch(`/indicator/update_json_horizontal_table/${this.state.data[0].indicator_id}/${this.state.indexHorizontal}/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260
                })
                /*               this.messageSuccess(data); */
            });
    }


    HandleClickUpdateHorizontalMonth = () => {
        let data = { data: this.state.formUpdateHorizontalMonth }
        fetch(`/indicator/update_json_month_horizontal_table/${this.state.data[0].indicator_id}/${this.state.indexHorizontalMonth}/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260,
                    indexMonth: 260,
                    indexHorizontalMonth: 260

                })
                /*               this.messageSuccess(data); */
            });
    }


    dataLine = (nextProps) => {


        let value = this.props.month
        let target = nextProps[2].data_indicators[value].target
        let array = [['x', 'datos', { role: "annotation", type: "string" }, 'x'], [0, 0, "", target]]

        nextProps[2].data_indicators[value].data.data.map((data, index) => {

            let data_percent = data.percentege + "%"
            let data_percent_num = data.percentege
            if (data.real == 0) {
                data_percent = ""
            }
            if (data.real == 0) {
                data_percent_num = null
            }

            array.push([index + 1, data_percent_num, data_percent, target])



        })


        this.setState((state, props) => ({
            dataLine: array
        }));
    }

    dataLineMonth = (nextProps) => {

        let target = nextProps.target
        let array = [['x', 'datos', { role: "annotation", type: "string" }, 'x']]


        nextProps[2].data_indicators.map((data, index) => {

            let data_percent = data.percentege
            let data_percent_num = data.percentege + "%"

            if (data.real == 0) {
                data_percent_num = ""
            }
            if (data.real == 0 && data.month_text != "ENE") {
                data_percent = null
            }
            data_percent
            array.push([data.month_text, data_percent, data_percent_num, data.target])



        })
        this.setState((state, props) => ({
            dataLineMonth: array
        }));
    }



    createVariable = () => {
        let data = { data: this.state.formCreateVariable }
        fetch(`/indicator/create_variable_table`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    newVariable: false,
                    formCreateVariable: {
                        name: "",
                        id: this.props.indicator.id,
                    },

                })
                this.messageSuccess(data)
            });
    }


    updateVariable = () => {
        console.log("editando variable")
        let data = { data: this.state.formUpdateVariable }
        fetch(`/indicator/update_variable`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    updateName: "",
                    formUpdateVariable: {
                        name: "",
                        id: "",
                    },

                })
                this.messageSuccess(data)
            });
    }

    deleteVariable = (id) => {
        fetch(`/indicator/delete_variable_table/${id}`, {
            method: 'DELETE', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.messageSuccess(data)
            });
    }





    numberToCurrency = (amount) => {
 amount = amount ? amount : 0
        var thousandsSeparator = ","
        var currencyNum = "";
        var amountString = amount.toString();
        var digits = amountString.split("");

        var countDigits = digits.length;
        var revDigits = digits.reverse();

        for (var i = 0; i < countDigits; i++) {
            if ((i % 3 == 0) && (i != 0)) {
                currencyNum += thousandsSeparator + revDigits[i];
            } else {
                currencyNum += digits[i];
            }
        };

        var revCurrency = currencyNum.split("").reverse().join("");

        var finalCurrency = "$" + revCurrency;

        return finalCurrency;
    }

    getInput = (indexP, index, data) => {

        if (indexP == this.state.index) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdate[index].real}
                    onChange={this.HandleChangeUpdate(index)}
                    className="form form-control"
                />)
        }
        else if (index == this.state.indexHorizontal) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdateHorizontal[indexP].real}
                    onChange={this.HandleChangeUpdateHorizontal(indexP)}
                    className="form form-control"
                />)
        }
        else {
            if (this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined) {
                return (
                    <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data} /></p>
                )
            }
            else {
                return (
                    <p>{data}</p>
                )
            }

        }
    }

    getInputMonth = (indexP, index, data) => {
        console.log(index)
        console.log(indexP)
        if (indexP == this.state.indexMonth) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdateMonth[index].real}
                    onChange={this.HandleChangeUpdateMonth(index)}
                    className="form form-control"
                />)
        }
        else if (index == this.state.indexHorizontalMonth) {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        name="real"
                        value={this.state.formUpdateHorizontalMonth[indexP].real}
                        onChange={this.HandleChangeUpdateHorizontalMonth(indexP)}
                        className="form form-control"

                    />
                    {/*                     <input
                        type="text"
                        name="first_variable"
                        value={this.state.formUpdateHorizontalMonth[indexP].first_variable}
                        onChange={this.HandleChangeUpdateHorizontalMonthFirstVariable(indexP)}
                        className="form form-control"
                    />
                    <input
                        type="text"
                        name="second_variable"
                        value={this.state.formUpdateHorizontalMonth[indexP].second_variable}
                        onChange={this.HandleChangeUpdateHorizontalMonthSecondVariable(indexP)}
                        className="form form-control"
                    /> */}
                </React.Fragment>
            )
        }
        else {
            console.log(this.props.indicator.total_type)
            console.log("this.props.indicator.total_type")
            if (this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined) {
                return (
                    <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data} /></p>
                )
            }
            else {
                return (
                    <p>{data}</p>
                )
            }


        }
    }


    showCreateVariable = () => {
        this.setState({
            newVariable: true,
            formCreateVariable: {
                name: "",
                id: this.props.indicator.id,
            },

        })
    }
    cancelCreateVariable = () => {
        this.setState({
            newVariable: false,
            formCreateVariable: {
                name: "",
                id: this.props.indicator.id,
            },
        })
    }
    editVariable = (id, name) => {
        this.setState({
            updateName: id,
            formUpdateVariable: {
                name: name,
                id: id,
            },
        })
    }
    cancelEdit = () => {
        this.setState({
            updateName: "",
        })
    }


    render() {
        console.log("entre en layou")
        console.log(this.state.data.length)
        console.log(this.props.data)
        if (this.props.data.length == 0) {
            return (
                <div></div>
            );
        }
        return (
            <React.Fragment>
                {(this.props.typeShow == "Day" && this.props.indicator.table_type != "mensual") && (
                    <div className="row indicators">

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    {!this.state.newVariable && (
                                        <button className="btn btn-primary mb-3" style={{ marginRight: "10px" }} onClick={this.showCreateVariable}>Nuevo registro</button>
                                    )}
                                    {this.state.newVariable && (
                                        <div className="row mb-2">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="">Nombre</label>
                                                    <input
                                                        name="name"
                                                        value={this.state.formCreateVariable.name}
                                                        onChange={this.HandleChangeCreateVariable}
                                                        className="form form-control"
                                                    />
                                                </div>

                                                <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={this.createVariable}>Actualizar</button>
                                                <button className="btn btn-outline-danger" onClick={this.cancelCreateVariable}>Cancelar</button>
                                            </div>
                                            <div className="col-md-12">
                                                <hr />
                                            </div>
                                        </div>
                                    )}
                                    <table className="indicator-horizontal-table-days">
                                        <tr className="day">
                                            <td className="tr-gray"></td>
                                            <td className="indicator-subtotal">%</td>
                                            <td className="indicator-subtotal">Total</td>
                                            {this.props.data[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                                <td style={{ background: (new Date().getDate()) - 1 == index + 1 && this.props.month == (new Date().getMonth()) ? "#3e69d8" : "whitesmoke" }} className="td td-info">
                                                    <p style={{ color: (new Date().getDate()) - 1 == index + 1 && this.props.month == (new Date().getMonth()) ? "white" : "#1ca7f9" }} className="link-line" onClick={() => this.updateFielsHorizontal(index, data.id, data.indicator_id)}>{index + 1}</p>
                                                    {index == this.state.indexHorizontal && (
                                                        <div className="indicators-check-vertical-seg">
                                                            <React.Fragment>
                                                                <i
                                                                    className="fas fa-check-circle true-btn"
                                                                    onClick={() => this.HandleClickUpdateHorizontal()}
                                                                    style={{ marginRight: "5px" }}
                                                                >
                                                                </i>
                                                                <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexHorizontal: 260 })}></i>
                                                            </React.Fragment>
                                                        </div>
                                                    )}
                                                </td>
                                            ))}

                                        </tr>
                                        {this.props.data.filter((data) => data.total_column === false).sort((a, b) => b.data_indicators[this.props.month].real - a.data_indicators[this.props.month].real).map((data, indexP) => (
                                            <tr className="day">
                                                {indexP == this.state.index && (
                                                    <div className="indicators-checks">
                                                        <React.Fragment>
                                                            <i
                                                                className="fas fa-check-circle true-btn ml-1"
                                                                onClick={() => this.HandleClickUpdate()}
                                                                style={{ marginRight: "5px" }}
                                                            >
                                                            </i>
                                                            <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ index: 260 })}></i>
                                                        </React.Fragment>
                                                    </div>
                                                )}
                                                <td className="tr" /* onClick={() => this.updateFiels(indexP, data.id, data.indicator_id)} */>

                                                    {this.state.updateName == data.id ? (
                                                        <div>
                                                            <input
                                                                name="name"
                                                                value={this.state.formUpdateVariable.name}
                                                                onChange={this.HandleChangeUpdateVariable}
                                                                className="form form-control"
                                                            />
                                                            <br />
                                                            <button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => this.updateVariable()}>Actualizar</button>
                                                            <button className="btn btn-outline-danger" style={{ marginRight: "10px" }} onClick={() => this.cancelEdit()}>Cancelar</button>

                                                        </div>
                                                    ) : (
                                                            <div className="name-variable">
                                                                <div>{data.name}</div>
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right text-center">
                                                                        <DropdownItem className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => this.editVariable(data.id, data.name)}>Editar</DropdownItem>
                                                                        <DropdownItem className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => this.deleteVariable(data.id)}>Eliminar</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                        )}



                                                </td>
                                                <td className="">


                                                    <p> <b>{data.data_indicators[this.props.month].percentege}%</b></p>

                                                </td>
                                                <td className="">

                                                    {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                        <p><b><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.data_indicators[this.props.month].real} /></b></p>
                                                    ) : (
                                                            <p> <b>{data.data_indicators[this.props.month].real}</b></p>
                                                        )}
                                                </td>

                                                {data.data_indicators[this.props.month].data.data.map((data, index) => (
                                                    <td className="td">
                                                        {this.getInput(indexP, index, data.real)}


                                                    </td>
                                                ))}

                                            </tr>
                                        ))}
                                        <tr className="day">
                                            <td className="tr" style={{ background: "#00aff3" }} >TOTAL</td>
                                            <td className="indicator-subtotal">

                                                <p>  {this.props.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].percentege}%</p>

                                            </td>
                                            <td className="indicator-subtotal">
                                                {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                    <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.props.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].real} /></p>
                                                ) : (
                                                        <p>  {this.props.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].real}</p>
                                                    )}
                                            </td>
                                            {this.props.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                                <td className="indicator-subtotal">


                                                    {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                        <p> <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.real} /></p>
                                                    ) : (
                                                            <p>{data.real}</p>
                                                        )}
                                                </td>
                                            ))}

                                        </tr>
                                        {/*                                         <div className="day">
                                            <div className="tr" >ACUMULADO</div>
                                            {this.props.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                                <div className="td">

                                                    <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.real != 0 ? data.accumulated : 0} /></p>

                                                </div>
                                            ))}
                                            <div className="indicator-subtotal"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.props.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].real} /></div>

                                        </div> */}
                                    </table>
                                </div>
                            </div>


                        </div>
                    </div>
                )}
                {(this.props.typeShow == "Month" || this.props.indicator.table_type == "mensual") && (
                    <div className="row indicators">
                        <div className="col-md-12">


                            <div className="card">
                                <div className="card-body">
                                    {!this.state.newVariable && (
                                        <button className="btn btn-primary mb-3" style={{ marginRight: "10px" }} onClick={this.showCreateVariable}>Nuevo Registro</button>
                                    )}
                                    {this.state.newVariable && (
                                        <div className="row mb-2">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="">Nombre</label>
                                                    <input
                                                        name="name"
                                                        value={this.state.formCreateVariable.name}
                                                        onChange={this.HandleChangeCreateVariable}
                                                        className="form form-control"
                                                    />
                                                </div>

                                                <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={this.createVariable}>Actualizar</button>
                                                <button className="btn btn-outline-danger" onClick={this.cancelCreateVariable}>Cancelar</button>
                                            </div>
                                            <div className="col-md-12">
                                                <hr />
                                            </div>
                                        </div>
                                    )}
                                    <table className="indicator-horizontal-table">
                                        <tr className="day">
                                            <td className="tr-gray">MES</td>
                                            <td className="indicator-subtotal">%</td>
                                            <td className="indicator-subtotal">Total</td>
                                            {this.props.data[0].data_indicators.map((data, index) => (

                                                <td style={{ background: index == (new Date().getMonth()) ? "#3e69d8" : "whitesmoke" }} className="td td-info">
                                                    <div style={{ background: index == (new Date().getMonth()) ? "#3e69d8" : "whitesmoke", color: index == (new Date().getMonth()) ? "white" : "#1ca7f9" }} className="link-line" onClick={() => this.updateFielsHorizontalMonth(index, data.id, data.indicator_id)} >{data.month_text}</div>
                                                    {index == this.state.indexHorizontalMonth && (
                                                        <div className="indicators-check-vertical-seg">
                                                            <React.Fragment>
                                                                <i
                                                                    className="fas fa-check-circle true-btn ml-1"
                                                                    onClick={() => this.HandleClickUpdateHorizontalMonth()}
                                                                    style={{ marginRight: "5px" }}
                                                                >
                                                                </i>
                                                                <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexHorizontalMonth: 260 })}></i>
                                                            </React.Fragment>
                                                        </div>
                                                    )}
                                                </td>
                                            ))}


                                        </tr>
                                        {this.props.data.filter((data) => data.total_column === false).sort((a, b) => b.accumulated - a.accumulated).map((data, indexPM) => (
                                            <tr className="day">
                                                {indexPM == this.state.indexMonth && (
                                                    <div className="indicators-checks">

                                                        <React.Fragment>
                                                            <i
                                                                className="fas fa-check-circle true-btn ml-1"
                                                                onClick={() => this.HandleClickUpdateMonth()}
                                                                style={{ marginRight: "5px" }}
                                                            >
                                                            </i>
                                                            <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexMonth: 260 })}></i>
                                                        </React.Fragment>
                                                    </div>
                                                )}
                                                <td className="tr" /* onClick={() => this.updateFielsMonth(indexPM, data.id, data.indicator_id)} */>
                                                    {this.state.updateName == data.id ? (
                                                        <div>
                                                            <input
                                                                name="name"
                                                                value={this.state.formUpdateVariable.name}
                                                                onChange={this.HandleChangeUpdateVariable}
                                                                className="form form-control"
                                                            />
                                                            <br />
                                                            <button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => this.updateVariable()}>Actualizar</button>
                                                            <button className="btn btn-outline-danger" style={{ marginRight: "10px" }} onClick={() => this.cancelEdit()}>Cancelar</button>

                                                        </div>
                                                    ) : (
                                                            <div className="name-variable">
                                                                <div>{data.name}</div>
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right text-center">
                                                                        <DropdownItem className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => this.editVariable(data.id, data.name)}>Editar</DropdownItem>
                                                                        <DropdownItem className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => this.deleteVariable(data.id)}>Eliminar</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                        )}
                                                </td>
                                                <td className="">

                                                    <p>  <b>  {data.percentage}%</b></p>


                                                </td>
                                                <td className="">
                                                    {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                        <b> <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.accumulated} /></p></b>
                                                    ) : (
                                                            <p>  <b> {data.accumulated}</b></p>
                                                        )}

                                                </td>

                                                {data.data_indicators.map((data, indexPMC) => (
                                                    <td className="td">
                                                        {this.getInputMonth(indexPM, indexPMC, data.real)}

                                                    </td>
                                                ))}
                                            </tr>
                                        ))}

                                        <tr className="day">
                                            <td className="tr" style={{ background: "#00aff3" }} >TOTAL</td>
                                            <td className="indicator-subtotal">

                                                <p>    {this.props.data.filter((data) => data.total_column === true)[0].percentage}%</p>

                                            </td>
                                            <td className="indicator-subtotal">
                                                {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                    <p>   <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.props.data.filter((data) => data.total_column === true)[0].accumulated} /></p>
                                                ) : (
                                                        <p>    {this.props.data.filter((data) => data.total_column === true)[0].accumulated}</p>
                                                    )}


                                            </td>
                                            {this.props.data.filter((data) => data.total_column === true)[0].data_indicators.map((data, index) => (

                                                <td className="indicator-subtotal">
                                                    {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                        <p> <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.real} /></p>
                                                    ) : (
                                                            <p>{data.real}</p>
                                                        )}


                                                </td>
                                            ))}


                                        </tr>
                                        {/*                                         <div className="day">
                                            <div className="tr" >ACUMULADO</div>
                                            {this.props.data.filter((data) => data.total_column === true)[0].data_indicators.map((data, index) => (
                                                <div className="td">

                                                    <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.real != 0 ? data.accumulated : 0} /></p>

                                                </div>
                                            ))}
                                            <div className="indicator-subtotal"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.props.data.filter((data) => data.total_column === true)[0].accumulated} />
                                            </div>
                                        </div> */}


                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                )}


            </React.Fragment>
        );
    }
}

export default layoutTableIndicator;



