import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from './FormCreate'
import FormCreateFiles from '../../../GeneralComponents/FormConfigurationFile'
import Loading from '../../../GeneralComponents/LoadingComponen'
import FormFilterName from '../../../GeneralComponents/FormFilterName';
import { isMobile } from 'react-device-detect';
import Pagination from "react-js-pagination";
import IndexMovil from "./Mobile/Index";

class Table extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showFilter: false,
            modal: false,
            ErrorValues: true,
            modeEdit: false,
            modaFiles: false,
            isLoadedFiles: false,
            isLoaded: false,
            id: "",

            form: {
                name: "",
                description: "",
                proceso_type: "",
                prefix: "",
            },

            formUpdate: {
                name: "",
                description: "",
                prefix: "",
            },

            module_id: "",
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            id: "",
            form: {
                name: "",
                description: "",
                proceso_type: "",
                prefix: "",
            }
        })
    }

    validationForm = () => {
        if (this.state.form.name != "" &&
            this.state.form.proceso_type != "" &&
            this.state.form.prefix != "" 
        ) {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false, ErrorValues: true })
        }
    }

    HandleClick = () => {
        if (this.validationForm() == true) {
            this.setState({ isLoaded: true })
            if(this.state.modeEdit){
                fetch(`/settings/procesos/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if (data.type != "error"){
                        this.clearValues();
                        this.props.loadData();
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                    }else{
                        this.clearValues();
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                    }
                });
            }else{
                fetch("/settings/procesos", {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if (data.type != "error"){
                        this.clearValues();
                        this.props.loadData();
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                    }else{
                        this.clearValues();
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                    }
                });
            }
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/procesos/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(response => response.json())
                .then(response => {
                    if (response.type != "error"){
                        this.props.loadData()
                        this.messageSuccess(response)
                    }else{
                        this.messageSuccess(response);
                    }
                });
            }
        });
    };

    //Update section

    edit = (proceso) => {
        this.setState({
            id: proceso.id,
            modal: true,
            modeEdit: true,
            form: {
                name: proceso.name,
                description: proceso.description,
                proceso_type: proceso.proceso_type,
                prefix: proceso.prefix,
            }
        })
    }

    //files 

    addFiles = (position) => {
        this.setState({
            module_id: position.id,
            modaFiles: true,
        })
    }

    toogleFiles = (from) => {
        if (from == "new") {
            this.setState({ modaFiles: true })
            this.clearValues();
        } else {
            this.setState({ modaFiles: false })
        }
    }

    showFilter = () => {
        this.setState({
            showFilter: true
        })

    }

    cancelFilter = () => {
        this.props.loadData();
        this.props.clearValues();
        this.setState({
            showFilter: false
        })
    }

    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={"Procesos"}
                    subTitle={"Gestión de procesos"}
                    titleNew={"Nuevo proceso"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    onClickFilter={this.showFilter}
                    showFilter={true}
                    
                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {/* FormCreateFiles */}

                {this.state.modaFiles && (
                    <FormCreateFiles
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modaFiles}
                        toggle={this.toogleFiles}
                        title={"Agregar Archivos"}

                        loadDataTable={this.props.loadData}
                        nameModule={"Procesos"}
                        module_id={this.state.module_id}
                    />
                )}

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar proceso" : "Agregar nuevo proceso"}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}

                        //form props
                        formValues={this.state.form}
                        errorValues={this.state.ErrorValues}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        tenant={this.props.tenant}
                        isLoaded={this.state.isLoaded}
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        {this.state.showFilter && (
                            <FormFilterName
                                handleChangeFilter={this.props.handleChangeFilter}
                                handleClickFilter={this.props.handleClickFilter}
                                showbtnSubmit={this.props.showbtnSubmit}
                                clearValues={this.cancelFilter}
                                formFilter={this.props.formFilter}
                            />
                        )}

                        {!isMobile ? (
                            <React.Fragment>
                                <div className="content main-card -mb-3 card card-style">
                                    <div className="card-body ui-tables_layout-register-card-body">
                                        <div className='ui-tables_layout-table__container'>
                                            <table className="table ui-tables_layout-table-header__container" id="sampleTable">
                                                {this.props.data.length >= 1 ? (
                                                    <React.Fragment>
                                                        <thead className='ui-tables_layout-table-header'>
                                                            <tr className='ui-tables_layout-__container-title-table'>
                                                                <th style={{width: '70px'}}>Prefijo</th>
                                                                <th>Proceso</th>
                                                                <th>Tipo de Proceso</th>
                                                                <th>Descripción</th>
                                                                {(this.props.estados.editar || this.props.estados.eliminar) &&(
                                                                    <th className="text-center"  style={{ width: "8%" }}>Opciones</th>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody className='ui-tables_layout-data__container'>
                                                            {this.props.data.map(procesos => (
                                                                <tr key={procesos.id}>
                                                                    <td className="text-center">{procesos.prefix}</td>
                                                                    <td>{procesos.name}</td>
                                                                    <td>{procesos.proceso_type}</td>
                                                                    <td>{procesos.description != '' ? procesos.description : 'Este proceso no tiene descripción'}</td>
                                                                    {(this.props.estados.editar || this.props.estados.eliminar) &&(
                                                                        <td className="text-center" style={{ width: "10px" }}>
                                                                            <UncontrolledDropdown className="btn-group ui-tableIndexWeb-positions-dropdown__container">
                                                                                <DropdownToggle>
                                                                                    <i className="fas fa-bars"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className="ui-tables_layout-dropdown_header dropdown-menu-right">
                                                                                    {this.props.estados.editar && (
                                                                                        <DropdownItem
                                                                                            onClick={() => this.edit(procesos)}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            Editar
                                                                                        </DropdownItem>
                                                                                    )}
                                                                                    {this.props.estados.eliminar && (  
                                                                                        <DropdownItem
                                                                                            onClick={() => this.delete(procesos.id)}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            Eliminar
                                                                                        </DropdownItem>
                                                                                    )}
                                                                                </DropdownMenu>                                                                            
                                                                            </UncontrolledDropdown>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </React.Fragment>
                                                ) : (
                                                    <div className='card col-md-12'>
                                                        <div className="text-center">
                                                            <div className="text-center mt-4 mb-4" style={{width: '100%'}}>
                                                                <p className='ui-settingProcess-messageNoRecord'>Todavía no hay registros creados, da clic en <a onClick={() => this.toogle('new')} className="btn btn-info">Nuevo proceso</a> y genera el primer registro.</p>
                                                                <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/8/noRecord_strategy.jpg" alt="" className="ui-settingProcess-imgNoRecord" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <IndexMovil
                                data={this.props.data}
                                estados={this.props.estados}
                                edit={this.edit}
                                delete={this.delete}
                            />
                        )}

                        {true && (
                            <div className="row mt-3">

                                <div className="col-md-2 text-left">
                                    <p>
                                        Mostrando {this.props.data.length} de {this.props.totalData}
                                    </p>
                                </div>

                                <div className="col-md-10 pl-0">
                                    <Pagination
                                        hideNavigation
                                        activePage={this.props.activePage}
                                        itemsCountPerPage={this.props.countPage}
                                        itemClass="page-item"
                                        innerClass="pagination"
                                        linkClass="page-link"
                                        totalItemsCount={this.props.totalData}
                                        pageRangeDisplayed={this.props.countPage}
                                        onChange={this.props.handlePageChange}
                                    />
                                </div>

                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

export default Table;