import React, { Component } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
// import esLocale from '@fullcalendar/core/locales/es';
// must manually import the stylesheets for each plugin
// import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import ShowTask from '../../tasks/tasksCard/ShowTask'
import FormCreateModal from "../../tasks/tasksCard/FormCreateModal";
import esLocale from '@fullcalendar/core/locales/es';

class Calendar extends Component {
    token = document.querySelector("[name='csrf-token']").content;
    state = {
        canDrop: true, 
        calendarWeekends: true,
        task: {},
        modalShow: false,
        modal: false,
        ErrorValues: true,
        calendarEvents: [],
        arg: "",

        form: {
            name: "",
            due_date: "",
            user_owner_id: "",
        },

        showColums: "Pendientes",

        selectedOption: {
            user_owner_id: "",
            label: "Responsable"
        },

        selectedOptionTaskBoard: {
            task_board_id: "",
            label: ""
        },

        selectedOptionColumn: {
            column_id: "",
            label: ""
        },

        users: [],
        columns: [],
    };
    

    setCanDrop = () => {};
    calendarComponentRef = React.createRef();

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            calendarEvents: nextProps.data
        })
    }

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                due_date: "",
                user_owner_id: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },
            modal: false,
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
                name: e.target.name == "name" ? (e.target.value.length + 0 > 200 ? this.state.form.name : e.target.value) : this.state.form.name,
            }
        });
    }

    componentDidMount = () => {
        let array = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array
        })
    }


    handleChangeAutocomplete = selectedOption => {
        this.setState({
          selectedOption,
          form: {
             ...this.state.form,
             user_owner_id: selectedOption.value
          }
        });
    };

    toggleWeekends = () => {
        this.setState({
            // update a property
            calendarWeekends: !this.state.calendarWeekends
        });
    };

    gotoPast = () => {
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
    };

    getDateOpenModal = (register_date) => {
        let date = new Date(register_date)
        let date_month = ("0" + (date.getMonth() + 1)).slice(-2)
        let day = ("0" + (date.getDate() + 1)).slice(-2)
        let new_date = `${date.getFullYear()}-${date_month}-${day}`
        return new_date
    }

    handleDateClick = arg => {
        const start_date = `${arg.dateStr}`

        this.setState({
            modal: true,
            arg: arg,

            form: {
                ...this.state.form,
                due_date: this.getDateOpenModal(start_date),
            },
        });

        /*
            // add new event data
            calendarEvents: this.state.calendarEvents.concat({
              // creates a new array
              title: "New Event",
              start: arg.date,
              allDay: arg.allDay
            })
        */
    };

    updateDate = (task_id, date) => {
        fetch(`/task/update_date_calendar/${task_id}/${date}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {

        });
    }

    handleClickShow = (id) => {
        fetch(`/task/get_show_task/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                task: data.task,
                modalShow: true,
            })
        });
    }

    handleClickCreate = () => {

    };


    eventDrop = (info) => {
        const task_id = info.event._def.publicId
        const { start, end } = info.oldEvent._instance.range;
        const { start: newStart, end: newEnd } = info.event._instance.range;
        const date = `${newStart.getFullYear()}-${newStart.getMonth() + 1}-${newStart.getDate() + 1}`
        this.updateDate(task_id, date)

        if (new Date(start).getDate() === new Date(newStart).getDate()) {
            info.revert();
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modalShow: true })
        } else {
            this.setState({ modalShow: false, task: {} })
        }
    }

    toogleNew = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
        }
    }

    validationForm = () => {
        if (this.state.form.name != "" &&  
            this.state.form.due_date != "" && 
            this.state.form.user_owner_id != "" 
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClickCreateTask = () => {
        if(this.validationForm() == true){
            fetch(`/task/tasks`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.setState({
                    calendarEvents: this.state.calendarEvents.concat({
                        // creates a new array
                        id: data.task.id,
                        title: data.task.name,
                        start: this.state.arg.date,
                    })
                })
            });
        }
    }

    handleChangeAutocompleteColumn = (selectedOptionColumn) => {
        this.setState({
            selectedOptionColumn,
                form: {
                    ...this.state.form,
                    column_id: selectedOptionColumn.value
                }
        });
    }

    handleChangeAutocompleteTaskBoard = selectedOptionTaskBoard => {
        fetch(`/task/get_task_board_columns/${selectedOptionTaskBoard.value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrayColumns = [];

            data.columns.map((item) => (
                arrayColumns.push({ label: item.title, value: item.id })
            ))
    
            this.setState({
                selectedOptionTaskBoard,
                columns: arrayColumns,
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        {this.state.modalShow && (
                            <ShowTask 
                                //modal props
                                backdrop={"static"}
                                modal={this.state.modalShow}
                                toggle={this.toogle}
                                title={"Crear tarea"}
        
                                //form props
                                task={this.state.task}
        
                                loadTasks={this.loadDataShow}
                                users={this.props.users}
        
                                //load show
                                isLoadedShow={false}
        
                                //current_user
                                user={this.props.user}
        
                                //load tasks
                                loadData={this.props.loadData}
        
                                //test load data
        
                                updateItem={this.props.updateItem}
                                updateData={this.props.updateData}
        
                                //procesos 
        
                                procesos={this.props.procesos}
                                company={this.props.company}
                                imageUpload={this.props.imageUpload}
                                key_text={this.props.key_text}
                            />
                        )}

                        {this.state.modal && (
                            <FormCreateModal
                                //modal props
                                backdrop={"static"}
                                modal={this.state.modal}
                                toggle={this.toogleNew}
                                title={"Crear tarea"}

                                //form props
                                HandleChange={this.HandleChange}
                                form={this.state.form}
                                clearValues={this.clearValues}
                                loadData={this.props.loadData}
                                updateStateLoad={this.props.updateStateLoad}
                                HandleClickCreateTask={this.HandleClickCreateTask}
                                selectColumn={true}

                                //select autocomplete
                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                selectedOption={this.state.selectedOption}
                                users={this.props.users}

                                //select autocomplete columns
                                handleChangeAutocompleteColumn={this.handleChangeAutocompleteColumn}
                                selectedOptionColumn={this.state.selectedOptionColumn}
                                columns={this.state.columns}

                                ErrorValues={this.state.ErrorValues}

                                handleChangeAutocompleteTaskBoard={this.handleChangeAutocompleteTaskBoard}
                                task_boards={this.props.task_boards}
                                selectedOptionTaskBoard={this.state.selectedOptionTaskBoard}
                            />
                        )}

                        <FullCalendar
                            editable={true}
                            defaultView="timeGridWeek"
                            locale={esLocale}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            ref={this.calendarComponentRef}
                            weekends={this.state.calendarWeekends}
                            events={this.state.calendarEvents}
                            dateClick={this.handleDateClick}

                            
                            eventColor={'#3f69d7'}
                            eventClick={(item) => this.handleClickShow(item.event._def.publicId)}
                            eventDrop={info => { this.eventDrop(info) }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Calendar;
