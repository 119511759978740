import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'

class Table extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            formUpdate: {
                name: "",
                description: ""
            }
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                description: ""
            }
        })
    }


    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.value) {
            fetch("/settings/rols/" + id, {
              method: "delete",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.props.loadData()
                this.messageSuccess(response)
              });
          }
        });
    };

    //Update section

    edit = (rol) => {
        this.setState({
            id: rol.id,
            formUpdate: {
                name: rol.name,
                description: rol.description
            }
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
              ...this.state.formUpdate,
              [e.target.name]: e.target.value
            }
        });
    }

    HandleClickUpdate = () => {
        fetch(`/settings/rols/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.clearValues();
            this.setState({ id: ""})
            this.messageSuccess(data);
        });
    }

    new = ()=>{
        Turbolinks.visit("/settings/rols/new", "_self");
    }


    render() {
        return (
            <React.Fragment>

                <HeaderTitle 
                    title={"Roles Administrativos"} 
                    subTitle={"Gestión de roles"}
                    titleNew={"Nuevo rol"}
                    titleFilter={"Filtros"}
                    icon={"fas fa-users-cog"}
                    onClickNew={this.new}
                    showNew={this.props.estados.crear}
                    showFilter={false}
                />

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                        <thead>
                            <tr>
                                <th style={{width:"20%"}}>Nombre</th>
                                <th style={{width:"75%"}}>Descripción</th>
                                {this.state.id != "" &&(
                                    <th className="text-center">Actualización</th>
                                )}
                                {(this.props.estados.editar == true || this.props.estados.eliminar == true) &&(
                                    <th className="text-center"></th>
                                )}
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.data.length >= 1 ? (
                                this.props.data.map(rol => (
                                    <tr key={rol.id}>
                                        <td>
                                            {rol.id == this.state.id ? (
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={this.state.formUpdate.name}
                                                    onChange={this.HandleChangeUpdate}
                                                    className="form form-control"
                                                />
                                            ) : (
                                                <p>{rol.name}</p>
                                            )}
                                        </td>
                                        <td>
                                            {rol.id == this.state.id ? (
                                                <textarea
                                                    type="text"
                                                    name="description"
                                                    value={this.state.formUpdate.description}
                                                    onChange={this.HandleChangeUpdate}
                                                    className="form form-control"
                                                    style={{ resize: "none"}}
                                                />
                                            ) : (
                                                <p>{rol.description}</p>
                                            )}
                                        </td>

                                        {this.state.id != "" &&(
                                            <td className="text-center">
                                                {rol.id == this.state.id &&(
                                                    <React.Fragment>
                                                        <i className="fas fa-check-circle true-btn" onClick={() => this.HandleClickUpdate()}></i> 
                                                        <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ id: ""})}></i>
                                                    </React.Fragment>
                                                )} 
                                            </td>
                                        )}

                                        {rol.name != "Administrador" && (this.props.estados.editar == true || this.props.estados.eliminar == true) && (
                                            <td className="text-right" style={{ width: "10px" }}>
                                                <UncontrolledDropdown className='btn-group'>
                                                    <DropdownToggle className='btn btn-info'>
                                                        <i className="fas fa-bars"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                        {this.props.estados.editar == true && (
                                                            <DropdownItem
                                                                href={`/settings/rols/${rol.name}/edit`}
                                                                data-turbolinks="true"
                                                                className="dropdown-item"
                                                            >
                                                                Editar 
                                                            </DropdownItem>
                                                        )}

                                                        {this.props.estados.eliminar == true && (  
                                                            <DropdownItem
                                                                onClick={() => this.delete(rol.id)}
                                                                className="dropdown-item"
                                                            >
                                                                Eliminar
                                                            </DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        )}
                                    </tr>
                            ))
                                ) : (
                                <tr>
                                <td colSpan="8" className="text-center">
                                    <div className="text-center mt-4 mb-4">
                                    <h4>No hay registros con el criterio de búsqueda</h4>
                                    </div>
                                </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Table;