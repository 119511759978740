import React, { useState, useEffect } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

const multipleDateSelect = (props) => {
    const handleChangeFirstDate = (e) => {
        props.handleChangeFirstDate(e);
        setPopoverFirstDate(!PopoverFirstDate);
    }

    const handleChangeSecondDate = (e) => {
        props.handleChangeSecodDate(e);
        setPopoverSecondDate(!PopoverSecondDate);
    }

    return (
        <div className="col-md-3">
            <div>
                <div>
                    <label>{props.title}</label>
                </div>

                <div className='row'>
                    <div className="col-md-6">
                        <input
                            type="date"
                            className="form form-control ui-multiple-date_select-input"
                            onChange={handleChangeFirstDate}
                            value={props.value.date1}
                        />
                    </div>

                    <div className="col-md-6">
                        <input
                            type="date"
                            className="form form-control ui-multiple-date_select-input"
                            onChange={handleChangeSecondDate}
                            value={props.value.date2}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default multipleDateSelect;
