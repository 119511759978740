import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './FormCreate'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;

        this.state = {
            modal: false,
            ErrorValues: true,
            ShowFormModule: true,
            isLoadedModuleAccion: false,
            position2: 0,

            id: "",

            form: {
                name: "",
                description: "",
            },

            formUpdate: {
                name: "",
                description: "",
                position: "",
            },

        }

    }

    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                description: "",
            },
        })
    }

    clearValuesUpdate = () => {
        this.setState({
            formUpdate: {
                name: "",
                description: "",
                position: "",
            },

            id: "",
            position2: 0,
        })
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        }else{
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
              ...this.state.form,
              [e.target.name]: e.target.value
            }
        });
    }

    toogleModalCategory = (from) => {
        if(from == "new"){
            this.setState({ modal: true, ShowFormModule: true})
            this.clearValues();
        }else{
            this.setState({ modal: false})
        }
    }

    HandleClick = () => {
        if(this.validationForm() == true){
            fetch("/settings/section_categories", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.props.updateDataCategory(data.register)
                this.setState({ modal: false})
                this.messageSuccess(data);
            });
        }
    }


    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.value) {
            fetch("/settings/section_categories/" + id, {
              method: "delete",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.props.loadData()
                this.messageSuccess(response)
              });
          }
        });
    };

    //Update section

    edit = (section_category) => {
        this.setState({
            id: section_category.id,
            formUpdate: {
                name: section_category.name,
                description: section_category.description,
                position: section_category.position,
            }
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
              ...this.state.formUpdate,
              [e.target.name]: e.target.value
            }
        });
    }

    HandleChangePosition = (e) => {
        this.setState({
            position2: e.target.value,
        });
    }

    HandleClickUpdate = () => {
        fetch(`/settings/section_categories/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateItemCategory(data.register);
            this.clearValues();
            this.setState({ id: ""})
            this.messageSuccess(data);
        });
    }

    update_position = (e) => {
        fetch(`/settings/update_position_section_category/${this.state.id}/${this.state.formUpdate.position}/${e.target.value}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.clearValuesUpdate();
        });
    }

    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogleModalCategory}
                        title={"Crear categoría"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                    />
                )}

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                        <thead>
                            <tr>
                                <th style={{ width: "8%" }}>#</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th className="text-center">Opciones</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.data.length >= 1 ? (
                                this.props.data.map(section => (
                                    <tr key={section.id}>
                                        <td>
                                            {section.id == this.state.id ? (
                                                <select
                                                    type="text"
                                                    name="position"
                                                    value={this.state.formUpdate.position}
                                                    onChange={(e) => this.update_position(e)}
                                                    className="form form-control"
                                                >

                                                    {this.props.data.map(item => (
                                                        <React.Fragment>
                                                            <option value={item.position}>{item.position}</option>
                                                        </React.Fragment>
                                                    ))}
                                                </select>
                                            ) : (
                                                <p>{section.position}</p>
                                            )}
                                        </td>

                                        <td>
                                            {section.id == this.state.id ? (
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={this.state.formUpdate.name}
                                                    onChange={this.HandleChangeUpdate}
                                                    className="form form-control"
                                                />
                                            ) : (
                                                <p>{section.name}</p>
                                            )}
                                        </td>

                                        <td>
                                            {section.id == this.state.id ? (
                                                <input
                                                    type="text"
                                                    name="description"
                                                    value={this.state.formUpdate.description}
                                                    onChange={this.HandleChangeUpdate}
                                                    className="form form-control"
                                                />
                                            ) : (
                                                <p>{section.description}</p>
                                            )}
                                        </td>

                                        <td className="text-right" style={{ width: "10px" }}>
                                            {this.state.id != "" ? (
                                                <React.Fragment>
                                                    {section.id == this.state.id &&(
                                                        <React.Fragment>
                                                            <i className="fas fa-check-circle update-section" onClick={() => this.HandleClickUpdate()}></i> 
                                                            <i className="fas fa-times-circle update-false" onClick={() => this.setState({ id: ""})}></i>
                                                        </React.Fragment>
                                                    )} 
                                                </React.Fragment>
                                            ) : (
                                                <UncontrolledDropdown className='btn-group' direction="left">
                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                        <i className="fas fa-bars"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                        {true && (
                                                            <DropdownItem
                                                                onClick={() => this.edit(section)}
                                                                className="dropdown-item"
                                                            >
                                                                Editar
                                                            </DropdownItem>
                                                        )}

                                                        {true && (
                                                            <DropdownItem
                                                                onClick={() => this.delete(section.id)}
                                                                className="dropdown-item"
                                                            >
                                                                Eliminar
                                                            </DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            )}

                                        </td>
                                    </tr>
                            ))
                                ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        <div className="text-center mt-4 mb-4">
                                            <h4>No hay registros con el criterio de búsqueda</h4>
                                        </div>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;

