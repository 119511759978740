import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ModalUpdateDocuments from './ModalUpdateDocuments';

class Index extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;

        this.state = {
            modal: false,
            modeEdit: false,
            ErrorValues: true,
            isLoadedCreate: false,
            
            showInput: false,
            modalUpdateDocument: false,
            document_type_id: "",
            id: "",

            formCreate: {
                name: "",
                prefix: "",
                code: "",
                has_process: false,
                has_header: false,
                has_footer: false,
                is_format: false,
                content_format_id: "",
                header: "",
                footer: "",
            },

            formCode: {
                code: "",
            },

            selectedOption: {
                content_format_id: "",
                label: "Formatos"
            },


            options: ["{TIPO}-{CONS}", "{CONS}-{TIPO}"],
            //options: ["{PRO}-{TIPO}-{CONS}", "{PRO}-{CONS}-{TIPO}", "{TIPO}-{PRO}-{CONS}", "{TIPO}-{CONS}-{PRO}", "{CONS}-{PRO}-{TIPO}", "{CONS}-{TIPO}-{PRO}", "PERSONALIZADO"],
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
            this.clearValues()
        }
    }

    toogleUpdateDocuments = (from) => {
        this.setState({ 
            modalUpdateDocument: false,
            document_type_id: ""
        })
    }

    validationForm = () => {
        if (this.state.formCreate.name != "" &&
            this.state.formCreate.code != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/document_management/document_types/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,
            showInput: false,

            formCreate: {
                name: "",
                prefix: "",
                code: "",
                has_process: false,
                has_header: false,
                has_footer: false,
                is_format: false,
                content_format_id: "",
                header: "",
                footer: "",
            },

            formCode: {
                code: "",
            },

            selectedOption: {
                content_format_id: "",
                label: "Formatos"
            },

            options: ["{TIPO}-{CONS}", "{CONS}-{TIPO}"],
        })
    }

    HandleClick = () => {
        if (this.validationForm()) {
            this.setState({ isLoadedCreate: true })

            if (!this.state.modeEdit)
                fetch(`/document_management/document_types`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoadedCreate: false })
                        this.messageSuccess(data);
                        this.props.updateData(data.register);
                        this.clearValues();
                    });
            else {
                fetch(`/document_management/document_types/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoadedCreate: false })
                        this.messageSuccess(data);
                        this.props.updateItem(data.register);
                        this.clearValues();
                    });
            }
        }
    }

    HandleChange = (e) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChangeSelectCode = (e) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [e.target.name]: e.target.value
            }
        })
    }

    handleChangeChecked = (name, e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;

        console.log("handleChangeChecked handleChangeCheckedhandleChangeChecked", name);

        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [name]: isChecked
            }
        })

        if (name == "has_process") {
            this.setState({
                formCode: {
                    ...this.state.formCode,
                    code: ""
                },

                formCreate: {
                    ...this.state.formCreate,
                    code: "",
                    [name]: isChecked,
                },

                showInput: false,
                options: isChecked ? ["{PRO}-{TIPO}-{CONS}", "{PRO}-{CONS}-{TIPO}", "{TIPO}-{PRO}-{CONS}", "{TIPO}-{CONS}-{PRO}", "{CONS}-{PRO}-{TIPO}", "{CONS}-{TIPO}-{PRO}", "PERSONALIZADO"] : ["{TIPO}-{CONS}", "{CONS}-{TIPO}"]
            })
        }
    }

    edit = (document_type) => {
        this.setState({
            modeEdit: true,
            modal: true,
            id: document_type.id,

            formCreate: {
                ...this.state.formCreate,
                name: document_type.name,
                prefix: document_type.prefix,
                code: document_type.code,
                has_process: document_type.has_process,
                content_format_id: document_type.content_format_id,
                header: document_type.header,
                footer: document_type.footer,
                has_header: document_type.has_header,
                has_footer: document_type.has_footer,
                is_format: document_type.is_format,
            },

            selectedOption: {
                content_format_id: `${document_type.content_format != null ? document_type.content_format.id : ""}`,
                label: `${document_type.content_format != null ? document_type.content_format.name : "Formato"}`,
            },

            options: document_type.has_process ? ["{PRO}-{TIPO}-{CONS}", "{PRO}-{CONS}-{TIPO}", "{TIPO}-{PRO}-{CONS}", "{TIPO}-{CONS}-{PRO}", "{CONS}-{PRO}-{TIPO}", "{CONS}-{TIPO}-{PRO}", "PERSONALIZADO"] : ["{TIPO}-{CONS}", "{CONS}-{TIPO}"]
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            formCreate: {
                ...this.state.formCreate,
                content_format_id: selectedOption.value
            }
        });
    };

    handleChangeFroala = (name, e) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [name]: e
            }
        });
    }


    handleChangeFieldCode = (e) => {
        this.setState({
            formCode: {
                ...this.state.formCode,
                code: e.target.value
            },


            formCreate: {
                ...this.state.formCreate,
                code: (e.target.value == "PERSONALIZADO" ? "" : e.target.value)
            },

            showInput: (e.target.value == "PERSONALIZADO" ? true : false)
        })
    }


    regenerateCode = (document_type_id) => {
        fetch(`/document_management/regenerate_code/${document_type_id}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            if (data.showModal){
                this.setState({
                    modalUpdateDocument: data.showModal,
                    document_type_id: document_type_id
                })
            }else{
                this.messageSuccess(data)
            }
        });
    }

    render() {
        return (
            <React.Fragment>

                {this.state.modalUpdateDocument && (
                    <ModalUpdateDocuments
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalUpdateDocument}
                        toggle={this.toogleUpdateDocuments}
                        title={"Actualizar documentos que no tienen procesos"}
                        nameBnt={null}

                        document_type_id={this.state.document_type_id}
                        procesos={this.props.procesos}
                    />
                )}

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar tipo de documento" : "Crear tipo de documento"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}
                        isLoadedCreate={this.state.isLoadedCreate}

                        //form props
                        formValues={this.state.formCreate}
                        formCode={this.state.formCode}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        errorValues={this.state.ErrorValues}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}

                        handleChangeFroala={this.handleChangeFroala}
                        handleChangeChecked={this.handleChangeChecked}

                        HandleChangeSelectCode={this.HandleChangeSelectCode}
                        options={this.state.options}
                        onChangeFieldCode={this.handleChangeFieldCode}
                        showInput={this.state.showInput}

                        //select values

                        //select Format
                        selectedOption={this.state.selectedOption}
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        formats={this.props.formats}
                    />
                )}

                <div className="content main-card mb-3 card card-style">
                    <div className="card-body ui-tables_layout-register-card-body">
                        <div className="ui-tables_layout-table__containerZ">
                            {this.props.data.length >= 1 ? (
                                <table className="table ui-tables_layout-table-header__container bw_tables_layout_fixed_columns" id="sampleTable">
                                    <thead className='ui-tables_layout-table-header'>
                                        <tr className='ui-tables_layout-__container-title-table'>
                                            <th>Nombre</th>
                                            <th>Prefijo</th>
                                            <th>Código</th>
                                            <th>¿Pertenece a un proceso?</th>
                                            {/*<th>Formato Asociado</th>*/}
                                            {(this.props.estados.delete || this.props.estados.edit) && (
                                                <th className="text-center th-accions">Opciones</th>
                                            )}
                                        </tr>
                                    </thead>

                                    <tbody className='ui-tables_layout-data__container'>
                                        {this.props.data.map(document_type => (
                                            <tr key={document_type.id}>
                                                <td>{document_type.name}</td>
                                                <td>{document_type.prefix}</td>
                                                <td>{document_type.code}</td>
                                                <td>{document_type.has_process ? "Si" : "No"}</td>
                                                {/*<td>{document_type.content_format ? document_type.content_format.name : "" }</td>*/}
                                                {(this.props.estados.edit || this.props.estados.delete) && (
                                                    <td className="text-center" style={{ width: "10px" }}>
                                                        <UncontrolledDropdown className='btn-group ui-tableIndexWeb-positions-dropdown__container'>
                                                            <DropdownToggle>
                                                                <i className="fas fa-bars"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="ui-tableIndexWeb-positions-dropdown_header dropdown-menu-right">
                                                                {true && (
                                                                    <DropdownItem
                                                                        onClick={() => this.regenerateCode(document_type.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Actualizar codigos
                                                                    </DropdownItem>
                                                                )}

                                                                {this.props.estados.edit && (
                                                                    <DropdownItem
                                                                        onClick={() => this.edit(document_type)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </DropdownItem>
                                                                )}

                                                                {this.props.estados.delete && (
                                                                    <DropdownItem
                                                                        onClick={() => this.delete(document_type.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </DropdownItem>
                                                                )}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <td style={{display: 'flex', justifyContent: 'center'}}>
                                    <div className="text-center">
                                        <div className="text-center mt-4 mb-4" style={{ width: '100%' }}>
                                            <p className='ui-indexWeb-messageNoRecord'>Todavía no hay registros creados, da clic en <a onClick={() => this.toogle('new')} className=" btn btn-info" ><i className='fas fa-file-alt'></i> Nuevo</a> y genera el primer registro.</p>
                                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/9/noRecord_documental.jpg" alt="" className="ui-indexWeb-imgNoRecord" />
                                        </div>
                                    </div>
                                </td>
                            )}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Index;