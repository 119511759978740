import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            showTable: false,
            format_pdf_id: "",

            form: {
                format_id: this.props.format.id,
                name: "",
                content: "",
            },

        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
                if (format.id === item.id) {
                    return {
                        ...item,
                        name: format.name,
                        content: format.content
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,

            form: {
                ...this.state.form,
                name: "",
                content: "",
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_pdfs/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/formatos/get_format_pdfs/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    isLoaded: false
                });
            });
    }

    HandleClick = () => {
        if (this.state.modeEdit) {
            fetch(`/formatos/format_pdfs/${this.state.format_pdf_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateItem(data.register)
                    this.clearValues();
                    this.setState({ modal: false })
                });
        } else {
            fetch(`/formatos/format_pdfs`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateData(data.register)
                    this.clearValues();
                    this.setState({ modal: false, showTable: true })
                });
        }
    }


    edit = (format_pdf) => {
        this.setState({
            modal: true,
            modeEdit: true,
            format_pdf_id: format_pdf.id,
            form: {
                ...this.state.form,
                name: format_pdf.name,
                content: format_pdf.content,
            }
        })
    }

    HandleChangeCkaditor = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                content: e
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <ModalBody>

                        {this.state.modal && (
                            <FormCreate
                                backdrop={"static"}
                                modal={this.state.modal}
                                toggle={this.toogle}
                                title={this.state.modeEdit ? "Actualizar PDF" : "Crear PDF"}
                                nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                                //form props
                                formValues={this.state.form}
                                onChangeForm={this.HandleChange}
                                submitForm={this.HandleClick}
                                HandleChangeCkaditor={this.HandleChangeCkaditor}
                            />
                        )}

                        <div className="col-md-12 mb-3 text-right pr-0">
                            {true && (
                                <a
                                    className="btn-shadow btn btn-info"
                                    href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/format_pdfs`}
                                    data-turbolinks="true"
                                >
                                    Crear pdf
                                </a>
                            )}
                        </div>

                        <div className="card ui-index-formatPDF__container-card">
                            <div className="background-gray ui-index-formatPDF__container_gray">
                                    
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map((format_pdf, index) => (
                                                <React.Fragment>
                                                    <div key={format_pdf.id} className="ui-index-formatPDF__container_cards_pdf">
                                                        <div className='ui-index-formatPDF-cardinfo-pdf'>
                                                            <div className='ui-index-formatPDF-options'>
                                                                <UncontrolledTooltip target={`bw_delete_PDF${format_pdf.id}`}>{'Eliminar'}</UncontrolledTooltip>
                                                                <i className="fas fa-trash-alt" id={`bw_delete_PDF${format_pdf.id}`} onClick={() => this.delete(format_pdf.id)}></i>
                                                            </div>
                                                            <a className='ui-index-formatPDF-cardinfo-text__container'
                                                                href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/format_pdfs/${format_pdf.id}`}
                                                                data-turbolinks="true"
                                                                target="_blank">
                                                                    <i className="fas fa-file-pdf"></i>
                                                                    <UncontrolledTooltip target={`na${format_pdf.id}`} placement='top'>{format_pdf.name}</UncontrolledTooltip>
                                                                    <span id={`na${format_pdf.id}`}>{format_pdf.name}</span>
                                                                </a>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <div className="ui-index-formatPDF_no_pdf__container">
                                                <div className="ui-index-formatPDF_noPdf_image">
                                                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/39691/No_PDF.png" alt="No hay PDF" />
                                                </div>
                                                <div className="ui-index-formatPDF_text_no_pdf">
                                                    No hay PDF's creados
                                                </div>
                                            </div>
                                        )}
                                    
                                
                            </div>
                        </div>


                    </ModalBody>

                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;
