import React, { Component } from 'react';
import Preloader from '../../../../GeneralComponents/LoadingComponen'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import FormCreate from './FormCreate'
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreateCkeckList from '../../taskConfiguration/Labels/FormCreateCkeckList';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modalCkeckList: false,
            modeEditCheckList: false,
            modeEdit: false,
            isLoaded: true,
            id: "",
            data: [],
            label: {},
            form: {
                name: "", 
                color: "", 
                task_board_id: this.props.task_board.id,
            },

            formCreateCheckList: {
                name: "",
                color: "",
                task_board_id: this.props.task_board.id,
            },
        }
    }

    clearValues = () => {
        this.setState({
            id: "",
            modeEdit: false,
            form: {
                ...this.state.form,
                name: "",
                color: "",
            }
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/task/get_label_tasks/${this.props.task_board.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                isLoaded: false,
                data: data.data,
            });
        });
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (task_label) => {
        this.setState({
            data: this.state.data.map(item => {
            if (task_label.id === item.id) {
              return { ...item, 
                name: task_label.name,
                color: task_label.color,
              }
            }
            return item;
          })
        });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleClick = () => {
        if(!this.state.modeEdit)
            fetch(`/task/task_labels`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false, isLoaded: false });
                this.updateData(data.register);
                this.clearValues();
            });
        else{
            fetch(`/task/task_labels/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false, isLoaded: false });
                this.updateItem(data.register);
                this.clearValues();
            });
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/task/task_labels/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData();
                    this.messageSuccess(response);
                });
            }
        });
    };

    edit = (task_board) => {
        this.setState({
            modeEdit: true,
            modal: false,
            id: task_board.id,
            form: {
                ...this.state.form,
                name: task_board.name, 
                color: task_board.color, 
            }
        })
    }


    handleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        
    }

    handleChangeComplete = (color) => {
        this.setState({ 
            form: {
                ...this.state.form,
                color: color.hex 
            }
        });
    };

    toogleChecklist = (from, label) => {
        if (from == "new") {
            this.setState({ modalCkeckList: true, label: label })
        } else {
            this.setState({ modalCkeckList: false, label: "" })
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.modalCkeckList && (
                    <FormCreateCkeckList
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCkeckList}
                        toggle={this.toogleChecklist}
                        title={"Crear Checklist"}

                        //form props
                        formValues={this.state.formCreateCheckList}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClickCheckList}
                        label={this.state.label}

                        //users 

                        users={this.props.users}
                        user={this.props.user}
                        loadDataLabels={this.props.loadDataLabels}

                        updateDataCheckLists={this.props.updateDataCheckLists}
                        deleteCheckLists={this.props.deleteCheckLists}
                    />
                )}

                <div className="card mb-2 mt-2">
                    <div className="card-header">
                        <div className="row w-100">
                            <div className="col-md-8">
                                Etiquetas del tablero
                            </div>

                            <div className="col-md-4 pr-0 text-right">
                                {true && (
                                    <React.Fragment>
                                        <button
                                            className="btn-shadow btn btn-info"
                                            id={`PopoverCreate`}
                                            onClick={() => this.setState({ modal: true })}
                                        >
                                            <i className="fas fa-plus"></i> Añadir etiqueta
                                        </button>

                                        <UncontrolledPopover isOpen={this.state.modal} trigger="legacy" placement="left" target={`PopoverCreate`}>
                                            <PopoverBody className="popover-column">
                                                <div className="col-md-12 p-0" style={{ width: "294px", height: "340px" }}>
                                                    <FormCreate
                                                        //form props
                                                        toogle={this.toogle}
                                                        formValues={this.state.form}
                                                        onChangeForm={this.handleChange}
                                                        submitForm={this.HandleClick}
                                                        modeEdit={this.state.modeEdit}
                                                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear etiqueta"}
                                                        handleChangeComplete={this.handleChangeComplete}
                                                    />
                                                </div>
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="card-body p-2">
                        <div className="col-md-12" style={{ background: "#e6e6e6", height: "95px", borderRadius: "10px" }}>
                            {!this.state.isLoaded ? (
                                <div className="col-md-12 pt-3 pl-0 pr-0">
                                    {this.state.data.length >= 1 ? (
                                        <div className="ui-scroll-horizontal-task" >
                                            {this.state.data.map((task_label, index) => (
                                                <React.Fragment>
                                                        <div className="col-md-3 ui-indexColumn-task-columns">
                                                        <UncontrolledTooltip target={`la${task_label.id}`} placement='left' >{task_label.name}</UncontrolledTooltip>
                                                            <div className={`card mb-2 ui-task-column-index column`} style={{display: 'flex', justifyContent: 'center' ,backgroundColor: task_label.color }}>
                                                                <div className="ui-taskLabel-index-color pl-0">
                                                                    <div style={{ color: "#ffff"}} id={`la${task_label.id}`}>{task_label.name}</div>
                                                                    <button className='float-right btn'>
                                                                        <i className="fas fa-tasks" onClick={() => this.toogleChecklist("new", task_label)} ></i>
                                                                    </button>

                                                                    {!this.props.task_board.delete_template && (
                                                                        <React.Fragment>
                                                                            <button 
                                                                                className="float-right btn ui-icon-indexcolum-edit" 
                                                                                style={{ color: "#ffff", cursor: "pointer" }} 
                                                                                onClick={() => this.edit(task_label)}
                                                                                id={`PopoverCreate${task_label.id}`}
                                                                            >
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </button>

                                                                            <UncontrolledPopover isOpen={this.state.id == task_label.id} trigger="legacy" placement="left" target={`PopoverCreate${task_label.id}`} className="popover-column">
                                                                                <PopoverBody>
                                                                                    <div className="col-md-12 p-0" style={{ width: "294px", height: "340px" }}>
                                                                                        <FormCreate
                                                                                            //form props
                                                                                            toogle={this.toogle}
                                                                                            formValues={this.state.form}
                                                                                            onChangeForm={this.handleChange}
                                                                                            submitForm={this.HandleClick}
                                                                                            modeEdit={this.state.modeEdit}
                                                                                            nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear columna"}
                                                                                            handleChangeComplete={this.handleChangeComplete}
                                                                                        />
                                                                                    </div>
                                                                                </PopoverBody>
                                                                            </UncontrolledPopover>
                                                                        </React.Fragment>
                                                                    )}

                                                                    {!this.props.task_board.delete_template && (
                                                                        <button className="float-right btn" style={{ color: "#ffff", cursor: "pointer" }} onClick={() => this.delete(task_label.id)}>
                                                                            <i className="far fa-trash-alt"></i>
                                                                        </button>
                                                                    )}
                                                                    
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    ) : (
                                        <div className="col-md-12 p-4 text-center ui-indexcolumn-textInfo">
                                            <p style={{ fontSize: "29px" }}>No se han creado etiquetas</p>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="col-md-12 text-center">
                                    <Preloader />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
