import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import HeaderTitle from '../GeneralComponents/HeaderTitle';
import FormFilter from '../components/settings/Tenants/FormatHooks/FormFilter';
import IndexFormatHook from '../components/settings/Tenants/FormatHooks/Index';
import Preloader from '../GeneralComponents/LoadingComponen';
import Pagination from "react-js-pagination";

const TenantIndexFormats = (props) => {
    const token = document.querySelector("[name='csrf-token']").content;
    const [data, setData] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [form, setForm] = useState({ company_name: "", format_name: "" });
    const [isLoaded, setIsLoaded] = useState(false);

    const [totalData, setTotalData] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [countPage, setCountPage] = useState(10);

    const [modal, setModal] = useState(false);
    const [formatId, setFormatId] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        fetch(`/settings/get_tenant_formats`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setTotalData(data.total_data);
            setIsLoaded(false)
        });
    }

    const handleChangeFilter = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }
    

    const handlePageChange = (pageNumber, tenant_id) => {
        const filter_url = `/settings/get_tenant_formats?page=${pageNumber}&company_name=${form.company_name}&format_name=${form.format_name}`
        fetch(filter_url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            updateData(data.data, tenant_id)
        });
    }

    const handlePageChangeTenant = (pageNumber) => {
        const filter_url = `/settings/get_tenant_format_pagination?page=${pageNumber}`
        setActivePage(pageNumber)

        fetch(filter_url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setTotalData(data.total_data);
        });
    }

    const handleClickFilter = () => {
        setIsLoaded(true);
        fetch(`/settings/get_tenant_format_pagination?company_name=${form.company_name}&format_name=${form.format_name}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setTotalData(data.total_data);
            setIsLoaded(false);
            setActivePage(1);
        });
    }

    const handleClickCancel = () => {
        fetch(`/settings/get_tenant_format_pagination`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setTotalData(data.total_data);
            setShowFilter(false);
            setActivePage(1);
        });
    }

    const updateData = (new_data, tenant_id) => {
        const register = new_data.filter((e) => e.id == tenant_id)[0]

        setData(data.map(item => {
            if (item.id === tenant_id) {
                return {
                    ...item,
                    pageNumber: Number(register.pageNumber),
                    formats: register.formats
                }
            }
            return item;
        }));
    }

    const handleClickOpenFilter = () => {
        setShowFilter(true);
        setForm({ company_name: "", format_name: "" })
    }
    
    const toogle = () => {
        setModal(false);
        setFormatId("")
    }

    const formatHook = (format_id) => {
        setModal(true);
        setFormatId(format_id)
    }

    return (
        <React.Fragment>
            <HeaderTitle 
                title={`Formatos de los clientes`} 
                subTitle={`Todos los formatos`}
                titleNew={"Nuevo módulo"}
                titleFilter={"Filtros"}
                icon={"fas fa-user-tie"}
                onClickNew={null}
                onClickFilter={handleClickOpenFilter}
                showNew={false}
                showFilter={true}
            />
            
            {showFilter && (
                <FormFilter 
                    handleClickFilter={handleClickFilter}
                    handleClickCancel={handleClickCancel}
                    form={form}
                    onChangeForm={handleChangeFilter}
                />
            )}

            {modal && (
                <IndexFormatHook 
                    backdrop={"static"}
                    modal={modal}
                    toggle={toogle}
                    format_id={formatId}
                    title={"Enpoints"}
                />
            )}

            {!isLoaded ? (
                <React.Fragment>
                    {data.length >= 1 ? (
                        <React.Fragment>
                            <div className="content main-card mb-3 card">
                                <div className="card-body">
                                    <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                        <thead>
                                            <tr>
                                                <th>Empresa</th>
                                                <th>Formatos</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {data.map(tenant => (
                                                <tr key={tenant.id}>
                                                    <td>{tenant.company_name}</td>
                                                    <td>  
                                                        {tenant.formats.length >= 1 ? (
                                                            <table className='table table-hover table-striped table-bordered mb-0' style={{ width: "100%" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Nombre</th>
                                                                        <th>Rutas</th>
                                                                    </tr>
                                                                </thead>

                                                                {tenant.formats.map(formato => (
                                                                    <tr key={formato.id}>
                                                                        <td>{formato.name}</td>
                                                                        <td>
                                                                            <button 
                                                                                onClick={() => formatHook(formato.id)} 
                                                                                className='btn-shadow btn btn-success'
                                                                            >
                                                                                {"Configuracion de rutas"}
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                                {true && (
                                                                    <div className="row mt-3">
                                                                        <div className="col-md-3 text-center">
                                                                            <p>Mostrando {tenant.formats.length} de {tenant.totalData}</p>
                                                                        </div>

                                                                        <div className="col-md-9 pl-0">
                                                                            <Pagination
                                                                                hideNavigation
                                                                                activePage={tenant.pageNumber}
                                                                                itemsCountPerPage={10}
                                                                                itemClass="page-item"
                                                                                innerClass="pagination"
                                                                                linkClass="page-link"
                                                                                totalItemsCount={tenant.totalData}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={(e) => handlePageChange(e, tenant.id)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </table>
                                                        ) : (
                                                            <div className="card">
                                                                <div className="card-body text-center">
                                                                    <h4>No hay formatos con ese nombre</h4>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {true && (
                                        <div className="row mt-3">
                                            <div className="col-md-3 text-left">
                                                <p>Mostrando {data.length} de {totalData}</p>
                                            </div>

                                            <div className="col-md-9 pl-0">
                                                <Pagination
                                                    hideNavigation
                                                    activePage={activePage}
                                                    itemsCountPerPage={countPage}
                                                    itemClass="page-item"
                                                    innerClass="pagination"
                                                    linkClass="page-link"
                                                    totalItemsCount={totalData}
                                                    pageRangeDisplayed={countPage}
                                                    onChange={handlePageChangeTenant}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="card">
                            <div className="card-body text-center">
                                <h5>No hay empresas con formatos</h5>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <div className="card">
                    <div className="card-body text-center">
                        <Preloader />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default TenantIndexFormats;
WebpackerReact.setup({ TenantIndexFormats });
