import React, { Component } from 'react';
import GeneralComments from '../../../GeneralComponents/ChatBoxModuleComment'
import Preloader from '../../../GeneralComponents/LoadingComponen'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TaskIndex from '../../tasks/tasksCard/Index'
import FormCreateCauses from '../../Causes/CauseAnalysis/FormCreate';
import FormCreateSubFiveCause from '../../Causes/SubFiveCause/FormCreate';
import TasksItems from '../../../components/tasks/tasksNumeralChildScore/TasksItems'
import TaskFormCreate from '../../../components/tasks/tasksNumeralChildScore/FormCreate'
import { UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class TaskAndComments extends Component {
    constructor(props){
        super(props)
        this.url_source = `/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id_format}/gestion/${this.props.survey.id}`,
        this.token = document.querySelector("[name='csrf-token']").content;
        this.childSection = React.createRef();
        this.state = {
            showForm: false,

            modal: false,
            modalCreateSubFiveCause: false,
            modalFiveCauses: false,

            modeEditFiveCauses: false,
            modeEditSubFiveCauses: false,

            showFiveCauses: false,
            showAnalisis: this.props.survey.have_cause_analisis,

            stateCreate: this.props.survey.have_five_cause,
            have_cause_analisis: this.props.survey.have_cause_analisis,

            ErrorValues: true,
            five_cause_id: "",
            five_cause: this.props.survey.five_cause,
            count: 1,
            idTask: "",
            sub_five_cause_id: "",

            //states task

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            selectedOptionUser: {
                responsable_id: "",
                label: "Responsable"
            },

            selectedOptionAreas: {
                specific_area_id: "",
                label: "Área Específicas"
            },

            selectedOptionCargo: {
                proceso_id: "",
                label: "Proceso"
            },

            formCreateCauses: {
                specific_area_id: "",
                proceso_id: "",
                date_of_analysis: "",
                responsable_id: "",
                description: "",
                has_similar_report: "",
                survey_id: this.props.survey.id,
            },

            formCreateFiveCauses: {
                proceso_id: "",
                date_of_analysis: "",
                responsable_id: "",
                description: "",
                has_similar_report: "",
                survey_id: this.props.survey.id,
            },

            formCreateSubFiveCause: {
                description: "",
                five_cause_id: "",
            },

            form: {
                name: "",
                due_date: "",
                user_owner_id: "",
                module_task_id: this.props.modulo.module_task_id,
                name_module: this.props.name_module_task,
            },

            users: [],
            specific_areas: [],
            cargos: [],
            dataSubFiveCauses: [],
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    //tasks 

    componentDidMount = () => {
        this.configSelect()
        this.loadData();
    }

    configSelect = () => {
        let array = []
        let arrayAreas = []
        let arrayCargos = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.props.specific_areas.map((item) => (
            arrayAreas.push({label: item.name, value: item.id})
        ))

        this.props.procesos.map((item) => (
            arrayCargos.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array,
            specific_areas: arrayAreas,
            cargos: arrayCargos
        })
    }


    //analisis de causas 

    handleChangeAutocompleteUser = selectedOptionUser => {
        if(this.state.modalFiveCauses){
            this.setState({
                selectedOptionUser,
                formCreateFiveCauses: {
                    ...this.state.formCreateFiveCauses,
                    responsable_id: selectedOptionUser.value
                }
            });
        }else{
            this.setState({
                selectedOptionUser,
                formCreateCauses: {
                    ...this.state.formCreateCauses,
                    responsable_id: selectedOptionUser.value
                }
            });
        }
    };

    handleChangeAutocompleteCargo = selectedOptionCargo => {
        if(this.state.modalFiveCauses){
            this.setState({
                selectedOptionCargo,
                formCreateFiveCauses: {
                    ...this.state.formCreateFiveCauses,
                    proceso_id: selectedOptionCargo.value
                }
            });
        }else{
            this.setState({
                selectedOptionCargo,
                formCreateCauses: {
                    ...this.state.formCreateCauses,
                    proceso_id: selectedOptionCargo.value
                }
            });
        }
    };

    handleChangeAutocompleteAreas = selectedOptionAreas => {
        this.setState({
            selectedOptionAreas,
            formCreateCauses: {
                ...this.state.formCreateCauses,
                specific_area_id: selectedOptionAreas.value
            }
        });
    };

    validationForm = () => {
        if (this.state.form.date_of_analysis != "" && 
            this.state.form.responsable_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClickCauses = () => {
        if(this.validationForm() == true){
            fetch(`/cause/cause_analyses`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateCauses), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data);
               Turbolinks.visit(`/cause/cause_analyses/${data.register.id}`);
            });
        }
    }

    HandleClickFiveCauses = () => {
        if (!this.state.modeEditFiveCauses)
            fetch(`/cause/five_causes`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateFiveCauses), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ 
                        modalFiveCauses: false,
                        stateCreate: true,
                        five_cause:  data.register,
                        count: 3,
                    })
                    this.messageSuccess(data);
                    this.clearValuesCauses();
                });
        else {
            fetch(`/cause/five_causes/${this.state.five_cause.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreateFiveCauses), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modalFiveCauses: false, five_cause: data.register })
                    this.messageSuccess(data);
                    this.clearValuesCauses();
                });
        }
    }

    updateData = (data) => {
        this.setState({
            dataSubFiveCauses: [...this.state.dataSubFiveCauses, data],
        })
    }

    //add update
    updateItem = sub_five_cause => {
        this.setState({
            dataSubFiveCauses: this.state.dataSubFiveCauses.map(item => {
            if (sub_five_cause.id === item.id) {
              return { ...item, 
                description: sub_five_cause.description,
              }
            }
            return item;
          })
        });
    }



    HandleClickSubFiveCauses = () => {
        fetch(`/cause/sub_five_causes`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.formCreateSubFiveCause), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.updateData(data.register)
            this.messageSuccess(data);
        });
    }

    HandleClickSubFiveCauses = () => {
        if (!this.state.modeEditSubFiveCauses)
            fetch(`/cause/sub_five_causes`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateSubFiveCause), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modalCreateSubFiveCause: false})
                    this.updateData(data.register);
                    this.clearValuesSubFiveCause();
                    this.messageSuccess(data);
                });
        else {
            fetch(`/cause/sub_five_causes/${this.state.sub_five_cause_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreateSubFiveCause), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modalCreateSubFiveCause: false })
                    this.updateItem(data.register)
                    this.clearValuesSubFiveCause();
                    this.messageSuccess(data);
                });
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValuesCauses();
        } else {
            this.setState({ modal: false })
        }
    }

    toogleFiveCauses = (from) => {
        if (from == "new") {
            this.setState({ modalFiveCauses: true })
            this.clearValuesCauses();
        } else {
            this.setState({ modalFiveCauses: false })
        }
    }

    toogleSubFiveCauses = (from) => {
        if (from == "new") {
            this.setState({ 
                modalCreateSubFiveCause: true ,
                formCreateSubFiveCause: {
                    ...this.state.formCreateSubFiveCause,
                    five_cause_id: this.state.five_cause.id,
                },
            })
        } else {
            this.setState({ modalCreateSubFiveCause: false })
            this.clearValuesSubFiveCause();
        }
    }

    deleteSubFiveCauses = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/cause/sub_five_causes/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData();
                        this.messageSuccess(response)
                    });
            }
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/cause/five_causes/${this.state.five_cause.id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ stateCreate: false, count: 1 })
                        this.clearValuesCauses();
                        this.messageSuccess(response)
                    });
            }
        });
    };

    loadData = () => {
        if(this.state.stateCreate){
            fetch(`/cause/get_sub_five_causes/${this.state.five_cause.id}`, {
                method: 'GET', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataSubFiveCauses: data.data,
                    isLoaded: false
                });
            });
        }
    }

    HandleChangeCauses = (e) => {
        this.setState({
            formCreateCauses: {
                ...this.state.formCreateCauses,
                [e.target.name]: e.target.value
            }
        })
    }  
    
    HandleChangeSubFiveCauses = (e) => {
        this.setState({
            formCreateSubFiveCause: {
                ...this.state.formCreateSubFiveCause,
                [e.target.name]: e.target.value
            }
        })
    }  

    HandleChangeFiveCauses = (e) => {
        this.setState({
            formCreateFiveCauses: {
                ...this.state.formCreateFiveCauses,
                [e.target.name]: e.target.value
            }
        })
    }  

    editFiveCauses = () => {
        this.setState({
            modalFiveCauses: true,
            modeEditFiveCauses: true,

            selectedOptionUser: {
                responsable_id: "",
                label: `${this.state.five_cause.responsable.first_name} ${this.state.five_cause.responsable.first_last_name}`
            },

            selectedOptionCargo: {
                proceso_id: "",
                label: `${this.state.five_cause.proceso.name} `
            },

            formCreateFiveCauses: {
                ...this.state.formCreateFiveCauses,
                proceso_id: this.state.five_cause.proceso_id,
                date_of_analysis: this.state.five_cause.date_of_analysis,
                responsable_id: this.state.five_cause.responsable_id,
                description: this.state.five_cause.description,
            },
        })
    }

    editsubFiveCause = (sub_five_cause) => {
        this.setState({
            modalCreateSubFiveCause: true,
            modeEditSubFiveCauses: true,
            sub_five_cause_id: sub_five_cause.id,

            formCreateSubFiveCause: {
                ...this.state.formCreateSubFiveCause,
                description: sub_five_cause.description,
            },
        })
    }

    clearValuesSubFiveCause = () => {
        this.setState({
            modeEditSubFiveCauses: false,
            formCreateSubFiveCause: {
                ...this.state.formCreateSubFiveCause,
                description: "",
            },
        })
    }

    clearValuesCauses = () => {
        this.setState({
            modeEditFiveCauses: false,

            selectedOptionUsers: {
                users_ids: "",
                label: "Responsable"
            },

            selectedOptionAreas: {
                specific_area_id: "",
                label: "Área Específicas"
            },

            selectedOptionUser: {
                responsable_id: "",
                label: "Responsable"
            },

            selectedOptionCargo: {
                proceso_id: "",
                label: "Proceso"
            },

            formCreateCauses: {
                ...this.state.formCreateCauses,
                specific_area_id: "",
                proceso_id: "",
                date_of_analysis: "",
                responsable_id: "",
                description: "",
                has_similar_report: "",
            },

            formCreateFiveCauses: {
                ...this.state.formCreateFiveCauses,
                proceso_id: "",
                date_of_analysis: "",
                responsable_id: "",
                description: "",
                has_similar_report: "",
            },
        })
    }


    handleChangeAutocomplete = selectedOption => {
        if(this.state.modalFiveCauses){
            this.setState({
                selectedOption,
                formCreateFiveCauses: {
                    ...this.state.formCreateFiveCauses,
                    user_owner_id: selectedOption.value
                }
            });
        }else{
            this.setState({
                selectedOption,
                form: {
                    ...this.state.form,
                    user_owner_id: selectedOption.value
                }
            });
        }
    };

    clearValues = () => {
        console.log("asdasdclearValuesclearValues")
        this.setState({
            showForm: false,
            form: {
                ...this.state.form,
                name: "",
                due_date: "",
                user_owner_id: "",
                module_task_id: this.props.modulo.module_task_id,
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable",
            },
        })
    }

    HandleChangeTask = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    requestType = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn-shadow btn btn-info',
              cancelButton: 'btn-shadow btn btn-info mr-3'
            },
            buttonsStyling: false
          })
          
          swalWithBootstrapButtons.fire({
            title: 'Tipo de análisis de causas',
            text: "Seleccione el tipo de análisis de causas a crear",
            icon: 'warning',
            showCancelButton: this.props.tenant.app_type == "beewo",
            confirmButtonText: 'Análisis de causa raíz',
            cancelButtonText: '5 porques',
            reverseButtons: true
          }).then((result) => {
            if (result.dismiss == "cancel") {
                this.setState({ showFiveCauses: true, showAnalisis: false, modalFiveCauses: true })
                this.clearValuesCauses();
            } else if (result.value)(
                this.setState({ showAnalisis: true, showFiveCauses: false, modal: true })
            )
          })
    }


    getColor = (number) => {
        if(number == 0 || number >= 12){
            return ["#FF0000","white"]
        }else if(number >= 6 && number <= 11){
            return ["#FFFF00","black"]
        }else if(number == 5 || number < 5){
            return ["#008000","white"]
        }
    }

    closeTask = () => {
        this.setState({
          idTask: "",
          showTask: false,
        })
    }  

    getColorImp = (number) => {
        if(number ==1){
            return ["white","black"]
        }else if(number ==2){
            return ["#00B050","black"]
        }else if(number ==3){
            return ["#FFC000","black"]
        }else if(number ==4){
            return ["#FF1300","white"]
        }else if(number ==5){
            return ["#921503","white"]
        }
        
        
    }




    render() {
        const estados = {
            gestionar: true,
            eliminar: true,
        }


        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreateCauses
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Añadir Análisis de causa"}

                        //form props
                        formValues={this.state.formCreateCauses}
                        onChangeForm={this.HandleChangeCauses}
                        submitForm={this.HandleClickCauses}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={"Crear"}
                        
                        //select values
                        
                        //select user
                        handleChangeAutocomplete={this.handleChangeAutocompleteUser}
                        selectedOption={this.state.selectedOptionUser}
                        users={this.state.users}

                        //select proceso
                        handleChangeAutocompleteAreas={this.handleChangeAutocompleteAreas}
                        selectedOptionAreas={this.state.selectedOptionAreas}
                        specific_areas={this.state.specific_areas}

                        //select cargos

                        handleChangeAutocompleteCargo={this.handleChangeAutocompleteCargo}
                        selectedOptionCargo={this.state.selectedOptionCargo}
                        cargos={this.state.cargos}

                        tenant={this.props.tenant}
                        showFile={true}
                        
                    />
                )}

                {this.state.modalFiveCauses && (
                    <FormCreateCauses
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalFiveCauses}
                        toggle={this.toogleFiveCauses}
                        title={this.state.modeEditFiveCauses ? "Actualizar 5 porqués" : "5 porqués"}

                        //form props
                        formValues={this.state.formCreateFiveCauses}
                        onChangeForm={this.HandleChangeFiveCauses}
                        submitForm={this.HandleClickFiveCauses}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={this.state.modeEditFiveCauses ? "Actualizar" : "Crear"}
                        
                        //select values
                        
                        //select user
                        handleChangeAutocomplete={this.handleChangeAutocompleteUser}
                        selectedOption={this.state.selectedOptionUser}
                        users={this.state.users}

                        //select proceso
                        handleChangeAutocompleteAreas={this.handleChangeAutocompleteAreas}
                        selectedOptionAreas={this.state.selectedOptionAreas}
                        specific_areas={this.state.specific_areas}

                        //select cargos

                        handleChangeAutocompleteCargo={this.handleChangeAutocompleteCargo}
                        selectedOptionCargo={this.state.selectedOptionCargo}
                        cargos={this.state.cargos}

                        tenant={this.props.tenant}
                        showFile={true}
                        
                    />
                )}

                {this.state.modalCreateSubFiveCause && (
                    <FormCreateSubFiveCause
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCreateSubFiveCause}
                        toggle={this.toogleSubFiveCauses}
                        title={this.state.modeEditSubFiveCauses ? "Editar PorQues" : "Crear PorQues"}

                        //form props
                        formValues={this.state.formCreateSubFiveCause}
                        onChangeForm={this.HandleChangeSubFiveCauses}
                        submitForm={this.HandleClickSubFiveCauses}

                        nameSubmit={this.state.modeEditSubFiveCauses ? "Actualizar" : "Crear"}
                    />
                )}


                <div className="row mt-4 mb-4">

                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            
                            {this.props.format.has_taks && (
                                <li className="nav-item">
                                    <a className={`${(this.props.format.has_taks && this.state.count == 1) ? "active" : ""} nav-link`} onClick={() => this.setState({ count: 1 })} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Tareas  <span className="badge badge-primary">{this.props.dataTask.length}</span></a>
                                </li>
                            )}
                            
                            {this.props.format.has_comments && (
                                <li className="nav-item">
                                    <a className={`${(this.props.format.has_comments && !this.props.format.has_taks || this.state.count == 2) ? "active" : ""} nav-link`} onClick={() => this.setState({ count: 2 })} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Comentarios <span className="badge badge-primary">{this.props.countComment}</span></a>
                                </li>
                            )}

                            {(this.state.have_cause_analisis == false && this.state.stateCreate == false && this.props.format.has_cause_analisis) && (
                                <li className="nav-item">
                                    <a className={`nav-link`} onClick={() => this.requestType()}>Crear análisis de causas</a>
                                </li>
                            )}

                            {this.state.stateCreate && (
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.count == 3 ? "active" : ""}`} id="question-tab" onClick={() => this.state.stateCreate ? this.setState({ count: 3 }) : this.toogleFiveCauses("new")} data-toggle="tab" href="#question" role="tab" aria-controls="question" aria-selected="false">5 Porqués</a>
                                </li>
                            )}

                            {(this.props.format.has_cause_analisis && this.state.showAnalisis) &&  (
                                <li className="nav-item">
                                    {this.props.survey.have_cause_analisis ? (
                                        <a className={`nav-link`} target="_blank" href={`/cause/cause_analyses/${this.props.survey.cause_analisis.id}`}>Análisis causa – raíz</a>
                                    ) : (
                                        <a className={`nav-link`} onClick={() => this.toogle("new")} data-toggle="tab" href={`#`}>Añadir análisis causa – raíz</a>
                                    )}
                                </li>
                            )}
                        
                        </ul>
                    </div>

                    <div className="tab-content w-100" id="myTabContent">
                        <div className={`tab-pane fade ${(this.props.format.has_taks && this.state.count == 1)? "show active" : ""}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="col-md-12">
                                <TaskIndex 
                                    data={this.props.dataTask} 
                                    estados={this.props.estados}
                                    user={this.props.user}
                                    users={this.props.users}
                                    module_task={this.props.modulo.module_task_id}
                                    moduleName={"Encuestas"}
                                    loadData={this.props.loadDataTask}
                                    updateStateLoad={this.props.updateStateLoad}

                                    updateData={this.props.updateData}
                                    updateItem={this.props.updateItem}
                                    procesos={this.props.procesos}

                                    url_source={this.url_source}
                                    task_source_id={this.props.task_source.id}
                                />
                            </div>
                        </div>

                        <div className={`tab-pane fade ${(this.props.format.has_comments && !this.props.format.has_taks || this.state.count == 2) ? "show active" : ""}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="col-md-12">
                                <GeneralComments
                                    usuario={this.props.user}
                                    users={this.state.users}
                                    module_name={this.props.nameComment}
                                    module_comment={this.props.modulo.module_comment_id}
                                    ref={this.childSection} 
                                    url={this.props.url}

                                    updateStateCountComment={this.props.updateStateCountComment}
                                    countComment={this.props.countComment}
                                    pdfs={this.props.pdfs}
                                    showConfig={true}
                                />
                            </div>  
                        </div>

                        <div className={`tab-pane fade ${this.state.count == 3 ? "show active" : ""}`} id="question" role="tabpanel" aria-labelledby="question-tab">
                            <div className="col-md-12">
                                {this.state.stateCreate ? (
                                    <React.Fragment>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <b>Responsable</b>
                                                        <p>{this.state.five_cause.responsable.first_name} {this.state.five_cause.responsable.first_last_name}</p>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <b>Proceso</b>
                                                        <p>{this.state.five_cause.proceso.name}</p>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <b>Fecha de análisis</b>
                                                        <p>{this.state.five_cause.date_of_analysis}</p>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <b>Descripción</b>
                                                        <p>{this.state.five_cause.description}</p>
                                                    </div>
                                                </div>
                                            </div>

                                                <div className="card-footer">

                                                    <button
                                                        className="btn-shadow btn btn-info mr-3"
                                                        onClick={() => this.toogleSubFiveCauses("new")}
                                                    >
                                                        Añadir porques <i className="fas fa-plus"></i>
                                                    </button>

                                                    <button
                                                        className="btn-shadow btn btn-info mr-3"
                                                        onClick={() => this.editFiveCauses()}
                                                    >
                                                        Editar <i className="fas fa-pencil-alt"></i>
                                                    </button>

                                                    <button
                                                        className="btn-shadow btn btn-danger"
                                                        onClick={() => this.delete(this.state.five_cause.id)}
                                                    >
                                                        Eliminar <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                        </div> 

                                        <div className="content main-card mb-3 card mt-4">
                                            <div className="card-body">

                                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "93%" }}>Porque</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {this.state.dataSubFiveCauses.length >= 1 ? (
                                                            this.state.dataSubFiveCauses.map(sub_five_cause => (
                                                                <tr key={sub_five_cause.id}>
                                                                    <td>
                                                                        <div className="row">
                                                                            <div className="col-md-10">
                                                                                {sub_five_cause.description}
                                                                            </div>

                                                                            <div className="col-md-2">
                                                                                <i 
                                                                                    id={`PopoverLabels${sub_five_cause.id}`}
                                                                                    className="fas fa-thumbtack float-right icon-add icon-task-popover"
                                                                                    onClick={() => this.setState({ idTask: sub_five_cause.id }) }
                                                                                >
                                                                                </i>
                                                                                
                                                                                
                                                                                <UncontrolledPopover isOpen={this.state.idTask == sub_five_cause.id} placement="bottom" target={`PopoverLabels${sub_five_cause.id}`}>
                                                                                    <PopoverHeader>Crear tarea <a className="close-popover" onClick={() => this.setState({ idTask: "" })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                                                    <PopoverBody>
                                                                                        <TaskFormCreate 
                                                                                            numeral_child_score={sub_five_cause.module_task_id} 
                                                                                            loadTasks={this.loadData}
                                                                                            users={this.props.users} 
                                                                                            closeTask={this.closeTask}
                                                                                            nameModule={'PorQues'}
                                                                                        />
                                                                                    </PopoverBody>
                                                                                </UncontrolledPopover>
                                                                            </div>
                                                                        </div>

                                                                        {sub_five_cause.tasks.length > 0 && (
                                                                            <div className="col-md-12 p-0">
                                                                                <hr/>
                                                                            </div>
                                                                        )}
                                                                        
                                                                        <div className="col-md-12">
                                                                            <ul>
                                                                                {sub_five_cause.tasks.map((task, index) => (
                                                                                    <TasksItems 
                                                                                        task={task} 
                                                                                        user={this.props.user} 
                                                                                        users={this.props.users} 
                                                                                        loadData={this.loadData}
                                                                                        procesos={this.props.procesos}
                                                                                    />
                                                                                ))}
                                                                            </ul>
                                                                        </div>

                                                                    </td>
                                                                    <td className="text-right" style={{ width: "10px" }}>
                                                                        <UncontrolledDropdown className='btn-group'>
                                                                            <DropdownToggle className='btn btn-info'>
                                                                                <i className="fas fa-bars"></i>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                                {true && (
                                                                                    <DropdownItem
                                                                                        onClick={() => this.editsubFiveCause(sub_five_cause)}
                                                                                        className="dropdown-item"
                                                                                    >
                                                                                        Editar
                                                                                    </DropdownItem>
                                                                                )}

                                                                                {true && (
                                                                                    <DropdownItem
                                                                                        onClick={() => this.deleteSubFiveCauses(sub_five_cause.id)}
                                                                                        className="dropdown-item"
                                                                                    >
                                                                                        Eliminar
                                                                                    </DropdownItem>
                                                                                )}
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </td>
                                                                </tr>
                                                            ))

                                                        ) : (
                                                                <tr>
                                                                    <td colSpan="8" className="text-center">
                                                                        <div className="text-center mt-4 mb-4">
                                                                            <h4>No hay registros</h4>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                        )}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>

                                    </React.Fragment>
                                ) : (
                                    <div className="card">
                                        <div className="card-body">
                                            <h3 className="text-center">No hay 5 Porqués</h3>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                </div>

            </React.Fragment>
        );
    }
}

export default TaskAndComments;
