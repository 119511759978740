import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { isMobile } from 'react-device-detect';
import Dropzone from "react-dropzone";

class FormConfigurationFile extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            nameFile: "",
            data: [],
            file: null,
            sizeFile: 0,
            isLoadedFiles: false,
            formUpdate: {
                file: {},
                fileName: ""
            },

            formAddFiles: {
                modulo: this.props.nameModule,
                position_id: this.props.nameModule == "Cargos" ? this.props.module_id : "",
                proceso_id: this.props.nameModule == "Procesos" ? this.props.module_id : "",
                user_id: this.props.nameModule == "Usuarios" ? this.props.module_id : "",
                file: "",  
                fileName: "",
            },
        }
    }

    HandleClick = () => {
        const formData = new FormData();
        formData.append("fileName", this.state.formUpdate.fileName)
        formData.append("file", this.state.formUpdate.file)

        fetch(`/settings/general_files/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.updateItem(data.register)
            this.setState({ 
                id: "", 
                formUpdate: {
                    file: {},
                    fileName: ""
                },
            })
        });
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
              ...this.state.formUpdate,
              [e.target.name]: e.target.value
            }
        });
    }

    edit = (file) => {
        this.setState({
            id: file.id,
            formUpdate: {
                ...this.state.formUpdate,
                file: file.file,
                fileName: file.fileName
            }
        })
    }

    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.value) {
            fetch(`/settings/general_files/${id}`, {
              method: "DELETE",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.loadDataFiles();
                this.props.loadDataTable();
              });
          }
        });
    };

    handleSubmit = e => {
        e.preventDefault();
    };

    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    file: file
                },
  
                nameFile: file.path,
                sizeFile: file.size
            })
        ));
    };

    //create file

    componentDidMount(){
        this.loadDataFiles();
    }

    loadDataFiles = () => {
        fetch(`/settings/get_general_files_configuration/${this.props.nameModule}/${this.props.module_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
          });
        });
    }

    clearValuesFiles = () => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                fileName: "",
                file: {},
            },

            isLoadedFiles: false,
            nameFile: "",
            file: null
        })
    }

    HandleChangeFiles = (e) => {
        this.setState({
            formAddFiles: {
              ...this.state.formAddFiles,
              [e.target.name]: e.target.value
            }
        });
    }

    handleFileCreate = (archivo) => {
      archivo.map(file => (
          this.setState({
              formAddFiles: {
                  ...this.state.formAddFiles,
                  file: file.size >= 11485760 ? "" : file
              },

              nameFile: file.size >= 11485760 ? "El archivo supera el maximo permitido intenta con otro" : file.path,
              sizeFile: file.size
          })
      ));
    };

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
            isLoadedFiles: false,
        })
    }

    HandleClickFiles = () => {
        this.setState({ isLoadedFiles: true })
        const formData = new FormData();
        formData.append("modulo", this.state.formAddFiles.modulo)
        formData.append("file", this.state.formAddFiles.file)
        formData.append("fileName", this.state.formAddFiles.fileName)
        formData.append("position_id", this.state.formAddFiles.position_id)
        formData.append("proceso_id", this.state.formAddFiles.proceso_id)
        formData.append("user_id", this.state.formAddFiles.user_id)

        fetch(`/settings/general_files`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.updateData(data.register)
            this.props.loadDataTable();
            this.clearValuesFiles()
        });
    }

    updateItem = archivo => {
        this.setState({
            data: this.state.data.map(item => {
                if (archivo.id === item.id) {
                    return {
                        ...item,
                        file: archivo.file,
                        fileName: archivo.fileName,
                        user_register: archivo.user_register,
                    }
                }
                return item;
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-lg modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className={`col-md-5 ${isMobile ? "mb-3" : ""}`}>
                                        <label>Nombre </label>
                                        <input
                                            type="text"
                                            name="fileName"
                                            value={this.state.formAddFiles.fileName}
                                            onChange={this.HandleChangeFiles}
                                            className={`form form-control`}
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    <div className={`col-md-5 ${isMobile ? "mb-3" : ""}`}>
                                        <label>Archivo </label>
                                        <Dropzone onDrop={(files) => this.handleFileCreate(files)}>
                                            {({getRootProps, getInputProps}) => (
                                                <div
                                                    {...getRootProps({
                                                        className: 'dropzone',
                                                    })}
                                                >
                                                    <input {...getInputProps()} />
                                                    <p>{`${(this.state.nameFile != "" ? this.state.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>

                                    <div className="col-md-2 files-add-button">
                                        <button 
                                            className="btn-shadow btn btn-info text-center" 
                                            onClick={() => this.HandleClickFiles()}
                                            disabled={this.state.sizeFile >= 11485760 ? true : this.state.isLoadedFiles}
                                        >
                                            {this.state.isLoadedFiles ? (
                                                <div className="loader-files">Cargando..</div>
                                            ) : (
                                                <React.Fragment>
                                                    {"Crear"}
                                                </React.Fragment>
                                            )} 
                                        </button>
                                    </div>
                                    
                                    {this.state.sizeFile >= 11485760 && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                                <b>{this.state.nameFile}</b>
                                            </div>
                                        </div>
                                    )}
                                        <div className="col-md-12">
                                            <hr/>
                                        </div>
                                       <div className="col-md-12 mt-4">
                                            <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th style={{ width: "44%" }}>Archivo</th>
                                                    <th className="text-center">Opciones</th>
                                                </tr>
                                            </thead>
                
                                            <tbody>
                                                {this.state.data.length >= 1 ? (
                                                    this.state.data.map(file => (
                                                        <tr key={file.id}>
                                                            <td>
                                                                {file.id == this.state.id ? (
                                                                    <input
                                                                        type="text"
                                                                        name="fileName"
                                                                        value={this.state.formUpdate.fileName}
                                                                        onChange={this.HandleChangeUpdate}
                                                                        className="form form-control"
                                                                        placeholder="Nombre del Archivo"
                                                                    />
                                                                ) : (
                                                                    <p>{file.fileName}</p>
                                                                )}
                                                            </td>

                                                            <td>
                                                                {file.id == this.state.id ? (
                                                                    <Dropzone onDrop={(files) => this.handleFile(files)}>
                                                                        {({getRootProps, getInputProps}) => (
                                                                            <div
                                                                                {...getRootProps({
                                                                                    className: 'dropzone',
                                                                                })}
                                                                            >
                                                                                <input {...getInputProps()} />
                                                                                <p>{`${(this.state.nameFile != "" ? this.state.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                                            </div>
                                                                        )}
                                                                    </Dropzone>
                                                                ) : (
                                                                    <p><a href={file.file.url != undefined ? file.file.url : ""} target="_blank">{file.file.url != undefined ? "Ver Archivo" : "No tiene archivo"}</a></p>
                                                                )}
                                                            </td>
                    
                                                            <td className="text-right" style={{ width: "10px" }}>
                                                                {this.state.id != "" ? (
                                                                    <React.Fragment>
                                                                        {file.id == this.state.id &&(
                                                                            <React.Fragment>
                                                                                <i className="fas fa-check-circle update-section" onClick={() => this.HandleClick()}></i> 
                                                                                <i className="fas fa-times-circle update-false" onClick={() => this.setState({ id: ""})}></i>
                                                                            </React.Fragment>
                                                                        )} 
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <UncontrolledDropdown className='btn-group'>
                                                                        <DropdownToggle className='btn btn-info'>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.edit(file)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}
                        
                                                                            {true && (  
                                                                                <DropdownItem
                                                                                    onClick={() => this.delete(file.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                )}
                                                            </td>
                                                        </tr>
                                                ))
                                                    ) : (
                                                    <tr>
                                                        <td colSpan="8" className="text-center">
                                                            <div className="text-center mt-4 mb-4">
                                                                <h4>No hay adjuntos</h4>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                       </div>


                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormConfigurationFile;
