import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class TableIndexWeb extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card">
                    <div className="card-body normativity-body">
                        <table className="table table-hover table-striped table-bordered normativity-body-table" id="sampleTable">
                        <thead>
                            <tr>
                                <th style={{ width: "45%" }}>Nombre</th>
                                {this.props.type != "NNOM" && (
                                    <th className="text-center" style={{ width: "20%" }}>Última Calificación</th>
                                )}

                                {this.props.estados.evaluations && (
                                    <th style={{ width: "15%" }}>
                                        {this.props.type == "NNOM" ? "Fecha de Calificación" : "" }
                                    </th>
                                )}

                                <th style={{ width: "15%" }}>{this.props.type == "NNOM" ? "Calificación" : "" }</th>
                                {this.props.type == "NNOM" && (
                                    <th style={{ width: "15%" }}>Archivos</th>
                                )}
                                {this.props.estados.gestionar && (
                                    <th style={{ width: "8%" }}></th>
                                )}
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.data.length >= 1 ? (
                                this.props.data.map(normativity => (
                                    <tr key={normativity.id}>
                                        <td>
                                            {normativity.id == this.props.id ? (
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={this.props.formUpdate.name}
                                                    onChange={this.props.HandleChangeUpdate}
                                                    className="form form-control"
                                                />
                                            ) : (
                                                <p>{normativity.name}</p>
                                            )}
                                        </td>

                                        {this.props.type != "NNOM" && (
                                            <td className="last-score"> 
                                                {this.props.getScore(normativity.normativity_scores)}  
                                            </td>   
                                        )}

                                        <td className="text-center"> 
                                            {this.props.type != "NNOM" ? (
                                                <React.Fragment>
                                                    {this.props.estados.evaluations && (
                                                        <a
                                                            className="link-item"
                                                            data-turbolinks="true"
                                                            href={`/health/normativity/${normativity.id}`}
                                                        >
                                                            Evaluaciones
                                                        </a>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {normativity.id == this.props.id ? (
                                                        <input
                                                            type="date"
                                                            name="date_score"
                                                            value={this.props.formUpdate.date_score}
                                                            onChange={this.props.HandleChangeUpdate}
                                                            className="form form-control"
                                                        />
                                                    ) : (
                                                        <p>{normativity.date_score}</p>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </td>

                                        <td className="text-center">
                                            {this.props.type != "NNOM" ? (
                                                <a
                                                    className="link-item"
                                                    data-turbolinks="true"
                                                    href={`/health/normativity/${normativity.id}/numeral_parents`}
                                                >
                                                    Estándares y guías
                                                </a>
                                            ) : (
                                                <React.Fragment>
                                                    {normativity.id == this.props.id ? (
                                                        <input
                                                            type="number"
                                                            name="score"
                                                            value={this.props.formUpdate.score}
                                                            onChange={this.props.HandleChangeUpdate}
                                                            placeholder="Calificación"
                                                            className="form form-control"
                                                        />
                                                    ) : (
                                                        <p>{normativity.score}</p>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </td>

                                        {this.props.type == "NNOM" && (
                                            <td className="text-center">
                                                <span className="link-line" onClick={() => this.props.addFiles(normativity)}>Archivos adjuntos </span>
                                            </td>
                                        )}

                                        {(true || true) &&(
                                            <td className="text-right" style={{ width: "10px" }}>
                                                {this.props.id != "" ? (
                                                    <React.Fragment>
                                                        {normativity.id == this.props.id &&(
                                                            <React.Fragment>
                                                                <i className="fas fa-check-circle update-section" onClick={() => this.props.HandleClickUpdate()}></i> 
                                                                <i className="fas fa-times-circle update-false" onClick={() => this.props.updateId()}></i>
                                                            </React.Fragment>
                                                        )} 
                                                    </React.Fragment>
                                                ) : (
                                                    <UncontrolledDropdown className='btn-group'>
                                                        <DropdownToggle className='btn btn-info'>
                                                            <i className="fas fa-bars"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                            {this.props.estados.editar && (
                                                                <DropdownItem
                                                                    onClick={() => this.props.edit(normativity)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Editar
                                                                </DropdownItem>
                                                            )}
                            
                                                            {this.props.estados.eliminar && (  
                                                                <DropdownItem
                                                                    onClick={() => this.props.delete(normativity.id)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Eliminar
                                                                </DropdownItem>
                                                            )}

                                                            {this.props.type != "NNOM" && (  
                                                                <DropdownItem
                                                                    className="dropdown-item"
                                                                    data-turbolinks="true"
                                                                    href={`/health/normativity/${normativity.id}/numeral_parents`}
                                                                >
                                                                    Gestionar
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                            ))
                                ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        <div className="text-center mt-4 mb-4">
                                            <h4>No hay registros</h4>
                                        </div>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TableIndexWeb;