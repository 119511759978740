import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            users: [],
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    componentDidMount = () => {
        let array = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array,
        })
    }

    render() {
        const config = {
            language: 'es',
            quickInsertEnabled: false,
            colorsHEXInput: true,
            autoFocus: true,
            toolbartop: true,
            linkAlwaysBlank: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            htmlExecuteScripts: true,
            key: this.props.key_text,
            imageUploadToS3: this.props.imageUpload,
            iframe: true,
            tabSpaces: 4,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass'],

                    // Alignment of the group in the toolbar.
                    align: 'left',

                    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                    buttonsVisible: 10
                },


                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL'],
                    align: 'left',
                    buttonsVisible: 10
                },

                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly'],
                    align: 'left',
                    buttonsVisible: 10
                },
            }
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <FroalaEditor
                                        model={this.props.formValues.comment}
                                        config={config}
                                        onModelChange={(e) => this.props.handleChangeCkaditor(e)}
                                    />
                                </div>

                                <div className="col-md-12 mt-3">
                                    <label>Enviar a:</label>
                                    <input
                                        type="hidden"
                                        name="user_ids"
                                        value={this.props.selectedOptionReply.user_ids}
                                    />

                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        autoFocus={false}
                                        className={`link-form`}
                                        classNamePrefix="select"
                                        placeholder="Seleccione"
                                        name="user_ids"
                                        onChange={this.props.handleChangeAutocompleteMultiReply}
                                        options={this.state.users}
                                        defaultValue={this.props.editValuesUsersReply}
                                    />
                                </div>

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBnt != undefined ? this.props.nameBnt : "Crear"}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;