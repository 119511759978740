import React, { Component } from 'react';
import Table from '../components/dofa/Dofa/Index'
import WebpackerReact from 'webpacker-react';
import { UncontrolledTooltip } from 'reactstrap';
import HeaderTitle from '../GeneralComponents/HeaderTitle';
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es'; 

import FroalaEditor from 'react-froala-wysiwyg';
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from '../GeneralComponents/LoadingComponen' 
import IndexQuestionPdfStyle from '../components/formatos/QuestionPdfStyle/Index';
import IndexFormatPdfSubForm from '../components/formatos/FormatPdfSubForm/Index';
import EditQrWidth from '../components/formatos/FormatPdf/EditQrWidth';

class IndexFormatPdf extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showMessage: false,
            ErrorValues: true,
            loderWP: true,
            modeEdit: this.props.modeEdit,
            modalEditQr: false,
            showAll: false,
            format_pdf_id: this.props.modeEdit ? this.props.format_pdf.id : "",
            currentTab: "",

            question: {},
            format_association: {},


            form: {
                format_id: this.props.format.id,
                name: this.props.modeEdit ? this.props.format_pdf.name : "",
                orientation: this.props.modeEdit ? this.props.format_pdf.orientation : "Portrait",
                content: this.props.modeEdit ? this.props.format_pdf.content : "",
                header: this.props.modeEdit ? this.props.format_pdf.header : "<p>Encabezado</p>",
                footer: this.props.modeEdit ? this.props.format_pdf.footer : "<p>Pie de pagina</p>",
            },
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleChangeCkaditor = (field, e) => {
        this.setState({
            form:{
                ...this.state.form,
                [field]: e
            }
        })
    }

    setCurrentTab = (tab) => {
        this.setState({
            showAll: false,
            currentTab: this.state.currentTab == tab ? "" : tab
        })
    }

    componentDidMount() {
        setTimeout(()=> this.setState({ loderWP: false }),2000)
    }

    validationForm = () => {
        if (this.state.form.content != "" &&
            this.state.form.name != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if(this.validationForm() == true){
            if (this.state.modeEdit) {
                fetch(`/formatos/format_pdfs/${this.state.format_pdf_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.messageSuccess(data)
                        this.setState({
                            format_pdf_id: data.register.id,
                            modeEdit: true,
                            form: {
                                format_id: this.props.format.id,
                                name: data.register.name,
                                orientation: data.register.orientation,
                                content: data.register.content,
                                header: data.register.header,
                                footer: data.register.footer,
                            },
                        })
                    });
            } else {
                fetch(`/formatos/format_pdfs`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.messageSuccess(data)
                        this.setState({
                            format_pdf_id: data.register.id,
                            modeEdit: true,
                            form: {
                                format_id: this.props.format.id,
                                name: data.register.name,
                                orientation: data.register.orientation,
                                content: data.register.content,
                                header: data.register.header,
                                footer: data.register.footer,
                            },
                        })
                    });
            }
        }
    }

    copyQuestion = (question_alias) => {
        
        this.setState({ showMessage: true })
        setTimeout(()=> {
            this.setState({ showMessage: false })
        },2000)
        navigator.clipboard.writeText(`{${question_alias}}`)
    }

    handleChangeChecked = (e) => {
        const isChecked = e.target.checked;

        this.setState({
            showAll: isChecked,
        })
    }

    toggleQuestionPdfStyle = () => {
        this.setState({
            question: {},
        })
    }

    toggleFormatPdfSubForm = () => {
        this.setState({
            format_association: {},
        })
    }

    toogle = () => {
        this.setState({ modalEditQr: !this.state.modalEditQr })
    }

    render() {
        const editorConfiguration = {
            language: 'es',
            toolbar: {
                items: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo',  'fontfamily', 'fontsize' ],
                viewportTopOffset: 30,
                shouldNotGroupWhenFull: true
            }
        };

        const config = {
            language: 'es',
            quickInsertEnabled: false,
            colorsHEXInput: true,
            autoFocus: true,
            toolbartop: true,
            linkAlwaysBlank: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            htmlExecuteScripts: true,
            key: this.props.id,
            imageUploadToS3: this.props.imageUpload,
            iframe: true,
            tabSpaces: 4,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                    // Alignment of the group in the toolbar.
                    align: 'left',

                    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                    buttonsVisible: 12
                },


                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                    align: 'left',
                    buttonsVisible: 11
                },

                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                    align: 'left',
                    buttonsVisible: 10
                },

                moreMisc: {
                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                    align: 'right',
                    buttonsVisible: 9
                }
            }
        }

        const style = {
            display: "flow-root",
            paddingTop: "18px"
        }

        const styleIconStyle = {
            fontSize: "20px",
            color: "#16aaff",
            cursor: "pointer"
        }


        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Configuración de PDFs"}
                    subTitle={"Fordata"}
                    titleNew={""}
                    titleFilter={"Filtros"}
                    showNew={false}
                    showFilter={false}
                />

                {Object.keys(this.state.question).length != 0 && (
                    <IndexQuestionPdfStyle
                        modal={Object.keys(this.state.question).length != 0}
                        question={this.state.question}
                        format_pdf_id={this.state.format_pdf_id}
                        title={"QuestionPdfStyle"}
                        toggle={this.toggleQuestionPdfStyle}
                    />   
                )}

                {Object.keys(this.state.format_association).length != 0 && (
                    <IndexFormatPdfSubForm
                        modal={Object.keys(this.state.format_association).length != 0 ? true : false}
                        format_association={this.state.format_association}
                        format_pdf_id={this.state.format_pdf_id}
                        title={"FormatPdfSubForm"}
                        toggle={this.toggleFormatPdfSubForm}
                    />   
                )}

                {this.state.modalEditQr && (
                    <EditQrWidth
                        backdrop={"static"}
                        modal={this.state.modalEditQr}
                        format_pdf_id={this.state.format_pdf_id}
                        title={"Editar tamaño del QR"}
                        toggle={this.toogle}
                        messageSuccess={this.messageSuccess}
                    />     
                )}

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}`}
                                data-turbolinks="true"
                            >
                                {"Volver al formato"}
                            </a>
                        </li>
                    </ol>
                </nav>
                
                <div className="row">
                    <div className="col-md-8">

                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <input 
                                            type="text" 
                                            name="name"
                                            value={this.state.form.name}
                                            onChange={this.HandleChange}
                                            className={`form form-control ${!this.state.ErrorValues && this.state.form.name == "" ? "error-class" : ""}`}
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    <div className="ui-indexFormatPdf-radios_select_orientation-pdf">
                                        <div className='ui-indexFormatPdf-radios_select_orientation-v'>
                                            <input type="radio" id="male" name="orientation" onChange={this.HandleChange} value="Portrait" checked={this.state.form.orientation == "Portrait" ? true : false}/>
                                            <label for="male" className="mr-3 ml-1">Vertical</label>
                                        </div>
                                        <div className='ui-indexFormatPdf-radios_select_orientation-h'>
                                            <input type="radio" id="female" name="orientation" onChange={this.HandleChange} value="Landscape" checked={this.state.form.orientation == "Landscape" ? true : false}/>
                                            <label for="female" className="ml-1">Horizontal</label>
                                        </div>
                                    </div>

                                    <div className="ui-indexFormatPdf-radios_select_orientation-show-all">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" onChange={this.handleChangeChecked} className="custom-control-input" id={`customHeader`} value={!this.state.showAll} checked={this.state.showAll} />
                                            <label className="custom-control-label" htmlFor={`customHeader`}>Mostrar todos</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header" style={style}>
                                Encabezado

                                <a className="float-right" onClick={() => this.setCurrentTab("header")}>
                                    {(this.state.currentTab == "header" || this.state.showAll) ? "Cerrar" : "Abrir"}
                                </a>
                            </div>

                            {(this.state.currentTab == "header" || this.state.showAll) && (
                                <div className="card-body">
                                    <FroalaEditor
                                        model={this.state.form.header}
                                        config={config}
                                        onModelChange={(data) => this.HandleChangeCkaditor("header", data)}
                                    />
                                </div>
                            )}
                        </div>
                        
                        <div className={`card mt-3 mb-3 ${!this.state.ErrorValues && this.state.form.content == "" ? "error-class" : ""}`}>
                            <div className="card-header" style={style}>
                                Contenido

                                <a className="float-right" onClick={() => this.setCurrentTab("content")}>
                                    {(this.state.currentTab == "content" || this.state.showAll) ? "Cerrar" : "Abrir"}
                                </a>
                            </div>

                            {(this.state.currentTab == "content" || this.state.showAll) && (
                                <div className="card-body">
                                    <FroalaEditor
                                        model={this.state.form.content}
                                        config={config}
                                        onModelChange={(data) => this.HandleChangeCkaditor("content", data)}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="card mb-3">
                            <div className="card-header" style={style}>
                                Pie de pagina

                                <a className="float-right" onClick={() => this.setCurrentTab("footer")}>
                                    {(this.state.currentTab == "footer" || this.state.showAll)  ? "Cerrar" : "Abrir"} 
                                </a>
                            </div>

                            {(this.state.currentTab == "footer" || this.state.showAll) && (
                                <div className="card-body">
                                    <FroalaEditor
                                        model={this.state.form.footer}
                                        config={config}
                                        onModelChange={(data) => this.HandleChangeCkaditor("footer", data)}
                                    />
                                </div>                
                            )}
                        </div>
                        
                        {!this.state.ErrorValues && this.state.form.content == "" && (
                            <div className="mt-2">
                                <div className="alert alert-danger text-center" role="alert">
                                    <b>Debes de completar todos los campos requeridos</b>
                                </div>
                            </div>
                        )}

                        <div className="card">
                            <div className="card-body">
                                <button className="btn-shadow btn btn-info mr-3" onClick={() => this.HandleClick()}>{this.state.modeEdit ? "Actualizar" : "Crear"}</button>
                                <a href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}`} className="btn btn-light" data-turbolinks="true">Volver al formato</a>
                            </div>
                        </div>

                        
                    </div>
                    

                    <div className="col-md-4">

                        <div className="card">
                            <div className="card-header">
                                Preguntas
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    {this.props.steps_questions.length >= 1 ? (
                                        this.props.steps_questions.map(question => (
                                            <React.Fragment>
                                                <div className="col-md-11">
                                                    <p style={{ cursor: "pointer" }} onClick={() => this.copyQuestion(question.question_alias)} key={question.id}>{"{"}{question.question_alias}{"}"}</p>
                                                </div>

                                                <div className="col-md-1">
                                                    {(this.state.modeEdit && question.question_type == "answer_file") && (
                                                        <React.Fragment>
                                                            <UncontrolledTooltip target='bw_configImg'>{'Configuración Tamaño Imagenes'}</UncontrolledTooltip>
                                                            <i style={styleIconStyle} onClick={() => this.setState({ question: question })} id='bw_configImg' className="fas fa-file-image"></i>
                                                        </React.Fragment>
                                                    )}
                                                    {( question.question_type == "answer_signature") && (
                                                        <React.Fragment>
                                                            <UncontrolledTooltip target='bw_configSignature'>{'Configuración Tamaño Firma'}</UncontrolledTooltip>
                                                            <i style={styleIconStyle} onClick={() => this.setState({ question: question })} id='bw_configSignature' className="fas fa-file-signature"></i>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <p>No hay preguntas</p>
                                    )}
                                </div>

                                {this.state.showMessage && (
                                    <div className="alert alert-warning alert-dismissible fade show" style={{position:"fixed", bottom:"20px", right:"0px", zIndex:"10"}} role="alert">
                                        <strong>Pregunta copiada</strong>
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ showMessage: false })}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>  
                                )}
                            </div>
                        </div>

                        {this.props.format_associations.length >= 1 && (
                            <div className="card mt-3">
                                <div className="card-header">
                                    Asociación de formatos
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {this.props.format_associations.map(format_association => (
                                            <React.Fragment>
                                                <div className="col-md-11">
                                                    <p style={{ cursor: "pointer" }} onClick={() => this.copyQuestion(format_association.format_select.alias)} key={format_association.id}>{"{"}{format_association.format_select.alias}{"}"}</p>
                                                </div>

                                                <div className="col-md-1">
                                                    <i style={styleIconStyle} onClick={() => this.setState({ format_association: format_association })} className="fas fa-file-image"></i>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="card mt-3">
                            <div className="card-header">
                                Especiales
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-11">
                                        <p style={{ cursor: "pointer" }} onClick={() => this.copyQuestion("qr")}>{"{QR}"}</p>
                                    </div>

                                    {this.state.modeEdit && (
                                        <div className="col-md-1">
                                            <i style={styleIconStyle} onClick={() => this.toogle()} className="fas fa-file-image"></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}


export default IndexFormatPdf;
WebpackerReact.setup({ IndexFormatPdf });
