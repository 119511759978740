import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-3">

                        <div className="col-md-6">
                            <input
                                type="hidden"
                                name="format_target_id"
                                value={this.props.selectedOption.format_target_id}
                            />
                            <label>Empresa</label>
                            <Select
                                onChange={this.props.handleChangeAutocomplete}
                                options={this.props.companies}
                                autoFocus={false}
                                className={`link-form`}
                                value={this.props.selectedOption}
                            />
                        </div>

                        <div className="col-md-6">
                            {this.props.format_categories.length >= 1 && (
                                <React.Fragment>
                                    <input
                                        type="hidden"
                                        name="format_category_id"
                                        value={this.props.selectedOptionFormatCategory.format_category_id}
                                    />
                                    <label>Modulo</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteFormatCategory}
                                        options={this.props.format_categories}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.props.selectedOptionFormatCategory}
                                    />  
                                </React.Fragment>
                            )}
                        </div>

                        <div className="col-md-12 text-right mt-3">
                            <button
                                className="btn btn-outline-danger mr-2"
                                onClick={() => this.props.toggle()}
                            >
                                Cancelar
                            </button>

                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.props.submitForm()}
                            >
                                {this.props.nameBnt}
                            </button>

                            <hr />
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;