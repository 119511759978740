import React, { useState, useEffect } from 'react';
import Pagination from "react-js-pagination";
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Select from "react-select";
import PopoverUpdateColumn from '../PopoverUpdateColumn';
import ReactHtmlParser from 'react-html-parser';

const IndexWeb = (props) => {
    const get_color = (date) => {
        let resta = new Date(date).getTime() - new Date().getTime()
        resta = Math.round((resta / (1000 * 24 * 60 * 60)));
        resta = resta + 1
        if (resta >= 5) {
            return "green"
        } else if (resta < 5 && resta >= 0) {
            return "orange"
        } else {
            return "red"
        }
    }

    const getStateTask = (state) => {
        if (state == "doing") {
            return "Haciendo"

        } else if (state == "to_do") {
            return "Por hacer"

        } else if (state == "done") {
            return "Hecha"

        } else {
            return "Archivada"
        }
    }

    return (
        <div className="content main-card mb-3 card mh-400 mt-4 card-style bw-index-container">
            <div className="card-body">

                <div className="col-md-12 pl-0 mb-3">
                    <div className="row">

                        <div className="col-md-1 pr-0 ui-indexWeb__container-button_new">
                            {(props.showNew && (props.task_board ? !props.task_board.delete_template : true) && props.estados.create) && (
                                <button
                                    className="btn-shadow btn btn-info mr-3 ui-indexWeb-viewTask__button_new"
                                    onClick={() => props.toogleNew("new")}
                                >
                                    <i className="fas fa-plus"></i> Nueva
                                </button>
                            )}
                        </div>

                        <div className="col-md-2 pl-0 ui-indexWeb__container-filter-date">
                            <select
                                onChange={props.onChangeDate}
                                className="form form-control select-filter-date"
                            >
                                <option value="">Filtro por fecha</option>
                                <option value="desc">Más recientes</option>
                                <option value="asc">Más antiguas</option>
                            </select>
                        </div>
                    </div>
                </div>


                <div className='content-table ui-tableTask-table__container' id='task'>
                    <table className='ui-tableTask__container-table' >
                        <thead>
                            <tr className='ui-tableTask-container-subtitle'>
                                <th>Código</th>
                                <th>Título Tarea</th>
                                <th>Etiquetas</th>
                                <th>Responsables</th>
                                <th>Fecha Inicio</th>
                                <th>Fecha Fin</th>
                                <th>Estado</th>
                                <th>Fase - Tablero</th>
                                <th className="ui-indexWeb-width-page-hidden">Comentarios</th>
                                <th className="ui-indexWeb-width-page-hidden">Checklist</th>
                                <th className="ui-indexWeb-width-page-hidden">Adjuntos</th>
                                <th className="ui-indexWeb-width-page-hidden">Cambios</th>
                                <th className="ui-indexWeb-width-page">Información</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody ref={props.scrollRef}>
                            {props.data.length >= 1 ?
                                (props.data.map((task, index) => (

                                    <div className="card ui-tableTask-container-card" style={{ borderLeft: `5px solid ${props.get_color(task)}` }} key={task.id}>
                                        <div className="card-body" style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                                            <tr className='ui-tableTask-container-data' onClick={() => props.estados.gestionar ? props.handleClickShow(task.id) : null}>
                                                <td>{`T${task.count}`}</td>
                                                <td style={{ paddingLeft: '5px', paddingRight: '5px' }} className='ui-tableTask-title-task'>
                                                    {task.name}
                                                </td>
                                                <td>
                                                    {task.task_labels.length > 0 ? (
                                                        <React.Fragment>
                                                            {task.task_labels.map((task_label, index) => (
                                                                <React.Fragment key={index}>
                                                                    <i id={`PopoverLabel${task_label.id}`} className="fas fa-square-full" style={{ fontSize: "18px", paddingLeft: '4px', borderRadius: '4px', color: task_label.color }}></i>
                                                                    <UncontrolledTooltip placement="top" target={`PopoverLabel${task_label.id}`}>
                                                                        {task_label.name}
                                                                    </UncontrolledTooltip>
                                                                </React.Fragment>
                                                            ))}
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <i className="fas fa-ban"></i>
                                                        </React.Fragment>
                                                    )}
                                                </td>
                                                <td>
                                                    <div className="row ui-indexWeb-row">
                                                        <div className="icon-show-user-responsible ui-boards-index"
                                                            id={`Popover${task.id}`}
                                                            style={{ marginLeft: '16px' }}>

                                                            {task.user != null ? task.user.first_name.charAt(0) : ""} {task.user != null ? task.user.first_last_name.charAt(0) : ""}
                                                        </div>

                                                        <UncontrolledTooltip placement="top" target={`Popover${task.id}`}>
                                                            {task.user != null ? task.user.first_name : ""} {task.user != null ? task.user.first_last_name : ""}
                                                        </UncontrolledTooltip>

                                                        <div className="icon-show-user-responsible ui-boards-index mr-2"
                                                            id={`PopoverOwner${task.id}`}
                                                            style={{ marginLeft: '8px' }} >

                                                            {task.user_owner != null ? task.user_owner.first_name.charAt(0) : ""} {task.user_owner != null ? task.user_owner.first_last_name.charAt(0) : ""}
                                                        </div>
                                                        <UncontrolledTooltip placement="top" target={`PopoverOwner${task.id}`}>
                                                            {task.user_owner != null ? task.user_owner.first_name : ""} {task.user_owner != null ? task.user_owner.first_last_name : ""}
                                                        </UncontrolledTooltip>
                                                    </div>
                                                </td>
                                                <td className='ui-indexWeb-width-date-created'>{task.created_date}</td>
                                                <td>{task.due_date}</td>

                                                <td className='ui-tableTask-state-task'>
                                                    {task.state == "filed" ? (
                                                        <React.Fragment>
                                                            {task.count_days}
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <div>{task.count_days} días</div>
                                                            <UncontrolledTooltip target='ui-tooltip-message-time-day' placemenet='top'>{'DÍAS TRANSCURRIDOS DESDE LA CREACIÓN DE LA TAREA'}</UncontrolledTooltip>
                                                            <div><i className="fas fa-stopwatch" id='ui-tooltip-message-time-day'></i></div>

                                                            {task.date.red.show && (
                                                                <React.Fragment>
                                                                    <UncontrolledTooltip target='ui-tooltip-message-expiration' placement='top'>{'DÍAS QUE LLEVA VENCIDA LA TAREA'}</UncontrolledTooltip>
                                                                    <div className='ui-tableTask-days-expiration'>{`${task.date.red.number} días `}</div><div style={{ background: 'red', width: '10px', height: '10px', borderRadius: '50%' }} id='ui-tooltip-message-expiration' className='ui-color-change-task'></div>
                                                                </React.Fragment>
                                                            )}

                                                            {task.date.yellow.show && (
                                                                <React.Fragment>
                                                                    <UncontrolledTooltip target='ui-tooltip-message-expiration-days-cuasi' placement='top'>{'DÍAS PARA QUE SE VENZA LA TAREA'}</UncontrolledTooltip>
                                                                    <div className='ui-tableTask-days-expiration' >{`${task.date.yellow.number} días `}</div><div id='ui-tooltip-message-expiration-days-cuasi' className='ui-color-change-task' style={{ background: 'orange', width: '10px', height: '10px', borderRadius: '50%' }}></div>
                                                                </React.Fragment>
                                                            )}

                                                            {task.date.green.show && (
                                                                <React.Fragment>
                                                                    <UncontrolledTooltip target='ui-tooltip-message-expiration-days-time' placement='top'>{'DÍAS RESTANTES PARA EL VENCIMIENTO DE LA TAREA'}</UncontrolledTooltip>
                                                                    <div className='ui-tableTask-days-expiration'>{`${task.date.green.number}  días`}</div><div id='ui-tooltip-message-expiration-days-time' className='ui-color-change-task' style={{ background: 'green', width: '10px', height: '10px', borderRadius: '50%' }}></div>
                                                                </React.Fragment>
                                                            )}


                                                            {true && (
                                                                <React.Fragment>
                                                                    <UncontrolledTooltip target='ui-tooltip-message-expiration-state' placement='top'>{getStateTask(task.state)}</UncontrolledTooltip>
                                                                    <div className='ui-tableTask-days-expiration'>{getStateTask(task.state)}</div><div id='ui-tooltip-message-expiration-state' className='ui-color-change-task' style={{ background: 'green', width: '10px', height: '10px', borderRadius: '50%' }}></div>
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </td>

                                                <td style={{ justifySelf: 'flex-start', paddingLeft: '5px' }}>
                                                    <div>
                                                        {(props.showOptions && props.estados.edit) && (
                                                            <React.Fragment>
                                                                <a className="ml-2"
                                                                    onClick={(e) => props.showPopover(e, task.id)}
                                                                    id={`PopoverLabels${task.id}`} >
                                                                    <UncontrolledTooltip target='ui-indexWeb_taskBoard_edit_statusTask' placement='top'>{'Editar Columna o Tablero'}</UncontrolledTooltip>
                                                                    <i className="fas fa-pencil-alt pr-3" id='ui-indexWeb_taskBoard_edit_statusTask' style={{ color: '#16aafb' }}></i>
                                                                </a>

                                                                <UncontrolledPopover isOpen={props.task_id == task.id} placement="bottom" target={`PopoverLabels${task.id}`} onClick={(e) => e.stopPropagation()}>
                                                                    <PopoverHeader>Cambiar de columna <a className="close-popover" onClick={() => props.cancelPopoverUpdateColumn()}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                                    <PopoverBody>
                                                                        <PopoverUpdateColumn
                                                                            cancelPopoverUpdateColumn={props.cancelPopoverUpdateColumn}
                                                                            updateColumn={props.updateColumn}
                                                                            task={task}
                                                                            task_boards={props.task_boards}
                                                                        />
                                                                    </PopoverBody>
                                                                </UncontrolledPopover>
                                                            </React.Fragment>
                                                        )}

                                                        {/*<UncontrolledTooltip target='ui-taskBoard_indexWeb_columns' placement='right'>{'Ir al tablero'}</UncontrolledTooltip>*/}
                                                        {task.columna ? ReactHtmlParser(task.columna.label) : ""}
                                                    </div>
                                                </td>
                                                <td className='ui-indexWeb-width-page-hidden'>
                                                    {task.general_comments >= 1 ? (
                                                        <div className="msg-count">
                                                            <React.Fragment>
                                                                <i className="far fa-comments pr-2"></i>
                                                                <b>{task.general_comments}</b>
                                                            </React.Fragment>
                                                        </div>
                                                    ) : (
                                                        <React.Fragment>
                                                            <i className="fas fa-ban"></i>
                                                        </React.Fragment>
                                                    )}
                                                </td>
                                                <td className='ui-indexWeb-width-page-hidden'>
                                                    {task.checklist != null ? (
                                                        <div className="msg-count" >
                                                            <React.Fragment>
                                                                <i className="fas fa-check-double pr-2"></i>
                                                                <b className="ml-1">{task.checklist}</b>
                                                            </React.Fragment>
                                                        </div>
                                                    ) : (
                                                        <React.Fragment>
                                                            <i className="fas fa-ban"></i>
                                                        </React.Fragment>
                                                    )}
                                                </td>
                                                <td className='ui-indexWeb-width-page-hidden'>
                                                    {task.general_files >= 1 ? (
                                                        <div className="msg-count">

                                                            <React.Fragment>
                                                                <i className="fas fa-paperclip pr-2"></i>
                                                                <b className="ml-1">{task.general_files}</b>
                                                            </React.Fragment>

                                                        </div>
                                                    ) : (
                                                        <React.Fragment>
                                                            <i className="fas fa-ban"></i>
                                                        </React.Fragment>
                                                    )}
                                                </td>
                                                <td className='ui-indexWeb-width-page-hidden'>
                                                    {task.notification_task_count >= 1 ? (
                                                        <div className="msg-count">
                                                            <React.Fragment>
                                                                <i className="fas fa-sync-alt  pr-2"></i>
                                                                <b className="ml-1">{task.notification_task_count}</b>
                                                            </React.Fragment>
                                                        </div>
                                                    ) : (
                                                        <React.Fragment>
                                                            <i className="fas fa-ban"></i>
                                                        </React.Fragment>
                                                    )}
                                                </td>
                                                {/* Pantallas pequeñas de web */}
                                                <td className="ui-indexWeb-width-page">
                                                    <div className="ui-indexWeb-width-page-icons">
                                                        {task.general_comments >= 1 ? (
                                                            <div className="msg-count">

                                                                <React.Fragment>
                                                                    <i className="far fa-comments pr-2"></i>
                                                                    <b>{task.general_comments}</b>
                                                                </React.Fragment>

                                                            </div>
                                                        ) : (
                                                            <React.Fragment>
                                                                <div className="ui-indexWeb-container-icons-width">
                                                                    <i className="far fa-comments pr-2"></i>
                                                                    <i className="fas fa-ban"></i>
                                                                </div>
                                                            </React.Fragment>

                                                        )}
                                                    </div>
                                                    <div className="ui-indexWeb-width-page-icons">
                                                        {task.checklist != null ? (
                                                            <div className="msg-count" >
                                                                <React.Fragment>
                                                                    <i className="fas fa-check-double pr-2"></i>
                                                                    <b className="ml-1">{task.checklist}</b>
                                                                </React.Fragment>
                                                            </div>
                                                        ) : (
                                                            <React.Fragment>
                                                                <div className="ui-indexWeb-container-icons-width mt-2">
                                                                    <i className="fas fa-check-double pr-2"></i>
                                                                    <i className="fas fa-ban"></i>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                    <div className="ui-indexWeb-width-page-icons">
                                                        {task.general_files >= 1 ? (
                                                            <div className="msg-count">

                                                                <React.Fragment>
                                                                    <i className="fas fa-paperclip pr-2"></i>
                                                                    <b className="ml-1">{task.general_files}</b>
                                                                </React.Fragment>

                                                            </div>
                                                        ) : (
                                                            <React.Fragment>
                                                                <div className="ui-indexWeb-container-icons-width mt-2">
                                                                    <i className="fas fa-paperclip pr-2"></i>
                                                                    <i className="fas fa-ban"></i>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                    <div className="ui-indexWeb-width-page-icons">
                                                        {task.notification_task_count >= 1 ? (
                                                            <div className="msg-count">
                                                                <React.Fragment>
                                                                    <i className="fas fa-sync-alt  pr-2"></i>
                                                                    <b className="ml-1">{task.notification_task_count}</b>
                                                                </React.Fragment>
                                                            </div>
                                                        ) : (
                                                            <React.Fragment>
                                                                <div className="ui-indexWeb-container-icons-width mt-2">
                                                                    <i className="fas fa-sync-alt  pr-2"></i>
                                                                    <i className="fas fa-ban"></i>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </td>
                                                {/* Fin de pantallas pequeñas */}
                                                <td className='ui-indexWeb-width-icons-delete-edit'>
                                                    {props.restore ? (
                                                        <React.Fragment>
                                                            <a onClick={(e) => props.restoreTask(e, task.id)}
                                                                className="btn ui-indexWeb-width-icon_edit"
                                                                style={{ color: "green", }}
                                                                id={`filed${task.id}`}
                                                            >

                                                                <i className="fas fa-archive"></i>
                                                            </a>
                                                            <UncontrolledTooltip placement="top" target={`filed${task.id}`}>
                                                                {"Restablecer tarea"}
                                                            </UncontrolledTooltip>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <a onClick={(e) => props.editState(e, task.id, "filed")}
                                                                className="btn"
                                                                style={{ color: "green" }}
                                                                id={`filed${task.id}`}>

                                                                <i className="fas fa-archive"></i>
                                                            </a>
                                                            <UncontrolledTooltip placement="top" target={`filed${task.id}`}>
                                                                {"Archivar"}
                                                            </UncontrolledTooltip>
                                                        </React.Fragment>
                                                    )}

                                                    {props.estados.delete && (
                                                        <React.Fragment>
                                                            <a onClick={(e) => props.delete(e, task.id)}
                                                                className="btn"
                                                                style={{ color: "red" }}
                                                                id={`delete${task.id}`}>

                                                                <i className="far fa-trash-alt"></i>
                                                            </a>
                                                            <UncontrolledTooltip placement="top" target={`delete${task.id}`}>
                                                                {"Eliminar"}
                                                            </UncontrolledTooltip>
                                                        </React.Fragment>
                                                    )}
                                                </td>
                                            </tr>
                                        </div>
                                    </div>
                                ))) :
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        <div className="text-center col-md-12">
                                            <div className="text-center mt-4 mb-4" style={{ width: '100%' }}>
                                                {props.estados.create && (
                                                    <React.Fragment>
                                                        {props.restore == undefined ? <p className='ui-tableTask-messageNoRecord'>Todavía no hay tareas creadas, da clic en <a onClick={() => props.toogleNew("new")} className="btn btn-info">+  Nueva</a> y genera la primera tarea.</p> : null}
                                                    </React.Fragment>
                                                )}
                                                <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/7/noRecord_task.jpg" alt="" className="ui-tableTask-imgNoRecord" />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-md-12  ui-index-surveys-fordata-count-register_pagination__container">
                {true && (
                    <div className="row col-md-12">

                        <div className="col-md-2 text-left">
                            <p>
                                Mostrando {props.data.length} de {props.total_data}
                            </p>
                        </div>

                        <div className="col-md-10 pl-0">
                            <Pagination
                                hideNavigation
                                activePage={props.activePage}
                                itemsCountPerPage={props.countPage}
                                itemClass="page-item"
                                innerClass="pagination"
                                linkClass="page-link"
                                totalItemsCount={props.total_data}
                                pageRangeDisplayed={props.countPage}
                                onChange={props.handlePageChange}
                            />
                        </div>

                    </div>
                )}
            </div>
        </div>
    );
}


export default IndexWeb;