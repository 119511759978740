import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import QrReader from '../GeneralComponents/QrReader/QrReader';

const NavTabBar = (props) => {
    return(
        <div className="ui-nav-tabbar-container__tabsIcons card-body">
            <div className="ui-nav-tabbar-icons_links">
                <button className='ui-nav-tabbar-tab btn-open-options' id="TooltipDemo"  data-text='Favoritos'>
                    <i className="fas fa-star"></i>
                </button>
            </div>
            <div className="ui-nav-tabbar-icons_links">
                <a className='ui-nav-tabbar-tab' href={`${props.task_tasks_path}?task=MY`} data-text='Mis Tareas'>
                    <i className="fas fa-thumbtack"></i>
                </a>
            </div>
            <div className="ui-nav-tabbar-icons_links">
                <QrReader />
            </div>
            <div className="ui-nav-tabbar-icons_links">
                <a className='ui-nav-tabbar-tab' href="https://bee-wo.com/formatos/generate_questions/gr4Ryw1GMwMbfq8J5FUuKrKr" data-text='Ticket'>
                    <i className="fas fa-question"></i>
                </a>
            </div>
        </div>

    )
}
export default NavTabBar;
WebpackerReact.setup({ NavTabBar });