import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import IconsCornerNew from '../../../../GeneralComponents/IconsCornerNew';

class IndexWeb extends Component {
    render() {
        return (
            <div className="content main-card mb-3 card card-style">
                <div className="card-body ui-tables_layout-register-card-body">
                    <div className="ui-tables_layout-table__container">
                        {this.props.data.length >= 1 ? (
                            <table className="table ui-tables_layout-table-header__container" id="sampleTable">
                                <thead className='ui-tables_layout-table-header'>
                                    <tr className='ui-tables_layout-__container-title-table'>
                                        <th>Nombre</th>
                                        <th>Fecha de creación</th>
                                        <th>Fecha de vencimiento</th>
                                        <th>Proceso</th>
                                        <th>Responsable</th>
                                        <th>Emisor</th>
                                        <th>Documento</th>
                                        {(this.props.estados.delete || this.props.estados.edit && this.props.state == "obsoleto") && (
                                            <th className="text-center w-9" st>Opciones</th>
                                        )}
                                    </tr>
                                </thead>

                                <tbody className='ui-tables_layout-data__container'>
                                    {this.props.data.map(document => (
                                        <tr key={document.id}>
                                            <td className='pl-3'>
                                                {(this.props.isNewDocument(document.creation_date)) && (
                                                    <IconsCornerNew 
                                                        id={document.id}
                                                        messageTooltip={'Este documento es nuevo'}
                                                    />
                                                )}
                                                {document.name}
                                            </td>
                                            <td>{document.creation_date}</td>
                                            <td style={{ background: this.props.state == "vigente" ? this.props.get_colorDate(document.due_date) : "", color: this.props.state == "vigente" ? "#fff" : "" }}>{document.due_date}</td>
                                            <td>{document.proceso ? document.proceso.name : ""}</td>
                                            <td>{document.responsable_user.first_name} {document.responsable_user.first_last_name}</td>
                                            <td>{document.emisor}</td>
                                            <td><a  target="_blank" href={document.attached.url ? document.attached.url : ""}>{document.attached.url ? "Ver Documento" : ""}</a></td>

                                            {(this.props.estados.edit || this.props.estados.delete && this.props.state == "obsoleto") && (
                                                <td className="text-center">
                                                    <UncontrolledDropdown className='btn-group ui-tableIndexWeb-positions-dropdown__container'>
                                                        <DropdownToggle>
                                                            <i className="fas fa-bars"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="ui-tableIndexWeb-positions-dropdown_header dropdown-menu-right">
                                                            {this.props.estados.edit && (
                                                                <DropdownItem
                                                                    onClick={() => this.props.edit(document)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Editar
                                                                </DropdownItem>
                                                            )}

                                                            {(this.props.estados.delete && this.props.state == "obsoleto")  && (
                                                                <DropdownItem
                                                                    onClick={() => this.props.delete(document.id)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Eliminar
                                                                </DropdownItem>
                                                            )}

                                                            {this.props.state != "obsoleto" && (
                                                                <DropdownItem
                                                                    onClick={() => this.props.changeState(document.id, "obsoleto")}
                                                                    className="dropdown-item"
                                                                >
                                                                    Pasar a obsoletos
                                                                </DropdownItem>
                                                            )}

                                                            {this.props.state == "obsoleto" && (
                                                                <DropdownItem
                                                                    onClick={() => this.props.changeState(document.id, "vigente")}
                                                                    className="dropdown-item"
                                                                >
                                                                    Pasar a activo
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="text-center mt-4 mb-4" style={{width: '100%'}}>
                                        <p className='ui-indexWeb-messageNoRecord'>Todavía no hay registros creados, da clic en <a onClick={() => this.props.toogle('new')} className=" btn btn-info"><i className='fas fa-file-alt'></i> Nuevo documento</a> y genera el primer registro.</p>
                                        <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/9/noRecord_documental.jpg" alt="" className="ui-indexWeb-imgNoRecord" />
                                    </div>
                                </div>
                            </div>
                        )} 
                    </div>       

                    {true && (
                        <div className="row mt-3">

                            <div className="col-md-2 text-left">
                                <p>
                                    Mostrando {this.props.data.length} de {this.props.totalData}
                                </p>
                            </div>

                            <div className="col-md-10 pl-0">
                                <Pagination
                                    hideNavigation
                                    activePage={this.props.activePage}
                                    itemsCountPerPage={this.props.countPage}
                                    itemClass="page-item"
                                    innerClass="pagination"
                                    linkClass="page-link"
                                    totalItemsCount={this.props.totalData}
                                    pageRangeDisplayed={this.props.countPage}
                                    onChange={this.props.handlePageChange}
                                />
                            </div>

                        </div>
                    )}

                </div>
            </div>
        );
    }
}

export default IndexWeb;
