import React, { Component } from 'react';
import Select from "react-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],

            questions_target: [],
            format_associations: [],

            isLoaded: true,

            ErrorValues: true,
            modeEdit: false,

            question_filter_format_id: "",

            formCreate: {
                question_id: this.props.question.id,
                format_association_id: "",
                question_target_id: "",
            },

            formUpdate: {
                question_id: this.props.question.id,
                format_association_id: "",
                question_target_id: "",
            },

            selectedOptionQuestionTarget: {
                question_target_id: "",
                label: "Pregunta"
            },

            selectedOptionFormatAssociation: {
                format_association_id: "",
                label: "Pregunta"
            },

            selectedOptionQuestionTargetUpdate: {
                question_target_id: "",
                label: "Pregunta"
            },

            selectedOptionFormatAssociationUpdate: {
                format_association_id: "",
                label: "Pregunta"
            },
        }
    }

    componentDidMount = () => {
        this.loadData();
        this.loadDataFormatAssociations();
        this.loadDataQuestionTarget();
    }

    loadData = () => {
        fetch(`/formatos/get_question_filter_formats/${this.props.question.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    isLoaded: false
                });
            });
    }

    loadDataFormatAssociations = () => {
        fetch(`/formatos/get_format_associations/${this.props.question.format_relation_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                let array = [];

                data.data.map((item) => (
                    array.push({ label: item.format_select.name, value: item.id })
                ))

                this.setState({
                    format_associations: array
                })
            });
    }


    loadDataQuestionTarget = () => {
        fetch(`/formatos/get_questions/${this.props.formato.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                let array = [];

                data.register.map((item) => (
                    array.push({ label: item.question, value: item.id, type: item.question_type, options_values: item.options })
                ))

                this.setState({
                    questions_target: array
                })
            });
    }


    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = config => {
        this.setState({
            data: this.state.data.map(item => {
                if (config.id === item.id) {
                    return {
                        ...item,
                        format_association: config.format_association,
                        question_target: config.question_target,
                    }
                }
                return item;
            })
        });
    }

    HandleClick = () => {
        if (this.validationForm()) {
            if (this.state.modeEdit) {
                fetch(`/formatos/question_filter_formats/${this.state.question_filter_format_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.updateItem(data.register)
                        this.messageSuccess(data);
                    });
            } else {
                fetch(`/formatos/question_filter_formats`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.updateData(data.register)
                        this.messageSuccess(data);
                    });
            }
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_filter_formats/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    edit = (question_format_input) => {
        this.setState({
            question_filter_format_id: question_format_input.id,
            modeEdit: true,
            formUpdate: {
                ...this.state.formUpdate,
                format_association_id: question_format_input.format_association.id,
                question_target_id: question_format_input.question_target.id,
            },

            selectedOptionFormatAssociationUpdate: {
                format_association_id: question_format_input.format_association.id,
                label: question_format_input.format_association.format_select.name,
            },

            selectedOptionQuestionTargetUpdate: {
                question_target_id: question_format_input.question_target.id,
                label: question_format_input.question_target.question,
            },
        })
    }

    validationForm = () => {
        if (this.state.formCreate.format_association_id != "" &&
            this.state.formCreate.question_target_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    clearValues = () => {

        this.setState({
            ErrorValues: true,
            modeEdit: false,
            question_filter_format_id: "",

            formCreate: {
                ...this.state.formCreate,
                format_association_id: "",
                question_target_id: "",
            },

            formUpdate: {
                ...this.state.formUpdate,
                format_association_id: "",
                question_target_id: "",
            },

            selectedOptionQuestionTarget: {
                question_target_id: "",
                label: "Pregunta"
            },

            selectedOptionFormatAssociation: {
                format_association_id: "",
                label: "Pregunta"
            },

            selectedOptionQuestionTargetUpdate: {
                question_target_id: "",
                label: "Pregunta"
            },

            selectedOptionFormatAssociationUpdate: {
                format_association_id: "",
                label: "Pregunta"
            },
        })
    }


    handleChangeAutocompleteQuestionTarget = selectedOptionQuestionTarget => {
        if (this.state.question_filter_format_id) {
            const selectedOptionQuestionTargetUpdate = selectedOptionQuestionTarget

            this.setState({
                selectedOptionQuestionTargetUpdate,
                formUpdate: {
                    ...this.state.formUpdate,
                    question_target_id: selectedOptionQuestionTargetUpdate.value,
                },
            });
        } else {
            this.setState({
                selectedOptionQuestionTarget,
                formCreate: {
                    ...this.state.formCreate,
                    question_target_id: selectedOptionQuestionTarget.value,
                },
            });
        }
    };

    handleChangeAutocompleteFormatAssociation = selectedOptionFormatAssociation => {
        if (this.state.question_filter_format_id) {
            const selectedOptionFormatAssociationUpdate = selectedOptionFormatAssociation

            this.setState({
                selectedOptionFormatAssociationUpdate,
                formUpdate: {
                    ...this.state.formUpdate,
                    format_association_id: selectedOptionFormatAssociationUpdate.value,
                },
            });
        } else {
            this.setState({
                selectedOptionFormatAssociation,
                formCreate: {
                    ...this.state.formCreate,
                    format_association_id: selectedOptionFormatAssociation.value,
                },
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="card mb-2 mb-3">
                    <div className="card-body p-2">
                        <div className="row">

                            <div className={`col-md-6`}>
                                <label>Asociación de formatos</label>
                                <input
                                    type="hidden"
                                    name="format_association_id"
                                    value={this.state.selectedOptionFormatAssociation.format_association_id}
                                />

                                <Select
                                    onChange={this.handleChangeAutocompleteFormatAssociation}
                                    options={this.state.format_associations}
                                    autoFocus={false}
                                    className={`link-form ${!this.state.ErrorValues && this.state.formCreate.format_association_id == "" ? "error-class" : ""}`}
                                    value={this.state.selectedOptionFormatAssociation}
                                />
                            </div>

                            <div className={`col-md-6`}>
                                <label>Pregunta Objetivo</label>
                                <input
                                    type="hidden"
                                    name="question_target_id"
                                    value={this.state.selectedOptionQuestionTarget.question_target_id}
                                />
                                <Select
                                    onChange={this.handleChangeAutocompleteQuestionTarget}
                                    options={this.state.questions_target}
                                    autoFocus={false}
                                    className={`link-form ${!this.state.ErrorValues && this.state.formCreate.question_target_id == "" ? "error-class" : ""}`}
                                    value={this.state.selectedOptionQuestionTarget}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="card-footer">
                        <button
                            className="btn-shadow btn btn-info mr-2"
                            onClick={() => this.HandleClick()}
                        >
                            Guardar
                        </button>

                        <button
                            className="btn btn-outline-danger"
                            onClick={() => this.clearValues()}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>

                <div className="content main-card mb-3 card">
                    <div className="card-body p-1">

                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>

                                    <th style={{ width: "26%" }}>Asociación de formato</th>
                                    <th style={{ width: "26%" }}>Pregunta objetivo</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.data.length >= 1 ? (
                                    this.state.data.map((question_filter_format, index) => (
                                        <tr key={question_filter_format.id}>
                                            <td>
                                                {question_filter_format.id == this.state.question_filter_format_id ? (
                                                    <React.Fragment>
                                                        <input
                                                            type="hidden"
                                                            name="format_association_id"
                                                            value={this.state.selectedOptionFormatAssociationUpdate.format_association_id}
                                                        />
                                                        <Select
                                                            onChange={this.handleChangeAutocompleteFormatAssociation}
                                                            options={this.state.format_associations}
                                                            autoFocus={false}
                                                            className={`link-form`}
                                                            value={this.state.selectedOptionFormatAssociationUpdate}
                                                        />
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {question_filter_format.format_association ? question_filter_format.format_association.format_select.name : ""}
                                                    </React.Fragment>
                                                )}
                                            </td>
                                            <td>
                                                {(question_filter_format.id == this.state.question_filter_format_id) ? (
                                                    <React.Fragment>
                                                        <input
                                                            type="hidden"
                                                            name="question_target_id"
                                                            value={this.state.selectedOptionQuestionTargetUpdate.question_target_id}
                                                        />
                                                        <Select
                                                            onChange={this.handleChangeAutocompleteQuestionTarget}
                                                            options={this.state.questions_target}
                                                            autoFocus={false}
                                                            className={`link-form`}
                                                            value={this.state.selectedOptionQuestionTargetUpdate}
                                                        />
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {question_filter_format.question_target.question}
                                                    </React.Fragment>
                                                )}
                                            </td>


                                            <td className="text-right" style={{ width: "11%" }}>
                                                {question_filter_format.id == this.state.question_filter_format_id ? (
                                                    <React.Fragment>
                                                        <i className="fas fa-check-circle update-section" onClick={() => this.HandleClick()}></i>
                                                        <i className="fas fa-times-circle update-false" onClick={() => this.clearValues()}></i>
                                                    </React.Fragment>
                                                ) : (
                                                    <UncontrolledDropdown className='btn-group'>
                                                        <DropdownToggle className='btn btn-info'>
                                                            <i className="fas fa-bars"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                            {true && (
                                                                <DropdownItem
                                                                    onClick={() => this.edit(question_filter_format)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Editar
                                                                </DropdownItem>
                                                            )}

                                                            {true && (
                                                                <DropdownItem
                                                                    onClick={() => this.delete(question_filter_format.id)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Eliminar
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                )}
                                            </td>


                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            <div className="text-center mt-4 mb-4">
                                                <h4>No hay registros</h4>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
