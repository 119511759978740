import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

const ModalEdit = (props) => {
    const token = document.querySelector("[name='csrf-token']").content;
    const [questions, setQuestions] = useState([]);
    const [errorValues, setErrorValues] = useState(true);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isLoadedQuestion, setIsLoadedQuestion] = useState(true);
    const [form, setForm] = useState({ 
        first_name_question_id: "",
        second_name_question_id: "",
        first_last_name_question_id: "",
        second_last_name_question_id: "",
        full_name_question_id: "",
        document_type_question_id: "",
        document_question_id: "",
        expedition_date_question_id: "",
        expedition_location_question_id: "",
        country_question_id: "",
        department_question_id: "",
        city_question_id: "",
        address_question_id: "",
        phone_question_id: "",
        mobile_question_id: "",
        email_question_id: "",
        gender_question_id: "",
        blood_type_question_id: "",
        civil_status_question_id: "",
        date_of_birth_question_id: "",
        driving_license_question_id: "",
        license_category_question_id: "",
        license_expiration_question_id: "",
        license_restrictions_question_id: "",
        vehicle_type_experience_question_id: "",
        transport_type_experience_question_id: "",
        study_level_question_id: ""
    });

    //select values 
    const [selectedOptionFirstNameQuestion, setSelectedOptionFirstNameQuestion] = useState({ label: "Primer Nombre", first_name_question_id: "" });
    const [selectedOptionSecondNameQuestion, setSelectedOptionSecondNameQuestion] = useState({ label: "Segundo Nombre", second_name_question_id: "" });
    const [selectedOptionFirstLastNameQuestion, setSelectedOptionFirstLastNameQuestion] = useState({ label: "Primer Apellido", first_last_name_question_id: "" });
    const [selectedOptionSecondLastNameQuestion, setSelectedOptionSecondLastNameQuestion] = useState({ label: "Segundo Apellido", second_last_name_question_id: "" });
    const [selectedOptionFullNameQuestion, setSelectedOptionFullNameQuestion] = useState({ label: "Nombre Completo", full_name_question_id: "" });
    const [selectedOptionDocumentTypeQuestion, setSelectedOptionDocumentTypeQuestion] = useState({ label: "Tipo de Documento", document_type_question_id: "" });
    const [selectedOptionDocumentQuestion, setSelectedOptionDocumentQuestion] = useState({ label: "Documento", document_question_id: "" });
    const [selectedOptionExpeditionDateQuestion, setSelectedOptionExpeditionDateQuestion] = useState({ label: "Fecha de Expedición", expedition_date_question_id: "" });
    const [selectedOptionExpeditionLocationQuestion, setSelectedOptionExpeditionLocationQuestion] = useState({ label: "Lugar De Expedición", expedition_location_question_id: "" });
    const [selectedOptionCountryQuestion, setSelectedOptionCountryQuestion] = useState({ label: "País", country_question_id: "" });
    const [selectedOptionDepartmentQuestion, setSelectedOptionDepartmentQuestion] = useState({ label: "Departamento", department_question_id: "" });
    const [selectedOptionOptionCityQuestion, setSelectedOptionCityQuestion] = useState({ label: "Ciudad", city_question_id: "" });
    const [selectedOptionAddressQuestion, setSelectedOptionAddressQuestion] = useState({ label: "Dirección", address_question_id: "" });
    const [selectedOptionPhoneQuestion, setSelectedOptionPhoneQuestion] = useState({ label: "Teléfono", phone_question_id: "" });
    const [selectedOptionMobileQuestion, setSelectedOptionMobileQuestion] = useState({ label: "Celular", mobile_question_id: "" });
    const [selectedOptionEmailQuestion, setSelectedOptionEmailQuestion] = useState({ label: "Correo", email_question_id: "" });
    const [selectedOptionGenderQuestion, setSelectedOptionGenderQuestion] = useState({ label: "Género", gender_question_id: "" });
    const [selectedOptionBloodTypeQuestion, setSelectedOptionBloodTypeQuestion] = useState({ label: "Tipo De Sangre", blood_type_question_id: "" });
    const [selectedOptionCityStatusQuestion, setSelectedOptionCityStatusQuestion] = useState({ label: "Estado Civil", civil_status_question_id: "" });
    const [selectedOptionDateOfBirthQuestion, setSelectedOptionDateOfBirthQuestion] = useState({ label: "Fecha De Nacimiento", date_of_birth_question_id: "" });
    const [selectedOptionDrivingLicenseQuestion, setSelectedOptionDrivingLicenseQuestion] = useState({ label: "Licencia Conducción", driving_license_question_id: "" });
    const [selectedOptionLicenseCategoryQuestion, setSelectedOptionLicenseCategoryQuestion] = useState({ label: "Categoría Licencia", license_category_question_id: "" });
    const [selectedOptionLicenseExpirationQuestion, setSelectedOptionLicenseExpirationQuestion] = useState({ label: "Vencimiento Licencia", license_expiration_question_id: "" });
    const [selectedOptionLicenseRestrictionsQuestion, setSelectedOptionLicenseRestrictionsQuestion] = useState({ label: "Restricciones De Licencia", license_restrictions_question_id: "" });
    const [selectedOptionVehicleTypeExperienceQuestion, setSelectedOptionVehicleTypeExperienceQuestion] = useState({ label: "Experiencia Tipo De Vehículo", vehicle_type_experience_question_id: "" });
    const [selectedOptionTransportTypeExperienceQuestion, setSelectedOptionTransportTypeExperienceQuestion] = useState({ label: "Experiencia Tipo Transporte", transport_type_experience_question_id: "" });
    const [selectedOptionStudyLevelQuestion, setSelectedOptionStudyLevelQuestion] = useState({ label: "Nivel De Estudio", study_level_question_id: "" });

    useEffect(() => {
        loadData();
        loadQuestions();
    }, []);

    const updateSelectValues = (trs_driver_configuration) => {
        setSelectedOptionFirstNameQuestion(trs_driver_configuration.first_name_question);
        setSelectedOptionSecondNameQuestion(trs_driver_configuration.second_name_question);
        setSelectedOptionFirstLastNameQuestion(trs_driver_configuration.first_last_name_question);
        setSelectedOptionSecondLastNameQuestion(trs_driver_configuration.second_last_name_question);
        setSelectedOptionFullNameQuestion(trs_driver_configuration.full_name_question);
        setSelectedOptionDocumentTypeQuestion(trs_driver_configuration.document_type_question);
        setSelectedOptionDocumentQuestion(trs_driver_configuration.document_question);
        setSelectedOptionExpeditionDateQuestion(trs_driver_configuration.expedition_date_question);
        setSelectedOptionExpeditionLocationQuestion(trs_driver_configuration.expedition_location_question);
        setSelectedOptionCountryQuestion(trs_driver_configuration.country_question);
        setSelectedOptionDepartmentQuestion(trs_driver_configuration.department_question);
        setSelectedOptionCityQuestion(trs_driver_configuration.city_question);
        setSelectedOptionAddressQuestion(trs_driver_configuration.address_question);
        setSelectedOptionPhoneQuestion(trs_driver_configuration.phone_question);
        setSelectedOptionMobileQuestion(trs_driver_configuration.mobile_question);
        setSelectedOptionEmailQuestion(trs_driver_configuration.email_question);
        setSelectedOptionGenderQuestion(trs_driver_configuration.gender_question);
        setSelectedOptionBloodTypeQuestion(trs_driver_configuration.blood_type_question);
        setSelectedOptionCityStatusQuestion(trs_driver_configuration.civil_status_question);
        setSelectedOptionDateOfBirthQuestion(trs_driver_configuration.date_of_birth_question);
        setSelectedOptionDrivingLicenseQuestion(trs_driver_configuration.driving_license_question);
        setSelectedOptionLicenseCategoryQuestion(trs_driver_configuration.license_category_question);
        setSelectedOptionLicenseExpirationQuestion(trs_driver_configuration.license_expiration_question);
        setSelectedOptionLicenseRestrictionsQuestion(trs_driver_configuration.license_restrictions_question);
        setSelectedOptionVehicleTypeExperienceQuestion(trs_driver_configuration.vehicle_type_experience_question);
        setSelectedOptionTransportTypeExperienceQuestion(trs_driver_configuration.transport_type_experience_question);
        setSelectedOptionStudyLevelQuestion(trs_driver_configuration.study_level_question);

        setForm({ 
            first_name_question_id: trs_driver_configuration.first_name_question.value,
            second_name_question_id: trs_driver_configuration.second_name_question.value,
            first_last_name_question_id: trs_driver_configuration.first_last_name_question.value,
            second_last_name_question_id: trs_driver_configuration.second_last_name_question.value,
            full_name_question_id: trs_driver_configuration.full_name_question.value,
            document_type_question_id: trs_driver_configuration.document_type_question.value,
            document_question_id: trs_driver_configuration.document_question.value,
            expedition_date_question_id: trs_driver_configuration.expedition_date_question.value,
            expedition_location_question_id: trs_driver_configuration.expedition_location_question.value,
            country_question_id: trs_driver_configuration.country_question.value,
            department_question_id: trs_driver_configuration.department_question.value,
            city_question_id: trs_driver_configuration.city_question.value,
            address_question_id: trs_driver_configuration.address_question.value,
            phone_question_id: trs_driver_configuration.phone_question.value,
            mobile_question_id: trs_driver_configuration.mobile_question.value,
            email_question_id: trs_driver_configuration.email_question.value,
            gender_question_id: trs_driver_configuration.gender_question.value,
            blood_type_question_id: trs_driver_configuration.blood_type_question.value,
            civil_status_question_id: trs_driver_configuration.civil_status_question.value,
            date_of_birth_question_id: trs_driver_configuration.date_of_birth_question.value,
            driving_license_question_id: trs_driver_configuration.driving_license_question.value,
            license_category_question_id: trs_driver_configuration.license_category_question.value,
            license_expiration_question_id: trs_driver_configuration.license_expiration_question.value,
            license_restrictions_question_id: trs_driver_configuration.license_restrictions_question.value,
            vehicle_type_experience_question_id: trs_driver_configuration.vehicle_type_experience_question.value,
            transport_type_experience_question_id: trs_driver_configuration.transport_type_experience_question.value,
            study_level_question_id: trs_driver_configuration.study_level_question.value,
        })
    }
    

    const handleChangeSelectFirstNameQuestion = (selectedOption) => {
        setSelectedOptionFirstNameQuestion(selectedOption)
        updateForm("first_name_question_id", selectedOption.value)
    }

    const handleChangeSelectSecondNameQuestion = (selectedOption) => {
        setSelectedOptionSecondNameQuestion(selectedOption)
        updateForm("second_name_question_id", selectedOption.value)
    }

    const handleChangeSelectFirstLastNameQuestion = (selectedOption) => {
        setSelectedOptionFirstLastNameQuestion(selectedOption)
        updateForm("first_last_name_question_id", selectedOption.value)
    }

    const handleChangeSelectSecondLastNameQuestion = (selectedOption) => {
        setSelectedOptionSecondLastNameQuestion(selectedOption)
        updateForm("second_last_name_question_id", selectedOption.value)
    }
    
    const handleChangeSelectFullNameQuestion = (selectedOption) => {
        setSelectedOptionFullNameQuestion(selectedOption)
        updateForm("full_name_question_id", selectedOption.value)
    }

    const handleChangeSelectDocumentTypeQuestion = (selectedOption) => {
        setSelectedOptionDocumentTypeQuestion(selectedOption)
        updateForm("document_type_question_id", selectedOption.value)
    }

    const handleChangeSelectDocumentQuestion = (selectedOption) => {
        setSelectedOptionDocumentQuestion(selectedOption)
        updateForm("document_question_id", selectedOption.value)
    }

    const handleChangeSelectExpeditionDateQuestion = (selectedOption) => {
        setSelectedOptionExpeditionDateQuestion(selectedOption)
        updateForm("expedition_date_question_id", selectedOption.value)
    }

    const handleChangeSelectExpeditionLocationQuestion = (selectedOption) => {
        setSelectedOptionExpeditionLocationQuestion(selectedOption)
        updateForm("expedition_location_question_id", selectedOption.value)
    }
    
    const handleChangeSelectCountryQuestion = (selectedOption) => {
        setSelectedOptionCountryQuestion(selectedOption)
        updateForm("country_question_id", selectedOption.value)
    }

    const handleChangeSelectDepartmentQuestion = (selectedOption) => {
        setSelectedOptionDepartmentQuestion(selectedOption)
        updateForm("department_question_id", selectedOption.value)
    }

    const handleChangeSelectCityQuestion = (selectedOption) => {
        setSelectedOptionCityQuestion(selectedOption)
        updateForm("city_question_id", selectedOption.value)
    }

    const handleChangeSelectAddressQuestion = (selectedOption) => {
        setSelectedOptionAddressQuestion(selectedOption)
        updateForm("address_question_id", selectedOption.value)
    }

    const handleChangeSelectPhoneQuestion = (selectedOption) => {
        setSelectedOptionPhoneQuestion(selectedOption)
        updateForm("phone_question_id", selectedOption.value)
    }

    const handleChangeSelectMobileQuestion = (selectedOption) => {
        setSelectedOptionMobileQuestion(selectedOption)
        updateForm("mobile_question_id", selectedOption.value)
    }

    const handleChangeSelectEmailQuestion = (selectedOption) => {
        setSelectedOptionEmailQuestion(selectedOption)
        updateForm("email_question_id", selectedOption.value)
    }

    const handleChangeSelectGenderQuestion = (selectedOption) => {
        setSelectedOptionGenderQuestion(selectedOption)
        updateForm("gender_question_id", selectedOption.value)
    }

    const handleChangeSelectBloodTypeQuestion = (selectedOption) => {
        setSelectedOptionBloodTypeQuestion(selectedOption)
        updateForm("blood_type_question_id", selectedOption.value)
    }

    const handleChangeSelectCityStatusQuestion = (selectedOption) => {
        setSelectedOptionCityStatusQuestion(selectedOption)
        updateForm("civil_status_question_id", selectedOption.value)
    }

    const handleChangeSelectDateOfBirthQuestion = (selectedOption) => {
        setSelectedOptionDateOfBirthQuestion(selectedOption)
        updateForm("date_of_birth_question_id", selectedOption.value)
    }

    const handleChangeSelectDrivingLicenseQuestion = (selectedOption) => {
        setSelectedOptionDrivingLicenseQuestion(selectedOption)
        updateForm("driving_license_question_id", selectedOption.value)
    }

    const handleChangeSelectLicenseCategoryQuestion = (selectedOption) => {
        setSelectedOptionLicenseCategoryQuestion(selectedOption)
        updateForm("license_category_question_id", selectedOption.value)
    }

    const handleChangeSelectLicenseExpirationQuestion = (selectedOption) => {
        setSelectedOptionLicenseExpirationQuestion(selectedOption)
        updateForm("license_expiration_question_id", selectedOption.value)
    }

    const handleChangeSelectLicenseRestrictionsQuestion = (selectedOption) => {
        setSelectedOptionLicenseRestrictionsQuestion(selectedOption)
        updateForm("license_restrictions_question_id", selectedOption.value)
    }

    const handleChangeSelectVehicleTypeExperienceQuestion = (selectedOption) => {
        setSelectedOptionVehicleTypeExperienceQuestion(selectedOption)
        updateForm("vehicle_type_experience_question_id", selectedOption.value)
    }

    const handleChangeSelectTransportTypeExperienceQuestion = (selectedOption) => {
        setSelectedOptionTransportTypeExperienceQuestion(selectedOption)
        updateForm("transport_type_experience_question_id", selectedOption.value)
    }
    
    const handleChangeSelectStudyLevelQuestion = (selectedOption) => {
        setSelectedOptionStudyLevelQuestion(selectedOption)
        updateForm("study_level_question_id", selectedOption.value)
    }

    const updateForm = (field, value) => {
        setForm({ ...form, [field]: value }) 
    }
        
    const handleSubmit = e => {
        e.preventDefault();
    };
    
    const handleClick = () => {
        fetch(`/formatos/trs_driver_configurations/${props.trs_driver_review_configuration.trs_driver_configuration.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => { 
            props.messageSuccess(data);
            props.toggle();
        });
    }


    const loadData = () => {
        fetch(`/formatos/get_trs_driver_configuration/${props.trs_driver_review_configuration.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            updateSelectValues(data.register);
            setIsLoaded(false)
        });
    }

    const loadQuestions = () => {
        fetch(`/formatos/get_format_questions/${props.trs_driver_review_configuration.format_personal.value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setQuestions(data.data);
            setIsLoadedQuestion(false)
        });
    }

    const isLoadedTotal = (isLoaded && isLoadedQuestion) ? true : false

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered modal-lg" backdrop={props.backdrop}>
                {/*<ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>*/}

                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                {isLoadedTotal ? (
                                    <div className="col-md-12 mb-4">
                                        <div className="alert text-center alert-primary" role="alert">
                                            <b>Cargando informacion....</b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-md-12 mb-4">
                                        <div className="alert text-center alert-primary" role="alert">
                                            <h5>Preguntas del formato: <b>{props.trs_driver_review_configuration.format_personal.label}</b></h5>
                                        </div>
                                    </div>
                                )}

                                <div className="col-md-6 mb-4">
                                    <label>Primer Nombre</label>
                                    <input
                                        type="hidden"
                                        name="first_name_question_id"
                                        value={selectedOptionFirstNameQuestion.first_name_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectFirstNameQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.first_name_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionFirstNameQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Segundo Nombre</label>
                                    <input
                                        type="hidden"
                                        name="second_name_question_id"
                                        value={selectedOptionSecondNameQuestion.second_name_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectSecondNameQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.second_name_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionSecondNameQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Primer Apellido</label>
                                    <input
                                        type="hidden"
                                        name="first_last_name_question_id"
                                        value={selectedOptionFirstLastNameQuestion.first_last_name_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectFirstLastNameQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.first_last_name_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionFirstLastNameQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Segundo Apellido</label>
                                    <input
                                        type="hidden"
                                        name="second_last_name_question_id"
                                        value={selectedOptionSecondLastNameQuestion.second_last_name_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectSecondLastNameQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.second_last_name_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionSecondLastNameQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Nombre Completo</label>
                                    <input
                                        type="hidden"
                                        name="full_name_question_id"
                                        value={selectedOptionFullNameQuestion.full_name_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectFullNameQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.full_name_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionFullNameQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Tipo de Documento</label>
                                    <input
                                        type="hidden"
                                        name="document_type_question_id"
                                        value={selectedOptionDocumentTypeQuestion.document_type_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectDocumentTypeQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.document_type_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionDocumentTypeQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Documento</label>
                                    <input
                                        type="hidden"
                                        name="document_question_id"
                                        value={selectedOptionDocumentQuestion.document_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectDocumentQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.document_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionDocumentQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Fecha de Expedición</label>
                                    <input
                                        type="hidden"
                                        name="expedition_date_question_id"
                                        value={selectedOptionExpeditionDateQuestion.expedition_date_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectExpeditionDateQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.expedition_date_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionExpeditionDateQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Lugar De Expedición</label>
                                    <input
                                        type="hidden"
                                        name="expedition_location_question_id"
                                        value={selectedOptionExpeditionLocationQuestion.expedition_location_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectExpeditionLocationQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.expedition_location_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionExpeditionLocationQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>País</label>
                                    <input
                                        type="hidden"
                                        name="country_question_id"
                                        value={selectedOptionCountryQuestion.country_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectCountryQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.country_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionCountryQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Departamento</label>
                                    <input
                                        type="hidden"
                                        name="department_question_id"
                                        value={selectedOptionDepartmentQuestion.department_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectDepartmentQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.department_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionDepartmentQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Ciudad</label>
                                    <input
                                        type="hidden"
                                        name="city_question_id"
                                        value={selectedOptionOptionCityQuestion.city_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectCityQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.city_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionOptionCityQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Dirección</label>
                                    <input
                                        type="hidden"
                                        name="address_question_id"
                                        value={selectedOptionAddressQuestion.address_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectAddressQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.address_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionAddressQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Teléfono</label>
                                    <input
                                        type="hidden"
                                        name="phone_question_id"
                                        value={selectedOptionPhoneQuestion.phone_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectPhoneQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.phone_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionPhoneQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Celular</label>
                                    <input
                                        type="hidden"
                                        name="mobile_question_id"
                                        value={selectedOptionMobileQuestion.mobile_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectMobileQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.mobile_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionMobileQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Correo</label>
                                    <input
                                        type="hidden"
                                        name="email_question_id"
                                        value={selectedOptionEmailQuestion.email_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectEmailQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.email_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionEmailQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Género</label>
                                    <input
                                        type="hidden"
                                        name="gender_question_id"
                                        value={selectedOptionGenderQuestion.gender_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectGenderQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.gender_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionGenderQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Tipo De Sangre</label>
                                    <input
                                        type="hidden"
                                        name="blood_type_question_id"
                                        value={selectedOptionBloodTypeQuestion.blood_type_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectBloodTypeQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.blood_type_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionBloodTypeQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Estado Civil</label>
                                    <input
                                        type="hidden"
                                        name="civil_status_question_id"
                                        value={selectedOptionCityStatusQuestion.civil_status_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectCityStatusQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.civil_status_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionCityStatusQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Fecha De Nacimiento</label>
                                    <input
                                        type="hidden"
                                        name="date_of_birth_question_id"
                                        value={selectedOptionDateOfBirthQuestion.date_of_birth_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectDateOfBirthQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.date_of_birth_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionDateOfBirthQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Licencia Conducción</label>
                                    <input
                                        type="hidden"
                                        name="driving_license_question_id"
                                        value={selectedOptionDrivingLicenseQuestion.driving_license_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectDrivingLicenseQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.driving_license_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionDrivingLicenseQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Categoría Licencia</label>
                                    <input
                                        type="hidden"
                                        name="license_category_question_id"
                                        value={selectedOptionLicenseCategoryQuestion.license_category_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectLicenseCategoryQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.license_category_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionLicenseCategoryQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Vencimiento Licencia</label>
                                    <input
                                        type="hidden"
                                        name="license_expiration_question_id"
                                        value={selectedOptionLicenseExpirationQuestion.license_expiration_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectLicenseExpirationQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.license_expiration_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionLicenseExpirationQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Restricciones De Licencia</label>
                                    <input
                                        type="hidden"
                                        name="license_restrictions_question_id"
                                        value={selectedOptionLicenseRestrictionsQuestion.license_restrictions_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectLicenseRestrictionsQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.license_restrictions_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionLicenseRestrictionsQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Experiencia Tipo De Vehículo</label>
                                    <input
                                        type="hidden"
                                        name="vehicle_type_experience_question_id"
                                        value={selectedOptionVehicleTypeExperienceQuestion.vehicle_type_experience_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectVehicleTypeExperienceQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.vehicle_type_experience_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionVehicleTypeExperienceQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Experiencia Tipo Transporte</label>
                                    <input
                                        type="hidden"
                                        name="transport_type_experience_question_id"
                                        value={selectedOptionTransportTypeExperienceQuestion.transport_type_experience_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectTransportTypeExperienceQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.transport_type_experience_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionTransportTypeExperienceQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Nivel De Estudio</label>
                                    <input
                                        type="hidden"
                                        name="study_level_question_id"
                                        value={selectedOptionStudyLevelQuestion.study_level_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectStudyLevelQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.study_level_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionStudyLevelQuestion}
                                    />
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                            {!isLoadedTotal && (
                                <button className="btn-shadow btn btn-info" onClick={handleClick}>{"Actualizar"}</button>  
                            )}
                        </ModalFooter>
                    </form>
            </Modal>
        </React.Fragment>
    );
}


export default ModalEdit;

