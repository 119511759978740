import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { isMobile } from 'react-device-detect';

import IndexMobile from "./Movil/Index";
import IndexWeb from "./Movil/IndexWeb";
import ModalEditPosition from './ModalEditPosition';

//moment
import moment from 'moment';
import { data } from 'jquery';

class Index extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modalEditPosition: false,
            modeEdit: false,
            ErrorValues: true,
            has_process: false,
            is_clone: false,
            editInputs: false,
            isLoaded: false,
            id: "",
            dateCache: new Map(),

            formCreate: {
                name: "",
                document_type_id: "",
                proceso_id: "",
                code: "",
                document: "",
                attached: {},
                is_attached: false,
                user_create_id: "",
                user_review_id: "",
                user_approve_id: "",
                validity: "",
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                version: "",
                position_ids: [this.props.current_position[0].value],
                document_parent_id: "",
                tenant_id: "",
                count: "",
            },

            selectedOptionUserCreate: {
                user_create_id: "",
                label: "Usuario que crea"
            },

            selectedOptionUserReview: {
                user_review_id: "",
                label: "Usuario que revisa"
            },

            selectedOptionUserApprove: {
                user_approve_id: "",
                label: "Usuario que aprueba"
            },

            selectedOptionDocumentType: {
                document_type_id: "",
                label: "Tipo de documento"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },

            selectedOptionMulti: {
                position_ids: [this.props.current_position[0].value],
                label: "Cargos que pueden acceder",
            },

            editValues: this.props.current_position,
            nameFile: "",
            sizeFile: 0
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
            this.clearValues()
        }
    }

    toogleModalEditPosition = (from) => {
        if (from == "new") {
            this.setState({ modalEditPosition: true })
        } else {
            this.setState({ modalEditPosition: false })
            this.clearValues()
        }
    }

    editPosition = (document) => {

        const array = [];
        const arrayIds = [];

        document.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modeEdit: true,
            modalEditPosition: true,
            is_clone: false,
            id: document.id,
            editInputs: document.edit,
            has_process: document.document_type.has_process,

            formCreate: {
                ...this.state.formCreate,
                position_ids: arrayIds,
            },

            selectedOptionMulti: {
                position_ids: arrayIds,
                label: "Cargos que pueden acceder",
            },

            editValues: array,
        })
    }

    validationForm = () => {
        if (this.state.has_process) {
            if (this.state.formCreate.user_create_id != "" &&
                this.state.formCreate.user_review_id != "" &&
                this.state.formCreate.user_approve_id != "" &&
                this.state.formCreate.version != "" &&
                this.state.formCreate.proceso_id != "" &&
                this.state.formCreate.name != "" &&
                this.state.formCreate.due_date != ""
            ) {
                this.setState({ ErrorValues: true })
                return true
            } else {
                this.setState({ ErrorValues: false })
                return false
            }
        } else {
            if (this.state.formCreate.user_create_id != "" &&
                this.state.formCreate.user_review_id != "" &&
                this.state.formCreate.user_approve_id != "" &&
                this.state.formCreate.document_type_id != "" &&
                this.state.formCreate.version != "" &&
                this.state.formCreate.name != "" &&
                this.state.formCreate.due_date != ""
            ) {
                this.setState({ ErrorValues: true })
                return true
            } else {
                this.setState({ ErrorValues: false })
                return false
            }
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/document_management/documents/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,
            is_clone: false,
            editInputs: false,

            formCreate: {
                name: "",
                document_type_id: "",
                proceso_id: "",
                document: "",
                code: "",
                attached: {},
                is_attached: false,
                user_create_id: "",
                user_review_id: "",
                user_approve_id: "",
                validity: "",
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                version: "",
                position_ids: [this.props.current_position[0].value],
                document_parent_id: "",
                tenant_id: "",
                count: "",
            },

            selectedOptionUserCreate: {
                user_create_id: "",
                label: "Usuario que crea"
            },

            selectedOptionUserReview: {
                user_review_id: "",
                label: "Usuario que revisa"
            },

            selectedOptionUserApprove: {
                user_approve_id: "",
                label: "Usuario que aprueba"
            },

            selectedOptionDocumentType: {
                document_type_id: "",
                label: "Tipo de documento"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },

            selectedOptionMulti: {
                position_ids: [this.props.current_position[0].value],
                label: "Cargos que pueden acceder",
            },

            editValues: this.props.current_position,
            nameFile: "",
            sizeFile: 0
        })
    }

    HandleClickUpdatePosition = () => {
        const formData = new FormData();
        formData.append("position_ids", this.state.formCreate.position_ids)

        fetch(`/document_management/documents/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modalEditPosition: false, isLoaded: false })
                this.messageSuccess(data);
                if (this.props.view == "document_view" || this.props.view == "create_view") {
                    Turbolinks.visit(`/document_management/documents_create`);
                }
            });
    }

    HandleClick = () => {
        if (this.validationForm() == true) {
            this.setState({ isLoaded: true })
            const formData = new FormData();
            formData.append("name", this.state.formCreate.name)
            formData.append("document_type_id", this.state.formCreate.document_type_id)
            formData.append("proceso_id", this.state.formCreate.proceso_id)
            formData.append("is_attached", this.state.formCreate.is_attached)
            formData.append("attached", this.state.formCreate.attached)
            formData.append("user_create_id", this.state.formCreate.user_create_id)
            formData.append("user_review_id", this.state.formCreate.user_review_id)
            formData.append("user_approve_id", this.state.formCreate.user_approve_id)
            formData.append("validity", this.state.formCreate.validity)
            formData.append("due_date", this.state.formCreate.due_date)
            formData.append("version", this.state.formCreate.version)
            formData.append("code", this.state.formCreate.code)
            formData.append("count", this.state.formCreate.count)
            formData.append("position_ids", this.state.formCreate.position_ids)
            formData.append("document_parent_id", this.state.formCreate.document_parent_id)
            formData.append("document", this.state.formCreate.document)

            console.log("this.state.formCreate.position_idsthis.state.formCreate.position_ids", this.state.formCreate.position_ids)
            if (!this.state.modeEdit)
                fetch(`/document_management/documents`, {
                    method: 'POST', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                        if (this.props.view == "document_view" || this.props.view == "create_view") {
                            Turbolinks.visit(`/document_management/documents_create`);
                        }

                        //this.props.updateData(data.register);
                        //this.clearValues();
                    });
            else {
                fetch(`/document_management/documents/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                        if (this.props.view == "document_view" || this.props.view == "create_view") {
                            Turbolinks.visit(`/document_management/documents_create`);
                        }

                        //this.props.updateItem(data.register);
                        //this.clearValues();
                    });
            }
        }
    }

    HandleClickGenerateVersion = (id) => {
        fetch(`/document_management/generate_version_document/${id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data);
                this.props.updateData(data.register);
            });
    }


    sendObsolete = (document_id) => {
        fetch(`/document_management/send_obsolete/${document_id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data);
                this.props.loadData();
            });
    }

    HandleChange = (e) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [e.target.name]: e.target.value
            }
        })
    }

    edit = (document) => {
        const array = [];
        const arrayIds = [];

        document.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modeEdit: true,
            modal: true,
            is_clone: false,
            id: document.id,
            editInputs: document.edit,
            has_process: document.document_type.has_process,

            formCreate: {
                ...this.state.formCreate,
                name: document.name,
                document_type_id: document.document_type_id,
                proceso_id: document.proceso_id,
                code: document.code,
                is_attached: document.is_attached,
                attached: document.attached,
                user_create_id: document.user_create_id,
                user_review_id: document.user_review_id,
                user_approve_id: document.user_approve_id,
                due_date: document.due_date,
                is_last: document.is_last,
                version: document.version,
                document: document.document,
                position_ids: arrayIds,
                count: document.count,
            },


            selectedOptionUserCreate: {
                user_create_id: `${document.user_create != null ? document.user_create.id : ""}`,
                label: `${document.user_create != null ? `${document.user_create.first_name} ${document.user_create.first_last_name}` : "Cargo que crea"}`
            },

            selectedOptionUserReview: {
                user_review_id: `${document.user_review != null ? document.user_review.id : ""}`,
                label: `${document.user_review != null ? `${document.user_review.first_name} ${document.user_review.first_last_name}` : "Cargo que revisa"}`
            },

            selectedOptionUserApprove: {
                user_approve_id: `${document.user_approve != null ? document.user_approve.id : ""}`,
                label: `${document.user_approve != null ? `${document.user_approve.first_name} ${document.user_approve.first_last_name}` : "Cargo que aprueba"}`,
            },

            selectedOptionDocumentType: {
                document_type_id: `${document.document_type != null ? document.document_type.id : ""}`,
                label: `${document.document_type != null ? document.document_type.name : "Tipo de documento"}`,
            },

            selectedOptionProceso: {
                proceso_id: `${document.proceso != null ? document.proceso.id : ""}`,
                label: `${document.proceso != null ? document.proceso.name : "Proceso"}`,
            },

            selectedOptionMulti: {
                position_ids: arrayIds,
                label: "Cargos que pueden acceder",
            },

            editValues: array,
        })
    }


    handleChangeChecked = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;

        this.setState({
            formCreate: {
                ...this.state.formCreate,
                is_attached: isChecked
            }
        })
    }

    handleChangeFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formCreate: {
                    ...this.state.formCreate,
                    attached: file.size >= 11485760 ? {} : file
                },

                nameFile: file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
                sizeFile: file.size,
            })
        ));
    };

    handleChangeAutocompleteUserCreate = selectedOptionUserCreate => {
        this.setState({
            selectedOptionUserCreate,
            formCreate: {
                ...this.state.formCreate,
                user_create_id: selectedOptionUserCreate.value
            }
        });
    };

    handleChangeAutocompleteUserReview = selectedOptionUserReview => {
        this.setState({
            selectedOptionUserReview,
            formCreate: {
                ...this.state.formCreate,
                user_review_id: selectedOptionUserReview.value
            }
        });
    };

    handleChangeAutocompleteUserApprove = selectedOptionUserApprove => {
        this.setState({
            selectedOptionUserApprove,
            formCreate: {
                ...this.state.formCreate,
                user_approve_id: selectedOptionUserApprove.value
            }
        });
    };

    handleChangeAutocompleteDocumentType = selectedOptionDocumentType => {
        this.setState({
            selectedOptionDocumentType,
            has_process: selectedOptionDocumentType.has_process,
            formCreate: {
                ...this.state.formCreate,
                document_type_id: selectedOptionDocumentType.value
            }
        });
    };

    handleChangeAutocompleteProceso = selectedOptionProceso => {
        this.setState({
            selectedOptionProceso,
            formCreate: {
                ...this.state.formCreate,
                proceso_id: selectedOptionProceso.value
            }
        });
    };

    handleChangeAutocompleteMulti = selectedOptionMulti => {
        let array = []

        if (selectedOptionMulti) {
            selectedOptionMulti.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            formCreate: {
                ...this.state.formCreate,
                position_ids: selectedOptionMulti ? array : [],
            }
        })
    }

    getDateTime = (datetime) => {
        let date = new Date(datetime)
        let result = `${date.getFullYear()}-${(date.getMonth() + 2)}-${date.getDate()}`
        return result
    }

    getMonthText = (fecha) => {
        let date = new Date(fecha)
        let month = date.getMonth();
        let day = (date.getDate() == 31 || date.getDate() == 30) ? date.getDate() : date.getDate() + 1
        let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        return `${day} de ${months[month]} del ${date.getFullYear()}`
    }

    generateClone = (document) => {
        console.log("document", document);

        const array = [];
        const arrayIds = [];

        document.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modal: true,
            modeEdit: false,
            is_clone: true,
            editInputs: document.edit,
            has_process: document.document_type.has_process,

            formCreate: {
                ...this.state.formCreate,
                name: document.name,
                document_type_id: document.document_type_id,
                proceso_id: document.proceso_id,
                code: document.code,
                is_attached: document.is_attached,
                attached: document.attached,
                user_create_id: document.user_create_id,
                user_review_id: document.user_review_id,
                user_approve_id: document.user_approve_id,
                document: document.document,
                validity: document.validity,
                due_date: document.due_date,
                version: (Number(document.version) + 1),
                position_ids: arrayIds,
                document_parent_id: document.id,
                is_last: true,
                tenant_id: document.tenant_id,
                count: document.count,
            },

            selectedOptionUserCreate: {
                user_create_id: `${document.user_create != null ? document.user_create.id : ""}`,
                label: `${document.user_create != null ? `${document.user_create.first_name} ${document.user_create.first_last_name}` : "Cargo que crea"}`
            },

            selectedOptionUserReview: {
                user_review_id: `${document.user_review != null ? document.user_review.id : ""}`,
                label: `${document.user_review != null ? `${document.user_review.first_name} ${document.user_review.first_last_name}` : "Cargo que revisa"}`
            },

            selectedOptionUserApprove: {
                user_approve_id: `${document.user_approve != null ? document.user_approve.id : ""}`,
                label: `${document.user_approve != null ? `${document.user_approve.first_name} ${document.user_approve.first_last_name}` : "Cargo que aprueba"}`,
            },

            selectedOptionDocumentType: {
                document_type_id: `${document.document_type != null ? document.document_type.id : ""}`,
                label: `${document.document_type != null ? document.document_type.name : "Tipo de documento"}`,
            },

            selectedOptionProceso: {
                proceso_id: `${document.proceso != null ? document.proceso.id : ""}`,
                label: `${document.proceso != null ? document.proceso.name : "Proceso"}`,
            },

            selectedOptionMulti: {
                position_ids: arrayIds,
                label: "Cargos que pueden acceder",
            },

            editValues: array,
        })
    }

    transformCode = (code) => {
        const code1 = code.split("-")[0]
        const code2 = code.split("-")[1]
        const new_code = `${code1}-${code2 <= 9 ? "00" : "0"}${code2}`
        return new_code
    }

    isNewDocument(date) {
        // Check if the date is null or undefined
        if (date == null) {
            // Treat as if it's not a new document
            return false;
        }
    
        // Create Moment.js objects for the creation date and the current date
        const createdDate = moment(date);
        const currentDate = moment();
    
        // Calculate the difference in days between the creation date and the current date
        const daysDifference = currentDate.diff(createdDate, 'days');

        // Determine if the document is new based on the specified time threshold
        return daysDifference <= 1;
    }
    

    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar documento" : "Crear documento"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}
                        is_clone={this.state.is_clone}
                        sizeFile={this.state.sizeFile}

                        //form props
                        formValues={this.state.formCreate}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        errorValues={this.state.ErrorValues}
                        handleChangeChecked={this.handleChangeChecked}
                        isLoaded={this.state.isLoaded}
                        has_process={this.state.has_process}
                        editInputs={this.state.editInputs}

                        //file
                        nameFile={this.state.nameFile}
                        handleChangeFile={this.handleChangeFile}

                        //select values

                        //select UserCreate
                        selectedOptionUserCreate={this.state.selectedOptionUserCreate}
                        handleChangeAutocompleteUserCreate={this.handleChangeAutocompleteUserCreate}

                        //select UserReview
                        selectedOptionUserReview={this.state.selectedOptionUserReview}
                        handleChangeAutocompleteUserReview={this.handleChangeAutocompleteUserReview}

                        //select UserApprove
                        selectedOptionUserApprove={this.state.selectedOptionUserApprove}
                        handleChangeAutocompleteUserApprove={this.handleChangeAutocompleteUserApprove}

                        //select DocumentType
                        selectedOptionDocumentType={this.state.selectedOptionDocumentType}
                        handleChangeAutocompleteDocumentType={this.handleChangeAutocompleteDocumentType}

                        //select Proceso
                        selectedOptionProceso={this.state.selectedOptionProceso}
                        handleChangeAutocompleteProceso={this.handleChangeAutocompleteProceso}

                        //select multi
                        selectedOptionMulti={this.state.selectedOptionMulti}
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}

                        positions={this.props.positions}
                        document_types={this.props.document_types}
                        procesos={this.props.procesos}
                        users={this.props.users}
                        editValues={this.state.editValues}
                        company={this.props.company}
                    />
                )}

                {this.state.modalEditPosition && (
                    <ModalEditPosition
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalEditPosition}
                        toggle={this.toogleModalEditPosition}
                        title={this.state.modeEdit ? "Actualizar los cargos que pueden ver el documento" : "Crear documento"}
                        submitForm={this.HandleClickUpdatePosition}

                        //select multi
                        selectedOptionMulti={this.state.selectedOptionMulti}
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}
                        positions={this.props.positions}
                        editValues={this.state.editValues}
                    />
                )}

                {isMobile ? (
                    <IndexMobile
                        data={this.props.data}
                        estados={this.props.estados}
                        edit={this.edit}
                        delete={this.delete}
                        generateClone={this.generateClone}
                        toogle={this.toogle}
                        editPosition={this.editPosition}
                        transformCode={this.transformCode}

                        //document is new?
                        isNewDocument={this.isNewDocument}

                        //paginate props
                        totalData={this.props.totalData}
                        activePage={this.props.activePage}
                        countPage={this.props.countPage}
                        handlePageChange={this.props.handlePageChange}
                        getDateTime={this.getDateTime}
                        sendObsolete={this.sendObsolete}
                        getMonthText={this.getMonthText}
                    />
                ) : (
                    <IndexWeb
                        data={this.props.data}
                        estados={this.props.estados}
                        edit={this.edit}
                        delete={this.delete}
                        generateClone={this.generateClone}
                        toogle={this.toogle}
                        editPosition={this.editPosition}
                        transformCode={this.transformCode}

                        //document is new?
                        isNewDocument={this.isNewDocument}

                        //paginate props
                        totalData={this.props.totalData}
                        activePage={this.props.activePage}
                        countPage={this.props.countPage}
                        handlePageChange={this.props.handlePageChange}
                        getDateTime={this.getDateTime}
                        sendObsolete={this.sendObsolete}
                        getMonthText={this.getMonthText}
                    />
                )}

            </React.Fragment>
        );
    }
}

export default Index;