import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            showTable: false,
            ErrorValues: true,
            question_pdf_style_id: "",

            form: {
                pdf_height: 50, 
                pdf_widh: 100, 
                pdf_subform_height: 50, 
                pdf_subform_widh: 100, 
                format_pdf_id: this.props.format_pdf_id, 
                question_id: this.props.question.id
            },
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = question_pdf_style => {
        this.setState({
            data: this.state.data.map(item => {
                if (question_pdf_style.id === item.id) {
                    return {
                        ...item,
                        pdf_height: question_pdf_style.pdf_height, 
                        pdf_subform_height: question_pdf_style.pdf_subform_height, 
                        pdf_subform_widh: question_pdf_style.pdf_subform_widh, 
                        pdf_widh: question_pdf_style.pdf_widh, 
                        question: question_pdf_style.question, 
                    }
                }
                return item;
            })
        });
    }

    updateFormValues = (register) => {
        this.setState({
            question_pdf_style_id: register.id,
            
            form: {
                ...this.state.form,
                pdf_height: register.pdf_height, 
                pdf_widh: register.pdf_widh,  
                pdf_subform_height: register.pdf_subform_height, 
                pdf_subform_widh: register.pdf_subform_widh, 
            },
        })
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            form: {
                ...this.state.form,
                pdf_height: 50, 
                pdf_widh: 100, 
                pdf_subform_height: 50, 
                pdf_subform_widh: 100, 
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_pdf_styles/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/formatos/get_question_pdf_styles/${this.props.question.id}/${this.props.format_pdf_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {

            const defaultValue = {id: "", pdf_height: 50, pdf_widh: 100, pdf_subform_height: 50, pdf_subform_widh: 100 }
            const register = data.data.length >= 1 ? data.data[0] : defaultValue

            this.updateFormValues(register);
            this.setState({
                data: data.data,
                isLoaded: false,
                modal: true,
                modeEdit: data.data.length >= 1 ? true : false,
            });
        });
    }

    validationForm = () => {
        if (this.state.form.format_select_id != "" &&
            this.state.form.question_asociation_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.validationForm()) {
            if (this.state.modeEdit) {
                fetch(`/formatos/question_pdf_styles/${this.state.question_pdf_style_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register);
                        this.updateFormValues(data.register);
                        this.setState({ modeEdit: true });
                    });
            } else {
                fetch(`/formatos/question_pdf_styles`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateData(data.register);
                        this.updateFormValues(data.register);
                        this.setState({ modeEdit: true });
                    });
            }
        }
    }
    


    edit = (question_pdf_style) => {
        this.setState({
            modal: true,
            modeEdit: true,
            question_pdf_style_id: question_pdf_style.id,

            form: {
                ...this.state.form,
                pdf_height: question_pdf_style.pdf_height, 
                pdf_subform_height: question_pdf_style.pdf_subform_height, 
                pdf_subform_widh: question_pdf_style.pdf_subform_widh, 
                pdf_widh: question_pdf_style.pdf_widh, 
            },
        })
    }


    handleChangeCheckBox = (e, value) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.checked ? "auto" : value, 
            },
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <ModalBody>

                        {this.state.modal && (
                            <FormCreate
                                toggle={this.toogle}
                                title={this.state.modeEdit ? "Actualizar" : "Crear"}
                                nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                                //form props
                                formValues={this.state.form}
                                onChangeForm={this.HandleChange}
                                submitForm={this.HandleClick}
                                errorValues={this.state.ErrorValues}
                                handleChangeCheckBox={this.handleChangeCheckBox}
                                question={this.props.question}
                            />
                        )}

                        {/*this.state.data.length == 0 && (
                            <div className="col-md-12 mb-3 text-right pr-0">
                                {!this.state.modal && (
                                    <button
                                        className="btn-shadow btn btn-info"
                                        onClick={() => this.toogle("new")}
                                    >
                                        Abrir formulario
                                    </button>
                                )}
                            </div>
                        )

                        <div className="card">
                            <div className="background-gray">
                                <table className="table " id="sampleTable">
                                    <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                        <tr>
                                            <th>Ancho</th>
                                            <th>Altura</th>
                                            <th>Ancho subformulario</th>
                                            <th>Altura subformulario</th>
                                            <th className="text-right">Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map((question_pdf_style, index) => (
                                                <React.Fragment>
                                                    {index >= 1 ? <div className="mb-2"></div> : null}
                                                    <tr key={question_pdf_style.id} className="tr-table-task" style={{ borderLeft: `4px solid green` }}>
                                                        <td>{question_pdf_style.pdf_widh}</td>
                                                        <td>{question_pdf_style.pdf_height}</td>
                                                        <td>{question_pdf_style.pdf_subform_widh}</td>
                                                        <td>{question_pdf_style.pdf_subform_height}</td>

                                                        <td className="text-right">
                                                            {true && (
                                                                <UncontrolledDropdown direction="left" className='btn-group'>
                                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {false && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(question_pdf_style)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(question_pdf_style.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>*/}


                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;