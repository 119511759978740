import React, { Component } from 'react';
import Preloader from '../../../GeneralComponents/LoadingComponen';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import ModalActions from './ModalActions';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import Pagination from "react-js-pagination";
// import ModalRenewPlan from './ModalRenewPlan';
import FormUpgradePlan from '../../settings/Users/FormUpgradePlan';

// import Swiper core and required modules
import SwiperCore, { Navigation, A11y} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, A11y]);

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            modal: false,
            modeEdit: false,
            modalActions: false,
            modalUpgradePlan: false,
            id: "",
            form: {
                name: "",
                position_ids: [],
                user_ids: [],
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionUsers: {
                user_ids: "",
                label: "Usuarios"
            },

            task_board: {},
            editValuesPosition: [],
            editValuesUsers: [],
            selectLabels: [],
            cargos: [],
            users: [],

            slidesPerView: 1,
            screenWidth: window.innerWidth,

        }
    }



    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true, showBtn: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    toogleUpgradePlan = (from) => {
        if (from == "new") {
            this.setState({ modalUpgradePlan: true })
        } else {
            this.setState({ modalUpgradePlan: false })
        }
    }

    toogleActions = (e, from, task_board) => {
        if (e) {
            e.stopPropagation();
        }

        if (from == "new") {
            this.setState({ modalActions: true, task_board: task_board })
        } else {
            this.setState({ modalActions: false, task_board: {} })
        }
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            id: "",
            task_board: {},
            editValuesPosition: [],
            selectLabels: [],
            editValuesUsers: [],

            form: {
                name: "",
                position_ids: [],
                user_ids: [],
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionUsers: {
                user_ids: "",
                label: "Usuarios"
            },
        })
    }

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.handleResize();

        this.configSelect();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('resize', this.handleResize);
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    configSelect = () => {
        let arrayCargos = []
        let arrayUsers = []

        this.props.positions.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))

        this.props.users.map((item) => (
            arrayUsers.push({ label: item.name, value: item.id })
        ))

        this.setState({
            cargos: arrayCargos,
            users: arrayUsers,
        })
    }

    handleChangeAutocomplete = selectedOption => {
        let array = []

        selectedOption.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            form: {
                ...this.state.form,
                position_ids: array
            }
        })
    }

    handleChangeAutocompleteUsers = selectedOptionUsers => {
        let array = []

        selectedOptionUsers.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            form: {
                ...this.state.form,
                user_ids: array
            }
        })
    }

    HandleChange = e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.message}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/task/task_boards/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    HandleClick = () => {
        if (!this.state.modeEdit) {
            if (this.validationForm()) {
                fetch(`/task/task_boards`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        if (data.type == "success") {
                            this.setState({ modeEdit: false, task_board: data.register, showBtn: false, modalUpgradePlan: false })
                            this.props.loadData();
                            this.messageSuccess(data);
                            this.props.updateTotalTaskBoards(data.total + 1);
                        } else {
                            this.setState({ modal: false, modeEdit: false, modalUpgradePlan: true })
                        }
                    });
            }

        } else {

            fetch(`/task/task_boards/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if (data.type == "success") {
                        this.clearValues();
                        this.setState({ modal: false, modeEdit: false, modalUpgradePlan: false })
                        this.props.updateItem(data.register)
                    } else {
                        this.setState({ modal: false, modeEdit: false, modalUpgradePlan: true })
                    }
                });
        }
    }

    addLabel = (label, state) => {
        const selectLabels = this.state.selectLabels

        if (!state) {
            selectLabels.push(label.id)

            this.setState({
                selectLabels: selectLabels,
                form: {
                    ...this.state.form,
                    task_label_ids: selectLabels,
                },
            })
        } else {
            var arr = selectLabels
            var index = selectLabels.indexOf(parseInt(label.id))
            delete arr[index]

            this.setState({
                selectLabels: arr.filter(item => item != null),
                form: {
                    ...this.state.form,
                    task_label_ids: arr.filter(item => item != null),
                },
            })
        }
    }


    generateTemplate = (e, task_board_id) => {
        e.stopPropagation();
        fetch(`/task/generate_template/${task_board_id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateDataTemplate(data.register)
                this.messageSuccess(data);
            });
    }

    duplicateBoard = (e, task_board_id) => {
        e.stopPropagation();
        fetch(`/task/duplicate_board/${task_board_id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateData(data.register)
                this.messageSuccess(data);
            });
    }

    selectFunction = (e, task_board_id) => {
        if (this.props.current_plan.task_board_count  <= (this.props.data.length)){
            return this.toogleUpgradePlan("new");
        }else{
            return  this.duplicateBoard(e, task_board_id);
        }
    }

    edit = (e, task_board) => {
        if (e) {
            e.stopPropagation();
        }
        const position = []
        const task_labels = []
        const arrayEdit = []
        const arrayEditUsers = []

        /*task_board.positions.map((item) => (
            position.push(item.id)
        ))*/

        /*task_board.task_labels.map((item) => (
            task_labels.push(item.id)
        ))*/

        /*task_board.positions.map((item) => (
            arrayEdit.push({label: item.name, value: item.id})
        ))*/

        task_board.users.map((item) => (
            arrayEditUsers.push({ label: `${item.first_name} ${item.first_last_name}`, value: item.id })
        ))

        this.setState({
            form: {
                name: task_board.name,
                position_ids: position,
                user_ids: arrayEditUsers,
            },

            id: task_board.id,
            selectLabels: task_labels,
            editValuesPosition: arrayEdit,
            editValuesUsers: arrayEditUsers,
            task_board: task_board,
            modal: true,
            showBtn: true,
            modeEdit: true,
        })
    }

    redirectTaks = (task_board) => {
        Turbolinks.visit(`/task/task_boards/${task_board.token}/tasks`);
    }

    handleResize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 390) {
          this.setState({ slidesPerView: 4 });
        }  else if (windowWidth >=  1700 && windowWidth <= 2000) {
          this.setState({ slidesPerView: 7});
        }else if (windowWidth >=  2001 && windowWidth <= 2100) {
          this.setState({ slidesPerView: 8 });
        } else if (windowWidth >=  2101 && windowWidth <= 3000) {
            this.setState({ slidesPerView: 10 });
        } else if (windowWidth >=  3001 ) {
            this.setState({ slidesPerView: 20 });
        }else {
            this.setState({ slidesPerView: 5 });
        }

        this.setState({
            screenWidth: window.innerWidth,
        });
    };

    renderUserList = (users) => {
        return users.map((user, index) => (
          <div className="pr-0 ui-boards-index list-user-responsible mb-2" key={index}>
            <div
              className="icon-show-user-responsible ui-boards-index"
              id={`PopoverOwner${user.id}`}
            >
              {user.first_name.charAt(0)}{user.first_last_name.charAt(0)}
            </div>
            <UncontrolledTooltip placement="top" target={`PopoverOwner${user.id}`}>
              {user.first_name} {user.first_last_name}
            </UncontrolledTooltip>
          </div>
        ));
    }

    renderUserListSwiper = (users) => {
        const { slidesPerView } = this.state;
        
        return (
          <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={5}
            slidesPerView={slidesPerView} 
            navigation
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
          >
            {users.map((user, index) => (
              <SwiperSlide className="pr-0 ui-boards-index list-user-responsible mb-2" key={index}>
                <div
                  className="icon-show-user-responsible ui-boards-index"
                  id={`PopoverOwner${user.id}`}
                >
                  {user.first_name.charAt(0)}{user.first_last_name.charAt(0)}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        );
    }

    UserList = (users) => {
        const { screenWidth } = this.state; // screen size divice 
        const validation1 = (screenWidth >= 280 && screenWidth <= 393) || (screenWidth >= 768 && screenWidth <= 899) || (screenWidth >= 1401 && screenWidth <= 1600);
        const validation2 = (screenWidth >= 394 && screenWidth <= 767) || (screenWidth >= 900 && screenWidth <= 1023) || (screenWidth >= 1601 && screenWidth <= 1889);
        const validation3 = (screenWidth >= 1024 && screenWidth <= 1049);
        const validation4 =  (screenWidth >= 1050 && screenWidth <= 1400);
        const validation5 =  (screenWidth >= 1900 && screenWidth <= 2000);
        const validation6 =  (screenWidth >= 2001 && screenWidth <= 2100);
        const validation7 =  (screenWidth >= 2101 && screenWidth <= 3000);
        const validation8 =  (screenWidth >= 3001);

        if (validation1 && users.length >= 6 ){
            return this.renderUserListSwiper(users);
        } else if (validation2 && users.length >= 7) {
            return this.renderUserListSwiper(users);
        } else if (validation3 && users.length >= 4) {
            return this.renderUserListSwiper(users);
        } else if (validation4 && users.length >= 5) {
            return this.renderUserListSwiper(users);
        } else if (validation5 && users.length >= 9) {
            return this.renderUserListSwiper(users);
        } else if (validation6 && users.length >= 9) {
            return this.renderUserListSwiper(users);
        } else if (validation7 && users.length >= 10) {
            return this.renderUserListSwiper(users);
        } else if (validation8 && users.length >= 20) {
            return this.renderUserListSwiper(users);
        } else {
            return this.renderUserList(users);
        }
    }

    render() {
        
        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Editar tablero" : "Nuevo tablero"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={this.state.modeEdit ? "Actualizar tablero" : "Siguiente"}
                        labels={this.props.labels}
                        modeEdit={this.state.modeEdit}

                        addLabel={this.addLabel}
                        selectLabels={this.state.selectLabels}
                        editValuesPosition={this.state.editValuesPosition}
                        showBtn={this.state.showBtn}
                        is_template={true}
                        messageSuccess={this.messageSuccess}

                        //select config 
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}
                        handleChangeAutocomplete={this.handleChangeAutocomplete}

                        //select user
                        handleChangeAutocompleteUsers={this.handleChangeAutocompleteUsers}
                        users={this.props.users}
                        selectedOptionUsers={this.state.selectedOptionUsers}
                        editValuesUsers={this.state.editValuesUsers}
                        task_board={this.state.task_board}
                        updateColumnCount={this.props.updateColumnCount}

                    />
                )}

                {this.state.modalUpgradePlan && (
                    <FormUpgradePlan
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalUpgradePlan}
                        toggle={this.toogleUpgradePlan}
                        title={"Mejora tu Plan"}
                        messageModal={["Has alcanzado ", <span className='ui_formUpgrade_Plan_message_first'>el límite de tableros</span>," permitidos en tu plan actual"]}
                        plans={this.props.plans}
                        current_plan={this.props.current_plan}
                    />
                )}

                {this.state.modalActions && (
                    <ModalActions
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalActions}
                        toggle={this.toogleActions}

                        task_board={this.state.task_board}
                        loadData={this.props.loadData}
                        messageSuccess={this.messageSuccess}
                    />
                )}

                {!this.props.isLoaded ? (
                    <div className='ui-boars-index-container__container_all'>
                        {this.props.data.length >= 1 ? (
                            <div className="ui-boars-index-container">
                                {this.props.data.map(task_board => (
                                    <div className="mb-4 ui-boards-index__container" key={task_board.id}>
                                        <div className="card ui-boards-index-card">
                                            <div className="card-header" style={{ borderTopLeftRadius: "10px" }} onClick={() => task_board.columns >= 1 ? this.redirectTaks(task_board) : null}>
                                                <span className='ui-boards-index__container_name' onClick={() => task_board.columns >= 1 ? this.redirectTaks(task_board) : null}>
                                                    {task_board.name}
                                                </span>
                                                
                                            </div>

                                            <div className="card-body ui-boards-index-card-body" onDoubleClick={() => task_board.columns >= 1 ? this.redirectTaks(task_board) : null} key={task_board.id}>
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        {task_board.users.length >= 1 && (
                                                            <p className="mb-0 ui-boards-index user"><b>Usuarios</b></p>
                                                        )}
                                                        <div className="row ui-boards-index">
                                                           {this.UserList(task_board.users)}

                                                            <div className="col-md-12 pl-0">
                                                                <div className="ui-btree-card-name-user-created">
                                                                    <span>Creado por: {task_board.user.name}</span>  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            {true && (
                                                <div className="card-footer border-bottom-card" style={{ padding: '0.42rem' }}>
                                                    <div className='ui-board-index-footer'>
                                                        <div className="ui-board-index-date-create">
                                                            {task_board.created_at}
                                                        </div>

                                                        {task_board.columns >= 1 ? (
                                                            <React.Fragment>
                                                                <div className="ui-index-btnTasks" style={{ height: "31px" }}>
                                                                    {true && (
                                                                        <a
                                                                            className="btn"
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            # Tareas {task_board.count_tasks}
                                                                        </a>
                                                                    )}
                                                                </div>

                                                                <div className="ui-board-index-colums">
                                                                    {true && (
                                                                        <a
                                                                            className="btn"
                                                                        >
                                                                            Columnas {task_board.columns}
                                                                        </a>
                                                                    )}
                                                                </div>


                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <div className="ui-board-index-textInfo">
                                                                    <span className="badge badge-info badge-columns">No hay columnas</span>
                                                                </div>
                                                                <div></div>
                                                            </React.Fragment>
                                                        )}

                                                        <div className="ui-board-icons-options">

                                                            {(task_board.columns >= 1 && !task_board.delete_template && this.props.user.id == task_board.user.id && this.props.showFooter) && (
                                                                <React.Fragment>
                                                                    <UncontrolledDropdown className='btn-group ui-taskBoard-dropdown-options'>
                                                                        <DropdownToggle className='btn'>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={(e) => this.selectFunction(e, task_board.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Duplicar tablero
                                                                                </DropdownItem>
                                                                            )}
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={(e) => this.generateTemplate(e, task_board.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Generar como plantilla
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </React.Fragment>
                                                            )}

                                                            {(this.props.user.id == task_board.user.id || this.props.estados.edit) && (
                                                                <a
                                                                    onClick={(e) => this.edit(e, task_board)}
                                                                    className="btn ui-indexTaskBoard-iconEdit"
                                                                >
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                            )}

                                                            {(this.props.estados.delete && !task_board.delete_template && this.props.user.id == task_board.user.id || this.props.estados.delete) && (
                                                                <a
                                                                    onClick={(e) => this.toogleActions(e, "new", task_board)}
                                                                    style={{ color: "red", display: this.props.showFooter ? "block" : "none" }}
                                                                    className="btn ui-indexTaskBoard-iconTrash"
                                                                >
                                                                    <i className="far fa-trash-alt"></i>
                                                                </a>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className='card col-md-12 mb-3'>
                                <div className="text-center">
                                    <div className="text-center mt-4 mb-4" style={{ width: '100%' }}>
                                        {this.props.showFooter && (
                                            <p className='ui-indexTaskBoard-messageNoRecord'>Todavía no hay tableros creados, da clic en  <a onClick={() => this.toogle('new')} className="btn btn-info"><i className='fas fa-chalkboard-teacher'></i>Nuevo tablero</a> y genera el primer tablero de tareas</p>
                                        )}

                                        <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/7/noRecord_task.jpg" alt="" className="ui-indexTaskBoard-imgNoRecord" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <Preloader />
                            </div>
                        </div>
                    </React.Fragment>
                )}


                {this.props.showFooter && (
                    <div className="row">

                        <div className="col-md-2 text-left">
                            <p>
                                Mostrando {this.props.data.length} de {this.props.total_data}
                            </p>
                        </div>

                        <div className="col-md-10 pl-0">
                            <Pagination
                                hideNavigation
                                activePage={this.props.activePage}
                                itemsCountPerPage={this.props.countPage}
                                itemClass="page-item"
                                innerClass="pagination"
                                linkClass="page-link"
                                totalItemsCount={this.props.total_data}
                                pageRangeDisplayed={this.props.countPage}
                                onChange={this.props.handlePageChange}
                            />
                        </div>
                    </div>
                )}

            </React.Fragment>
        );
    }
}

export default Index;
