import React, { useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

const FormUpgradePlan = (props) => {
    return(
        <React.Fragment>
            <Modal isOpen={props.modal} toogle={props.toogle} className={`modal-dialog${isMobile ? `-centered modal-sm` : `${isTablet ? `-centered  modal-lg` : `-centered modal-xl`}`} ui_formUpgradePlan_modal`}  backdrop={props.backdrop}>
                <ModalHeader toogle={props.toogle} className='ui_formUpgradePlan__plan_header__container'>
                    <span><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</span>
                    <a onClick={() => props.toggle()}><i className="fas fa-times"></i></a>
                </ModalHeader>
                <ModalBody className='card ui_formUpgradePlan_card'>
                    <div className="ui_formUpgradePlan__container">
                        <div className="ui_formUpgradePlan_meesage_Plan card">
                            <div className="card-body ui_formUpgradePlan_body-message">
                                <i className="fas fa-info-circle"></i>
                                <span className='ui_formUpgradePlan_message_text'>{props.messageModal}</span>

                            </div>
                        </div>

                        <div className="ui_formUpgradePlan__container-plans mt-2">
                            {props.plans.map((plan, index) => (

                                <React.Fragment key={index}>
                                    
                                <div className="card-body ui_formUpgradePlan__plan_container" key={plan.id} style={{ background: (props.current_plan.id == plan.id || props.current_plan.tenant_state != "demo" && props.current_plan.name == "beefree" && plan.is_recommended) ? "linear-gradient(157deg, #F1C052 0%, #DD9F3D 57%, #C97E28 100%)" : plan.background }}>
                                    {(props.current_plan.tenant_state != "demo" && props.current_plan.name == "beefree" && plan.is_recommended) && (
                                        <div className='ui_formUpgradePlan__recomended'>
                                            <i className="fas fa-chess-queen mr-1"></i> RECOMENDADO
                                        </div>
                                    )}

                                    {(props.current_plan.tenant_state == "demo" && plan.name == plan.nick_name) && (
                                        <div className='ui_formUpgradePlan__demo'>
                                            <i className="fas fa-chess-rook mr-1"></i>PLAN ACTUAL EN DEMO
                                        </div>
                                    )}

                                    <div className="ui_formUpgradePlan-title">
                                        {(props.current_plan.tenant_state != "demo" && props.current_plan.name == "beefree" && plan.is_recommended) ? (
                                                <React.Fragment>
                                                    <i className="fas fa-chess-queen mr-1"></i> {plan.nick_name.toUpperCase()}
                                                </React.Fragment>
                                            
                                        ) : (
                                                <React.Fragment>
                                                    <i className="fas fa-chess-bishop mr-1"></i> {plan.nick_name.toUpperCase()}
                                                </React.Fragment>
                                        )}
                                        
                                    </div>

                                    <div className="ui_formUpgradePlan-price__container" style={{ background: "#ffff" }}>
                                        {/* <span className='mr-1' style={{ color: "#17A3F2" }}><b>${plan.price} <span style={{fontSize: '13px', color: "#17A3F2" }}>USD</span></b></span>
                                        <span style={{fontSize: '11px'}}>Pago mensual</span> */}
                                        <a 
                                            className="btn ui_formUpgradePlan-btn"
                                            style={{ color: "#17A3F2" }}
                                            href={plan.link}
                                        >
                                            SOLICÍTALO AQUÍ                                            
                                        </a>
                                    </div>

                                    {/* <div className="ui_formUpgradePlan-btn_change_plan">
                                        <a 
                                            className="btn ui_formUpgradePlan-btn"
                                            style={{ color: "#17A3F2" }}
                                            href={plan.link}
                                        >
                                            SOLICÍTALO AQUÍ
                                             // CAMBIAR A {plan.nick_name.toUpperCase()} MES
                                        </a>
                                    </div> */}

                                    <div className="ui_formUpgradePlan__container_user_plan">
                                        {plan.users_plan > 1 ? ( `${plan.users_plan} Usuarios`) : (` ${plan.users_plan} Usuario`)}
                                    </div>
                                    <div className="ui_formUpgradePlan__container_text">
                                        {ReactHtmlParser(plan.description)}
                                    </div>
                                    
                                    
                                </div>
                                </React.Fragment>

                            ))}
                        </div>
                    </div>

                    <div className="ui_formUpgradePlan__container_banners">
                        <div className="ui_formUpgradePlan_left_Banner">
                            <div className="ui_formUpgradePlan_circle_banner"></div>
                            <div className="ui_formUpgradePlan_text_banner">
                                ¿Quieres más de 20 usuarios en tu plan?
                            </div>
                            <div className="ui_formUpgradePlan_button_banner">
                                <a href="https://bee-wo.com/formatos/generate_questions/p2eGqx9jz4Puq25HKtgY7LgL/0/0" target="_blank"  className="btn ui_formUpgradePlan_button">Solicitalo</a>
                            </div>
                        </div>

                        <div className="ui_formUpgradePlan_right_Banner">
                            <div className="ui_formUpgradePlan_circle_banner"></div>
                            <div className="ui_formUpgradePlan_text_banner">
                                ¿Quieres ser un <span>beewoker</span>?
                            </div>

                            <div className="ui_formUpgradePlan_button_banner">
                                <a href="https://beewo.academy/lp-webinars/" target="_blank"  className="btn ui_formUpgradePlan_button">Contactanos</a>
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter >
                    <label className="btn btn-outline-danger mt-2" onClick={() => props.toggle()}>Cerrar</label>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default FormUpgradePlan;