import React, { Component } from 'react';

import TaskIndexTrello from '../../taskTrello/index'
import TableTask from '../taskBoard/TableTask';
import CalendarTask from '../taskBoard/CalendarTask';
import TaskBoardUpdateUsers from './TaskBoardUpdateUsers';
import { UncontrolledTooltip } from 'reactstrap';


class IndexTaskModule extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            listView: "columns",
            users: [],
            moda: false,
        }
    }

    updateView = (view) => {
        this.setState({
            listView: view,
        })
    }

    componentDidMount = () => {
        this.loadData();
    }


    loadData = () => {
        fetch(`/task/get_users_task_board/${this.props.task_board.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                users: data.register
            })
        });
    }


    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
        }
    }

    setUsers = (users) => {
        this.setState({
            users: users
        })
    }

    render() {
        return (
            <div className="col-md-12 p-0">

                {this.state.modal && (
                    <TaskBoardUpdateUsers
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Usuarios del tablero"}
                        users={this.props.users}
                        setUsers={this.setUsers}
                        task_board={this.props.task_board}
                        task_board_users={this.state.users}
                    />
                )}


                <div className="card mb-3">
                    <div className="card-body p-2">
                        {true && (
                            <React.Fragment>
                                <UncontrolledTooltip target='bw-indexTaskModule-tooltip-view-columns' placement='bottom'>{'VISTA DE TAREAS EN COLUMNAS'}</UncontrolledTooltip>
                                <a onClick={() => this.updateView("columns")} style={{ color: this.state.listView == "columns" ? "#3f6ad8" : "#a2a2a2", cursor: "pointer", textDecoration: this.state.listView == "columns" ? "underline" : "" }} className="stretched-link" id='bw-indexTaskModule-tooltip-view-columns'>
                                    <i className="fas fa-columns" style={{ fontSize: "33px" }}></i>
                                </a>
                            </React.Fragment>
                        )}

                        {true && (
                            <React.Fragment>
                            <UncontrolledTooltip target='bw-indexTaskModule-tooltip-view-List' placement='bottom'>{'VISTA DE TAREAS EN LISTA'}</UncontrolledTooltip>
                                <a onClick={() => this.updateView("list")} style={{ color: this.state.listView == "list" ? "#3f6ad8" : "#a2a2a2", cursor: "pointer", textDecoration: this.state.listView == "list" ? "underline" : "" }} className="ml-2 mr-2" id='bw-indexTaskModule-tooltip-view-List'>
                                    <i className="fas fa-bars" style={{ fontSize: "33px" }}></i>
                                </a>
                            </React.Fragment>
                        )}

                        {true && (
                            <React.Fragment>
                                <UncontrolledTooltip target='bw-indexTaskModule-tooltip-view-calendar' placement='bottom'>{'VISTA DE TAREAS EN CALENDARIO'}</UncontrolledTooltip>
                                <a onClick={() => this.updateView("calendar")} style={{ color: this.state.listView == "calendar" ? "#3f6ad8" : "#a2a2a2", cursor: "pointer", textDecoration: this.state.listView == "calendar" ? "underline" : "" }} className="mr-3" id='bw-indexTaskModule-tooltip-view-calendar'>
                                    <i className="far fa-calendar-alt" style={{ fontSize: "33px" }}></i>
                                </a>
                            </React.Fragment>
                        )}

                        {true && (
                            <React.Fragment>
                                <UncontrolledTooltip target='bw-indexTaskModule-tooltip-add-user' placement='bottom'>{'AGREGAR USUARIOS'}</UncontrolledTooltip>
                                <a onClick={() => this.toogle("new")} style={{ color: "#3f6ad8", cursor: "pointer" }} className="mr-3" id='bw-indexTaskModule-tooltip-add-user'>
                                    <i className="fas fa-user-plus" style={{ fontSize: "33px" }}></i>                                
                                </a>
                            </React.Fragment>
                        )}
                    </div>
                </div>

                {this.state.listView == "columns" && (
                    <TaskIndexTrello
                        url={`/task/get_data_tasks_board/${this.props.task_board.id}`}
                        user={this.props.user}
                        users={this.state.users}
                        estados={this.props.estados}
                        task_board={this.props.task_board}
                        procesos={this.props.procesos}

                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        task_sources={this.props.task_sources}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                    />
                )}

                {this.state.listView == "list" && (
                    <TableTask
                        url_table={`/task/get_data_tasks_table/${this.props.task_board.id}`}
                        user={this.props.user}
                        users={this.state.users}
                        estados={this.props.estados}
                        task_board={this.props.task_board}
                        procesos={this.props.procesos}
                        columns={this.props.columns}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        task_sources={this.props.task_sources}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}

                        showNew={true}
                        showOptions={true}
                    />
                )}

                {this.state.listView == "calendar" && (
                    <CalendarTask
                        url_calendar={`/task/get_data_tasks_calendar/${this.props.task_board.id}`}
                        user={this.props.user}
                        users={this.state.users}
                        estados={this.props.estados}
                        task_board={this.props.task_board}
                        columns={this.props.columns}
                        procesos={this.props.procesos}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        task_sources={this.props.task_sources}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                    />
                )}
            </div>
        );
    }
}

export default IndexTaskModule;
