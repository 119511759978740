import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FormCreateFormatDuplicateItem from './FormatDuplicationItem/FormCreate';


class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            showTable: false,
            ErrorValues: true,
            format_duplication_item_id: "",

            there_is_format_subform_targets: false,
            there_is_format_subform_sources: false,

            form: {
                format_id: this.props.format.id,
                format_source_id: "",
                question_subform_id: "",
                format_subform_source_id: "",
                format_subform_target_id: ""
            },

            formCreateFormatDuplicateItem: {
                format_duplication_id: "", 
                question_source_id: "", 
                question_target_id: ""
            },

            selectedOption: {
                label: "Selecione un formato",
                format_source_id: ""
            },

            selectedOptionQuestion: {
                label: "Selecione una pregunta",
                question_subform_id: "",
            },

            selectedOptionFormatSubFormSource: {
                label: "Seleccione un formato",
                format_subform_source_id: ""
            },

            selectedOptionFormatSubFormTarget: {
                label: "Seleccione un formato",
                format_subform_target_id: ""
            },

            questions: [],
            format_subform_sources: [],
            format_subform_targets: []
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add items
    updateDataFormatDuplicationItem = (format_duplication_id, data) => {
        this.setState({
            data: this.state.data.map(item => {
                if (format_duplication_id === item.id) {
                    return {
                        ...item,
                        format_duplication_items: [...item.format_duplication_items, data],
                    }
                }
                return item;
            })
        })
    }

    updateItemFormatDuplicationItem = (register) => {
        this.setState({
            data: this.state.data.map(item => {
                if (register.format_duplication_id === item.id) {
                    return {
                        ...item,
                        format_duplication_items: item.format_duplication_items.map(format_duplication_item => {
                            if (register.id === format_duplication_item.id) {
                                return {
                                    ...format_duplication_item,
                                    question_source: register.question_source,
                                    question_target: register.question_target
                                }
                            }
                            return format_duplication_item;
                        })
                    }
                }
                return item;
            })
        })
    }

    //add update
    updateItem = format_duplication => {
        this.setState({
            data: this.state.data.map(item => {
                if (format_duplication.id === item.id) {
                    return {
                        ...item,
                        format_source: format_duplication.format_source,
                        question_subform: format_duplication.question_subform
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,
            there_is_format_subform_sources: false,

            form: {
                format_id: this.props.format.id,
                format_source_id: "",
                question_subform_id: "",
                format_subform_source_id: "",
                format_subform_target_id: ""
            },

            selectedOption: {
                label: "Selecione un formato",
                format_source_id: ""
            },

            selectedOptionQuestion: {
                label: "Selecione una pregunta",
                question_subform_id: "",
            },

            selectedOptionFormatSubFormSource: {
                label: "Seleccione un formato",
                format_subform_source_id: ""
            },

            selectedOptionFormatSubFormTarget: {
                label: "Seleccione un formato",
                format_subform_target_id: ""
            },

            format_subform_sources: []
        })
    }

    componentDidMount = () => {
        this.loadData();
        this.loadDataFormatSubFormTargets();
    }

    selectConfiguration = () => {
        let array = []

        this.props.steps_questions.map((item) => (
            array.push({ label: item.question, value: item.id })
        ))

        this.setState({
            questions: array,
        })
    }

    loadData = () => {
        fetch(`/formatos/get_format_duplications/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    loadDataSelects = () => {
        fetch(`/formatos/data_questions/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                let array = []

                data.steps_questions.map((item) => (
                    array.push({ label: item.question, value: item.id })
                ))

                this.setState({
                    questions: array,
                    modal: true
                });
            });
    }

    validationForm = () => {
        if (this.state.form.format_source_id != "" &&
            this.state.form.format_subform_source_id != "" &&
            this.state.form.format_subform_target_id != "" &&
            this.state.form.question_subform_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.validationForm()) {
            if (this.state.modeEdit) {
                fetch(`/formatos/format_duplications/${this.state.format_association_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            } else {
                fetch(`/formatos/format_duplications`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateData(data.register)
                        this.clearValues();
                        this.setState({ modal: false, showTable: true })
                    });
            }
        }
    }


    editFormatDuplicate = (format_association) => {
        this.loadDataFormatSubFormSources(format_association.format_source.id);
        this.setState({
            modal: true,
            modeEdit: true,
            format_association_id: format_association.id,

            form: {
                format_source_id: format_association.format_source.id,
                question_subform_id: format_association.question_subform != null ? format_association.question_subform.id : "",
                format_subform_source_id: format_association.format_subform_source != null ? format_association.format_subform_source.id : "",
                format_subform_target_id: format_association.format_subform_target != null ? format_association.format_subform_target.id : "",
            },

            selectedOption: {
                label: `${format_association.format_source.name}`,
                format_source_id: format_association.format_source.id,
            },

            selectedOptionQuestion: {
                label: `${format_association.question_subform != null ? format_association.question_subform.question : "Selecione una pregunta"}`,
                question_subform_id: format_association.question_subform != null ? format_association.question_subform.id : "",
            },

            selectedOptionFormatSubFormSource: {
                label: `${format_association.format_subform_source != null ? format_association.format_subform_source.name : "Selecione una pregunta"}`,
                format_subform_source_id: format_association.format_subform_source != null ? format_association.format_subform_source.id : "",
            },

            selectedOptionFormatSubFormTarget: {
                label: `${format_association.format_subform_target != null ? format_association.format_subform_target.name : "Selecione una pregunta"}`,
                format_subform_target_id: format_association.format_subform_target != null ? format_association.format_subform_target.id : "",
            }
        })
    }

    editFormatDuplicateItem = (format_duplication_item) => {
        this.setState({
            format_duplication_item_id: format_duplication_item.id,

            formCreateFormatDuplicateItem: {
                format_duplication_id: "", 
                question_source_id: format_duplication_item.question_source.value, 
                question_target_id: format_duplication_item.question_target.value, 
            },
        })
    }

    clearFormatDuplicateItemValues = () => {
        this.setState({
            format_duplication_item_id: "",
            formCreateFormatDuplicateItem: {
                format_duplication_id: "", 
                question_source_id: "", 
                question_target_id: "", 
            },
        })
    }

    deleteFormatDuplicate = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_duplications/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };


    deleteFormatDuplicateItem = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_duplication_items/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    }

    loadDataFormatSubFormSources = (format_source_id) => {
        fetch(`/formatos/get_format_source_format_associations/${format_source_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                there_is_format_subform_sources: (data.data.length >= 1 ? true : false),
                format_subform_sources: data.data,
            });
        });
    }

    loadDataFormatSubFormTargets = () => {
        fetch(`/formatos/get_format_source_format_associations/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                there_is_format_subform_targets: (data.data.length >= 1 ? true : false),
                format_subform_targets: data.data,
            });
        });
    }

    handleChangeAutocomplete = selectedOption => {
        this.loadDataFormatSubFormSources(selectedOption.value);
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                format_source_id: selectedOption.value,
                format_subform_source_id: ""
            },

            selectedOptionFormatSubFormSource: {
                label: "",
                format_subform_source_id: ""
            },
        });
    };

    handleChangeAutocompleteFormatSubFormSource = selectedOptionFormatSubFormSource => {
        this.setState({
            selectedOptionFormatSubFormSource,
            form: {
                ...this.state.form,
                format_subform_source_id: selectedOptionFormatSubFormSource.value
            }
        });
    };


    handleChangeAutocompleteFormatSubFormTarget = selectedOptionFormatSubFormTarget => {
        this.setState({
            selectedOptionFormatSubFormTarget,
            form: {
                ...this.state.form,
                format_subform_target_id: selectedOptionFormatSubFormTarget.value
            }
        });
    };

    handleChangeAutocompleteQuestions = selectedOptionQuestion => {
        this.setState({
            selectedOptionQuestion,
            form: {
                ...this.state.form,
                question_subform_id: selectedOptionQuestion.value
            }
        });
    };

    handleClickCreateItem = (format_duplication) => {
        this.setState({
            formCreateFormatDuplicateItem: {
                ...this.state.formCreateFormatDuplicateItem,
                format_duplication_id: format_duplication.id
            }
        })
    }

    handleChangeFormatDuplicateItem = (field, value) => {
        this.setState({
            formCreateFormatDuplicateItem: {
                ...this.state.formCreateFormatDuplicateItem,
                [field]: value
            }
        });
    }

    render() {
        const styeleIcons = {
            fontSize: "16px",
            color: "#3f69d8",
            cursor: "pointer",
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <ModalBody>

                        {this.state.modal && (
                            <FormCreate
                                backdrop={"static"}
                                modal={this.state.modal}
                                toggle={this.toogle}
                                title={this.state.modeEdit ? "Actualizar asociación" : "Crear asociación"}
                                nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}
                                modeEdit={this.state.modeEdit}

                                //form props
                                formValues={this.state.form}
                                onChangeForm={this.HandleChange}
                                submitForm={this.HandleClick}
                                errorValues={this.state.ErrorValues}

                                there_is_format_subform_sources={this.state.there_is_format_subform_sources}
                                there_is_format_subform_targets={this.state.there_is_format_subform_targets}

                                //select values
                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                selectedOption={this.state.selectedOption}
                                formatos={this.props.formatos}

                                //selct format_subform_source_id
                                selectedOptionFormatSubFormSource={this.state.selectedOptionFormatSubFormSource}
                                handleChangeAutocompleteFormatSubFormSource={this.handleChangeAutocompleteFormatSubFormSource}
                                format_subform_sources={this.state.format_subform_sources}

                                //select format_subform_target_id
                                selectedOptionFormatSubFormTarget={this.state.selectedOptionFormatSubFormTarget}
                                handleChangeAutocompleteFormatSubFormTarget={this.handleChangeAutocompleteFormatSubFormTarget}
                                format_subform_targets={this.state.format_subform_targets}

                                //select questions 
                                handleChangeAutocompleteQuestions={this.handleChangeAutocompleteQuestions}
                                selectedOptionQuestion={this.state.selectedOptionQuestion}
                                questions={this.state.questions}
                                format={this.props.format}
                            />
                        )}

                        <div className="col-md-12 mb-3 text-right pr-0">
                            {!this.state.modal && (
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.loadDataSelects()}
                                >
                                    Abrir formulario
                                </button>
                            )}
                        </div>

                        {this.state.data.length >= 1 ? (
                            this.state.data.map((format_duplication, index) => (
                                <React.Fragment>
                                    <div className="card mb-2" key={format_duplication.id}>
                                        <div className="card-header" style={{ display: "block" }}>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <p className='mb-0 mt-2'><b>Formato</b></p>
                                                    <label className='ui-letter-format-duplication'>{format_duplication.format_source.name}</label>
                                                </div>

                                                <div className="col-md-4">
                                                    <p className='mb-0 mt-2'><b>Pregunta seleccionada</b></p>
                                                    <label className='ui-letter-format-duplication'>{format_duplication.question_subform.question}</label>
                                                </div>

                                                <div className="col-md-5 text-right">

                                                    <button
                                                        className="btn mt-2"
                                                        onClick={() => this.deleteFormatDuplicate(format_duplication.id)}
                                                    >
                                                        <i
                                                            className="fas fa-trash-alt"
                                                            style={styeleIcons}
                                                            onClick={() => this.deleteFormatDuplicate(failure.id)}
                                                        >
                                                        </i>
                                                    </button>

                                                    <button
                                                        className="btn mt-2 ml-1 mr-1"
                                                        onClick={() => this.editFormatDuplicate(format_duplication)}
                                                    >
                                                        <i
                                                            className="fas fa-pencil-alt"
                                                            style={styeleIcons}
                                                        >
                                                        </i>
                                                    </button>

                                                    {(this.state.formCreateFormatDuplicateItem.format_duplication_id == format_duplication.id) && (
                                                        <FormCreateFormatDuplicateItem
                                                            form={this.state.formCreateFormatDuplicateItem}
                                                            isOpen={this.state.formCreateFormatDuplicateItem.format_duplication_id == format_duplication.id ? true : false}
                                                            modeEdit={false}
                                                            target={`PopoverCreateFormatDuplicateItem${format_duplication.id}`}
                                                            format={this.props.format}
                                                            format_duplication={format_duplication}
                                                            format_duplication_id={this.state.format_duplication_id}
                                                            handleChangeFormatDuplicateItem={this.handleChangeFormatDuplicateItem}
                                                            updateDataFormatDuplicationItem={this.updateDataFormatDuplicationItem}
                                                            clearFormatDuplicateItemValues={this.clearFormatDuplicateItemValues}
                                                        />
                                                    )}

                                                    <button
                                                        className="btn-shadow btn btn-info mt-2"
                                                        id={`PopoverCreateFormatDuplicateItem${format_duplication.id}`}
                                                        onClick={() => this.handleClickCreateItem(format_duplication)}
                                                    >
                                                        Crear item
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="background-gray">
                                            {format_duplication.format_duplication_items.length >= 1 ? (
                                                format_duplication.format_duplication_items.map((format_duplication_item, index) => (
                                                    <React.Fragment>
                                                        <div className="card mb-2" key={format_duplication_item.id}>
                                                            <div className="card-body p-2">
                                                                <div className="row">
                                                                    <div className="col-md-3">
                                                                        <p className='mb-0 mt-2'><b>question_source_id</b></p>
                                                                        <label className='ui-letter-format-duplication'>{format_duplication_item.question_source.label}</label>
                                                                    </div>

                                                                    <div className="col-md-4">
                                                                        <p className='mb-0 mt-2'><b>question_target_id</b></p>
                                                                        <label className='ui-letter-format-duplication'>{format_duplication_item.question_target.label}</label>
                                                                    </div>

                                                                    <div className="col-md-5 text-right">
                                                                        <button
                                                                            className="btn mt-2"
                                                                            onClick={() => this.deleteFormatDuplicateItem(format_duplication_item.id)}
                                                                        >
                                                                            <i
                                                                                className="fas fa-trash-alt"
                                                                                style={styeleIcons}
                                                                            >
                                                                            </i>
                                                                        </button>

                                                                        {(this.state.format_duplication_item_id == format_duplication_item.id) && (
                                                                            <FormCreateFormatDuplicateItem
                                                                                form={this.state.formCreateFormatDuplicateItem}
                                                                                isOpen={this.state.format_duplication_item_id == format_duplication_item.id ? true : false}
                                                                                modeEdit={true}
                                                                                target={`PopoverEditFormatDuplicateItem${format_duplication_item.id}`}
                                                                                format={this.props.format}
                                                                                format_duplication={format_duplication}
                                                                                format_duplication_item={format_duplication_item}
                                                                                handleChangeFormatDuplicateItem={this.handleChangeFormatDuplicateItem}
                                                                                updateItemFormatDuplicationItem={this.updateItemFormatDuplicationItem}
                                                                                clearFormatDuplicateItemValues={this.clearFormatDuplicateItemValues}
                                                                            />
                                                                        )}

                                                                        <button
                                                                            className="btn mt-2 ml-1 mr-1"
                                                                            id={`PopoverEditFormatDuplicateItem${format_duplication_item.id}`}
                                                                            onClick={() => this.editFormatDuplicateItem(format_duplication_item)}
                                                                        >
                                                                            <i
                                                                                className="fas fa-pencil-alt"
                                                                                style={styeleIcons}
                                                                            >
                                                                            </i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))
                        ) : (
                            <div className="card">
                                <div className="card-body text-center">
                                    <h4>No hay registros</h4>
                                </div>
                            </div>
                        )}

                        {/*<div className="card">
                            <div className="background-gray">
                                <table className="table " id="sampleTable">
                                    <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                        <tr>
                                            <th>Formato</th>
                                            <th>Pregunta</th>
                                            <th className="text-right">Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map((format_duplication, index) => (
                                                <React.Fragment>
                                                    {index >= 1 ? <div className="mb-2"></div> : null}
                                                    <tr key={format_duplication.id} className="tr-table-task" style={{ borderLeft: `4px solid green` }}>

                                                        <td>{format_duplication.format_target ? format_duplication.format_target.name : ""}</td>
                                                        <td>{format_duplication.question_subform ? format_duplication.question_subform.question : ""}</td>
                                                        <td className="text-right">
                                                            {true && (
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(format_duplication)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}
                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(format_duplication.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>*/}


                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;