import React, { useState, useEffect } from 'react';
import Pagination from "react-js-pagination";
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody  } from 'reactstrap';
import PopoverUpdateColumn from '../PopoverUpdateColumn';
import ReactHtmlParser from 'react-html-parser';

const IndexTablet = (props) => {

    const [id, setId] = useState("");

    const openInfo = (e ,task_id) => {
        e.stopPropagation();
        setId(task_id);
    }

    const clearValues = (e) => {
        e.stopPropagation();
        setId('');
    }

    return (
        <React.Fragment>
            <div className="content main-card mb-3 card card-style">
                    <div className="card-body">

                        {(props.showNew && (props.task_board ? !props.task_board.delete_template : true) && props.estados.create) && (
                            <div className="col-md-12 pl-0 mb-2">
                                <button
                                    className="btn-shadow btn btn-info mr-3"
                                    onClick={() => props.toogleNew("new")}
                                >
                                    <i className="fas fa-plus"></i> Nueva
                                </button>
                            </div>
                        )}
                    {props.data.length >= 1 ? (
                        <React.Fragment>
                            <table>
                                <thead>
                                    <tr className='ui-tableTaskTablet-container-subtitle'>
                                        <th>Código</th>
                                        <th>Título Tarea</th>
                                        <th>Responsables</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Final</th>                                
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody ref={props.scrollRef}>
                                    {props.data.map((task, index) => (
                                        <React.Fragment>
                                            <div className="card ui-tableTaskTablet-container-card" onClick={() => props.estados.gestionar ? props.handleClickShow(task.id) : null } style={{borderLeft: `5px solid ${props.get_color(task)}`}}>
                                                <div className="card-body">
                                                    <tr className='ui-tableTaskTablet-container-data'>
                                                        <td>{`T${task.count}`}</td>
                                                        <td style={{paddingLeft: '5px', paddingRight: '5px', width: '98%'}} className='ui-tableTaskTablet-title-task'>
                                                            {task.name} 
                                                        </td>

                                                        <td>
                                                            <div className="row" style={{flexWrap: 'nowrap'}}>
                                                            <div className="icon-show-user-responsible ui-boards-index"
                                                                id={`Popover${task.id}`}
                                                                style={{ marginLeft: '8px', height: '37px', width: '37px' }}>

                                                                {task.user != null ? task.user.first_name.charAt(0) : ""} {task.user != null ? task.user.first_last_name.charAt(0) : ""}
                                                            </div>

                                                            <UncontrolledTooltip placement="top" target={`Popover${task.id}`}>
                                                                {task.user != null ? task.user.first_name : ""} {task.user != null ? task.user.first_last_name : ""}
                                                            </UncontrolledTooltip>

                                                            <div className="icon-show-user-responsible ui-boards-index mr-2"
                                                                id={`PopoverOwner${task.id}`}
                                                                style={{ marginLeft: '8px', height: '37px', width: '37px' }} >

                                                                {task.user_owner != null ? task.user_owner.first_name.charAt(0) : ""} {task.user_owner != null ? task.user_owner.first_last_name.charAt(0) : ""}
                                                            </div>
                                                            <UncontrolledTooltip placement="top" target={`PopoverOwner${task.id}`}>
                                                                {task.user_owner != null ? task.user_owner.first_name : ""} {task.user_owner != null ? task.user_owner.first_last_name : ""}
                                                            </UncontrolledTooltip>
                                                            </div>
                                                        </td>

                                                        <td>{task.created_date}</td>
                                                        <td>{task.due_date}</td>
                                                        <td><i className="fas fa-ellipsis-h" id={`PopoverTaskTablet${task.id}`} onClick={(e) => openInfo(e, task.id)}></i></td>
                                                    </tr>
                                                    <UncontrolledPopover isOpen={id == task.id}  placement="bottom" target={`PopoverTaskTablet${task.id}`}>
                                                        <PopoverHeader>Información Complementaria de {`T${task.count}`}<a className="close-popover" onClick={(e) => clearValues(e)}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                        <PopoverBody className="popover-column">
                                                        <div className="ui-tableTaskMobilePopover-container">
                                                            <tr className='ui-tableTaskTabletPopover-container-subtitle'>
                                                                <th>Estados</th>
                                                                <th>Fase - Tablero</th>
                                                                <th>Comentarios</th>
                                                                <th>Checklist</th>
                                                                <th>Adjuntos</th>
                                                                <th>Cambios</th>
                                                                <th>Opciones</th>
                                                            </tr>
                                                            <tr className='ui-tableTaskTabletPopover-container-data'>
                                                                <td className='ui-tableTask-state-task'>
                                                                    <React.Fragment>
                                                                        <div>{task.count_days} días</div>
                                                                        <div><i className="fas fa-stopwatch"></i></div>
                                                                    </React.Fragment>
                                                                    {task.date.red.show && (
                                                                        <React.Fragment>
                                                                            <div className='ui-tableTask-days-expiration'>{`${task.date.red.number} días `}</div><div className='ui-color-change-task' style={{background: 'red', width: '10px', height: '10px', borderRadius: '50%'}}></div>
                                                                        </React.Fragment>
                                                                    )}

                                                                    {task.date.yellow.show && (
                                                                        <React.Fragment>
                                                                            <div className='ui-tableTask-days-expiration' >{`${task.date.yellow.number} días `}</div><div className='ui-color-change-task' style={{background: 'yellow', width: '10px', height: '10px', borderRadius: '50%'}}></div>
                                                                        </React.Fragment>
                                                                    )}

                                                                    {task.date.green.show && (
                                                                        <React.Fragment>
                                                                            <div className='ui-tableTask-days-expiration'>{`${task.date.green.number}  días`}</div><div className='ui-color-change-task' style={{background: 'green', width: '10px', height: '10px', borderRadius: '50%'}}></div>                                                
                                                                        </React.Fragment>
                                                                    )}                                                
                                                                </td>
                                                                <td style={{justifySelf: 'flex-start', paddingLeft:'5px'}}>
                                                                    <div>
                                                                        {(props.showOptions && props.estados.edit) && (
                                                                            <React.Fragment>
                                                                                <a className="ml-2"
                                                                                onClick={(e) => props.showPopover(e, task.id)}
                                                                                id={`PopoverLabels${task.id}`} >

                                                                                    <i className="fas fa-pencil-alt pr-3" style={{color: '#16aafb'}}></i>
                                                                                </a>

                                                                                <UncontrolledPopover isOpen={props.task_id == task.id} placement="bottom" target={`PopoverLabels${task.id}`} onClick={(e) => e.stopPropagation()}>
                                                                                    <PopoverHeader>Cambiar de columna <a className="close-popover" onClick={() => props.cancelPopoverUpdateColumn()}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                                                        <PopoverBody>
                                                                                            <PopoverUpdateColumn
                                                                                                cancelPopoverUpdateColumn={props.cancelPopoverUpdateColumn}
                                                                                                updateColumn={props.updateColumn}
                                                                                                task={task.task}
                                                                                                task_boards={props.task_boards}
                                                                                            />
                                                                                        </PopoverBody>
                                                                                    </UncontrolledPopover>
                                                                                </React.Fragment>
                                                                            )}

                                                                        {task.columna ? ReactHtmlParser(task.columna.label) : ""}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                {task.general_comments >= 1 ? (
                                                                    <div className="msg-count">

                                                                        <React.Fragment>
                                                                            <i className="far fa-comments pr-2"></i>
                                                                            <b>{task.general_comments}</b>
                                                                        </React.Fragment>

                                                                    </div>
                                                                ) : (
                                                                        <React.Fragment>
                                                                            <i className="fas fa-ban"></i>
                                                                        </React.Fragment>
                                                                    
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {task.checklist != null ?  (
                                                                        <div className="msg-count" >
                                                                            <React.Fragment>
                                                                                <i className="fas fa-check-double pr-2"></i>
                                                                                <b className="ml-1">{task.checklist}</b>
                                                                            </React.Fragment>
                                                                        </div>
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            <i className="fas fa-ban"></i>
                                                                        </React.Fragment>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                {task.general_files >= 1 ? (
                                                                    <div className="msg-count">

                                                                        <React.Fragment>
                                                                            <i className="fas fa-paperclip pr-2"></i>
                                                                            <b className="ml-1">{task.general_files}</b>
                                                                        </React.Fragment>

                                                                    </div>
                                                                ): (
                                                                    <React.Fragment>
                                                                        <i className="fas fa-ban"></i>
                                                                    </React.Fragment>
                                                                )}
                                                                </td>
                                                                <td>
                                                                {task.notification_task_count >= 1 ? (
                                                                    <div className="msg-count">
                                                                        <React.Fragment>
                                                                            <i className="fas fa-sync-alt  pr-2"></i>
                                                                            <b className="ml-1">{task.notification_task_count}</b>
                                                                        </React.Fragment>
                                                                    </div>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <i className="fas fa-ban"></i>
                                                                    </React.Fragment>
                                                                )}
                                                                </td>
                                                                <td>
                                                                    {props.restore ? (
                                                                        <React.Fragment>
                                                                            <a onClick={(e) => props.restoreTask(e, task.id)}
                                                                            className="btn"
                                                                            style={{ color: "green" }}
                                                                            id={`filed${task.id}`}>
                                                                                
                                                                                <i className="fas fa-archive"></i>
                                                                            </a>
                                                                            <UncontrolledTooltip placement="top" target={`filed${task.id}`}>
                                                                                {"Restablecer tarea"}
                                                                            </UncontrolledTooltip>
                                                                        </React.Fragment>
                                                                        ) : (
                                                                        <React.Fragment>
                                                                            <a onClick={(e) => props.editState(e, task.id, "filed")}
                                                                            className="btn"
                                                                            style={{ color: "green" }}
                                                                            id={`filed${task.id}`}>

                                                                                <i className="fas fa-archive"></i>
                                                                            </a>
                                                                            <UncontrolledTooltip placement="top" target={`filed${task.id}`}>
                                                                                {"Archivar"}
                                                                            </UncontrolledTooltip>
                                                                        </React.Fragment>
                                                                    )}
                                                                    
                                                                    {true && (
                                                                        <React.Fragment>
                                                                            <a onClick={(e) => props.delete(e, task.id)}
                                                                            className="btn"
                                                                            style={{ color: "red" }}
                                                                            id={`delete${task.id}`}>

                                                                                <i className="far fa-trash-alt"></i>
                                                                            </a>
                                                                            <UncontrolledTooltip placement="top" target={`delete${task.id}`}>
                                                                                {"Eliminar"}
                                                                            </UncontrolledTooltip>
                                                                        </React.Fragment>
                                                                    )}
                                                                </td>
                                                            </tr>

                                                        </div>
                                                        </PopoverBody>
                                                    </UncontrolledPopover>
                                                </div>
                                            </div>                                 
                                        
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </React.Fragment>
                    ) : (
                        <div className='card'>
                        <div  colSpan="8" className=" card-body text-center">
                            <div className="text-center col-md-12">
                                <div className="text-center mt-4 mb-4" style={{ width: '100%' }}>
                                    {props.restore == undefined ? <p className='ui-tableTask-messageNoRecord'>Todavía no hay tareas creadas, da clic en <a onClick={() => props.toogleNew("new")} className="btn btn-info">+  Nueva</a> y genera la primera tarea.</p> : null}
                                        <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/7/noRecord_task.jpg" alt="" className="ui-tableTask-imgNoRecord" />
                                </div>
                            </div>
                        </div>
                    </div>   
                    )}
                </div>
                <div>
                    {true && (
                        <div className="row col-md-12">

                            <div className="col-md-4 text-left">
                                <p>
                                    Mostrando {props.data.length} de {props.total_data}
                                </p>
                            </div>

                            <div className="col-md-6 pl-0">
                                <Pagination
                                    hideNavigation
                                    activePage={props.activePage}
                                    itemsCountPerPage={props.countPage}
                                    itemClass="page-item"
                                    innerClass="pagination"
                                    linkClass="page-link"
                                    totalItemsCount={props.total_data}
                                    pageRangeDisplayed={props.countPage}
                                    onChange={props.handlePageChange}
                                />
                            </div>

                        </div>
                    )}
    
                </div>
            </div>
        </React.Fragment>
    );
}


export default IndexTablet;