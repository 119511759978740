import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import Show from '../components/formatos/FormatCustomView/Show';

class ShowCustomView extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            isLoaded: true,
        }
    }

    componentDidMount(){
        //this.loadData();
    }

    loadData = () => {
        fetch(this.state.url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={this.props.format_custom_view.type == "dashboard" ? "Vista tipo dashboard" : "Vista pesonalizada"}
                    subTitle={"Gestión de tareas"}
                    titleNew={""}
                    titleFilter={"Filtros"}
                    onClickNew={""}
                    onClickFilter={this.handleClickShowFilter}
                    showNew={false}
                    showFilter={false}
                />
                
                <Show
                    format_custom_view={this.props.format_custom_view}
                    questions={this.props.questions}
                    formatos={this.props.formatos}
                    states={this.props.states}
                    users={this.props.users}
                    procesos={this.props.procesos}
                    cargos={this.props.cargos}
                    recommended_charts_api={this.props.recommended_charts_api}
                />
               
            </React.Fragment>
        );
    }
}


export default ShowCustomView;
WebpackerReact.setup({ ShowCustomView });

