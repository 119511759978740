import React from 'react';
import Table from "../NotificationEvents/table";
import Filter from "../NotificationEvents/FormFilter"
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'



class index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      formFilter: {
        event_date_first: "",
        event_date_second: "",
        specific_area_id: [],
        source_id: [],
        related_event_id: [],
      },

      selectedSpecificAreas: {
        specific_area_id: "",
        label: "Área específica"
      },

      selectedOptionSource: {
        source_id: "",
        label: "Tipo de evento"
      },

      selectedOptionRelatedEvent: {
        related_event_id: "",
        label: "Evento relacionado"
      },


      stateSearch: false,
      stateSearchCancel: false,
      showChart: false,

      dataSpecificAreas: [],
      dataSource: [],
      dataRelatedEvent: [],

      dataDoughnut: {
        show: false,
        labels: [],
        data: [],
        datag: []
      },
      dataColumn: {
        show: false,
        labels: [],
        data: [],
        datag: []
      },
      dataLine: {
        show: false,
        labels: [],
        data: [],
        datag: []
      },
      is_loading:true,
    }
  }

  loadData = () => {
    console.log("hola")
    fetch("/health/get_notification_events")
      .then(response => response.json())
      .then(data => {
        console.log(data)
        this.setState({
          data: data.notifications,
          is_loading:false
        });
      });


  }

  componentDidMount() {
    this.loadData();
    this.selectsValues();
  }

  selectsValues = () => {
    let arraySpecificAreas = []
    let arraySource = []
    let arrayRelatedEvent = []

    this.props.areas.map((item) => (
      arraySpecificAreas.push({ label: item.name, value: item.id })
    ))

    this.props.sources.map((item) => (
      arraySource.push({ label: item.name, value: item.id })
    ))

    this.props.events.map((item) => (
      arrayRelatedEvent.push({ label: item.name, value: item.id })
    ))

    this.setState({
      dataSpecificAreas: arraySpecificAreas,
      dataSource: arraySource,
      dataRelatedEvent: arrayRelatedEvent
    })
  }

  handleChangeAutocompleteSpecificAreas = selectedOptionSpecificAreas => {
    let array = []

    selectedOptionSpecificAreas.map((item) => (
      array.push(item.value)
    ))

    this.setState({
      formFilter: {
        ...this.state.formFilter,
        specific_area_id: array
      }
    })
  };


  handleChangeAutocompleteSource = selectedOptionSource => {
    let array = []

    selectedOptionSource.map((item) => (
      array.push(item.value)
    ))

    this.setState({
      formFilter: {
        ...this.state.formFilter,
        source_id: array
      }
    })
  };

  handleChangeAutocompleteRelatedEvent = selectedOptionRelatedEvent => {
    let array = []

    selectedOptionRelatedEvent.map((item) => (
      array.push(item.value)
    ))

    this.setState({
      formFilter: {
        ...this.state.formFilter,
        related_event_id: array
      }
    })
  };

  clearValuesFilter = () => {
    this.setState({
      showChart: false,
      show_filter: false,
      formFilter: {
        event_date_first: "",
        event_date_second: "",
        specific_area_id: [],
        source_id: [],
        related_event_id: [],
      },

      selectedSpecificAreas: {
        specific_area_id: "",
        label: "Área específica"
      },

      selectedOptionSource: {
        safety_related_event_id: "",
        label: "Tipo de evento"
      },

      selectedOptionRelatedEvent: {
        related_event_id: "",
        label: "Evento relacionado"
      },
    })
  }

  handleChangeFilter = e => {
    this.setState({
      formFilter: {
        ...this.state.formFilter,
        [e.target.name]: e.target.value
      }
    });
  };

  HandleClickFilter = e => {
    fetch(`/health/get_notification_events?event_date_first=${this.state.formFilter.event_date_first != undefined ? this.state.formFilter.event_date_first : ""}&event_date_second=${this.state.formFilter.event_date_second != undefined ? this.state.formFilter.event_date_second : ""}&specific_areas=${this.state.formFilter.specific_area_id}&sources=${this.state.formFilter.source_id}&related_events=${this.state.formFilter.related_event_id}&`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          data: data.notifications,
        });

        this.getDataDoughnut();
        this.getColumnChart();
        this.getLineChart();
      });
  };
  HandleClickFilterChart = e => {
    fetch(`/health/get_notification_events?event_date_first=${this.state.formFilter.event_date_first != undefined ? this.state.formFilter.event_date_first : ""}&event_date_second=${this.state.formFilter.event_date_second != undefined ? this.state.formFilter.event_date_second : ""}&specific_areas=${this.state.formFilter.specific_area_id}&sources=${this.state.formFilter.source_id}&related_events=${this.state.formFilter.related_event_id}&`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          data: data.notifications,
          showChart: true
        });

        this.getDataDoughnut();
        this.getColumnChart();
        this.getLineChart();
      });
  };


  getDataDoughnut = () => {

    let dataX = [];
    let dataY = [];
    let dataz = [['Task', 'Hours per Day']];

    if (this.state.formFilter.source_id.length > 0) {
      console.log("no 0")
      this.props.sources.map(source => {
        if (this.state.formFilter.source_id.includes(source.id)) {
          if (source.name == "Cuasi falla") {
            dataX.push("#4ab77b");
          } else if (source.name == "Evento Adverso") {
            dataX.push("#ffc800");
          } else {
            dataX.push("#ec695c");
          }
          let x = source.name 
          dataz.push([x, this.state.data.filter(value => value.safety_related_event_id == source.id).length])
        }
      })
    } else {
      console.log("si 0")
      this.props.sources.map(source => {

        if (source.name == "Cuasi falla") {
          dataX.push("#4ab77b");
        } else if (source.name == "Evento Adverso") {
          dataX.push("#ffc800");
        } else {
          dataX.push("#ec695c");
        }
        let x = source.name 
        dataz.push([x, this.state.data.filter(value => value.safety_related_event_id == source.id).length])

      })
    }

    this.setState({
      dataDoughnut: {
        ...this.state.dataDoughnut,
        labels: dataX,
        data: dataY,
        datag: dataz
      },

    });
  }


  getColumnChart = () => {

    let dataX = [];
    let dataY = [];
    let dataz = [['x', 'Evento']];

    if (this.state.formFilter.related_event_id.length > 0) {
      console.log("no 0")
      this.props.events.map(source => {
        if (this.state.formFilter.related_event_id.includes(source.id)) {
          let count = this.state.data.filter(value => value.related_event_id == source.id).length
          let x = source.name 
          dataz.push([x, count])
        }
      })
    } else {
      console.log("si 0")
      this.props.events.map(source => {
        let count = this.state.data.filter(value => value.related_event_id == source.id).length
        let x = source.name 
        dataz.push([x,count])

      })
    }

    this.setState({
      dataColumn: {
        ...this.state.dataBar,
        datag: dataz
      },

    });
  }

  getLineChart = () => {

    let date_first = new Date(this.state.formFilter.event_date_first)
    let date_second = new Date(this.state.formFilter.event_date_second)
    let meses = [];
    let meses_string = [];
    let i;
    let pairFechas = this.getPairMonth(date_first, date_second);

    for (i = pairFechas[0]; i < pairFechas[1] + 1; i++) {
      meses.push(i);

    }
    meses.map(mes => meses_string.push(this.date(mes)))
    let meses_data = [['x', 'Evento']]
    meses.map((mes, index) => {
      let a = 0;
      this.state.data.map(data => {
        console.log(new Date(data.event_date).getMonth())
        console.log(mes)
        if (new Date(data.event_date).getMonth() == mes) {
          a = a + 1;
           }

      })

      meses_data.push([meses_string[index], a])

    })

    
if (meses_data.length > 1)
{
  this.setState({

    dataLine: {

      ...this.state.dataLine,
      datag: meses_data,
      show:true,
    },

  });
}else{
  this.setState({

    dataLine: {
     
      ...this.state.dataLine,
      datag: meses_data,
      show:false,
    },

  });
}


  }

  getPairMonth = (f1, f2) => {
    let date_first_month = new Date(f1)
    let date_second_month = new Date(f2)

    date_first_month = new Date(date_first_month.setDate(date_first_month.getDate() + 1)).getMonth();
    date_second_month = new Date(date_second_month.setDate(date_second_month.getDate() + 1)).getMonth();

    return [date_first_month, date_second_month]
  }

  getMonths = (date) => {
    date = new Date(date);
    new Date(date.setDate(date.getDate() + 1)).getMonth()

  };


  date = (fecha) => {
    var d = new Date(fecha),
      months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    return months[fecha];

  }





  CancelFilter = () => {
    this.setState({
      formSearch: {
        name: "",
      },
      stateSearchCancel: false
    });
    this.loadData();
  }

  showFilter = (valor) => {
    console.log("hola mundo");
    if (valor == true) {
      this.clearValuesFilter();
      this.loadData();

    } else {
      this.setState({ show_filter: true });
    }
  }

  new = () => {
    Turbolinks.visit("/health/event_notification/new", "_self");
  }

  render() {
    return (
      <React.Fragment>
        <HeaderTitle
          title={"Notificación de Eventos"}
          subTitle={"Notifica todos los eventos que sepresenten en el hospital"}
          titleNew={"Nuevo Evento"}
          titleFilter={"Filtros"}
          onClickFilter={this.showFilter}
          onClickNew={this.new}
          showNew={this.props.estados.crear}
          icon={"far fa-bell"}
          showFilter={true}
        />

        <div style={{ display: this.state.show_filter == true ? "block" : "none" }}>
          <Filter

            onChangeFilter={this.handleChangeFilter}
            formValuesFilter={this.state.formFilter}
            onClick={this.HandleClickFilter}
            handleClickFilterChart={this.HandleClickFilterChart}
            cancelFilter={this.cancelFilter}
            closeFilter={this.showFilter}
            areas={this.props.areas}
            events={this.props.events}
            sources={this.props.sources}

            //selects Multis

            selectedSpecificAreas={this.state.selectedSpecificAreas}
            selectedOptionSource={this.state.selectedOptionSource}
            selectedOptionRelatedEvent={this.state.selectedOptionRelatedEvent}

            //values 

            dataSpecificAreas={this.state.dataSpecificAreas}
            dataSource={this.state.dataSource}
            dataRelatedEvent={this.state.dataRelatedEvent}

            //handlecHange Selects

            handleChangeAutocompleteSpecificAreas={this.handleChangeAutocompleteSpecificAreas}
            handleChangeAutocompleteSource={this.handleChangeAutocompleteSource}
            handleChangeAutocompleteRelatedEvent={this.handleChangeAutocompleteRelatedEvent}


          />
        </div>


        <Table
         handleClickFilterChart={this.HandleClickFilterChart}
          dataActions={this.state.data}
          loadInfo={this.loadData}
          usuario={this.props.usuario}
          show={this.showFilter}
          showChart={this.state.showChart}
          //selects Multis
          closeFilter={this.showFilter}
          selectedSpecificAreas={this.props.areas}
          selectedOptionSource={this.props.sources}
          selectedOptionRelatedEvent={this.props.events}
          dataDoughnut={this.state.dataDoughnut}
          dataColumn={this.state.dataColumn}
          dataLine={this.state.dataLine}
          nameModule={this.props.nameModule}
          is_loading={this.state.is_loading}

          estados={this.props.estados}
        />

      </React.Fragment>

    )

  }
}

export default index;


//fetch(`/health/get_notification_events?event_date=${this.state.formFilter.date_incident != undefined ? this.state.formFilter.date_incident : "" }&specific_area_id=${this.state.formFilter.specific_area_id != undefined ? this.state.formFilter.specific_area_id : ""}&safety_related_event_id=${this.state.formFilter.safety_related_event_id != undefined ? this.state.formFilter.safety_related_event_id : ""}&related_event_id=37,38&description=${this.state.formFilter.description != undefined ? this.state.formFilter.description : ""}`)