import React, { Component } from 'react';
import Grid from './grid'
import ShowModal from './ShowModal'
import { Tooltip } from 'reactstrap';
import FormCreate from '../../formatos/Formats/FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

const INITIAL_STATE = {
    causes: undefined, 
    effect: undefined, 
    index: 0,
    modal: false,
    modalMejora: false,
    showCause: "",
    showTitle: "",
    subClase: {},

    form: {
        title: "",
        description: "",
    },
}

export default class FishboneChart extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = INITIAL_STATE
    }

    HandleChange = (e) => {
        this.setState({
            form: {
              ...this.state.form,
              title: e.target.value
            }
        });
    }

    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                ...this.state.form,
                title: ""
            }
        })
    }

    toogleMejora = (from, subClase) => {
        if(from == "new"){
            this.setState({ 
                modalMejora: true, 
                subClase: subClase,
                form: {
                    ...this.state.form,
                    description: subClase +  ", " + this.props.cause_analysis.id
                },
            })
            //this.clearValues();
        }else{
            this.setState({ modalMejora: false})
        }
    }

    HandleClick = () => {
        fetch("/cause/sub_factor_items", {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.clearValues();
            this.props.loadData();
            this.setState({ modalMejora: false})
            this.messageSuccess(data);
        });
    }

    componentWillMount() { 
        const data = this.props.data
        if(data) {
            const effect = Object.keys(data)[this.state.index]            
            this.setState({causes: data[effect], effect})
        }        
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.data !== nextProps.data) {
            const data = nextProps.data
            const effect = Object.keys(data)[this.state.index]
            this.setState({causes: data[effect], effect})
        }
    }

    showCause= (code) => {
        this.setState({
            showCause: code
        })
    }

    render() {
        if (!this.state.causes) {
            return <React.Fragment></React.Fragment>
        }

        return (
            <Grid cols={this.props.cols}>
                {this.state.modal && (
                    <ShowModal
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Añadir subcausas"}
                        subClase={this.state.subClase}
                        loadData={this.props.loadData}
                        cause_analysis={this.props.cause_analysis}
                        get_name={this.props.get_name}
                    />
                )}

                {this.state.modalMejora && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalMejora}
                        toggle={this.toogleMejora}    
                        title={`Agregar Item para ${this.state.subClase}`}

                        //form props
                        formValues={this.state.form}
                        errorValues={this.state.ErrorValues}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        subClase={this.state.subClase}
                    />
                )}

                <div className="fishboneChart">
                    {this.getCauses()}
                    {this.getEffect()}
                    {this.getLegend()}
                </div>
            </Grid >
        )
    }

    toogle = (from, subClase) => {
        if (from == "new") {
            this.setState({ modal: true, subClase: subClase})
        } else {
            this.setState({ modal: false })
        }
    }


    getTopCauses(causes) {
        const categories = Object.keys(causes)

        const middle = parseInt(categories.length / 2)
        const topArray = categories.slice(0, middle)

        const color = this.getColor(this.state.index)
        const topCauses = topArray.map((category, index) => {
            return (
                <div key={`top_causes_${category}_${index}`} className="causeContent">
                    <div className={`cause top ${color}_ ${color}Border`} style={{position:"relative"}}>
                        <span
                            onMouseEnter={ ()=> this.setState({ showTitle: category})}
                            onMouseLeave={ ()=> this.setState({ showTitle:""})}
                        >{category}</span> 
                             <i className="fas fa-plus cause-icon" onClick={() => this.props.tenant.app_type == "beewo" ? this.toogleMejora("new", category) : this.toogle("new", category)}></i>
                            {this.props.tenant.app_type != "beewo" && (
                                <React.Fragment>
                                    <div className="causes-show-cause" style={{ position:"absolute", left:"90px", display: this.state.showTitle == category ? "block" : "none" }}><span>{this.props.get_name(category)}</span></div>
                                </React.Fragment>
                            )}
                    </div>

                    <div className="causeAndLine">
                        {this.getRootCauses(causes[category],"top")}
                        <div className={`diagonalLine ${color}TopBottom`}/>
                    </div>
                </div>
            )
        })

        return (<div className="causesGroup">{topCauses}</div>)
    }

    getBottomCauses(causes) {
        const categories = Object.keys(causes)

        const middle = parseInt(categories.length / 2)
        const bottomArray = categories.slice(middle)

        const color = this.getColor(this.state.index)

        const bottomCauses = bottomArray.map((category, index) => {
            return (
                <div key={`bottom_causes_${category}_${index}`}
                    className="causeContent">
                    <div className="causeAndLine">
                        {this.getRootCauses(causes[category], "bottom")}
                        <div className={`diagonalLine ${color}BottomTop`}/>
                    </div>
                    <div 
                        className={`cause bottom ${color}_ ${color}Border`}
                        style={{paddingBottom:"5px", position:"relative", left: "24px"}}
                    >
                        <span
                            onMouseEnter={ ()=> this.setState({ showTitle: category})}
                            onMouseLeave={ ()=> this.setState({ showTitle:""})}
                        >{category}</span> 
                        <i className="fas fa-plus cause-icon" onClick={() => this.props.tenant.app_type == "beewo" ? this.toogleMejora("new", category) : this.toogle("new", category) }></i>
                        {this.props.tenant.app_type != "beewo" && (
                           <React.Fragment>
                                <div className="causes-show-cause" style={{ position:"absolute", left:"80px", display: this.state.showTitle == category ? "block" : "none" }}><span>{this.props.get_name(category)}</span></div>
                            </React.Fragment>
                        )}
                    

                    </div> 
                </div>
            )
        })

        return (<div className="causesGroup"><img className="img-fishbone" src="https://mybc1.s3.amazonaws.com/uploads/rseguimiento/evidencia/713/flecha1.png" alt=""/> {bottomCauses}</div>)
    }

    getRootCauses(rootCauses, type) {
        const causes = rootCauses.map((rootCause, index) => {
            return (
                <div 
                    key={`root_causes_${rootCause.code}_${index}`}
                    style={{paddingBottom:"5px", position:"relative", left: `${type == "top" ? (index * 5 + (20 - rootCauses.length )) : (15 - index * 5 + 10 - (10 - rootCauses.length))}px`}}
                >
                    <div className="causes-show-cause" style={{position:"absolute",left:"36px", display: this.state.showCause == rootCause.code ? "block" : "none"}}><span>{rootCause.code}:</span> {rootCause.description}</div>
                   <div className="bone-cause-description">
                       {this.props.tenant.app_type != "beewo" && (
                           <React.Fragment>
                                <a 
                                    href="javascript:void(0)" 
                                    data-toggle="tooltip" 
                                    data-placement="top" 
                                    style={{ 
                                        position: "relative",
                                    }}
                                    className="causes-show-link"
                                    onMouseEnter={ ()=> this.showCause(rootCause.code)}
                                    onMouseLeave={ ()=> this.setState({showCause:""})}
                                >
                                    {rootCause.code}: 
                                </a>
                            </React.Fragment>
                       )}
                    <span className="ml-1">{this.props.tenant.app_type == "beewo" ? rootCause.title : rootCause.description}</span>
                    </div>
                </div>
            )
        })
        return (<div className={`rootCauses`}>{causes} </div>)
    }


    getCauses() {
        const causes = this.state.causes
        const color = this.getColor(this.state.index)
        return (
            <div className="causes">
                {this.getTopCauses(causes)}
                <div className={`lineEffect ${color}Border`} />
                {this.getBottomCauses(causes)}
            </div>
        )
    }

    getEffect() {        
        const color = this.getColor(this.state.index)
        return (
            <div className={`effect left ${color}_ ${color}Border`}>
                <div className={`effectValue`}>
                    <p className="mb-0" style={{fontWeight:"bold"}}>{this.state.effect}</p>
                    <img className="img-fishbone-right" src="https://mybc1.s3.amazonaws.com/uploads/rseguimiento/evidencia/713/flecha1.png" alt=""/>
                </div>
            </div>
        )
    }

    selectDataset(index) {
        const data = this.props.data
        if(data) {
            const effect = Object.keys(data)[index]            
            this.setState({causes: data[effect], effect, index})
        }
    }

    getLegend() {        
        const labels = Object.keys(this.props.data)

        if(labels.length <= 1) {
            return
        }

        const labelsDivs = labels.map((label, index) => {
            const labelClass = index === this.state.index ? 'label_' : 'labelLineThrough'
            const color = this.getColor(index)
            return (
                <div key={`labels_${label}_${index}`} className="legendLabel" onClick={() => this.selectDataset(index)}>
                    <div className={`labelSquare legend all ${color}_dark ${color}Border`} />
                    <div className={labelClass}>{label}</div>
                </div>
            )
        })

        return (
            <div className="legend">
                {labelsDivs}
            </div>
        )
    }

    getColor(index) {
        const colors = [
            'blue',
            'pink',
            'gray',
            'green',            
            'blue_two',            
            'orange',
            'black',            
            'purple'
        ]
    
        if (index >= colors.length) {
            index -= colors.length
        }

        return colors[index]
    }
}