import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-6">
                                    <label>Nombre del indicador</label>
                                    <input
                                        type="text"
                                        name="name"
                                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                        value={this.props.formValues.name}
                                        onChange={this.props.onChangeForm}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label>Tipo de gráfica</label>
                                    <select 
                                        name="chart_type"
                                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.chart_type == "" ? "error-class" : ""}`}
                                        value={this.props.formValues.chart_type}
                                        onChange={this.props.onChangeForm}
                                    >
                                        <option value="">Seleccione un tipo</option>
                                        <option value="barras">Barras</option>
                                        <option value="linea">Linea</option>
                                        <option value="linea_mesnual">Linea Mensual</option>
                                        <option value="pie">Pie</option>
                                    </select>
                                </div>

                                <div className="col-md-12 mt-3">
                                    <input
                                        type="hidden"
                                        name="question_x_id"
                                        value={this.props.selectedOptionQuestion.question_x_id}
                                    />
                                    <label>Pregunta en x</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteQuestions}
                                        options={this.props.questions}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.props.selectedOptionQuestion}
                                    />
                                </div>
                                <div className="col-md-12 mb-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="question_y_id"
                                        value={this.props.selectedOption.question_y_id}
                                    />
                                    <label>Pregunta en y</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocomplete}
                                        options={this.props.questions}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.props.selectedOption}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <label>Preguntas</label>
                                    <input
                                        type="hidden"
                                        name="question_ids"
                                        value={this.props.selectedOptionMulti.question_ids}
                                    />
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        autoFocus={false}
                                        className={`link-form`}
                                        classNamePrefix="select"
                                        placeholder="Seleccione"
                                        name="question_ids"
                                        onChange={this.props.handleChangeAutocompleteMulti}
                                        options={this.props.questions}
                                        defaultValue={this.props.editValues}
                                    />
                                </div>

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={() => this.props.submitForm()}>{this.props.nameBnt}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;