import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import IconsCornerNew from '../../../../GeneralComponents/IconsCornerNew';

class IndexWeb extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card card-style">
                    <div className="card-body ui-tables_layout-register-card-body">
                        <div className="ui-tables_layout-table__container">
                            {this.props.data.length >= 1 ? (
                                <table className="table ui-tables_layout-table-header__container" id="sampleTable">
                                    <thead className='ui-tables_layout-table-header'>
                                        <tr className='ui-tables_layout-__container-title-table'>
                                            <th>Versión</th>
                                            <th>Código</th>
                                            <th>Nombre del documento</th>
                                            <th>Solicitud de documento</th>
                                            <th>Fecha de aprobación</th>
                                            <th>Tipo de documento</th>
                                            <th>Proceso</th>
                                            <th>Crea</th>
                                            <th>Revisa</th>
                                            <th>Aprueba</th>
                                            {(this.props.estados.delete || this.props.estados.edit || this.props.estados.edit_position) && (
                                                <th className="text-center">Opciones</th>
                                            )}
                                        </tr>
                                    </thead>

                                    <tbody className='ui-tables_layout-data__container'>
                                        {this.props.data.map(document => (
                                            <tr key={document.id}>
                                                <td>{document.version}</td>
                                                <td>{document.code}</td>
                                                <td>
                                                    {(this.props.isNewDocument(document.approve_date) || (this.props.isNewDocument(document.created_at))) && (
                                                        <IconsCornerNew 
                                                            id={document.id}
                                                            messageTooltip={'Este documento es nuevo'}
                                                        />
                                                    )}
                                                    {document.name}
                                                </td>
                                                <td>{this.props.getMonthText(document.due_date)}</td>
                                                <td>
                                                    {document.approve_date ? this.props.getMonthText(document.approve_date) : "Todavía no cuenta con fecha de aprobación"}
                                                </td>
                                                <td>{document.document_type ? document.document_type.name : ""}</td>
                                                <td>{document.proceso ? document.proceso.name : ""}</td>
                                                <td>{document.user_create.first_name}</td>
                                                <td>{document.user_review.first_name}</td>
                                                <td>{document.user_approve.first_name}</td>

                                                {(this.props.estados.edit || this.props.estados.delete || document.state == "obsoleto" || this.props.estados.edit_position) && (
                                                    <td className="text-center" style={{ width: "10px" }}>
                                                        <UncontrolledDropdown className='btn-group ui-tableIndexWeb-positions-dropdown__container'>
                                                            <DropdownToggle>
                                                                <i className="fas fa-bars"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="ui-tableIndexWeb-positions-dropdown_header dropdown-menu-right">
                                                                {(this.props.estados.edit && document.state != "aprobado") && (
                                                                    <DropdownItem
                                                                        href={`/document_management/documents/${document.token}`}
                                                                        data-turbolinks="true"
                                                                        className="dropdown-item"
                                                                    >
                                                                        Gestionar
                                                                    </DropdownItem>
                                                                )}
                                                                {(document.state == "aprobado" || document.state == "obsoleto") && (
                                                                    <DropdownItem
                                                                        href={`/document_management/documents/${document.token}`}
                                                                        data-turbolinks="true"
                                                                        className="dropdown-item"
                                                                    >
                                                                        Ver documento
                                                                    </DropdownItem>
                                                                )}
                                                                {(document.state == "aprobado" && document.is_attached) && (
                                                                    <DropdownItem
                                                                        href={document.attached.url}
                                                                        data-turbolinks="true"
                                                                        target="_blank"
                                                                        className="dropdown-item"
                                                                        download
                                                                    >
                                                                        Descargar documento
                                                                    </DropdownItem>
                                                                )}
                                                                {(this.props.estados.edit && document.state != "aprobado") && (
                                                                    <DropdownItem
                                                                        onClick={() => this.props.edit(document)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </DropdownItem>
                                                                )}
                                                                {(this.props.estados.generate && document.state == "aprobado") && (
                                                                    <DropdownItem
                                                                        onClick={() => this.props.generateClone(document)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Generar versión
                                                                    </DropdownItem>
                                                                )}
                                                                {(document.state != "obsoleto" && document.state != "creando") && (
                                                                    <DropdownItem
                                                                        onClick={() => this.props.sendObsolete(document.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Enviar a obsoletos
                                                                    </DropdownItem>
                                                                )}
                                                                {(document.state == "creando" || document.state == "obsoleto") && (
                                                                    <DropdownItem
                                                                        onClick={() => this.props.delete(document.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </DropdownItem>
                                                                )}
                                                                {this.props.estados.edit_position && (
                                                                    <DropdownItem
                                                                        onClick={() => this.props.editPosition(document)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar acceso
                                                                    </DropdownItem>
                                                                )}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            ) : (
                                <div lassName="text-center">
                                    <div className="text-center">
                                        <div className="text-center mt-4 mb-4" style={{ width: '100%' }}>
                                            <p className='ui-indexWeb-messageNoRecord'>Todavía no hay registros creados, da clic en<a onClick={() => this.props.toogle("new")} className=" btn btn-info"><i className='fas fa-file-alt'></i> Nuevo documento</a> y genera el primer registro.</p>
                                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/9/noRecord_documental.jpg" alt="" className="ui-indexWeb-imgNoRecord" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {true && (
                            <div className="row mt-4">

                                <div className="col-md-2 text-left">
                                    <p>
                                        Mostrando {this.props.data.length} de {this.props.totalData}
                                    </p>
                                </div>

                                <div className="col-md-10 pl-0">
                                    <Pagination
                                        hideNavigation
                                        activePage={this.props.activePage}
                                        itemsCountPerPage={this.props.countPage}
                                        itemClass="page-item"
                                        innerClass="pagination"
                                        linkClass="page-link"
                                        totalItemsCount={this.props.totalData}
                                        pageRangeDisplayed={this.props.countPage}
                                        onChange={this.props.handlePageChange}
                                    />
                                </div>

                            </div>
                        )}

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default IndexWeb;
