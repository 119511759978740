import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

class UploadFile extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: false,
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-12">
                                    <Dropzone onDrop={(files) => this.props.handleFile(files)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div
                                                {...getRootProps({
                                                    className: 'dropzone',
                                                })}
                                            >
                                                <input {...getInputProps()} />
                                                <p>{`${(this.props.nameFile != "" ? this.props.nameFile : "Arrastra y suelta el archivo aquí o haz clic para subir el archivo. Puedes subir archivos de máximo 30 Mb")}`}</p>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>

                                {this.props.sizeFile >= 31485760 && (
                                    <div className="col-md-12 mt-3">
                                        <div className="alert alert-info text-center" role="alert">
                                            <b>El archivo que intentas subir, ha superado el limite permitido de 30Mb. Intenta de nuevo con otro archivo</b>
                                        </div>
                                    </div>
                                )}

                            </div>

                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.props.handleClick()}
                                disabled={this.props.isLoaded || this.props.sizeFile >= 31485760}
                            >

                                {this.props.isLoaded ? (
                                    <div className="loader-files"></div>
                                ) : (
                                    <React.Fragment>
                                        Subir archivo
                                    </React.Fragment>
                                )}
                            </button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default UploadFile;
