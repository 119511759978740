import React, { Component } from 'react';
import CustomViewIndex from './CustomViewFilter/Index';
import Select from "react-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import IndexQuestions from './IndexQuestions';
import FormatCustomChartIndex from './FormatCustomChart/Index'

class Show extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            isLoaded: true,
            formUpdate: {
                format_id: this.props.format_custom_view.format.id,
                name: this.props.format_custom_view.name,
            },

            selectedOption: {
                format_id: this.props.format_custom_view.format.id,
                label: this.props.format_custom_view.format.name,
            },

            formUpdateCheckBox: {
                export_excel: this.props.states.export_excel,
                import_excel: this.props.states.import_excel,
                download_template: this.props.states.download_template,
                create_record: this.props.states.create_record,
                share_link: this.props.states.share_link,
            },

            questions: [],
            formatos: [],
        }
    }


    componentDidMount() {
        this.configSelect();
    }

    configSelect = () => {
        let array = [];
        let arrayFormat = [];

        this.props.questions.map((item) => (
            array.push({ label: item.name, value: item.id, type: item.type, options_values: item.options })
        ))

        this.props.formatos.map((item) => (
            arrayFormat.push({ label: item.name, value: item.id })
        ))

        this.setState({
            formatos: arrayFormat,
            questions: array,
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    handleClickUpate = () => {
        fetch(`/formatos/format_custom_views/${this.props.format_custom_view.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                let array = [];

                data.questions.map((item) => (
                    array.push({ label: item.name, value: item.id })
                ))

                this.setState({
                    formUpdate: {
                        format_id: data.register.format.id,
                        name: data.register.name,
                    },

                    selectedOption: {
                        format_id: data.register.format.id,
                        label: data.register.format.name,
                    },
                })
                this.messageSuccess(data);
            });
    }

    HandleChange = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                [e.target.name]: e.target.value
            }
        });
    }


    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            formUpdate: {
                ...this.state.formUpdate,
                format_id: selectedOption.value
            }
        });
    };


    updateStateStep = (e) => {
        const isChecked = e.target.checked;

        fetch(`/formatos/format_custom_views/${this.props.format_custom_view.id}/update_state/${e.target.name}/${isChecked}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    formUpdateCheckBox: {
                        ...data.register,
                    }
                })
            });
    }

    render() {
        return (
            <React.Fragment>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">

                        {this.props.format_custom_view.header.map((format_category, index) => (
                            <li className="breadcrumb-item" key={format_category.id}>
                                <a
                                    href={`/formatos/format_categories?categories=${this.props.format_custom_view.header_ids}`}
                                    data-turbolinks="true"
                                >
                                    {format_category.name}
                                </a>
                            </li>
                        ))}

                    </ol>
                </nav>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-3">
                            <div className="card-body">

                                <button
                                    className="btn float-right"
                                    onClick={() => this.handleClickUpate()}
                                >
                                    <i className="far fa-edit"></i> Actualizar
                                </button>

                                <div className="row">
                                    <div className="col-md-12 pl-0">
                                        <input
                                            type="text"
                                            name="name"
                                            onChange={this.HandleChange}
                                            value={this.state.formUpdate.name}
                                            className={`form form-control`}
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    {/*<div className="col-md-6">
                                        <input
                                            type="hidden"
                                            name="format_id"
                                            value={this.state.selectedOption.format_id}
                                        />
                                        <Select
                                            onChange={this.handleChangeAutocomplete}
                                            options={this.state.formatos}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOption}
                                        />
                                    </div>*/}
                                </div>
                            </div>
                        </div>

                        <CustomViewIndex
                            format_custom_view={this.props.format_custom_view}
                            questions={this.state.questions}
                            users={this.props.users}
                            procesos={this.props.procesos}
                            cargos={this.props.cargos}
                        />

                        {this.props.format_custom_view.type == "dashboard" && (
                            <FormatCustomChartIndex
                                format_custom_view={this.props.format_custom_view}
                                questions={this.state.questions}
                                recommended_charts_api={this.props.recommended_charts_api}
                            />
                        )}

                        <div className="card">
                            <div className="card-header">
                                {this.props.format_custom_view.type == "dashboard" ? "COMPONER FILTRO" : "COLUMNAS"}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <IndexQuestions format_custom_view={this.props.format_custom_view} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="col-md-3">
                        <div className="card">
                            <div className="card-header">
                                Adicionar a la vista
                            </div>

                            <div className="card-body pb-0">
                                <div className="col-md-12 pl-0">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <p>Generar excel</p>
                                        </div>

                                        <div className="col-md-5 text-right pr-0">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" name="export_excel" onChange={(e) => this.updateStateStep(e)} className="custom-control-input" id={`customGestionExport`} checked={this.state.formUpdateCheckBox.export_excel} />
                                                <label className="custom-control-label" htmlFor={`customGestionExport`}></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 pl-0">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <p>Importar excel</p>
                                        </div>

                                        <div className="col-md-5 text-right pr-0">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" name="import_excel" onChange={(e) => this.updateStateStep(e)} className="custom-control-input" id={`customGestionImport`} checked={this.state.formUpdateCheckBox.import_excel} />
                                                <label className="custom-control-label" htmlFor={`customGestionImport`}></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 pl-0">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <p>Descargar plantilla</p>
                                        </div>

                                        <div className="col-md-3 text-right pr-0">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" name="download_template" onChange={(e) => this.updateStateStep(e)} className="custom-control-input" id={`customGestionDownload`} checked={this.state.formUpdateCheckBox.download_template} />
                                                <label className="custom-control-label" htmlFor={`customGestionDownload`}></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 pl-0">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <p>Crear registro</p>
                                        </div>

                                        <div className="col-md-5 text-right pr-0">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" name="create_record" onChange={(e) => this.updateStateStep(e)} className="custom-control-input" id={`customGestionCreate`} checked={this.state.formUpdateCheckBox.create_record} />
                                                <label className="custom-control-label" htmlFor={`customGestionCreate`}></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 pl-0">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <p>Generar link de compartir</p>
                                        </div>

                                        <div className="col-md-3 text-right pr-0">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" name="share_link" onChange={(e) => this.updateStateStep(e)} className="custom-control-input" id={`customGestionLink`} checked={this.state.formUpdateCheckBox.share_link} />
                                                <label className="custom-control-label" htmlFor={`customGestionLink`}></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>*/}

                </div>
            </React.Fragment>
        );
    }
}

export default Show;
