
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ChromePicker } from 'react-color'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import FormCreate from './QuestionStyleConditional/FormCreate';
import FormCreateQuestionStyle from './FormCreate';
import Select from "react-select";

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

class Modalindex extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            questions: [],

            users: [],
            procesos: [],
            cargos: [],

            isLoaded: true,
            showPikerFirstColor: false,
            showPikerFirstBackgroundColor: false,


            /* ESTADOS DE QUESTION STYLE  */

            formCreateQuestionStyle: {
                question_id: this.props.question.id,
                send_email: false,
                question_compare_id: "",
                question_value_id: "",
                email_content: "",
                position_ids: [],
                color: "",
                background_color: "",
            },

            formUpdateQuestionStyle: {
                color: "",
                value: "",
                send_email: false,
                question_compare_id: "",
                question_value_id: "",

                email_content: "",
                position_ids: [],

                background_color: "",
            },

            editValuesPosition: [],
            ErrorValuesQuestionStyle: true,
            modeEditQuestionStyle: false,

            showPikerFirstColorEdit: false,
            showPikerFirstBackgroundColorEdit: false,

            question_style_id: "",

            selectedOptionPositions: {
                position_ids: [],
                label: "Cargos"
            },

            selectedOptionPositionsUpdate: {
                position_ids: [],
                label: "Cargos"
            },

            selectedOptionQuestionCompareUpdate: {
                question_compare_id: "",
                label: "Pregunta a comparar"
            },

            selectedOptionQuestionCompare: {
                question_compare_id: "",
                label: "Pregunta a comparar",
            },

            selectedOptionQuestionValue: {
                question_value_id: "",
                label: "Valor de la pregunta",
            },

            selectedOptionQuestionValueUpdate: {
                question_value_id: "",
                label: "Valor de la pregunta",
            },

            selectedOptionQuestionToCompare: {
                question_to_compare_id: "",
                label: "Pregunta a comparar",
            },

            selectedOptionQuestionToCompareUpdate: {
                question_to_compare_id: "",
                label: "Pregunta a comparar",
            },

            /* ESTADOS DE QUESTION STYLE CONDITIONAL  */

            formCreateQuestionStyleConditional: {
                question_style_id: "",
                conditional: "",
                question_id: "",
                value: "",
                operation: "",
                question_to_compare_show: false,
                question_to_compare_id: "",
            },

            selectedOption: {
                question_id: "",
                label: "Pregunta"
            },

            selectedOptionSelect: {
                value: "",
                label: "Modulo"
            },

            ErrorValuesQuestionStyleConditional: true,
            modeEditQuestionStyleConditional: false,
            question_style_conditional_id: "",
            question_style_conditional_length: 0,
            index_style_conditional: 1,

            currentOptions: [],
            type_question: this.props.question.question_type,
            options: (this.props.question.question_type == "answer_select" || this.props.question.question_type == "answer_radio" ? this.props.question.options : []),
            options_operation: (this.props.question.question_type == "answer_integer" || this.props.question.question_type == "answer_formula" || this.props.question.question_type == "answer_date" ? ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual"] : ["Contiene", "Igual", "Diferente"]),
        }
    }

    componentDidMount = () => {
        this.loadData();
        this.loadDataQuestions();
        this.configSelect();
    }

    configSelect = () => {
        let array = []
        let arrayCargos = []
        let arrayProcesos = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.procesos.map((item) => (
            arrayProcesos.push({ label: item.name, value: item.id })
        ))

        this.props.cargos.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array,
            procesos: arrayProcesos,
            cargos: arrayCargos
        }, () => {
            this.setState({
                currentOptions: this.selectOptions(this.props.question.question_type),
                selectedOptionSelect: {
                    value: "",
                    label: this.selectType(this.props.question.question_type),
                },
            })
        });
    }

    loadData = () => {
        fetch(`/formatos/get_question_styles/${this.props.question.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    isLoaded: false
                });
            });
    }

    loadDataQuestions = () => {
        fetch(`/formatos/get_questions/${this.props.formato.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                let array = [];

                data.register.map((item) => (
                    array.push({ label: item.question, value: item.id, type: item.question_type, options_values: item.options })
                ))

                this.setState({
                    questions: array
                })
            });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = config => {
        this.setState({
            data: this.state.data.map(item => {
                if (config.id === item.id) {
                    return {
                        ...item,
                        background_color: config.background_color,
                        value: config.value,
                        color: config.color,

                        question_to_compare_show: config.question_to_compare_show,
                        question_to_compare: config.question_to_compare,

                        email_content: config.email_content,
                        positions: config.positions,
                        question_compare: config.question_compare,
                        question_value: config.question_value,
                        send_email: config.send_email,
                    }
                }
                return item;
            })
        });
    }


    /* CODIGO PARA CREAR LAS QUESTION STYLE  */

    HandleChange = (e) => {
        if (this.state.question_style_id) {
            this.setState({
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    [e.target.name]: e.target.value,
                },

                selectedOptionQuestionValueUpdate: {
                    question_value_id: "",
                    label: "Valor de la pregunta",
                },
            });
        } else {
            this.setState({
                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    [e.target.name]: e.target.value
                },

                selectedOptionQuestionValue: {
                    question_value_id: "",
                    label: "Valor de la pregunta",
                },
            });
        }
    }

    HandleChangeQuestionStyle = (e) => {
        console.log(e)
        if (this.state.question_style_id) {
            this.setState({
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    [e.target.name]: e.target.value,
                }
            });
        } else {
            this.setState({
                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    [e.target.name]: e.target.value,
                }
            });
        }
    }

    validationFormQuestionStyle = () => {
        if (this.state.question_style_id) {
            if (this.state.formUpdateQuestionStyle.color != "" &&
                this.state.formUpdateQuestionStyle.background_color != ""
            ) {
                this.setState({ ErrorValuesQuestionStyle: true })
                return true
            } else {
                this.setState({ ErrorValuesQuestionStyle: false })
                return false
            }
        } else {
            if (this.state.formCreateQuestionStyle.color != "" &&
                this.state.formCreateQuestionStyle.background_color != ""
            ) {
                this.setState({ ErrorValuesQuestionStyle: true })
                return true
            } else {
                this.setState({ ErrorValuesQuestionStyle: false })
                return false
            }
        }
    }

    handleClickFirstColor = () => {
        if (this.state.question_style_id) {
            this.setState({ showPikerFirstColorEdit: !this.state.showPikerFirstColorEdit })
        } else {
            this.setState({ showPikerFirstColor: !this.state.showPikerFirstColor })
        }
    };

    handleClickFirstBackgroundColor = () => {
        if (this.state.question_style_id) {
            this.setState({ showPikerFirstBackgroundColorEdit: !this.state.showPikerFirstBackgroundColorEdit })
        } else {
            this.setState({ showPikerFirstBackgroundColor: !this.state.showPikerFirstBackgroundColor })
        }
    }

    handleCloseFirstColor = () => {
        if (this.state.question_style_id) {
            this.setState({ showPikerFirstColorEdit: false })
        } else {
            this.setState({ showPikerFirstColor: false })
        }
    };

    handleCloseFirstBackgroundColor = () => {
        if (this.state.question_style_id) {
            this.setState({ showPikerFirstBackgroundColorEdit: false })
        } else {
            this.setState({ showPikerFirstBackgroundColor: false })
        }
    };

    handleChangeFirstColor = (color) => {
        if (this.state.question_style_id) {
            this.setState({
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    color: color.hex,
                }
            })
        } else {
            this.setState({
                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    color: color.hex,
                }
            })
        }
    }

    handleChangeBackgroundColor = (color) => {
        if (this.state.question_style_id) {
            this.setState({
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    background_color: color.hex,
                }
            })
        } else {
            this.setState({
                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    background_color: color.hex,
                }
            })
        }
    }

    handleChangeCheckBox = (e, field) => {

        console.log(field);

        if (field == "question_to_compare_show") {
            console.log(field, "añlfjlakñfjsd");
            this.setState({
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    ["question_value_id"]: 12,
                }
            })
        }

        const isChecked = e.target.checked;
        if (this.state.question_style_id) {
            this.setState({
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    [field]: isChecked,
                }
            }, () => {
                if (!this.state.formUpdateQuestionStyle.question_to_compare_show) {
                    this.setState({
                        formUpdateQuestionStyle: {
                            ...this.state.formUpdateQuestionStyle,
                            ["question_value_id"]: null,
                        },
                        selectedOptionQuestionValueUpdate: {
                            question_value_id: "",
                            label: "Valor de la pregunta",
                        },

                    })
                }
            })
        } else {
            this.setState({
                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    [field]: isChecked,
                }
            })
        }
    }

    handleChangeAutocompletePositions = selectedOptionPosition => {
        let array = []

        selectedOptionPosition.map((item) => (
            array.push(item.value)
        ))

        if (this.state.question_style_id) {
            this.setState({
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    position_ids: array,
                },
            })
        } else {
            this.setState({
                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    position_ids: array,
                },
            })
        }
    }


    handleChangeAutocompleteQuestionCompare = selectedOptionQuestionCompare => {

        if (this.state.question_style_id) {
            const selectedOptionQuestionCompareUpdate = selectedOptionQuestionCompare
            this.setState({
                selectedOptionQuestionCompareUpdate,
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    question_compare_id: selectedOptionQuestionCompareUpdate.value
                }
            });
        } else {
            this.setState({
                selectedOptionQuestionCompare,
                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    question_compare_id: selectedOptionQuestionCompare.value
                }
            });
        }
    }


    handleChangeAutocompleteQuestionToCompare = selectedOptionQuestionToCompare => {
        this.setState({
            selectedOptionQuestionToCompare,
            formCreateQuestionStyleConditional: {
                ...this.state.formCreateQuestionStyleConditional,
                question_to_compare_id: selectedOptionQuestionToCompare.value,
                value: selectedOptionQuestionToCompare.label
            }
        });
    }

    handleChangeCheckBoxQuestionToCompareShow = (e, field) => {
        const isChecked = e.target.checked;
        this.setState({
            formCreateQuestionStyleConditional: {
                ...this.state.formCreateQuestionStyleConditional,
                [field]: isChecked,
            }
        })
    }

    handleChangeAutocompleteQuestionValue = selectedOptionQuestionValue => {
        if (this.state.question_style_id) {
            const selectedOptionQuestionValueUpdate = selectedOptionQuestionValue
            this.setState({
                selectedOptionQuestionValueUpdate,
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    question_value_id: selectedOptionQuestionValueUpdate.value
                },

                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    value: selectedOptionQuestionValueUpdate.label,
                    question_value_id: selectedOptionQuestionValue.value
                }
            });
        } else {
            console.log(selectedOptionQuestionValue)
            this.setState({
                selectedOptionQuestionValue,
                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    question_value_id: selectedOptionQuestionValue.value
                },

                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    value: selectedOptionQuestionValue.label,
                    question_value_id: selectedOptionQuestionValue.value
                }
            });
        }
    }

    HandleChangeCkaditor = (e) => {
        if (this.state.question_style_id) {
            this.setState({
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    email_content: e
                }
            })
        } else {
            this.setState({
                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    email_content: e
                }
            })
        }
    }

    clearValuesQuestionStyle = () => {
        this.setState({
            showPikerFirstColor: false,
            showPikerFirstBackgroundColor: false,
            question_style_id: "",


            /* ESTADOS DE QUESTION STYLE  */

            formCreateQuestionStyle: {
                ...this.state.formCreateQuestionStyle,
                color: "",
                value: "",
                send_email: false,
                question_compare_id: "",
                question_to_compare_show: false,
                question_to_compare_id: "",
                email_content: "",
                position_ids: [],
                background_color: "",
            },

            formUpdateQuestionStyle: {
                color: "",
                value: "",

                question_to_compare_show: false,
                question_to_compare_id: "",

                send_email: false,
                question_compare_id: "",
                email_content: "",
                position_ids: [],

                background_color: "",
            },

            selectedOptionQuestionValue: {
                question_value_id: "",
                label: "Valor de la pregunta",
            },

            selectedOptionQuestionValueUpdate: {
                question_value_id: "",
                label: "Valor de la pregunta",
            },

            selectedOptionQuestionToCompare: {
                question_to_compare_id: "",
                label: "Pregunta a comparar",
            },

            selectedOptionQuestionToCompareUpdate: {
                question_to_compare_id: "",
                label: "Pregunta a comparar",
            },


            ErrorValuesQuestionStyle: true,
            modeEditQuestionStyle: false,
        })
    }

    HandleClickQuestionStyle = () => {
        if (this.validationFormQuestionStyle()) {
            if (this.state.modeEditQuestionStyle) {
                fetch(`/formatos/question_styles/${this.state.question_style_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formUpdateQuestionStyle), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValuesQuestionStyle();
                        this.updateItem(data.register)
                        this.messageSuccess(data);
                    });
            } else {
                fetch(`/formatos/question_styles`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreateQuestionStyle), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValuesQuestionStyle();
                        this.updateData(data.register)
                        this.messageSuccess(data);
                    });
            }
        }
    }


    deleteQuestionStyle = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_styles/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    editQuestionStyle = (question_style) => {
        let array = [];
        let arrayIds = [];

        question_style.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            question_style_id: question_style.id,
            editValuesPosition: array,
            modeEditQuestionStyle: true,

            selectedOptionQuestionValueUpdate: {
                question_value_id: question_style.question_value ? question_style.question_value.id : "",
                label: question_style.question_value ? question_style.question_value.question : "",
            },

            formUpdateQuestionStyle: {
                ...this.state.formUpdateQuestionStyle,
                question_id: this.props.question.id,
                color: question_style.color,
                value: question_style.value,
                background_color: question_style.background_color,
                position_ids: arrayIds,
                question_to_compare_show: question_style.question_to_compare_show,
                send_email: question_style.send_email,
                question_compare_id: question_style.send_email ? question_style.question_compare.id : "",
                question_value_id: question_style.question_value ? question_style.question_value.id : "",
                question_to_compare_id: question_style.question_to_compare ? question_style.question_to_compare.id : "",
                email_content: question_style.email_content,
            },

        })
    }


    /* CODIGO PARA CREAR LAS QUESTION STYLE CONDITIONAL  */

    HandleChangeQuestionStyleConditional = (e) => {
        this.setState({
            formCreateQuestionStyleConditional: {
                ...this.state.formCreateQuestionStyleConditional,
                [e.target.name]: e.target.value,
            }
        });
    }

    validationFormQuestionStyleConditional = () => {
        if (this.state.formCreateQuestionStyleConditional.value != "" &&
            this.state.formCreateQuestionStyleConditional.operator != ""
        ) {
            this.setState({ ErrorValuesQuestionStyleConditional: true })
            return true
        } else {
            this.setState({ ErrorValuesQuestionStyleConditional: false })
            return false
        }
    }

    clearValuesQuestionStyleConditional = () => {


        let options_operation = []
        if (this.props.question.question_type == "answer_integer" || this.props.question.question_type == "answer_formula") {
            options_operation = ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual"]
        }
        else if (this.props.question.question_type == "answer_date") {
            options_operation = ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Mayor que hoy", "Menor que hoy", "Igual a hoy"]
        } else {
            options_operation = ["Contiene", "Igual", "Diferente"]
        }


        this.setState({
            ErrorValuesQuestionStyleConditional: true,
            modeEditQuestionStyleConditional: false,
            index_style_conditional: 1,

            showPikerFirstBackgroundColorEdit: false,
            showPikerFirstColorEdit: false,

            question_type: "",

            formCreateQuestionStyleConditional: {
                question_style_id: "",
                conditional: "",
                question_id: "",
                value: "",
                operation: "",
                question_to_compare_show: false,
                question_to_compare_id: "",
            },

            selectedOption: {
                question_id: "",
                label: "Pregunta"
            },

            selectedOptionSelect: {
                value: "",
                label: this.selectType(this.props.question.question_type),
            },

            selectedOptionQuestionToCompare: {
                question_to_compare_id: "",
                label: "Pregunta a comparar",
            },

            type_question: this.props.question.question_type,
            options: (this.props.question.question_type == "answer_select" || this.props.question.question_type == "answer_radio" ? this.props.question.options : []),
            options_operation: options_operation,

            currentOptions: this.selectOptions(this.props.question.question_type),
        })
    }


    handleChangeAutocomplete = selectedOption => {
        let options_operation = []
        if (selectedOption.type == "answer_integer" || selectedOption.type == "answer_formula") {
            options_operation = ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual"]
        }
        else if (selectedOption.type == "answer_date") {
            options_operation = ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Mayor que hoy", "Menor que hoy", "Igual a hoy"]
        } else {
            options_operation = ["Contiene", "Igual", "Diferente"]
        }
        this.setState({
            selectedOption,
            formCreateQuestionStyleConditional: {
                ...this.state.formCreateQuestionStyleConditional,
                question_id: selectedOption.value,
                value: "",
            },

            selectedOptionSelect: {
                value: "",
                label: this.selectType(selectedOption.type),
            },

            currentOptions: this.selectOptions(selectedOption.type),
            type_question: selectedOption.type,

            options: (selectedOption.type == "answer_select" || selectedOption.type == "answer_radio" ? selectedOption.options_values : []),
            options_operation: options_operation,
        });
    };

    selectOptions = (type_question) => {
        if (type_question == "answer_user_id") {
            return this.state.users
        } else if (type_question == "proceso_id") {
            return this.state.procesos
        } else if (type_question == "position_id") {
            return this.state.cargos
        }
    }

    selectType = (type_question) => {
        if (type_question == "answer_user_id") {
            return "Seleccione un usuario"
        } else if (type_question == "proceso_id") {
            return "Seleccione un proceso"
        } else if (type_question == "position_id") {
            return "Seleccione un cargo"
        }
    }


    handleChangeAutocompleteSelect = selectedOptionSelect => {
        this.setState({
            selectedOptionSelect,
            formCreateQuestionStyleConditional: {
                ...this.state.formCreateQuestionStyleConditional,
                value: selectedOptionSelect.value
            },
        });
    };

    editQuestionStyleConditional = (index, question_style, question_style_conditional) => {
        console.log("question_style_conditional", question_style_conditional);
        this.setState({
            index_style_conditional: index,
            question_style_conditional_length: question_style.question_style_conditionals.length,
            ErrorValuesQuestionStyleConditional: true,
            modeEditQuestionStyleConditional: true,
            question_style_conditional_id: question_style_conditional.id,

            formCreateQuestionStyleConditional: {
                question_style_id: question_style_conditional.question_style_id,
                conditional: question_style_conditional.conditional,
                value: question_style_conditional.value,
                operation: question_style_conditional.operation,
                question_to_compare_show: question_style_conditional.question_to_compare_show,
                question_to_compare_id: question_style_conditional.question_to_compare ? question_style_conditional.question_to_compare.id : "",
            },

            selectedOptionQuestionToCompare: {
                question_to_compare_id: question_style_conditional.question_to_compare ? question_style_conditional.question_to_compare.id : "",
                label: question_style_conditional.question_to_compare ? question_style_conditional.question_to_compare.question : "Pregunta a comparar",
            },

            selectedOption: {
                question_id: question_style_conditional.question ? question_style_conditional.question.id : "",
                label: question_style_conditional.question ? question_style_conditional.question.question : "",
            },
            //primero estaba question_style_conditional.question
            //se cambio a question_style_conditional.question_to_compare

            options: question_style_conditional.question_to_compare ? (question_style_conditional.question_to_compare.question_type == "answer_select" || question_style_conditional.question_to_compare.question_type == "answer_radio" ? question_style_conditional.question_to_compare.options : []) : [],
            options_operation: question_style_conditional.question_to_compare ? (question_style_conditional.question_to_compare.question_type == "answer_integer" || question_style_conditional.question_to_compare.question_type == "answer_formula" || question_style_conditional.question_to_compare.question_type == "answer_date" ? ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual"] : ["Contiene", "Igual", "Diferente"]) : ["Contiene", "Igual", "Diferente"],
        })
    }

    HandleClickQuestionStyleConditional = () => {
        if (this.validationFormQuestionStyleConditional()) {
            if (this.state.modeEditQuestionStyleConditional) {
                fetch(`/formatos/question_style_conditionals/${this.state.question_style_conditional_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formCreateQuestionStyleConditional), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValuesQuestionStyleConditional();
                        this.updateItemQuestionStyleConditional(data.register)
                        this.messageSuccess(data);
                    });
            } else {
                fetch(`/formatos/question_style_conditionals`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreateQuestionStyleConditional), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValuesQuestionStyleConditional();
                        this.updateDataQuestionStyleConditional(data.register)
                        this.messageSuccess(data);
                    });
            }
        }
    }

    updateDataQuestionStyleConditional = (register) => {
        this.setState({
            data: this.state.data.map(item => {
                if (register.question_style_id === item.id) {
                    return {
                        ...item,
                        background_color: item.background_color,
                        color: item.color,
                        question_style_conditionals: [...item.question_style_conditionals, register],
                    }
                }
                return item;
            })
        });
    }


    updateItemQuestionStyleConditional = (register) => {
        this.setState({
            data: this.state.data.map(item => {
                if (register.question_style_id === item.id) {
                    return {
                        ...item,
                        background_color: item.background_color,
                        color: item.color,
                        question_style_conditionals: item.question_style_conditionals.map(formato => {
                            if (register.id === formato.id) {
                                return {
                                    ...formato,
                                    conditional: register.conditional,
                                    operation: register.operation,
                                    question_style_id: register.question_style_id,
                                    value: register.value,
                                    question: register.question,
                                    question_to_compare: register.question_to_compare,
                                    question_to_compare_show: register.question_to_compare_show,
                                }
                            }
                            return formato;
                        }),
                    }
                }
                return item;
            })
        });
    }

    createConditional = (question_style) => {

        this.setState({
            question_style_conditional_length: question_style.question_style_conditionals.length,

            formCreateQuestionStyleConditional: {
                ...this.state.formCreateQuestionStyleConditional,
                question_style_id: question_style.id,
                conditional: "",
                question_id: "",
                value: "",
                operation: "",
            },
        })
    }

    deleteQuestionStyleConditional = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_style_conditionals/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }


        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>
                        <div className='ui-modals-header-title__container'>
                            <span className='ui-titles_modals'>
                                <i className="app-menu__icon fa fa-user mr-2"></i>
                                {this.props.title}
                            </span>
                            <span className='ui-name-question-modals ui-icon-in-title'>Pregunta {this.props.question.question}</span> 
                        </div>
                    </ModalHeader>

                    <ModalBody>
                        <div className="card mb-2 mb-3">
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="ui-text-predicate">
                                        <p>Elige el estilo del registro</p>
                                    </div>
                                    <div className="col-md-3 ui-modalIndex-questionStyle-colortext">
                                        <button
                                            onClick={this.handleClickFirstColor}
                                            style={{
                                                border: `1px solid ${!this.state.ErrorValuesQuestionStyle && this.state.formCreateQuestionStyle.color == "" ? "#fa403c" : "#ffff"}`,
                                                fontSize: "14px"
                                            }}
                                            className="btn btn-light w-100"
                                        >
                                            <i className="fas fa-font ui-condicional-icons" style={{ borderBottom: `6px solid ${this.state.formCreateQuestionStyle.color}` }}></i> Color
                                        </button>
                                        {this.state.showPikerFirstColor ? <div style={popover}>
                                            <div style={cover} onClick={this.handleCloseFirstColor} />
                                            <ChromePicker
                                                color={this.state.formCreateQuestionStyle.color}
                                                onChange={this.handleChangeFirstColor}
                                            />
                                        </div> : null}
                                    </div>

                                    <div className="col-md-3 ui-modalIndex-questionStyle-colorBackground">
                                        <button
                                            onClick={this.handleClickFirstBackgroundColor}
                                            style={{
                                                border: `1px solid ${!this.state.ErrorValuesQuestionStyle && this.state.formCreateQuestionStyle.background_color == "" ? "#fa403c" : "#ffff"}`,
                                                fontSize: "14px"
                                            }}
                                            className="btn btn-light w-100"
                                        >
                                            <i className="fas fa-fill-drip ui-condicional-icons" style={{ borderBottom: `6px solid ${this.state.formCreateQuestionStyle.background_color}` }}></i> Color de fondo
                                        </button>
                                        {this.state.showPikerFirstBackgroundColor ? <div style={popover}>
                                            <div style={cover} onClick={this.handleCloseFirstBackgroundColor} />
                                            <ChromePicker
                                                color={this.state.formCreateQuestionStyle.background_color}
                                                onChange={this.handleChangeBackgroundColor}
                                            />
                                        </div> : null}
                                    </div>

                                    <div className="col-md-6 ui-modalIndex-questionStyle-texts">
                                        <div className="p-2 ui-text-dinamic-condicional" style={{ backgroundColor: this.state.formCreateQuestionStyle.background_color, color: this.state.formCreateQuestionStyle.color }}>
                                            <span> {this.props.question.question_type == "dynamic_field" ? this.state.formCreateQuestionStyle.value : "Ejemplo: Registro 1"}</span>
                                        </div>
                                    </div>

                                    {this.props.question.question_type == "dynamic_field" && (
                                        <React.Fragment>
                                            <div className="col-md-12 mt-3 ui-modalIndex-questionStyle-textMessage">
                                                <div /* className="ui-text-predicate-dinamic" */>
                                                    <p>Ingrese el mensaje o valor</p>
                                                </div>

                                                <input
                                                    type="text"
                                                    name="value"
                                                    value={this.state.formCreateQuestionStyle.value}
                                                    onChange={this.HandleChange}
                                                    className={`form form-control`}
                                                    placeholder="Valor"
                                                />
                                            </div>

                                            <div className="col-md-6 mt-3">
                                                <label>Valor de la pregunta</label>
                                                <input
                                                    type="hidden"
                                                    name="question_value_id"
                                                    value={this.state.selectedOptionQuestionValue.question_value_id}
                                                />

                                                <Select
                                                    onChange={this.handleChangeAutocompleteQuestionValue}
                                                    options={this.state.questions}
                                                    autoFocus={false}
                                                    className={`link-form`}
                                                    value={this.state.selectedOptionQuestionValue}
                                                />
                                            </div>

                                            <div className="col-md-12 mt-3">
                                                <label>(Si seleccionas una pregunta, el campo condicional traerá el valor que tiene el registro en la pregunta seleccionada.)</label>

                                            </div>
                                            <div className="col-md-12"><hr /> </div>

                                            <div className="col-md-8 mt-2 ui-modalIndex-questionStyle-sendMail">
                                                <input type="checkbox" onChange={(e) => this.handleChangeCheckBox(e, "send_email")} checked={this.state.formCreateQuestionStyle.send_email} value={!this.state.formCreateQuestionStyle.send_email} id="checkboxState" />
                                                <label htmlFor="checkboxState" className="ml-1 mt-2">Enviar correo</label>
                                            </div>

                                            {this.state.formCreateQuestionStyle.send_email && (
                                                <React.Fragment>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Participantes </label>
                                                        <input
                                                            type="hidden"
                                                            name="position_ids"
                                                            value={this.state.selectedOptionPositions.position_ids}
                                                        />
                                                        <Select
                                                            onChange={this.handleChangeAutocompletePositions}
                                                            options={this.state.cargos}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            autoFocus={false}
                                                            className={`link-form`}
                                                            classNamePrefix="select"
                                                            placeholder="Seleccione"
                                                            name="position_ids"
                                                        />
                                                    </div>

                                                    <div className="col-md-6 mt-3">
                                                        <label>Pregunta para comparar</label>
                                                        <input
                                                            type="hidden"
                                                            name="question_compare_id"
                                                            value={this.state.selectedOptionQuestionCompare.question_compare_id}
                                                        />

                                                        <Select
                                                            onChange={this.handleChangeAutocompleteQuestionCompare}
                                                            options={this.state.questions}
                                                            autoFocus={false}
                                                            className={`link-form`}
                                                            value={this.state.selectedOptionQuestionCompare}
                                                        />
                                                    </div>

                                                    <div className="col-md-12 mt-3">
                                                        <FroalaEditor
                                                            model={this.state.formCreateQuestionStyle.email_content}
                                                            config={this.props.config}
                                                            onModelChange={this.HandleChangeCkaditor}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>

                            <div className="card-footer">
                                <button
                                    className="btn-shadow btn btn-info mr-2"
                                    onClick={() => this.HandleClickQuestionStyle()}
                                >
                                    Guardar
                                </button>

                                <button
                                    className="btn btn-outline-danger"
                                    onClick={() => this.clearValuesQuestionStyle()}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>

                        {this.state.data.length >= 1 ? (
                            <React.Fragment>
                                {this.state.data.map((question_style, index) => (

                                    <div className={`card ${index >= 0 ? "mb-2" : ""}`}>
                                        <div className="card-header">
                                            <div className="col-md-3 pl-0 ui-predicate-text">
                                                El registro se verá así
                                            </div>

                                            <div className="col-md-5 text-center ui-style-register">
                                                <div className="p-2 ui-text-dinamic-condicional" style={{ backgroundColor: question_style.background_color, color: question_style.color }}>
                                                    <span>{this.props.question.question_type == "dynamic_field" ? question_style.value : "Ejemplo: Registro 1"}</span>
                                                </div>
                                            </div>
                                            <div className="ui-predicate-text">CUANDO</div>

                                            <div className="col-md-5">
                                                <button
                                                    className="btn-shadow btn btn-info"
                                                    id={`PopoverCreateFather${question_style.id}`}
                                                    onClick={() => this.createConditional(question_style)}
                                                >
                                                    Crear condición
                                                </button>

                                                <i className="fas fa-pencil-alt btn" id={`PopoverCreateQuestionStyle${question_style.id}`} style={{ color: "#16aaff" }} onClick={() => this.editQuestionStyle(question_style)}></i>
                                                <i className="fas fa-trash-alt btn" style={{ color: "#16aaff" }} onClick={() => this.deleteQuestionStyle(question_style.id)}></i>

                                                {/* Editar la condicion padre*/}
                                                <UncontrolledPopover isOpen={this.state.question_style_id == question_style.id} placement="bottom" target={`PopoverCreateQuestionStyle${question_style.id}`}>
                                                    <PopoverBody>
                                                        <div className="col-md-12 p-0" style={{ width: "292px", height: "auto" }}>
                                                            <FormCreateQuestionStyle
                                                                formValues={this.state.formUpdateQuestionStyle}
                                                                handleClick={this.HandleClickQuestionStyle}
                                                                onChangeForm={this.HandleChangeQuestionStyle}
                                                                modeEdit={this.state.modeEditQuestionStyle}

                                                                clearValues={this.clearValuesQuestionStyle}
                                                                errorValues={this.state.ErrorValuesQuestionStyle}
                                                                question={this.props.question}

                                                                showPikerFirstColorEdit={this.state.showPikerFirstColorEdit}
                                                                showPikerFirstBackgroundColorEdit={this.state.showPikerFirstBackgroundColorEdit}

                                                                handleChangeFirstColor={this.handleChangeFirstColor}
                                                                handleChangeBackgroundColor={this.handleChangeBackgroundColor}

                                                                handleCloseFirstColor={this.handleCloseFirstColor}
                                                                handleCloseFirstBackgroundColor={this.handleCloseFirstBackgroundColor}

                                                                handleClickFirstColor={this.handleClickFirstColor}
                                                                handleClickFirstBackgroundColor={this.handleClickFirstBackgroundColor}

                                                                //send_email values

                                                                selectedOptionPositions={this.state.selectedOptionPositionsUpdate}
                                                                handleChangeAutocompletePositions={this.handleChangeAutocompletePositions}
                                                                cargos={this.state.cargos}

                                                                selectedOptionQuestionCompare={this.state.selectedOptionQuestionCompareUpdate}
                                                                handleChangeAutocompleteQuestionCompare={this.handleChangeAutocompleteQuestionCompare}
                                                                questions={this.state.questions}

                                                                HandleChangeCkaditor={this.HandleChangeCkaditor}
                                                                editValues={this.state.editValuesPosition}
                                                                handleChangeCheckBox={this.handleChangeCheckBox}
                                                                selectedOptionQuestionToCompareUpdate={this.state.selectedOptionQuestionToCompareUpdate}
                                                                handleChangeAutocompleteQuestionValue={this.handleChangeAutocompleteQuestionValue}
                                                                selectedOptionQuestionValueUpdate={this.state.selectedOptionQuestionValueUpdate}
                                                                config={this.props.config}
                                                            />
                                                        </div>
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                                {/* Crear la condición */}
                                                <UncontrolledPopover isOpen={this.state.formCreateQuestionStyleConditional.question_style_id == question_style.id && !this.state.modeEditQuestionStyleConditional} placement="bottom" target={`PopoverCreateFather${question_style.id}`}>
                                                    <PopoverBody>
                                                        <div className="col-md-12 p-0" style={{ width: "292px", height: "auto" }}>
                                                            <FormCreate
                                                                formValues={this.state.formCreateQuestionStyleConditional}
                                                                handleClick={this.HandleClickQuestionStyleConditional}
                                                                onChangeForm={this.HandleChangeQuestionStyleConditional}
                                                                modeEdit={this.state.modeEditQuestionStyleConditional}

                                                                clearValues={this.clearValuesQuestionStyleConditional}
                                                                errorValues={this.state.ErrorValuesQuestionStyleConditional}
                                                                question={this.props.question}

                                                                options={this.state.options}
                                                                options_operation={this.state.options_operation}
                                                                type_question={this.state.type_question}
                                                                question_style_conditional_length={this.state.question_style_conditional_length}
                                                                index_style_conditional={this.state.index_style_conditional}

                                                                //select 

                                                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                                selectedOption={this.state.selectedOption}
                                                                questions={this.state.questions}

                                                                selectedOptionSelect={this.state.selectedOptionSelect}
                                                                handleChangeAutocompleteSelect={this.handleChangeAutocompleteSelect}
                                                                values={this.state.currentOptions}


                                                                selectedOptionQuestionToCompare={this.state.selectedOptionQuestionToCompare}
                                                                handleChangeAutocompleteQuestionToCompare={this.handleChangeAutocompleteQuestionToCompare}
                                                                handleChangeCheckBoxQuestionToCompareShow={this.handleChangeCheckBoxQuestionToCompareShow}
                                                                config={this.props.config}
                                                            />
                                                        </div>
                                                    </PopoverBody>
                                                </UncontrolledPopover>

                                            </div>

                                        </div>

                                        <div className="card-body p-0">
                                            <div className="scroll-area-sm" style={{ height: "auto" }}>
                                                <div className="scrollbar-container" style={{ height: question_style.question_style_conditionals.length > 3 ? "auto" : "auto", background: "lightgrey", padding: "10px" }}>
                                                    {question_style.question_style_conditionals.map((question_style_conditional, index) => (
                                                        <div
                                                            className={`card mb-2 ${index == 0 ? "mt-2" : ""}`}
                                                        >
                                                            <div className="card-body p-2">
                                                                <div className="row">
                                                                    <div className="col-md-1 ui-text-conditional">
                                                                        {index >= 1 && (
                                                                            <React.Fragment>
                                                                                {question_style_conditional.conditional}
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>

                                                                    <div className="col-md-5 ui-text-codicional-info">
                                                                        {this.props.question.question_type == "dynamic_field" && question_style_conditional.question ? question_style_conditional.question.question : this.props.question.question}
                                                                    </div>

                                                                    <div className="col-md-2 ui-text-codicional-info">
                                                                        {question_style_conditional.operation}
                                                                    </div>

                                                                    <div className="col-md-2 ui-text-codicional-info">
                                                                        {question_style_conditional.value}
                                                                    </div>

                                                                    <div className="col-md-2">
                                                                        {true && (
                                                                            <i className="far fa-trash-alt float-right btn" onClick={(e) => this.deleteQuestionStyleConditional(question_style_conditional.id)}></i>
                                                                        )}

                                                                        {true && (
                                                                            <i className="fas fa-pencil-alt float-right btn" onClick={(e) => this.editQuestionStyleConditional(index, question_style, question_style_conditional)} id={`PopoverCreate${question_style_conditional.id}`}></i>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {/* Editar la condición creada hija*/}
                                                                <UncontrolledPopover isOpen={this.state.question_style_conditional_id == question_style_conditional.id && this.state.modeEditQuestionStyleConditional} placement="left" target={`PopoverCreate${question_style_conditional.id}`}>
                                                                    <PopoverBody>
                                                                        <div className="col-md-12 p-0" style={{ width: "292px", height: "auto" }}>
                                                                            <FormCreate
                                                                                formValues={this.state.formCreateQuestionStyleConditional}
                                                                                handleClick={this.HandleClickQuestionStyleConditional}
                                                                                onChangeForm={this.HandleChangeQuestionStyleConditional}
                                                                                modeEdit={this.state.modeEditQuestionStyleConditional}

                                                                                clearValues={this.clearValuesQuestionStyleConditional}
                                                                                errorValues={this.state.ErrorValuesQuestionStyleConditional}
                                                                                question={this.props.question}

                                                                                options={this.state.options}
                                                                                options_operation={this.state.options_operation}
                                                                                type_question={this.state.type_question}
                                                                                question_style_conditional_length={this.state.question_style_conditional_length}
                                                                                index_style_conditional={this.state.index_style_conditional}

                                                                                //select 

                                                                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                                                selectedOption={this.state.selectedOption}
                                                                                questions={this.state.questions}

                                                                                selectedOptionSelect={this.state.selectedOptionSelect}
                                                                                handleChangeAutocompleteSelect={this.handleChangeAutocompleteSelect}
                                                                                values={this.state.currentOptions}

                                                                                selectedOptionQuestionToCompare={this.state.selectedOptionQuestionToCompare}
                                                                                handleChangeAutocompleteQuestionToCompare={this.handleChangeAutocompleteQuestionToCompare}
                                                                                handleChangeCheckBoxQuestionToCompareShow={this.handleChangeCheckBoxQuestionToCompareShow}
                                                                            />
                                                                        </div>
                                                                    </PopoverBody>
                                                                </UncontrolledPopover>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        ) : (
                            <div className="card">
                                <div className="card-body text-center">
                                    <h4>No hay registros</h4>
                                </div>
                            </div>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Modalindex;
