import React, { Component } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import Items from "../../settings/Icons/Items";
import { SwatchesPicker } from 'react-color';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            is_select: false,
            showPopover: false,
            modeEdit: false,
            title: "",
            icons: [],
            favorite_module: {},

            form: {
                icon_class: this.props.icon, 
                url: this.props.url, 
                color: "", 
                name: this.props.name,
            }
        }
    }

    handleChangeComplete = (color) => {
        this.setState({ 
            form: {
                ...this.state.form,
                color: color.hex 
            }
        });
    };

    handleChange = (e) => {
        this.setState({ 
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    };


    openEdit = () => {
        this.setState({
            showPopover: true,
            form: {
                icon_class: this.state.favorite_module.icon_class, 
                url: this.state.favorite_module.url, 
                color: this.state.favorite_module.color, 
                name: this.state.favorite_module.name,
            }
        })
    }
    

    componentDidMount = () => {
        this.IsSelect();
    }

    closeEdit = () => {
        this.setState({
            showPopover: false,
        })
    }

    favorite = (type) => {
        if(type == "add"){
            fetch("/settings/favorite_modules", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    favorite_module: data.register,
                    is_select: true,
                    showPopover: true,
                    form: {
                        icon_class: data.register.icon_class, 
                        url: data.register.url, 
                        color: data.register.color, 
                        name: data.register.name,
                    }
                })
            });
        }else{
            fetch(`/settings/favorite_modules/${this.state.favorite_module.id}`, {
                method: 'DELETE', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    favorite_module: {},
                    is_select: false,
                    showPopover: false,
                    form: {
                        ...this.state.form,
                        color: "", 
                    }
                })
            });
        }
    }

    updateFavorite = () => {
        fetch(`/settings/favorite_modules/${this.state.favorite_module.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({
                favorite_module: data.register,
                is_select: this.state.is_select,
                showPopover: false,
                form: {
                    ...this.state.form,
                    color: "", 
                }
            })
        });
    }

    IsSelect = () => {
        fetch(`/settings/exist_module?url=${this.props.url}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({
                icons: data.icons,
                favorite_module: data.favorite_module,
                is_select: data.is_select
            })
        });
    }


    render() {
        return (
            <React.Fragment>
                <i className={`${this.state.is_select ? `fas fa-star` : `far fa-star` } icon-gradient bg-mean-fruit ml-3`} id="Popover" onClick={() => this.state.is_select ? this.openEdit() : this.favorite("add")} style={{ fontSize: "30px", cursor: "pointer" }}></i>

                <Popover isOpen={this.state.showPopover} className="popover-favorite" placement="bottom" target="Popover" style={{ maxWidth: "170%", }}>
                    <PopoverHeader> Agregar a favoritos<a className="close-popover" onClick={() => this.setState({ showPopover: (!this.state.showPopover) })}> <i className="fas fa-times"></i></a></PopoverHeader>
                    <PopoverBody className='ui-favoriteModule-Index-popoverBody'>
                        <div className="row">

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-3">
                                        <span className="mr-3"> Nombre </span> 
                                    </div>

                                    <div className="col-md-9">
                                        <input 
                                            type="text" 
                                            className="form form-control"
                                            value={this.state.form.name}
                                            onChange={this.handleChange}
                                            name="name" 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-3">
                                <div className="row">
                                    <div className="col-md-3">
                                        <span className="mr-3"> Color </span> 
                                    </div>

                                    <div className="col-md-9">
                                        <SwatchesPicker
                                            className="w-100"
                                            color={ this.state.form.color }
                                            onChangeComplete={ this.handleChangeComplete }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <hr />
                            </div>

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6 text-left ui-FavoriteModule-btn">
                                        <button
                                            className="btn btn-outline-danger"
                                            onClick={() => this.favorite("remove")}
                                        >
                                            Quitar marcador
                                        </button>
                                    </div>

                                    <div className="col-md-6 text-right ui-FavoriteModule-btn">
                                        <button 
                                            className="btn btn-outline-secondary"
                                            onClick={() => this.closeEdit()}
                                        >
                                            Cerrar
                                        </button>

                                        <button 
                                            className="btn btn-shadow btn btn-info ml-2"
                                            onClick={() => this.updateFavorite()}
                                        >
                                            Listo
                                        </button>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </PopoverBody>
                </Popover>
            </React.Fragment>
        );
    }
}

export default Index;
