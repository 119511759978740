import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import Loading from '../../../GeneralComponents/LoadingComponen';
import { isMobile } from 'react-device-detect';
import IndexWeb from './TableIndexWeb';
import IndexWebMejora from './Mejora/TableIndexWebMejora'

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './FormCreate'
import FormCreateMejora from './Mejora/FormCreateMejora'


import FormFilter from './FormFilter'
import FormFilterMejora from './Mejora/FormFilterMejora'



class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            modeEdit: false,
            showFilter: false,
            id: "",

            form: {
                description: "",
                risk_type: "",
                possible_involvement_array: [],
                activity_id: "",
                specific_area_id: "",
                criterio_id: "",
                probability: 0,
                impact: 0,
                matrix_id: this.props.risk_matrix.id
            },

            formFilter: {
                description: "",
                risk_type: "",
                activity_id: "",
                specific_area_id: "",
                criterio_id: "",
                probability: "",
                impact: "",
            },

            selectedOptionActivities: {
                activity_id: "",
                label: "Seleccione"
            },

            selectedOptionSpecificAreas: {
                specific_area_id: "",
                label: "Seleccione"
            },

            selectedOptionCriterios: {
                criterio_id: "",
                label: "Seleccione"
            },

            activities: [],
            criterios: [],
            specificAreas: [],
            arrayTest: [],
        }
    }

    clearValues = () => {
        this.setState({
            ErrorValues: true,
            modeEdit: false,

            form: {
                description: "",
                risk_type: "",
                possible_involvement_array: [],
                activity_id: "",
                specific_area_id: "",
                criterio_id: "",
                probability: 0,
                impact: 0,
                matrix_id: this.props.risk_matrix.id
            },

            selectedOptionActivities: {
                activity_id: "",
                label: "Seleccione"
            },

            selectedOptionSpecificAreas: {
                specific_area_id: "",
                label: "Seleccione"
            },

            selectedOptionCriterios: {
                criterio_id: "",
                label: "Seleccione"
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    validationForm = () => {
        if (this.state.form.description != "" &&
            this.state.form.risk_type != "" &&
            this.state.form.possible_involvement_array != [] &&
            this.state.form.activity_id != "" &&
            this.state.form.criterio_id != "" &&
            this.state.form.probability != "" &&
            this.state.form.impact != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    componentDidMount = () => {
        this.configSelect();
    }

    configSelect = () => {
        let arrayActivities = []
        let arrayCriterios = []
        let arraySpecificAreas = []

        this.props.activities.map((item) => (
            arrayActivities.push({ label: item.name, value: item.id })
        ))

        this.props.criterios.map((item) => (
            arrayCriterios.push({ label: item.name, value: item.id })
        ))

        this.props.specific_areas.map((item) => (
            arraySpecificAreas.push({ label: item.name, value: item.id })
        ))


        this.setState({
            activities: arrayActivities,
            criterios: arrayCriterios,
            specificAreas: arraySpecificAreas
        })
    }

    handleChangeAutocompleteActivities = selectedOptionActivities => {
        this.setState({
            selectedOptionActivities,
            form: {
                ...this.state.form,
                activity_id: selectedOptionActivities.value
            }
        });
    };

    handleChangeAutocompleteCriterios = selectedOptionCriterios => {
        this.setState({
            selectedOptionCriterios,
            form: {
                ...this.state.form,
                criterio_id: selectedOptionCriterios.value
            }
        });
    };

    handleChangeAutocompleteSpecificAreas = selectedOptionSpecificAreas => {
        this.setState({
            selectedOptionSpecificAreas,
            form: {
                ...this.state.form,
                specific_area_id: selectedOptionSpecificAreas.value
            }
        });
    };

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    HandleChangeUpdateStepDescription = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                description: e,
            }
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    HandleChangeCheckbox = (e) => {
        const array = this.state.form.possible_involvement_array;
        const value = e.target.value != "on" ? e.target.value : ""

        if (e.target.checked) {
            array.push(value)

            this.setState({
                form: {
                    ...this.state.form,
                    possible_involvement_array: array,
                }
            })

        } else {

            const index = array.indexOf(e.target.value)

            delete array[index]
            this.setState({
                form: {
                    ...this.state.form,
                    possible_involvement_array: array.filter(item => item != null),
                }
            })

        }
    }

    edit = (risk) => {
        this.setState({
            modal: true,
            modeEdit: true,
            id: risk.id,

            form: {
                description: risk.description,
                risk_type: risk.risk_type,
                possible_involvement_array: risk.possible_involvement_array,
                activity_id: risk.activity_id,
                specific_area_id: risk.specific_area_id,
                criterio_id: risk.criterio_id,
                probability: risk.probability,
                impact: risk.impact,
                matrix_id: risk.matrix.id
            },

            selectedOptionActivities: {
                activity_id: risk.activity_id,
                label: `${risk.activity.name}`
            },

            selectedOptionSpecificAreas: {
                specific_area_id: risk.specific_area.id,
                label: `${risk.specific_area.name}`
            },

            selectedOptionCriterios: {
                criterio_id: risk.criterio_id,
                label: `${risk.criterio.name}`
            },
        })
    }

    HandleClick = () => {
        if (this.validationForm() == true) {
            if (this.state.modeEdit == true) {
                fetch(`/risk/matrices/${this.props.risk_matrix.id}/risks/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.setState({ modal: false })
                        this.props.loadData()
                        this.messageSuccess(data);
                    });
            } else {
                fetch(`/risk/matrices/${this.props.risk_matrix.id}/risks`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.setState({ modal: false })
                        this.props.loadData()
                        this.messageSuccess(data);
                    });
            }
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/risk/matrices/${this.props.risk_matrix.id}/risks/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };


    //form filter 

    clearValuesFilter = () => {
        this.setState({
            formFilter: {
                description: "",
                risk_type: "",
                activity_id: "",
                specific_area_id: "",
                criterio_id: "",
                probability: "",
                impact: "",
            },
        })
    }

    HandleChangeFilter = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value,
            }
        })
    }

    showFilter = (estado) => {
        this.clearValuesFilter();
        if (estado == true) {
            this.setState({ showFilter: true })
        } else {
            this.setState({ showFilter: false })
            this.props.loadData();
        }
    }


    HandleClickFilter = e => {
        this.props.updateStateLoad(true)
        fetch(`${this.props.urldata}?description=${this.state.formFilter.description}&risk_type=${this.state.formFilter.risk_type}&activity_id=${this.state.formFilter.activity_id}&specific_area_id=${this.state.formFilter.specific_area_id}&criterio_id=${this.state.formFilter.criterio_id}&probability=${this.state.formFilter.probability}&impact=${this.state.formFilter.impact}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.props.updateStateLoad(false)
                this.props.updateDateFilter(data.data)
            });
    };


    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Matriz de Riesgos (${this.props.risk_matrix.proceso})`}
                    subTitle={"Gestión riesgos"}
                    titleNew={"Nuevo riesgo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    onClickFilter={() => this.showFilter(true)}
                    showNew={true}
                    showFilter={true}
                />

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={"/risk/matrices"}
                                data-turbolinks="true"
                            >
                                {"Matriz de riesgos"}
                            </a>
                        </li>

                        <li className="breadcrumb-item">
                            <a
                                href={`/formatos/formats/${this.props.risk_matrix.id}`}
                                data-turbolinks="true"
                            >
                                {"Riesgos"}
                            </a>
                        </li>
                    </ol>
                </nav>

                {(this.state.showFilter && this.props.user.app_type == "beewo") && (
                    <FormFilterMejora
                        activities={this.props.activities}
                        criterios={this.props.criterios}
                        specific_areas={this.props.specific_areas}
                        showFilter={this.showFilter}
                        HandleClickFilter={this.HandleClickFilter}
                        HandleChangeFilter={this.HandleChangeFilter}
                        formValues={this.state.formFilter}
                    />
                )}


                {(this.state.showFilter && this.props.user.app_type != "beewo") &&(
                    <FormFilter
                        activities={this.props.activities}
                        criterios={this.props.criterios}
                        specific_areas={this.props.specific_areas}
                        showFilter={this.showFilter}
                        HandleClickFilter={this.HandleClickFilter}
                        HandleChangeFilter={this.HandleChangeFilter}
                        formValues={this.state.formFilter}
                    />
                )}

                {(this.state.modal && this.props.user.app_type == "beewo") && (
                    <FormCreateMejora
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit == true ? "Actualizar riesgo" : "Nuevo riesgo"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={this.state.modeEdit == true ? "Actualizar" : "Crear"}
                        HandleChangeUpdateStepDescription={this.HandleChangeUpdateStepDescription}
                        onChangeFormCheckbox={this.HandleChangeCheckbox}

                        //select values

                        //select activities
                        handleChangeAutocompleteActivities={this.handleChangeAutocompleteActivities}
                        selectedOptionActivities={this.state.selectedOptionActivities}
                        activities={this.state.activities}

                        //select criterios
                        handleChangeAutocompleteCriterios={this.handleChangeAutocompleteCriterios}
                        selectedOptionCriterios={this.state.selectedOptionCriterios}
                        criterios={this.state.criterios}

                        //select specific Areas

                        handleChangeAutocompleteSpecificAreas={this.handleChangeAutocompleteSpecificAreas}
                        selectedOptionSpecificAreas={this.state.selectedOptionSpecificAreas}
                        specificAreas={this.state.specificAreas}
                    />
                )}

                {(this.state.modal && this.props.user.app_type != "beewo") && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit == true ? "Actualizar riesgo" : "Nuevo riesgo"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={this.state.modeEdit == true ? "Actualizar" : "Crear"}
                        HandleChangeUpdateStepDescription={this.HandleChangeUpdateStepDescription}
                        onChangeFormCheckbox={this.HandleChangeCheckbox}

                        //select values

                        //select activities
                        handleChangeAutocompleteActivities={this.handleChangeAutocompleteActivities}
                        selectedOptionActivities={this.state.selectedOptionActivities}
                        activities={this.state.activities}

                        //select criterios
                        handleChangeAutocompleteCriterios={this.handleChangeAutocompleteCriterios}
                        selectedOptionCriterios={this.state.selectedOptionCriterios}
                        criterios={this.state.criterios}

                        //select specific Areas

                        handleChangeAutocompleteSpecificAreas={this.handleChangeAutocompleteSpecificAreas}
                        selectedOptionSpecificAreas={this.state.selectedOptionSpecificAreas}
                        specificAreas={this.state.specificAreas}
                    />
                )}




                {(!this.props.isLoaded && this.props.user.app_type == "beewo") ? (

                    <React.Fragment>
                        <IndexWebMejora
                            data={this.props.data}
                            edit={this.edit}
                            delete={this.delete}
                            risk_matrix={this.props.risk_matrix}
                            criterio={this.props.criterio}
                            activity={this.props.activity}
                            showLinks={true}
                        />
                    </React.Fragment>


                ) : (
                        <React.Fragment>
                            <IndexWeb
                                data={this.props.data}
                                edit={this.edit}
                                delete={this.delete}
                                risk_matrix={this.props.risk_matrix}
                                criterio={this.props.criterio}
                                activity={this.props.activity}
                                showLinks={true}
                            />
                        </React.Fragment>
                    )}

                {(this.props.isLoaded) ? (


                    <Loading />

                ) : (
                        <div></div>
                    )}
            </React.Fragment>
        );
    }
}

export default Index;