import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import QuestionSearchValuesFilterIndex from './QuestionSearchValuesFilter/Index';
import { isMobile } from 'react-device-detect';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            type_search: false,
            ErrorValues: true,
            format_search_value: {},
            id: "",

            form: {
                question_id: this.props.question.id,
                question_compare_id: "",
                format_source_id: "",
                question_compare_source_id: "",
                question_to_get_id: "",
                type_search: false,
                operation: ""
            },

            selectedOptionQuestion: {
                label: "Selecione una pregunta",
                question_compare_id: "",
            },

            selectedOption: {
                label: "Selecione un formato",
                format_source_id: ""
            },

            selectedOptionQuestionCompare: {
                label: "Selecione una pregunta",
                question_compare_source_id: "",
            },

            selectedOptionQuestionGet: {
                label: "Selecione una pregunta",
                question_to_get_id: ""
            },

            questions: [],
            format_questions: [],
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
                if (format.id === item.id) {
                    return {
                        ...item,
                        format_source: format.format_source,
                        question_compare: format.question_compare,
                        question_compare_source: format.question_compare_source,
                        question_to_get: format.question_to_get,
                        type_search: format.type_search,
                        operation: format.operation,
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            form: {
                ...this.state.form,
                question_compare_id: "",
                format_source_id: "",
                question_compare_source_id: "",
                question_to_get_id: "",
                type_search: false,
                operation: ""
            },

            selectedOptionQuestion: {
                label: "Selecione una pregunta",
                question_compare_id: "",
            },

            selectedOption: {
                label: "Selecione un formato",
                format_source_id: ""
            },

            selectedOptionQuestionCompare: {
                label: "Selecione una pregunta",
                question_compare_source_id: "",
            },

            selectedOptionQuestionGet: {
                label: "Selecione una pregunta",
                question_to_get_id: ""
            },

            format_questions: [],
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_search_values/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ format_search_value: {} })
                        this.messageSuccess(response)
                    });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/formatos/get_format_search_values/${this.props.question.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    format_search_value: data.register,
                    type_search: data.register.type_search,
                    isLoaded: false
                });
            });
    }

    loadDataSelects = () => {
        fetch(`/formatos/data_questions/${this.props.formato.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                let array = []

                data.steps_questions.map((item) => (
                    array.push({ label: item.question, value: item.id })
                ))

                this.setState({
                    questions: array,
                    modal: true
                });
            });
    }

    loadDataQuestions = (format_id) => {
        fetch(`/formatos/data_questions/${format_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                let array = []

                data.steps_questions.map((item) => (
                    array.push({ label: item.question, value: item.id })
                ))

                this.setState({
                    format_questions: array,
                });
            });
    }

    validationForm = () => {
        if (this.state.form.question_compare_id != "" &&
            this.state.form.format_source_id != "" &&
            this.state.form.question_compare_source_id != "" &&
            this.state.form.question_to_get_id != "" &&
            this.state.form.question_compare_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.validationForm()) {
            if (this.state.modeEdit) {
                fetch(`/formatos/format_search_values/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.messageSuccess(data)
                        this.setState({
                            modal: false,
                            type_search: data.register.type_search,
                            format_search_value: data.register,
                        })
                    });
            } else {
                fetch(`/formatos/format_search_values`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.setState({
                            modal: false,
                            format_search_value: data.register,
                            type_search: data.register.type_search
                        })
                    });
            }
        }
    }


    edit = (format_search_value) => {
        this.loadDataQuestions(format_search_value.format_source.id);
        this.setState({
            modal: true,
            modeEdit: true,
            id: format_search_value.id,

            form: {
                ...this.state.form,
                question_compare_id: format_search_value.question_compare.id,
                format_source_id: format_search_value.format_source.id,
                question_compare_source_id: format_search_value.question_compare_source.id,
                question_to_get_id: format_search_value.question_to_get.id,
                type_search: format_search_value.type_search,
                operation: format_search_value.operation
            },

            selectedOptionQuestion: {
                label: format_search_value.question_compare.question,
                question_compare_id: format_search_value.question_compare.id,
            },

            selectedOption: {
                label: format_search_value.format_source.name,
                format_source_id: format_search_value.format_source.id,
            },

            selectedOptionQuestionCompare: {
                label: format_search_value.question_compare_source.question,
                question_compare_source_id: format_search_value.question_compare_source.id,
            },

            selectedOptionQuestionGet: {
                label: format_search_value.question_to_get.question,
                question_to_get_id: format_search_value.question_to_get.id,
            },
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.loadDataQuestions(selectedOption.value);
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                format_source_id: selectedOption.value
            }
        });
    };

    handleChangeAutocompleteQuestions = selectedOptionQuestion => {
        this.setState({
            selectedOptionQuestion,
            form: {
                ...this.state.form,
                question_compare_id: selectedOptionQuestion.value
            }
        });
    };

    handleChangeAutocompleteQuestionCompare = selectedOptionQuestionCompare => {
        this.setState({
            selectedOptionQuestionCompare,
            form: {
                ...this.state.form,
                question_compare_source_id: selectedOptionQuestionCompare.value
            }
        });
    };

    handleChangeAutocompleteQuestionGet = selectedOptionQuestionGet => {
        this.setState({
            selectedOptionQuestionGet,
            form: {
                ...this.state.form,
                question_to_get_id: selectedOptionQuestionGet.value
            }
        });
    };

    selectQuestionSearchValuesFilter = (state) => {
        this.setState({
            type_search: state
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg modal-search-values" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>
                        <div className='ui-modals-header-title__container'>
                            <span className='ui-titles_modals'>{this.props.title}</span>
                            <span className='ui-name-question-modals'>Pregunta {this.props.question.question}</span>
                        </div>
                    </ModalHeader>

                    <ModalBody>

                        {this.state.modal && (
                            <FormCreate
                                backdrop={"static"}
                                modal={this.state.modal}
                                toggle={this.toogle}
                                title={this.state.modeEdit ? "Actualizar asociación" : "Crear asociación"}
                                nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                                //form props
                                formValues={this.state.form}
                                onChangeForm={this.HandleChange}
                                submitForm={this.HandleClick}
                                errorValues={this.state.ErrorValues}

                                //select values
                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                selectedOption={this.state.selectedOption}
                                formatos={this.props.formatos}

                                //select questions 
                                handleChangeAutocompleteQuestions={this.handleChangeAutocompleteQuestions}
                                selectedOptionQuestion={this.state.selectedOptionQuestion}
                                questions={this.state.questions}

                                //select question compare
                                handleChangeAutocompleteQuestionCompare={this.handleChangeAutocompleteQuestionCompare}
                                selectedOptionQuestionCompare={this.state.selectedOptionQuestionCompare}
                                format_questions={this.state.format_questions}

                                //select question get
                                handleChangeAutocompleteQuestionGet={this.handleChangeAutocompleteQuestionGet}
                                selectedOptionQuestionGet={this.state.selectedOptionQuestionGet}

                            />
                        )}

                        {!isMobile ? (
                            <React.Fragment>
                                <div className="col-md-12 mb-3 text-right pr-0">
                                    {Object.keys(this.state.format_search_value).length == 0 && (
                                        <button
                                            className="btn-shadow btn btn-info"
                                            onClick={() => this.loadDataSelects()}
                                        >
                                            Abrir formulario
                                        </button>
                                    )}
                                </div>
                                <div className="card">
                                    <div className="background-gray">
                                        <table className="table " id="sampleTable">
                                            <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                                <tr>
                                                    <th>Pregunta para comparar</th>
                                                    <th>Formato fuente</th>
                                                    <th>Pregunta para comparar (fuente)</th>
                                                    <th>Pregunta para traer (fuente)</th>
                                                    {this.state.type_search && (
                                                        <th>Operación</th>
                                                    )}
                                                    <th className="text-right">Opciones</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {Object.keys(this.state.format_search_value).length != 0 ? (
                                                    <tr key={this.state.format_search_value.id} className="tr-table-task" style={{ borderLeft: `4px solid green` }}>
                                                        <td>{this.state.format_search_value.question_compare.question}</td>
                                                        <td>{this.state.format_search_value.format_source.name}</td>
                                                        <td>{this.state.format_search_value.question_compare_source.question}</td>
                                                        <td>{this.state.format_search_value.question_to_get.question}</td>
                                                        {this.state.type_search && (
                                                            <td>{this.state.format_search_value.operation}</td>
                                                        )}
                                                        <td className="text-right">
                                                            {true && (
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.selectQuestionSearchValuesFilter(!this.state.type_search)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                <div>Filtro del buscador</div>
                                                                            </DropdownItem>
                                                                        )}
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(this.state.format_search_value)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(this.state.format_search_value.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}


                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td colSpan="8" className="text-center">
                                                            <div className="text-center mt-4 mb-4">
                                                                <h4>No hay registros</h4>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        {(Object.keys(this.state.format_search_value).length != 0 && this.state.type_search) && (
                                            <React.Fragment>
                                                <QuestionSearchValuesFilterIndex
                                                    question={this.props.question}
                                                    format_search_value={this.state.format_search_value}
                                                    formato={this.props.formato}
                                                />
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="card ui-index-formarSearchValue-mobile__container">
                                    <table>
                                        <thead>
                                            <tr className='ui-index-formatSearchValue__container-columns'>
                                                <th className='ui-index-formatSearchValue-title'>Pregunta para comparar</th>
                                                <th className='ui-index-formatSearchValue-title'>Formato fuente</th>
                                                <th className='ui-index-formatSearchValue-title'>Pregunta para comparar (fuente)</th>
                                                <th className='ui-index-formatSearchValue-title'>Pregunta para traer (fuente)</th>
                                                {this.state.type_search && (
                                                    <th className='ui-index-formatSearchValueFilter-title'>Operación</th>
                                                )}
                                                <th className='ui-index-formatSearchValueFilter-title'>Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(this.state.format_search_value).length != 0 ? (
                                                <tr key={this.state.format_search_value.id} className='ui-index-formatSearchValue__container-columns'>
                                                    <td className='ui-index-formatSearchValue-data'>{this.state.format_search_value.question_compare.question}</td>
                                                    <td className='ui-index-formatSearchValue-data'>{this.state.format_search_value.format_source.name}</td>
                                                    <td className='ui-index-formatSearchValue-data'>{this.state.format_search_value.question_compare_source.question}</td>
                                                    <td className='ui-index-formatSearchValue-data' >{this.state.format_search_value.question_to_get.question}</td>
                                                    {this.state.type_search && (
                                                        <td className='ui-index-formatSearchValueFilter-data'>{this.state.format_search_value.operation}</td>
                                                    )}
                                                    <td className='ui-index-formatSearchValueFilter-data'>
                                                        {true && (
                                                            <UncontrolledDropdown className='btn-group ui-index-formatSearchValue-dropdown__container'>
                                                                <DropdownToggle className='btn'>
                                                                    <i className="fas fa-bars"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                    {true && (
                                                                        <DropdownItem
                                                                            className="dropdown-item"
                                                                            onClick={() => this.edit(this.state.format_search_value)}
                                                                        >
                                                                            Editar
                                                                        </DropdownItem>
                                                                    )}
                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => this.delete(this.state.format_search_value.id)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Eliminar
                                                                        </DropdownItem>
                                                                    )}
                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => this.selectQuestionSearchValuesFilter(!this.state.type_search)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            QuestionSearchValuesFilter
                                                                        </DropdownItem>
                                                                    )}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        )}
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colspan="8" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {(Object.keys(this.state.format_search_value).length != 0 && this.state.type_search) && (
                                    <React.Fragment>
                                        <QuestionSearchValuesFilterIndex
                                            question={this.props.question}
                                            format_search_value={this.state.format_search_value}
                                            formato={this.props.formato}
                                        />
                                    </React.Fragment>
                                )}
                            </React.Fragment>

                        )}



                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;