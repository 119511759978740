import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            modeEdit: false,
            id: "",
            form: {
                name: "",
                variable: "",
                data: [],
                unit_type: "",
                indicator_type: this.props.type,
                nameEmpresa: "",
            },

            dataNames: [],
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                variable: "",
                data: [],
                unit_type: "",
                indicator_type: this.props.type,
            },

            modeEdit: false,
            id: "",
            dataNames: []
        })
    }

    edit = (indicator) => {
        this.setState({
            form: {
                ...this.state.form,
                name: indicator.name,
                variable: indicator.variable,
                data: indicator.data,
                unit_type: indicator.unit_type,
            },

            dataNames: indicator.data,
            id: indicator.id,
            modal: true,
            modeEdit: true,
        })
    }

    toogle = (from) => {
        if(from == "new"){
            this.setState({ modal: true})
            this.clearValues();
        }else{
            this.setState({ modal: false})
        }
    }

    getNameTitle = (name) => {
        const indicadores = "Indicadores"

        if(name == "SACLI"){
            return `${indicadores} Clinicos`
        }else if(name == "SAADM"){
            return `${indicadores} Administrativos`
        }else if(name == "SAOP"){
            return `${indicadores} Operativos`
        }else if(name == "SAMEJ"){
            return `${indicadores} Mejora`
        }else{
            return `${indicadores} Gerenciales`
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleClick = () => {
        if (this.state.modeEdit == false)
            fetch(`/indicator/indicator_tables`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    this.clearValues();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        else {
            fetch(`/indicator/indicator_tables/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    this.clearValues();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
            });
        }
    }

    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.value) {
            fetch("/indicator/indicator_tables/" + id, {
              method: "delete",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.props.loadData()
                this.messageSuccess(response)
              });
          }
        });
    };

    AddNames = () => {
        const arrayName = this.state.dataNames
        arrayName.push(this.state.form.nameEmpresa)

        this.setState({
            dataNames: arrayName,
            form: {
                ...this.state.form,
                nameEmpresa: "",
                data: arrayName
            },
        })
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.getNameTitle(this.props.type)}`} 
                    subTitle={`Gestión de Indicadores`}
                    titleNew={"Nuevo indicador"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />

                <FormCreate
                    //modal props
                    backdrop={"static"}
                    modal={this.state.modal}
                    toggle={this.toogle}    
                    title={this.state.modeEdit == true ? "Editar Indicador" : "Agregar nuevo Indicador"}

                    //form props
                    formValues={this.state.form}
                    errorValues={this.state.ErrorValues}
                    onChangeForm={this.HandleChange}
                    submitForm={this.HandleClick}
                    nameSubmit={this.state.modeEdit == true ? "Actualizar" : "Crear"}

                    //props 
                    dataNames={this.state.dataNames}
                    AddNames={this.AddNames}
                />

                {this.props.data.length >= 1 ? (
                    <div className="row indicators-wp">
                        {this.props.data.map(indicator => (
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body">
                                        <p>{indicator.name}</p>
                                    </div>

                                    <div className="card-footer">
                                        <a
                                            className="btn"
                                            onClick={() => this.edit(indicator)}
                                        >
                                            <i className="fas fa-pen"></i>
                                        </a>

                                        <a
                                            className="btn"
                                            onClick={() => this.delete(indicator.id)}
                                        >
                                            <i className="fas fa-trash-alt"></i>
                                        </a>

                                        <a
                                            href={`/indicator/indicator_tables/${indicator.id}`}
                                            className="btn"
                                            data-turbolinks="true"
                                        >
                                            <i className="fas fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                ) : (
                    <div className="card">
                        <div className="card-body text-center">
                            <h4>No hay indicadores</h4>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Index;