import React, { Component } from 'react';
import { ChromePicker } from 'react-color'

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showPikerFirstColor: false,
            showPikerSecondColor: false
        }
    }

    handleClickColor = (piker) => {
        this.setState({
            showPikerFirstColor: (piker == "color" ? true : false),
            showPikerSecondColor: (piker == "color" ? false : true)
        })
    }

    handleCloseColor = () => {
        this.setState({
            showPikerFirstColor: false,
            showPikerSecondColor: false,
        })
    }

    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        
        return (
            <React.Fragment>
                <div className="col-md-12 p-0">
                    <input
                        type="text"
                        name="title"
                        value={this.props.formValues.title}
                        onChange={this.props.onChangeForm}
                        className={`form form-control ${!this.props.errorValues && this.props.formValues.title == "" ? "error-class" : ""}`}
                        placeholder="Título"
                    />
                </div>

                {true && (
                    <div className="col-md-12 p-0 mt-2">
                        <select
                            type="text"
                            name="type_column"
                            value={this.props.formValues.type_column}
                            onChange={this.props.onChangeForm}
                            className={`form form-control ${!this.props.errorValues && this.props.formValues.type_column == "" ? "error-class" : ""}`}
                        >
                            <option value="">Tipo de columna</option>
                            <option value="to_do">Por hacer</option>
                            <option value="doing">Haciendo</option>
                            <option value="done">Hecho</option>
                            <option value="does_not_count">Que no cuenta en el desempeño</option>
                        </select>
                    </div>
                )}
            

                <div className="card mt-2">
                    <div className="card-header p-0" style={{ backgroundColor: this.props.formValues.color, color: this.props.formValues.color_text }}>
                        <div className="ui-columns-header-form-create">
                            <div className="ui-title-column-task" title={this.props.formValues.title}>
                                {this.props.formValues.title}
                            </div>

                            <div className="ui-column-new-Tasks-form-create">
                                <span className="badge badge-light float-right ui-columns-task-badge">{0}</span>
                                <i className="fas fa-plus float-right icon-add mr-2" style={{ color: this.props.formValues.color_text }}></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6 pl-0 mt-2 piker-hue-horizontal mt-2">
                            <button 
                                onClick={() => this.handleClickColor("color")} 
                                style={{ border: `1px solid ${this.props.formValues.color}`, fontSize: "13px" }}
                                className="btn btn-light w-100"
                            >
                                <i className="fas fa-font ui-condicional-icons" style={{ borderBottom: `6px solid ${this.props.formValues.color}` }}></i> Fondo
                            </button>

                            {this.state.showPikerFirstColor ? <div style={ popover }>
                            <div style={ cover } onClick={this.handleCloseColor}/>
                                <ChromePicker 
                                    color={this.props.formValues.color} 
                                    onChange={(color) => this.props.onChangeForm({ target: { name: "color", value: color.hex } } )} 
                                />
                            </div> : null }
                        </div>

                        <div className="col-md-6 p-0 mt-2 piker-hue-horizontal mt-2">
                            <button 
                                onClick={() => this.handleClickColor("color_text")} 
                                style={{ border: `1px solid ${this.props.formValues.color_text}`, fontSize: "13px" }}
                                className="btn btn-light w-100"
                            >
                                <i className="fas fa-font ui-condicional-icons" style={{ borderBottom: `6px solid ${this.props.formValues.color_text}` }}></i> Texto
                            </button>

                            {this.state.showPikerSecondColor ? <div style={ popover }>
                            <div style={ cover } onClick={this.handleCloseColor}/>
                                <ChromePicker 
                                    color={this.props.formValues.color_text} 
                                    onChange={(color) => this.props.onChangeForm({ target: { name: "color_text", value: color.hex } } )} 
                                />
                            </div> : null }
                        </div>
                    </div>
                </div>

                {this.props.formValues.type_column == "does_not_count" && (
                    <div className="row mt-2">
                        <div className="col-md-12 text-center">
                            <div class="alert alert-info" role="alert">
                                <b>Las tareas que se creen en esta columna no contarán en el desempeño del empleado.</b>
                            </div>
                        </div>
                    </div>
                )}

                <div className="col-md-12 p-0 mt-2">
                    <div className="row">

                        <div className="col-md-12 pr-0">
                            {(this.props.modeEdit && this.props.data != undefined) && (
                                <React.Fragment>
                                    <label className="mt-2 mr-2">Posición</label>
                                    <select
                                        onChange={(e) => this.props.updatePosition(e)}
                                        value={this.props.formValues.position}
                                    >
                                        {this.props.data.map((column, index) => (
                                            <option value={column.position}>{column.position}</option>
                                        ))}
                                    </select>
                                </React.Fragment>
                            )}
                        </div>

                        <div className="col-md-12" style={{color:"white", display:"flex", justifyContent:"flex-end" , gap:"5px"}}>
                            <a className="btn-shadow btn btn-info"  style={{color:"white"}} onClick={this.props.submitForm}>{this.props.nameSubmit}</a>
                            <a className="btn btn-outline-danger mr-2" onClick={() => this.props.toogle()}>Cancelar</a>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default FormCreate;


/*
{this.props.data.map((column) => {
                                            <option value={this.props.formValues.position}>{column.position}</option>
                                        })}

*/