import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import {  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


const HeaderMenuAdmin = (props) => {
    return(
        <div className="header-btn-lg pr-0 ui-headerMenu-container-dropdowns">
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left">
                                    <UncontrolledDropdown className='btn-group'>
                                        <DropdownToggle data-title="Perfil de Usuario">
                                            <img width='42' className='rounded-circle' src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRuEG5mQKFGi9hNgrdBJnEyBfw2Kl0JNXwoHiXjVeKQemKOQqFe" style={{height: '44px'}} />
                                            <i className="fa fa-angle-down ml-2 opacity-8"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className='dropdown-menu-header' style={{padding: '0px', width: '350px'}}>
                                            <div className="dropdown-menu-header-inner bg-info" style={{padding: '2rem 0.5rem'}}>
                                                <div className="menu-header-image opacity-10" style={{backgroundImage: "url('assets/images/dropdown-header/city3.jpg')", left: '7px', top: '12px'}}>
                                                    <div className="menu-header-content text-left">                                                    
                                                        <div className="widget-content p-0">
                                                            <div className="widget-content-wrapper">
                                                                <div className="widget-content-left" style={{display: 'flex'}}>
                                                                    <img width='42' 
                                                                        className='rounded-circle'
                                                                        style={{height: '44px'}}  
                                                                        src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRuEG5mQKFGi9hNgrdBJnEyBfw2Kl0JNXwoHiXjVeKQemKOQqFe" 
                                                                        alt=""
                                                                    />

                                                                    <div className="widget-content-left">
                                                                        <div className="widget-heading ml-4" style={{textTransform: 'uppercase'}}>
                                                                            {props.current_admin}
                                                                        </div>
                                                                        <div className="widget-subheading opacity-8 ml-4">
                                                                            Administrador
                                                                        </div>
                                                                    </div>
                                                                    <div className="widget-content-right ml-5">
                                                                        <a href={props.destroy_admin_session_path} className="btn-pill btn-shadow btn-shine btn btn-focus">Salir</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="scroll-area-xs" style={{height: '160px'}}>
                                                <div className="scrollbar-container ps">
                                                    <ul className="nav flex-column">
                                                        <DropdownItem>
                                                            <li className="nav-item">
                                                                <a href={props.edit_admin_registration_path} className="nav-link" data-turbolinks='true'>
                                                                    Editar Contraseña
                                                                </a>
                                                            </li>
                                                        </DropdownItem>                                                        
                                                    </ul>
                                                </div>
                                            </div>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                        </div>
                    </div>
    )

}

export default HeaderMenuAdmin;
WebpackerReact.setup({ HeaderMenuAdmin });