import React, { useState, useEffect } from 'react';

const FormUpdate = (props) => {
    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <div style={{ width: "289px" }}>
                <form onSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-md-12">
                            <input 
                                type="text" 
                                name="name"
                                placeholder='Columnas'
                                className={`form form-control`}
                                value={props.formUpdateRow.name}
                                onChange={props.handleChangeUpdateRow}
                            />
                        </div>

                        <div className="col-md-12 mt-4">
                            <textarea 
                                type="text" 
                                name="description"
                                placeholder='Descripcion'
                                className={`form form-control`}
                                value={props.formUpdateRow.description}
                                onChange={props.handleChangeUpdateRow}
                            />
                        </div>

                        <div className="col-md-12 mt-2">
                            <button
                                className='btn-shadow btn btn-info btn-block'
                                onClick={props.handleClickUpdate}
                            >
                                Actualizar
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}


export default FormUpdate;

