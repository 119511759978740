import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

class FormFilter extends Component {
    render() {
        return (
            <div className="content main-card mb-3 card">
                <div className="card-body">
                    <div className="row">

                        <div className={`col-md-5 ${isMobile ? "mb-3" : ""}`}>
                            <label>Descripción del (Riesgo - Problema - Peligro)</label>
                            <input 
                                type="text"
                                name="description"
                                onChange={this.props.HandleChangeFilter}
                                value={this.props.formValues.description}
                                className="form form-control"
                                placeholder={"Descripción del (Riesgo - Problema - Peligro)"}
                            />
                        </div>

                        <div className={`col-md-4 ${isMobile ? "mb-3" : ""}`}>
                            <label>Tipo de riesgo</label>
                            <select 
                                type="text"
                                name="risk_type"
                                onChange={this.props.HandleChangeFilter}
                                value={this.props.formValues.risk_type}
                                className="form form-control"
                            >
                                <option value="">Seleccione un tipo</option>
                                <option value="Riesgo">Riesgo</option>
                                <option value="Problema">Problema</option>
                                <option value="Peligro">Peligro</option>
                            </select>
                        </div>

                        <div className="col-md-3">
                            <label>Criterio</label>
                            <select 
                                type="text"
                                name="criterio_id"
                                onChange={this.props.HandleChangeFilter}
                                value={this.props.formValues.criterio_id}
                                className="form form-control"
                            >
                                <option value="">Seleccione un criterio</option>
                                {this.props.criterios.map(item => (
                                    <React.Fragment>
                                        <option value={item.id}>{item.name}</option>
                                    </React.Fragment>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-3 mt-3">
                            <label>Probabilidad</label>
                            <select 
                                type="text"
                                name="probability"
                                onChange={this.props.HandleChangeFilter}
                                value={this.props.formValues.probability}
                                className="form form-control"
                            >
                                <option value="">Seleccione una probabilidad</option>
                                <option value="1">Improbable</option>
                                <option value="2">Remota</option>
                                <option value="3">Ocasional</option>
                                <option value="4">Probable</option>
                                <option value="5">Altamente Probable</option>
                            </select>
                        </div>

                        <div className="col-md-3 mt-3">
                            <label>Impacto</label>
                            <select 
                                type="text"
                                name="impact"
                                onChange={this.props.HandleChangeFilter}
                                value={this.props.formValues.impact}
                                className="form form-control"
                            >
                                <option value="">Seleccione un Impacto</option>
                                <option value="1">Insignificante</option>
                                <option value="2">Baja gravedad</option>
                                <option value="3">Grave</option>
                                <option value="4">Muy Grave</option>
                                <option value="5">Catastrófico</option>
                            </select>
                        </div>

                        {/*<div className="col-md-3 mt-3">
                            <label>Posible Afectación</label>
                            <select 
                                type="text"
                                name="risk_type"
                                onChange={this.props.HandleChangeFilter}
                                value={this.props.formValues.risk_type}
                                className="form form-control"
                            >
                                <option value="">Seleccione un Impacto</option>
                                <option value="1">Insignificante</option>
                                <option value="2">Baja gravedad</option>
                                <option value="3">Grave</option>
                                <option value="4">Muy Grave</option>
                                <option value="5">Catastrofico</option>
                            </select>
                        </div>*/}

                        <div className="col-md-3 mt-3">
                            <label>Área Específica</label>
                            <select 
                                type="text"
                                name="specific_area_id"
                                onChange={this.props.HandleChangeFilter}
                                value={this.props.formValues.specific_area_id}
                                className="form form-control"
                            >
                                <option value="">Seleccione un criterio</option>
                                {this.props.specific_areas.map(item => (
                                    <React.Fragment>
                                        <option value={item.id}>{item.name}</option>
                                    </React.Fragment>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-3 mt-3">
                            <label>Actividad de riesgo</label>
                            <select 
                                type="text"
                                name="activity_id"
                                onChange={this.props.HandleChangeFilter}
                                value={this.props.formValues.activity_id}
                                className="form form-control"
                            >
                                <option value="">Seleccione un criterio</option>
                                {this.props.activities.map(item => (
                                    <React.Fragment>
                                        <option value={item.id}>{item.name}</option>
                                    </React.Fragment>
                                ))}
                            </select>
                        </div>

                    </div>
                </div>

                <div className="card-footer">
                    <button 
                        className="btn-shadow btn btn-info mr-3"
                        onClick={() => this.props.HandleClickFilter()}
                    >
                        Aplicar Filtro
                    </button>

                    <button 
                        className="btn btn-outline-danger"
                        onClick={() => this.props.showFilter()}
                    >
                        Cancelar
                    </button>
                </div>

            </div>
        );
    }
}

export default FormFilter;
