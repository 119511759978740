import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { isMobile } from 'react-device-detect';

//views
import IndexMobile from './Movil/Index';
import IndexWeb from './Movil/IndexWeb';

//moment
import moment from 'moment';

class Index extends Component {
    constructor(props) {
        super(props);
        const date = new Date();
        this.token = document.querySelector("[name='csrf-token']").content;
        this.fecha = `${date.getFullYear()}-${date.getMonth() < 10 ? 0 : ""}${date.getMonth()}-${date.getDate() < 10 ? 0 : ""}${date.getDate()}`
        this.state = {
            modal: false,
            modeEdit: false,
            ErrorValues: true,
            id: "",

            formCreate: {
                name: "",
                proceso_id: "",
                responsable_user_id: "",
                external_document_parent_id: "",
                emisor: "",
                attached: {},
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                creation_date: this.fecha,
                position_ids: [],
            },

            selectedOptionUser: {
                responsable_user_id: "",
                label: "responsable"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },

            selectedOptionMulti: {
                position_ids: [],
                label: "Cargos que pueden acceder",
            },

            editValues: [],
            nameFile: "",
            sizeFile: 0
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
            this.clearValues()
        }
    }

    validationForm = () => {
        if (this.state.formCreate.responsable_user_id != "" &&
            this.state.formCreate.name != "" &&
            this.state.formCreate.proceso_id != "" &&
            this.state.formCreate.due_date != "" &&
            this.state.formCreate.creation_date != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/document_management/external_documents/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            formCreate: {
                name: "",
                proceso_id: "",
                responsable_user_id: "",
                external_document_parent_id: "",
                emisor: "",
                attached: {},
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                creation_date: "",
                position_ids: [],
            },

            selectedOptionUser: {
                responsable_user_id: "",
                label: "Usuario que crea"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },


            editValues: [],
            nameFile: "",
            sizeFile: 0
        })
    }

    HandleClick = () => {
        if (this.validationForm() && this.state.sizeFile <= 11485760 || this.state.sizeFile == 0) {
            this.setState({ isLoaded: true })
            const formData = new FormData();
            formData.append("name", this.state.formCreate.name)
            formData.append("proceso_id", this.state.formCreate.proceso_id)
            formData.append("responsable_user_id", this.state.formCreate.responsable_user_id)
            formData.append("external_document_parent_id", this.state.formCreate.external_document_parent_id)
            formData.append("emisor", this.state.formCreate.emisor)
            formData.append("attached", this.state.formCreate.attached)
            formData.append("due_date", this.state.formCreate.due_date)
            formData.append("creation_date", this.state.formCreate.creation_date)
            formData.append("position_ids", this.state.formCreate.position_ids)


            if (!this.state.modeEdit)
                fetch(`/document_management/external_documents`, {
                    method: 'POST', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                        this.props.loadData();
                        this.clearValues();
                    });
            else {
                fetch(`/document_management/external_documents/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                        this.props.updateItem(data.register);
                        this.clearValues();
                    });
            }
        }
    }

    HandleChange = (e) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [e.target.name]: e.target.value
            }
        })
    }

    edit = (document) => {
        const array = [];
        const arrayIds = [];

        document.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))


        this.setState({
            modeEdit: true,
            modal: true,
            id: document.id,

            formCreate: {
                ...this.state.formCreate,
                name: document.name,
                proceso_id: document.proceso.id,
                responsable_user_id: document.responsable_user.id,
                emisor: document.emisor,
                attached: {},
                due_date: document.due_date,
                creation_date: document.creation_date,
                external_document_parent_id: document.external_document_parent_id,
                position_ids: arrayIds,
            },


            selectedOptionUser: {
                responsable_user_id: `${document.responsable_user != null ? document.responsable_user.id : ""}`,
                label: `${document.responsable_user != null ? `${document.responsable_user.first_name} ${document.responsable_user.first_last_name}` : "Cargo que crea"}`
            },

            selectedOptionProceso: {
                proceso_id: `${document.proceso != null ? document.proceso.id : ""}`,
                label: `${document.proceso != null ? document.proceso.name : "Proceso"}`,
            },

            selectedOptionMulti: {
                position_ids: arrayIds,
                label: "Cargos que pueden acceder",
            },

            editValues: array,
        })
    }

    generateClone = (document) => {

        const array = [];
        const arrayIds = [];

        document.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modal: true,
            modeEdit: false,
            is_clone: true,

            formCreate: {
                ...this.state.formCreate,
                name: document.name,
                proceso_id: document.proceso.id,
                responsable_user_id: document.responsable_user.id,
                emisor: document.emisor,
                attached: {},
                due_date: document.due_date,
                creation_date: document.creation_date,  
                external_document_parent_id: document.id,
                position_ids: arrayIds,
            },

            selectedOptionUser: {
                responsable_user_id: `${document.responsable_user != null ? document.responsable_user.id : ""}`,
                label: `${document.responsable_user != null ? `${document.responsable_user.first_name} ${document.responsable_user.first_last_name}` : "Cargo que crea"}`
            },

            selectedOptionProceso: {
                proceso_id: `${document.proceso != null ? document.proceso.id : ""}`,
                label: `${document.proceso != null ? document.proceso.name : "Proceso"}`,
            },

            selectedOptionMulti: {
                position_ids: arrayIds,
                label: "Cargos que pueden acceder",
            },

            editValues: array,
        })
    }


    changeState = (document_id, state) => {
        fetch(`/document_management/update_state_external_document/${document_id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
        });
    }


    handleChangeChecked = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;

        this.setState({
            formCreate: {
                ...this.state.formCreate,
                is_attached: isChecked
            }
        })
    }

    handleChangeFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formCreate: {
                    ...this.state.formCreate,
                    attached: file.size >= 11485760 ? {} : file
                },

                nameFile: file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
                sizeFile: file.size,
            })
        ));
    };

    handleChangeAutocompleteUser = selectedOptionUser => {
        this.setState({
            selectedOptionUser,
                formCreate: {
                    ...this.state.formCreate,
                    responsable_user_id: selectedOptionUser.value
                }
        });
    };

    handleChangeAutocompleteProceso = selectedOptionProceso => {
        this.setState({
            selectedOptionProceso,
                formCreate: {
                    ...this.state.formCreate,
                    proceso_id: selectedOptionProceso.value
                }
        });
    };

    handleChangeAutocompleteMulti = selectedOptionMulti => {
        let array = []

        selectedOptionMulti.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            formCreate: {
                ...this.state.formCreate,
                position_ids: array
            }
        })
    }

    get_colorDate = (state) => {
        let resta = new Date(state).getTime() - new Date().getTime()
        resta = Math.round((resta / (1000 * 24 * 60 * 60)));
        resta = resta + 1
        if (resta >= 5) {
            return "green"
        } else if (resta < 5 && resta >= 0) {
            return "orange"
        } else {
            return "red"
        }
    }

    isNewDocument(date) {
        // Check if the date is null or undefined
        if (date == null) {
            // Treat as if it's not a new document
            return false;
        }
    
        // Create Moment.js objects for the creation date and the current date
        const createdDate = moment(date);
        const currentDate = moment();
    
        // Calculate the difference in days between the creation date and the current date
        const daysDifference = currentDate.diff(createdDate, 'days');

        // Determine if the document is new based on the specified time threshold
        return daysDifference <= 1;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar documento externo" : "Crear documento externo"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.formCreate}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        errorValues={this.state.ErrorValues}
                        handleChangeChecked={this.handleChangeChecked}
                        isLoaded={this.state.isLoaded}
                        company={this.props.company}

                        //file
                        nameFile={this.state.nameFile}
                        handleChangeFile={this.handleChangeFile}
                        sizeFile={this.state.sizeFile}

                        //select values

                        //select UserCreate
                        selectedOptionUser={this.state.selectedOptionUser}
                        handleChangeAutocompleteUser={this.handleChangeAutocompleteUser}

                        //select Proceso
                        selectedOptionProceso={this.state.selectedOptionProceso}
                        handleChangeAutocompleteProceso={this.handleChangeAutocompleteProceso}

                        //select multi
                        selectedOptionMulti={this.state.selectedOptionMulti}
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}


                        procesos={this.props.procesos}
                        users={this.props.users}
                        positions={this.props.positions}
                        editValues={this.state.editValues}
                    />
                )}

                {isMobile ? (
                    <IndexMobile 
                        estados={this.props.estados}
                        data={this.props.data} 
                        edit={this.edit}
                        delete={this.delete}
                        toogle={this.toogle}

                        //IsNewDocument
                        isNewDocument={this.isNewDocument}

                        activePage={this.props.activePage}
                        countPage={this.props.countPage}
                        totalData={this.props.totalData}
                        handlePageChange={this.props.handlePageChange}
                        changeState={this.changeState}
                        get_colorDate={this.get_colorDate}
                        state={this.props.state}
                    />
                ) : (
                    <IndexWeb 
                        data={this.props.data} 
                        estados={this.props.estados}
                        edit={this.edit}
                        delete={this.delete}
                        toogle={this.toogle}

                        //IsNewDocument
                        isNewDocument={this.isNewDocument}

                        activePage={this.props.activePage}
                        countPage={this.props.countPage}
                        totalData={this.props.totalData}
                        handlePageChange={this.props.handlePageChange}
                        changeState={this.changeState}
                        get_colorDate={this.get_colorDate}
                        state={this.props.state}
                    />
                )}

            </React.Fragment>
        );
    }
}

export default Index;