import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

const ModalEdit = (props) => {
    const token = document.querySelector("[name='csrf-token']").content;
    const [questions, setQuestions] = useState([]);
    const [errorValues, setErrorValues] = useState(true);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isLoadedQuestion, setIsLoadedQuestion] = useState(true);
    const [form, setForm] = useState({ 
        report_date_question_id: "",
        company_question_id: "",
        document_question_id: "",
        driver_question_id: "",
        report_type_question_id: "",
        cause_question_id: "",
        description_question_id: "",
    });

    //select values 
    const [selectedOptionReportDateQuestion, setSelectedOptionReportDateQuestion] = useState({ label: "Fecha del Reporte", report_date_question_id: "" });
    const [selectedOptionCompanyQuestion, setSelectedOptionCompanyQuestion] = useState({ label: "Empresa", company_question_id: "" });
    const [selectedOptionDocumentQuestion, setSelectedOptionDocumentQuestion] = useState({ label: "Documento", document_question_id: "" });
    const [selectedOptionDriverQuestion, setSelectedOptionDriverQuestion] = useState({ label: "Conductor", driver_question_id: "" });
    const [selectedOptionReportTypeQuestion, setSelectedOptionReportTypeQuestion] = useState({ label: "Tipo de Reporte", report_type_question_id: "" });
    const [selectedOptionCauseQuestion, setSelectedOptionCauseQuestion] = useState({ label: "Motivo", cause_question_id: "" });
    const [selectedOptionDescriptionQuestion, setSelectedOptionDescriptionQuestion] = useState({ label: "Descriptión", description_question_id: "" });
    
    useEffect(() => {
        loadData();
        loadQuestions();
    }, []);

    const updateSelectValues = (trs_external_report_configuration) => {
        setSelectedOptionReportDateQuestion(trs_external_report_configuration.report_date_question);
        setSelectedOptionCompanyQuestion(trs_external_report_configuration.company_question);
        setSelectedOptionDocumentQuestion(trs_external_report_configuration.document_question);
        setSelectedOptionDriverQuestion(trs_external_report_configuration.driver_question);
        setSelectedOptionReportTypeQuestion(trs_external_report_configuration.report_type_question);
        setSelectedOptionCauseQuestion(trs_external_report_configuration.cause_question);
        setSelectedOptionDescriptionQuestion(trs_external_report_configuration.description_question);

        setForm({ 
            report_date_question_id: trs_external_report_configuration.report_date_question.value,
            company_question_id: trs_external_report_configuration.company_question.value,
            document_question_id: trs_external_report_configuration.document_question.value,
            driver_question_id: trs_external_report_configuration.driver_question.value,
            report_type_question_id: trs_external_report_configuration.report_type_question.value,
            cause_question_id: trs_external_report_configuration.cause_question.value,
            description_question_id: trs_external_report_configuration.description_question.value
        })
    }
    

    const handleChangeSelectReportDateQuestion = (selectedOption) => {
        setSelectedOptionReportDateQuestion(selectedOption)
        updateForm("report_date_question_id", selectedOption.value)
    }

    const handleChangeSelectCompanyQuestion = (selectedOption) => {
        setSelectedOptionCompanyQuestion(selectedOption)
        updateForm("company_question_id", selectedOption.value)
    }

    const handleChangeSelectDocumentQuestion = (selectedOption) => {
        setSelectedOptionDocumentQuestion(selectedOption)
        updateForm("document_question_id", selectedOption.value)
    }

    const handleChangeSelectDriverQuestion = (selectedOption) => {
        setSelectedOptionDriverQuestion(selectedOption)
        updateForm("driver_question_id", selectedOption.value)
    }
    
    const handleChangeSelectReportTypeQuestion = (selectedOption) => {
        setSelectedOptionReportTypeQuestion(selectedOption)
        updateForm("report_type_question_id", selectedOption.value)
    }

    const handleChangeSelectCauseQuestion = (selectedOption) => {
        setSelectedOptionCauseQuestion(selectedOption)
        updateForm("cause_question_id", selectedOption.value)
    }

    const handleChangeSelectDescriptionQuestion = (selectedOption) => {
        setSelectedOptionDescriptionQuestion(selectedOption)
        updateForm("description_question_id", selectedOption.value)
    }

    const updateForm = (field, value) => {
        setForm({ ...form, [field]: value }) 
    }
        
    const handleSubmit = e => {
        e.preventDefault();
    };
    
    const handleClick = () => {
        fetch(`/formatos/trs_internal_report_configurations/${props.trs_driver_review_configuration.trs_internal_report_configuration.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => { 
            props.messageSuccess(data);
            props.toggle();
        });
    }


    const loadData = () => {
        fetch(`/formatos/get_trs_internal_report_configuration/${props.trs_driver_review_configuration.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            updateSelectValues(data.register);
            setIsLoaded(false)
        });
    }

    const loadQuestions = () => {
        fetch(`/formatos/get_format_questions/${props.trs_driver_review_configuration.format_internal_report.value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setQuestions(data.data);
            setIsLoadedQuestion(false)
        });
    }

    const isLoadedTotal = (isLoaded && isLoadedQuestion) ? true : false

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered modal-lg" backdrop={props.backdrop}>
                {/*<ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>*/}

                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                {isLoadedTotal ? (
                                    <div className="col-md-12 mb-4">
                                        <div className="alert text-center alert-primary" role="alert">
                                            <b>Cargando informacion....</b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-md-12 mb-4">
                                        <div className="alert text-center alert-primary" role="alert">
                                            <h5>Preguntas del formato: <b>{props.trs_driver_review_configuration.format_internal_report.label}</b></h5>
                                        </div>
                                    </div>
                                )}

                                <div className="col-md-6 mb-4">
                                    <label>Fecha del Reporte</label>
                                    <input
                                        type="hidden"
                                        name="report_date_question_id"
                                        value={selectedOptionReportDateQuestion.report_date_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectReportDateQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.report_date_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionReportDateQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Empresa</label>
                                    <input
                                        type="hidden"
                                        name="company_question_id"
                                        value={selectedOptionCompanyQuestion.company_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectCompanyQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.company_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionCompanyQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Documento</label>
                                    <input
                                        type="hidden"
                                        name="document_question_id"
                                        value={selectedOptionDocumentQuestion.document_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectDocumentQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.document_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionDocumentQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Conductor</label>
                                    <input
                                        type="hidden"
                                        name="driver_question_id"
                                        value={selectedOptionDriverQuestion.driver_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectDriverQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.driver_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionDriverQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Tipo de Reporte</label>
                                    <input
                                        type="hidden"
                                        name="report_type_question_id"
                                        value={selectedOptionReportTypeQuestion.report_type_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectReportTypeQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.report_type_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionReportTypeQuestion}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Motivo</label>
                                    <input
                                        type="hidden"
                                        name="cause_question_id"
                                        value={selectedOptionCauseQuestion.cause_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectCauseQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.cause_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionCauseQuestion}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Descriptión</label>
                                    <input
                                        type="hidden"
                                        name="description_question_id"
                                        value={selectedOptionDescriptionQuestion.description_question_id}
                                    />

                                    <Select
                                        onChange={handleChangeSelectDescriptionQuestion}
                                        options={questions}
                                        isDisabled={isLoadedTotal}
                                        autoFocus={false}
                                        className={`link-form ${!errorValues && form.description_question_id == "" ? "error-class" : ""}`}
                                        value={selectedOptionDescriptionQuestion}
                                    />
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                            {!isLoadedTotal && (
                                <button className="btn-shadow btn btn-info" onClick={handleClick}>{"Actualizar"}</button>  
                            )}
                        </ModalFooter>
                    </form>
            </Modal>
        </React.Fragment>
    );
}


export default ModalEdit;

