import React, { Component } from 'react';
import Index from '../components/tasks/tasksCard/TaskIndexCard'
import IndexList from '../components/settings/FavoriteModule/IndexList'

import WebpackerReact from 'webpacker-react';
import { isMobile } from 'react-device-detect';
import SoftwareChangeIndex from './SoftwareChangeIndex';

class TaskIndexCard extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            hasNewFeature: props.user.has_new_feature,
            current_tab: isMobile ? "links" : (props.favorite ? "links" : "")
        }
    }

    updateHasNewFeature = () => {
        let token = document.querySelector("[name='csrf-token']").content;
        fetch(`/update_user_has_new_feature`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setTimeout(() => {
                this.setState({ hasNewFeature: data.register });
            }, 90000);
        });
    }

    render() {
        return (
            <React.Fragment>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" onClick={() => this.setState({ current_tab: "links" })}>
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#homeCardLayout" role="tab" aria-controls="home" aria-selected="true">
                            {isMobile ? ('Favoritos'):('Accesos Rápidos')}
                        </a>
                    </li>

                    <li className="nav-item" onClick={() => this.setState({ current_tab: "tasks" })}>
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profileCardLayout" role="tab" aria-controls="profile" aria-selected="false">Tareas</a>
                    </li>

                    {isMobile && 
                        <li className="nav-item" onClick={() => this.setState({ current_tab: "changes" })}>
                            <a className={`nav-link ${this.state.hasNewFeature ? 'ui-taskIndexCard-navlink-red':'ui-taskIndexCard-navlink-inherit'}`} id="changes-tab" data-toggle="tab" href="#changesCardLayout" role="tab" aria-controls="changes" aria-selected="false">Novedades</a>
                        </li>
                    }
                </ul>

                <div className="tab-content" id="myTabContent">
                    {(this.state.current_tab == "links" && (this.props.favorite ? this.props.favorite : true)) && (
                        <div className={`tab-pane fade ${this.state.current_tab == "links" ? 'show active' : null}`} id="homeCardLayout" role="tabpanel" aria-labelledby="home-tab">
                            <IndexList />
                        </div>  
                    )}
                    
                    {this.state.current_tab == "tasks" && (
                        <div className={`tab-pane fade ${this.state.current_tab == "tasks" ? 'show active' : null}`}id="profileCardLayout" role="tabpanel" aria-labelledby="profile-tab">
                            <Index
                                users={this.props.users}
                                user={this.props.user}
                                procesos={this.props.procesos}
                                task_boards={this.props.task_boards}
                                module_task={this.state.current_tab}

                                company={this.props.company}
                                imageUpload={this.props.imageUpload}
                                key_text={this.props.key_text}
                            />
                        </div>
                    )}

                    {(isMobile && this.state.current_tab == "changes") && (
                        <div className={`tab-pane fade ${this.state.current_tab == "changes" ? 'show active' : null}`} id="changesCardLayout" role="tabpanel" aria-labelledby="changes-tab">                        
                            <SoftwareChangeIndex 
                                changes={this.props.changes}
                                date={this.props.date}
                            />
                        </div>
                    )}
                </div>
                
            </React.Fragment>
        );
    }
}


export default TaskIndexCard;
WebpackerReact.setup({ TaskIndexCard });
