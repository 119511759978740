import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label>Tipo</label>
                            <select 
                                name="addon_type"
                                className={`form form-control`}
                                onChange={this.props.onChangeForm}
                                value={this.props.formValues.addon_type}
                            >
                                <option value="">Seleccione una opcion</option>
                                <option value="usuarios">Usuarios</option> 
                                <option value="almacenamiento">Almacenamiento</option> 
                            </select>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label>Valor</label>
                            <input 
                                type="number" 
                                name="value" 
                                className={`form form-control`}
                                onChange={this.props.onChangeForm}
                                value={this.props.formValues.value}
                            />
                        </div>

                        <div className="col-md-12 text-right">
                            <button
                                className="btn btn-outline-danger mr-2"
                                onClick={() => this.props.toggle()}
                            >
                                Cancelar
                            </button>

                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.props.submitForm()}
                            >
                                {this.props.nameBnt}
                            </button>

                            <hr />
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;