import React, { Component } from 'react';
import Preloader from '../../../../GeneralComponents/LoadingComponen'
import FormCreate from './FormCreate'
import Swal from "sweetalert2/dist/sweetalert2.js";

class IndexColumn extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modeEdit: false,
            isLoaded: true,
            id: "",
            form: {
                title: "",
                position: "",
            }
        }
    }

    clearValues = () => {
        this.setState({
            id: "",
            modeEdit: false,
            form: {
                title: "",
                position: "",
            }
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/task/get_columns`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.test);
            this.setState({
                isLoaded: false,
                data: data.data,
            });
        });
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (column) => {
        this.setState({
            data: this.state.data.map(item => {
            if (column.id === item.id) {
              return { ...item, 
                title: column.title,
                position: column.position,
              }
            }
            return item;
          })
        });
    }

    HandleClick = () => {
        this.setState({ isLoaded: true })
        if(!this.state.modeEdit)
            fetch(`/task/columns`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false, isLoaded: false })
                this.updateData(data.register)
                this.clearValues();
            });
        else{
            fetch(`/task/columns/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false, isLoaded: false })
                this.updateItem(data.register)
                this.clearValues();
            });
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                //this.props.updateStateLoadColors(true)
                fetch(`/task/columns/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };

    edit = (column) => {
        this.setState({
            modeEdit: true,
            modal: true,
            id: column.id,
            form: {
                title: column.title,
                position: column.position,
            }
        })
    }


    handleChangeComplete = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar columna" : "Crear columna"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.handleChangeComplete}
                        submitForm={this.HandleClick}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Añadir"}
                    />
                )}

                <div className="card">
                    <div className="card-header">
                        <div className="row w-100">
                            <div className="col-md-10">
                                Columnas de tareas
                            </div>

                            <div className="col-md-2 pr-0 text-right">
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.toogle("new")}
                                >
                                    Añadir
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            {!this.state.isLoaded ? (
                                this.state.data.map((column, index) => (
                                    <React.Fragment>
                                        <div className={`col-md-3`}>
                                            <div className="card">
                                                <div className="card-header" style={{ background: "#3f6ad8", color: "#ffff" }}>
                                                    {column.title}
                                                </div>

                                                <div className="card-body">
                                                    
                                                </div>
                                                
                                                <div className="card-footer">
                                                    <button
                                                        className="btn-shadow btn btn-info mr-2"
                                                        onClick={() => this.edit(column)}
                                                    >
                                                        Editar
                                                    </button>

                                                    <button
                                                        className="btn-shadow btn btn-danger"
                                                        onClick={() => this.delete(column.id)}
                                                    >
                                                        Eliminar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))
                            ) : (
                                <div className="col-md-12 text-center">
                                    <Preloader />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default IndexColumn;
