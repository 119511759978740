import React, { Component } from 'react';
import Select from "react-select";
import Preloader from '../../../GeneralComponents/Preloaders';
import MultipleDateSelect from './multipleDateSelect';

class FormFilter extends Component {
    constructor(props){
        super(props)
        this.state = {
            isFiltering: false,
            showMessage: false,
            isLoaded: false,
            url: "",
            
            formFilter: {
                firstDate: {
                    date1: "",
                    date2: "",
                },

                secondDate: {
                    date1: "",
                    date2: "",
                },

                name: "",
                state: "",
                description: "",
                gestor_user_id: "",
                user_id: "",
                task_label_id: [],
                name_module: [],
                user_owner_id: "",
                count_task: "",
                state_task: "",
                date_state: "",
                label_name: "",
                procesos_ids: [],
                task_board_ids: [],
            },

            selectedOption: {
                gestor_user_id: "",
                label: "Gestores"
            },

            selectedOptionEtiquetas: {
                task_label_id: "",
                label: "Etiqueta"
            },

            selectedOptionUserOwner: {
                user_owner_id: "",
                label: "Responsable"
            },

            selectedOptionUser: {
                user_id: "",
                label: "Dueño de la tarea"
            },

            selectedOptionTasks: {
                count_task: "",
                label: "Número de tarea"
            },

            selectedOptionCargos: {
                procesos_ids: "",
                label: "Cargo"
            },

            selectedOptionTaskSources: {
                task_source_ids: "",
                label: "Módulo"
            },

            selectedOptionTaskBoard: {
                task_board_ids: [],
                label: "Módulo"
            },

            labels: [],
            users: [],
            tasks: [],
            cargos: [],
            task_sources: [],
        }
    }

    clearValues = () => {
        this.setState({
            formFilter: {
                firstDate: {
                    date1: null,
                    date2: null,
                },

                secondDate: {
                    date1: null,
                    date2: null,
                },

                name: "",
                state: "",
                description: "",
                gestor_user_id: "",
                user_id: "",
                task_label_id: [],
                name_module: [],
                user_owner_id: "",
                count_task: "",
                state_task: "",
                date_state: "",
                label_name: "",
                procesos_ids: [],
                task_board_ids: [],
            },

            selectedOption: {
                user_id: "",
                label: "Gestores"
            },

            selectedOptionUserOwner: {
                user_owner_id: "",
                label: "Responsable"
            },

            selectedOptionEtiquetas: {
                task_label_id: "",
                label: "Etiqueta"
            },

            selectedOptionTasks: {
                count_task: "",
                label: "Número de tarea"
            },

            selectedOptionCargos: {
                procesos_ids: "",
                label: "Cargo"
            },

            selectedOptionUser: {
                user_id: "",
                label: "Dueño de la tarea"
            },

            selectedOptionTaskBoard: {
                task_board_ids: [],
                label: "Módulo"
            },
        })
    }

    handleChangeAutocompleteTaskSources = selectedOptionTaskSources => {
        let array = []

        if(selectedOptionTaskSources){
            selectedOptionTaskSources.map((item) => (
                array.push(item.value)
            ))
        }
    
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                name_module: selectedOptionTaskSources ? array : [],
            }
        })
    }


    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
                formFilter: {
                    ...this.state.formFilter,
                    gestor_user_id: selectedOption.value
                }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    };

    handleChangeAutocompleteUserOwner = selectedOptionUserOwner => {
        this.setState({
            selectedOptionUserOwner,
              formFilter: {
                  ...this.state.formFilter,
                  user_owner_id: selectedOptionUserOwner.value
              }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    handleChangeAutocompleteUser = selectedOptionUser => {
        this.setState({
            selectedOptionUser,
              formFilter: {
                  ...this.state.formFilter,
                  user_id: selectedOptionUser.value
              }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    handleChangeAutocompleteSeachTask = selectedOptionTasks => {
        this.setState({
            selectedOptionTasks,
              formFilter: {
                  ...this.state.formFilter,
                  count_task: selectedOptionTasks.value
              }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    handleChangeAutocompleteEtiquetas = selectedOptionEtiquetas => {
        let array = []

        selectedOptionEtiquetas.map((item) => (
          array.push(item.value)
        ))

        this.setState({
            formFilter: {
                ...this.state.formFilter,
                task_label_id: array
            }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    handleChangeAutocompleteTaskBoard = selectedOptionTaskBoard => {
        let array = []

        if (selectedOptionTaskBoard){
            selectedOptionTaskBoard.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            formFilter: {
                ...this.state.formFilter,
                task_board_ids: selectedOptionTaskBoard ? array : [],
            }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    handleChangeAutocompleteCargos = selectedOptionCargos => {
        let array = []

        if (selectedOptionCargos){
            selectedOptionCargos.map((item) => (
                array.push(item.value)
            ))
        }
    
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                procesos_ids: selectedOptionCargos ? array : [],
            }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    }

    usersSelect = () => {
        let arrayLabels = [];
        let arrayTasks = [];
        let arrayCargos = [];
        let arrayTaskSources = [];


        this.props.labels.map((item) => (
            arrayLabels.push({label: item.name, value: item.id})
        ))

        this.props.tasks.map((item) => (
            arrayTasks.push({label: item.count, value: item.id})
        ))

        this.props.procesos.map((item) => (
            arrayCargos.push({label: item.name, value: item.id})
        ))

        this.props.task_sources.map((item) => (
            arrayTaskSources.push({label: item.name, value: item.id})
        ))

        this.setState({
            labels: arrayLabels,
            tasks: arrayTasks,
            cargos: arrayCargos,
            task_sources: arrayTaskSources,
        })
    }

    HandleClickFilter = () => {        
        if (this.state.formFilter.firstDate.date1 != "" || this.state.formFilter.firstDate.date2 != "" || this.state.formFilter.secondDate.date1 != "" || this.state.formFilter.secondDate.date2 != "" || this.state.formFilter.fechaHasta != "" || this.state.formFilter.name != "" || this.state.formFilter.description != "" || this.state.formFilter.gestor_user_id != "" || this.state.formFilter.task_label_id != "" || this.state.formFilter.name_module != "" || this.state.formFilter.user_owner_id != "" || this.state.formFilter.count_task != "" || this.state.formFilter.procesos_ids.length >= 1 || this.state.formFilter.user_id != "" || this.state.formFilter.state != "" || this.state.formFilter.state_task != "" || this.state.formFilter.task_board_ids != "" || this.state.formFilter.date_state != "" || this.state.formFilter.label_name != ""){
            this.setState({ isLoaded: true })
            const url = `${this.props.url}?createdDate1=${this.state.formFilter.firstDate.date1}&createdDate2=${this.state.formFilter.firstDate.date2}&dueDate1=${this.state.formFilter.secondDate.date1}&dueDate2=${this.state.formFilter.secondDate.date2}&name=${this.state.formFilter.name}&description=${this.state.formFilter.description}&gestor_user_id=${this.state.formFilter.gestor_user_id}&task_label_id=${this.state.formFilter.task_label_id}&name_module=${this.state.formFilter.name_module}&user_owner_id=${this.state.formFilter.user_owner_id}&count_task=${this.state.formFilter.count_task}&procesos=${this.state.formFilter.procesos_ids}&user_id=${this.state.formFilter.user_id}&state=${this.state.formFilter.state}&state_task=${this.state.formFilter.state_task}&task_board_ids=${this.state.formFilter.task_board_ids}&date_state=${this.state.formFilter.date_state}&label_name=${this.state.formFilter.label_name}&filtering=${this.props.isFiltering}`
            if(this.props.urlFilter){
                this.props.urlFilter(url)
            }

            fetch(url, {
                method: 'GET', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
              .then(response => response.json())
              .then(data => {
                    this.props.updateDataFilter(data)
                    this.props.updateStateIsLoaded(false)
                    this.setState({ showMessage: false, isLoaded: false })
                    if (this.props.scrollRef.current) {
                        this.props.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
            });
        }else{
            this.setState({ showMessage: true })
        }
    };    

    componentDidMount(){
        this.usersSelect();
    }

    handleChangeFilter = e => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        }, () => {
            if (this.props.getStateFilter != undefined){
                this.props.getStateFilter(this.state)
            }
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
    }

    handleChangeFirstDate1 = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                firstDate:{
                    ...this.state.formFilter.firstDate,
                    date1: e.target.value
                }
            }
        })
    }

    handleChangeSecodDate1 = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                firstDate:{
                    ...this.state.formFilter.firstDate,
                    date2: e.target.value
                }
            }
        })
    }

    handleChangeFirstDate2 = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                secondDate:{
                    ...this.state.formFilter.secondDate,
                    date1: e.target.value
                }
            }
        })
    }

    handleChangeSecodDate2 = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                secondDate:{
                    ...this.state.formFilter.secondDate,
                    date2: e.target.value
                }
            }
        })
    }

    render() {
        
        return (
            <React.Fragment>
                <div className="card mb-3" ref={this.props.scrollRef}>
                    <form onSubmit={this.handleSubmit}>
                        <div className={`card-body ${this.state.showMessage ? "pb-0" : null}`}>
                            <div className="row">

                                <MultipleDateSelect
                                    title={"Fecha de creación"}
                                    target="date1"
                                    value={this.state.formFilter.firstDate}
                                    handleChangeFirstDate={this.handleChangeFirstDate1}
                                    handleChangeSecodDate={this.handleChangeSecodDate1}
                                />

                                <MultipleDateSelect
                                    title={"Fecha de vencimiento"}
                                    target="date2"
                                    value={this.state.formFilter.secondDate}
                                    handleChangeFirstDate={this.handleChangeFirstDate2}
                                    handleChangeSecodDate={this.handleChangeSecodDate2}
                                />

                                {/*
                                <div className="col-md-3">
                                    <label>Fecha desde</label>
                                    <input 
                                        type="date"
                                        name="fechaDesde"
                                        className="form form-control"
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.fechaDesde}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label>Fecha hasta</label>
                                    <input 
                                        type="date"
                                        name="fechaHasta"
                                        className="form form-control"
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.fechaHasta}
                                    />
                                </div>*/}

                                {false && (
                                    <div className="col-md-3">
                                        <input
                                            type="hidden"
                                            name="gestor_user_id"
                                            value={this.state.selectedOption.gestor_user_id}
                                        />
                                        <label>Gestor</label>
                                        <Select
                                            onChange={this.handleChangeAutocomplete}
                                            options={this.props.users}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOption}
                                        />
                                    </div>
                                )}

                                <div className="col-md-3">
                                    <label>Nombre de la tarea</label>
                                    <input 
                                        type="text"
                                        name="name"
                                        className="form form-control"
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.name}
                                        placeholder="Nombre de la tarea"
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label>Descripción</label>
                                    <input 
                                        type="text"
                                        name="description"
                                        className="form form-control"
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.description}
                                        placeholder="Descripción"
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    {!this.props.task_board ? (
                                        <React.Fragment>
                                            <input
                                                type="hidden"
                                                name="task_board_ids"
                                                value={this.state.selectedOptionTaskBoard.task_board_ids}
                                            />
                                            <label>Tableros de tareas</label>
                                            <Select
                                                onChange={this.handleChangeAutocompleteTaskBoard}
                                                options={this.props.task_boards}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                autoFocus={false}
                                                className={`link-form`}
                                                classNamePrefix="select"
                                                name="task_board_ids"
                                                placeholder="Seleccione"
                                            />
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <input
                                                type="hidden"
                                                name="task_label_id"
                                                value={this.state.selectedOptionEtiquetas.task_label_id}
                                            />
                                            <label>Etiquetas</label>
                                            <Select
                                                onChange={this.handleChangeAutocompleteEtiquetas}
                                                options={this.state.labels}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                autoFocus={false}
                                                className={`link-form`}
                                                classNamePrefix="select"
                                                name="task_label_id"
                                                placeholder="Seleccione"
                                            />
                                        </React.Fragment>
                                    )}
                                </div>

                                <div className="col-md-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="task_source_ids"
                                        value={this.state.selectedOptionTaskSources.task_source_ids}
                                    />
                                    <label>Módulo</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteTaskSources}
                                        options={this.state.task_sources}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        autoFocus={false}
                                        className={`link-form`}
                                        classNamePrefix="select"
                                        name="task_source_ids"
                                        placeholder="Seleccione"
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="user_owner_id"
                                        value={this.state.selectedOptionUserOwner.user_owner_id}
                                    />
                                    <label>Responsable</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteUserOwner}
                                        options={this.props.users}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.state.selectedOptionUserOwner}
                                    />
                                </div>

                                {true && (
                                    <div className="col-md-3 mt-3">
                                        <input
                                            type="hidden"
                                            name="count_task"
                                            value={this.state.selectedOptionTasks.count_task}
                                        />
                                        <label>Número de tarea</label>
                                        <Select
                                            onChange={this.handleChangeAutocompleteSeachTask}
                                            options={this.state.tasks}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOptionTasks}
                                        />
                                    </div>
                                )}

                                <div className="col-md-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="procesos_ids"
                                        value={this.state.selectedOptionCargos.procesos_ids}
                                    />
                                    <label>Procesos</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteCargos}
                                        options={this.state.cargos}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        autoFocus={false}
                                        className={`link-form`}
                                        classNamePrefix="select"
                                        name="procesos_ids"
                                        placeholder="Seleccione"
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <input
                                        type="hidden"
                                        name="user_id"
                                        value={this.state.selectedOptionUser.user_id}
                                    />
                                    <label>Dueño de tarea</label>
                                    <Select
                                        onChange={this.handleChangeAutocompleteUser}
                                        options={this.props.users}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.state.selectedOptionUser}
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <label>Estados de fecha</label>
                                    <select 
                                        name="date_state"
                                        className='form form-control'
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.date_state}
                                    >
                                        <option value="">Seleccione un estado</option>
                                        <option value="vencidas">Vencidas</option>
                                        <option value="proximas">Próximas a vencer</option>
                                        <option value="vigentes">Vigentes</option>
                                    </select>
                                </div>

                                <div className="col-md-3 mt-3">
                                    <label>Nombre de la etiqueta</label>
                                    <input 
                                        type="text"
                                        name="label_name"
                                        className="form form-control"
                                        onChange={this.handleChangeFilter}
                                        value={this.state.formFilter.label_name}
                                        placeholder="Nombre de la tarea"
                                        autoComplete='off'
                                    />
                                </div>
                                

                                {this.state.showMessage && (
                                    <div className="col-md-12 mt-3 text-center">
                                        <div className="alert alert-primary" role="alert">
                                            <b>No ha seleccionado una opción válida</b>
                                        </div>
                                    </div>
                                )}
                                
                            </div>
                        </div>

                        <div className="card-footer">
                            <button
                                className="btn-shadow btn btn-info  mr-3"
                                onClick={() => this.HandleClickFilter()}
                                disabled={this.state.isLoaded}
                                style={{ display: "flex" }}
                            >
                                {this.state.isLoaded ? (
                                    <React.Fragment> 
                                        {"Cargando... "} <div className="ml-2"> <Preloader /> </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment> {"Aplicar Filtro"} </React.Fragment>
                                )}
                            </button>

                            <a
                                className="btn btn-outline-danger"
                                onClick={() => this.props.cancelFilter()}
                            >
                                Cancelar
                            </a>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default FormFilter;