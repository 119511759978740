import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import QrScan from 'react-qr-reader'

class ModalReadQr extends Component {
     handleError = err => {
        console.error(err)
        }
        
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    {/*<ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>*/}
                    <ModalBody>
                        <div className="row">
                            <div
                                style={{
                                    margin: 'auto',
                                    width: '400px'
                                }}
                            >
                                {this.props.modalQrActive && (

                                    <QrScan
                                        delay={300}
                                        onError={this.handleError}
                                        onScan={this.props.onResult}
                                       
                                    />
                                )}
                                {!this.props.modalQrActive && (
                                    <div>
                                        {this.props.modalQrData.data.map(dataParent => (
                                            <div>
                                                <div className='alert alert-success mb-4 mt-4'>{dataParent.message}</div>
                                                {dataParent.data.map(data => (
                                                    <div style={{ display: "flex", gap: "5px" }}>
                                                        <span> <b>{data.question}</b></span> :
                                                        <span>{data.value}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}

                                        <button className='btn btn-primary mt-4 btn-block' onClick={() => this.props.actvieQr()}>Leer nuevo</button>
                                    </div>
                                )}

                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalReadQr;
