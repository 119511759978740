import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

class FormUpdate extends Component {
    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        const thumbsContainer = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 16
        };
          
        const thumb = {
            display: 'inline-flex',
            borderRadius: 2,
            border: '1px solid #eaeaea',
            marginBottom: 8,
            marginRight: 8,
            width: '100%',
            height: 100,
            padding: 4,
            justifyContent: 'center',
            boxSizing: 'border-box'            
        };
          
        const thumbInner = {
            display: 'flex',
            with: '100%',
            overflow: 'hidden'
        };
          
        const img = {
            display: 'block',
            width: 'auto',
            height: '100%'
        };

          
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className={`col-md-${this.props.tenant.app_type == "Salud" ? "6" : "12"}`}>
                                        <label>Nombre de la empresa</label>
                                        <input
                                            type="text"
                                            name="company_name"
                                            value={this.props.formValues.company_name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control`}
                                            placeholder="Nombre de la empresa "
                                        />
                                    </div>

                                    {this.props.tenant.app_type == "Salud" && (
                                        <div className="col-md-6">
                                            <label>Licencia Sanitaria</label>
                                            <input
                                                type="text"
                                                name="nit"
                                                value={this.props.formValues.nit}
                                                onChange={this.props.onChangeForm}
                                                className={`form form-control`}
                                                placeholder="Licencia Sanitaria"
                                            />
                                        </div>
                                    )}

                                    <div className={`col-md-12 mt-3`}>
                                        <label>Sitio web</label>
                                        <input
                                            type="text"
                                            name="web"
                                            value={this.props.formValues.web}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control`}
                                        />
                                    </div>

                                    <div className={`col-md-12 mt-3`}>
                                        <label>Url para el beetre</label>
                                        <input
                                            type="text"
                                            name="url_name"
                                            value={this.props.formValues.url_name}
                                            onChange={this.props.onChangeUrlBeetre}
                                            className={`form form-control`}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <label>Logo</label>
                                        <Dropzone
                                            onDrop={(files) => this.props.handleFile(files)}
                                            accept="image/*"
                                           
                                        >
                                            {({getRootProps, getInputProps}) => (
                                                <div
                                                    {...getRootProps({
                                                        className: 'dropzone',
                                                    })}
                                                >
                                                    <input {...getInputProps()} />
                                                    <p>{`${(this.props.nameFile != "" ? this.props.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                </div>
                                            )}
                                        </Dropzone>

                                        <aside style={thumbsContainer}>
                                            {this.props.file.length >= 1 && (
                                                this.props.file.map(file => (
                                                    <div style={thumb} key={file.name}>
                                                        <div style={thumbInner}>
                                                            <img
                                                                src={URL.createObjectURL(file)}
                                                                style={img}
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </aside>
                                    </div>


                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" disabled={this.props.isLoading} onClick={this.props.submitForm}>{this.props.isLoading ? "Cargando..." : "Actualizar"}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormUpdate;