import React, { useState, useEffect } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

const FormCreateChange = (props) => {
    const handleSubmit = e => {
        e.preventDefault();
    };

    const config = {
        language: 'es',
        quickInsertEnabled: false,
        colorsHEXInput: true,
        autoFocus: true,
        toolbartop: true,
        linkAlwaysBlank: true,
        fontFamilySelection: true,
        fontSizeSelection: true,
        paragraphFormatSelection: true,
        htmlExecuteScripts: true,
        key: props.key_text,
        // imageUploadToS3: this.props.imageUpload,
        iframe: true,
        tabSpaces: 4,
        pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
        toolbarButtons: {
            moreText: {
                buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                // Alignment of the group in the toolbar.
                align: 'left',

                // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                buttonsVisible: 12
            },


            moreParagraph: {
                buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                align: 'left',
                buttonsVisible: 11
            },

            moreRich: {
                buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                align: 'left',
                buttonsVisible: 10
            },

            moreMisc: {
                buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                align: 'right',
                buttonsVisible: 9
            }
        }
    }

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered modal-lg" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <div className="ui-formCreate-change-container ">
                            <div className="ui-formCreate-change-input-title">
                                <label>Codigo</label>
                                <input 
                                    type="text" 
                                    name='code' 
                                    className={`form form-control`}
                                    value={props.formValues.code}
                                    onChange={props.onChangeForm} 
                                />
                            </div>

                            <div className="ui-formCreate-change-input-description">
                                <label>Descripción</label>
                                <textarea
                                    name='description' 
                                    className={`form form-control`}
                                    value={props.formValues.description}
                                    onChange={props.onChangeForm} 
                                    rows="10"
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                        <button className="btn-shadow btn btn-info" onClick={props.submitForm}>{props.nameBnt}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </React.Fragment>
      )

}
export default FormCreateChange;