import React, { Component } from 'react';
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';

class FormCreateModal extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            ErrorValues: true,
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">


                                    <div className={`col-md-${this.props.selectColumn ? "6" : "12"} mb-4 ui-formCreateModal__container-calendar-responsable`}>
                                        <label>Responsable de la tarea</label>
                                        <input
                                            type="hidden"
                                            name="user_owner_id"
                                            value={this.props.selectedOption.user_owner_id}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocomplete}
                                            options={this.props.users}
                                            autoFocus={false}
                                            className={`link-form ${this.props.ErrorValues == false && this.props.form.user_owner_id == "" ? "error-class" : ""}`}
                                            value={this.props.selectedOption}
                                        />
                                    </div>


                                    {this.props.selectColumn && (
                                        <React.Fragment>
                                            {!this.props.task_board && (
                                                <div className="col-md-6 mb-4">
                                                    <label>Tablero de tarea</label>
                                                    <input
                                                        type="hidden"
                                                        name="column_id"
                                                        value={this.props.selectedOptionTaskBoard.task_board_id}
                                                    />

                                                    <Select
                                                        onChange={this.props.handleChangeAutocompleteTaskBoard}
                                                        options={this.props.task_boards}
                                                        autoFocus={false}
                                                        className={`link-form ${this.props.ErrorValues == false && this.props.form.column_id == "" ? "error-class" : ""}`}
                                                        value={this.props.selectedOptionTaskBoard}
                                                    />
                                                </div>
                                            )}

                                            {this.props.columns.length >= 1 && ( 
                                                <div className={`col-md-${!this.props.task_board ? "12" : "6"} mb-4`}>
                                                    <label>Elige la columna para asignar la tarea</label>
                                                    <input
                                                        type="hidden"
                                                        name="column_id"
                                                        value={this.props.selectedOptionColumn.column_id}
                                                    />

                                                    <Select
                                                        onChange={this.props.handleChangeAutocompleteColumn}
                                                        options={this.props.columns}
                                                        autoFocus={false}
                                                        className={`link-form ${this.props.ErrorValues == false && this.props.form.column_id == "" ? "error-class" : ""}`}
                                                        value={this.props.selectedOptionColumn}
                                                    />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}

                                    {this.props.selectDate && (
                                        <div className="col-md-12 mb-4">
                                            <label>Fecha de la tarea</label>
                                            <input
                                                type="date"
                                                name="due_date"
                                                value={this.props.form.due_date}
                                                onChange={this.props.HandleChange}
                                                className={`form form-control ${this.props.errorValues == false && this.props.form.due_date == "" ? "error-class" : ""}`}
                                            />
                                        </div>
                                    )}
                                    
                                    <div className="col-md-12">
                                        <textarea 
                                            className={`form form-control style-text ${this.props.ErrorValues == false && this.props.form.name == "" ? "error-class" : ""}`}
                                            name="name"
                                            onChange={this.props.HandleChange}
                                            value={this.props.form.name}
                                            placeholder="Introduzca un título para esta tarjeta..."
                                        >
                                        </textarea>
                                    </div>

                                    <div className="col-md-12  mt-2 mb-2">
                                        <div className="ui-form-task-notifications_words_responsables__container">
                                            <div className="ui-form-task-notifications__container_responsables">
                                                <div className="custom-control custom-switch">
                                                    <UncontrolledTooltip target='bw-notification-responsable_task' placement='top'>NOTIFICAR AL RESPONSABLE</UncontrolledTooltip>
                                                    <input type="checkbox" onChange={(e) => this.props.handleChangeSwitch(!this.props.form.notify, "notify")} className="custom-control-input" id={`customSwitch${1}`} checked={this.props.form.notify} />
                                                    <label className="custom-control-label" htmlFor={`customSwitch${1}`}><i className="fas fa-bell" id='bw-notification-responsable_task'></i></label>
                                                </div>
                                            </div>

                                            <div className="ui-form-task-notifications_alert__container">
                                                <span className="ui-form-taskCard-count-length" style={{ color: (this.props.form.name.length + 0 >= 200 ? "red" : "#3f6ad8") }}>
                                                    Caracteres restantes: {200 - this.props.form.name.length}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {this.props.form.name.length >= 200 && (
                                        <div className="col-md-12 mt-3">
                                            <div className="alert alert-primary" role="alert">
                                                <b>Si quieres ampliar la información debes hacerlo en la descripción</b>
                                            </div>
                                        </div>
                                    )}

                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button 
                                    className="btn-shadow btn btn-primary mr-3" 
                                    onClick={() => this.props.HandleClickCreateTask()}
                                >
                                    {this.state.ErrorValues == false ? "Complete los campos" : "Crear" }
                                </button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreateModal;