import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: true,
            modeEdit: false,
            type_search: false,
            ErrorValues: true,
            question_custom_formula: {},
            id: "",

            form: {
                question_id: this.props.question.id, 
                operation: "",
                question_type: "",
                question_first_id: "",
                question_second_id: "",
            },

            selectedOptionQuestion: {
                label: "Selecione una pregunta",
                question_first_id: "",
            },

            selectedOption: {
                label: "Selecione una pregunta",
                question_second_id: ""
            },

            questions: [],
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
                if (format.id === item.id) {
                    return {
                        ...item,
                        format_source: format.format_source,
                        question_compare: format.question_compare,
                        question_compare_source: format.question_compare_source,
                        question_to_get: format.question_to_get,
                        type_search: format.type_search,
                        operation: format.operation,
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            ErrorValues: true,

            form: {
                ...this.state.form,
                operation: "",
                question_type: "",
                question_first_id: "",
                question_second_id: "",
            },

            selectedOptionQuestion: {
                label: "Selecione una pregunta",
                question_first_id: "",
            },

            selectedOption: {
                label: "Selecione un formato",
                question_second_id: ""
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_custom_formulas/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.clearValues();
                        this.setState({ question_custom_formula: {} })
                        this.messageSuccess(response)
                    });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
        this.loadDataSelects();
    }

    updateForm = (register) => {
        this.setState({
            form: {
                ...this.state.form,
                operation: register.operation,
                question_type: register.question_type,
                question_first_id: register.question_first.id,
                question_second_id: register.question_second.id,
            },

            selectedOptionQuestion: {
                label: register.question_first.question,
                question_first_id: register.question_first.id,
            },

            selectedOption: {
                label: register.question_second.question,
                question_second_id: register.question_second.id,
            },

            question_custom_formula: register,
        })
    }

    loadData = () => {
        fetch(`/formatos/get_question_custom_formula/${this.props.question.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            if(Object.keys(data.data).length != 0){
                this.updateForm(data.data);
                this.setState({
                    isLoaded: false
                });
            }else{
            
                this.setState({
                    question_custom_formula: {},
                    isLoaded: false
                });
            }
        });
    }

    loadDataSelects = () => {
        fetch(`/formatos/data_questions/${this.props.formato.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            let array = []

            data.steps_questions.map((item) => (
                array.push({ label: item.question, value: item.id })
            ))

            this.setState({
                questions: array,
                modal: true
            });
        });
    }

    validationForm = () => {
        if (this.state.form.operation != "" &&
            this.state.form.question_type != "" &&
            this.state.form.question_first_id != "" &&
            this.state.form.question_second_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.validationForm()) {
            if (Object.keys(this.state.question_custom_formula).length != 0) {
                fetch(`/formatos/question_custom_formulas/${this.state.question_custom_formula.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateForm(data.register);
                    });
            } else {
                fetch(`/formatos/question_custom_formulas`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.updateForm(data.register);
                });
            }
        }
    }


    edit = (question_custom_formula) => {
        this.setState({
            modal: true,
            modeEdit: true,
            id: question_custom_formula.id,

            form: {
                ...this.state.form,
                operation: question_custom_formula.operation,
                question_type: question_custom_formula.question_type,
                question_first_id: question_custom_formula.question_first.id,
                question_second_id: question_custom_formula.question_second.id,
            },

            selectedOptionQuestion: {
                label: question_custom_formula.question_first.question,
                question_first_id: question_custom_formula.question_first.id,
            },

            selectedOption: {
                label: question_custom_formula.question_second.question,
                question_second_id: question_custom_formula.question_second.id,
            },
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                question_second_id: selectedOption.value
            }
        });
    };

    handleChangeAutocompleteQuestions = selectedOptionQuestion => {
        this.setState({
            selectedOptionQuestion,
            form: {
                ...this.state.form,
                question_first_id: selectedOptionQuestion.value
            }
        });
    };

    getQuestionType = (question_type) => {
        if(question_type == "date"){
            return "Fecha"
        }else{
            return "Fecha Hora"
        }
    }

    getOperation = (operation) => {
        if(operation == "resta"){
            return "Resta"
        }else{
            return "Resta de hoy"
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>
                        <div className='ui-modals-header-title__container'>
                            <span className='ui-titles_modals'>{this.props.title}</span>
                            <span className='ui-name-question-modals'>Pregunta {this.props.question.question}</span> 
                        </div>
                    </ModalHeader>

                    <ModalBody>

                        {this.state.modal && (
                            <FormCreate
                                backdrop={"static"}
                                modal={this.state.modal}
                                toggle={this.toogle}
                                title={this.state.modeEdit ? "Actualizar asociación" : "Crear asociación"}
                                nameBnt={Object.keys(this.state.question_custom_formula).length != 0 ? "Actualizar" : "Crear"}

                                //form props
                                formValues={this.state.form}
                                onChangeForm={this.HandleChange}
                                submitForm={this.HandleClick}
                                errorValues={this.state.ErrorValues}

                                //select values
                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                selectedOption={this.state.selectedOption}
                                formatos={this.props.formatos}

                                //select questions 
                                handleChangeAutocompleteQuestions={this.handleChangeAutocompleteQuestions}
                                selectedOptionQuestion={this.state.selectedOptionQuestion}
                                questions={this.state.questions}
                            />
                        )}

                        <div className="col-md-12 mb-3 text-right pr-0">
                            {false && (
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.loadDataSelects()}
                                >
                                    Abrir formulario
                                </button>
                            )}
                        </div>

                        <div className="card">
                            <div className="background-gray">
                                <table className="table " id="sampleTable">
                                    <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                        <tr>
                                            <th>Tipo de pregunta</th>
                                            <th>Primera pregunta</th>
                                            <th>Segunda pregunta</th>
                                            <th>Operación</th>
                                            <th className="text-right">Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {Object.keys(this.state.question_custom_formula).length != 0 ? (
                                            <tr key={this.state.question_custom_formula.id} className="tr-table-task" style={{ borderLeft: `4px solid green` }}>
                                                <td>{this.getQuestionType(this.state.question_custom_formula.question_type)}</td>
                                                <td>{this.state.question_custom_formula.question_first.question}</td>
                                                <td>{this.state.question_custom_formula.question_second.question}</td>
                                                <td>{this.getOperation(this.state.question_custom_formula.operation)}</td>
                                                <td className="text-right">
                                                    {true && (
                                                        <UncontrolledDropdown className='btn-group'>
                                                            <DropdownToggle className='btn-shadow btn btn-info'>
                                                                <i className="fas fa-bars"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                {false && (
                                                                    <DropdownItem
                                                                        className="dropdown-item"
                                                                        onClick={() => this.edit(this.state.question_custom_formula)}
                                                                    >
                                                                        Editar
                                                                    </DropdownItem>
                                                                )}

                                                                {true && (
                                                                    <DropdownItem
                                                                        onClick={() => this.delete(this.state.question_custom_formula.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </DropdownItem>
                                                                )}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    )}
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;