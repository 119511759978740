import React, { Component } from 'react';
import LineChartIndicator from "../../../../GeneralComponents/Charts/LineChartIndicator"
import LineChartIndicatorMonth from "../../../../GeneralComponents/Charts/LineChartIndicatorMonth"
import NumberFormat from "react-number-format";

class layoutGeneralIndicator extends Component {

    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            day: (new Date().getDate()) - 1 <= 0 ? new Date().getDate() : new Date().getDate() - 1,

            data: [],
            formUpdate: [],
            formUpdateMonth: [],
            formUpdateHorizontal: [],
            formUpdateHorizontalMonth: [],
            matrix: [],
            index: 500,
            id: "",
            indicator_id: "",
            indexMonth: 500,
            indexHorizontal: 500,
            indexHorizontalMonth: 500,
            dataLine: [],
            dataLineAccumulated: [],
            dataLineMonth: [],
            dataLineMonthAccumulated: [],
            dataLineRealValues: [],
            dataToday: [],
            dataMonth: [],
            todayMonth: "",
            todayDay: this.date(),
            showDataLineRealValues: false,
            showDataLineMonth: true,
            ErrorValues: true


        }
    }

    componentWillReceiveProps(nextProps) {
        console.log("recive procps loayot")
        if (this.props !== nextProps) {
            this.renderAgain(nextProps)
        }

    }

    renderAgain = (nextProps) => {

        this.dataLine(nextProps.data)
        this.dataLineMonth(nextProps.data)
        this.dataLineRealValues(nextProps.data)

        let month = nextProps.indicator.table_type == "mensual" ? (nextProps.month - 1) : nextProps.month
        month = month < 0 ? 0 : month
        if ((new Date().getDate()) - 2 > 1) {
            this.setState({

                data: nextProps.data,
                matrix: this.setMatrix(nextProps),
                todayMonth: this.getMonthText(nextProps.month),
                dataToday: [
                    nextProps.data[0].data_indicators[(new Date().getMonth())].data.data[((new Date().getDate()) - 2)].real,
                    nextProps.data[1].data_indicators[(new Date().getMonth())].data.data[((new Date().getDate()) - 2)].real,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].data.data[((new Date().getDate()) - 2)].percentege,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].percentege,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].target,
                    this.getPercentage(nextProps.data[2].data_indicators[(new Date().getMonth())].accumulated, nextProps.data[2].data_indicators[(new Date().getMonth())].target),
                    nextProps.indicator.variable_1,
                    nextProps.indicator.variable_2,


                ],
                dataMonth: [
                    nextProps.data[0].data_indicators[month].real,
                    nextProps.data[1].data_indicators[month].real,
                    nextProps.data[2].data_indicators[month].real,
                    nextProps.data[2].data_indicators[month].percentege,
                    nextProps.data[2].data_indicators[month].target,
                    this.getPercentage(nextProps.data[2].data_indicators[month].accumulated, nextProps.data[2].target_year),
                    nextProps.indicator.variable_1,
                    nextProps.indicator.variable_2,
                ]

            })
        }
        else {

            let year = new Date().getFullYear();
            let month = new Date().getMonth();
            let day = new Date().getDate() - 1;
            month = nextProps.indicator.table_type == "mensual" ? (nextProps.month - 1) : nextProps.month
            month = month < 0 ? 0 : month
            this.setState({

                data: nextProps.data,
                matrix: this.setMatrix(nextProps),
                todayMonth: this.getMonthText(nextProps.month),
                dataToday: [
                    nextProps.data[0].data_indicators[(new Date().getMonth())].data.data[day].real,
                    nextProps.data[1].data_indicators[(new Date().getMonth())].data.data[day].real,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].data.data[day].percentege,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].percentege,
                    nextProps.data[2].data_indicators[(new Date().getMonth())].target,
                    this.getPercentage(nextProps.data[2].data_indicators[(new Date().getMonth())].accumulated, nextProps.data[2].data_indicators[day].target),
                    nextProps.indicator.variable_1,
                    nextProps.indicator.variable_2,


                ],
                dataMonth: [
                    nextProps.data[0].data_indicators[month].real,
                    nextProps.data[1].data_indicators[month].real,
                    nextProps.data[2].data_indicators[month].real,
                    nextProps.data[2].data_indicators[month].percentege,
                    nextProps.data[2].data_indicators[month].target,
                    this.getPercentage(nextProps.data[2].data_indicators[month].accumulated, nextProps.data[2].target_year),
                    nextProps.indicator.variable_1,
                    nextProps.indicator.variable_2,
                ]

            })
        }
    }

    componentDidMount = () => {
        this.renderAgain(this.props)
    }

    date = (fecha) => {
        let day = ((new Date().getDate()) - 1);

        if ((new Date().getDate()) - 1 <= 0) {
            day = day + 1
        }
        let month = (new Date().getMonth());
        let year = (new Date().getFullYear());
        var d = new Date(year, month, day),
            months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[month] + " " + day + " " + 'del' + " " + year;
    }

    getMonthText = (fecha) => {
        let months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
        return months[fecha]
    }


    getPercentage = (val1, val2) => {
        let percentege = 0
        if (val1 != 0) {
            return percentege = val2 = !0 ? Math.round((val1 / val2) * 100, 0) : 0

        }
        else {
            return 0
        }

    }
    setMatrix = (nextProps) => {

        let matrix = []
        let vector = []
        nextProps.data.map((data) => {
            data.data_indicators[this.props.month].data.data.map((data, index) => {
                vector.push(data)
            })
            matrix.push(vector)
            vector = []
        })
        return matrix
    }


    setHorizontal = (index) => {

        let array = []
        this.state.data.filter((data) => data.total_column === false).map(data => {

            let getMonth = data.data_indicators[this.props.month].data.data[index]
            array.push(getMonth)


        })

        return array

    }

    setMonth = (index) => {

        console.log(index)
        let array = []
        console.log(this.state.data[index].data_indicators)
        let getMonth = [...this.state.data[index].data_indicators]
        getMonth.map(data => {

            let value = { real: data.real }
            array.push(value)


        })
        console.log(this.state.data[index].data_indicators)
        console.log(array)
        return array


    }
    setHorizontalMonth = (index) => {

        let array = []
        this.state.data.filter((data) => data.total_column === false).map(data => {

            let getMonth = { real: data.data_indicators[index].real, first_variable: data.data_indicators[index].first_variable, second_variable: data.data_indicators[index].second_variable }
            array.push(getMonth)


        })

        return array


    }



    updateFiels = (index, id, indicator_id) => {
        this.setState({
            index: index,
            indexHorizontal: 200,
            indexMonth: 200,
            indexHorizontalMonth: 200,
            id: id,
            indicator_id: indicator_id,
            formUpdate: this.state.matrix[index]
        })
    }

    updateFielsHorizontal = (index, id, indicator_id) => {
        this.setState({
            indexHorizontal: index,
            index: 200,
            indexMonth: 200,
            indexHorizontalMonth: 200,
            id: id,
            indicator_id: indicator_id,
            formUpdateHorizontal: this.setHorizontal(index)
        })
    }

    updateFielsMonth = (index, id, indicator_id) => {
        console.log("indexMOnth")
        this.setState({
            indexMonth: index,
            index: 200,
            indexHorizontal: 200,
            indexHorizontalMonth: 200,
            id: id,
            formUpdateMonth: this.setMonth(index)

        })
    }
    updateFielsHorizontalMonth = (index, id, indicator_id) => {
        this.setState({
            indexHorizontalMonth: index,
            index: 200,
            indexHorizontal: 200,
            indexMonth: 200,
            id: id,
            formUpdateHorizontalMonth: this.setHorizontalMonth(index)

        })
    }




    HandleChangeUpdate = value => (e) => {
        const values = this.state.formUpdate;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }


    HandleChangeUpdateHorizontal = value => (e) => {
        const values = this.state.formUpdateHorizontal;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }

    HandleChangeUpdateMonth = value => (e) => {
        const values = this.state.formUpdateMonth;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }

    HandleChangeUpdateHorizontalMonth = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }
    HandleChangeUpdateHorizontalMonthFirstVariable = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].first_variable = e.target.value;
        values[value].real = parseInt(e.target.value) + parseInt(values[value].second_variable);
        this.setState({
            values
        });
    }

    HandleChangeUpdateHorizontalMonthSecondVariable = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].second_variable = e.target.value;
        values[value].real = parseInt(e.target.value) + parseInt(values[value].first_variable);;
        this.setState({
            values
        });
    }






    HandleClickUpdate = () => {
        let data = { data: this.state.formUpdate }
        fetch(`/indicator/update_json_new/${this.state.id}/0/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260
                })
                /*               this.messageSuccess(data); */
            });
    }

    validationFormHorizontal = () => {

        if ((this.state.formUpdateHorizontal[0].real != "" || this.state.formUpdateHorizontal[0].real === 0) && (this.state.formUpdateHorizontal[1].real != "" || this.state.formUpdateHorizontal[1].real === 0)) {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }
    HandleClickUpdateHorizontal = () => {

        if (this.validationFormHorizontal()) {

            let data = { data: this.state.formUpdateHorizontal }
            fetch(`/indicator/update_json_horizontal/${this.state.data[0].indicator_id}/${this.state.indexHorizontal}/${this.props.month + 1}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    console.log("lñaskfjlañsfjs");
                    this.setState({
                        index: 260,
                        indexHorizontal: 260,
                        ErrorValues: true
                    })
                    /*               this.messageSuccess(data); */
                });
        }
    }

    HandleClickUpdateMonth = () => {
        let data = { data: this.state.formUpdateMonth }
        fetch(`/indicator/update_json_month/${this.state.id}/0/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260,
                    indexMonth: 260

                })
                /*               this.messageSuccess(data); */
            });
    }

    validationForm = () => {
        console.log("hola como estasmos adfasfadsfdasfdsafdsfadsafasdfdsaafasdfsfsadfdafsfsd2222")
        if ((this.state.formUpdateHorizontalMonth[0].real || this.state.formUpdateHorizontalMonth[0].real === 0) && (this.state.formUpdateHorizontalMonth[1].real || this.state.formUpdateHorizontalMonth[1].real === 0)) {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log(this.state.formUpdateHorizontalMonth[0].real);
            console.log(this.state.formUpdateHorizontalMonth[1].real);
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClickUpdateHorizontalMonth = () => {
        console.log("hola como estasmos adfasfadsfdasfdsafdsfadsafasdfdsaafasdfsfsadfdafsfsd")
        if (this.validationForm()) {

            let data = { data: this.state.formUpdateHorizontalMonth }
            fetch(`/indicator/update_json_month_horizontal/${this.state.data[0].indicator_id}/${this.state.indexHorizontalMonth}/${this.props.month + 1}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    console.log("lñaskfjlañsfjs");
                    this.setState({
                        index: 260,
                        indexHorizontal: 260,
                        indexMonth: 260,
                        indexHorizontalMonth: 260,
                        ErrorValues: true

                    })
                    /*               this.messageSuccess(data); */
                });
        }
    }


    dataLine = (nextProps) => {


        let value = this.props.month
        let target = nextProps[2].data_indicators[value].target
        let array = [['x', 'datos', { role: "annotation", type: "string" }, 'x'], [0, 0, "", target]]

        nextProps[2].data_indicators[value].data.data.map((data, index) => {

            let data_percent = data.percentege + "%"
            let data_percent_num = data.percentege
            if (data.real == 0) {
                data_percent = ""
            }
            if (data.real == 0) {
                data_percent_num = null
            }

            array.push([index + 1, data_percent_num, data_percent, target])



        })


        this.setState((state, props) => ({
            dataLine: array
        }));
    }

    dataLineMonth = (nextProps) => {

        let target = nextProps.target
        console.log(nextProps)
        console.log("jejejeejejejejejeejj")
        let array = [['x', 'datos', { role: "annotation", type: "string" }, 'x']]


        nextProps[2].data_indicators.map((data, index) => {

            let data_percent = data.percentege
            let data_percent_num = data.percentege + "%"

            if (data.real == 0) {
                data_percent_num = ""
            }
            if (data.real == 0 && data.month_text != "ENE") {
                data_percent = null
            }

            array.push([data.month_text, data_percent, data_percent_num, data.target])



        })
        this.setState((state, props) => ({
            dataLineMonth: array
        }));
    }


    dataLineRealValues = (nextProps) => {

        let target = nextProps.target
        console.log(nextProps)
        console.log("jejejeejejejejejeejj")

        let array = []

        if (true) {
            array = [['x', 'datos', { role: "annotation", type: "string" }, 'real']]

        } else {
            array = [['x', 'datos', { role: "annotation", type: "string" }]]
        }


        if (true) {

            nextProps[0].data_indicators.map((data, index) => {

                let data_target = data.real
                let data_target_num = this.props.indicator.total_type == "numerico" ? data.real : this.numberToCurrency(data.real)

                if (data.real == 0) {
                    data_target_num = ""
                }
                if (data.real == 0 && data.month_text != "ENE") {
                    data_target = null
                }

                let data_real_first = nextProps[1].data_indicators[index].real

                let data_real = data_real_first
                let data_real_num = this.props.indicator.total_type == "numerico" ? data_real_first : this.numberToCurrency(data_real_first)


                if (data_real == 0) {
                    data_real_num = ""
                }
                if (data_real == 0 && data.month_text != "ENE") {
                    data_real = null
                }



                array.push([data.month_text, data_target, data_target_num, data_real])



            })

        } else {

            nextProps[0].data_indicators.map((data, index) => {

                let data_target = data.real
                let data_target_num = data.real

                if (data.real == 0) {
                    data_target_num = ""
                }
                if (data.real == 0 && data.month_text != "ENE") {
                    data_target = null
                }

                array.push([data.month_text, data_target, data_target_num])



            })
        }







        this.setState((state, props) => ({
            dataLineRealValues: array
        }));
    }



    numberToCurrency = (amount) => {
        amount = amount ? amount : 0
        var thousandsSeparator = ","
        var currencyNum = "";
        var amountString = amount.toString();
        var digits = amountString.split("");

        var countDigits = digits.length;
        var revDigits = digits.reverse();

        for (var i = 0; i < countDigits; i++) {
            if ((i % 3 == 0) && (i != 0)) {
                currencyNum += thousandsSeparator + revDigits[i];
            } else {
                currencyNum += digits[i];
            }
        };

        var revCurrency = currencyNum.split("").reverse().join("");

        var finalCurrency = "$" + revCurrency;

        return finalCurrency;
    }

    getInput = (indexP, index, data) => {

        if (indexP == this.state.index) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdate[index].real}
                    onChange={this.HandleChangeUpdate(index)}
                    className={`form form-control ${this.state.ErrorValues == false && this.state.formUpdateHorizontal[index].real == "" ? "error-class" : ""}`}
                />)
        }
        else if (index == this.state.indexHorizontal) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdateHorizontal[indexP].real}
                    onChange={this.HandleChangeUpdateHorizontal(indexP)}
                    className={`form form-control ${this.state.ErrorValues == false && this.state.formUpdateHorizontal[indexP].real == "" ? "error-class" : ""}`}

                />)
        }
        else {
            if (this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined) {
                return (
                    <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data} /></p>
                )
            }
            else {
                return (
                    <p>{data}</p>
                )
            }

        }
    }

    getInputMonth = (indexP, index, data) => {
        console.log(index)
        console.log(indexP)
        if (indexP == this.state.indexMonth) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdateMonth[index].real}
                    onChange={this.HandleChangeUpdateMonth(index)}
                    className={`form form-control ${this.state.ErrorValues == false && this.state.formUpdateHorizontalMonth[index].real == "" ? "error-class" : ""}`}
                />)
        }
        else if (index == this.state.indexHorizontalMonth) {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        name="real"
                        value={this.state.formUpdateHorizontalMonth[indexP].real}
                        onChange={this.HandleChangeUpdateHorizontalMonth(indexP)}
                        className={`form form-control ${this.state.ErrorValues == false && this.state.formUpdateHorizontalMonth[indexP].real == "" ? "error-class" : ""}`}

                    />
                    {/*                     <input
                        type="text"
                        name="first_variable"
                        value={this.state.formUpdateHorizontalMonth[indexP].first_variable}
                        onChange={this.HandleChangeUpdateHorizontalMonthFirstVariable(indexP)}
                        className="form form-control"
                    />
                    <input
                        type="text"
                        name="second_variable"
                        value={this.state.formUpdateHorizontalMonth[indexP].second_variable}
                        onChange={this.HandleChangeUpdateHorizontalMonthSecondVariable(indexP)}
                        className="form form-control"
                    /> */}
                </React.Fragment>
            )
        }
        else {
            console.log(this.props.indicator.total_type)
            console.log("this.props.indicator.total_type")
            if (this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined) {
                return (
                    <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data} /></p>
                )
            }
            else {
                return (
                    <p>{data}</p>
                )
            }


        }
    }

    fshowDataLineMonth = () => {
        console.log("hola")
        this.setState({
            showDataLineMonth: true,
            showDataLineRealValues: false
        })

    }
    fshowDataLineRealValues = () => {
        console.log("hola1")

        this.setState({
            showDataLineMonth: false,
            showDataLineRealValues: true
        })

    }


    render() {
        console.log("entre en layou")
        console.log(this.state.data.length)
        console.log(this.props.data)
        if (this.props.data.length == 0) {
            return (
                <div></div>
            );
        }
        return (
            <React.Fragment>
                {(this.props.typeShow == "Day" && this.props.indicator.table_type != "mensual") && (
                    <div className="row indicators">
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-vertical-container">
                                        <div className="day">
                                            <div className="tr">Día</div>
                                            {this.props.data[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                                <div style={{ background: this.state.day == index + 1 && this.props.month == (new Date().getMonth()) ? "#3e69d8" : "whitesmoke" }} className="td td-info">
                                                    <p style={{ color: this.state.day == index + 1 && this.props.month == (new Date().getMonth()) ? "white" : "#1ca7f9" }} className="link-line" onClick={() => this.updateFielsHorizontal(index, data.id, data.indicator_id)}>{index + 1}</p>
                                                    {index == this.state.indexHorizontal && (
                                                        <div className="indicators-check-vertical">
                                                            <React.Fragment>
                                                                <i
                                                                    className="fas fa-check-circle true-btn"
                                                                    onClick={() => this.HandleClickUpdateHorizontal()}
                                                                    style={{ marginRight: "5px" }}
                                                                >
                                                                </i>
                                                                <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexHorizontal: 260, ErrorValues: true })}></i>
                                                            </React.Fragment>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            <div className="indicator-subtotal">Total</div>
                                        </div>
                                        {this.props.data.filter((data) => data.total_column === false).map((data, indexP) => (
                                            <div className="day">
                                                {indexP == this.state.index && (
                                                    <div className="indicators-checks">
                                                        <React.Fragment>
                                                            <i
                                                                className="fas fa-check-circle true-btn ml-1"
                                                                onClick={() => this.HandleClickUpdate()}
                                                                style={{ marginRight: "5px" }}
                                                            >
                                                            </i>
                                                            <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ index: 260, ErrorValues: true })}></i>
                                                        </React.Fragment>
                                                    </div>
                                                )}
                                                <div className="tr" /* onClick={() => this.updateFiels(indexP, data.id, data.indicator_id)} */>{data.name}</div>
                                                {data.data_indicators[this.props.month].data.data.map((data, index) => (
                                                    <div className="td">
                                                        {this.getInput(indexP, index, data.real)}


                                                    </div>
                                                ))}
                                                <div className="indicator-subtotal">

                                                    {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                        <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.data_indicators[this.props.month].real} />
                                                    ) : (
                                                            <p> {data.data_indicators[this.props.month].real}</p>
                                                        )}
                                                </div>
                                            </div>
                                        ))}
                                        <div className="day">
                                            <div className="tr" > % Cumplimiento</div>
                                            {this.props.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                                <div className="td">
                                                    <p>{data.percentege}%</p>


                                                </div>
                                            ))}
                                            <div className="indicator-subtotal">{this.props.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].percentege}%</div>
                                        </div>
                                        {/*                                         <div className="day">
                                            <div className="tr" >ACUMULADO</div>
                                            {this.props.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].data.data.map((data, index) => (
                                                <div className="td">

                                                    <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.real != 0 ? data.accumulated : 0} /></p>

                                                </div>
                                            ))}
                                            <div className="indicator-subtotal"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.props.data.filter((data) => data.total_column === true)[0].data_indicators[this.props.month].real} /></div>

                                        </div> */}
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-7">
                            <div className="card">
                                <div className="card-body show-values-indicator-ventas">
                                    <h4>{this.state.todayDay} <span style={{ fontSize: "12px", marginLeft: "5px" }}> {this.state.day == !1 ? "(Día Previo)" : "(Día Actual)"} </span> </h4>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-3">{this.state.dataToday[6]}</div>
                                        <div className="col-md-3">{this.state.dataToday[7]}</div>
                                        <div className="col-md-3">Cumplimiento</div>
                                        <div className="col-md-3">Meta</div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 value-venta">
                                            {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataToday[0]} />
                                            ) : (
                                                    <p> {this.state.dataToday[0]}</p>
                                                )}

                                        </div>
                                        <div className="col-md-3 value-venta">

                                            {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataToday[1]} />
                                            ) : (
                                                    <p> {this.state.dataToday[1]}</p>
                                                )}
                                        </div>
                                        <div className="col-md-3 value-venta">{this.state.dataToday[2]}%</div>
                                        <div className="col-md-3 value-venta">{this.state.dataToday[4]}%</div>

                                    </div>
                                    {/*                                     <hr />
                                    <div className="row">
                                        <div className="col-md-4">Acumulado</div>
                                        <div className="col-md-4">Presupuesto del mes</div>
                                        <div className="col-md-4">Cumplimiento</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 value-venta"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataToday[3]} /></div>
                                        <div className="col-md-4 value-venta"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataToday[4]} /></div>
                                        <div className="col-md-4 value-venta">{this.state.dataToday[5]}%</div>
                                    </div> */}
                                </div>
                            </div>
                            <br />

                            <div className="card">
                                <div className="card-body">
                                    {/*                                 <h5 style={{color:"#3e68d8"}}>Comportamiento ventas diarias</h5> 
 */}
                                    <LineChartIndicator data={this.state.dataLine} />
                                </div>
                            </div>

                        </div>
                    </div>
                )}
                {(this.props.typeShow == "Month" || this.props.indicator.table_type == "mensual") && (
                    <div className="row indicators">
                        <div className="col-md-5">


                            <div className="card">
                                <div className="card-body">

                                    <div className="table-vertical-container">
                                        <div className="day">
                                            <div className="tr">MES</div>
                                            {this.props.data[0].data_indicators.map((data, index) => (

                                                <div style={{ background: index == (new Date().getMonth()) ? "#3e69d8" : "whitesmoke" }} className="td td-info">
                                                    <div style={{ background: index == (new Date().getMonth()) ? "#3e69d8" : "whitesmoke", color: index == (new Date().getMonth()) ? "white" : "#1ca7f9" }} className="link-line" onClick={() => this.updateFielsHorizontalMonth(index, data.id, data.indicator_id)} >{data.month_text}</div>
                                                    {index == this.state.indexHorizontalMonth && (
                                                        <div className="indicators-check-vertical">
                                                            <React.Fragment>
                                                                <i
                                                                    className="fas fa-check-circle true-btn ml-1"
                                                                    onClick={() => this.HandleClickUpdateHorizontalMonth()}
                                                                    style={{ marginRight: "5px" }}
                                                                >
                                                                </i>
                                                                <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexHorizontalMonth: 260, ErrorValues: true })}></i>
                                                            </React.Fragment>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            <div className="indicator-subtotal">Total</div>

                                        </div>
                                        {this.props.data.filter((data) => data.total_column === false).map((data, indexPM) => (
                                            <div className="day">
                                                {indexPM == this.state.indexMonth && (
                                                    <div className="indicators-checks">

                                                        <React.Fragment>
                                                            <i
                                                                className="fas fa-check-circle true-btn ml-1"
                                                                onClick={() => this.HandleClickUpdateMonth()}
                                                                style={{ marginRight: "5px" }}
                                                            >
                                                            </i>
                                                            <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexMonth: 260, ErrorValues: true })}></i>
                                                        </React.Fragment>
                                                    </div>
                                                )}
                                                <div className="tr" /* onClick={() => this.updateFielsMonth(indexPM, data.id, data.indicator_id)} */>{data.name}</div>
                                                {data.data_indicators.map((data, indexPMC) => (
                                                    <div className="td">
                                                        {this.getInputMonth(indexPM, indexPMC, data.real)}

                                                    </div>
                                                ))}

                                                <div className="indicator-subtotal">
                                                    {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                        <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.accumulated} />
                                                    ) : (
                                                            <p>   {data.accumulated}</p>
                                                        )}

                                                </div>
                                            </div>
                                        ))}
                                        {true && (
                                            <div className="day">
                                                <div className="tr" >%</div>
                                                {this.props.data.filter((data) => data.total_column === true)[0].data_indicators.map((data, index) => (
                                                    <div className="td">
                                                        <p>{data.percentege}% </p>
                                                    </div>
                                                ))}
                                                <div className="indicator-subtotal">{this.props.data.filter((data) => data.total_column === true)[0].percentage}%</div>
                                            </div>
                                        )}
                                        {/*                                         <div className="day">
                                            <div className="tr" >ACUMULADO</div>
                                            {this.props.data.filter((data) => data.total_column === true)[0].data_indicators.map((data, index) => (
                                                <div className="td">

                                                    <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.real != 0 ? data.accumulated : 0} /></p>

                                                </div>
                                            ))}
                                            <div className="indicator-subtotal"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.props.data.filter((data) => data.total_column === true)[0].accumulated} />
                                            </div>
                                        </div> */}


                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-7">
                            <div className="card">
                                <div className="card-body show-values-indicator-ventas">
                                    <h4> {this.state.todayMonth}<span style={{ fontSize: "12px", marginLeft: "5px" }}> </span> </h4>
                                    <hr />
                                    <div className="row">

                                        <div className="col-md-3">{this.state.dataMonth[6]}</div>
                                        <div className="col-md-3">{this.state.dataMonth[7]}</div>
                                        <div className="col-md-3">% Cumplimiento</div>
                                        <div className="col-md-3">Meta</div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 value-venta">
                                            {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataMonth[0]} />
                                            ) : (
                                                    <p> {this.state.dataMonth[0]}</p>
                                                )}

                                        </div>
                                        <div className="col-md-3 value-venta">

                                            {this.props.indicator.total_type == "moneda" && this.props.indicator.total_type != undefined ? (
                                                <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataMonth[1]} />
                                            ) : (
                                                    <p> {this.state.dataMonth[1]}</p>
                                                )}
                                        </div>

                                        <div className="col-md-3 value-venta">{this.state.dataMonth[3]}% </div>
                                        <div className="col-md-3 value-venta">{this.state.dataMonth[4]}% </div>

                                    </div>
                                    {/*                                     <hr />
                                    <div className="row">
                                        <div className="col-md-4">Acumulado</div>
                                        <div className="col-md-4 ">Presupuesto del año</div>
                                        <div className="col-md-4">Cumplimiento</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 value-venta"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataMonth[3]} /></div>
                                        <div className="col-md-4 value-venta"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataMonth[4]} /></div>
                                        <div className="col-md-4 value-venta">{this.state.dataMonth[5]}%</div>
                                    </div> */}
                                </div>
                            </div>
                            <hr />
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-md-12 text-right">
                                        <button onClick={() => this.fshowDataLineMonth()} style={{ border: this.state.showDataLineMonth ? "3px solid #65bcf8" : "none" }} className="btn btn-primary mr-4">Cumplimiento</button>
                                        <button onClick={() => this.fshowDataLineRealValues()} style={{ border: this.state.showDataLineRealValues ? "3px solid #65bcf8" : "none" }} className="btn btn-primary">Variables </button>
                                    </div>
                                    <hr />
                                    <div style={{ display: this.state.showDataLineMonth ? "block" : "none" }}>
                                        <div>    <span className="mr-4 label-chart" style={{ background: "#3f6ad8", color: "white" }}>Cumplimiento</span> <span className="label-chart" style={{ background: "#dc3913", color: "white" }}>Esperado</span></div>
                                        <br />
                                        <LineChartIndicatorMonth data={this.state.dataLineMonth} maxValue={100} />
                                    </div>

                                    <div style={{ display: this.state.showDataLineRealValues ? "block" : "none" }}>
                                        <div> <span className="mr-4 label-chart" style={{ background: "#3f6ad8", color: "white" }}>{this.props.indicator.variable_1}</span> <span className="label-chart" style={{ background: "#dc3913", color: "white" }}>{this.props.indicator.variable_2}</span></div>
                                        <br />
                                        <LineChartIndicatorMonth data={this.state.dataLineRealValues} maxValue={50} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                )}


            </React.Fragment>
        );
    }
}

export default layoutGeneralIndicator;



