import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class TableIndexWeb extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card">
                    <div className="card-body">

                        <table className="table table-hover table-striped table-bordered table-fixed">
                            <thead>
                                <tr className="tr-table">
                                <th style={{ width: "3%" }}>#</th>
                                <th style={{ width: "20%" }}>Fecha</th>
                                <th style={{ width: "20%" }}>Lugar</th>
                                <th style={{ width: "20%" }}>Evento</th>
                                <th style={{ width: "35%" }}>Descripción</th>
                                <th style={{ width: "5%" }} className="text-center w-8" ></th>

                                </tr>
                            </thead>

                            <tbody>
                                {this.props.dataActions.length >= 1 ? (
                                this.props.dataActions.map(accion => (
                                    <tr key={accion.id}>
                                    <td >{accion.position}</td>
                                    <td >{this.props.dateTime(accion.event_date)}</td>
                                    <td >{accion.specific_area != undefined ? accion.specific_area.name : ""}</td>
                                    <td >{accion.related_event != undefined ? accion.related_event.name : ""}</td>
                                    <td >{accion.description}</td>

                                    <td className="text-right" style={{ width: "10px" }}>
                                        {(this.props.estados.editar || this.props.estados.eliminar) && (
                                            <UncontrolledDropdown className='btn-group'>
                                                <DropdownToggle className='btn btn-info'>
                                                    <i className="fas fa-bars"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                    {this.props.estados.editar && (
                                                        <DropdownItem
                                                            href={`/health/safety_notification/${accion.id}/edit`}
                                                            className="dropdown-item"
                                                            data-turbolinks='false'
                                                        >
                                                            Editar
                                                        </DropdownItem>
                                                    )}

                                                    {this.props.estados.eliminar && (
                                                        <DropdownItem
                                                            onClick={() => this.props.delete(accion.id)}
                                                            className="dropdown-item"
                                                        >
                                                            Eliminar
                                                        </DropdownItem>
                                                    )}

                                                    {true && (
                                                        <DropdownItem
                                                            href={`/health/safety_notification/${accion.id}`}
                                                            data-turbolinks="true"
                                                            className="dropdown-item"
                                                        >
                                                            Ver información
                                                        </DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}

                                    </td>

                                    </tr>
                                ))
                                ) : (
                                    <tr>
                                    <td colSpan="5" className="text-center">
                                        <div className="text-center mt-4 mb-4">

                                        <h4>No hay registros</h4>
                                        {true && (
                                            <a href="/customers/new" className="btn btn-primary" style={{ display: "none" }}>Nueva notificación</a>
                                        )}
                                        </div>
                                    </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default TableIndexWeb;