import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            questions: [],
            isLoaded: true
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/formatos/get_questions_formats/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    questions: data.data,
                    isLoaded: false
                });
            });
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <input
                                type="hidden"
                                name="format_source_id"
                                value={this.props.selectedOption.format_source_id}
                            />
                            <label>Formato del cual quiero duplicar los items</label>
                            <Select
                                onChange={this.props.handleChangeAutocomplete}
                                options={this.props.formatos}
                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.format_source_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOption}
                            />
                        </div>

                        {this.props.format_subform_sources.length >= 1 && (
                            <div className="col-md-6 mb-3">
                                <input
                                    type="hidden"
                                    name="format_subform_source_id"
                                    value={this.props.selectedOptionFormatSubFormSource.format_subform_source_id}
                                />
                                <label>Sub formularios del formato seleccionado</label>
                                <Select
                                    onChange={this.props.handleChangeAutocompleteFormatSubFormSource}
                                    options={this.props.format_subform_sources}
                                    autoFocus={false}
                                    className={`link-form ${!this.props.errorValues && this.props.formValues.format_subform_source_id == "" ? "error-class" : ""}`}
                                    value={this.props.selectedOptionFormatSubFormSource}
                                />
                            </div>
                        )}

                        <div className="col-md-6 mb-3">
                            <input
                                type="hidden"
                                name="question_asociation_id"
                                value={this.props.selectedOptionQuestion.question_asociation_id}
                            />
                            <label>Pregunta de la cual selecciono el registro que quiero duplicar</label>
                            <Select
                                onChange={this.props.handleChangeAutocompleteQuestions}
                                options={this.state.questions}
                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.question_asociation_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOptionQuestion}
                            />
                        </div>

                        {this.props.format_subform_targets.length >= 1 && (
                            <div className="col-md-6 mb-3">
                                <input
                                    type="hidden"
                                    name="format_subform_target_id"
                                    value={this.props.selectedOptionFormatSubFormTarget.format_subform_target_id}
                                />
                                <label>Subformulario al cual se le duplicaran los items</label>
                                <Select
                                    onChange={this.props.handleChangeAutocompleteFormatSubFormTarget}
                                    options={this.props.format_subform_targets}
                                    autoFocus={false}
                                    className={`link-form ${!this.props.errorValues && this.props.formValues.format_subform_target_id == "" ? "error-class" : ""}`}
                                    value={this.props.selectedOptionFormatSubFormTarget}
                                />
                            </div>
                        )}

                        {true && (
                            <div className="col-md-12">
                                <div class={`alert alert-${this.props.format_subform_targets.length >= 1 && this.props.there_is_format_subform_targets && this.props.format_subform_sources.length >= 1 && this.props.there_is_format_subform_sources ? "success" : "danger"} p-1`} role="alert">
                                    <div className='text-center mb-2'>
                                        <b>{this.props.format_subform_targets.length >= 1 && this.props.there_is_format_subform_targets && this.props.format_subform_sources.length >= 1 && this.props.there_is_format_subform_sources ? `Ya se completaron las condiciones para poder ${this.props.modeEdit ? "actualizar" : "crear"} el registro, ahora llena todos los campos para poder guardar` : `Para poder ${this.props.modeEdit ? "actualizar el" : "crear un"} registro se deben de completar las siguientes condiciones:`}</b>
                                    </div>

                                    {(this.props.format_subform_targets.length == 0 || this.props.there_is_format_subform_targets == false || this.props.format_subform_sources.length == 0 && this.props.there_is_format_subform_sources == false) && (
                                        <ul>
                                            <li className={this.props.format_subform_targets.length >= 1 && this.props.there_is_format_subform_targets ? "color-password" : "color-falsePassword"}>Este formato debe de tener al menos un registro en la asociación de formatos</li>
                                            <li className={this.props.format_subform_sources.length >= 1 && this.props.there_is_format_subform_sources ? "color-password" : "color-falsePassword"}>El formato que se selecciona en el campo 'formato' debe de tener al menos un registro en la asociación de formatos</li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="col-md-12 text-right">
                            <button
                                className="btn btn-outline-danger mr-2"
                                onClick={() => this.props.toggle()}
                            >
                                Cancelar
                            </button>

                            {(this.props.format_subform_targets.length >= 1 && this.props.there_is_format_subform_targets && this.props.format_subform_sources.length >= 1 && this.props.there_is_format_subform_sources && this.props.formValues.format_subform_source_id != "" && this.props.formValues.format_subform_target_id != "") && (
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.props.submitForm()}
                                >
                                    {this.props.nameBnt}
                                </button>
                            )}

                            <hr />
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;