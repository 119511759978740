import React, { Component } from 'react';
import Show from '../components/failures/Efects/Causes/Show'
import ShowMejora from '../components/risk/Risk/Mejora/ShowMejora'
import WebpackerReact from 'webpacker-react';

class CauseShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataTask: [],
            isLoadedTask: true,
        }
    }

    componentDidMount(){
        this.loadDataTask();
    }
    

    updateStateLoadTask = (state) => {
        this.setState({ isLoadedTask: state })
    }

    //add items
    updateData = (data) => {
        this.setState({
            dataTask: [...this.state.dataTask, data],
            isLoadedTask: false,
        })
    }

    //add update
    updateItem = task => {
        this.setState({
            dataTask: this.state.dataTask.map(item => {
            if (task.id === item.id) {
              return { ...item, 
                description: task.description,
                due_date: task.due_date,
                name: task.name,
                state: task.state,
                //user_owner: {document: "1037660678", document_type: "Cédula de"},
                general_comments: task.general_comments.length,
                general_files: task.general_files.length,
                task_labels: task.task_labels,
                users: task.task_labels,
              }
            }
            return item;
          })
        });
    }

    loadDataTask = () => {
        fetch(`/task/get_module_task/${this.props.cause.module_task_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataTask: data.data,
                isLoadedTask: false,
            });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Show
                    // task module
                    users={this.props.users}
                    user={this.props.user}
                    isLoadedTask={this.state.isLoadedTask}
                    updateStateLoad={this.updateStateLoadTask}
                    dataTask={this.state.dataTask}
                    loadDataTask={this.loadDataTask}
    
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    cause={this.props.cause}
                    task_source={this.props.task_source}
                    url={this.props.url}
                />
            </React.Fragment>
        );
    }
}


export default CauseShow;
WebpackerReact.setup({ CauseShow });

