import React, { useState, useEffect } from 'react';
import Preloader from '../../../../GeneralComponents/LoadingComponen';
import FormCreate from './FormCreate'
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormUpdate from './QuestionMatrixItem/FormUpdate';
import { UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledTooltip } from 'reactstrap';
import FormUpdateColumn from './FormUpdateColumn';
import IndexQuestionMatrixItemCodition from './QuestionMatrixItem/QuestionMatrixItemCodition/IndexQuestionMatrixItemCodition';
import Dropzone from "react-dropzone";

const QuestionTypeMatrix = (props) => {
    const token = document.querySelector("[name='csrf-token']").content;
    const [rows, setRow] = useState([]);
    const [columns, setColumn] = useState(["Columna", "Columna", "Columna", "Columna"]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [errorValueOptionColumn, setErrorValueOptionColumn] = useState(true);
    const [errorValueOptionUpdateColumn, setErrorValueOptionUpdateColumn] = useState(true);
    const [modalIndexQuestionMatrixItemConditional, setModalIndexQuestionMatrixItemConditional] = useState(false);
    const [disabledTextArea, setDisabledTextArea] = useState(true);

    const [formRow, setFormRow] = useState({ name: "" });
    const [questionMatrixItem, setQuestionMatrixItem] = useState({});
    const [formUpdateRow, setFormUpdateRow] = useState({ name: "", description: "", id: "" });
    const [formColumn, setFormColumn] = useState({ name: "" });
    const [formUpdateColumn, setFormUpdateColumn] = useState({ name: "", index: null })
    const [formUpdateMatrix, setFormUpdateMatrix] = useState({ type_question: "", has_commets: false, has_file: false, set_value: false, question_to_set_id: "", value_to_set: "", columns: [] });
    const showIcon = (props.questionMatrix.id == props.question.id ? true : false)
    const questionMatrix = props.question.question_matrix

    const handleClickUpdate = () => {
        fetch(`/formatos/question_matrices/${questionMatrix.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(formUpdateMatrix), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                setQuestionMatrix(data.register);
            });
    }

    const handleChangeUpdateSelect = (e) => {
        const form = { [e.target.name]: e.target.value }
        fetchUpdateQuestionMatrix(form)
    }

    const handleChangeRow = (e) => {
        setFormRow({ ...formRow, [e.target.name]: e.target.value })
    }

    const handleChangeColumn = (e) => {
        setErrorValueOptionColumn(true)
        setFormColumn({ ...formColumn, [e.target.name]: e.target.value })
    }

    const clearValues = (type) => {
        if (type == "column") {
            setFormColumn({ ...formColumn, name: "" })
            setErrorValueOptionColumn(true);
        } else {
            setFormRow({ ...formRow, name: "" })
        }
    }

    const validationFormColumn = () => {
        if (formUpdateColumn.index != null) {
            if (formUpdateColumn.name != "") {
                setErrorValueOptionColumn(true)
                return true
            } else {
                setErrorValueOptionColumn(false)
                return false
            }
        } else {
            if (formColumn.name != "") {
                setErrorValueOptionColumn(true)
                return true
            } else {
                setErrorValueOptionColumn(false)
                return false
            }
        }
    }

    const validationOptions = () => {
        if (compare(questionMatrix.columns, formColumn.name)) {
            setErrorValueOptionColumn(false)
            return false
        } else {
            setFormColumn({ ...formColumn, name: "" })
            setErrorValueOptionColumn(true)
            return true
        }
    }

    const validationUpdateColumn = () => {
        if (compare(questionMatrix.columns.filter((e, index) => index != formUpdateColumn.index), formUpdateColumn.name)) {
            setErrorValueOptionUpdateColumn(false)
            return false
        } else {
            setFormUpdateColumn({ name: "", index: null })
            setErrorValueOptionUpdateColumn(true)
            return true
        }
    }

    const createColumn = () => {
        if (validationOptions() && validationFormColumn()) {
            const value = formColumn.name
            const form = { columns: [...questionMatrix.columns, value] }
            fetchUpdateQuestionMatrix(form)
        }
    }

    const removeColumn = (index) => {
        const form = { columns: questionMatrix.columns.filter((e, indexFilter) => indexFilter != index) }
        fetchUpdateQuestionMatrix(form)
    }

    const handleClickUpdateQuestionMatrixItem = () => {
        const form = { name: formUpdateRow.name, description: formUpdateRow.description }
        fetchUpdateQuestionMatrixItem(formUpdateRow.id, form)
    }

    const destroy = (question_matrix_item_id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_matrix_items/${question_matrix_item_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        props.updateQuestionMatrix(props.question.step_id, props.question.id, response.register);
                    });
            }
        });
    };

    const fetchUpdateQuestionMatrix = (form) => {
        fetch(`/formatos/question_matrices/${questionMatrix.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                clearValues("column");
                props.updateQuestionMatrix(props.question.step_id, props.question.id, data.register);
            });
    }

    const fetchUpdateQuestionMatrixItem = (id, form) => {
        fetch(`/formatos/question_matrix_items/${id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                clearValuesEditRow();
                props.updateQuestionMatrix(props.question.step_id, props.question.id, data.register);
            });
    }

    const createRow = () => {
        const form = { name: formRow.name, question_matrix_id: questionMatrix.id }
        fetch(`/formatos/question_matrix_items`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                clearValues("rows");
                props.updateQuestionMatrix(props.question.step_id, props.question.id, data.register);
            });
    }

    const editColumn = (name, index) => {
        setFormUpdateColumn({ name: name, index: index })
    }

    const handleChangeUpdateColumn = (e) => {
        setFormUpdateColumn({ ...formUpdateColumn, [e.target.name]: e.target.value })
    }

    const handleClickUpdateColumn = () => {
        if (validationUpdateColumn() && validationFormColumn()) {
            const value = formUpdateColumn.name
            const form = {
                columns: questionMatrix.columns.map((item, index) => {
                    if (formUpdateColumn.index === index) {
                        return value
                    }
                    return item;
                })
            }
            fetchUpdateQuestionMatrix(form)
        }
    }

    const editRow = (question_matrix_item) => {
        setFormUpdateRow({ ...formUpdateRow, id: question_matrix_item.id, name: question_matrix_item.name, description: question_matrix_item.description })
    }

    const handleChangeUpdateRow = (e) => {
        setFormUpdateRow({ ...formUpdateRow, [e.target.name]: e.target.value })
    }

    const clearValuesEditRow = () => {
        setFormUpdateRow({ id: "", name: "", description: "" })
    }

    const crearValueEditOption = () => {
        setFormUpdateColumn({ name: "", index: null })
        setErrorValueOptionUpdateColumn(true)
    }

    const compare = (options, formName) => {
        var palabraMin1 = formName.toLowerCase()
        let is_valid = false

        options.forEach((element) => {
            var palabraMin2 = element.toLowerCase();

            var palabraSinAcentos1 = palabraMin1.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            var palabraSinAcentos2 = palabraMin2.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

            if (palabraSinAcentos1 === palabraSinAcentos2) {
                is_valid = true
            }
        });

        return is_valid
    }

    const updateBoolean = (state, field) => {
        const form = { [field]: state }
        fetchUpdateQuestionMatrix(form)
    }

    const selectQuestionMatrixItem = (question_matrix_item) => {
        setModalIndexQuestionMatrixItemConditional(true)
        setQuestionMatrixItem(question_matrix_item)
    }

    const toggle = () => {
        setModalIndexQuestionMatrixItemConditional(false)
        setQuestionMatrixItem({})
    }

    const fileQuestion = () => {
        
        return (
            <Dropzone
                onDrop={''}
            >
                {({ getRootProps, getInputProps }) => (
                    <div
                        {...getRootProps({
                            className: 'dropzone-matrix',
                        })}
                    >
                        <p class="dropzone-matrix-text" style={{ color: "#a5a5a5" }}> "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo"</p>
                    </div>
                )}
            </Dropzone>
        )
        
    }

    const isComments = () => {
        return(
            <textarea className='form form-control' type="text" disabled={disabledTextArea}/>
        )
    }

    const condictionActive = (has_comments, has_file) => {
        const classUse = 'ui-question-type_matrix-col_name_ban_only';
        if(has_comments && has_file){
            return 'ui-question-type_matrix-col_name';
        } else if (!has_comments && has_file) {
            return classUse;
        } else if (has_comments && !has_file) {
            return classUse;
        } else {
            return 'ui-question-type_matrix-col_name_ban';
        }
    }

    const condictionActiveFileComments = (has_comments, has_file) => {
        const classUse = 'ui-question-type-matrix-td-question_file_comments_ban_only';
        if(has_comments && has_file){
            return 'ui-question-type-matrix-td-question_file_comments';
        } else if (!has_comments && has_file) {
            return classUse;
        } else if (has_comments && !has_file) {
            return classUse;
        } else {
            return classUse;
        }
    }

    const condictionActiveFileCommentsSelect = (has_comments, has_file) => {
        const classUse = 'ui-question-type-matrix-td-question_select_file_comments_ban';
        if(has_comments && has_file){
            return 'ui-question-type-matrix-td-question_select_file_comments';
        } else if (!has_comments && has_file) {
            return classUse;
        } else if (has_comments && !has_file) {
            return classUse;
        } else {
            return 'ui-question-type-matrix-td-question_select';
        }
    }

    const renderView = () => {
        if (questionMatrix.type_question == "checkbox" || questionMatrix.type_question == "radio") {
            return (
                <React.Fragment>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {questionMatrix.columns.map((columna, index) => (
                                <React.Fragment>
                                    {showIcon && (
                                        <UncontrolledPopover isOpen={index == formUpdateColumn.index} placement="bottom" target={`PopoverColumn${index}`}>
                                            <PopoverHeader>Editar columna <a className="close-popover" onClick={() => crearValueEditOption()}> <i className="fas fa-times"></i></a></PopoverHeader>
                                            <PopoverBody>
                                                <FormUpdateColumn
                                                    formUpdateColumn={formUpdateColumn}
                                                    handleChangeUpdateColumn={handleChangeUpdateColumn}
                                                    handleClickUpdateColumn={handleClickUpdateColumn}
                                                    errorValueOptionUpdateColumn={errorValueOptionUpdateColumn}
                                                />
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    )}
                                    <UncontrolledTooltip target={`col${index}`} placement='top'>{columna}</UncontrolledTooltip>
                                    <th className={`text-center ${condictionActive(questionMatrix.has_commets, questionMatrix.has_file)}`} scope="col" key={index}>
                                        <div className='ui-question-type-check-radio__container_column'>
                                            <span className='ui-question-type-check-radio_columns_name' id={`col${index}`}>{columna} </span>
                                            {showIcon ? 
                                                (<div className='ui-question-type-matrix__container-icons'>
                                                        <i className="fas fa-pen mr-1 ml-1" onClick={() => editColumn(columna, index)} id={`PopoverColumn${index}`}></i> 
                                                        <i className="fas fa-trash-alt" onClick={() => removeColumn(index)}></i> 
                                                </div>
                                                ):( null)
                                            }
                                        </div>
                                    </th>
                                    
                                </React.Fragment>
                            ))}

                            {questionMatrix.has_file && (
                                <th className={condictionActiveFileComments(questionMatrix.has_commets, questionMatrix.has_file)} scope='col'>Evidencia</th>
                            )}
                            
                            {questionMatrix.has_commets && (
                                <th className={condictionActiveFileComments(questionMatrix.has_commets, questionMatrix.has_file)} scope='col'>Comentarios</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {questionMatrix.question_matrix_items.map((question_matrix_item, indexFila) => (
                           <>
                            <UncontrolledTooltip target={`row${indexFila}`} placement='top'>{question_matrix_item.name}</UncontrolledTooltip>
                            <UncontrolledTooltip target={`descriptionRow${indexFila}`} placement='right'>{question_matrix_item.description}</UncontrolledTooltip>
                            <tr style={{ background: question_matrix_item.id == questionMatrixItem.id ? "#27aaf566" : null }} className='ui-question-type-matrix-row__container'>
                                {showIcon && (
                                    <UncontrolledPopover isOpen={question_matrix_item.id == formUpdateRow.id} placement="bottom" target={`PopoverRow${question_matrix_item.id}`}>
                                        <PopoverHeader>Fila <a className="close-popover" onClick={() => clearValuesEditRow()}> <i className="fas fa-times"></i></a></PopoverHeader>
                                        <PopoverBody>
                                            <FormUpdate
                                                handleChangeUpdateRow={handleChangeUpdateRow}
                                                handleClickUpdate={handleClickUpdateQuestionMatrixItem}
                                                formUpdateRow={formUpdateRow}
                                            />
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                )}
                                
                                <td className='ui-question-type-matrix-names-row'>
                                    <span id={`row${indexFila}`}>{question_matrix_item.name}</span>
                                        {question_matrix_item.description !== "" && (
                                            <div className="ui-question-type-matrix-description-row" id={`descriptionRow${indexFila}`}>
                                                {question_matrix_item.description}
                                            </div>
                                        )}
                                    <div className='ui-question-type-matrix__container-icons'>
                                        {showIcon ? <React.Fragment> <i className="fas fa-pen mr-1 ml-1" onClick={() => editRow(question_matrix_item)} id={`PopoverRow${question_matrix_item.id}`}></i> <i className="fas fa-trash-alt" onClick={() => destroy(question_matrix_item.id)}></i> <i className="fas fa-cog" onClick={() => selectQuestionMatrixItem(question_matrix_item)}></i> </React.Fragment> : null} 
                                    </div>
                                 </td>                                 
                                {questionMatrix.columns.map((columna, index) => (
                                    <td className='text-center' key={index}>
                                        <input
                                            type={questionMatrix.type_question}
                                        />
                                    </td>
                                ))}
                                {questionMatrix.has_file && (
                                    <td className={condictionActiveFileComments(questionMatrix.has_commets, questionMatrix.has_file)}>
                                        {fileQuestion()}
                                    </td>
                                )}
                                {questionMatrix.has_commets && (
                                    <td className={condictionActiveFileComments(questionMatrix.has_commets, questionMatrix.has_file)}>
                                        {isComments()}
                                    </td>
                                )}
                            </tr>
                           </>
                        ))}
                    </tbody>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <thead>
                        <tr>
                            <th scope="col">Fila</th>
                            <th className='text-center' scope="col">Contenido</th>
                            {questionMatrix.has_file && (
                                <th className={condictionActiveFileCommentsSelect(questionMatrix.has_commets, questionMatrix.has_file)} scope='col'>Evidencia</th>
                            )}
                            {questionMatrix.has_commets && (
                                <th className={condictionActiveFileCommentsSelect(questionMatrix.has_commets, questionMatrix.has_file)} scope='col'>Comentarios</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {questionMatrix.question_matrix_items.map((question_matrix_item, index) => (
                            <React.Fragment>
                                <UncontrolledTooltip target={`row${index}`} placement='top'>{question_matrix_item.name}</UncontrolledTooltip>
                                <UncontrolledTooltip target={`descriptionRow${index}`} placement='right'>{question_matrix_item.description}</UncontrolledTooltip>
                                <tr className='ui-question-type-matrix-row__container'>
                                    {showIcon && (
                                        <UncontrolledPopover isOpen={question_matrix_item.id == formUpdateRow.id} placement="bottom" target={`PopoverRow${question_matrix_item.id}`}>
                                            <PopoverHeader>Fila <a className="close-popover" onClick={() => clearValuesEditRow()}> <i className="fas fa-times"></i></a></PopoverHeader>
                                            <PopoverBody>
                                                <FormUpdate
                                                    handleChangeUpdateRow={handleChangeUpdateRow}
                                                    handleClickUpdate={handleClickUpdateQuestionMatrixItem}
                                                    formUpdateRow={formUpdateRow}
                                                />
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    )}
                                    
                                    <td className='ui-question-type-matrix-names-row'>
                                        <span id={`row${index}`}>
                                            {question_matrix_item.name} 
                                        </span>                                        
                                        {question_matrix_item.description !== "" && (
                                            <div className="ui-question-type-matrix-description-row" id={`descriptionRow${index}`}>
                                                {question_matrix_item.description}
                                            </div>
                                        )}
                                        <div className='ui-question-type-matrix__container-icons'>
                                            {showIcon ? <React.Fragment> <i className="fas fa-pen mr-1 ml-1" onClick={() => editRow(question_matrix_item)} id={`PopoverRow${question_matrix_item.id}`}></i> <i className="fas fa-trash-alt" onClick={() => destroy(question_matrix_item.id)}></i> <i className="fas fa-cog" onClick={() => selectQuestionMatrixItem(question_matrix_item)}></i> </React.Fragment> : null}
                                        </div>
                                        
                                    </td>
                                    <td className={condictionActiveFileCommentsSelect(questionMatrix.has_commets, questionMatrix.has_file)}>
                                        <select
                                            name="type_question"
                                            className='form form-control'
                                            value={null}
                                            onChange={null}
                                        >
                                            <option value="">Seleccione de columna</option>

                                            {questionMatrix.columns.map((columna, index) => (
                                                <option value={columna} key={index}>{columna}</option>
                                            ))}
                                        </select>
                                    </td>
                                    {questionMatrix.has_file && (
                                        <td className={condictionActiveFileCommentsSelect(questionMatrix.has_commets, questionMatrix.has_file)}>
                                            {fileQuestion()}
                                        </td>
                                    )}
                                    {questionMatrix.has_commets && (
                                        <td className={condictionActiveFileCommentsSelect(questionMatrix.has_commets, questionMatrix.has_file)}>
                                            {isComments()}
                                        </td>
                                    )}
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </React.Fragment>
            )
        }
    }

    if (isLoaded) {
        return (
            <Preloader />
        )
    }

    return (
        <div>
            {showIcon && (
                <FormCreate
                    formRow={formRow}
                    formColumn={formColumn}
                    handleChangeColumn={handleChangeColumn}
                    handleChangeRow={handleChangeRow}
                    clearValues={clearValues}
                    questionMatrix={questionMatrix}
                    handleChangeUpdateSelect={handleChangeUpdateSelect}
                    createRow={createRow}
                    createColumn={createColumn}
                    errorValueOptionColumn={errorValueOptionColumn}
                    formUpdateColumn={formUpdateColumn}
                    updateBoolean={updateBoolean}
                    question={props.question}
                    updateQuestionMatrix={props.updateQuestionMatrix}
                />
            )}

            {modalIndexQuestionMatrixItemConditional && (
                <IndexQuestionMatrixItemCodition
                    title={`Condiciones para el item: ${questionMatrixItem.name}`}
                    backdrop={"static"}
                    modal={modalIndexQuestionMatrixItemConditional}
                    questionMatrixItem={questionMatrixItem}
                    columns={questionMatrix.columns}
                    toggle={toggle}
                />
            )}

            {(questionMatrix.columns.length >= 1 || questionMatrix.question_matrix_items.length >= 1) && (
                <table className="table">
                    {renderView()}
                </table>
            )}
        </div>
    );

}


export default QuestionTypeMatrix;

