import React, { Component } from 'react';
import Select from "react-select";
import { isMobile } from 'react-device-detect';

class IndexUsers extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            users: [],

            form:{
                user_ids: "",
            },

            selectedOption: {
                user_id: "",
                label: "Responsable"
            },
        }
    }

    clearValues = () => {
        this.setState({
            form:{
                user_ids: "",
            },

            selectedOption: {
                user_id: "",
                label: "Responsable"
            },
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
          selectedOption,
          form: {
             ...this.state.form,
             user_ids: selectedOption.value
          }
        });

        this.add_users(selectedOption.value)
    };

    add_users = (user) => {
        fetch(`/task/add_users/${this.props.task.id}/${user}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateDataUsers(data.register.users)
            this.clearValues();
        });
    }

    remove_user = (user) => {
        fetch(`/task/tasks_users_delete/${this.props.task.id}/${user}`, {
            method: "delete",
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateDataUsers(data.register.users)
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12" style={{ width: isMobile ? "" : "313px" }}>
                      <input
                        type="hidden"
                        name="user_id"
                        value={this.state.selectedOption.user_id}
                      />
                      <label>Responsables</label>
                      <Select
                        onChange={this.handleChangeAutocomplete}
                        options={this.props.users}
                        autoFocus={false}
                        className={`link-form`}
                        value={this.state.selectedOption}
                      />

                      <hr/>
                    </div>

                    <div className="col-md-12">
                        {(this.props.task != undefined && this.props.task.users != undefined) &&(
                            <React.Fragment>
                                {this.props.taskUsers.length >= 1 ? (
                                    this.props.taskUsers.map((user, index) => (
                                        <div key={user.id} className={`card ${index >= 1 ? "mt-2" : "" }`}>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <span className="ml-2">{user.first_name} {user.first_last_name}</span>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <i className="fas fa-trash-alt" style={{ cursor: "pointer" }} onClick={() => this.remove_user(user.id)}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <h6>No hay responsables</h6>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default IndexUsers;