import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import SectionIndex from './Index';
import SectionCategoryIndex from '../../settings/SectionCategory/Index';

class ConfigurationSection extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.childSection = React.createRef();
        this.childSectionCategory = React.createRef();

        this.state = {
            title: "Módulos Administrativos",
            titleNew: "Nuevo módulo",
            count: 1,
        }

    }

    setConfiguration = (number, title, titleNew) => {
        this.setState({
            title: title,
            titleNew: titleNew,
            count: number,
        })
    }

    render() {
        return (
            <React.Fragment>

                <HeaderTitle 
                    title={this.state.title} 
                    subTitle={"Gestión de Módulos"}
                    titleNew={this.state.titleNew}
                    titleFilter={"Filtros"}
                    icon={"fas fa-user-tie"}
                    onClickNew={this.childSection.current != undefined ? (this.state.count == 1 ? this.childSection.current.toogle : this.childSectionCategory.current.toogleModalCategory) : ""}
                    showNew={true}
                    showFilter={false}
                />

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className={`nav-link ${this.state.title == "Módulos Administrativos" ? `active` : ''}`} onClick={() => this.setConfiguration(1, "Módulos Administrativos", "Nuevo módulo")} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true">
                            Módulos Administrativos
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className={`nav-link ${this.state.title == "Categorías" ? `active` : ''}`} onClick={() => this.setConfiguration(2, "Categorías", "Nueva categoría")} id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false">
                            Categorías
                        </a>
                    </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                    <div className={`tab-pane fade ${this.state.title == "Módulos Administrativos" ? `show active` : ''}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                        <SectionIndex 
                            data={this.props.data}
                            loadData={this.props.loadData}
                            isLoaded={this.props.isLoaded}
                            updateStateLoad={this.props.updateStateLoad}
                            dataCategory={this.props.dataCategory}
                            ref={this.childSection} 
                        />
                    </div>

                    <div className={`tab-pane fade ${this.state.title == "Categorías" ? `show active` : ''}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <SectionCategoryIndex 
                            data={this.props.dataCategory}
                            updateDataCategory={this.props.updateDataCategory}
                            loadData={this.props.loadDataCategory}
                            updateItemCategory={this.props.updateItemCategory}
                            ref={this.childSectionCategory} 
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ConfigurationSection;