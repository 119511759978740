
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import Select from "react-select";

class Modalindex extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            form: {
                question_year_id: "",
                question_month_id: "",
                question_day_id: "",
                question_month_text_id: "",
                question_day_text_id: "",
                question_holiday_id: "",
                split_date: false,
                split_type: "",
            },

            selectedOptionYear: {
                question_year_id: "",
                label: "",
            },

            selectedOptionMonth: {
                question_month_id: "",
                label: "",
            },

            selectedOptionDay: {
                question_day_id: "",
                label: "",
            },
            selectedOptionMonthText: {
                question_month_text_id: "",
                label: "",
            },

            selectedOptionDayText: {
                question_day_text_id: "",
                label: "",
            },

            selectedOptionHoliday: {
                question_holiday_id: "",
                label: "",
            },

            questions: [],
        }
    }

    componentDidMount = () => {
        this.loadData();
        this.loadDataQuestions();
    }

    updateState = (configuration_question_date) => {
        this.setState({
            form: {
                question_year_id: configuration_question_date.question_year ? configuration_question_date.question_year.id : "",
                question_month_id: configuration_question_date.question_month ? configuration_question_date.question_month.id : "",
                question_day_id: configuration_question_date.question_day ? configuration_question_date.question_day.id : "",
                question_month_text_id: configuration_question_date.question_month_text ? configuration_question_date.question_month_text.id : "",
                question_day_text_id: configuration_question_date.question_day_text ? configuration_question_date.question_day_text.id : "",
                question_holiday_id: configuration_question_date.question_holiday ? configuration_question_date.question_holiday.id : "",
                split_date: configuration_question_date.split_date,
                split_type: configuration_question_date.split_type,
            },

            selectedOptionYear: {
                question_year_id: configuration_question_date.question_year ? configuration_question_date.question_year.id : "",
                label: configuration_question_date.question_year ? configuration_question_date.question_year.question : "",
            },

            selectedOptionMonth: {
                question_month_id: configuration_question_date.question_month ? configuration_question_date.question_month.id : "",
                label: configuration_question_date.question_month ? configuration_question_date.question_month.question : "",
            },

            selectedOptionDay: {
                question_day_id: configuration_question_date.question_day ? configuration_question_date.question_day.id : "",
                label: configuration_question_date.question_day ? configuration_question_date.question_day.question : "",
            },
            selectedOptionMonthText: {
                question_month_text_id: configuration_question_date.question_month_text ? configuration_question_date.question_month_text.id : "",
                label: configuration_question_date.question_month_text ? configuration_question_date.question_month_text.question : "",
            },

            selectedOptionDayText: {
                question_day_text_id: configuration_question_date.question_day_text ? configuration_question_date.question_day_text.id : "",
                label: configuration_question_date.question_day_text ? configuration_question_date.question_day_text.question : "",
            },

            selectedOptionHoliday: {
                question_holiday_id: configuration_question_date.question_holiday ? configuration_question_date.question_holiday.id : "",
                label: configuration_question_date.question_holiday ? configuration_question_date.question_holiday.question : "",
            },
        })
    }


    loadData = () => {
        fetch(`/formatos/get_configuration_question_date_register/${this.props.question.configuration_question_date.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.updateState(data.register);
                this.setState({ isLoaded: false });
            });
    }

    loadDataQuestions = () => {
        fetch(`/formatos/get_questions/${this.props.formato.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                let array = [];

                data.register.map((item) => {
                    if (item.question_type === "answer_string") {
                        array.push({ label: item.question, value: item.id, type: item.question_type, options_values: item.options })
                    }
                })

                this.setState({
                    questions: array
                })
            });
    }

    HandleClick = () => {
        fetch(`/formatos/update_state_config_question/${this.props.question.configuration_question_date.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.updateState(data.configuration_question_date);
                this.messageSuccess(data);
                this.props.toggle();
            });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    handleChangeAutocompleteYear = selectedOptionYear => {
        console.log("selectedOptionYear", selectedOptionYear);
        this.setState({
            selectedOptionYear,
            form: {
                ...this.state.form,
                question_year_id: selectedOptionYear.value
            }
        });
    }


    handleChangeAutocompleteMonthText = selectedOptionMonthText => {
        this.setState({
            selectedOptionMonthText,
            form: {
                ...this.state.form,
                question_month_text_id: selectedOptionMonthText.value
            }
        });
    }

    handleChangeAutocompleteDayText = selectedOptionDayText => {
        this.setState({
            selectedOptionDayText,
            form: {
                ...this.state.form,
                question_day_text_id: selectedOptionDayText.value
            }
        });
    }

    handleChangeAutocompleteMonth = selectedOptionMonth => {
        this.setState({
            selectedOptionMonth,
            form: {
                ...this.state.form,
                question_month_id: selectedOptionMonth.value
            }
        });
    }

    handleChangeAutocompleteDay = selectedOptionDay => {
        this.setState({
            selectedOptionDay,
            form: {
                ...this.state.form,
                question_day_id: selectedOptionDay.value
            }
        });
    }

    handleChangeAutocompleteHoliday = selectedOptionHoliday => {
        this.setState({
            selectedOptionHoliday,
            form: {
                ...this.state.form,
                question_holiday_id: selectedOptionHoliday.value
            }
        });
    }

    updateStateStep = (e) => {
        const isChecked = e.target.checked;
        this.setState({
            form: {
                ...this.state.form,
                split_date: isChecked
            }
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    {/*<ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>*/}

                    <ModalBody>
                        <div className="row">

                            <div className="col-md-12 mb-3">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" onChange={(e) => this.updateStateStep(e)} className="custom-control-input" id={`customGestion`} checked={this.state.form.split_date} />
                                    <label className="custom-control-label" htmlFor={`customGestion`}>¿Desglosar fecha?</label>
                                </div>
                            </div>

                            {this.state.form.split_date && (
                                <React.Fragment>
                                    <div className="col-md-4">
                                        <input
                                            type="hidden"
                                            name="question_year_id"
                                            value={this.state.selectedOptionYear.question_year_id}
                                        />
                                        <label>¿Año?</label>
                                        <Select
                                            onChange={this.handleChangeAutocompleteYear}
                                            options={this.state.questions}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOptionYear}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <input
                                            type="hidden"
                                            name="question_month_id"
                                            value={this.state.selectedOptionMonth.question_month_id}
                                        />
                                        <label>¿Mes?</label>
                                        <Select
                                            onChange={this.handleChangeAutocompleteMonth}
                                            options={this.state.questions}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOptionMonth}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <input
                                            type="hidden"
                                            name="question_month_text_id"
                                            value={this.state.selectedOptionMonth.question_month_text_id}
                                        />
                                        <label>¿Mes en texto?</label>
                                        <Select
                                            onChange={this.handleChangeAutocompleteMonthText}
                                            options={this.state.questions}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOptionMonthText}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <br />
                                    </div>

                                    <div className="col-md-4">
                                        <input
                                            type="hidden"
                                            name="question_day_id"
                                            value={this.state.selectedOptionDay.question_day_id}
                                        />
                                        <label>¿Dia?</label>
                                        <Select
                                            onChange={this.handleChangeAutocompleteDay}
                                            options={this.state.questions}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOptionDay}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <input
                                            type="hidden"
                                            name="question_day_text_id"
                                            value={this.state.selectedOptionDay.question_day_text_id}
                                        />
                                        <label>¿Dia en texto?</label>
                                        <Select
                                            onChange={this.handleChangeAutocompleteDayText}
                                            options={this.state.questions}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOptionDayText}
                                        />
                                    </div>


                                    <div className="col-md-4">
                                        <input
                                            type="hidden"
                                            name="question_holiday_id"
                                            value={this.state.selectedOptionHoliday.question_holiday_id}
                                        />
                                        <label>¿Es festivo?</label>
                                        <Select
                                            onChange={this.handleChangeAutocompleteHoliday}
                                            options={this.state.questions}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOptionHoliday}
                                        />
                                    </div>
                                </React.Fragment>
                            )}

{/*                             <div className="col-md-5 mt-3">
                                <label>¿Tipo de dato?</label>
                                <select
                                    name="split_type"
                                    value={this.state.form.split_type}
                                    onChange={this.HandleChange}
                                    className='form form-control'
                                >
                                    <option value="">Selecciona una opción</option>
                                    <option value="number">Numero</option>
                                    <option value="text">Text</option>
                                </select>
                            </div> */}
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                        <button className='btn-shadow btn btn-info' onClick={() => this.HandleClick()}>Actualizar</button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Modalindex;
