import React, { Component } from 'react';
import Table from '../components/failures/Efects/Index'
import WebpackerReact from 'webpacker-react';
import FormCreate from '../components/settings/FeaturePlan/FormCreate';
import Swal from "sweetalert2/dist/sweetalert2.js";

class FeaturePlanIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modeEdit: false,
            id: "",
            feature_plans: this.props.feature_plans,
            form: {
                name: "",
                plan_ids: [],
            },
        }
    }

    updateItem = feature_plan => {
        this.setState({
            feature_plans: this.state.feature_plans.map(item => {
            if (feature_plan.id === item.id) {
              return { ...item, 
                name: feature_plan.name,
                plans: feature_plan.plans,
              }
            }
            return item;
          })
        });
    }

    //add items
    updateData = (data) => {
        this.setState({
            feature_plans: [...this.state.feature_plans, data],
        })
    }

    handleChangeAccions = (feature_plan_id, plan_id) => {
        fetch(`/settings/include_plan/${feature_plan_id}/${plan_id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.updateItem(data.register)
        });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    clearValues = () => {
        let array = []

        this.props.plants.map((item) => (
            array.push(item.id)
        ))

        this.setState({
            modeEdit: false,
            id: "",

            form: {
                name: "",
                plan_ids: array,
            },
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleChangeCheckBox = (plan_id) => {
        if(this.state.form.plan_ids.includes(plan_id)){
            this.setState({
                form: {
                    ...this.state.form,
                    plan_ids: this.state.form.plan_ids.filter((e) => e != plan_id),
                },
            })
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    plan_ids: [...this.state.form.plan_ids, plan_id],
                },
            })
        }
    }

    HandleClick = () => {
        if (!this.state.modeEdit)
            fetch(`/settings/feature_plans`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false })
                this.messageSuccess(data);
                this.updateData(data.register);
                this.clearValues();
            });
        else {
            fetch(`/settings/feature_plans/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false })
                this.updateItem(data.register);
                this.messageSuccess(data);
                this.clearValues();
            });
        }
    }

    delete = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/feature_plans/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(response => response.json())
                .then(response => {
                    this.messageSuccess(response);
                    this.setState({
                        feature_plans: this.state.feature_plans.filter((e) => e.id != id),
                    })
                });
            }
        });
    };

    edit = (feature_plan) => {
        let array = []

        feature_plan.plans.map((item) => (
            array.push(item)
        ))

        this.setState({
            modeEdit: true,
            id: feature_plan.id,
            modal: true,
            form: {
                name: feature_plan.name,
                plan_ids: array,
            },
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Crear modulo"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}
                        plants={this.props.plants}
                        handleChangeCheckBox={this.HandleChangeCheckBox}
                    />
                )}

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="card">
                                <div className="card-header">
                                    <div className="col-md-9">
                                        Añadir mas
                                    </div>

                                    <div className="col-md-3 text-right">
                                        <button
                                            className="btn-shadow btn btn-info"
                                            onClick={() => this.toogle("new")}
                                        >
                                            Añadir registro
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "203px" }}>Características</th>
                                                {this.props.plants.map(( plant, index ) => (
                                                    <React.Fragment>
                                                        <th key={plant.id} className="text-center"><b>{plant.name}</b></th>
                                                        {this.props.plants.length == (index + 1) && (
                                                            <th className="text-center" style={{ width: "12%" }}>
                                                                Acciones
                                                            </th>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.feature_plans.length >= 1 ? (
                                                <React.Fragment>
                                                    {this.state.feature_plans.map(feature_plan => (
                                                        <tr key={feature_plan.id}>
                                                            <td>
                                                                {feature_plan.name}
                                                            </td>
                                                            
                                                            {this.props.plants.map((plan, index) => (
                                                                <React.Fragment>
                                                                    <td className="text-center">
                                                                        <input 
                                                                            type="checkbox" 
                                                                            value={plan.id}
                                                                            checked={feature_plan.plans.includes(plan.id)}
                                                                            onChange={() => this.handleChangeAccions(feature_plan.id, plan.id)}
                                                                        />
                                                                    </td>
                
                                                                    {this.props.plants.length == (index + 1) && (
                                                                        <td>
                                                                            <button
                                                                                className="btn"
                                                                                onClick={() => this.edit(feature_plan)}
                                                                            >
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </button>


                                                                            <button
                                                                                className="btn"
                                                                                onClick={() => this.delete(feature_plan.id)}
                                                                            >
                                                                                <i className="fas fa-trash-alt"></i>
                                                                            </button>
                                                                        </td>
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default FeaturePlanIndex;
WebpackerReact.setup({ FeaturePlanIndex });