import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { isMobile } from 'react-device-detect';


class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            format_questions: [],
            modal: false,
            modeEdit: false,
            ErrorValues: true,

            question_search_values_filter_id: "",

            form: {
                format_search_value_id: this.props.format_search_value.id, 
                question_source_id: "", 
                question_target_id: "",
                operator: ""
            },

            selectedOptionQuestion: {
                label: "Selecione una pregunta",
                question_source_id: "",
            },

            selectedOptionQuestionTarget: {
                label: "Selecione una pregunta",
                question_target_id: "",
            },
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
                if (format.id === item.id) {
                    return {
                        ...item,
                        question_target: format.question_target,
                        question_source: format.question_source,
                        operator: format.operator
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            form: {
                format_search_value_id: this.props.format_search_value.id, 
                question_source_id: "", 
                question_target_id: "",
                operator: ""
            },

            selectedOptionQuestion: {
                label: "Selecione una pregunta",
                question_source_id: "",
            },

            selectedOptionQuestionTarget: {
                label: "Selecione una pregunta",
                question_target_id: "",
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_search_values_filters/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
        this.loadDataQuestions();
    }

    loadData = () => {
        fetch(`/formatos/get_question_search_values_filters/${this.props.format_search_value.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    loadDataSelects = () => {
        fetch(`/formatos/data_questions/${this.props.formato.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            let array = []

            data.steps_questions.map((item) => (
                array.push({ label: item.question, value: item.id })
            ))

            this.setState({
                questions: array,
                modal: true
            });
        });
    }

    loadDataQuestions = () => {
        fetch(`/formatos/data_questions/${this.props.format_search_value.format_source.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            let array = []

            data.steps_questions.map((item) => (
                array.push({ label: item.question, value: item.id })
            ))

            this.setState({
                format_questions: array,
            });
        });
    }

    validationForm = () => {
        if (this.state.form.question_source_id != "" &&
            this.state.form.question_target_id != "" &&
            this.state.form.operator != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }


    HandleClick = () => {
        if (this.validationForm()) {
            if (this.state.modeEdit) {
                fetch(`/formatos/question_search_values_filters/${this.state.question_search_values_filter_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            } else {
                fetch(`/formatos/question_search_values_filters`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateData(data.register)
                        this.clearValues();
                        this.setState({ modal: false, showTable: true })
                    });
            }
        }
    }


    edit = (question_search_values_filter) => {
        this.setState({
            modal: true,
            modeEdit: true,

            question_search_values_filter_id: question_search_values_filter.id,

            form: {
                ...this.state.form,
                question_source_id: question_search_values_filter.question_source.id, 
                question_target_id: question_search_values_filter.question_target.id,
                operator: question_search_values_filter.operator
            },

            selectedOptionQuestion: {
                label: question_search_values_filter.question_source.question,
                question_source_id: question_search_values_filter.question_source.id,
            },

            selectedOptionQuestionTarget: {
                label: question_search_values_filter.question_target.question,
                question_target_id: question_search_values_filter.question_target.id,
            },
        })
    }

    handleChangeAutocompleteQuestionCompare = selectedOptionQuestionTarget => {
        this.setState({
            selectedOptionQuestionTarget,
            form: {
                ...this.state.form,
                question_target_id: selectedOptionQuestionTarget.value
            }
        });
    };

    handleChangeAutocompleteQuestions = selectedOptionQuestion => {
        this.setState({
            selectedOptionQuestion,
            form: {
                ...this.state.form,
                question_source_id: selectedOptionQuestion.value
            }
        });
    };

    selectQuestionSearchValuesFilter = (format_search_value_id) => {
        this.setState({
            formQuestionSearchValuesFilter: {
                format_search_value_id: format_search_value_id,
                question_source_id: "",
                question_target_id: "",
                operator: ""
            },
        })
    }

    getOperation = (operator) => {
        if (operator == "igual") {
            return "Igual"
        } else if (operator == "mayor_igual") {
            return "Mayor e igual"
        } else if (operator == "menor_igual") {
            return "Menor e igual"

        } else if (operator == "menor") {
            return "Menor"
        }
    }

    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <FormCreate
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar asociación" : "Crear asociación"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}

                        //select questions 
                        handleChangeAutocompleteQuestions={this.handleChangeAutocompleteQuestions}
                        selectedOptionQuestion={this.state.selectedOptionQuestion}
                        questions={this.state.questions}

                        //select question compare
                        handleChangeAutocompleteQuestionCompare={this.handleChangeAutocompleteQuestionCompare}
                        selectedOptionQuestionCompare={this.state.selectedOptionQuestionTarget}
                        format_questions={this.state.format_questions}
                    />
                )}

                {!isMobile ? (
                    <React.Fragment>
                        <div className="col-md-12 mb-3 text-right pr-0">
                            {false && (
                                <button
                                    className="btn-shadow btn btn-danger mr-3"
                                    onClick={() => this.props.selectQuestionSearchValuesFilter("")}
                                >
                                    Cerrar
                                </button>
                            )}

                            {!this.state.modal && (
                                <button
                                    className="btn-shadow btn btn-info mt-2"
                                    onClick={() => this.loadDataSelects()}
                                >
                                    Abrir formulario
                                </button>
                            )}
                        </div>
                        <div className="card">
                            <div className="background-gray">
                                <table className="table " id="sampleTable">
                                    <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                        <tr>
                                            <th>Pregunta fuente</th>
                                            <th>Operación</th>
                                            <th>Pregunta objetivo</th>
                                            <th className="text-right">Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map((question_search_values_filter, index) => (
                                                <React.Fragment>
                                                    {index >= 1 ? <div className="mb-2"></div> : null}
                                                    <tr key={question_search_values_filter.id} className="tr-table-task" style={{ borderLeft: `4px solid green` }}>
                                                        <td>{question_search_values_filter.question_source.question}</td>
                                                        <td>{this.getOperation(question_search_values_filter.operator)}</td>
                                                        <td>{question_search_values_filter.question_target.question}</td>
                                                        <td className="text-right">
                                                            {true && (
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(question_search_values_filter)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(question_search_values_filter.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}

                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                ):(
                    <React.Fragment>
                        <div className="col-md-12 mb-3 text-right pr-0 mt-3 mb-3">
                            {false && (
                                <button
                                    className="btn-shadow btn btn-danger mr-3"
                                    onClick={() => this.props.selectQuestionSearchValuesFilter("")}
                                >
                                    Cerrar
                                </button>
                            )}

                            {!this.state.modal && (
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.loadDataSelects()}
                                >
                                    Abrir formulario
                                </button>
                            )}
                        </div>

                        {this.state.data.length >= 1 ? (
                            this.state.data.map((question_search_values_filter, index) => (
                                <div className="card mt-4 ui-index-formarSearchValue-filter-card">
                                    <table>
                                        <React.Fragment>
                                            <thead>
                                                <tr className='ui-index-formatSearchValue__container-columns'>
                                                    <th className='ui-index-formatSearchValueFilter-title'>Pregunta fuente</th>
                                                    <th className='ui-index-formatSearchValueFilter-title'>Operación</th>
                                                    <th className='ui-index-formatSearchValueFilter-title'>Pregunta objetivo</th>
                                                    <th className='ui-index-formatSearchValueFilter-title'>Opciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <React.Fragment>
                                                    <tr key={question_search_values_filter.id} className='ui-index-formatSearchValue__container-columns'>
                                                        <td className='ui-index-formatSearchValueFilter-data'>{question_search_values_filter.question_source.question}</td>
                                                        <td className='ui-index-formatSearchValueFilter-data'>{this.getOperation(question_search_values_filter.operator)}</td>
                                                        <td className='ui-index-formatSearchValueFilter-data'>{question_search_values_filter.question_target.question}</td>
                                                        <td className='ui-index-formatSearchValueFilter-data'>
                                                            {true && (
                                                                <UncontrolledDropdown className='btn-group ui-index-formatSearchValue-dropdown__container'>
                                                                    <DropdownToggle>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(question_search_values_filter)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(question_search_values_filter.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}

                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            )}  
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            </tbody>
                                        </React.Fragment>
                                    </table>
                                </div>
                                ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">
                                    <div className="text-center mt-4 mb-4">
                                        <h4>No hay registros</h4>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Index;