import { data } from 'jquery';
import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';

const FormRecommendedChart = (props) => {
    const [showInfo, setShowInfo] = useState(false);
    
    const [chartIndex, setIndex] = useState(null);

    const handleSubmit = e => {
        e.preventDefault();
    }; 

    const handleClickChart = (index) => {
        if (index == chartIndex){
            setIndex(null);
        } else {
            setIndex(index);
        }
    }

    const getCharIndicator = (grafico) => {
        if (grafico == "bar"){

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/48948/apexcharts-bar.jpg"

        } else if (grafico == "bar_mensual"){

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/47637/Barras-Apex-2.jpg"

        } else if (grafico == "pie"){

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/47638/apexcharts-Pie-2.jpg"

        } else if (grafico == "donut"){

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/48946/apexcharts-Donuts-1.jpg"

        } else if (grafico == "linea_mensual"){

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/48947/apexcharts-line.jpg"

        } else if (grafico == "linea"){

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/47656/Linea-Apex-1.jpg"

        }

    }    

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered modal-lg modal-recomended-charts" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fas fa-chart-pie mr-2"></i> {props.title}</ModalHeader>
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            {props.data.length >= 1 ? (
                                <React.Fragment>
                                    <div className="ui-formRecommendedChart__container">
                                        <div className="ui-formRecommendedChart-message-recommended">
                                            <p>Según tus tipos de preguntas en el formato <b>{props.format_custom_view.format.name}</b> realizamos una predición de los indicadores que pueden ayudarte en una mejor interpretación de tu información</p>
                                        </div>
                                        <div className="ui-formRecommendedChart-charts__container">
                                            {props.data.map((chart, index) => (
                                                <div className="ui-formRecommendedChart-chart">
                                                    <div className="ui-formRecommendedChart-button-generate">
                                                        <UncontrolledTooltip target={`create${index}`}>{'Generar Gráfica'}</UncontrolledTooltip>
                                                       <button type='button' id={`create${index}`} onClick={() => props.generateChart(chart)}> <i className="fas fa-plus-circle"></i></button>
                                                    </div>
                                                    <div className="ui-formRecommendedChart-chart-type">
                                                    <span>Gráfica de</span> {chart.chart_type}
                                                    </div>                                                    
                                                    <img src={getCharIndicator(chart.chart_type)} alt="Gráfico de barras" className='ui-formRecommendedChart__img-charts' />                                                    

                                                    <div className="ui-formRecommendedChart-chart-title">
                                                        {chart.name}
                                                    </div>
                                                    <button type='buttom' className='ui-formRecommendedChart-chart-more-info' onClick={() => handleClickChart(index)}> {chartIndex == index  ? 'Ocultar': 'Más Información'}</button>
                                                    {(chartIndex == index) && (
                                                        <div className="ui-formRecommendedChart-chart-varibles">
                                                            <div className="ui-formRecommendedChart-chart-typechart"><span>Tipo:</span> {chart.result_type}</div>
                                                            {chart.x_axis.map(x => (
                                                                <div className="ui-formRecommendedChart-chart-varx"><span>Eje x:</span> {x.question}</div>
                                                            ))}
                                                            {chart.y_axis.map(y => (
                                                                <div className="ui-formRecommendedChart-chart-vary"><span>Eje y:</span> {y.question}</div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className='ui-formRecommendedChart-message-No_chats_recommended'>
                                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/47821/No_hay_Graficas_recomendadas.jpg" alt="No hay Gráficas recomendadas" />
                                    <div><h5>No hay gŕaficas para recomendar, por favor revisa que tengas campos o preguntas creadas.</h5></div>
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                        </ModalFooter>
                    </form>
            </Modal>
        </React.Fragment>
    );
}


export default FormRecommendedChart;