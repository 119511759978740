import React from "react";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormUser from "../users/formUser";
import HeaderTitle from '../../GeneralComponents/HeaderTitle'
import Pagination from "react-js-pagination";
import Filter from './filter'
import FormCreateFiles from '../../GeneralComponents/FormConfigurationFile';
import FormUpgradePlan from "../settings/Users/FormUpgradePlan";
import Loading from '../../GeneralComponents/LoadingComponen'
import { isMobile } from 'react-device-detect';
import IndexMobile from './Mobile/Index'
import IndexWeb from './TableIndexWeb'
import { data } from "jquery";

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.token = document.querySelector("[name='csrf-token']").content;
    this.state = {
      user: {},
      title: "Nuevo usuario",
      modal: false,
      modalUpgradePlan: false,
      backdrop: "static",
      modeEdit: false,
      passwor: true,
      password_confir: true,
      isLoadedFiles: false,
      factor_authentication: this.props.current_user.factor_authentication,

      modaFiles: false,

      formAddFiles: {
        modulo: "Usuarios",
        file: "",  
        fileName: "",
        user_id: "",
      },

      idGeneralFile: "",
      file: null,

      dataUsersFiles: [],

      form: {
        username: "",
        email: "",
        first_name: "",
        second_name: "",
        first_last_name: "",
        second_last_name: "",
        is_tenant: false,
        tenant_id:this.props.current_user.tenant,
        position_id: "",
        name: "",
        avatar: {},
        password: "",
        password_confirmation: "",
      },

      ErrorValues: true,
      errorName: "",
      sizeFile: 0,

      selectedOption: {
        position_id: "",
        label: "Seleccionar"
      },

      nameFile: "",
      module_id: "",
      roles: [],
      
    };
    this.toggle = this.toggle.bind(this);
  }

  handleFileUpload = e => {
    this.setState({
      avatar: e.target.files[0]
    });
  };


  clearValues = (form) => {
    this.setState({
      form: {
        username: "",
        second_email: "",
        first_name: "",
        second_name: "",
        first_last_name: "",
        second_last_name: "",
        position_id: "",
        is_tenant: false,
        tenant_id:this.props.current_user.tenant,
        avatar: {},
        password: "",
        password_confirmation: "",
      },

      errorName: "",

      selectedOption: {
        position_id: "",
        label: "Seleccionar"
      },

      modal: false,
      modeEdit: false,
    })
  }

  passwordConfirmationValidate = () => {
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/
    if (regex.test(this.state.form.password_confirmation)){
      this.setState({password_confir: true})
    }else{
      this.setState({password_confir: false})
    }         
  }

  validatePasswords () {
    if (this.state.form.password === this.state.form.password_confirmation) {
      return true
    }else{
      return false
    }
  }


  delete = id => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡El registro será eliminado para siempre!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009688",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        fetch("/user/" + id, {
          method: "delete"
        })
          .then(response => response.json())
          .then(response => {
            if (response.type != "error"){
              this.props.loadDataTable();

              Swal.fire(
                "¡Borrado!",
                "¡El registro fue eliminado con éxito!",
                "success"
              );
            }else{
              this.messageSuccess(response);
            }
          });
      }
    });
  };

  changeAuthentication = () => {
    fetch(`/settings/update_two_factor_authentication/${!this.state.factor_authentication}`, {
        method: "PATCH",
        headers: {
            "X-CSRF-Token": this.token,
            "Content-Type": "application/json"
        }
    })
    .then(response => response.json())
    .then(response => {
      this.setState({
        factor_authentication: response.state
      })
    });
  }

  componentDidMount(){
    let array = []

    this.props.rols.map((item) => (
      array.push({label: item.name, value: item.id})
    ))

    this.setState({
      roles: array
      
    })
  }

  edit = user => {
    if(this.state.modeEdit === true){
      this.setState({modeEdit: false})
    }else{
      this.setState({modeEdit: true})
    }

    this.toggle("edit")
    
    this.setState({

      user: user,
      title: "Editar Registro",
      form: {
        username: user.username,
        second_email: user.second_email,
        first_name: user.first_name,
        second_name: user.second_name,
        first_last_name: user.first_last_name,
        second_last_name: user.second_last_name,
        position_id: user.position != undefined ? user.position.id : "",
        is_tenant: user.is_tenant,
        avatar: {},
        password: "",
        password_confirmation: "",
      },

      selectedOption: {
        label: `${user.position != undefined ? user.position.name : "Sin rol"}`,
        value: user.position != undefined ? user.position.id : ""
      }


    });
  };

  messageSuccess = (response) => {
    Swal.fire({
        position: "center",
        type: `${response.type}`,
        title: `${response.success}`,
        showConfirmButton: false,
        timer: 1500,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  handleChange = e => {
    const date = new Date();
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
        password: !this.state.modeEdit ? (this.state.form.username + date.getFullYear() + 'A') : "",
        password_confirmation: !this.state.modeEdit ? (this.state.form.username + date.getFullYear() + 'A') : "",
      }
    });
  };
  

  handleChangePassword = e => {
    if (e.target.name == "password" && this.state.modeEdit) {
      var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/
      //(?=.*[@$!%*_?&])
      if (regex.test(e.target.value)){
        console.log("el campo de contraseña es correcto" + e.target.value)   
        this.setState({passwor: true})
      }else{
        console.log("el campo de contraseña no es correcto" + e.target.value)
        this.setState({passwor: false})
      }  
    }


    if (e.target.name == "password_confirmation" && this.state.modeEdit) {
      this.validatePasswords()
    }

    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      }
    });
  };



  validationForm = () => {
    if (this.state.form.username != "" &&  
        this.state.form.second_email != "" && 
        this.state.form.first_name != "" && 
        this.state.form.first_last_name != "" && 
        this.state.form.position_id != "" &&
        this.state.form.second_email != ""
        ) {
      this.setState({ ErrorValues: true })
      return true
    }else{
      this.setState({ ErrorValues: false })
      return false
      
    }
  }


  validationOptions = () => {
    if (this.state.form.password.length >= 6 && 
        (/[A-Z]/).test(this.state.form.password) &&  
        (/[a-z]/).test(this.state.form.password) && 
        (/[0-9]/).test(this.state.form.password) && 
        this.state.form.password === this.state.form.password_confirmation 
      ) {
      return true
    }else{
      return this.state.form.password != "" ? false : true
    }
  }

  HandleClick = e => {
    if (this.validatePasswords() == true && this.validationForm() == true && this.validationOptions() == true)  {
    const formData = new FormData();
    formData.append("username", this.state.form.username != null ? this.state.form.username : "");
    formData.append("second_email", this.state.form.second_email != null ? this.state.form.second_email : "");
    formData.append("first_name", this.state.form.first_name != null ? this.state.form.first_name : "");
    formData.append("second_name", this.state.form.second_name != null ? this.state.form.second_name : "");
    formData.append("first_last_name", this.state.form.first_last_name != null ? this.state.form.first_last_name : "");
    formData.append("second_last_name", this.state.form.second_last_name != null ? this.state.form.second_last_name : "");
    formData.append("is_tenant", false);
    formData.append("tenant_id", this.props.current_user.tenant);
    formData.append("position_id", this.state.form.position_id);
    formData.append("avatar", this.state.avatar);
    formData.append("user_state", this.props.current_user.countUsers == 0 ? true : (this.props.available_users >= this.props.current_plan.total_users ? false : true))
    formData.append("password", this.state.form.password);
    formData.append("password_confirmation",this.state.form.password_confirmation);
    const token = document.querySelector('[name=csrf-token]').content;

    if (this.state.modeEdit) {
      fetch("/update_user/" + this.state.user.id, {
        method: "PATCH", // or 'PUT'
        body: formData, // data can be `string` or {object}!
        headers: {}
      })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(response => {
          if (response.type != "error"){
            this.props.updateItem(response.register)
            //this.props.loadDataTable();
            this.messageSuccess(response);
            this.clearValues();
          }else{
            this.messageSuccess(response);
            this.clearValues();
            //this.setState({ errorName: "El nombre de usuario es incorrecto" })
          }
        });
    } else {
      fetch("/create_user", {
        method: "POST", // or 'PUT'
        body: formData, // data can be `string` or {object}!
        headers: {}
      })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
          if (data.type != "error"){
            //this.props.loadDataTable();
            this.props.updateData(data.register)
            this.messageSuccess(data);
            this.setState({passwor: true});
            this.clearValues();
          }else{
            this.messageSuccess(data);
            this.clearValues();
            if(data.error[0]){
              this.setState({ errorName: data.error[0].replace("Username", "") })
            }
          }
        });
    }

  }
  };

  toggle(from) {
    if(from == "edit"){
      this.setState({modeEdit: true})
      
    }else if(from == "new"){

      this.setState({
        title: "Nuevo usuario",
      })

      this.clearValues("files");

    }else{
      if(this.state.modeEdit === true){
        this.setState({modeEdit: false})
      }else{
        this.setState({modeEdit: true})
      }

      this.setState({
        user: {},
        ErrorValues: true,
        passwor: true
      })

    }

    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }


  handleChangeAutocomplete = selectedOption => {
    this.setState({
      selectedOption,
      form: {
        ...this.state.form,
        position_id: selectedOption.value
      }
    });
  };

      //files 

    clearValuesFiles = () => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                fileName: "",
                file: {},
            },

            isLoadedFiles: false,
            nameFile: "",
            file: null
        })
    }

    addFiles = (position) => {
      this.setState({
          module_id: position.id,
          modaFiles: true,
      })
    }

    HandleChangeFiles = (e) => {
        this.setState({
            formAddFiles: {
              ...this.state.formAddFiles,
              [e.target.name]: e.target.value
            }
        });
    }

    toogleFiles = (from) => {
        if(from == "new"){
            this.setState({ modaFiles: true})
            this.clearValues();
        }else{
            this.setState({ modaFiles: false})
        }
    }

    toogleUpgradePlan = (from) => {
      if (from == "new") {
          this.setState({ modalUpgradePlan: true })
      } else {
          this.setState({ modalUpgradePlan: false })
      }
    }

    loadDataFiles = (id) => {
        fetch(`/settings/get_general_files_users/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataUsersFiles: data.data,
            isLoadedFiles: false
          });
        });
    }

    handleFile = (archivo) => {
      archivo.map(file => (
          this.setState({
              formAddFiles: {
                  ...this.state.formAddFiles,
                  file: file.size >= 11485760 ? "" : file
              },

              nameFile: file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
              sizeFile: file.size
          })
      ));
    };

    HandleClickFiles = () => {
        this.setState({ isLoadedFiles: true })
        const formData = new FormData();
        formData.append("modulo", this.state.formAddFiles.modulo)
        formData.append("file", this.state.formAddFiles.file)
        formData.append("fileName", this.state.formAddFiles.fileName)
        formData.append("user_id", this.state.formAddFiles.user_id)

        fetch(`/settings/general_files`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadDataTable();
            this.clearValuesFiles()
            this.loadDataFiles(this.state.formAddFiles.user_id)
            this.messageSuccess(data);
        });
    }

    updateState = (id, state) => {
      fetch(`/update_state/${id}/${state}`, {
          method: 'PATCH', // or 'PUT'
          headers: {}
      })
      .then(res => res.json())
      .catch(error => console.error("Error:", error))
      .then(data => {
        this.messageSuccess(data);
        if (data.type != "error"){
          this.props.loadDataTable();
        }
      });
    }

    handleChangeUserName = (e) => {
      this.setState({
        form: {
          ...this.state.form,
          username: e.target.value.toLowerCase()
        }
      })
    }

    handleSelectModal = () => {
      if (this.props.tenant.plan.name != 'plan1' ){
        this.toggle('new');
      } else {
        this.toogleUpgradePlan('new');
      }
    }

  render() {
    const usersTrue = this.props.dataUsers.filter(user => user.user_state == true).length

    return (
      <React.Fragment>
        <HeaderTitle 
          title={"Usuarios"} 
          subTitle={"Gestión de usuarios"}
          titleNew={"Nuevo Usuario"}
          titleFilter={"Filtros"}
          icon={"fas fa-users"}
          onClickFilter={this.props.funShowFilter}
          onClickNew={this.handleSelectModal}
          showNew={this.props.estados.crear}
          showFilter={true}

          //favorite module params
          is_index={true}
          url={this.props.current_url}
        />

        {this.state.modaFiles && (
            <FormCreateFiles
                //modal props
                backdrop={"static"}
                modal={this.state.modaFiles}
                toggle={this.toogleFiles}
                title={"Agregar Archivos"}

                loadDataTable={this.props.loadData}
                nameModule={"Usuarios"}
                module_id={this.state.module_id}
            />
        )}

        {this.state.modalUpgradePlan && (
            <FormUpgradePlan
              //modal props
              backdrop={"static"}
              modal={this.state.modalUpgradePlan}
              toggle={this.toogleUpgradePlan}
              title={"Mejora tu Plan"}
              messageModal={["Has alcanzado ", <span className='ui_formUpgrade_Plan_message_first'>el límite de usuarios</span>, " permitidos en tu plan actual"]}
              plans={this.props.plans}
              current_plan={this.props.current_plan}
            />
          )}

        <div className="col-md-12 pr-0 mb-3">
          <div className="row">

              {this.state.modal && (
                <FormUser

                  toggle={this.toggle}
                  backdrop={this.state.backdrop}
                  modal={this.state.modal}

                  onChangeForm={this.handleChange}
                  formValues={this.state.form}
                  submit={this.HandleClick}
                  FormSubmit={this.handleSubmit}

                  titulo={this.state.title}
                  nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}
                  handleChangeUserName={this.handleChangeUserName}



                  loadDataTable={this.props.loadDataTable}
                  handleFileUpload={this.handleFileUpload}
                  editState={this.state.modeEdit}
                  formAutocomplete={this.state.selectedOption}
                  roles={this.state.roles}
                  onChangeAutocomplete={this.handleChangeAutocomplete}
                  errorValues={this.state.ErrorValues}
                  onBlurPasswordConfirmation={this.passwordConfirmationValidate}

                  passworState={this.state.passwor}
                  passworConfirmationState={this.validatePasswords()}

                  errorName={this.state.errorName}
                  handleChangePassword={this.handleChangePassword}

                />
              )}

          </div>
        </div>

        {this.props.showFilter && (
          <Filter
              onChangeFilter={this.props.onChangeFilter}
              formValuesFilter={this.props.FormFilter}
              onClickFilter={this.props.onClickFilter}
              cancelFilter={this.props.cancelFilter}
              closeFilter={this.props.showFilter}
              rols={this.props.rols}
              showMessage={this.props.showMessage}
          />
        )}

        {(this.props.current_plan.tenant_state != "demo" && this.props.current_plan.name != "beefree") && (
          <div className="row">
              <div className="col-md-12">
                  <div className="alert alert-info" role="alert">
                    <b><span>{this.props.available_users} / {this.props.current_plan.total_users} usuarios disponibles</span> </b>
                  </div>
              </div>
          </div>
        )}

        {!this.props.isLoaded ? (
          <React.Fragment>
            {isMobile ? (
              <IndexMobile 
                dataUsers={this.props.dataUsers} 
                estados={this.props.estados}
                edit={this.edit}
                delete={this.delete}
                addFiles={this.addFiles}
                updateState={this.updateState}
                usersTrue={usersTrue}
                current_user={this.props.current_user}
                tenant={this.props.tenant}
                factor_authentication={this.state.factor_authentication}
                changeAuthentication={this.changeAuthentication}
              />
            ) : (
              <IndexWeb 
                dataUsers={this.props.dataUsers} 
                estados={this.props.estados}
                edit={this.edit}
                delete={this.delete}
                addFiles={this.addFiles}
                updateState={this.updateState}
                usersTrue={usersTrue}
                current_user={this.props.current_user}
                tenant={this.props.tenant}
                factor_authentication={this.state.factor_authentication}
                changeAuthentication={this.changeAuthentication}
              />
            )}
          </React.Fragment>
        ) : (
          <Loading/>
        )}

      </React.Fragment>
    );
  }
}

export default Table;


{/*

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-10 text-left pl-0">
                  <p>
                      Mostrando {this.props.dataUsers.length} de {this.props.users_total}
                  </p>
                </div>

                <div className="col-md-2 text-right">
                  <Pagination
                    hideNavigation
                    activePage={this.props.activePage}
                    itemsCountPerPage={this.props.countPage}
                    itemClass="page-item"
                    innerClass="pagination"
                    linkClass="page-link"
                    totalItemsCount={this.props.users_total}
                    pageRangeDisplayed={this.props.countPage}
                    onChange={this.props.handlePageChange}
                  />
                </div>

              </div>
            </div>


*/}