import React, { Component } from 'react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';
import FormCreate from './FormCreate';
import Select from "react-select";

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            isLoaded: true,
            plan_copy_format_id: "",

            form: {
                plan_id: this.props.plan_id,
                tenant_id: "",
                format_category_id: "",
            },

            selectedOption: {
                label: "Seleccione la empresa",
                tenant_id: "", 
            },

            selectedOptionFormatCategory: {
                label: "Seleccione el modulo",
                format_category_id: "", 
            },

            format_categories: []
        }
    }

    handleChangeAutocomplete = selectedOption => {
        this.loadDataFormatCategories(selectedOption.value);
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                tenant_id: selectedOption.value
            }
        });
    };

    handleChangeAutocompleteFormatCategory = selectedOptionFormatCategory => {
        this.setState({
            selectedOptionFormatCategory,
            form: {
                ...this.state.form,
                format_category_id: selectedOptionFormatCategory.value
            }
        });
    };

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (plan_copy_format) => {
        this.setState({
            data: this.state.data.map(item => {
                if (plan_copy_format.id === item.id) {
                    return {
                        ...item,
                        format_category: plan_copy_format.format_category, 
                        tenant: plan_copy_format.tenant, 
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            plan_copy_format_id: "",
            format_categories: [],

            form: {
                ...this.state.form,
                tenant_id: "",
                format_category_id: "",
            },

            selectedOption: {
                label: "Seleccione la empresa",
                tenant_id: "", 
            },

            selectedOptionFormatCategory: {
                label: "Seleccione el modulo",
                format_category_id: "", 
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/plan_copy_formats/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
    }

    loadDataFormatCategories = (tenant_id) => {
        this.setState({ isLoaded: true })
        fetch(`/settings/get_format_categories_tenant/${tenant_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                format_categories: data.data,
                isLoaded: false
            });
        });
    }

    loadData = () => {
        fetch(`/settings/get_plan_copy_formats/${this.props.plan_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
            });
        });
    }

    HandleClick = () => {
        if (this.state.modeEdit) {
            fetch(`/settings/plan_copy_formats/${this.state.plan_copy_format_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.updateItem(data.register)
                this.clearValues();
                this.setState({ modal: false })
            });
        } else {
            fetch(`/settings/plan_copy_formats`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.updateData(data.register)
                this.clearValues();
                this.setState({ modal: false })
            });
        }
    }


    edit = (plan_copy_format) => {
        this.loadDataFormatCategories(plan_copy_format.tenant.id);
        this.setState({
            modeEdit: true,
            plan_copy_format_id: plan_copy_format.id,
            form: {
                ...this.state.form,
                tenant_id: plan_copy_format.tenant.id,
                format_category_id: plan_copy_format.format_category.id,
            },

            selectedOption: {
                label: plan_copy_format.tenant.name,
                tenant_id: plan_copy_format.tenant.id,
            },

            selectedOptionFormatCategory: {
                label: plan_copy_format.format_category.name,
                format_category_id: plan_copy_format.format_category.id,
            },
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <ModalBody>

                        {this.state.modal && (
                            <FormCreate
                                toggle={this.toogle}
                                nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                                //form props
                                formValues={this.state.form}
                                onChangeForm={this.HandleChange}
                                submitForm={this.HandleClick}

                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                selectedOption={this.state.selectedOption}
                                companies={this.props.companies}
                                isLoaded={this.state.isLoaded}

                                selectedOptionFormatCategory={this.state.selectedOptionFormatCategory}
                                handleChangeAutocompleteFormatCategory={this.handleChangeAutocompleteFormatCategory}
                                format_categories={this.state.format_categories}
                            />
                        )}

                        <div className="col-md-12 mb-3 text-right pr-0">
                            {!this.state.modal && (
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.toogle("new")}
                                >
                                    Abrir formulario
                                </button>
                            )}
                        </div>

                        <div className="card">
                            <div className="background-gray">
                                <table className="table " id="sampleTable">
                                    <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                        <tr>
                                            <th>Empresa</th>
                                            <th>Modulo</th>
                                            <th className="text-right">Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map((plan_copy_format, index) => (
                                                <React.Fragment>
                                                    {index >= 1 ? <div className="mb-2"></div> : null}
                                                    <tr key={plan_copy_format.id} className="tr-table-task" style={{ borderLeft: `4px solid green` }}>
                                                        <td>
                                                            {this.state.plan_copy_format_id == plan_copy_format.id ? (
                                                                <React.Fragment>
                                                                    <input
                                                                        type="hidden"
                                                                        name="format_target_id"
                                                                        value={this.state.selectedOption.format_target_id}
                                                                    />
                                                                    <Select
                                                                        onChange={this.handleChangeAutocomplete}
                                                                        options={this.props.companies}
                                                                        autoFocus={false}
                                                                        className={`link-form`}
                                                                        value={this.state.selectedOption}
                                                                    />
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {plan_copy_format.tenant.name}
                                                                </React.Fragment>
                                                            )}
                                                        </td>

                                                        <td>
                                                            {this.state.plan_copy_format_id == plan_copy_format.id ? (
                                                                <React.Fragment>
                                                                    <input
                                                                        type="hidden"
                                                                        name="format_category_id"
                                                                        value={this.state.selectedOptionFormatCategory.format_category_id}
                                                                    />
                                                                    <Select
                                                                        onChange={this.handleChangeAutocompleteFormatCategory}
                                                                        options={this.state.format_categories}
                                                                        autoFocus={false}
                                                                        className={`link-form`}
                                                                        value={this.state.selectedOptionFormatCategory}
                                                                    />  
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {plan_copy_format.format_category.name}
                                                                </React.Fragment>
                                                            )}
                                                        </td>

                                                        <td className="text-right">
                                                            {this.state.plan_copy_format_id == plan_copy_format.id ? (
                                                                <React.Fragment>
                                                                    <i className="fas fa-check-circle update-section" onClick={() => this.HandleClick()}></i>
                                                                    <i className="fas fa-times-circle update-false" onClick={() => this.clearValues()}></i>
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <UncontrolledDropdown direction="left" className='btn-group'>
                                                                        <DropdownToggle className='btn-shadow btn btn-info'>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    className="dropdown-item"
                                                                                    onClick={() => this.edit(plan_copy_format)}
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}
                                                                            
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.delete(plan_copy_format.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </React.Fragment>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </ModalBody>

                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;
