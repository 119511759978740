import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            showTable: false,
            ErrorValues: true,
            format_indicator_id: "",

            form: {
                format_id: this.props.format.id,
                question_ids: [],
                chart_type: "",
                question_y_id: "",
                question_x_id: "",
                name: "",
            },

            selectedOptionMulti: {
                question_ids: [],
                label: "Seleccione las preguntas"
            },

            selectedOption: {
                label: "Selecione pregunta en y",
                question_y_id: ""
            },

            selectedOptionQuestion: {
                label: "Selecione pregunta en x",
                question_x_id: "",
            },

            editValues: [],
            questions: [],
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
                if (format.id === item.id) {
                    return {
                        ...item,
                        name: format.name,
                        question_x: format.question_x,
                        question_y: format.question_y,
                        chart_type: format.chart_type,
                        questions: format.questions,
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            form: {
                ...this.state.form,
                question_ids: [],
                chart_type: "",
                question_y_id: "",
                question_x_id: "",
                name: "",
            },

            selectedOption: {
                label: "Selecione pregunta en y",
                question_y_id: ""
            },

            selectedOptionQuestion: {
                label: "Selecione pregunta en x",
                question_x_id: "",
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_indicators/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
        //this.selectConfiguration();
    }

    selectConfiguration = () => {
        let array = []

        this.props.steps_questions.map((item) => (
            array.push({ label: item.question, value: item.id })
        ))

        this.setState({
            questions: array,
        })
    }

    loadData = () => {
        fetch(`/formatos/get_format_indicators/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }

    loadDataSelects = () => {
        fetch(`/formatos/data_questions/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            let array = []

            data.steps_questions.map((item) => (
                array.push({ label: item.question, value: item.id })
            ))

            this.setState({
                questions: array,
                modal: true
            });
        });
    }

    validationForm = () => {
        if (this.state.form.chart_type != "" &&
            this.state.form.name != "" 
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }


    HandleClick = () => {
        if(this.validationForm()){
            if (this.state.modeEdit) {
                fetch(`/formatos/format_indicators/${this.state.format_indicator_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            } else {
                fetch(`/formatos/format_indicators`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateData(data.register)
                        this.clearValues();
                        this.setState({ modal: false, showTable: true })
                    });
            }
        }
    }


    edit = (format_indicator) => {
        const array = [];
        const arrayIds = [];

        format_indicator.questions.map((item) => (
            array.push({ label: item.question, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modal: true,
            modeEdit: true,
            format_indicator_id: format_indicator.id,

            form: {
                question_ids: arrayIds,
                chart_type: format_indicator.chart_type,
                question_y_id: format_indicator.question_y_id,
                question_x_id: format_indicator.question_x_id,
                name: format_indicator.name,
            },

            selectedOption: {
                label: `${format_indicator.question_y.question}`,
                question_y_id: format_indicator.question_y_id,
            },

            selectedOptionQuestion: {
                label: `${format_indicator.question_x.question}`,
                question_x_id: format_indicator.question_x_id,
            },

            selectedOptionMulti: {
                question_ids: arrayIds,
                label: "Seleccione las preguntas"
            },

            editValues: array
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
                form: {
                    ...this.state.form,
                    question_y_id: selectedOption.value
                }
        });
    };

    handleChangeAutocompleteQuestions = selectedOptionQuestion => {
        this.setState({
            selectedOptionQuestion,
                form: {
                    ...this.state.form,
                    question_x_id: selectedOptionQuestion.value
                }
        });
    };

    handleChangeAutocompleteMulti = selectedOptionMulti => {
        let array = []

        selectedOptionMulti.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            form: {
                ...this.state.form,
                question_ids: array
            }
        })
    }


    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar asociación" : "Crear asociación"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}

                        //select questions 1
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOption={this.state.selectedOption}

                        //select questions 2
                        handleChangeAutocompleteQuestions={this.handleChangeAutocompleteQuestions}
                        selectedOptionQuestion={this.state.selectedOptionQuestion}
                        questions={this.state.questions}

                        //select questions 3
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}
                        selectedOptionMulti={this.state.selectedOptionMulti}
                        editValues={this.state.editValues}
                    />
                )}

                <div className="card mb-3">
                    <div className="card-header" style={{ display: "flow-root", paddingTop: 12 }}>
                        Indicadores

                        <button
                            className="btn-shadow btn btn-info float-right"
                            onClick={() => this.loadDataSelects()}
                        >
                            Crear
                        </button>

                        <a className="float-right mr-3" data-toggle="collapse" href="#collapseExampleIndicators" role="button" aria-expanded="false" aria-controls="collapseExampleResponsabilidades">
                            <i className="fas fa-angle-down"></i>
                        </a>
                    </div>

                    <div className="card-body p-3">
                        <div className="row">

                            <div className={`collapse ${this.state.showTable ? "show" : ""} w-100`} id="collapseExampleIndicators">
                                <div className="col-md-12">
                                    <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                {/*<th>Pregunta en y</th>
                                                <th>Pregunta en x</th>*/}
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.data.length >= 1 ? (
                                                this.state.data.map(format_indicator => (
                                                    <tr key={format_indicator.id}>
                                                        <td>{format_indicator.name}</td>
                                                        {/*<td>{format_indicator.question_y.question}</td>
                                                        <td>{format_indicator.question_x.question}</td>*/}

                                                        {/*<td>{format_association.description}</td>*/}
                                                        {true && (
                                                            <td className="text-right" style={{ width: "10px" }}>
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(format_indicator)}
                                                                                        
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (  
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(format_indicator.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))
                                            ) : (
                                                    <tr>
                                                        <td colSpan="8" className="text-center">
                                                            <div className="text-center mt-4 mb-4">
                                                                <h4>No hay registros</h4>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
