import React, { Component } from 'react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Progress } from 'reactstrap';
import GeneralComments from '../../../GeneralComponents/ChatBoxModuleComment'
import NotificationDocument from './NotificationDocument';
import { isMobile } from 'react-device-detect';
import FormCreate from '../Notification/FormCreate';
import DocumentVersionIndex from '../DocumentVersion/Index';
import Preloader from '../../../GeneralComponents/LoadingComponen';

class DocumentState extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: true,
            modal: false,
            ErrorValues: true,
            title: "",
            document: {
                name: this.props.document.name,
                attached: this.props.document.attached,
    
                user_create: this.props.document.user_create,
                user_review: this.props.document.user_review,
                user_approve: this.props.document.user_approve,
    
                create_state: this.props.document.create_state,
                review_state: this.props.document.review_state,
                approve_state: this.props.document.approve_state,
    
                creation_date: this.props.document.creation_date,
                review_date: this.props.document.review_date,
                approve_date: this.props.document.approve_date,
    
                review_status: this.props.document.review_status,
                approve_status: this.props.document.approve_status,

                user_approve_state_firm: this.props.document.user_approve_state_firm,
                user_create_state_firm: this.props.document.user_create_state_firm,
                user_review_state_firm: this.props.document.user_review_state_firm,
            },

            form: {
                status: "",
                justification: "",
            }
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/document_management/get_document/${this.props.document.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log("trajo informacion del documento DocumentState");
            this.updateDocument(data.document);
        });
    }

    updateUserFirmState = (state) => {
        const form = { status: "aprobado" }
        fetch(`/document_management/update_user_firm_state/${this.props.document.id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.messageSuccess(data);
            this.updateDocument(data.register);
        });
    }

    handleClickUpdate = (status) => {
        fetch(`/document_management/update_status_document/${this.props.document.id}/${status}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.messageSuccess(data)
            this.updateDocument(data.register)
        });
    }
    
    sendNotification = () => {
        fetch(`/document_management/send_notification/${this.props.document.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.messageSuccess(data)
        }); 
    }


    updateDocument = (document) => {
        this.setState({
            isLoaded: false,
            document: {
                id: document.id,
                name: document.name,
                attached: document.attached,
    
                user_create: document.user_create,
                user_review: document.user_review,
                user_approve: document.user_approve,
    
                create_state: document.create_state,
                review_state: document.review_state,
                approve_state: document.approve_state,
    
                creation_date: document.creation_date,
                review_date: document.review_date,
                approve_date: document.approve_date,
    
                review_status: document.review_status,
                approve_status: document.approve_status,

                user_approve_state_firm: document.user_approve_state_firm,
                user_create_state_firm: document.user_create_state_firm,
                user_review_state_firm: document.user_review_state_firm,
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    justfyChangeState = (type_create, status, title) => {
        this.setState({
            modal: true,
            title: title,
            form: {
                status: status,
                justification: "",
                type_create: type_create
            }
        })
    }
    
    getUserStateFirm = () => {
        if (this.props.document.user_create.id == this.props.user.id && !this.state.document.create_state && !this.state.document.review_state && !this.state.document.approve_state){
            return(
                <React.Fragment>
                    {this.props.user_state_firm && (
                        <button
                            type="button"
                            className="btn-shadow btn btn-info mr-2"
                            onClick={() =>  this.props.has_parent ? this.justfyChangeState("digital_firm", "aprobado", "Motivo de la actualización") : this.updateUserFirmState(!this.state.document.user_create_state_firm)}
                        >
                            {!this.state.document.user_create_state_firm ? "Crear con" : "Cancelar"} firma digital 
                        </button>
                    )}
                </React.Fragment>
            )

        } else if (this.props.document.user_review.id == this.props.user.id && this.state.document.create_state && !this.state.document.review_state) {

            return (
                <React.Fragment>
                    {(!this.state.document.review_status || this.state.document.review_status == "no aprobado" && this.state.document.create_state && this.props.user_state_firm) && (
                        <React.Fragment>
                            <button 
                                type="button" 
                                className="btn-shadow btn btn-info mr-3 ml-3" 
                                style={{ fontSize: isMobile ? "10px" : "", marginLeft: isMobile ? "20px" : "" }} 
                                onClick={() => this.updateUserFirmState(!this.state.document.user_review_state_firm)}
                            >
                                {!this.state.document.user_review_state_firm ? "Revisar con" : "Cancelar"} firma digital 
                            </button>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )

        } else if (this.props.document.user_approve.id == this.props.user.id && this.state.document.review_state && !this.state.document.approve_state) {

            return (
                <React.Fragment>
                    
                    {(this.state.document.approve_status == "" || this.state.document.approve_status == "no aprobado" && this.state.document.review_state && !this.state.document.user_approve_state_firm && this.props.user_state_firm) && (
                        <React.Fragment>
                            <button 
                                type="button" 
                                className="btn-shadow btn btn-info mr-3 ml-3" 
                                style={{ fontSize: isMobile ? "10px" : "", marginLeft: isMobile ? "20px" : "" }} 
                                onClick={() => this.updateUserFirmState(!this.state.document.user_approve_state_firm)}
                            >
                                {!this.state.document.user_approve_state_firm ? "Aprobar con" : "Cancelar"} firma digital 
                            </button>
                        </React.Fragment>
                    )}

                </React.Fragment>
            )

        }
    }

    getFooterUser = () => {
        if (this.props.document.user_create.id == this.props.user.id && !this.state.document.create_state && !this.state.document.review_state && !this.state.document.approve_state){
            return(
                <React.Fragment>
                    {true && (
                        <button
                            type="button"
                            className="btn-shadow btn btn-outline-info mr-2"
                            onClick={() => this.props.has_parent ? this.justfyChangeState("firm", "aprobado", "Motivo de la actualización") : this.handleClickUpdate("aprobado")}
                        >
                            Documento creado
                        </button>
                    )}

                    {false && (
                        <button
                            type="button"
                            className="btn-shadow btn btn-outline-info"
                        >
                            Enviar notificación a la persona que revisa 
                        </button>
                    )}
                </React.Fragment>
            )

        } else if (this.props.document.user_review.id == this.props.user.id && this.state.document.create_state && !this.state.document.review_state) {

            return (
                <React.Fragment>
                    {(!this.state.document.review_status || this.state.document.review_status == "no aprobado" && this.state.document.create_state) && (
                        <React.Fragment>
                            <button type="button" className="btn-shadow btn btn-info mr-3" style={{ fontSize: isMobile ? "10px" : "", marginLeft: isMobile ? "20px" : "" }} onClick={() => this.handleClickUpdate("aprobado")}>Documento revisado</button>
                            <button type="button" className="btn-shadow btn btn-danger" style={{ fontSize: isMobile ? "10px" : "" }} onClick={() => this.justfyChangeState(null, "no aprobado", "Motivo de rechazo")}>Revision no aprobada</button>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )

        } else if (this.props.document.user_approve.id == this.props.user.id && this.state.document.review_state && !this.state.document.approve_state) {

            return (
                <React.Fragment>
                    
                    {(this.state.document.approve_status == "" || this.state.document.approve_status == "no aprobado" && this.state.document.review_state) && (
                        <React.Fragment>
                            <button type="button" className="btn-shadow btn btn-info mr-3" style={{ fontSize: isMobile ? "10px" : "", marginLeft: isMobile ? "20px" : "" }} onClick={() => this.handleClickUpdate("aprobado")}>Apruebo el documento</button>
                            <button type="button" className="btn-shadow btn btn-danger" style={{ fontSize: isMobile ? "10px" : "" }} onClick={() => this.justfyChangeState(null, "no aprobado", "Motivo de rechazo")}>No apruebo el documento</button>
                        </React.Fragment>
                    )}

                </React.Fragment>
            )

        } else {
            return (
                <React.Fragment>
                    {(this.state.document.create_state && !this.state.document.review_state && this.props.document.user_create.id == this.props.user.id) && (
                        <button
                            type="button"
                            className="btn-shadow btn btn-success"
                            onClick={() => this.sendNotification()}
                        >
                            Notificar al responsable de revisado
                        </button>
                    )}

                    {(this.state.document.review_state && !this.state.document.approve_state && this.props.document.user_review.id == this.props.user.id) && (
                        <button
                            type="button"
                            className="btn-shadow btn btn-success"
                            onClick={() => this.sendNotification()}
                        >
                            Notificar al responsable de aprobar
                        </button>
                    )}
                </React.Fragment>
            )
        }
    }

    toogle = (title, from) => {
        if (from == "new") {
            this.setState({ modal: true, title: title })
            this.clearValues();
        } else {
            this.setState({ modal: false, title: "" })
            this.clearValues();
        }
    }

    clearValues = () => {
        this.setState({
            title: "",

            form: {
                status: "",
                justification: "",
            }
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    validationForm = () => {
        if (this.state.form.justification != "" 
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if(this.validationForm() == true){
            fetch(`/document_management/update_status_document/${this.props.document.id}/${this.state.form.status}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
    
            .then(response => response.json())
            .then(data => {
                this.setState({ modal: false });
                this.clearValues();
                this.messageSuccess(data);
                this.updateDocument(data.register);
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.title}
                        nameBnt={"Añadir"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                        errorValues={this.state.ErrorValues}
                    />
                )}

                <div className="card mb-3">
                    <div className="card-body">

                        <div className="progress-container" >
                            <Progress multi>
                                <Progress animated style={{ fontSize: isMobile ? "8px" : "" }} striped bar color={this.state.document.create_state ? "info" : "secondary"} value="34">CREADO ({this.state.document.creation_date != null ? this.state.document.creation_date : "Pendiente"}) </Progress>
                                <Progress animated style={{ fontSize: isMobile ? "8px" : "" }} striped bar color={this.state.document.review_status == "aprobado" ? "info" : "secondary"} value="34"> REVISADO ({this.state.document.review_date != null ? this.state.document.review_date : "Pendiente"}) </Progress>
                                <Progress animated style={{ fontSize: isMobile ? "8px" : "" }} striped bar color={this.state.document.approve_status == "aprobado" ? "info" : "secondary"} value="34"> APROBADO ({this.state.document.approve_date != null ? this.state.document.approve_date : "Pendiente"}) </Progress>
                            </Progress>

                            <div className="display-flex mt-2">
                                <p className="mr-2"><i className="fas fa-circle" style={{ color: "#16aaff" }}></i> <b>Completado</b></p>
                                <p><i className="fas fa-circle"></i> <b>No completado</b></p>
                            </div>
                        </div>

                    </div>
                </div>

                {(this.props.document.user_create.id == this.props.user.id || this.props.document.user_approve.id == this.props.user.id || this.props.document.user_review.id == this.props.user.id) && (
                    <div className="card">
                        <div className={`card-body text-center ${ isMobile ? "pr-2 pl-2 d-flex" : "" }`}>
                            {!this.state.isLoaded ? (
                                <React.Fragment>
                                    {(this.state.document.attached.url != null || this.props.document.document != "") ? (
                                        <React.Fragment>
                                            {this.getFooterUser()}
                                            {this.getUserStateFirm()}
                                        </React.Fragment>                
                                    ) : (
                                        <div className="alert alert-primary" role="alert">
                                            <h5><b>Debes de subir un archivo o redactar un documento para poder comenzar hacer los pasos de los estados.</b></h5>
                                        </div>  
                                    )}
                                </React.Fragment>
                            ) : (
                                <Preloader/>
                            )}
                        </div>
                    </div>
                )}
                
                <div className="row mb-3">
                    <div className="col-md-6 mt-3">
                        <NotificationDocument
                            notifications={this.props.notifications}
                        />
                    </div>

                    <div className="col-md-6 mt-3">
                        <GeneralComments
                            usuario={this.props.user}
                            module_name={"Gestión documental"}
                            users={this.props.users}
                            module_comment={this.props.document.module_comment_id}
                            imageUpload={this.props.imageUpload}
                            key_text={this.props.key_text}
                            url={this.props.url}
                        />
                    </div>

                    <div className="col-md-6">
                        <DocumentVersionIndex
                            document={this.props.document}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DocumentState;
