import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import IndexFormatCategories from './FormatCategory/Index';
import IndexFormats from './Formats/Index';
import IndexQuestions from './Questions/Index';

const Index = (props) => {
    const [current_tab, setCurrentTab] = useState("format_categories");

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered modal-lg" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>

                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className='text-center'>{props.tenant.company_name}</h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" onClick={() => setCurrentTab("format_categories")}>
                                    <a className={`nav-link ${current_tab == "format_categories" ? 'active' : null}`} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true">
                                        Mudulos
                                    </a>
                                </li>

                                <li className="nav-item" onClick={() => setCurrentTab("formats")}>
                                    <a className={`nav-link ${current_tab == "formats" ? 'active' : null}`} id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false">
                                        Formatos
                                    </a>
                                </li>

                                <li className="nav-item" onClick={() => setCurrentTab("questions")}>
                                    <a className={`nav-link ${current_tab == "questions" ? 'active' : null}`} id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false">
                                        Preguntas
                                    </a>
                                </li>
                            </ul>

                            <div className="tab-content" id="myTabContent">
                                {current_tab == "format_categories" && (
                                    <div className={`tab-pane fade ${current_tab == "format_categories" ? 'show active' : null}`} role="tabpanel" aria-labelledby="home-tab">
                                        <IndexFormatCategories
                                            tenant={props.tenant}
                                        />
                                    </div>  
                                )}
                                
                                {current_tab == "formats" && (
                                    <div className={`tab-pane fade ${current_tab == "formats" ? 'show active' : null}`} role="tabpanel" aria-labelledby="profile-tab">
                                        <IndexFormats
                                            tenant={props.tenant}
                                        />
                                    </div>
                                )}

                                {current_tab == "questions" && (
                                    <div className={`tab-pane fade ${current_tab == "questions" ? 'show active' : null}`} role="tabpanel" aria-labelledby="profile-tab">
                                        <IndexQuestions
                                            tenant={props.tenant}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <label className="btn-shadow btn btn-info mt-2" onClick={() => props.toggle()}>Cerrar</label>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}


export default Index;

