import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import Preloader from '../GeneralComponents/LoadingComponen';
import FormCreate from '../components/settings/RealTimeMessage/FormCreate';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

class RealTimeMessage extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            modeEdit: false,
            
            form: {
                imagen: "", 
                subject: "", 
                link: "",
                plan_id: ""
            },

            selectedOption: {
                label: "Selecione un formato",
                plan_id: ""
            },
        }
    }

    componentDidMount(){
        this.loadData();
    }


    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (real_time_message) => {
        this.setState({
            data: this.state.data.map(item => {
                if (real_time_message.id === item.id) {
                    return {
                        ...item,
                        imagen: real_time_message.imagen,
                        plan: real_time_message.plan,
                        link: real_time_message.link,
                        subject: real_time_message.subject
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            form: {
                imagen: "", 
                subject: "", 
                link: "",
                plan_id: ""
            },

            selectedOption: {
                label: "Selecione un plan",
                plan_id: ""
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/real_time_messages/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                    });
            }
        });
    };

    sendMessage = (real_time_message) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El mensaje será enviado!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Enviar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/send_real_time_messages/${real_time_message.id}`, {
                    method: "post",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.messageSuccess(response);
                });
            }
        });
    };

    validationForm = () => {
        if (this.state.form.format_select_id != "" &&
            this.state.form.question_asociation_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (true) {
            if (this.state.modeEdit) {
                fetch(`/settings/real_time_messages/${this.state.real_time_message_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            } else {
                fetch(`/settings/real_time_messages`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateData(data.register)
                    this.clearValues();
                    this.setState({ modal: false })
                });
            }
        }
    }

    edit = (real_time_message) => {
        this.setState({
            modal: true,
            modeEdit: true,
            real_time_message_id: real_time_message.id,

            form: {
                imagen: real_time_message.imagen, 
                subject: real_time_message.subject, 
                link: real_time_message.link, 
                plan_id: real_time_message.plan.id
            },

            selectedOption: {
                label: real_time_message.plan.name,
                plan_id: real_time_message.plan.id,
            },
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                plan_id: selectedOption.value
            }
        });
    };

    loadData = () => {
        fetch("/settings/get_real_time_messages", {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Mensajes en tiempo real`}
                    subTitle={"Comparte tus formatos con otras empresas"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />


                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar mensaje" : "Crear mensaje"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}

                        //select tenant
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOption={this.state.selectedOption}
                        plans={this.props.plans}
                    />
                )}

                {!this.state.isLoaded ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Url de la imagen</th>
                                            <th>Tema</th>
                                            <th>Plan</th>
                                            <th>Enviar mensaje</th>
                                            <th>Redireccionamiento </th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map(real_time_message => (
                                                <tr key={real_time_message.id}>
                                                    <td>{real_time_message.imagen}</td>
                                                    <td>{ReactHtmlParser(real_time_message.subject)}</td>
                                                    <td>{real_time_message.plan.name}</td>
                                                    <td>
                                                        <button
                                                            className="btn-shadow btn btn-info"
                                                            onClick={() => this.sendMessage(real_time_message)}
                                                        >
                                                            Enviar mensaje
                                                        </button>
                                                    </td>

                                                    <td>{real_time_message.link}</td>
                                                
                                                    <td className="text-right">
                                                        {true && (
                                                            <UncontrolledDropdown className='btn-group'>
                                                                <DropdownToggle className='btn-shadow btn btn-info'>
                                                                    <i className="fas fa-bars"></i>
                                                                </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(real_time_message)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(real_time_message.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                    </React.Fragment>
                ) : (
                    <Preloader />
                )}
            </React.Fragment>
        );
    }
}


export default RealTimeMessage;
WebpackerReact.setup({ RealTimeMessage });