import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FormEdit from './Form'

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            modal: false,
            modeEdit: false,
            showFormEdit: false,

            formUpdate: {
                date_version: "",
                description: "",
            },

            data: []
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
        }
    }

    clearValues = () => {
        this.setState({
            id: "",
            modeEdit: false,
            showFormEdit: false,

            form: {
                format_id: "",
                description: "",
            },

            formUpdate: {
                date_version: "",
                description: "",
            }
        })
    }

    updateItem = (version) => {
        this.setState({
            data: this.state.data.map(item => {
                if (version.id === item.id) {
                    return {
                        ...item,
                        date_version: version.date_version, 
                        description: version.description,
                    }
                }
                return item;
            })
        });
    }

    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    editar = (version) => {
        this.setState({
            modal: true,
            modeEdit: true,
            showFormEdit: true,
            id: version.id,

            formUpdate: {
                date_version: version.date_version,
                description: version.description,
            }
        })
    }
    
    HandleClick = () => {
            fetch(`/formatos/format_versions/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.updateItem(data.register)
                this.clearValues();
                this.setState({ modal: false })
            });
    }

    HandleChange = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                [e.target.name]: e.target.value,
            }
        })
    }

    loadData = () => {
        fetch(`/formatos/get_format_versions/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }

    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.value) {
            fetch(`/formatos/format_versions/${id}`, {
              method: "delete",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.loadData()
                this.props.messageSuccess(response)
                this.props.updateStateVersion(true)
              });
          }
        });
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                {this.state.showFormEdit && (
                                    <div className="col-md-12 mb-3">
                                        <div className="card">
                                            <div className="card-body p-2">
                                                <FormEdit
                                                    form={this.state.formUpdate}
                                                    HandleChange={this.HandleChange}
                                                />
                                            </div>
                                            
                                            <div className="card-footer">
                                                <a
                                                    className="btn-shadow btn btn-info mr-3"
                                                    style={{ color: "#ffff" }}
                                                    onClick={() => this.HandleClick()}
                                                >
                                                    Actualizar
                                                </a>

                                                <a
                                                    className="btn btn-outline-danger"
                                                    onClick={() => this.clearValues()}
                                                >
                                                    Cancelar
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="col-md-12">
                                    <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                                <thead>
                                                    <tr>
                                               
                                                        <th style={{ width: "15%" }}>Versión</th>
                                                        <th style={{ width: "32%" }}>Fecha</th>
                                                        <th style={{ width: "23%" }}>Descripción del cambio</th>
                                                        <th style={{ width: "23%" }}></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.data.length >= 1 ? (
                                                        this.state.data.map(version => (
                                                            <tr key={version.id} style={{ backgroundColor: this.state.id == version.id ? "#c6eaff" : ""}}>
                                                            
                                                                <td>{version.code}</td>
                                                                <td>{version.date_version}</td>
                                                                <td>{version.description}</td>

                                                                <td className="text-right">
                                                                    <UncontrolledDropdown className='btn-group'>
                                                                        <DropdownToggle className='btn btn-info'>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.editar(version)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.delete(version.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        ))

                                                    ) : (
                                                            <tr>
                                                                <td colSpan="8" className="text-center">
                                                                    <div className="text-center mt-4 mb-4">
                                                                        <h4>No hay registros</h4>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;
