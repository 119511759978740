import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import IndexLabels from '../tasksLabels/IndexLabels';
import IndexUsers from '../tasksUsers/IndexUsers';
import IndexProcesos from '../tasksProcesos/IndexProcesos';
import IndexTypes from '../taskTypes/IndexTypes';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import IndexAttached from '../tasksAttached/IndexAttached';
import is_imagen from 'is-image';
import Select from "react-select";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { isMobile } from 'react-device-detect';
import Ckeditor from '../../formatos/Formats/Ckeditor';
import ReactHtmlParser from 'react-html-parser';
import Preloader from '../../../GeneralComponents/LoadingComponen';
import CheckList from '../taskCheckList/ChekList';
import IndexTaskSurvey from '../tasksSurvey/Index';
import IndexComments from '../taskComment/Index';
import IndexUpdateColumn from '../taskUpdateColumn/Index';
import IndexChangeTaskBoard from '../taskChangeTaskBoard/Index';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

//moment
import moment from 'moment';

class ShowTask extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            editDescription: false,
            editTitle: false,
            editOwner: false,
            editFileState: false,
            notificationState: false,
            errorValues: true,
            module_file_id: "",

            //popovers
            showPopoverDate: false,
            showPopoverUsers: false,
            showPopoverFile: false,
            showPopoverLabels: false,
            showPopoverTypes: false,
            showPopoverOwnerUser: false,
            showPopoverChecklist: false,
            showPopoverProcesos: false,
            showPopoverTaskSurvey: false,
            showPopoverChangeTaskBoard: false,

            formUpdateTask: {
                description: "",
                name: "",
                due_date: "",
                user_owner_id: "",
                state: "",
            },

            formUpdate: {
                description: "",
                name: "",
                due_date: "",
                user_owner_id: "",
                state: "",
            },

            formCreateNotification: {
                description: "",
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                user_owner_id: "",
                type_notification: "",
                task_id: this.props.task.id,
            },

            selectedOption: {
                user_id: "",
                label: "Responsable"
            },

            selectedOptionComment: {
                user_ids: [],
                label: "Responsables"
            },
            
            formCreateChecklist: {
                name: "Checklist",
                task_id: this.props.task.id
            },

            check_list_id: "",            
            userOwnerTask: {},
            users: [],
            procesos: [],
            dataComments: [],
            dataFiles: [],
            dataLabels: [],
            dataUsers: [],
            dataProcesos: [],
            dataChecklist: [],
            editValuesUsers: [],
            dataNotifications: [],
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    getDateTime = (datetime) => {
        let date = new Date(datetime)
        let result = date.getFullYear() + "-" + (date.getMonth() < 10 ? 0 : "") + (date.getMonth() + 1) + "-" + (date.getDate() < 10 ? 0 : "") + date.getDate()
        return result
    }


    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };


    updateDataFiles = (data) => {
        this.setState({
            dataFiles: [...this.state.dataFiles, data],
        })
    }

    updateDataNotifications = (data) => {
        this.setState({
            dataNotifications: [...this.state.dataNotifications, data],
        })
    }

    //add update
    updateItemFiles = file => {
        this.setState({
            dataFiles: this.state.dataFiles.map(item => {
            if (file.id === item.id) {
              return { ...item, 
                file: file.file, 
                fileName: file.fileName,
                url: file.url
              }
            }
            return item;
          })
        });
    }

    updateDataLabels = (data) => {
        this.setState({
            dataLabels: data,
        })
    }

    updateDataUsers = (data) => {
        this.setState({
            dataUsers: data
        })
    }

    updateDataProcesos = (data) => {
        this.setState({
            dataProcesos: data
        })
    }

    updateDataComents = (data) => {
        this.setState({
            dataComments: [...this.state.dataComments, data],
        })
    }

    updateDataChecklist = (data) => {
        this.setState({
            dataChecklist: [...this.state.dataChecklist, data],
            showPopoverChecklist: false,
        })
    }
    
    //añadir nuevo item en el checklists
    updateDataItems = (register) => {
        this.setState({
            dataChecklist: this.state.dataChecklist.map(item => {
                if (register.check_list_id === item.id) {
                    return {
                        ...item,
                        check_list_items: [...item.check_list_items, register]
                    }
                }
                return item;
            })
        });
    }

    //actualiza el item del checklist 
    updateCheckListItem = (register) => {
        this.setState({
            dataChecklist: this.state.dataChecklist.map(item => {
                if (register.check_list_id === item.id) {
                    return {
                        ...item,
                        check_list_items: item.check_list_items.map(check_list_item => {
                            if (register.id === check_list_item.id) {
                                return {
                                    ...check_list_item,
                                    date: register.date, 
                                    state: register.state,  
                                    description: register.description,  
                                    position: register.position, 
                                    user_owner: register.user_owner,
                                }
                            }
                            return check_list_item;
                        })
                    }
                }
                return item;
            })
        });
    }

    //elimina un item del checklist
    deleteCheckListItem = (check_list_id, check_list_item_id) => {
        this.setState({
            dataChecklist: this.state.dataChecklist.map(item => {
                if (check_list_id === item.id) {
                    return {
                        ...item,
                        check_list_items: item.check_list_items.filter(check_list_item => check_list_item.id != check_list_item_id),
                    }
                }
                return item;
            })
        });
    }

    //elimina un checklist
    deleteDataCheckList = (check_list_id) => {
        this.setState({
            dataChecklist: this.state.dataChecklist.filter(check_list => check_list.id != check_list_id),
        });
    }
    

    //add update
    updateItemComents = comment => {
        this.setState({
            dataComments: this.state.dataComments.map(item => {
            if (comment.id === item.id) {
              return { ...item, 
                comment: comment.comment,
                users: comment.users, 
              }
            }
            return item;
          })
        });
    }


    updateItemChecklist = ckeck => {
        this.setState({
            dataChecklist: this.state.dataChecklist.map(item => {
            if (ckeck.id === item.id) {
              return { ...item, 
                name: ckeck.name, 
              }
            }
            return item;
          })
        });
    }


    componentDidMount() {
        this.setDefaultValues();
        this.loadFiles();
        this.loadCheckList();
        this.loadDataNotifications();
    }

    setDefaultValues = () => {
        let array = []
        let arrayProcesos = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.procesos.map((item) => (
            arrayProcesos.push({ label: item.name, value: item.id })
        ))

        this.setState({
            formUpdateTask: {
                ...this.state.formUpdateTask,
                description: this.props.task.description,
                name: this.props.task.name,
                due_date: this.props.task.due_date,
                state: this.props.task.state,
                user_owner_id: this.props.task.user_owner != null ? this.props.task.user_owner.id : "",
            },

            formUpdate: {
                ...this.state.formUpdate,
                description: this.props.task.description,
                name: this.props.task.name,
                due_date: this.props.task.due_date,
                state: this.props.task.state,
                user_owner_id: this.props.task.user_owner != null ? this.props.task.user_owner.id : "",
            },

            selectedOption: {
                user_id: `${this.props.task.user_owner != null ? this.props.task.user_owner.id : ""}`,
                label: `${this.props.task.user_owner != null ? this.props.task.user_owner.first_name : "Usuario responsable"} ${this.props.task.user_owner != null ? this.props.task.user_owner.first_last_name : ""}`
            },

            dataLabels: this.props.task.task_labels,
            dataUsers: this.props.task.users,
            dataProcesos: this.props.task.procesos,
            userOwnerTask: this.props.task.user_owner,
            procesos: arrayProcesos,
            users: array
        })
    }

    loadDataNotifications = () => {
        fetch(`/task/get_notification_task/${this.props.task.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataNotifications: data.data,
            });
        });
    }

    HandleChangeCreateNotification = (e, field) => {
        this.setState({
            formCreateNotification: {
                ...this.state.formCreateNotification,
                [e.target.name]: e.target.value,
                type_notification: field,
            }
        });
    }

    getFormm

    validationFormCreateNotification = () => {
        if(this.state.formCreateNotification.type_notification == "due_date"){
            if (this.state.formCreateNotification.due_date!= "" && 
                this.state.formCreateNotification.description != ""
            ) {
                this.setState({ errorValues: true })
                return true
            }else{
                this.setState({ errorValues: false })
                return false
            }
        }else{
            if (this.state.formCreateNotification.user_owner_id!= "" && 
                this.state.formCreateNotification.description != ""
            ) {
                this.setState({ errorValues: true })
                return true
            }else{
                this.setState({ errorValues: false })
                return false
            }
        }
    }


    handleClickCreateNotification = () => {
        if(this.validationFormCreateNotification()){
            fetch(`/task/notification_tasks`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateNotification), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
    
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValuesTask();
                this.getDateValue(this.props.task.due_date);
                this.props.loadData();
                this.updateDataNotifications(data.register);
                this.setState({
                    formUpdateTask: {
                        ...this.state.formUpdateTask,
                        description: data.task.description,
                        name:  data.task.name,
                        due_date: data.task.due_date,
                        user_owner_id: data.task.user_owner_id,
                    },
    
                    formCreateNotification: {
                        description: "",
                        due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                        user_owner_id: "",
                        type_notification: "",
                        task_id: this.props.task.id,
                    },
    
                    formUpdate: {
                        ...this.state.formUpdate,
                        description: data.task.description,
                        name:  data.task.name,
                        due_date: data.task.due_date,
                        user_owner_id: data.task.user_owner_id,
                    },
    
                    userOwnerTask: data.task.user_owner
                })
            });
        }
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            formCreateNotification: {
                ...this.state.formCreateNotification,
                user_owner_id: selectedOption.value
            }
        });
    };

    clearValues = () => {
        this.setState({
            showSubmitComment: false,
            modeEditComment: false, 
            editValuesUsers: [],
            formComment: {
                ...this.state.formComment,
                comment: "",
                notify: "",
                user_ids: [],
            }
        })
    }

    HandleChange = (e) => {
        this.setState({
            formComment: {
                ...this.state.formComment,
                comment: (e.target.value.length + 0 > 500 ? this.state.formComment.comment : e.target.value),
            }
        });
    }

    HandleChangeCheckBox = (e) => {
        this.setState({
            formComment: {
                ...this.state.formComment,
                notify: e.target.checked,
            }
        });
    }

    HandleChangeTaskUpdate = (e) => {
        this.setState({
            formUpdateTask: {
                ...this.state.formUpdateTask,
                [e.target.name]: e.target.value,
            }
        });

        if (e.target.name == "due_date") {
            this.getDateValue(e.target.value)
        }
    }

    HandleClickTaskUpdate = () => {
        fetch(`/task/tasks/${this.props.task.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateTask), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.clearValuesTask();
            this.getDateValue(this.props.task.due_date)
            this.setState({
                formUpdateTask: {
                    ...this.state.formUpdateTask,
                    description: data.task.description,
                    name:  data.task.name,
                    due_date: data.task.due_date,
                    user_owner_id: data.task.user_owner_id,
                },

                formUpdate: {
                    ...this.state.formUpdate,
                    description: data.task.description,
                    name:  data.task.name,
                    due_date: data.task.due_date,
                    user_owner_id: data.task.user_owner_id,
                },

                userOwnerTask: data.task.user_owner
            })
            
            this.props.loadData();
        });
    }

    updateFormTask = (editMoment) => {
        this.setState({
            showSubmitComment: false,
            editDescription: (editMoment == "description" ? true : false),
            editTitle: (editMoment == "title" ? true : false),
            editOwner: (editMoment == "user" ? true : false),
        })
    }

    clearValuesTask = () => {
        this.setState({

            selectedOption: {
                user_id: "",
                label: "Responsable"
            },

            showSubmitComment: false,
            editDescription: false,
            editTitle: false,
            editOwner: false,

            //popovers

            showPopoverDate: false,
            showPopoverFile: false,
            showPopoverUsers: false,
            showPopoverLabels: false,
            showPopoverTypes: false,
            showPopoverOwnerUser: false,
        })
    }

    clearValuesDescription = () => {
        this.setState({
            formUpdateTask: {
                ...this.state.formUpdateTask,
                description: this.state.formUpdate.description,
            },

            editDescription: false,
        })
    }

    clearValuesTitle = () => {
        this.setState({
            formUpdateTask: {
                ...this.state.formUpdateTask,
                name: this.state.formUpdate.name,
            },

            editTitle: false,
        })
    }

    clearValuesDate = () => {
        this.setState({
            formUpdateTask: {
                ...this.state.formUpdateTask,
                due_date: this.props.task.due_date,
            },
        })
    }

    setValues = (showPopover) => {
        this.setState({
            showPopoverDate: (showPopover == "date" ? true : false),
            showPopoverUsers: (showPopover == "users" ? true : false),
            showPopoverFile: (showPopover == "file" ? true : false),
            showPopoverLabels: (showPopover == "labels" ? true : false),
            showPopoverTypes: (showPopover == "types" ? true : false),
            showPopoverOwnerUser: (showPopover == "user" ? true : false),
            showPopoverChecklist: (showPopover == "checklist" ? true : false),
            showPopoverProcesos: (showPopover == "procesos" ? true : false),
            showPopoverTaskSurvey: (showPopover == "survey" ? true : false),
            showPopoverColumn: (showPopover == "column" ? true : false),
            showPopoverChangeTaskBoard: (showPopover == "task_board" ? true : false),
            errorValues: true,
        })
    }

    closePopoversColumn = () => {
        this.setState({
            showPopoverColumn: false,
        })
    }


    closePopovers = () => {
        this.setState({
            showPopoverFile: false
        })
    }

    deleteFiles = id => {
        Swal.fire({
            title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/general_files/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadFiles();
                        this.props.loadData();
                        //this.props.updateItem(response.task)
                    });
            }
        });
    };

    sendNotification = () => {
        this.setState({ notificationState: true })
        fetch(`/task/send_notification/${this.props.task.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.messageSuccess(data)
                this.setState({ notificationState: false })
            });
    }

    getDateValue = (date) => {
        const dateCurrent = new Date();
        const dateCreate = new Date(date).getDate()
        if (dateCreate > dateCurrent) {
            console.log("date-expired")
            return "date-rank"
        } else {
            console.log("date-rank")
            return "date-expired"
        }
    }


    setUpdateState = (state) => {
        fetch(`/task/update_state/${this.props.task.id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {

                if (this.props.updateItem != undefined) {
                    this.props.updateItem(data.task)
                    this.setState({
                        formUpdateTask: {
                            ...this.state.formUpdateTask,
                            state: data.task.state,
                        },
                    })
                }

                if(this.props.loadData != undefined){
                    this.props.loadData();
                    setTimeout(() => {
                        this.setState({
                            formUpdateTask: {
                                ...this.state.formUpdateTask,
                                state: data.task.state,
                            },
                        })
                    }, 1000);
                }

            });
    }

    HandleChangeUpdateStepDescription = (e) => {
        this.setState({
            formUpdateTask:{
                ...this.state.formUpdateTask,
                description: e
            }
        })
    }
    
    loadFiles = () => {
        fetch(`/settings/get_general_files_normativity/${this.props.task.module_file_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataFiles: data.data,
                    isLoadedFiles: false
                });
            });
    }

    loadCheckList = () => {
        fetch(`/task/get_check_list/${this.props.task.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataChecklist: data.data,
            });
        });
    }

    updateDate = () => {
        fetch(`/task/update_date/${this.props.task.id}/${this.state.formUpdateTask.due_date}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                formUpdateTask:{
                    ...this.state.formUpdateTask,
                    due_date: data.register.due_date
                },

                showPopoverDate: false
            })
        });
    }

    HandleClickCheckList = () => {
        fetch(`/task/check_lists`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.formCreateChecklist), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.updateDataChecklist(data.register)
            this.props.loadData();
        });
    }

    HandleChangeChecklist = (e) => {
        this.setState({
            formCreateChecklist: {
                ...this.state.formCreateChecklist,
                [e.target.name]: e.target.value,
            }
        });
    }

    handleChangeAutocompleteMulti = selectedOption => {
        let array = []

        selectedOption.map((item) => (
            array.push(item.value)
        ))

        if(this.state.modeEditComment){
            this.setState({
                formUpdateComment: {
                    ...this.state.formUpdateComment,
                    user_ids: array
                }
            })
        }else{
            this.setState({
                formComment: {
                    ...this.state.formComment,
                    user_ids: array
                }
            })
        }

    }

    closePopoverIndexAttached = () => {
        this.setState({
            editFileState: false,
            module_file_id: "",
        })
    }

    render() {
        const options = {

            buttons: {
                iconColor: "rgb(255, 255, 255)",
                showDownloadButton: false,
                showNextButton: false,
                showPrevButton: false,
                showAutoplayButton: false,
            },

            thumbnails: {
                showThumbnails: false,
            },

            caption: {
                captionFontFamily: "Raleway, sans-serif",
                captionFontWeight: "300",
                captionTextTransform: "uppercase",
            }
        };

        const config = {
            language: 'es',
            quickInsertEnabled: false,
            colorsHEXInput: true,
            autoFocus: true,
            toolbartop: true,
            linkAlwaysBlank: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            htmlExecuteScripts: true,
            key: this.props.key_text,
            imageUploadToS3: this.props.imageUpload,
            iframe: true,
            tabSpaces: 4,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass'],

                    // Alignment of the group in the toolbar.
                    align: 'left',

                    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                    buttonsVisible: 10
                },


                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL'],
                    align: 'left',
                    buttonsVisible: 10
                },

                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly'],
                    align: 'left',
                    buttonsVisible: 10
                },
            }
        }
        
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader className="modal-header-task" toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i>
                        Tarea / T{this.props.task.count != 0 ? this.props.task.count : ""} Responsable: { this.state.userOwnerTask != null ? this.state.userOwnerTask.first_name : ""} {this.state.userOwnerTask != null ? this.state.userOwnerTask.first_last_name : ""}<br />
                        {this.props.task.task_source != "" && (
                            <a 
                                href={this.props.task.url_source}
                                target="_blank"
                                style={{ fontSize: "15px" }}
                            >
                                {this.props.task.task_source}
                            </a>
                        )}
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="card">
                                    <div className="ui-showTask-container-subtitle-container">
                                        <div><span>Título Tarea</span></div>
                                        <div className='ui-showatask-subtile-date'><span>Fecha Creación</span></div>
                                        <div className='ui-showatask-subtile-date'><span>Fecha Vencimiento</span></div>
                                    </div>
                                    <div className="card-body">
                                        <div className="ui-showTask-container-title-date">                                                                                
                                            <div className="ui-showTask-title-task">
                                                {(this.state.editTitle && this.props.task.state != 'filed') ? (
                                                    <input
                                                        type="text"
                                                        className="form form-control"
                                                        placeholder="Añadir una descripción más detallada..."
                                                        name="name"
                                                        value={this.state.formUpdateTask.name}
                                                        onChange={this.HandleChangeTaskUpdate}
                                                    />
                                                ) : (
                                                    <h5 onClick={() => this.props.task.edit_task ? this.updateFormTask("title") : ""}>{this.state.formUpdateTask.name}</h5>
                                                )}
                                            </div>
                                            <div className="ui-showTask-date-create">
                                            <h5><span className='ui-showTask-subtitleMobile'>Fecha Creación:</span>
                                                {this.props.task.created_date}
                                            </h5>
                                            </div>

                                            <div className="ui-showTask-date-finish">
                                            
                                                <h5><span className='ui-showTask-subtitleMobile'>Fecha Vencimiento:</span>
                                                    {this.state.formUpdateTask.due_date}
                                                </h5>
                                             </div>
                                        </div>
                                    </div>

                                    {(this.state.editTitle && this.props.task.state != 'filed') && (
                                        <div className="card-footer">
                                            <button className="btn-shadow btn btn-success mr-2" onClick={() => this.HandleClickTaskUpdate()}>Actualizar</button>
                                            <button className="btn btn-outline-danger" onClick={() => this.clearValuesTitle()}>Cancelar</button>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-9">
                                <div className="row">
                                        
                                    {this.state.dataLabels.length >= 1 && (
                                        <div className="col-md-12 mb-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <b>ETIQUETAS</b>
                                                </div>
                                                <div className="card-body">

                                                    {this.state.dataLabels.map((estados, index) => (
                                                        <span style={{ background: estados.color }} className={`badge badge-secondary mt-2 ${index >= 0 ? "mr-2" : ""}`}>
                                                            {estados.name}
                                                        </span>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.dataUsers.length >= 1 && (
                                        <div className="col-md-12 mb-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <b>GESTORES</b>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        {this.state.dataUsers.map((user, index) => (
                                                            <span className="ml-2 link-line-dark">{user.first_name} {user.first_last_name}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.dataProcesos.length >= 1 && (
                                        <div className="col-md-12 mb-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <b>PROCESOS</b>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        {this.state.dataProcesos.map((proceso, index) => (
                                                            <span className="ml-2 link-line-dark">{proceso.name}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-md-12">
                                        <div className="row">

                                            <div className='col-md-1 text-center'>
                                                <i
                                                    className="fas fa-bars"
                                                    style={{ fontSize: "29px" }}
                                                >

                                                </i>
                                            </div>

                                            <div className='col-md-11'>
                                                <b className='ui-showTask-Description'>Descripción</b>
                                                <div className="card ui-showTask__container_card">
                                                    <div className="card-body ui-showTask-card-body">
                                                        {this.props.isLoadedShow ? (
                                                            <div className="col-md-12 text-left ui-showTask__container__load">
                                                                <p className="mb-0 ui-showTask__load">Cargando..</p>
                                                            </div>
                                                        ) : (
                                                            <React.Fragment>
                                                                {(this.state.editDescription && this.props.task.state != 'filed') ? (
                                                                    <FroalaEditor
                                                                        model={this.state.formUpdateTask.description}
                                                                        config={config}
                                                                        onModelChange={(e) => this.HandleChangeUpdateStepDescription(e)}
                                                                    />
                                                                ) : (
                                                                    <p className="mb-0 ui-showTask__data-froala" onClick={() => this.props.task.edit_task ? this.updateFormTask("description") : ""}>
                                                                        {this.state.formUpdateTask.description != "" ? ReactHtmlParser(this.state.formUpdateTask.description) : "Descripción"}
                                                                    </p>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </div>

                                                    {(this.state.editDescription && this.props.task.state != 'filed') && (
                                                        <div className="card-footer">
                                                            <button className="btn-shadow btn btn-success mr-2" onClick={() => this.HandleClickTaskUpdate()}>Actualizar</button>
                                                            <button className="btn btn-outline-danger" onClick={() => this.clearValuesDescription()}>Cancelar</button>
                                                        </div>
                                                    )}
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    {this.state.dataFiles.length >= 1 && (
                                        <div className="col-md-12 mt-4">
                                            <div className="row">
                                                <div className='col-md-1 text-center'>
                                                    <i
                                                        className="fas fa-paperclip"
                                                        style={{ fontSize: "29px" }}
                                                    >
                                                    </i>
                                                </div>

                                                <div className='col-md-11 ui-showTask-adjunto'>
                                                    <h4>Adjuntos</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.dataFiles.length >= 1 && (
                                        <div className="col-md-12 mb-4 mt-4">
                                            {this.state.dataFiles.map((file, index) => (
                                                <div key={file.id} className={`row ${index >= 1 ? 'mt-3' : ""}`}>
                                                    <div className='col-md-1 text-center'>

                                                    </div>

                                                    <div className='col-md-11'>

                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="row task-file">
                                                                    {file.type_file == "url" ? (
                                                                        <div className={`col-md-4 task-file-image`}>
                                                                            <a href={file.url} target="_blank">Ir al link </a>
                                                                        </div>
                                                                    ) : (
                                                                        <div className={`col-md-4 task-file-image`}>
                                                                            {file.file != undefined && (
                                                                                <React.Fragment>
                                                                                    {is_imagen(file.file.url) ? (
                                                                                        <SimpleReactLightbox>
                                                                                            <SRLWrapper options={options}>
                                                                                                <img
                                                                                                    src={file.file.url}
                                                                                                    alt={file.fileName}
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                />
                                                                                            </SRLWrapper>
                                                                                        </SimpleReactLightbox>
                                                                                    ) : (
                                                                                            <i
                                                                                                className="fas fa-file-alt"
                                                                                                style={{ fontSize: "45px", color: "#d8d8d8" }}
                                                                                            >

                                                                                            </i>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </div>
                                                                    )}


                                                                    {file.file != undefined && (
                                                                        <div className={`col-md-8 task-file-info`}>
                                                                            <div className="task-file-name">{file.fileName}</div>
                                                                            <div className="task-file-options ui-showTask-fileOptions">

                                                                                {(file.type_file == "archivo" && file.type_file != "url" || file.file.url != undefined) && (
                                                                                    <a className="link-line" target="_blank" href={file.file.url}>
                                                                                        {is_imagen(file.file.url) ? "Descargar" : "Descargar"}
                                                                                    </a>
                                                                                )}

                                                                                {(file.user_register.id == this.props.user.id && this.props.task.state != 'filed') && (
                                                                                    <div className="task-file-options-edit ui-showTask-fileOptionEdit">

                                                                                        <span className="link-line action-comment" id={`PopoverLegacy${file.id}`} onClick={() => this.setState({ editFileState: !this.state.editFileState, module_file_id: file.id })}>Editar</span>

                                                                                        <UncontrolledPopover isOpen={this.state.editFileState && this.state.module_file_id == file.id} trigger="legacy" placement="bottom" target={`PopoverLegacy${file.id}`}>
                                                                                            <PopoverHeader>{this.state.editFileState ? "Editar Archivo" : "Adjuntar archivo"}</PopoverHeader>
                                                                                            <PopoverBody>
                                                                                                <IndexAttached
                                                                                                    task={this.props.task}
                                                                                                    moduleName={"Tareas"}
                                                                                                    editFileState={this.state.editFileState}
                                                                                                    valuesFiles={this.state.editFileState ? file : {}}
                                                                                                    closePopovers={this.closePopovers}
                                                                                                    updateItem={this.props.updateItem}
                                                                                                    updateItemFiles={this.updateItemFiles}
                                                                                                    loadData={this.props.loadData}
                                                                                                    company={this.props.company}
                                                                                                    closePopoverIndexAttached={this.closePopoverIndexAttached}
                                                                                                />
                                                                                            </PopoverBody>
                                                                                        </UncontrolledPopover>


                                                                                        <span className="link-line action-comment" onClick={() => this.deleteFiles(file.id)}>Eliminar</span>

                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {this.state.dataChecklist.length >= 1 && (
                                        <CheckList
                                            dataChecklist={this.state.dataChecklist}
                                            loadCheckList={this.loadCheckList}
                                            messageSuccess={this.messageSuccess}
                                            updateData={this.props.updateData}
                                            users={this.props.users}
                                            user={this.props.user}
                                            task={this.props.task}
                                            loadData={this.props.loadData}
                                            updateItemChecklist={this.updateItemChecklist}
                                            updateItem={this.props.updateItem}
                                            showFields={true}

                                            //update data 
                                            updateDataItems={this.updateDataItems}
                                            updateCheckListItem={this.updateCheckListItem}
                                            deleteCheckListItem={this.deleteCheckListItem}
                                            deleteDataCheckList={this.deleteDataCheckList}
                                        />
                                    )}

                                    <IndexComments
                                        task={this.props.task}
                                        user={this.props.user}
                                        updateItem={this.props.updateItem}
                                        loadData={this.props.loadData}
                                    />

                                    {this.state.dataNotifications.length >= 1 && (
                                        <div className="col-md-12 mt-4">
                                            <div className="row">
                                                <div className='col-md-1 text-center'>
                                                    <i className ="fas fa-sync-alt"
                                                    // "fas fa-clipboard-list"
                                                    // "fas fa-book-reader"
                                                        style={{ fontSize: "29px" }}
                                                    >
                                                    </i>
                                                </div>

                                                <div className='col-md-11 ui-showTask-adjunto'>
                                                    <h4>Historial de cambios</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.dataNotifications.length >= 1 && (
                                        <div className="col-md-12 mb-4 mt-4">
                                            <div className={`row`}>
                                                <div className='col-md-1 text-center'>

                                                </div>

                                                <div className='col-md-11'>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            {this.state.dataNotifications.map((notification, index) => (
                                                                <React.Fragment>
                                                                    {ReactHtmlParser(notification.description)}
                                                                    {index >= 0 ? <hr/> : null }
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    
                                </div>
                            </div>


                            {this.state.formUpdateTask.state != 'filed' && (
                                <div className={`col-md-3 ${isMobile ? "mt-3" : ""}`}>
                                    <h6 className="text-center">OPCIONES</h6>
                                    {this.props.task.edit_task &&(
                                        <React.Fragment>   
                                            <div className="">
                                                <button
                                                    id="PopoverUsers"
                                                    type="button"
                                                    className="btn-shadow btn btn-primary text-left"
                                                    style={{ width: "100%" }}
                                                    onClick={() => this.setValues("users")}

                                                >
                                                    <i className="fas fa-users"></i> Gestores
                                                    </button>

                                                <UncontrolledPopover isOpen={this.state.showPopoverUsers} placement="bottom" target="PopoverUsers">
                                                    <PopoverHeader>Selecciona un gestor <a className="close-popover" onClick={() => this.setState({ showPopoverUsers: (!this.state.showPopoverUsers) })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                    <PopoverBody>
                                                        <IndexUsers 
                                                            task={this.props.task} 
                                                            updateDataUsers={this.updateDataUsers}
                                                            taskUsers={this.state.dataUsers}
                                                            users={this.props.users} 
                                                        />
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                            </div>

                                            <div className="mt-2">
                                                <button
                                                    id="PopoverProcesos"
                                                    type="button"
                                                    className="btn-shadow btn btn-primary text-left"
                                                    style={{ width: "100%" }}
                                                    onClick={() => this.setValues("procesos")}

                                                >
                                                    <i className="fas fa-users"></i> Procesos
                                                    </button>

                                                <UncontrolledPopover isOpen={this.state.showPopoverProcesos} placement="bottom" target="PopoverProcesos">
                                                    <PopoverHeader>Selecciona procesos <a className="close-popover" onClick={() => this.setState({ showPopoverProcesos: (!this.state.showPopoverProcesos) })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                    <PopoverBody>
                                                        <IndexProcesos 
                                                            task={this.props.task} 
                                                            updateDataProcesos={this.updateDataProcesos}
                                                            taskProcesos={this.state.dataProcesos}
                                                            procesos={this.props.procesos} 
                                                        />
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    <div className="mt-2 mb-2">
                                        <button
                                            id="PopoverLabels"
                                            type="button"
                                            className="btn-shadow btn btn-primary text-left"
                                            style={{ width: "100%" }}
                                            onClick={() => this.setValues("labels")}

                                        >
                                            <i className="fas fa-tags"></i> Etiquetas
                                         </button>

                                        <UncontrolledPopover isOpen={this.state.showPopoverLabels} placement="bottom" target="PopoverLabels">
                                            <PopoverHeader>Añade etiquetas <a className="close-popover" onClick={() => this.setState({ showPopoverLabels: (!this.state.showPopoverLabels) })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                            <PopoverBody>
                                                <IndexLabels
                                                    task={this.props.task}
                                                    loadDataTaskShow={this.props.loadTasks}
                                                    loadData={this.props.loadData}
                                                    user={this.props.user}
                                                    updateItem={this.props.updateItem}
                                                    dataLabels={this.state.dataLabels}
                                                    updateDataLabels={this.updateDataLabels}
                                                    loadCheckList={this.loadCheckList}
                                                />
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </div>

                                    {this.props.task.edit_task &&(
                                        <div className="mt-2 mb-2">
                                            <button
                                                id="Popoverchecklist"
                                                type="button"
                                                className="btn-shadow btn btn-primary text-left"
                                                style={{ width: "100%" }}
                                                onClick={() => this.setValues("checklist")}

                                            >
                                                <i className="fas fa-check-double"></i> Checklist
                                            </button>

                                            <UncontrolledPopover isOpen={this.state.showPopoverChecklist} placement="bottom" target="Popoverchecklist">
                                                <PopoverHeader>Añade Checklist <a className="close-popover" onClick={() => this.setState({ showPopoverChecklist: (!this.state.showPopoverChecklist) })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                <PopoverBody>
                                                    <div className="row">
                                                        <div className="col-md-12" style={{ width: isMobile ? "" : "289px" }}>
                                                            <input
                                                                type="text"
                                                                className="form form-control"
                                                                name="name"
                                                                value={this.state.formCreateChecklist.name}
                                                                onChange={(e) => this.HandleChangeChecklist(e)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 text-center mt-3 p-0">
                                                        <hr />
                                                        <a
                                                            className="btn-shadow btn btn-light btn-block mr-3"
                                                            style={{ color: "#1ba8fa" }}
                                                            onClick={() => this.HandleClickCheckList()}
                                                        >
                                                            Añadir
                                                        </a>
                                                    </div>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </div>
                                    )}

                                    <div className="">
                                        <button
                                            id="PopoverLegacy"
                                            type="button"
                                            className="btn-shadow btn btn-primary text-left"
                                            style={{ width: "100%" }}
                                            onClick={() => this.setValues("file")}
                                        >

                                            <i className="far fa-file-alt"></i> Adjuntos
                                        </button>

                                        <UncontrolledPopover isOpen={this.state.showPopoverFile} placement="bottom" target="PopoverLegacy">
                                            <PopoverHeader>{this.state.editFileState ? "Editar Archivo o Url" : "Añadir nuevo archivo o Url"} <a className="close-popover" onClick={() => this.setState({ showPopoverFile: (!this.state.showPopoverFile) })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                            <PopoverBody>
                                                <IndexAttached
                                                    task={this.props.task}
                                                    editFileState={false}
                                                    moduleName={"Tareas"}
                                                    valuesFiles={this.state.editFileState ? {} : {}}
                                                    closePopovers={this.closePopovers}
                                                    updateItem={this.props.updateItem}
                                                    updateDataFiles={this.updateDataFiles}
                                                    loadData={this.props.loadData}
                                                    company={this.props.company}
                                                />
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </div>

                                    {true && (
                                        <div className="mt-2">
                                            <button
                                                id="OwnerUser"
                                                type="button"
                                                className="btn-shadow btn btn-primary text-left"
                                                style={{ width: "100%" }}
                                                onClick={() => this.setValues("user")}
                                            >
                                                <i className="fas fa-user"></i> Usuario responsable
                                            </button>

                                            <UncontrolledPopover isOpen={this.state.showPopoverOwnerUser} placement="bottom" target="OwnerUser">
                                                <PopoverHeader>Cambiar al responsable de la tarea<a className="close-popover" onClick={() => this.setState({ showPopoverOwnerUser: (!this.state.showPopoverOwnerUser), errorValues: true, formCreateNotification: { ...this.state.formCreateNotification, description: "", user_owner_id: "", type_notification: ""} })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                <PopoverBody>
                                                    <div className="row">
                                                        <div className="col-md-12" style={{ width: isMobile ? "" : "289px" }}>
                                                            <input
                                                                type="hidden"
                                                                name="user_id"
                                                                value={this.state.selectedOption.user_id}
                                                            />

                                                            <Select
                                                                onChange={this.handleChangeAutocomplete}
                                                                options={this.props.users}
                                                                autoFocus={false}
                                                                className={`link-form`}
                                                                value={this.state.selectedOption}
                                                            />
                                                        </div>

                                                        <div className="col-md-12 mt-3 mb-2" style={{ width: isMobile ? "" : "289px" }}>
                                                            <textarea
                                                                name="description"
                                                                className={`form form-control ${!this.state.errorValues && this.state.formCreateNotification.description == "" ? "error-class" : ""}`}
                                                                value={this.state.formCreateNotification.description}
                                                                onChange={(e) => this.HandleChangeCreateNotification(e, "user_owner_id")}
                                                                placeholder="Motivo del cambio de responsable..."
                                                            />
                                                        </div>

                                                        
                                                        <div className="col-md-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <span className="ml-2">{this.state.userOwnerTask != null ? this.state.userOwnerTask.first_name : ""} {this.state.userOwnerTask != null ? this.state.userOwnerTask.first_last_name : ""}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        

                                                    </div>

                                                    <div className="col-md-12 text-center mt-3 p-0">
                                                        <hr />
                                                        <a
                                                            className="btn-shadow btn btn-light btn-block mr-3"
                                                            style={{ color: "#1ba8fa" }}
                                                            onClick={() => this.handleClickCreateNotification()}
                                                        >
                                                            Cambiar usuario
                                                        </a>
                                                    </div>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </div>
                                    )}

                                    {true && (
                                        <div className="mt-2">
                                            <button
                                                id="dateUpdate"
                                                type="button"
                                                className="btn-shadow btn btn-primary text-left"
                                                style={{ width: "100%" }}
                                                onClick={() => this.setValues("date")}
                                            >
                                                <i className="far fa-calendar-alt"></i> Fecha de compromiso
                                            </button>

                                            <UncontrolledPopover isOpen={this.state.showPopoverDate} placement="bottom" target="dateUpdate">
                                                <PopoverHeader>Cambiar la fecha de compromiso <a className="close-popover" onClick={() => this.setState({ showPopoverDate: (!this.state.showPopoverDate), errorValues: true, formCreateNotification: { ...this.state.formCreateNotification, description: "", due_date: isMobile ? moment().format('YYYY-MM-DD') : '', type_notification: ""}  })}> <i className="fas fa-times"></i></a> </PopoverHeader>
                                                <PopoverBody>
                                                    <div className="row">
                                                        <div className="col-md-12" style={{ width: isMobile ? "" : "289px" }}>
                                                            <input
                                                                type="date"
                                                                className="form form-control"
                                                                name="due_date"
                                                                value={this.state.formCreateNotification.due_date}
                                                                onChange={(e) => this.HandleChangeCreateNotification(e, "due_date")}
                                                            />
                                                        </div>

                                                        <div className="col-md-12 mt-3" style={{ width: isMobile ? "" : "289px" }}>
                                                            <textarea
                                                                name="description"
                                                                className={`form form-control ${!this.state.errorValues && this.state.formCreateNotification.description == "" ? "error-class" : ""}`}
                                                                value={this.state.formCreateNotification.description}
                                                                onChange={(e) => this.HandleChangeCreateNotification(e, "due_date")}
                                                                placeholder="Motivo del cambio de fecha..."
                                                            />
                                                        </div>

                                                        <div className="col-md-12 text-center mt-2">
                                                            <hr />
                                                            <a
                                                                className="btn-shadow btn btn-light btn-block mr-3"
                                                                style={{ color: "#1ba8fa" }}
                                                                onClick={() => this.handleClickCreateNotification()}
                                                            >
                                                                Actualizar fecha
                                                            </a>
                                                        </div>

                                                    </div>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </div>
                                    )}


                                    {false && (
                                        <div className="mt-2">
                                            <button
                                                id="survey"
                                                type="button"
                                                className="btn-shadow btn btn-primary text-left"
                                                style={{ width: "100%" }}
                                                onClick={() => this.setValues("survey")}
                                            >
                                                <i className="far fa-sticky-note"></i> Asociar registro
                                            </button>

                                            <UncontrolledPopover isOpen={this.state.showPopoverTaskSurvey} placement="bottom" target="survey">
                                                <PopoverHeader>Añadir identificador del registro <a className="close-popover" onClick={() => this.setState({ showPopoverTaskSurvey: (!this.state.showPopoverTaskSurvey) })}> <i className="fas fa-times"></i></a> </PopoverHeader>
                                                <PopoverBody>
                                                    <IndexTaskSurvey
                                                        task={this.props.task}
                                                        messageSuccess={this.messageSuccess}
                                                        closePopoversTaskSurvey={this.closePopoversTaskSurvey}
                                                        updateItem={this.props.updateItem}
                                                    />
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </div>
                                    )}  

                                    {true && (
                                        <div className="mt-2">
                                            <button
                                                type="button"
                                                className="btn-shadow btn btn-primary text-left"
                                                style={{ width: "100%" }}
                                                onClick={() => this.sendNotification()}
                                                disabled={this.state.notificationState}
                                            >
                                                <i className="fas fa-envelope"></i> {this.state.notificationState ? "Notificando.." : "Notificar responsable"}
                                            </button>
                                        </div>
                                    )}

                                    {true && (
                                        <div className="mt-2">
                                            <button
                                                id="column"
                                                type="button"
                                                className="btn-shadow btn btn-primary text-left"
                                                style={{ width: "100%" }}
                                                onClick={() => this.setValues("column")}
                                            >
                                                <i className="far fa-sticky-note"></i> Cambiar de columna
                                            </button>

                                            <UncontrolledPopover isOpen={this.state.showPopoverColumn} placement="bottom" target="column">
                                                <PopoverHeader>Cambiar de columna <a className="close-popover" onClick={() => this.setState({ showPopoverColumn: (!this.state.showPopoverColumn) })}> <i className="fas fa-times"></i></a> </PopoverHeader>
                                                <PopoverBody>
                                                    <IndexUpdateColumn
                                                        task={this.props.task}
                                                        messageSuccess={this.messageSuccess}
                                                        closePopoversColumn={this.closePopoversColumn}
                                                        updateItem={this.props.updateItem}
                                                        loadData={this.props.loadData}
                                                    />
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </div>
                                    )}  

                                    {this.props.task.edit_task && (
                                        <div className="mt-2">
                                            <button
                                                id="task_board"
                                                type="button"
                                                className="btn-shadow btn btn-primary text-left"
                                                style={{ width: "100%" }}
                                                onClick={() => this.setValues("task_board")}
                                            >
                                                <i className="far fa-sticky-note"></i> Cambiar tablero
                                            </button>

                                            <UncontrolledPopover isOpen={this.state.showPopoverChangeTaskBoard} placement="bottom" target="column">
                                                <PopoverHeader>Cambiar tablero <a className="close-popover" onClick={() => this.setState({ showPopoverChangeTaskBoard: (!this.state.showPopoverChangeTaskBoard) })}> <i className="fas fa-times"></i></a> </PopoverHeader>
                                                <PopoverBody>
                                                    <IndexChangeTaskBoard
                                                        task={this.props.task}
                                                        messageSuccess={this.messageSuccess}
                                                        updateItem={this.props.updateItem}
                                                        loadData={this.props.loadData}
                                                        closeModal={this.props.toggle}
                                                    />
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </div>
                                    )}  

                                    

                                </div>
                            )}
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <button
                            className="btn btn-outline-danger"
                            onClick={() => this.props.toggle()}
                        >
                            Cerrar
                        </button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ShowTask;