import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

const FormCreate = (props) => {
    
    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <div className="ui-index-configurationFormats__container-inputs card">
                    <div className="card-body">
                        <div className="ui-index-configurationFormats_container-subject-name">
                            <div className="ui-index-configuration_format-name">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    className='form form-control'
                                    name="name"
                                    value={props.formValues.name}
                                    onChange={(e) => props.onChangeForm(e)}
                                />
                            </div>

                            <div className="ui-index-configuration_format-subject">
                                <label>Asunto</label>
                                <input
                                    type="text"
                                    className='form form-control'
                                    name="subject"
                                    value={props.formValues.subject}
                                    onChange={(e) => props.onChangeForm(e)}
                                />
                            </div>
                        </div>

                        <div className="mt-3">
                            <label>Contenido</label>
                            <FroalaEditor
                                model={props.formValues.content}
                                config={props.config}
                                onModelChange={(e) => props.onChangeForm({ target: { name: "content", value: e } } )}
                            />
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className="btn-shadow btn btn-primary mr-2" onClick={props.submitForm}>{props.nameBnt}</button>
                        <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
}


export default FormCreate;

