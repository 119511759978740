import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import Ckaditor from '../../../formatos/Formats/Ckeditor';
import { isMobile } from 'react-device-detect';

class FormCreateMejora extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-md-12">

                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                            <label>Descripción del (Riesgo - Problema - Peligro)</label>
                                                <textarea 
                                                    name="description" 
                                                    className={`form form-control ${this.props.errorValues == false && this.props.formValues.description == "" ? "error-class" : ""}`}
                                                    value={this.props.formValues.description}
                                                    onChange={this.props.onChangeForm}
                                                    rows="4"
                                                >

                                                </textarea>
                                            </div>
                          
                                            <div className="col-md-6 mb-1">
                                                <label>Clasificación del riesgo </label>
                                                <select 
                                                    name="risk_type" 
                                                    className={`form form-control ${this.props.errorValues == false && this.props.formValues.risk_type == "" ? "error-class" : ""}`}
                                                    value={this.props.formValues.risk_type}
                                                    onChange={this.props.onChangeForm}
                                                >
                                                    <option value="">Seleccione un tipo</option>
                                                    <option value="Riesgo">Riesgo</option>
                                                    <option value="Problema">Problema</option>
                                                    <option value="Peligro">Peligro</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 mb-1">
                                                <label>Cargo </label>
                                                <input
                                                    type="hidden"
                                                    name="criterio_id"
                                                    value={this.props.selectedOptionCriterios.criterio_id}
                                                />

                                                <Select
                                                    onChange={this.props.handleChangeAutocompleteCriterios}
                                                    options={this.props.criterios}
                                                    autoFocus={false}
                                                    className={`link-form ${this.props.errorValues == false && this.props.formValues.criterio_id == "" ? "error-class" : ""}`}
                                                    value={this.props.selectedOptionCriterios}
                                                />
                                            </div>

                                            <div className="col-md-12"><hr/></div>

                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className={`col-md-4`}>
                                                        <table className="table table-hover table-striped table-bordered" id="sampleTable" style={{ marginBottom:"5px"}}>
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th colSpan="3" style={{ backgroundColor: (this.props.errorValues == false && this.props.formValues.probability == "" ? "#c02f2c" : "#02295f"), color: "#fff" }}>{this.props.errorValues == false && this.props.formValues.probability == "" ? "Seleccionar 1" : "Probabilidad"}</th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#9a9a9a", color: "#fff" }}>Improbable</th>
                                                                    <th>1</th>
                                                                    <th><input type="radio" name="probability" value={1} checked={this.props.formValues.probability == 1 ? true : false} onChange={this.props.onChangeForm}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#44b051", color: "#fff" }}>Remota</th>
                                                                    <th>2</th>
                                                                    <th><input type="radio" name="probability" value={2} checked={this.props.formValues.probability == 2 ? true : false} onChange={this.props.onChangeForm}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#ffc23b", color: "#fff" }}>Ocasional</th>
                                                                    <th>3</th>
                                                                    <th><input type="radio" name="probability" value={3} checked={this.props.formValues.probability == 3 ? true : false} onChange={this.props.onChangeForm}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#fa403c", color: "#fff" }}>Probable</th>
                                                                    <th>4</th>
                                                                    <th><input type="radio" name="probability" value={4} checked={this.props.formValues.probability == 4 ? true : false} onChange={this.props.onChangeForm}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#7f1c1a", color: "#fff" }}>Altamente Probable</th>
                                                                    <th>5</th>
                                                                    <th><input type="radio" name="probability" value={5} checked={this.props.formValues.probability == 5 ? true : false} onChange={this.props.onChangeForm}/></th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>

                                                    <div className={`col-md-4 ${isMobile ? "mt-4 mb-4" : ""}`}>
                                                        <table className="table table-hover table-striped table-bordered" id="sampleTable" style={{ marginBottom:"5px"}}>
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th colSpan="3" style={{ backgroundColor: (this.props.errorValues == false && this.props.formValues.impact == "" ? "#c02f2c" : "#02295f"), color: "#fff" }}>{this.props.errorValues == false && this.props.formValues.impact == "" ? "Seleccionar 1" : "Impacto"}</th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#9a9a9a", color: "#fff" }}>Insignificante</th>
                                                                    <th>1</th>
                                                                    <th><input type="radio" name="impact" value={1} checked={this.props.formValues.impact == 1 ? true : false} onChange={this.props.onChangeForm}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#44b051", color: "#fff" }}>Baja gravedad</th>
                                                                    <th>2</th>
                                                                    <th><input type="radio" name="impact" value={2} checked={this.props.formValues.impact == 2 ? true : false} onChange={this.props.onChangeForm}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#ffc23b", color: "#fff" }}>Grave</th>
                                                                    <th>3</th>
                                                                    <th><input type="radio" name="impact" value={3} checked={this.props.formValues.impact == 3 ? true : false} onChange={this.props.onChangeForm}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#fa403c", color: "#fff" }}>Muy Grave</th>
                                                                    <th>4</th>
                                                                    <th><input type="radio" name="impact" value={4} checked={this.props.formValues.impact == 4 ? true : false} onChange={this.props.onChangeForm}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#c02f2c", color: "#fff" }}>Catastrófico</th>
                                                                    <th>5</th>
                                                                    <th><input type="radio" name="impact" value={5} checked={this.props.formValues.impact == 5 ? true : false} onChange={this.props.onChangeForm}/></th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>

                                                    <div className={`col-md-4`}>
                                                        <table className="table table-hover table-striped table-bordered" id="sampleTable" style={{ marginBottom:"5px"}}>
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th colSpan="3" style={{ backgroundColor: (this.props.errorValues == false && this.props.formValues.possible_involvement_array.length == 0 ? "#c02f2c" : "#16aaff"), color: "#fff" }}>{this.props.errorValues == false && this.props.formValues.possible_involvement_array.length == 0 ? "Seleccionar al menos 1" : "Posible Afectación"}</th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#02295f", color: "#fff" }}>Paciente</th>
                                                                    <th><input type="checkbox" name="probability" checked={this.props.formValues.possible_involvement_array.includes("Paciente")} value={"Paciente"} onChange={this.props.onChangeFormCheckbox}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#02295f", color: "#fff" }}>Personal Interno</th>
                                                                    <th><input type="checkbox" name="probability"  checked={this.props.formValues.possible_involvement_array.includes("Personal de salud")} value={"Personal de salud"} onChange={this.props.onChangeFormCheckbox}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#02295f", color: "#fff" }}>Empresa de Salud</th>
                                                                    <th><input type="checkbox" name="probability" checked={this.props.formValues.possible_involvement_array.includes("Empresa de Salud")} value={"Empresa de Salud"} onChange={this.props.onChangeFormCheckbox}/></th>
                                                                </tr>

                                                                <tr>
                                                                    <th style={{ backgroundColor: "#02295f", color: "#fff" }}>Externos</th>
                                                                    <th><input type="checkbox" name="probability" checked={this.props.formValues.possible_involvement_array.includes("Externos")} value={"Externos"} onChange={this.props.onChangeFormCheckbox}/></th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12"><hr/></div>
                                            <div className="col-md-6 mb-3">
                                                <label>Actividad de riesgo</label>
                                                <input
                                                    type="hidden"
                                                    name="risk_activity_id"
                                                    value={this.props.selectedOptionActivities.risk_activity_id}
                                                />

                                                <Select
                                                    onChange={this.props.handleChangeAutocompleteActivities}
                                                    options={this.props.activities}
                                                    autoFocus={false}
                                                    className={`link-form ${this.props.errorValues == false && this.props.formValues.risk_activity_id == "" ? "error-class" : ""}`}
                                                    value={this.props.selectedOptionActivities}
                                                />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label>Tarea</label>
                                                <input
                                                    type="hidden"
                                                    name="specific_area_id"
                                                    value={this.props.selectedOptionSpecificAreas.specific_area_id}
                                                />

                                                <Select
                                                    onChange={this.props.handleChangeAutocompleteSpecificAreas}
                                                    options={this.props.specificAreas}
                                                    autoFocus={false}
                                                    className={`link-form ${this.props.errorValues == false && this.props.formValues.specific_area_id == "" ? "error-class" : ""}`}
                                                    value={this.props.selectedOptionSpecificAreas}
                                                />
                                            </div>

                                       

                                        </div>
                                    </div>



                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreateMejora;