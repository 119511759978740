import React, {useEffect, useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import GoogleDocsViewer from 'react-google-docs-viewer';
import Preloader from '../../../GeneralComponents/LoadingComponen';

const ModalShowFile = (props) => {
    const [width, setWidth] = useState('1000px');
     const [height, setHeight] = useState('494px');
     const [isLoaded, setIsLoaded] = useState(false);

   const updateDimensions = () => {
    if (window.innerWidth <= 768) {
      setWidth('100%'); 
      setHeight('500px'); 
    } else if (window.innerWidth > 768 && window.innerWidth <= 1100) {
        setWidth('100%'); 
        setHeight('490px');
    } else {
      setWidth('1000px'); 
      setHeight('494px'); 
    }
  };
  
  useEffect(() => {
    updateDimensions();
    
    window.addEventListener('resize', updateDimensions);
    
    setTimeout(() => {
        setIsLoaded(true);
      }, 2000);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
    
  }, []);

  const typeFileViewer = (type_file) => {
    if (!isLoaded) {
        return (
            <Preloader /> 
        );
    }

    if(type_file === 'image'){
        return(
            <img className='ui-modalShowFiles-img' src={props.files} alt="Imagen"/> 
        );
    } else if (type_file === 'file') {
        return(
            <div className="col-md-12 text-center">
                <GoogleDocsViewer
                    width={width}
                    height={height}
                    fileUrl={props.files}
                />
            </div>
        );
    } else {
       return(
            <iframe className='ui-modalShowFiles-video' src={"https://www.youtube.com/embed/" + props.files} title="Video" frameborder="0" allowfullscreen></iframe>
       ); 
    }
  }
  
    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toogle={props.toogle} className={`modal-dialog-centered ${props.typeFile == 'file' ? ('modal-xl'): ('modal-lg')}`} backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
                <ModalBody>
                    <div className="ui-modalShowFiles-files__container">
                        {typeFileViewer(props.typeFile)}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );

    
}

export default ModalShowFile;
