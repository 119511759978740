import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';
import ReactHtmlParser from 'react-html-parser';

class FormUpdateFooter extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            footer: this.props.tenant.footer,
            form: {
                footer: this.props.tenant.footer,
            }
        }
    }

    handleClickUpdate = () => {
        fetch(`/settings/tenants/${this.props.tenant.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({ footer: data.register.footer })
            this.props.updateItem(data.register);
            this.props.messageSuccess(data);
        });
    }

    handleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                footer: e
            }, 
        })
    };

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        const config = {
            language: 'es',
            colorsHEXInput: true,
            autoFocus: true,
            toolbartop: true,
            linkAlwaysBlank: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            htmlExecuteScripts: true,
            key: this.props.key_text,
            imageUploadToS3: this.props.imageUpload,
            iframe: true,
            tabSpaces: 4,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                    // Alignment of the group in the toolbar.
                    align: 'left',

                    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                    buttonsVisible: 12
                },


                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                    align: 'left',
                    buttonsVisible: 11
                },

                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                    align: 'left',
                    buttonsVisible: 10
                },

                moreMisc: {
                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                    align: 'right',
                    buttonsVisible: 9
                }
            }
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-md-12">
                                        <FroalaEditor
                                            model={this.state.form.footer}
                                            config={config}
                                            onModelChange={(e) => this.handleChange(e)}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                {ReactHtmlParser(this.state.footer)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={() => this.handleClickUpdate()}>{"Actualizar"}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormUpdateFooter;