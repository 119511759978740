import React, { Component } from "react";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreateShow from "../../health/Normativities/FormCreateShow";
import Chart from "../../../GeneralComponents/Charts/ChartNormativity";
import HeaderTitle from "../../../GeneralComponents/HeaderTitle";
import Comments from "../../../GeneralComponents/Comments"
import Loading from '../../../GeneralComponents/LoadingComponen'
import LineChart from '../../../GeneralComponents/Charts/LineChartBasic'

class Show extends Component {
  constructor(props) {
    const date = new Date();
    super(props);
    this.fecha = `${date.getFullYear()}-${date.getMonth() < 10 ? 0 : ""}${date.getMonth()}-${date.getDate() < 10 ? 0 : ""}${date.getDate()}`
    this.token = document.querySelector("[name='csrf-token']").content;
    this.state = {
      data: this.props.califications,
      modal: false,
      modeEdit: false,
      accion: {},
      id: "",

      formValues: {
        date_score: this.fecha,
        proceso_id: this.props.proceso.id,
        responsible_user_id: "",
      },

      selectedOption: {
        responsible_user_id: "",
        label: "Seleccionar"
      },

      formValuesComments: {
        comment: "",
        module_name: this.props.nameModule,
        proceso_score_id: "",
      },

      dataLine: {
        show: false,
        labels: [],
        data: [],
      },

      users: [],
      editValues: [],
      dataCommets: [],
    };

  }

  componentDidMount() {

    if (this.props.data[0] != undefined){
      setTimeout(() => {
        this.loadDataComments();
        this.setState({
          formValuesComments: {
            ...this.state.formValuesComments,
            proceso_score_id: this.props.data[0].id,
          },
        })
      }, 2000);
    }
    let array = []

    this.props.users.map((item) => (
      array.push({ label: item.name, value: item.id })
    ))

    this.setState({
      users: array,
    })
  }

  handleChangeAutocomplete = selectedOption => {
    this.setState({
      selectedOption,
      formValues: {
        ...this.state.formValues,
        responsible_user_id: selectedOption.value
      }
    });
  };

  messageSuccess = (response) => {
    Swal.fire({
      position: "center",
      type: "success",
      title: `${response.success}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  clearValues = () => {
    this.setState({
      modal: false,
      modeEdit: false,
      id: "",
      formValues: {
        date_score: this.fecha,
        proceso_id: this.props.proceso.id,
        responsible_user_id: "",
      },

      selectedOption: {
        responsible_user_id: "",
        label: "Seleccionar"
      },

    });
  };

  onChangeForm = (e) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [e.target.name]: e.target.value,
      },
    });
  };


  toogle = (from) => {
    if (from == "new") {
      this.setState({ modal: true, titleForm: "Agregar Numeral", })
    } else {
      this.setState({ modal: false })
      this.clearValues()

    }
  }

  HandleClick = (e) => {
    if (this.state.modeEdit == true) {
      fetch("/settings/proceso_scores/" + this.state.id, {
        method: "PATCH", // or 'PUT'
        body: JSON.stringify(this.state.formValues), // data can be `string` or {object}!
        headers: {
          "X-CSRF-Token": this.token,
          "Content-Type": "application/json"
        },
      })
        .then((res) => res.json())
        .catch((error) => console.error("Error:", error))
        .then((response) => {
          this.messageSuccess(response);
          this.props.loadData();
          this.clearValues();
        });
    } else {
      this.props.updateStateLoad(true)
      fetch("/settings/proceso_scores", {
        method: "POST", // or 'PUT'
        body: JSON.stringify(this.state.formValues), // data can be `string` or {object}!
        headers: {
          "X-CSRF-Token": this.token,
          "Content-Type": "application/json"
        },
      })
        .then((res) => res.json())
        .catch((error) => console.error("Error:", error))
        .then((response) => {
          this.messageSuccess(response);
          this.props.loadData();
          this.props.updateStateLoad(false)
          this.loadDataComments();
          this.clearValues();
        });
    }
  };

  toggle = (show, register) => {
    if (show == "open") {
      this.setState({
        modal: true,
        modeEdit: true,
        accion: register,
        formValues: {
          date_score: this.fecha,
          proceso_id: this.props.proceso.id,
          responsible_user_id: "",
        },
      });
    } else {
      this.clearValues();
    }
  };

  editCalification = (calification) => {
    this.setState({
      modal: true,
      id: calification.id,
      modeEdit: true,
      formValues: {
        ...this.state.formValues,
        responsible_user_id: calification.responsible_user_id,
        date_score: calification.date_score,
      },

      selectedOption: {
        responsible_user_id: calification.responsible_user_id,
        label: `${calification.responsible_user.first_name} ${calification.responsible_user.first_last_name}`
      },

    })
  }

  delete = id => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡El registro será eliminado para siempre!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009688",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        fetch(`/settings/proceso_scores/${id}`, {
          method: "delete",
          headers: {
            "X-CSRF-Token": this.token,
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(response => {
            this.props.loadData();
            this.loadDataComments();
            this.messageSuccess(response)
          });
      }
    });
  };

  getColor(value) {
    let color = ""
    if (value < 60) {
      color = "#ea4612"
    } else if (value >= 60 && value < 80) {
      color = "orange";
    }
    else {
      color = "#30af28"
    }
    return color
  }

  getLineChart = () => {
    console.log("holaget")
    console.log(this.props.califications)
    let dataLine = [['x', 'Resultado']];
    let data2 = [...this.props.data]
    data2.reverse().map(data => {
      console.log("hola")
      dataLine.push([data.date_score, data.score])


    })
    console.log(dataLine)
    this.setState({
      dataLine: {
        data: dataLine,
      },
    });
  }


  componentDidUpdate(prevProps) {
    // Uso tipico (no olvides de comparar los props):
    if (this.props.data.length !== prevProps.data.length) {
      this.getLineChart();
    }
  }


  //commentarios 
  loadDataComments = () => {
    if (this.props.data[0] != undefined){
      fetch(`/get_general_commets_procesos/${this.props.data[0].id}`, {
        method: 'GET', // or 'PUT'
        headers: {
          "X-CSRF-Token": this.token,
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataCommets: data.data,
          });
        });
    }
  }

  HandleChangeComment = (e) => {
    this.setState({
      formValuesComments: {
        ...this.state.formValuesComments,
        comment: e.target.value
      }
    });
  }

  clearValuesComment = () => {
    this.setState({
      formValuesComments: {
        ...this.state.formValuesComments,
        comment: ""
      }
    });
  }
  getBack = () => {
    Turbolinks.visit("/settings/clinic_process/index", "_self")
  }

  render() {
    return (
      <React.Fragment>
        
        {this.state.modal && (
          <FormCreateShow
            toggle={this.toggle}
            backdrop={"static"}
            modal={this.state.modal}
            onChangeForm={this.onChangeForm}
            formValues={this.state.formValues}
            submitForm={this.HandleClick}
            nameSubmit={
              this.state.modeEdit == true ? "Actualizar" : "Crear calificación"
            }

            title={this.state.modeEdit == true ? "Actualizar calificación" : "Crear Nueva calificación"}

            //autocomplete

            users={this.state.users}
            formAutocomplete={this.state.selectedOption}
            onChangeAutocomplete={this.handleChangeAutocomplete}

            //is_loading
            isLoaded={this.props.isLoaded}

            //autocomplete users
            handleChangeAutocompleteUsers={this.handleChangeAutocompleteUsers}
            editValues={this.state.editValues}

            showAddUsers={false}
          />
        )}


        <HeaderTitle
          title={"Evaluaciones proceso de " + this.props.proceso.name}
          subTitle={"Gestiona y comenta las evaluaciones del proceso"}
          titleNew={"Nueva evaluación"}
          titleFilter={"Filtros"}
          onClickNew={this.toogle}
          normativity={this.props.normativity}
          showNew={true}
          showFilter={false}
        />

        <nav className="" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="javascript:void(0);" onClick={() => this.getBack()}>Proceso Clinicos</a></li>
            <li className="active breadcrumb-item" aria-current="page">Evaluaciones de {this.props.proceso.name}</li>
          </ol>
        </nav>


        {!this.props.isLoaded ? (
          <React.Fragment>
            {this.props.data.length >= 1 ? (
              <div className="container mb-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mt-3">

                      <div>
                        <div className="main-calification mb-4">
                          {this.props.loadCalifications == true ? (
                            <Preloader />
                          ) : (
                              <div>
                                <div className="row">
                                  <div className="mb-3 card col-md-4" style={{ width: "100%" }}>
                                    <div className="card-header-tab card-header">
                                      <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                        <i className="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
                                        Última calificación({this.props.data[0].date_score})
                                                      </div>

                                    </div>
                                    <div className="no-gutters row">
                                      <div className="col-sm-12 col-md-12 col-xl-12">
                                        <div className="card no-shadow rm-border bg-transparent widget-chart text-left">

                                          <div className="">
                                            <div className="widget-subheading">Nota de cumplimiento</div>
                                            <div className="widget-numbers text-center" style={{ color: this.getColor(this.props.data[0].score != null ? this.props.data[0].score : 0) }}>{this.props.data[0].score != null ? this.props.data[0].score : 0}</div>
                                            <div className="widget-description opacity-8 text-focus">

                                              Califica: {this.props.data[0].responsible_user.first_name + " " + this.props.data[0].responsible_user.first_last_name}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="divider m-0 d-md-none d-sm-block"></div>
                                      </div>

                                    </div>
                                    <div className="text-center d-block p-3 card-footer">
                                      <a 
                                        href={`/settings/proceso_scores/${this.props.data[0].id}`} 
                                        className="btn-pill btn-shadow btn-info fsize-1 btn btn-primary btn-lg"
                                        data-turbolinks="true"
                                      >
                                        <span className="mr-2 opacity-7">
                                          <i className="icon icon-anim-pulse ion-ios-analytics-outline"></i>
                                        </span>
                                        <span className="mr-1">Gestionar Evaluación</span>
                                      </a>
                                      <div className="text-center process-score-trash">
                                        <a
                                          onClick={() => this.editCalification(this.props.data[0])}
                                          style={{ cursor: "pointer", fontSize: "14px", marginRight: "12px" }}
                                        >
                                          <i className="fas fa-pencil-alt"></i>
                                        </a>

                                        <a
                                          onClick={() => this.delete(this.props.data[0].id)}
                                          style={{ cursor: "pointer", fontSize: "14px", marginRight: "12px" }}
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                        </a>
                                      </div>
                                    </div>


                                  </div>
                                  <div className="col-md-8">
                                    <Comments
                                      nameModule={"hola"}
                                      usuario={this.props.usuario}
                                      dataCommets={this.state.dataCommets}
                                      loadComments={this.loadDataComments}
                                      formValuesComments={this.state.formValuesComments}
                                      HandleChangeComment={this.HandleChangeComment}
                                      clearValuesComment={this.clearValuesComment}
                                    />
                                  </div>
                                </div>


                                <div className="card" style={{ display: "none" }}>
                                  <div className="card-header">
                                    <h4>Última calificación({this.props.data[0].date_score}) </h4>
                                  </div>
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-4 text-center">
                                        <h2 style={{ color: this.getColor(this.props.data[0].score != null ? this.props.data[0].score : 0) }}>{this.props.data[0].score != null ? this.props.data[0].score : 0}</h2>
                                        <p>Nota de cumplimiento</p>
                                      </div>

                                      <div className="col-md-5">
                                        <p>Responsable: <b>{this.props.data[0].responsible_user.first_name} {this.props.data[0].responsible_user.first_last_name} </b></p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card-footer">
                                    <div className="col-md-4">
                                      <a
                                        href={`/settings/proceso_scores/${this.props.data[0].id}`}
                                        target="_blank"
                                        style={{ cursor: "pointer" }}
                                        className="btn-shadow btn btn-info"
                                      >
                                        Gestionar Evaluación
                                        </a>
                                    </div>

                                    <div className="col-md-12 text-center">
                                      <a
                                        onClick={() => this.editCalification(this.props.data[0])}
                                        style={{ cursor: "pointer", fontSize: "14px", marginRight: "12px" }}
                                      >
                                        <i className="fas fa-pencil-alt"></i>
                                      </a>

                                      <a
                                        onClick={() => this.delete(this.props.data[0].id)}
                                        style={{ cursor: "pointer", fontSize: "14px", marginRight: "12px" }}
                                      >
                                        <i className="fas fa-trash-alt"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>

                      </div>

                      {this.props.califications_date.length >= 2 ? (
                        <Chart
                          califications_date={this.props.califications_date}
                        />
                      ) : ""}
                    </div>
                  </div>


                  <div className="col-md-12 pt-2 line-chart-content" >
                    {this.state.dataLine.data.length > 0 ? (
                      <LineChart dataLine={this.state.dataLine.data} />
                    ) : (
                        <div className="no-line-chart col-md-12">
                          <p><i className="metismenu-icon pe-7s-graph1"></i><br />
                            <span>Debes tener mínimo dos calificaciones para ver esta gráfica</span></p>
                        </div>
                      )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <h3>Historial</h3>
                    <hr />
                  </div>
                  <div className="col-md-12 ">
                    <div className="row history mt-2">

                      {this.props.data.map((accion, index) =>
                        this.props.data[0].id != accion.id ? (
                          <div className="col-md-3">
                            <div className="card card-normas" key={accion.id}>

                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <p><b>{accion.date_score}</b></p>
                                    <p style={{ fontSize: "30px", marginBottom: "0px", color: this.getColor(accion.score != null ? accion.score : 0) }}>
                                      {accion.score != null ? accion.score : 0}
                                    </p>
                                    <p>Nota de cumplimiento </p>
                                    <div className="row ">

                                      <div className="col-md-6" style={{ textAlign: "left" }}>
                                        <a
                                          href={`/settings/proceso_scores/${accion.id}`}
                                          target="_blank"
                                          style={{ cursor: "pointer" }}
                                          className="btn-shadow btn btn-info"
                                        >
                                          Ver
                                                      </a>
                                      </div>

                                      <div className="col-md-6">
                                        <a
                                          onClick={() => this.editCalification(accion)}
                                          style={{ cursor: "pointer", fontSize: "18px", marginRight: "12px" }}
                                        >
                                          <i className="fas fa-pencil-alt"></i>
                                        </a>

                                        <a
                                          onClick={() => this.delete(accion.id)}
                                          style={{ cursor: "pointer", fontSize: "18px", marginRight: "6px" }}
                                        >
                                          <i className="fas fa-trash-alt icon-16"></i>
                                        </a>
                                      </div>

                                    </div>
                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                            ""
                          )
                      )}

                    </div>
                  </div>

                </div>
              </div>
            ) : (
                <div className="container">
                  <div className="card card-normas no-califications">
                    <div className="card-body">
                      <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-md-12 text-center mb-2">
                          <img
                            style={{ width: "70px" }}
                            src="https://mybc1.s3.amazonaws.com/uploads/rseguimiento/evidencia/579/geekmejoraapp.png"
                          />
                        </div>
                        <div className="col-md-12 text-center mb-2">
                          <p>
                            No has realizado la primera calificación <br />{" "}
                            <span>¡ Hazlo ahora y mira como esta la norma !</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </React.Fragment>
        ) : (
            <Loading />
        )}
      </React.Fragment>
    );
  }
}

export default Show;

