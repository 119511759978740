import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    getDataState = () => {
        if(this.props.formValues.strategic_objective_type == "Estrategias Defensivas"){
            this.setState({ data: ["Fortalezas", "Amenazas"] })
        }else if(this.props.formValues.strategic_objective_type == "Estrategias Ofensivas"){
            this.setState({ data: ["Fortalezas", "Oportunidades"] })
        }else if(this.props.formValues.strategic_objective_type == "Estrategias Supervivencias"){
            this.setState({ data: ["Debilidades", "Amenazas"] })
        }else if(this.props.formValues.strategic_objective_type == "Estrategias Adaptativas"){
            this.setState({ data: ["Debilidades", "Oportunidades"] })
        }
    }

    componentDidMount  = () => {
        this.getDataState();
        if(this.props.modeEditObjetives){
            setTimeout(() => {
                let map = new Map();
                let mapObjetivos = new Map();

                this.state.data.map((factor, index) => (
                    this.props.dataFactors.filter(data => data.factor_type == factor).forEach(element => {
                        this.props.formValues.factor_ids.forEach(items => {
                            if (element.id == items) {
                                map.set(items.toString(), true)
                            }
                        });
                    })    
                ));

                this.props.dataObjetives.forEach(element => {
                    this.props.formValues.company_objetive_ids.forEach(items => {
                        if (element.id == items) {
                            mapObjetivos.set(items.toString(), true)
                        }
                    });
                })  
                
                this.props.updateMapObjetivos(mapObjetivos)
                this.props.updateMap(map)
            }, 100);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-lg modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader style={{ color: "#ffff", backgroundColor: this.props.getColorObjetives(this.props.formValues.strategic_objective_type) }} toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.formValues.strategic_objective_type}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-12">
                                        <b>Son estrategias creadas a partir de enfrentar {this.state.data[0]} + {this.state.data[1]} </b>
                                        <p>Para crear cada estrategia debe de hacer dos cosas: </p>
                                    </div>

                                    <ul>
                                        <li>Rellenar el título y la descripción de la estrategia.</li>
                                        <li>Seleccionar los factores del FODA relacionados con esta estrategia</li>
                                    </ul>

                                    <div className="col-md-12">
                                        <label>Nombre</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.props.formValues.name}
                                            onChange={this.props.onChangeForm}
                                            placeholder="Título"
                                            className={`form form-control ${this.props.ErrorValuesEstrategia == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-2 mb-3">
                                        <label>Descripción</label>
                                        <textarea
                                            name="description"
                                            value={this.props.formValues.description}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${this.props.ErrorValuesEstrategia == false && this.props.formValues.description == "" ? "error-class" : ""}`}
                                            rows="5"
                                        />
                                    </div>

                                    <div className="col-md-4 mb-3">
                                        <input
                                            type="hidden"
                                            name="category_indicator_id"
                                            value={this.props.selectedOptionCategory.category_indicator_id}
                                        />
                                        <label>Categorías de indicadores <small className="validate-label">(opcional)</small></label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteCategory}
                                            options={this.props.category_indicators}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionCategory}
                                        />
                                    </div>

                                    {this.props.sub_category_indicators.length >= 1 && (
                                        <div className="col-md-4 mb-3">
                                            <input
                                                type="hidden"
                                                name="sub_category_indicator_id"
                                                value={this.props.selectedOptionSubCategory.sub_category_indicator_id}
                                            />
                                            <label>Sub Categorías de indicadores </label>
                                            <Select
                                                onChange={this.props.handleChangeAutocompleteSubCategory}
                                                options={this.props.sub_category_indicators}
                                                autoFocus={false}
                                                className={`link-form`}
                                                value={this.props.selectedOptionSubCategory}
                                            />
                                        </div>
                                    )}


                                    {this.props.indicators.length >= 1 && (
                                        <div className="col-md-4 mb-3">
                                            <label>Indicadores</label>
                                            <input
                                                type="hidden"
                                                name="position_ids"
                                                value={this.props.selectedOptionMulti.position_ids}
                                            />

                                            <Select
                                                onChange={this.props.handleChangeAutocompleteMulti}
                                                options={this.props.indicators}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                autoFocus={false}
                                                className={`link-form`}
                                                classNamePrefix="select"
                                                placeholder="Seleccione"
                                                name="position_ids"
                                                defaultValue={this.props.editValues}
                                            />
                                        </div>
                                    )}
                                    
                                    <div className="col-md-12">
                                        <div className="row">
                                            {this.state.data.map((factor, index) => (
                                                <div className={`col-md-6 mb-3`}>
                                                    <div className="card">
                                                        <div className="card-header" style={{ color: "#ffff", backgroundColor: this.props.getColorFactors(factor) }}>
                                                            {factor}
                                                        </div>
                                                        <div className="card-body pr-0 pl-0 pt-0">
                                                            {this.props.dataFactors.filter(data => data.factor_type == factor).map((data, index) => (
                                                                <div className={`col-md-12 ${index == 0 ? "mt-2" : "" }`}>
                                                                    <p> <input type="checkbox" className="mr-2" onChange={this.props.handleChangeChecked} value={data.id} checked={this.props.checkedItems.get(data.id.toString())} />  {data.description}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>


                                    {this.props.dataObjetives.length >= 1 && (
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    Objetivos
                                                </div>

                                                <div className="card-body pr-0 pl-0 pt-0">
                                                    {this.props.dataObjetives.map((data, index) => (
                                                        <div className={`col-md-12 ${index == 0 ? "mt-2" : "" }`}>
                                                            <p> <input type="checkbox" className="mr-2" onChange={this.props.handleChangeCheckedObjetives} value={data.id} checked={this.props.checkedItemsObjetives.get(data.id.toString())} />  {data.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>   
                                    )}

                                    {this.props.ErrorValuesEstrategia == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                                <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn"
                                    onClick={this.props.submitForm}
                                    style={{ color: "#ffff", backgroundColor: this.props.getColorObjetives(this.props.formValues.strategic_objective_type) }}
                                >
                                    {this.props.nameSubmit}
                                </button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;