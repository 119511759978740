import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import Preloader from '../../../GeneralComponents/LoadingComponen';

class TaskBoardUpdateUsers extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            selectedOptionUsers: {
                label: "Usuarios",
                user_ids: [],
            },

            default_users: [],
            users: [],
            isLoaded: true,
        }
    }


    handleSubmit = e => {
        e.preventDefault();
    };
    
    componentDidMount = () => {
        this.setDefaultValues();
        this.loadData();
    }

    loadData = () => {
        fetch(`/task/get_users_task_board/${this.props.task_board.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                default_users: data.register,
                isLoaded: false
            })
        });
    }


    setDefaultValues = () => {
        let array = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array,
        })
    }

    handleChangeAutocompleteUsers = (selectedOption) => {
        let array = [];

        if (selectedOption){
            selectedOption.map((item) => (
                array.push(item.value)
            ))
        }

        let form = {
            task_board_id: this.props.task_board.id,
            users_ids: selectedOption ? array : [],
        }

        fetch(`/task/update_users_task_board`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.setUsers(data.register);
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
                                    {!this.state.isLoaded ? (
                                        <div className="col-md-12 mb-3">
                                            <input
                                                type="hidden"
                                                name="user_ids"
                                                value={this.state.selectedOptionUsers.user_ids}
                                            />

                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                autoFocus={false}
                                                className={`link-form`}
                                                classNamePrefix="select"
                                                placeholder="Seleccione"
                                                name="user_ids"
                                                onChange={this.handleChangeAutocompleteUsers}
                                                options={this.state.users}
                                                defaultValue={this.state.default_users}
                                            />
                                        </div>
                                    ) : (
                                        <div className="card">
                                            <div className="card-body">
                                                <Preloader />
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <button className="btn-shadow btn btn-info" onClick={() => this.props.toggle()}>Cerrar</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default TaskBoardUpdateUsers;