import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FormCreate = (props) => {
    
    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>

                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        name="page_name"
                                        value={props.formValues.page_name}
                                        onChange={props.onChangeForm}
                                        className={`form form-control ${!props.errorValues && props.formValues.page_name == "" ? "error-class" : ""}`}
                                        placeholder="Nombre"
                                    />
                                </div>

                                <div className="col-md-12 mt-3">
                                    <span className="ui-span-url">{props.current_url}</span>
                                    <input
                                        style={{width: '100%'}}
                                        type="text"
                                        name="url"
                                        value={props.formValues.url}
                                        onChange={props.onChangeFormUrl}
                                        className={`form form-control ui-input-url ${!props.errorValues && props.formValues.url == "" ? "error-class" : ""}`}
                                        placeholder="Nombre del enlace de la página"
                                    />
                                </div>

                                {!props.errorValues && (
                                    <div className="col-md-12 mt-4">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={props.submitForm}>{props.nameBnt}</button>
                        </ModalFooter>
                    </form>
            </Modal>
        </React.Fragment>
    );
}


export default FormCreate;

