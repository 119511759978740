import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import Index from '../components/tasks/tasksCard/Index';
import FormFilter from '../components/tasks/tasksCard/FormFilter';
import HeaderTitle from '../components/tasks/taskBoard/HeaderTitle'

// VIEWS
import TaskIndexTrello from '../components/taskTrello/index'
import TableTask from '../components/tasks/taskBoard/TableTask';
import CalendarTask from '../components/tasks/taskBoard/CalendarTask';
import GanttDiagram from '../components/tasks/ganttDiagram/Index'  

class IndexTasks extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            data: [], //
            urlFilter: "",
            url: (this.props.from != "TABLERO" ? `/task/get_tasks?task=${this.props.from}` : `/task/get_task_table/${this.props.task_board.id}`),
            isLoaded: true,
            showFilter: false,
            isFiltering: false,
            listView: (this.props.task_board.delete_template ? "list" : "columns"),
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data].reverse(),
            isLoaded: false,
            isFiltering: false,
        })
    }

    //add update
    updateItem = task => {
        this.setState({
            data: this.state.data.map(item => {
            if (task.id === item.id) {
              return { ...item, 
                description: task.description,
                due_date: task.due_date,
                name: task.name,
                state: task.state,
                user_owner: task.user_owner,
                general_comments: task.general_comments.length,
                general_files: task.general_files.length,
                task_labels: task.task_labels,
                users: task.task_labels,
                checklist: task.checklist,
                url_source: task.url_source,
                task_source: task.task_source,
              }
            }
            return item;
          })
        });
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    updateView = (view) => {
        this.setState({ listView: view })
    }

    getNameTitle = (name) => {
        if (name == "ALL") {
            return `Todas las tareas`
        } else if (name == "ASSIGNED") {
            return `Tareas asignadas por mi`
        } else if (name == "MY") {
            return `Todas mis tareas`
        } else if (name == "GESTOR") {
            return `Tareas compartidas para mi`
        } else if (name == "TABLERO"){
            return `Tareas`
        }
    }

    updateDataFilter = (data) => {
        this.child.current.updateState(data.data)
    }

    updateStates = (state_is_filtering, state_is_loaded, url) => {
        this.setState({
            urlFilter: url,
            isFiltering: state_is_filtering,
            isLoaded: state_is_loaded,
        })
    }

    updateStateIsLoaded = (state) => {
        this.setState({
            isLoaded: state,
        })
    }

    cancelFilter = () => {
        this.child.current.loadData()
        this.setState({
            isFiltering: false,
            isLoaded: false,
            showFilter: false,
        })
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={this.props.task_board.name}
                    subTitle={"Gestión de tareas"}
                    titleNew={""}
                    titleFilter={"Filtros"}
                    onClickNew={""}
                    onClickFilter={this.handleClickShowFilter}
                    showNew={false}
                    showFilter={true}
                    taskHistory={this.props.from != "TABLERO" ? this.props.from : undefined}
                    updateView={this.updateView}
                    listView={this.state.listView}
                    task_board={this.props.task_board}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.showFilter && (
                    <FormFilter 
                        handleClickFilter={this.HandleClickFilter}
                        handleClickCancel={this.handleClickCancel}
                        handleChangeFilter={this.handleChangeFilter}
                        from={this.props.from}
                        index={true}
                        url={this.state.url}

                        //values data
                        users={this.props.users}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        procesos={this.props.procesos}
                        task_sources={this.props.task_sources}

                        //update states
                        updateStateIsLoaded={this.updateStateIsLoaded}
                        updateStates={this.updateStates}
                        updateDataFilter={this.updateDataFilter}
                        cancelFilter={this.cancelFilter}

                        //states
                        isFiltering={this.state.isFiltering}
                    />
                )}
                
                {this.state.listView == "columns" && (
                    <TaskIndexTrello
                        url={this.props.url}
                        user={this.props.user} 
                        users={this.props.users}
                        estados={this.props.estados}
                        task_board={this.props.task_board}
                        procesos={this.props.procesos}

                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        task_sources={this.props.task_sources}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                        task={this.props.task}

                        //change updateView
                        updateView={this.updateView}
                        listView={this.state.listView}
                    />
                )}

                {this.state.listView == "list" && (
                    <TableTask
                        url_table={this.props.url_table}
                        user={this.props.user} 
                        users={this.props.users}
                        estados={this.props.estados}
                        task_board={this.props.task_board}
                        procesos={this.props.procesos}
                        columns={this.props.columns}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        task_sources={this.props.task_sources}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}

                        //change updateView
                        updateView={this.updateView}
                        listView={this.state.listView}

                        showNew={true}
                        showOptions={true}
                    />
                )}

                {this.state.listView == "calendar" && (
                    <CalendarTask 
                        url_calendar={this.props.url_calendar}
                        user={this.props.user} 
                        users={this.props.users}
                        estados={this.props.estados}
                        task_board={this.props.task_board}
                        columns={this.props.columns}
                        procesos={this.props.procesos}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        task_sources={this.props.task_sources}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                        task={this.props.task}

                        //change updateView
                        updateView={this.updateView}
                        listView={this.state.listView}
                    />
                )}

                {this.state.listView == "ganttDiagram" && (
                    <GanttDiagram 
                        users={this.props.users}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        procesos={this.props.procesos}
                        task_sources={this.props.task_sources}
                        columns={this.props.columns}
                        
                        url={`/task/get_data_gantt_diagram/${this.props.task_board.id}`}
                        current_user={this.props.user}
                        company={this.props.company}
                    />
                )}

                {this.state.listView == "history" && (
                    <TableTask
                        url_table={`/task/get_history_task_board/${this.props.task_board.id}`}
                        user={this.props.user} 
                        users={this.props.users}
                        estados={this.props.estados}
                        task_board={this.props.task_board}
                        procesos={this.props.procesos}
                        columns={this.props.columns}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        task_sources={this.props.task_sources}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}

                        //change updateView
                        updateView={this.updateView}
                        listView={this.state.listView}

                        showNew={false}
                        showOptions={false}
                        restore={true}
                    />
                )}
                


                {/*<Index
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    user={this.props.user} 
                    users={this.props.users}
                    from={this.props.from}
                    estados={this.props.estados}

                    //test data 

                    updateData={this.updateData}
                    updateItem={this.updateItem}
                    task_board={this.props.task_board}

                    //parametros 

                    task_id={this.props.task_id}
                    procesos={this.props.procesos}
                    handleClickFilter={this.loadDataFilter}
                    isFiltering={this.state.isFiltering}
                />*/}
            </React.Fragment>
        );
    }
}


export default IndexTasks;
WebpackerReact.setup({ IndexTasks });
