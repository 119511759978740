import React from "react";
import Chart from "react-apexcharts";


export const options = {
    chart: {
        title: "Box Office Earnings in First Two Weeks of Opening",
        subtitle: "in millions of dollars (USD)",
    },
};


class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();
        const max_length = 10;
        const categories = this.props.data.categories;
        const is_currency = this.props.data.is_currency;
        const result_type = this.props.data.result_type;
        this.state = {
            options: {
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                        }
                    },
                    id: "basic-bar"
                },
                xaxis: {
                    categories: categories,
                    // RECORTAR LAS ETIQUETAS DE LAS CATEGORIAS DLE EJE X
                    labels: {
                        formatter: function (value) {
                            if (typeof value !== 'undefined' && value !== null) {
                                if (value.length > max_length) {
                                    return value.substring(0, max_length-3) + "...";
                                }
                            }
                            return value;
                        },
                        style: {
                            fontSize: '16px',
                        },
                    },
                },
                tooltip: {
                    enabled: true,
                    x: {
                        formatter: function (value, opt) {
                            return categories[opt.dataPointIndex];
                        },
                    },
                    y: {
                        formatter: function (value, { seriesIndex }) {
                            if (value != null) {
                                if (result_type == "suma") {
                                    if (is_currency.includes(seriesIndex)) {
                                        return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'COP', currencyDisplay: 'narrowSymbol' }).format(value);
                                    }
                                    return value;
                                } else {
                                    return value;
                                }
                            }
                        },
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            // SI ES UN NUMERO ENTERO LO DEVUELVE TAL CUAL
                            if (Number.isInteger(val)) {
                                return val;
                            }
                            // SI TIENE DECIMALES LO FORMATEA A 2 DECIMALES
                            return parseFloat(val).toFixed(2);
                        },
                        style: {
                            fontSize: '16px',
                        },
                    },
                },
                // stroke: {
                //     curve: 'smooth',
                // },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            // width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                markers: {
                    size: 7,
                }
            },
            series: this.props.data.series
        };
    }
    componentDidMount() {
        console.log(this.chartReference); // returns a Chart.js instance reference
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.data !== nextProps.data) {
            const max_length = 10;
            const categories = this.props.data.categories;
            const is_currency = this.props.data.is_currency;
            const result_type = this.props.data.result_type;
            this.setState(
                {
                    options: {
                        chart: {
                            toolbar: {
                                show: true,
                                tools: {
                                    download: true,
                                }
                            },
                            id: "basic-bar"
                        },
                        xaxis: {
                            categories: nextProps.data.categories,
                            // RECORTAR LAS ETIQUETAS DE LAS CATEGORIAS DLE EJE X
                            labels: {
                                formatter: function (value) {
                                    if (typeof value !== 'undefined' && value !== null) {
                                        if (value.length > max_length) {
                                            return value.substring(0, max_length-3) + "...";
                                        }
                                    }
                                    return value;
                                },
                                style: {
                                    fontSize: '16px',
                                },
                            },
                        },
                        tooltip: {
                            enabled: true,
                            x: {
                                formatter: function (value, opt) {
                                    return categories[opt.dataPointIndex];
                                },
                            },
                            y: {
                                formatter: function (value, { seriesIndex }) {
                                    if (value != null) {
                                        if (result_type == "suma") {
                                            if (is_currency.includes(seriesIndex)) {
                                                return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'COP', currencyDisplay: 'narrowSymbol' }).format(value);
                                            }
                                            return value;
                                        } else {
                                            return value;
                                        }
                                    }
                                },
                            },
                        },
                        yaxis: {
                            labels: {
                                formatter: function (val) {
                                    // SI ES UN NUMERO ENTERO LO DEVUELVE TAL CUAL
                                    if (Number.isInteger(val)) {
                                        return val;
                                    }
                                    // SI TIENE DECIMALES LO FORMATEA A 2 DECIMALES
                                    return parseFloat(val).toFixed(2);
                                },
                                style: {
                                    fontSize: '16px',
                                },
                            },
                        },
                        // stroke: {
                        //     curve: 'smooth',
                        // },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    // width: 300
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }],
                        markers: {
                            size: 7,
                        }
                    },
                    series: nextProps.data.series
                }
            )
        }
    }


    render() {

        return (


            <React.Fragment>
                <div className="app">
                    <div className="mixed-chart">
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="line"
                            className="chart-full"
                        />
                    </div>
                </div>





            </React.Fragment>
        );
    }
}

export default LineChart;
