import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Items from "../../../settings/Icons/Items";
import Select from "react-select";
import CheckboxContainer from '../../FormatCustomView/CheckboxContainer';

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
                                    <div className={`col-md-${this.props.selectedOption ? "6" : "12"}`}>
                                        <label>Nombre</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.props.formValues.name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.name == "" ? "error-class" : ""}`}
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    {this.props.selectedOption && (
                                        <div className="col-md-6">
                                            <label>Formatos </label>
                                            <input
                                                type="hidden"
                                                name="format_id"
                                                value={this.props.selectedOption.format_id}
                                            />
                                            <Select
                                                onChange={this.props.handleChangeAutocompleteFormat}
                                                options={this.props.formatos}
                                                isDisabled={this.props.modeEdit}
                                                autoFocus={false}
                                                className={`link-form ${!this.props.errorValues && this.props.formValues.format_id == "" ? "error-class" : ""}`}
                                                value={this.props.selectedOption}
                                            />
                                        </div>
                                    )}


                                    {(this.props.modeEdit && this.props.selectedOptionFormatCategory) && (
                                        <div className="col-md-12 mt-3">
                                            <label>Cambiar de categoria</label>
                                            <input
                                                type="hidden"
                                                name="format_category_id"
                                                value={this.props.selectedOptionFormatCategory.format_category_id}
                                            />
                                            <Select
                                                onChange={this.props.handleChangeAutocompleteFormatCategory}
                                                options={this.props.categories}
                                                autoFocus={false}
                                                className={`link-form`}
                                                value={this.props.selectedOptionFormatCategory}
                                            />
                                        </div>
                                    )}
                                </div>

                                {/*<Items
                                    icons={this.props.icons}
                                    modeEdit={this.props.modeEdit}
                                    setIconForm={this.props.setIconForm}
                                    icon_id={this.props.formValues.icon_id}
                                />*/}

                                <div className="mt-4">
                                    <CheckboxContainer
                                        checkedItems={this.props.checkedItems}
                                        handleChangeAccions={this.props.handleChangeAccions}
                                        checkboxes={this.props.checkboxes}
                                        modules={this.props.section_custom_views}
                                        handleChangeSelectAll={this.props.handleChangeSelectAll}
                                        modulo_ids={this.props.modulo_ids}
                                        formValues={this.props.formValues}
                                        position={this.props.position}
                                    />
                                </div>

                                {!this.props.errorValues && (
                                    <div className="col-md-12 mt-4 p-0">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                        </div>
                                    </div>
                                )}

                                {(this.props.formValues.custom_view_permission_ids.length >= 0 && !this.props.errorValues) && (
                                   <div className="col-md-12 mt-4 p-0">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes de añadir los permisos</b>
                                        </div>
                                    </div>
                                )}

                                
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                    <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;