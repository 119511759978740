import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TaskList from '../../../GeneralComponents/TaskList'
import GeneralCommets from '../../../GeneralComponents/Comments'
import NumberFormat from "react-number-format";

class Show extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            formUpdate: {
                value: 0,
            },

            formValuesComments: {
                module_comment_id: this.props.indicator.module_comment,
                comment: "",
            },

            formUpdateName: {
                name: "",
            },

            idIndex: 260,
            id: "",
            idEmpresa: "",
            postion: 0,
            dataComments: [],
            idsSelections: [],
            modeEditTable: false,
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    HandleClickUpdate = () => {
        fetch(`/indicator/update_json_table/${this.state.id}/${this.state.postion}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.setState({ idIndex: 260, modeEditTable: false, idEmpresa: "", id: "" })
            this.messageSuccess(data);
        });
    }

    HandleClickUpdateName = () => {
        fetch(`/indicator/update_name/${this.state.idEmpresa}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateName), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.setState({ modeEditTable: false, idEmpresa: "", id: "" })
            this.messageSuccess(data);
        });
    }

    updateFiels = (mes, index, id) => {
        this.setState({
            formUpdate: {
                value: mes.value,
            },

            idIndex: index,
            id: id,
            idEmpresa: "",
            postion: index,
            modeEditTable: true,
        })
    }

    updateName = (empresa) => {
        this.setState({
            formUpdateName: {
                name: empresa.name
            },

            idEmpresa: empresa.id,
            modeEditTable: false,
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleChangeUpdateName = (e) => {
        this.setState({
            formUpdateName: {
                ...this.state.formUpdateName,
                [e.target.name]: e.target.value
            }
        });
    }

    selectionTitle = (indexTable) => {
        const arrayIds = []

        if(this.props.data.data != undefined){
         const array = this.props.data.data
         for (let index = 0; index < array.length; index++) {
             const element = array[index];
             arrayIds.push({id: element.id, value: 0, position: indexTable})     
         }   
        }
        
        this.setState({
            idsSelections: arrayIds,
            idIndex: indexTable,
            modeEditTable: true,
        })
    }

    //comentarios .includes('cat')

    componentDidMount = () => {
        this.loadDataComments()
    }

    loadDataComments = () => {
        fetch(`/get_general_commets_indicators/${this.props.indicator.module_comment}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataComments: data.data,
          });
        });
    }
    
    HandleChangeComment = (e) => {
        this.setState({
            formValuesComments: {
              ...this.state.formValuesComments,
              comment: e.target.value
            }
        });
    }
    
    clearValuesComment = () => {
        this.setState({
            formValuesComments: {
              ...this.state.formValuesComments,
              comment: ""
            }
        });
    }

    editTableChangeUpdate = (e) => {
        const array = this.state.idsSelections
        console.log(array.find(id => parseInt(e.target.name), value => e.target.value ))
    }
    

    render() {
        return (
            <React.Fragment>
                <nav className="" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a
                                href={`/indicator/indicator_tables?type=${this.props.data.indicator_type}`}
                                data-turbolinks="true"
                            >
                                Indicadores
                            </a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="javascript:void(0);">                   
                                {this.props.data.name}
                            </a>
                        </li>
                    </ol>
                </nav>
                <div className="row mb-5">

                    <div className="col-md-12">
                        <div className="card mt-4">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered">
                                    <thead className="background-theader">
                                        <tr>
                                            <th className="text-center">Hospital</th>
                                            <th className="text-center">{this.props.indicator.name}</th>
                                            <th className="text-center">2020</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>

                        <div className="card mt-4">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered">
                                    <thead className="background-theader">
                                        <tr>
                                            <th className="text-center" style={{ width: "20%" }}>{this.props.indicator.variable}</th>
                                            {this.props.data.data != undefined && (
                                                this.props.data.data[0].data.month.map((empresas, index) => (
                                                    <th 
                                                        className="text-center" 
                                                        style={{width: "6%"}}
                                                        onClick={() => this.selectionTitle(index)}
                                                    >
                                                        {empresas.mes}
                                                    </th>
                                                ))
                                            )}
                                            <th>Acomulado</th>
                                            <th>%</th>
                                        </tr>
                                
                                
                                    </thead>


                                            {/*<NumberFormat value={} displayType={"text"} thousandSeparator={true} prefix={"$"}/>*/}

                                    <tbody>
                                        {this.props.data.data != undefined ? (
                                            this.props.data.data.map((empresas, index) => (
                                                <tr key={empresas.id}>
                                                    <th>
                                                        {empresas.id == this.state.idEmpresa ? (
                                                            <input 
                                                                type="text" 
                                                                name={"name"} 
                                                                value={this.state.formUpdateName.name} 
                                                                onChange={this.HandleChangeUpdateName} 
                                                                className="form form-control" 
                                                            />  
                                                        ) : (
                                                            <p onClick={() => this.updateName(empresas)}>{empresas.name}</p>
                                                        )}
                                                    </th>
                                                    {empresas.data.month.map((mes, index) => (
                                                        <th>
                                                            {empresas.id == this.state.id && index == this.state.idIndex ? (
                                                                this.props.indicator.unit_type == "moneda" ? <NumberFormat name="value" thousandSeparator={true} prefix={'$'} value={this.state.formUpdate.value} onChange={this.HandleChangeUpdate} className="form form-control"/> : <input type="text" name={"value"} value={this.state.formUpdate.value} onChange={this.HandleChangeUpdate} className="form form-control" />
                                                            ) : (
                                                                this.props.indicator.unit_type == "moneda" ? <NumberFormat onClick={() => this.updateFiels(mes, index, empresas.id)} value={mes.value} displayType={"text"} thousandSeparator={true} prefix={"$"}/> : <p onClick={() => this.updateFiels(mes, index, empresas.id)}>{mes.value}</p>
                                                            )}
                                                        </th>
                                                    ))}
                                                    <th>{empresas.accumulated}</th>
                                                    <th>{empresas.accumulated_percent}%</th>
                                                </tr>
                                            ))
                                            ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros con el criterio de búsqueda</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            {(this.state.id != "" || this.state.idEmpresa != "") &&(
                                <div className="card-footer">
                                    <a 
                                        className="btn-shadow btn btn-success mr-3" 
                                        onClick={() => this.state.modeEditTable == true ? this.HandleClickUpdate() : this.HandleClickUpdateName()}
                                        style={{ cursor: "pointer", color: "#ffff" }}
                                    >
                                        Actualizar
                                    </a> 

                                    <a 
                                        className="btn btn-outline-danger" 
                                        onClick={() => this.setState({ idIndex: 260, idEmpresa: "", id: "" })}
                                        style={{ cursor: "pointer"}}
                                    >
                                        Cancelar
                                    </a>
                                </div>
                             )} 
                        </div>
                    </div>
                    
                    <div className="col-md-6 mt-4">
                        <TaskList />
                    </div>
                    
                    <div className="col-md-6 mt-4">
                        <GeneralCommets
                            nameModule={"hola"} 
                            dataCommets={this.state.dataComments}  
                            formValuesComments={this.state.formValuesComments}      
                            loadComments={this.loadDataComments}
                            HandleChangeComment={this.HandleChangeComment}
                            clearValuesComment={this.clearValuesComment}
                        />
                    </div>



                </div>
            </React.Fragment>
        );
    }
}

export default Show;

// && index == this.state.idIndex