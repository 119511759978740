import React from 'react'

export default function CardDataThree(props) {
  return (
    <div className='my-dashboard-data-item' style={{ borderBlockColor: props.color, color: props.color }}>
      <div className='my-dashboard-data-item-total'>
        <h4 className='my-dashboard-data-text'>{props.titleTop}</h4>
        <p className='my-dashboard-data-value'>{props.valueTop}</p>
      </div>
      <div className='my-dashboard-data-item-values'>
        <div className='left-content'>
          <h4 className='my-dashboard-data-text'>{props.titleLeft}</h4>
          <p className='my-dashboard-data-value'>{props.valueLeft}</p></div>
        <div className='right-content'>
          <h4 className='my-dashboard-data-text'>{props.titleRight}</h4>
          <p className='my-dashboard-data-value'>{props.valueRight}</p>
        </div>

      </div>
    </div>
  )
}
