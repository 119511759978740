import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

class ShowChildren extends Component {

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <b>Estándar de Seguridad del paciente</b>
                                            <p>{this.props.numeral_children.patient_safety_standar}</p>

                                            <hr/>

                                            <b>Guía de implementación</b>
                                            <p>{ReactHtmlParser(this.props.numeral_children.implementation_guide)}</p>

                                            <hr/>

                                            <b>Área/servicio</b>
                                            <p>{this.props.numeral_children.service_area}</p>

                                            <hr/>

                                            <b>Cláusula ISO</b>
                                            <p>{this.props.numeral_children.iso_clause}</p>
                                            <hr/>

                                            <p><b>Procesos:</b></p>
                                                {this.props.numeral_children.procesos != undefined &&(
                                                    <React.Fragment>
                                                        <ul>
                                                            {this.props.numeral_children.procesos.map(proceso => (
                                                                <li>{proceso.name}</li>
                                                            ))} 
                                                        </ul>
                                                    </React.Fragment>
                                                )}
                                            <hr/>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    <ModalFooter>
                        <label className="btn btn-secondary mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ShowChildren;
