import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import IconsCornerNew from '../../../../GeneralComponents/IconsCornerNew';

class Index extends Component {
    render() {
        const style = {
            fontSize: "11px"
        }

        const styleDiv = {
            paddingTop: "3px", 
            paddingBottom: "3px"
        }

        return (
            <React.Fragment>
                {this.props.data.length >= 1 ? (
                    this.props.data.map((document, index) => (
                        <div className={`card ${index >= 0 ? "mt-3" : ""}`} key={document.id}>
                            <div className="card-body p-2" >
                                <div className="row">
                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Nombre</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>
                                            {(this.props.isNewDocument(document.creation_date)) && (
                                                <IconsCornerNew 
                                                    id={document.id}
                                                    messageTooltip={'Este documento es nuevo'}
                                                />
                                            )}
                                            {document.name}
                                        </span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Fecha de creación</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.creation_date}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Fecha de vencimiento</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={{ background: this.props.state == "vigente" ? this.props.get_colorDate(document.due_date) : "", color: this.props.state == "vigente" ? "#fff" : "" }}>{document.due_date}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Proceso</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.proceso ? document.proceso.name : ""}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Responsable</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.responsable_user.first_name} {document.responsable_user.first_last_name}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Emisor</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.emisor}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Documento</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}><a  target="_blank" href={document.attached.url ? document.attached.url : ""}>{document.attached.url ? "Ver Documento" : ""}</a></span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Opciones</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        {(this.props.estados.edit || this.props.estados.delete) && (
                                            <UncontrolledDropdown className='btn-group'>
                                                <DropdownToggle className='btn btn-info'>
                                                    <i className="fas fa-bars"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                {this.props.estados.edit && (
                                                    <DropdownItem
                                                        onClick={() => this.props.edit(document)}
                                                        className="dropdown-item"
                                                    >
                                                        Editar
                                                    </DropdownItem>
                                                )}

                                                {(this.props.estados.delete && this.props.state == "obsoleto")  && (
                                                    <DropdownItem
                                                        onClick={() => this.props.delete(document.id)}
                                                        className="dropdown-item"
                                                    >
                                                        Eliminar
                                                    </DropdownItem>
                                                )}

                                                {this.props.state != "obsoleto" && (
                                                    <DropdownItem
                                                        onClick={() => this.props.changeState(document.id)}
                                                        className="dropdown-item"
                                                    >
                                                        Pasar a obsoletos
                                                    </DropdownItem>
                                                )}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="card text-center">
                        <div className="text-center">
                            <div className="text-center mt-4 mb-4" style={{width: '100%'}}>
                                <p className='ui-indexWeb-messageNoRecord'>Todavía no hay registros creados, da clic en<a onClick={() => this.props.toogle('new')}  className=" btn btn-info"><i className='fas fa-file-alt'></i> Nuevo documento</a> y genera el primer registro.</p>
                                <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/11/noRecord_global.jpg" alt="" className="ui-indexWeb-imgNoRecord" />
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Index;
