import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

const FormCreate = (props) => {

    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                {/*<ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>*/}

                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <div className="row">

                            <div className="col-md-6 mb-4">
                                <label>Empresa</label>
                                <input
                                    type="hidden"
                                    name="tenant_id"
                                    value={props.selectedOption.tenant_id}
                                />

                                <Select
                                    onChange={props.handleChangeAutocomplete}
                                    options={props.tenants}
                                    autoFocus={false}
                                    className={`link-form ${!props.errorValues && props.formValues.tenant_id == "" ? "error-class" : ""}`}
                                    value={props.selectedOption}
                                />
                            </div>

                            {props.formats.length >= 1 && (
                                <div className="col-md-6">
                                    <label>Formato Conductores</label>
                                    <input
                                        type="hidden"
                                        name="format_personal_id"
                                        value={props.selectedOptionFormatPersonal.format_personal_id}
                                    />

                                    <Select
                                        onChange={props.handleChangeAutocompleteFormatPersonal}
                                        options={props.formats}
                                        autoFocus={false}
                                        className={`link-form ${!props.errorValues && props.formValues.format_personal_id == "" ? "error-class" : ""}`}
                                        value={props.selectedOptionFormatPersonal}
                                    />
                                </div>
                            )}



                            {props.formats.length >= 1 && (
                                <div className="col-md-6">
                                    <label>Formato Reportes Internos</label>
                                    <input
                                        type="hidden"
                                        name="format_internal_report_id"
                                        value={props.selectedOptionFormatInternal.format_internal_report_id}
                                    />

                                    <Select
                                        onChange={props.handleChangeAutocompleteFormatInternal}
                                        options={props.formats}
                                        autoFocus={false}
                                        className={`link-form ${!props.errorValues && props.formValues.format_internal_report_id == "" ? "error-class" : ""}`}
                                        value={props.selectedOptionFormatInternal}
                                    />
                                </div>
                            )}

                            {props.formats.length >= 1 && (
                                <div className="col-md-6 mb-4">
                                    <label>Formato Reportes Externos(PQRS)</label>
                                    <input
                                        type="hidden"
                                        name="format_external_report_id"
                                        value={props.selectedOptionFormatExternal.format_external_report_id}
                                    />

                                    <Select
                                        onChange={props.handleChangeAutocompleteFormatExternal}
                                        options={props.formats}
                                        autoFocus={false}
                                        className={`link-form ${!props.errorValues && props.formValues.format_external_report_id == "" ? "error-class" : ""}`}
                                        value={props.selectedOptionFormatExternal}
                                    />
                                </div>
                            )}

                            {!props.errorValues && (
                                <div className="col-md-12 mt-4">
                                    <div className="alert text-center alert-danger" role="alert">
                                        <b>Debes de completar todos los campos requeridos</b>
                                    </div>
                                </div>
                            )}

                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                        <button className="btn-shadow btn btn-info" onClick={props.handleClick}>{props.nameBnt}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </React.Fragment>
    );
}


export default FormCreate;

