import React from "react";
import Chart from "react-google-charts";

class ColumnChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();


    }
    componentDidMount() {
        console.log(this.chartReference); // returns a Chart.js instance reference
    }
    render() {
        return (
            <React.Fragment>
                <div>
                <div className="pie-header-column">
                        <div className="datos">
                            {this.props.dataColumn.datag.map((dn, index) => (
                                <div key={index} className={"column-" + dn[0]}>
                                    <p>{dn[0]}</p>
                                    <p>{dn[1]}</p>
                                </div>
                            )
                            )}

                        </div>
                    </div>

                    <Chart
                        width={'100%'}
                        height={'300px'}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={
                            this.props.dataColumn.datag
                        } 
                        options={{
                            title:"CLASIFICACIÓN DE LOS EVENTOS",
                            hAxis: {
                                title: 'Evento relacionado',
                            }, vAxis: {
                                title: '# Eventos',
                            },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />


                </div>
            </React.Fragment>
        );
    }
}

export default ColumnChart;
[["x","Evento"],["Administración Clínica 1",2],["Procedimiento clínico 1",1],["Infección asociada a la atención sanitaria 1",1]]