import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import Preloader from '../GeneralComponents/LoadingComponen';
import FormCreate from '../components/settings/Holidays/FormCreate';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { array } from 'prop-types';

class HolidayIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            holiday_id: "",
            
            form: {
                country: "",
                year: "",
                days: []
            },

            selectedOption: {
                country: "",
                label: "Seleccione el pais"
            },
        }
    }

    componentDidMount(){
        this.loadData();
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (plan_category) => {
        this.setState({
            data: this.state.data.map(item => {
                if (plan_category.id === item.id) {
                    return {
                        ...item,
                        name: plan_category.name,
                        plans: plan_category.plans
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            holiday_id: "",
            ErrorValues: true,

            form: {
                country: "",
                year: "",
                days: []
            },

            selectedOption: {
                country: "",
                label: "Seleccione el pais"
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/holidays/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                });
            }
        });
    };

    validationForm = () => {
        if (this.state.form.format_select_id != "" &&
            this.state.form.question_asociation_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (true) {
            if (this.state.holiday_id) {
                fetch(`/settings/holidays/${this.state.holiday_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register);
                        this.messageSuccess(data);
                        this.setState({ modal: false });
                        this.clearValues();
                    });
            } else {
                fetch(`/settings/holidays`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateData(data.register)
                    this.messageSuccess(data);
                    this.setState({ modal: false });
                    this.clearValues();
                });
            }
        }
    }

    edit = (holiday) => {
        this.setState({
            modal: true,
            holiday_id: holiday.id,
            form: {
                country: holiday.country,
                year: holiday.year,
                days: holiday.days,
            },

            selectedOption: {
                country: holiday.country,
                label: holiday.country
            },
        })
    }


    loadData = () => {
        fetch("/settings/get_holidays", {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                country: selectedOption.value
            }
        });
    };

    handleChangeCalendar = (e) => {
        let date = new Date(e)
        let date_month = ("0" + (date.getMonth() + 1)).slice(-2)
        let day = ("0" + (date.getDate())).slice(-2)
        let new_date = `${date.getFullYear()}-${date_month}-${day}`
        let array = this.state.form.days
        //año-mes-dia
        if (true){
            array.push(new_date)
        }

        this.setState({
            form: {
                ...this.state.form,
                days: array
            }
        })
    }

    removeArray = (index) => {
        var arr = this.state.form.days
        delete arr[index]
        this.setState({
            form: {
                ...this.state.form,
                days: arr.filter(item => item != null)
            }
        })
    }

    removeAll = () => {
        this.setState({
            form: {
                ...this.state.form,
                days: []
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Configuracion de paises`}
                    subTitle={"Comparte tus formatos con otras empresas"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />


                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.holiday_id ? "Actualizar pais" : "Crear pais"}
                        nameBnt={this.state.holiday_id ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        handleChangeCalendar={this.handleChangeCalendar}
                        removeArray={this.removeArray}
                        removeAll={this.removeAll}

                        //select
                        countries={this.props.countries}
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOption={this.state.selectedOption}
                    />
                )}

                {!this.state.isLoaded ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Pais</th>
                                            <th>Año</th>
                                            <th>Dias festivos</th>
                                            <th style={{ width: "4%" }}>Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map(holiday_register => (
                                                <tr key={holiday_register.id}>
                                                    <td>{holiday_register.country}</td>
                                                    <td>{holiday_register.year}</td>
                                                    <td>
                                                        {holiday_register.days.map((item, index) => (
                                                            <span className="badge-calendar mr-2">{item}</span>
                                                        ))}
                                                    </td>

                                                    <td className="text-right">
                                                        {true && (
                                                            <UncontrolledDropdown className='btn-group'>
                                                                <DropdownToggle className='btn-shadow btn btn-info'>
                                                                    <i className="fas fa-bars"></i>
                                                                </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(holiday_register)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(holiday_register.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                    </React.Fragment>
                ) : (
                    <Preloader />
                )}
            </React.Fragment>
        );
    }
}


export default HolidayIndex;
WebpackerReact.setup({ HolidayIndex });