import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from '../../../GeneralComponents/FormEvents'
import FormFilterName from '../../../GeneralComponents/FormFilterName';

class Table extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            submitBtnFile: false,
            isLoadedFiles: false,
            id: "",

            form: {
                name: "",
                description: "",
                module: "",
            },

            formUpdate: {
                name: "",
                module: ""
            },
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                description: "",
                module: ""
            },

            formUpdate: {
                name: "",
                module: ""
            },
            
            ErrorValues: true,
        })
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        }else{
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
              ...this.state.form,
              [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if(from == "new"){
            this.setState({ modal: true})
            this.clearValues();
        }else{
            this.setState({ modal: false})
        }
    }

    HandleClick = () => {
        if(this.validationForm() == true){
            fetch("/health/specific_areas", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateData(data.area);
                this.clearValues();
                this.setState({ modal: false})
                this.messageSuccess(data);
            });
        }
    }


    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.value) {
            fetch(`/health/specific_areas/${id}`, {
              method: "delete",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.props.loadData()
                this.messageSuccess(response)
              });
          }
        });
    };

    //Update section

    edit = (rol) => {
        this.setState({
            id: rol.id,
            formUpdate: {
                name: rol.name,
                module: rol.module
            }
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
              ...this.state.formUpdate,
              [e.target.name]: e.target.value
            }
        });
    }

    HandleClickUpdate = () => {
        fetch(`/health/specific_areas/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateItem(data.area)
            this.clearValues();
            this.setState({ id: ""})
            this.messageSuccess(data);
        });
    }

    getModule = (moduleName) => {
        if(moduleName == "n_eventos"){
            return "Notificación de eventos"
        }else{
            return "Eventos de seguridad"
        }
    }

    //subida de archivo

    uploadExel = (e) => {
        this.setState({
            file: e.target.files[0],
            submitBtnFile: (this.state.submitBtnFile == false ? true : false)
        });
    }

    handleClickUpload = () => {
        this.setState({ isLoadedFiles: true })
        const formData = new FormData();
        formData.append("file", this.state.file)
        fetch(`/health/import_specific_areas`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.setState({ file: null, submitBtnFile: false, isLoadedFiles: false })
            this.messageSuccess(data);
        });
    }

    render() {
        return (
            <React.Fragment>

                <HeaderTitle 
                    title={ this.props.user.app_type == "beewo" ? "Tareas" : "Áreas Específicas"} 
                    subTitle={""}
                    titleNew={"Nueva Área"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />

                <FormCreate
                    //modal props
                    backdrop={"static"}
                    modal={this.state.modal}
                    toggle={this.toogle}    
                    title={"Agregar nueva Área"}

                    //form props
                    formValues={this.state.form}
                    errorValues={this.state.ErrorValues}
                    onChangeForm={this.HandleChange}
                    submitForm={this.HandleClick}

                    showSelect={false}
                />

                <FormFilterName
                    handleChangeFilter={this.props.handleChangeFilter}
                    handleClickFilter={this.props.handleClickFilter}
                    showbtnSubmit={this.props.showbtnSubmit}
                    clearValues={this.props.clearValues}
                    formFilter={this.props.formFilter}
                />

                <div className="content main-card mb-3 card">
                    <div className="card-header text-right">

                        <label
                            htmlFor="fathers"
                            className="btn-shadow btn btn-info mt-2 mr-2"
                        >
                            {this.state.isLoadedFiles ? (
                                <div className="loader-files"></div>
                            ) : (
                                <React.Fragment>
                                {"Cargar excel"}
                                </React.Fragment>
                            )} 
                        </label>

                        <a 
                            href="https://mybc1.s3.amazonaws.com/uploads/rseguimiento/evidencia/704/FORMATO_AREAS_V2__2_.xlsx"
                            target="_blank"
                        >
                            Formato
                        </a>

                        <input
                            type="file"
                            id="fathers"
                            onChange={(e) => this.uploadExel(e)}
                            style={{ display: "none" }}
                        />

                        {this.state.submitBtnFile && (
                            <button
                                onClick={() => this.handleClickUpload()}
                                className="btn-shadow btn btn-primary ml-3"
                            >
                                Subir <i className="fas fa-file-upload"></i>
                            </button>
                        )}

                    </div>

                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                            {/*     <th>Módulo</th> */}
                                {(true || true) &&(
                                    <th className="text-center"  style={{ width: "8%" }}></th>
                                )}
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.data.length >= 1 ? (
                                this.props.data.map(procesos => (
                                    <tr key={procesos.id}>
                                        <td>
                                            {procesos.id == this.state.id ? (
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={this.state.formUpdate.name}
                                                    onChange={this.HandleChangeUpdate}
                                                    className="form form-control"
                                                />
                                            ) : (
                                                <p>{procesos.name}</p>
                                            )}
                                        </td>

{/*                                         <td>
                                            {procesos.id == this.state.id ? (
                                                <select 
                                                    name="module"
                                                    value={this.state.formUpdate.module}
                                                    onChange={this.HandleChangeUpdate}
                                                    className={`form form-control`}
                                                >
                                                    <option value="">Seleccione el evento de destino</option>
                                                    <option value="n_eventos">Notificacion de eventos</option>
                                                    <option value="e_seguridad">Eventos de seguridad</option>

                                                </select>
                                            ) : (
                                                <p>{this.getModule(procesos.module)}</p>
                                            )}
                                        </td> */}

                                        {(true || true) &&(
                                            <td className="text-right" style={{ width: "10px" }}>
                                                {this.state.id != "" ? (
                                                    <React.Fragment>
                                                        {procesos.id == this.state.id &&(
                                                            <React.Fragment>
                                                                <i className="fas fa-check-circle update-section" onClick={() => this.HandleClickUpdate()}></i> 
                                                                <i className="fas fa-times-circle update-false" onClick={() => this.setState({ id: ""})}></i>
                                                            </React.Fragment>
                                                        )} 
                                                    </React.Fragment>
                                                ) : (
                                                    <UncontrolledDropdown className='btn-group'>
                                                        <DropdownToggle className='btn btn-info'>
                                                            <i className="fas fa-bars"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                            {true && (
                                                                <DropdownItem
                                                                    onClick={() => this.edit(procesos)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Editar
                                                                </DropdownItem>
                                                            )}
                            
                                                            {true && (  
                                                                <DropdownItem
                                                                    onClick={() => this.delete(procesos.id)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Eliminar
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                            ))
                                ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        <div className="text-center mt-4 mb-4">
                                            <h4>No hay registros</h4>
                                        </div>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Table;