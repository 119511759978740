import React, { Component } from 'react';

class FormFilterName extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
    }

    render() {
        return (
            <React.Fragment>
                <div className="card mb-3">
                    <form onSubmit={this.handleSubmit}>
                        <div className={`card-body`}>
                            <div className="row">
                                <div className="col-md-4">
                                    <input 
                                        type="text" 
                                        name="name" 
                                        className="form-control" 
                                        onChange={this.props.handleChangeFilter} 
                                        value={this.props.formFilter.name} 
                                        placeholder="Buscador" 
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <button
                                className="btn-shadow btn btn-info  mr-3"
                                onClick={() => this.props.handleClickFilter()}
                            >
                                Aplicar Filtro
                            </button>

                            <a
                                className="btn btn-outline-danger"
                                onClick={() => this.props.clearValues()}
                            >
                                Cancelar
                            </a>
                        </div>
                    </form>
                </div>

            </React.Fragment>
        );
    }
}

export default FormFilterName;
