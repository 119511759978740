import WebpackerReact from 'webpacker-react';
import React, { Component } from 'react';
import HeaderTitle from '../GeneralComponents/HeaderTitle';
import SignaturePad from 'react-signature-canvas'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import UploadFile from '../components/documentManagement/Document/UploadFile';

class UpdateUserFirm extends Component {

    constructor(props){
        super(props)
        this.sigPad = {}
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            isLoaded: false,
            trimmedDataURL: this.props.current_user.firm,
            firm_img: this.props.current_user.firm_img,
            
            nameFile: "",
            sizeFile: 0,
            formCode: {
                attached: {}
            },
        }
    }

    clear = () => {
        this.sigPad.clear()
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    trim = () => {
        const value = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
        const form = { firm: value }

        fetch(`/users/update_user_firm`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({ 
                trimmedDataURL: value,
                firm_img: null,
            });
            this.sigPad.clear();
            this.messageSuccess(data);
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.clearValues();
        }
    }

    clearValues = () => {
        this.setState({
            modal: false,
            nameFile: "",
            sizeFile: 0,
        })
    }

    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formCode: {
                    ...this.state.formCode,
                    attached: file.size >= 11485760 ? {} : file
                },
  
                nameFile: file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
                sizeFile: file.size,
            })
        ));
    };

    handleClick = () => {
        if(this.state.sizeFile <= 11485760){
            this.setState({ isLoaded: true })
            const formData = new FormData();
            formData.append("firm_img", this.state.formCode.attached)
    
            fetch(`/users/upload_user_firm`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data);
                this.setState({
                    modal: false,
                    isLoaded: false,
                    trimmedDataURL: null,
                    firm_img: data.register,
                    formCode: {
                        attached: {}
                    },
                })
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle 
                    title={"Firma"} 
                    subTitle={"Añade y actualiza tu firma"}
                    titleNew={"Crear Cambio"}
                    icon={"fas fa-file-code"} 
                    onClickNew={null}
                    showNew={false}
                    showFilter={false} 
                />

                {this.state.modal && (
                    <UploadFile
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Subir imagen"}

                        handleClick={this.handleClick}
                        isLoaded={this.state.isLoaded}
                        nameFile={this.state.nameFile}
                        handleFile={this.handleFile}
                        sizeFile={this.state.sizeFile}
                    />
                )}

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <SignaturePad 
                                    canvasProps={{ className: `sigPad_generate sigPad_generate_505`, width: 505, height: 150 }} 
                                    ref={(ref) =>  this.sigPad = ref } 
                                />

                                <div className="mt-2">
                                    <button className={"btn-shadow btn btn-info mr-2"} onClick={() => this.clear()}>
                                        <i className="fas fa-trash-alt"></i> Limpiar
                                    </button>

                                    <button className={"btn-shadow btn btn-info"} onClick={() => this.trim()}>
                                        <i className="fas fa-camera"></i> Confirmar
                                    </button>

                                    <button className={"btn-shadow btn btn-info ml-2"} onClick={() => this.toogle("new")}>
                                        <i className="fas fa-camera"></i> Subir firma
                                    </button>
                                </div>
                            </div>

                            <div className="col-md-6">
                                {(this.state.trimmedDataURL || this.state.firm_img) && (
                                    <React.Fragment>
                                        <img style={{ width: "200px" }} className={"sigImage"} src={this.state.firm_img ? this.state.firm_img : this.state.trimmedDataURL} />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default UpdateUserFirm;
WebpackerReact.setup({ UpdateUserFirm });
