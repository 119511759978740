import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import TaskAndComments from '../components/formatos/Surveys/TaskAndComments'
import HeaderTitle from '../GeneralComponents/HeaderTitle';
import TaskIndex from '../components/tasks/tasksCard/Index'
import GeneralComments from '../GeneralComponents/ChatBoxModuleComment'
import NumberFormat from 'react-number-format';
import SurveyIndex from '../components/formatos/Surveys/SurveyIndex'
import Select from "react-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import ModalShowInfo from '../components/formatos/Surveys/ModalShowInfo';
import SurveyCustomView from '../packComponents/SurveyCustomView';
import IndexTaskModule from '../components/tasks/taskBoard/IndexTaskModule';
import { UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledTooltip } from 'reactstrap';
import ModalQr from '../components/formatos/Surveys/ModalQr';
import FlowChartIndex from '../components/formatos/FlowChart/Index';

class SurveyShowTask extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataTask: [],
            dataSurvey: [],
            countComment: 0,
            modal: false,
            question: {},
            task_board: this.props.survey.task_board ? this.props.survey.task_board : "",
            flow_chart: this.props.survey.flow_chart ? this.props.survey.flow_chart : {},
            isLoadedTask: true,
            showParrafo: 0,
            showSurveys: false,
            modalQr: false,
            showTasks: true,
            format_association: {},
            id_question_header: this.props.module.header,
            url: "",

            changeFormat: false,
            showHeader: this.props.module.header,
            name: "",
            register_true: {},

            selectedOption: {
                question_id: "",
                label: "Selecciona la pregunta"
            },

            form: {
                question_id: "",
            },

            questions: [],
            format_association_id: "",
            tableWidth: 0,
            survey_total: 0,
            formato: {},
            estados: {},

            pdfs: [],
            format_pdfs: []

        }
    }

    /*     copyLink = () => {
            Swal.fire({
                position: "center",
                type: `success`,
                title: `Link copiado`,
                showConfirmButton: false,
                timer: 1500,
            });
    
            navigator.clipboard.writeText(this.props.survey.id)
        } */

    copyId = () => {
        Swal.fire({
            position: "center",
            type: `success`,
            title: `Link copiado`,
            showConfirmButton: false,
            timer: 1500,
        });

        navigator.clipboard.writeText(this.props.survey.id)
    }

    componentDidMount() {
        this.loadDataTask();
        this.selectConfiguration();

        if (this.props.format_association_id != 0) {
            this.getFormatInfo(this.props.format_association_id)
        } else if (this.props.format.format_associations.length > 0) {
            let format_associations_id = this.props.format.format_associations[0].format_select.id
            this.getFormatInfo(format_associations_id)
        }
    }

    selectConfiguration = () => {
        let array = []
        let arrayPdfs = [];

        this.props.questions.map((item) => (
            array.push({ label: item.question, value: item.id })
        ))

        this.props.format_pdfs.map((item) => (
            arrayPdfs.push({ label: item.name, value: item.id })
        ))

        this.props.module.answers.filter(value => value.is_header).map((item) => (
            this.setState({
                register_true: item,
                name: item.name,
            })
        ))

        this.setState({
            questions: array,
            pdfs: arrayPdfs,
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                question_id: selectedOption.value
            }
        });
    };

    updateTitle = () => {
        fetch(`/formatos/update_question_header`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    changeFormat: false,
                    showHeader: true,
                    name: data.register.question,
                    id_question_header: data.header,

                    selectedOption: {
                        question_id: "",
                        label: "Pregunta"
                    },

                    form: {
                        question_id: "",
                    },
                })
            });
    }




    updateStateLoad = (state) => {
        this.setState({ isLoadedTask: state })
    }

    updateStateCountComment = (count) => {
        this.setState({ countComment: count })
    }

    //add items
    updateData = (data) => {
        this.setState({
            dataTask: [...this.state.dataTask, data],
            isLoadedTask: false,
        })
    }

    updateItem = task => {
        console.log("updateItemupdateItemupdateItem")
        this.setState({
            dataTask: this.state.dataTask.map(item => {
                if (task.id === item.id) {
                    return {
                        ...item,
                        description: task.description,
                        due_date: task.due_date,
                        name: task.name,
                        state: task.state,
                        user_owner: task.user_owner,
                        general_comments: task.general_comments.length,
                        general_files: task.general_files.length,
                        task_labels: task.task_labels,
                        users: task.task_labels,
                        checklist: task.checklist,
                    }
                }
                return item;
            })
        });
    }

    loadDataTask = () => {
        fetch(`/task/get_module_task/${this.props.module.module_task_id}?survey=${this.props.survey.id} `, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataTask: data.data,
                    countComment: data.comments,
                    isLoadedTask: false,
                });
            });
    }

    generateTaskBoardSurvey = () => {
        fetch(`/task/generate_task_board_survey/${this.props.survey.id}/${this.props.formato.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    task_board: data.register,
                });
            });
    }

    generateFlowChart = () => {
        const form = { description: "Editar Título Árbol de Fallos", survey_id: this.props.survey.id }
        fetch(`/formatos/flow_charts`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    flow_chart: data.register
                })
            });
    }

    cancelSelect = () => {
        this.setState({
            showSurveys: false,
            showTasks: true,
            format_association: {},
        })
    }

    getFormatInfo = (format_association) => {
        this.setState({
            showTasks: false,
            showSurveys: false,
        });
        console.log("entre")
        fetch(`/formatos/get_survey_formats/${format_association}/${this.props.survey.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    format_association: format_association,
                    dataQuestionnvm: data.dataQuestion,
                    dataQdataAnswersnvm: data.dataAnswers,
                    showSurveys: true,
                    formato: data.formato,
                    tableWidth: data.tableWidth,
                    dataSurvey: data.data,
                    format_association_id: format_association,
                    survey_total: data.survey_total,
                    estados: data.estados,
                    format_pdfs: data.format_pdfs
                });
                this.render();
            });
    }

    dateDate = (fecha) => {

        console.log(fecha)
        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));
        console.log(d)
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear();

    }
    dateTime = (fecha) => {

        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));

        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear() + " / " + d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes());


    }

    showParrafoF = (val) => {
        this.setState({
            showParrafo: val
        })
    }

    hideParrafoF = () => {
        this.setState({
            showParrafo: 0
        })
    }

    toogle = (from, question) => {
        if (from == "new") {
            this.setState({ modal: true, question: question })
        } else {
            this.setState({ modal: false, question: {} })
        }
    }

    copyLink = () => {
        Swal.fire({
            position: "center",
            type: `success`,
            title: `Link copiado`,
            showConfirmButton: false,
            timer: 1500,
        });

        const url = `${this.props.domain}/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys/${this.props.survey.token}`
        navigator.clipboard.writeText(url)
    }

    toogleModalQr = (from, url) => {
        if (from == "new") {
            this.setState({ modalQr: true, url: url })
        } else {
            this.setState({ modalQr: false, url: "" })
        }
    }

    deleteFlowChart = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El arbol sera eliminado para siempre, con todos los items!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/flow_charts/${this.state.flow_chart.id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.setState({
                            flow_chart: {}
                        })
                    });
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.format.name}`}
                    subTitle={"Detalle del Registro"}
                    titleNew={"Nuevo riesgo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}

                    is_index={true}
                    url={this.props.current_url}
                />

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">

                        <li className="breadcrumb-item">
                            <a
                                href={this.props.custom_view_id == 0 ? `/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys?view=ACTIVE` : `/formatos/custom_view/${this.props.custom_view_id}`}
                                data-turbolinks="true"
                            >
                                {"Volver al listado"}
                            </a>
                        </li>
                    </ol>
                </nav>


                {this.state.modalQr && (
                    <ModalQr
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalQr}
                        toggle={this.toogleModalQr}
                        title={"Código QR"}
                        url={this.state.url}
                    />
                )}

                {(!this.state.showHeader && this.props.permit) && (
                    <div className="alert alert-warning" role="alert">
                        <p>Te invitamos a que pongas una pregunta título<b style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => this.setState({ changeFormat: !this.state.changeFormat })}> para cambiarlo haz click aqui </b></p>
                        {this.state.changeFormat && (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-4">
                                        <input
                                            type="hidden"
                                            name="question_id"
                                            value={this.state.selectedOption.question_id}
                                        />
                                        <Select
                                            onChange={this.handleChangeAutocomplete}
                                            options={this.state.questions}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.state.selectedOption}
                                        />
                                    </div>

                                    {this.state.form.question_id != "" && (
                                        <React.Fragment>
                                            <div className="col-md-2">
                                                <i className="fas fa-check icon-check" style={{ cursor: "pointer" }} onClick={() => this.updateTitle()}></i>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                )}


                <div className="card">
                    <div className="card-header" style={{ border: 0 }}>

                        <div className="col-md-12 mt-2">

                            {this.state.showHeader && (
                                <div className="col-md-12">
                                    {this.state.changeFormat ? (
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="hidden"
                                                        name="question_id"
                                                        value={this.state.selectedOption.question_id}
                                                    />
                                                    <Select
                                                        onChange={this.handleChangeAutocomplete}
                                                        options={this.state.questions}
                                                        autoFocus={false}
                                                        className={`link-form`}
                                                        value={this.state.selectedOption}
                                                    />
                                                </div>
                                                <div className="col-md-4 pl-0 text-left">
                                                    <i className="fas fa-times icon-false" style={{ cursor: "pointer" }} onClick={() => this.setState({ changeFormat: !this.state.changeFormat })}></i>
                                                    {this.state.form.question_id != "" && (
                                                        <i className="fas fa-check icon-check ml-3" style={{ cursor: "pointer" }} onClick={() => this.updateTitle()}></i>
                                                    )}
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <p className="title-show-survey" style={{ display: "flex", justifyContent: "center" }}>
                                                {ReactHtmlParser(this.state.id_question_header)} {this.props.permit ? <i className="fas fa-pencil-alt" style={{ cursor: "pointer", fontSize: "20px", marginRight: "10px", marginLeft: "20px" }} onClick={() => this.setState({ changeFormat: true })}></i> : ""}
                                            </p>
                                        </React.Fragment>
                                    )}
                                </div>
                            )}

                        </div>
                        <div className="options-survey-show text-right ui-surveyShowTask-option-survey" >
                            {(!this.state.task_board && this.props.formato.task_board) && (
                                <button
                                    className="btn-shadow btn btn-primary float-right mr-3"
                                    onClick={() => this.generateTaskBoardSurvey()}
                                >
                                    Generar tareas
                                </button>
                            )}

                            {(this.props.formato.has_flow_chart && Object.keys(this.state.flow_chart).length == 0) && (
                                <button
                                    className="btn-shadow btn btn-primary float-right mr-3"
                                    onClick={() => this.generateFlowChart()}
                                >
                                    Generar arbol de fallos
                                </button>
                            )}

                            {this.props.estados.edit && (
                                <button
                                    className="btn-shadow btn btn-primary mr-3"
                                    onClick={() => this.copyLink()}
                                >
                                    Copiar link de edicion
                                </button>
                            )}

                            {true && (
                                <button
                                    className="btn-shadow btn btn-primary mr-3"
                                    onClick={() => this.copyId()}
                                >
                                    Copiar ID
                                </button>
                            )}


                            {true && (
                                <React.Fragment>
                                    <button
                                        className="btn-shadow btn btn-info mr-2 ui-surveyShowTask-button-qr"
                                        id="PopoverLegacyQR"
                                        onClick={() => this.toogleModalQr("new", `/formatos/generate_questions_qr/${this.props.survey.id}`)}
                                        type="button"
                                    >
                                        <i className="fas fa-bars"></i> QR del formato
                                    </button>


                                </React.Fragment>
                            )}


                            {(true && this.props.format_pdfs.length >= 1) && (
                                <React.Fragment>
                                    <button
                                        className="btn-shadow btn btn-info mr-2 ui-surveyShowTask-button-pdf"
                                        id="PopoverLegacyPdf"
                                        type="button"
                                    >
                                        <i className="fas fa-bars"></i>  PDF del formato
                                    </button>

                                    <UncontrolledPopover trigger="legacy" placement="bottom" target={`PopoverLegacyPdf`}>
                                        <PopoverHeader>{"PDF's del formato"}</PopoverHeader>
                                        <PopoverBody>
                                            <div className="col-md-12 p-0 ui-surveyShowTask-list__container" style={{ width: "216px" }}>
                                                <ul className="ui-surveyShowTask-list__container-ul">
                                                    {false && (
                                                        <li className="mb-2">
                                                            <a
                                                                target="_blank"
                                                                href={`/formatos/survey_pdf/${this.props.survey.id}.pdf?type=grid`}
                                                            >
                                                                <i className="fas fa-table mr-2"></i>PDF en tabla
                                                            </a>

                                                            <button
                                                                className="btn-shadow btn btn-info float-right btn-qr"
                                                                onClick={() => this.toogleModalQr("new", `${this.props.domain}/formatos/survey_pdf/${this.props.survey.id}.pdf?type=grid`)}
                                                            >
                                                                QR
                                                            </button>
                                                        </li>
                                                    )}

                                                    {false && (
                                                        <li className="mb-2">
                                                            <a
                                                                href={`/formatos/survey_pdf/${this.props.survey.id}.pdf?type=list`}
                                                                target="_blank"
                                                            >
                                                                <i className="fas fa-list mr-2"></i>PDF lista
                                                            </a>

                                                            <button
                                                                className="btn-shadow btn btn-info float-right btn-qr"
                                                                onClick={() => this.toogleModalQr("new", `${this.props.domain}/formatos/survey_pdf/${this.props.survey.id}.pdf?type=list`)}
                                                            >
                                                                QR
                                                            </button>
                                                        </li>
                                                    )}

                                                    {this.props.format_pdfs.map(pdf => (
                                                        <li
                                                            key={pdf.id}
                                                            className="mb-2"
                                                        >
                                                            <a
                                                                href={`/formatos/survey_pdf/${this.props.survey.id}.pdf?type=custom&pdf_id=${pdf.id}&show=one`}
                                                                target="_blank"
                                                            >
                                                                {pdf.name}
                                                            </a>

                                                            <button
                                                                className="btn-shadow btn btn-info float-right btn-qr"
                                                                onClick={() => this.toogleModalQr("new", `${this.props.domain}/formatos/survey_pdf/${this.props.survey.id}.pdf?type=custom&pdf_id=${pdf.id}&show=one`)}
                                                            >
                                                                QR
                                                            </button>

                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </PopoverBody>

                                        {/* href={`/formatos/survey_pdf/${this.props.survey.id}.pdf?type=custom&pdf_id=${pdf.id}&show=one`} */}
                                    </UncontrolledPopover>
                                </React.Fragment>
                            )}

                            {/*<div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                <div className="btn-group" role="group">
                                    <button
                                        className="btn-shadow btn btn-info mr-2"
                                        id="btnGroupDrop1"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-bars"></i>
                                    </button>

                                    <div className="dropdown-menu dropdown-menu-right dp-pdf">
                                        {true && (
                                            <a
                                                target="_blank"
                                                className="dropdown-item"
                                                href={`/formatos/survey_pdf/${this.props.survey.id}.pdf?type=grid`}
                                            >
                                                PDF grilla
                                            </a>
                                        )}

                                        {true && (
                                            <a
                                                className="dropdown-item"
                                                href={`/formatos/survey_pdf/${this.props.survey.id}.pdf?type=list`}
                                                target="_blank"
                                            >
                                                PDF lista
                                            </a>
                                        )}

                                        {this.props.format_pdfs.map(pdf => (
                                            <a
                                                className="dropdown-item"
                                                key={pdf.id}
                                                href={`/formatos/survey_pdf/${this.props.survey.id}.pdf?type=custom&pdf_id=${pdf.id}&show=one`}
                                                target="_blank"
                                            >
                                                {pdf.name} 

                                                
                                            </a>
                                        ))}




                                        {false && (
                                            <button
                                                className="dropdown-item"
                                                onClick={() => console.log("asdasdddddddddddddddddddddddddddddd")}
                                            >
                                                Copiar link de proyecto
                                            </button>
                                        )}

                                    </div>
                                </div>
                            </div>*/}

                            {false && (
                                <button
                                    className="btn-shadow mr-2 btn btn-primary float-right"
                                    onClick={() => this.copyLink()}
                                >
                                    <i className="far fa-copy"></i>
                                </button>
                            )}

                            {false && (
                                <a
                                    className="btn-shadow mr-3 btn btn-primary float-right"
                                    style={{ textTransform: "initial" }}
                                    href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id_format}/surveys/${this.props.survey.id}/gantt_diagram`}
                                    target="_blank"
                                >
                                    <i className="far fa-folder-open"></i> Diagrama de gantt
                                </a>
                            )}
                        </div>
                    </div>

                    <div className="card-body">
                        {this.state.modal && (
                            <ModalShowInfo
                                backdrop={"static"}
                                modal={this.state.modal}
                                question={this.state.question}
                                toggle={this.toogle}
                                title={this.state.question.name}
                            />
                        )}

                        {!this.props.module.show_custom && (
                            <React.Fragment>
                                <div style={{ display: "grid" }}>
                                    <ul className="nav nav-tabs mb-0 ui-navTab-Tabs" id="myTab" role="tablist">
                                        {this.props.module.steps.map((answer, index) => (
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${index == 0 ? "active" : ""}`}
                                                    id="home-tab"
                                                    data-toggle="tab"
                                                    href={`#home${answer.id}`}
                                                    onClick={() => this.setState({ nexStep: index })}
                                                    role="tab"
                                                    aria-disabled={true}
                                                    aria-controls="home"
                                                    aria-selected="true"
                                                >

                                                    {answer.name}
                                                </a>

                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="row mt-4">

                                    <React.Fragment>
                                        <div className="tab-content pl-3 pr-3" id="myTabContent" style={{ width: "100%" }} >
                                            {this.props.module.steps.map((answer, index) => (
                                                <div className={`tab-pane fade show ${index == 0 ? "active" : ""}`} id={`home${answer.id}`} role="tabpanel" aria-labelledby="home-tab">
                                                    <div className="row">
                                                        <React.Fragment>
                                                            <React.Fragment>
                                                                {answer.answers.filter(value => value.show_in_gestion).map((data, index) => (

                                                                    <React.Fragment>
                                                                        {data.type == "answer_file" && (
                                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                <p className="m-0">
                                                                                    <strong>{data.name}</strong>
                                                                                </p>
                                                                                <p className="m-0 p-2">
                                                                                    <a href={data.value}>Ver archivo</a>
                                                                                </p>

                                                                            </div>
                                                                        )
                                                                        }


                                                                        {(data.type != "answer_integer" && data.type != "url" && data.type != "answer_formula" && data.type != "answer_file" && data.type != "answer_datetime" && data.type != "answer_date" && data.type != "answer_text" && data.type != "answer_checkbox" && data.type != "answer_localization") && (
                                                                            <div className="col-md-3" style={{ padding: "15px" }} >
                                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                <p className="m-0 p-2" style={{ background: data.style.background, color: data.style.color }}>  {typeof data.value !== 'object' ? data.value : ""}</p>
                                                                            </div>
                                                                        )
                                                                        }
                                                                        {((data.type == "answer_integer" || data.type == "answer_formula") && data.is_currency) && (
                                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                <p className="m-0 p-2" style={{ background: data.style.background, color: data.style.color }}> <NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.value} /></p>
                                                                            </div>
                                                                        )
                                                                        }
                                                                        {((data.type == "answer_integer" || data.type == "answer_formula") && data.is_currency != true) && (
                                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                <p className="m-0 p-2" style={{ background: data.style.background, color: data.style.color }}>{typeof data.value !== 'object' ? data.value : ""}</p>
                                                                            </div>
                                                                        )
                                                                        }
                                                                        {data.type == "answer_date" && (
                                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                <p className="m-0 p-2" style={{ background: data.style.background, color: data.style.color }}> {data.value != null & data.value != "" ? data.value : ""}</p>
                                                                            </div>
                                                                        )
                                                                        }
                                                                        {data.type == "answer_datetime" && (
                                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                <p className="m-0 p-2" style={{ color: "#909090" }}> {data.value != null & data.value != "" ? this.dateTime(data.value) : ""}</p>
                                                                            </div>
                                                                        )
                                                                        }
                                                                        {data.type == "answer_text" && (
                                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                {/*<p className="m-0 gm-hover" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", display: this.state.showParrafo == data.id ? "block" : "none" }} > {ReactHtmlParser(data.value)}</p>*/}
                                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                <p className="m-0 pl-2 pt-2 ui-surveyShowTask_type_paragraph" style={{ background: data.style.background, color: data.style.color, maxWidth: "300px", cursor: "pointer", maxHeight: "67px", overflow: "hidden", wordBreak: "break-all" }} onClick={() => this.toogle("new", data)}>
                                                                                    {ReactHtmlParser(data.value).length >= 1 && (ReactHtmlParser(data.value)[0])}

                                                                                </p>
                                                                                {/*<p className="m-0 gm-ellipsis" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", maxWidth: "300px" }} > {ReactHtmlParser(data.value)}</p>*/}
                                                                            </div>
                                                                        )
                                                                        }
                                                                        {data.type == "url" && (
                                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                <p className="m-0 p-2" style={{ color: "#909090" }}>   <a href={data.value} target="_blank">{data.value}</a> </p>
                                                                            </div>
                                                                        )
                                                                        }
                                                                        {(data.type == "answer_checkbox") && (
                                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                <p className="m-0 p-2" style={{ background: data.style.background, color: data.style.color }}>
                                                                                    {
                                                                                        data.value.map((value, index) => (

                                                                                            <span>{index == 0 ? "" : ", "} {value}</span>

                                                                                        ))
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                        }
                                                                        {(data.type == "answer_localization") && (
                                                                            <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                <p className="m-0 pb-2" ><strong>{data.name}</strong></p>
                                                                                <button onClick={() => this.toogle("new", data)} className="btn btn-primary">Ver Mapa</button>


                                                                            </div>
                                                                        )
                                                                        }


                                                                        {(((index + 1) % 4) == 0 || this.props.module.answers.filter(value => value.show_in_gestion).length == (index + 1)) && (
                                                                            <div className="col-md-12"><hr className="mt-0 mb-0" /></div>
                                                                        )
                                                                        }
                                                                    </React.Fragment>

                                                                ))}
                                                            </React.Fragment>

                                                            <React.Fragment>
                                                                {(answer.answers.filter(value => !value.show_in_gestion).length > 0) && (
                                                                    <div className="col-md-12 mt-3" > <a className="bw-link-blue" data-toggle="collapse" href={`#collapseExampleNoshow`} role="button" aria-expanded="false" aria-controls={`collapseExampleNoshow`}>
                                                                        Ver mas</a>
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                            <React.Fragment>
                                                                <div className="collapse col-md-12 " style={{ padding: "15px" }} id={`collapseExampleNoshow`}>
                                                                    <div className="row mt-3">

                                                                        <React.Fragment>
                                                                            {answer.answers.filter(value => !value.show_in_gestion).map((data, index) => (

                                                                                <React.Fragment>
                                                                                    {data.type == "answer_file" && (
                                                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                            <p className="m-0">
                                                                                                <strong>{data.name}</strong>
                                                                                            </p>
                                                                                            <p className="m-0">
                                                                                                <a href={data.value}>Ver archivo</a>
                                                                                            </p>

                                                                                        </div>
                                                                                    )
                                                                                    }
                                                                                    {(data.type != "answer_file" && data.type != "url" && data.type != "answer_datetime" && data.type != "answer_text" && data.type != "answer_checkbox") && (
                                                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                            <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                            <p className="m-0" style={{ color: "#909090" }}> {data.value}</p>
                                                                                        </div>
                                                                                    )
                                                                                    }
                                                                                    {data.type == "answer_datetime" && (
                                                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                            <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                            <p className="m-0" style={{ color: "#909090" }}> {this.dateTime(data.value)}</p>
                                                                                        </div>
                                                                                    )
                                                                                    }
                                                                                    {data.type == "answer_text" && (
                                                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                            <p className="m-0 gm-hover" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", display: this.state.showParrafo == data.id ? "block" : "none" }} > {ReactHtmlParser(data.value)}</p>
                                                                                            <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                            <p className="m-0 gm-ellipsis" onMouseLeave={() => this.hideParrafoF()} onMouseEnter={() => this.showParrafoF(data.id)} style={{ color: "#909090", maxWidth: "300px" }} > {ReactHtmlParser(data.value)}</p>
                                                                                        </div>
                                                                                    )
                                                                                    }
                                                                                    {data.type == "url" && (
                                                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                            <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                            <p className="m-0" style={{ color: "#909090" }}>   <a href={data.value} target="_blank">{data.value}</a> </p>
                                                                                        </div>
                                                                                    )
                                                                                    }
                                                                                    {(data.type == "answer_checkbox") && (
                                                                                        <div className="col-md-3" style={{ padding: "15px" }}>
                                                                                            <p className="m-0" ><strong>{data.name}</strong></p>
                                                                                            <p className="m-0" style={{ color: "#909090" }}>
                                                                                                {
                                                                                                    data.value.map((value, index) => (

                                                                                                        <span>{index == 0 ? "" : ", "} {value}</span>

                                                                                                    ))
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    )
                                                                                    }


                                                                                    {(((index + 1) % 4) == 0) && (
                                                                                        <div className="col-md-12"><hr className="mt-0 mb-0" /></div>
                                                                                    )
                                                                                    }
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </React.Fragment>

                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                </div>
                            </React.Fragment>
                        )}

                        <div>
                            {this.props.module.show_custom && (
                                <div >
                                    {ReactHtmlParser(this.props.module.custom_view)}
                                </div>
                            )}
                        </div>
                        <div className="row">
                            {this.props.format.format_associations.length >= 1 && (
                                <React.Fragment>
                                    <div className="col-md-12 p-2 text-center mt-3 ui-surveyShowTask-btns__container">

                                        <div className='ui-surveyShowTask-btns-form-associate'>
                                            <div className='ui-surveyShowTask-btns-tasks-commets'>
                                                {(this.props.format.has_taks && this.props.format.has_comments) && (
                                                    <button
                                                        className={`${this.state.showTasks ? "btn btn-info" : "btn btn-outline-info"} mr-3 ui-surveyShowtask-button-taskComments`}
                                                        onClick={() => this.cancelSelect()}
                                                        style={{ minWidth: "150px" }}
                                                    >
                                                        <i className="fas fa-calendar-check mr-1" ></i>
                                                        Tareas y comentarios
                                                    </button>
                                                )}
                                            </div>
                                            {this.props.format.format_associations.map(format_association => (
                                                <React.Fragment>

                                                    <UncontrolledTooltip target={`nameAssociative${format_association.format_select.id}`} placement='right'>
                                                        {format_association.format_select.name}
                                                    </UncontrolledTooltip>
                                                    <button
                                                        className={`${this.state.format_association.id == format_association.id ? "btn btn-primary" : "btn btn-outline-primary"} mr-3 ui-surveyShowTask-btn-associative-forms`}
                                                        key={format_association.id}
                                                        onClick={() => this.getFormatInfo(format_association.format_select.id)}
                                                    >
                                                        <span id={`nameAssociative${format_association.format_select.id}`}>
                                                            {format_association.format_select.name}
                                                        </span>
                                                    </button>
                                                </React.Fragment>
                                            ))}
                                        </div>



                                        {/*(this.state.showSurveys && this.props.format.has_taks && this.props.format.has_comments) ? (
                                            <button
                                                className={"btn btn-outline-primary mr-3"}
                                                onClick={() => this.cancelSelect()}
                                                style={{ minWidth: "150px" }}
                                            >
                                                <i className="fas fa-calendar-check mr-1" ></i>
                                                Tareas y comentarios
                                            </button>
                                        ) : (
                                            <button
                                                className={"btn btn-outline-primary mr-3"}
                                                onClick={() => this.cancelSelect()}
                                                style={{ minWidth: "150px" }}
                                            >
                                                <i className="fas fa-calendar-check mr-1" ></i>
                                                Volver
                                            </button>
                                        )*/}
                                    </div>
                                </React.Fragment>
                            )}
                            {/*<a
                                    className="btn-shadow btn btn-info mr-3"
                                    href={`/formatos/survey_pdf/${this.props.survey.id}.pdf`}
                                >
                                    Pdf <i className="fas fa-file-pdf"></i>
                                </a>*/}


                        </div>
                    </div>
                </div>
                <div className="survey-show-surveys__container">
                    {this.state.showSurveys ? (
                        <React.Fragment>
                            {/*                         <SurveyIndex
                            data={this.state.dataSurvey}
                            format={this.state.format_association.format_select}
                            format_category={this.props.format_category}
                            formato={this.props.format}
                            survey={this.props.survey}
                            dataQuestionnvm={this.state.dataQuestionnvm}
                            tableWidth={this.state.tableWidth}
                            dataQdataAnswersnvm={this.state.dataQdataAnswersnvm}
                            loadData={this.getFormatInfo}
                            format_association={this.state.format_association_id}
                            showSurveys={this.state.showSurveys}
                            cancelSelect={this.cancelSelect}
                            domain={this.props.domain}
                            survey_total={this.state.survey_total}
                        /> */}

                            <SurveyCustomView
                                formato={this.state.formato}
                                domain={this.props.domain}
                                users={this.props.users}
                                positions={this.props.format}
                                procesos={this.props.position}
                                format_category={this.props.format_category}
                                estados={this.props.estados_list}
                                format_exels={this.props.format_exels}
                                view={this.props.view}
                                format_indicators={this.props.format_indicators}
                                state_records={this.props.state_records}
                                custom_view={this.props.custom_view}
                                survey_parent_id={this.props.survey.id}
                                survey_parent_token={this.props.survey.token}
                                tenant={this.props.tenant}
                                format_pdfs={this.state.format_pdfs}
                                count_registers={this.props.count_registers}
                                current_plan={this.props.current_plan}
                                plans={this.props.plans}
                                configuration_format={this.state.formato.configuration_format}
                                imageUpload={this.props.imageUpload}
                                key_text={this.props.key_text}
                            />

                        </React.Fragment>
                    ) : (
                        <div></div>
                    )}
                </div>

                <div className="survey-show-taks__container">

                    <ul className="nav nav-tabs mt-3" id="myTab" role="tablist">

                        {(this.state.showTasks && Object.keys(this.state.task_board).length != 0) && (
                            <li className="nav-item">
                                <a className={`nav-link`} id="home-tab" data-toggle="tab" href="#homeShowSurvey" role="tab" aria-controls="home" aria-selected="true">Tareas</a>
                            </li>
                        )}

                        {this.props.formato.has_comments && (
                            <li className="nav-item">
                                <a className={`nav-link`} id="profile-tab" data-toggle="tab" href="#profileShowSurvey" role="tab" aria-controls="profile" aria-selected="false">Comentarios <i className="pl-2 pr-2 far fa-comments"></i>{this.state.countComment > 0 ? this.state.countComment : <i className='fas fa-ban'></i>}</a>
                            </li>
                        )}

                        {(this.props.formato.has_flow_chart && Object.keys(this.state.flow_chart).length != 0) && (
                            <li className="nav-item">
                                <a className={`nav-link`} id="profile-tab" data-toggle="tab" href="#profileFlowChart" role="tab" aria-controls="profile" aria-selected="false">Árbol de fallos</a>
                            </li>
                        )}
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        {this.state.showTasks && Object.keys(this.state.task_board).length != 0 && (
                            <div className={`tab-pane fade show active`} id="homeShowSurvey" role="tabpanel" aria-labelledby="home-tab">
                                <IndexTaskModule
                                    task_board={this.state.task_board}

                                    //task props
                                    user={this.props.user}
                                    users={this.props.users}
                                    estados={this.props.estados}
                                    procesos={this.props.procesos}

                                    columns={this.props.columns}
                                    labels={this.props.labels}
                                    tasks={this.props.tasks}
                                    task_sources={this.props.task_sources}
                                    company={this.props.company}
                                    imageUpload={this.props.imageUpload}
                                    key_text={this.props.key_text}
                                />
                            </div>
                        )}

                        {this.props.formato.has_comments && (
                            <div className={`tab-pane fade`} id="profileShowSurvey" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="mt-3 ui-surveyShowTask-comments__containers">
                                    <GeneralComments
                                        usuario={this.props.user}
                                        users={this.props.users}
                                        module_name={this.props.name_module_task}
                                        module_comment={this.props.module.module_comment_id}
                                        ref={this.childSection}
                                        url={this.props.url}

                                        updateStateCountComment={this.updateStateCountComment}
                                        countComment={this.state.countComment}
                                        pdfs={this.state.pdfs}
                                        showConfig={true}
                                        imageUpload={this.props.imageUpload}
                                        key_text={this.props.key_text}
                                    />
                                </div>
                            </div>
                        )}


                        {(this.props.formato.has_flow_chart && Object.keys(this.state.flow_chart).length != 0) && (
                            <div className={`tab-pane fade`} id="profileFlowChart" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="mt-3 ui-surveyShowTask-flowCharts__containers">
                                    <FlowChartIndex
                                        survey={this.props.survey}
                                        flow_chart={this.state.flow_chart}
                                        deleteFlowChart={this.deleteFlowChart}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                </div>


            </React.Fragment >

        );
    }
}


export default SurveyShowTask;
WebpackerReact.setup({ SurveyShowTask });

{/*
{(this.props.format.has_taks || this.props.format.has_comments) && (
    <TaskAndComments
        modulo={this.props.module}
        user={this.props.user}
        users={this.props.users}
        dataTask={this.state.dataTask}
        name_module_task={this.props.name_module_task}
        nameComment={this.props.name_module_task}
        updateData={this.updateData}
        updateItem={this.updateItem}
        updateStateLoad={this.updateStateLoad}
        procesos={this.props.procesos}
        format={this.props.format}
        estados={this.props.estados}

        specific_areas={this.props.specific_areas}
        tenant={this.props.tenant}
        procesos={this.props.procesos}
        survey={this.props.survey}
        task_source={this.props.task_source}
        format_category={this.props.format_category}
        loadDataTask={this.loadDataTask}
        url={this.props.url}
        countComment={this.state.countComment}
        updateStateCountComment={this.updateStateCountComment}
        domain={this.props.domain}
        pdfs={this.state.pdfs}
    />
)}
*/}