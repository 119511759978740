import React, { Component } from 'react';
import HeaderTitle from "../../../GeneralComponents/HeaderTitle"
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from '../../../GeneralComponents/LoadingComponen'
import { isMobile } from 'react-device-detect';
import Filter from './Filter'
import Pagination from "react-js-pagination";
import UploadFile from './UploadFile'
import NumberFormat from 'react-number-format';
import ReactHtmlParser from 'react-html-parser';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { array } from 'prop-types';
import Ckaditor from '../Formats/Ckeditor';
import ReactDOM from 'react-dom'
import ModalQr from './ModalQr'
import LineChart from '../../../GeneralComponents/GeneralCharts/LineChart'
import PieChart from '../../../GeneralComponents/GeneralCharts/PieChart'
import BarChart from '../../../GeneralComponents/GeneralCharts/BarChart'
import ChatBoxCommentModule from '../../../GeneralComponents/ChatBoxModuleComment';

class IndexDashboard extends Component {
    constructor(props) {
        super(props);

        this.token = document.querySelector("[name='csrf-token']").content;
        this.url = this.props.survey_parent_id == 0 ? `${this.props.domain}/formatos/generate_questions/${this.props.format.id}` : `${this.props.domain}/formatos/generate_questions_surveys/${this.props.format.id}/${this.props.survey_parent_id}/parent`
        this.state = {
            formUpdateRepy: {

            },
            formUpdateRepySelect: {

            },

            formatValues: {

            },

            formUpdateWidth: {
                column_width: "",
            },

            showFilter: false,
            question_id: "",
            modal: false,
            showPopover: false,
            sort: false,
            showIcons: false,
            selectItems: [],
            checked: false,
            modalQr: false,
            options: [],

            answer_id: "",
            module_comment_id: "",

            formUpdateAnswer: {
                answer_type: "",
                value: "",
            },
            innerWidht: 0
        }
    }

    HandleChangeRepy = (e) => {
        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: e.target.value + "&" + (this.state.formUpdateRepySelect[e.target.dataset.id] != undefined ? this.state.formUpdateRepySelect[e.target.dataset.id] : "contiene")
            }
        });
    }


    HandleChangeUpdateAnswer = (e) => {
        this.setState({
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                [e.target.name]: e.target.value,
            }
        });
    }

    selectValue = (answer) => {
        this.loadOptions(answer.survey_id, answer.question_id)
        this.setState({
            answer_id: answer.survey_id,
            question_id: answer.question_id,

            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                answer_type: answer.type,
                value: answer.value,
            }
        })
    }

    loadOptions = (survey_id, question_id) => {
        fetch(`/formatos/get_options_surveys/${survey_id}/${question_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    options: data.register,
                })
            });
    }

    cancelValues = () => {
        this.setState({
            answer_id: "",
            question_id: "",
            options: [],

            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                answer_type: "",
                value: "",
            }
        })
    }


    HandleChangeCkaditor = (e) => {
        this.setState({
            formUpdateAnswer: {
                ...this.state.formUpdateAnswer,
                value: e
            }
        })
    }

    updateValue = () => {
        fetch(`/formatos/update_survey_values/${this.state.answer_id}/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateAnswer), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                if (this.state.showFilter) {

                    this.props.handlePageChange_filter(this.props.activePage_filter);
                    this.setState({
                        show_count: this.state.show_count - 1
                    })
                }
                else {
                    this.props.handlePageChange(this.props.activePage);
                }
                this.cancelValues();
            });
    }


    HandleChangeRepySelect = (e) => {
        var value = this.state.formUpdateRepy[e.target.dataset.id].split("&")[0];
        this.setState({
            formUpdateRepySelect: {
                ...this.state.formUpdateRepySelect,
                [e.target.dataset.id]: e.target.value
            },

            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: value + "&" + e.target.value
            }
        });
    }

    HandleChangeWidth = (e) => {
        this.setState({
            formUpdateWidth: {
                ...this.state.formUpdateWidth,
                [e.target.name]: e.target.value
            }
        });
    }

    toogleModuleComment = (module_comment_id) => {
        this.setState({
            module_comment_id: module_comment_id
        })
    }

    setValues = (question) => {
        this.setState({
            question_id: question.id,
            formUpdateWidth: {
                ...this.state.formUpdateWidth,
                column_width: question.column_width,
            }
        })
    }

    HandleChangeRepyDate1 = (e) => {
        var value = "";
        if (this.state.formUpdateRepy[e.target.dataset.id].includes("&")) {
            var value = `&${this.state.formUpdateRepy[e.target.dataset.id].split("&")[1]}`;
        }

        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: `${e.target.value},${this.state.formUpdateRepy[e.target.dataset.id] != undefined ? this.state.formUpdateRepy[e.target.dataset.id].split(",")[1] : null}${value}`
            }
        });
    }


    HandleChangeRepyDate2 = (e) => {
        var value = "";
        if (this.state.formUpdateRepy[e.target.dataset.id].includes("&")) {
            var value = `&${this.state.formUpdateRepy[e.target.dataset.id].split("&")[1]}`;
        }


        this.setState({
            formUpdateRepy: {
                ...this.state.formUpdateRepy,
                [e.target.dataset.id]: `${this.state.formUpdateRepy[e.target.dataset.id] != undefined ? this.state.formUpdateRepy[e.target.dataset.id].split(",")[0] : null},${e.target.value}${value}`
            }
        });
    }




    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };


    componentWillReceiveProps = (nexProps) => {
        this.loadValues(nexProps)
        let filterPareameters = nexProps.filters
        //COMO EL COMPONENTE SE RENDEREA TRAE NUEVAMENTE LOS FILTROS
        this.setState({
            formUpdateRepy: filterPareameters,
            show_count: nexProps.isFilter ? nexProps.survey_total_filter : nexProps.survey_total
        })
    }


    componentDidMount = () => {
        let box = window.innerWidth - 360;
        this.setState({
            innerWidht: box
        })
        window.fbAsyncInit = function () {
            FB.init({
                appId: '338057327568708',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v8.0'
            });
        };
    }

    onClickFacebook = () => {
        FB.ui({
            method: 'feed',
            link: this.url
        }, function (response) { });
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        if (this.state.showFilter) {

                            this.props.handlePageChange_filter(this.props.activePage_filter);
                            this.setState({
                                show_count: this.state.show_count - 1
                            })
                        }
                        else {
                            this.props.handlePageChange(this.props.activePage);
                        }
                        this.messageSuccess(response)
                    });
            }
        });
    };


    getWidth = (value) => {
        if (value > 4 || isMobile) {

            return (((value * 300) + 300).toString()) + "px";

        } else {
            return "100%";
        }

    }


    generateQuestions
    generateQuestions = () => {
        Turbolinks.visit(`${this.url}`, '_self');
    }

    dateDate = (fecha) => {
        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear();

    }
    dateTime = (fecha) => {

        var c = new Date();
        var e = new Date(fecha);
        var d = new Date(c.setDate(e.getDate() + 1));

        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + (d.getDate()) + " " + 'del' + " " + d.getFullYear() + " / " + d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes());


    }
    showFilter = () => {
        this.setState({
            showFilter: true
        })

    }
    cancelFilter = () => {
        this.props.cleanFilters();
        this.props.loadData();
        this.setState({
            showFilter: false
        })
    }

    sortby = (question, order) => {
        var order_value = order ? "ASC" : "DESC"
        fetch(`/formatos/sort_list/${this.props.format.id}/${question}/${order_value}`, {
            method: "PATCH",
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                this.props.loadData();
                this.setState({
                    sort: !this.state.sort
                })

            });
    }

    queryExcel = (data) => {
        let query = Object.keys(data)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
            .join('&');
        return query
    }


    updateWidth = () => {
        fetch(`/formatos/update_width/${this.state.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateWidth), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    question_id: "",
                    formUpdateWidth: {
                        column_width: "",
                    }
                })

                this.props.loadData()
            });
    }


    getFormatsValues = (datas) => {
        fetch(`/formatos/get_formats_values/${datas}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    formatValues: {
                        ...this.state.formatValues,
                        [datas]: data
                    }
                })
            });
    }

    loadValues = (nexProps) => {
        nexProps.dataQuestion.filter(value => value.question_type == "format_id").map((question, index) => {
            this.getFormatsValues(question.id)
        })
    }

    copyLink = () => {
        Swal.fire({
            position: "center",
            type: `success`,
            title: `Link copiado`,
            showConfirmButton: false,
            timer: 1500,
        });

        navigator.clipboard.writeText(this.url)
        this.setState({
            modalQr: false,
        })
    }

    addAllValues = (e) => {
        if (e.target.checked) {
            const array = []



            this.setState({
                selectItems: array,
                checked: e.target.checked,
            })
        } else {
            this.setState({
                selectItems: [],
                checked: e.target.checked,
            })
        }
    }


    addItem = (survey, state) => {
        const selectItems = this.state.selectItems

        if (!state) {
            selectItems.push(survey.survey)

            this.setState({
                selectItems: selectItems,
            })
        } else {
            var arr = selectItems
            var index = selectItems.indexOf(parseInt(survey.survey))
            delete arr[index]

            this.setState({
                selectItems: arr.filter(item => item != null),
            })
        }
    }

    deleteAll = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡Los registros serán eliminados para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/delete_surveys/${this.state.selectItems}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ selectItems: [], checked: false });
                        if (this.state.showFilter) {

                            this.props.handlePageChange_filter(this.props.activePage_filter);
                            this.setState({
                                show_count: this.state.show_count - 1
                            })
                        }
                        else {
                            this.props.handlePageChange(this.props.activePage);
                        }
                        this.messageSuccess(response)
                    });
            }
        });
    };

    updateView = () => {
        fetch(`/formatos/update_suveys_view/${this.state.selectItems}/${this.props.view}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData()
                this.setState({ selectItems: [], checked: false })
            });
    }




    toogleModalQr = (from) => {
        if (from == "new") {
            this.setState({ modalQr: true })
        } else {
            this.setState({ modalQr: false })
        }
    }

    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={`${this.props.format.name}`}
                    titleNew={"Crear"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    showNew={/* this.props.dataQuestion.length >= 1 && this.props.state_records != "inactive" */ false}
                    onClickNew={this.generateQuestions}
                    showFilter={this.props.dataQuestion.length >= 1 ? true : false}
                    onClickFilter={this.showFilter}
                />

                {this.state.modal && (
                    <ChatBoxCommentModuleModal
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Subir archivo"}

                        usuario={this.props.user}
                        module_comment={this.state.module_comment_id}
                    />
                )}

                {this.state.modalQr && (
                    <ModalQr
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalQr}
                        toggle={this.toogleModalQr}
                        title={"Código QR"}
                        url={this.url}
                        copyLink={this.copyLink}
                    />
                )}



                {!this.props.isLoaded ? (
                    this.props.dataQuestion.length >= 1 ? (
                        <React.Fragment>
                            <div className="content main-card mb-3 card mh-400" >
                                <div className="card-body" style={{ justifyContent: "center", width: "100%" }}>
                                    <div className="row mb-2">


                                        <div className="col-md-12 ui-indexDashboard-survey-container_buttons_stadistics_register">
                                            <a
                                                className="btn btn-outline-info mobile ui-indexDashboard-survey-button-back-register"
                                                data-turbolinks="true"
                                                href={`/formatos/format_categories/${this.props.format_category.token}/formats/${this.props.format.token}/surveys?view=ACTIVE`}
                                            >
                                                Ir a Registros
                                            </a>
                                            <a
                                                className="btn btn-primary mobile ui-indexDashboard-survey-button-editor-stadistics"
                                                style={{ color: "#ffff", marginLeft: "10px" }}
                                                data-turbolinks="true"
                                                href={`/formatos/format_custom_views/${this.props.custom_view_id}/format_category/${this.props.format_category.token}`}
                                            >
                                                Ir al editor de estadísticas
                                            </a>

                                            {false && (
                                                <a
                                                    className="btn btn-info ml-2"
                                                    target="_blank"
                                                    href={`https://twitter.com/intent/tweet?original_referer=${this.url}&ref_src=twsrc%5Etfw&text=${this.props.format.name}&tw_p=tweetbutton&url=${this.url}`}
                                                >
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            )}

                                            {false && (
                                                <a
                                                    className="btn btn-primary ml-2"
                                                    style={{ color: "#ffff" }}
                                                    onClick={() => this.onClickFacebook()}
                                                >
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            )}

                                            {/*                                             <div className="btn-group ml-2" role="group" aria-label="Button group with nested dropdown">
                                                <div className="btn-group mobile" role="group">
                                                    <button
                                                        className="btn-shadow btn btn-info mobile"
                                                        id="btnGroupDrop1"
                                                        type="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span className="label">Vistas<i className="ml-2 fas fa-bars"></i></span><i className="ml-2 far fa-eye"></i>
                                                    </button>

                                                    <div className="dropdown-menu dropdown-menu-right dp-pdf">
                                                        {true && (
                                                            <a
                                                                className="dropdown-item"
                                                                data-turbolinks="true"
                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys?view=ALL`}
                                                            >
                                                                Ver todos
                                                            </a>
                                                        )}

                                                        {true && (
                                                            <a
                                                                className="dropdown-item"
                                                                data-turbolinks="true"
                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys`}
                                                            >
                                                                Activos
                                                            </a>
                                                        )}

                                                        {true && (
                                                            <a
                                                                className="dropdown-item"
                                                                data-turbolinks="true"
                                                                href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/surveys}
                                                            >
                                                                Inactivos
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div> */}

                                            {this.props.format_indicators.length >= 1 && (
                                                <a
                                                    className="btn-shadow btn btn-info ml-2"
                                                    data-turbolinks="true"
                                                    href={`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/format_indicators`}
                                                >
                                                    Indicadores <i className="ml-2 fas fa-bars"></i>
                                                </a>
                                            )}



                                        </div>
                                    </div>
                                    {!this.props.isLoaded ? (
                                        <div className="row" id="for-data" >
                                            <div className="col-md-12 mb-4">
                                                {this.state.showFilter && (
                                                    <div className="table-survey-index__tr">

                                                        <div className="row">
                                                            {this.props.dataQuestion.length >= 1 ? (
                                                                this.props.dataQuestion.filter(value => value.show_filter).map(question => (
                                                                    (question.question_type != "answer_file" && question.question_type != "answer_autdatetime") ? (
                                                                        <div className="col-md-2 pr-0">
                                                                            <div className="filter-inputs">
                                                                                <Filter question={question}
                                                                                    isDashboard={true}
                                                                                    users={this.props.users}
                                                                                    procesos={this.props.procesos}
                                                                                    positions={this.props.positions}
                                                                                    HandleChangeRepy={this.HandleChangeRepy}
                                                                                    HandleChangeRepySelect={this.HandleChangeRepySelect}
                                                                                    formUpdateRepy={this.state.formUpdateRepy}
                                                                                    formUpdateRepySelect={this.state.formUpdateRepySelect}
                                                                                    HandleChangeRepyDate1={this.HandleChangeRepyDate1}
                                                                                    HandleChangeRepyDate2={this.HandleChangeRepyDate2}
                                                                                    formValues={this.state.formatValues}
                                                                                /></div>
                                                                        </div>
                                                                    ) :
                                                                        (
                                                                            <td></td>
                                                                        )

                                                                ))
                                                            ) : (<div></div>
                                                            )}


                                                        </div>
                                                        <div colSpan="2" className="row">
                                                            <div className="col-md-2 dashboard-filter-btn">

                                                                <button className="btn btn-primary w-100p mt-1 action-filter-index" onClick={() => this.props.loadDataFilter(this.state.formUpdateRepy)}>Filtrar</button>
                                                                < button className="btn btn-outline-info w-100p mt-1 cancel-filter-index" onClick={() => this.cancelFilter()}>Cancelar</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {this.props.charts.map(chart =>

                                                <div className="col-md-6 mb-5 ui-index-dashboard__container_charts">
                                                    <p className="text-fw-600 text-fs-20 bw-chart-container_p" >
                                                        {chart.name}

                                                        <button
                                                            className={`btn-shadow btn btn-${this.state.module_comment_id == chart.module_comment_id ? "danger" : "info"} float-right`}
                                                            onClick={() => this.state.module_comment_id == chart.module_comment_id ? this.setState({ module_comment_id: "" }) : this.setState({ module_comment_id: chart.module_comment_id })}
                                                        >
                                                            {this.state.module_comment_id == chart.module_comment_id ? "Cerrar Análisis" : "Análisis"}
                                                        </button>
                                                    </p>

                                                    <div className="bw-chart-container">
                                                        {(chart.type == "pie" &&
                                                            <PieChart data={chart.data} options={chart.options} />
                                                        )}
                                                        {(chart.type == "line" &&
                                                            <LineChart data={chart.data.data_chart} options={chart.options} labels={chart.data.labels_chart} />)}

                                                        {(chart.type == "bar" &&
                                                            <BarChart data={chart.data.data_chart} options={chart.options} labels={chart.data.labels_chart} />)}
                                                    </div>


                                                    {this.state.module_comment_id == chart.module_comment_id && (
                                                        <ChatBoxCommentModule
                                                            usuario={this.props.user}
                                                            users={this.props.users}
                                                            module_comment={chart.module_comment_id}
                                                            imageUpload={this.props.imageUpload}
                                                            key_text={this.props.key_text}
                                                        />
                                                    )}

                                                </div>


                                            )}


                                        </div>
                                    ) : (
                                        <div className="col-md-12 text-center">
                                            <Preloader />
                                        </div>
                                    )
                                    }

                                </div>
                            </div>
                        </React.Fragment >
                    ) : (

                        <div className="no-fordata"><i className="far fa-list-alt"></i> <br />
                            ¡Aún no has completado tu Formato! <br /> crea al menos una entrada</div>

                    )
                ) : (
                    <div className="col-md-12 text-center">
                        <Preloader />
                    </div>
                )}
            </React.Fragment >
        );
    }
}

export default IndexDashboard;