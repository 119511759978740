import React, { useMemo, useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import GoogleFontLoader from 'react-google-font-loader';

const BreadcrumbTasks = (props) => {
    const [isWeb, setIsWeb] = useState(window.innerWidth > 767);

    useEffect(() => {
        function handleResize() {
            setIsWeb(window.innerWidth > 767);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])


    const fontConfig = useMemo(() => ({
        fonts: [
            {
                font: 'Material Symbols Outlined',
                weights: [400],
            },
        ],
        async: true,
        defer: true,
    }), [fontConfig]);

    return (
        <React.Fragment>
            {useMemo(() => <GoogleFontLoader {...fontConfig} />, [fontConfig])}
            <nav aria-label="breadcrumb ui-index-taskTrello-breadcrumb__container">
                <div className="breadcrumb ui-index-taskTrello-breadcrumb__container_lists">
                    <div className='ui-index-taskTrello-breadcrumb__items'>
                        {isWeb && (
                            <span className="breadcrumb-item ui-index-taskTrello-item-breadcrumb">
                                <a href="/" data-turbolinks="true">
                                    <i className="material-symbols-outlined ui-breadcrumb-task-icon-home">
                                        &#xe88a;
                                    </i>
                                </a>
                            </span>
                        )}
                        <span className="breadcrumb-item ui-index-taskTrello-item-breadcrumb"><a href="/task/task_boards" data-turbolinks="true">Tableros</a></span>
                        <span className="breadcrumb-item ui-index-taskTrello-item-breadcrumb active" aria-current="page">tareas</span>
                    </div>
                    <div className="ui-breadcrumb-task__container-change">
                        {(isWeb && props.listView) && (
                            <div className="ui-breadcrumb-task__container_change-view">
                                {true && (
                                    <a onClick={() => props.updateView("columns")}
                                        style={{ color: props.listView == "columns" ? "#3f6ad8" : "#ACE379", cursor: "pointer", border: props.listView == "columns" ? "1px solid #3f6ad8" : "" }} className="stretched-link ui-header-title-icons_material"
                                        id="ui-headerTitle-columns">
                                        <i className="material-symbols-outlined">
                                            &#xe8f3;
                                        </i>
                                        <UncontrolledTooltip target="ui-headerTitle-columns" placement='bottom'>{"VER TAREAS POR COLUMNAS"}</UncontrolledTooltip>
                                    </a>
                                )}
                                {true && (
                                    <a onClick={() => props.updateView("list")}
                                        style={{ color: props.listView == "list" ? "#3f6ad8" : "#63BBFF", cursor: "pointer", border: props.listView == "list" ? "1px solid #3f6ad8" : "" }} className="ml-2 mr-2 ui-header-title-icons_material"
                                        id="ui-headerTitle-list">
                                        <i className="material-symbols-outlined">
                                            &#xf06d;
                                        </i>
                                        <UncontrolledTooltip target="ui-headerTitle-list" placement='bottom'>{"VER TAREAS EN LISTA"}</UncontrolledTooltip>
                                    </a>
                                )}
                                {true && (
                                    <a onClick={() => props.updateView("calendar")}
                                        style={{ color: props.listView == "calendar" ? "#3f6ad8" : "#FFAD95", cursor: "pointer", border: props.listView == "calendar" ? "1px solid #3f6ad8" : "" }} className="mr-2 ui-header-title-icons_material"
                                        id="ui-headerTitle-calendar">
                                        <i className="material-symbols-outlined">
                                            &#xebcc;
                                        </i>
                                        <UncontrolledTooltip target="ui-headerTitle-calendar" placement='bottom'>{"VER TAREAS EN CALENDARIO"}</UncontrolledTooltip>
                                    </a>
                                )}

                                {true && (
                                    <a className="mr-2 ui-header-title-icons_material"
                                        onClick={() => props.updateView("history")} data-turbolinks="true"
                                        style={{ color: props.listView == "history" ? "#3f6ad8" : "#E3D179", cursor: "pointer", border: props.listView == "history" ? "1px solid #3f6ad8" : "" }}
                                        id="ui-headerTitle-history">
                                        <i className="material-symbols-outlined">
                                            &#xe1a1;
                                        </i>
                                        <UncontrolledTooltip target="ui-headerTitle-history" placement='bottom'>{"VER TAREAS ARCHIVADAS"}</UncontrolledTooltip>
                                    </a>
                                )}

                            </div>
                        )}
                        <div className="ui-breadcrum-task__container-filter">
                            <UncontrolledTooltip target="ui-taskTrello-index-tooltip">{"BÚSQUEDA POR FILTRO"}</UncontrolledTooltip>
                            <a
                                className="ui-index-taskTrello-breadcrumb-filter__container"
                                onClick={() => props.handleClickShowFilter()}
                                id="ui-taskTrello-index-tooltip"
                            >
                                <i className="material-symbols-outlined pr-1"> &#xef4f;</i> Filtrar
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
};

export default BreadcrumbTasks;