import React, { Component } from 'react';
import Select from "react-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from '../../tasks/taskBoard/FormCreate';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Preloader from '../../../GeneralComponents/LoadingComponen';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modeEdit: false,
            modalActions: false,
            task_board: {},
            has_task_board: false,
            isLoaded: true,
            id: "",
            form: {
                name: "",
                is_template: true,
                position_ids: [],
                task_label_ids: [],
                user_ids: [],
            },

            task_board: {},
            editValuesPosition: [],
            editValuesUsers: [],
            selectLabels: [],
            cargos: [],
            users: [],
            questions: [],
            default_users: this.props.format.users,

            task_boards: [],
            modal: false,

            selectedOption: {
                label: this.props.format.task_board ? this.props.format.task_board.name : "Seleccione la plantilla",
                task_board_id: this.props.format.task_board ? this.props.format.task_board.id : "",
            },

            selectedOptionUsers: {
                label: "Usuarios",
                user_ids: [],
            },

            selectedOptionQuestion: {
                label: this.props.format.question_task_board ? this.props.format.question_task_board.name : "Seleccione el nombre del tablero",
                value: this.props.format.question_task_board ? this.props.format.question_task_board.id : "",
            }
        }
    }

    componentDidMount = () => {
        this.configSelect();
        this.loadQuestion();
        this.loadFormatTaskBoard();
    }

    loadFormatTaskBoard = () => {
        fetch(`/formatos/get_format_task_board/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateDefaultValue(data.register);
            this.setState({
                isLoaded: false,
                selectedOption: {
                    label: data.register.task_board ? data.register.task_board.name : "Seleccione la plantilla",
                    task_board_id: data.register.task_board ? data.register.task_board.id : "",
                },
    
                selectedOptionQuestion: {
                    label: data.register.question_task_board ? data.register.question_task_board.name : "Seleccione el nombre del tablero",
                    value: data.register.question_task_board ? data.register.question_task_board.id : "",
                },

                task_board: data.register.task_board,
                has_task_board: data.register.has_task_board,
                default_users: data.register.users,
            })
        });
    }

    configSelect = () => {
        let array = []
        let arrayUsers = []

        this.props.task_board_templates.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.props.users.map((item) => (
            arrayUsers.push({label: item.name, value: item.id})
        ))

        this.setState({
            task_boards: array,
            users: arrayUsers,
        })
    }

    loadQuestion = () => {
        fetch(`/formatos/get_questions/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            let array = [];

            data.register.map((item) => (
                array.push({ label: item.question, value: item.id })
            ))

            this.setState({
                questions: array,
            })
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true, showBtn: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }


    clearValues = () => {
        this.setState({
            modeEdit: false,
            id: "",
            task_board: {},
            editValuesPosition: [],
            selectLabels: [],
            editValuesUsers: [],

            form: {
                name: "",
                is_template: true,
                position_ids: [],
                task_label_ids: [],
                user_ids: [],
            },

            selectedOptionUsers: {
                user_ids: "",
                label: "Usuarios"
            },
        })
    }

    HandleChange = e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleClick = () => {
        if (!this.state.modeEdit) {
            if(this.validationForm()){
                fetch(`/task/task_boards`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.messageSuccess(data);
                    this.setState({ 
                        modeEdit: false, 
                        task_board: data.register, 
                        showBtn: false,
                        task_boards: [...this.state.task_boards, { label: data.register.name, value: data.register.id }]
                    })
                });
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    {this.state.modal && (
                        <FormCreate
                            //modal props
                            backdrop={"static"}
                            modal={this.state.modal}
                            toggle={this.toogle}
                            title={this.state.modeEdit ? "Editar tablero" : "Nuevo tablero"}

                            //form props
                            formValues={this.state.form}
                            onChangeForm={this.HandleChange}
                            submitForm={this.HandleClick}
                            errorValues={this.state.ErrorValues}
                            nameSubmit={this.state.modeEdit ? "Actualizar tablero" : "Siguiente"}
                            labels={this.props.labels}
                            modeEdit={this.state.modeEdit}

                            addLabel={this.addLabel}
                            selectLabels={this.state.selectLabels}
                            editValuesPosition={this.state.editValuesPosition}
                            showBtn={this.state.showBtn}
                            is_template={false}

                            //select config 
                            selectedOption={this.state.selectedOption}
                            cargos={this.state.cargos}
                            handleChangeAutocomplete={this.handleChangeAutocomplete}

                            //select user
                            handleChangeAutocompleteUsers={this.handleChangeAutocompleteUsers}
                            users={this.props.users}
                            selectedOptionUsers={this.state.selectedOptionUsers}
                            editValuesUsers={this.state.editValuesUsers}
                            task_board={this.state.task_board}

                        />
                    )}

                    {!this.state.isLoaded ? (
                        <React.Fragment>
                            <div className="col-md-12 mb-3 mt-3">
                                <label>El tablero tendra como nombre la siguiente pregunta</label>
                                <input
                                    type="hidden"
                                    name="question_id"
                                    value={this.props.selectedOptionQuestionTemplate.value}
                                />

                                <Select
                                    onChange={this.props.handleChangeAutocompleteQuestionTemplate}
                                    options={this.state.questions}
                                    autoFocus={false}
                                    className={`link-form`}
                                    value={this.props.selectedOptionQuestionTemplate}
                                />
                            </div>

                            {(this.props.formUpdateFormatTemplate.question_id || this.props.format.task_board)&& (
                                <React.Fragment>
                                    <div className="col-md-12 mb-3 mt-2">
                                        <label>Seleccione los usuarios que tendra ese tablero</label>
                                        <input
                                            type="hidden"
                                            name="user_ids"
                                            value={this.props.selectedOptionUsersTemplate.user_ids}
                                        />

                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            autoFocus={false}
                                            className={`link-form`}
                                            classNamePrefix="select"
                                            placeholder="Seleccione"
                                            name="user_ids"
                                            onChange={this.props.handleChangeAutocompleteUsersTemplate}
                                            options={this.state.users}
                                            defaultValue={this.state.default_users}
                                        />
                                    </div>
                                        
                                    <div className="col-md-12 mt-2 mb-2">
                                        <label>Seleccione la plantilla de tarea</label>
                                        <input
                                            type="hidden"
                                            name="task_board_id"
                                            value={this.props.selectedOptionTaskBoardTemplate.task_board_id}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocompleteTaskBoardTemplate}
                                            options={this.state.task_boards}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionTaskBoardTemplate}
                                        />
                                    </div>

                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <div className="col-md-12 mt-4 mb-4">
                            <div className="card">
                                <Preloader/>
                            </div>
                        </div>
                    )}

                    <ModalFooter>
                        <button className="btn btn-outline-danger" onClick={() => this.props.toggle()}>Cerrar</button>
                        {true && (
                            <button
                                className="btn-shadow btn btn-info"
                                disabled={this.state.isLoaded}
                                onClick={this.props.handleClick}
                            >
                                {this.state.isLoaded ? "Cargando..." : "Actualizar plantilla"}
                            </button>
                        )}
                    </ModalFooter>

                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;
