import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import GoogleDocsViewer from 'react-google-docs-viewer';
import UploadFile from './UploadFile';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { isMobile } from 'react-device-detect';
import is_imagen from 'is-image'
import Preloader from '../../../GeneralComponents/LoadingComponen';

//import "../Document/Document.css"

class Document extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: true,
            modal: false,
            nameFile: "",
            sizeFile: 0,

            formCode: {
                attached: {}
            },
            
            document: {
                user_create: this.props.document.user_create,
                state: "",
                attached: {},
                approve_status: "",
                token: "",
                header: "",
                has_header: "",
                name: "",
                version: "",
                has_footer: "",
                user_create_state_firm: "",
                user_create: "",
                creation_date: "",
                user_review_state_firm: "",
                user_review: "",
                review_date: "",
                user_approve_state_firm: "",
                user_approve: "",
                approve_date: "",
            },

            form: {
                attached: this.props.document.attached.url
            }
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.clearValues();
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    clearValues = () => {
        this.setState({
            formCode: {
                attached: {}
            },
            
            modal: false,
            nameFile: "",
            sizeFile: 0,
        })
    }

    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formCode: {
                    ...this.state.formCode,
                    attached: file.size >= 11485760 ? {} : file
                },
  
                nameFile: file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
                sizeFile: file.size,
            })
        ));
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };


    handleClick = () => {
        if(file.size <= 11485760){
            this.setState({ isLoaded: true })
            const formData = new FormData();
            formData.append("attached", this.state.formCode.attached)
            formData.append("is_attached", true)
    
            fetch(`/document_management/update_text/${this.props.document.id}`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data)
                this.setState({ 
                    isLoaded: false, 
                    modal: false,
                    nameFile: "",
                    form: {
                        attached: data.register.attached.url
                    }
                })
            });
        }
    }

    loadData = () => {
        fetch(`/document_management/get_document/${this.props.document.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log("trajo informacion del documento");
            this.updateDocumentState(data.document);
        });
    }

    updateDocumentState = (document) => {
        this.setState({
            isLoaded: false,
            document: {
                state: document.state,
                attached: document.attached,
                approve_status: document.approve_status,
                token: document.token,
                header: document.header,
                has_header: document.has_header,
                name: document.name,
                version: document.version,
                has_footer: document.has_footer,
                user_create_state_firm: document.user_create_state_firm,
                user_create: document.user_create,
                creation_date: document.creation_date,
                user_review_state_firm: document.user_review_state_firm,
                user_review: document.user_review,
                review_date: document.review_date,
                user_approve_state_firm: document.user_approve_state_firm,
                user_approve: document.user_approve,
                approve_date: document.approve_date,
            },

            form: {
                attached: document.attached ? document.attached.url : "",
            }
        })
    }

    render() {

        if(this.state.isLoaded){
            return(
                <div className="card">
                    <div className="card-body">
                        <Preloader/>
                    </div>
                </div>
            )
        }

        return (
            <React.Fragment>
                {this.state.modal && (
                    <UploadFile
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Subir archivo"}

                        handleClick={this.handleClick}
                        isLoaded={this.state.isLoaded}
                        nameFile={this.state.nameFile}
                        handleFile={this.handleFile}
                        sizeFile={this.state.sizeFile}
                    />
                )}

                <div className="card mb-3 document-show-container">
                    {this.props.document.user_create.id == this.props.user.id && (
                        <div className={`card-header ${isMobile ? "d-flex" : ""}`}>

                            {(this.state.document.state != "aprobado" && this.props.company.permit_upload_file) && (
                                <button
                                    type="button"
                                    className="btn-shadow btn btn-outline-info mr-3"
                                    style={{ fontSize: isMobile ? "10px" : "" }}
                                    onClick={() => this.toogle("new")}
                                >
                                    Subir documento
                                </button>
                            )}

                            {(this.state.document.state == "aprobado" || this.state.document.state == "obsoleto") && (
                                <React.Fragment>
                                    <UncontrolledDropdown className='btn-group mr-3 ui-document-btn-downloads'>
                                        <DropdownToggle className='btn btn-info btn-shadow'>
                                            Descargas
                                        </DropdownToggle>
                                        <DropdownMenu className='dropdown-menu dropdown-menu-rigth'>
                                            <DropdownItem
                                                href={this.state.document.attached.url ? this.state.document.attached.url : `/document_management/document_pdf/${this.props.document.id}.pdf`}
                                                className="dropdown-item"
                                                target="_blank"                                                
                                            >
                                                Descargar Documento
                                            </DropdownItem>
                                            <DropdownItem
                                                href={this.state.document.attached.url ? this.state.document.attached.url : `/document_management/document_pdf_control_versions/${this.props.document.id}.pdf`}
                                                className="dropdown-item"
                                                target="_blank"                                                
                                            >
                                                Descargar Documento + Historial
                                            </DropdownItem>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {/* <a
                                        href={this.state.document.attached.url ? this.state.document.attached.url : `/document_management/document_pdf/${this.props.document.id}.pdf`}
                                        className="btn-shadow btn btn-outline-info mr-3"
                                        target="_blank"
                                        style={{ fontSize: isMobile ? "10px" : "", textTransform: "initial" }}
                                    >
                                        Descargar documento en PDF
                                    </a>

                                    <a
                                        href={this.state.document.attached.url ? this.state.document.attached.url : `/document_management/document_pdf_control_versions/${this.props.document.id}.pdf`}
                                        className="btn-shadow btn btn-outline-info mr-3"
                                        target="_blank"
                                        style={{ fontSize: isMobile ? "10px" : "", textTransform: "initial" }}
                                    >
                                        Descargar documento con la actividad del documento y el control de versiones
                                    </a> */}
                                </React.Fragment>
                            )}

                            {(!this.state.form.attached && this.state.document.approve_status != "aprobado") &&(
                                <a
                                    type="button"
                                    href={`/document_management/documents/${this.props.document.token}/edit`}
                                    data-turbolinks="true"
                                    style={{ textTransform: "initial", fontSize: isMobile ? "10px" : ""  }}
                                    className="btn-shadow btn btn-outline-info"
                                >
                                    Actualizar documento
                                </a>                                
                            )}

                            {true && (
                                <a
                                    href={`/document_management/documents`}
                                    className={`btn-shadow btn btn-outline-info mr-3 ${!this.state.form.attached && this.state.document.approve_status != "aprobado" ? "ml-3" : ""}`}
                                    data-turbolinks="true"
                                    style={{ fontSize: isMobile ? "10px" : "", textTransform: "initial" }}
                                >
                                    Volver
                                </a>
                            )}
                        </div>
                    )}

                    <div className="card-body">

                        <div className="row">
                            <div className="col-md-12" >
                                {(this.props.document.approve_state != true && this.props.document.suervey_id != "na") && (
                                    <a href={`/formatos/format_categories/${this.props.document.format_category_id}/formats/${this.props.document.format_id}/surveys/${this.props.document.suervey_id}`} className="btn btn-primary"><i className="fas fa-pencil-alt"></i> </a>
                                )}
                            </div>
                        </div>

                        <div className="row">
                                    

                            {this.state.form.attached ? (
                                <React.Fragment>
                                    {is_imagen(this.state.form.attached) ? (
                                        <React.Fragment>
                                            <div className="col-md-12 text-center ui-documentGestion-containerfiles">
                                                <img src={this.state.form.attached} alt="" style={{ width: '100%'}}/>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="col-md-12 text-center ui-documentGestion-containerfiles">
                                                <GoogleDocsViewer
                                                    width={isMobile ? ('100%'): ('900px')}
                                                    height="780px"
                                                    fileUrl={this.state.form.attached}
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}    
                                </React.Fragment>
                            
                            ) : (
                                <React.Fragment>
                                    {this.props.document.has_header && (
                                        //Cambio para poder actualizar
                                        <div className="col-md-12 text-center document-header" >
                                            {ReactHtmlParser(this.props.document.header.replace("{NOMBRE}", this.props.document.name).replace("{VERSION}", this.props.document.version).replace("{CODIGO}", this.props.document.code))}
                                        </div>
                                    )}
                                    
                                    <div className="col-md-12">
                                        {ReactHtmlParser(this.props.document.document)}
                                    </div>

                                    {this.props.document.has_footer && (
                                        <div className="col-md-12 text-center document-footer" width="900px">
                                            {ReactHtmlParser(this.props.document.footer.replace("{NOMBRE}", this.props.document.name).replace("{VERSION}", this.props.document.version).replace("{CODIGO}", this.props.document.code))}
                                        </div>
                                    )}
                                </React.Fragment>
                            )}

                            {this.props.document.suervey_id != "na" && (
                                this.props.document.survey.answers.map((data, index) => (
                                    <div className="col-md-12">
                                        <Answers data={data} index={index} />
                                    </div>
                                ))
                            )}

                        </div>
                    </div>
                </div>

                {true && (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <table>
                                    <thead>
                                        <tr className="text-center">
                                            <th>Documento creado</th>
                                            <th>Documento revisado</th>
                                            <th>Documento aprobado</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr className="text-center">
                                            <td className="tr-info-document">
                                                {this.state.document.user_create_state_firm ? (
                                                    <React.Fragment>
                                                        <img src={this.state.document.user_create.firm} alt="" />
                                                    </React.Fragment>
                                                
                                                ) : (
                                                    <React.Fragment>
                                                        {this.state.document.user_create.name}
                                                    </React.Fragment>
                                                )}
                                                
                                                {this.state.document.creation_date && (
                                                    <React.Fragment>
                                                        <p className="mb-0 mt-2">Fecha de creacion: <b>{this.state.document.creation_date}</b>  </p>
                                                    </React.Fragment>
                                                )}
                                            </td>

                                            <td className="tr-info-document">
                                                {this.state.document.user_review_state_firm ? (
                                                    <React.Fragment>
                                                        <img src={this.state.document.user_review.firm} alt="" />
                                                    </React.Fragment>
                                                
                                                ) : (
                                                    <React.Fragment>
                                                        {this.state.document.user_review.name}
                                                    </React.Fragment>
                                                )}

                                                {this.state.document.review_date && (
                                                    <React.Fragment>
                                                        <p className="mb-0 mt-2">Fecha de revision: <b>{this.state.document.review_date}</b>  </p>
                                                    </React.Fragment>
                                                )}
                                            </td>

                                            <td className="tr-info-document">
                                                {this.state.document.user_approve_state_firm ? (
                                                    <React.Fragment>
                                                        <img src={this.state.document.user_approve.firm} alt="" />
                                                    </React.Fragment>
                                                
                                                ) : (
                                                    <React.Fragment>
                                                        {this.state.document.user_approve.name}
                                                    </React.Fragment>
                                                )}

                                                {this.state.document.approve_date && (
                                                    <React.Fragment>
                                                        <p className="mb-0 mt-2">Fecha de aprobacion: <b>{this.state.document.approve_date}</b>  </p>
                                                    </React.Fragment>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Document;
