import React, { Component } from 'react';
import Index from '../components/formatos/Surveys/Index'
import WebpackerReact from 'webpacker-react';

class SurveyCustomView extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            dataQuestion: [],
            dataAnswers: [],
            surveys: [],
            isLoaded: true,
            isLoadedPag: false,
            paramas: {},
            filters: {},
            tableWidth: 0,

            activePage: 1,
            countPage: this.props.configuration_format.quantity_to_show,
            survey_total: 0,

            activePage_filter: 1,
            countPage_filter: this.props.configuration_format.quantity_to_show,
            survey_total_filter: 0,
            formUpdateRepy: {},
            isFilter: false
        }
    }

    wordCount = (elements) => {
        var count = 0;
        for (var i = 0; i < elements.length; i++) {
            count += elements[i].textContent.split(/\s/).length;
        }
        return count;
    }

    componentDidMount() {
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    cleanFilters = () => {
        this.setState({
            filters: {}
        })
    }

    loadData = () => {
        //OBTIENE EL PARAMETRO PARA SABER SI ES ACTIVE O INACTIVE
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        // OBTIENE EL PARAMATRO PARA ENVIARLO EL EL REQUEST
        let view = urlParams.get('view')
        fetch(`/formatos/get_surveys_index_view/${this.props.formato.id}/${this.props.custom_view}/${this.props.survey_parent_id}?view=${view}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    dataQuestion: data.dataQuestion,
                    dataAnswers: data.dataAnswers,
                    tableWidth: data.tableWidth,
                    isLoaded: false,
                    survey_total: data.survey_total,
                    filters: data.filters,
                    isFilter: false,
                    surveys: data.surveys,
                    activePage: 1,
                    activePage_filter: 1,
                }, () => {
                    var wordsInParagraphs = this.wordCount(document.getElementsByTagName("p"));
                    console.log(wordsInParagraphs);
                });
            });
    }
    handlePageChange = (pageNumber) => {
        this.setState({
            isLoadedPag: true,
        })
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let view = urlParams.get('view')
        this.setState({ activePage: pageNumber });
        fetch(`/formatos/get_surveys_index_view/${this.props.formato.id}/${this.props.custom_view}/${this.props.survey_parent_id}?page=${pageNumber}&filter=${this.state.countPage}&view=${view}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {

                this.setState({
                    data: data.data,
                    dataQuestion: data.dataQuestion,
                    dataAnswers: data.dataAnswers,
                    tableWidth: data.tableWidth,
                    isLoaded: false,
                    isLoadedPag: false,
                    survey_total: data.survey_total,
                    filters: data.filters,
                    isFilter: false,
                    surveys: data.surveys,
                    activePage: pageNumber,
                    activePage_filter: pageNumber
                });
            });
    }


    loadDataFilter = (data) => {
        console.log("estoy en el data filter:", data)
        this.setState({
            formUpdateRepy: data
        })
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let view = urlParams.get('view')
        console.log("Object keys:", Object.keys(data))
        console.log("Object keys:", data[Object.keys(data)[0]])
        let query = Object.keys(data)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
            .join('&');

        fetch(`/formatos/get_surveys_index_view_filter/${this.props.formato.id}/${this.props.custom_view}/${this.props.survey_parent_id}/?${query}&view=${view}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("hola")
                this.setState({
                    data: data.data,
                    dataQuestion: data.dataQuestion,
                    dataAnswers: data.dataAnswers,
                    tableWidth: data.tableWidth,
                    isLoaded: false,
                    filters: data.filters,
                    survey_total_filter: data.survey_total,
                    isFilter: true,
                    activePage_filter: 1,
                    activePage: 1,

                });
            });
    }

    loadDataFilterUpdate = (data) => {
        console.log("estoy en el data filter1111:", data)
        this.setState({
            formUpdateRepy: data
        })
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let view = urlParams.get('view')
        console.log("Object keys:", Object.keys(data))
        console.log("Object keys:", data[Object.keys(data)[0]])
        data.page = this.state.activePage_filter

        let query = Object.keys(data)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
            .join('&');

        fetch(`/formatos/get_surveys_index_view_filter/${this.props.formato.id}/${this.props.custom_view}/${this.props.survey_parent_id}/?${query}&view=${view}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("hola")
                this.setState({
                    data: data.data,
                    dataQuestion: data.dataQuestion,
                    dataAnswers: data.dataAnswers,
                    tableWidth: data.tableWidth,
                    isLoaded: false,
                    filters: data.filters,
                    survey_total_filter: data.survey_total,
                    isFilter: true,
                    activePage_filter: this.state.activePage_filter,
                    activePage: this.state.activePage_filter
                });
            });
    }


    handlePageChange_filter = (pageNumber) => {
        var data = this.state.formUpdateRepy
        let query = Object.keys(data)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
            .join('&');
        this.setState({ activePage_filter: pageNumber });
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let view = urlParams.get('view')
        fetch(`/formatos/get_surveys_index_view_filter/${this.props.formato.id}/${this.props.custom_view}/${this.props.survey_parent_id}?${query}&page=${pageNumber}&filter=${this.state.countPage}&view=${view}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    dataQuestion: data.dataQuestion,
                    dataAnswers: data.dataAnswers,
                    tableWidth: data.tableWidth,
                    isLoaded: false,
                    survey_total_filter: data.survey_total,
                    filters: data.filters,
                    isFilter: true.valueOf,
                    activePage_filter: pageNumber,
                    activePage: pageNumber,
                });
            });
    }

    handleChangeQuantityToShow = (e) => {
        const configuration_format = {
            quantity_to_show: e.target.value
        }

        fetch(`/formatos/configuration_formats/${this.props.configuration_format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(configuration_format), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.loadData();
                this.setState({
                    countPage_filter: data.configuration_format.quantity_to_show,
                    countPage: data.configuration_format.quantity_to_show,
                })
            });
    }

    render() {
        return (
            <React.Fragment>
                <Index
                    updateStateLoad={this.updateStateLoad}
                    data={this.state.data}
                    dataQuestion={this.state.dataQuestion}
                    dataAnswers={this.state.dataAnswers}
                    surveys={this.state.surveys}
                    format={this.props.formato}
                    updateData={this.updateData}
                    loadData={this.loadData}
                    isLoaded={this.state.isLoaded}
                    isLoadedPag={this.state.isLoadedPag}
                    users={this.props.users}
                    procesos={this.props.procesos}
                    positions={this.props.positions}
                    loadDataFilter={this.loadDataFilter}
                    loadDataFilterUpdate={this.loadDataFilterUpdate}
                    filters={this.state.filters}
                    format_category={this.props.format_category}
                    tableWidth={this.state.tableWidth}
                    handlePageChange={this.handlePageChange}
                    domain={this.props.domain}

                    survey_total={this.state.survey_total}
                    countPage={this.state.countPage}
                    activePage={this.state.activePage}

                    survey_total_filter={this.state.survey_total_filter}
                    countPage_filter={this.state.countPage_filter}
                    handlePageChange_filter={this.handlePageChange_filter}
                    activePage_filter={this.state.activePage_filter}

                    isFilter={this.state.isFilter}
                    cleanFilters={this.cleanFilters}
                    estados={this.props.estados}
                    format_exels={this.props.format_exels}
                    view={this.props.view}
                    format_indicators={this.props.format_indicators}
                    state_records={this.props.state_records}
                    survey_parent_id={this.props.survey_parent_id}
                    survey_parent_token={this.props.survey_parent_token}
                    custom_view_id={this.props.custom_view}
                    current_url={this.props.current_url}
                    tenant={this.props.tenant}
                    format_pdfs={this.props.format_pdfs}
                    format_custom_view_dashboard={this.props.format_custom_views}
                    count_registers={this.props.count_registers}
                    current_plan={this.props.current_plan}
                    plans={this.props.plans}
                    handleChangeQuantityToShow={this.handleChangeQuantityToShow}
                    key_text={this.props.key_text}
                    imageUpload={this.props.imageUpload}
                />
            </React.Fragment>
        );
    }
}


export default SurveyCustomView;
WebpackerReact.setup({ SurveyCustomView });

