import React, { useMemo, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import FavoriteModule from '../../settings/FavoriteModule/Index';
import GoogleFontLoader from 'react-google-font-loader';

const HeaderTitle = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const fontConfig = useMemo(() => ({
        fonts: [
            {
                font: 'Material Symbols Outlined',
                weights: [400],
            },
        ],
        async: true,
        defer: true,
    }), []);

    return (
        <React.Fragment>
            {useMemo(() => <GoogleFontLoader {...fontConfig} />, [fontConfig])}
            <div className="app-page-title">
                <div className="page-title-wrapper">
                    <div className="page-title-heading ui-dynamic">
                        <div className="page-title-icon">
                            <i className={`${props.icon != undefined ? props.icon : "fas fa-user-cog"} icon-gradient bg-mean-fruit`}>
                            </i>
                        </div>
                        <div className='ui-header-title__container_title_subtitle'>
                            <span className='ui-header-title__space_title'>{props.title}</span>
                            <div className="page-title-subheading">
                                {props.subTitle}
                            </div>
                        </div>


                        {props.is_index && (
                            <FavoriteModule
                                url={props.url}
                                name={props.title}
                                icon={props.icon != undefined ? props.icon : "fas fa-user-cog"}
                            />
                        )}
                    </div>

                    <div className="page-title-actions ui-header-title__contaienr_title-actions">
                        {isMobile && (
                            <React.Fragment>
                                {true && (
                                    <a onClick={() => props.updateView("columns")}
                                        style={{ color: props.listView == "columns" ? "#3f6ad8" : "#ACE379", cursor: "pointer", border: props.listView == "columns" ? "1px solid #3f6ad8" : "" }} className="stretched-link ui-header-title-icons_material"
                                        id="ui-headerTitle-columns">
                                        <i class="material-symbols-outlined">
                                            &#xe8f3;
                                        </i>
                                        <UncontrolledTooltip target="ui-headerTitle-columns" placement='bottom'>{"VER TAREAS POR COLUMNAS"}</UncontrolledTooltip>
                                    </a>
                                )}

                                {true && (
                                    <a onClick={() => props.updateView("list")}
                                        style={{ color: props.listView == "list" ? "#3f6ad8" : "#63BBFF", cursor: "pointer", border: props.listView == "list" ? "1px solid #3f6ad8" : "" }} className="ml-2 mr-2 ui-header-title-icons_material"
                                        id="ui-headerTitle-list">
                                        <i class="material-symbols-outlined">
                                            &#xf06d;
                                        </i>
                                        <UncontrolledTooltip target="ui-headerTitle-list" placement='bottom'>{"VER TAREAS EN LISTA"}</UncontrolledTooltip>
                                    </a>
                                )}

                                {true && (
                                    <a onClick={() => props.updateView("calendar")}
                                        style={{ color: props.listView == "calendar" ? "#3f6ad8" : "#FFAD95", cursor: "pointer", border: props.listView == "calendar" ? "1px solid #3f6ad8" : "" }} className="mr-2 ui-header-title-icons_material"
                                        id="ui-headerTitle-calendar">
                                        <i class="material-symbols-outlined">
                                            &#xebcc;
                                        </i>
                                        <UncontrolledTooltip target="ui-headerTitle-calendar" placement='bottom'>{"VER TAREAS EN CALENDARIO"}</UncontrolledTooltip>
                                    </a>
                                )}

                                {false && (
                                    <a
                                        style={{ color: "#a2a2a2", cursor: "pointer" }}
                                        className="mr-3"
                                        href={`/task/task_boards/${props.task_board.token}/gantt`}
                                        data-turbolinks="true"
                                        id="ui-headerTitle-digram"
                                    >
                                        <i className="fas fa-project-diagram" style={{ fontSize: "33px" }}></i>
                                        <UncontrolledTooltip target="ui-headerTitle-digram" placement='bottom'>{"Ver Tareas como Proyecto Gantt"}</UncontrolledTooltip>
                                    </a>
                                )}

                                {true && (
                                    <a className="mr-2 ui-header-title-icons_material"
                                        onClick={() => props.updateView("history")} data-turbolinks="true"
                                        style={{ color: props.listView == "history" ? "#3f6ad8" : "#E3D179", cursor: "pointer", border: props.listView == "history" ? "1px solid #3f6ad8" : "" }}
                                        id="ui-headerTitle-history">
                                        <i class="material-symbols-outlined">
                                            &#xe1a1;
                                        </i>
                                        <UncontrolledTooltip target="ui-headerTitle-history" placement='bottom'>{"VER TAREAS ARCHIVADAS"}</UncontrolledTooltip>
                                    </a>
                                )}


                                {false && (
                                    <React.Fragment>
                                        <UncontrolledTooltip target="ui-headerTitle-tootltip">{"BÚSQUEDA POR FILTRO"}</UncontrolledTooltip>
                                        <button type="button" onClick={() => props.onClickFilter()} className="btn-shadow mr-3 btn btn-primary" id="ui-headerTitle-tootltip">
                                            <i className="fas fa-filter pr-1"></i> Filtrar
                                        </button>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>


            </div>
        </React.Fragment>
    );
}

export default HeaderTitle;