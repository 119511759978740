import React, { Component } from 'react';
import Table from '../components/dofa/Dofa/Index'
import WebpackerReact from 'webpacker-react';

class IndexDofa extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,

            //pagination 

            activePage: 1,
            countPage: 12,
            total: 0
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = dofa => {
        this.setState({
            data: this.state.data.map(item => {
            if (dofa.id === item.id) {
              return { ...item, 
                title: dofa.title,
                date: dofa.date,
                responsable: dofa.responsable,
                description: dofa.description,
                positions: dofa.positions,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/dofa/get_dofa`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                total: data.total,
                isLoaded: false
            });
        });
    }

    handlePageChange = pageNumber => {
        this.setState({ activePage: pageNumber }); 
        fetch(`/dofa/get_dofa?page=${pageNumber}&filter=${this.state.countPage}&filtering=${this.state.filtering}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({ 
                data: data.data,
                total: data.total,
            });
        });     
    }; 


    render() {
        return (
            <React.Fragment>
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    users={this.props.users}
                    estados={this.props.estados}
                    positions={this.props.positions}
                    task_board_templates={this.props.task_board_templates}
                    current_url={this.props.current_url}

                    //pagination props
                    totalData={this.state.total}
                    activePage={this.state.activePage}
                    countPage={this.state.countPage}
                    handlePageChange={this.handlePageChange}
                />
            </React.Fragment>
        );
    }
}


export default IndexDofa;
WebpackerReact.setup({ IndexDofa });
