import React, { useState, useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Form } from 'reactstrap';

const FormFilter = (props) => {
    const [form, setForm] = useState({ type_module: "", value: "" });
    const [filterValue, setFilterValue] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [data, setData] = useState([]);
    const token = document.querySelector("[name='csrf-token']").content;

    const [showNoResultsMessage, setShowNoResultsMessage] = useState(false);
    const [showNullResultsMessage, setShowNullResultsMessage] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          setShowNoResultsMessage(false);
        }, 7000);
    
        const nullTimer = setTimeout(() => {
          setShowNullResultsMessage(false);
        }, 5000);
    
        return () => {
          clearTimeout(timer);
          clearTimeout(nullTimer);
        };
    }, [showNoResultsMessage, showNullResultsMessage]);
    
    const handleSubmit = e => {
        e.preventDefault();
    };

    const optionSelectSearch = [
        { value: 'format', label: 'Formatos' },
        { value: 'view', label: 'Vistas Personalizadas' },
        { value: 'dashboard', label: 'Estadísticas' },
        { value: 'module', label: 'Categoria' }
    ];

    const handleChangeFilter = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleChangeSelect = (e) => {
        setForm({ type_module: e.target.value, value: "" });
        setData([]);
    }

    const handleClickFilter = () => {
        if (!form.value) {
            setShowNullResultsMessage(true);
            return;
        }
        setDisabled(true);
        fetch(`/formatos/filter_format_categories/${form.type_module}/${form.value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            setData(data.data);
            setDisabled(false);
            setFilterValue("");
            setShowNoResultsMessage(data.data.length === 0);
        })
    }

    const handleChangeSelectModule = (e) => {
        if(false){
            props.updateIsLoadedFilterState(true);
            fetch(`/formatos/result_filter_format_categories/${form.type_module}/${e.target.value}`, {
                method: 'GET', // or 'PUT'
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                props.setDataFilter(data);
            })
        }
    }

    const cancelFilter = () => {
        props.clearDataFilter();
        setForm({ type_module: "", value: "" });
        setData([]);
    }

    const getRedirrectUrl = (option) => {
        if(form.type_module == "format"){
            return `/formatos/format_categories/${option.format_category}/formats/${option.token}`
        }else if(form.type_module == "module"){
            return `/formatos/format_categories/${option.token}`
        }else{
            return `/formatos/format_custom_views/${option.token}/format_category/${option.format_category}`
        }
    }

    const type_module_label = optionSelectSearch.find(option => option.value.includes(form.type_module));      

    return (
        <div className='ui-formFilter-formatCategoriesIndex__container-form'>
            {(showNoResultsMessage && data.length === 0) && (
                <div className='alert alert-warning ui-formFilter-formatCategoriesIndex_message'>
                    No encontramos ningún resultado que coincida con "{type_module_label.label}" y "{form.value}". Por favor, intenta con otra búsqueda o cambia la categoría.
                </div>
            )}

            {showNullResultsMessage  && (
                <div className='alert alert-danger ui-formFilter-formatCategoriesIndex_message'>
                    Por favor, proporciona información sobre lo que estás buscando.
                </div>
            )}

            <select 
                className='form form-control ui-formFilter-formatCategoriesIndex_select' 
                name="type_module" 
                value={form.type_module} 
                onChange={handleChangeSelect}
            >
                <option value="">Selecciona un modulo a filtrar</option>
                {optionSelectSearch.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>

            {form.type_module && (
                <form className='ui-formatCategoriesIndex-breadcrumb-form' onSubmit={handleSubmit}>
                    <input 
                        type="text" 
                        name="value" 
                        placeholder="Palabra o frase a filtrar..." 
                        value={form.value} 
                        onChange={handleChangeFilter}
                        className='ml-2 form form-control' 
                    />  

                   <div className='ui-formFilter-formatCategoriesIndex__container_btn_select'>
                        {data.length >= 1 && (
                            <UncontrolledDropdown direction="bottom" className='btn-group'>
                                <DropdownToggle className='btn btn-info btn-shadow'>
                                    Resultado de la búsqueda <i className="fas fa-arrow-down"></i>
                                </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                        {data.map((option, index) => (
                                            <DropdownItem
                                                key={index}
                                                href={getRedirrectUrl(option)}
                                                data-turbolinks="true"
                                                target="_blank"
                                                className="dropdown-item"
                                            >
                                                {option.name}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                            </UncontrolledDropdown>
                        )}


                        <div className='ui-formFilter-formatCategoriesIndex__container-btn'>
                            <button
                                type="submit" 
                                disabled={disabled}
                                className="ml-2 btn-shadow btn btn-info"
                                onClick={handleClickFilter}
                            > 
                                <i className={ disabled ? `"fas fa-spinner` :`fas fa-search`} ></i>
                            </button>

                            {data.length >= 1 &&(
                                <button
                                    type="submit"
                                    className="ml-2 btn-shadow btn btn-danger"
                                    onClick={cancelFilter}
                                >
                                    <i className="fas fa-times"></i>
                                </button>
                            )}
                        </div>
                   </div>                   
                </form>                          
            )} 
        </div>
    );
}


export default FormFilter;



/*
                            <select 
                                className='form form-control ml-2 ui-formFilter-formatCategoriesIndex_select' 
                                name="type_module" 
                                value={filterValue} 
                                onChange={(e) => { handleChangeSelectModule(e), setFilterValue(e.target.value) } }
                            >
                                <option value="">Filtrando resultados...</option>
                                {data.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        <a href={form.type_module == "format" ? `/formatos/format_categories/${option.format_category}/formats/${option.token}` : `/formatos/format_custom_views/${option.format_category}/format_category/${option.token}`}></a>
                                    </option>
                                ))}
                            </select>
*/