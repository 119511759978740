import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <input
                                type="hidden"
                                name="format_select_id"
                                value={this.props.selectedOption.format_select_id}
                            />
                            <label>Formato</label>
                            <Select
                                onChange={this.props.handleChangeAutocomplete}
                                options={this.props.formatos}
                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.format_select_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOption}
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <input
                                type="hidden"
                                name="question_asociation_id"
                                value={this.props.selectedOptionQuestion.question_asociation_id}
                            />
                            <label>Pregunta que quieres asociar a este formato</label>
                            <Select
                                onChange={this.props.handleChangeAutocompleteQuestions}
                                options={this.props.questions}
                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.question_asociation_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOptionQuestion}
                            />
                        </div>

                        <div className="col-md-12 text-right">
                            <button
                                className="btn btn-outline-danger mr-2"
                                onClick={() => this.props.toggle()}
                            >
                                Cancelar
                            </button>

                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.props.submitForm()}
                            >
                                {this.props.nameBnt}
                            </button>

                            <hr />
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;