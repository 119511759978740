import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

class ModalUpdateImagen extends Component {
    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className={`col-md-12`}>
                                    {(this.props.url_img == null || this.props.modeEditFile) ? (
                                        <React.Fragment>
                                            <Dropzone onDrop={(files) => this.props.handleFile(files)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div
                                                        {...getRootProps({
                                                            className: 'dropzone',
                                                        })}
                                                    >
                                                        <input {...getInputProps()} accept={"image/*"} />
                                                        <p>{`${(this.props.nameFile != "" ? this.props.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                    </div>
                                                )}
                                            </Dropzone>

                                            <div className="col-md-12 mt-3 text-center p-0">
                                                <div className="alert alert-info mb-0" role="alert">
                                                    <b>Solo puede subir imagen de máximo 1 MB</b>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <img src={this.props.url_img} style={{ maxWidth: "200px" }} />
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            {this.props.nameFile != "" ? (
                                <React.Fragment>
                                    <button
                                        className="btn-shadow btn btn-info mr-2"
                                        onClick={() => this.props.HandleClickFile()}
                                    >
                                        {this.props.modeEditFile ? "Actualizar imagen" : "Subir imagen"}
                                    </button>

                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={() => this.props.cancelUpload()}
                                    >
                                        Cancelar
                                    </button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {this.props.modeEditFile ? (
                                        <React.Fragment>
                                            <button
                                                className="btn-shadow btn btn-info mr-2"
                                                onClick={() => this.props.HandleClickFile()}
                                            >
                                                Actualizar imagen
                                            </button>

                                            <button
                                                className="btn btn-outline-danger"
                                                onClick={() => this.props.cancelUpload()}
                                            >
                                                Cancelar
                                            </button>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <button
                                                className="btn btn-light"
                                                onClick={() => this.props.editFIle()}
                                            >
                                                <i className="fas fa-pencil-alt"></i>
                                            </button>

                                            {this.props.url_img != null && (
                                                <button
                                                    className="btn-shadow btn btn-danger ml-3"
                                                    onClick={() => this.props.removeImagen()}
                                                >
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}

                            <button className="btn btn-outline-danger" onClick={() => this.props.toggle()}>Cerrar</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalUpdateImagen;
