import React, { Component } from 'react';
import Select from "react-select";

class FormFilter extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }

    render() {
        
        return (
            <React.Fragment>
                <div className="card mb-3">
                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-md-4 mb-3">
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={this.props.formValues.name}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label>Fecha de creación</label>
                                    <input
                                        type="date"
                                        name="creation_date"
                                        value={this.props.formValues.creation_date}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label>Fecha de vencimiento</label>
                                    <input
                                        type="date"
                                        name="due_date"
                                        value={this.props.formValues.due_date}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <input
                                        type="hidden"
                                        name="responsable_user_id"
                                        value={this.props.selectedOptionUser.responsable_user_id}
                                    />
                                    <label>Usuario responsable </label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteUser}
                                        options={this.props.users}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.props.selectedOptionUser}
                                    />
                                </div>

                                {true && (
                                    <div className="col-md-4 mb-3">
                                        <input
                                            type="hidden"
                                            name="proceso_id"
                                            value={this.props.selectedOptionProceso.proceso_id}
                                        />
                                        <label>Proceso </label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteProceso}
                                            options={this.props.procesos}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionProceso}
                                        />
                                    </div>  
                                )}

                                <div className="col-md-4 mb-3">
                                    <label>Emisor</label>
                                    <input
                                        type="text"
                                        name="emisor"
                                        value={this.props.formValues.emisor}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <button
                                className="btn-shadow btn btn-info  mr-3"
                                onClick={() => this.props.HandleClickFilter()}
                            >
                                Aplicar Filtro
                            </button>

                            <a
                                className="btn btn-outline-danger"
                                onClick={() => this.props.filter(false)}
                            >
                                Cancelar
                            </a>

                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default FormFilter;