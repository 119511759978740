import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-lg modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <label>Nombre de usuario </label>
                                        <input
                                            type="text"
                                            name="username"
                                            value={this.props.formValues.username}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.username == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <label>Correo electrónico </label>
                                        <input
                                            type="email"
                                            name="second_email"
                                            value={this.props.formValues.second_email}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.second_email == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <label>Primer Nombre </label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            value={this.props.formValues.first_name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.first_name == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <label>Segundo Nombre </label>
                                        <input
                                            type="text"
                                            name="second_name"
                                            value={this.props.formValues.second_name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.second_name == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label>Primer Apellido </label>
                                        <input
                                            type="text"
                                            name="first_last_name"
                                            value={this.props.formValues.first_last_name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.first_last_name == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label>Segundo Apellido </label>
                                        <input
                                            type="text"
                                            name="second_last_name"
                                            value={this.props.formValues.second_last_name}
                                            onChange={this.props.onChangeForm}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.second_last_name == "" ? "error-class" : ""}`}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3 mb-3">
                                        <label>Empresa </label>
                                        <input
                                            type="hidden"
                                            name="tenant_id"
                                            value={this.props.selectedOption.tenant_id}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocomplete}
                                            options={this.props.companies}
                                            autoFocus={false}
                                            className={`link-form ${!this.props.errorValues && this.props.formValues.tenant_id == "" ? "error-class" : ""}`}
                                            value={this.props.selectedOption}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label>Contraseña</label>
                                        <input
                                            type={this.props.password1 ? "text" : "password"}
                                            name="password"
                                            value={this.props.formValues.password}
                                            onChange={this.props.handleChangePassword}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.password == "" ? "error-class" : ""}`}
                                        />

                                        <label className="btn-password" onClick={() => this.props.changePasswordView("password", !this.props.password1)}><i className={`fas ${this.props.password1 ? "fa-eye" : "fa-eye-slash"}`}></i></label>
                                    </div>

                                    <div className="col-md-6">
                                        <label>Confirmar contraseña </label>
                                        <input
                                            type={this.props.password2 ? "text" : "password"}
                                            name="password_confirmation"
                                            value={this.props.formValues.password_confirmation}
                                            onChange={this.props.handleChangePassword}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.password_confirmation == "" ? "error-class" : ""}`}
                                        />

                                        <label className="btn-password" onClick={() => this.props.changePasswordView("password_confirmation", !this.props.password2)}><i className={`fas ${this.props.password2 ? "fa-eye" : "fa-eye-slash"}`}></i></label>
                                    </div>


                                    {!this.props.errorValues && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                                <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBnt}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;