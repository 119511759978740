import React, { useState, useEffect } from 'react';

const showTenantInfo = (props) => {
    return (
        <div className="ui-tenant_admin-container">
                <div className="card ui-tenant_admin-container__logo">
                    <div className="card-header">
                        <h6 className='ui-tenant_admin-name_user'>{props.tenant.company_name}</h6>
                    </div>
                    <div className="card-body">
                        {props.tenant.logo.url != null ? (
                            <img className='ui-tenant_admin-logo' src={`${props.tenant.logo.url}`} alt="Logo Empresa" />    
                        ):(
                            <img className='ui-tenant_admin-logo' src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/990/3060465483633_a0137784f7832be35fdf_88.png" alt="Logo Beewo" />
                        )}
                    </div>
                </div>
            
                <div className="card ui-tenant_admin-container__data">
                    <div className="card-body">
                        <table className="ui-tenant_admin-card-container">
                            <tr>
                                <td>
                                    <div className="card ui-tenant_admin-card-data">
                                        <h6>Usuario</h6>
                                        <div className="card-body ui-tenant_admin-body-data">
                                        {props.tenant.admin.name}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="card ui-tenant_admin-card-data">
                                        <h6>Cantidad Usuarios</h6>
                                        <div className="card-body ui-tenant_admin-body-data">
                                            {props.tenant.users}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="card ui-tenant_admin-card-data">
                                        <h6>Tamaño</h6>
                                        <div className="card-body ui-tenant_admin-body-data">
                                            {props.tenant.upload} GB
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="card ui-tenant_admin-card-data">
                                        <h6>Registros</h6>
                                        <div className="card-body ui-tenant_admin-body-data">
                                            {props.tenant.plan.records_plan}
                                        </div>
                                    </div>
                                </td>                            
                            </tr>
                            <tr>
                                <td>
                                    <div className="card ui-tenant_admin-card-data">
                                        <h6>Estado Registros</h6>
                                        <div className="card-body ui-tenant_admin-body-data">
                                            {props.tenant.state_records}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="card ui-tenant_admin-card-data">
                                        <h6>Inicio Demo</h6>
                                        <div className="card-body ui-tenant_admin-body-data">
                                        {props.tenant.start_demo}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="card ui-tenant_admin-card-data">
                                        <h6>Fin Demo</h6>
                                        <div className="card-body ui-tenant_admin-body-data">
                                            {props.tenant.end_demo}
                                        </div>
                                    </div>
                                </td>
                                <td rowSpan="2">
                                    <div className="card ui-tenant_admin-card-data ui-plan-active" style={{borderTop: '4px solid green', borderBottom: '4px solid green' }}>
                                        <h6 style={{color: 'green'}}>Estado del Plan</h6>
                                        <div className="card-body ui-tenant_admin-body-data ui-active-data" style={{color: 'green'}}>
                                            Activo
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="card ui-tenant_admin-card-data">
                                        <h6>Plan</h6>
                                        <div className="card-body ui-tenant_admin-body-data">
                                            {props.tenant.plan.name}
                                        </div>
                                    </div>
                                </td>
                                <td colSpan="2">
                                    <div className="card ui-tenant_admin-card-data ui-tenant-email-user">
                                        <h6>Correo Electrónico</h6>
                                        <div className="card-body ui-tenant_admin-body-data">
                                            {props.tenant.admin.email}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
    )
}

export default showTenantInfo;