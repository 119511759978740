import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import Preloader from '../GeneralComponents/LoadingComponen';
import Pagination from "react-js-pagination";

import HeaderTitle from '../GeneralComponents/HeaderTitle';
import FormCreate from '../components/settings/HealthCodes/FormCreate';
import FormFilter from '../components/settings/HealthCodes/FormFilter';
import UploadFile from '../components/documentManagement/Document/UploadFile';


const HealthCode = (props) => {  
    const [data, setData] = useState([]);
    const [form, setForm] = useState({ code: "", description: "" });
    const [formFilter, setFormFilter] = useState({ code: "", description: "" });

    const [id, setId] = useState("");
    const [isLoaded, setIsLoaded] = useState(true);

    const [modal, setModal] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const [modeEdit, setModeEdit] = useState(false);
    const [errorValues, setErrorValues] = useState(true);

    //file state
    const [isLoadedFile, setIsLoadedFile] = useState(false);
    const [modalFile, setModalFile] = useState(false);
    const [nameFile, setNameFile] = useState('');
    const [formFile, setFormFile] = useState({ file: {} });

    //paginate
    const [totalData, setTotalData] = useState(0);
    const [countPage, setCountPage] = useState(30);
    const [activePage, setActivePage] = useState(1);
    

    const token = document.querySelector("[name='csrf-token']").content;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        fetch(`/settings/get_health_codes`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setTotalData(data.total_data);
            setIsLoaded(false);
        });
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })        
    }

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const updateItem = (software_change) => {
        setData(data.map(item => {
          if (item.id === software_change.id) {
            return { ...item, 
                code: software_change.code, 
                description: software_change.description, 
            }
          }
          return item;
        }));
    }

    const updateData = (new_item) => {
        setData([...data, new_item])
    }    
    
    const handleClick = () => {
        if(true){
            if (!modeEdit)
                fetch(`/settings/health_codes`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type == "success"){
                        setModal(false);
                        messageSuccess(data);
                        updateData(data.register);
                        clearValues();
                    }else{
                        messageSuccess(data);
                        clearValues();
                    }
                });
            else {
                fetch(`/settings/health_codes/${id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type == "success"){
                        setModal(false);
                        messageSuccess(data);
                        updateItem(data.register);
                        clearValues();
                    }else{
                        messageSuccess(data);
                        clearValues();
                    }
                });
            }
        }
    }

    const edit = (health_code) => {
        setForm({ code: health_code.code, description: health_code.description });
        setId(health_code.id);
        setModal(true);
        setModeEdit(true);
    }

    const destroy = (health_code_id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/health_codes/${health_code_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    loadData()
                });
            }
        });
    };
    
    const clearValues = () => {
        setModeEdit(false);
        setForm({ code: "", description: "" });
        setErrorValues(true);
    }
    
    const handleFile = (file) => {
        setForm({ ...form, ['file']: file[0].size >= 11485760 ? {} : file[0] });
        setNameFile(file[0].size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file[0].path);
    };

    const toogle = (from) => {
        if (from == "new") {
            setModal(true);
        } else {
            setModal(false);
            clearValues();
        }
    }

    const toogleFile = (from) => {
        if (from == "new") {
            setModalFile(true);
        } else {
            setModalFile(false);
        }
    }

    const handleClickUpload = () => {
        if(true){
            setIsLoadedFile(true);

            const formData = new FormData();
            formData.append("file", formFile.file)
    
            fetch(`/settings/import_health_codes`, {
                method: 'POST', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                messageSuccess(data)
                setIsLoadedFile(false);
                setModalFile(false);
                setNameFile("");
                loadData();
                setFormFile({ file: {} })
            });
        }
    }


    const handleUploadFile = (archivo) => {
        archivo.map(file => (
            setFormFile({ file: file }),
            setNameFile(file.path)
        ));
    };
    
    const handleChangeFormFilter = (e) => {
        setFormFilter({ ...formFilter, [e.target.name]: e.target.value })      
    }

    const cancelFilter = () => {
        setFormFilter({ code: "", description: "" });
        setShowFilter(false);
        loadData();
    }

    const handleClickFilter = () => {
        fetch(`/settings/get_health_codes?code=${formFilter.code}&description=${formFilter.description}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setIsLoaded(false);
        });
    }

    //pagination

    const handlePageChange = pageNumber => {
        setActivePage(pageNumber);
        fetch(`/settings/get_health_codes?page=${pageNumber}&filter=${countPage}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setTotalData(data.total_data);
        });
    };

    return (
        <React.Fragment>
            <HeaderTitle 
                title={"CE10 Beewo"} 
                subTitle={"Gestión de codigos para CE10"}
                titleNew={"Crear CE10"}
                icon={"fas fa-file-code"} 
                onClickNew={toogle}
                showNew={true}
                showFilter={false} 
            />

            {modal && (
                <FormCreate
                    backdrop={"static"}
                    modal={modal}
                    toggle={toogle}
                    title={modeEdit ? "Actualizar código" : "Crear código"}
                    nameBnt={modeEdit ? "Actualizar" : "Crear"}     
                    
                    formValues={form}
                    onChangeForm={handleChange}
                    handleFile={handleFile}
                    nameFile={nameFile}
                    submitForm={handleClick}
                    key_text={props.key_text}
                />
            )}

            {modalFile && (
                <UploadFile
                    //modal props
                    backdrop={"static"}
                    modal={modalFile}
                    toggle={toogleFile}
                    title={"Subir archivo"}

                    handleClick={handleClickUpload}
                    isLoaded={isLoadedFile}
                    nameFile={nameFile}
                    handleFile={handleUploadFile}
                    sizeFile={0}
                />
            )}

            {showFilter && (
                <FormFilter
                    onChangeForm={handleChangeFormFilter}
                    formValues={formFilter}
                    cancelFilter={cancelFilter}
                    handleClickFilter={handleClickFilter}
                />
            )}
        
        
            <React.Fragment>
                <div className="content main-card mb-3 card ui-softwareChange-card__container">
                    {!isLoaded ? (
                        <React.Fragment>
                            {data.length >= 1 ? (
                                <React.Fragment>
                                    <div className="card-header">
                                        
                                        {(!showFilter && false) && (
                                            <button
                                                onClick={() => setShowFilter(true)}
                                                className="btn-shadow btn btn-info mr-3"
                                            >
                                                Abrir filtros
                                            </button>
                                        )}

                                        <button
                                            onClick={() => toogleFile("new")}
                                            className="btn-shadow btn btn-info"
                                        >
                                            Subir archivo
                                        </button>
                                    </div>

                                    <div className="card-body ui-softwareChange-cardbody__container">
                                
                                        <table className="table table-hover table-striped table-bordered ui-softwareChange-table__conatiner" id="sampleTable"> 
                                            <thead className='ui-softwareChange-head_title__container'>
                                                <tr className='ui-softwareChange-titles'>
                                                    <th style={{width: '58px'}}>Codigo</th>
                                                    <th style={{width: '350px'}}>Descripción</th>
                                                    <th style={{width: '4%'}}>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((health_code) => (
                                                    <tr key={health_code.id}>
                                                        <td className='ui-softwareChange-td_title'>{health_code.code}</td>
                                                        <td className='ui-softwareChange-td_description'>{ReactHtmlParser(health_code.description)}</td>

                                                        <td className='ui-softwareChange-td_options'>
                                                            <UncontrolledDropdown className='btn-group'>
                                                                <DropdownToggle className='btn-shadow btn btn-info'>
                                                                    <i className="fas fa-bars"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => edit(health_code)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Editar
                                                                        </DropdownItem>
                                                                    )}

                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => destroy(health_code.id)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Eliminar
                                                                        </DropdownItem>
                                                                    )}
                                                                            
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="card-header">
                                        <button
                                            onClick={() => toogleFile("new")}
                                            className="btn-shadow btn btn-info"
                                        >
                                            Subir archivo
                                        </button>
                                    </div>
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h4>No hay registros</h4>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <Preloader />
                    )}


                    {true && (
                        <div className="row mt-3">

                            <div className="col-md-2 text-left">
                                <p className="ml-4">
                                    Mostrando {data.length} de {totalData}
                                </p>
                            </div>

                            <div className="col-md-10 pl-0">
                                <Pagination
                                    hideNavigation
                                    activePage={activePage}
                                    itemsCountPerPage={countPage}
                                    itemClass="page-item"
                                    innerClass="pagination"
                                    linkClass="page-link"
                                    totalItemsCount={totalData}
                                    pageRangeDisplayed={countPage}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    )}

                </div>
            </React.Fragment>
         
        </React.Fragment>
    )
}


export default HealthCode;
WebpackerReact.setup({ HealthCode });
