import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-12">
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={this.props.formValues.name}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                        placeholder="Nombre del indicador"

                                    />
                                </div>

                                <div className="col-md-12 mt-3">
                                    <label>Descripción <small className="validate-label">(opcional)</small></label>
                                    <textarea
                                        type="text"
                                        name="description"
                                        value={this.props.formValues.description}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                        placeholder="Descripción del indicador"

                                    />
                                </div>

                                {this.props.type == "GER" && (
                                    <div className="col-md-6 mt-4">
                                        <label>Tipo de indicador</label>
                                        <select
                                            type="text"
                                            name="indicator_type_module"
                                            value={this.props.formValues.indicator_type_module}
                                            onChange={this.props.onChangeForm}
                                            disabled={this.props.modeEdit}
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.indicator_type_module == "" ? "error-class" : ""}`}
                                        >
                                            <option value="">Seccione un valor</option>
                                            <option value="gerencia_ventas">Ventas</option>
                                            <option value="gerencia_recaudo">Recaudo</option>
                                            <option value="gerencia_tickets">Ticket promedio</option>
                                            <option value="two_variables">Dos Variables</option>

                                        </select>
                                    </div>
                                )}


                                <div className="col-md-6 mt-4">
                                    <label>Año</label>
                                    <select
                                        type="month"
                                        name="actual_year"
                                        value={this.props.formValues.actual_year}
                                        onChange={this.props.onChangeForm}
                                        disabled={this.props.modeEdit}
                                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.actual_year == "" ? "error-class" : ""}`}
                                    >
                                        <option value="">Seleccione un año</option>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                    </select>
                                </div>


                                {((this.props.type == "GER" && this.props.formValues.indicator_type_module == "gerencia_tickets") || this.props.type == "SEGV") && (
                                    <div className="col-md-12 mt-4">
                                        <label>Variables</label>
                                        <textarea
                                            type="text"
                                            name="initial_variables"
                                            value={this.props.formValues.initial_variables}
                                            onChange={this.props.onChangeForm}
                                            style={{ resize: "none" }}
                                            disabled={this.props.modeEdit}
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.initial_variables == "" ? "error-class" : ""}`}


                                            rows="5"
                                        />
                                    </div>
                                )}

                                <div className="col-md-12">
                                    <div className="row">

                                        {(this.props.type != "GER" && this.props.type != "SEGV" || this.props.formValues.indicator_type_module == "two_variables" || this.props.formValues.indicator_type_module == "gerencia_ventas") && (
                                            <div className={`col-md-6 mt-4 ${this.props.formValues.has_fordata ? "mb-3" : ""}`}>
                                                <label>Variable 1</label>
                                                <input
                                                    type="text"
                                                    name="variable_1"
                                                    value={this.props.formValues.variable_1}
                                                    onChange={this.props.onChangeForm}
                                                    /* disabled={this.props.modeEdit} */
                                                    style={{ resize: "none" }}
                                                    className={`form form-control ${this.props.errorValues == false && this.props.formValues.variable_1 == "" ? "error-class" : ""}`}
                                                    placeholder="Variable 1"
                                                />

                                            </div>
                                        )}

                                        {(this.props.type != "GER" && this.props.type != "SEGV" || this.props.formValues.indicator_type_module == "two_variables" || this.props.formValues.indicator_type_module == "gerencia_ventas") && (
                                            <div className={`col-md-6 mt-4 ${this.props.formValues.has_fordata ? "mb-3" : ""}`}>
                                                <label>Variable 2</label>
                                                <input
                                                    type="text"
                                                    name="variable_2"
                                                    value={this.props.formValues.variable_2}
                                                    onChange={this.props.onChangeForm}
                                                    /*  disabled={this.props.modeEdit} */
                                                    style={{ resize: "none" }}
                                                    className={`form form-control ${this.props.errorValues == false && this.props.formValues.variable_2 == "" ? "error-class" : ""}`}
                                                    placeholder="Variable 2"
                                                />
                                            </div>
                                        )}

                                        {(this.props.type != "GER" && this.props.type != "SEGV" || this.props.formValues.indicator_type_module == "two_variables" || this.props.formValues.indicator_type_module == "gerencia_ventas") && (
                                            <React.Fragment>
                                                <div className={`col-md-6 mb-3`}>
                                                    <div className="custom-control custom-switch mt-2">
                                                        <input type="checkbox" onChange={(e) => this.props.handleChangeChecked(e)} className="custom-control-input" id={`customShowTask`} checked={this.props.formValues.has_fordata} />
                                                        <label className="custom-control-label" htmlFor={`customShowTask`}>¿Quieres seleccionar fuente de datos de la variable 1?</label>
                                                    </div>
                                                </div>

                                                <div className={`col-md-6 mb-3`}>
                                                    <div className="custom-control custom-switch mt-2">
                                                        <input type="checkbox" onChange={(e) => this.props.handleChangeSecondChecked(e)} className="custom-control-input" id={`customShowTaskSecond`} checked={this.props.formValues.has_fordata_second} />
                                                        <label className="custom-control-label" htmlFor={`customShowTaskSecond`}>¿Quieres seleccionar fuente de datos de la variable 2?</label>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}

                                        <div className="col-md-6">
                                            {this.props.formValues.has_fordata && (
                                                <React.Fragment>
                                                    <input
                                                        type="hidden"
                                                        name="first_custom_view_id"
                                                        value={this.props.selectedOptionFormatCustomView1.value}
                                                    />
                                                    <label>(Fuente de datos) variable 1</label>
                                                    <Select
                                                        onChange={this.props.handleChangeAutocompleteFormatCustomView1}
                                                        options={this.props.format_custom_views}
                                                        autoFocus={false}
                                                        className={`link-form ${!this.props.errorValues && this.props.formValues.first_custom_view_id == "" ? "error-class" : ""}`}
                                                        value={this.props.selectedOptionFormatCustomView1}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            {this.props.formValues.has_fordata_second && (
                                                <React.Fragment>
                                                    <input
                                                        type="hidden"
                                                        name="second_custom_view_id"
                                                        value={this.props.selectedOptionFormatCustomView2.value}
                                                    />
                                                    <label>(Fuente de datos) variable 2</label>
                                                    <Select
                                                        onChange={this.props.handleChangeAutocompleteFormatCustomView2}
                                                        options={this.props.format_custom_views}
                                                        autoFocus={false}
                                                        className={`link-form ${!this.props.errorValues && this.props.formValues.second_custom_view_id == "" ? "error-class" : ""}`}
                                                        value={this.props.selectedOptionFormatCustomView2}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className="col-md-6 mt-3 mb-3">
                                            {this.props.formValues.has_fordata && (
                                                <React.Fragment>
                                                    <input
                                                        type="hidden"
                                                        name="first_question_date_id"
                                                        value={this.props.selectedOptionFirstQuestionDate.value}
                                                    />
                                                    <label>Fecha variable 1</label>
                                                    <Select
                                                        onChange={this.props.handleChangeAutocompleteFirstQuestionDate}
                                                        options={this.props.questions_date1}
                                                        autoFocus={false}
                                                        className={`link-form ${!this.props.errorValues && this.props.formValues.first_question_date_id == "" ? "error-class" : ""}`}
                                                        value={this.props.selectedOptionFirstQuestionDate}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className="col-md-6 mt-3 mb-3">
                                            {this.props.formValues.has_fordata_second && (
                                                <React.Fragment>
                                                    <input
                                                        type="hidden"
                                                        name="second_question_date_id"
                                                        value={this.props.selectedOptionSecondQuestionDate.value}
                                                    />
                                                    <label>Fecha variable 2</label>
                                                    <Select
                                                        onChange={this.props.handleChangeAutocompleteSecondQuestionDate}
                                                        options={this.props.questions_date2}
                                                        autoFocus={false}
                                                        className={`link-form ${!this.props.errorValues && this.props.formValues.second_question_date_id == "" ? "error-class" : ""}`}
                                                        value={this.props.selectedOptionSecondQuestionDate}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            {this.props.formValues.has_fordata && (
                                                <React.Fragment>
                                                    <label>Tipo variable 1</label>
                                                    <select
                                                        name="first_type"
                                                        value={this.props.formValues.first_type}
                                                        onChange={(e) => this.props.handleChangeTaype(e, "variable1")}
                                                        className={`form form-control ${!this.props.errorValues && this.props.formValues.first_type == "" ? "error-class" : ""}`}
                                                    >
                                                        <option value="">Seleccione</option>
                                                        <option value="suma">Suma</option>
                                                        <option value="cantidad">Cantidad</option>
                                                    </select>
                                                </React.Fragment>
                                            )}
                                        </div>


                                        <div className="col-md-6">
                                            {this.props.formValues.has_fordata_second && (
                                                <React.Fragment>
                                                    <label>Tipo variable 2</label>
                                                    <select
                                                        name="second_type"
                                                        value={this.props.formValues.second_type}
                                                        onChange={(e) => this.props.handleChangeTaype(e, "variable2")}
                                                        className={`form form-control ${!this.props.errorValues && this.props.formValues.second_type == "" ? "error-class" : ""}`}
                                                    >
                                                        <option value="">Seleccione</option>
                                                        <option value="suma">Suma</option>
                                                        <option value="cantidad">Cantidad</option>
                                                    </select>
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            {this.props.formValues.has_fordata && (
                                                <React.Fragment>
                                                    <input
                                                        type="hidden"
                                                        name="first_question_value_id"
                                                        value={this.props.selectedOptionFirstQuestionValue.first_question_value_id}
                                                    />
                                                    <label>Valor variable 1 <small className="validate-label">(opcional)</small></label>
                                                    <Select
                                                        onChange={this.props.handleChangeAutocompleteFirstQuestionValue}
                                                        options={this.props.questions_number1}
                                                        autoFocus={false}
                                                        className={`link-form ${!this.props.errorValues && this.props.formValues.first_question_value_id == "" && this.props.formValues.first_type == "suma" ? "error-class" : ""}`}
                                                        value={this.props.selectedOptionFirstQuestionValue}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            {this.props.formValues.has_fordata_second && (
                                                <React.Fragment>
                                                    <input
                                                        type="hidden"
                                                        name="second_question_value_id"
                                                        value={this.props.selectedOptionSecondQuestionValue.second_question_value_id}
                                                    />
                                                    <label>Valor variable 2 <small className="validate-label">(opcional)</small></label>
                                                    <Select
                                                        onChange={this.props.handleChangeAutocompleteSecondQuestionValue}
                                                        options={this.props.questions_number2}
                                                        autoFocus={false}
                                                        className={`link-form ${!this.props.errorValues && this.props.formValues.second_question_value_id == "" && this.props.formValues.second_type == "suma" ? "error-class" : ""}`}
                                                        value={this.props.selectedOptionSecondQuestionValue}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className="col-md-12"> <hr/></div>
                                    </div>
                                </div>

                                {(this.props.type != "GER" || this.props.formValues.indicator_type_module == "two_variables") && (
                                    <React.Fragment>
                                        <div className="col-md-6 mt-4">
                                            <label>Tipo de valor <small className="validate-label">(opcional)</small></label><br />
                                            <select
                                                type="month"
                                                name="total_type"
                                                value={this.props.formValues.total_type}
                                                onChange={this.props.onChangeForm}
                                                disabled={this.props.modeEdit}
                                                className={`form form-control`}
                                            >
                                                <option value="">Seleccione</option>
                                                <option value="moneda">Moneda</option>
                                                <option value="numerico">Numerico</option>
                                            </select>

                                        </div>
                                    </React.Fragment>
                                )}


                                {/*<div className="col-md-6 mt-4">
                                        <label>Tipo de valor</label><br/>

                                        <div className="col-md-12">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="total_type"
                                                    checked={this.props.formValues.total_type == "Suma" ? true : false}
                                                    value={"Suma"}
                                                    className="mr-2"
                                                    onChange={this.props.onChangeForm}
                                                />
                                                Suma
                                            </label>
                                        </div>

                                        <div className="col-md-12">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="total_type"
                                                    checked={this.props.formValues.total_type == "Porcentaje" ? true : false}
                                                    value={"Porcentaje"}
                                                    className="mr-2"
                                                    onChange={this.props.onChangeForm}
                                                />
                                                Porcentaje
                                            </label>
                                        </div>
                                    </div>*/}

                                {this.props.type == "GER" && this.props.formValues.indicator_type_module == "gerencia_tickets" && (
                                    <div className="col-md-12 mt-4">
                                        <div className="alert alert-info" role="alert">
                                            <b><i className="fas fa-info-circle"></i> Debes de separar las variables por comas</b>
                                        </div>
                                    </div>
                                )}

                                {(this.props.type != "GER" || this.props.formValues.indicator_type_module == "two_variables") && (
                                    <React.Fragment>
                                        <div className="col-md-6 mt-4">
                                            <label>Tipo <small className="validate-label">(opcional)</small></label><br />
                                            <select
                                                type="month"
                                                name="table_type"
                                                value={this.props.formValues.table_type}
                                                onChange={this.props.onChangeForm}
                                                disabled={this.props.modeEdit}
                                                className={`form form-control`}
                                            >
                                                <option value="">Seleccione</option>
                                                <option value="diario">Diario</option>
                                                <option value="mensual">Mensual</option>
                                            </select>

                                        </div>
                                    </React.Fragment>
                                )}


                                <div className="col-md-6 mt-4">
                                    <input
                                        type="hidden"
                                        name="analyzed_position_id"
                                        value={this.props.selectedOptionPosition1.analyzed_position_id}
                                    />
                                    <label>Responsable</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocomplete1}
                                        options={this.props.position}
                                        autoFocus={false}
                                        className={`link-form ${!this.props.errorValues && this.props.formValues.analyzed_position_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionPosition1}
                                    />
                                </div>

                                {/*
                                <div className="col-md-6 mt-4">
                                    <input
                                        type="hidden"
                                        name="reviewed_position_id"
                                        value={this.props.selectedOptionPosition2.reviewed_position_id}
                                    />
                                    <label>Analiza</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocomplete2}
                                        options={this.props.position}
                                        autoFocus={false}
                                        className={`link-form ${this.props.errorValues == false && this.props.formValues.reviewed_position_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionPosition2}
                                    />
                                </div>

                                <div className="col-md-6 mt-4">
                                    <input
                                        type="hidden"
                                        name="approved_position_id"
                                        value={this.props.selectedOptionPosition3.approved_position_id}
                                    />
                                    <label>Aprueba</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocomplete3}
                                        options={this.props.position}
                                        autoFocus={false}
                                        className={`link-form ${this.props.errorValues == false && this.props.formValues.approved_position_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionPosition3}
                                    />
                                </div>
                                */}

                                {this.props.modeEdit && (
                                    <div className="col-md-6 mt-4">
                                        <input
                                            type="hidden"
                                            name="sub_category_indicator_id"
                                            value={this.props.selectedOptionSubCategory.sub_category_indicator_id}
                                        />
                                        <label>Cambiar sub categoría</label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteSubCategory}
                                            options={this.props.sub_category_indicators_select}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionSubCategory}
                                        />
                                    </div>
                                )}

                                {!this.props.errorValues && (
                                    <div className="col-md-12 mt-3">
                                        <div className="alert alert-danger text-center" role="alert">
                                            <b>Debes de completar todo los campos requeridos</b>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button
                                className="btn-shadow btn btn-info"
                                onClick={this.props.submitForm}
                                disabled={this.props.isLoaded ? false : true}
                            >
                                {this.props.isLoaded ? this.props.nameSubmit : "Cargando..."}
                            </button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;