import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter,  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            format_resposabilities: [],
            format_responsability_id: "",
            formResponsabilidades: {
                format_id: this.props.format.id,
                question_id: "",
                type_responsabilty: "",
                content_email: "",
            },

            formResponsabilidadesUpdate: {
                question_id: "",
                type_responsabilty: "",
                content_email: "",
            },

            modal: false,
            modeEditResponsabilidades: false,
        }
    }

    componentDidMount = () => {
        this.loadDataResposabilidades();
    }

    //responsablilidades

    HandleChangeResponsabilidades = (e) => {
        if (this.state.modeEditResponsabilidades) {
            this.setState({
                formResponsabilidadesUpdate: {
                    ...this.state.formResponsabilidadesUpdate,
                    [e.target.name]: e.target.value
                }
            });
        } else {
            this.setState({
                formResponsabilidades: {
                    ...this.state.formResponsabilidades,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    clearValuesResposabilidades = () => {
        this.setState({
            formResponsabilidades: {
                ...this.state.formResponsabilidades,
                question_id: "",
                type_responsabilty: "",
                content_email: "",
            },

            formResponsabilidadesUpdate: {
                question_id: "",
                type_responsabilty: "",
                content_email: "",
            },

            format_responsability_id: "",
        })
    }


    HandleChangeCkaditor = (e) => {
        if (this.state.modeEditResponsabilidades) {
            this.setState({
                formResponsabilidadesUpdate: {
                    ...this.state.formResponsabilidadesUpdate,
                    content_email: e
                }
            });
        } else {
            this.setState({
                formResponsabilidades: {
                    ...this.state.formResponsabilidades,
                    content_email: e
                }
            });
        }
    }

    //add items
    updateDataResponsabilidades = (data) => {
        this.setState({
            format_resposabilities: [...this.state.format_resposabilities, data],
            modalResponsabilidades: false,
            showTable: true,
        })
    }

    //add update
    updateItemResponsabilidades = data => {
        this.setState({
            format_resposabilities: this.state.format_resposabilities.map(item => {
                if (data.id === item.id) {
                    return {
                        ...item,
                        question: data.question,
                        type_responsabilty: data.type_responsabilty,
                        content_email: data.content_email,
                    }
                }
                return item;
            })
        });
    }

    toogle = (from) => {
        if(from == "new"){
            this.setState({ modal: true })
        }else{
            this.setState({ modal: false })
        }
    }

    HandleClickResponsabilidades = () => {
        if (this.state.modeEditResponsabilidades) {
            fetch(`/formatos/format_responsabilities/${this.state.format_responsability_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formResponsabilidadesUpdate), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateItemResponsabilidades(data.register)
                    this.clearValuesResposabilidades();
                    this.setState({ modal: false })
                });
        } else {
            fetch(`/formatos/format_responsabilities`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formResponsabilidades), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateDataResponsabilidades(data.register)
                    this.clearValuesResposabilidades();
                    this.setState({ modal: false })
                });
        }
    }

    loadDataResposabilidades = () => {
        fetch(`/formatos/get_format_resposabilities/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    format_resposabilities: data.data,
                });
            });
    }

    duplicateQuestion = (question) => {
        fetch(`/formatos/duplicate_question/${question.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.clearValues();
            this.messageSuccess(data)
        });
    }

    deleteResponsabilidades = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_responsabilities/${id}`, {
                    method: "DELETE",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.loadDataResposabilidades()
                    });
            }
        });
    };

    editarResponsabilidades = (responsabilidad) => {
        console.log("editResponsabilidadeseditResponsabilidades")
        this.setState({
            modal: true,
            modeEditResponsabilidades: true,
            format_responsability_id: responsabilidad.id,

            formResponsabilidadesUpdate: {
                question_id: responsabilidad.question_id,
                type_responsabilty: responsabilidad.type_responsabilty,
                content_email: responsabilidad.content_email,
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <ModalBody>

                        {this.state.modal && (
                            <FormCreate
                                backdrop={"static"}
                                modal={this.state.modal}
                                toggle={this.toogle}
                                title={this.state.modeEditResponsabilidades ? "Actualizar responsabilidad" : "Crear responsabilidad"}
                                nameBnt={this.state.modeEditResponsabilidades ? "Actualizar" : "Crear"}
        
                                //form props
                                formValues={this.state.modeEditResponsabilidades ? this.state.formResponsabilidadesUpdate : this.state.formResponsabilidades}
                                onChangeForm={this.HandleChangeResponsabilidades}
                                submitForm={this.HandleClickResponsabilidades}
                                questions_users={this.props.questions_users}
                                HandleChangeCkaditor={this.HandleChangeCkaditor}
                                modeEdit={this.state.modeEditResponsabilidades}
                            />
                        )}

                        <div className="col-md-12 mb-3 text-right pr-0">
                            {!this.state.modal && (
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.toogle("new")}
                                >
                                    Abrir formulario
                                </button>
                            )}
                        </div>

                        <div className="card">
                            <div className="background-gray">
                                <table className="table " id="sampleTable">
                                    <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                        <tr>
                                            <th>Pregunta</th>
                                            <th className="text-right">Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.format_resposabilities.length >= 1 ? (
                                            this.state.format_resposabilities.map((format_association, index) => (
                                                <React.Fragment>
                                                    {index >= 1 ?  <div className="mb-2"></div> : null}
                                                    <tr key={format_association.id} className="tr-table-task" style={{ borderLeft: `4px solid green` }}>
                                                        <td>{format_association.question}</td>
                                                        <td className="text-right">
                                                            {true && (
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn btn-info'>
                                                                    <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    className="dropdown-item"
                                                                                    onClick={() => this.editarResponsabilidades(format_association)}   
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {true && (  
                                                                                <DropdownItem
                                                                                    onClick={() => this.deleteResponsabilidades(format_association.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                           )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                                
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;