
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ChromePicker } from 'react-color'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import FormCreate from '../QuestionStyle/QuestionStyleConditional/FormCreate';
import FormCreateQuestionStyle from './FormCreate';
import Select from "react-select";

class ModalIndex extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            questions: [],

            users: [],
            procesos: [],
            cargos: [],

            isLoaded: true,

            /* ESTADOS DE QUESTION STYLE  */

            question_action_id: "",
        
            form: {
                question_id: this.props.question.id, 
                question_select_id: "",
                question_action: "",
            },

            formUpdate: {
                question_select_id: "",
                question_action: "",
            },

            selectedOptionQuestionSelectUpdate: {
                question_select_id: "",
                label: "Pregunta a comparar"
            },

            selectedOptionQuestionSelect: {
                question_select_id: "",
                label: "Pregunta a comparar",
            },

            ErrorValues: true,

            editValuesPosition: [],
            modeEditQuestionStyle: false,


            selectedOptionPositions: {
                position_ids: [],
                label: "Cargos"
            },

            selectedOptionPositionsUpdate: {
                position_ids: [],
                label: "Cargos"
            },

            /* ESTADOS DE QUESTION STYLE CONDITIONAL  */

            formCreateQuestionStyleConditional: {
                question_action_id: "", 
                conditional: "",
                question_id: "",
                value: "",
                operation: "",
            },

            selectedOption: {
                question_id: "",
                label: "Pregunta"
            },

            selectedOptionSelect: {
                value: "",
                label: "Modulo"
            },

            ErrorValuesQuestionStyleConditional: true,
            modeEditQuestionStyleConditional: false,
            question_style_conditional_id: "",
            question_style_conditional_length: 0,
            index_style_conditional: 1,

            currentOptions: [],
            type_question: this.props.question.question_type,
            options: (this.props.question.question_type == "answer_select" || this.props.question.question_type == "answer_radio" ? this.props.question.options : []),
            options_operation: (this.props.question.question_type == "answer_integer" || this.props.question.question_type == "answer_formula" || this.props.question.question_type == "answer_date" ? ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Entre: ejemplo 10 y 20"] : ["Contiene", "Igual", "Diferente"]),
        }
    }

    componentDidMount = () => {
        this.loadData();
        this.loadDataQuestions();
        this.configSelect();
    }

    configSelect = () => {
        let array = []
        let arrayCargos = []
        let arrayProcesos = []

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.props.procesos.map((item) => (
            arrayProcesos.push({label: item.name, value: item.id})
        ))

        this.props.cargos.map((item) => (
            arrayCargos.push({label: item.name, value: item.id})
        ))

        this.setState({
            users: array,
            procesos: arrayProcesos,
            cargos: arrayCargos
        }, () => {
            this.setState({
                currentOptions: this.selectOptions(this.props.question.question_type),
                selectedOptionSelect: {
                    value: "",
                    label: this.selectType(this.props.question.question_type),
                },
            })
        });
    }

    loadData = () => {
        fetch(`/formatos/get_question_actions/${this.props.question.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    loadDataQuestions = () => {
        fetch(`/formatos/get_questions/${this.props.formato.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            let array = [];

            data.register.map((item) => (
                array.push({ label: item.question, value: item.id, type: item.question_type, options_values: item.options })
            ))

            this.setState({
                questions: array
            })
        });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = question_action => {
        this.setState({
            data: this.state.data.map(item => {
                if (question_action.id === item.id) {
                    return { ...item, 
                        question_action: question_action.question_action, 
                        question_select: question_action.question_select
                    }
                }
                return item;
            })
        });
    }


    /* CODIGO PARA CREAR LAS QUESTION STYLE  */

    HandleChange = (e) => {
        if(this.state.question_action_id){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdateQuestionStyle,
                    [e.target.name]: e.target.value,
                }
            });
        }else{
            this.setState({
                form: {
                  ...this.state.form,
                  [e.target.name]: e.target.value
                }
            });
        }
    }

    HandleChangeQuestionStyle = (e) => {
        if(this.state.question_style_id){
            this.setState({
                formUpdateQuestionStyle: {
                    ...this.state.formUpdateQuestionStyle,
                    [e.target.name]: e.target.value,
                }
            });
        }else{
            this.setState({
                formCreateQuestionStyle: {
                    ...this.state.formCreateQuestionStyle,
                    [e.target.name]: e.target.value,
                }
            });
        }
    }

    validationForm = () => {
        if(this.state.question_action_id){
            if (this.state.formUpdate.question_select_id != "" && 
                this.state.formUpdate.question_action != ""
            ) {
                this.setState({ ErrorValues: true })
                return true
            }else{
                this.setState({ ErrorValues: false })
                return false
            }
        }else{
            if (this.state.form.question_select_id != "" && 
                this.state.form.question_action != ""
            ) {
                this.setState({ ErrorValues: true })
                return true
            }else{
                this.setState({ ErrorValues: false })
                return false
            }
        }
    }

    handleChangeAutocompleteQuestionSelect = selectedOptionQuestionSelect => {
        if(this.state.question_action_id){
            const selectedOptionQuestionSelectUpdate = selectedOptionQuestionSelect
            this.setState({
                selectedOptionQuestionSelectUpdate,
                    formUpdate: {
                    ...this.state.formUpdate,
                    question_select_id: selectedOptionQuestionSelectUpdate.value
                }
            });  
        }else{
            this.setState({
                selectedOptionQuestionSelect,
                    form: {
                    ...this.state.form,
                    question_select_id: selectedOptionQuestionSelect.value
                }
            });  
        }
    }

    clearValues = () => {
        this.setState({
            question_action_id: "",

            form: {
                ...this.state.form,
                question_select_id: "",
                question_action: "",
            },

            formUpdate: {
                question_select_id: "",
                question_action: "",
            },

            selectedOptionQuestionSelectUpdate: {
                question_select_id: "",
                label: "Pregunta a comparar"
            },

            selectedOptionQuestionSelect: {
                question_select_id: "",
                label: "Pregunta a comparar",
            },

            ErrorValues: true,
            modeEdit: false,
        })
    }

    HandleClick = () => {
        if(this.validationForm()){
            if (this.state.question_action_id) {
                fetch(`/formatos/question_actions/${this.state.question_action_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.updateItem(data.register)
                    this.messageSuccess(data);
                });
            }else{
                fetch(`/formatos/question_actions`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.updateData(data.register)
                    this.messageSuccess(data);
                });
            }
        }
    }


    delete = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_actions/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.setState({ data: this.state.data.filter((e) => e.id != id ) })
                    this.messageSuccess(response)
                });
            }
        });
    };

    edit = (question_action) => {
        this.setState({
            question_action_id: question_action.id,
            modeEdit: true,

            formUpdate: {
                ...this.state.formUpdate,
                question_select_id: question_action.question_select.id,
                question_action: question_action.question_action,
            },

            selectedOptionQuestionSelectUpdate: {
                question_select_id: question_action.question_select.id,
                label: question_action.question_select.question
            },
        })
    }
    

    /* CODIGO PARA CREAR LAS QUESTION STYLE CONDITIONAL  */

    HandleChangeQuestionStyleConditional = (e) => {
        this.setState({
            formCreateQuestionStyleConditional: {
                ...this.state.formCreateQuestionStyleConditional,
                [e.target.name]: e.target.value,
            }
        });
    }

    validationFormQuestionStyleConditional = () => {
        if (this.state.formCreateQuestionStyleConditional.value != "" && 
            this.state.formCreateQuestionStyleConditional.operator != ""
        ) {
            this.setState({ ErrorValuesQuestionStyleConditional: true })
            return true
        }else{
            this.setState({ ErrorValuesQuestionStyleConditional: false })
            return false
        }
    }

    clearValuesQuestionStyleConditional = () => {


        let  options_operation = []
        if ( this.props.question.question_type == "answer_integer" || this.props.question.question_type == "answer_formula"){
            options_operation = ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual"]
        }
        else if (this.props.question.question_type == "answer_date"){
            options_operation = ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Mayor que hoy", "Menor que hoy", "Igual a hoy"]
        }else{
            options_operation = ["Contiene", "Igual", "Diferente"]
        }

        
        this.setState({
            ErrorValuesQuestionStyleConditional: true,
            modeEditQuestionStyleConditional: false,
            index_style_conditional: 1,

            showPikerFirstBackgroundColorEdit: false,
            showPikerFirstColorEdit: false,

            question_type: "",

            formCreateQuestionStyleConditional: {
                question_action_id: "", 
                question_id: "",
                conditional: "",
                value: "",
                operation: "",
            },

            selectedOption: {
                question_id: "",
                label: "Pregunta"
            },

            selectedOptionSelect: {
                value: "",
                label: this.selectType(this.props.question.question_type),
            },

            
            type_question: this.props.question.question_type,
            options: (this.props.question.question_type == "answer_select" || this.props.question.question_type == "answer_radio" ? this.props.question.options : []),
            options_operation: options_operation,
            
            currentOptions: this.selectOptions(this.props.question.question_type),
        })
    }


    handleChangeAutocomplete = selectedOption => {
        let  options_operation = []
        if ( selectedOption.type == "answer_integer" || selectedOption.type == "answer_formula"){
            options_operation = ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual"]
        }
        else if (selectedOption.type == "answer_date"){
            options_operation = ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Mayor que hoy", "Menor que hoy", "Igual a hoy"]
        }else{
            options_operation = ["Contiene", "Igual", "Diferente"]
        }
        this.setState({
            selectedOption,
                formCreateQuestionStyleConditional: {
                    ...this.state.formCreateQuestionStyleConditional,
                    question_id: selectedOption.value,
                    value: "",
                },

                selectedOptionSelect: {
                    value: "",
                    label: this.selectType(selectedOption.type),
                },

                currentOptions: this.selectOptions(selectedOption.type),
                type_question: selectedOption.type,

                options: (selectedOption.type == "answer_select" || selectedOption.type == "answer_radio" ? selectedOption.options_values : []),
                options_operation: options_operation,
        });
    };

    selectOptions = (type_question) => {
        if(type_question == "answer_user_id"){
            return this.state.users
        }else if(type_question == "proceso_id"){
            return this.state.procesos
        }else if(type_question == "position_id"){
            return this.state.cargos
        }
    }

    selectType = (type_question) => {
        if(type_question == "answer_user_id"){
            return "Seleccione un usuario"
        }else if(type_question == "proceso_id"){
            return "Seleccione un proceso"
        }else if(type_question == "position_id"){
            return "Seleccione un cargo"
        }
    }


    handleChangeAutocompleteSelect = selectedOptionSelect => {
        this.setState({
            selectedOptionSelect,
                formCreateQuestionStyleConditional: {
                    ...this.state.formCreateQuestionStyleConditional,
                    value: selectedOptionSelect.value
                },
        });
    };

    editQuestionStyleConditional = (index, question_action, question_style_conditional) => {
        this.setState({
            index_style_conditional: index,
            question_style_conditional_length: question_action.question_style_conditionals.length,
            ErrorValuesQuestionStyleConditional: true,
            modeEditQuestionStyleConditional: true,
            question_style_conditional_id: question_style_conditional.id,

            formCreateQuestionStyleConditional: {
                question_style_id: question_style_conditional.question_style_id, 
                conditional: question_style_conditional.conditional, 
                value: question_style_conditional.value, 
                operation: question_style_conditional.operation, 
            },

            selectedOption: {
                question_id: question_style_conditional.question ? question_style_conditional.question.id : "",
                label: question_style_conditional.question ? question_style_conditional.question.question : "",
            },

            options: question_style_conditional.question ? (question_style_conditional.question.question_type == "answer_select" || question_style_conditional.question.question_type == "answer_radio" ? question_style_conditional.question.options : []) : [],
            options_operation: question_style_conditional.question ? (question_style_conditional.question.question_type == "answer_integer" || question_style_conditional.question.question_type == "answer_formula" || question_style_conditional.question.question_type == "answer_date" ? ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Entre: ejemplo 10 y 20"] : ["Contiene", "Igual", "Diferente"]) : ["Contiene", "Igual", "Diferente"],
        })
    }

    HandleClickQuestionStyleConditional = () => {
        if(this.validationFormQuestionStyleConditional()){
            if (this.state.modeEditQuestionStyleConditional) {
                fetch(`/formatos/question_style_conditionals/${this.state.question_style_conditional_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formCreateQuestionStyleConditional), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValuesQuestionStyleConditional();
                    this.updateItemQuestionStyleConditional(data.register)
                    this.messageSuccess(data);
                });
            }else{
                fetch(`/formatos/question_style_conditionals`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreateQuestionStyleConditional), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValuesQuestionStyleConditional();
                    this.updateDataQuestionStyleConditional(data.register)
                    this.messageSuccess(data);
                });
            }
        }
    }

    updateDataQuestionStyleConditional = (register) => {
        this.setState({
            data: this.state.data.map(item => {
                if (register.question_action.id === item.id) {
                    return {
                        ...item,
                        background_color: item.background_color,
                        color: item.color,
                        question_style_conditionals: [...item.question_style_conditionals, register],
                    }
                }
                return item;
            })
        });
    }


    updateItemQuestionStyleConditional = (register) => {
        this.setState({
            data: this.state.data.map(item => {
                if (register.question_action.id === item.id) {
                    return {
                        ...item,
                        background_color: item.background_color,
                        color: item.color,
                        question_style_conditionals: item.question_style_conditionals.map(formato => {
                            if (register.id === formato.id) {
                                return {
                                    ...formato,
                                    conditional: register.conditional,
                                    operation: register.operation,
                                    question_style_id: register.question_style_id,
                                    value: register.value,
                                    question: register.question,
                                }
                            }
                            return formato;
                        }),
                    }
                }
                return item;
            })
        });
    }

    createConditional = (question_style) => {
        this.setState({
            question_style_conditional_length: question_style.question_style_conditionals.length,

            formCreateQuestionStyleConditional: {
                ...this.state.formCreateQuestionStyleConditional,
                question_action_id: question_style.id, 
                conditional: "",
                question_id: "",
                value: "",
                operation: "",
            },
        })
    }

    deleteQuestionStyleConditional = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_style_conditionals/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };

    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>
                        <div className='ui-modals-header-title__container'>
                            <span className='ui-titles_modals'>
                                <i className="app-menu__icon fa fa-user mr-2"></i>
                                {this.props.title}
                            </span>
                            <span className='ui-name-question-modals ui-icon-in-title'>Pregunta {this.props.question.question}</span> 
                        </div>
                    </ModalHeader>

                    <ModalBody>
                        <div className="card mb-2 mb-3">
                            <div className="card-body p-2">
                                <div className="row"> 

                                    <div className={`col-md-6`}>
                                        <input
                                            type="hidden"
                                            name="question_select_id"
                                            value={this.state.selectedOptionQuestionSelect.question_select_id}
                                        />
                                        <Select
                                            onChange={this.handleChangeAutocompleteQuestionSelect}
                                            options={this.state.questions}
                                            autoFocus={false}
                                            className={`link-form ${!this.state.ErrorValues && !this.state.question_style_id && this.state.form.question_select_id == "" ? "error-class" : ""}`}
                                            value={this.state.selectedOptionQuestionSelect}
                                        />
                                    </div>


                                    <div className={`col-md-6`}>
                                        <select 
                                            name="question_action" 
                                            value={this.state.form.question_action}
                                            onChange={this.HandleChange}
                                            className={`form form-control ${!this.state.ErrorValues && !this.state.question_style_id && this.state.form.question_action == "" ? "error-class" : ""}`}
                                        >
                                            <option value="">Seleccione</option>
                                            <option value="esconder">Esconder</option>
                                        
                                        </select>
                                    </div>

                                </div>
                            </div>

                            <div className="card-footer">
                                <button
                                    className="btn-shadow btn btn-info mr-2"
                                    onClick={() => this.HandleClick()}
                                >
                                    Guardar
                                </button>

                                <button
                                    className="btn btn-outline-danger"
                                    onClick={() => this.clearValues()}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>

                        {this.state.data.length >= 1 ? (
                            <React.Fragment>
                                {this.state.data.map((question_action, index) => (
                                    <React.Fragment>
                                    <div className={`card ${index >= 0 ? "mb-2" : ""}`}>
                                        <div className="card-header">
                                            <div className="col-md-4 pl-0 ui-predicate-text">
                                                {question_action.question_select.question}
                                            </div> 

                                            <div className="col-md-5 text-center ui-style-register"> 

                                            </div>

                                            <div className="col-md-5">
                                                <button
                                                    className="btn-shadow btn btn-info"
                                                    onClick={() => this.createConditional(question_action)}
                                                    id={`PopoverCreate${question_action.id}`}
                                                >
                                                    Crear condición
                                                </button>

                                                <i className="fas fa-pencil-alt btn" id={`PopoverCreateQuestionStyle${question_action.id}`} style={{ color: "#16aaff" }} onClick={() => this.edit(question_action)}></i>
                                                <i className="fas fa-trash-alt btn" style={{ color: "#16aaff" }} onClick={() => this.delete(question_action.id)}></i>
                                                
                                                <UncontrolledPopover isOpen={this.state.question_action_id == question_action.id} placement="bottom" target={`PopoverCreateQuestionStyle${question_action.id}`}>
                                                    <PopoverBody>
                                                        <div className="col-md-12 p-0" style={{ width: "292px", height: "auto" }}>
                                                            <FormCreateQuestionStyle 
                                                                formValues={this.state.formUpdate}
                                                                handleClick={this.HandleClick}
                                                                onChangeForm={this.HandleChange}
                                                                modeEdit={this.state.modeEdit}

                                                                clearValues={this.clearValues}
                                                                errorValues={this.state.ErrorValues}
                                                                question={this.props.question}

                                                                showPikerFirstColorEdit={this.state.showPikerFirstColorEdit}
                                                                showPikerFirstBackgroundColorEdit={this.state.showPikerFirstBackgroundColorEdit}

                                                                handleChangeFirstColor={this.handleChangeFirstColor}
                                                                handleChangeBackgroundColor={this.handleChangeBackgroundColor}
                                                                
                                                                handleCloseFirstColor={this.handleCloseFirstColor}
                                                                handleCloseFirstBackgroundColor={this.handleCloseFirstBackgroundColor}

                                                                handleClickFirstColor={this.handleClickFirstColor}
                                                                handleClickFirstBackgroundColor={this.handleClickFirstBackgroundColor}

                                                                //send_email values

                                                                selectedOptionPositions={this.state.selectedOptionPositionsUpdate}
                                                                handleChangeAutocompletePositions={this.handleChangeAutocompletePositions}
                                                                cargos={this.state.cargos}

                                                                selectedOptionQuestionSelect={this.state.selectedOptionQuestionSelectUpdate}
                                                                handleChangeAutocompleteQuestionSelect={this.handleChangeAutocompleteQuestionSelect}
                                                                questions={this.state.questions}

                                                                HandleChangeCkaditor={this.HandleChangeCkaditor}
                                                                editValues={this.state.editValuesPosition}
                                                                handleChangeCheckBox={this.handleChangeCheckBox}
                                                            />
                                                        </div>
                                                    </PopoverBody>
                                                </UncontrolledPopover>

                                                <UncontrolledPopover isOpen={this.state.formCreateQuestionStyleConditional.question_action_id == question_action.id && !this.state.modeEditQuestionStyleConditional} placement="bottom" target={`PopoverCreate${question_action.id}`}>
                                                    <PopoverBody>
                                                        <div className="col-md-12 p-0" style={{ width: "292px", height: "auto" }}>
                                                            <FormCreate 
                                                                formValues={this.state.formCreateQuestionStyleConditional}
                                                                handleClick={this.HandleClickQuestionStyleConditional}
                                                                onChangeForm={this.HandleChangeQuestionStyleConditional}
                                                                modeEdit={this.state.modeEditQuestionStyleConditional}

                                                                clearValues={this.clearValuesQuestionStyleConditional}
                                                                errorValues={this.state.ErrorValuesQuestionStyleConditional}
                                                                question={this.props.question}

                                                                options={this.state.options}
                                                                options_operation={this.state.options_operation}
                                                                type_question={this.state.type_question}
                                                                question_style_conditional_length={this.state.question_style_conditional_length}
                                                                index_style_conditional={this.state.index_style_conditional}

                                                                //select 

                                                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                                selectedOption={this.state.selectedOption}
                                                                questions={this.state.questions}

                                                                selectedOptionSelect={this.state.selectedOptionSelect}
                                                                handleChangeAutocompleteSelect={this.handleChangeAutocompleteSelect}
                                                                values={this.state.currentOptions}
                                                            />
                                                        </div>
                                                    </PopoverBody>
                                                </UncontrolledPopover>

                                            </div>

                                        </div>

                                        <div className="card-body p-0">
                                            <div className="scroll-area-sm" style={{height:"auto"}}>
                                                <div className="scrollbar-container" style={{ height: question_action.question_style_conditionals.length > 3 ? "auto" : "auto" , background: "lightgrey", padding: "10px"}}>
                                                    {question_action.question_style_conditionals.map((question_style_conditional, index) => (
                                                        <div
                                                            className={`card mb-2 ${index == 0 ? "mt-2" : ""}`}
                                                        >
                                                            <div className="card-body p-2">
                                                                <div className="row">
                                                                    <div className="col-md-1 ui-text-conditional">
                                                                        {index >= 1 && (
                                                                            <React.Fragment>
                                                                                {question_style_conditional.conditional} 
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>

                                                                    <div className="col-md-5 ui-text-codicional-info">
                                                                        {this.props.question.question_type == "dynamic_field" && question_style_conditional.question ? question_style_conditional.question.question : this.props.question.question}
                                                                    </div>

                                                                    <div className="col-md-2 ui-text-codicional-info">
                                                                        {question_style_conditional.operation}
                                                                    </div>

                                                                    <div className="col-md-2 ui-text-codicional-info">
                                                                        {question_style_conditional.value} 
                                                                    </div>

                                                                    <div className="col-md-2">
                                                                        {true && (
                                                                            <i className="far fa-trash-alt float-right btn"  onClick={(e) => this.deleteQuestionStyleConditional(question_style_conditional.id)} id={`PopoverCreate${question_style_conditional.id}`}></i>
                                                                        )}

                                                                        {true && (
                                                                            <i className="fas fa-pencil-alt float-right btn" onClick={(e) => this.editQuestionStyleConditional(index, question_action, question_style_conditional)}></i>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <UncontrolledPopover isOpen={this.state.question_style_conditional_id == question_style_conditional.id && this.state.modeEditQuestionStyleConditional} placement="bottom" target={`PopoverCreate${question_style_conditional.id}`}>
                                                                    <PopoverBody>
                                                                        <div className="col-md-12 p-0" style={{ width: "292px", height: "auto" }}>
                                                                            <FormCreate 
                                                                                formValues={this.state.formCreateQuestionStyleConditional}
                                                                                handleClick={this.HandleClickQuestionStyleConditional}
                                                                                onChangeForm={this.HandleChangeQuestionStyleConditional}
                                                                                modeEdit={this.state.modeEditQuestionStyleConditional}

                                                                                clearValues={this.clearValuesQuestionStyleConditional}
                                                                                errorValues={this.state.ErrorValuesQuestionStyleConditional}
                                                                                question={this.props.question}

                                                                                options={this.state.options}
                                                                                options_operation={this.state.options_operation}
                                                                                type_question={this.state.type_question}     
                                                                                question_style_conditional_length={this.state.question_style_conditional_length}           
                                                                                index_style_conditional={this.state.index_style_conditional}

                                                                                //select 

                                                                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                                                selectedOption={this.state.selectedOption}
                                                                                questions={this.state.questions}

                                                                                selectedOptionSelect={this.state.selectedOptionSelect}
                                                                                handleChangeAutocompleteSelect={this.handleChangeAutocompleteSelect}
                                                                                values={this.state.currentOptions}
                                                                            />
                                                                        </div>
                                                                    </PopoverBody>
                                                                </UncontrolledPopover>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   <hr/>
                                   </React.Fragment>
                                ))}
                            </React.Fragment>
                        ) : (
                            <div className="card">
                                <div className="card-body text-center">
                                    <h4>No hay registros</h4>
                                </div>
                            </div>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalIndex;
