import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import { isMobile } from 'react-device-detect';

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-3 ui-formCreate-questionSearchValueFilter__container">
                        <div className={`col-md-4 ${isMobile ? (''): ('mb-3')}`}>
                            {this.props.format_questions.length >= 1 && (
                                <React.Fragment>
                                    <input
                                        type="hidden"
                                        name="question_compare_source_id"
                                        value={this.props.selectedOptionQuestionCompare.question_compare_source_id}
                                    />
                                    <label>Pregunta para comparar (fuente)</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteQuestions}
                                        options={this.props.format_questions}
                                        autoFocus={false}
                                        className={`link-form ${!this.props.errorValues && this.props.formValues.question_compare_source_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionQuestion}
                                    />
                                </React.Fragment>
                            )}
                        </div>

                        <div className="col-md-4 ui-formCreate-questionSearchValueFilter_operator__container">
                            <label>Operación</label>
                            <select
                                name="operator"
                                value={this.props.formValues.operator}
                                onChange={this.props.onChangeForm}
                                className={`ui-formCreate-questionSearchValueFilter_select-layout form-control ${!this.props.errorValues && this.props.formValues.operator == "" ? "error-class" : ""}`}
                            >
                                <option value="">Seleccione un valor</option>
                                <option value="igual">Igual</option>
                                <option value="mayor_igual">Mayor e igual</option>
                                <option value="menor_igual">Menor e igual</option>
                                <option value="menor">Menor</option>
                            </select>
                        </div>

                        <div className="col-md-4 mb-3">
                            <input
                                type="hidden"
                                name="question_asociation_id"
                                value={this.props.selectedOptionQuestion.question_source_id}
                            />
                            <label>Pregunta para comparar (Objetivo)</label>
                            <Select

                                onChange={this.props.handleChangeAutocompleteQuestionCompare}
                                options={this.props.questions}

                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.question_source_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOptionQuestionCompare}
                            />
                        </div>


                        {!this.props.errorValues && (
                            <div className="col-md-12">
                                <div className="alert alert-warning text-center" role="alert">
                                    <b>Debes de completar todos los datos requeridos</b>
                                </div>
                            </div>
                        )}

                        <div className="col-md-12 text-right">
                            <button
                                className="btn btn-outline-danger mr-2"
                                onClick={() => this.props.toggle()}
                            >
                                Cancelar
                            </button>

                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.props.submitForm()}
                            >
                                {this.props.nameBnt}
                            </button>
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;