import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

class TableIndexWeb extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th>Proceso</th>
                                    <th>Fecha de análisis</th>
                                    <th>Responsable</th>
                                    {(this.props.estados.editar || this.props.estados.eliminar) && (
                                        <th className="text-center"></th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(risk_matrix => (
                                        <tr key={risk_matrix.id}>
                                            <td>{risk_matrix.proceso}</td>
                                            <td>{risk_matrix.date_of_analysis}</td>
                                            <td>{risk_matrix.responsable != undefined ?risk_matrix.responsable.first_name : ""} {risk_matrix.responsable != undefined ? risk_matrix.responsable.first_last_name : ""}</td>

                                            {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                    <UncontrolledDropdown className='btn-group'>
                                                        <DropdownToggle className='btn btn-info'>
                                                            <i className="fas fa-bars"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                            {this.props.estados.gestionar && (
                                                                <DropdownItem
                                                                    href={`/risk/matrices/${risk_matrix.id}/risks`}
                                                                    data-turbolinks="true"
                                                                    className="dropdown-item"
                                                                >
                                                                    Gestionar
                                                                </DropdownItem>
                                                            )}

                                                            {this.props.estados.editar && (
                                                                <DropdownItem
                                                                    onClick={() => this.props.edit(risk_matrix)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Editar
                                                                </DropdownItem>
                                                            )}

                                                            {this.props.estados.eliminar && (
                                                                <DropdownItem
                                                                    onClick={() => this.props.delete(risk_matrix.id)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Eliminar
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="text-center mt-4 mb-4">
                                                    <h4>No hay registros</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TableIndexWeb;