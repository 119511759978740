import React, { Component } from 'react';
import Select from "react-select";

class FormFilter extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }

    render() {
        
        return (
            <React.Fragment>
                <div className="card mb-5">
                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="row">

                                
                            </div>
                        </div>

                        <div className="card-footer">
                            <button
                                className="btn-shadow btn btn-info  mr-3"
                                onClick={() => this.HandleClickFilter()}
                            >
                                Aplicar Filtro
                            </button>

                            <a
                                className="btn btn-outline-danger"
                                onClick={() => this.props.handleClickCancel()}
                            >
                                Cancelar
                            </a>

                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default FormFilter;