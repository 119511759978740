import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";

class ModalActions extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
    }
    
    delete = (from) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: (from == "all" ? "Recuerda que se eliminara el tablero y las tareas" : "Solo se eliminara el tablero") ,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/task/delete_task_board/${this.props.task_board.id}/${from}`, {
                    method: "post",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.props.messageSuccess(response);
                    this.props.loadData();
                    this.props.toggle();
                });
            }
        });
    };

    render() {
        const style = {
            background: "white",
            borderBottom: "initial",
            padding: "5px 5px 0px 0px",
            height: "10px"
        };

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12 text-center mb-3">
                                <h4>¿Qué acción quiere tomar para eliminar?</h4>
                            </div>

                            <div className="col-md-12 text-center p-0">
                                <button
                                    className="btn-shadow btn btn-danger"
                                    onClick={() => this.delete("board")}
                                >
                                    Solo el tablero
                                </button>

                                <button
                                    className="btn-shadow btn btn-danger ml-2 mr-2"
                                    onClick={() => this.delete("all")}
                                >
                                    {"Todo (incluye las tareas que tenga el tablero)"}
                                </button>

                                <button
                                    className="btn btn-shadow btn-outline-danger"
                                    onClick={() => this.props.toggle()}
                                    style={{marginTop: '1.3rem'}}
                                >
                                    Cancelar
                                </button>                            
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalActions;

