import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        const dataCharts = [
            { id: 1, chart_type: "linea", name: "Linea", url: "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/47656/Linea-Apex-1.jpg" },
            { id: 2, chart_type: "linea_mensual", name: "Linea mensual", url: "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/48947/apexcharts-line.jpg" },
            { id: 3, chart_type: "bar", name: "Barras", url: "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/48948/apexcharts-bar.jpg" },

            { id: 4, chart_type: "bar_mensual", name: "Barras mensual", url: "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/47637/Barras-Apex-2.jpg" },
            { id: 5, chart_type: "pie", name: "Pie", url: "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/47638/apexcharts-Pie-2.jpg" },
            { id: 6, chart_type: "donut", name: "Dona", url: "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/48946/apexcharts-Donuts-1.jpg" }
        ]

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-6">
                                    <h4 className="title-type-chart text-center">Tipo de gráfica</h4>
                                    <div className="col-md-12 mt-3 mb-4 bw-title-type__chart-container">
                                        <div className="row">

                                            <div className="col-md-12 text-center">
                                            </div>
                                            <div className='ui-form-create-customCharts__container-charts'>
                                                {dataCharts.map((chart, index) => (
                                                    <div
                                                        className={`ui-form-create-customCharts-charts__container ${!this.props.errorValues && this.props.formValues.chart_type == "" ? "error-class" : ""}`} key={chart.id}
                                                        onClick={() => this.props.selectChartType(chart.chart_type)}
                                                        style={{ border: `2px solid ${this.props.formValues.chart_type == chart.chart_type ? "#1aa9fb" : "#f5f5f5"}` }}
                                                    >
                                                        <img
                                                            src={chart.url}
                                                            className="ui-form-create-customCharts-charts-img mb-2"
                                                        />
                                                        <span>{chart.name}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-6 mt-3 mb-4">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h4 className="title-type-chart">Datos</h4>
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label>Nombre</label>
                                            <input
                                                type="text"
                                                name="name"
                                                className={`form form-control ${!this.props.errorValues && this.props.formValues.name == "" ? "error-class" : ""}`}
                                                value={this.props.formValues.name}
                                                onChange={this.props.HandleChange}
                                            />
                                        </div>



                                        {(this.props.formValues.chart_type == "pie" || this.props.formValues.chart_type == "donut") && (
                                            <div className="col-md-12 mb-3">
                                                <label>Campo a mostrar</label>
                                                <input
                                                    type="hidden"
                                                    name="question_one_variable_id"
                                                    value={this.props.selectOptionQuestionOneVariable.question_one_variable_id}
                                                />

                                                <Select
                                                    onChange={this.props.handleChangeAutocompleteQuestionOneVariable}
                                                    options={this.props.questions}
                                                    autoFocus={false}
                                                    className={`link-form ${!this.props.errorValues && this.props.formValues.question_one_variable_id == "" ? "error-class" : ""}`}
                                                    placeholder="Pregunta para comparar"
                                                    value={this.props.selectOptionQuestionOneVariable}
                                                />
                                            </div>
                                        )}

                                        {(this.props.formValues.chart_type == "linea_mensual" || this.props.formValues.chart_type == "bar_mensual") && (
                                            <div className="col-md-12 mb-3">
                                                <input
                                                    type="hidden"
                                                    name="question_date_id"
                                                    value={this.props.selectedOptionQuestionDate.question_date_id}
                                                />
                                                <label>Eje x (Fecha)</label>
                                                <Select
                                                    onChange={this.props.handleChangeAutocompleteQuestionDate}
                                                    options={this.props.questions} // this.props.questions_date
                                                    autoFocus={false}
                                                    className={`link-form ${!this.props.errorValues && this.props.formValues.question_date_id == "" ? "error-class" : ""}`}
                                                    value={this.props.selectedOptionQuestionDate}
                                                />
                                            </div>
                                        )}

                                        {(this.props.formValues.chart_type == "bar" || this.props.formValues.chart_type == "linea") && (
                                            <div className="col-md-12 mb-3">
                                                <input
                                                    type="hidden"
                                                    name="question_x_id"
                                                    value={this.props.selectedOptionQuestionX.question_x_id}
                                                />
                                                <label>Eje x </label>
                                                <Select
                                                    onChange={this.props.handleChangeAutocompleteQuestionX}
                                                    options={this.props.questions} // this.props.questions_number
                                                    autoFocus={false}
                                                    className={`link-form ${!this.props.errorValues && this.props.formValues.question_x_id == "" ? "error-class" : ""}`}
                                                    value={this.props.selectedOptionQuestionX}
                                                />
                                            </div>
                                        )}

                                        {(this.props.formValues.chart_type == "bar" || this.props.formValues.chart_type == "linea_mensual" || this.props.formValues.chart_type == "bar_mensual" || this.props.formValues.chart_type == "linea") && (
                                            <div className={`col-md-12 mb-3`}>
                                                <label>Eje y</label>
                                                <input
                                                    type="hidden"
                                                    name="question_ids"
                                                    value={this.props.selectedOption.question_ids}
                                                />
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    autoFocus={false}
                                                    className={`link-form ${!this.props.errorValues && this.props.formValues.question_ids.length >= 1 == "" ? "error-class" : ""}`}
                                                    classNamePrefix="select"
                                                    name="question_ids"
                                                    onChange={this.props.handleChangeAutocomplete}
                                                    options={this.props.questions}
                                                    defaultValue={this.props.editValues}
                                                />
                                            </div>
                                        )}
                                        <div className="col-md-12 mb-3">
                                            <label>Tipo de resultado</label>
                                            <select
                                                name="result_type"
                                                className={`form form-control ${!this.props.errorValues && this.props.formValues.result_type == "" ? "error-class" : ""}`}
                                                value={this.props.formValues.result_type}
                                                onChange={this.props.HandleChange}
                                            >
                                                <option value="">Seleccione una opción</option>
                                                <option value="suma">Suma</option>
                                                <option value="cantidad">Cantidad</option>
                                            </select>
                                        </div>

                                        {((this.props.formValues.chart_type == "pie" || this.props.formValues.chart_type == "donut") && this.props.formValues.result_type == "suma") && (
                                            <div className="col-md-12 mb-3">
                                                <input
                                                    type="hidden"
                                                    name="question_x_id"
                                                    value={this.props.selectedOptionQuestionX.question_x_id}
                                                />
                                                <label>Pregunta(Valor) </label>
                                                <Select
                                                    onChange={this.props.handleChangeAutocompleteQuestionX}
                                                    options={this.props.questions.filter(question => question.type === "answer_integer" || question.type === "answer_formula")} // this.props.questions_number
                                                    autoFocus={false}
                                                    className={`link-form ${!this.props.errorValues && this.props.formValues.question_x_id == "" ? "error-class" : ""}`}
                                                    value={this.props.selectedOptionQuestionX}
                                                />
                                            </div>
                                        )}


                                    </div>
                                </div>

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={this.props.handleClick}>{this.props.nameBnt}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;
