import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import GoogleDocsViewer from 'react-google-docs-viewer';

class BasicTutorialShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Tutorial: ${this.props.tutorial.name}`}
                    subTitle={"Centro de ayudas"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-info-circle icon-gradient bg-mean-fruit"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />
                <div className="ui-basicTutorialShow__container__all mb-3">
                    

                    <div className="ui-basicTutorialShow__container-card-left card" >
                        <div className="card-header ui-basicTutorialShow__container-header">
                            <a href="/settings/tutorials" data-turbolinks="true" className="btn btn-secondary-info mr-3">Volver</a>
                        </div>
                        <div className="card-body ui-basicTutorialShow__container-body">
                            
                                <div className="col-md-12 text-center ui-basicTutorialShow_containerfiles">
                                    {this.props.tutorial.tutorial_type == "file" ? (
                                        <GoogleDocsViewer
                                            width={isMobile ? ('100%'): ('900px')}
                                            height="780px"
                                            fileUrl={this.props.tutorial.file.url}
                                        />
                                    ) : (
                                        <iframe width="750" height="500" src={this.props.tutorial.video_link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    )}
                                </div>

                                <div className="col-md-12 text-center ui-basicTutorialShow_container_videos mt-2 mb-2">
                                    <div className="ui-basicTutorialShow_row row">
                                        {/* <div className="col-md-12 ui-basicTutorialShow__container_visual_videos">
                                            <iframe src={"https://www.youtube.com/embed/" + ''
                                            // this.props.video.video_url
                                            } style={{ width: "253px", height: "142px", margin: '0.5rem'}} allowfullscreen></iframe>

                                            <iframe src={"https://www.youtube.com/embed/" + ''
                                            // this.props.video.video_url
                                            } style={{ width: "253px", height: "142px", margin: '0.5rem'}} allowfullscreen></iframe>

                                            <iframe src={"https://www.youtube.com/embed/" + ''
                                            // this.props.video.video_url
                                            } style={{ width: "253px", height: "142px", margin: '0.5rem' }} allowfullscreen></iframe>

                                            <iframe src={"https://www.youtube.com/embed/" + ''
                                            // this.props.video.video_url
                                            } style={{ width: "253px", height: "142px", margin: '0.5rem'}} allowfullscreen></iframe>
                                        </div> */}
                                    </div>
                                </div>
                            
                        </div>
                    </div>

                    <div className="ui-basicTutorialShow__container-card-rigth card">
                        <div className="card-header ui-basicTutorialShow__container-header">
                            <h6>Más Tutoriales</h6>
                        </div>

                        <div className="card-body ui-basicTutorialShow__container-moreTutorials">
                            {this.props.tutorials.map(basic_tutorial => (
                                <div className="ui-basicTutorialShow_body-items" key={basic_tutorial.id}>
                                    <a 
                                        href={`/settings/tutorials/${basic_tutorial.token}`} 
                                        data-turbolinks="true"
                                        className='ui-basicTutorialShow_body-items-link'
                                    >
                                        <div className="ui-basicTutorialShow_body-items-icon">
                                            <img src={basic_tutorial.image} alt="Imagen identificativa" />
                                        </div>

                                        <div className="ui-basicTutorialShow_body-items-title">
                                            <h5>{basic_tutorial.name}</h5>
                                        </div>

                                        <div className="ui-basicTutorialShow_body-items-description">
                                            {ReactHtmlParser(basic_tutorial.description)}
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>



            </React.Fragment>
        );
    }
}


export default BasicTutorialShow;
WebpackerReact.setup({ BasicTutorialShow });