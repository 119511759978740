import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `item ${k + offset}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: 'lightgrey',
    padding: grid,
    width: "100%",
    height: "inherit",
});

class IndexQuestions extends Component {
    state = {
        items: [],
        selected: []
    };

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    componentDidMount = () => {
        let arrayTrue = [];
        let arrayFalse = [];

        this.props.format_custom_view.questions_true.map(item => {
            arrayTrue.push({ content: item.name, id: `${item.id}`  })
        })

        this.props.format_custom_view.questions_false.map(item => {
            arrayFalse.push({ content: item.name, id: `${item.id}`  })
        })

        this.setState({ 
            items: arrayTrue,
            selected: arrayFalse,
        })
    }

    updatePosition = (result) => {
        const { source, destination, draggableId } = result;
        let token = document.querySelector("[name='csrf-token']").content;
        let question_id = Number(draggableId);
        let state = destination.droppableId == "droppable2" ? false : true


        let form = {
            question_id: question_id,
            state: state,
        }
        

        fetch(`/formatos/format_custom_views/${this.props.format_custom_view.id}/update_state_question`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            
        });
    }

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const { source, destination } = result;
        
        this.updatePosition(result);

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'droppable2') {
                state = { selected: items };
            }

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                items: result.droppable,
                selected: result.droppable2
            });
        }
    };

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    // style divs style={getListStyle(snapshot.isDraggingOver)}
    // style items style={getItemStyle(snapshot.isDragging, provided.draggableProps.style )}
    
    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>

                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            Incluidos 
                        </div>
                        <div className="card-body p-0 height-card">
                            <div className="scroll-area-lg">
                                <div className="scrollbar-container" style={{ height: "65%" , background: "lightgrey", padding: "10px"}}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}  
                                                style={{ height: "100%" }}  
                                            >
                                                {this.state.items.map((item, index) => (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={item.id}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={`card mb-2 ${index == 0 ? "mt-2" : ""}`}
                                                            >
                                                                <div className="card-body p-2">
                                                                    {item.content}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            Descartar
                        </div>

                        <div className="card-body p-0 height-card">
                            <div className="scroll-area-lg">
                                <div className="scrollbar-container" style={{ height: "65%" , background: "lightgrey", padding: "10px"}}>
                                    <Droppable droppableId="droppable2">
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}    
                                                style={{ height: "100%" }}
                                            >
                                                {this.state.selected.map((item, index) => (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={item.id}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={`card mb-2 ${index == 0 ? "mt-2" : ""}`}
                                                            >
                                                                <div className="card-body p-2">
                                                                    {item.content}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </DragDropContext>
        );
    }
}

export default IndexQuestions;

