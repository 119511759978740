import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Preloader from '../../../../../../GeneralComponents/LoadingComponen';
import FormCreate from './FormCreate';
import Swal from "sweetalert2/dist/sweetalert2.js";

const IndexQuestionMatrixItemCodition = (props) => {
    const [isLoaded, setIsLoaded] = useState(true);
    const [errorValues, setErrorValues] = useState(true);
    const [question_matrix_item_codition_id, setQuestionMatrixItemCoditionId] = useState(null);
    const [data, setData] = useState([]);
    const [form, setForm] = useState({ value_column: "", condition: "", value: "", question_matrix_item_id: props.questionMatrixItem.id });
    const token = document.querySelector("[name='csrf-token']").content;

    useEffect(() => {
        loadData();
    }, []);

    const updateData = (new_item) => {
        setData([...data, new_item])
    }

    const clearValues = () => {
        setErrorValues(true);
        setForm({ value_column: "", condition: "", value: "", question_matrix_item_id: props.questionMatrixItem.id })
        setQuestionMatrixItemCoditionId(null);
    }

    const updateItem = (question_matrix_item_codition) => {
        setData(data.map(item => {
            if (item.id === question_matrix_item_codition.id) {
                return {
                    ...item,
                    value_column: question_matrix_item_codition.value_column,
                    condition: question_matrix_item_codition.condition,
                    value: question_matrix_item_codition.value
                }
            }
            return item;
        }));
    }

    const loadData = () => {
        fetch(`/formatos/get_question_matrix_item_coditions/${props.questionMatrixItem.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                setIsLoaded(false);
                setData(data.data)
            });
    }

    const destroy = (question_matrix_item_codition_id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_matrix_item_coditions/${question_matrix_item_codition_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        setData(data.filter((e) => e.id != question_matrix_item_codition_id))
                    });
            }
        });
    };

    const validationForm = () => {
        if (form.value_column != "" &&
            form.condition != "" &&
            form.value != ""
        ) {
            setErrorValues(true)
            return true
        } else {
            setErrorValues(false)
            return false
        }
    }

    const handleClick = () => {
        if (validationForm()) {
            if (question_matrix_item_codition_id)
                fetch(`/formatos/question_matrix_item_coditions/${question_matrix_item_codition_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        updateItem(data.register);
                        clearValues();
                    });
            else {
                fetch(`/formatos/question_matrix_item_coditions`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        updateData(data.register);
                        clearValues();
                    });
            }
        }
    }


    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const edit = (question_matrix_item_codition) => {
        setQuestionMatrixItemCoditionId(question_matrix_item_codition.id);
        setForm({ ...form, value_column: question_matrix_item_codition.value_column, condition: question_matrix_item_codition.condition, value: question_matrix_item_codition.value })
    }

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered modal-lg" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>
                <ModalBody>

                    <FormCreate
                        handleClick={handleClick}
                        handleChange={handleChange}
                        errorValues={errorValues}
                        questionMatrixItem={props.questionMatrixItem}
                        columns={props.columns}
                        question_matrix_item_codition_id={question_matrix_item_codition_id}
                        form={form}
                        clearValues={clearValues}
                    />

                    {!isLoaded ? (
                        <React.Fragment>
                            {data.length >= 1 ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='text-left' scope="col">Columna</th>
                                            <th className='text-left' scope="col">Condicion</th>
                                            <th className='text-left' scope="col">Valor</th>
                                            <th className='text-left' scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map(question_matrix_item_codition => (
                                            <tr key={question_matrix_item_codition.id}>
                                                <td>{question_matrix_item_codition.value_column}</td>
                                                <td>{question_matrix_item_codition.condition}</td>
                                                <td>{question_matrix_item_codition.value}</td>
                                                <td>
                                                    <UncontrolledDropdown className='btn-group'>
                                                        <DropdownToggle className='btn btn-info'>
                                                            <i className="fas fa-bars"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                            {true && (
                                                                <DropdownItem
                                                                    onClick={() => edit(question_matrix_item_codition)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Editar
                                                                </DropdownItem>
                                                            )}

                                                            {true && (
                                                                <DropdownItem
                                                                    onClick={() => destroy(question_matrix_item_codition.id)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Eliminar
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h5>No hay registros</h5>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    ) : (
                        <Preloader />
                    )}
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}


export default IndexQuestionMatrixItemCodition;

