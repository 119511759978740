import React from 'react';

const index = (props) => {
    return (
        <React.Fragment>
            {props.data.length >= 1 ? (
                props.data.map(dofa => (
                    <div className={`card mb-3`} key={dofa.id}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <b>Título</b>
                                    <p>{dofa.title}</p>
                                </div>   
                                <div className="col-md-12">
                                    <b>Fecha</b>
                                    <p>{dofa.date}</p>
                                </div> 
                                <div className="col-md-12">
                                    <b>Responsable</b>
                                    <p>{dofa.responsable != undefined ? dofa.responsable.first_name : ""} {dofa.responsable != undefined ? dofa.responsable.first_last_name : ""}</p>
                                </div> 
                                <div className="col-md-12">
                                    <b>Descripción</b>
                                    <p>{dofa.description != '' ? dofa.description : 'Esta FODA no tiene descripción'}</p>
                                </div>                               

                            </div>
                        </div>
                        <React.Fragment>
                            {(props.estados.editar || props.estados.eliminar || props.estados.gestionar) && (
                                <div className="card-footer">
                                    <React.Fragment>
                                        {props.estados.gestionar && (
                                            <button
                                                onClick={() =>  props.redirectTaks(dofa)}
                                                className="dropdown-item"
                                            >
                                                Gestionar
                                            </button>
                                        )}

                                        {props.estados.editar && (
                                            <button
                                                onClick={() => props.edit(dofa)}
                                                className="dropdown-item"
                                            >
                                                Editar
                                            </button>
                                        )}
                                        {props.estados.eliminar && (  
                                            <button
                                                onClick={() => props.delete(dofa.id)}
                                                className="dropdown-item"
                                            >
                                                Eliminar
                                            </button>
                                        )}
                                    </React.Fragment>
                                </div>
                            )}
                        </React.Fragment>
                    </div>
                ))
            ) : (
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body text-center">
                            <h4>No hay registros</h4>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default index;