import React, { Component } from 'react';
import Table from '../components/settings/Procesos/Table'


class ProcesoIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            showbtnSubmit: false,
            formFilter: {
                name: ""
            },


            //pagination 

            activePage: 1,
            countPage: 12,
            total: 0
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //filter

    clearValues = () => {
        this.setState({
            formFilter: {
                name: ""
            },
            showbtnSubmit: false,
        })
        
        this.loadData();
    }

    handleChangeFilter = e => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        });
    };

    handleClickFilter = () => {
        fetch(`/settings/get_procesos/${'procesos administrativos'}?name=${this.state.formFilter.name}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                showbtnSubmit: true
            });
        });
    };

    loadData = () => {
        fetch(`/settings/get_procesos/${'procesos administrativos'}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                total: data.total,
                isLoaded: false
            });
        });
    }

    handlePageChange = pageNumber => {
        this.setState({ activePage: pageNumber }); 
        fetch(`/settings/get_procesos/${'procesos administrativos'}?page=${pageNumber}&filter=${this.state.countPage}&filtering=${this.state.filtering}&work_description=${this.state.filtering == true ? this.state.formFilter.work_description : "" }&report_execute_id=${this.state.filtering == true ? this.state.formFilter.report_execute_id : ""}&date_ejecution=${this.state.filtering == true ? this.state.formFilter.date_ejecution : ""}&report_sate=${this.state.filtering == true ? this.state.formFilter.report_sate : ""}&cost_center_id=${this.state.filtering == true ? this.state.formFilter.cost_center_id : ""}&customer_id=${this.state.filtering == true && this.state.formFilter.customer_id != undefined  ? this.state.formFilter.customer_id : ""}&date_desde=${this.state.formFilter.date_desde}&date_hasta=${this.state.formFilter.date_hasta}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({ 
                data: data.data,
                total: data.total,
            });
        });     
    };  


    render() {
        return (
            <React.Fragment>
                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    estados={this.props.estados}
                    isLoaded={this.state.isLoaded}

                    //filter
                    handleClickFilter={this.handleClickFilter}
                    handleChangeFilter={this.handleChangeFilter}
                    showbtnSubmit={this.state.showbtnSubmit}
                    formFilter={this.state.formFilter}
                    clearValues={this.clearValues}
                    tenant={this.props.tenant}
                    current_url={this.props.current_url}

                    //pagination
                    totalData={this.state.total}
                    activePage={this.state.activePage}
                    countPage={this.state.countPage}
                    handlePageChange={this.handlePageChange}
                />
            </React.Fragment>
        );
    }
}


export default ProcesoIndex;