import React, { Component } from 'react';
import Select from "react-select";
import { isMobile } from 'react-device-detect';
import Preloader from '../../../GeneralComponents/LoadingComponen';
import Swal from "sweetalert2/dist/sweetalert2.js";

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: true,
            isLoadedChange: false,
            errorValues: true,

            task_boards: [],
            columns: [],

            formUpdate: {
                column_id: "",
                task_board_id: "",
                task_id: this.props.task.id,
            },

            selectedOptionTaskBoard: {
                value: "",
                label: "Seleccione el tablero",
            },

            selectedOptionColumn: {
                value: "",
                label: "Seleccione la columna",
            },
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    handleChangeAutocompleteTaskBoard = selectedOptionTaskBoard => {
        this.loadColumns(selectedOptionTaskBoard.value);
        this.setState({
            selectedOptionTaskBoard,
            formUpdate: {
                ...this.state.formUpdate,
                task_board_id: selectedOptionTaskBoard.value
            }
        });
    };

    handleChangeAutocompleteColumn = selectedOptionColumn => {
        this.setState({
            selectedOptionColumn,
            formUpdate: {
                ...this.state.formUpdate,
                column_id: selectedOptionColumn.value
            }
        });
    };

    loadData = () => {
        fetch(`/task/get_change_task_boards/${this.props.task.task_board.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                task_boards: data.data,
                isLoaded: false
            });
        });
    }

    loadColumns = (task_board_id) => {
        fetch(`/task/get_task_board_columns/${task_board_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            let array = []

            data.columns.map((item) => (
                array.push({ label: item.title, value: item.id })
            ))

            this.setState({
                columns: array,
            })
        });
    }

    validationForm = () => {
        if (this.state.formUpdate.column_id != "" &&
            this.state.formUpdate.task_board_id != "" 
        ) {
            this.setState({ errorValues: true })
            return true
        } else {
            this.setState({ errorValues: false })
            return false

        }
    }

    handleClick = () => {
        if(this.validationForm()){
            Swal.fire({
                title: "¿Esta seguro que quiere cambiar la tarea de tablero?",
                text: "¡La tarea sera cambiada de tablero!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#009688",
                cancelButtonColor: "#d33",
                confirmButtonText: "Cambiar tarea",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.value) {
                    this.setState({ isLoadedChange: true })
                    fetch(`/task/change_task_to_task_board`, {
                        method: 'PATCH', // or 'PUT'
                        body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
        
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.props.messageSuccess(data);
                        this.props.loadData();
                        this.props.closeModal();
                        this.setState({ isLoadedChange: false })
                    });
                }
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className={`col-md-12 ${this.state.columns.length >= 1 ? "" : "mb-4"}`} style={{ width: isMobile ? "" : "313px" }}>
                        <input
                            type="hidden"
                            name="value"
                            value={this.state.selectedOptionTaskBoard.value}
                        />
                        <Select
                            onChange={this.handleChangeAutocompleteTaskBoard}
                            options={this.state.task_boards}
                            autoFocus={false}
                            className={`link-form ${!this.state.errorValues && this.state.formUpdate.task_board_id == "" ? "error-class" : ""}`}
                            value={this.state.selectedOptionTaskBoard}
                        />
                    </div>

                    {this.state.columns.length >= 1 && (
                        <div className="col-md-12 mt-4 mb-4">
                            <input
                                type="hidden"
                                name="value"
                                value={this.state.selectedOptionColumn.value}
                            />
                            <Select
                                onChange={this.handleChangeAutocompleteColumn}
                                options={this.state.columns}
                                autoFocus={false}
                                className={`link-form ${!this.state.errorValues && this.state.formUpdate.column_id == "" ? "error-class" : ""}`}
                                value={this.state.selectedOptionColumn}
                            />
                        </div>  
                    )}

                    <div className="col-md-12">
                        {true &&(
                            <button
                                className='btn-shadow btn btn-primary btn-block'
                                disabled={this.state.isLoadedChange}
                                onClick={() => this.handleClick()}
                            >
                                {this.state.isLoadedChange ? "Cambiando de tablero.." : "Enviar a nuevo tablero"}
                            </button>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;