import React, { Component } from 'react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FormCreate from './FormCreate';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            format_qr_question_id: "",

            form: {
                format_qr_id: this.props.format_qr.id,
                question_source_id: "",
                question_target_id: "",
            },

            selectedOptionQuestionSource: {
                label: "",
                question_source_id: "",
            },

            selectedOptionQuestionTarget: {
                label: "",
                question_target_id: "",
            }

        }
    }

    handleChangeAutocompleteQuestionTarget = (selectedOptionQuestionTarget) => {
        this.setState({
            selectedOptionQuestionTarget,
            form: {
                ...this.state.form,
                question_target_id: selectedOptionQuestionTarget.value
            }
        });
    };

    handleChangeAutocompleteQuestionSource = (selectedOptionQuestionSource) => {
        this.setState({
            selectedOptionQuestionSource,
            form: {
                ...this.state.form,
                question_source_id: selectedOptionQuestionSource.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
                if (format.id === item.id) {
                    return {
                        ...item,
                        name: format.name, 
                        type_to_answer: format.type_to_answer,
                        format_target: format.format_target
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            format_qr_question_id: "",

            form: {
                ...this.state.form,
                question_source_id: "",
                question_target_id: "",
            },
            
            selectedOptionQuestionSource: {
                label: "",
                question_source_id: "",
            },

            selectedOptionQuestionTarget: {
                label: "",
                question_target_id: "",
            }
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_qr_questions/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/formatos/get_format_qr_questions/${this.props.format_qr.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    HandleClick = () => {
        if (this.state.modeEdit) {
            fetch(`/formatos/format_qr_questions/${this.state.format_qr_question_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateItem(data.register)
                    this.clearValues();
                    this.setState({ modal: false })
                });
        } else {
            fetch(`/formatos/format_qr_questions`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateData(data.register)
                    this.clearValues();
                    this.setState({ modal: false })
                });
        }
    }


    edit = (format_qr_question) => {
        this.setState({
            modal: true,
            modeEdit: true,
            format_qr_question_id: format_qr_question.id,
            form: {
                ...this.state.form,
                question_source_id: format_qr_question.question_source.id,
                question_target_id: format_qr_question.question_target.id,
            },
            
            selectedOptionQuestionSource: {
                label: format_qr_question.question_source.question,
                question_source_id: format_qr_question.question_source.id,
            },

            selectedOptionQuestionTarget: {
                label: format_qr_question.question_target.question,
                question_target_id: format_qr_question.question_target.id,
            }
        })
    }

    render() {
        return (
            <React.Fragment>

                {this.state.modal && (
                    <FormCreate
                        toggle={this.toogle}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                        //form props
                        submitForm={this.HandleClick}
                        format={this.props.format}
                        format_qr={this.props.format_qr}

                        selectedOptionQuestionSource={this.state.selectedOptionQuestionSource}
                        handleChangeAutocompleteQuestionSource={this.handleChangeAutocompleteQuestionSource}

                        selectedOptionQuestionTarget={this.state.selectedOptionQuestionTarget}
                        handleChangeAutocompleteQuestionTarget={this.handleChangeAutocompleteQuestionTarget}
                    />
                )}

                <div className="col-md-12 mb-3 text-right pr-0">
                    {!this.state.modal && (
                        <button
                            className="btn-shadow btn btn-info"
                            onClick={() => this.toogle("new")}
                        >
                            Abrir formulario
                        </button>
                    )}
                </div>

                <table className="table " id="sampleTable">
                    <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                        <tr>
                            <th>Pregunta Fuente</th>
                            <th>Pregunta Objetiva</th>
                            <th className="text-right">Acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.data.length >= 1 ? (
                            this.state.data.map((format_qr_question, index) => (
                                <React.Fragment>
                                    {index >= 1 ? <div className="mb-2"></div> : null}
                                    <tr key={format_qr_question.id} className="tr-table-task" style={{ borderLeft: `4px solid green` }}>
                                        <td>{format_qr_question.question_source.question}</td>
                                        <td>{format_qr_question.question_target.question}</td>

                                        <td className="text-right">
                                            {true && (
                                                <UncontrolledDropdown direction="left" className='btn-group'>
                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                        <i className="fas fa-bars"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                        {true && (
                                                            <DropdownItem
                                                                className="dropdown-item"
                                                                onClick={() => this.edit(format_qr_question)}
                                                            >
                                                                Editar
                                                            </DropdownItem>
                                                        )}
                                                                        
                                                        {true && (
                                                            <DropdownItem
                                                                onClick={() => this.delete(format_qr_question.id)}
                                                                className="dropdown-item"
                                                            >
                                                                Eliminar
                                                            </DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            )}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">
                                    <div className="text-center mt-4 mb-4">
                                        <h4>No hay registros</h4>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default Index;
