import React, { Component } from 'react';
import { ChromePicker } from 'react-color'
import Select from "react-select";
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

class FormCreate extends Component {
    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        
        return (
            <React.Fragment>
                <div className="row">
                <div className="ui-text-predicate-edit">
                        <p className='pl-3'> Cambia el estilo del registro</p>
                    </div>
                    <div className="col-md-12">
                        <button 
                            onClick={() => this.props.handleClickFirstColor()} 
                            style={{ 
                                border: `1px solid #ffff`,
                                fontSize: "19px"
                            }}
                            className="btn btn-light w-100"
                        >
                            <i className="fas fa-font" style={{ borderBottom: `6px solid ${this.props.formValues.color}` }}></i> Color
                        </button>
                        { this.props.showPikerFirstColorEdit ? <div style={ popover }>
                            <div style={ cover } onClick={ this.props.handleCloseFirstColor }/>
                                <ChromePicker 
                                    color={this.props.formValues.color} 
                                    onChange={this.props.handleChangeFirstColor} 
                                />
                        </div> : null }
                    </div>                   

                    <div className="col-md-12 mt-3">
                        <button 
                            onClick={() => this.props.handleClickFirstBackgroundColor()} 
                            style={{ 
                                border: `1px solid #ffff`,
                                fontSize: "19px"
                             }}
                            className="btn btn-light w-100"
                        >
                            <i className="fas fa-fill-drip" style={{ borderBottom: `6px solid ${this.props.formValues.background_color}` }}></i> Color de fondo
                        </button>
                        { this.props.showPikerFirstBackgroundColorEdit ? <div style={ popover }>
                            <div style={ cover } onClick={ this.props.handleCloseFirstBackgroundColor }/>
                                <ChromePicker 
                                    color={this.props.formValues.background_color} 
                                    onChange={this.props.handleChangeBackgroundColor} 
                                />
                        </div> : null }
                    </div>

                    {this.props.question.question_type == "dynamic_field" && (
                        <React.Fragment>
                            <div className="col-md-12 mt-2">
                                <input
                                    type="text"
                                    name="value"
                                    value={this.props.formValues.value}
                                    onChange={this.props.onChangeForm}
                                    className={`form form-control`}
                                    placeholder="Valor"
                                />
                            </div>

                            <div className="col-md-12 mt-3">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" onChange={(e) => this.props.handleChangeCheckBox(e, "question_to_compare_show")} className="custom-control-input" id={`customShowTable`} checked={this.props.formValues.question_to_compare_show} />
                                    <label className="custom-control-label" htmlFor={`customShowTable`}>Traer valor de una pregunta</label>
                                </div>
                            </div>

                            <div className="col-md-12 mt-3">
                                <input
                                    type="hidden"
                                    name="question_value_id"
                                    value={this.props.selectedOptionQuestionValueUpdate.question_value_id}
                                />
                                <Select
                                    onChange={this.props.handleChangeAutocompleteQuestionValue}
                                    options={this.props.questions}
                                    autoFocus={false}
                                    className={`link-form`}
                                    value={this.props.selectedOptionQuestionValueUpdate}
                                />
                            </div>

                           {/*  {this.props.formValues.question_to_compare_show && (
                                <div className="col-md-12 mt-3">
                                    <input
                                        type="hidden"
                                        name="question_to_compare_id"
                                        value={this.props.selectedOptionQuestionToCompareUpdate.question_to_compare_id}
                                    />
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteQuestionToCompare}
                                        options={this.props.questions}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.props.selectedOptionQuestionToCompareUpdate}
                                    />
                                </div>
                            )} */}

                            <div className="col-md-8 mt-2">
                                <input type="checkbox" onChange={(e) => this.props.handleChangeCheckBox(e, "send_email")} checked={this.props.formValues.send_email} value={!this.props.formValues.send_email} id="checkboxState" /> 
                                <label htmlFor="checkboxState" className="ml-1 mt-2">Enviar correo</label>
                            </div>

                            {this.props.formValues.send_email && (
                                <React.Fragment>
                                    <div className="col-md-12 mt-3">
                                        <label>Participantes </label>
                                        <input
                                            type="hidden"
                                            name="position_ids"
                                            value={this.props.selectedOptionPositions.position_ids}
                                        />
                                        <Select
                                            onChange={this.props.handleChangeAutocompletePositions}
                                            options={this.props.cargos}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            autoFocus={false}
                                            className={`link-form`}
                                            classNamePrefix="select"
                                            placeholder="Seleccione"
                                            name="position_ids"
                                            defaultValue={this.props.editValues}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <label>Pregunta para comparar</label>
                                        <input
                                            type="hidden"
                                            name="question_compare_id"
                                            value={this.props.selectedOptionQuestionCompare.question_compare_id}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocompleteQuestionCompare}
                                            options={this.props.questions}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionQuestionCompare}
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <FroalaEditor
                                            model={this.props.formValues.email_content}
                                            config={this.props.config}
                                            onModelChange={this.props.HandleChangeCkaditor}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}

                    <div className="col-md-12 mt-3 text-center">
                        <div className="p-2" style={{  backgroundColor: this.props.formValues.background_color, color: this.props.formValues.color }}>
                            <span>{this.props.question.question_type == "dynamic_field" ? this.props.formValues.value : this.props.question.question}</span>
                        </div>
                    </div>
                    

                    <div className="col-md-12 mt-3 text-center">
                        <button
                            className="btn-shadow btn btn-info"
                            onClick={this.props.handleClick}
                        >
                            {this.props.modeEdit ? "Actualizar" : "Crear"}
                        </button>

                        <button
                            className="btn-shadow btn btn-danger ml-2"
                            onClick={this.props.clearValues}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FormCreate;
