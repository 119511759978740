import React, { Component } from 'react';
import GeneralComments from '../../../GeneralComponents/ChatBoxModuleComment'
import Preloader from '../../../GeneralComponents/LoadingComponen'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from '../../tasks/tasksCard/FormCreate'
import TaskList from '../../tasks/tasksCard/TaskList'
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import ReactHtmlParser from 'react-html-parser';

class Show extends Component {
    constructor(props){
        super(props)
        this.url_source = `/risk/matrices/${this.props.risk_matrix.id}/risks/${this.props.risk.id}`,
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showForm: false,

            //states task

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            users: [],

            form: {
                name: "",
                due_date: "",
                user_owner_id: "",
                url_source: this.url_source,
                task_source_id: this.props.task_source.id,
                module_task_id: this.props.risk.module_task_id,
                name_module: "",
            },
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    //tasks 

    componentDidMount = () => {
        let array = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                user_owner_id: selectedOption.value
            }
        });
    };

    clearValues = () => {
        this.setState({
            showForm: false,
            form: {
                ...this.state.form,
                name: "",
                due_date: "",
                user_owner_id: "",
                module_task_id: this.props.risk.module_task_id,
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },
        })
    }

    HandleChangeTask = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }


    getColor = (number) => {
        if(number == 0 || number >= 12){
            return ["#FF0000","white"]
        }else if(number >= 6 && number <= 11){
            return ["#FFFF00","black"]
        }else if(number == 5 || number < 5){
            return ["#008000","white"]
        }
    }

    getColorImp = (number) => {
        if(number ==1){
            return ["white","black"]
        }else if(number ==2){
            return ["#00B050","black"]
        }else if(number ==3){
            return ["#FFC000","black"]
        }else if(number ==4){
            return ["#FF1300","white"]
        }else if(number ==5){
            return ["#921503","white"]
        }
        
        
    }




    render() {
        const estados = {
            gestionar: true,
            eliminar: true,
        }


        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.risk.risk_type}: ${this.props.risk.description}`}
                    subTitle={"Gesión de riesgos"}
                    titleNew={"Nuevo riesgo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a 
                                href={"/risk/matrices"}
                                data-turbolinks="true"
                            >
                                {"Matriz de riesgos"}
                            </a>
                        </li>

                        <li className="breadcrumb-item">
                            <a 
                                href={`/risk/matrices/${this.props.risk_matrix.id}/risks`}
                                data-turbolinks="true"
                            >
                                {"Riesgos"}
                            </a>
                        </li>

                        <li className="breadcrumb-item">
                            <a 
                                href={`/risk/matrices/${this.props.risk_matrix.id}/risks/${this.props.risk.id}`}
                                data-turbolinks="true"
                            ></a>
                                {`${this.props.risk_matrix.proceso}`}
                          
                        </li>
                    </ol>
                </nav>


                <div className="row mt-4 mb-4">

                    <div className="col-md-12 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row risk-show-container">

                                    <div className="col-md-4">
                                        <b>Reporta</b>
                                        <p>{this.props.risk.user.first_name} {this.props.risk.user.first_last_name}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <b>Tipo de riesgo</b>
                                        <p>{this.props.risk.risk_type}</p>
                                    </div>

                                    <div className="col-md-4">
                                        <b>Criterio</b>
                                        <p>{this.props.risk.criterio.name}</p>
                                    </div>


    
                                    <div className="col-md-12">
                                       <hr/>
                                    </div>

                                    <div className="col-md-4">
                                        <b>Probabilidad</b>
                                        <p><span className="badged-risk" style={{ backgroundColor: this.getColorImp(this.props.risk.probability)[0], color: this.getColorImp(this.props.risk.probability)[1] }}>{this.props.risk.probability}</span></p>
                                    </div>

                                    <div className="col-md-4" >
                                        <b>Impacto</b>
                                        <p><span className="badged-risk" style={{ backgroundColor: this.getColorImp(this.props.risk.impact)[0], color: this.getColorImp(this.props.risk.impact)[1] }}>{this.props.risk.impact}</span></p>
                                    </div>

                                    <div className="col-md-4" >
                                        <b>Score</b>
                                        <p><span className="badged-risk" style={{ backgroundColor: this.getColor(this.props.risk.score)[0], color: this.getColor(this.props.risk.score)[1] }}>{this.props.risk.score}</span> </p>
                                    </div>

                                    <div className="col-md-12">
                                       <hr/>
                                    </div>

                                    <div className="col-md-3">
                                        <b>Posible Afectación</b>
                                        <ul>
                                            {this.props.risk.possible_involvement_array.map(possible => (
                                                <li>{possible}</li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className="col-md-4">
                                        <b>Servicio / Área</b>
                                        <p>{this.props.risk.area.name}</p>
                                    </div>

                                    <div className="col-md-4">
                                        <b>Actividad/ Procedimiento</b>
                                        <p>{this.props.risk.activity.name}</p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="card card-list" style={{ height: "772px" }}>
                            <div className="card-header">
                                <div className="col-md-12 pl-0">
                                    Tareas
                                    {true ? <React.Fragment> {this.state.showForm == false ? <i className="fas fa-plus float-right icon-add" onClick={() => this.setState({ showForm: true })}></i> : <i className="fas fa-times float-right icon-add" onClick={() => this.setState({ showForm: false })}></i>} </React.Fragment> : ""}
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="scroll-area-lg scroll-tasks">
                                    <div className="scrollbar-container">
                                        {this.state.showForm == true && (
                                            <FormCreate
                                                HandleChange={this.HandleChangeTask}
                                                form={this.state.form}
                                                clearValues={this.clearValues}
                                                loadData={this.props.loadDataTask}
                                                updateStateLoad={this.props.updateStateLoad}

                                                //select autocomplete
                                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                selectedOption={this.state.selectedOption}
                                                users={this.state.users}

                                                updateData={this.props.updateData}
                                                task_source_id={this.props.task_source.id}
                                            />
                                        )}

                                        {this.props.isLoadedTask ? (
                                            <div className="col-md-12 text-center mt-4">
                                                <Preloader />
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                {this.props.dataTask.length >= 1 ? (
                                                    this.props.dataTask.map(task => (
                                                        <TaskList
                                                            task={task}
                                                            user={this.props.user}
                                                            users={this.props.users}
                                                            showOptions={true}
                                                            loadData={this.props.loadDataTask}
                                                            estados={estados}
                                                            updateStateLoad={this.props.updateStateLoad}
                                                            updateItem={this.props.updateItem}
                                                            procesos={this.props.procesos}
                                                        />
                                                    ))
                                                ) : (
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <h6>No hay tareas</h6>
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-7">
                        <GeneralComments
                            usuario={this.props.user}
                            module_name={"Riesgos"}
                            users={this.state.users}
                            url={this.props.url}
                            module_comment={this.props.risk.module_comment_id}
                        />
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Show;