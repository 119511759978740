import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';  
import HeaderTitle from '../GeneralComponents/HeaderTitle';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton,AccordionItemPanel, } from 'react-accessible-accordion';
import Preloader from "../GeneralComponents/LoadingComponen";
import { isMobile } from 'react-device-detect';

class ChecklistIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: true,
            data: [], //
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/task/get_check_lists_data`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false,
            })
        });
    }

    handleChangeCheckBox = (e, check_list_item_id) => {
        const state = e.target.checked;

        fetch(`/task/update_state_my_check_list_item/${check_list_item_id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.updateData(data.register);
        });
    }

    updateData = (register) => {
        if(register.check_list_items_count == 0){
            this.setState({
                data: this.state.data.filter(e => e.id != register.task_id)
            })
        }else{
            this.setState({
                data: this.state.data.map(item => {
                    if (register.task_id === item.id) {
                        return {
                            ...item,
                            check_list_items: item.check_list_items.filter(e => e.id != register.id)
                        }
                    }
                    return item;
                })
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Mis Checklist`}
                    subTitle={"Gestión de Tareas"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-check-square icon-gradient bg-mean-fruit"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />
                
                <div className="content main-card mb-3 card card-style ui_checklist_taskIndex__container">
                    <div className="card-body">
                    
                        {this.state.isLoaded ? (
                            <Preloader/>
                        ) : (
                            <React.Fragment>
                                {this.state.data.length >= 1 ? (
                                    <React.Fragment>
                                        {this.state.data.map((task, index) => (
                                            <Accordion preExpanded={['active', 'actived']} allowZeroExpanded>
                                                <AccordionItem uuid='active'>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            <div className="ui_checklist_taskIndex_container_title">
                                                                <div className='ui_checklist_taskIndex_titleTask'>
                                                                    <a href={`/task/task_boards/${task.task_board.token}/tasks?task_id=${task.id}`} data-turbolinks="true" target="_blank" className='ui_checklist_taskIndex_numberTask'>{`T${task.count}`}</a> {task.name} 
                                                                </div>
                                                                {task.check_list_items.length >= 1 ? <div className='ui-accordion-icon'></div> : null }
                                                            </div>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>

                                                    {task.check_list_items.length >= 1 && (
                                                        <AccordionItemPanel>
                                                            <div className="ui_checklist_taskIndex__container_checklist_info">
                                                                
                                                                <tr className='ui_checklist_taskIndex__container_checklist_titles'>
                                                                    <th>Descripción</th>
                                                                    {isMobile ? "" : (
                                                                        <React.Fragment>
                                                                            <th className='ui_checklist_taskIndex_checklist_titles'>Fecha Fin</th>
                                                                            <th className='ui_checklist_taskIndex_checklist_titles'>Responsable</th>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <th className='ui_checklist_taskIndex_checklist_titles'>Completar</th>
                                                                </tr>

                                                                {task.check_list_items.map((check_list_item, index) => (
                                                                    <tr className='ui_checklist_taskIndex__container_checklist_data'>
                                                                        <td className='ui_checklist_taskIndex_checklist_data_description'>{check_list_item.description}</td>

                                                                        {isMobile ? "": (
                                                                            <React.Fragment>
                                                                                <td className='ui_checklist_taskIndex_checklist_data_date'>{check_list_item.date}</td>

                                                                                <td>
                                                                                    <div className="row ui-indexWeb-row ui_checklist_taskIndex_responsibles">
                                                                                        <div 
                                                                                            className="icon-show-user-responsible ui-boards-index"
                                                                                            style={{ marginLeft: '16px' }}
                                                                                        >
                                                                                            {check_list_item.user_owner.first_name.charAt(0)} {check_list_item.user_owner.first_last_name.charAt(0)}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </React.Fragment>
                                                                        )}

                                                                        <td>
                                                                            <input 
                                                                                type="checkbox"
                                                                                className="form form-control mt-1 ck-no-out-line"                                            
                                                                                style={{ height: "18px" }}
                                                                                onChange={(e) => this.handleChangeCheckBox(e, check_list_item.id)}
                                                                                checked={check_list_item.state}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            </div>
                                                        </AccordionItemPanel>
                                                    )}
                                                </AccordionItem> 
                                            </Accordion>
                                        ))}
                                    </React.Fragment>
                                ) : (
                                    <div className="col-md-12 text-center">
                                        <h4>No hay registros</h4>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default ChecklistIndex;
WebpackerReact.setup({ ChecklistIndex });
