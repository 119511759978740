import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const HeaderInformation = (props) => {
    return (
        <React.Fragment>
            <div className="ui-btree-appearance-profile">
                <div className="ui-btree-appearance-profile-img_logo">
                    <img src={props.enterprise.img_logo_company} alt="" />
                </div>

                <div className="ui-btree-appearance-profile-name_email">
                    <div className="ui-btree-appearance-profile-name">
                        <p>{props.enterprise.name ? props.enterprise.name : "Nombre de la empresa"}</p>
                    </div>

                    {/*<div className="ui-btree-appearance-profile-email">
                        <p>{props.enterprise.name ? props.enterprise.email : "Correo corporativo"}</p>
                    </div>*/}

                    <div className="ui-btree-settings-complement-info">
                        <p>{props.enterprise.aditional_info ? props.enterprise.aditional_info : "Información complementaria"}</p>
                    </div>
                </div>
                
                {props.setDisableInputFunction && (
                    <div className="ui-btree-setting-edit">
                        <UncontrolledTooltip target='bw-btree-icon-unlock-input'>{props.disableInput ? 'Habilitar edición' : 'Deshabilitar edición' }</UncontrolledTooltip>
                        <button id='bw-btree-icon-unlock-input' className='btn btn-outline-info' onClick={() => props.setDisableInputFunction(!props.disableInput)}><i className={`fas ${props.disableInput ? "fa-pencil-alt" : "fa-times"}`}></i></button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}


export default HeaderInformation;

