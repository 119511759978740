import React, { useState, useEffect } from 'react';
import FormConfigurationValue from './FormConfigurationValue';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { isMobile } from 'react-device-detect';

const FormCreate = (props) => {
    const [showConfigurationValue, setShowConfigurationValue] = useState(false);

    const closePopover = () => {
        setShowConfigurationValue(!showConfigurationValue)
    }

    return (
        <React.Fragment>  
            <div className="mb-4">
                <div className="">
                    <div className="row">
                        <div className="col-md-3">
                            <input 
                                type="text" 
                                name="name"
                                className='form form-control'
                                placeholder='Nombre de la fila'
                                value={props.formRow.name}
                                onChange={props.handleChangeRow}
                            />


                            <a
                                className="link-add"
                                onClick={() => props.createRow()}
                            >
                                Agregar
                            </a>

                            <a
                                className="link-remove btn-outline-danger"
                                onClick={() => props.clearValues("row")}
                            >
                                Cancelar
                            </a>
                        </div>

                        {props.questionMatrix.columns.length <= 4 && (
                            <div className="col-md-3">
                                <input 
                                    type="text" 
                                    name="name"
                                    placeholder='Nombre de la columna'
                                    className={`form form-control ${!props.errorValueOptionColumn && props.formColumn.name == "" ? "error-class" : ""}`}
                                    value={props.formColumn.name}
                                    onChange={props.handleChangeColumn}
                                />


                                <a
                                    className="link-add"
                                    onClick={() => props.createColumn()}
                                >
                                    Agregar
                                </a>

                                <a
                                    className="link-remove btn-outline-danger"
                                    onClick={() => props.clearValues("column")}
                                >
                                    Cancelar
                                </a>

                                {(!props.errorValueOptionColumn && props.formUpdateColumn.index == null) && (
                                    <div class="alert alert-danger text-center mt-3" role="alert">
                                        <b>{props.formColumn.name == "" ? "El campo no puede estar vacio" : "No se permiten columnas con el mismo nombre"}</b>
                                    </div>
                                )}
                            </div>
                        )}

                        {props.questionMatrix.columns.length >= 1 && (
                            <div className="col-md-3">
                                <select 
                                    name="default_column"
                                    className='form form-control'
                                    value={props.questionMatrix.default_column}
                                    onChange={props.handleChangeUpdateSelect}
                                >
                                    <option value="radio">Seleccione una opción por defecto</option>
                                    {props.questionMatrix.columns.map((columna, index) => (
                                        <option value={columna} key={index}>{columna}</option>
                                    ))}
                                </select>
                                <label>Opción por defecto</label>
                            </div>
                        )}

                        <div className="col-md-3">
                            <select 
                                name="type_question"
                                className='form form-control'
                                value={props.questionMatrix.type_question}
                                onChange={props.handleChangeUpdateSelect}
                            >
                                {/*<option value="checkbox">Cuadricula de casilla de verificación</option>*/}
                                <option value="radio">Cuadricula de opción múltiple</option>
                                <option value="select">Lista desplegable</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        {props.questionMatrix.set_value && (
                            <div> 
                                <FormConfigurationValue
                                    questionMatrix={props.questionMatrix}
                                    closePopover={closePopover}
                                    question={props.question}
                                    updateQuestionMatrix={props.updateQuestionMatrix}
                                />
                            </div>  
                        )}
                    </div>
                </div>

                <div className={`${isMobile ? ('row mt-3'): ('ui-form-create-matrix__container-switch')}`}> 
                    <div className="col-md-6"></div>
                    <div className="col-md-2">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => props.updateBoolean(!props.questionMatrix.has_file, "has_file")} className="custom-control-input" id={`customConfigurationMatrixFile${props.questionMatrix.id}`} checked={props.questionMatrix.has_file} />
                            <label className="custom-control-label" htmlFor={`customConfigurationMatrixFile${props.questionMatrix.id}`}>Archivo</label>
                        </div>
                    </div>

                    <div className="col-md-2 mr-1">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => props.updateBoolean(!props.questionMatrix.has_commets, "has_commets")} className="custom-control-input" id={`customConfigurationMatrixComment${props.questionMatrix.id}`} checked={props.questionMatrix.has_commets} />
                            <label className="custom-control-label" htmlFor={`customConfigurationMatrixComment${props.questionMatrix.id}`}>Comentarios</label>
                        </div>
                    </div>

                    <div className="col-md-3 pr-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => props.updateBoolean(!props.questionMatrix.set_value, "set_value")} className="custom-control-input" id={`customConfigurationMatrixSetValue${props.questionMatrix.id}`} checked={props.questionMatrix.set_value} />
                            <label className="custom-control-label" htmlFor={`customConfigurationMatrixSetValue${props.questionMatrix.id}`}>Asignar valor</label>
                        </div>
                    </div>
                </div>
                
            </div>
        </React.Fragment>
    );
}


export default FormCreate;

