import React, { Component } from 'react';
import Select from "react-select";
import { isMobile } from 'react-device-detect';
import Preloader from '../../../GeneralComponents/LoadingComponen';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: true,
            isLoadedDefault: true,
            columns: [],
            column: this.props.task.column,
            selectedOption: {
                column_id: this.props.task.column.id,
                label: this.props.task.column.title,
            },
        }
    }

    componentDidMount = () => {
        this.loadData();
        this.loadDefaulValues();
    }

    loadDefaulValues = () => {
        fetch(`/task/get_column_task/${this.props.task.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
    
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({ 
                selectedOption: {
                    column_id: data.column.id,
                    label: data.column.label,
                },

                column: data.column_show,
                isLoadedDefault: false,
            })
        });
    }

    clearValues = () => {
        this.setState({
            selectedOption: {
                column_id: this.props.task.column.id,
                label: this.props.task.column.title,
            },
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.selectColumn(selectedOption.value);
        this.setState({
            selectedOption,
        });
    };

    loadData = () => {
        fetch(`/task/get_task_board_columns/${this.props.task.task_board.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            let array = []

            data.columns.map((item) => (
                array.push({ label: item.title, value: item.id })
            ))

            this.setState({
                isLoaded: false,
                columns: array,
            })
        });
    }

    selectColumn = (column_id) => {
        fetch(`/task/update_task_column/${this.props.task.id}/${column_id}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.setState({
                column: data.register.column,
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12" style={{ width: isMobile ? "" : "313px" }}>
                        {(!this.state.isLoaded && !this.state.isLoadedDefault) ? (
                            <React.Fragment>
                                <input
                                    type="hidden"
                                    name="column_id"
                                    value={this.state.selectedOption.column_id}
                                />
                                <Select
                                    onChange={this.handleChangeAutocomplete}
                                    options={this.state.columns}
                                    autoFocus={false}
                                    className={`link-form`}
                                    value={this.state.selectedOption}
                                />
                            <hr/>
                            </React.Fragment>
                        ) : (
                            <Preloader />
                        )}
                    </div>

                    <div className="col-md-12">
                        {true &&(
                            <React.Fragment>
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h6>{this.state.column.title}</h6>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;