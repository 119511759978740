import React from "react";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Select from "react-select";
import moment from 'moment';
import { DatetimePicker, DatetimePickerTrigger, DatetimeRangePicker } from 'rc-datetime-picker';
import 'rc-datetime-picker/dist/picker.css';


class FormCreate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modeEdit: this.props.editMode,
      ErrorValues: true,

      form: {
        tenant_id: (this.props.editMode == true ? this.props.notification_event.tenant_id : this.props.usuario.tenant_id),
        user_id: (this.props.editMode == true ? this.props.notification_event.user_id : this.props.usuario.id),
        event_date: (this.props.editMode == true ? this.props.notification_event.event_date : ""),
        specific_area_id: (this.props.editMode == true ? this.props.notification_event.specific_area_id : ""),
        patient_name: (this.props.editMode == true ? this.props.notification_event.patient_name : ""),
        birthdate: (this.props.editMode == true ? this.props.notification_event.birthdate : ""),
        safety_related_event_id: (this.props.editMode == true ? this.props.notification_event.safety_related_event_id : ""),
        related_event_id: (this.props.editMode == true ? this.props.notification_event.related_event_id : ""),
        description: (this.props.editMode == true ? this.props.notification_event.description : ""),
        module: (this.props.editMode == true ? this.props.notification_event.module : "events"),
      },

      selectedOptionArea: {
        specific_area_id: (this.props.editMode == true ? this.props.notification_event.specific_area_id : ""),
        label: `${this.props.editMode == true && this.props.notification_event.specific_area != undefined ? this.props.notification_event.specific_area.name : "Seleccionar"}`
      },

      selectedOptionSource: {
        safety_related_event_id: (this.props.editMode == true ? this.props.notification_event.safety_related_event_id : ""),
        label: `${this.props.editMode == true ? this.props.notification_event.safety_related_event.name : "Seleccionar"}`
      },

      selectedOptionEvent: {
        related_event_id: (this.props.editMode == true ? this.props.notification_event.related_event_id : ""),
        label: `${this.props.editMode == true && this.props.notification_event.related_event != undefined ? this.props.notification_event.related_event.name : "Seleccionar"}`
      },


      dataArea: [],
      dataSource: [],
      dataEvent: [],

      moment: moment()
    }

  }


  getDateTime = (datetime) => {

    let time = new Date(datetime)
    console.log(datetime)
    let result = time.getFullYear() + "-" + (time.getMonth() < 10 ? `0${time.getMonth()}` : time.getMonth()) + "-" + (time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()) + "T" + (time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()) + ":" + (time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes())
    return result
}

  validationForm = () => {
    if (this.state.form.event_date != "" &&
      this.state.form.specific_area_id != "" &&
      this.state.form.patient_name != "" &&
      this.state.form.birthdate != "" &&
      this.state.form.safety_related_event_id != "" &&
      this.state.form.related_event_id != "" &&
      this.state.form.description != ""
    ) {
      console.log("los campos estan llenos")
      this.setState({ ErrorValues: true })
      return true
    } else {
      console.log("los campos no se han llenado")
      this.setState({ ErrorValues: false })
      return false

    }
  }

  handleChangePiker = (moment) => {
    this.setState({
      moment,

      form: {
        ...this.state.form,
        event_date: moment.format('YYYY-MM-DD HH:mm')
      }
    });
  }

  MessageSucces = (name_success, type, error_message) => {
    Swal.fire({
      position: "center",
      type: type,
      html: '<p>' + error_message != undefined ? error_message : "asdasdasd" + '</p>',
      title: name_success,
      showConfirmButton: false,
      timer: 1500
    });
  }

  componentDidMount() {
    let arrayArea = []
    let arraySource = []
    let arrayEvent = []

    this.props.areas.map((item) => (
      arrayArea.push({ label: item.name, value: item.id })
    ))

    this.props.events.map((item) => (
      arrayEvent.push({ label: item.name, value: item.id })
    ))

    this.props.sources.map((item) => (
      arraySource.push({ label: item.name, value: item.id })
    ))

    this.setState({
      dataArea: arrayArea,
      dataSource: arraySource,
      dataEvent: arrayEvent,
    })
  }

  handleSubmit = e => {
    e.preventDefault();
  };

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      },
    });
  };

  handleChangeAutocompleteArea = selectedOptionArea => {
    this.setState({
      selectedOptionArea,
      form: {
        ...this.state.form,
        specific_area_id: selectedOptionArea.value
      }
    });
  };

  handleChangeAutocompleteSource = selectedOptionSource => {
    this.setState({
      selectedOptionSource,
      form: {
        ...this.state.form,
        safety_related_event_id: selectedOptionSource.value
      }
    });
  };

  handleChangeAutocompleteEvent = selectedOptionEvent => {
    this.setState({
      selectedOptionEvent,
      form: {
        ...this.state.form,
        related_event_id: selectedOptionEvent.value
      }
    });
  };

  updateValues() {
    this.setState({
      ErrorValues: true,
      form: {
        tenant_id: "",
        user_id: "",
        event_date: "",
        specific_area_id: "",
        patient_name: "",
        birthdate: "",
        safety_related_event_id: "",
        related_event_id: "",
        description: ""
      },

      selectedOptionArea: {
        specific_area_id: "",
        label: "Elejir"
      },

      selectedOptionSource: {
        safety_related_event_id: "",
        label: "Elejir"
      },

      selectedOptionEvent: {
        related_event_id: "",
        label: "Elejir"
      },

    });
  }

  HandleClick = e => {
    if (this.validationForm() == true) {
      if (this.state.modeEdit == true) {
        fetch("/health/event_notification/" + this.props.notification_event.id, {
          method: "PATCH", // or 'PUT'
          body: JSON.stringify(this.state.form), // data can be `string` or {object}!
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(res => res.json())
          .catch(error => console.error("Error:", error))
          .then(data => {
            this.updateValues()
            this.MessageSucces(data.message, data.type, data.message_error);
            Turbolinks.visit("/health/event_notification");
          });

      } else {
        fetch("/health/event_notification", {
          method: "POST", // or 'PUT'
          body: JSON.stringify(this.state.form), // data can be `string` or {object}!
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(res => res.json())
          .catch(error => console.error("Error:", error))
          .then(data => {
            this.updateValues()
            this.MessageSucces(data.message, data.type, data.message_error);
            window.location.href = "/health/event_notification";

          });
      }
    }
  };

  render() {
    const shortcuts = {
      'Today': moment(),
      'Yesterday': moment().subtract(1, 'days'),
      'Clear': ''
    };

    return (
      <React.Fragment>
        <div className="container event-notification-form mb-4">
          <div className="row">
            <div className="card col-md-12 card-style">
              <div className="card-body">
                <h4 className="text-center event-notification-title">SISTEMA DE NOTIFICACIÓN DE EVENTOS RELACIONADOS CON LA SEGURIDAD DEL PACIENTE </h4>
                <hr/>
                <div className="mt-4 col-md-12 p-0 event-notification-label">
                  <h4>FECHA DEL EVENTO</h4>
                  <p>Registre cuándo se presentó el evento</p>
                  <div className="col-md-6 p-0" style={{ marginBottom: "10px" }}>
                    <input
                      name="event_date"
                      type="datetime-local"
                      className={`form form-control`}
                      value={this.getDateTime(this.state.form.event_date)}
                      onChange={this.handleChange}  
                      placeholder="Nombre del paciente"
                    />
                    {/*
                    <DatetimePickerTrigger
                      shortcuts={shortcuts}
                      moment={this.state.moment}
                      onChange={this.handleChangePiker}>
                      <input type="text" value={this.state.moment.format('YYYY-MM-DD HH:mm')} className="form form-control" readOnly />
                    </DatetimePickerTrigger>
                    */}

                  </div>
                </div>

                <div className="mt-4 col-md-12 p-0 event-notification-label" style={{ marginBottom: "10px" }}>
                  <h4>ÁREA ESPECÍFICA DONDE SE PRESENTO EL EVENTO </h4>
                  <p>Registre dónde se presentó el evento</p>
                  <div className="col-md-6 p-0">
                    <input
                      type="hidden"
                      name="specific_area_id"
                      value={this.state.selectedOptionArea.specific_area_id}
                    />
                    <Select
                      onChange={this.handleChangeAutocompleteArea}
                      options={this.state.dataArea}
                      autoFocus={false}
                      className={`link-form ${this.state.ErrorValues == false && this.state.selectedOptionArea.specific_area_id == "" ? "error-class" : ""}`}
                      value={this.state.selectedOptionArea}
                    />
                  </div>
                </div>

                <div className="mt-4 col-md-12 p-0 event-notification-label">
                  <h4>NOMBRE COMPLETO DEL PACIENTE</h4>
                  <p>Registre el nombre iniciando por apellidos</p>
                  <div className="col-md-6 p-0" style={{ marginBottom: "10px" }}>
                    <input
                      name="patient_name"
                      type="text"
                      className={`form form-control ${this.state.ErrorValues == false && this.state.form.patient_name == "" ? "error-class" : ""}`}
                      value={this.state.form.patient_name}
                      onChange={this.handleChange}
                      placeholder="Nombre del paciente"
                    />
                  </div>
                </div>

                <div className="mt-4 col-md-12 p-0 event-notification-label">
                  <h4>FECHA DE NACIMIENTO DEL PACIENTE </h4>
                  <p>Fecha</p>
                  <div className="col-md-6 p-0" style={{ marginBottom: "10px" }}>
                    <input
                      name="birthdate"
                      type="date"
                      className={`form form-control ${this.state.ErrorValues == false && this.state.form.birthdate == "" ? "error-class" : ""}`}
                      value={this.state.form.birthdate}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="mt-4 col-md-12 p-0 event-notification-label" style={{ marginBottom: "10px" }}>
                  <h4>TIPO DE EVENTO RELACIONADO CON LA SEGURIDAD DEL PACIENTE</h4>
                  <div className="col-md-6 p-0">
                    <input
                      type="hidden"
                      name="safety_related_event_id"
                      value={this.state.selectedOptionSource.safety_related_event_id}
                    />
                    <Select
                      onChange={this.handleChangeAutocompleteSource}
                      options={this.state.dataSource}
                      autoFocus={false}
                      className={`link-form ${this.state.ErrorValues == false && this.state.selectedOptionSource.safety_related_event_id == "" ? "error-class" : ""}`}
                      value={this.state.selectedOptionSource}
                    />
                  </div>
                </div>

                <div className="mt-4 col-md-12 p-0 event-notification-label" style={{ marginBottom: "10px" }}>
                  <h4>EVENTO RELACIONADO CON:</h4>
                  <p>Seleccione, de acuerdo con la Clasificación Internacional de Seguridad del Paciente de la OMS, con qué punto está relacionado el evento.</p>
                  <div className="col-md-6 p-0">
                    <input
                      type="hidden"
                      name="related_event_id"
                      value={this.state.selectedOptionEvent.related_event_id}
                    />
                    <Select
                      onChange={this.handleChangeAutocompleteEvent}
                      options={this.state.dataEvent}
                      autoFocus={false}
                      className={`link-form ${this.state.ErrorValues == false && this.state.selectedOptionEvent.related_event_id == "" ? "error-class" : ""}`}
                      value={this.state.selectedOptionEvent}
                    />
                  </div>
                </div>

                <div className="mt-4 mb-3 col-md-12 p-0 event-notification-label">
                  <h4>DESCRIBA EL EVENTO </h4>
                  <p>Redacte a texto libre lo acontecido</p>

                  <div>
                    <textarea
                      name="description"
                      className={`form form-control ${this.state.ErrorValues == false && this.state.form.description == "" ? "error-class" : ""}`}
                      rows="5"
                      value={this.state.form.description}
                      onChange={this.handleChange}
                      placeholder="Observaciones..."
                    />
                  </div>
                </div>

                <div className="col-md-12 text-center">
                  <a href="/notification_events" data-turbolinks="true" className="btn btn-secondary-info mr-3">Volver</a>
                  <button className="btn btn-primary" onClick={this.HandleClick}>Guardar</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>

    );
  }
}

export default FormCreate;

