import React, { useState, useEffect, createRef } from 'react';
import WebpackerReact from 'webpacker-react';
import { computerVision, isConfigured as ComputerVisionIsConfigured } from './AzureConfi';
import Dropzone from "react-dropzone";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from '../GeneralComponents/LoadingComponen';

function FormIa(props) {

  const [fileSelected, setFileSelected] = useState('');
  const [analysis, setAnalysis] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [json, setJson] = useState([]);
  const [task_board_id, setTaskBoardId] = useState("");
  const [default_values, setDefaultValues] = useState([]);

  const dropzoneRef = createRef()
  const token = document.querySelector("[name='csrf-token']").content;
  
  useEffect(() => {
    if(analysis){
      PrettyPrintJson(analysis);
    }
  }, [analysis]);


      
  const handleChange = (e) => {
    setTaskBoardId(e.target.value)
  }

  const eachInputOnChange = (e) => {
    setJson(json.map(item => {
      if (item.id === e.target.id) {
        return { ...item, 
          value: e.target.value,
        }
      }
      return item;
    }));
  }

  const onFileUrlEntered = (imagen) => {
    computerVision(imagen, props.endpoint, props.keyEndpoint).then((item) => {
      // reset state/form
      setAnalysis(item);
      setFileSelected("");
      setProcessing(false);
    });
  };

  // Display JSON data in readable format
  const PrettyPrintJson = (data) => {
    JSON.stringify(data)

      let fullText = []
      for(let i = 0; i < data.text.readResults[0].lines.length; i++){
        let text = data.text.readResults[0].lines[i].text;
        if(text.endsWith("-")){
          text = text + " " + data.text.readResults[0].lines[i+1].text;
          data.text.readResults[0].lines[i+1].text = "";            
          if(text.includes("-")){
            text = text.replace("-","");
          }
        }
        if(text === ""){
          continue;
        }else{
          fullText.push({id: fullText.length.toString(),value: text})
        }

      };
      console.log(fullText[0].value);

      for(let i = 0; i < fullText.length; i++){
        if(fullText[i].value.endsWith("-")){
          fullText[i].value = fullText[i].value + " " + fullText[i+1].value;
          fullText[i+1].value = "";
          if(fullText[i].value.includes("-")){
            fullText[i].value = fullText[i].value.replace("-","");
          }
        }
        if(fullText[i].value === ""){
          continue;
        }else{
          fullText.splice(i+1,1);
        }
      } 
      setJson(fullText);
      setDefaultValues(fullText)
  }

  const DisplayResults = () => {
    return (
      <React.Fragment>
        <img src={analysis.URL} className="ui-formia-container-img" border="1" alt={(analysis.description && analysis.description.captions && analysis.description.captions[0].text ? analysis.description.captions[0].text : "can't find caption")} />
      </React.Fragment>
    )
  };

  const handleChangeFile = (upload_file) => {
    const formData = new FormData();
    setProcessing(true);
    formData.append("imagen",  upload_file[0])
    fetch(`/settings/intelligent_images`, {
        method: 'POST', // or 'PUT'
        body: formData, // data can be `string` or {object}!
        headers: {}
    })
    .then(res => res.json())
    .catch(error => console.error("Error:", error))
    .then(data => {
      onFileUrlEntered(data.register.imagen.url);
    });
  }
  
  const Analyze = () => {
    return (
    <div>
      {!processing &&
        <div className="card card-modules-user ui-indexFavorite-Scontainer">
          <div className="card-body">
            <div className="row">

              <div className="col-md-6">
                <label>Selecciona un tablero, para que las tareas que estén en la imagen vayan al tablero seleccionado.</label>
                <select
                  className="mt-2 form form-control ui-formia-select-boardTask"
                  name="task_board_id"
                  onChange={handleChange}
                  value={task_board_id}
                >
                    <option value={""}>{"Tablero"}</option>
                    {props.task_boards.map(task_board => (
                      <option value={task_board.value}>{task_board.label}</option>
                    ))}
                </select>
              </div>

              <div className="col-md-6 ui-formia-dropzone">
                {task_board_id && (
                  <Dropzone onDrop={file => handleChangeFile(file)}>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div className="dropzone" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>Subir imagen</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}
              </div>

            </div>
          </div>
        </div>
      }

      {processing && (
        <div className="card">
          <div className="card-body">
            <div><h3 className='text-center' style={{color: '#4cd0e1', fontWeight: '600'}}>Analizando Imagen</h3>  </div>
            <Preloader />
          </div>                           
        </div>
      )}

      </div>
    )
  }
  
  const CantAnalyze = () => {
    return (
      <div className="alert alert-info" role="alert">
        ¡No se pudo analizar la imagen, intento nuevamente!
      </div>
    )
  }

  const cancelCreate = () => {
    setTaskBoardId("");
    setJson([]);
    setAnalysis(null);
    setFileSelected("");
  }

  const createTask = () => {

    const form = {
      tasks: json,
      task_board_id: task_board_id,
    }

    fetch(`/task/create_task_imagen`, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(form), // data can be `string` or {object}!
        headers: {
            "X-CSRF-Token": token,
            "Content-Type": "application/json"
        }
    })

    .then(res => res.json())
    .catch(error => console.error("Error:", error))
    .then(data => {
      cancelCreate();
      Swal.fire({
        position: "center",
        type: `${data.type}`,
        title: `${data.success}`,
        showConfirmButton: false,
        timer: 1500,
      });
    });
  }
  
  function Render() {
    const ready = ComputerVisionIsConfigured(props.endpoint, props.keyEndpoint);
    if (ready) {
      return <Analyze />;
    }
    return <CantAnalyze />;
  }
  

  return (
    <React.Fragment>
    {Render()}

    {analysis && (
      <div className="card card-modules-user ui-indexFavorite-Scontainer ui-formia-card mt-5">
        <div className="card-body">
          <div className="row">

            <div className="col-md-6">
              {analysis && DisplayResults()}
            </div>

            <div className="col-md-6">
              {json.map((item, index) => {
                  return (

                                <div key={index} className='response--container'>
                                <div className='response--item'>
                                  {item.value ?
                        <textarea id={item.id} name="value" className='form form-control mt-3 mb-4' value={item.value} onChange={eachInputOnChange} />
                        :
                                    <div></div>
                                  }
                                </div>
                          </div>
                  )
              })}

              {json.length >= 1 ? <React.Fragment><div ></div> <button className="ml-2 btn btn-outline-danger mb-2 mt-2" onClick={() => cancelCreate()}>Cancelar</button> <button className="ml-2 btn btn-info mb-2 mt-2" onClick={() => createTask()}>Generar como tarea</button> </React.Fragment> : null }
            </div>

          </div>
        </div>
      </div>
    )}

    </React.Fragment>
  );
}

export default FormIa;
WebpackerReact.setup({ FormIa });