import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import CheckboxContainer from '../components/settings/Tenants/CheckboxContainer'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class TenantModule extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modules: [],
            isLoaded: true,
            checkedItems: new Map(),
            form: {
                section_accion_app_ids: []
            }
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    }
    
    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };


    loadData = () => {
        fetch(`/settings/get_accions_tenants`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
    
        let map = new Map();

          data[1].forEach(element => {
            this.props.tenant.section_accion_app.forEach(element_accion => {
              if (element.id == element_accion.id) {
                map.set(element_accion.id.toString(), true)
              }
            });
          })


          this.setState({
            checkboxes: data[1],
            modules: data[0],
            checkedItems: map,
            isLoaded: false
          });

        });
    }

    handleChangeAccions = e => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        this.setState(prevState => ({
          checkedItems: prevState.checkedItems.set(item, isChecked)
        }));
    };

    HandleClick = e => {
        let section_accion_app_ids = [];
        let form = {};
        form = this.state.form

        this.state.checkedItems.forEach((value, key) => {
            if (value == true) {
                section_accion_app_ids.push(key);
            }
        });

        form.section_accion_app_ids = section_accion_app_ids;
    
    
        fetch("/settings/update_modules/" + this.props.tenant.id, {
            method: "PATCH", // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
              "X-CSRF-Token": this.token,
              "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(response => {
            this.messageSuccess(response);
            Turbolinks.visit( "/settings/tenants");
         
        });
    }


    render() {
        return (
            <React.Fragment>
                <HeaderTitle 
                    title={this.props.tenant.name} 
                    subTitle={`Gestión de módulos para la empresa ${this.props.tenant.name}`}
                    titleNew={"Nuevo módulo"}
                    titleFilter={"Filtros"}
                    icon={"fas fa-user-tie"}
                    onClickNew={""}
                    showNew={false}
                    showFilter={false}
                />

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <CheckboxContainer
                            checkedItems={this.state.checkedItems}
                            handleChangeAccions={this.handleChangeAccions}
                            checkboxes={this.state.checkboxes}
                            modules={this.state.modules}
                        />

                        <div className="col-md-12 text-right">
                            <a className="btn-transition btn btn-outline-light" href="/settings/tenants" data-turbolinks="true">Volver</a>
                            <button className="btn-shadow  btn btn-info ml-3" onClick={this.HandleClick}>Actualizar módulos</button>
                        </div>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}


export default TenantModule;
WebpackerReact.setup({ TenantModule });

