import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Preloader from '../../../GeneralComponents/LoadingComponen';
import QRCode from 'react-qr-code';

class EditQrWidth extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            form: {
                qr_width: 3
            },

            format_pdf: {},
            isLoaded: true
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/formatos/get_format_pdf/${this.props.format_pdf_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                format_pdf: data.data,
                isLoaded: false,
                form: {
                    ...this.state.form,
                    qr_width: data.data.qr_width
                }
            })
        });
    }
    
    handleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                qr_width: Number(e.target.value)
            }
        });
    }
    
    handleClick = () => {
        fetch(`/formatos/format_pdfs/${this.props.format_pdf_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => { 
            this.props.messageSuccess(data);
            this.props.toggle();
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    {/*<ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>*/}

                    <ModalBody>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    {!this.state.isLoaded ? (
                                        <React.Fragment>
                                            <div className="col-md-12 mb-4 text-center">
                                                <img 
                                                    src={"https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/298347/qrcode.png"} 
                                                    alt="imagen" 
                                                    style={{ width: `${this.state.form.qr_width * 40}px` }} 
                                                />
                                            </div>


                                            <div className="col-md-12 mb-3 text-center">
                                                <input 
                                                    style={{ width: "100%" }} 
                                                    type="range" 
                                                    name="qr_width" 
                                                    min="1" 
                                                    max="11"
                                                    value={this.state.form.qr_width}
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                            <div className="col-md-12 mt-3">
                                                <div class="alert alert-info" role="alert">
                                                    <b>Para que la imagen coincida con la que se muestra en el PDF, el zoom de la pantalla debe estar configurado al 100%</b>
                                                </div>
                                            </div>                  
                                        </React.Fragment>
                                    ) : (
                                        <Preloader/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                        <button className="btn-shadow btn btn-info" onClick={() => this.handleClick()}>Actualizar</button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default EditQrWidth;