import React, { Component, CSSProperties } from 'react';
import Preloader from '../../../GeneralComponents/LoadingComponen';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ShowTask from '../../tasks/tasksCard/ShowTask'
import FormCreateModal from "../../tasks/tasksCard/FormCreateModal";
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import FormFilter from '../../tasks/tasksCard/FormFilter'
import { isMobile, isTablet } from 'react-device-detect';
import * as rdd from 'react-device-detect';

//views 
import IndexMovil from './viewTask/IndexMovil';
import IndexTablet from './viewTask/IndexTablet';
import IndexWeb from './viewTask/IndexWeb';

//moment
import moment from 'moment';
import BreadcrumbTasks from '../../../GeneralComponents/Breadcrumb/BreadcrumbTasks';

class TableTask extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            first_data: [],
            users: [],
            isLoaded: true,
            modal: false,
            modalShow: this.props.task ? true : false,
            modeEdit: false,
            ErrorValues: true,
            showFilter: false,
            isFiltering: false,
            id: "",
            task_id: "",
            url: this.props.url_table,

            formCreate: {
                name: "",
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                notify: false,
                user_owner_id: "",
                column_id: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },


            selectedOptionColumnTable: {
                column_id: "",
                label: "Responsable"
            },

            selectedOptionColumn: {
                column_id: "",
                label: ""
            },

            selectedOptionTaskBoard: {
                task_board_id: "",
                label: ""
            },

            users: [],
            columns: [],
            task: this.props.task,
            estados: {},

            //pagination

            activePage: 1,
            countPage: 30,
            total_data: 0
        }
    }

    urlFilter = (url) => {
        this.setState({
            url: url,
        })
    }


    //pagination 

    handlePageChange = pageNumber => {
        const filter_url = `${this.state.url}${this.state.isFiltering ? "&" : "?"}page=${pageNumber}&filter=${this.state.countPage}`
        this.setState({ activePage: pageNumber });
        fetch(filter_url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    total_data: data.total_data,
                });
            });
    };


    componentDidMount = () => {
        this.configSelect();
        this.loadData();

        if (this.props.task_board) {
            const selectedOptionTaskBoard = {
                label: this.props.task_board.name,
                value: this.props.task_board.id,
            }

            this.handleChangeAutocompleteTaskBoard(selectedOptionTaskBoard)
        }
    }

    configSelect = () => {
        let arrayUsers = []

        this.props.users.map((item) => (
            arrayUsers.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: arrayUsers,
        })
    }

    loadData = () => {
        fetch(this.state.url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    estados: data.estados,
                    data: data.data,
                    first_data: data.data,
                    total_data: data.total_data,
                    isLoaded: false,
                    activePage: 1,
                });
            });
    }

    updateItem = task => {
        this.setState({
            data: this.state.data.map(item => {
                if (task.id === item.id) {
                    return {
                        ...item,
                        description: task.description,
                        due_date: task.due_date,
                        name: task.name,
                        columna: task.columna,
                        state: task.state,
                        user_owner: task.user_owner,
                        general_comments: task.general_comments,
                        general_files: task.general_files,
                        task_labels: task.task_labels,
                        users: task.task_labels,
                        checklist: task.checklist,
                        url_source: task.url_source,
                        task_source: task.task_source,
                    }
                }
                return item;
            })
        });
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data].reverse(),
            isLoaded: false,
            isFiltering: false,
        })
    }

    delete = (e, id) => {
        e.stopPropagation();
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/task/delete_task/${id}`, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData();
                    });
            }
        });
    };


    clearValues = () => {
        this.setState({
            formCreate: {
                name: "",
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                notify: false,
                user_owner_id: "",
                column_id: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            selectedOptionColumn: {
                column_id: "",
                label: ""
            },

            selectedOptionTaskBoard: {
                task_board_id: "",
                label: ""
            },

            columns: this.props.task_board ? this.state.columns : [],

            modal: false,
            modeEdit: false,
        })
    }

    messageSuccess = (response) => {
        if(response.type != "error"){
            Swal.fire({
                position: "center",
                type: `${response.type}`,
                title: `${response.success}`,
                showConfirmButton: false,
                timer: 1500,
            });
        }else{
            Swal.fire({
                position: "center",
                type: `${response.type}`,
                title: `${response.success}`,
                showConfirmButton: true,
            });
        }
    };

    validationForm = () => {
        if (this.state.formCreate.name != "" &&
            this.state.formCreate.due_date != "" &&
            this.state.formCreate.user_owner_id != "" &&
            this.state.formCreate.column_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.validationForm()) {
            if (!this.state.modeEdit)
                fetch(`/task/tasks`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type != "error"){
                        this.setState({ modal: false })
                        this.messageSuccess(data);
                        this.updateData(data.task);
                        this.clearValues();
                    }else{
                        this.setState({ modal: false })
                        this.messageSuccess(data);
                        this.clearValues();
                    }
                });
            else {
                fetch(`/task/tasks/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false })
                        this.messageSuccess(data);
                        this.updateItem(data.task);
                        this.clearValues();
                    });
            }
        }
    }

    HandleChange = (e) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [e.target.name]: e.target.value
            }
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            formCreate: {
                ...this.state.formCreate,
                user_owner_id: selectedOption.value
            }
        });
    };


    handleChangeAutocompleteColumn = (selectedOptionColumn) => {
        this.setState({
            selectedOptionColumn,
            formCreate: {
                ...this.state.formCreate,
                column_id: selectedOptionColumn.value
            }
        });
    }


    handleChangeAutocompleteTaskBoard = selectedOptionTaskBoard => {
        fetch(`/task/get_task_board_columns/${selectedOptionTaskBoard.value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                const arrayColumns = [];

                data.columns.map((item) => (
                    arrayColumns.push({ label: item.title, value: item.id })
                ))

                this.setState({
                    selectedOptionTaskBoard,
                    columns: arrayColumns,
                });
            });
    }


    edit = (task) => {
        this.setState({

            formCreate: {
                ...this.state.formCreate,
                name: task.name,
                due_date: task.due_date,
                user_owner_id: task.user_owner_id,
                column_id: task.columna.id,
            },

            selectedOption: {
                user_owner_id: task.user_owner_id,
                label: `${task.user_owner.first_name} ${task.user_owner.first_last_name}`
            },

            selectedOptionColumn: {
                column_id: task.columna.id,
                label: `${task.columna.title}`
            },

            id: task.id,
            modal: true,
            modeEdit: true
        })
    }

    handleClickShow = (id) => {
        fetch(`/task/get_show_task/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    task: data.task,
                    modalShow: true,
                })
            });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modalShow: true })
        } else {
            this.setState({ modalShow: false, task: {} })
        }
    }

    toogleNew = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }


    get_color = (task) => {
        if (task.state == "done" && task.fulfilled == "no") {
            return "red"

        } else if (task.state == "done" && task.fulfilled == "si") {
            return "green"

        } else if (task.state == "filed" && task.fulfilled == "si") {
            return "green"

        } else if (task.state == "filed" && task.fulfilled == "no") {
            return "red"

        } else {
            let state = task.due_date
            let resta = new Date(state).getTime() - new Date().getTime()
            resta = Math.round((resta / (1000 * 24 * 60 * 60)));
            resta = resta + 1
            if (resta >= 5) {
                return "green"
            } else if (resta < 5 && resta >= 0) {
                return "orange"
            } else {
                return "red"
            }
        }
    }

    updateDataFilter = (data) => {
        this.setState({
            data: data.data,
            total_data: data.total_data,
        })
    }

    updateStateIsLoaded = (state) => {
        this.setState({
            isLoaded: state,
        })
    }

    cancelFilter = () => {
        this.setState({
            isFiltering: false,
            isLoaded: false,
            showFilter: false,
            url: this.props.url_table,
        }, () => {
            this.loadData();
        });
    }

    handleClickShowFilter = () => {
        this.setState({
            showFilter: !this.state.showFilter,
            isFiltering: true,
        })
    }


    updateColumn = (column_id) => {
        fetch(`/task/update_colum_task/${column_id}/${this.state.task_id}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ task_id: "" })
                this.updateItem(data.task)
            });
    }


    editState = (e, id, state) => {
        e.stopPropagation();
        fetch(`/task/update_state_filed_table/${id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.loadData();
            });
    }

    restoreTask = (e, id) => {
        e.stopPropagation();
        fetch(`/task/restore_task/${id}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.loadData();
            });
    }

    showPopover = (e, task_id) => {
        e.stopPropagation();
        this.setState({ task_id: task_id })
    }

    cancelPopoverUpdateColumn = () => {
        this.setState({ task_id: "" })
    }

    onChangeDate = (e) => {
        fetch(`${this.props.url_table}?date=${e.target.value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    estados: data.estados,
                    data: data.data,
                    first_data: data.data,
                    total_data: data.total_data,
                    isLoaded: false
                });
            });
    }

    handleChangeSwitch = (value, field) => {
        this.setState({
            formCreate: {
                ...this.state.formCreate,
                [field]: value,
            }
        });
    }

    selectView = () => {
        if (isMobile && rdd.mobileModel != "iPad") {
            return (
                <IndexMovil
                    data={this.state.data}
                    restore={this.props.restore}
                    restoreTask={this.restoreTask}
                    editState={this.editState}
                    delete={this.delete}
                    toogleNew={this.toogleNew}
                    showOptions={this.props.showOptions}
                    task_id={this.state.task_id}
                    handleClickShow={this.handleClickShow}
                    showNew={this.props.showNew}

                    cancelPopoverUpdateColumn={this.cancelPopoverUpdateColumn}
                    updateColumn={this.updateColumn}
                    task_board={this.props.task_board}
                    task_boards={this.props.task_boards}
                    showPopover={this.showPopover}
                    estados={this.state.estados}
                    get_color={this.get_color}
                    onChangeDate={this.onChangeDate}

                    //pagination

                    activePage={this.state.activePage}
                    countPage={this.state.countPage}
                    total_data={this.state.total_data}
                    handlePageChange={this.handlePageChange}
                />
            )
        } else if (isTablet && rdd.mobileModel == "iPad") {
            return (
                <IndexTablet
                    data={this.state.data}
                    restore={this.props.restore}
                    restoreTask={this.restoreTask}
                    editState={this.editState}
                    delete={this.delete}
                    toogleNew={this.toogleNew}
                    showOptions={this.props.showOptions}
                    task_id={this.state.task_id}
                    handleClickShow={this.handleClickShow}
                    showNew={this.props.showNew}
                    get_color={this.get_color}
                    onChangeDate={this.onChangeDate}

                    cancelPopoverUpdateColumn={this.cancelPopoverUpdateColumn}
                    updateColumn={this.updateColumn}
                    task_board={this.props.task_board}
                    task_boards={this.props.task_boards}
                    showPopover={this.showPopover}
                    estados={this.state.estados}

                    //pagination

                    activePage={this.state.activePage}
                    countPage={this.state.countPage}
                    total_data={this.state.total_data}
                    handlePageChange={this.handlePageChange}
                    //ref
                    scrollRef={this.scrollRef}
                />
            )
        } else {
            return (
                <IndexWeb
                    data={this.state.data}
                    restore={this.props.restore}
                    restoreTask={this.restoreTask}
                    editState={this.editState}
                    delete={this.delete}
                    toogleNew={this.toogleNew}
                    showOptions={this.props.showOptions}
                    task_id={this.state.task_id}
                    handleClickShow={this.handleClickShow}
                    showNew={this.props.showNew}
                    get_color={this.get_color}
                    onChangeDate={this.onChangeDate}

                    cancelPopoverUpdateColumn={this.cancelPopoverUpdateColumn}
                    updateColumn={this.updateColumn}
                    task_board={this.props.task_board}
                    task_boards={this.props.task_boards}
                    showPopover={this.showPopover}
                    estados={this.state.estados}

                    //pagination

                    activePage={this.state.activePage}
                    countPage={this.state.countPage}
                    total_data={this.state.total_data}
                    handlePageChange={this.handlePageChange}

                    //ref
                    scrollRef={this.scrollRef}

                />
            )
        }
    }


    render() {
        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        };

        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        if (this.state.isLoaded) {
            return (
                <div className="card">
                    <div className="card-body">
                        <Preloader />
                    </div>
                </div>
            )
        }

        return (
            <React.Fragment>
                {this.state.showFilter && (
                    <FormFilter
                        from={this.props.from}
                        index={true}
                        url={this.props.url_table}

                        //values data
                        users={this.props.users}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        procesos={this.props.procesos}
                        task_sources={this.props.task_sources}

                        task_board={this.props.task_board}
                        task_boards={this.props.task_boards}

                        //update states
                        updateStateIsLoaded={this.updateStateIsLoaded}
                        updateDataFilter={this.updateDataFilter}
                        cancelFilter={this.cancelFilter}

                        //states
                        isFiltering={this.state.isFiltering}
                        urlFilter={this.urlFilter}

                        //ref
                        scrollRef={this.scrollRef}
                    />
                )}

                {this.state.modalShow && (
                    <ShowTask
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalShow}
                        toggle={this.toogle}
                        title={"Crear tarea"}

                        //form props
                        task={this.state.task}

                        loadTasks={this.loadDataShow}
                        users={this.props.users}

                        //load show
                        isLoadedShow={false}

                        //current_user
                        user={this.props.user}

                        //load tasks
                        loadData={this.loadData}

                        //test load data

                        updateItem={this.updateItem}
                        updateData={this.updateData}

                        //procesos 
                        procesos={this.props.procesos}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                    />
                )}

                {this.state.modal && (
                    <FormCreateModal
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogleNew}
                        title={this.state.modeEdit ? "Actualizar tarea" : "Crear tarea"}

                        //form props
                        HandleChange={this.HandleChange}
                        form={this.state.formCreate}
                        clearValues={this.clearValues}
                        loadData={this.props.loadData}
                        updateStateLoad={this.props.updateStateLoad}
                        HandleClickCreateTask={this.HandleClick}
                        selectColumn={true}
                        selectDate={true}

                        //select autocomplete
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOption={this.state.selectedOption}
                        users={this.props.users}

                        //select autocomplete columns
                        handleChangeAutocompleteColumn={this.handleChangeAutocompleteColumn}
                        selectedOptionColumn={this.state.selectedOptionColumn}
                        columns={this.state.columns}

                        ErrorValues={this.state.ErrorValues}

                        handleChangeAutocompleteTaskBoard={this.handleChangeAutocompleteTaskBoard}
                        task_board={this.props.task_board}
                        task_boards={this.props.task_boards}
                        selectedOptionTaskBoard={this.state.selectedOptionTaskBoard}
                        handleChangeSwitch={this.handleChangeSwitch}
                    />
                )}

                <BreadcrumbTasks
                    handleClickShowFilter={this.handleClickShowFilter}
                    updateView={this.props.updateView}
                    listView={this.props.listView}                    
                />

                {this.selectView()}

            </React.Fragment>
        );
    }
}

export default TableTask;
