import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            showTable: false,
            ErrorValues: true,
            format_association_id: "",

            form: {
                format_id: this.props.format.id,
                question_target_id: "",
                format_select_id: "",
                question_asociation_id: "",
                question_condition_id: "",
                question_format_id: "",
                operation: "",
                comparation_value: "",
            },

            selectedOption: {
                label: "Selecione un formato",
                format_select_id: ""
            },

            selectedOptionQuestionValues: {
                label: "Selecione una pregunta",
                question_asociation_id: ""
            },

            selectedOptionQuestion: {
                label: "Selecione una pregunta",
                question_target_id: "",
            },

            selectedOptionQuestionCondition: {
                label: "Selecione una pregunta",
                question_condition_id: "",
            },

            questions: [],
            questions_values: [],
        }
    }

    LoadQuestion = (format_id) => {
        fetch(`/formatos/get_questions/${format_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                let array = [];

                data.register.map((item) => (
                    array.push({ label: item.question, value: item.id })
                ))

                this.setState({
                    questions_values: array
                })

            });
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.clearValues();
            this.setState({ modal: false })
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
                if (format.id === item.id) {
                    return {
                        ...item,
                        comparation_value: format.description,
                        format_select: format.format_select,
                        format_select_id: format.format_select_id,
                        operation: format.operation,
                        question_asociation: format.question_asociation,
                        question_asociation_id: format.question_asociation_id,
                        question_condition: format.question_condition,
                        question_condition_id: format.question_condition_id,
                        question_target: format.question_target,
                        question_target_id: format.question_target_id,
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            form: {
                ...this.state.form,
                question_target_id: "",
                format_select_id: "",
                question_asociation_id: "",
                question_condition_id: "",
                question_format_id: "",
                operation: "",
                comparation_value: "",
            },

            selectedOption: {
                label: "Selecione un formato",
                format_select_id: ""
            },

            selectedOptionQuestionValues: {
                label: "Selecione una pregunta",
                question_asociation_id: ""
            },

            selectedOptionQuestion: {
                label: "Selecione una pregunta",
                question_target_id: "",
            },

            selectedOptionQuestionCondition: {
                label: "Selecione una pregunta",
                question_condition_id: "",
            },

            questions_values: [],
        })
    }

    clearSeccion = () => {
        this.setState({
            form: {
                ...this.state.form,
                question_condition_id: "",
                comparation_value: ""
            },

            selectedOptionQuestionCondition: {
                label: "Selecione una pregunta",
                question_condition_id: "",
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_associations/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
        //this.selectConfiguration();
    }

    selectConfiguration = () => {
        let array = []

        this.props.steps_questions.map((item) => (
            array.push({ label: item.question, value: item.id })
        ))

        this.setState({
            questions: array,
        })
    }

    loadData = () => {
        fetch(`/formatos/get_question_associations/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    isLoaded: false
                });
            });
    }

    loadDataSelects = () => {
        fetch(`/formatos/data_questions/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                let array = []

                data.steps_questions.map((item) => (
                    array.push({ label: item.question, value: item.id })
                ))

                this.setState({
                    questions: array,
                    modal: true
                });
            });
    }

    validationForm = () => {
        if (this.state.form.question_target_id != "" &&
            this.state.form.format_select_id != "" &&
            this.state.form.operation != "" &&
            this.state.form.question_asociation_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.validationForm() == true) {
            if (this.state.modeEdit) {
                fetch(`/formatos/question_associations/${this.state.format_association_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            } else {
                fetch(`/formatos/question_associations`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateData(data.register)
                        this.clearValues();
                        this.setState({ modal: false, showTable: true })
                    });
            }
        }
    }


    edit = (format_association) => {
        this.LoadQuestion(format_association.format_select_id)

        this.setState({
            modal: true,
            modeEdit: true,
            format_association_id: format_association.id,

            form: {
                format_select_id: format_association.format_select.id,
                description: format_association.description,
                question_asociation_id: format_association.question_asociation_id,
                question_target_id: format_association.question_target_id,
                operation: format_association.operation,
                comparation_value: format_association.comparation_value,
            },

            selectedOption: {
                label: `${format_association.format_select.name}`,
                format_select_id: format_association.format_select.id,
            },

            selectedOptionQuestion: {
                label: `${format_association.question_target != null ? format_association.question_target.question : "Selecione una pregunta"}`,
                question_target_id: format_association.question_target_id,
            },

            selectedOptionQuestionValues: {
                label: `${format_association.question_asociation != null ? format_association.question_asociation.question : "Selecione una pregunta"}`,
                question_asociation_id: format_association.question_asociation_id,
            },

            selectedOptionQuestionCondition: {
                label: `${format_association.question_condition != null ? format_association.question_condition.question : "Selecione una pregunta"}`,
                question_condition_id: format_association.question_condition_id,
            },
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                format_select_id: selectedOption.value
            }
        });

        this.LoadQuestion(selectedOption.value)
    };

    handleChangeAutocompleteQuestionsValues = selectedOptionQuestionValues => {
        this.setState({
            selectedOptionQuestionValues,
            form: {
                ...this.state.form,
                question_asociation_id: selectedOptionQuestionValues.value
            }
        });
    }

    handleChangeAutocompleteQuestions = selectedOptionQuestion => {
        this.setState({
            selectedOptionQuestion,
            form: {
                ...this.state.form,
                question_target_id: selectedOptionQuestion.value
            }
        });
    };


    handleChangeAutocompleteQuestionCondition = selectedOptionQuestionCondition => {
        this.setState({
            selectedOptionQuestionCondition,
            form: {
                ...this.state.form,
                question_condition_id: selectedOptionQuestionCondition.value
            }
        });
    };


    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <ModalBody>
                        {this.state.modal && (
                            <FormCreate
                                backdrop={"static"}
                                modal={this.state.modal}
                                toggle={this.toogle}
                                title={this.state.modeEdit ? "Actualizar asociación" : "Crear asociación"}
                                nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}
                                modeEdit={this.state.modeEdit}

                                //form props
                                formValues={this.state.form}
                                onChangeForm={this.HandleChange}
                                submitForm={this.HandleClick}
                                errorValues={this.state.ErrorValues}
                                clearSeccion={this.clearSeccion}

                                //select values
                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                selectedOption={this.state.selectedOption}
                                formatos={this.props.formatos}

                                //select questions 
                                handleChangeAutocompleteQuestions={this.handleChangeAutocompleteQuestions}
                                selectedOptionQuestion={this.state.selectedOptionQuestion}
                                questions={this.state.questions}

                                //select question find
                                handleChangeAutocompleteQuestionsValues={this.handleChangeAutocompleteQuestionsValues}
                                selectedOptionQuestionValues={this.state.selectedOptionQuestionValues}
                                questions_values={this.state.questions_values}

                                //select question condition
                                handleChangeAutocompleteQuestionCondition={this.handleChangeAutocompleteQuestionCondition}
                                selectedOptionQuestionCondition={this.state.selectedOptionQuestionCondition}

                            />
                        )}

                        <div className="col-md-12 mb-3 text-right pr-0">
                            {!this.state.modal && (
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.loadDataSelects()}
                                >
                                    Abrir formulario
                                </button>
                            )}
                        </div>

                        <div className="card">
                            <div className="background-gray">
                                <table className="table " id="sampleTable">
                                    <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                        <tr>
                                            <th>Pregunta Objetivo</th>
                                            <th>Formato Seleccionado</th>
                                            <th>Pregunta Asociada</th>
                                            <th>Operación</th>
                                            <th className="text-right">Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map((question_association, index) => (
                                                <React.Fragment  key={question_association.id}>
                                                    {index >= 1 ? <div className="mb-2"></div> : null}
                                                    <tr className="tr-table-task" style={{ borderLeft: `4px solid green` }}>
                                                        <td>{question_association.question_target.question}</td>
                                                        <td>{question_association.format_select.name}</td>
                                                        <td>{question_association.question_asociation != null ? question_association.question_asociation.question : ""}</td>
                                                        <td>{question_association.operation}</td>
                                                        <td className="text-right">
                                                            {true && (
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(question_association)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}
                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(question_association.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/*<div className="card mb-3">
                        <div className="card-header" style={{ display: "flow-root", paddingTop: 12 }}>
                            Asociacion de preguntas

                            <button
                                className="btn-shadow btn btn-info float-right"
                                onClick={() => this.loadDataSelects()}
                            >
                                Crear
                            </button>

                            <a className="float-right mr-3" data-toggle="collapse" href="#collapseExampleQuestionAssociatio" role="button" aria-expanded="false" aria-controls="collapseExampleQuestionAssociatio">
                                <i className="fas fa-angle-down"></i>
                            </a>
                        </div>

                        <div className="card-body p-3">
                            <div className="row">

                                <div className={`collapse ${this.state.showTable ? "show" : ""} w-100`} id="collapseExampleQuestionAssociatio">
                                    <div className="col-md-12">
                                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                            <thead>
                                                <tr>
                                                    <th>Formato Seleccionado</th>
                                                    <th>Pregunta asociada</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.state.data.length >= 1 ? (
                                                    this.state.data.map(question_association => (
                                                        <tr key={question_association.id}>
                                                            <td>{question_association.format_select.name}</td>
                                                            <td>{question_association.question_asociation != null ? question_association.question_asociation.question : ""}</td>

                                                            {true && (
                                                                <td className="text-right" style={{ width: "10px" }}>
                                                                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                            <div className="btn-group" role="group">
                                                                                <button
                                                                                    className="btn-shadow btn btn-info"
                                                                                    id="btnGroupDrop1"
                                                                                    type="button"
                                                                                    data-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <i className="fas fa-bars"></i>
                                                                                </button>
                                                
                                                                                <div className="dropdown-menu dropdown-menu-right">

                                                                                    {true && (
                                                                                        <a
                                                                                            className="dropdown-item"
                                                                                            onClick={() => this.edit(question_association)}
                                                                                            
                                                                                        >
                                                                                            Editar
                                                                                        </a>
                                                                                    )}

                                                                                    {true && (  
                                                                                        <button
                                                                                            onClick={() => this.delete(question_association.id)}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            Eliminar
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                ) : (
                                                        <tr>
                                                            <td colSpan="8" className="text-center">
                                                                <div className="text-center mt-4 mb-4">
                                                                    <h4>No hay registros</h4>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>*/}

                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;
