import React, { Component } from 'react';
import Table from '../components/documentManagement/ExternalDocument/Index';
import FormFilter from '../components/documentManagement/ExternalDocument/FormFilter';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle";
import Preloader from '../GeneralComponents/LoadingComponen';

import { isMobile } from 'react-device-detect';

//moment
import moment from 'moment';

class ExternalDocumentIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            data: [],
            isLoaded: true,
            showFilter: false,
            filtering: false,

            formFilter: {
                name: "",
                proceso_id: "",
                responsable_user_id: "",
                emisor: "",
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                creation_date: isMobile ? moment().format('YYYY-MM-DD') : '',
            },

            selectedOptionUser: {
                responsable_user_id: "",
                label: "Usuario"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },


            procesos: [],
            users: [],
            positions: [],

            //pagination 

            activePage: 1,
            countPage: 10,
            external_documents_total: 0
        }
    }

    componentDidMount(){
        this.loadData();
        this.configSelect();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
            documents_total: this.state.documents_total + 1
        })
    }

    configSelect = () => {
        let arrayProcesos = [];
        let arrayUsers = [];
        let arrayPositions = [];

        this.props.procesos.map((item) => (
            arrayProcesos.push({label: `${item.name}`, value: item.id})
        ))

        this.props.users.map((item) => (
            arrayUsers.push({label: `${item.name}`, value: item.id})
        ))
        
        this.props.positions.map((item) => (
            arrayPositions.push({label: `${item.name}`, value: item.id})
        ))

        this.setState({
            procesos: arrayProcesos,
            users: arrayUsers,
            positions: arrayPositions,
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
            if (format.id === item.id) {
              return { ...item, 
                name: format.name,
                emisor: format.emisor,
                due_date: format.due_date,
                creation_date: format.creation_date,
                proceso: format.proceso,
                responsable_user: format.responsable_user,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/document_management/get_external_documents/${this.props.state}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                external_documents_total: data.external_documents_total,
                isLoaded: false
            });
        });
    }

    HandleClickFilter = e => {
        this.setState({ isLoaded: true })
        fetch(`/document_management/get_external_documents/${this.props.state}?name=${this.state.formFilter.name}&proceso_id=${this.state.formFilter.proceso_id}&responsable_user_id=${this.state.formFilter.responsable_user_id}&emisor=${this.state.formFilter.emisor}&due_date=${this.state.formFilter.due_date}&creation_date=${this.state.formFilter.creation_date}&filtering=${true}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
          .then(response => response.json())
          .then(data => {
            this.setState({
                data: data.data,
                external_documents_total: data.external_documents_total,
                isLoaded: false,
            });
        });
    };

    clearValues = () => {
        this.setState({
            formFilter: {
                name: "",
                proceso_id: "",
                responsable_user_id: "",
                emisor: "",
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                creation_date: isMobile ? moment().format('YYYY-MM-DD') : '',
            },

            selectedOptionUser: {
                responsable_user_id: "",
                label: "Usuario"
            },

            selectedOptionProceso: {
                proceso_id: "",
                label: "Proceso"
            },

        })
    }

    filter = (state) => {
        this.setState({ showFilter: state })
        if (!state){
            this.loadData();
            this.clearValues();
        }
    }

    HandleChange = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        })
    }

    handleChangeAutocompleteUser = selectedOptionUser => {
        this.setState({
            selectedOptionUser,
            formFilter: {
                ...this.state.formFilter,
                responsable_user_id: selectedOptionUser.value
            }
        });
    };

    handleChangeAutocompleteProceso = selectedOptionProceso => {
        this.setState({
            selectedOptionProceso,
            formFilter: {
                ...this.state.formFilter,
                proceso_id: selectedOptionProceso.value
            }
        });
    };

    //pagination 

    handlePageChange = pageNumber => {
        this.setState({ activePage: pageNumber }); 
        fetch(`/document_management/get_external_documents/${this.props.state}?page=${pageNumber}&filter=${this.state.countPage}&filtering=${this.state.filtering}&work_description=${this.state.filtering ? this.state.formFilter.work_description : "" }&report_execute_id=${this.state.filtering ? this.state.formFilter.report_execute_id : ""}&date_ejecution=${this.state.filtering ? this.state.formFilter.date_ejecution : ""}&report_sate=${this.state.filtering ? this.state.formFilter.report_sate : ""}&cost_center_id=${this.state.filtering ? this.state.formFilter.cost_center_id : ""}&customer_id=${this.state.filtering && this.state.formFilter.customer_id != undefined  ? this.state.formFilter.customer_id : ""}&date_desde=${this.state.formFilter.date_desde}&date_hasta=${this.state.formFilter.date_hasta}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
          .then(response => response.json())
          .then(data => {
            this.setState({ 
                data: data.data,
                external_documents_total: data.external_documents_total,
            });
        });     
    };  

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={this.props.title}
                    subTitle={"Gestión documental"}
                    titleNew={"Nuevo documento"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={() => this.child.current.toogle("new")}
                    onClickFilter={() => this.filter(true)}
                    showNew={this.props.estados.create}
                    showFilter={true}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.showFilter && (
                    <FormFilter
                        HandleClickFilter={this.HandleClickFilter}
                        formValues={this.state.formFilter}
                        onChangeForm={this.HandleChange}
                        filter={this.filter}

                        //select values

                        //select UserCreate
                        selectedOptionUser={this.state.selectedOptionUser}
                        handleChangeAutocompleteUser={this.handleChangeAutocompleteUser}

                        //select Proceso
                        selectedOptionProceso={this.state.selectedOptionProceso}
                        handleChangeAutocompleteProceso={this.handleChangeAutocompleteProceso}

                        positions={this.state.positions}
                        document_types={this.state.document_types}
                        procesos={this.state.procesos}
                        users={this.state.users}
                    />
                )}
                
                {!this.state.isLoaded ? (
                    <Table
                        ref={this.child}
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        isLoaded={this.state.isLoaded}
                        updateItem={this.updateItem}
                        updateData={this.updateData}
                        estados={this.props.estados}

                        procesos={this.state.procesos}
                        users={this.state.users}
                        positions={this.state.positions}
                        state={this.props.state}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}

                        //pagination
                        totalData={this.state.external_documents_total}
                        activePage={this.state.activePage}
                        countPage={this.state.countPage}
                        handlePageChange={this.handlePageChange}
                    />
                ) : (
                    <div className="card">
                        <div className="card-body">
                            <Preloader />
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}


export default ExternalDocumentIndex;
WebpackerReact.setup({ ExternalDocumentIndex });

