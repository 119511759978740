import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalSelectType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            questions: [],
            data: [
                {name: "Básica", options: ["Respuesta breve", "Casillas de verificación", "Email", "Párrafo", "Fecha", "Campo numérico", "Hora", "Opción múltiple", "Fecha / Hora (Automática)", "Carga de archivos", "Link", this.props.step.step_position >= 1 ? "Visor PDF" : null ]},
                {name: "Selección", options: ["Lista desplegable", "Lista de procesos", "Lista de usuarios", "Lista de cargos"]},
                {name: "Especiales", options: ["Código", "Formato", "Campo dinámico", "Geolocalización", "Fórmula", "Subformulario", "Firma", "CE10", "Matriz"]},
            ]
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    componentDidMount = () => {
        this.loadQuestion();
    }

    loadQuestion = () => {
        fetch(`/formatos/get_questions/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({
                questions: data.register,
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className={`col-md-12`}>
                                    {this.state.data.map((values, index) => (
                                        <React.Fragment>
                                            {index >= 1 ? <hr /> : null}
                                            <b className="color-title">{values.name}</b>
                                            <hr />

                                            <div className="row">
                                                {values.options.filter((type_question) => (this.state.questions.filter((question) => question.question_type == "code_format").length >= 1 ? type_question != "Código" : type_question != "")  ).map((type, index) => (
                                                    <div className="col-md-6">
                                                        <p className={`list-format ${this.props.nameBtn == type ? "border-active" : ""}`} onClick={() => this.props.setName(type)}>{this.props.getIcon(type)} {type}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <button className="btn btn-outline-danger" onClick={() => this.props.toggle()}>Cerrar</button>
                            {/*<button className="btn-shadow btn btn-info" onClick={() => this.props.toggle()}>{"Confirmar"}</button>*/}
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalSelectType;
