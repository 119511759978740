// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("../packComponents/root")
/* require("../packComponents/template") */
require("../packComponents/FormCreatePosition")
require("../packComponents/IndexRol")
require("../packComponents/IndexTasks")
require("../packComponents/IndicatorIndex")
require("../packComponents/IndicatorShow")
require("../packComponents/IndicatorTableIndex")
require("../packComponents/IndicatorTableShow")
require("../packComponents/NewIndexProceso")
require("../packComponents/NormativitiesIndex")
require("../packComponents/NormativitiesScoresShow")
require("../packComponents/NormativitiesShow")
/* require("../packComponents/NotificationEventIndex")
require("../packComponents/NotificationEventNew")
require("../packComponents/NotificationEventShow") */
require("../packComponents/NotificationSafetyIndex")
require("../packComponents/NotificationSafetyNew")
require("../packComponents/NotificationSafetyShow")
require("../packComponents/NumeralParentsIndex")
/* require("../packComponents/PositionIndex") */
/* require("../packComponents/ProcesoIndex")
 */require("../packComponents/ProcesoScoreShow")
/* require("../packComponents/ProcesoShow")
 */require("../packComponents/RelatedEventIndex")
/* require("../packComponents/SectionIndex")
 */require("../packComponents/SpecificAreasIndex")
require("../packComponents/TaskIndexCard")
/* require("../packComponents/TenantShow") */
require("../packComponents/User")
require("../packComponents/NotificationTask")
require("../packComponents/NotificationDocument")
require("../packComponents/HistoryTask")
require("../packComponents/FormatsShow")
require("../packComponents/SurveysShow")
require("../packComponents/SurveysIndex")
require("../packComponents/RiskMatricesIndex")
require("../packComponents/RiskIndex")
require("../packComponents/RiskShow")
require("../packComponents/RiskConfig")
require("../packComponents/ConfigurationShow")
require("../packComponents/KnowledgeBaseIndex")
require("../packComponents/SurveyShowTask")
/* require("../packComponents/TenantIndex") */
require("../packComponents/PlanIndex")
/* require("../packComponents/SectionAppIndex") */
require("../packComponents/TenantModule")
require("../packComponents/EfectIndex")
require("../packComponents/EfectShow")
require("../packComponents/CauseShow")
require("../packComponents/CauseAnalysisIndex");
require("../packComponents/CauseAnalysisShow");
require("../packComponents/FormatCategories");
require("../packComponents/QuestionShow");
require("../packComponents/CategoryIndicatorsIndex")
require("../packComponents/IndexDofa");
require("../packComponents/ShowDofa");
require("../packComponents/CompanyObjetiveIndex");
require("../packComponents/ConfigurationTask");
require("../packComponents/CalendarTask");
require("../packComponents/IndexIcon");
require("../packComponents/DuplicateFormIndex");
require("../packComponents/IndexTaskBoard");
require("../packComponents/CustomPageIndex");
require("../packComponents/DashboardTask");
require("../packComponents/GanttDiagramSurvey");
require("../packComponents/FormatIndicatorIndex");
require("../packComponents/DocumentTypeIndex");
require("../packComponents/DocumentEdit");
require("../packComponents/DocumentIndex");
require("../packComponents/DocumentShow");
require("../packComponents/ExternalDocumentIndex");
require("../packComponents/FormatSubCategoryShow");
require("../packComponents/IndexTasksTable");
require("../packComponents/SurveyCustomView");
require("../packComponents/SurveyCustomDashboard");
require("../packComponents/ShowCustomView");
require("../packs/IndexFormatPdf");
require("../packComponents/FormatCategoryShow");
require("../packComponents/FormatTreeView");
require("../packComponents/HomeIndex");
require("../packComponents/FormIa");
require("../packComponents/AzureConfi");
require("../packComponents/FeaturePlanIndex");
require("../packComponents/PageIndex");
require("../packComponents/PageShow");
require("../packComponents/PageSettings");
require("../packComponents/PageAppearance");
require("../packComponents/MyDashboard");
require("../packComponents/CongratulationQuiz");
require("../packComponents/HeaderMenu");
require("../packComponents/HeaderMenuAdmin");
require("../packComponents/TenantAdminShow");
require("../packComponents/IndexAdminGestor");
require("../packComponents/NavTabBar");
require("../packComponents/ButtonFavorite");
require("../packComponents/RealTimeMessage");
require("../packComponents/BasicTutorialAdmin");
require("../packComponents/BasicTutorialIndex");
require("../packComponents/BasicTutorialShow");
require("../packComponents/OnBoardingStepIndex");
require("../packComponents/ChecklistIndex");
require("../packComponents/SoftwareChange");
require("../packComponents/SoftwareChangeIndex");
require("../packComponents/UpdateUserFirm");
require("../packComponents/HealthCode");
require("../packComponents/PlanCategoryIndex");
require("../packComponents/BeetreeShowPage");
require("../packComponents/HolidayIndex");
require("../packComponents/TrsDriverReviewConfigurationIndex");
require("../packComponents/TrsDriverReviewResult");
require("../packComponents/TenantIndexFormats");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
/* require("../packComponents/TenantShow") */