import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { HuePicker, ChromePicker } from 'react-color';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton,AccordionItemPanel, } from 'react-accessible-accordion';
import Preloader from '../../../GeneralComponents/LoadingComponen';

const FormCreate = (props) => {
    const [direction, setDirection] = useState("to left");
    const [colorGradient, setColors] = useState({ first_color: "#f3c153", second_color: "#1f3668", third_color: "#16aaff", color_text: "#ffff" });
    const [colorBackGround, setColorBackGround] = useState({ color: "#f3c153", color_text: "#ffff" });
    const [type_background, setTypeBackground] = useState("");
    const [selectedColor, setSelectedColor] = useState("");

    const token = document.querySelector("[name='csrf-token']").content;
    const typeFonts = [
        {
            font: "Roboto",
            name: "Aa",
            font_name: "Robot",
        },

        {
            font: "Rubik",
            name: "Aa",
            font_name: "Rubik",
        },

        {
            font: "Montserrat",
            name: "Aa",
            font_name: "Montserrat",
        },

        {
            font: "Arsenal",
            name: "Aa",
            font_name: "Arsenal",
        },

        {
            font: "Ruluko",
            name: "Aa",
            font_name: "Ruluko",
        },

        {
            font: "Fira Sans Condensed",
            name: "Aa",
            font_name: "Fira Sans",
        },


        {
            font: "Redressed",
            name: "Aa",
            font_name: "Redressed",
        },


        {
            font: "Handlee",
            name: "Aa",
            font_name: "Handlee",
        },


        {
            font: "Macondo",
            name: "Aa",
            font_name: "Macondo",
        },


        {
            font: "Times New Roman",
            name: "Aa",
            font_name: "Times New Roman",
        },

        {
            font: "Libre bodoni",
            name: "Aa",
            font_name: "Bodoni",
        },


        {
            font: "Cormorant Garamond",
            name: "Aa",
            font_name: "Garamond",
        }


    ]

    const [register, setRegister] = useState({});
    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        getInfo();
    }, [props.page_id]);

    const getInfo = () => {
        fetch(`/beetree/get_register_page/${props.page_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setRegister(data.register);
            setIsLoaded(false)
        });
    }

    const handleSubmit = e => {
        e.preventDefault();
    };

    const views = (class_name) => {
        return(
            <div className={`ui-btree-${class_name}-containerAll`}>
                <div className="ui-btree-mobileView-container-logo">
                    <div className="ui-btree-mobileView-logo">
                        <img src={register.enterprise_img_logo_company} alt="" />
                    </div>
                </div>

                <div className="ui-btree-mobileView-container-textEnterprise" style={{ fontFamily: props.formValues.background_style.fontFamily, color: props.formValues.background_style.color}}>
                    <div className="ui-btree-mobileView-nameEnterprise">
                        <span>{register.enterprise_name}</span>
                    </div>
                    <div className="ui-btree-mobileView-infocomplementEnterprise">
                        <span>{register.enterprise_aditional_info}</span>
                    </div>
                </div>
            
                {register.links.map((link, index) => (
                    <div className={`ui-btree-${class_name}-containerCard-link`} style={props.formValues.button_style} key={link.id}>
                        <div className="ui-btree-mobileView-iconSelect">
                            {link.icon ? <i className={link.icon.html_name}></i> : <i className="fab fa-reddit"></i>}
                        </div>

                        <div className="ui-btree-mobileView-title_link">
                            <a href={link.url} style={{ color: props.formValues.button_style.color }} target="_blank" >{link.title_link}</a>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const selectTypeBackground = () => {
        if(type_background == "gradient"){
            return(
                <React.Fragment>

                    <div className="col-md-12 mb-3">
                        <b>Comienzo del degradado</b>
                        <select
                            value={direction}
                            className="form form-control"
                            onChange={(e) => setDirection(e.target.value)}
                        >
                            <option value="to left">Desde la izquierda</option>
                            <option value="to right">Desde la derecha</option>
                            <option value="to top">Desde de Arriba</option>
                            <option value="to bottom">Desde de Abajo</option>
                            <option value="45deg">45deg</option>
                        </select>
                    </div>

                    <div className="col-md-12">
                        <div className="color-piker-template">
                            <ChromePicker 
                                color={colorGradient.first_color} 
                                onChange={(color) => onChangeForm({ target: { name: "first_color", value: color.hex } } )} 
                            />
                        </div>
                    </div>

                    <div className="col-md-12 mb-3 mt-3">
                        <div className="color-piker-template">
                            <ChromePicker 
                                color={colorGradient.second_color} 
                                onChange={(color) => onChangeForm({ target: { name: "second_color", value: color.hex } } )} 
                            />
                        </div>
                    </div>

                    <div className="col-md-12 mt-4">
                        <button className="btn-shadow btn btn-info" onClick={() => cancelselectedBackGround("gradient")}>Volver a la configuración</button>
                    </div>

                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    <div className="col-md-12 mt-3 mb-3">
                        <b>Color de fondo</b>
                        <div className="color-piker-template">
                            <ChromePicker 
                                color={props.formValues.background_style.background} 
                                onChange={(color) => props.handleChangeTemplate("background", color.hex)} 
                            />
                        </div>
                    </div>

                    <div className="col-md-12 mt-4">
                        <button className="btn-shadow btn btn-info" onClick={() => cancelselectedBackGround("background")}>Volver a la configuración</button>
                    </div>
                </React.Fragment>
            )
        }
    }

    const configurationTemplate = () => {
        return(
            <React.Fragment>
                <h6>Estilos de la página</h6>
                <Accordion allowZeroExpanded style={{width: '95%'}}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Tipo de color de fondo
                            </AccordionItemButton>
                        </AccordionItemHeading> 
                        <AccordionItemPanel>
                        <div className="ui-btree-appearance-personalized_background_Styles">
                            <div className="ui-btree-appearance-personalized_backStyle-item active-select" 
                                style={{background: '#158fb7'}} onClick={() => selectBackGroundColor("background")}></div>
                            <div className="ui-btree-appearance-personalized_backStyle-item" 
                                style={{background: 'linear-gradient(90deg, rgba(21,143,183,1) 45%, rgba(10,207,198,1) 100%)'}} onClick={() => selectBackGroundColor("gradient")}></div>
                            {/*<div className="ui-btree-appearance-personalized_backStyle-item item_img">
                                <i className="fas fa-image"></i>
                            </div>*/}
                        </div>
                        <div className="ui-btree-appearance-personalized_background_Styles">
                            <div className="ui-btree-appearance-personalized_text-item" onClick={() => selectBackGroundColor("background")}>
                                Fondo color solido
                            </div>
                            <div className="ui-btree-appearance-personalized_text-item" onClick={() => selectBackGroundColor("gradient")}>
                                Fondo degradado lineal
                            </div>

                            {/*<div className="ui-btree-appearance-personalized_text-item item_img">
                                Fondo con imagen
                            </div>*/}
                        </div>
                        </AccordionItemPanel>                                                       
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Color de texto
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="ui-appearance-color-piker-pages">                                                                
                                <div className="color-piker-template">
                                    <ChromePicker 
                                        color={props.formValues.background_style.color} 
                                        onChange={(color) => props.handleChangeTemplate("color", color.hex)} 
                                    />
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Fuente de texto
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="ui-appearance-typo-option">
                                {typeFonts.map((font, index) => (
                                    <div className="ui-apperance-typo-container" key={index} onClick={() => props.handleChangeTemplate("fontFamily", font.font)}>
                                        <div className="ui-appearance-typo-item" style={{ fontFamily: font.font }}>
                                            {font.name}
                                        </div>
                                        <div className="ui-apperance-typo-name-font">{font.font_name}</div>                                                                
                                    </div>        
                                ))}                        

                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>

                <h6 className='mt-4'>Estilos de los botones de enlace</h6>
                <Accordion allowZeroExpanded style={{width: '95%'}}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Estilo de Botones
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                                <p className='ui-apperance-subtitle-btn-style'>Estilos Básicos</p>
                                <div className="ui-appearance-btn-style">
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("borderRadius", "2px")}>
                                        <div className="ui-appearnace-btn" style={{border: '1px solid #606060', borderRadius: '2px' }}></div> 
                                    </div>
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("borderRadius", "8px")}>
                                        <div className="ui-appearnace-btn" style={{border: '1px solid #606060', borderRadius: '8px' }}></div>  
                                    </div>
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("borderRadius", "15px")}>
                                        <div className="ui-appearnace-btn" style={{border: '1px solid #606060', borderRadius: '15px' }}></div>
                                    </div>
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("borderRadius", "25px")}>
                                        <div className="ui-appearnace-btn" style={{border: '1px solid #606060', borderRadius: '25px' }}></div> 
                                    </div>                                                                                                                           
                                </div>
                                <hr />
                                <p className='ui-apperance-subtitle-btn-style'>Estilos con sombra suave</p>
                                <div className="ui-appearance-btn-style">
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("boxShadow", "4px 2px 9px 5px rgba(56,56,56,0.58)")}>
                                        <div className="ui-appearnace-btn" style={{border: '2px solid #606060', borderRadius: '2px', background: '#ffffff', boxShadow: '4px 2px 9px 5px rgba(56,56,56,0.58)'}}></div> 
                                    </div>
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("boxShadow", "4px 2px 9px 5px rgba(56,56,56,0.58)")}>
                                        <div className="ui-appearnace-btn" style={{border: '2px solid #606060', borderRadius: '8px', background: '#ffffff', boxShadow: '4px 2px 9px 5px rgba(56,56,56,0.58)'}}></div>  
                                    </div>
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("boxShadow", "4px 2px 9px 5px rgba(56,56,56,0.58)")}>
                                        <div className="ui-appearnace-btn" style={{border: '2px solid #606060', borderRadius: '15px', background: '#ffffff', boxShadow: '4px 2px 9px 5px rgba(56,56,56,0.58)'}}></div>
                                    </div>
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("boxShadow", "4px 2px 9px 5px rgba(56,56,56,0.58)")}>
                                        <div className="ui-appearnace-btn" style={{border: '2px solid #606060', borderRadius: '25px', background: '#ffffff', boxShadow: '4px 2px 9px 5px rgba(56,56,56,0.58)'}}></div> 
                                    </div>                                                                                                                           
                                </div>
                                <hr />
                                <p className='ui-apperance-subtitle-btn-style'>Estilos con sombra fuerte</p>
                                <div className="ui-appearance-btn-style">
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("boxShadow", "9px 8px 1px 0px #383838")}>
                                        <div className="ui-appearnace-btn" style={{border: '2px solid #606060', borderRadius: '2px', background: '#ffffff', boxShadow: '9px 8px 1px 0px #383838'}}></div> 
                                    </div>
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("boxShadow", "9px 8px 1px 0px #383838")}>
                                        <div className="ui-appearnace-btn" style={{border: '2px solid #606060', borderRadius: '8px', background: '#ffffff', boxShadow: '9px 8px 1px 0px #383838'}}></div>  
                                    </div>
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("boxShadow", "9px 8px 1px 0px #383838")}>
                                        <div className="ui-appearnace-btn" style={{border: '2px solid #606060', borderRadius: '15px', background: '#ffffff', boxShadow: '9px 8px 1px 0px #383838'}}></div>
                                    </div>
                                    <div className="ui-appearance-btn-style-container" onClick={() => props.handleChangeButtonTemplate("boxShadow", "9px 8px 1px 0px #383838")}>
                                        <div className="ui-appearnace-btn" style={{border: '2px solid #606060', borderRadius: '25px', background: '#ffffff', boxShadow: '9px 8px 1px 0px #383838'}}></div> 
                                    </div>                                                                                                                           
                                </div>
                            
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Color Fondo Botones
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <ChromePicker 
                                color={props.formValues.button_style.background} 
                                onChange={(color) => props.handleChangeButtonTemplate("background", color.hex)} 
                            />
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Color Texto Botones
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <ChromePicker 
                                color={props.formValues.button_style.color} 
                                onChange={(color) => props.handleChangeButtonTemplate("color", color.hex)} 
                            />
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Fuente de texto
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="ui-appearance-typo-option">
                                {typeFonts.map((font, index) => (
                                    <div className="ui-apperance-typo-container" key={index} onClick={() => props.handleChangeButtonTemplate("fontFamily", font.font)}>
                                        <div className="ui-appearance-typo-item" style={{ fontFamily: font.font }}>
                                            {font.name}
                                        </div>
                                        <div className="ui-apperance-typo-name-font">{font.font_name}</div>                                                                
                                    </div>        
                                ))}                        
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </React.Fragment>
        )
    }
    

    const onChangeForm = (e) => {
        setColors({ ...colorGradient, [e.target.name]: e.target.value })
        props.handleChangeTemplate("background", `linear-gradient(${direction}, ${colorGradient.first_color} 68%, ${colorGradient.second_color} 99%)` )
    }

    const onChangeFormBackGround = (e) => {
        setColorBackGround({ ...colorBackGround, [e.target.name]: e.target.value })
    }

    const handleChangeGradien = (filed, color) => {
        onChangeForm({ target: { name: filed, value: color.hex } } );

    }

    const selectBackGroundColor = (background) => {
        setSelectedColor("volver");
        setTypeBackground(background);
    }

    const cancelselectedBackGround = (type) => {
        setSelectedColor("");
        if (type == "gradient"){
            props.handleChangeTemplate("background", `linear-gradient(${direction}, ${colorGradient.first_color} 68%, ${colorGradient.second_color} 99%)`)
        }else{
            props.handleChangeTemplate("background", `${props.formValues.background_style.background}`)
        }
    }

    return (
        <React.Fragment>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Arsenal&family=Cormorant+Garamond&family=Fira+Sans+Condensed&family=Handlee&family=Libre+Bodoni:ital@1&family=Macondo&family=Redressed&family=Roboto&family=Rubik&family=Ruluko&display=swap');
            </style>

            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered modal-lg" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>

                    <form onSubmit={handleSubmit}>
                        <ModalBody className='ui-appearance-modal-body'>
                            <div className="row">
                                <div className="col-md-7">
                                    {!isLoaded ? (
                                        <React.Fragment>
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className='ui-btree-mobileView-title-tabs'>Cambiar vista a:</li>

                                                <li className="nav-item">
                                                    <a className="nav-link active" id="home-tab-create-template" data-toggle="tab" href="#home-create-template" role="tab" aria-controls="home-create-template" aria-selected="true">
                                                        <i className="fas fa-mobile-alt"></i>
                                                    </a>
                                                </li>

                                                <li className="nav-item">
                                                    <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile-create-template" role="tab" aria-controls="profile-create-template" aria-selected="false">
                                                        <i className="fas fa-tablet-alt"></i>
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="home-create-template" role="tabpanel" aria-labelledby="home-tab">
                                                    <div className="smartphone ui-smartphone" style={{ height: "538px", background: props.formValues.background_style.background }}>
                                                        <div className="content">      
                                                            {views("mobileView")}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade" id="profile-create-template" role="tabpanel" aria-labelledby="profile-tab">
                                                    {/* TABLET */}
                                                    <div className="tablet ui-tablet" style={{ height: "538px", background: props.formValues.background_style.background }}>
                                                        <div className="content">
                                                            {views("tabletView")}    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <Preloader />
                                    )}
                                </div>

                                <div className="col-md-5">
                                    <div className="row">
                                        {selectedColor ? (
                                            <React.Fragment>
                                                {selectTypeBackground()}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {configurationTemplate()}
                                            </React.Fragment>
                                        )}    
                                    </div>
                                </div>

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info" onClick={props.submitForm}>{props.nameBnt}</button>
                        </ModalFooter>
                    </form>
            </Modal>
        </React.Fragment>
    );
}


export default FormCreate;

