import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import ShowTenantInfo from '../components/settings/Tenants/ShowTenantInfo';
import PaymentTenantInfo from '../components/settings/TenantPayment/PaymentTenantInfo';


const TenantAdminShow = (props) => {
    const [data, setData] = useState([]);
    


    return (
        <React.Fragment>
            <ShowTenantInfo
                tenant={props.tenant}
             />
            <PaymentTenantInfo
                tenant={props.tenant} 
                plan={props.plan}
            />
        </React.Fragment>
    );
}

export default TenantAdminShow;
WebpackerReact.setup({ TenantAdminShow });
