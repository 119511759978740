import React, { Component } from 'react';
import Index from '../components/tasks/taskBoard/Index'
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle';
import FormFilter from '../components/tasks/taskBoard/FormFilter';
import IndexTemplate from '../components/tasks/taskBoard/IndexTemplate';

class IndexTaskBoard extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.childTemplate = React.createRef();
        this.state = {
            isLoaded: true,
            showFilter: false,
            isFiltering: false,
            showMessage: false,
            curretTab: "task_board",

            formFilter: {
                name: "",
                user_ids: [],
            },

            selectedOptionUsers: {
                user_ids: "",
                label: "Usuarios"
            },

            cargos: [],
            users: [],
            data: [],
            dataFormatTaskBoards: [],
            dataDeleteTemplate: [],

            //template
            dataTemplate: [],
            isLoadedTemplate: true,

            //pagination

            activePage: 1,
            countPage: 12,
            total_data: 0,
            total_task_boards: this.props.total_task_boards
        }
    }

    componentDidMount(){
        this.configSelect();
        this.loadData();
        this.loadDataTemplate();
        this.loadFormatTaskBoards();
        if(this.props.estados.show_table){
            this.loadDeleteTemplate();
        }
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    updateDataTemplate = (data) => {
        this.setState({
            dataTemplate: [...this.state.dataTemplate, data],
        })
    }

    //add update
    updateItem = icon => {
        this.setState({
            data: this.state.data.map(item => {
                if (icon.id === item.id) {
                    return { ...item, 
                        name: icon.name,
                        positions: icon.positions,
                        task_labels: icon.task_labels,
                        users: icon.users,
                        last_users: icon.last_users,
                    }
                }
                return item;
            })
        });
    }

    updateItemTemplate = icon => {
        this.setState({
            dataTemplate: this.state.dataTemplate.map(item => {
                if (icon.id === item.id) {
                    return { ...item, 
                        name: icon.name,
                        positions: icon.positions,
                        task_labels: icon.task_labels,
                        users: icon.users,
                        last_users: icon.last_users,
                    }
                }
                return item;
            })
        });
    }

    updateColumnCount = (task_board_id, column) => {
        this.setState({
            data: this.state.data.map(item => {
                if (task_board_id === item.id) {
                    return { ...item, 
                        columns: column,
                    }
                }
                return item;
            })
        });
    }

    updateColumnCountTemplate = (task_board_id, column) => {
        this.setState({
            dataTemplate: this.state.dataTemplate.map(item => {
                if (task_board_id === item.id) {
                    return { ...item, 
                        columns: column,
                    }
                }
                return item;
            })
        });
    }

    configSelect = () => {
        let arrayCargos = []
        let arrayUsers = []

        this.props.positions.map((item) => (
            arrayCargos.push({label: item.name, value: item.id})
        ))

        this.props.users.map((item) => (
            arrayUsers.push({label: item.name, value: item.id})
        ))

        this.setState({
            cargos: arrayCargos,
            users: arrayUsers,
        })
    }

    loadData = () => {
        fetch(`/task/get_task_boards`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                total_data: data.total_data,
                isLoaded: false
            });
        });
    }

    loadFormatTaskBoards = () => {
        fetch(`/task/get_format_task_boards`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataFormatTaskBoards: data.data,
            });
        });
    }

    loadDataTemplate = () => {
        fetch(`/task/get_task_board_templates`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataTemplate: data.data,
                isLoadedTemplate: false,
                total_data: data.total_data,
            });
        });
    }

    loadDeleteTemplate = () => {
        fetch(`/task/get_task_board_delete`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataDeleteTemplate: data.data,
                total_data: data.data.length,
            });
        });
    }


    HandleChangeFilter = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        });
    }


    handleChangeAutocomplete = selectedOption => {
        let array = []
        
        if(selectedOption){
            selectedOption.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            formFilter: {
                ...this.state.formFilter,
                user_ids: selectedOption ? array : []
            }
        })
    };

    selectFilterRouter = () => {
        if(this.state.curretTab == "task_board"){
            return "get_task_boards"
        }else if(this.state.curretTab == "template"){
            return "get_task_board_templates"
        }else if(this.state.curretTab == "task_board_fordata"){
            return "get_format_task_boards"
        }
    }


    HandleClickFilter = () => {
        if (this.state.formFilter.name != "" || this.state.formFilter.user_ids.length >= 1){
            this.setState({ isLoaded: true, isFiltering: true })
            fetch(`/task/${this.selectFilterRouter()}?name=${this.state.formFilter.name}&user_ids=${this.state.formFilter.user_ids}`, {
                method: 'GET', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then(data => {
                if(this.state.curretTab == "task_board"){
                    this.setState({
                        data: data.data,
                        showMessage: false,
                        isLoaded: false,
                    })
                }else if (this.state.curretTab == "template"){
                    this.setState({
                        dataTemplate: data.data,
                        showMessage: false,
                        isLoaded: false,
                    })
                }else if (this.state.curretTab == "task_board_fordata"){
                    this.setState({
                        dataFormatTaskBoards: data.data,
                        showMessage: false,
                        isLoaded: false,
                    })
                }
            });
        }else{
            this.setState({ showMessage: true })
        }
    };    

    //pagination 

    handlePageChange = pageNumber => {
        this.setState({ activePage: pageNumber }); 
        fetch(`/task/get_task_boards?page=${pageNumber}&filter=${this.state.countPage}&filtering=${this.state.isFiltering}&name=${this.state.formFilter.name}&user_ids=${this.state.formFilter.user_ids}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
          .then(response => response.json())
          .then(data => {
            this.setState({ 
                data: data.data,
                total_data: data.total_data,
            });
        });     
    };  


    cancelFilter = () => {
        if(this.state.curretTab == "task_board"){
            this.loadData();
        }else if (this.state.curretTab == "template"){
            this.loadDataTemplate();
        }else if (this.state.curretTab == "task_board_fordata"){
            this.loadFormatTaskBoards();
        }

        this.setState({
            isFiltering: false,
            isLoaded: false,
            showFilter: false,

            formFilter: {
                name: "",
                user_ids: [],
            },
        })
    }

    handleClickShowFilter = () => {
        this.setState({
            showFilter: !this.state.showFilter
        })
    }

    updateTotalTaskBoards = (count) => {
        this.setState({
            total_task_boards: count
        }) 
    }

    handleChangeTab = (tab) => {
        this.setState({
            curretTab: tab,
            showMessage: false,
            formFilter: {
                name: "",
                user_ids: [],
            },
        })
    }

    selectModal = () => {
        if (this.props.plan.task_board_count  <= this.state.total_task_boards){
            return this.child.current.toogleUpgradePlan("new");
        }else{
            if(this.state.curretTab == "task_board"){
                return this.child.current.toogle("new");
            }else if(this.state.curretTab == "template"){
                return this.childTemplate.current.toogle("new");
            }
        }
    }
    
    render() {
        const total_count = (this.props.plan.task_board_count - this.state.total_task_boards)

        return (
            <React.Fragment>
                <HeaderTitle
                    title={this.state.curretTab == "task_board" ? "Tableros de tareas" : "Plantillas de tableros"}
                    subTitle={this.state.curretTab == "task_board" ? "Gestión de tableros" : "Gestión de plantillas"}
                    titleNew={this.state.curretTab == "task_board" ? "Nuevo tablero" : "Nueva plantilla"}
                    titleFilter={"Filtros"}
                    icon="fas fa-chalkboard-teacher"
                    onClickNew={() => this.selectModal()}
                    onClickFilter={this.handleClickShowFilter}
                    showNew={this.props.estados.create && this.state.curretTab == "task_board" || this.state.curretTab == "template"}
                    showFilter={this.state.curretTab != "task_board_delete"}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.showFilter && (
                    <FormFilter 
                        cancelFilter={this.cancelFilter}
                        HandleChangeFilter={this.HandleChangeFilter}
                        formFilter={this.state.formFilter}
                        HandleClickFilter={this.HandleClickFilter}
                        showMessage={this.state.showMessage}

                        //values data
                        curretTab={this.state.curretTab}

                        //select 
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOptionUsers={this.state.selectedOptionUsers}
                        users={this.state.users}
                    />
                )}

                <ul className="nav nav-tabs ui-navTab-Tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => this.handleChangeTab("task_board")}>Tableros de tareas</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={() => this.handleChangeTab("template")}>Plantillas de tableros</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false" onClick={() => this.handleChangeTab("task_board_fordata")}>Tableros creado desde fordata</a>
                    </li>
                    
                    {this.props.estados.show_table && (
                        <li className="nav-item">
                            <a className="nav-link" id="task_board_delete-tab" data-toggle="tab" href="#task_board_delete" role="tab" aria-controls="task_board_delete" aria-selected="false" onClick={() => this.handleChangeTab("task_board_delete")}>Tablero de tareas eliminadas</a>
                        </li>
                    )}
                </ul>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                        {total_count <= 5 && (
                            <div className="alert alert-primary" role="alert">
                                <p className="mb-0">Cantidad de tableros permitidos <b>{this.state.total_task_boards}/{this.props.plan.task_board_count}</b></p>
                            </div>
                        )}

                        <Index
                            data={this.state.data}
                            updateItem={this.updateItem}
                            updateData={this.updateData}
                            loadData={this.loadData}
                            isLoaded={this.state.isLoaded}
                            labels={this.props.labels}
                            positions={this.props.positions}
                            users={this.state.users}
                            ref={this.child}
                            estados={this.props.estados}
                            user={this.props.user}
                            plans={this.props.plans}
                            current_plan={this.props.current_plan}

                            //pagination
                            total_data={this.state.total_data}
                            activePage={this.state.activePage}
                            countPage={this.state.countPage}
                            handlePageChange={this.handlePageChange}
                            total_task_boards={this.state.total_task_boards}
                            updateTotalTaskBoards={this.updateTotalTaskBoards}

                            updateColumnCount={this.updateColumnCount}
                            updateDataTemplate={this.updateDataTemplate}
                            is_admin={this.props.is_admin}
                            showFooter={true}
                        />
                    </div>

                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <IndexTemplate
                            data={this.state.dataTemplate}
                            updateItem={this.updateItemTemplate}
                            updateData={this.updateDataTemplate}
                            loadData={this.loadDataTemplate}
                            isLoaded={this.state.isLoadedTemplate}

                            labels={this.props.labels}
                            positions={this.props.positions}
                            users={this.state.users}
                            ref={this.childTemplate}
                            estados={this.props.estados}
                            user={this.props.user}
                            plans={this.props.plans}
                            current_plan={this.props.current_plan}

                            //pagination
                            total_data={this.state.total_data}
                            activePage={this.state.activePage}
                            countPage={this.state.countPage}
                            handlePageChange={this.handlePageChange}

                            updateColumnCount={this.updateColumnCountTemplate}
                            is_admin={this.props.is_admin}
                        />
                    </div>

                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <Index
                            data={this.state.dataFormatTaskBoards}
                            updateItem={this.updateItem}
                            updateData={this.updateData}
                            loadData={this.loadData}
                            isLoaded={this.state.isLoaded}
                            labels={this.props.labels}
                            positions={this.props.positions}
                            users={this.state.users}
                            ref={this.child}
                            estados={this.props.estados}
                            user={this.props.user}
                            plans={this.props.plans}
                            current_plan={this.props.current_plan}
                            total_task_boards={this.state.total_task_boards}
                            updateTotalTaskBoards={this.updateTotalTaskBoards}

                            //pagination
                            total_data={this.state.total_data}
                            activePage={this.state.activePage}
                            countPage={this.state.countPage}
                            handlePageChange={this.handlePageChange}

                            updateColumnCount={this.updateColumnCount}
                            updateDataTemplate={this.updateDataTemplate}
                            is_admin={this.props.is_admin}
                            showFooter={false}
                            
                        />
                    </div>

                    <div className="tab-pane fade" id="task_board_delete" role="tabpanel" aria-labelledby="task_board_delete-tab">
                        <Index
                            data={this.state.dataDeleteTemplate}
                            updateItem={this.updateItem}
                            updateData={this.updateData}
                            loadData={this.loadData}
                            isLoaded={this.state.isLoaded}
                            labels={this.props.labels}
                            positions={this.props.positions}
                            users={this.state.users}
                            ref={this.child}
                            estados={this.props.estados}
                            user={this.props.user}
                            plans={this.props.plans}
                            current_plan={this.props.current_plan}
                            total_task_boards={this.state.total_task_boards}
                            updateTotalTaskBoards={this.updateTotalTaskBoards}

                            //pagination
                            total_data={this.state.total_data}
                            activePage={this.state.activePage}
                            countPage={this.state.countPage}
                            handlePageChange={this.handlePageChange}

                            updateColumnCount={this.updateColumnCount}
                            updateDataTemplate={this.updateDataTemplate}
                            is_admin={this.props.is_admin}
                            showFooter={false}
                            
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default IndexTaskBoard;
WebpackerReact.setup({ IndexTaskBoard });


