import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import Preloader from '../../../GeneralComponents/LoadingComponen';

class ModalConfigurationQuestion extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: true,
            data: [],
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/formatos/get_questions/${this.props.formato.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({
                data: data.register,
                isLoaded: false
            });
        });
    }

    updateQuestionValue = (question) => {
        fetch(`/formatos/update_question_value/${question.id}/${question.question_value}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {

        });
    }


    handleChange = (e, question) => {
        this.setState({
            data: this.state.data.map(item => {
                if (question.id === item.id) {
                    return { ...item, 
                        question_value: e.target.value,
                    }
                }
                return item;
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <ModalBody>
                            <div className="row">
                                {!this.state.isLoaded ? (
                                    <div className="col-md-12">
                                        <table className="table border-table-question" id="sampleTable">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "52%" }}>Pregunta</th>
                                                    <th>Valor de la pregunta</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               {this.state.data.filter((list) => list.question_type == "answer_string" || list.question_type == "email" || list.question_type == "answer_checkbox" || list.question_type == "answer_radio" || list.question_type == "answer_select").map(question => (
                                                    <tr key={question.id}>
                                                        <td>{question.question}</td>
                                                        <td>
                                                            <input 
                                                                type="number" 
                                                                className="form form-control"
                                                                onChange={(e) => this.handleChange(e, question) }
                                                                onBlur={() => this.updateQuestionValue(question)}
                                                                value={question.question_value}
                                                            />
                                                        </td>
                                                    </tr>
                                               ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <div className="card">
                                            <div className="card-body">
                                                <Preloader />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                        </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalConfigurationQuestion;
