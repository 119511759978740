import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';


class Ckeditor extends Component {
    render() {
        const editorConfiguration = {
            language: 'es',
            toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
        };
        
        return (
            <div className="App">
                <CKEditor
         
                    data={this.props.formValues}
                    config={ editorConfiguration }
                    
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.props.HandleChange(data, this.props.question)
                    }}
                />
            </div>
        );
    }
}

export default Ckeditor;