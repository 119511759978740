import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ModalVideo from '../components/health/NumeralParents/ShowModalVideo';
import FavoriteModule from '../components/settings/FavoriteModule/Index';
import { UncontrolledTooltip} from 'reactstrap';
import { isMobile } from 'react-device-detect';

class HeaderTitle extends Component {
    constructor(props){
        super(props)
        this.state = {
            modalVideo: false,
        }
    }

    toogle = () => {
        this.setState({ modalVideo: !this.state.modalVideo })
    }

    render() {
        return (
            <React.Fragment>
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading ui-modules">
                            <div className="page-title-icon">
                                <i className={`${this.props.icon != undefined ? this.props.icon : "fas fa-user-cog"} icon-gradient bg-mean-fruit`}>
                                </i>
                            </div>
                            <div className='ui-header-title__container_title_subtitle'>
                                <span className='ui-header-title__space_title'>{this.props.title}</span>

                                <div className="page-title-subheading">
                                    {this.props.subTitle}
                                </div>
                            </div>

                            {this.props.is_index && (
                                <FavoriteModule
                                    url={this.props.url}
                                    name={this.props.title}
                                    icon={this.props.icon != undefined ? this.props.icon : "fas fa-user-cog"}
                                />
                            )}

                        </div>


                        {this.state.modalVideo &&(
                            <ModalVideo
                                modal={this.state.modalVideo}
                                video_url={this.props.video_url}
                                toggle={this.toogle}
                            />
                        )}

                        <div className="page-title-actions">

                                {this.props.showFilter &&(
                                    <React.Fragment>
                                        <UncontrolledTooltip target="ui-headerTitle-tooltip-h" placement='bottom'>{"BÚSQUEDA POR FILTRO"}</UncontrolledTooltip>
                                        <button type="button" onClick={() => this.props.onClickFilter()} className="btn-shadow mr-3 btn btn-primary" id="ui-headerTitle-tooltip-h">
                                            <i className="fas fa-filter pr-2"></i>Filtrar
                                        </button>
                                    </React.Fragment>
                                )}

                                {this.props.showVideo &&(
                                    <button type="button" onClick={() =>  this.setState({ modalVideo: !this.state.modalVideo })} className="btn-shadow mr-3 btn btn-primary">
                                        <span className="pr-2 opacity-7">
                                            <i className="fas fa-video"></i>
                                        </span>
                                        {"Video"} 
                                    </button>
                                )}

                                {this.props.titleNew == "Nuevo numeral" &&(
                                    <a 
                                       href={`/health/normativity/${this.props.normativity.id}`} 
                                       className="btn-shadow mr-3 btn btn-primary"
                                       data-turbolinks="true"
                                    >
                                       Ver evaluaciones
                                    </a>
                                )}
                                
                                {this.props.showNew &&(
                                    <button type="button" onClick={() => this.props.onClickNew("new")} className="btn-shadow  btn btn-info">
                                        <span className="pr-2 opacity-7">
                                            <i className={this.props.icon}></i>
                                        </span>
                                        {this.props.titleNew} 
                                    </button>
                                )}

                                {(this.props.taskHistory != undefined && this.props.taskHistory != "FINISHED") &&(
                                    <a 
                                        href={`/task/history/${this.props.taskHistory}`} 
                                        className="btn-shadow btn btn-info"
                                        style={{ display: this.props.taskHistory == "gestor" ? "none" : "" }}
                                        data-turbolinks="true"
                                    >
                                        <i className="fas fa-archive"></i> Archivo
                                    </a>
                                )}

                                {(this.props.title != "Archivo de tareas" && this.props.taskHistory) &&(
                                    <a 
                                        href={`/task/finished/${this.props.taskHistory}`} 
                                        className="btn-shadow btn btn-info ml-2"
                                        data-turbolinks="true"
                                    >
                                        <i className="fas fa-archive"></i> Tareas finalizadas
                                    </a>
                                )}

                                {(this.props.title != "Archivo de tareas" && this.props.taskHistory) &&(
                                    <a 
                                        href={`/task/calendar/${this.props.taskHistory}`} 
                                        className={`btn-shadow btn btn-info ml-2 ${isMobile && 'mt-2'}`}
                                        style={{ display: this.props.taskHistory == "gestor" ? "none" : ""}}
                                        data-turbolinks="true"
                                    >
                                        <i className="fas fa-calendar-alt"></i> Calendario
                                    </a>
                                )}
                                

                            </div>
                        </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HeaderTitle;