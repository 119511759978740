
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ChromePicker } from 'react-color'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Select from "react-select";

class ModalIndex extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            questions: [],

            users: [],
            procesos: [],
            cargos: [],

            isLoaded: true,

            ErrorValues: true,
            modeEdit: false,

            configuration_format_condition_id: "",

            formCreate: {
                configuration_format_id: this.props.configuration_format.id,
                type_email: this.props.type_email,
                conditional: "",
                question_id: "",
                value: "",
                operation: "",
            },

            formUpdate: {
                configuration_format_id: this.props.configuration_format.id,
                type_email: this.props.type_email,
                conditional: "",
                question_id: "",
                value: "",
                operation: "",
            },

            selectedOption: {
                question_id: "",
                label: "Pregunta"
            },

            selectedOptionUpdate: {
                question_id: "",
                label: "Pregunta"
            },

            selectedOptionSelect: {
                value: "",
                label: "Módulo"
            },

            currentOptions: [],
            type_question: "",
            options: [],
            options_operation: ["Contiene", "Igual", "Diferente"],
        }
    }

    componentDidMount = () => {
        this.loadData();
        this.loadDataQuestions();
        this.configSelect();
    }

    configSelect = () => {
        let array = []
        let arrayCargos = []
        let arrayProcesos = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.procesos.map((item) => (
            arrayProcesos.push({ label: item.name, value: item.id })
        ))

        this.props.cargos.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array,
            procesos: arrayProcesos,
            cargos: arrayCargos
        });
    }

    loadData = () => {
        fetch(`/formatos/get_configuration_format_conditions/${this.props.configuration_format.id}/${this.props.type_email}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    isLoaded: false
                });
            });
    }

    loadDataQuestions = () => {
        fetch(`/formatos/get_questions/${this.props.formato.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                let array = [];

                data.register.map((item) => (
                    array.push({ label: item.question, value: item.id, type: item.question_type, options_values: item.options })
                ))

                this.setState({
                    questions: array
                })
            });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = config => {
        this.setState({
            data: this.state.data.map(item => {
                if (config.id === item.id) {
                    return {
                        ...item,
                        question: config.question,
                        conditional: config.conditional,
                        operation: config.operation,
                        value: config.value,
                    }
                }
                return item;
            })
        });
    }


    HandleChange = (e) => {
        if (this.state.configuration_format_condition_id) {
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    [e.target.name]: e.target.value,
                }
            });
        } else {
            this.setState({
                formCreate: {
                    ...this.state.formCreate,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    HandleClick = () => {
        if (this.validationForm()) {
            if (this.state.modeEdit) {
                fetch(`/formatos/configuration_format_conditions/${this.state.configuration_format_condition_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.updateItem(data.register)
                        this.messageSuccess(data);
                    });
            } else {
                fetch(`/formatos/configuration_format_conditions`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.updateData(data.register)
                        this.messageSuccess(data);
                    });
            }
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/configuration_format_conditions/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    edit = (configuration_format_condition) => {
        this.setState({

            configuration_format_condition_id: configuration_format_condition.id,
            modeEdit: true,
            formUpdate: {
                ...this.state.formUpdate,
                conditional: configuration_format_condition.conditional,
                question_id: configuration_format_condition.question.id,
                value: configuration_format_condition.value,
                operation: configuration_format_condition.operation,
            },

            selectedOptionUpdate: {
                question_id: configuration_format_condition.question.id,
                label: configuration_format_condition.question.question,
            },

            selectedOptionSelect: {
                value: "",
                label: (configuration_format_condition.question.question_type == "answer_user_id" || configuration_format_condition.question.question_type == "proceso_id" || configuration_format_condition.question.question_type == "position_id" ? configuration_format_condition.value.name : configuration_format_condition.value)
            },

            currentOptions: this.selectOptions(configuration_format_condition.question.question_type),
            type_questionEdit: configuration_format_condition.question.question_type,

            options: (configuration_format_condition.question.question_type == "answer_select" || configuration_format_condition.question.question_type == "answer_radio" ? configuration_format_condition.question.options : []),
            options_operation: (configuration_format_condition.question.question_type == "answer_integer" || configuration_format_condition.question.question_type == "answer_formula" || configuration_format_condition.question.question_type == "answer_date" ? ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Entre: ejemplo 10 y 20"] : ["Contiene", "Igual", "Diferente"]),
        })
    }

    validationForm = () => {
        if (this.state.configuration_format_condition_id) {
            if (this.state.formUpdate.question_id != "" &&
                this.state.formUpdate.operation != ""
            ) {
                return true
            } else {
                return false
            }
        } else {
            if (this.state.formCreate.question_id != "" &&
                this.state.formCreate.operation != ""
            ) {
                this.setState({ ErrorValues: true })
                return true
            } else {
                this.setState({ ErrorValues: false })
                return false
            }
        }

    }

    clearValues = () => {

        this.setState({
            ErrorValues: true,
            modeEdit: false,
            configuration_format_condition_id: "",

            question_type: "",

            formCreate: {
                ...this.state.formCreate,
                question_id: "",
                conditional: "",
                value: "",
                operation: "",
            },

            formUpdate: {
                ...this.state.formUpdate,
                question_id: "",
                conditional: "",
                value: "",
                operation: "",
            },

            selectedOption: {
                question_id: "",
                label: "Pregunta"
            },

            selectedOptionSelect: {
                value: "",
                label: "",
            },

            currentOptions: [],
            type_question: "",
            type_questionEdit: "",
            options: [],
            options_operation: ["Contiene", "Igual", "Diferente"],
        })
    }


    handleChangeAutocomplete = selectedOption => {
        if (this.state.configuration_format_condition_id) {
            const selectedOptionUpdate = selectedOption

            this.setState({
                selectedOptionUpdate,
                formUpdate: {
                    ...this.state.formUpdate,
                    question_id: selectedOption.value,
                    value: "",
                },

                selectedOptionSelect: {
                    value: "",
                    label: this.selectType(selectedOptionUpdate.type),
                },

                currentOptions: this.selectOptions(selectedOptionUpdate.type),
                type_questionEdit: selectedOptionUpdate.type,

                options: (selectedOptionUpdate.type == "answer_select" || selectedOptionUpdate.type == "answer_radio" ? selectedOptionUpdate.options_values : []),
                options_operation: (selectedOptionUpdate.type == "answer_integer" || selectedOptionUpdate.type == "answer_formula" || selectedOptionUpdate.type == "answer_date" ? ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Entre: ejemplo 10 y 20"] : ["Contiene", "Igual", "Diferente"]),
            });
        } else {
            this.setState({
                selectedOption,
                formCreate: {
                    ...this.state.formCreate,
                    question_id: selectedOption.value,
                    value: "",
                },

                selectedOptionSelect: {
                    value: "",
                    label: this.selectType(selectedOption.type),
                },

                currentOptions: this.selectOptions(selectedOption.type),
                type_question: selectedOption.type,

                options: (selectedOption.type == "answer_select" || selectedOption.type == "answer_radio" ? selectedOption.options_values : []),
                options_operation: (selectedOption.type == "answer_integer" || selectedOption.type == "answer_formula" || selectedOption.type == "answer_date" ? ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Entre: ejemplo 10 y 20"] : ["Contiene", "Igual", "Diferente"]),
            });
        }
    };

    selectOptions = (type_question) => {
        if (type_question == "answer_user_id") {
            return this.state.users
        } else if (type_question == "proceso_id") {
            return this.state.procesos
        } else if (type_question == "position_id") {
            return this.state.cargos
        }
    }

    selectType = (type_question) => {
        if (type_question == "answer_user_id") {
            return "Seleccione un usuario"
        } else if (type_question == "proceso_id") {
            return "Seleccione un proceso"
        } else if (type_question == "position_id") {
            return "Seleccione un cargo"
        }
    }


    handleChangeAutocompleteSelect = selectedOptionSelect => {
        if (this.state.configuration_format_condition_id) {
            this.setState({
                selectedOptionSelect,
                formUpdate: {
                    ...this.state.formUpdate,
                    value: selectedOptionSelect.value
                },
            });
        } else {
            this.setState({
                selectedOptionSelect,
                formCreate: {
                    ...this.state.formCreate,
                    value: selectedOptionSelect.value
                },
            });
        }
    };

    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }


        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <ModalBody>

                        <div className="card mb-2 mb-3">
                            <div className="card-body p-2">
                                <div className="row">

                                    {this.state.data.length >= 1 && (
                                        <div className="col-md-3">
                                            <select
                                                name="conditional"
                                                value={this.state.formCreate.conditional}
                                                onChange={this.HandleChange}
                                                className={`form form-control ${!this.state.ErrorValues && this.state.formCreate.conditional == "" ? "error-class" : ""}`}
                                            >
                                                <option value="">Condicional</option>
                                                <option value="Y">Y</option>
                                                <option value="O">O</option>
                                            </select>
                                        </div>
                                    )}

                                    <div className={`col-md-3`}>
                                        <input
                                            type="hidden"
                                            name="question_id"
                                            value={this.state.selectedOption.question_id}
                                        />
                                        <Select
                                            onChange={this.handleChangeAutocomplete}
                                            options={this.state.questions}
                                            autoFocus={false}
                                            className={`link-form ${!this.state.ErrorValues && this.state.formCreate.question_id == "" ? "error-class" : ""}`}
                                            value={this.state.selectedOption}
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <select
                                            name="operation"
                                            value={this.state.formCreate.operation}
                                            onChange={this.HandleChange}
                                            className={`form form-control ${!this.state.ErrorValues && this.state.formCreate.operation == "" ? "error-class" : ""}`}
                                        >
                                            <option value="">Operación..</option>
                                            {this.state.options_operation.map((item, index) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {(this.state.type_question != "answer_user_id" && this.state.type_question != "proceso_id" && this.state.type_question != "position_id") && (
                                        <div className="col-md-3">
                                            <input
                                                type={this.state.type_question == "answer_date" ? "date" : "text"}
                                                disabled={this.state.type_question == "answer_user_id" || this.state.type_question == "proceso_id" || this.state.type_question == "position_id" ? true : false}
                                                name="value"
                                                onChange={this.HandleChange}
                                                value={this.state.formCreate.value}
                                                className={`form form-control ${!this.state.ErrorValues && this.state.formCreate.value == "" ? "error-class" : ""}`}
                                                placeholder="Valor"
                                            />
                                        </div>
                                    )}

                                    {(this.state.type_question == "answer_user_id" || this.state.type_question == "proceso_id" || this.state.type_question == "position_id") && (
                                        <div className={`col-md-3`}>
                                            <input
                                                type="hidden"
                                                name="value"
                                                value={this.state.selectedOptionSelect.value}
                                            />
                                            <Select
                                                onChange={this.handleChangeAutocompleteSelect}
                                                options={this.state.currentOptions}
                                                autoFocus={false}
                                                className={`link-form`}
                                                value={this.state.selectedOptionSelect}
                                            />
                                        </div>
                                    )}


                                </div>
                            </div>

                            <div className="card-footer">
                                <button
                                    className="btn-shadow btn btn-info mr-2"
                                    onClick={() => this.HandleClick()}
                                >
                                    Guardar
                                </button>

                                <button
                                    className="btn btn-outline-danger"
                                    onClick={() => this.clearValues()}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>

                        <div className="content main-card mb-3 card">
                            <div className="card-body p-1">

                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "24%" }}>Condición</th>
                                            <th style={{ width: "26%" }}>Pregunta</th>
                                            <th style={{ width: "20%" }}>Operación</th>
                                            <th>Valor</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map((configuration_format_condition, index) => (
                                                <tr key={configuration_format_condition.id}>

                                                    <td>
                                                        {(configuration_format_condition.id == this.state.configuration_format_condition_id && index != 0) ? (
                                                            <select
                                                                name="conditional"
                                                                value={this.state.formUpdate.conditional}
                                                                onChange={this.HandleChange}
                                                                className={`form form-control`}
                                                            >
                                                                <option value="">Condicional</option>
                                                                <option value="Y">Y</option>
                                                                <option value="O">O</option>
                                                            </select>
                                                        ) : (
                                                            <React.Fragment>
                                                                {configuration_format_condition.conditional}
                                                            </React.Fragment>
                                                        )}
                                                    </td>

                                                    <td>
                                                        {configuration_format_condition.id == this.state.configuration_format_condition_id ? (
                                                            <React.Fragment>
                                                                <input
                                                                    type="hidden"
                                                                    name="question_id"
                                                                    value={this.state.selectedOptionUpdate.question_id}
                                                                />
                                                                <Select
                                                                    onChange={this.handleChangeAutocomplete}
                                                                    options={this.state.questions}
                                                                    autoFocus={false}
                                                                    className={`link-form`}
                                                                    value={this.state.selectedOptionUpdate}
                                                                />
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                {configuration_format_condition.question.question}
                                                            </React.Fragment>
                                                        )}
                                                    </td>

                                                    <td>
                                                        {configuration_format_condition.id == this.state.configuration_format_condition_id ? (
                                                            <select
                                                                name="operation"
                                                                value={this.state.formUpdate.operation}
                                                                onChange={this.HandleChange}
                                                                className={`form form-control`}
                                                            >
                                                                <option value="">Operación..</option>
                                                                {this.state.options_operation.map((item, index) => (
                                                                    <option value={item}>{item}</option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            <React.Fragment>
                                                                {configuration_format_condition.operation}
                                                            </React.Fragment>
                                                        )}
                                                    </td>

                                                    <td>
                                                        {configuration_format_condition.id == this.state.configuration_format_condition_id ? (
                                                            <React.Fragment>

                                                                {(this.state.type_questionEdit != "answer_user_id" && this.state.type_questionEdit != "proceso_id" && this.state.type_questionEdit != "position_id") && (
                                                                    <input
                                                                        type={this.state.type_questionEdit == "answer_date" ? "date" : "text"}
                                                                        disabled={this.state.type_questionEdit == "answer_user_id" || this.state.type_questionEdit == "proceso_id" || this.state.type_questionEdit == "position_id" ? true : false}
                                                                        name="value"
                                                                        onChange={this.HandleChange}
                                                                        value={this.state.formUpdate.value}
                                                                        className={`form form-control`}
                                                                        placeholder="Valor"
                                                                    />
                                                                )}

                                                                {(this.state.type_questionEdit == "answer_user_id" || this.state.type_questionEdit == "proceso_id" || this.state.type_questionEdit == "position_id") && (
                                                                    <React.Fragment>
                                                                        <input
                                                                            type="hidden"
                                                                            name="value"
                                                                            value={this.state.selectedOptionSelect.value}
                                                                        />
                                                                        <Select
                                                                            onChange={this.handleChangeAutocompleteSelect}
                                                                            options={this.state.currentOptions}
                                                                            autoFocus={false}
                                                                            className={`link-form`}
                                                                            value={this.state.selectedOptionSelect}
                                                                        />
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                {configuration_format_condition.question.question_type == "answer_user_id" || configuration_format_condition.question.question_type == "proceso_id" || configuration_format_condition.question.question_type == "position_id" ? configuration_format_condition.value.name : configuration_format_condition.value}
                                                            </React.Fragment>
                                                        )}
                                                    </td>

                                                    <td className="text-right" style={{ width: "11%" }}>
                                                        {configuration_format_condition.id == this.state.configuration_format_condition_id ? (
                                                            <React.Fragment>
                                                                <i className="fas fa-check-circle update-section" onClick={() => this.HandleClick()}></i>
                                                                <i className="fas fa-times-circle update-false" onClick={() => this.clearValues()}></i>
                                                            </React.Fragment>
                                                        ) : (
                                                            <UncontrolledDropdown className='btn-group'>
                                                                <DropdownToggle className='btn btn-info'>
                                                                    <i className="fas fa-bars"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => this.edit(configuration_format_condition)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Editar
                                                                        </DropdownItem>
                                                                    )}

                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => this.delete(configuration_format_condition.id)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Eliminar
                                                                        </DropdownItem>
                                                                    )}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                            
                                                        )}
                                                    </td>


                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalIndex;
