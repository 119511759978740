import React, { Component } from 'react';
import Select from "react-select";
import { isMobile } from 'react-device-detect';
import Preloader from '../../../GeneralComponents/LoadingComponen';

class IndexColumnFilter extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: false,
            form: {
                value: ""
            }
        }
    }

    handleChangeSelect = (e) => {
        this.setState({  isLoaded: true, form: { value: e.target.value } })
        fetch(`/task/filter_tasks_column/${this.props.column.column_id}?date=${e.target.value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateTaskOrderColumn(data.register)
            this.setState({ isLoaded: false })
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12" style={{ width: isMobile ? "" : "313px" }}>
                        {!this.state.isLoaded ? (
                            <React.Fragment>
                                <select
                                    onChange={this.handleChangeSelect}
                                    value={this.state.form.value}
                                    className="form form-control"
                                >
                                    <option value="">Filtro por fecha</option>
                                    <option value="desc">Más recientes</option>
                                    <option value="asc">Más antiguas</option>
                                </select>
                            </React.Fragment>
                        ) : (
                            <Preloader />
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default IndexColumnFilter;