import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { HuePicker, ChromePicker } from 'react-color';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showPikerFirstColor: false,
            showPikerSecondColor: false,
            form: {
                color1: "#17A3F2",
                color2: "#0C306E",
            },
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    handleClickFirstColor = (state) => {
        this.setState({ showPikerFirstColor: state })
    };

    handleClickSecondColor = (state) => {
        this.setState({ showPikerSecondColor: state })
    };

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        }, () => {
            this.props.onChangeForm({ target: { name: "background", value: `linear-gradient(204deg, ${this.state.form.color1} 0%, ${this.state.form.color2} 100%)` } } )
        });
    }

    render() {
        const config = {
            language: 'es',
            quickInsertEnabled: false,
            colorsHEXInput: true,
            autoFocus: true,
            toolbartop: true,
            linkAlwaysBlank: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            htmlExecuteScripts: true,
            key: this.props.key_text,
            imageUploadToS3: this.props.imageUpload,
            iframe: true,
            tabSpaces: 4,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass'],

                    // Alignment of the group in the toolbar.
                    align: 'left',

                    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                    buttonsVisible: 12
                },


                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL'],
                    align: 'left',
                    buttonsVisible: 11
                },

                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly'],
                    align: 'left',
                    buttonsVisible: 10
                },

                moreMisc: {
                    buttons: ['fullscreen'],
                    align: 'right',
                    buttonsVisible: 9
                }
            }
        }

        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    {/*<ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>*/}

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-4">
                                        <div className="card-body ui_formUpgradePlan__plan_container" style={{ background: this.props.formValues.background, position: 'fixed', marginTop: '1rem' }}>
                                            <div className="ui_formUpgradePlan-title">
                                                <i className="fas fa-chess-bishop mr-1"></i> {this.props.formValues.nick_name ? this.props.formValues.nick_name.toUpperCase() : ""}
                                            </div>

                                            <div className="ui_formUpgradePlan-price__container">
                                                {/* <span style={{color: "#17A3F2" }}><b>${this.props.formValues.price} <span style={{fontSize: '13px', color: "#17A3F2" }}>USD</span></b></span>
                                                <span style={{fontSize: '11px'}}>Pago mensual</span> */}
                                                <a
                                                    className="btn ui_formUpgradePlan-btn"
                                                    href={this.props.formValues.link}
                                                >
                                                    SOLICÍTALO AQUÍ
                                                    {/* CAMBIAR A {this.props.formValues.nick_name ? this.props.formValues.nick_name.toUpperCase() : ""} */}
                                                </a>
                                            </div>

                                            {/* <div className="ui_formUpgradePlan-btn_change_plan">
                                                <a
                                                    className="btn ui_formUpgradePlan-btn"
                                                    href={this.props.formValues.link}
                                                >
                                                    COMIENZA AHORA
                                                    //CAMBIAR A {this.props.formValues.nick_name ? this.props.formValues.nick_name.toUpperCase() : ""}
                                                </a>
                                            </div> */}
                                            <div className="ui_formUpgradePlan__container_user_plan">
                                                {this.props.formValues.users_plan > 1 ? ( `${this.props.formValues.users_plan} Usuarios`) : (` ${this.props.formValues.users_plan} Usuario`)}
                                            </div>

                                            <div className="ui_formUpgradePlan__container_text">
                                                {ReactHtmlParser(this.props.formValues.description)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label>Nombre</label>
                                                <input
                                                    type="text"
                                                    name="nick_name"
                                                    value={this.props.formValues.nick_name}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                                    placeholder="Nombre"
                                                />
                                            </div>

                                            {/* <div className="col-md-6">
                                                <label>Valor del plan</label>
                                                <input
                                                    type="number"
                                                    name="price"
                                                    value={this.props.formValues.price}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                />
                                            </div> */}

                                            <div className="col-md-6 mb-3">
                                                <label>Cantidad de usuarios</label>
                                                <input
                                                    type="number"
                                                    name="users_plan"
                                                    value={this.props.formValues.users_plan}
                                                    onChange={this.props.onChangeForm}
                                                    placeholder="Cantidad de usuarios"
                                                    className={`form form-control`}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label>Tamaño de archivos</label>
                                                <input
                                                    type="text"
                                                    name="size_plan"
                                                    value={this.props.formValues.size_plan}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                    placeholder="Tamaño de archivos"
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label>Registros del plan</label>
                                                <input
                                                    type="number"
                                                    name="records_plan"
                                                    value={this.props.formValues.records_plan}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label>Registros de los tableros</label>
                                                <input
                                                    type="text"
                                                    name="tasks_board_plan"
                                                    value={this.props.formValues.tasks_board_plan}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                />
                                            </div>

                                            <div className="col-md-12 mt-3 mb-3">
                                                <label>Título de bienvenida</label>
                                                <input
                                                    type="text"
                                                    name="sign_up_title"
                                                    value={this.props.formValues.sign_up_title}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                />
                                            </div>

                                            <div className="col-md-12 mt-2 mb-3">
                                                <label>Nombre backend</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={this.props.formValues.name}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                />
                                            </div>

                                            <div className="col-md-12 mb-3">
                                                <label>Url del plan</label>
                                                <input
                                                    type="text"
                                                    name="link"
                                                    value={this.props.formValues.link}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label>Pago de año</label>
                                                <input
                                                    type="text"
                                                    name="payment_year"
                                                    value={this.props.formValues.payment_year}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label>Link del pago de año</label>
                                                <input
                                                    type="text"
                                                    name="link_payment_year"
                                                    value={this.props.formValues.link_payment_year}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                />
                                            </div>

                                            <div className="col-md-12">
                                                <FroalaEditor
                                                    model={this.props.formValues.description}
                                                    config={config}
                                                    onModelChange={(e) => this.props.onChangeForm({ target: { name: "description", value: e } } )}
                                                />
                                            </div>


                                            <div className="col-md-6 text-right mt-3">
                                                <button 
                                                    onClick={() => this.handleClickFirstColor(true)} 
                                                    style={{ backgroundColor: this.state.form.color1, color: "#ffff" }}
                                                    className="btn"
                                                >
                                                    Primer color
                                                </button>
                                                { this.state.showPikerFirstColor ? <div style={ popover }>
                                                    <div style={ cover } onClick={ () => this.handleClickFirstColor(false) }/>
                                                    <ChromePicker 
                                                        color={this.state.form.color1} 
                                                        onChange={(color) => this.HandleChange({ target: { name: "color1", value: color.hex } } )} 
                                                    />
                                                </div> : null }
                                            </div>

                                            <div className="col-md-6 text-left mt-3">
                                                <button 
                                                    onClick={() => this.handleClickSecondColor(true)} 
                                                    style={{ backgroundColor: this.state.form.color2, color: "#ffff" }}
                                                    className="btn"
                                                >
                                                    Segundo color
                                                </button>
                                                { this.state.showPikerSecondColor ? <div style={ popover }>
                                                    <div style={ cover } onClick={ () => this.handleClickSecondColor(false) }/>
                                                    <ChromePicker 
                                                        color={this.state.form.color2} 
                                                        onChange={(color) => this.HandleChange({ target: { name: "color2", value: color.hex } } )  } 
                                                    />
                                                </div> : null }
                                            </div>

                                        </div>

                                        {!this.props.errorValues && (
                                            <div className="col-md-12 mt-4">
                                                <div className="alert alert-danger" role="alert">
                                                <b>Debes de completar todos los campos requeridos</b>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBtn}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;