import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";
import Preloader from '../../../GeneralComponents/LoadingComponen';

const ModalUploadImagen = (props) => {
    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                {/*<ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>*/}
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <Dropzone 
                                    onDrop={(files) => props.handleFile(files)}
                                    accept="image/*"
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <div
                                            {...getRootProps({
                                                className: 'dropzone',
                                            })}
                                        >
                                        <input {...getInputProps()} />
                                            <p>{"Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo"}</p>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>

                            <div className="col-md-12 mt-3">
                                {!props.isLoaded ? (
                                    <div className="alert alert-info" role="alert">
                                        <b>Al subir la imagen, guardara automáticamente</b>
                                    </div>
                                ) : (
                                    <Preloader />
                                )}
                            </div>
                        </div>
                    </ModalBody>

                <ModalFooter>
                    <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}


export default ModalUploadImagen;

