import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import Preloader from '../GeneralComponents/LoadingComponen';

import HeaderTitle from '../GeneralComponents/HeaderTitle';
import ModalCreateChange from '../components/settings/SoftwareChanges/FormCreate';


const SoftwareChange = (props) => {  
    const [data, setData] = useState([]);
    const [form, setForm] = useState({ date: "", description: "", file: {}, link_video: "", title: "", type_change: "", type_file: "image"});
    const [id, setId] = useState("");
    const [isLoaded, setIsLoaded] = useState(true);

    const [modal, setModal] = useState(false);
    const [modeEdit, setModeEdit] = useState(false);
    const [errorValues, setErrorValues] = useState(true);
    const [nameFile, setNameFile] = useState('');

    const token = document.querySelector("[name='csrf-token']").content;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        console.log()
        fetch(`/settings/get_software_changes`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setIsLoaded(false);
        });
    }

    const handleChangeTypeFile = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value, file: {}, link_video: "",})
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })        
    }

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const updateItem = (software_change) => {
        setData(data.map(item => {
          if (item.id === software_change.id) {
            return { ...item, 
                date: software_change.date, 
                description: software_change.description, 
                file: software_change.file,
                link_video: software_change.link_video,
                title: software_change.title, 
                type_change: software_change.type_change,
                type_file: software_change.type_file,
            }
          }
          return item;
        }));
    }

    const updateData = (new_item) => {
        setData([...data, new_item])
    }    
    
    const handleClick = () => {
        const formData = new FormData();
        formData.append("date", form.date)
        formData.append("description", form.description)
        formData.append("file", form.file)
        formData.append("link_video", form.link_video)
        formData.append("title", form.title)
        formData.append("type_change", form.type_change)
        formData.append("type_file", form.type_file)

        if(true){
            if (!modeEdit)
                fetch(`/settings/software_changes`, {
                    method: 'POST', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {
                        
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type == "success"){
                        setModal(false);
                        messageSuccess(data);
                        updateData(data.register);
                        clearValues();
                    }else{
                        messageSuccess(data);
                        clearValues();
                    }
                });
            else {
                fetch(`/settings/software_changes/${id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type == "success"){
                        setModal(false);
                        messageSuccess(data);
                        updateItem(data.register);
                        clearValues();
                    }else{
                        messageSuccess(data);
                        clearValues();
                    }
                });
            }
        }
    }

    const edit = (software_change) => {
        setForm({ date: software_change.date, description: software_change.description, file: software_change.file, link_video: software_change.link_video, title: software_change.title, type_change: software_change.type_change, type_file: software_change.type_file });
        setNameFile(software_change.file ? software_change.file.name : 'Arrastre y suelte la Imagen aquí, o haga clic para seleccionar la Imagen')
        setId(software_change.id);
        setModal(true);
        setModeEdit(true);
    }

    const destroy = (software_change_id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/software_changes/${software_change_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    loadData()
                });
            }
        });
    };
    
    const clearValues = () => {
        setModeEdit(false);
        setForm({date: "", description: "", file: {}, link_video:"", title: "", type_change: "", type_file: "image"});
        setErrorValues(true);
    }
    
    const handleFile = (file) => {
        setForm({ ...form, ['file']: file[0].size >= 11485760 ? {} : file[0] });
        setNameFile(file[0].size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file[0].path);
               
    };

    const toogle = (from) => {
        if (from == "new") {
            setModal(true);
        } else {
            setModal(false);
            clearValues();
        }
    }

    const typeChangeFile = (type_file, file) => {
        if (file || type_file.link_video) {
          if (type_file.type_file === 'image') {
            return (
              <a href={file.url} target='_blank' rel="noopener noreferrer">
                Ver Imagen
              </a>
            );
          } else if (type_file.type_file === 'file') {
            return (
              <a href={file.url} target='_blank' rel="noopener noreferrer">
                Ver Archivo
              </a>
            );
          } else {
            return (
             <a href={type_file.link_video} alt="Video" target='_blank'>Ver Video</a>
            );
          }
        } else {
          if (type_file === 'image') {
            return <p>No hay imagen</p>;
          } else if (type_file === 'file') {
            return <p>No hay archivo</p>;
          } else {
            return <p>No hay video</p>;
          }
        }
      };

    return (
    <React.Fragment>
        <HeaderTitle 
            title={"Cambios Beewo"} 
            subTitle={"Gestión de los cambios de la plataforma"}
            titleNew={"Crear Cambio"}
            icon={"fas fa-file-code"} 
            onClickNew={toogle}
            showNew={true}
            showFilter={false} 
        />

        {modal && (
            <ModalCreateChange
                backdrop={"static"}
                modal={modal}
                toggle={toogle}
                title={modeEdit ? "Actualizar Cambio" : "Crear Cambio"}
                nameBnt={modeEdit ? "Actualizar" : "Crear"}   
                modeEdit={modeEdit}  
                
                formValues={form}
                onChangeForm={handleChange}
                handleFile={handleFile}
                nameFile={nameFile}
                submitForm={handleClick}
                handleChangeTypeFile={handleChangeTypeFile}
                
            />
        )}
        
        
            <React.Fragment>
                <div className="content main-card mb-3 card ui-softwareChange-card__container">
                {!isLoaded ? (
                    <React.Fragment>
                        {data.length >= 1 ? (
                            <div className="card-body ui-softwareChange-cardbody__container">
                        
                                <table className="table table-hover table-striped table-bordered ui-softwareChange-table__conatiner" id="sampleTable"> 
                                    <thead className='ui-softwareChange-head_title__container'>
                                        <tr className='ui-softwareChange-titles'>
                                            <th className='ui-softwareChange-th_title' style={{width: '350px'}}>Título</th>
                                            <th className='ui-softwareChange-th_description'>Descripción</th>
                                            <th className='ui-softwareChange-th_type' style={{width: '180px'}}>Tipo de Cambio</th>
                                            <th className='ui-softwareChange-th_date'style={{width: '100px'}}>Fecha</th>
                                            <th className='ui-softwareChange-th_file' style={{width: '250px'}}>Archivo</th>
                                            <th className='ui-softwareChange-th_options' style={{width: '10px'}}>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((software_change) => (
                                            <tr key={software_change.id}>
                                                <td className='ui-softwareChange-td_title'>{software_change.title}</td>
                                                <td className='ui-softwareChange-td_description'>{ReactHtmlParser(software_change.description)}</td>
                                                <td className='ui-softwareChange-td_type'>{software_change.type_change}</td>
                                                <td className='ui-softwareChange-td_date'>{software_change.date}</td>
                                                <td className='ui-softwareChange-td_file'>
                                                    {typeChangeFile(software_change, software_change.file)}
                                                                                               
                                                </td>
                                                <td className='ui-softwareChange-td_options'>
                                                    <UncontrolledDropdown className='btn-group'>
                                                        <DropdownToggle className='btn-shadow btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                            {true && (
                                                                <DropdownItem
                                                                    onClick={() => edit(software_change)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Editar
                                                                </DropdownItem>
                                                            )}
                                                            {true && (
                                                                <DropdownItem
                                                                    onClick={() => destroy(software_change.id)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Eliminar
                                                                </DropdownItem>
                                                            )}

                                                                    
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="card-body">
                                <div className="text-center">
                                    <h4>No hay actualizaciones creadas</h4>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <Preloader />
                )}
                </div>
                
            </React.Fragment>
         
      </React.Fragment>
    )
}


export default SoftwareChange;
WebpackerReact.setup({ SoftwareChange });
