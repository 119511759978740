import React, { useState, useEffect } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Select from "react-select";
import Swal from "sweetalert2/dist/sweetalert2.js";

const FormCreate = (props) => {
    const [selectedOptionQuestionSource, setSelectedOptionQuestionSource] = useState({ label: (props.modeEdit ? props.format_duplication_item.question_source.label : ""), question_source_id: (props.modeEdit ? props.format_duplication_item.question_source.value : "") });
    const [selectedOptionQuestionTarget, setSelectedOptionQuestionTarget] = useState({ label: (props.modeEdit ? props.format_duplication_item.question_target.label : ""), question_target_id: (props.modeEdit ? props.format_duplication_item.question_target.value : "") });

    const [questionsSource, setQuestionsSource] = useState([]);
    const [questionsTarget, setQuestionsTarget] = useState([]);

    const token = document.querySelector("[name='csrf-token']").content;

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const clearValues = () => {
        setSelectedOptionQuestionSource({ label: "", question_source_id: "" })
        setSelectedOptionQuestionTarget({ label: "", question_target_id: "" })
    }

    const handleChangeAutocompleteQuestionSource = (selectedOptionQuestion) => {
        setSelectedOptionQuestionSource(selectedOptionQuestion);
        props.handleChangeFormatDuplicateItem("question_source_id", selectedOptionQuestion.value);
    }

    const handleChangeAutocompleteQuestionTarget = (selectedOptionQuestion) => {
        setSelectedOptionQuestionTarget(selectedOptionQuestion);
        props.handleChangeFormatDuplicateItem("question_target_id", selectedOptionQuestion.value);
    }

    useEffect(() => {
        loadQuestionSource();
        loadQuestionTarget();
    }, []);

    const loadQuestionSource = () => {
        fetch(`/formatos/data_questions/${props.format_duplication.format_subform_source.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            let array = []

            data.steps_questions.map((item) => (
                array.push({ label: item.question, value: item.id })
            ))

            setQuestionsSource(array)
        });
    }

    const loadQuestionTarget = () => {
        fetch(`/formatos/data_questions/${props.format_duplication.format_subform_target.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            let array = []

            data.steps_questions.map((item) => (
                array.push({ label: item.question, value: item.id })
            ))
            
            setQuestionsTarget(array);
        });
    }

    const handleClick = () => {
        if(props.modeEdit){
            fetch(`/formatos/format_duplication_items/${props.format_duplication_item.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(props.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                messageSuccess(data);
                clearValues();
                props.clearFormatDuplicateItemValues();
                props.updateItemFormatDuplicationItem(data.register);
            });
        }else{
            fetch(`/formatos/format_duplication_items`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(props.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                messageSuccess(data);
                clearValues();
                props.clearFormatDuplicateItemValues();
                props.updateDataFormatDuplicationItem(props.format_duplication.id, data.register)
            });
        }
    }

    return (
        <React.Fragment>
            <UncontrolledPopover isOpen={props.isOpen} trigger="legacy" placement="botton" target={props.target} onClick={(e) => e.stopPropagation()}>
                <PopoverHeader>
                    <div className='ui-modals-header-title__container'>
                        <span className='ui-titles_modals'>
                            {props.modeEdit ? "Actualizar registro" : "Crear registro"}
                            <a className="close-popover" onClick={(e) => props.clearFormatDuplicateItemValues()}> <i className="fas fa-times"></i></a>
                        </span> 
                        {/*<span className='ui-name-question-modals ui-icon-in-title ml-0'>{}</span> */}
                    </div>
                </PopoverHeader>
                
                <PopoverBody className="popover-column">
                    <div className="col-md-12 p-0" style={{ width: "294px", height: "auto" }}>
                        <div className="row">

                            <div className="col-md-12 mb-2">
                                <input
                                    type="hidden"
                                    name="question_source_id"
                                    value={selectedOptionQuestionSource.question_source_id}
                                />
                                <label>question_source_id</label>
                                <Select
                                    onChange={handleChangeAutocompleteQuestionSource}
                                    options={questionsSource}
                                    autoFocus={false}
                                    className={`link-form`}
                                    value={selectedOptionQuestionSource}
                                />
                            </div>

                            <div className="col-md-12 mb-2 mt-2">
                                <input
                                    type="hidden"
                                    name="question_target_id"
                                    value={selectedOptionQuestionTarget.question_target_id}
                                />
                                <label>question_target_id</label>
                                <Select
                                    onChange={handleChangeAutocompleteQuestionTarget}
                                    options={questionsTarget}
                                    autoFocus={false}
                                    className={`link-form`}
                                    value={selectedOptionQuestionTarget}
                                />
                            </div>

                            <div className="col-md-12">
                                <button
                                    className='btn-shadow btn btn-info btn-block'
                                    onClick={() => handleClick()}
                                >
                                    {props.modeEdit ? "Actualizar " : "Crear"}
                                </button>
                            </div>
                        </div>
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </React.Fragment>
    );
}

export default FormCreate;

