import React, { Component } from 'react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';

class CongratulationQuiz extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showForm: false,
            form: {
                survey_id: this.props.survey.id,
                email: "",
            }
        }
    }

    setFormEmail = () => {
        if(this.state.showForm){
            this.setState({
                showForm: false,
                form: {
                    ...this.state.form,
                    email: "",
                }
            })
        }else{
            this.setState({
                showForm: true,
                form: {
                    ...this.state.form,
                    email: "",
                }
            })
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    handleChange = (e) => {
        this.setState({
            form: {
              ...this.state.form,
              email: e.target.value
            }
        });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    handleClick = () => {
        fetch("/formatos/send_pdf_format", {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.messageSuccess(data);
            this.setState({
                showForm: false,
                form: {
                    ...this.state.form,
                    email: "",
                }
            })
        });
    }

    validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    

    getImagen = () => {
        if (this.props.formato.header_image.url){
            return(
                <React.Fragment>
                    <img
                        src={this.props.formato.header_image.url}
                        alt=""
                        className="img-fluid"
                        style={{ width: "300px" }}
                    />
                </React.Fragment>
            )

        } else if (this.props.formato.tenant.logo.url){
            return(
                <React.Fragment>
                    <img
                        src={this.props.formato.tenant.logo.url}
                        alt=""
                        className="img-fluid"
                        style={{ width: "300px" }}
                    />
                </React.Fragment>
            )
        } else {
            if (this.props.domain == "beewo"){
                    if (this.props.formato.tenant.logo.url){
                        return(
                            <React.Fragment>
                                <img
                                    src={this.props.formato.tenant.logo.url}
                                    alt=""
                                    className="img-fluid"
                                    style={{ width: "300px" }}
                                />
                            </React.Fragment>
                        )
                    } else {
                        return(
                            <React.Fragment>
                                <img
                                    src="https://beewo.s3.amazonaws.com/uploads/tenant/logo/1/Logo_Beewo_-_Principal__1_.png"
                                    alt=""
                                    className="img-fluid"
                                    style={{ width: "300px" }}
                                />
                            </React.Fragment>
                        )
                    }
            } else {
                return(
                    <React.Fragment>
                        <img
                            src="https://mybc1.s3.amazonaws.com/uploads/rseguimiento/evidencia/698/gestionsaludm.png"
                            alt=""
                            className="img-fluid"
                            style={{ width: "300px" }}
                        />
                    </React.Fragment>
                )
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">    

                        <div className="col-md-6 mx-auto" style={{ marginTop: "170px" }}>
                            <div className="card login-box">
                                <div className="card-body">
                                    <div className="text-center">
                                        {this.getImagen()}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 text-center mt-3">
                                            {ReactHtmlParser(this.props.formato.custom_message)}

                                            <hr/>
                                            <a 
                                                href={`/formatos/generate_questions/${this.props.formato.token}`}
                                                className="btn-shadow btn btn-outline-info mr-2"
                                            >
                                               Generar otro Registro
                                            </a>

                                            {this.props.formato.download_pdf && (
                                                <a
                                                    className="btn-shadow btn btn-outline-info mr-2"
                                                    target="_blank"
                                                    href={`/formatos/download_pdf/${this.props.survey.token}.pdf`}
                                                >
                                                    Imprimir Registro
                                                </a>
                                            )}

                                            {this.props.formato.send_email && (
                                                <button
                                                    className={`btn-shadow btn btn-${this.state.showForm ? "danger" : "outline-info"}`}
                                                    onClick={() => this.setFormEmail()}
                                                >
                                                    {this.state.showForm ? "Cancelar" : "Enviar por Correo-e"}
                                                </button>  
                                            )}
                                        </div>
                                        
                                        {this.state.showForm && (
                                            <div className="col-md-12 mt-3">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="row" style={{justifyContent: 'center'}}>
                                                        <div className="col-md-10 mr-2">
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                value={this.state.form.name}
                                                                onChange={this.handleChange}
                                                                className={`form form-control`}
                                                                placeholder="Correo-e"
                                                            />
                                                        </div>

                                                        {this.validateEmail(this.state.form.email) && (
                                                            <div className="col-md-2">
                                                                <button className="btn-shadow btn btn-info mt-3" onClick={() => this.handleClick()}>{"Enviar"}</button>
                                                            </div>  
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        )}

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default CongratulationQuiz;
WebpackerReact.setup({ CongratulationQuiz });
