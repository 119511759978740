import React, { Component } from 'react';
import Index from '../components/tasks/ganttDiagram/Index'  
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"

class GanttDiagramSurvey extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
            isLoaded: false,
        })
    }

    //add update
    updateItem = step => {
        this.setState({
            data: this.state.data.map(item => {
            if (step.id === item.id) {
              return { ...item, 
                name: step.name,
                description: step.description,
                is_private: step.is_private,
                positions: step.positions,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/task/get_gantt_diagram_tasks_surveys/${this.props.module.module_task_id}/${this.props.survey.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Diagrama de gantt`}
                    subTitle={"ForData"}
                    titleNew={"Nuevo formato"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />

                <Index
                    url={`/task/get_gantt_diagram_tasks_surveys/${this.props.module.module_task_id}/${this.props.survey.id}`}
                    current_user={this.props.current_user}
                />
            </React.Fragment>
        );
    }
}


export default GanttDiagramSurvey;
WebpackerReact.setup({ GanttDiagramSurvey });

