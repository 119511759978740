import React from 'react';

const HeaderMenu = (props) => {
    return (
        <React.Fragment>
            <div className="card ui-btreeLink-cardHeader">
                <div className="card-body">
                    <div className="row">
                        <div className="ui-btree-link-subHeader">
                            <ul>
                                <li><a href={`/beetree/page/${props.page.token}`} data-turbolinks="true" className={props.current_page == "links" ? "active" : ""}>Enlaces</a></li>
                                <li><a href={`/beetree/pages/${props.page.token}/appearance`} data-turbolinks="true" className={props.current_page == "appearance" ? "active" : ""}>Apariencia</a></li>   
                                <li><a href={`/beetree/pages/${props.page.token}/settings`} data-turbolinks="true" className={props.current_page == "settings" ? "active" : ""}>Configuración</a></li>
                            </ul>  
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default HeaderMenu;
