import React from "react";
import WebpackerReact from 'webpacker-react';
import Table from "../components/users/table";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoaded: true,
      activePage: 1,
      users_total: 0,
      show_filter: false, 
      showMessage: false, 
      formFilter: {
        username: "",
        second_name: "",
        first_name: "",
        position_id: "",
        user_state: "",
      },

      countPage: 30,
      available_users: 0
    };
  }


  loadData = () => {
    fetch("/users/get_users")
    .then(response => response.json())
    .then(data => {
      this.setState({
        data: data.data,
        users_total: data.users_total,
        isLoaded: false,
        available_users: data.data.filter(value => value.user_state == true).length
      });
    });
  }

    //add items
  updateData = (data) => {
    this.setState({
      data: [...this.state.data, data],
    })
  }

  //add update
  updateItem = user => {
    this.setState({
        data: this.state.data.map(item => {
          if (user.id === item.id) {
            return { ...item, 
              username: user.username,
              email: user.email,
              first_name: user.first_name,
              second_name: user.second_name,
              second_email: user.second_email,
              first_last_name: user.first_last_name,
              second_last_name: user.second_last_name,
              position_id: user.position_id,
              position: user.position,
              name: user.name,
              avatar: user.avatar,
              user_state: user.user_state,
              password: user.password,
              password_confirmation: user.password_confirmation,
            }
          }
        return item;
      })
    });
  }

  clearValuesFilter = () => {
    this.setState({
      formFilter: {
        username: "",
        second_name: "",
        first_name: "",
        position_id: "",
        user_state: "",
      },
    })
  }

  componentDidMount() {
    this.loadData();
  }
  
  showFilter = () => {
    this.setState({ show_filter: (this.state.show_filter == true ? false : true) });
    this.clearValuesFilter();
  }
  
  cancelFilter = () => {
    this.setState({ show_filter: false, showMessage: false })
    this.clearValuesFilter();
    this.loadData();
  }

  change = e => {
    this.setState({
      countPage: e.target.value,
      activePage: this.state.countPage
    });
    fetch("/users/get_users?filter=" + e.target.value)
    .then(response => response.json())
    .then(data => {
      this.setState({
        data: data.users_paginate,
        users_total: data.users_total,
        activePage: 1
      });
    });
  }
  
  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    fetch(`/users/get_users?page=${pageNumber}&filter=${this.state.countPage}`) 
      .then(response => response.json())
      .then(data => {
        this.setState({ data: data.users_paginate });
      });
     
  };

  handleChangeFilter = e => {
    this.setState({
      formFilter: {
        ...this.state.formFilter,
        [e.target.name]: e.target.value
      }
    });
  };

  HandleClickFilter = e => {
    if (this.state.formFilter.username != "" || this.state.formFilter.second_name != "" || this.state.formFilter.first_name != "" || this.state.formFilter.position_id != "" || this.state.formFilter.user_state != ""){
      fetch(`/users/get_users?username=${this.state.formFilter.username}&second_email=${this.state.formFilter.second_name}&first_name=${this.state.formFilter.first_name}&position_id=${this.state.formFilter.position_id}&user_state=${this.state.formFilter.user_state}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          data: data.data,
          showMessage: false,
          users_total: data.users_total,
          activePage: 1
        });
      });
    }else{
        this.setState({ showMessage: true })
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">

                    <Table 
                      dataUsers={this.state.data} 
                      users={this.loadData}
                      name={this.state.show} 
                      show={this.showFilter} 
                      showFilter={this.change}
                      loadDataTable={this.loadData}
                      isLoaded={this.state.isLoaded}
                      rols={this.props.rol}
                      all_users ={this.state.users_total}
                      current_user={this.props.current_user}
                      plans={this.props.plans}

                      // Paginacion props

                      activePage={this.state.activePage}
                      countPage={this.state.countPage}
                      users_total={this.state.users_total}
                      handlePageChange={this.handlePageChange}


                      // filter values

                      FormFilter={this.state.formFilter}
                      cancelFilter={this.cancelFilter}
                      closeFilter={this.showFilter}
                      onChangeFilter={this.handleChangeFilter}
                      onClickFilter={this.HandleClickFilter}
                      showMessage={this.state.showMessage}
                      showFilter={this.state.show_filter}
                      funShowFilter={this.showFilter}

                      estados={this.props.estados}

                      updateData={this.updateData}
                      updateItem={this.updateItem}

                      tenant={this.props.tenant}
                      available_users={this.state.available_users}
                      current_url={this.props.current_url}
                      current_plan={this.props.current_plan}
                    />

              </div>
            </div>
          </div>
        </div>




      </React.Fragment>
    );
  }
}

export default User;

WebpackerReact.setup({ User });