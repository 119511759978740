import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from './FormCreate'
import Loading from '../../../GeneralComponents/LoadingComponen'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';
import ModalIndex from './PlanCopyFormat/Index';
import ConfigurationWatermark from './ConfigurationWatermark';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modalPlanCopyFormat: false,
            modaWatermark: false,
            plan_id: "",
            isLoaded: true,
            id: "",
            form: {
                name: "",
                users_plan: 20,
                size_plan: 20,
                nick_name: "",
                records_plan: "",
                tasks_board_plan: "",
                price: 0,
                description: "",
                link: "",
                background: 'linear-gradient(204deg, #17A3F2 0%, #0C306E 100%)',
                show: true,
                sign_up_title: "",
                watermark: "",
                has_watermark_demo: false,
                has_watermark_always: false,
                channel_state: false,
                payment_year: "",
                link_payment_year: "",
            },

            formUpdatePostion: {
                id: "",
                position: "",
            }
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch("/settings/plans/" + id, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    toogleWatermark = (from, plan) => {
        if (from == "new") {
            this.setState({
                modaWatermark: true,
                id: plan.id,

                form: {
                    name: plan.name,
                    users_plan: plan.users_plan,
                    size_plan: plan.size_plan,
                    nick_name: plan.nick_name,
                    records_plan: plan.records_plan,
                    tasks_board_plan: plan.tasks_board_plan,
                    price: plan.price,
                    description: plan.description,
                    link: plan.link,
                    background: plan.background,
                    show: plan.show,
                    sign_up_title: plan.sign_up_title,
                    watermark: plan.watermark,
                    has_watermark_demo: plan.has_watermark_demo,
                    has_watermark_always: plan.has_watermark_always,
                    channel_state: plan.channel_state,
                    payment_year: plan.payment_year,
                    link_payment_year: plan.link_payment_year,
                }
            })
        } else {
            this.setState({ modaWatermark: false, id: "" })
            this.clearValues();
        }
    }

    tooglePlanCopyFormat = (from, plan) => {
        if (from == "new") {
            this.setState({ modalPlanCopyFormat: true, plan_id: plan.id })
        } else {
            this.setState({ modalPlanCopyFormat: false, plan_id: "" })
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            id: "",
            form: {
                name: "",
                users_plan: 20,
                size_plan: 20,
                nick_name: "",
                records_plan: "",
                tasks_board_plan: "",
                price: 0,
                description: "",
                position: "",
                link: "",
                background: 'linear-gradient(204deg, #17A3F2 0%, #0C306E 100%)',
                show: true,
                sign_up_title: "",
                watermark: "",
                has_watermark_demo: false,
                has_watermark_always: false,
                channel_state: false,
                payment_year: "",
                link_payment_year: "",
            },
        })
    }

    HandleClick = () => {
        if (this.state.id) {
            fetch(`/settings/plans/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false, modaWatermark: false })
                    this.messageSuccess(data);
                    this.props.updateItem(data.register)
                });
        } else {
            fetch("/settings/plans", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.props.updateData(data.register)
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        }
    }

    edit = (plan) => {
        this.setState({
            id: plan.id,
            modal: true,
            form: {
                name: plan.name,
                users_plan: plan.users_plan,
                size_plan: plan.size_plan,
                nick_name: plan.nick_name,
                records_plan: plan.records_plan,
                tasks_board_plan: plan.tasks_board_plan,
                price: plan.price,
                description: plan.description,
                link: plan.link,
                background: plan.background,
                show: plan.show,
                sign_up_title: plan.sign_up_title,
                watermark: plan.watermark,
                has_watermark_demo: plan.has_watermark_demo,
                has_watermark_always: plan.has_watermark_always,
                channel_state: plan.channel_state,
                payment_year: plan.payment_year,
                link_payment_year: plan.link_payment_year,
            }
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                [e.target.name]: e.target.value
            }
        })
    }

    handleChangeCheckbox = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                has_watermark_demo: e.target.checked,
            }
        })
    }

    updatePosition = (e) => {
        fetch(`/settings/update_position_plan/${this.state.formUpdatePostion.id}/${e.target.value}/${this.state.formUpdatePostion.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.clearValuesPosition();
            });
    }

    updateValues = (plan) => {
        this.setState({
            formUpdatePostion: {
                id: plan.id,
                position: plan.position,
            }
        })
    }

    clearValuesPosition = () => {
        this.setState({
            formUpdatePostion: {
                id: "",
                position: "",
            }
        })
    }

    updateState = (id, e) => {
        const form = {
            [e.target.name]: e.target.checked,
        }

        fetch(`/settings/plans/${id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.setState({ modal: false })
                this.props.updateItem(data.register)
            });
    }

    updateIsRecommended = (plan_id, is_recommended) => {
        if (is_recommended) {
            fetch(`/settings/update_is_recommended/${plan_id}/${is_recommended}`, {
                method: 'PATCH', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.updateAllData(data.register);
                });
        }
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Planes"}
                    subTitle={"Gestión de encuestas"}
                    titleNew={"Nuevo plan"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.id ? "Actualizar plan" : "Crear plan"}
                        nameBtn={this.state.id ? "Actualizar" : "Crear"}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={true}

                    />
                )}

                {this.state.modalPlanCopyFormat && (
                    <ModalIndex
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalPlanCopyFormat}
                        toggle={this.tooglePlanCopyFormat}
                        title={"Modulo por defecto"}
                        plan_id={this.state.plan_id}
                        companies={this.props.companies}
                    />
                )}

                {this.state.modaWatermark && (
                    <ConfigurationWatermark
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modaWatermark}
                        toggle={this.toogleWatermark}
                        title={"Configuracion de marca de agua"}
                        handleChangeCheckbox={this.handleChangeCheckbox}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        nameBtn={"Actualizar"}
                        errorValues={true}
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card">
                            <div className="card-body">
                                {true && (
                                    <div className="ui-table_tenant_client-button-config">
                                        <UncontrolledTooltip target='bw-index-plan_settings__btn_add-category' placement='top'>
                                            Categorizar planes o Visualizar planes en Clientes
                                        </UncontrolledTooltip>
                                        <a className='btn btn-info btn-shadow' href="./plan_categories" id='bw-index-plan_settings__btn_add-category'>Asignar Plan</a>
                                    </div>
                                )}
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Posición</th>
                                            <th>Nombre del plan</th>
                                            <th>Nombre backend</th>
                                            <th>Cantidad de usuarios activos</th>
                                            <th>Tamaño de archivos (MG)</th>
                                            <th>Registros del plan</th>
                                            <th>Registros de los tableros</th>
                                            <th>¿Enviar mensajes?</th>
                                            <th>¿Mostrar?</th>
                                            <th>¿Recomendar?</th>

                                            <th style={{ width: "82px" }} >Opciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.data.length >= 1 ? (
                                            this.props.data.map(plan => (
                                                <tr key={plan.id}>
                                                    <td>
                                                        {this.state.formUpdatePostion.id == plan.id ? (
                                                            <React.Fragment>
                                                                <select
                                                                    className="form form-control"
                                                                    value={plan.position}
                                                                    onChange={(e) => this.updatePosition(e)}
                                                                >
                                                                    {this.props.data.map(item => (
                                                                        <React.Fragment>
                                                                            <option value={item.position}>{item.position}</option>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </select>

                                                                <i className="fas fa-times" style={{ cursor: "pointer", color: "red", fontSize: "19px", float: "right" }} onClick={() => this.clearValuesPosition()}></i>
                                                            </React.Fragment>
                                                        ) : (
                                                            <p onClick={() => this.updateValues(plan)}>{plan.position}</p>
                                                        )}
                                                    </td>

                                                    <td>
                                                        <p>{plan.nick_name}</p>
                                                    </td>

                                                    <td>
                                                        <p>{plan.name}</p>
                                                    </td>

                                                    <td>
                                                        <p>{plan.users_plan}</p>
                                                    </td>

                                                    <td>
                                                        <p>{plan.size_plan}</p>
                                                    </td>

                                                    <td>
                                                        <p>{plan.records_plan}</p>
                                                    </td>

                                                    <td>
                                                        <p>{plan.tasks_board_plan}</p>
                                                    </td>

                                                    <td><input type="checkbox" name="channel_state" onChange={(e) => this.updateState(plan.id, e)} checked={plan.channel_state} /></td>
                                                    <td><input type="checkbox" name="show" onChange={(e) => this.updateState(plan.id, e)} checked={plan.show} /></td>
                                                    <td><input type="checkbox" name="is_recommended" onChange={(e) => this.updateIsRecommended(plan.id, !plan.is_recommended)} checked={plan.is_recommended} /></td>

                                                    {(true || true) && (
                                                        <td className="text-right" style={{ width: "10px" }}>
                                                            <UncontrolledDropdown direction='right' className='btn-group'>
                                                                <DropdownToggle className='btn-shadow btn btn-info'>
                                                                    <i className="fas fa-bars"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => this.tooglePlanCopyFormat("new", plan)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Modulos
                                                                        </DropdownItem>
                                                                    )}

                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => this.toogleWatermark("new", plan)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Marca de agua
                                                                        </DropdownItem>
                                                                    )}

                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => this.edit(plan)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Editar
                                                                        </DropdownItem>
                                                                    )}

                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => this.delete(plan.id)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Eliminar
                                                                        </DropdownItem>
                                                                    )}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

export default Index;