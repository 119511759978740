import React, { useState, useEffect } from 'react';
const FormFilter = (props) => {

    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="row">

                            <div className="col-md-4">
                                <input 
                                    type="text" 
                                    name='code' 
                                    className={`form form-control`}
                                    value={props.formValues.code}
                                    onChange={props.onChangeForm} 
                                    placeholder="Codigo"
                                />
                            </div>

                            <div className="col-md-4">
                                <input 
                                    type="text" 
                                    name='description' 
                                    className={`form form-control`}
                                    value={props.formValues.description}
                                    onChange={props.onChangeForm} 
                                    placeholder="Descripción"
                                />
                            </div>

                        </div>
                    </div>

                    <div className="card-footer">
                        <button
                            className="btn-shadow btn btn-info mr-3"
                            onClick={() => props.handleClickFilter()}
                        >
                            Aplicar filtro
                        </button>

                        <button
                            className="btn-shadow btn btn-danger"
                            onClick={() => props.cancelFilter()}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            </form>
        </React.Fragment>
      )

}
export default FormFilter;