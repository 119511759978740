import React, { Component } from 'react';
import Select from "react-select";
import Preloader from '../../../../GeneralComponents/Preloaders';

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showPopoverOwnerUser: false,
            showPopoverDate: false,
            ErrorValues: true,
            isLoaded: false,
        }
    }

    validationForm = () => {
        if (this.props.formCreateChecklistItems.description != "" &&
            this.props.formCreateChecklistItems.user_owner_id != "" &&
            this.props.formCreateChecklistItems.date != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }    


    HandleClickCheckListItems = () => {
        if (this.validationForm() == true){
            this.setState({ isLoaded: true })

            if(this.props.modeEdit){

                fetch(`/task/check_list_items/${this.props.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.props.formCreateChecklistItems), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.updateCheckListItem(data.register);
                    this.props.clearValuesItems();
                    this.setState({ isLoaded: false })
                });

            }else{

                fetch(`/task/check_list_items`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.props.formCreateChecklistItems), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.updateDataItems(data.register);
                    this.props.clearValuesItems();
                    this.setState({ isLoaded: false })
                });

            }
        }
    }

    setValues = (name) => {
        this.setState({
            showPopoverOwnerUser: (name == "users" ? true : false),
            showPopoverDate: (name == "date" ? true : false)
        })
    }

    render() {
        return (
            <div className={this.props.modeEdit ? "row p-4": "row"}>
                <div className="col-md-12">
                    <textarea 
                        rows="3"
                        name="description" 
                        value={this.props.formCreateChecklistItems.description}
                        onChange={this.props.HandleChangeChecklistItems}
                        className={`form form-control ${!this.state.ErrorValues && this.props.formCreateChecklistItems.description == "" ? "error-class" : ""}`}
                        placeholder="Añadir un elemento"
                    />
                </div>

                {this.props.showFields && (
                    <React.Fragment>
                        <div className="col-md-6 mt-3">
                            <input
                                type="hidden"
                                name="user_owner_id"
                                value={this.props.selectedOption.user_owner_id}
                            />

                            <Select
                                onChange={this.props.handleChangeAutocomplete}
                                options={this.props.users}
                                autoFocus={false}
                                className={`link-form ${!this.state.ErrorValues && this.props.formCreateChecklistItems.user_owner_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOption}
                            />
                        </div>

                        <div className="col-md-6 mt-3">
                            <input
                                type="date"
                                name="date" 
                                value={this.props.formCreateChecklistItems.date}
                                onChange={this.props.HandleChangeChecklistItems}
                                className={`form form-control ${!this.state.ErrorValues && this.props.formCreateChecklistItems.date == "" ? "error-class" : ""}`}
                            />
                        </div>
                    </React.Fragment>
                )}


                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-12">
                            <button
                                className="btn-shadow btn btn-primary"
                                onClick={() => this.HandleClickCheckListItems()}
                                disabled={this.state.isLoaded}
                                style={{
                                    width: (this.state.isLoaded ? "120px" : ""),
                                    display: (this.state.isLoaded ? "inline-flex" : "")
                                }}
                            >
                                {this.state.isLoaded ? (
                                    <React.Fragment> 
                                        {"Cargando... "} <div className="ml-2"> <Preloader /> </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment> 
                                        {this.props.modeEdit ? "Actualizar" : "Añadir"}
                                    </React.Fragment>
                                )}
                            </button>

                            <i 
                                className="fas fa-times icon-cancel-items icon-cancel-items-task"
                                onClick={() => this.props.clearValuesItems()}
                            >
                                
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormCreate;
