import React, { Component } from 'react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './FormCreate';
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Select from "react-select";

class Index extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            type_question: "",
            data: [],
            isLoaded: true,
            modeEdit: false,
            showForm: false,
            ErrorValues: true,

            form: {
                format_custom_view_id: this.props.format_custom_view.id,
                operation: "",
                value: "",
                conditional: "",
                question_id: "",
            },

            selectedOption: {
                question_id: "",
                label: "Pregunta"
            },

            selectedOptionSelect: {
                value: "",
                label: "Módulo"
            },

            options: [],
            users: [],
            procesos: [],
            cargos: [],
            currentOptions: [],
            options_operation: ["Contiene", "Igual", "Diferente"],
        }
    }

    componentDidMount() {
        this.loadData();
        this.configSelect();
    }

    configSelect = () => {
        let array = []
        let arrayCargos = []
        let arrayProcesos = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))
        array.push({ label: "Usuario en sesión", value: 0 })

        this.props.procesos.map((item) => (
            arrayProcesos.push({ label: item.name, value: item.id })
        ))

        this.props.cargos.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))
        arrayCargos.push({ label: "Cargo en sesión", value: 0 })

        this.setState({
            users: array,
            procesos: arrayProcesos,
            cargos: arrayCargos
        })
    }

    loadData = () => {
        fetch(`/formatos/format_custom_views/${this.props.format_custom_view.id}/get_custom_view_filter`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    isLoaded: false
                });
            });
    }

    clearValues = () => {
        this.setState({
            showForm: false,
            modeEdit: false,
            ErrorValues: true,
            id: "",
            type_question: "",
            options: [],
            options_operation: ["Contiene", "Igual", "Diferente"],

            form: {
                ...this.state.form,
                operation: "",
                value: "",
                conditional: "",
                question_id: "",
            },

            selectedOptionSelect: {
                value: "",
                label: "Módulo"
            },

            selectedOption: {
                question_id: "",
                label: "Pregunta"
            },
        })
    }

    updateItem = custom_view_filter => {
        this.setState({
            data: this.state.data.map(item => {
                if (custom_view_filter.id === item.id) {
                    return {
                        ...item,
                        question: custom_view_filter.question,
                        operation: custom_view_filter.operation,
                        conditional: custom_view_filter.conditional,
                        value: custom_view_filter.value,
                    }
                }
                return item;
            })
        });
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    validationForm = () => {
        if (this.state.form.question_id != "" &&
            this.state.form.value != "" &&
            this.state.form.operation != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }


    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/custom_view_filters/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData();
                    });
            }
        });
    };

    HandleClick = () => {
        if (this.validationForm() == true) {
            if (!this.state.modeEdit)
                fetch(`/formatos/custom_view_filters`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.messageSuccess(data);
                        this.updateData(data.register);
                        this.clearValues();
                    });
            else {
                fetch(`/formatos/custom_view_filters/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.messageSuccess(data);
                        this.updateItem(data.register);
                        this.clearValues();
                    });
            }
        }
    }

    toogleForm = (type) => {
        if (type == "new")
            this.setState({ showForm: true })
        else {
            this.setState({ showForm: false })
            this.clearValues();
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    handleChangeAutocompleteSelect = selectedOptionSelect => {
        this.setState({
            selectedOptionSelect,
            form: {
                ...this.state.form,
                value: selectedOptionSelect.value
            },
        });
    };

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                question_id: selectedOption.value,
                value: "",
            },

            selectedOptionSelect: {
                value: "",
                label: this.selectType(selectedOption.type),
            },

            currentOptions: this.selectOptions(selectedOption.type),
            type_question: selectedOption.type,
            options: (selectedOption.type == "answer_select" || selectedOption.type == "answer_radio" ? selectedOption.options_values : []),
            options_operation: (selectedOption.type == "answer_integer" || selectedOption.type == "answer_formula" || selectedOption.type == "answer_date" ? ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Entre: ejemplo 10 y 20"] : ["Contiene", "Igual", "Diferente"]),
        });
    };

    selectOptions = (type_question) => {
        if (type_question == "answer_user_id") {
            return this.state.users
        } else if (type_question == "proceso_id") {
            return this.state.procesos
        } else if (type_question == "position_id") {
            return this.state.cargos

        }
    }

    selectType = (type_question) => {
        if (type_question == "answer_user_id") {
            return "Usuarios"
        } else if (type_question == "proceso_id") {
            return "Procesos"
        } else if (type_question == "position_id") {
            return "Cargos"
        }
    }

    edit = (custom_view_filter) => {
        this.setState({
            modeEdit: true,
            showForm: true,
            id: custom_view_filter.id,

            form: {
                ...this.state.form,
                operation: custom_view_filter.operation,
                value: custom_view_filter.question.question_type == "answer_user_id" || custom_view_filter.question.question_type == "proceso_id" || custom_view_filter.question.question_type == "position_id" ? custom_view_filter.value.id : custom_view_filter.value,

                conditional: custom_view_filter.conditional,
                question_id: custom_view_filter.question.id,
            },

            selectedOptionSelect: {
                value: custom_view_filter.question.question_type == "answer_user_id" || custom_view_filter.question.question_type == "proceso_id" || custom_view_filter.question.question_type == "position_id" ? custom_view_filter.value.id : "",
                label: custom_view_filter.question.question_type == "answer_user_id" || custom_view_filter.question.question_type == "proceso_id" || custom_view_filter.question.question_type == "position_id" ? custom_view_filter.value.name : "",
            },

            selectedOption: {
                question_id: custom_view_filter.question.id,
                label: custom_view_filter.question.question,
            },

            currentOptions: this.selectOptions(custom_view_filter.question.question_type),
            type_question: custom_view_filter.question.question_type,
            options: (custom_view_filter.question.question_type == "answer_select" || custom_view_filter.question.question_type == "answer_radio" ? custom_view_filter.question.options : []),
            options_operation: (custom_view_filter.question.question_type == "answer_integer" || custom_view_filter.question.question_type == "answer_formula" || custom_view_filter.question.question_type == "answer_date" ? ["Igual", "Diferente", "Mayor", "Mayor e igual", "Menor", "Menor e igual", "Entre: ejemplo 10 y 20"] : ["Contiene", "Igual", "Diferente"]),
        })
    }


    render() {
        return (
            <React.Fragment>
                <div className="card mb-3">
                    <div className="card-header" style={{ display: "grid" }}>
                        <div className="row">
                            <div className="col-md-4">
                                {this.props.format_custom_view.type == "dashboard" ? "COMPONER FILTRO" : "Componer vista"}
                            </div>

                            <div className="col-md-8 text-right ui-indexCustomViewFilter-btnOptions">
                                {this.props.format_custom_view.type == "dashboard" ? (
                                    <React.Fragment>
                                        <a
                                            className="btn-shadow btn btn-primary mr-2"
                                            href={`/formatos/custom_dashboard/${this.props.format_custom_view.id}`}
                                            data-turbolinks="true"
                                            style={{ textTransform: "initial" }}
                                        >
                                            <i className="far fa-list-alt"></i> Ver estadísticas
                                        </a>
                                    </React.Fragment>
                                ) : (
                                    <a
                                        className="btn-shadow btn btn-primary mr-2"
                                        href={`/formatos/custom_view/${this.props.format_custom_view.id}`}
                                        data-turbolinks="true"
                                        style={{ textTransform: "initial" }}
                                    >
                                        <i className="far fa-list-alt"></i> Ver vista
                                    </a>
                                )}

                                <a
                                    className="btn-shadow btn btn-primary mr-1"
                                    href={`/formatos/format_categories/${this.props.format_custom_view.format_category.token}/formats/${this.props.format_custom_view.format.token}`}
                                    data-turbolinks="true"
                                    style={{ textTransform: "initial" }}
                                >
                                    Volver al editor del formato
                                </a>


                                <button
                                    className="btn-shadow btn btn-primary ml-2"
                                    onClick={() => this.toogleForm("new")}
                                >
                                    <i className="fas fa-plus mr-2"></i> Agregar filtros
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="card-body ui-indexCustomViewFilter-cardBody">

                        {this.state.showForm && (
                            <FormCreate
                                backdrop={"static"}
                                modal={this.state.showForm}
                                toggle={this.toogleForm}
                                title={this.state.modeEdit ? "Actualizar" : "Crear"}
                                nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                                formValues={this.state.form}
                                HandleChange={this.HandleChange}
                                data={this.state.data}
                                errorValues={this.state.ErrorValues}
                                handleClick={this.HandleClick}
                                options={this.state.options}
                                options_operation={this.state.options_operation}
                                type_question={this.state.type_question}

                                selectedOption={this.state.selectedOption}
                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                questions={this.props.questions}

                                selectedOptionSelect={this.state.selectedOptionSelect}
                                handleChangeAutocompleteSelect={this.handleChangeAutocompleteSelect}
                                values={this.state.currentOptions}
                            />
                        )}

                        <table className="table " id="sampleTable">
                            <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                <tr>
                                    <th>Condicional</th>
                                    <th>Pregunta</th>
                                    <th>Operación</th>
                                    <th>Valor</th>
                                    <th style={{ width: "11%" }}>Opciones</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.data.length >= 1 ? (
                                    this.state.data.map((custom_view_filter, index) => (
                                        <React.Fragment>
                                            {index >= 1 ? <div className="mb-2"></div> : null}
                                            <tr key={custom_view_filter.id} className="tr-table-task">
                                                <td>{custom_view_filter.conditional}</td>
                                                <td>{custom_view_filter.question.question}</td>
                                                <td>{custom_view_filter.operation}</td>
                                                <td>{custom_view_filter.question.question_type == "answer_user_id" || custom_view_filter.question.question_type == "proceso_id" || custom_view_filter.question.question_type == "position_id" ? custom_view_filter.value.name : custom_view_filter.value}</td>
                                                {true && (
                                                    <td className="text-right" style={{ width: "13%" }}>
                                                        {true && (
                                                            <React.Fragment>
                                                                <button
                                                                    onClick={(e) => this.edit(custom_view_filter)}
                                                                    className="btn"
                                                                    style={{ color: "green" }}
                                                                    id={`filed${custom_view_filter.id}`}
                                                                >
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>

                                                                <UncontrolledTooltip placement="top" target={`filed${custom_view_filter.id}`}>
                                                                    {"Editar"}
                                                                </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        )}

                                                        {true && (
                                                            <React.Fragment>
                                                                <button
                                                                    onClick={(e) => this.delete(custom_view_filter.id)}
                                                                    className="btn"
                                                                    style={{ color: "red" }}
                                                                    id={`delete${custom_view_filter.id}`}
                                                                >
                                                                    <i className="far fa-trash-alt"></i>
                                                                </button>

                                                                <UncontrolledTooltip placement="top" target={`delete${custom_view_filter.id}`}>
                                                                    {"Eliminar"}
                                                                </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                )}

                                            </tr>
                                        </React.Fragment>
                                    ))
                                ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="text-center mt-4 mb-4">
                                                    <h4>No hay registros</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>

                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default Index;
