import React, { Component } from 'react';

class Preloaders extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="preloader-double-torus"></div>
            </React.Fragment>
        );
    }
}

export default Preloaders;