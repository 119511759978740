import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-3">

                        <div className="col-md-6">
                            <label>Tipo de pregunta</label>
                            <select
                                name="question_type"
                                value={this.props.formValues.question_type}
                                onChange={this.props.onChangeForm}
                                className={`form-control ${!this.props.errorValues && this.props.formValues.question_type == "" ? "error-class" : ""}`}
                            >
                                <option value="">Seleccione un valor</option>
                                <option value="date">Fecha</option>
                                <option value="datetime">Fecha Hora</option>
                            </select>
                        </div>
                        
                        <div className="col-md-6 mb-3">
                            <input
                                type="hidden"
                                name="question_first_id"
                                value={this.props.selectedOptionQuestion.question_first_id}
                            />
                            <label>Primera pregunta</label>
                            <Select
                                onChange={this.props.handleChangeAutocompleteQuestions}
                                options={this.props.questions}
                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.question_first_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOptionQuestion}
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <input
                                type="hidden"
                                name="question_second_id"
                                value={this.props.selectedOption.question_second_id}
                            />
                            <label>Segunda pregunta</label>
                            <Select
                                onChange={this.props.handleChangeAutocomplete}
                                options={this.props.questions}
                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.question_second_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOption}
                            />
                        </div>

                        <div className="col-md-6">
                            <label>Operación</label>
                            <select
                                name="operation"
                                value={this.props.formValues.operation}
                                onChange={this.props.onChangeForm}
                                className={`form-control ${!this.props.errorValues && this.props.formValues.operation == "" ? "error-class" : ""}`}
                            >
                                <option value="">Seleccione un valor</option>
                                <option value="resta">Resta</option>
                                <option value="due_days">Resta de hoy</option>
                            </select>
                        </div>
                        
                        {!this.props.errorValues && (
                            <div className="col-md-12">
                                <div className="alert alert-warning text-center" role="alert">
                                    <b>Debes de completar todos los datos requeridos</b>
                                </div>
                            </div>
                        )}

                        <div className="col-md-12 text-right">
                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.props.submitForm()}
                            >
                                {this.props.nameBnt}
                            </button>

                            <hr />
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;