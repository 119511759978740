import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NumberFormat from 'react-number-format';
import Select from "react-select";
import Dropzone from "react-dropzone";

class FormCreate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            disabled: true
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };


    handleChangeChecked = (e) => {
        const isChecked = e.target.checked;

        this.setState({
            disabled: isChecked,
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg modal-lg-980" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-4 mb-3">
                                    <input
                                        type="hidden"
                                        name="document_type_id"
                                        value={this.props.selectedOptionDocumentType.document_type_id}
                                    />
                                    <label>Tipo de documento</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteDocumentType}
                                        options={this.props.document_types}
                                        autoFocus={false}
                                        className={`link-form ${!this.props.errorValues && this.props.formValues.document_type_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionDocumentType}
                                    />
                                </div>

                                <div className="col-md-8 mb-3">
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={this.props.formValues.name}
                                        onChange={this.props.onChangeForm}
                                        disabled={/* !this.props.editInputs */false}
                                        className={`form form-control ${!this.props.errorValues && this.props.formValues.name == "" ? "error-class" : ""}`}
                                    />
                                </div>


                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label>Solicitud de documento</label>
                                    <input
                                        type="date"
                                        name="due_date"
                                        value={this.props.formValues.due_date}
                                        onChange={this.props.onChangeForm}
                                        disabled={/* !this.props.editInputs */false}
                                        className={`form form-control ${!this.props.errorValues && this.props.formValues.due_date == "" ? "error-class" : ""}`}
                                    />
                                </div>


                                {this.props.has_process && (
                                    <div className="col-md-4 mb-3">
                                        <input
                                            type="hidden"
                                            name="proceso_id"
                                            value={this.props.selectedOptionProceso.proceso_id}
                                        />
                                        <label>Proceso </label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteProceso}
                                            options={this.props.procesos}
                                            isDisabled={/* !this.props.editInputs */false}
                                            autoFocus={false}
                                            className={`link-form ${!this.props.errorValues && this.props.has_process && this.props.formValues.proceso_id == "" ? "error-class" : ""}`}
                                            value={this.props.selectedOptionProceso}
                                        />
                                    </div>
                                )}

                                <div className="col-md-4">
                                    <label>Versión</label>
                                    <input
                                        type="number"
                                        name="version"
                                        value={this.props.formValues.version}
                                        onChange={this.props.onChangeForm}
                                        disabled={this.props.is_clone}
                                        className={`form form-control ${!this.props.errorValues && this.props.formValues.version == "" ? "error-class" : ""}`}
                                    />
                                </div>

                                <div className="col-md-4">
                                    <label>Consecutivo</label>
                                    <input
                                        type="number"
                                        name="count"
                                        value={this.props.formValues.count}
                                        onChange={this.props.onChangeForm}
                                        disabled={this.state.disabled}
                                        className={`form form-control`}
                                    />

                                    <span className="label-12">
                                        (Solo ingresalo si el documento ya tiene un consecutivo asignado)
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" onChange={this.handleChangeChecked} className="custom-control-input" id={`customHeader`} value={!this.state.disabled} checked={this.state.disabled} />
                                            <label className="custom-control-label" htmlFor={`customHeader`}>Cambiar consecutivo</label>
                                        </div>
                                    </span>
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label>Cargos que pueden acceder <small className="validate-label">(opcional)</small></label>
                                    <input
                                        type="hidden"
                                        name="position_ids"
                                        value={this.props.selectedOptionMulti.position_ids}
                                    />

                                    <Select
                                        onChange={this.props.handleChangeAutocompleteMulti}
                                        options={this.props.positions}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        autoFocus={false}
                                        className={`link-form`}
                                        classNamePrefix="select"
                                        placeholder="Seleccione"
                                        name="position_ids"
                                        defaultValue={this.props.editValues}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                </div>

                                {false && (
                                    <div className="col-md-4 mb-3">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" onChange={this.props.handleChangeChecked} className="custom-control-input" id={`customHeader`} value={!this.props.formValues.is_attached} checked={this.props.formValues.is_attached} />
                                            <label className="custom-control-label" htmlFor={`customHeader`}>¿Tiene un archivo?</label>
                                        </div>
                                    </div>
                                )}

                                {this.props.company.permit_upload_file && (
                                    <div className="col-md-12 mb-3">
                                        <Dropzone onDrop={(files) => this.props.handleChangeFile(files)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div
                                                    {...getRootProps({
                                                        className: `dropzone`,
                                                    })}
                                                >
                                                    <input {...getInputProps()} />
                                                    <p>{`${(this.props.nameFile ? this.props.nameFile : "Arrastra y suelta el archivo aquí o haz clic para subir el archivo. Puedes subir archivos de máximo 10 Mb")}`}</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                )}

                                {this.props.sizeFile >= 11485760 && (
                                    <div className="col-md-12">
                                        <div className="alert alert-info text-center" role="alert">
                                            <b>El archivo que intentas subir, ha superado el limite permitido de 10Mb. Intenta de nuevo con otro archivo</b>
                                        </div>
                                    </div>
                                )}
                                
                                {this.props.company.permit_upload_file && (
                                    <div className="col-md-12">
                                        <hr />
                                    </div>
                                )}

                                <div className="col-md-4 mb-3">
                                    <input
                                        type="hidden"
                                        name="user_create_id"
                                        value={this.props.selectedOptionUserCreate.user_create_id}
                                    />
                                    <label>Usuario que crea </label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteUserCreate}
                                        isDisabled={/* !this.props.editInputs */false}
                                        options={this.props.users}
                                        autoFocus={false}
                                        className={`link-form ${!this.props.errorValues && this.props.formValues.user_create_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionUserCreate}
                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <input
                                        type="hidden"
                                        name="user_review_id"
                                        value={this.props.selectedOptionUserReview.user_review_id}
                                    />
                                    <label>Usuario que revisa </label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteUserReview}
                                        options={this.props.users}
                                        isDisabled={/* !this.props.editInputs */false}
                                        autoFocus={false}
                                        className={`link-form ${!this.props.errorValues && this.props.formValues.user_review_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionUserReview}
                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <input
                                        type="hidden"
                                        name="user_approve_id"
                                        value={this.props.selectedOptionUserApprove.user_approve_id}
                                    />
                                    <label>Usuario que aprueba</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteUserApprove}
                                        options={this.props.users}
                                        isDisabled={/* !this.props.editInputs */false}
                                        autoFocus={false}
                                        className={`link-form ${!this.props.errorValues && this.props.formValues.user_approve_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionUserApprove}
                                    />
                                </div>


                                {!this.props.errorValues && (
                                    <div className="col-md-12 mt-4">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes completar todos los campos obligatorios, que tienen el simbolo *</b>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button className="btn-shadow btn btn-info"
                                onClick={this.props.submitForm}
                                disabled={this.props.isLoaded || this.props.sizeFile >= 11485760}
                                style={{ color: "#ffff" }}
                            >
                                {this.props.isLoaded ? "Cargando.." : this.props.nameBnt}
                            </button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;