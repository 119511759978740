import React, { Component } from "react";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { isMobile } from "react-device-detect";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from "react-html-parser";
import { Progress } from "reactstrap";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import loading from "../../../GeneralComponents/LoadingComponen";
import LoadingComponen from "../../../GeneralComponents/LoadingComponen";
import TimeField from "react-simple-timefield";
import PreloaderMini from '../../../GeneralComponents/Preloaders';


class Filter extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            formUpdateRepySelect: {},
            formatValuesFormat: {

            },
            formUpdateAnswerSelect: {
                answer_type: "",
                value: "",
                label: "",
                selectItems: [],
            },

            //GER VALUES TO ANSWER TYPE FORMAT
            format_field_loading: false,
            format_field_current: 0,
        }
    }


    /// GET FORMAT VALUES
    handleInputChangeSelect = (value, e) => {
        if (value.length >= 3) {
            this.getFormatsValuesFormat(value, e)
        }
        console.log(value)
        console.log(e, "fadsfds")
    }

    handleInputOnFocusSelect = (value, e) => {


        this.getFormatsValuesFormat("bw-blank", e)
        console.log(value, "value")
        console.log(e, "e")
    }


    getFormatsValuesFormat = (value, format_id) => {
        this.setState({
            format_field_current: format_id,
            format_field_loading: true,
        })
        fetch(`/formatos/get_formats_values_format/${format_id}/${value}/0/0`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    format_field_current: 0,
                    format_field_loading: false,
                    formatValuesFormat: {
                        ...this.state.formatValuesFormat,
                        [format_id]: data
                    }
                })
                console.log(data)
            });
    }

    HandleChangeUpdateAnswerSelect = (e, type) => {

        this.props.HandleChangeRepyFormat(e, type)

        this.setState({
            formUpdateAnswerSelect: {
                ...this.state.formUpdateAnswerSelect,
                label: e.label,
                value: e.value
            }
        });

    }



    componentWillReceiveProps = (nexProps) => {
        console.log(nexProps.formUpdateRepySelect)
        console.log(nexProps)
        //let filterPareameters = nexProps.filters
        //COMO EL COMPONENTE SE RENDEREA TRAE NUEVAMENTE LOS FILTROS
        this.setState({
            formUpdateRepySelect: nexProps.formUpdateRepySelect,
        })
    }

    getTypeQuestion = (question) => {
        if (
            question.question_type == "answer_string" ||
            question.question_type == "email" ||
            question.question_type == "answer_text" ||
            question.question_type == "code_format" ||
            question.question_type == "url" ||
            question.question_type == "dynamic_field" ||
            question.question_type == "ce_10"
        ) {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        name="answer_string"
                        className="form form-control imput-options-disabled mb-1"
                        placeholder=""
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepy}
                    />
                    <select
                        name="answer_string_type"
                        className="form form-control"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepySelect[question.id] != undefined
                                ? this.props.formUpdateRepySelect[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepySelect}
                    >
                        <option value="contiene">Contiene</option>
                        <option value="diferente">No contiene</option>
                        <option value="igual">Igual</option>
                    </select>
                </React.Fragment>
            );
        } else if (
            question.question_type == "answer_integer" ||
            question.question_type == "answer_formula"
        ) {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled mb-1"
                        placeholder={question.question}
                        name="answer_integer"
                        /*  onBlur={this.updateRepy} */
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepy}
                    />
                    <select
                        name="answer_integer_type"
                        className="form form-control"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepySelect[question.id] != undefined
                                ? this.props.formUpdateRepySelect[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepySelect}
                    >
                        <option value="contiene">Igual</option>
                        <option value="diferente">Diferente</option>
                        <option value="mayor">Mayor</option>
                        <option value="mayorigual">Mayor e igual</option>
                        <option value="menor">Menor</option>
                        <option value="menorigual">Menor e igual</option>
                        <option value="entre">Entre: ejemplo 10 y 20</option>
                    </select>
                </React.Fragment>
            );
        } else if (
            question.question_type == "answer_select" ||
            question.question_type == "answer_radio" ||
            question.question_type == "answer_checkbox"
        ) {
            return (
                <React.Fragment>
                    <select
                        name="answer_select"
                        className="form form-control mb-1"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepy}
                    >
                        <option value="">{question.question}</option>
                        {question.options.map((name, index) => (
                            <option value={name.name}>{name.name}</option>
                        ))}
                    </select>
                    <select
                        name="answer_select_type"
                        className="form form-control mb-1"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepySelect[question.id] != undefined
                                ? this.props.formUpdateRepySelect[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepySelect}
                    >
                        <option value="contiene">Contiene</option>
                        <option value="diferente">No contiene</option>
                        <option value="igual">Igual</option>
                    </select>
                </React.Fragment>
            );
        } else if (question.question_type == "answer_datetime") {
            const format = "h:mm a";
            const now = moment().hour(0).minute(0);

            return (
                <React.Fragment>
                    <input
                        type="datetime-local"
                        className="form form-control imput-options-disabled width-48"
                        name="answer_time"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        data-date={"desde"}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepyDate1}
                    />
                    <input
                        type="datetime-local"
                        className="form form-control imput-options-disabled width-48"
                        name="answer_time"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        data-date={"hasta"}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepyDate2}
                    />
                </React.Fragment>
            );
        } else if (
            question.question_type == "answer_date" ||
            question.question_type == "answer_autdatetime"
        ) {
            return (
                <React.Fragment>
                    <select
                        name="answer_select_type"
                        className="form form-control mb-1"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepySelect[question.id] != undefined
                                ? this.props.formUpdateRepySelect[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepySelect}
                    >
                        <option value="igual">Igual</option>
                        <option value="mayor">Mayor e igual a</option>
                        <option value="menor">Menor e igual a</option>
                        <option value="entre">Entre</option>
                    </select>
                    <div className="filterInputIndex ui-filter_input_index">
                        <input
                            type="date"
                            className={`form form-control imput-options-disabled mb-1 ${this.state.formUpdateRepySelect[question.id] === "entre" && 'ui-filter-date-one'}`}
                            name="answer_date"
                            data-id={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            data-date={"desde"}
                            value={
                                this.props.formUpdateRepy[question.id] != undefined
                                    ? this.props.formUpdateRepy[question.id].value
                                    : ""
                            }
                            onChange={this.props.HandleChangeRepyDate1}
                        />

                        {
                            (this.state.formUpdateRepySelect[question.id] === "entre") && (
                                <input
                                    type="date"
                                    className="form form-control imput-options-disabled ui-filter-date-two"
                                    name="answer_date"
                                    data-id={question.id}
                                    data-type={question.question_type}
                                    data-mandatory={question.is_mandatory}
                                    data-active={question.is_active}
                                    data-date={"hasta"}
                                    value={
                                        this.props.formUpdateRepy[question.id] != undefined
                                            ? this.props.formUpdateRepy[question.id].value
                                            : ""
                                    }
                                    onChange={this.props.HandleChangeRepyDate2}
                                />
                            )

                        }

                    </div>
                </React.Fragment>
            );
        } else if (question.question_type == "answer_hour") {
            return (
                <React.Fragment>
                    <TimeField
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepyDate1}
                        input={
                            <input
                                type="text"
                                className="form form-control imput-options-disabled mb-1"
                                name="answer_date"
                                data-id={question.id}
                                data-type={question.question_type}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                data-date={"desde"}
                            />
                        }
                    />
                    <TimeField
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepyDate2}
                        input={
                            <input
                                type="text"
                                className="form form-control imput-options-disabled"
                                name="answer_date"
                                data-id={question.id}
                                data-type={question.question_type}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                data-date={"hasta"}
                            />
                        }
                    />
                </React.Fragment>
            );
        } else if (question.question_type == "answer_user_id") {
            return (
                <React.Fragment>
                    <select
                        name="answer_select"
                        className="form form-control mb-1"
                        data-id={question.id}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        data-type={question.question_type}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : null
                        }
                        onChange={this.props.HandleChangeRepy}
                    >
                        <option value="">Seleccione una opción</option>
                        {this.props.users.map((name, index) => (
                            <option value={name.id}>{name.name}</option>
                        ))}
                    </select>

                    <select
                        name="answer_select_type"
                        className="form form-control"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepySelect}
                    >
                        <option value="contiene">Contiene</option>
                        <option value="diferente">No contiene</option>
                    </select>
                </React.Fragment>
            );
        } else if (question.question_type == "position_id") {
            return (
                <React.Fragment>
                    <select
                        name="position_id"
                        className="form form-control mb-1"
                        /*  onChange={(e) => this.setValueSelect(e, question)} */
                        data-id={question.id}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        data-type={question.question_type}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : null
                        }
                        onChange={this.props.HandleChangeRepy}
                    >
                        <option value="">Seleccione una opción</option>
                        {this.props.positions.map((name, index) => (
                            <option value={name.id}>{name.name}</option>
                        ))}
                    </select>

                    <select
                        name="answer_select_type"
                        className="form form-control"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepySelect}
                    >
                        <option value="contiene">Contiene</option>
                        <option value="diferente">No contiene</option>
                    </select>
                </React.Fragment>
            );
        } else if (question.question_type == "proceso_id") {
            return (
                <React.Fragment>
                    <select
                        name="proceso_id"
                        className="form form-control mb-1"
                        /*  onChange={(e) => this.setValueSelect(e, question)} */
                        data-id={question.id}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        data-type={question.question_type}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : null
                        }
                        onChange={this.props.HandleChangeRepy}
                    >
                        <option value="">Seleccione una opción</option>
                        {this.props.procesos.map((name, index) => (
                            <option value={name.id}>{name.name}</option>
                        ))}
                    </select>

                    <select
                        name="answer_select_type"
                        className="form form-control"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepySelect}
                    >
                        <option value="contiene">Contiene</option>
                        <option value="diferente">No contiene</option>
                    </select>
                </React.Fragment>
            );
        } else if (question.question_type == "format_id") {
            return (
                <React.Fragment>


                    <React.Fragment>
                        <div className="question-format-id ui-filter-question-format-id">
                            {(this.state.format_field_current == question.id && this.state.format_field_loading) && (
                                <div className='question-format-id__loading--filter'>  <PreloaderMini /></div>
                            )}
                            <input
                                type="hidden"
                                name="specific_area_id"
                                value={this.state.formUpdateAnswerSelect.value}
                            />

                            <Select
                                onChange={(e) => this.HandleChangeUpdateAnswerSelect(e, question)}
                                onInputChange={(e) => this.handleInputChangeSelect(e, question.id)}
                                onFocus={(e) => this.handleInputOnFocusSelect(e, question.id)}
                                options={this.state.formatValuesFormat[question.id] ? this.state.formatValuesFormat[question.id].data : []}
                                autoFocus={false}
                                className={`link-form ui-filter_surveysformatos-linkForm`}
                                value={this.state.formUpdateAnswerSelect}
                            />
                        </div>
                    </React.Fragment>





                    {/* 
                    <select
                        name="proceso_id"
                        className="form form-control mb-1"
                     
                        data-id={question.id}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        data-type={question.question_type}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : null
                        }
                        onChange={this.props.HandleChangeRepy}
                    >
                        <option value="">Seleccione una opción</option>
                        {this.props.formValues[question.id] != undefined &&
                            this.props.formValues[question.id].data.map((name, index) => (
                                <option value={name.value}>{name.label}</option>
                            ))}
                    </select> */}

                    <select
                        name="answer_select_type"
                        className="form form-control"
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={
                            this.props.formUpdateRepy[question.id] != undefined
                                ? this.props.formUpdateRepy[question.id].value
                                : ""
                        }
                        onChange={this.props.HandleChangeRepySelect}
                    >
                        <option value="contiene">Contiene</option>
                        <option value="diferente">No contiene</option>
                    </select>
                </React.Fragment>
            );
        } else {
            return <p></p>;
        }
    };

    render() {
        return (
            <React.Fragment>
                {this.props.isDashboard && (
                    <p className="mb-1 ellipsis-1">{this.props.question.question}</p>
                )}
                {this.getTypeQuestion(this.props.question)}
            </React.Fragment>
        );
    }
}

export default Filter;
