import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from '../../formatos/Formats/FormUpdatePermisionFormat';
//import FormCreateCategories from './FormCreateCategories'
import FormCreateSubCategories from '../../formatos/FormatCategories/SubModules/FormCreate'
import ModalShowCategory from '../../formatos/FormatCategories/ModalShowCategory'
import Preloader from '../../../GeneralComponents/LoadingComponen'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import FormCreateCustomView from '../../formatos/FormatCustomView/FormUpdatePermisionCustomView'
import Scroll from 'react-scroll';

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

class FormatPermision extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            modal: false,
            modalCategories: false,
            modalSubCategories: false,
            modalCategoriesShow: false,
            modeEdit: false,
            modeEditSubCategories: false,
            modeEditFormat: false,
            modeEditFormatCustomView: false,
            isLoaded: false,
            format_custom_view_id: "",

            title: "",
            formato_id: "",
            format_category: {},
            category: {},
            format_sub_category_id: "",
            modulo_ids: [],
            created_format: false,
            current_format_category: {},
            current_category: {},
            select_category_list_id: "",

            //format_custom_view
            modalCustomView: false,

            formCreateFormatCustomView: {
                format_id: "",
                name: "",
                format_category_id: "",
                icon_id: "",
                custom_view_permission_ids: [],
            },


            selectedOptionFormat: {
                format_id: "",
                label: "Formatos"
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionFormatCategory: {
                format_category_id: "",
                label: "Categoría"
            },


            selectedOptionFormatCustomView: {
                format_category_id: "",
                label: "Categoría"
            },


            selectedOptionSubCategory: {
                format_sub_category_id: "",
                label: "Sub Módulo"
            },

            form: {
                name: "",
                icon_id: "",
                format_category_id: "",
                is_parent: false,
                show_index: true,
                position_ids: [],
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: "",
                format_sub_category_id: "",
            },

            formCreateSubCategories: {
                name: "",
                format_category_id: "",
                position_ids: [],
            },

            checkedItems: new Map(),
            oldcheckedItems: new Map(),

            //custom view checkList
            checkedItemsCustomView: new Map(),
            checkboxesCustomView: [],
            modulo_custom_view_ids: [],
            modulos_custom_view: [],

            checkboxes: [],
            dataProcesosEdit: [],
            cargos: [],
            sub_categories: [],
            modulos: [],

            categories: [],
            format_sub_categories: [],
            data: [],
            dataList: [],
            formatos: [],
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            id: "",
            created_format: false,
            modeEdit: false,
            modeEditFormat: false,
            modeEditSubCategories: false,
            modulo_ids: [],
            modulos: [],

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionFormatCategory: {
                format_category_id: "",
                label: "Categoría"
            },

            selectedOptionSubCategory: {
                format_sub_category_id: "",
                label: "Sub Módulo"
            },

            form: {
                name: "",
                format_category_id: "",
                is_parent: false,
                position_ids: [],
            },

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: "",
                format_sub_category_id: "",
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            checkedItems: new Map(),
            checkboxes: [],
            dataProcesosEdit: [],
            format_sub_categories: [],
        })
    }

    clearValuesSubCategory = () => {
        this.setState({
            formCreateSubCategories: {
                name: "",
                format_category_id: "",
                position_ids: [],
            },
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    subCategories = (from, category) => {
        if (from == "new") {
            this.setState({
                formCreateSubCategories: {
                    ...this.state.formCreateSubCategories,
                    format_category_id: category.id,
                },

                modalSubCategories: true,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        } else {
            this.clearValuesSubCategory();
            this.setState({
                modalSubCategories: false,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        }
    }

    toogleCategories = (from, state) => {
        if (from == "new") {
            this.setState({
                modalCategories: true,
                form: {
                    ...this.state.form,
                    show_index: state,
                    is_parent: state,
                }
            })
        } else {
            this.setState({ modalCategories: false })
            this.clearValues();
        }
    }

    toogleCategoriesShow = (from, format_category) => {
        if (from == "new") {
            this.setState({ modalCategoriesShow: true, format_category: format_category })
        } else {
            this.setState({ modalCategoriesShow: false, format_category: {} })
        }
    }

    componentDidMount = () => {
        this.getFormatos();
    }

    getFormatos = () => {
        fetch(`/formatos/get_formatos_all`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                let arrayFormatos = [];

                data.data.map((item) => (
                    arrayFormatos.push({ label: item.name, value: item.id })
                ))

                this.setState({
                    formatos: arrayFormatos,
                });
            });
    }

    defaultValues = () => {
        let array = [];
        let arraySubCategory = [];
        let arrayCategories = [];

        this.props.positions.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.format_sub_categories.map((item) => (
            arraySubCategory.push({ label: item.name, value: item.id })
        ))

        this.props.categories.map((item) => (
            arrayCategories.push({ label: item.name, format_sub_categories: item.format_sub_categories, value: item.id })
        ))

        this.setState({
            cargos: array,
            sub_categories: arraySubCategory,
            categories: arrayCategories,
        })
    }

    handleChangeAutocomplete = selectedOption => {
        let array = []

        selectedOption.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            form: {
                ...this.state.form,
                position_ids: array
            }
        })
    }

    handleChangeAutocompleteSubCategoryMulti = selectedOption => {
        let array = []

        selectedOption.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                position_ids: array
            }
        })
    }

    handleChangeAutocompleteSubCategory = selectedOptionSubCategory => {
        this.setState({
            selectedOptionSubCategory,
            formCreateFormat: {
                ...this.state.formCreateFormat,
                format_sub_category_id: selectedOptionSubCategory.value
            }
        });
    }


    validationForm = () => {
        if (this.state.form.name != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (!this.state.modeEdit)
            fetch(`/formatos/format_categories`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modalCategories: false })
                    this.messageSuccess(data);
                    if (data.register.level == 0) {
                        this.props.updateData(data.register);
                    }
                    if (Object.keys(this.state.current_format_category).length != 0) {
                        this.updateDataColumns(data.register, "categories");
                        //this.props.updateDataColumns(this.state.select_category_list_id, this.state.current_format_category, data.register, "categories");
                    }
                    //window.location.replace(`/formatos/format_categories`);
                });
        else {
            fetch(`/formatos/format_categories/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modalCategories: false })
                    this.messageSuccess(data);
                    this.clearValues();
                    console.log("dadadad", data.register);
                    if (data.register.level == 0) {
                        console.log(data.register);
                        this.props.updateItem(data.register);
                    } else {
                        console.log("else", data.register);
                        this.updateDataItem(data.register, "categories")
                    }
                    //window.location.replace(`/formatos/format_categories`);
                });
        }
    }

    HandleClickSubCategory = () => {
        if (this.state.modeEditSubCategories == false)
            fetch(`/formatos/format_sub_categories`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateSubCategories), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    Turbolinks.visit(`/formatos/format_categories`);
                    this.clearValuesSubCategory();
                    this.setState({ modalSubCategories: false })
                    this.messageSuccess(data);
                });
        else {
            fetch(`/formatos/format_sub_categories/${this.state.format_sub_category_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreateSubCategories), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modalSubCategories: false })
                    this.messageSuccess(data);
                    this.clearValuesSubCategory();
                    Turbolinks.visit(`/formatos/format_categories`);
                });
        }
    }

    HandleChange = (e) => {
        this.setState({
            formCreateFormat: {
                ...this.state.formCreateFormat,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChangeCategory = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChangeSubCategory = (e) => {
        this.setState({
            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                [e.target.name]: e.target.value
            }
        })
    }

    delete = (e, format_category) => {
        e.stopPropagation();


        Swal.fire({
            title: 'Escribe el nombre de la categoría para poder eliminarla',
            input: 'text',
            footer: `<p>El nombre de la categoría es (${format_category.name}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == format_category.name.trim()) {
                    fetch(`/formatos/delete_format_category/${format_category.id}`, {
                        method: "PATCH",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            this.messageSuccess(response);
                            if (format_category.level == 0) {
                                this.props.updateDelete(format_category);
                                this.setState({ data: [] });
                            } else {
                                this.setState({
                                    data: this.state.data.map(item => {
                                        if (format_category.format_category_id === item.id) {
                                            return {
                                                ...item,
                                                categories: item.categories.filter(item => item.id != format_category.id),
                                                format_custom_views: item.format_custom_views,
                                                formatos: item.formatos,
                                            }
                                        }
                                        return item;
                                    })
                                });
                            }
                        });
                } else {

                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };

    //formatos 

    HandleClickFormat = () => {
        if (!this.state.modeEditFormat) {
            fetch(`/formatos/format_categories/${this.state.formCreateFormat.format_category_id}/formats`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateFormat), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false })
                    this.updateDataColumns(data.register, "formatos");
                    //this.props.updateDataColumns(this.state.current_format_category, data.register, "formatos");
                    this.messageSuccess(data);
                    this.getFormatos();
                });

        } else {

            fetch(`/formatos/format_categories/${this.state.formCreateFormat.format_category_id}/formats/${this.state.formato_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreateFormat), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateDataItem(data.register, "formatos");
                    this.clearValues();
                    this.setState({ modal: false })
                    this.messageSuccess(data);
                });
        }
    }

    formatAdd = (category) => {
        this.setState({
            modal: true,
            modeEditFormat: false,
            title: "Añadir formato",
            checkedItems: new Map(),
            category: category,
            current_format_category: category,

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: category.id,
            }
        })
    }

    editForm = (formato, category) => {
        const arrayPermissions = formato.formats_permissions;
        const arrayNewPermissions = [];
        const array = [];
        let map = new Map();

        arrayPermissions.map((permission, index) => (
            arrayNewPermissions.push(permission.id)
        ));

        this.props.formats_permissions_all.forEach(element => {
            arrayPermissions.forEach(element_accion => {
                if (element.id == element_accion.id) {
                    map.set(element_accion.id.toString(), true)
                }
            });
        })


        category.format_sub_categories.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            modal: true,
            created_format: true,
            modeEditFormat: true,
            category: category,
            title: "Editar formato",
            formato_id: formato.id,
            checkedItems: map,

            selectedOptionSubCategory: {
                format_sub_category_id: formato.format_sub_category != null ? formato.format_sub_category.id : "",
                label: `${formato.format_sub_category != null ? formato.format_sub_category.name : "Sub Categorías"}`
            },

            selectedOptionFormatCategory: {
                format_category_id: category.id,
                label: category.name,
            },

            formCreateFormat: {
                name: formato.name,
                formats_permission_ids: arrayNewPermissions,
                format_category_id: category.id,
            },

            format_sub_categories: array,
        })
    }

    edit = (e, config) => {
        e.stopPropagation();
        let arrayPositonEdit = [];
        let arrayIds = [];

        config.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            id: config.id,
            modeEdit: true,
            dataProcesosEdit: arrayPositonEdit,
            modalCategories: true,

            form: {
                name: config.name,
                icon_id: config.icon_id,
                format_category_id: config.format_category_id,
                is_parent: config.is_parent,
                show_index: config.show_index,
                position_ids: arrayIds,
            }
        })
    }

    editSubCategory = (format_category, format_sub_category) => {
        let arrayPositonEdit = []
        let arrayIds = []

        format_sub_category.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            format_sub_category_id: format_sub_category.id,
            modeEditSubCategories: true,
            dataProcesosEdit: arrayPositonEdit,
            modalSubCategories: true,

            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                position_ids: arrayIds,
                name: format_sub_category.name,
                order_number: format_sub_category.order_number,
                format_category_id: format_category.id,
            }
        })
    }

    deleteFormat = (category_id, formato) => {

        Swal.fire({
            title: 'Escribe el nombre del formato para poder eliminarlo',
            input: 'text',
            footer: `<p>El nombre del formato es (${formato.name}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == formato.name.trim()) {
                    fetch(`/formatos/delete_format/${formato.id}`, {
                        method: "PATCH",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            this.messageSuccess(response);
                            this.getFormatos();
                            this.setState({
                                data: this.state.data.map(item => {
                                    if (category_id === item.id) {
                                        //const indexRegister =  item.formatos.findIndex(format => format.id === formato.id);
                                        return {
                                            ...item,
                                            categories: item.categories,
                                            format_custom_views: item.format_custom_views,
                                            formatos: item.formatos.filter(item => item.id != formato.id),
                                        }
                                    }
                                    return item;
                                })
                            });
                        });
                } else {

                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };


    deleteCustomView = (category_id, format_custom_view) => {

        Swal.fire({
            title: 'Escribe el nombre de vista eliminarlo',
            input: 'text',
            footer: `<p>El nombre de la vista es (${format_custom_view.name}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == format_custom_view.name.trim()) {
                    fetch(`/formatos/format_custom_views/${format_custom_view.id}`, {
                        method: "delete",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            this.messageSuccess(response)

                            this.setState({
                                data: this.state.data.map(item => {
                                    if (category_id === item.id) {
                                        //const indexRegister =  item.formatos.findIndex(format => format.id === formato.id);
                                        return {
                                            ...item,
                                            categories: item.categories,
                                            format_custom_views: item.format_custom_views.filter(item => item.id != format_custom_view.id),
                                            formatos: item.formatos,
                                        }
                                    }
                                    return item;
                                })
                            });
                        });
                } else {

                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };

    /*deleteFormat = (category_id, id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_categories/${category_id}/formats/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.messageSuccess(response)
                       Turbolinks.visit(`/formatos/format_categories`);
                    });
            }
        });

            preConfirm: (login) => {
              return fetch(`/delete_agreement/${accion.id}/${login}`, {
                method: 'delete'
            })
                .then(response => {
                  if (!response.ok) {
                    throw new Error(response.statusText)
                  }
                  return response.json()
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `${error == "Internal Server Error" ? "" : "Error el nombre no concuerda"}`
                  )
                })
            },
    };

    */

    deleteSubCategory = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_sub_categories/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        Turbolinks.visit(`/formatos/format_categories`);
                        this.messageSuccess(response)
                    });
            }
        });
    };

    handleChangeAccions = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        const array = this.state.formCreateFormat.formats_permission_ids

        this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, isChecked)
        }));

        console.log("items", item)

        if (isChecked) {

            array.push(parseInt(item))
            this.setState({
                formCreateFormat: {
                    ...this.state.formCreateFormat,
                    formats_permission_ids: array
                },
            })

            console.log("añadiendo items")
        } else {
            console.log("quitando items")

            var arr = array
            var index = array.indexOf(parseInt(item))
            delete arr[index]

            console.log("delete array")
            console.log(arr.filter(item => item != null))

            this.setState({
                formCreateFormat: {
                    ...this.state.formCreateFormat,
                    formats_permission_ids: arr.filter(item => item != null),
                },
            })
        }
    };

    handleChangeSelectAll = (e, modulo) => {
        const checkedItems = this.state.checkedItems;
        const isChecked = e.target.checked;

        const modulesIds = this.state.modulo_ids;
        const defaultValues = this.state.formCreateFormat.formats_permission_ids

        if (isChecked) {

            this.setState(
                {

                    modulo_ids: [...this.state.modulo_ids, modulo.id],
                    modulos: [...this.state.modulos.filter(item => item.id != modulo.id), modulo],
                }

                , function () {
                    var jeje = this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions
                    this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions.map(value => {
                        checkedItems.set(`${value.id}`, true)
                    })
                    this.setState(
                        {
                            checkedItems: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(Number(key));
                                }
                            }
                            this.setState(
                                {
                                    formCreateFormat: {
                                        ...this.state.formCreateFormat,
                                        formats_permission_ids: array_final,
                                    },

                                })
                        }
                    )
                });




        } else {
            const newSelectCheckedItems = new Map();
            var arr = modulesIds
            var index = modulesIds.indexOf(parseInt(modulo.id))
            delete arr[index]

            var arrModulos = this.state.modulos
            var indexModulos = 0

            /* 
                        this.state.modulos.filter(lista => lista.id != modulo.id).map((modulo) => (
                            modulo.formats_permissions.filter(value => value.name != "Editar todos" && value.name != "Eliminar todos").map((item) => (
                                newSelectCheckedItems.set(`${item.id}`, true) //true this.state.checkedItems.has(item.id)
                            ))
                        )) */

            this.setState(
                {

                    modulo_ids: arr.filter(item => item != null),
                    modulos: arrModulos.filter(item => item != null)
                }

                , function () {
                    var jeje = this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions
                    this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions.map(value => {
                        checkedItems.set(`${value.id}`, false)
                    })
                    this.setState(
                        {
                            checkedItems: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(key);
                                }
                            }
                            this.setState(
                                {
                                    formCreateFormat: {
                                        ...this.state.formCreateFormat,
                                        formats_permission_ids: array_final,
                                    },

                                })
                        }
                    )

                });

        }
    };


    //checkbox custom view


    handleChangeAccionsCustomView = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        const array = this.state.formCreateFormatCustomView.custom_view_permission_ids

        this.setState(prevState => ({
            checkedItemsCustomView: prevState.checkedItemsCustomView.set(item, isChecked)
        }));

        console.log("items", item)

        if (isChecked) {

            array.push(parseInt(item))
            this.setState({
                formCreateFormatCustomView: {
                    ...this.state.formCreateFormatCustomView,
                    custom_view_permission_ids: array
                },
            })

            console.log("añadiendo items")
        } else {
            console.log("quitando items")

            var arr = array
            var index = array.indexOf(parseInt(item))
            delete arr[index]

            console.log("delete array")
            console.log(arr.filter(item => item != null))

            this.setState({
                formCreateFormatCustomView: {
                    ...this.state.formCreateFormatCustomView,
                    custom_view_permission_ids: arr.filter(item => item != null),
                },
            })
        }
    };

    handleChangeSelectAllCustomView = (e, modulo) => {
        const checkedItems = this.state.checkedItemsCustomView;
        const isChecked = e.target.checked;

        const modulesIds = this.state.modulo_custom_view_ids;
        const defaultValues = this.state.formCreateFormatCustomView.custom_view_permission_ids

        if (isChecked) {

            this.setState(
                {

                    modulo_custom_view_ids: [...this.state.modulo_custom_view_ids, modulo.id],
                    modulos_custom_view: [...this.state.modulos_custom_view.filter(item => item.id != modulo.id), modulo],
                }

                , function () {
                    var jeje = this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions
                    this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions.map(value => {
                        checkedItems.set(`${value.id}`, true)
                    })
                    this.setState(
                        {
                            checkedItemsCustomView: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(Number(key));
                                }
                            }
                            this.setState(
                                {
                                    formCreateFormatCustomView: {
                                        ...this.state.formCreateFormatCustomView,
                                        custom_view_permission_ids: array_final,
                                    },

                                })
                        }
                    )
                });




        } else {
            const newSelectCheckedItems = new Map();
            var arr = modulesIds
            var index = modulesIds.indexOf(parseInt(modulo.id))
            delete arr[index]

            var arrModulos = this.state.modulos_custom_view
            var indexModulos = 0

            /* 
                        this.state.modulos.filter(lista => lista.id != modulo.id).map((modulo) => (
                            modulo.formats_permissions.filter(value => value.name != "Editar todos" && value.name != "Eliminar todos").map((item) => (
                                newSelectCheckedItems.set(`${item.id}`, true) //true this.state.checkedItems.has(item.id)
                            ))
                        )) */

            this.setState(
                {

                    modulo_custom_view_ids: arr.filter(item => item != null),
                    modulos_custom_view: arrModulos.filter(item => item != null)
                }

                , function () {
                    var jeje = this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions
                    this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions.map(value => {
                        checkedItems.set(`${value.id}`, false)
                    })
                    this.setState(
                        {
                            checkedItemsCustomView: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(key);
                                }
                            }
                            this.setState(
                                {
                                    formCreateFormatCustomView: {
                                        ...this.state.formCreateFormatCustomView,
                                        custom_view_permission_ids: array_final,
                                    },

                                })
                        }
                    )

                });

        }
    };

    //checkbox custom view

    handleChangeAutocompleteFormatCategory = selectedOptionFormatCategory => {
        const array = [];

        selectedOptionFormatCategory.format_sub_categories.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))


        this.setState({
            selectedOptionFormatCategory,
            formCreateFormat: {
                ...this.state.formCreateFormat,
                format_category_id: selectedOptionFormatCategory.value
            },

            format_sub_categories: array,
        });
    }

    setIconForm = (icon_id) => {
        this.setState({
            form: {
                ...this.state.form,
                icon_id: icon_id,
            },
        })
    }

    createColumn = (format_category, status) => {
        this.setState({ isLoaded: true })
        fetch(`/formatos/get_format_category_show/false/${format_category.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                const format_category_find = data.register;
                this.setState({ isLoaded: false })
                if (status) {
                    /*             this.setState({
                                    current_format_category: {},
                                    data: this.state.data 
                                }) */
                } else {

                    if (this.state.current_format_category.id != format_category_find.id && Object.keys(this.state.current_format_category).length != 0) {
                        // el current_format_category es diferente que el actual item en el que le doy click
                        console.log("el current_format_category es diferente que el actual item en el que le doy click");
                        let array = [];
                        this.state.data.filter(value => value.level < format_category_find.level).map(value => {
                            array.push(value);
                        })
                        this.setState({
                            data: array,
                        }, () => {
                            this.setState({
                                current_format_category: format_category_find,
                                data: this.state.current_format_category.id != format_category_find.id ? [...this.state.data, format_category_find] : array,
                            })
                        })
                    } else {
                        console.log("añadiendo nuevo item");
                        this.setState({
                            current_format_category: format_category_find,
                            data: [...this.state.data, format_category_find],
                        })
                    }
                }
            });

    }

    createColumnCategory = (format_category, status) => {
        this.setState({ isLoaded: true })
        fetch(`/formatos/get_format_category_show/false/${format_category.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                const format_category_find = data.register;

                if (status) {
                    this.setState({
                        select_category_list_id: format_category_find.id,
                        current_category: format_category_find,
                        data: [],
                        isLoaded: false,
                    })
                } else {

                    if (this.state.current_category.id != format_category_find.id && Object.keys(this.state.current_category).length != 0) {
                        // el current_category es diferente que el actual

                        this.setState({
                            current_category: format_category_find,
                            select_category_list_id: format_category_find.id,
                            data: [format_category_find],
                            isLoaded: false
                        })
                    } else {
                        this.setState({
                            current_category: format_category_find,
                            select_category_list_id: format_category_find.id,
                            data: [...this.state.data, format_category_find],
                            isLoaded: false
                        })
                    }
                }
            });
    }

    createModulo = (format_category) => {
        this.setState({
            modalCategories: true,
            current_format_category: format_category,

            form: {
                ...this.state.form,
                format_category_id: format_category.id,
                is_parent: true,
                show_index: false,
            }
        })
    }

    //format_custom_views

    toogleCustomView = (from, format_category) => {
        if (from == "new") {
            this.setState({
                modalCustomView: true,
                current_format_category: format_category,
                formCreateFormatCustomView: {
                    ...this.state.formCreateFormatCustomView,
                    format_category_id: format_category.id
                }
            })
        } else {
            this.setState({ modalCustomView: false })
            this.clearValuesCustomView();
        }
    }

    updateDataColumns = (register, from) => {
        this.setState({
            data: this.state.data.map(item => {
                if (this.state.current_format_category.id === item.id) {
                    return {
                        ...item,
                        categories: (from == "categories" ? [...item.categories, register] : item.categories),
                        format_custom_views: (from == "format_custom_views" ? [...item.format_custom_views, register] : item.format_custom_views),
                        formatos: (from == "formatos" ? [...item.formatos, register] : item.formatos),
                    }
                }
                return item;
            })
        });
    }

    clearValuesCustomView = () => {
        this.setState({
            formCreateFormatCustomView: {
                format_id: "",
                name: "",
                format_category_id: "",
                icon_id: "",
                header: [],
            }
        })
    }


    HandleClickCustomView = () => {
        const array = [];

        this.state.data.map((item) => {
            array.push(item.id)
        })

        const formCreateFormatCustomView = {
            format_id: this.state.formCreateFormatCustomView.format_id,
            name: this.state.formCreateFormatCustomView.name,
            format_category_id: this.state.formCreateFormatCustomView.format_category_id,
            icon_id: this.state.formCreateFormatCustomView.icon_id,
            custom_view_permission_ids: this.state.formCreateFormatCustomView.custom_view_permission_ids,
            header: array,
        }

        if (!this.state.modeEditFormatCustomView)
            fetch(`/formatos/format_custom_views`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(formCreateFormatCustomView), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValuesCustomView();
                    this.setState({ modalCustomView: false })
                    this.messageSuccess(data);
                    this.updateDataColumns(data.register, "format_custom_views");
                    this.props.updateDataColumns(this.state.current_format_category, data.register, "format_custom_views")
                });
        else {
            fetch(`/formatos/format_custom_views/${this.state.format_custom_view_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(formCreateFormatCustomView), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modalCustomView: false })
                    this.messageSuccess(data);
                    this.updateDataItem(data.register, "format_custom_views");
                    this.clearValuesCustomView();
                });
        }
    }

    HandleChangeCustomView = (e) => {
        this.setState({
            formCreateFormatCustomView: {
                ...this.state.formCreateFormatCustomView,
                [e.target.name]: e.target.value
            }
        })
    }

    setIconFormatCustomView = (icon_id) => {
        this.setState({
            formCreateFormatCustomView: {
                ...this.state.formCreateFormatCustomView,
                icon_id: icon_id,
            },
        })
    }


    handleChangeAutocompleteFormat = selectedOptionFormat => {
        this.setState({
            selectedOptionFormat,
            formCreateFormatCustomView: {
                ...this.state.formCreateFormatCustomView,
                format_id: selectedOptionFormat.value
            }
        });
    }

    handleChangeAutocompleteFormatCustomView = selectedOptionFormatCustomView => {
        this.setState({
            selectedOptionFormatCustomView,
            formCreateFormatCustomView: {
                ...this.state.formCreateFormatCustomView,
                format_category_id: selectedOptionFormatCustomView.value
            }
        });
    }

    handleChangeCheckBox = (e, format_category) => {
        const isChecked = e.target.checked;

        if (e.target.name == "all") {
            this.setState({
                data: this.state.data.map(item => {
                    if (format_category.id === item.id) {
                        return {
                            ...item,
                            show_all: isChecked,
                            show_categories: false,
                            show_formats: false,
                            show_custom_views: false,
                        }
                    }
                    return item;
                })
            });
        } else if (e.target.name == "modules") {
            this.setState({
                data: this.state.data.map(item => {
                    if (format_category.id === item.id) {
                        return {
                            ...item,
                            show_all: false,
                            show_categories: isChecked,
                            show_formats: false,
                            show_custom_views: false,
                        }
                    }
                    return item;
                })
            });

        } else if (e.target.name == "formats") {
            this.setState({
                data: this.state.data.map(item => {
                    if (format_category.id === item.id) {
                        return {
                            ...item,
                            show_all: false,
                            show_categories: false,
                            show_formats: isChecked,
                            show_custom_views: false,
                        }
                    }
                    return item;
                })
            });

        } else if (e.target.name == "views") {
            this.setState({
                data: this.state.data.map(item => {
                    if (format_category.id === item.id) {
                        return {
                            ...item,
                            show_all: false,
                            show_categories: false,
                            show_formats: false,
                            show_custom_views: isChecked,
                        }
                    }
                    return item;
                })
            });
        }
    }

    updateDataItem = (register, from) => {
        if (from == "categories") {
            this.setState({
                data: this.state.data.map(item => {
                    if (register.format_category_id === item.id) {
                        return {
                            ...item,
                            format_sub_categories: item.format_sub_categories,
                            level: item.level,
                            name: item.name,
                            show_all: item.show_all,
                            show_categories: item.show_categories,
                            show_custom_views: item.show_custom_views,
                            show_formats: item.show_formats,

                            format_category: item.format_category,
                            format_custom_views: item.format_custom_views,
                            formatos: item.formatos,

                            categories: item.categories.map(category => {
                                if (register.id === category.id) {
                                    return {
                                        ...category,
                                        categories: register.categories,
                                        format_category: register.format_category,
                                        format_category_id: register.format_category_id,
                                        format_custom_views: register.format_custom_views,
                                        name: register.name,

                                        format_sub_categories: register.format_sub_categories,
                                        formatos: register.formatos,
                                        icon_id: register.icon_id,
                                        is_parent: register.is_parent,
                                        level: register.level,
                                        positions: register.positions,
                                        show_all: register.show_all,
                                        show_categories: register.show_categories,
                                        show_custom_views: register.show_custom_views,
                                        show_formats: register.show_formats,
                                    }
                                }
                                return category;
                            })
                        }
                    }
                    return item;
                })
            });
        } else if (from == "formatos") {
            this.setState({
                data: this.state.data.map(item => {
                    if (register.format_category_id === item.id) {
                        return {
                            ...item,
                            categories: item.categories,
                            format_sub_categories: item.format_sub_categories,
                            level: item.level,
                            name: item.name,
                            show_all: item.show_all,
                            show_categories: item.show_categories,
                            show_custom_views: item.show_custom_views,
                            show_formats: item.show_formats,

                            format_category: item.format_category,
                            format_custom_views: item.format_custom_views,
                            formatos: item.formatos.map(formato => {
                                if (register.id === formato.id) {
                                    return {
                                        ...formato,
                                        editar: register.editar,
                                        eliminar: register.eliminar,
                                        format_associations: register.format_associations,
                                        format_category_id: register.format_category_id,
                                        format_sub_category: register.format_sub_category,
                                        format_sub_category_id: register.format_sub_category_id,
                                        formats_permissions: register.formats_permissions,
                                        gestionar: register.gestionar,
                                        name: register.name,
                                        questions_count: register.questions_count,
                                    }
                                }
                                return formato;
                            }),
                        }
                    }
                    return item;
                })
            });
        } else if (from == "format_custom_views") {
            this.setState({
                data: this.state.data.map(item => {
                    if (register.format_category_id === item.id) {
                        return {
                            ...item,
                            categories: item.categories,
                            format_sub_categories: item.format_sub_categories,
                            level: item.level,
                            name: item.name,
                            show_all: item.show_all,
                            show_categories: item.show_categories,
                            show_custom_views: item.show_custom_views,
                            show_formats: item.show_formats,

                            format_category: item.format_category,
                            formatos: item.formatos,
                            format_custom_views: item.format_custom_views.map(format_custom_view => {
                                if (register.id === format_custom_view.id) {
                                    return {
                                        ...format_custom_view,
                                        format: register.format,
                                        icon: register.icon,
                                        name: register.name,
                                        custom_view_permissions: register.custom_view_permissions,
                                    }
                                }
                                return format_custom_view;
                            }),
                        }
                    }
                    return item;
                })
            });
        }
    }

    editFormatCustomView = (format_custom_view, format_category) => {
        const arrayPermissions = format_custom_view.custom_view_permissions;
        const arrayNewPermissions = [];
        const array = [];
        let map = new Map();

        arrayPermissions.map((permission, index) => (
            arrayNewPermissions.push(permission.id)
        ));

        this.props.custom_view_permissions.forEach(element => {
            arrayPermissions.forEach(element_accion => {
                if (element.id == element_accion.id) {
                    map.set(element_accion.id.toString(), true)
                }
            });
        })

        this.setState({
            checkedItemsCustomView: map,
            format_custom_view_id: format_custom_view.id,
            current_format_category: format_category,
            modeEditFormatCustomView: true,
            modalCustomView: true,

            formCreateFormatCustomView: {
                ...this.state.formCreateFormatCustomView,
                format_id: format_custom_view.format.id,
                name: format_custom_view.name,
                format_category_id: format_custom_view.format_category_id,
                custom_view_permission_ids: arrayNewPermissions,
                icon_id: format_custom_view.icon_id,
            },

            selectedOptionFormatCustomView: {
                format_id: format_custom_view.format_category.id,
                label: format_custom_view.format_category.name,
            },

            selectedOptionFormat: {
                format_id: format_custom_view.format.id,
                label: format_custom_view.format.name,
            }
        })
    }

    render() {
        const styleIcons = {
            cursor: "pointer",
            color: "#3f69d8",
        }

        const styleHeight = {
            height: 'calc(100vh - 290px)'
        }

        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEditFormat ? "Actualizar formato" : "Añadir formato"}
                        nameBnt={this.state.modeEditFormat ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.formCreateFormat}
                        submitForm={this.HandleClickFormat}
                        onChangeForm={this.HandleChange}
                        category={this.state.category}
                        modeEdit={this.state.modeEditFormat}

                        //ckeckcontainer

                        checkedItems={this.state.checkedItems}
                        handleChangeAccions={this.handleChangeAccions}
                        checkboxes={this.state.checkboxes}
                        modules={this.props.permissions}
                        handleChangeSelectAll={this.handleChangeSelectAll}
                        modulo_ids={this.state.modulo_ids}

                        //select format category
                        selectedOptionFormatCategory={this.state.selectedOptionFormatCategory}
                        handleChangeAutocompleteFormatCategory={this.handleChangeAutocompleteFormatCategory}
                        categories={this.state.categories}

                        //select sub categorias
                        handleChangeAutocompleteSubCategory={this.handleChangeAutocompleteSubCategory}
                        selectedOptionSubCategory={this.state.selectedOptionSubCategory}
                        format_sub_categories={this.state.format_sub_categories}
                        position={this.props.position}
                    />
                )}

                {this.state.modalCategories && (
                    <FormCreateCategories
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCategories}
                        toggle={this.toogleCategories}
                        title={this.state.modeEdit ? "Actualizar categoría" : "Crear Módulo"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChangeCategory}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}

                        // select 

                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        editValuesProcesos={this.state.dataProcesosEdit}
                        selectedOption={this.state.selectedOption}

                        icons={this.props.icons}
                        modeEdit={this.state.modeEdit}
                        setIconForm={this.setIconForm}
                        cargos={this.state.cargos}
                    />
                )}

                {this.state.modalSubCategories && (
                    <FormCreateSubCategories
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalSubCategories}
                        toggle={this.subCategories}
                        title={this.state.modeEditSubCategories ? "Actualizar Sub Módulo" : "Crear Sub Módulo"}

                        //form props
                        formValues={this.state.formCreateSubCategories}
                        submitForm={this.HandleClickSubCategory}
                        onChangeForm={this.HandleChangeSubCategory}
                        nameSubmit={this.state.modeEditSubCategories ? "Actualizar" : "Crear"}

                        // select 

                        handleChangeAutocomplete={this.handleChangeAutocompleteSubCategoryMulti}
                        editValuesProcesos={this.state.dataProcesosEdit}
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}
                    />
                )}

                {this.state.modalCategoriesShow && (
                    <ModalShowCategory
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCategoriesShow}
                        toggle={this.toogleCategoriesShow}
                        title={"información de la categoría"}
                        format_category={this.state.format_category}
                    />
                )}

                {this.state.modalCustomView && (
                    <FormCreateCustomView
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCustomView}
                        toggle={this.toogleCustomView}
                        title={this.state.modeEditFormatCustomView ? "Actualizar vista personalizada" : "Crear vista personalizada"}

                        //form props
                        formValues={this.state.formCreateFormatCustomView}
                        submitForm={this.HandleClickCustomView}
                        onChangeForm={this.HandleChangeCustomView}
                        nameSubmit={this.state.modeEditFormatCustomView ? "Actualizar" : "Crear"}

                        // select 

                        handleChangeAutocompleteFormat={this.handleChangeAutocompleteFormat}
                        formatos={this.state.formatos}
                        selectedOption={this.state.selectedOptionFormat}

                        icons={this.props.icons}
                        modeEdit={this.state.modeEditFormatCustomView}
                        setIconForm={this.setIconFormatCustomView}

                        //select format category
                        selectedOptionFormatCategory={this.state.selectedOptionFormatCustomView}
                        handleChangeAutocompleteFormatCategory={this.handleChangeAutocompleteFormatCustomView}
                        categories={this.state.categories}

                        //ckeckcontainer

                        checkedItems={this.state.checkedItemsCustomView}
                        handleChangeAccions={this.handleChangeAccionsCustomView}
                        checkboxes={this.state.checkboxesCustomView}
                        section_custom_views={this.props.section_custom_views}
                        handleChangeSelectAll={this.handleChangeSelectAllCustomView}
                        modulo_ids={this.state.modulo_custom_view_ids}
                        position={this.props.position}
                    />
                )}



                {/*<nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item" > MODULOS</li>
                        {this.state.data.length >= 1 && (
                            <React.Fragment>
                                {this.state.data.map((format_category, index) => (
                                    <li className="breadcrumb-item" key={format_category.id}>
                                        <a
                                            href={`/failure/causes/${1}`}
                                            data-turbolinks="true"
                                        >
                                            {format_category.name}
                                        </a>
                                    </li>
                                ))}
                            </React.Fragment>
                        )}
                    </ol>
                </nav>*/}


                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="card card-height ui-formatCategoriesIndex-cardHeight">
                                    <div className="card-body p-2" style={{ background: "#ffffff" }} id="containerElement">
                                        <div className="scroll-area-lg" style={styleHeight}>
                                            <div className="scrollbar-container">
                                                <ul className="list-group">
                                                    {this.props.data.map((format_category, index) => (
                                                        <li className={`list-group-item justify-content-between align-items-center ${this.state.data.filter((e) => e.id == format_category.id).length > 0 ? "list-group-item-primary" : "item-background"} mb-2 ui-card-list-formatCategoriesIndex`} key={format_category.id} onClick={() => this.state.isLoaded ? console.log("cargando..") : this.createColumnCategory(format_category, this.state.data.filter((e) => e.id == format_category.id).length >= 1)}>
                                                            <div className="ui-card-iconText-formatCategoriesIndex">
                                                                <i className={`${this.state.data.filter((e) => e.id == format_category.id).length > 0 ? "fas fa-folder-open" : "fas fa-folder"} mr-3 ui-card-icon-folder-formatCategoriesIndex`} style={{ fontSize: "17px" }}></i><div className="ui-text-formarCategoriesIndex">{format_category.name}</div>
                                                            </div>

                                                            <div className="ui-icons-formatCategoriesIndex">
                                                                <input
                                                                    type="checkbox"
                                                                    value={format_category.id}
                                                                    checked={this.props.checkedItemFormData.get(format_category.id.toString())}
                                                                    onChange={(e) => this.props.handleChangeAccionFormat(e, true)}
                                                                />
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*<div className="content-categories"> */}
                            {/* <div className="row"> */}

                            <div className="ui-scroll-horizontal-modulos pb-1">
                                {this.state.data.map((format_category, index) => (

                                    <div className="col-md-4" key={format_category.id}>
                                        <div className="card card-height ui-formatCategoriesIndex-cardHeight">
                                            <div className="card-body pb-0 p-2 " style={{ background: "#ffffff" }} id={`containerElement${format_category.id}`}>
                                                <div className="scroll-area-lg scroll-format-categories" style={styleHeight}>
                                                    <div className="scrollbar-container">
                                                        {(format_category.categories.length >= 1 && format_category.show_all || format_category.show_categories) && (
                                                            <React.Fragment>
                                                                <ul className="list-group">
                                                                    {format_category.categories.map((category, index) => (
                                                                        <li className={`list-group-item justify-content-between align-items-center mb-1 ${this.state.data.filter((e) => e.id == category.id).length > 0 ? "list-group-item-primary" : "item-background"} ui-card-list-formatCategoriesIndex`} key={category.id} onClick={() => this.state.isLoaded ? console.log("cargando..") : this.createColumn(category, this.state.data.filter((e) => e.id == category.id).length >= 1)}>
                                                                            {/* onClick={() => this.createColumn(category, this.state.data.filter((e) => e.id == category.id).length >= 1)}  */}
                                                                            <div className="ui-card-iconText-formatCategoriesIndex">
                                                                                <i className={`${this.state.data.filter((e) => e.id == category.id).length > 0 ? "fas fa-folder-open" : "fas fa-folder"} mr-3 ui-card-icon-folder-formatCategoriesIndex`} style={{ fontSize: "17px" }}></i><div className="ui-text-formarCategoriesIndex">{category.name}</div>
                                                                            </div>

                                                                            <div className="ui-icons-formatCategoriesIndex">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name=""
                                                                                    value={category.id}
                                                                                    checked={this.props.checkedItemFormData.get(category.id.toString())}
                                                                                    onChange={(e) => this.props.handleChangeAccionFormat(e, false)}
                                                                                />
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </React.Fragment>
                                                        )}

                                                        {(format_category.formatos.length >= 1 && format_category.show_all || format_category.show_formats) && (
                                                            <React.Fragment>
                                                                <ul className="list-group">
                                                                    {format_category.formatos.map((formato, index) => (
                                                                        <li className={`list-group-item justify-content-between align-items-center item-background mb-1 ${index == 0 && !format_category.show_formats && format_category.categories.length >= 1 ? "mt-3" : ""} ui-card-form-formatCategoriesIndex`} key={formato.id} style={{ background: "#d3e3ce" }}>
                                                                            <div className="container-form-formatCategoriesIndex">
                                                                                <i className={`fas fa-file-alt mr-3`} style={{ fontSize: "17px" }}></i>
                                                                                <a
                                                                                    className="mr-2 ui-text-format-form-categoriesIndex"
                                                                                    data-turbolinks="true"
                                                                                >
                                                                                    {formato.name}
                                                                                </a>
                                                                            </div>

                                                                            <div className="widget-content-right widget-content-actions ui-icons-form-formatCategoriesIndex">

                                                                                {this.props.estados.editFormat && (
                                                                                    <i className="fas fa-pencil-alt ui-icon-edit-form-formatCategoriesIndex" onClick={() => this.editForm(formato, format_category)}></i>
                                                                                )}

                                                                                {/*<div className="d-inline-block dropdown">
                                                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" className="border-0 btn-transition btn btn-link">
                                                                                    <i className="fa fa-ellipsis-h"></i>
                                                                                </button>
                                                                                <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                                                                                    <button 
                                                                                        type="button" 
                                                                                        tabindex="0" 
                                                                                        className="dropdown-item"
                                                                                        onClick={() => console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")}
                                                                                    >
                                                                                        Editar
                                                                                    </button>
                                                                                    <button type="button" tabindex="0" className="dropdown-item">Eliminar</button>
                                                                                </div>
                                                                            </div>*/}
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </React.Fragment>
                                                        )}

                                                        {(format_category.format_custom_views.length >= 1 && format_category.show_all || format_category.show_custom_views) && (
                                                            <React.Fragment>
                                                                <ul className="list-group">
                                                                    {format_category.format_custom_views.map((format_custom_view, index) => (
                                                                        <li className={`list-group-item justify-content-between align-items-center item-background mb-1 ${index == 0 && !format_category.show_custom_views && format_category.categories.length >= 1 ? "mt-3" : ""} ui-card-view-formatCategoriesIndex`} key={format_custom_view.id}>
                                                                            <div className="container-view-formatCategoriesIndex">
                                                                                <i className={`${format_custom_view.icon ? format_custom_view.icon.html_name : "far fa-list-alt"} mr-3`} style={{ fontSize: "17px" }}></i>
                                                                                <a
                                                                                    className="mr-2 ui-text-view-formatCategoriesIndex"
                                                                                    data-turbolinks="true"
                                                                                    target="_blank"
                                                                                >
                                                                                    {format_custom_view.name}
                                                                                </a>
                                                                            </div>

                                                                            <div className="widget-content-right widget-content-actions ui-icons-views-formatCategoriesIndex">
                                                                                {false && (
                                                                                    <i className="far fa-trash-alt ui-views-delete-formatCategoriesIndex" onClick={() => this.deleteCustomView(format_category.id, format_custom_view)}></i>
                                                                                )}

                                                                                {true && (
                                                                                    <i className="fas fa-pencil-alt ui-views-edit-formatCategoriesIndex" onClick={() => this.editFormatCustomView(format_custom_view, format_category)}></i>
                                                                                )}
                                                                                {/*<div className="d-inline-block dropdown">
                                                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" className="border-0 btn-transition btn btn-link">
                                                                                    <i className="fa fa-ellipsis-h"></i>
                                                                                </button>
                                                                                <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                                                                                    <button 
                                                                                        type="button" 
                                                                                        tabindex="0" 
                                                                                        className="dropdown-item"
                                                                                        onClick={() => console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")}
                                                                                    >
                                                                                        Editar
                                                                                    </button>
                                                                                    <button type="button" tabindex="0" className="dropdown-item">Eliminar</button>
                                                                                </div>
                                                                            </div>*/}
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <div className="card">
                        <div className="card-body">
                            <Preloader />
                        </div>
                    </div>
                )}

            </React.Fragment>
        );
    }
}

export default FormatPermision;
