import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreateShow extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row" >

                                    <div className="col-md-12">
                                        <label>Fecha <small className="validate-label">(opcional)</small></label>
                                        <input
                                            type="date"
                                            name="date_score"
                                            value={this.props.formValues.date_score}
                                            onChange={this.props.onChangeForm}
                                            className="form form-control"
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3 mb-2">
                                        <input
                                            type="hidden"
                                            name="responsible_user_id"
                                            value={this.props.formAutocomplete.responsible_user_id}
                                        />
                                        <label>Responsable <small className="validate-label">(opcional)</small></label>
                                        <Select
                                            onChange={this.props.onChangeAutocomplete}
                                            options={this.props.users}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.formAutocomplete}
                                        />
                                    </div>

                                    {this.props.showAddUsers && (
                                        <div className="col-md-12 mt-3">
                                            <input
                                                type="hidden"
                                                name="user_ids"
                                                value={this.props.procesoIds}
                                            />
                                            <label>Gestores</label>
                                            <Select
                                                onChange={this.props.handleChangeAutocompleteUsers}
                                                isMulti
                                                defaultValue={this.props.editValues}
                                                closeMenuOnSelect={false}
                                                name="user_ids"
                                                options={this.props.users}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Seleccione"
                                            />
                                        </div>
                                    )}

                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                    {this.props.isLoaded && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-success" role="alert">
                                                <b>¡Estamos generando tu evaluación!</b>
                                            </div>
                                        </div>
                                    )}



                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button 
                                    className="btn-shadow btn btn-info" 
                                    onClick={this.props.submitForm}
                                    disabled={this.props.isLoaded}
                                >
                                    {this.props.nameSubmit}
                                </button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreateShow;