import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from '../../formatos/Formats/FormCreate'
import Pagination from "react-js-pagination";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import IndexMobile from './Mobile/index';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            submitBtnFile: false,
            isLoadedFiles: false,
            isLoaded: false,
            id: "",

            form: {
                name: "",
                description: "",
            },

            formUpdate: {
                name: "",
            },
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                description: "",
            },

            formUpdate: {
                name: "",
            },

            ErrorValues: true,
        })
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    HandleClick = () => {
        if (this.validationForm() == true) {
            this.setState({ isLoaded: true })
            fetch("/dofa/company_objetives", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if (data.type != "error") {
                        this.props.updateData(data.register);
                        this.clearValues();
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                    } else {
                        this.clearValues();
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                    }
                });
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/dofa/company_objetives/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        if (response.type != "error") {
                            this.props.loadData()
                            this.messageSuccess(response)
                        } else {
                            this.messageSuccess(response);
                        }
                    });
            }
        });
    };

    //Update section

    edit = (rol) => {
        this.setState({
            id: rol.id,
            formUpdate: {
                name: rol.name,
                module: rol.module
            }
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                [e.target.name]: e.target.value
            }
        });
    }

    HandleClickUpdate = () => {
        this.setState({ isLoaded: true })
        fetch(`/dofa/company_objetives/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                if (data.type != "error") {
                    this.props.updateItem(data.register)
                    this.clearValues();
                    this.setState({ id: "", isLoaded: false })
                    this.messageSuccess(data);
                } else {
                    this.clearValues();
                    this.setState({ id: "", isLoaded: false })
                    this.messageSuccess(data);
                }
            });
    }

    HandleClickCancel = (event) => {
        event.preventDefault();
        this.setState({ id: "" });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Objetivos"}
                    subTitle={"Gestión de Objetivos"}
                    titleNew={"Nuevo Objetivo"}
                    titleFilter={"Filtros"}
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Agregar Objetivo"}

                        //form props
                        formValues={this.state.form}
                        errorValues={this.state.ErrorValues}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        isLoaded={this.state.isLoaded}
                        nameBnt={this.state.id ? "Actualizar" : "Crear"}
                    />
                )}

                    <React.Fragment>
                        <React.Fragment>
                            {!isMobile ? (
                                <div className="content main-card -mb-3 card card-style">
                                    <div className="card-body ui-tables_layout-register-card-body">
                                        <div className='ui-tables_layout-table__container'>
                                            <table className="table ui-tables_layout-table-header__container" id="sampleTable">
                                                {this.props.data.length >= 1 ? (
                                                    <React.Fragment>
                                                        <thead className='ui-tables_layout-table-header'>
                                                            <tr className='ui-tables_layout-__container-title-table'>
                                                                <th style={{width: '40px'}}>No.</th>
                                                                <th>Objetivo</th>
                                                                {(this.props.estados.editar || this.props.estados.eliminar) &&(
                                                                    <th className="text-center"  style={{ width: "8%" }}>Opciones</th>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody className='ui-tables_layout-data__container'>
                                                            {this.props.data.map((procesos, index) => (
                                                                <tr key={procesos.id}>
                                                                    <td className='text-center'>{index + 1}</td>
                                                                    <td>
                                                                        {procesos.id == this.state.id ? (
                                                                            <input
                                                                                type="text"
                                                                                name="name"
                                                                                value={this.state.formUpdate.name}
                                                                                onChange={this.HandleChangeUpdate}
                                                                                className="form form-control"
                                                                            />
                                                                        ) : (
                                                                            <span>{procesos.name}</span>
                                                                        )}
                                                                    </td>
                                                                    {(this.props.estados.editar || this.props.estados.eliminar) &&(
                                                                        <td className="text-center" style={{ width: "10px" }}>
                                                                            {this.state.id != "" ? (
                                                                                <React.Fragment>
                                                                                    {procesos.id == this.state.id &&(
                                                                                        <React.Fragment>
                                                                                            <i className="fas fa-check-circle update-section" onClick={() => this.HandleClickUpdate()}></i> 
                                                                                            <i className="fas fa-times-circle update-false" onClick={(event) => this.HandleClickCancel(event)}></i>
                                                                                        </React.Fragment>
                                                                                    )} 
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <UncontrolledDropdown className="btn-group ui-tableIndexWeb-positions-dropdown__container">
                                                                                    <DropdownToggle>
                                                                                        <i className="fas fa-bars"></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu className="ui-tableIndexWeb-positions-dropdown_header dropdown-menu-right">
                                                                                        {this.props.estados.editar && (
                                                                                            <DropdownItem
                                                                                                onClick={() => this.edit(procesos)}
                                                                                                className="dropdown-item"
                                                                                            >
                                                                                                Editar
                                                                                            </DropdownItem>
                                                                                        )}
                                                                                        {this.props.estados.eliminar && (  
                                                                                            <DropdownItem
                                                                                                onClick={() => this.delete(procesos.id)}
                                                                                                className="dropdown-item"
                                                                                            >
                                                                                                Eliminar
                                                                                            </DropdownItem>
                                                                                        )}
                                                                                    </DropdownMenu>                                                                            
                                                                                </UncontrolledDropdown>
                                                                            )}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </React.Fragment>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="8" className="text-center">
                                                                <div className='card col-md-12'>
                                                                    <div className="text-center">
                                                                        <div className="text-center mt-4 mb-4" style={{width: '100%'}}>
                                                                            <p className='ui-companyObjetive-messageNoRecord'>Todavía no hay registros creados, da clic en <a onClick={() => this.toogle('new')} className=" btn btn-info">Nuevo Objetivo</a> y genera el primer registro.</p>
                                                                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/8/noRecord_strategy.jpg" alt="" className="ui-companyObjetive-imgNoRecord" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <IndexMobile
                                    data={this.props.data}
                                    edit={this.edit}
                                    delete={this.delete}
                                    formUpdateValue={this.state.formUpdate.name}
                                    HandleClickUpdate={this.HandleClickUpdate}
                                    HandleChangeUpdate={this.HandleChangeUpdate}
                                    stateId={this.state.id}
                                    HandleClickCancel={this.HandleClickCancel}
                                    estados={this.props.estados}
                                />
                            )}
                        </React.Fragment>
                        {true && (
                            <div className="row mt-3">
                                <div className="col-md-2 text-left">
                                    <p>
                                        Mostrando {this.props.data.length} de {this.props.totalData}
                                    </p>
                                </div>
                                <div className="col-md-10 pl-0">
                                    <Pagination
                                        hideNavigation
                                        activePage={this.props.activePage}
                                        itemsCountPerPage={this.props.countPage}
                                        itemClass="page-item"
                                        innerClass="pagination"
                                        linkClass="page-link"
                                        totalItemsCount={this.props.totalData}
                                        pageRangeDisplayed={this.props.countPage}
                                        onChange={this.props.handlePageChange}
                                    />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
            </React.Fragment>
        );
    }
}

export default Index;
