import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './FormCreate';
import FormCreateCategories from './FormCreateCategories';
import FormFilter from './FormFilter';
import FormCreateSubCategories from './SubModules/FormCreate';
import ModalShowCategory from '../FormatCategories/ModalShowCategory'
import Preloader from '../../../GeneralComponents/LoadingComponen'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Scroll from 'react-scroll';
import PreloaderFormatCategory from '../../../GeneralComponents/Preloaders';

import FormCreateCustomView from './FormatCustomView/FormCreate'
import UploadFile from '../../documentManagement/Document/UploadFile';


var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.scrollToTop = this.scrollToTop.bind(this);
        this.state = {
            id: "",
            modal: false,
            modalCategories: false,
            modalSubCategories: false,
            isLoadShowCategory: false,
            isSearchVisible: false,
            isLoadedFilter: false,
            isLoadCreateFormat: false,
            isLoadedXml: false,
            isLoadedChangePosition: false,

            //last values
            last_category_id: 0,
            up: 1,
            last_format_category_id: 0,
            last_show_index: false,

            modalCategoriesShow: false,
            modalUploadXml: false,
            modeEdit: false,
            modeEditSubCategories: false,
            modeEditFormat: false,
            modeEditFormatCustomView: false,
            showInputs: true,
            remove_category: false,
            format_custom_view_id: "",

            ErrorValuesFormat: true,
            ErrorValuesFormatCustomView: true,
            ErrorValuesCategories: true,

            title: "",
            formato_id: "",
            format_category: {},
            category: {},
            format_sub_category_id: "",
            modulo_ids: [],
            created_format: false,
            current_format_category: this.props.current_category,

            current_category: this.props.current_category,
            select_category_list_id: this.props.select_category_list_id,

            //format_custom_view
            modalCustomView: false,

            formCreateFormatCustomView: {
                format_id: "",
                name: "",
                format_category_id: "",
                icon_id: "",
                type_view: "",
                custom_view_permission_ids: [],
            },

            //custom view checkList
            checkedItemsCustomView: new Map(),
            checkboxesCustomView: [],
            modulo_custom_view_ids: [],
            modulos_custom_view: [],


            selectedOptionFormat: {
                format_id: "",
                label: "Formatos"
            },

            selectedOption: {
                position_create_ids: [],
                label: "Cargos"
            },

            selectedOptionFordata: {
                position_fordata_ids: [],
                label: "Cargos"
            },

            selectedOptionPosition: {
                position_ids: [],
                label: "Cargos a los que le aparece esta categoria"
            },

            selectedOptionFormatCategory: {
                format_category_id: "",
                label: "Categoría"
            },


            selectedOptionFormatCustomView: {
                format_category_id: "",
                label: "Categoría"
            },


            selectedOptionSubCategory: {
                format_sub_category_id: "",
                label: "Sub Módulo"
            },

            selectedOptionChangeCategory: {
                format_category_id: "",
                label: "Categoría"
            },

            saveFormatCategory: {
                name: "",
                id: ""
            },

            form: {
                name: "",
                icon_id: "",
                format_category_id: "",
                is_parent: false,
                show_index: true,
                position: null,
                position_ids: [this.props.current_position[0].value],
                position_create_ids: [this.props.current_position[0].value],
                position_fordata_ids: [this.props.current_position[0].value],
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: "",
                format_sub_category_id: "",
            },

            formCreateSubCategories: {
                name: "",
                format_category_id: "",
                position_ids: [],
            },

            formUploadXml: {
                format_category_id: null,
                file: {}
            },

            nameFile: "",
            sizeFile: 0,

            checkedItems: new Map(),
            oldcheckedItems: new Map(),

            checkboxes: [],
            dataProcesosEdit: this.props.current_position,
            dataPositionEdit: this.props.current_position,
            editValuesPositionFordata: this.props.current_position,

            cargos: [],
            sub_categories: [],
            modulos: [],

            categories: [],
            format_sub_categories: [],
            data: this.props.default_data,
            dataList: [],
            formatos: [],
            format_category_load_parent_id: null,
            format_category_load_son_id: null
        }

        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    handleSelectChange(event) {
        const value = event.target.value;
        this.setState({ selectSearchValue: value });
        if (value != '') {
            this.setState({ isSearchVisible: true });
        } else {
            this.setState({ isSearchVisible: false });
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };


    clearFormatCategoryId = () => {
        this.setState({
            format_category_load_parent_id: null,
            format_category_load_son_id: null
        })
    }

    messageDeleteFormatCategory = (response) => {

        Swal.fire({
            title: '<strong>Lista de errores</strong>',
            icon: 'info',
            footer: `<strong className="text-center">Debes de corregir estos errores, para poder eliminar la categoria</strong>`,
            html: response.html,
            showCloseButton: true,
            //showCancelButton: true,
            //focusConfirm: false,
            //confirmButtonText:'<i className="fa fa-thumbs-up"></i> Great!',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            //cancelButtonText:'<i className="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: 'Thumbs down'
        })
    }

    clearValues = () => {
        this.setState({
            id: "",
            created_format: false,
            modeEdit: false,
            modeEditFormat: false,
            modeEditSubCategories: false,

            ErrorValuesFormat: true,
            ErrorValuesCategories: true,

            modulo_ids: [],
            modulos: [],

            selectedOption: {
                position_create_ids: [],
                label: "Cargos"
            },

            selectedOptionPosition: {
                position_ids: [],
                label: "Cargos a los que le aparece esta categoria"
            },

            selectedOptionFormatCategory: {
                format_category_id: "",
                label: "Categorísa"
            },

            selectedOptionSubCategory: {
                format_sub_category_id: "",
                label: "Sub Módulo"
            },

            form: {
                name: "",
                icon_id: "",
                format_category_id: "",
                is_parent: false,
                show_index: true,
                position: null,
                position_ids: [this.props.current_position[0].value],
                position_create_ids: [this.props.current_position[0].value],
                position_fordata_ids: [this.props.current_position[0].value],
            },

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: "",
                format_sub_category_id: "",
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            checkedItems: new Map(),
            checkboxes: [],
            dataProcesosEdit: this.props.current_position,
            dataPositionEdit: this.props.current_position,
            editValuesPositionFordata: this.props.current_position,
            format_sub_categories: [],
        })
    }

    clearValuesSubCategory = () => {
        this.setState({
            formCreateSubCategories: {
                name: "",
                format_category_id: "",
                position_ids: [],
            },
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    subCategories = (from, category) => {
        if (from == "new") {
            this.setState({
                formCreateSubCategories: {
                    ...this.state.formCreateSubCategories,
                    format_category_id: category.id,
                },

                modalSubCategories: true,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        } else {
            this.clearValuesSubCategory();
            this.setState({
                modalSubCategories: false,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        }
    }

    handleChangeChecked = (e) => {
        const isChecked = e.target.checked;

        this.setState({
            remove_category: isChecked,

            form: {
                ...this.state.form,
                format_category_id: isChecked ? null : this.state.saveFormatCategory.id,
            },

            selectedOptionChangeCategory: {
                format_category_id: isChecked ? "" : this.state.saveFormatCategory.id,
                label: isChecked ? "Categoria" : this.state.saveFormatCategory.name,
            },
        })
    }

    toogleCategories = (from, state) => {
        if (from == "new") {
            this.setState({
                modalCategories: true,
                showInputs: true,
                form: {
                    ...this.state.form,
                    show_index: state,
                    is_parent: state,
                }
            })
        } else {
            this.setState({ modalCategories: false, showInputs: true })
            this.clearValues();
        }
    }

    toogleCategoriesShow = (from, format_category) => {
        if (from == "new") {
            this.setState({ modalCategoriesShow: true, format_category: format_category })
        } else {
            this.setState({ modalCategoriesShow: false, format_category: {} })
        }
    }

    componentDidMount = () => {
        this.defaultValues();
        this.getFormatos();

        Events.scrollEvent.register('begin', function () {
            console.log("begin");
        });

        Events.scrollEvent.register('end', function () {
            console.log("end");
        });

        scrollSpy.update();
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    getFormatos = () => {
        fetch(`/formatos/get_formatos_all`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                let arrayFormatos = [];

                data.data.map((item) => (
                    arrayFormatos.push({ label: item.name, value: item.id })
                ))

                this.setState({
                    formatos: arrayFormatos,
                });
            });
    }

    update_position = (e) => {
        this.setState({ isLoadedChangePosition: true })
        fetch(`/formatos/update_position_format_category/${this.state.id}/${e.target.value}/${this.state.form.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                if (data.is_parent) {
                    this.props.loadData();
                } else {
                    this.updateCategories({ format_category_id: data.data.format_category_id, categories: data.data.register.categories })
                }

                this.setState({ modalCategories: false, isLoadedChangePosition: false })
                this.messageSuccess(data);
                this.clearValues();
            });
    }

    updatePositionState = (position) => {
        this.setState({
            form: {
                ...this.state.form,
                position: position
            }
        })
    }

    updateCategories = (register) => {
        this.setState({
            data: this.state.data.map(item => {
                if (register.format_category_id === item.id) {
                    return {
                        ...item,
                        categories: register.categories,
                    }
                }
                return item;
            })
        });
    }

    defaultValues = () => {
        let array = [];
        let arraySubCategory = [];
        let arrayCategories = [];

        this.props.positions.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.format_sub_categories.map((item) => (
            arraySubCategory.push({ label: item.name, value: item.id })
        ))

        this.props.categories.map((item) => (
            arrayCategories.push({ label: item.name, format_sub_categories: item.format_sub_categories, value: item.id })
        ))

        this.setState({
            cargos: array,
            sub_categories: arraySubCategory,
            categories: arrayCategories,
        })
    }

    handleChangeAutocomplete = selectedOption => {
        let array = []

        if (selectedOption) {
            selectedOption.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            form: {
                ...this.state.form,
                position_create_ids: selectedOption ? array : []
            }
        })
    }

    handleChangeAutocompleteFordata = selectedOption => {
        let array = []

        if (selectedOption) {
            selectedOption.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            form: {
                ...this.state.form,
                position_fordata_ids: selectedOption ? array : []
            }
        })
    }

    handleChangeAutocompletePosition = selectedOptionPosition => {
        let array = []

        if (selectedOptionPosition) {
            selectedOptionPosition.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            form: {
                ...this.state.form,
                position_ids: selectedOptionPosition ? array : []
            }
        })
    }

    handleChangeAutocompleteSubCategoryMulti = selectedOption => {
        let array = []

        if (selectedOption) {
            selectedOption.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                position_ids: selectedOption ? array : []
            }
        })
    }

    handleChangeAutocompleteSubCategory = selectedOptionSubCategory => {
        this.setState({
            selectedOptionSubCategory,
            formCreateFormat: {
                ...this.state.formCreateFormat,
                format_sub_category_id: selectedOptionSubCategory.value
            }
        });
    }

    handleChangeAutocompleteChangeCategory = selectedOptionChangeCategory => {
        this.setState({
            selectedOptionChangeCategory,
            form: {
                ...this.state.form,
                format_category_id: selectedOptionChangeCategory.value,
            }
        });
    }

    validationFormatCategories = () => {
        if (this.state.form.name != "" &&
            (this.state.form.position_fordata_ids.length >= 1 || !this.state.showInputs) &&
            this.state.form.icon_id != (this.state.showInputs ? "" : "*")
        ) {
            this.setState({ ErrorValuesCategories: true })
            return true
        } else {
            this.setState({ ErrorValuesCategories: false })
            return false
        }
    }

    validationFormat = () => {
        if (this.state.formCreateFormat.name != "" &&
            this.state.formCreateFormat.formats_permission_ids.length >= 1
        ) {
            this.setState({ ErrorValuesFormat: true })
            return true
        } else {
            this.setState({ ErrorValuesFormat: false })
            return false
        }
    }

    validationFormatCustomView = () => {
        if (this.state.formCreateFormatCustomView.name != "" &&
            this.state.formCreateFormatCustomView.custom_view_permission_ids.length >= 1 &&
            this.state.formCreateFormatCustomView.format_id != ""
        ) {
            this.setState({ ErrorValuesFormatCustomView: true })
            return true
        } else {
            this.setState({ ErrorValuesFormatCustomView: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.validationFormatCategories() == true) {
            if (!this.state.modeEdit)
                fetch(`/formatos/format_categories`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        if (data.type != "error") {
                            this.clearValues();
                            this.setState({ modalCategories: false })
                            this.messageSuccess(data);
                            if (data.register.level == 0) {
                                this.props.updateData(data.register);
                            }
                            if (Object.keys(this.state.current_format_category).length != 0) {
                                this.updateDataColumns(data.register, "categories");
                                //this.props.updateDataColumns(this.state.select_category_list_id, this.state.current_format_category, data.register, "categories");
                            }
                        } else {
                            this.messageSuccess(data);
                            this.clearValues();
                            this.setState({ modalCategories: false })
                        }
                    });
            else {
                fetch(`/formatos/format_categories/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modalCategories: false, remove_category: false })
                        this.messageSuccess(data);
                        this.clearValues();
                        console.log("datadatadatadatadatadatadatadata", data)
                        if (data.register.level == 0) {
                            this.props.updateItem(data.register);
                        } else {
                            console.log("data.register.level == 0", data.register.level);
                            this.updateDataItem(data.register, "categories")
                        }

                        //entra cuando se quita la categoria a la que pertence y pasa a ser modulo principal 
                        if (this.state.last_format_category_id != null && data.register.format_category_id == null) {
                            this.props.updateData(data.register);
                            this.updateCategory("Categoria", this.state.last_format_category_id, data.register)
                        } else if (this.state.last_format_category_id == null && data.register.format_category_id != null) {
                            this.props.updateDelete(data.register);
                        } else if (this.state.last_format_category_id != data.register.format_category_id) {
                            this.updateCategory("Categoria", this.state.last_format_category_id, data.register)
                        }
                    });
            }
        }
    }

    HandleClickSubCategory = () => {
        if (this.state.modeEditSubCategories == false)
            fetch(`/formatos/format_sub_categories`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateSubCategories), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    Turbolinks.visit(`/formatos/format_categories`);
                    this.clearValuesSubCategory();
                    this.setState({ modalSubCategories: false })
                    this.messageSuccess(data);
                });
        else {
            fetch(`/formatos/format_sub_categories/${this.state.format_sub_category_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreateSubCategories), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modalSubCategories: false })
                    this.messageSuccess(data);
                    this.clearValuesSubCategory();
                    Turbolinks.visit(`/formatos/format_categories`);
                });
        }
    }

    HandleChange = (e) => {
        this.setState({
            formCreateFormat: {
                ...this.state.formCreateFormat,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChangeCategory = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChangeSubCategory = (e) => {
        this.setState({
            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                [e.target.name]: e.target.value
            }
        })
    }

    delete = (e, format_category) => {
        e.stopPropagation();


        Swal.fire({
            title: 'Escribe el nombre de la categoría para poder eliminarla',
            input: 'text',
            footer: `<p>El nombre de la categoría es (${format_category.name}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == format_category.name.trim()) {
                    fetch(`/formatos/delete_format_category/${format_category.id}`, {
                        method: "PATCH",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.type == "success") {
                                this.messageSuccess(response);
                                if (format_category.level == 0) {
                                    this.props.updateDelete(format_category);
                                    this.setState({ data: [] });
                                } else {
                                    this.setState({
                                        data: this.state.data.map(item => {
                                            if (format_category.format_category_id === item.id) {
                                                return {
                                                    ...item,
                                                    categories: item.categories.filter(item => item.id != format_category.id),
                                                    format_custom_views: item.format_custom_views,
                                                    formatos: item.formatos,
                                                }
                                            }
                                            return item;
                                        })
                                    });
                                }
                            } else {
                                this.messageDeleteFormatCategory(response);
                            }
                        });
                } else {

                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };

    //formatos 

    HandleClickFormat = () => {
        if (this.validationFormat() == true) {
            this.setState({ isLoadCreateFormat: true })
            if (!this.state.modeEditFormat) {
                fetch(`/formatos/format_categories/${this.state.formCreateFormat.format_category_id}/formats`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreateFormat), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        if (data.type != "error") {
                            this.clearValues();
                            this.setState({ modal: false, isLoadCreateFormat: false })
                            this.updateDataColumns(data.register, "formatos");
                            //this.props.updateDataColumns(this.state.current_format_category, data.register, "formatos");
                            this.messageSuccess(data);
                            this.getFormatos();
                        } else {
                            this.messageSuccess(data);
                            this.clearValues();
                            this.setState({ modal: false })
                        }
                    });

            } else {

                fetch(`/formatos/format_categories/${this.state.formCreateFormat.format_category_id}/formats/${this.state.formato_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formCreateFormat), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateDataItem(data.register, "formatos");
                        this.clearValues();
                        this.setState({ modal: false, isLoadCreateFormat: false })
                        this.messageSuccess(data);

                        if (data.register.format_category_id != this.state.last_category_id) {
                            this.updateCategory("Formato", this.state.last_category_id, data.register)
                        }
                    });
            }
        }
    }

    formatAdd = (category) => {
        this.setState({
            modal: true,
            modeEditFormat: false,
            ErrorValuesFormat: true,
            title: "Añadir formato",
            checkedItems: new Map(),
            category: category,
            current_format_category: category,

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: category.id,
            }
        })
    }

    editForm = (formato, category) => {

        const arrayPermissions = formato.formats_permissions;
        const arrayNewPermissions = [];
        const array = [];
        let map = new Map();

        arrayPermissions.map((permission, index) => (
            arrayNewPermissions.push(permission.id)
        ));

        this.props.formats_permissions_all.forEach(element => {
            arrayPermissions.forEach(element_accion => {
                if (element.id == element_accion.id) {
                    map.set(element_accion.id.toString(), true)
                }
            });
        })


        category.format_sub_categories.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            modal: true,
            created_format: true,
            modeEditFormat: true,
            category: category,
            title: "Editar formato",
            formato_id: formato.id,
            checkedItems: map,
            last_category_id: category.id,

            selectedOptionSubCategory: {
                format_sub_category_id: formato.format_sub_category != null ? formato.format_sub_category.id : "",
                label: `${formato.format_sub_category != null ? formato.format_sub_category.name : "Sub Categorías"}`
            },

            selectedOptionFormatCategory: {
                format_category_id: category.id,
                label: category.name,
            },

            formCreateFormat: {
                name: formato.name,
                formats_permission_ids: arrayNewPermissions,
                format_category_id: category.id,
            },

            format_sub_categories: array,
        })
    }


    updateCategory = (modulo, category_id, register) => {
        if (modulo == "Formato") {
            this.setState({
                data: this.state.data.map(item => {
                    if (category_id === item.id) {
                        return {
                            ...item,
                            categories: item.categories,
                            format_custom_views: item.format_custom_views,
                            formatos: item.formatos.filter(item => item.id != register.id),
                        }
                    }
                    return item;
                })
            });
        } else if (modulo == "Vista") {
            this.setState({
                data: this.state.data.map(item => {
                    if (category_id === item.id) {
                        return {
                            ...item,
                            categories: item.categories,
                            format_custom_views: item.format_custom_views.filter(item => item.id != register.id),
                            formatos: item.formatos,
                        }
                    }
                    return item;
                })
            });
        } else if (modulo == "Categoria") {
            this.setState({
                data: this.state.data.map(item => {
                    if (category_id === item.id) {
                        return {
                            ...item,
                            categories: item.categories.filter(item => item.id != register.id),
                            format_custom_views: item.format_custom_views,
                            formatos: item.formatos,
                        }
                    }
                    return item;
                })
            });
        }
    }

    edit = (e, config, showInputs) => {
        e.stopPropagation();
        let arrayPositonEdit = [];
        let arrayIds = [];

        let arrayEdit = [];
        let arrayPositionIds = [];

        let arrayPositionFordataEdit = [];
        let arrayPositionFordataIds = [];


        config.position_creates.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        config.positions.map((item) => (
            arrayEdit.push({ label: item.name, value: item.id }),
            arrayPositionIds.push(item.id)
        ))

        config.position_fordatas.map((item) => (
            arrayPositionFordataEdit.push({ label: item.name, value: item.id }),
            arrayPositionFordataIds.push(item.id)
        ))

        this.setState({
            id: config.id,
            modeEdit: true,
            dataProcesosEdit: arrayPositonEdit,
            dataPositionEdit: arrayEdit,
            modalCategories: true,
            showInputs: showInputs,
            editValuesPositionFordata: arrayPositionFordataEdit,

            last_format_category_id: config.format_category_id,
            last_show_index: config.show_index,

            form: {
                name: config.name,
                icon_id: showInputs ? config.icon.id : config.icon_id,
                format_category_id: config.format_category_id,
                is_parent: config.is_parent,
                show_index: config.show_index,
                position: config.position,
                position_create_ids: arrayIds,
                position_ids: arrayPositionIds,
                position_fordata_ids: arrayPositionFordataIds,
            },

            saveFormatCategory: {
                name: !showInputs ? config.format_category.name : "",
                id: config.format_category_id
            },

            selectedOptionChangeCategory: {
                format_category_id: config.format_category_id,
                label: config.format_category ? config.format_category.name : "Categoria",
            },
        })
    }

    editSubCategory = (format_category, format_sub_category) => {
        let arrayPositonEdit = []
        let arrayIds = []

        format_sub_category.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            format_sub_category_id: format_sub_category.id,
            modeEditSubCategories: true,
            dataProcesosEdit: arrayPositonEdit,
            modalSubCategories: true,

            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                position_ids: arrayIds,
                name: format_sub_category.name,
                order_number: format_sub_category.order_number,
                format_category_id: format_category.id,
            }
        })
    }

    deleteFormat = (category_id, formato) => {

        Swal.fire({
            title: 'Escribe el nombre del formato para poder eliminarlo',
            input: 'text',
            footer: `<p>El nombre del formato es (${formato.name}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == formato.name.trim()) {
                    fetch(`/formatos/delete_format/${formato.id}`, {
                        method: "PATCH",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.type == "success") {
                                this.messageSuccess(response);
                                this.getFormatos();
                                this.setState({
                                    data: this.state.data.map(item => {
                                        if (category_id === item.id) {
                                            return {
                                                ...item,
                                                categories: item.categories,
                                                format_custom_views: item.format_custom_views,
                                                formatos: item.formatos.filter(item => item.id != formato.id),
                                            }
                                        }
                                        return item;
                                    })
                                });
                            } else {
                                this.messageSuccess(response);
                            }
                        });
                } else {

                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };


    deleteCustomView = (category_id, format_custom_view) => {

        Swal.fire({
            title: 'Escribe el nombre de vista eliminarlo',
            input: 'text',
            footer: `<p>El nombre de la vista es (${format_custom_view.name}) </p>`,

            inputAttributes: {
                autocapitalize: 'off'
            },

            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#16aaff',
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                if (login == format_custom_view.name.trim()) {
                    fetch(`/formatos/format_custom_views/${format_custom_view.id}`, {
                        method: "delete",
                        headers: {
                            "X-CSRF-Token": this.token,
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.type != "error") {
                                this.messageSuccess(response)
                                this.setState({
                                    data: this.state.data.map(item => {
                                        if (category_id === item.id) {
                                            //const indexRegister =  item.formatos.findIndex(format => format.id === formato.id);
                                            return {
                                                ...item,
                                                categories: item.categories,
                                                format_custom_views: item.format_custom_views.filter(item => item.id != format_custom_view.id),
                                                formatos: item.formatos,
                                            }
                                        }
                                        return item;
                                    })
                                });
                            } else {
                                this.messageSuccess(response)
                            }
                        });
                } else {

                    Swal.showValidationMessage("El nombre no concuerda")
                }
            },

            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {

            }
        })
    };

    /*deleteFormat = (category_id, id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_categories/${category_id}/formats/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.messageSuccess(response)
                       Turbolinks.visit(`/formatos/format_categories`);
                    });
            }
        });
            preConfirm: (login) => {
              return fetch(`/delete_agreement/${accion.id}/${login}`, {
                method: 'delete'
            })
                .then(response => {
                  if (!response.ok) {
                    throw new Error(response.statusText)
                  }
                  return response.json()
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `${error == "Internal Server Error" ? "" : "Error el nombre no concuerda"}`
                  )
                })
            },
    };
    */

    deleteSubCategory = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_sub_categories/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        Turbolinks.visit(`/formatos/format_categories`);
                        this.messageSuccess(response)
                    });
            }
        });
    };

    handleChangeAccions = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        const array = this.state.formCreateFormat.formats_permission_ids

        this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, isChecked)
        }));

        console.log("items", item)

        if (isChecked) {

            array.push(parseInt(item))
            this.setState({
                formCreateFormat: {
                    ...this.state.formCreateFormat,
                    formats_permission_ids: array
                },
            })

            console.log("añadiendo items")
        } else {
            console.log("quitando items")

            var arr = array
            var index = array.indexOf(parseInt(item))
            delete arr[index]

            console.log("delete array")
            console.log(arr.filter(item => item != null))

            this.setState({
                formCreateFormat: {
                    ...this.state.formCreateFormat,
                    formats_permission_ids: arr.filter(item => item != null),
                },
            })
        }
    };

    handleChangeSelectAll = (e, modulo) => {
        const checkedItems = this.state.checkedItems;
        const isChecked = e.target.checked;

        const modulesIds = this.state.modulo_ids;
        const defaultValues = this.state.formCreateFormat.formats_permission_ids

        if (isChecked) {

            this.setState(
                {

                    modulo_ids: [...this.state.modulo_ids, modulo.id],
                    modulos: [...this.state.modulos.filter(item => item.id != modulo.id), modulo],
                }

                , function () {
                    var jeje = this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions
                    this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions.map(value => {
                        checkedItems.set(`${value.id}`, true)
                    })
                    this.setState(
                        {
                            checkedItems: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(Number(key));
                                }
                            }
                            this.setState(
                                {
                                    formCreateFormat: {
                                        ...this.state.formCreateFormat,
                                        formats_permission_ids: array_final,
                                    },

                                })
                        }
                    )
                });




        } else {
            const newSelectCheckedItems = new Map();
            var arr = modulesIds
            var index = modulesIds.indexOf(parseInt(modulo.id))
            delete arr[index]

            var arrModulos = this.state.modulos
            var indexModulos = 0

            /* 
                        this.state.modulos.filter(lista => lista.id != modulo.id).map((modulo) => (
                            modulo.formats_permissions.filter(value => value.name != "Editar todos" && value.name != "Eliminar todos").map((item) => (
                                newSelectCheckedItems.set(`${item.id}`, true) //true this.state.checkedItems.has(item.id)
                            ))
                        )) */

            this.setState(
                {

                    modulo_ids: arr.filter(item => item != null),
                    modulos: arrModulos.filter(item => item != null)
                }

                , function () {
                    var jeje = this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions
                    this.state.modulos.filter(lista => lista.id == modulo.id)[0].formats_permissions.map(value => {
                        checkedItems.set(`${value.id}`, false)
                    })
                    this.setState(
                        {
                            checkedItems: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(key);
                                }
                            }
                            this.setState(
                                {
                                    formCreateFormat: {
                                        ...this.state.formCreateFormat,
                                        formats_permission_ids: array_final,
                                    },

                                })
                        }
                    )

                });

        }
    };

    handleChangeAutocompleteFormatCategory = selectedOptionFormatCategory => {
        this.setState({
            selectedOptionFormatCategory,
            formCreateFormat: {
                ...this.state.formCreateFormat,
                format_category_id: selectedOptionFormatCategory.value
            },
        });
    }

    setIconForm = (icon_id) => {
        this.setState({
            form: {
                ...this.state.form,
                icon_id: icon_id,
            },
        })
    }

    createColumn = (format_category, status) => {
        console.log("Hijo");
        this.setState({ format_category_load_son_id: format_category.id })
        fetch(`/formatos/get_format_category_show/false/${format_category.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.clearFormatCategoryId();
                const format_category_find = data.register;

                if (status) {
                    /*             this.setState({
                                    current_format_category: {},
                                    data: this.state.data 
                                }) */
                } else {

                    if (this.state.current_format_category.id != format_category_find.id && Object.keys(this.state.current_format_category).length != 0) {
                        // el current_format_category es diferente que el actual item en el que le doy click
                        console.log("el current_format_category es diferente que el actual item en el que le doy click");
                        let array = [];
                        this.state.data.filter(value => value.level < format_category_find.level).map(value => {
                            array.push(value);
                        })
                        this.setState({
                            data: array,
                        }, () => {
                            this.setState({
                                current_format_category: format_category_find,
                                data: this.state.current_format_category.id != format_category_find.id ? [...this.state.data, format_category_find] : array,
                            })
                        })
                    } else {
                        console.log("añadiendo nuevo item");
                        this.setState({
                            current_format_category: format_category_find,
                            data: [...this.state.data, format_category_find],
                        })
                    }
                }
            });

    }


    createColumnCategory = (format_category, status) => {
        console.log("Padre");
        this.setState({ isLoadShowCategory: true, format_category_load_parent_id: format_category.id })
        fetch(`/formatos/get_format_category_show/false/${format_category.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                const format_category_find = data.register;
                this.clearFormatCategoryId();

                if (status) {
                    this.setState({
                        select_category_list_id: format_category_find.id,
                        current_category: format_category_find,
                        isLoadShowCategory: false,
                        data: [],
                    })
                } else {

                    if (this.state.current_category.id != format_category_find.id && Object.keys(this.state.current_category).length != 0) {
                        // el current_category es diferente que el actual

                        this.setState({
                            current_category: format_category_find,
                            select_category_list_id: format_category_find.id,
                            data: [format_category_find],
                            isLoadShowCategory: false,
                        })
                    } else {
                        this.setState({
                            current_category: format_category_find,
                            select_category_list_id: format_category_find.id,
                            data: [...this.state.data, format_category_find],
                            isLoadShowCategory: false,
                        })
                    }
                }
            });
    }

    createModulo = (format_category) => {
        this.setState({
            modalCategories: true,
            showInputs: false,
            current_format_category: format_category,

            form: {
                ...this.state.form,
                format_category_id: format_category.id,
                is_parent: true,
                show_index: false,
            }
        })
    }

    //format_custom_views

    toogleCustomView = (from, format_category, type_view) => {
        if (from == "new") {
            this.setState({
                modalCustomView: true,
                modeEditFormatCustomView: false,
                ErrorValuesFormatCustomView: true,
                checkedItemsCustomView: new Map(),
                current_format_category: format_category,

                selectedOptionFormat: {
                    format_id: "",
                    label: "Formatos"
                },

                formCreateFormatCustomView: {
                    ...this.state.formCreateFormatCustomView,
                    format_category_id: format_category.id,
                    type_view: type_view,

                    format_id: "",
                    name: "",
                    icon_id: "",
                    header: [],
                }
            })
        } else {
            this.setState({ modalCustomView: false })
            this.clearValuesCustomView();
        }
    }

    updateDataColumns = (register, from) => {
        this.setState({
            data: this.state.data.map(item => {
                if (this.state.current_format_category.id === item.id) {
                    return {
                        ...item,
                        categories: (from == "categories" ? [...item.categories, register] : item.categories),
                        format_custom_views: (from == "format_custom_views" ? [...item.format_custom_views, register] : item.format_custom_views),
                        formatos: (from == "formatos" ? [...item.formatos, register] : item.formatos),
                    }
                }
                return item;
            })
        });
    }

    clearValuesCustomView = () => {
        this.setState({
            formCreateFormatCustomView: {
                format_id: "",
                name: "",
                format_category_id: "",
                icon_id: "",
                type_view: "",
                custom_view_permission_ids: [],
            }
        })
    }


    HandleClickCustomView = () => {
        if (this.validationFormatCustomView() == true) {
            const array = [];

            this.state.data.map((item) => {
                array.push(item.id)
            })

            const formCreateFormatCustomView = {
                format_id: this.state.formCreateFormatCustomView.format_id,
                name: this.state.formCreateFormatCustomView.name,
                format_category_id: this.state.formCreateFormatCustomView.format_category_id,
                icon_id: this.state.formCreateFormatCustomView.icon_id,
                type_view: this.state.formCreateFormatCustomView.type_view,
                custom_view_permission_ids: this.state.formCreateFormatCustomView.custom_view_permission_ids,
                header: array,
            }

            if (!this.state.modeEditFormatCustomView)
                fetch(`/formatos/format_custom_views`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(formCreateFormatCustomView), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValuesCustomView();
                        this.setState({ modalCustomView: false })
                        this.messageSuccess(data);
                        this.updateDataColumns(data.register, "format_custom_views");
                        this.props.updateDataColumns(this.state.current_format_category, data.register, "format_custom_views")
                    });
            else {
                fetch(`/formatos/format_custom_views/${this.state.format_custom_view_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(formCreateFormatCustomView), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modalCustomView: false })
                        this.messageSuccess(data);
                        this.updateDataItem(data.register, "format_custom_views");
                        this.clearValuesCustomView();

                        if (data.register.format_category_id != this.state.last_category_id) {
                            this.updateCategory("Vista", this.state.last_category_id, data.register)
                        }
                    });
            }
        }
    }

    HandleChangeCustomView = (e) => {
        this.setState({
            formCreateFormatCustomView: {
                ...this.state.formCreateFormatCustomView,
                [e.target.name]: e.target.value
            }
        })
    }

    setIconFormatCustomView = (icon_id) => {
        this.setState({
            formCreateFormatCustomView: {
                ...this.state.formCreateFormatCustomView,
                icon_id: icon_id,
            },
        })
    }


    handleChangeAutocompleteFormat = selectedOptionFormat => {
        this.setState({
            selectedOptionFormat,
            formCreateFormatCustomView: {
                ...this.state.formCreateFormatCustomView,
                format_id: selectedOptionFormat.value
            }
        });
    }

    handleChangeAutocompleteFormatCustomView = selectedOptionFormatCustomView => {
        this.setState({
            selectedOptionFormatCustomView,
            formCreateFormatCustomView: {
                ...this.state.formCreateFormatCustomView,
                format_category_id: selectedOptionFormatCustomView.value
            }
        });
    }


    //checkbox custom view


    handleChangeAccionsCustomView = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        const array = this.state.formCreateFormatCustomView.custom_view_permission_ids

        this.setState(prevState => ({
            checkedItemsCustomView: prevState.checkedItemsCustomView.set(item, isChecked)
        }));

        console.log("items", item)

        if (isChecked) {

            array.push(parseInt(item))
            this.setState({
                formCreateFormatCustomView: {
                    ...this.state.formCreateFormatCustomView,
                    custom_view_permission_ids: array
                },
            })

            console.log("añadiendo items")
        } else {
            console.log("quitando items")

            var arr = array
            var index = array.indexOf(parseInt(item))
            delete arr[index]

            console.log("delete array")
            console.log(arr.filter(item => item != null))

            this.setState({
                formCreateFormatCustomView: {
                    ...this.state.formCreateFormatCustomView,
                    custom_view_permission_ids: arr.filter(item => item != null),
                },
            })
        }
    };

    handleChangeAccionsCustomView = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        const array = this.state.formCreateFormatCustomView.custom_view_permission_ids

        this.setState(prevState => ({
            checkedItemsCustomView: prevState.checkedItemsCustomView.set(item, isChecked)
        }));

        console.log("items", item)

        if (isChecked) {

            array.push(parseInt(item))
            this.setState({
                formCreateFormatCustomView: {
                    ...this.state.formCreateFormatCustomView,
                    custom_view_permission_ids: array
                },
            })

            console.log("añadiendo items")
        } else {
            console.log("quitando items")

            var arr = array
            var index = array.indexOf(parseInt(item))
            delete arr[index]

            console.log("delete array")
            console.log(arr.filter(item => item != null))

            this.setState({
                formCreateFormatCustomView: {
                    ...this.state.formCreateFormatCustomView,
                    custom_view_permission_ids: arr.filter(item => item != null),
                },
            })
        }
    };

    handleChangeSelectAllCustomView = (e, modulo) => {
        const checkedItems = this.state.checkedItemsCustomView;
        const isChecked = e.target.checked;

        const modulesIds = this.state.modulo_custom_view_ids;
        const defaultValues = this.state.formCreateFormatCustomView.custom_view_permission_ids

        if (isChecked) {

            this.setState(
                {

                    modulo_custom_view_ids: [...this.state.modulo_custom_view_ids, modulo.id],
                    modulos_custom_view: [...this.state.modulos_custom_view.filter(item => item.id != modulo.id), modulo],
                }

                , function () {
                    var jeje = this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions
                    this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions.map(value => {
                        checkedItems.set(`${value.id}`, true)
                    })
                    this.setState(
                        {
                            checkedItemsCustomView: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(Number(key));
                                }
                            }
                            this.setState(
                                {
                                    formCreateFormatCustomView: {
                                        ...this.state.formCreateFormatCustomView,
                                        custom_view_permission_ids: array_final,
                                    },

                                })
                        }
                    )
                });




        } else {
            const newSelectCheckedItems = new Map();
            var arr = modulesIds
            var index = modulesIds.indexOf(parseInt(modulo.id))
            delete arr[index]

            var arrModulos = this.state.modulos_custom_view
            var indexModulos = 0

            /* 
                        this.state.modulos.filter(lista => lista.id != modulo.id).map((modulo) => (
                            modulo.formats_permissions.filter(value => value.name != "Editar todos" && value.name != "Eliminar todos").map((item) => (
                                newSelectCheckedItems.set(`${item.id}`, true) //true this.state.checkedItems.has(item.id)
                            ))
                        )) */

            this.setState(
                {

                    modulo_custom_view_ids: arr.filter(item => item != null),
                    modulos_custom_view: arrModulos.filter(item => item != null)
                }

                , function () {
                    var jeje = this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions
                    this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions.map(value => {
                        checkedItems.set(`${value.id}`, false)
                    })
                    this.setState(
                        {
                            checkedItemsCustomView: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(key);
                                }
                            }
                            this.setState(
                                {
                                    formCreateFormatCustomView: {
                                        ...this.state.formCreateFormatCustomView,
                                        custom_view_permission_ids: array_final,
                                    },

                                })
                        }
                    )

                });

        }
    };

    //checkbox custom view

    handleChangeCheckBox = (e, format_category) => {
        const isChecked = e.target.checked;

        if (e.target.name == "all") {
            this.setState({
                data: this.state.data.map(item => {
                    if (format_category.id === item.id) {
                        return {
                            ...item,
                            show_all: isChecked,
                            show_categories: false,
                            show_formats: false,
                            show_custom_views: false,
                        }
                    }
                    return item;
                })
            });
        } else if (e.target.name == "modules") {
            this.setState({
                data: this.state.data.map(item => {
                    if (format_category.id === item.id) {
                        return {
                            ...item,
                            show_all: false,
                            show_categories: isChecked,
                            show_formats: false,
                            show_custom_views: false,
                        }
                    }
                    return item;
                })
            });

        } else if (e.target.name == "formats") {
            this.setState({
                data: this.state.data.map(item => {
                    if (format_category.id === item.id) {
                        return {
                            ...item,
                            show_all: false,
                            show_categories: false,
                            show_formats: isChecked,
                            show_custom_views: false,
                        }
                    }
                    return item;
                })
            });

        } else if (e.target.name == "views") {
            this.setState({
                data: this.state.data.map(item => {
                    if (format_category.id === item.id) {
                        return {
                            ...item,
                            show_all: false,
                            show_categories: false,
                            show_formats: false,
                            show_custom_views: isChecked,
                        }
                    }
                    return item;
                })
            });
        }
    }

    updateDataItem = (register, from) => {
        if (from == "categories") {
            console.log("updateDataItem categories");
            this.setState({
                data: this.state.data.map(item => {
                    if (register.format_category_id === item.id) {
                        return {
                            ...item,
                            format_sub_categories: item.format_sub_categories,
                            level: item.level,
                            name: item.name,
                            show_all: item.show_all,
                            show_categories: item.show_categories,
                            show_custom_views: item.show_custom_views,
                            show_formats: item.show_formats,

                            format_category: item.format_category,
                            format_custom_views: item.format_custom_views,
                            formatos: item.formatos,

                            categories: item.categories.map(category => {
                                if (register.id === category.id) {
                                    return {
                                        ...category,
                                        categories: register.categories,
                                        format_category: register.format_category,
                                        format_category_id: register.format_category_id,
                                        format_custom_views: register.format_custom_views,
                                        name: register.name,

                                        format_sub_categories: register.format_sub_categories,
                                        formatos: register.formatos,
                                        icon_id: register.icon_id,
                                        is_parent: register.is_parent,
                                        level: register.level,
                                        positions: register.positions,
                                        show_all: register.show_all,
                                        show_categories: register.show_categories,
                                        show_custom_views: register.show_custom_views,
                                        show_formats: register.show_formats,
                                    }
                                }
                                return category;
                            })
                        }
                    }
                    return item;
                })
            });
        } else if (from == "formatos") {
            this.setState({
                data: this.state.data.map(item => {
                    if (register.format_category_id === item.id) {
                        return {
                            ...item,
                            categories: item.categories,
                            format_sub_categories: item.format_sub_categories,
                            level: item.level,
                            name: item.name,
                            show_all: item.show_all,
                            show_categories: item.show_categories,
                            show_custom_views: item.show_custom_views,
                            show_formats: item.show_formats,

                            format_category: item.format_category,
                            format_custom_views: item.format_custom_views,
                            formatos: item.formatos.map(formato => {
                                if (register.id === formato.id) {
                                    return {
                                        ...formato,
                                        editar: register.editar,
                                        eliminar: register.eliminar,
                                        format_associations: register.format_associations,
                                        format_category_id: register.format_category_id,
                                        format_sub_category: register.format_sub_category,
                                        format_sub_category_id: register.format_sub_category_id,
                                        formats_permissions: register.formats_permissions,
                                        gestionar: register.gestionar,
                                        name: register.name,
                                        questions_count: register.questions_count,
                                    }
                                }
                                return formato;
                            }),
                        }
                    }
                    return item;
                })
            });
        } else if (from == "format_custom_views") {
            this.setState({
                data: this.state.data.map(item => {
                    if (register.format_category_id === item.id) {
                        return {
                            ...item,
                            categories: item.categories,
                            format_sub_categories: item.format_sub_categories,
                            level: item.level,
                            name: item.name,
                            show_all: item.show_all,
                            show_categories: item.show_categories,
                            show_custom_views: item.show_custom_views,
                            show_formats: item.show_formats,

                            format_category: item.format_category,
                            formatos: item.formatos,
                            format_custom_views: item.format_custom_views.map(format_custom_view => {
                                if (register.id === format_custom_view.id) {
                                    return {
                                        ...format_custom_view,
                                        format: register.format,
                                        icon: register.icon,
                                        name: register.name,
                                        custom_view_permissions: register.custom_view_permissions,
                                        edit: register.edit,
                                        delete: register.delete,
                                    }
                                }
                                return format_custom_view;
                            }),
                        }
                    }
                    return item;
                })
            });
        }
    }

    editFormatCustomView = (format_custom_view, format_category) => {
        const arrayPermissions = format_custom_view.custom_view_permissions;
        const arrayNewPermissions = [];
        const array = [];
        let map = new Map();

        arrayPermissions.map((permission, index) => (
            arrayNewPermissions.push(permission.id)
        ));

        this.props.custom_view_permissions.forEach(element => {
            arrayPermissions.forEach(element_accion => {
                if (element.id == element_accion.id) {
                    map.set(element_accion.id.toString(), true)
                }
            });
        })

        this.setState({
            checkedItemsCustomView: map,
            format_custom_view_id: format_custom_view.id,
            current_format_category: format_category,
            modeEditFormatCustomView: true,
            modalCustomView: true,

            last_category_id: format_category.id,

            formCreateFormatCustomView: {
                ...this.state.formCreateFormatCustomView,
                format_id: format_custom_view.format.id,
                name: format_custom_view.name,
                format_category_id: format_custom_view.format_category_id,
                custom_view_permission_ids: arrayNewPermissions,
                icon_id: format_custom_view.icon_id,
                type_view: format_custom_view.type_view,
            },

            selectedOptionFormatCustomView: {
                format_id: format_custom_view.format_category.id,
                label: format_custom_view.format_category.name,
            },

            selectedOptionFormat: {
                format_id: format_custom_view.format.id,
                label: format_custom_view.format.name,
            }
        })
    }

    setDataFilter = (data) => {
        this.setState({
            data: data.default_categories,

            current_format_category: data.current_category,
            current_category: data.current_category,
            select_category_list_id: data.select_category_list_id,
            isLoadedFilter: false
        })
    }

    clearDataFilter = () => {
        this.setState({
            data: this.props.default_data,
            current_format_category: this.props.current_category,
            current_category: this.props.current_category,
            select_category_list_id: this.props.select_category_list_id,
            isLoadedFilter: false
        })
    }

    updateIsLoadedFilterState = (state) => {
        this.setState({
            isLoadedFilter: state
        })
    }

    handleClickUploadXml = () => {
        if (file.size <= 11485760) {
            this.setState({ isLoadedXml: true })
            const formData = new FormData();
            formData.append("format_category_id", this.state.formUploadXml.format_category_id)
            formData.append("file", this.state.formUploadXml.file)

            fetch(`/formatos/format_category_upload_xml`, {
                method: 'POST', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.messageSuccess(data)
                    this.clearValuesUploadXml();
                    if (data.register.level == 0) {
                        this.props.updateData(data.register);
                    } else {

                    }
                });
        }
    }

    clearValuesUploadXml = () => {
        this.setState({
            modalUploadXml: !this.state.modalUploadXml,
            isLoadedXml: false,
            nameFile: "",
            sizeFile: 0,
            formUploadXml: {
                format_category_id: null,
                file: {}
            }
        })
    }

    uploadXml = (format_category_id = null) => {
        this.setState({
            modalUploadXml: true,
            formUploadXml: {
                format_category_id: format_category_id,
                file: {}
            }
        })
    }

    toogleUploadXml = () => {
        this.clearValuesUploadXml();
    }

    handleChangeFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formUploadXml: {
                    ...this.state.formUploadXml,
                    file: file.size >= 31485760 ? {} : file
                },

                nameFile: file.size >= 31485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
                sizeFile: file.size,
            })
        ));
    };

    render() {
        const styleIcons = {
            cursor: "pointer",
            color: "#3f69d8",
        }

        const styleHeight = {
            height: 'calc(100vh - 290px)'
        }

        const styleHeightFormatCategories = {
            height: 'calc(100vh - 340px)'
        }

        return (
            <React.Fragment>
                {this.state.modalUploadXml && (
                    <UploadFile
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalUploadXml}
                        toggle={this.toogleUploadXml}
                        title={"Subir xml"}

                        handleClick={this.handleClickUploadXml}
                        isLoaded={this.state.isLoadedXml}
                        nameFile={this.state.nameFile}
                        handleFile={this.handleChangeFile}
                        sizeFile={this.state.sizeFile}
                    />
                )}

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEditFormat ? "Actualizar formato" : "Añadir formato"}
                        nameBnt={this.state.modeEditFormat ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.formCreateFormat}
                        submitForm={this.HandleClickFormat}
                        onChangeForm={this.HandleChange}
                        category={this.state.category}
                        modeEdit={this.state.modeEditFormat}
                        errorValues={this.state.ErrorValuesFormat}
                        isLoaded={this.state.isLoadCreateFormat}

                        //ckeckcontainer

                        checkedItems={this.state.checkedItems}
                        handleChangeAccions={this.handleChangeAccions}
                        checkboxes={this.state.checkboxes}
                        modules={this.props.permissions}
                        handleChangeSelectAll={this.handleChangeSelectAll}
                        modulo_ids={this.state.modulo_ids}

                        //select format category
                        selectedOptionFormatCategory={this.state.selectedOptionFormatCategory}
                        handleChangeAutocompleteFormatCategory={this.handleChangeAutocompleteFormatCategory}
                        categories={this.state.categories}

                        //select sub categorias
                        handleChangeAutocompleteSubCategory={this.handleChangeAutocompleteSubCategory}
                        selectedOptionSubCategory={this.state.selectedOptionSubCategory}
                        format_sub_categories={this.state.format_sub_categories}
                    />
                )}

                {this.state.modalCategories && (
                    <FormCreateCategories
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCategories}
                        toggle={this.toogleCategories}
                        title={this.state.modeEdit ? "Actualizar categoría" : "Crear Módulo"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChangeCategory}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}
                        showInputs={this.state.showInputs}
                        errorValues={this.state.ErrorValuesCategories}

                        messageSuccess={this.messageSuccess}
                        handleChangeChecked={this.handleChangeChecked}
                        remove_category={this.state.remove_category}
                        update_position={this.update_position}
                        updatePositionState={this.updatePositionState}
                        isLoadedChangePosition={this.state.isLoadedChangePosition}

                        // select 

                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        editValuesProcesos={this.state.dataProcesosEdit}
                        selectedOption={this.state.selectedOption}

                        //select position fordata

                        handleChangeAutocompleteFordata={this.handleChangeAutocompleteFordata}
                        editValuesPositionFordata={this.state.editValuesPositionFordata}
                        selectedOptionFordata={this.state.selectedOptionFordata}

                        // select update position
                        handleChangeAutocompletePosition={this.handleChangeAutocompletePosition}
                        selectedOptionPosition={this.state.selectedOptionPosition}
                        editValuesPosition={this.state.dataPositionEdit}

                        //select change category
                        handleChangeAutocompleteChangeCategory={this.handleChangeAutocompleteChangeCategory}
                        selectedOptionChangeCategory={this.state.selectedOptionChangeCategory}
                        format_category_id={this.state.modeEdit ? this.state.id : ""}
                        data={this.props.data}

                        icons={this.props.icons}
                        modeEdit={this.state.modeEdit}
                        setIconForm={this.setIconForm}
                        cargos={this.state.cargos}
                    />
                )}

                {this.state.modalSubCategories && (
                    <FormCreateSubCategories
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalSubCategories}
                        toggle={this.subCategories}
                        title={this.state.modeEditSubCategories ? "Actualizar Sub Módulo" : "Crear Sub Módulo"}

                        //form props
                        formValues={this.state.formCreateSubCategories}
                        submitForm={this.HandleClickSubCategory}
                        onChangeForm={this.HandleChangeSubCategory}
                        nameSubmit={this.state.modeEditSubCategories ? "Actualizar" : "Crear"}

                        // select 

                        handleChangeAutocomplete={this.handleChangeAutocompleteSubCategoryMulti}
                        editValuesProcesos={this.state.dataProcesosEdit}
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}
                    />
                )}

                {this.state.modalCategoriesShow && (
                    <ModalShowCategory
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCategoriesShow}
                        toggle={this.toogleCategoriesShow}
                        title={"información de la categoría"}
                        format_category={this.state.format_category}
                    />
                )}

                {this.state.modalCustomView && (
                    <FormCreateCustomView
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCustomView}
                        toggle={this.toogleCustomView}
                        title={this.state.modeEditFormatCustomView ? `Actualizar ${this.state.formCreateFormatCustomView.type_view == "custom_view" ? "vista personalizada" : "estadística"}` : `Crear ${this.state.formCreateFormatCustomView.type_view == "custom_view" ? "vista personalizada" : "estadística"}`}

                        //form props
                        formValues={this.state.formCreateFormatCustomView}
                        submitForm={this.HandleClickCustomView}
                        onChangeForm={this.HandleChangeCustomView}
                        nameSubmit={this.state.modeEditFormatCustomView ? "Actualizar" : "Crear"}
                        errorValues={this.state.ErrorValuesFormatCustomView}

                        // select 

                        handleChangeAutocompleteFormat={this.handleChangeAutocompleteFormat}
                        formatos={this.state.formatos}
                        selectedOption={this.state.selectedOptionFormat}

                        icons={this.props.icons}
                        modeEdit={this.state.modeEditFormatCustomView}
                        setIconForm={this.setIconFormatCustomView}

                        //select format category
                        selectedOptionFormatCategory={this.state.selectedOptionFormatCustomView}
                        handleChangeAutocompleteFormatCategory={this.handleChangeAutocompleteFormatCustomView}
                        categories={this.state.categories}

                        section_custom_views={this.props.section_custom_views}

                        //ckeckcontainer

                        checkedItems={this.state.checkedItemsCustomView}
                        handleChangeAccions={this.handleChangeAccionsCustomView}
                        checkboxes={this.state.checkboxesCustomView}
                        // section_custom_views={this.props.section_custom_views}
                        handleChangeSelectAll={this.handleChangeSelectAllCustomView}
                        modulo_ids={this.state.modulo_custom_view_ids}
                        position={this.props.position}
                    />
                )}

                {this.props.visibleFilter &&
                    <div className="mb-2 ui-formatCategoriesIndex-breadcrumb-search__container">
                        <div className="ui-formatCategoriesIndex-breadcrumb-search p-2">
                            <FormFilter
                                setDataFilter={this.setDataFilter}
                                clearDataFilter={this.clearDataFilter}
                                updateIsLoadedFilterState={this.updateIsLoadedFilterState}
                            />
                        </div>
                    </div>
                }


                <nav aria-label="breadcrumb">
                    <div className="breadcrumb">
                        <ol style={{ padding: 0, margin: 0, listStyle: 'none', display: 'flex', flexWrap: 'wrap' }}>
                            <li className="breadcrumb-item" > MÓDULOS</li>
                            {this.state.data.length >= 1 && (
                                <React.Fragment>
                                    {this.state.data.map((format_category, index) => (
                                        <li className="breadcrumb-item" key={format_category.id}>
                                            <a
                                                data-turbolinks="true"
                                            >
                                                {format_category.name}
                                            </a>
                                        </li>
                                    ))}
                                </React.Fragment>
                            )}
                        </ol>
                    </div>
                </nav>


                <div className="ui-tformatCategoriesIndex-contianer-scroll-notOverflow">

                    {this.state.isLoadedFilter ? (
                        <div className="card ml-3">
                            <div className="card-body">
                                <Preloader />
                            </div>
                        </div>
                    ) : (

                        <React.Fragment>
                            {!this.props.isLoaded ? (
                                <React.Fragment>
                                    <div className="row ui-formatCategoriesIndex-containerRow">
                                        <div className="col-md-3 ui-formatCategoriesIndex-cardContainerModule">
                                            <div className="card card-height ui-formatCategoriesIndex-cardHeight ui_height-card_principal pb-0">
                                                <div className="card-body p-2" style={{ background: "#ffffff" }}>
                                                    <div className="scroll-area-lg scroll-format-categories" style={styleHeightFormatCategories}>
                                                        <div className="scrollbar-container">
                                                            {this.props.data.length >= 1 ? (
                                                                <React.Fragment>
                                                                    <ul className="list-group">
                                                                        {this.props.data.map((format_category, index) => (
                                                                            <li className={`list-group-item justify-content-between align-items-center ${this.state.data.filter((e) => e.id == format_category.id).length > 0 ? "list-group-item-primary" : "item-background"} mb-2 ui-card-list-formatCategoriesIndex`} key={format_category.id} onClick={() => !this.state.isLoadShowCategory ? this.createColumnCategory(format_category, this.state.data.filter((e) => e.id == format_category.id).length >= 1) : console.log("esperando que traiga datos")}>
                                                                                {/* onClick={() => this.createColumnCategory(format_category, this.state.data.filter((e) => e.id == format_category.id).length >= 1)} */}
                                                                                <div className="ui-card-iconText-formatCategoriesIndex">
                                                                                    <i className={`${format_category.icon.class} mr-3 ui-card-icon-folder-formatCategoriesIndex`} style={{ fontSize: "17px" }}></i><div className="ui-text-formarCategoriesIndex">{format_category.name}</div>
                                                                                </div>

                                                                                <div className="ui-icons-formatCategoriesIndex">
                                                                                    {this.state.format_category_load_parent_id == format_category.id ? (
                                                                                        <PreloaderFormatCategory />
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            <i className="fas fa-chevron-right ui-card-icon-chevron-formatCategoriesIndex"></i>

                                                                                            {true && (
                                                                                                <i className="far fa-trash-alt btn ui-card-icon-delete-formatCategoriesIndex" onClick={(e) => this.delete(e, format_category)}></i>
                                                                                            )}

                                                                                            {true && (
                                                                                                <i className="fas fa-pencil-alt btn ui-card-icon-edit-formatCategoriesIndex" onClick={(e) => this.edit(e, format_category, true)}></i>
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </React.Fragment>
                                                            ) : (
                                                                <div className="text-center ui-formatCategoriesIndex-messageNoRecordContainer">
                                                                    <div className="text-center mt-4 mb-4">
                                                                        <p className='ui-formatCategoriesIndex-messageNoRecord'>Todavía no hay módulos creados, da clic en <a onClick={() => this.toogleCategories('new')} className="btn btn-info"><i className='fas fa-toolbox'></i>Nuevo</a> y genera el primer módulo padre.</p>
                                                                        <img className='ui-formatCategoriesIndex-moduleImage-messageNoRecord' src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/6/noRecord_fordata.jpg" alt="" />
                                                                    </div>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>

                                                    <div className="card-footer p-2 ui-formatCategoriesIndex-Card-footer" style={{ borderTop: "initial", justifyContent: "center" }}>
                                                        <UncontrolledDropdown className='btn-group' direction='up'>
                                                            <DropdownToggle className='btn btn-info'>
                                                                <i className="fas fa-bars"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                {true && (
                                                                    <DropdownItem
                                                                        className='dropdown-item'
                                                                        onClick={() => this.toogleCategories('new')}
                                                                    >
                                                                        Crear Módulo
                                                                    </DropdownItem>
                                                                )}
                                                                {true && (
                                                                    <DropdownItem
                                                                        className='dropdown-item'
                                                                        onClick={() => this.uploadXml()}
                                                                    >
                                                                        Subir XML
                                                                    </DropdownItem>
                                                                )}
                                                            </DropdownMenu>

                                                        </UncontrolledDropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/*<div className="content-categories"> */}
                                        {/* <div className="row"> */}
                                        <div className="ui-scroll-horizontal-modulos">
                                            {this.state.data.map((format_category, index) => (

                                                <div className="col-md-4 ui-formatCategoriesIndex-cardContainer-submodule" key={format_category.id}>
                                                    <div className="card card-height-format-category ui-formatCategoriesIndex-cardHeight">

                                                        {/*<div className="">  ui-formatCategoriesIndex-container-submodeleScrollvertical */}
                                                        <div className="card-body pb-0 p-2"> {/* ui-formatCategoriesIndex-container-submodeleCardBody */}
                                                            <div className="scroll-area-lg scroll-format-categories" style={styleHeightFormatCategories}>
                                                                <div className="scrollbar-container">
                                                                    {format_category.categories.length < 1 && format_category.formatos.length < 1 && format_category.format_custom_views.length < 1 ? (
                                                                        <div className="text-center">
                                                                            <div className="text-center mt-4 mb-4">
                                                                                <p className='ui-formatCategoriesIndex-submodule-messageNoRecord'>Todavía no hay módulos, formatos, vistas personalizadas o dashboards creados, da clic en <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                                                    <UncontrolledDropdown className='btn-group'>
                                                                                        <DropdownToggle className='btn-shadow btn btn-info'>
                                                                                            <i className="fas fa-bars"></i>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                                            {true && (
                                                                                                <DropdownItem
                                                                                                    onClick={() => this.createModulo(format_category)}
                                                                                                    className="dropdown-item"
                                                                                                >
                                                                                                    Crear modulo
                                                                                                </DropdownItem>
                                                                                            )}
                                                                                            {this.props.estados.createFormat && (
                                                                                                <DropdownItem
                                                                                                    onClick={() => this.formatAdd(format_category)}
                                                                                                    className="dropdown-item"
                                                                                                >
                                                                                                    Crear formatos
                                                                                                </DropdownItem>
                                                                                            )}
                                                                                            {true && (
                                                                                                <DropdownItem
                                                                                                    onClick={() => this.toogleCustomView("new", format_category, "custom_view")}
                                                                                                    className="dropdown-item"
                                                                                                >
                                                                                                    Crear vista personalizada
                                                                                                </DropdownItem>
                                                                                            )}
                                                                                            {true && (
                                                                                                <DropdownItem
                                                                                                    onClick={() => this.toogleCustomView("new", format_category, "dashboard")}
                                                                                                    className="dropdown-item"
                                                                                                >
                                                                                                    Crear estadísticas
                                                                                                </DropdownItem>
                                                                                            )}
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </div>
                                                                                    <span className='mr-1'> y genera alguna de las opciones que tenemos para ti.</span> </p>
                                                                                <img className='ui-formatCategoriesIndex-submoduleimage-messageNoRecord' src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/6/noRecord_fordata.jpg" alt="" />
                                                                            </div>
                                                                        </div>
                                                                    ) : ("")}
                                                                    {(format_category.categories.length >= 1 && format_category.show_all || format_category.show_categories) && (
                                                                        <React.Fragment>
                                                                            <ul className="list-group">
                                                                                {format_category.categories.map((category, index) => (
                                                                                    <li className={`list-group-item justify-content-between align-items-center mb-1 ${this.state.data.filter((e) => e.id == category.id).length > 0 ? "list-group-item-primary" : "item-background"} ui-card-list-formatCategoriesIndex`} key={category.id} onClick={() => !this.state.isLoadShowCategory ? this.createColumn(category, this.state.data.filter((e) => e.id == category.id).length >= 1) : console.log("cargando registro..")}>
                                                                                        {/* onClick={() => this.createColumn(category, this.state.data.filter((e) => e.id == category.id).length >= 1)}  */}
                                                                                        <div className="ui-card-iconText-formatCategoriesIndex">
                                                                                            <i className={`${this.state.data.filter((e) => e.id == category.id).length > 0 ? "fas fa-folder-open" : "fas fa-folder"} mr-3 ui-card-icon-folder-formatCategoriesIndex`} style={{ fontSize: "17px" }}></i><div className="ui-text-formarCategoriesIndex">{category.name}</div>
                                                                                        </div>

                                                                                        <div className="ui-icons-formatCategoriesIndex">
                                                                                            {this.state.format_category_load_son_id == category.id ? (
                                                                                                <PreloaderFormatCategory />
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <i className="fas fa-chevron-right ui-card-icon-chevron-formatCategoriesIndex"></i>

                                                                                                    <button className="btn ui-card-icon-delete-formatCategoriesIndex" onClick={(e) => this.delete(e, category)}>
                                                                                                        <i className="far fa-trash-alt"></i>
                                                                                                    </button>

                                                                                                    {true && (
                                                                                                        <i className="fas fa-pencil-alt btn ui-card-icon-edit-formatCategoriesIndex" style={{ cursor: "pointer" }} onClick={(e) => this.edit(e, category, false)}></i>
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </div>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>

                                                                            {/* <li className={`link-categories ${this.state.data.filter((e) => e.id == category.id).length > 0 ? "active" : ""}`} onClick={() => this.createColumn(category, this.state.data.filter((e) => e.id == category.id).length >= 1)}>{category.name}</li> */}
                                                                        </React.Fragment>
                                                                    )}

                                                                    {(format_category.formatos.length >= 1 && format_category.show_all || format_category.show_formats) && (
                                                                        <React.Fragment>
                                                                            <ul className="list-group">
                                                                                {format_category.formatos.map((formato, index) => (
                                                                                    <li className={`list-group-item justify-content-between align-items-center item-background mb-1 ${index == 0 && !format_category.show_formats && format_category.categories.length >= 1 ? "mt-3" : ""} ui-card-form-formatCategoriesIndex`} key={formato.id} style={{ background: "#d3e3ce" }}>
                                                                                        <div className="container-form-formatCategoriesIndex">
                                                                                            <i className={`fas fa-file-alt mr-2`} style={{ fontSize: "17px" }}></i>
                                                                                            <a
                                                                                                href={true ? `/formatos/format_categories/${formato.format_category.token}/formats/${formato.token}` : ""}
                                                                                                className="mr-2 ui-text-format-form-categoriesIndex"
                                                                                                data-turbolinks="true"
                                                                                            >
                                                                                                {formato.name} {/* ({formato.questions_count}) */}
                                                                                            </a>
                                                                                        </div>

                                                                                        <div className="widget-content-right widget-content-actions ui-icons-form-formatCategoriesIndex">
                                                                                            {(true) && (
                                                                                                <i className="far fa-trash-alt ml-2 ui-icon-delete-form-formatCategoriesIndex" onClick={() => this.deleteFormat(format_category.id, formato)}></i>
                                                                                            )}

                                                                                            {(true) && (
                                                                                                <i className="fas fa-pencil-alt ui-icon-edit-form-formatCategoriesIndex" onClick={() => this.editForm(formato, format_category)}></i>
                                                                                            )}

                                                                                            {/*<div className="d-inline-block dropdown">
                                                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" className="border-0 btn-transition btn btn-link">
                                                                                    <i className="fa fa-ellipsis-h"></i>
                                                                                </button>
                                                                                <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                                                                                    <button 
                                                                                        type="button" 
                                                                                        tabindex="0" 
                                                                                        className="dropdown-item"
                                                                                        onClick={() => console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")}
                                                                                    >
                                                                                        Editar
                                                                                    </button>
                                                                                    <button type="button" tabindex="0" className="dropdown-item">Eliminar</button>
                                                                                </div>
                                                                            </div>*/}
                                                                                        </div>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </React.Fragment>
                                                                    )}

                                                                    {(format_category.format_custom_views.length >= 1 && format_category.show_all || format_category.show_custom_views) && (
                                                                        <React.Fragment>
                                                                            <ul className="list-group">
                                                                                {format_category.format_custom_views.map((format_custom_view, index) => (
                                                                                    <li className={`list-group-item justify-content-between align-items-center item-background mb-1 ${index == 0 && !format_category.show_custom_views && format_category.categories.length >= 1 ? "mt-3" : ""} ui-card-view-formatCategoriesIndex`} key={format_custom_view.id}>
                                                                                        <div className="container-view-formatCategoriesIndex">
                                                                                            <i className={`${format_custom_view.type_view == "custom_view" ? "fas fa-file-alt" : "fas fa-chart-bar"} mr-2`} style={{ fontSize: "17px" }}></i>
                                                                                            <a
                                                                                                href={`/formatos/format_custom_views/${format_custom_view.token}/format_category/${format_custom_view.format_category.token}`}
                                                                                                className="mr-2 ui-text-view-formatCategoriesIndex"

                                                                                                target="_blank"
                                                                                            >
                                                                                                {format_custom_view.name}
                                                                                            </a>
                                                                                        </div>

                                                                                        <div className="widget-content-right widget-content-actions ui-icons-views-formatCategoriesIndex">
                                                                                            {true && (
                                                                                                <i className="far fa-trash-alt ui-views-delete-formatCategoriesIndex" onClick={() => this.deleteCustomView(format_category.id, format_custom_view)}></i>
                                                                                            )}

                                                                                            {true && (
                                                                                                <i className="fas fa-pencil-alt ui-views-edit-formatCategoriesIndex" onClick={() => this.editFormatCustomView(format_custom_view, format_category)}></i>
                                                                                            )}
                                                                                            {/*<div className="d-inline-block dropdown">
                                                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" className="border-0 btn-transition btn btn-link">
                                                                                    <i className="fa fa-ellipsis-h"></i>
                                                                                </button>
                                                                                <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                                                                                    <button 
                                                                                        type="button" 
                                                                                        tabindex="0" 
                                                                                        className="dropdown-item"
                                                                                        onClick={() => console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")}
                                                                                    >
                                                                                        Editar
                                                                                    </button>
                                                                                    <button type="button" tabindex="0" className="dropdown-item">Eliminar</button>
                                                                                </div>
                                                                            </div>*/}
                                                                                        </div>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="card-footer p-2 ui-formatCategoriesIndex-Card-footer" style={{ borderTop: "initial", justifyContent: "center" }}>

                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.createModulo(format_category)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Crear módulo
                                                                            </DropdownItem>
                                                                        )}
                                                                        {this.props.estados.createFormat && (
                                                                            <DropdownItem
                                                                                onClick={() => this.formatAdd(format_category)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Crear formatos
                                                                            </DropdownItem>
                                                                        )}
                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.toogleCustomView("new", format_category, "custom_view")}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Crear vista personalizada
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.toogleCustomView("new", format_category, "dashboard")}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Crear estadísticas
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.uploadXml(format_category.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Subir xml
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>


                                                                <UncontrolledTooltip target={`PopoverCreate${format_category.id}`}>{"BÚSQUEDA POR FILTRO"}</UncontrolledTooltip>
                                                                <button
                                                                    className="btn-shadow btn btn-info ml-2"
                                                                    id={`PopoverCreate${format_category.id}`}
                                                                >
                                                                    <i className="fas fa-filter pr-2"></i>Filtrar
                                                                </button>

                                                                <UncontrolledPopover trigger="legacy" placement="bottom" target={`PopoverCreate${format_category.id}`}>
                                                                    <PopoverBody className="popover-column">
                                                                        <div className="col-md-12 p-0" style={{ width: "115px", height: "76px" }}>
                                                                            <ul className="pl-1" style={{ listStyleType: "none" }}>
                                                                                <li> <input type="checkbox" name="all" checked={format_category.show_all} onChange={(e) => this.handleChangeCheckBox(e, format_category)} /> Todos</li>
                                                                                <li> <input type="checkbox" name="modules" checked={format_category.show_categories} disabled={!format_category.categories.length >= 1} onChange={(e) => this.handleChangeCheckBox(e, format_category)} /> Módulos</li>
                                                                                <li> <input type="checkbox" name="formats" checked={format_category.show_formats} disabled={!format_category.formatos.length >= 1} onChange={(e) => this.handleChangeCheckBox(e, format_category)} /> Formatos</li>
                                                                                <li> <input type="checkbox" name="views" checked={format_category.show_custom_views} disabled={!format_category.format_custom_views.length >= 1} onChange={(e) => this.handleChangeCheckBox(e, format_category)} /> Vistas</li>
                                                                            </ul>
                                                                        </div>
                                                                    </PopoverBody>
                                                                </UncontrolledPopover>
                                                            </div>
                                                        </div>
                                                        {/* </div>   */}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="card">
                                    <div className="card-body">
                                        <Preloader />
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>

            </React.Fragment>
        );
    }
}

export default Index;