import React from "react";
import Chart from "react-apexcharts";

export const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
];

export const options = {
    title: "My Daily Activities",
    is3D: true,
};


class GeneralLineChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();
        const max_length = 10;
        const categories = this.props.data.Task;
        // RECORTAR LAS LABELS
        const labels = this.props.data.Task.map((item) => {
            if (item.length > max_length) {
                return item.substring(0, max_length-3) + "...";
            }
            return item;
        });
        const is_currency = this.props.data.is_currency;
        this.state = {

            series: this.props.data.Hours_per_Day,
            options: {
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                        }
                    },
                    // width: 380,
                    type: 'pie',
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(0) + "%"
                    },
                    style: {
                        fontSize: '20px',
                    }
                },
                tooltip: {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let value;
                        if (is_currency) {
                            value = new Intl.NumberFormat('es-ES', {style: 'currency', currency: 'COP', currencyDisplay: 'narrowSymbol'}).format(series[seriesIndex]);
                        } else {
                            value = series[seriesIndex];
                        };
                        // CALCULAR EL PORCENTAJE
                        const total = series.reduce((a, b) => a + b, 0);
                        const percentage = ((series[seriesIndex] / total) * 100).toFixed(0) + "%";
                        return '<div style="' +
                                'background-color:' + w.globals.colors[seriesIndex] + '; ' +  // Color de fondo
                                'padding: 4px; ' +             // Padding
                                'border-radius: 5px; ' +        // Bordes redondeados
                                'box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2); ' +  // Sombra opcional
                                'color: #FFFFFF; ' +               // Color del texto
                                'font-size: 16px;' +            // Tamaño del texto
                                'font-weight: 500;' +           // Grosor del texto
                            '">' +
                            '<span>'+categories[seriesIndex] + ": " + value + " (" + percentage + ")" + '</span>' +
                            '</div>'
                    }
                },
                labels: labels,
                y: {
                    formatter: function (value) {
                        if (is_currency) {
                            return new Intl.NumberFormat('es-ES', {style: 'currency', currency: 'COP', currencyDisplay: 'narrowSymbol'}).format(value);
                        }
                        return value;
                    }
                },
                legend: {
                    fontSize: '16px',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            // width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },


        };

    }
    componentDidMount() {
        console.log("this.chartReference"); // returns a Chart.js instance reference
    }
    componentWillReceiveProps(nextProps) {

        if (this.props.data !== nextProps.data) {
            const max_length = 10;
            const categories = nextProps.data.Task;
            // RECORTAR LAS LABELS
            const labels = this.props.data.Task.map((item) => {
                if (item.length > max_length) {
                    return item.substring(0, max_length-3) + "...";
                }
                return item;
            });
            const is_currency = this.props.data.is_currency;
            this.setState(
                {

                    series: nextProps.data.Hours_per_Day,
                    options: {
                        chart: {
                            toolbar: {
                                show: true,
                                tools: {
                                    download: true,
                                }
                            },
                            // width: 380,
                            type: 'pie',
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: function (val) {
                                return val.toFixed(0) + "%"
                            },
                            style: {
                                fontSize: '20px',
                            }
                        },
                        tooltip: {
                            enabled: true,
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let value;
                                if (is_currency) {
                                    value = new Intl.NumberFormat('es-ES', {style: 'currency', currency: 'COP', currencyDisplay: 'narrowSymbol'}).format(series[seriesIndex]);
                                } else {
                                    value = series[seriesIndex];
                                };
                                // CALCULAR EL PORCENTAJE
                                const total = series.reduce((a, b) => a + b, 0);
                                const percentage = ((series[seriesIndex] / total) * 100).toFixed(0) + "%";
                                return '<div style="' +
                                        'background-color:' + w.globals.colors[seriesIndex] + '; ' +  // Color de fondo
                                        'padding: 4px; ' +             // Padding
                                        'border-radius: 5px; ' +        // Bordes redondeados
                                        'box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2); ' +  // Sombra opcional
                                        'color: #FFFFFF; ' +               // Color del texto
                                        'font-size: 16px;' +            // Tamaño del texto
                                        'font-weight: 500;' +           // Grosor del texto
                                    '">' +
                                    '<span>'+categories[seriesIndex] + ": " + value + " (" + percentage + ")" + '</span>' +
                                    '</div>'
                            }
                        },
                        labels: labels,
                        y: {
                            formatter: function (value) {
                                if (is_currency) {
                                    return new Intl.NumberFormat('es-ES', {style: 'currency', currency: 'COP', currencyDisplay: 'narrowSymbol'}).format(value);
                                }
                                return value;
                            }
                        },
                        legend: {
                            fontSize: '16px',
                        },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    // width: 300
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }]
                    }

                }
            )
        }
    }

    render() {

        return (


            <React.Fragment>

                <div>
                    <div className="mixed-chart" style={{minHeight: '300px'}}>
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type={this.props.data.type}
                            // width={400}
                            className={`bw-chart-pie`} 
                        />
                    </div>
                </div>





            </React.Fragment>
        );
    }
}

export default GeneralLineChart;
