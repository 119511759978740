import React, { Component } from 'react';
import Gantt from './Gantt/index';
import FormFilter from '../tasksCard/FormFilter';
import FormSelectColumn from './FormSelectColumn';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            currentZoom: 'Days',
            isLoaded: true,
            modal: false,
            messages: [],

            isFiltering: false,
            showFilter: false,

            selectedOption: {
                column_id: "",
                label: "Columna en la que van a quedar las tareas creadas"
            },

            columns: [],
        }
    }

    handleZoomChange = (zoom) => {
        this.setState({
            currentZoom: zoom
        });
    }

    componentDidMount = () => {
        this.loadData();
        this.configSelect();
    }

    configSelect = () => {
        let arrayColumns = []

        this.props.columns.map((item) => (
            arrayColumns.push({label: item.name, value: item.id})
        ))

        this.setState({
            columns: arrayColumns,
            modal: true,
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            modal: false,
        });
    };

    //tasks functions

    createTask = (item) => {
        const form = {
            name: item.text,
            due_date: item.start_date,
            parent_task_id: item.parent,
            duration: item.duration,
            end_date: item.end_date,
            user_owner_id: this.props.current_user.id,
            column_id: 133,
        }

        fetch(`/task/create_task`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
            
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.loadData();
        });
    }

    updateTask = (item) => {
        const form = {
            name: item.text,
            due_date: item.start_date,
            parent_task_id: item.parent,
            duration: item.duration,
            end_date: item.end_date,
            progress: item.progress,
            column_id: 133,
        }

        fetch(`/task/update_task/${item.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.loadData();
        });
    }


    deleteTask = (item) => {
        fetch(`/task/destroy_task/${item.id}`, {
            method: "delete",
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(response => {
            this.loadData();
        });
    }


    //links functions

    createLink = (item) => {

        const form = {
            source_task_id: item.source,
            target_task_id: item.target, 
            type_task: item.type,
        }

        fetch(`/task/create_link`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.loadData();
        });
    }

    updateLink = (item) => {

        const form = {
            source_task_id: item.source,
            target_task_id: item.target, 
            type_task: item.type,
        }
        
        fetch(`/task/update_link/${item.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.loadData();
        });
    }


    deleteLink = (item) => {
        fetch(`/task/destroy_link/${item.id}`, {
            method: "delete",
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(response => {
            this.loadData();
        });
    }


    logDataUpdate = (type, action, item, id) => {
        console.log("type", type)
        console.log("action", action)
        console.log("item", item)

        //links 

        if(type == "link" && action == "create"){
            this.createLink(item);
        }else if(type == "link" && action == "update"){
            this.updateLink(item);
        }else if(type == "link" && action == "delete"){
            this.deleteLink(item);
        }   

        //tasks

        if(type == "task" && action == "create"){
            this.createTask(item);
        }else if(type == "task" && action == "update"){
            this.updateTask(item);
        }else if(type == "task" && action == "delete"){
            this.deleteTask(item);
        }
    }

    loadData = () => {
        fetch(this.props.url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    cancelFilter = () => {
        this.loadData()
        this.setState({
            isFiltering: false,
            isLoaded: false,
            showFilter: false,
        })
    }

    handleClickShowFilter = () => {
        this.setState({
            showFilter: !this.state.showFilter
        })
    }

    updateStateIsLoaded = (state) => {
        this.setState({
            isLoaded: state,
        })
    }

    updateDataFilter = (data) => {
        this.setState({
            data: data.data
        })
    }

    render() {
        const { currentZoom } = this.state;

        if(this.state.isLoaded){
            return(
                <p>Cargando...</p>
            )
        }

        return (
            <React.Fragment>

                {this.state.showFilter && (
                    <FormFilter 
                        from={this.props.from}
                        index={true}
                        url={this.props.url}

                        //values data
                        users={this.props.users}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        procesos={this.props.procesos}
                        task_sources={this.props.task_sources}

                        //update states
                        updateStateIsLoaded={this.updateStateIsLoaded}
                        updateDataFilter={this.updateDataFilter}
                        cancelFilter={this.cancelFilter}

                        //states
                        isFiltering={this.state.isFiltering}
                    />
                )}

                {false && (
                    <FormSelectColumn 
                        backdrop={"static"}
                        modal={this.state.modal}
                        title={"Crear tarea"}

                        selectedOption={this.state.selectedOption}
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        columns={this.state.columns}
                    />
                )}
                                
                <nav aria-label="breadcrumb">
                    <a
                        className="icon-filter"    
                        onClick={() => this.handleClickShowFilter()}
                    >
                        <i className="fas fa-filter pr-2"></i> Filtrar
                    </a>
                            
                    {true && (
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/task/task_boards" data-turbolinks="true">Tableros</a></li>
                            <li className="breadcrumb-item active" aria-current="page">tareas</li>
                        </ol>
                    )}
                </nav>

                <div className="gantt-container">
                    <Gantt
                        tasks={this.state.data}
                        zoom={currentZoom}
                        onDataUpdated={this.logDataUpdate}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
