import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../../../GeneralComponents/HeaderTitle'
import FormCreate from './FormCreate'
import FormCreateSection from './FormCreateSection'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;

        this.state = {
            modal: false,
            modalSection: false,
            ErrorValues: true,
            ShowFormModule: true,
            isLoadedModuleAccion: false,
            modeEdit: false,
            position2: 0,
            section_accion_id: "",

            id: "",
            section_id: "",

            form: {
                name: "",
                show_module: "",
                section_category_id: "",
            },

            formUpdate: {
                position: "",
            },

            formSectionAccion: {
                name: "",
                state: "",
                section_id: ""
            },

        }

    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            id: "",
            section_id: "",

            form: {
                name: "",
                show_module: "",
                section_category_id: "",
            },
        })
    }

    clearValuesUpdate = () => {
        this.setState({
            formUpdate: {
                name: "",
                show_module: "",
                position: "",
            },

            id: "",
            position2: 0,

        })
    }


    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    toogleModalSection = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modalSection: true })
            this.clearValues();
        } else {
            this.setState({ modalSection: false })
        }
    }

    HandleClick = () => {
        if (!this.state.modeEdit) {
            fetch("/settings/sections", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    this.clearValues();
                    this.setState({ modalSection: false })
                    this.messageSuccess(data);
                });
        } else {
            fetch(`/settings/sections/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadData();
                    this.clearValues();
                    this.setState({ id: "", section_id: "", modalSection: false })
                    this.messageSuccess(data);
                });
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch("/settings/sections/" + id, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    //Update section

    edit = (section) => {
        this.setState({
            id: section.id,
            modeEdit: true,
            modalSection: true,

            form: {
                name: section.name,
                show_module: section.show_module,
                section_category_id: section.section_category_id,
            },
        })
    }

    editPosition = (section) => {
        this.setState({
            section_id: section.id,
            formUpdate: {
                position: section.position,
            },
        })
    }

    HandleChangePosition = (e) => {
        this.setState({
            position2: e.target.value,
        });
    }

    // acciones de modulo 

    addActions = (section) => {
        this.setState({
            modal: true,
            section_accion_id: section.id,
        })
    }

    update_position = (e) => {
        fetch(`/settings/update_position/${this.state.id}/${this.state.formUpdate.position}/${e.target.value}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.clearValuesUpdate();
                this.messageSuccess(data);
            });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogleModalSection}
                        title={"Agregar acciones para el módulo"}

                        errorValues={this.state.ErrorValues}
                        section_accion_id={this.state.section_accion_id}

                        //form props create section_accion
                        messageSuccess={this.messageSuccess}
                        loadData={this.props.loadData}
                    />
                )}

                {this.state.modalSection && (
                    <FormCreateSection
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalSection}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar módulo" : "Agregar nuevo módulo"}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Agregar"}

                        //form props create module
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        dataCategory={this.props.dataCategory}

                    />
                )}

                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr>
                                    <th style={{ width: "8%" }}>#</th>
                                    <th>Nombre</th>
                                    <th>Categoría</th>
                                    <th>App</th>
                                    <th>Acciones del módulos</th>
                                    <th className="text-center">Opciones</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.data.length >= 1 ? (
                                    this.props.data.map(section => (
                                        <tr key={section.id}>
                                            <td>
                                                {section.id == this.state.section_id ? (
                                                    <React.Fragment>
                                                        <i className="fas fa-times float-right" style={{ cursor: "pointer" }} onClick={() => this.clearValues()}></i>
                                                        <select
                                                            type="text"
                                                            name="position"
                                                            value={this.state.formUpdate.position}
                                                            onChange={(e) => this.update_position(e)}
                                                            className="form form-control"
                                                        >

                                                            {this.props.data.map(item => (
                                                                <React.Fragment>
                                                                    <option value={item.position}>{item.position}</option>
                                                                </React.Fragment>
                                                            ))}
                                                        </select>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <i className="fas fa-pencil-alt float-right" style={{ cursor: "pointer" }} onClick={() => this.editPosition(section)}></i>
                                                        <p>{section.position}</p>
                                                    </React.Fragment>
                                                )}
                                            </td>
                                            <td>
                                                <p>{section.name}</p>
                                            </td>

                                            <td>
                                                <p>{section.section_category != undefined ? section.section_category.name : ""}</p>
                                            </td>

                                            <td>
                                                <p>{section.show_module}</p>
                                            </td>

                                            <td className="">
                                                {section.section_accions.length >= 1 ? (
                                                    <React.Fragment>
                                                        <p><span className="link-line" onClick={() => this.addActions(section)}> Crear acción </span></p>
                                                        {section.section_accions.map((section_accion, index) => (
                                                            <span className={`span-section-actions mr-2`}>{section_accion.name}</span>
                                                        ))}
                                                    </React.Fragment>
                                                ) : (
                                                    <p>No hay Acciones para {section.name} <span className="link-line" onClick={() => this.addActions(section)}> Crear acción </span></p>
                                                )}
                                            </td>

                                            <td className="text-right" style={{ width: "10px" }}>
                                                <UncontrolledDropdown className='btn-group'>
                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                        <i className="fas fa-bars"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                        {true && (
                                                            <DropdownItem
                                                                onClick={() => this.edit(section)}
                                                                className="dropdown-item"
                                                            >
                                                                Editar

                                                            </DropdownItem>
                                                        )}
                                                        {true && (
                                                            <DropdownItem
                                                                onClick={() => this.delete(section.id)}
                                                                className="dropdown-item"
                                                            >
                                                                Eliminar
                                                            </DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            <div className="text-center mt-4 mb-4">
                                                <h4>No hay registros con el criterio de búsqueda</h4>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;

