import React, { Component } from 'react';
import Index from '../components/settings/Home/Index';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle';
import Preloader from '../GeneralComponents/LoadingComponen'

class HomeIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataModules: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    loadData = () => {
        fetch(`/settings/get_favorite_modules`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataModules: data.dataModules,
                isLoaded: false
            });
        });
    }


    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Página principal"}
                    subTitle={"Gestiona tus módulos"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={() => this.child.current.toogleCategories("new", true)}
                    showNew={false}
                    showFilter={false}
                />


                {this.state.isLoaded ? (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <Preloader />
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <Index
                        isLoaded={this.state.isLoaded}
                        current_user={this.props.current_user}
                        dataModules={this.state.dataModules}
                        loadData={this.loadData}
                    />
                )}
            </React.Fragment>
        );
    }
}


export default HomeIndex;
WebpackerReact.setup({ HomeIndex });
