import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class TableIndexWeb extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card card-style">
                    <div className="card-body ui-tables_layout-register-card-body">

                        <div className='ui-tables_layout-table__container'>

                            <table className="table ui-tables_layout-table-header__container" id="sampleTable">
                                <thead className='ui-tables_layout-table-header'>
                                    <tr className='ui-tables_layout-__container-title-table'>
                                        <th style={{ width: "30%" }}>Nombre</th>
                                        <th style={{ width: "25%" }}>Descripción</th>
                                        <th>Procesos</th>
                                        {this.props.estados.uploadFile && (
                                            <th>Adjuntos</th>
                                        )}
                                        {(this.props.estados.editar || this.props.estados.eliminar) && (
                                            <th className="text-center">Opciones</th>
                                        )}
                                    </tr>
                                </thead>

                                <tbody className='ui-tables_layout-data__container'>
                                    {this.props.data.length >= 1 ? (
                                        this.props.data.map(position => (
                                            <tr key={position.id}>
                                                <td>{position.name}</td>
                                                <td>{position.description}</td>
                                                <td>
                                                    <span
                                                        className="link-line"
                                                        onClick={() => position.procesos.length >= 1 ? this.props.toogleShow("new", position) : ""}
                                                    >
                                                        {position.procesos.length} procesos
                                                    </span>
                                                </td>

                                                {this.props.estados.uploadFile && (
                                                    <td>
                                                        <p><span className="link-line" onClick={() => this.props.addFiles(position)}>{position.general_files.length} Archivos adjuntos </span></p>
                                                    </td>
                                                )}

                                                {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                    <td className="text-center" style={{ width: "10px" }}>
                                                        {true && (
                                                            <React.Fragment>
                                                                {(this.props.estados.editar || this.props.estados.eliminar) && (
                                                                    <UncontrolledDropdown className='btn-group ui-tables_layout-dropdown__container'>
                                                                        <DropdownToggle>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="ui-tables_layout-dropdown_header dropdown-menu-right">
                                                                            {this.props.estados.editar && (
                                                                                <DropdownItem
                                                                                    href={`/settings/positions/${position.token}/edit`}
                                                                                    data-turbolinks="true"
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}
                                                                            {(this.props.estados.eliminar && position.name != "Administrador Sistema" && position.name != "Sistema Basico") && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.props.delete(position.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        ))

                                    ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="text-center mt-4 mb-4">
                                                    <h4>No hay registros</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TableIndexWeb;
