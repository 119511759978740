import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import IndexColumn from './columns/IndexColumn';
import IndexTaskLabel from './taskLabels/Index';

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered form-create-board modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                    {!this.props.task_board.delete_template && (
                                        <React.Fragment>
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="row w-100">
                                                        <div className="col-md-8">
                                                            {this.props.modeEdit ? "Actualizar tablero" : "Crear tablero"}
                                                        </div>

                                                        <div className="col-md-4 pr-0 text-right">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body p-2">
                                                    <div className="col-md-12 mb-3">
                                                        <label>Nombre</label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            value={this.props.formValues.name}
                                                            onChange={this.props.onChangeForm}
                                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                                        />
                                                    </div>

                                                    {this.props.is_template && (
                                                        <div className="col-md-12 mb-3">
                                                            <label>Usuarios </label>
                                                            <input
                                                                type="hidden"
                                                                name="user_ids"
                                                                value={this.props.selectedOptionUsers.user_ids}
                                                            />

                                                            <Select
                                                                onChange={this.props.handleChangeAutocompleteUsers}
                                                                options={this.props.users}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                autoFocus={false}
                                                                className={`link-form`}
                                                                classNamePrefix="select"
                                                                placeholder="Seleccione"
                                                                name="user_ids"
                                                                defaultValue={this.props.editValuesUsers}
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                                {this.props.showBtn && (
                                                    <div className="card-footer">
                                                        <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameSubmit}</button>
                                                    </div>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}
                                    </div>


                                    {Object.keys(this.props.task_board).length != 0 && (
                                        <React.Fragment>
                                            <div className="col-md-12">
                                                <IndexColumn 
                                                    task_board={this.props.task_board}
                                                    updateColumnCount={this.props.updateColumnCount}
                                                />
                                            </div>

                                            <div className="col-md-12 mt-3">
                                                <IndexTaskLabel
                                                    task_board={this.props.task_board}
                                                    users={this.props.users}
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;


                                    {/*<div className="col-md-12">
                                        {this.props.labels.map(label => (
                                            <span key={label.id} onClick={() => this.props.addLabel(label, this.props.selectLabels.includes(label.id))} style={{ background: label.color, cursor: "pointer" }} className={`badge badge-secondary mt-2 mr-2`}>{label.name} {this.props.selectLabels.includes(label.id) ? <i className="fas fa-check"></i> : null }</span>
                                        ))}
                                        </div>*/}