import React, { Component } from 'react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';

class NotificationDocument extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
        }
    }
    
    componentDidMount(){
        this.loadData();
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    loadData = () => {
        fetch(`/get_general_commets_indicators/${this.props.module_comment}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
            });
        });
    }


    dateTime = (fecha) => {
        var d = new Date(fecha),
          months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + d.getDate() + " " + 'del' + " " + d.getFullYear()
    }

    render() {
        return (
            <div className="card-hover-shadow-2x card card-list">
                <div className="card-header-tab card-header card-header-document">
                    <i className="header-icon far fa-comments"> </i> Actividad del documento
                </div>

                <div className="scroll-area-lg">
                    <div className="scrollbar-container">
                        <div className="p-2">
                            <div className="chat-wrapper p-1">
                                <div className="chat-box-wrapper">
                                    <div className="col-md-12">

                                        {this.props.notifications.map(notification => (
                                            <React.Fragment>
                                                <div className="row mb-4 ui-notificationDocument-rowComment">
                                                    <div className="ui-notificationDocument-containerAvatar">
                                                        <div className="avatar-icon-wrapper mr-1">
                                                            <div className="avatar-icon avatar-icon-lg rounded">
                                                                <img src={notification.user_send.img} alt="" className="avatar-reply" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="pl-0 ui-notificationDocument-containerComment">
                                                        <div className="">
                                                            <b>{notification.title}</b> <br />
                                                            {ReactHtmlParser(notification.description)}
                                                        </div>

                                                        <small className="opacity-6">
                                                            <i className="fa fa-calendar-alt mr-1" />{this.dateTime(notification.create_date)} 
                                                        </small>
                                                    </div>
                                                </div>

                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        );
    }
}

export default NotificationDocument;


