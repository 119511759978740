import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    {this.props.data.length >= 1 && (
                                        <div className="col-md-6">
                                            <select 
                                                name="conditional"
                                                onChange={this.props.HandleChange}
                                                value={this.props.formValues.conditional}
                                                className="form form-control"
                                            >
                                                <option value="">Condicional</option>
                                                <option value="Y">Y</option>
                                                <option value="O">O</option>
                                            </select>
                                        </div>
                                    )}

                                    <div className={`col-md-${this.props.data.length >= 1 ? "6" : "12"} mb-3`}>
                                        <input
                                            type="hidden"
                                            name="question_id"
                                            value={this.props.selectedOption.question_id}
                                        />
                                        <Select
                                            onChange={this.props.handleChangeAutocomplete}
                                            options={this.props.questions}
                                            autoFocus={false}
                                            className={`link-form ${!this.props.errorValues && this.props.formValues.question_id == "" ? "error-class" : ""}`}
                                            value={this.props.selectedOption}
                                        />
                                    </div>
                                

                                    <div className={`col-md-${this.props.data.length >= 1 ? "6" : "6"}`}>
                                        <select 
                                            name="operation"
                                            onChange={this.props.HandleChange}
                                            value={this.props.formValues.operation}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.operation == "" ? "error-class" : ""}`}
                                        >
                                            <option value="">Operación..</option>
                                            {this.props.options_operation.map((item, index) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {(this.props.type_question == "answer_user_id" || this.props.type_question == "proceso_id" || this.props.type_question == "position_id") && (
                                        <div className={`col-md-12 mt-3`}>
                                            <input
                                                type="hidden"
                                                name="value"
                                                value={this.props.selectedOptionSelect.value}
                                            />
                                            <Select
                                                onChange={this.props.handleChangeAutocompleteSelect}
                                                options={this.props.values}
                                                autoFocus={false}
                                                className={`link-form`}
                                                value={this.props.selectedOptionSelect}
                                            />
                                        </div>
                                    )}

                                    <div className={`col-md-${this.props.data.length >= 1 ? "6" : "6"}`}>
                                        {this.props.options.length >= 1 ? (
                                            <React.Fragment>
                                                <select 
                                                    name="value" 
                                                    onChange={this.props.HandleChange}
                                                    value={this.props.formValues.value}
                                                    className="form form-control"
                                                >
                                                    <option value="">Seleccione</option>
                                                    {this.props.options.map((item, index) => (
                                                        <option value={item.name}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {(this.props.type_question != "answer_user_id" && this.props.type_question != "proceso_id" && this.props.type_question != "position_id") && (
                                                    <input 
                                                        type={this.props.type_question == "answer_date" ? "date" : "text"} 
                                                        name="value"
                                                        onChange={this.props.HandleChange}
                                                        disabled={this.props.type_question == "answer_user_id" || this.props.type_question == "proceso_id" || this.props.type_question == "position_id" ? true : false}
                                                        value={this.props.formValues.value}
                                                        className={`form form-control ${!this.props.errorValues && this.props.formValues.value == "" ? "error-class" : ""}`}
                                                        placeholder="Valor"
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>

                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.handleClick}>{this.props.nameBnt}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;
