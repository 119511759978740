import React from 'react';

const index = (props) => {
    return (
        <React.Fragment>
            {props.data.length >= 1 ? (
                props.data.map(objetive => (
                    <div className={`card mb-3`} key={objetive.id}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <b>Objetivo</b>
                                    <p className='mt-2'>
                                        {objetive.id ==  props.stateId ? (
                                            <input
                                                type="text"
                                                name="name"
                                                value={props.formUpdateValue}
                                                onChange={props.HandleChangeUpdate}
                                                className="form form-control"
                                            />
                                        ) : (
                                            <span>{objetive.name}</span>
                                        )}
                                    </p>
                                </div>                                 

                            </div>
                        </div>
                        <React.Fragment>
                            {(props.estados.editar || props.estados.eliminar) && (
                                <div className="card-footer">
                                    {props.stateId != "" ? (
                                        <React.Fragment>
                                            {objetive.id == props.stateId &&(
                                                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                                    <i className="fas fa-check-circle update-section" onClick={() => props.HandleClickUpdate()}></i> 
                                                    <i className="fas fa-times-circle update-false" onClick={(event) => props.HandleClickCancel(event)}></i>
                                                </div>
                                            )} 
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {props.estados.editar && (
                                                <button
                                                    onClick={() => props.edit(objetive)}
                                                    className="dropdown-item"
                                                >
                                                    Editar
                                                </button>
                                            )}

                                            {props.estados.eliminar && (  
                                                <button
                                                    onClick={() => props.delete(objetive.id)}
                                                    className="dropdown-item"
                                                >
                                                    Eliminar
                                                </button>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                </div>
            ))

        ) : (
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body text-center">
                        <h4>No hay registros</h4>
                    </div>
                </div>
            </div>
        )}
        </React.Fragment>
    );
};

export default index;