import React from "react";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CheckboxContainer from "./CheckboxContainer";
import Select from "react-select";
import InputProcess from "./inputProcess"
import { isMobile } from 'react-device-detect';

class FormCreate extends React.Component {
  constructor(props) {
    super(props);
    this.token = document.querySelector("[name='csrf-token']").content;
    this.state = {
      title: "Nuevo rol",
      modal: false,
      ErrorValues: true,
      backdrop: "static",
      modeEdit: this.props.editMode,
      id: (this.props.editMode ? this.props.position.token : ""),
      form: {
        name: (this.props.editMode ? this.props.position.name : ""),
        description: (this.props.editMode ? this.props.position.description : ""),
        id: (this.props.editMode ? this.props.position.id : ""),
        section_accion_ids: [],
        format_category_ids: [],
        proceso_ids: [],
      },

      checkedItems: new Map(),
      checkboxes: [],
      modules: [],

      checkedItemFormData: new Map(),
      checkedItemParentFormatCategory: new Map(),
      checkboxesFormData: [],



      selectedOptionProcesos: {
        proceso_ids: "",
        label: "Procesos"
      },

      editValues: [],
      dataProcesos: [],
      data: [],
    };

  }


  handleChangeAccions = e => {
    const item = e.target.value;
    const isChecked = e.target.checked;
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked)
    }));
  };

  handleChangeAccionFormat = (e, is_module_parent) => {
    e.stopPropagation();
    const item = e.target.value;
    const isChecked = e.target.checked;

    const form = {
      position_id: this.props.position.id,
      is_parent: is_module_parent,
      format_category_id: item,
    };

    this.setState(prevState => ({
      checkedItemFormData: prevState.checkedItemFormData.set(item, isChecked)
    }));

    fetch(`/settings/update_position_format_category`, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(form), // data can be `string` or {object}!
        headers: {
            "X-CSRF-Token": this.token,
            "Content-Type": "application/json"
        }
    })
    .then(res => res.json())
    .catch(error => console.error("Error:", error))
    .then(data => {

    });
  };

  handleChangeAccionParentFormatCategory = (e) => {
    e.stopPropagation();
    const item = e.target.value;
    const isChecked = e.target.checked;

    const form = {
      position_id: this.props.position.id,
      format_category_id: item,
    };

    this.setState(prevState => ({
      checkedItemParentFormatCategory: prevState.checkedItemParentFormatCategory.set(item, isChecked)
    }));

    fetch(`/settings/update_position_parent_format_category`, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(form), // data can be `string` or {object}!
        headers: {
            "X-CSRF-Token": this.token,
            "Content-Type": "application/json"
        }
    })
    .then(res => res.json())
    .catch(error => console.error("Error:", error))
    .then(data => {

    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      checkedItems: new Map()
    });

  }

  section_category = () => {
    fetch(`/settings/get_positions_section_category`, {
        method: 'GET', // or 'PUT'
        headers: {
            "X-CSRF-Token": this.token,
            "Content-Type": "application/json"
        }
    })
    .then(response => response.json())
    .then(data => {
      if (this.props.editMode) {
        this.setState({
          data: data.data,
        });

        this.selectValuesUpdate();
      }else{
        this.setState({
          data: data.data,
        });
      }
    });
  }

  componentDidMount = () => {
    this.selectValuesCreate();
    this.section_category();
  }

  selectValuesCreate = () => {
    if (this.props.editMode) {
      let map = new Map();
      let mapFormat = new Map();
      let mapParentFormatCategory = new Map();

      this.props.section_accions.forEach(element => {
        this.props.position.section_accions.forEach(element_accion => {
          if (element.id == element_accion.id) {
            map.set(element_accion.id.toString(), true)
          }
        });
      })

      this.props.position_format_categories_all.forEach(element => {
        this.props.position.format_categories.forEach(element_accion => {
          if (element.id == element_accion.id) {
            mapFormat.set(element_accion.id.toString(), true)
          }
        });
      })

      this.props.position_format_categories_all.forEach(element => {
        this.props.position.format_categories_fordatas.forEach(element_accion => {
          if (element.id == element_accion.id) {
            mapParentFormatCategory.set(element_accion.id.toString(), true)
          }
        });
      })

      this.setState({
        checkedItems: map,
        checkedItemFormData: mapFormat,
        checkedItemParentFormatCategory: mapParentFormatCategory,
      });
    }

    let arrayProcesos = []

    this.props.procesos.map((item) => (
      arrayProcesos.push({ label: `${item.name}`, value: item.id })
    ))

    this.setState({
      dataProcesos: arrayProcesos
    })
  }


  validationForm = () => {
    if (this.state.form.name != "") {
        this.setState({ ErrorValues: true })
        return true
    }else{
        this.setState({ ErrorValues: false })
        return false
    } 
  }


  selectValuesUpdate = () => {
    console.log("ñaosfjld")
    let arrayPositionEdit = []
    let arrayPositionIds = []

    this.props.position.procesos.map((item) => (
      arrayPositionEdit.push({ label: item.name, value: item.id }),
      arrayPositionIds.push(item.id)
    ))

      this.setState({
        editValues: arrayPositionEdit,
      })

    this.setState({
      form: {
        ...this.state.form,
        proceso_ids: arrayPositionIds
      }
    })

    console.log(this.state.editValues[0])
    console.log(this.state.editValues[1])
  }

  handleChangeAutocomplete = selectedOption => {
    let array = []

    selectedOption.map((item) => (
      array.push(item.value)
    ))

    this.setState({
      form: {
        ...this.state.form,
        proceso_ids: array
      }
    })
  };


  openModal(name) {
    if (name == "edit") {
      this.setState({
        modeEdit: false
      });
    } else {

      this.setState({
        title: "Nuevo rol",
        modeEdit: false,
        form: {
          name: "",
          description: "",
          section_accion_ids: [],
        }
      });
    }

    this.setState({
      modalIsOpen: true
    });
  }

  messageSuccess = (response) => {
    Swal.fire({
      position: "center",
      type: `${response.type}`,
      title: `${response.success}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  HandleClick = e => {
    if (this.state.modeEdit) {

      let section_accion_ids = [];
      this.state.checkedItems.forEach((value, key) => {
        if (value == true) {
          section_accion_ids.push(key);
        }
      });


      let format_category_ids = [];
      this.state.checkedItemFormData.forEach((value, key) => {
        if (value == true) {
          format_category_ids.push(key);
        }
      });
      
      let form = {};
      form = this.state.form
      form.section_accion_ids = section_accion_ids;
      form.format_category_ids = format_category_ids

      fetch(`/settings/positions/${this.state.id}`, {
        method: "PATCH", // or 'PUT'
        body: JSON.stringify(form), // data can be `string` or {object}!
        headers: {
          "X-CSRF-Token": this.token,
          "Content-Type": "application/json"
        }
      })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(response => {
          if (response.type != "error"){
              this.messageSuccess(response);
             // window.location.href = "/settings/positions";
          }else{
              this.messageSuccess(response);
          }
        });


    } else {

      if(this.validationForm() == true){

      let section_accion_ids = [];
      this.state.checkedItems.forEach((value, key) => {
        if (value == true) {
          section_accion_ids.push(key);
        }
      });

      let format_category_ids = [];
      this.state.checkedItemFormData.forEach((value, key) => {
        if (value == true) {
          format_category_ids.push(key);
        }
      });

      let form = {};
      form = this.state.form
      form.section_accion_ids = section_accion_ids;
      form.format_category_ids = format_category_ids;

      fetch("/settings/positions", {
        method: "POST", // or 'PUT'
        body: JSON.stringify(form), // data can be `string` or {object}!
        headers: {
          "X-CSRF-Token": this.token,
          "Content-Type": "application/json"
        }
      })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {

          if (data.type != "error"){
              this.messageSuccess(data);
              Turbolinks.visit( `/settings/positions/${data.register.token}/edit`);
    
              this.setState({
                modal: false,
                form: {
                  name: "",
                  description: "",
                },
    
                checkedItems: new Map()
              });
          }else{
              this.messageSuccess(data);
          }
        });
      }
    }
  };

  handleChangeAll = (e) => {
    const arrayTrue = new Array();

    if (e.target.checked) {

      console.log("true")
      
      const map = new Map();

      this.props.section_accions.forEach(element => {
        map.set(element.id.toString(), true)
        arrayTrue.push(element.id)
      })

      this.setState({
        checkedItems: map,
        form: {
          ...this.state.form,
          section_accion_ids: arrayTrue
        }
      })

    }else{
      const map = new Map();

      console.log("false")

      this.props.section_accions.forEach(element => {
        map.set(element.id.toString(), false)
      })

      this.setState({
        checkedItems: map,
        form: {
          ...this.state.form,
          section_accion_ids: []
        }
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="content main-card mb-3 card">
          <div className="row card-body">

            <div className={`col-md-4 ${isMobile ? "mb-3" : ""}`}>
              {true && (
                  <React.Fragment>
                    <label>Nombre</label>
                    <input
                      type="text"
                      name="name"
                      value={this.state.form.name}
                      onChange={this.handleChange}
                      disabled={this.props.editMode ? (this.props.position.name == "Sistema Basico" || this.props.position.name == "Administrador Sistema") : false}
                      className={`form form-control ${this.state.ErrorValues == false && this.state.form.name == "" ? "error-class" : ""}`}
                      autoComplete="off"
                      placeholder="Nombre"
                    />
                  </React.Fragment>
              )}
            </div>

            <div className={`col-md-4 ${isMobile ? "mb-3" : ""}`}>
              <label>Descripción <small className="validate-label">(opcional)</small></label>
              <input
                type="text"
                name="description"
                value={this.state.form.description}
                onChange={this.handleChange}
                className="form form-control"
                autoComplete="off"
                placeholder="Descripción"
              />
            </div>

            {this.state.editValues.length > 0  ? (
              <InputProcess 
                procesoIds={this.state.selectedOptionProcesos.proceso_ids} 
                handleChangeAutocomplete={this.handleChangeAutocomplete} 
                editValues={this.state.editValues} 
                dataProcesos={this.state.dataProcesos} 
              />
            ) : (
                <div className={`col-md-4 ${isMobile ? "mb-3" : ""}`}>
                  <input
                    type="hidden"
                    name="proceso_ids"
                    value={this.state.selectedOptionProcesos.proceso_ids}
                  />
                  <label>Procesos <small className="validate-label">(opcional)</small></label>
                  <Select
                    onChange={this.handleChangeAutocomplete}
                    isMulti
                    defaultValue={this.state.editValues}
                    closeMenuOnSelect={false}
                    name="proceso_ids"
                    options={this.state.dataProcesos}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Seleccione"
                  />

                </div>
            )}

            <div className="col-md-12 text-right mt-2">
              <div className="custom-control custom-switch">
                  <input type="checkbox" onChange={this.handleChangeAll} className="custom-control-input" id={`customSwitch`}/>
                  <label className="custom-control-label" htmlFor={`customSwitch`}>Habilitar todos los permisos</label>
              </div>
            </div>

          </div>

          <div className="card-footer">
              <a className="btn-transition btn btn-secondary-info" href="/settings/positions" data-turbolinks="true">Volver</a>
              <button className="btn-shadow  btn btn-info ml-3" onClick={this.HandleClick}>{this.state.modeEdit ? "Actualizar cargo" : "Crear cargo"}</button>
          </div>
        </div>

        {this.state.modeEdit && (
          <React.Fragment>
              <div className="alert alert-primary text-center" role="alert">
                <h4><b>Elige los permisos del cargo</b></h4>
              </div>

              {this.state.data.map(category => (
                <React.Fragment>
                  {category.sections.length >= 1 && (
                    <div key={category.id} className="content main-card mb-3">
                      <div className="card">
                        <div className="card-header header-position">
                            {category.name}
                        </div>

                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-md-12 mt-4">
                              <CheckboxContainer
                                checkedItems={this.state.checkedItems}
                                handleChangeAccions={this.handleChangeAccions}
                                checkboxes={this.state.checkboxes}
                                modules={category.sections}
                                tenant={this.props.tenant}

                                //formData
                                format_categories={this.props.format_categories}
                                checkedItemFormData={this.state.checkedItemFormData}
                                checkboxesFormData={this.state.checkboxesFormData}
                                handleChangeAccionFormat={this.handleChangeAccionFormat}
                                formats_permissions_all={this.props.formats_permissions_all}
                                permissions={this.props.permissions}
                                position={this.props.position}

                                section_custom_views={this.props.section_custom_views}
                                custom_view_permissions={this.props.custom_view_permissions}

                                checkedItemParentFormatCategory={this.state.checkedItemParentFormatCategory}
                                handleChangeAccionParentFormatCategory={this.handleChangeAccionParentFormatCategory}

                              />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </React.Fragment>
        )}


      </React.Fragment>
    );
  }
}


export default FormCreate;