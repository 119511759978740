import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import IconsCornerNew from '../../../../GeneralComponents/IconsCornerNew';

class Index extends Component {
    render() {
        const style = {
            fontSize: "11px"
        }

        const styleDiv = {
            paddingTop: "3px", 
            paddingBottom: "3px"
        }

        return (
            <React.Fragment>
                {this.props.data.length >= 1 ? (
                    this.props.data.map(document => (
                        <div className="card mb-3 mt-3" key={document.id}>
                            <div className="card-body p-2" >
                                <div className="row">
                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Versión</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.version}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Código</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.code}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Nombre del documento</b>
                                    </div>

                                    <div className="col-7 pr-0 pt-2" style={styleDiv}>
                                        <span className='mt-2' style={style}>
                                            {(this.props.isNewDocument(document.approve_date) || (this.props.isNewDocument(document.created_at))) && (
                                                <IconsCornerNew 
                                                    id={document.id}
                                                    messageTooltip={'Este documento es nuevo'}
                                                />
                                            )}
                                            {document.name}
                                            </span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Fecha de creación</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{this.props.getDateTime(document.created_at)}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Tipo de documento</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.document_type ? document.document_type.name : ""}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Proceso</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.proceso ? document.proceso.name : ""}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Crea</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.user_create.first_name}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Revisa</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.user_review.first_name}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Aprueba</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        <span style={style}>{document.user_approve.first_name}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-0 mb-0" />
                                    </div>

                                    <div className="col-5 pr-0" style={styleDiv}>
                                        <b style={style}>Opciones</b>
                                    </div>

                                    <div className="col-7 pr-0" style={styleDiv}>
                                        {(this.props.estados.edit || this.props.estados.delete) && (
                                            <UncontrolledDropdown className='btn-group'>
                                                <DropdownToggle className='btn btn-info'>
                                                    <i className="fas fa-bars"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                    {(this.props.estados.edit && document.state != "aprobado") &&(
                                                        <DropdownItem
                                                            href={`/document_management/documents/${document.token}`}
                                                            data-turbolinks="true"
                                                            className="dropdown-item"
                                                        >
                                                            Gestionar
                                                        </DropdownItem>
                                                    )}
                                                    {(document.state == "aprobado") &&(
                                                        <DropdownItem
                                                            href={`/document_management/documents/${document.token}`}
                                                            data-turbolinks="true"
                                                            className="dropdown-item"
                                                        >
                                                            Ver documento
                                                        </DropdownItem>
                                                    )}

                                                    {this.props.estados.edit && (
                                                        <DropdownItem
                                                            onClick={() => this.props.edit(document)}
                                                            className="dropdown-item"
                                                        >
                                                            Editar
                                                        </DropdownItem>
                                                    )}
                                                    {this.props.estados.generate && (
                                                        <DropdownItem
                                                            onClick={() => this.props.generateClone(document)}
                                                            className="dropdown-item"
                                                        >
                                                            Generar versión
                                                        </DropdownItem>
                                                    )}

                                                    {(document.state != "obsoleto" && document.state != "creando") && (
                                                        <DropdownItem
                                                            onClick={() => this.props.sendObsolete(document.id)}
                                                            className="dropdown-item"
                                                        >
                                                            Enviar a obsoletos
                                                        </DropdownItem>
                                                    )}

                                                    {document.state == "creando" || document.state == "obsoleto"  && (
                                                        <DropdownItem
                                                            onClick={() => this.props.delete(document.id)}
                                                            className="dropdown-item"
                                                         >
                                                            Eliminar
                                                        </DropdownItem>
                                                    )}

                                                    {(this.props.estados.edit_position || document.state == "aprobado" || document.state == "obsoleto") && (
                                                        <DropdownItem
                                                             onClick={() => this.props.editPosition(document)}
                                                            className="dropdown-item"
                                                        >
                                                            Editar acceso
                                                        </DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="card text-center">
                        <div className="text-center">
                            <div className="text-center mt-4 mb-4" style={{width: '100%'}}>
                                <p className='ui-indexWeb-messageNoRecord'>Todavía no hay registros creados, da clic en  <a onClick={() => this.props.toogle("new")}  className=" btn btn-info"><i className='fas fa-file-alt'></i> Nuevo documento</a> y genera el primer registro.</p>
                                <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/11/noRecord_global.jpg" alt="" className="ui-indexWeb-imgNoRecord" />
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Index;
