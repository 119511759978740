import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './CheckListItems/FormCreate';
import Preloader from '../../../GeneralComponents/LoadingComponen'
import { Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';

import moment from 'moment';

class ChekList extends Component {

    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            formCreateChecklistItems: {
                description: "", 
                date: isMobile ? moment().format('YYYY-MM-DD') : '',  
                user_owner_id: "", 
                check_list_id: "",
            },

            formUpdateCkeck: {
                name: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },
            
            check_list_id: "",
            check_list_item_id: "",
            check_list_item_check_id: "",
            users: [],
            value: "",
        }
    }

    HandleChangeChecklistItems = (e) => {
        this.setState({
            formCreateChecklistItems: {
                ...this.state.formCreateChecklistItems,
                [e.target.name]: e.target.value,
            }
        });
    }

    setValuesItems = (check_list) => {
        this.setState({
            formCreateChecklistItems: {
                ...this.state.formCreateChecklistItems,
                check_list_id: check_list.id
            }
        });
    }

    clearValuesItems = () => {
        this.setState({
            formCreateChecklistItems: {
                description: "", 
                date: isMobile ? moment().format('YYYY-MM-DD') : '',  
                user_owner_id: "", 
                check_list_id: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },
            
            check_list_item_id: "",
        })
    }

    HandleClickUpdate = () => {
        fetch(`/task/check_lists/${this.state.check_list_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateCkeck), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.props.updateItemChecklist(data.register)
            this.setState({ check_list_id: "" })
        });
    }

    delete = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/task/check_lists/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(response => response.json())
                .then(response => {
                    this.props.deleteDataCheckList(response.register.id);
                    if (this.props.deleteCheckLists != undefined ){
                        this.props.deleteCheckLists(response.register.task_label_id, response.register.id);
                    }
                    //this.props.loadData();
                    //this.props.loadCheckList();
                });
            }
        });
    };

    deleteItems = (check_list_id, check_list_item_id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/task/check_list_items/${check_list_item_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(response => response.json())
                .then(response => {
                    this.props.deleteCheckListItem(check_list_id, check_list_item_id)
                });
            }
        });
    };

    generaTask = (check_list_item) => {
        fetch(`/task/generate_task/${check_list_item.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.props.messageSuccess(data);
            this.props.loadData();
            //this.props.updateData(data.register)
        });
    }

    updateState = (e, id) => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        this.setState({ check_list_item_check_id: id })

        if (isChecked){

            fetch(`/task/update_state/${id}/${true}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then(data => {
                //this.props.updateItem(data.task)
                this.props.loadCheckList();
                this.props.loadData();
                this.setState({ check_list_item_check_id: "" })
            });

        }else{

            fetch(`/task/update_state/${id}/${false}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then(data => {
                //this.props.updateItem(data.task)
                this.props.loadData();
                this.props.loadCheckList();
                this.setState({ check_list_item_check_id: "" })
            });
        }
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            formCreateChecklistItems: {
                ...this.state.formCreateChecklistItems,
                user_owner_id: selectedOption.value
            }
        });
    };

    edit = (item) => {
        this.setState({
            formCreateChecklistItems: {
                description: item.description, 
                date: item.date,  
                user_owner_id: item.user_owner_id, 
            },

            selectedOption: {
                user_owner_id: `${item.user_owner_id}`,
                label: `${item.user_owner != null ? item.user_owner.first_name : ""} ${item.user_owner != null ? item.user_owner.first_last_name : ""}`
            },

            check_list_item_id: item.id,
        })
    }

    editCheckList = (check_list) => {
        this.setState({
            check_list_id: check_list.id,
            formUpdateCkeck: {
                ...this.state.formUpdateCkeck,
                name: check_list.name
            }
        })
    }

    HandleChangeUpdateCkeck = (e) => {
        this.setState({
            formUpdateCkeck: {
                ...this.state.formUpdateCkeck,
                [e.target.name]: e.target.value
            }
        })
    }

    componentDidMount = () => {
        this.setDefaultValues()
    }

    setDefaultValues = () => {
        let array = []

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            users: array,
        })
    }

    getStateShow = (items) => {
        if(items.date != null && items.user_owner != null){
            return true
        }else{
            return false
        }
    }

    update_position = (e, check_list_item, check_list_id) => {
        fetch(`/task/update_position/${check_list_id}/${check_list_item.id}/${e.target.value}/${check_list_item.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadCheckList();
        });
    }

    notificationItem = (check_list_item) => {
        fetch(`/task/notification_check_list_item/${check_list_item.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.messageSuccess(data);
        });
    }
    

    render() {
        return (
            <div className="col-md-12 mt-4">
                <div className="row">

            
                    {this.props.dataChecklist.map((check, index) => (
                        <React.Fragment>
                                <div className='col-md-1 text-center'>
                        <i
                            className="fas fa-tasks"
                            style={{ fontSize: "22px" }}
                        >
                        </i>
                    </div>

                            <div className={`col-md-11 ${index > 0 ? "mt-3" : ""}`}>
                                <div className="card">
                                    <div className="card-header">

                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-10 pl-0">
                                                    {check.id == this.state.check_list_id ? (
                                                        <input 
                                                            type="text"
                                                            className="form form-control"
                                                            name="name"
                                                            onChange={this.HandleChangeUpdateCkeck}
                                                            value={this.state.formUpdateCkeck.name}
                                                        />
                                                    ) : (
                                                        <React.Fragment>
                                                            {check.name}                                                                                    
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                                
                                                <div className={`col-md-2 text-right ${check.id == this.state.check_list_id ? "pl-0 mt-1" : ""}`}>
                                                    {true && (
                                                        <React.Fragment>
                                                            {check.id == this.state.check_list_id ? (
                                                                <React.Fragment>
                                                                    <i className="fas fa-check-circle update-section" onClick={() => this.HandleClickUpdate()}></i> 
                                                                    <i className="fas fa-times-circle update-false" onClick={() => this.setState({ check_list_id: "" })}></i>
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <UncontrolledDropdown className='btn-group'>
                                                                        <DropdownToggle className='btn-light ui-checList-btnOption-top' style={{border: 'none', background: 'none'}}>
                                                                            <i className="fa fa-ellipsis-h"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.editCheckList(check)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {true && (  
                                                                                <DropdownItem
                                                                                    onClick={() => this.delete(check.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>   
                                                    )}
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className="card-body">
                                        {this.props.showFields && (
                                            <div className={`progress ${check.check_list_items.length >= 1 ? "mb-3" : "" }`} style={{    height: "21px"}}>
                                                <div className="progress-bar" role="progressbar" style={{ width: `${(check.check_list_items.filter(items => items.state == true).length * 100 ) / check.check_list_items.length}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                <b className="icon-count ui-checkList-iconCount">{check.check_list_items.filter(items => items.state == true).length} / {check.check_list_items.length}</b>
                                            </div>
                                        )}

                                        {check.check_list_items.map((items, index) => (
                                            <div className={`card ${index > 0 ? "mt-3" : ""}`} style={{ bordeRadius: "0px", background: items.state ? "#e9ecef" : "" }}>
                                                <div className="card-body" style={{ padding: "0px" }}>
                                                        {items.id == this.state.check_list_item_id ? (
                                                            <React.Fragment>
                                                                <FormCreate
                                                                    HandleChangeChecklistItems={this.HandleChangeChecklistItems}
                                                                    formCreateChecklistItems={this.state.formCreateChecklistItems}
                                                                    clearValuesItems={this.clearValuesItems}
                                                                    loadCheckList={this.props.loadCheckList}
                                                                    updateItem={this.props.updateItem}
                                                                    id={this.state.check_list_item_id}
                                                                    modeEdit={true}
                                                                    showFields={this.props.showFields}
                                                                    loadData={this.props.loadData}

                                                                    //select options 

                                                                    selectedOption={this.state.selectedOption}
                                                                    handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                                    users={this.props.users}

                                                                    //update data
                                                                    updateDataItems={this.props.updateDataItems}
                                                                    updateCheckListItem={this.props.updateCheckListItem}
                                                                />
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <div className="row">
                                                                    <div className="col-md-2 pr-0 ui-checkbox_input__container">
                                                                        {this.props.showFields && (
                                                                            <React.Fragment>
                                                                                {(this.state.check_list_item_check_id == items.id && this.state.check_list_item_check_id != "")  ? (
                                                                                    <React.Fragment>
                                                                                        <div style={{ textAlign: "center", marginTop: 6 }}>
                                                                                            <Spinner color="primary" size="sm" />
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <input 
                                                                                        type="checkbox"
                                                                                        className="form form-control mt-1 ck-no-out-line"
                                                                                        onChange={(e) => this.updateState(e, items.id) }
                                                                                        checked={items.state}
                                                                                        style={{ height: "18px"}}
                                                                                    />
                                                                                )}
                                                                                
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>

                                                                    <div className="col-md-8 ui-checkList-nameCheck__container">
                                                                        <p className="mb-0 ui-checkList-nameCheck" style={{ textDecoration:  items.state ? "line-through" : "inherit" }}>{items.position} - {items.description} </p>
                                                                    </div>

                                                                    <div className="col-md-2 text-right ui-checkList-dropdown__container">
                                                                        {true && (
                                                                            <UncontrolledDropdown className='btn-group'>
                                                                                <DropdownToggle className='btn-light' style={{border: 'none', background: 'none'}}>
                                                                                    <i className="fa fa-ellipsis-h" style={{color: '#17a3f2'}}></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                                    {this.getStateShow(items) && (
                                                                                        <DropdownItem
                                                                                            onClick={() => this.generaTask(items)}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            Generar como tarea
                                                                                        </DropdownItem>
                                                                                    )}

                                                                                    {true && (
                                                                                        <DropdownItem
                                                                                            onClick={() => this.notificationItem(items)}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            Notificar al usuario
                                                                                        </DropdownItem>
                                                                                    )}

                                                                                    {true && (
                                                                                        <DropdownItem
                                                                                            onClick={() => this.edit(items)}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            Editar
                                                                                        </DropdownItem>
                                                                                    )}
                                            
                                                                                    {true && (  
                                                                                        <DropdownItem
                                                                                            onClick={() => this.deleteItems(check.id, items.id)}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            Eliminar
                                                                                        </DropdownItem>
                                                                                    )}

                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        )}
                                                                    </div>
                                                                    
                                                                    {true && (
                                                                        <div className="col-md-12 ui-checkList-line-separations__container">
                                                                            <hr className="mb-0 mt-0"/>
                                                                        </div>      
                                                                    )}

                                                                    <div className="col-md-2 pr-0 ui-checkList-line__conatiner"></div>

                                                                    {this.props.showFields && (
                                                                        <React.Fragment>
                                                                            <div className="col-md-9 p-0 pt-1 pb-1 ui-checkList-iconCheck-info" style={{color:"gray",fontSize:"12px"}}>
                                                                                {true && (
                                                                                    <React.Fragment>
                                                                                        <i className="far fa-calendar-alt"></i> <span className="mr-3">{items.date}</span>
                                                                                        <i className="fas fa-user"></i>   <span>{ items.user_owner != undefined ? items.user_owner.first_name : ""} {items.user_owner != undefined ?  items.user_owner.first_last_name: ""}</span>
                                                                                        <select
                                                                                            className="float-right"
                                                                                            style={{ border: "initial", background:"none" , cursor:"pointer" }}
                                                                                            value={this.state.value}
                                                                                            onChange={(e) => this.update_position(e, items, check.id)}
                                                                                        >
                                                                                            <option value=""></option>
                                                                                            {check.check_list_items.map((item, index) => (
                                                                                                <option value={item.position}>{item.position}</option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </div> 
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>                                                          
                                                            </React.Fragment>
                                                        )}
                                                </div>
                                            </div>
                                        ))}

                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                {check.id == this.state.formCreateChecklistItems.check_list_id ? (
                                                    <React.Fragment>
                                                        <FormCreate
                                                            HandleChangeChecklistItems={this.HandleChangeChecklistItems}
                                                            formCreateChecklistItems={this.state.formCreateChecklistItems}
                                                            clearValuesItems={this.clearValuesItems}
                                                            loadCheckList={this.props.loadCheckList}
                                                            updateItem={this.props.updateItem}
                                                            modeEdit={false}
                                                            showFields={this.props.showFields}
                                                            loadData={this.props.loadData}

                                                            //select options 

                                                            selectedOption={this.state.selectedOption}
                                                            handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                            users={this.props.users}

                                                            //update data
                                                            updateDataItems={this.props.updateDataItems}
                                                            updateCheckListItem={this.props.updateCheckListItem}
                                                        />
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {this.props.task != undefined ? (
                                                            <React.Fragment>
                                                                {true && (
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="link-line"
                                                                        onClick={() => this.setValuesItems(check)}
                                                                    >
                                                                        Nuevo elemento
                                                                    </a>     
                                                                )} 
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                {true && (
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="link-line"
                                                                        onClick={() => this.setValuesItems(check)}
                                                                    >
                                                                        Nuevo elemento
                                                                    </a>     
                                                                )}                                                                               
                                                            </React.Fragment>
                                                        )}                                                                              
                                                    </React.Fragment>
                                                )}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    }
}

export default ChekList;