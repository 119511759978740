import React, { Component } from 'react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"
import Table from '../components/configurationRisk/Table'

class ConfigurationShow extends Component {
    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.headerTitleName}`}
                    subTitle={"Configuración"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a 
                                href={"/risk/activities"}
                                data-turbolinks="true"
                            >
                                {"Actividad de riesgos"}
                            </a>
                        </li>
                    </ol>
                </nav>

                <Table
                    data={this.props.data} 
                    module={this.props.module}
                />
            </React.Fragment>
        );
    }
}

export default ConfigurationShow;
WebpackerReact.setup({ ConfigurationShow });