import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import MobileView from '../components/Beetree/pages/MobileView';
import HeaderMenu from '../components/Beetree/pages/HeaderMenu';
import { UncontrolledTooltip } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import ModalSelectIcon from '../components/Beetree/Link/ModalSelectIcon';
import ModalFormatSelect from '../components/Beetree/Link/ModalFormatSelect';
import ModalDocumentSelect from '../components/Beetree/Link/ModalDocumentSelect';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const PageShow = (props) => {
    const [data, setData] = useState([]);
    const [form, setForm] = useState({ page_id: props.page.id, title_link: "", url: ""});
    const [formUpdate, setFormUpdate] = useState({ id: "", type_field: "", text: "" });
    const [formCreateFormat, setFormCreateFormat] = useState({ id: "", title_link: "", url: "" });

    const [formUpdateIcon, setFormUpdateIcon] = useState({ id: "", icon_id: "" });
    const [selectedOption, setSelectedOption] = useState({ format_id: "", label: "Seleccione el formato" });
    const [selectedOptionDocument, setSelectedOptionDocument] = useState({ document_id: "", label: "Seleccione el documento" });

    const [modal, setModal] = useState(false);

    const [modalSelectFormat, setModalSelectFormat] = useState(false);
    const [modalSelectDocument, setModalSelectDocument] = useState(false);

    const [items, setItems] = useState([]);

    const [isLoaded, setIsLoaded] = useState(true);
    const [loadView, setLoadView] = useState(false);
    const token = document.querySelector("[name='csrf-token']").content;

    const [isErrorShown, setIsErrorShown] = useState(false);
    const [isRequestSent, setIsRequestSent] = useState(false);

    const fetchData = async () => {
        try {
          // Realiza la solicitud POST
          await fetch('https://www.youtube.com/youtubei/v1/log_event?alt=json&key=...')
            .then(response => response.json())
            .then(data => {
              // Maneja los datos de respuesta según tus necesidades
              console.log(data);
            });
        } catch (error) {
          // Muestra el mensaje de error solo si no se ha mostrado antes
          if (!isErrorShown) {
            console.error(error);
            setIsErrorShown(true);
          }
        }
    };
  
    useEffect(() => {
        const fetchDataOnce = async () => {
            await fetchData();
        };
      
        fetchDataOnce();
        loadData();
    }, []);

    const updateItem = (link) => {
        setData(data.map(item => {
          if (item.id === link.id) {
            return { ...item, 
              icon_link: link.icon_link, 
              label_link: link.label_link,
              button_style: link.button_style, 
              title_link: link.title_link, 
              url: link.url,
              active: link.active,
              icon: link.icon,
            }
          }
          return item;
        }));
    }

    const updateData = (new_item) => {
        setData([...data, new_item].reverse())
    }

    const loadData = () => {
        fetch(`/beetree/get_links/${props.page.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data)
        });
    }

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const addNewLink = () => {
        fetch(`/beetree/links`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            updateData(data.register);
        });
    }

    const deleteLink = (link_id) => {
        setLoadView(false);

        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/beetree/links/${link_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    setLoadView(true);
                    setData(data.filter(item => item.id != link_id))
                });
            }
        });
    };

    const handleChangeStateLink = (link) => {
        if(link.title_link && isUrl(link.url)){
            const formUpdateActive = {
                active: !link.active
            }

            setLoadView(false);

            fetch(`/beetree/links/${link.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(formUpdateActive), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                updateItem(data.register);
                setLoadView(true);
            });
        }else{

        }
    }

    const handleClickEditInput = (type_field, link) => {
        setFormUpdate({ id: link.id, type_field: type_field, text: type_field == "url" ? link.url : link.title_link })
    }

    const handleChangeInput = (e) => {
        const inputValue = e.target.value;
        const inputName = e.target.name;

        // Verificar si el input es el de URL del video
        const isUrlInput = inputName === "url";

        // Verificar si es un código de video de YouTube válido
        const youtubePattern = new RegExp('^([A-Za-z0-9_-]{11})$');
        const isValidYouTubeCode = youtubePattern.test(inputValue);

        let updatedValue = inputValue;

        if (isUrlInput && isValidYouTubeCode) {
            // Si el input es el de URL del video y es un código de video de YouTube válido
            // Agregar la URL base y el código de video
            const youtubeUrl = 'https://www.youtube.com/embed/' + inputValue;
            updatedValue = youtubeUrl;
        }

        // Actualizar el estado del formulario según el input modificado
        if (isUrlInput) {
            setFormUpdate((prevFormUpdate) => ({ ...prevFormUpdate, text: updatedValue }));
        } else {
            setFormUpdate({ ...formUpdate, text: e.target.value })
        }
    }

    const toogle = (from, link) => {
        if (from == "new") {
            setModal(true);
            setFormUpdateIcon({ id: link.id, icon_id: "" })
        } else {
            setModal(false);
            setFormUpdateIcon({ id: "", icon_id: "" })
        }
    }

    const toogleSelectFormat = (from) => {
        if (from == "new") {
            setModalSelectFormat(true);
            setFormCreateFormat({ id: "", title_link: "", url: "" });
        } else {
            setModalSelectFormat(false);
            setFormCreateFormat({ id: "", title_link: "", url: "" });
        }
    }

    const toogleSelectDocument = (from) => {
        if (from == "new") {
            setModalSelectDocument(true);
            setFormCreateFormat({ id: "", title_link: "", url: "" });
        } else {
            setModalSelectDocument(false);
            setFormCreateFormat({ id: "", title_link: "", url: "" });
        }
    }

    const editFormat = (modal, link_id) => {
        if (modal == "format"){
            setModalSelectFormat(true);
        }else{
            setModalSelectDocument(true);
        }
        
        setFormCreateFormat({ id: link_id, title_link: "", url: "" });
        setSelectedOptionDocument({ document_id: "", label: "Seleccione el documento" })
    }

    const handleChangeAutocompleteDocument = (selectedOptionDocument) => {
        setIsLoaded(false);

        fetch(`/document_management/get_document_url/${selectedOptionDocument.value}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            updateDocumentLink(data.url)
        });
    }

    const updateDocumentLink = (url) => {
        const formCreateLink = {
            id: formCreateFormat.id,
            url: url
        }

        fetch(`/beetree/links/${formCreateFormat.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(formCreateLink), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            updateItem(data.register);
            setIsLoaded(true);
            setModalSelectDocument(false);
            setSelectedOptionDocument(selectedOptionDocument)
        });
    }

    const handleChangeAutocomplete = (selectedOption) => {
        if(formCreateFormat.id){
            setLoadView(false);

            const formCreateLink = {
                id: formCreateFormat.id,
                title_link: selectedOption.label,
                url: `${props.current_url}/${selectedOption.value}`
            }

            fetch(`/beetree/links/${formCreateFormat.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(formCreateLink), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json"
                }
            })
    
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                updateItem(data.register);
                setLoadView(true);
                setModalSelectFormat(false);
                setFormCreateFormat({ id: "", title_link: "", url: "" });
            });
        }else{
            setLoadView(false);

            const formCreateLink = {
                page_id: props.page.id,
                active: true,
                title_link: selectedOption.label,
                url: `${props.current_url}/${selectedOption.value}`
            }


            fetch(`/beetree/links`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(formCreateLink), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json"
                }
            })
    
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                updateData(data.register);
                setLoadView(true);
                setModalSelectFormat(false);
                setFormCreateFormat({ id: "", title_link: "", url: "" });
            });
        }
    }

    const setIconForm = (icon_id) => {
        console.log("setIconFormsetIconFormsetIconForm", icon_id);

        const updateValues = {
            id: formUpdateIcon.id,
            icon_id: icon_id,
        } 

        setLoadView(false);

        fetch(`/beetree/links/${updateValues.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(updateValues), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            updateItem(data.register);
            setLoadView(true);
            setModal(false);
            setFormUpdateIcon({ id: "", icon_id: "" })
        });
    }

    const hadleClickUpdate = (link) => {
        const updateValues = {
            [formUpdate.type_field]: formUpdate.text,
            active: (formUpdate.type_field == "url" ? isUrl(formUpdate.text) : link.active),
        }
        
        setLoadView(false);

        fetch(`/beetree/links/${formUpdate.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(updateValues), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            updateItem(data.register);
            setLoadView(formUpdate.type_field == "url" ? isUrl(formUpdate.text) : link.active);
            setFormUpdate({ id: "", type_field: "", text: "" });
        });
    }

    const isUrl = (str) => {
        var pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$','i' // fragment locator
        );
      
        if (pattern.test(str)) {
          // Si es una URL válida
          return true;
        } else {
          // Si no es una URL válida, intentar verificar si es un código de video de YouTube
          var youtubePattern = new RegExp('^([A-Za-z0-9_-]{11})$');
          if (youtubePattern.test(str)) {
            // Si es un código de video de YouTube válido
            // Agregar la URL base y el código de video
            var youtubeUrl = 'https://www.youtube.com/embed/' + str;
            // Guardar la URL completa o realizar cualquier otra acción necesaria
            console.log('URL de YouTube válida:', youtubeUrl);
            return true;
          } else {
            // No es una URL válida ni un código de video de YouTube válido
            return false;
          }
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };

    const onDragEnd = result => {
        const { source, destination } = result;
    
        if (!destination) {
            return;
        }

        const itemsTest = reorder(
            data,
            source.index,
            destination.index
        );

        setData(itemsTest);
        updatePosition(itemsTest);
    };

    const updatePosition = (new_array) => {
        let array = []

        new_array.map((item) => (
            array.push(item.id)
        ))

        const updateValues = {
            link_ids: array
        }
        
        setLoadView(false);

        fetch(`/beetree/update_position_links`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(updateValues), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            setLoadView(true);
        });
    }

    const cancelEdit = () => {
        setFormUpdate({ id: "", type_field: "", text: "" });
        setFormCreateFormat({ id: "", title_link: "", url: "" });
    }

    return (
        <React.Fragment>
            {isErrorShown && <p style={{display: 'none'}}>Ocurrió un error al cargar el contenido.</p>}
            <HeaderMenu
                current_page={"links"}
                page={props.page}
            />

            {modal && (
                <ModalSelectIcon
                    backdrop={"static"}
                    modal={modal}
                    toggle={toogle}
                    title={"Seleccionar icon"}

                    formValues={formUpdateIcon}
                    setIconForm={setIconForm}
                    icons={props.icons}
                />
            )}

            {modalSelectFormat && (
                <ModalFormatSelect
                    backdrop={"static"}
                    modal={modalSelectFormat}
                    toggle={toogleSelectFormat}
                    title={"Seleccionar icon"}

                    handleChangeAutocomplete={handleChangeAutocomplete}
                    selectedOption={selectedOption}
                    formats={props.formats}
                />
            )}

            {modalSelectDocument && (
                <ModalDocumentSelect
                    backdrop={"static"}
                    modal={modalSelectDocument}
                    toggle={toogleSelectDocument}
                    title={"Seleccionar icon"}
                    documents={props.documents}

                    handleChangeAutocompleteDocument={handleChangeAutocompleteDocument}
                    selectedOptionDocument={selectedOptionDocument}
                    isLoaded={isLoaded}
                />
            )}

            <div className="ui-container-betreeLinks">
                <div className="card ui-btree-card-linkContainer">
                    <div className="card-header">
                        <button type="button" onClick={() => addNewLink()} className="btn-shadow btn btn-info ui-btree-link-btnNewPage mr-2">
                            <i className="fas fa-link"></i> Crear Nuevo Enlace
                        </button>

                        <div className="ui-btree-card-link-name-page">
                            <h6>Beetree / <a href={`/beetree/pages/`}>{props.page.page_name}</a></h6>
                        </div>

                 {/*        <button type="button" onClick={() => toogleSelectFormat("new")} className="btn-shadow btn btn-info ui-btree-link-btnNewPage">
                            <i className="fas fa-link"></i> Crear link con formato
                        </button> */}
                    </div>

                    <div className="card-body p-3 ui-btree-link-card-body">
                        {data.length >= 1 ? 
                            <DragDropContext onDragEnd={onDragEnd}>
                                <div className='ui-btree-linkContainer'>
                                    <div className="ui-btree-link-itemContainer">
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}  
                                                    style={{ height: "100%" }}  
                                                >
                                                    {data.map((link, index) => (
                                                        <Draggable
                                                            key={link.id}
                                                            draggableId={`${link.id}`}
                                                            index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <div className={`ui-btree-linkcard ${index > 0 ? "mt-3" : ""}`} key={link.id}>
                                                                        <div className="ui-btree-link-cardContainer">
                                                                            <div className="ui-btree-card-selectMove">
                                                                                <a><i className="fas fa-ellipsis-v"></i></a>
                                                                            </div>

                                                                            <div className="ui-btree-iconSelect-userLink">
                                                                                {link.icon ? <i className={link.icon.html_name}></i> : <i className="fab fa-reddit"></i>}
                                                                                <UncontrolledTooltip target='bw-btree-icon_Icons'>{'CAMBIAR ICONO'}</UncontrolledTooltip>
                                                                                <div className="ui-btree-icon-editIcon" onClick={() => toogle("new", link)} id='bw-btree-icon_Icons'>
                                                                                    <i className="fas fa-pen"></i>
                                                                                </div>
                                                                            </div>

                                                                            <div className="ui-btree-cardLink-title_url">
                                                                                <div className="ui-btree-cardLink-title">
                                                                                    {(formUpdate.id == link.id && formUpdate.type_field == "title_link") ? (
                                                                                        <input type="text" name="title_link" className="ui-input-url" onChange={handleChangeInput} value={formUpdate.text} onBlur={() => hadleClickUpdate(link)} style={{ width: "100%" }} />
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            <div className='bw-btree-name-edit-container'>
                                                                                                <UncontrolledTooltip target='bw-btree-link-edit-icon'>{'EDITAR NOMBRE DEL ENLACE'}</UncontrolledTooltip>
                                                                                                <p><i className="fas fa-pen" onClick={() => handleClickEditInput("title_link", link)} id='bw-btree-link-edit-icon'></i>
                                                                                                    {link.title_link ? link.title_link : "Nombre del link"}</p>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>

                                                                                <div className="ui-btree-link-icons-options">
                                                                                    <div className='ui-btree-icons-options-rigth__container'>
                                                                                        <UncontrolledTooltip target='bw-btree-icon-format' className='ui-btree-tooltip'>{'ELEGIR URL DESDE UN FORMATO EN BEEWO'}</UncontrolledTooltip>
                                                                                        <i className="fas fa-file-import" id='bw-btree-icon-format' onClick={() => editFormat("format", link.id)} style={{fontSize: '18px'}}></i>
                                                                                    </div>

                                                                                    <div className='ui-btree-icons-options-rigth__container'>
                                                                                        <UncontrolledTooltip target='bw-btree-icon-document'>{'ELEGIR URL DESDE LOS DOCUMENTOS'}</UncontrolledTooltip>
                                                                                        <i className="fas fa-file-invoice" id='bw-btree-icon-document' onClick={() => editFormat("document", link.id)} style={{fontSize: '18px'}}></i>
                                                                                    </div>

                                                                                    <UncontrolledTooltip target='bw-btree-linl-switch-check' className='ui-btree-tooltip'>{'ACTIVAR / DESACTIVAR ENLACE'}</UncontrolledTooltip>
                                                                                    <div className="custom-control custom-switch ui-btree-icons-options-rigth__container" id='bw-btree-linl-switch-check'>
                                                                                        <input type="checkbox" onChange={(e) => handleChangeStateLink(link)} className="custom-control-input" id={`customCheckbox${link.id}`} checked={link.active} />
                                                                                        <label className="custom-control-label" htmlFor={`customCheckbox${link.id}`}></label>
                                                                                    </div>

                                                                                    
                                                                                    {(formUpdate.id == link.id) && (
                                                                                        <React.Fragment>
                                                                                            <div className='ui-btree-icons-options-rigth__container'>
                                                                                                <UncontrolledTooltip target='bw-btree-icon-cancel-edit'>{'CANCELAR EDICION'}</UncontrolledTooltip>
                                                                                                <i className="fas fa-times" id='bw-btree-icon-cancel-edit' onClick={() => cancelEdit()} style={{ color: "red", marginRight: '50px' }}></i>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    <div className="ui-btree-iconDelete-link ui-btree-icons-options-rigth__container">
                                                                                        <UncontrolledTooltip target='bw-btree-link-delete-icon' className='ui-btree-tooltip'>{'Eliminar Enlace'}</UncontrolledTooltip>
                                                                                        <a className="btn ui-btree-link-icon-delete" id='bw-btree-link-delete-icon'><i className="far fa-trash-alt" onClick={() => deleteLink(link.id)}></i></a>
                                                                                    </div>

                                                                                </div>                                                                                
                                                                            </div>
                                                                        </div>
                                                                                <div className='ui-btree-card-url'>
                                                                                    {(formUpdate.id == link.id && formUpdate.type_field == "url") ? (
                                                                                        <input type="text" name="url" className="ui-input-url" onChange={handleChangeInput} value={formUpdate.text} onBlur={() => hadleClickUpdate(link)} style={{ width: "100%" }} />
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            {isUrl(link.url) ? (
                                                                                                <React.Fragment>
                                                                                                    <UncontrolledTooltip target='bw-btree-link-edit-icon-url' className='ui-btree-tooltip'>{'EDITAR URL DEL ENLACE'}</UncontrolledTooltip>
                                                                                                    <i className="fas fa-pen mr-2" onClick={() => handleClickEditInput("url", link)} id='bw-btree-link-edit-icon-url' style={{color: '#919191'}}></i>
                                                                                                    <a href={link.url} target="_blank">
                                                                                                        {link.url ? link.url : "URL"} </a> 
                                                                                                </React.Fragment>
                                                                                                
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <UncontrolledTooltip target='bw-btree-link-edit-icon-url_2' className='ui-btree-tooltip'>{'EDITAR URL DEL ENLACE'}</UncontrolledTooltip>
                                                                                                    <i className="fas fa-pen mr-2" id='bw-btree-link-edit-icon-url_2' onClick={() => handleClickEditInput("url", link)} style={{color: '#919191'}}></i>
                                                                                                    <a 
                                                                                                        href={""} 
                                                                                                        className={"ui-error-url"}
                                                                                                    >
                                                                                                        {link.url ? link.url : "URL"} 
                                                                                                    </a> 
                                                                                                    <UncontrolledTooltip target='bw-btree-icon-error' className='ui-btree-tooltip'>{'URL INVÁLIDA O CÓDIGO DE YOUTUBE INVALIDO ELIMINA watch?v='}</UncontrolledTooltip>
                                                                                                    <i className="fas fa-info-circle ml-2" id='bw-btree-icon-error' style={{ color: "red" }}></i>

                                                                                                </React.Fragment>
                                                                                            )} 
                                                                                        </React.Fragment>
                                                                                    )} 
                                                                                </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                </div>
                            </DragDropContext>
                        : 
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/5029/No-hay_enlaces_img-f.png" alt="No hay enlaces creados aún" />
                        </div>
                        }
                    </div>
                </div>
                <div className="ui-link-complement"></div>
            </div>

            <MobileView 
                page_id={props.page.id}
                loadView={loadView}
            />
        </React.Fragment>
    );
}

export default PageShow;
WebpackerReact.setup({ PageShow });