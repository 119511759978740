import React, { useState, useEffect } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

const FormCreate = (props) => {

    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Nombre</label>
                                <input 
                                    type="text" 
                                    name='name' 
                                    className={`form form-control`}
                                    value={props.formValues.name}
                                    onChange={props.onChangeForm} 
                                />
                            </div>

                            <div className="col-md-12 mt-3">
                                <label>Planes</label>
                                <input
                                    type="hidden"
                                    name="plan_ids"
                                    value={props.selectedOption.plan_ids}
                                />

                                <Select
                                    onChange={props.handleChangeAutocompleteMulti}
                                    options={props.plans}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    autoFocus={false}
                                    className={`link-form`}
                                    classNamePrefix="select"
                                    placeholder="Seleccione"
                                    name="plan_ids"
                                    defaultValue={props.editValues}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                        <button className="btn-shadow btn btn-info" onClick={props.submitForm}>{props.nameBnt}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </React.Fragment>
      )

}
export default FormCreate;