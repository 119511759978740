import React, { useState, useEffect } from 'react';
import WebpackerReact from 'webpacker-react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import FormCreate from '../components/formatos/TrsDriverReviewConfiguration/FormCreate';
import ModalEditTrsDriverConfiguration from '../components/formatos/TrsDriverReviewConfiguration/TrsDriverConfiguration/ModalEdit';
import ModalEditTrsExternalReportConfiguration from '../components/formatos/TrsDriverReviewConfiguration/TrsExternalReportConfiguration/ModalEdit';
import ModalEditTrsInternalReportConfiguration from '../components/formatos/TrsDriverReviewConfiguration/TrsInternalReportConfiguration/ModalEdit';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledCollapse } from 'reactstrap';

const TrsDriverReviewConfigurationIndex = (props) => {
    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = document.querySelector("[name='csrf-token']").content;
    const [id, setId] = useState("");
    const [form, setForm] = useState({ tenant_id: "", format_external_report_id: "", format_internal_report_id: "", format_personal_id: "" });
    const [formats, setFormats] = useState([]);
    const [trs_driver_review_configuration, setTrsDriverReviewConfiguration] = useState({});

    //modal state
    const [modal, setModal] = useState(false);
    const [modalEditTrsDriverConfiguration, setModalEditTrsDriverConfiguration] = useState(false);
    const [modalEditTrsExternalReportConfiguration, setModalEditTrsExternalReportConfiguration] = useState(false);
    const [modalEditTrsInternalReportConfiguration, setModalEditTrsInternalReportConfiguration] = useState(false);
    const [modeEdit, setModeEdit] = useState(false);

    //select values 
    const [selectedOption, setSelectedOption] = useState({ label: "Empresa", tenant_id: "" });
    const [selectedOptionFormatExternal, setSelectedOptionFormatExternal] = useState({ label: "Formato externo", format_external_report_id: "" });
    const [selectedOptionFormatInternal, setSelectedOptionFormatInternal] = useState({ label: "Formato interno", format_internal_report_id: "" });
    const [selectedOptionFormatPersonal, setSelectedOptionFormatPersonal] = useState({ label: "Formato personal", format_personal_id: "" });


    const [errorValues, setErrorValues] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const updateItem = (trs_driver_review_configuration) => {
        setData(data.map(item => {
            if (item.id === trs_driver_review_configuration.id) {
                return {
                    ...item,
                    format_external_report: trs_driver_review_configuration.format_external_report,
                    format_internal_report: trs_driver_review_configuration.format_internal_report,
                    format_personal: trs_driver_review_configuration.format_personal,
                    tenant: trs_driver_review_configuration.tenant
                }
            }
            return item;
        }));
    }

    const clearValues = () => {
        setModeEdit(false);
        setErrorValues(true);
        setFormats([]);
        setForm({ tenant_id: "", format_external_report_id: "", format_internal_report_id: "", format_personal_id: "" })
        setSelectedOption({ label: "Empresa", tenant_id: "" })
        setSelectedOptionFormatExternal({ label: "Formato externo", format_external_report_id: "" })
        setSelectedOptionFormatInternal({ label: "Formato interno", format_internal_report_id: "" })
        setSelectedOptionFormatPersonal({ label: "Formato personal", format_personal_id: "" })
    }

    const updateData = (new_item) => {
        setData([...data, new_item])
    }

    const toogle = (from) => {
        clearValues();
        if (from == "new") {
            setModal(true);
        } else {
            setModal(false);
        }
    }

    const toogleEditTrsDriverConfiguration = () => {
        setModalEditTrsDriverConfiguration(false)
        setTrsDriverReviewConfiguration({})
    }

    const toogleEditTrsExternalReportConfiguration = () => {
        setModalEditTrsExternalReportConfiguration(false)
        setTrsDriverReviewConfiguration({})
    }

    const toogleEditTrsInternalReportConfiguration = () => {
        setModalEditTrsInternalReportConfiguration(false)
        setTrsDriverReviewConfiguration({})
    }

    const handleChangeAutocomplete = selectedOption => {
        setSelectedOption(selectedOption)
        setForm({ ...form, tenant_id: selectedOption.value })
        loadFormats(selectedOption.value)
    }

    const handleChangeAutocompleteFormatExternal = selectedOptionFormatExternal => {
        setSelectedOptionFormatExternal(selectedOptionFormatExternal)
        setForm({ ...form, format_external_report_id: selectedOptionFormatExternal.value })
    }

    const handleChangeAutocompleteFormatInternal = selectedOptionFormatInternal => {
        setSelectedOptionFormatInternal(selectedOptionFormatInternal)
        setForm({ ...form, format_internal_report_id: selectedOptionFormatInternal.value })
    }

    const handleChangeAutocompleteFormatPersonal = selectedOptionFormatPersonal => {
        setSelectedOptionFormatPersonal(selectedOptionFormatPersonal)
        setForm({ ...form, format_personal_id: selectedOptionFormatPersonal.value })
    }

    const loadFormats = (tenant_id) => {
        fetch(`/formatos/get_tenant_formats/${tenant_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                setFormats(data.data)
            });
    }

    const validationForm = () => {
        if (form.tenant_id != "" &&
            form.format_external_report_id != "" &&
            form.format_internal_report_id != "" &&
            form.format_personal_id != ""
        ) {
            setErrorValues(true)
            return true
        } else {
            setErrorValues(false)
            return false
        }
    }

    const handleClick = () => {
        if (validationForm()) {
            if (!modeEdit)
                fetch(`/formatos/trs_driver_review_configurations`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        if (data.type == "success") {
                            messageSuccess(data);
                            setModal(false);
                            updateData(data.register);
                            clearValues();
                        } else {
                            messageSuccess(data);
                            clearValues();
                        }
                    });
            else {
                fetch(`/formatos/trs_driver_review_configurations/${id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        if (data.type == "success") {
                            messageSuccess(data);
                            setModal(false);
                            updateItem(data.register);
                            clearValues();
                        } else {
                            messageSuccess(data);
                            clearValues();
                        }
                    });
            }
        }
    }

    const destroy = (trs_driver_review_configuration_id) => {
        Swal.fire({
            title: 'Estas seguro?',
            text: "El registro sera eliminado para siempre!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.value) {
                fetch(`/formatos/trs_driver_review_configurations/${trs_driver_review_configuration_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        messageSuccess(response);
                        loadData()
                    });
            }
        })
    };

    const loadData = () => {
        fetch(`/formatos/get_trs_driver_review_configurations`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                setIsLoaded(false);
                setData(data.data);
            });
    }

    const edit = (trs_driver_review_configuration) => {
        setModal(true);
        setModeEdit(true);
        setId(trs_driver_review_configuration.id);
        setForm({ tenant_id: trs_driver_review_configuration.tenant.value, format_external_report_id: trs_driver_review_configuration.format_external_report.value, format_internal_report_id: trs_driver_review_configuration.format_internal_report.value, format_personal_id: trs_driver_review_configuration.format_personal.value });
        setSelectedOption(trs_driver_review_configuration.tenant);
        setSelectedOptionFormatExternal(trs_driver_review_configuration.format_external_report);
        setSelectedOptionFormatInternal(trs_driver_review_configuration.format_internal_report);
        setSelectedOptionFormatPersonal(trs_driver_review_configuration.format_personal);
        loadFormats(trs_driver_review_configuration.tenant.value);
    }

    const openTrsDriverConfiguration = (trs_driver_review_configuration) => {
        setModalEditTrsDriverConfiguration(true);
        setTrsDriverReviewConfiguration(trs_driver_review_configuration);
    }

    const openTrsExternalReportConfiguration = (trs_driver_review_configuration) => {
        setModalEditTrsExternalReportConfiguration(true);
        setTrsDriverReviewConfiguration(trs_driver_review_configuration);
    }

    const openTrsInternalReportConfiguration = (trs_driver_review_configuration) => {
        setModalEditTrsInternalReportConfiguration(true);
        setTrsDriverReviewConfiguration(trs_driver_review_configuration);
    }

    return (
        <React.Fragment>
            <HeaderTitle
                title={`Central de Riesgos`}
                subTitle={"Central de Riesgos"}
                titleNew={"Nuevo"}
                titleFilter={"Filtros"}
                icon="fas fa-toolbox"
                onClickNew={toogle}
                showNew={true}
                showFilter={false}
            />

            {modal && (
                <FormCreate
                    //modal props
                    backdrop={"static"}
                    modal={modal}
                    toggle={toogle}
                    title={"Añadir subcausas"}
                    nameBnt={modeEdit ? "Actualizar" : "Crear"}
                    errorValues={errorValues}
                    formValues={form}
                    handleClick={handleClick}

                    //select props
                    selectedOption={selectedOption}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                    tenants={props.tenants}


                    handleChangeAutocompleteFormatExternal={handleChangeAutocompleteFormatExternal}
                    selectedOptionFormatExternal={selectedOptionFormatExternal}

                    handleChangeAutocompleteFormatInternal={handleChangeAutocompleteFormatInternal}
                    selectedOptionFormatInternal={selectedOptionFormatInternal}

                    handleChangeAutocompleteFormatPersonal={handleChangeAutocompleteFormatPersonal}
                    selectedOptionFormatPersonal={selectedOptionFormatPersonal}
                    formats={formats}
                />
            )}

            {modalEditTrsDriverConfiguration && (
                <ModalEditTrsDriverConfiguration
                    //modal props
                    backdrop={"static"}
                    modal={modalEditTrsDriverConfiguration}
                    toggle={toogleEditTrsDriverConfiguration}
                    trs_driver_review_configuration={trs_driver_review_configuration}
                    messageSuccess={messageSuccess}
                />
            )}

            {modalEditTrsExternalReportConfiguration && (
                <ModalEditTrsExternalReportConfiguration
                    //modal props
                    backdrop={"static"}
                    modal={modalEditTrsExternalReportConfiguration}
                    toggle={toogleEditTrsExternalReportConfiguration}
                    trs_driver_review_configuration={trs_driver_review_configuration}
                    messageSuccess={messageSuccess}
                />
            )}

            {modalEditTrsInternalReportConfiguration && (
                <ModalEditTrsInternalReportConfiguration
                    //modal props
                    backdrop={"static"}
                    modal={modalEditTrsInternalReportConfiguration}
                    toggle={toogleEditTrsInternalReportConfiguration}
                    trs_driver_review_configuration={trs_driver_review_configuration}
                    messageSuccess={messageSuccess}
                />
            )}

            {!isLoaded ? (
                <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable">
                            <thead>
                                <tr className="tr-title">
                                    <th>Empresa</th>
                                    <th>Formato externo</th>
                                    <th>Formato interno</th>
                                    <th>Formato personal</th>
                                    <th>Conductores consulta</th>
                                    <th>Reportes</th>
                                    <th>PQRS</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.length >= 1 ? (
                                    data.map(trs_driver_review_configuration => (
                                        <tr key={trs_driver_review_configuration.id}>
                                            <td>{trs_driver_review_configuration.tenant.label}</td>
                                            <td>{trs_driver_review_configuration.format_external_report.label}</td>
                                            <td>{trs_driver_review_configuration.format_internal_report.label}</td>
                                            <td>{trs_driver_review_configuration.format_personal.label}</td>
                                            <td><a href="javascript:void(0)" onClick={() => openTrsDriverConfiguration(trs_driver_review_configuration)}>Editar información</a></td>
                                            <td><a href="javascript:void(0)" onClick={() => openTrsInternalReportConfiguration(trs_driver_review_configuration)}>Editar información</a></td>
                                            <td><a href="javascript:void(0)" onClick={() => openTrsExternalReportConfiguration(trs_driver_review_configuration)}>Editar información</a></td>
                                            {(true || true) && (
                                                <td className="text-right" style={{ width: "10px" }}>
                                                    <UncontrolledDropdown className='btn-group'>
                                                        <DropdownToggle className='btn btn-info'>
                                                            <i className="fas fa-bars"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                            {true && (
                                                                <DropdownItem
                                                                    onClick={() => edit(trs_driver_review_configuration)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Editar
                                                                </DropdownItem>
                                                            )}

                                                            {true && (
                                                                <DropdownItem
                                                                    onClick={() => destroy(trs_driver_review_configuration.id)}
                                                                    className="dropdown-item"
                                                                >
                                                                    Eliminar
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <td colSpan="13" className="text-center">
                                        <div className="text-center mt-4 mb-4">
                                            <h4>No hay registros</h4>
                                        </div>
                                    </td>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className="card">
                    <div className="card-body">
                        <p>Cargando informacion...</p>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default TrsDriverReviewConfigurationIndex;
WebpackerReact.setup({ TrsDriverReviewConfigurationIndex });