import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from "../../../GeneralComponents/LoadingComponen"
import { isMobile } from 'react-device-detect';
import Dropzone from "react-dropzone";

class IndexAttached extends Component {
    constructor(props){
        super(props)
        this.state = {
            ErrorValues: true,
            loadCreate: false,
            validUrl: false,
            sizeFile: 0,
            nameFile: "",
            formValues: {
                fileName: this.props.editFileState ? this.props.valuesFiles.fileName : "",
                file: {},
                url: this.props.editFileState ? this.props.valuesFiles.url : "",
                type_file: (this.props.company.permit_upload_file ? "archivo" : "url"),
                module_file_id: "",
                modulo: this.props.moduleName,
            }
        }
    }

    validationForm = () => {
        if (this.state.formValues.type_file == "url"){

            if (this.state.formValues.fileName != "" &&  
                this.state.formValues.url != ""
            ) {
                this.setState({ ErrorValues: true })
                return true
            }else{
                this.setState({ ErrorValues: false })
                return false
            }

        }else{
            if (this.state.formValues.fileName != "" &&  
                Object.keys(this.state.formValues.file).length != 0 
            ) {
                this.setState({ ErrorValues: true })
                return true
            }else{
                this.setState({ ErrorValues: false })
                return false
            }
        }
    }

    HandleChange = (e) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                fileName: e.target.value,
                module_file_id: this.props.task.module_file_id
            }
        });
    }

    passwordConfirmationValidate = () => {
        var regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        if (regex.test(this.state.form.password_confirmation)){
            this.setState({password_confir: true})
        }else{
            this.setState({password_confir: false})
        }         
    }


    HandleChangeUrl = (e) => {
        var regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        if (regex.test(e.target.value)){
            this.setState({ validUrl: true })
        }else{
            this.setState({ validUrl: false })
        } 

        this.setState({
            formValues: {
                ...this.state.formValues,
                url: e.target.value
            }
        });
    }

    HandleChangeFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formValues: {
                    ...this.state.formValues,
                    file: file.size >= 11485760 ? "" : file
                },
  
                nameFile: file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
                sizeFile: file.size,
                ErrorValues: true,
            })
        ));
    }

    clearValues = () => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                fileName: "",
                file: {}
            }
        });
    }

    clearValuesFile = (file) => {
        if(file == "url"){
            this.setState({
                formValues: {
                    ...this.state.formValues,
                    fileName: "",
                    type_file: (this.props.company.permit_upload_file ? "archivo" : "url"),
                    file: {},
                },

                nameFile: "",
                sizeFile: 0,
                validUrl: false,
                ErrorValues: true,
            });
        }else{
            this.setState({
                formValues: {
                    ...this.state.formValues,
                    url: this.props.editFileState ? this.props.valuesFiles.url : "",
                    file: {},
                    type_file: "url"
                },

                validUrl: false,
                nameFile: "",
                sizeFile: 0,
                ErrorValues: true,
            });
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleClickFile = () => {
        if(this.validationForm() && this.state.sizeFile <= 11485760){
            const formData = new FormData();
            formData.append("fileName", this.state.formValues.fileName)
            formData.append("file", this.state.formValues.file)
            formData.append("module_file_id", this.props.task.module_file_id)
            formData.append("modulo", this.state.formValues.modulo)
            formData.append("url", this.state.formValues.url)
            formData.append("type_file", this.state.formValues.type_file)

            this.setState({ loadCreate: true  })

            if(this.props.editFileState){    
                fetch(`/settings/general_files/${this.props.valuesFiles.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.messageSuccess(data);
                    this.setState({ loadCreate: false })
                    this.props.closePopoverIndexAttached();
                    this.props.updateItemFiles(data.register);
                    if (this.props.updateItem != undefined) {
                        //this.props.updateItem(data.task)
                        this.props.loadData();
                    }
                });
            }else{
                fetch(`/settings/general_files`, {
                    method: 'POST', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.messageSuccess(data);
                    this.setState({ loadCreate: false })
                    this.props.closePopovers();
                    if (this.props.updateItem != undefined) {
                        //this.props.updateItem(data.task)
                        this.props.loadData();
                        this.props.updateDataFiles(data.register)
                    }else{
                        this.props.updateDataFiles(data.register)
                    }
                });
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    {this.state.loadCreate ? (
                        <div className="col-md-12 text-center" style={{ width: (isMobile ? "" : "289px"), height: "124px"}}>
                            <Preloader />
                            <p>Subiendo archivo..</p>
                        </div>
                    ) : (
                        <React.Fragment>

                            <div className="col-md-12" style={{ width: (isMobile ? "" : "289px") }}>
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    name="fileName"
                                    value={this.state.formValues.fileName}
                                    onChange={this.HandleChange}
                                    className={`form form-control`}
                                    placeholder="Nombre"
                                />
                            </div>

                            <div className="col-md-12 mt-3">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    {this.props.company.permit_upload_file && (
                                        <li className={`nav-item`} onClick={() => this.clearValuesFile("url") }>
                                            <a className={`nav-link ${this.props.editFileState ? (this.props.valuesFiles.type_file == "archivo" || this.props.valuesFiles.type_file == "" ? "active" : "") : "show active"}`} id="home-tab" data-toggle="tab" href="#home-file" role="tab" aria-controls="home" aria-selected="true">Subir archivo</a>
                                        </li>
                                    )}

                                    <li className={`nav-item`} onClick={() => this.clearValuesFile("file") }>
                                        <a className={`nav-link ${this.props.editFileState ? (this.props.valuesFiles.type_file == "url" ? "active show" : "") : (!this.props.company.permit_upload_file ? "active show" : "") }`} id="profile-tab" data-toggle="tab" href="#profile-file" role="tab" aria-controls="profile" aria-selected="false">Url</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab-content" id="myTabContent">


                                {this.props.company.permit_upload_file && (
                                    <div className={`tab-pane fade ${this.props.editFileState ? (this.props.valuesFiles.type_file == "archivo" || this.props.valuesFiles.type_file == "" ? "show active" : "") : "show active"}`} id="home-file" role="tabpanel" aria-labelledby="home-tab">
                                        <div className="col-md-12">
                                            <Dropzone onDrop={(files) => this.HandleChangeFile(files)}>
                                                {({getRootProps, getInputProps}) => (
                                                    <div
                                                        {...getRootProps({
                                                            className: 'dropzone',
                                                        })}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <p>{`${(this.state.nameFile != "" ? this.state.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                )}

                                <div className={`tab-pane fade ${this.props.editFileState ? (this.props.valuesFiles.type_file == "url" ? "show active" : "") : (!this.props.company.permit_upload_file ? "show active" : "")}`} id="profile-file" role="tabpanel" aria-labelledby="profile-tab" style={{ width: "126%" }}>
                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="fileName"
                                            value={this.state.formValues.url}
                                            onChange={this.HandleChangeUrl}
                                            className={`form form-control`}
                                            placeholder="Url"
                                        />

                                        {false && (
                                            <label className="invalid-url">Url inválida</label>
                                        )}
                                    </div>

                                </div>
                            </div>

                            {!this.state.ErrorValues &&(
                                <div className="col-md-12 mt-3 text-center">
                                    <div className="alert alert-danger" role="alert">
                                        <b>Añade un nombre y {this.state.formValues.type_file == "archivo" ? "un archivo" : "una url" }</b>
                                    </div>
                                </div>
                            )}

                            {this.state.sizeFile >= 11485760 && (
                                <div className="col-md-12 mt-3 text-center">
                                    <div className="alert alert-info mb-0" role="alert">
                                        <b>Solo puede subir archivos de máximo 10 MB</b>
                                    </div>
                                </div>
                            )}

                            <div className="col-md-12">
                                <hr/>
                            </div>
                            
                            <div className="col-md-12 text-center">
                                <button
                                    className="btn-shadow btn btn-light btn-block mr-3"
                                    style={{ color: "#1ba8fa"}}
                                    disabled={this.state.sizeFile >= 11485760}
                                    onClick={() => this.HandleClickFile()}
                                >
                                    {this.props.editFileState ? (this.state.formValues.type_file == "archivo" ? "Actualizar archivo" : "Actualizar link") : (this.state.formValues.type_file == "archivo" ? "Agregar archivo" : "Agregar link")} 
                                </button>
                            </div>
                        </React.Fragment>
                    )}

                </div>
            </React.Fragment>
        );
    }
}

export default IndexAttached;
