import React, { Component } from 'react';
import Table from '../components/documentManagement/DocumentType/Index'
import FormFilter from '../components/documentManagement/DocumentType/FormFilter';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"
import Preloader from '../GeneralComponents/LoadingComponen'

class DocumentTypeIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            data: [],
            isLoaded: true,
            showFilter: false,

            formFilter: {
                name: "",
                prefix: "",
                code: "",
                has_process: "",
                content_format_id: "",
                header: "",
                footer: "",
            },

            selectedOption: {
                content_format_id: "",
                label: "Formato"
            },

            formats: []
        }
    }

    componentDidMount(){
        this.loadData();
        this.configSelect();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    configSelect = () => {
        let arrayFormats = [];

        this.props.formats.map((item) => (
            arrayFormats.push({label: `${item.name}`, value: item.id})
        ))

        this.setState({
            formats: arrayFormats,
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
            if (format.id === item.id) {
              return { ...item, 
                content_format: format.content_format,
                footer: format.footer,
                header: format.header,
                name: format.name,
                prefix: format.prefix,
                code: format.code,
                has_process: format.has_process,
                has_header: format.has_header, 
                has_footer: format.has_footer,
                is_format: format.is_format,
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/document_management/get_document_types`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            isLoaded: false
          });
        });
    }


    HandleClickFilter = e => {
        this.setState({ isLoaded: true, isFiltering: true })
        fetch(`/document_management/get_document_types?cost_center_id=${this.state.formFilter.cost_center_id}&user_invoice_id=${this.state.formFilter.user_invoice_id}&invoice_name=${this.state.formFilter.invoice_name}&invoice_date=${this.state.formFilter.invoice_date}&type_identification=${this.state.formFilter.type_identification}&description=${this.state.formFilter.description}&invoice_number=${this.state.formFilter.invoice_number}&invoice_type=${this.state.formFilter.invoice_type}&payment_type=${this.state.formFilter.payment_type}&invoice_value=${this.state.formFilter.invoice_value}&invoice_tax=${this.state.formFilter.invoice_tax}&invoice_total=${this.state.formFilter.invoice_total}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
          .then(response => response.json())
          .then(data => {
            this.setState({
              data: data.data,
              isLoaded: false,
            });
        });
    };

    clearValues = () => {
        this.setState({
            formFilter: {
                name: "",
                prefix: "",
                code: "",
                has_process: "",
                content_format_id: "",
                header: "",
                footer: "",
            },

            selectedOption: {
                content_format_id: "",
                label: "Proceso"
            },
        })
    }

    filter = (state) => {
        this.setState({ showFilter: state })
        if (!state){
            this.loadData();
            this.clearValues();
        }
    }

    HandleChange = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
                formFilter: {
                    ...this.state.formFilter,
                    content_format_id: selectedOption.value
                }
        });
    };


    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Tipos de documentos`}
                    subTitle={"Gestión documental"}
                    titleNew={"Nuevo documento"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={() => this.child.current.toogle("new")}
                    showNew={this.props.estados.create}
                    showFilter={false}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.showFilter && (
                    <FormFilter
                        HandleClickFilter={this.HandleClickFilter}
                        formValues={this.state.formFilter}
                        onChangeForm={this.HandleChange}
                        filter={this.filter}

                        //select values

                        //select PositionCreate
                        selectedOption={this.state.selectedOption}
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                    />
                )}

                {!this.state.isLoaded ? (
                    <Table
                        ref={this.child}
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        isLoaded={this.state.isLoaded}
                        updateItem={this.updateItem}
                        updateData={this.updateData}
                        estados={this.props.estados}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                        formats={this.state.formats}
                        procesos={this.props.procesos}
                    />
                ) : (
                    <div className="card">
                        <div className="card-body">
                            <Preloader />
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}


export default DocumentTypeIndex;
WebpackerReact.setup({ DocumentTypeIndex });

