import React, { Component } from 'react';
import Select from "react-select";
import { UncontrolledTooltip } from 'reactstrap';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Form extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            ErrorValues: true,
        }
    }

    validationForm = () => {
        if (this.props.form.name != "" &&  
            this.props.form.due_date != "" && 
            this.props.form.user_owner_id != "" &&
            this.props.form.task_board_id != "" &&
            this.props.form.column_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    messageSuccess = (response) => {
        if(response.type != "error"){
            Swal.fire({
                position: "center",
                type: `${response.type}`,
                title: `${response.success}`,
                showConfirmButton: false,
                timer: 1500,
            });
        }else{
            Swal.fire({
                position: "center",
                type: `${response.type}`,
                title: `${response.success}`,
                showConfirmButton: true,
            });
        }
    };

    HandleClick = () => {
        if(this.validationForm()){
            fetch(`/task/tasks`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.props.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                if(data.type != "error"){
                    this.messageSuccess(data);
                    this.props.clearValues();
                    this.props.updateData(data.task);
                }else{
                    this.messageSuccess(data);
                    this.props.clearValues();
                }
            });
        }
    }

    render() {     
        return (
            <React.Fragment>
                <div className="card mb-3" >
                    <div className="card-body p-2">
                        <div className="row">

                            <div className="col-md-12 mb-4" >
                                <input
                                    type="hidden"
                                    name="user_owner_id"
                                    value={this.props.selectedOption.user_owner_id}
                                />

                                <Select
                                    onChange={this.props.handleChangeAutocomplete}
                                    options={this.props.users}
                                    autoFocus={false}
                                    className={`link-form ${!this.state.ErrorValues && this.props.form.user_owner_id == "" ? "error-class" : ""}`}
                                    value={this.props.selectedOption}
                                />
                            </div>

                            <div className="col-md-12 mb-4" >
                                <input
                                    type="hidden"
                                    name="task_board_id"
                                    value={this.props.selectedOption.task_board_id}
                                />

                                <Select
                                    onChange={this.props.handleChangeAutocompleteTaskBoard}
                                    options={this.props.task_boards}
                                    autoFocus={false}
                                    className={`link-form ${!this.state.ErrorValues && this.props.form.task_board_id == "" ? "error-class" : ""}`}
                                    value={this.props.selectedOptionTaskBoard}
                                />
                            </div>

                            {this.props.columns.length >= 1 && (
                                <div className="col-md-12 mb-4" >
                                    <input
                                        type="hidden"
                                        name="column_id"
                                        value={this.props.selectedOptionColumn.column_id}
                                    />

                                    <Select
                                        onChange={this.props.handleChangeAutocompleteColumn}
                                        options={this.props.columns}
                                        autoFocus={false}
                                        className={`link-form ${!this.state.ErrorValues && this.props.form.column_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionColumn}
                                        placeholder="Seleccionar columna"
                                    />
                                </div>
                            )}

                            <div className="col-md-12 mb-4">
                                <input 
                                    type="date"
                                    name="due_date"
                                    onChange={this.props.HandleChange}
                                    value={this.props.form.due_date}
                                    className={`form form-control ${!this.state.ErrorValues && this.props.form.due_date == "" ? "error-class" : ""}`}
                                />
                            </div>
                            
                            <div className="col-md-12">
                                <textarea 
                                    className={`form form-control style-text ${!this.state.ErrorValues && this.props.form.name == "" ? "error-class" : ""}`}
                                    name="name"
                                    onChange={this.props.HandleChange}
                                    value={this.props.form.name}
                                    placeholder="Introduzca un título para esta tarjeta..."
                                >
                                </textarea>
                                
                            </div>
                            
                            <div className="col-md-12 mt-2 mb-2">
                                <div className="ui-form-task-notifications_words_responsables__container">
                                    <div className="ui-form-task-notifications__container_responsables">
                                        <div className="custom-control custom-switch">
                                            <UncontrolledTooltip target='bw-notification-responsable_task' placement='top'>NOTIFICAR AL RESPONSABLE</UncontrolledTooltip>
                                            <input type="checkbox" onChange={(e) => this.props.handleChangeSwitch(!this.props.form.notify, "notify")} className="custom-control-input" id={`customSwitch${1}`} checked={this.props.form.notify} />
                                            <label className="custom-control-label" htmlFor={`customSwitch${1}`}><i className="fas fa-bell" id='bw-notification-responsable_task'></i></label>
                                        </div>
                                    </div>

                                    <div className="ui-form-task-notifications_alert__container">
                                        <span className="ui-form-taskCard-count-length" style={{ color: (this.props.form.name.length + 0 >= 200 ? "red" : "#3f6ad8") }}>
                                            Caracteres restantes: {200 - this.props.form.name.length}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {this.props.form.name.length >= 200 && (
                                <div className="col-md-12 mt-3">
                                    <div className="alert alert-primary" role="alert">
                                        <b>Si quieres ampliar la información debes hacerlo en la descripción</b>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>

                    <div className="card-footer">
                        <button 
                            className="btn-shadow btn btn-primary mr-3" 
                            onClick={() => this.HandleClick()}
                        >
                            {!this.state.ErrorValues ? "Complete los campos" : "Crear" }
                        </button>
                        <button className="btn btn-outline-danger" style={{color: 'white'}} onClick={() => this.props.clearValues()}>Cancelar</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Form;
