import React from "react";
import { isMobile } from "react-device-detect";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton,AccordionItemPanel, } from 'react-accessible-accordion';

class CheckboxContainer extends React.Component {
  render() {
    return (
      (isMobile? (
        <Accordion allowZeroExpanded>
          {this.props.modules.filter((e) => this.props.position ? (e.position.id == this.props.position.id) : e.position.id != "").map(modulex => (
            <AccordionItem key={modulex.id}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className='ui-accordion-titleContainer'>
                    <div className='ui-accordion-title'>
                      {modulex.position.name}
                    </div>
                    <div className='ui-accordion-icon'></div>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="ui-containerAll-accordion">
                <div className="ui-checkboxContainer-modalFormatCategories-mobilecontainerTitles">
                        
                        <div className="ui-checkboxContainer-modalFormatCategories-mobiletitle">Ingresar</div>
                        <div className="ui-checkboxContainer-modalFormatCategories-mobiletitle">Compartir</div>
                        <div className="ui-checkboxContainer-modalFormatCategories-mobiletitle">Importar</div>

                        <div className="ui-checkboxContainer-modalFormatCategories-mobiletitle">Exportar</div>
                        <div className="ui-checkboxContainer-modalFormatCategories-mobiletitle">Descargar plantilla</div>
                        <div className="ui-checkboxContainer-modalFormatCategories-mobiletitle">Crear</div>

                        <div className="ui-checkboxContainer-modalFormatCategories-mobiletitle">Editar</div>
                        <div className="ui-checkboxContainer-modalFormatCategories-mobiletitle">Eliminar</div>
                        <div className="ui-checkboxContainer-modalFormatCategories-mobiletitle">Todos</div>
                      
                    </div>

                    <div className="ui-checkboxContainer-modalFormatCategories-mobilecontainerChecks">
                    {modulex.custom_view_permissions.map((item, index) => (
                      <React.Fragment>
                        <div key={item.id} className="ui-checkboxContainer-modalFormatCategories-mobileCheck">
                          <input
                            // style={{ marginRight: "10px" }}
                            type="checkbox"
                            value={item.id}
                            checked={this.props.checkedItems.get(item.id.toString())}
                            onChange={(e) => this.props.handleChangeAccions(e)}
                          />
                        </div>
                        {modulex.custom_view_permissions.length == index + 1 && (
                          <React.Fragment>
                            <div className="ui-checkboxContainer-modalFormatCategories-mobileCheck">
                              
                                <input
                                  style={{ marginRight: "10px" }}
                                  type="checkbox"
                                  checked={this.props.modulo_ids.includes(modulex.id)}
                                  onChange={(e) => this.props.handleChangeSelectAll(e, modulex)}
                                />
                            
                          </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                    </div>

                </div>
              </AccordionItemPanel>
            </AccordionItem>          
            ))}
        </Accordion>
      ) : (
      <div className="ui-checkboxContainer-modalFormatCategories-scrollTable">
      <React.Fragment>
        <tr>
              <th className="ui-checkboxContainer-modalFormatCategories-titles ui-fixed-column">Cargo</th>
              <th className="ui-checkboxContainer-modalFormatCategories-titles">Ingresar</th>
              <th className="ui-checkboxContainer-modalFormatCategories-titles">Compartir</th>
              <th className="ui-checkboxContainer-modalFormatCategories-titles">Importar</th>
              <th className="ui-checkboxContainer-modalFormatCategories-titles">Exportar</th>
              <th className="ui-checkboxContainer-modalFormatCategories-titles">Descargar plantilla</th>
              <th className="ui-checkboxContainer-modalFormatCategories-titles">Crear</th>
              <th className="ui-checkboxContainer-modalFormatCategories-titles">Editar</th>
              <th className="ui-checkboxContainer-modalFormatCategories-titles">Eliminar</th>
              <th className="ui-checkboxContainer-modalFormatCategories-titles">Todos</th>
          </tr>
          {this.props.modules.filter((e) => this.props.position ? (e.position.id == this.props.position.id) : e.position.id != "").map(modulex => (
            <tr key={modulex.id} className="ui-checkboxContainer-modalFormatCategories-trContainercheck">
                <td>{modulex.position.name}</td>
                {modulex.custom_view_permissions.map((item, index) => (
                  <React.Fragment>
                    <td>
                      <label key={item.id}>
                      <input
                          style={{ marginRight: "10px" }}
                          type="checkbox"
                          value={item.id}
                          checked={this.props.checkedItems.get(item.id.toString())}
                          onChange={(e) => this.props.handleChangeAccions(e)}
                        />
                      </label>
                    </td>
                    {modulex.custom_view_permissions.length == index + 1 && (
                      <td>
                          <label>
                            <input
                              style={{ marginRight: "10px" }}
                              type="checkbox"
                              checked={this.props.modulo_ids.includes(modulex.id)}
                              onChange={(e) => this.props.handleChangeSelectAll(e, modulex)}
                            />
                          </label>
                      </td>
                    )}
                  </React.Fragment>
                ))}
            </tr>
          ))}
      </React.Fragment>
    </div>
    ))
    )}
}

export default CheckboxContainer;

{/* <div className="row">
          <div className="col-md-12 form-checks" style={{ marginBottom: "10px" }}>
            <div className="form-checks-descriptions"></div>
            <div className="form-checks-titles">Ingresar</div>
            <div className="form-checks-titles">Compartir</div>
            <div className="form-checks-titles">Importar</div>
            <div className="form-checks-titles">Exportar</div>

            <div className="form-checks-titles">Descargar plantilla</div>
            <div className="form-checks-titles">Crear</div>
            <div className="form-checks-titles">Editar</div>
            <div className="form-checks-titles">Eliminar</div>
            <div className="form-checks-titles">Todos</div>
            
            
          </div>
          {this.props.modules.filter((e) => this.props.position ? (e.position_id == this.props.position.id) : e.position_id != "").map(modulex => (
            <div className="col-md-12 form-checks" style={{ marginBottom: "10px" }}>
              <h5 className="form-checks-descriptions" key={modulex.id}>{modulex.name} {/*<input type="checkbox" className="float-right" onChange={(e) => this.props.handleChangeAll(e, this.props.modules)}/>*/}
              
              // {modulex.custom_view_permissions.map((item, index) => (
              //   <React.Fragment>
              //     <div className="form-checks-titles">
              //       <label key={item.id}>
              //         <input
              //           style={{ marginRight: "10px" }}
              //           type="checkbox"
              //           value={item.id}
              //           checked={this.props.checkedItems.get(item.id.toString())}
              //           onChange={(e) => this.props.handleChangeAccions(e)}
              //         />
              //       </label>
              //     </div>

                  {/* {modulex.custom_view_permissions.length == index + 1 && (
                    <div className="form-checks-titles">
                        <label>
                          <input
                            style={{ marginRight: "10px" }}
                            type="checkbox"
                            checked={this.props.modulo_ids.includes(modulex.id)}
                            onChange={(e) => this.props.handleChangeSelectAll(e, modulex)}
                          />
                        </label>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        </div> */}
