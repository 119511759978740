import React from 'react';
import { isMobile } from 'react-device-detect';
import { UncontrolledTooltip } from 'reactstrap';

const IconsCornerNew = (props) => {
    return (
        <>
            {!isMobile && (
                <UncontrolledTooltip target={`bwisM${props.id}`} placement='top'>
                    {props.messageTooltip}
                </UncontrolledTooltip>
            )}
            <svg width="211" height="211" viewBox="0 0 211 211" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ui-iconsCornet-new_img-svg`} id={`bwisM${props.id}`}>
                <g clip-path="url(#clip0_228_100)">
                    <path d="M87.92 175.83H0V211H87.92V175.83Z" fill="black"/>
                    <path d="M131.44 106.38L106.38 131.44L44.3999 69.0099L69.0099 44.3999L131.44 106.38Z" fill="black"/>
                    <path d="M211 0H175.83V87.92H211V0Z" fill="black"/>
                </g>
                <defs>
                    <clipPath id="clip0_228_100">
                        <rect width="211" height="211" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default IconsCornerNew;