import React, { Component } from 'react';
import { SwatchesPicker } from 'react-color';

class FormCreate extends Component {
    render() {
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-md-12 mb-2">
                        <input
                            type="text"
                            name="name"
                            value={this.props.formValues.name}
                            onChange={this.props.onChangeForm}
                            className={`form form-control`}
                            placeholder="Título"
                        />
                    </div>

                            
                    <div className="col-md-12 mb-4">
                        <SwatchesPicker
                            className="w-100"
                            color={ this.props.formValues.color }
                            onChangeComplete={ this.props.handleChangeComplete }
                        />
                    </div>

                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-md-4 pr-0">

                            </div>

                            <div className="col-md-8">
                                <a className="btn-column float-right" style={{ background: "#3d67d3", color: "white" }} onClick={this.props.submitForm}>{this.props.nameSubmit}</a>
                                <a className="float-right btn btn-outline-danger btn-column mr-2" onClick={() => this.props.toogle()}>Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default FormCreate;


/*
{this.props.data.map((column) => {
                                            <option value={this.props.formValues.position}>{column.position}</option>
                                        })}

*/