import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect} from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import ReactHtmlParser from 'react-html-parser';

import ModalShowFile from '../components/settings/SoftwareChanges/ModalShowFiles';

const SoftwareChangeIndex = (props) => {

    const [modalFile, setModalFile] = useState(false);
    const [files, setFiles] = useState({});
    const [typeFile, setTypeFile] = useState({})
    
   const toogleModalFile = (from, link, type) => {
        if (from == "new") {
            setModalFile(true);
            setFiles(link);
            setTypeFile(type)
        } else {
            setModalFile(false);
            setFiles({});
            setTypeFile({});
        }
    }
    const typeChangeIcon = (type_change, id) => {
        if(type_change === 'funcionalidad'){
            return (
                <React.Fragment>
                    <UncontrolledTooltip target={`fun${id}`} placement='left'>{'Funcionalidad'}</UncontrolledTooltip>
                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/54998/computer.png" alt="Nueva Funcionalidad" id={`fun${id}`} />    
                </React.Fragment>
            );
        } else if(type_change === 'mejoras') {
            return (
                <React.Fragment>
                    <UncontrolledTooltip target={`imp${id}`} placement='left'>{'Mejora'}</UncontrolledTooltip>
                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/54999/exchange.png" alt="Mejora" id={`imp${id}`} />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <UncontrolledTooltip target={`imp${id}`} placement='left'>{'Anuncio'}</UncontrolledTooltip>
                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/321833/megafono.png" alt="Mejora" id={`imp${id}`} />
                </React.Fragment>
            );
        }
    }

    const typeChangeName = (type_change) => {
        if(type_change === 'funcionalidad'){
            return 'Funcionalidad';
        } else if(type_change === 'mejoras') {
            return 'Mejora';
        } else {
            return 'Anuncio';
        }
    }

    return(
        <React.Fragment>
            {modalFile && (
                <ModalShowFile 
                    backdrop={"static"}
                    modal={modalFile}
                    files={files}
                    typeFile={typeFile}
                    toggle={toogleModalFile}
                    title={"Ver Archivo"}
                />
            )}
            <div className="ui-softwareChangeIndex-container__all">
                <div className="ui-softwareChangeIndex__container">
                    <div className="ui-softwareChangeIndex-title__container">
                        <h3>Novedades beewo</h3>
                    </div>
                    <ul className="ui-softwareChangeIndex__container_data">
                        {props.date.map((date, index) =>
                            <li className='ui-softwareChangeIndex__items-container' key={index}>
                                <React.Fragment>
                                    <h4 className='ui-softwareChangeIndex-date'>{date.dates}</h4>
                                    {props.changes.map((changes, index) => (
                                        <React.Fragment key={index}>
                                            {changes.date == date.date && (
                                                <div className="ui-softwareChangeIndex__container_info-change">
                                                    <div className="ui-softwareChangeIndex-icon-title__container">
                                                        <div className="ui-softwareChangeIndex-icon">
                                                            {typeChangeIcon(changes.type_change, date.id)}
                                                        </div>
                                                        <div className="ui-softwareChangeIndex-title-change">{changes.title}</div>
                                                    </div>
                                                    <div className="ui-softwareChangeIndex-description__container">
                                                        <span>{ReactHtmlParser(changes.description)}</span>
                                                    </div>
                                                    <div className="ui-softwareChangeIndex-type-file__container">
                                                            <div className="ui-softwareChangeIndex-link__container">
                                                                {(changes.type_file == 'image' || changes.type_file == 'file') ? (
                                                                    <React.Fragment>
                                                                        {changes.type_file == 'image' && (
                                                                            changes.file && <button className='ui-softwareChangeIndex-buttons-files' onClick={() => toogleModalFile("new", changes.file.url, changes.type_file)}>¡Ver Imagen!</button>
                                                                        )}
                                                                        {changes.type_file == 'file' && (
                                                                            changes.file && <button className='ui-softwareChangeIndex-buttons-files' onClick={() => toogleModalFile("new", changes.file.url, changes.type_file)}>¡Ver Archivo!</button>
                                                                        )}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        {changes.link_video && <button className='ui-softwareChangeIndex-buttons-files' onClick={() => toogleModalFile("new", changes.link_video, changes.type_file)}>¡Ver Video!</button>}
                                                                    </React.Fragment>
                                                                )}
                                                            </div>

                                                        <div className="ui-softwareChangeIndex-type-typechange__container">
                                                            <span>{typeChangeName(changes.type_change)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );

}

export default SoftwareChangeIndex;
WebpackerReact.setup({ SoftwareChangeIndex });