import React, { Component } from 'react';
import Select from "react-select";

class FormFilter extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
    }

    render() {
        
        return (
            <React.Fragment>
                <div className="card mb-4">
                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="row">

                                <div className={`col-md-3`}>
                                    <label>Nombre de la empresa</label>
                                    <input
                                        type="text"
                                        name="company_name"
                                        value={this.props.formValues.company_name}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                        placeholder="Nombre de la empresa "
                                    />
                                </div>

                                <div className={`col-md-3`}>
                                    <label>Nombre usuario</label>
                                    <input
                                        type="text"
                                        name="username"
                                        value={this.props.formValues.username}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                        placeholder="Nombre usuario"
                                    />
                                </div>

                                <div className={`col-md-3`}>
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        name="email"
                                        value={this.props.formValues.email}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control`}
                                        placeholder="Email"
                                    />
                                </div>

                                <div className={`col-md-3`}>
                                    <label>Estado de registro</label>
                                    <select 
                                        name="state_records"
                                        value={this.props.formValues.state_records}
                                        onChange={this.props.onChangeForm}
                                        className="form form-control"
                                    >
                                        <option value="">Seleccione</option>
                                        <option value="activo">activo</option>
                                        <option value="inactivo">inactivo</option>
                                    </select>
                                </div>

                                <div className={`col-md-3 mt-3`}>
                                    <label>Estado Plan</label>
                                    <select 
                                        name="tenant_state"
                                        value={this.props.formValues.tenant_state}
                                        onChange={this.props.onChangeForm}
                                        className="form form-control"
                                    >
                                        <option value="">Seleccione</option>
                                        <option value="demo">demo</option>
                                        <option value="activo">activo</option>
                                        <option value="inactivo">inactivo</option>
                                    </select>
                                </div>

                                <div className="col-md-3 mt-3">
                                    <label>Pais</label>
                                    <input
                                        type="hidden"
                                        name="country_field"
                                        value={this.props.selectedOptionCountry.country_field}
                                    />
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteCountry}
                                        options={this.props.countries}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={this.props.selectedOptionCountry}
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <label>Fecha desde (creacion de la licencia)</label>
                                    <input 
                                        type="date"
                                        name="start_date"
                                        className="form form-control"
                                        onChange={this.props.onChangeForm}
                                        value={this.props.formValues.start_date}
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <label>Fecha hasta (creacion de la licencia)</label>
                                    <input 
                                        type="date"
                                        name="end_date"
                                        className="form form-control"
                                        onChange={this.props.onChangeForm}
                                        value={this.props.formValues.end_date}
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <label>Fecha desde (vencimiento de la licencia)</label>
                                    <input 
                                        type="date"
                                        name="expiration_start_date"
                                        className="form form-control"
                                        onChange={this.props.onChangeForm}
                                        value={this.props.formValues.expiration_start_date}
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    <label>Fecha hasta (vencimiento de la licencia)</label>
                                    <input 
                                        type="date"
                                        name="expiration_end_date"
                                        className="form form-control"
                                        onChange={this.props.onChangeForm}
                                        value={this.props.formValues.expiration_end_date}
                                    />
                                </div>

                                <div className="col-md-3 mt-3">
                                    {this.props.states.length >= 1 && (
                                        <React.Fragment>
                                            <label>Departamento</label>
                                            <input
                                                type="hidden"
                                                name="state_field"
                                                value={this.props.selectedOptionState.state_field}
                                            />
                                            <Select
                                                onChange={this.props.handleChangeAutocompleteState}
                                                options={this.props.states}
                                                autoFocus={false}
                                                className={`link-form`}
                                                value={this.props.selectedOptionState}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>

                                <div className="col-md-3 mt-3">
                                    {this.props.cities.length >= 1 && (
                                        <React.Fragment>
                                            <label>Ciudad</label>
                                            <input
                                                type="hidden"
                                                name="city_field"
                                                value={this.props.selectedOptionCity.city_field}
                                            />
                                            <Select
                                                onChange={this.props.handleChangeAutocompleteCity}
                                                options={this.props.cities}
                                                autoFocus={false}
                                                className={`link-form`}
                                                value={this.props.selectedOptionCity}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>
                                
                                
                            </div>
                        </div>

                        <div className="card-footer">
                            <button
                                className="btn-shadow btn btn-info  mr-3"
                                onClick={() => this.props.handleClickFilter()}
                            >
                                Aplicar Filtro
                            </button>

                            <a
                                className="btn btn-outline-danger"
                                onClick={() => this.props.handleClickCancel()}
                            >
                                Cancelar
                            </a>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default FormFilter;