
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const Donutchart = (props) => {

    const [data, setData] = useState({
        options: {
            chart: {
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                    }
                },
                type: 'donut',
            },
            labels: [],
            legend: {
                position: 'top'
            }
        },
        series: []
    })

    useEffect(() => {
        setData({
            options: {
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                        }
                    },
                    type: 'donut',
                },
                colors: props.colors,
                labels: props.labels,
                legend: {
                    position: 'top'
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                      return val.toFixed(1) + "%"
                    },
                  }
            },
            series: props.series
        })
    }, [props]);


    return (
        <div>
            <ReactApexChart 
                width="400px" 
                height="400px" 
                className="bw_donut_chart__container"
                options={data.options} 
                series={data.series} 
                type="donut"
            />
        </div>
    );
}

export default Donutchart;
