
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import IndexQuestionFormatInput from './Index'
import IndexQuestionFilterFormat from '../QuestionFilterFormat/Index'

class ModalIndex extends Component {
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>
                        <div className='ui-modals-header-title__container'>
                            <span className='ui-titles_modals'>
                                <i className="app-menu__icon fa fa-user mr-2"></i>
                                {this.props.title}
                            </span>
                            <span className='ui-name-question-modals ui-icon-in-title'>Pregunta {this.props.question.question}</span> 
                        </div>
                    </ModalHeader>

                    <ModalBody>
                        <ul className="nav nav-tabs ui-modalIndex-questionFormatInput-scroll" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                                    Configuración del campo
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                                    Filtro de formato
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <IndexQuestionFormatInput 
                                    question={this.props.question}
                                    formato={this.props.formato}
                                />
                            </div>

                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <IndexQuestionFilterFormat
                                    question={this.props.question}
                                    formato={this.props.formato}
                                />
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalIndex;
