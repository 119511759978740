import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import Donutchart from '../GeneralComponents/ApexCharts/donutChart'
import CardData from '../GeneralComponents/bw-ui/CardData';
import CardDataThree from '../GeneralComponents/bw-ui/CardDataThree';
import Columnchart from '../GeneralComponents/ApexCharts/columnChart'
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Preloader from '../GeneralComponents/LoadingComponen';

const MyDashboard = (props) => {

    const [form, setForm] = useState({})
    const [isLoaded, setIsLoaded] = useState(true);
    const [data, setData] = useState({
        user: {
            name: "",
            position: "",
            avatar: "",
        },

        tasks: {
            count: 0,
            expired: 0,
            performance: 0,
            cumplimiento: 0,
            total_cumplimiento: 0,
            total_state: 0
        }
    });
    const token = document.querySelector("[name='csrf-token']").content;


    useEffect(() => {
        console.log(props)
        loadData(props.current_user.id);
    }, []);






    const loadData = (user) => {

        let route = "/task/my_dashboard_info/"

        if (props.type != "user") {
            route = "/task/general_dashboard_info/"
        }

        fetch(`${route}${user}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                setIsLoaded(false);
                setData({
                    user: {
                        name: data.user.name,
                        position: data.user.position,
                        avatar: data.user.avatar,
                    },
                    tasks: {
                        count: data.tasks.count,
                        series_state: data.tasks.series_state,
                        series_time: data.tasks.series_time,
                        labels_state: data.tasks.labels_state,
                        labels_time: data.tasks.labels_time,
                        expired: data.tasks.expired,
                        performance: data.tasks.performance,
                        cumplimiento: data.tasks.cumplimiento,
                        total_cumplimiento: data.tasks.total_cumplimiento,
                        total_state: data.tasks.total_state,
                        tasks_done_or_filed: data.tasks.tasks_done_or_filed,
                        total_open: data.tasks.total_open,
                        fulfilled: data.tasks.fulfilled,
                        fulfilled_not: data.tasks.fulfilled_not,
                        total_vigentes: data.tasks.total_vigentes,
                        vencidas: data.tasks.vencidas,
                        eficacia: data.tasks.eficacia,
                        eficiencia: data.tasks.eficiencia,
                        eficacia_abiertas: data.tasks.eficacia_abiertas,
                        efectividad: data.tasks.efectividad,
                        color_eficacia: data.tasks.color_eficacia,
                        color_eficiencia: data.tasks.color_eficiencia,
                        color_eficacia_abiertas: data.tasks.color_eficacia_abiertas,
                        color_efectividad: data.tasks.color_efectividad,
                        tasks_all_percentage: data.tasks.tasks_all_percentage,
                        total_tasks_account: data.tasks.total_tasks_account,
                        value_divider: data.tasks.value_divider,
                        users_values: data.tasks.users_values,
                        user_titles: data.tasks.user_titles,
                        user_colors: data.tasks.user_colors,
                    }

                })

            });

    }

    const handleChange = (e) => {
        setForm({
            form: {
                [e.target.name]: e.target.value,
            },
        });
        loadData(e.target.value);
    }


    const dasboardData = [
        {
            text: "Efectividad",
            percentageValue: "95%",
            color: "#7fa80d"
        },
        {
            text: "Total de tareas",
            value: data.tasks.count,
            color: "#3b9ed8"
        },
        {
            text: "Tareas Vencidas",
            value: data.tasks.expired,
            color: "#d41724"
        }
    ]

    const series = [44, 55, 41];
    const labels = ['Efectividad', 'Total de tareas', 'Tareas Vencidas']

    if (isLoaded) {
        return (
            <div className="card">
                <div className="card-body">
                    <Preloader />
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className='my-dashboard-container'>
                {props.type == "user" ? (
                    <div className='my-dashboard-user-container mb-4'>

                        <div className='my-dashboard-user-info-container'>
                            <img src={data.user.avatar} alt="user image" />
                            <div className='my-dashboard-user-info'>
                                <h1 className='my-dashboard-user-name'>{data.user.name}</h1>
                                <p className='my-dashboard-user-charge'>{data.user.position}</p>
                            </div>
                            {props.estados.show_users && (
                                <div className='my-dashboard-select-user ml-3'>
                                    <select
                                        name="value"
                                        className={`form form-control`}
                                        value={form.value}
                                        onChange={handleChange}
                                        style={{ width: "200px", marginLeft: "10px" }}
                                    >  <option value="">Seleccione Usuario</option>
                                        {props.users.map(user => (
                                            <option value={user.id}>{user.name}</option>
                                        ))}

                                    </select>
                                </div>
                            )}
                            <div className='colors-info'>
                                <i
                                    id={`PopoverLabelsRed`}
                                    className="fas fa-info-circle float-right icon-add icon-task-popover colors-info-red"

                                >

                                </i>
                                <UncontrolledPopover trigger="legacy" placement="bottom" target={`PopoverLabelsRed`}>
                                    <PopoverBody>
                                        <div className="dashboard-poppover">
                                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/12605/Imagen_1_Rojo_semaforo_empleado.png" alt="" />
                                        </div>
                                    </PopoverBody>
                                </UncontrolledPopover>


                                <i
                                    id={`PopoverLabelsOrange`}
                                    className="fas fa-info-circle float-right icon-add icon-task-popove colors-info-orange"

                                >

                                </i>
                                <UncontrolledPopover trigger="legacy" placement="bottom" target={`PopoverLabelsOrange`}>
                                    <PopoverBody>
                                        <div className="dashboard-poppover">
                                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/12606/Imagen_3_Amarillo_semaforo_empleado.png" alt="" />
                                        </div>
                                    </PopoverBody>
                                </UncontrolledPopover>


                                <i
                                    id={`PopoverLabelsGreen`}
                                    className="fas fa-info-circle float-right icon-add icon-task-popover colors-info-green"

                                >

                                </i>
                                <UncontrolledPopover trigger="legacy" placement="bottom" target={`PopoverLabelsGreen`}>
                                    <PopoverBody>
                                        <div className="dashboard-poppover">
                                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/12607/Imagen2_verde_semaforo_de_empleado.png" alt="" />
                                        </div>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                        </div>

                        <div className='my-dashboard-user-info-total-tasks'>Seguimiento tareas </div>
                    </div>) : (
                    <div className='my-dashboard-user-container mb-4'>
                        <div className='my-dashboard-user-info-total-tasks' style={{ padding: "10px 0 10px 0", display: "flex", gap: "10x" }}><span>Desempeño empresarial </span>
                            <div className='colors-info'>

                                <i
                                    id={`PopoverLabelsRed`}
                                    className="fas fa-info-circle float-right icon-add icon-task-popover colors-info-red"

                                >

                                </i>
                                <UncontrolledPopover trigger="legacy" placement="bottom" target={`PopoverLabelsRed`}>
                                    <PopoverBody>
                                        <div className="dashboard-poppover">
                                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/12602/Imagen_1_Rojo_semaforo_empresa.png" alt="" />
                                        </div>
                                    </PopoverBody>
                                </UncontrolledPopover>


                                <i
                                    id={`PopoverLabelsOrange`}
                                    className="fas fa-info-circle float-right icon-add icon-task-popove colors-info-orange"

                                >

                                </i>
                                <UncontrolledPopover trigger="legacy" placement="bottom" target={`PopoverLabelsOrange`}>
                                    <PopoverBody>
                                        <div className="dashboard-poppover">
                                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/12603/Imagen_3_Amarillo_semaforo_empresa.png" alt="" />
                                        </div>
                                    </PopoverBody>
                                </UncontrolledPopover>


                                <i
                                    id={`PopoverLabelsGreen`}
                                    className="fas fa-info-circle float-right icon-add icon-task-popover colors-info-green"

                                >

                                </i>
                                <UncontrolledPopover trigger="legacy" placement="bottom" target={`PopoverLabelsGreen`}>
                                    <PopoverBody>
                                        <div className="dashboard-poppover">
                                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/12604/Imagen2_verde_semaforo_de_empresa.png" alt="" />
                                        </div>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                        </div>

                    </div>
                )}

                <div className='my-dashboard-data-container mb-4'>
                    <CardDataThree titleTop="Total" titleLeft="Finalizadas" titleRight="Abiertas" valueTop={data.tasks.count} valueLeft={data.tasks.tasks_done_or_filed} valueRight={data.tasks.total_open} color="#4eb0ee" />
                    <CardDataThree titleTop="Finalizadas" titleLeft="Cumplidas" titleRight="No Cumplidas" valueTop={data.tasks.tasks_done_or_filed} valueLeft={data.tasks.fulfilled} valueRight={data.tasks.fulfilled_not} color="#4eb0ee" />
                    <CardDataThree titleTop="Abiertas" titleLeft="Vigentes" titleRight="Vencidas" valueTop={data.tasks.total_open} valueLeft={data.tasks.total_vigentes} valueRight={data.tasks.vencidas} color="#4eb0ee" />
                </div>
                <div className='my-dashboard-data-container mb-4'>
                    <CardData idCard="1" text1={props.img_eficacia} text2={{right:"0px"}} className="" title="Eficacia" value={data.tasks.eficacia + "%"} color={data.tasks.color_eficacia} />
                    <CardData idCard="2" text1={props.img_eficiencia} text2={{right:"0px"}} className="" title="Eficiencia" value={data.tasks.tasks_done_or_filed > 0 ? data.tasks.eficiencia + "%" : "-"} color={data.tasks.color_eficiencia} />
                    <CardData idCard="3" text1={props.img_rendimiento} text2={{right:"210px"}} title="Rendimiento actual" value={data.tasks.total_open > 0 ? data.tasks.eficacia_abiertas + "%" : "-"} color={data.tasks.color_eficacia_abiertas} />

                </div>
                <div className='my-dashboard-data-container mb-4'>
                    <CardData className="" text1={props.img_desempeño} text2={{right:"0px"}} title="Desempeño" value={data.tasks.efectividad + "%"} color={data.tasks.color_efectividad} />

                </div>
                {props.type == "user" && (
                    <div className='my-dashboard-charts-container mb-4'>
                        <div className='each-chart-container'>
                            <p className='my-dashboard-chart-title'>Participación de tareas en la Empresa (Total tareas {data.tasks.total_tasks_account} )</p>
                            {data.tasks.series_state && (
                                <Donutchart colors={['#1ce590', '#e8cb78', '#e96028']} labels={data.tasks.labels_time} series={data.tasks.series_time} />
                            )
                            }
                        </div>
                    </div>
                )
                }
                {props.type != "user" && (
                    <div className='my-dashboard-charts-container mb-4'>
                        <div className='each-chart-container'>
                            <p className='my-dashboard-chart-title'>Desempeño del personal</p>
                            {data.tasks.series_state && (
                                <Columnchart colors={['#1ce590', '#e8cb78', '#e96028']} labels={data.tasks.user_titles} series={data.tasks.users_values} user_colors={data.tasks.user_colors} />
                            )
                            }
                        </div>
                    </div>
                )
                }
            </div>
        </React.Fragment>
    );
}

export default MyDashboard;
WebpackerReact.setup({ MyDashboard });
