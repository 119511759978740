import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",

            formSectionAccionUpdate: {
                name: "",
                position: "",
                state: "",
            },

            formSectionAccion: {
                name: "",
                section_id: this.props.section_accion_id,
            },

            dataSectionAccions: [],
        }
    }

    componentDidMount = () => {
        this.loadDataModuleAccion()
    }

    clearValuesSectionAccion = () => {
        this.setState({
            id: "",
            formSectionAccion: {
                ...this.state.formSectionAccion,
                name: "",
                state: ""
            },

            formSectionAccionUpdate: {
                name: "",
                position: "",
                state: "",
            },
        })
    }


    HandleChangeSectionAccions = (e) => {
        this.setState({
            formSectionAccion: {
              ...this.state.formSectionAccion,
              [e.target.name]: e.target.value
            }
        });
    }

    loadDataModuleAccion = () => {
        fetch(`/settings/get_section_accions/${this.props.section_accion_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataSectionAccions: data.data,
                isLoadedModuleAccion: true
            });
        });
    }

    updateData = (data) => {
        this.setState({ 
            dataSectionAccions: [...this.state.dataSectionAccions, data]
        })
    }


    HandleClickSectionAccions = () => {
        fetch("/settings/section_accions", {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.formSectionAccion), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.updateData(data.register)
            this.clearValuesSectionAccion();
            this.props.loadData();
            this.messageSuccess(data);
        });
    }

    updateItem = (section_accion) => {
        this.setState({
            dataSectionAccions: this.state.dataSectionAccions.map(item => {
                if (section_accion.id === item.id) {
                    return {
                        ...item,
                        description: section_accion.description,
                        name: section_accion.name,
                        position: section_accion.position,
                        state: section_accion.state,
                    }
                }
                return item;
            })
        });
    }


    HandleClick = () => {
        fetch(`/settings/section_accions/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formSectionAccionUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => { 
            this.updateItem(data.register);
            //this.props.loadData();
            this.setState({ id: "" })
            this.props.messageSuccess(data);
        });
    }

    HandleChangeSectionAccion = (e) => {
        this.setState({
            formSectionAccionUpdate: {
              ...this.state.formSectionAccionUpdate,
              [e.target.name]: e.target.value
            }
        });
    }

    edit = (section) => {
        this.setState({
            id: section.id,
            formSectionAccionUpdate: {
                name: section.name,
                position: section.position,
                state: section.state
            },
        })
    }

    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar"
          
        }).then(result => {
          if (result.value) {
            fetch(`/settings/section_accions/${id}`, {
              method: "DELETE",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.loadDataModuleAccion();
                this.props.loadData()
                this.props.messageSuccess(response);
              });
          }
        });
    };

    update_position = (e) => {
        fetch(`/settings/update_position_section_actions/${this.state.id}/${this.props.section_accion_id}/${this.state.formSectionAccionUpdate.position}/${e.target.value}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.loadDataModuleAccion();
            this.clearValuesSectionAccion();
        });
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-9">
                                        <label>Nombre</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.state.formSectionAccion.name}
                                            onChange={this.HandleChangeSectionAccions}
                                            className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    <div className="col-md-3 text-right">
                                        <button className="btn-shadow btn btn-info btn-lg btn-block" style={{ marginTop: "30px" }} onClick={this.HandleClickSectionAccions}>Añadir</button>
                                    </div>



                                       <div className="col-md-12 mt-4">
                                            <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Nombre</th>
                                                    <th className="text-center">Opciones</th>
                                                </tr>
                                            </thead>
                
                                            <tbody>
                                                {this.state.dataSectionAccions.length >= 1 ? (
                                                    this.state.dataSectionAccions.map(section => (
                                                        <tr key={section.id}>

                                                            <td>
                                                                {section.id == this.state.id ? (
                                                                    <select
                                                                        type="text"
                                                                        name="position"
                                                                        value={this.state.formSectionAccionUpdate.position}
                                                                        onChange={(e) => this.update_position(e)}
                                                                        className="form form-control"
                                                                    >
                                                                        {this.state.dataSectionAccions.map(item => (
                                                                            <React.Fragment>
                                                                                <option value={item.position}>{item.position}</option>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </select>
                                                                ) : (
                                                                    <p>{section.position}</p>
                                                                )}
                                                            </td>
                                                            
                                                            <td>
                                                                {section.id == this.state.id ? (
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        value={this.state.formSectionAccionUpdate.name}
                                                                        onChange={this.HandleChangeSectionAccion}
                                                                        className="form form-control"
                                                                    />
                                                                ) : (
                                                                    <p>{section.name}</p>
                                                                )}
                                                            </td>
                    
                                                            <td className="text-right" style={{ width: "10px" }}>
                                                                {this.state.id != "" ? (
                                                                    <React.Fragment>
                                                                        {section.id == this.state.id &&(
                                                                            <React.Fragment>
                                                                                <i className="fas fa-check-circle update-section" onClick={() => this.HandleClick()}></i> 
                                                                                <i className="fas fa-times-circle update-false" onClick={() => this.setState({ id: ""})}></i>
                                                                            </React.Fragment>
                                                                        )} 
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <UncontrolledDropdown className='btn-group' direction="left">
                                                                        <DropdownToggle className='btn-shadow btn btn-info'>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.edit(section)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.delete(section.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                )}
                                                            </td>
                                                        </tr>
                                                ))
                                                    ) : (
                                                    <tr>
                                                        <td colSpan="8" className="text-center">
                                                            <div className="text-center mt-4 mb-4">
                                                                <h4>No hay registros</h4>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                       </div>

                                    
                                    {this.props.errorValues == false && (
                                        <div className="col-md-12 mt-4">
                                            <div className="alert alert-danger" role="alert">
                                                <b>Debes de completar todos los campos requeridos</b>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;