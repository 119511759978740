import React, { Component } from 'react';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';

class TreeView extends Component {
    render() {
        const MyNodeComponent = ({node}) => {
            return (
              <div className="initechNode" onClick={() => alert("Hi my real name is: " + node.id)}>{ node.name }</div>
            );
        };

        const initechOrg = {
            name: "Bill Lumbergh",
            actor: "Gary Cole",
            children: [
              {
                name: "Peter Gibbons",
                actor: "Ron Livingston",
                children: [
                  {
                    name: "And More!!",
                    actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!"
                  },

                  {
                    name: "And More!!",
                    actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!"
                  },

                  {
                    name: "And More!!",
                    actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!"
                  },

                  {
                    name: "And More!!",
                    actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!",
                    children: [
                        {
                          name: "And More!!",
                          actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!"
                        },
      
                        {
                          name: "And More!!",
                          actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!"
                        },
      
                        {
                          name: "And More!!",
                          actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!"
                        },
      
                        {
                            name: "And More!!",
                            actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!",
                            children: [
                                {
                                    name: "And More!!",
                                    actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!"
                                },
            
                                {
                                    name: "And More!!",
                                    actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!"
                                },
            
                                {
                                    name: "And More!!",
                                    actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!"
                                },
            
                                {
                                    name: "And More!!",
                                    actor: "This is just to show how to build a complex tree with multiple levels of children. Enjoy!"
                                },
                            ],
                        },
                    ],
                  },
                ],

              },
            ],
        };


        return (
            <React.Fragment>
                <OrgChart tree={this.props.data/* initechOrg */} NodeComponent={MyNodeComponent} />
            </React.Fragment>
        );
    }
}

export default TreeView;
