import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../../../GeneralComponents/HeaderTitle';
import FormCreateFiles from '../../../GeneralComponents/FormConfigurationFile';
import Select from "react-select";
import Loading from '../../../GeneralComponents/LoadingComponen'
import FormFilterName from '../../../GeneralComponents/FormFilterName';
import ModalShow from './Show'
import { isMobile } from 'react-device-detect';
import IndexMobile from './Mobile/Index'
import IndexWeb from './TableIndexWeb'

class Table extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoadedFiles: true,
            showFilter: false,
            modaFiles: false,
            modaShow: false,
            id: "",
            idGeneralFile: "",
            module_id: "",

            file: null,
            dataProcesos: [],
            dataProcesosFiles: [],
            position: {}
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                description: ""
            }
        })
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "Al borrar este cargo todos los lideres que asociados a este, pasaran al cargo Sistema Básico",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/delete_position/${id}`, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    if (response.type != "error"){
                        this.props.loadData()
                        this.messageSuccess(response)
                    }else{
                        this.messageSuccess(response);
                    }
                });
            }
        });
    };

    //files 

    clearValuesFiles = () => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                fileName: "",
                file: {},
            },

            nameFile: "",
            sizeFile: 0,

            isLoadedFiles: false,
        })
    }

    addFiles = (position) => {
        this.setState({
            module_id: position.id,
            modaFiles: true,
        })
    }

    HandleChangeFiles = (e) => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                [e.target.name]: e.target.value
            }
        });
    }

    toogleFiles = (from) => {
        if (from == "new") {
            this.setState({ modaFiles: true })
            this.clearValues();
        } else {
            this.setState({ modaFiles: false })
        }
    }

    toogleShow = (from, register) => {
        if (from == "new") {
            this.setState({ modaShow: true, position: register })
        } else {
            this.setState({ modaShow: false, position: {} })
        }
    }

    loadDataFiles = (id) => {
        fetch(`/settings/get_general_files_position/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataProcesosFiles: data.data,
                    isLoadedFiles: false
                });
            });
    }

    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                formAddFiles: {
                    ...this.state.formAddFiles,
                    file: file.size >= 11485760 ? "" : file
                },
  
                nameFile: file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
                sizeFile: file.size
            })
        ));
    };

    HandleClickFiles = () => {
        this.setState({ isLoadedFiles: true })
        const formData = new FormData();
        formData.append("modulo", this.state.formAddFiles.modulo)
        formData.append("file", this.state.formAddFiles.file)
        formData.append("fileName", this.state.formAddFiles.fileName)
        formData.append("position_id", this.state.formAddFiles.position_id)

        fetch(`/settings/general_files`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.clearValuesFiles()
                this.setState({ file: null })
                this.loadDataFiles(this.state.formAddFiles.position_id)
                this.messageSuccess(data);
            });
    }

    newPage = () => {
        Turbolinks.visit("/settings/positions/new", "_self");
    }

    showFilter = () => {
        this.setState({
            showFilter: true
        })

    }

    cancelFilter = () => {
        this.props.loadData();
        this.props.clearValues();
        this.setState({
            showFilter: false
        })
    }

    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={"Cargos de la organización"}
                    subTitle={"Gestión de cargos"}
                    titleNew={"Nuevo cargo"}
                    titleFilter={"Filtros"}
                    icon={"fas fa-user-tie"}
                    onClickNew={this.newPage}
                    showNew={this.props.estados.crear}
                    onClickFilter={this.showFilter}
                    showFilter={true}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                <ModalShow
                    backdrop={"static"}
                    modal={this.state.modaShow}
                    toggle={this.toogleShow}
                    title={`Procesos de ${this.state.position.name != undefined ? this.state.position.name.toLowerCase() : ""}`}
                    positions={this.state.position}
                />

                {/* FormCreateFiles */}

                {this.state.modaFiles && (
                    <FormCreateFiles
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modaFiles}
                        toggle={this.toogleFiles}
                        title={"Agregar Archivos"}

                        loadDataTable={this.props.loadData}
                        nameModule={"Cargos"}
                        module_id={this.state.module_id}
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>

                        {this.state.showFilter && (
                            <FormFilterName
                                handleChangeFilter={this.props.handleChangeFilter}
                                handleClickFilter={this.props.handleClickFilter}
                                showbtnSubmit={this.props.showbtnSubmit}
                                clearValues={this.cancelFilter}
                                formFilter={this.props.formFilter}
                            />
                        )}

                        {isMobile ? (
                            <IndexMobile 
                                data={this.props.data} 
                                estados={this.props.estados}
                                edit={this.edit}
                                delete={this.delete}
                                addFiles={this.addFiles}
                                toogleShow={this.toogleShow}
                            />
                            ) : (
                            <IndexWeb 
                                data={this.props.data} 
                                estados={this.props.estados}
                                edit={this.edit}
                                delete={this.delete}
                                addFiles={this.addFiles}
                                toogleShow={this.toogleShow}
                            />
                        )}
                    </React.Fragment>
                ) : (
                        <Loading />
                    )}
            </React.Fragment>
        );
    }
}

export default Table;