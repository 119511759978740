import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import Items from "../../settings/Icons/Items";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledTooltip } from 'reactstrap';

class FormCreateCategories extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoadedSelect: false,
            isLoaded: true,
            position_creates: [],
            categories: [],
            data: [],
        };
    }

    componentDidMount = () => {
        if(this.props.modeEdit){
            this.loadData();
            this.loadDataPositions();
        }else{
            this.setState({
                isLoaded: false
            })
        }
    }

    loadData = () => {
        fetch(`/formatos/get_edit_categories/${this.props.format_category_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            let array = []

            data.data.map((item) => (
                array.push({label: item.name, value: item.id})
            ))

            this.setState({
                categories: array,
            });
        });
    }

    loadDataPositions = () => {
        fetch(`/formatos/get_format_categories_position/${this.props.format_category_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                isLoaded: false,
                position_creates: data.position_creates,
                data: data.data,
            });
        });
    }

    handleClickUpdatePosition = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "Al darle en 'aceptar' se corregirá la numeración que se muestra en la lista desplegable y a esta categoría se le pondrá otra posición diferente",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                this.setState({ isLoadedSelect: true })
                fetch(`/formatos/update_position_select/${this.props.formValues.format_category_id}/${this.props.format_category_id}`, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.props.messageSuccess(response);
                    this.props.updatePositionState(response.register);
                    this.setState({
                        data: response.data,
                        isLoadedSelect: false
                    })
                });
            }
        });
    }
    
    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={!this.props.isLoadedChangePosition ? this.props.toggle : null}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className={`mb-3 col-md-${this.props.modeEdit ? "6" : "12"}`}>
                                        <label>Nombre</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.props.formValues.name}
                                            onChange={this.props.onChangeForm}
                                            disabled={this.props.isLoadedChangePosition}
                                            className={`form form-control ${!this.props.errorValues && this.props.formValues.name == "" ? "error-class" : ""}`}
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    {this.props.modeEdit && (
                                        <div className={`col-md-6`}>
                                            <input
                                                type="hidden"
                                                name="format_category_id"
                                                value={this.props.selectedOptionChangeCategory.format_category_id}
                                            />                                                        
                                            <label>Cambiar de categoria </label>
                                            <Select
                                                onChange={this.props.handleChangeAutocompleteChangeCategory}
                                                options={this.state.categories}
                                                autoFocus={false}
                                                className={`link-form`}
                                                isDisabled={this.props.isLoadedChangePosition}
                                                value={this.props.selectedOptionChangeCategory}
                                            />

                                            {(!this.props.showInputs && !this.props.isLoadedChangePosition) && (
                                                <label><input className="mt-2" type="checkbox" onChange={(e) => this.props.handleChangeChecked(e)} value={!this.props.remove_category} checked={this.props.remove_category} /> {this.props.remove_category ? "Invertir cambios" : "Remover categoria"}</label>
                                            )}
                                        </div>
                                    )}

                                    {(!this.props.showInputs && this.props.remove_category) && (
                                        <div className="col-md-12 mt-3">
                                            <div className="alert alert-primary" role="alert">
                                                <b>La categoria fue removida, actualiza para que esta categoria pase a ser un modulo principal</b>
                                            </div>
                                        </div>
                                    )}
                                

                                    {this.props.showInputs && (
                                        <React.Fragment>
                                            <div className={`col-md-${this.props.modeEdit ? "12 mt-3 mb-3" : "12 mt-3 mb-3" } ui-FormCreateCategoriesModal-charges`}>
                                                <label>Cargos que pueden ver este módulo desde el menú izquierdo <small className="validate-label">(opcional)</small></label>
                                                <input
                                                    type="hidden"
                                                    name="position_ids"
                                                    value={this.props.selectedOptionPosition.position_ids}
                                                />
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    autoFocus={false}
                                                    className={`link-form`}
                                                    classNamePrefix="select"
                                                    placeholder="Seleccione"
                                                    name="position_ids"
                                                    onChange={this.props.handleChangeAutocompletePosition}
                                                    options={this.props.cargos}
                                                    defaultValue={this.props.editValuesPosition}
                                                />
                                            </div> 

                                            <div className={`col-md-12 mt-2 mb-3`}>
                                                <label>Cargos que pueden acceder a este modulo cuando ingresen a FORDATA</label>
                                                <input
                                                    type="hidden"
                                                    name="position_fordata_ids"
                                                    value={this.props.selectedOptionFordata.position_fordata_ids}
                                                />
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    autoFocus={false}
                                                    className={`link-form ${!this.props.errorValues && this.props.formValues.position_fordata_ids.length <= 0 ? "error-class" : ""}`}
                                                    classNamePrefix="select"
                                                    placeholder="Seleccione"
                                                    name="position_ids"
                                                    onChange={this.props.handleChangeAutocompleteFordata}
                                                    options={this.props.cargos}
                                                    defaultValue={this.props.editValuesPositionFordata}
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}   

                                    {this.props.modeEdit && (
                                        <div className={`col-md-${this.props.modeEdit ? "6 mt-3 mb-2" : "6 mt-3 mb-2" }`}>
                                            {!this.state.isLoadedSelect ? (
                                                <React.Fragment>
                                                    {!this.props.isLoadedChangePosition ? (
                                                        <React.Fragment>
                                                            <label>Posición de la categoría</label>
                                                            <select 
                                                                name="position" 
                                                                className="form form-control"
                                                                value={this.props.formValues.position}
                                                                onChange={this.props.update_position}
                                                            >
                                                                <option value="">Seleccione la posición </option>
                                                                {this.state.data.map(format_category => (
                                                                    <option value={format_category.position} disabled={this.props.format_category_id == format_category.id}>{format_category.position}</option>
                                                                ))}
                                                            </select>
                                                        </React.Fragment>
                                                    ) : (
                                                        <div className="alert alert-info text-center" role="alert">
                                                            <b>La categoría está cambiando de posición...</b>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <div className="alert alert-info text-center" role="alert">
                                                    <b>Cargando valores</b>
                                                </div>
                                            )}

                                            {(!this.state.isLoadedSelect && !this.props.isLoadedChangePosition) && (
                                                <React.Fragment>
                                                    <UncontrolledTooltip target='ui-restore' placement='right'>Este botón sirve para reorganizar los numeros de la lista desplegable</UncontrolledTooltip>
                                                    <span 
                                                        className='span-restore'
                                                        id="ui-restore" data-toggle="tooltip" data-trigger="hover" data-placement="right"
                                                        onClick={this.handleClickUpdatePosition}
                                                    >
                                                        Reorganizar valores
                                                    </span>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}  
                                    
                                    {(!this.props.showInputs && !this.state.isLoaded) && (
                                        <React.Fragment>
                                            <div className={`col-md-12 mt-3`}>
                                                <label>Cargos que pueden ver este módulo</label>
                                                <input
                                                    type="hidden"
                                                    name="position_create_ids"
                                                    value={this.props.selectedOption.position_create_ids}
                                                />
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    autoFocus={false}
                                                    className={`link-form ${!this.props.errorValues && this.props.formValues.position_create_ids.length >= 0 ? "error-class" : ""}`}
                                                    classNamePrefix="select"
                                                    placeholder="Seleccione"
                                                    name="position_ids"
                                                    onChange={this.props.handleChangeAutocomplete}
                                                    options={this.props.cargos}
                                                    defaultValue={this.state.position_creates}
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}                     
                                </div>
                                
                                {this.props.showInputs && (
                                    <Items
                                        icons={this.props.icons}
                                        modeEdit={this.props.modeEdit}
                                        setIconForm={this.props.setIconForm}
                                        icon_id={this.props.formValues.icon_id}
                                        errorValues={this.props.errorValues}
                                    />
                                )} 

                                {!this.props.errorValues && (
                                    <div className="col-md-12 p-0 mt-4">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                        </div>
                                    </div>
                                )} 
                            </ModalBody>

                            <ModalFooter>
                                <button 
                                    className="btn btn-light" 
                                    onClick={() => this.props.toggle()}
                                    disabled={this.props.isLoadedChangePosition}
                                >
                                    Cerrar
                                </button>

                                <button 
                                    className="btn-shadow btn btn-info" 
                                    onClick={this.props.submitForm}
                                    disabled={this.props.isLoadedChangePosition}
                                >
                                    {this.props.nameSubmit}
                                </button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreateCategories;