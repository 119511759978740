import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { QrReader } from 'react-qr-reader';

class ModalReadQr extends Component {
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    {/*<ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>*/}
                    <ModalBody>
                        <div className="row">
                            <div
                                style={{
                                    margin: 'auto',
                                    width: '400px'
                                }}
                            >
                                {this.props.modalQrActive && (
                                    <QrReader
                                        constraints={{ facingMode: 'environment' }}
                                        onResult={this.props.onResult}
                                    />
                                )}
                                {!this.props.modalQrActive && (
                                   <div>
                                    <div className='alert alert-success mb-4'>{this.props.modalQrData.message}</div>
                                    { this.props.modalQrData.data.map(data=> (
                                        <div style={{display:"flex", gap:"5px"}}>
                                                <span> <b>{data.question}</b></span> :
                                                <span>{data.value}</span>
                                        </div>
                                    ))}
                                    <button className='btn btn-primary mt-4 btn-block' onClick={() => this.props.actvieQr()}>Leer nuevo</button>
                                   </div>  
                                )}

                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalReadQr;
