import React, { Component } from 'react';
import FormUpdate from './FormUpdate';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import IndexAttached from '../../tasks/tasksAttached/IndexAttached';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import moment from 'moment';
import is_imagen from 'is-image';
import PieChart from '../../../GeneralComponents/GeneralCharts/PieChart';

//Icons google
import GoogleFontLoader from 'react-google-font-loader';

import FormUpgradePlan from '../Users/FormUpgradePlan';

class Show extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modalUpgradePlan: false,
            modeEditFile: false,
            showPopoverFile: false,
            editComment: false,
            isLoading: false,
            loaded: false,
            popoverOpen: false,
            id: "",
            nameFile: "",
            form: {
                company_name: "",
                web: "",
                nit: "",
                logo: {},
                url_name: "",
            },
            date_start: moment(this.props.data.start_demo),
            date_end: moment(this.props.data.end_demo),
            days: 0,

            file: {}
        }
    }

    componentDidMount(){
        const days = this.state.date_end.diff(this.state.date_start, 'days');
        this.setState({ days });
    } 

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                company_name: this.props.data.company_name,
                nit: this.props.data.nit,
                web: this.props.data.web,
                logo: {},
                url_name: this.props.data.url_name,
            },

            nameFile: "",
            file: {}
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    handleFile = archivo => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    logo: file
                },

                nameFile: file.path,
                file: archivo,
            })
        ));
    };

    HandleClick = () => {
        this.setState({ isLoading: true })
        const formData = new FormData();
        formData.append("company_name", this.state.form.company_name)
        formData.append("nit", this.state.form.nit)
        formData.append("logo", this.state.form.logo)
        formData.append("web", this.state.form.web)
        formData.append("url_name", this.state.form.url_name)

        fetch(`/settings/tenants/${this.props.user}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                this.clearValues()
                this.messageSuccess(data);
                this.setState({ modal: false, isLoading: false })
               Turbolinks.visit(`/settings/tenants/${this.props.data.token}`);
            });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    //files

    closePopovers = () => {
        this.setState({
            showPopoverFile: false,
            editComment: false,
            id: ""
        })
    }

    deleteFiles = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/general_files/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData();
                    });
            }
        });
    };

    removeLogo = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El logo sera removido para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/remove_img`, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.messageSuccess(response)
                        this.props.loadData();
                       Turbolinks.visit(`/settings/tenants/${this.props.data.token}`);
                    });
            }
        });
    };

    toogleUpgradePlan = (from) =>{
        if (from == "new"){
            this.setState({ modalUpgradePlan: true })
        } else {
            this.setState({ modalUpgradePlan: false })
        } 
    } 

    tooglePopover = (from) => {
        if (from == "new"){
            this.setState({ popoverOpen: !this.popoverOpen });
        } else{
            this.setState({ popoverOpen: this.popoverOpen });
        } 
    } 

    onChangeUrlBeetre = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value.toLowerCase().replace(" ", "-")
            }
        });
    }

    render() {

        const styleIconFill ={
            fontVariationSettings: "'fill' 1",
        };

        return (
            //ClassName ui-show-tenant-license_
            <React.Fragment>
                <GoogleFontLoader
                    fonts={[
                        {
                          font: 'Material Symbols Outlined',
                          weights: [400],
                        },
                    ]}
                    async={true}
                    defer={true}
                />

                <GoogleFontLoader
                    fonts={[
                        {
                          font: 'Material Icons',
                          weights: [400],
                        },
                    ]}
                    async={true}
                    defer={true}
                />

                {this.state.modal && (
                    <FormUpdate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Actualizar Registro"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        onChangeUrlBeetre={this.onChangeUrlBeetre}

                        //file
                        handleFile={this.handleFile}
                        nameFile={this.state.nameFile}
                        file={this.state.file}
                        tenant={this.props.tenant}
                        isLoading={this.state.isLoading}
                    />
                )}

               {this.state.modalUpgradePlan && (
                    <FormUpgradePlan
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalUpgradePlan}
                        toggle={this.toogleUpgradePlan}
                        title={"Mejora tu Plan"}
                        messageModal={[<span className='ui_formUpgrade_Plan_message_first'>Mejora tu plan actual</span>," selecciona un plan más avanzado"]}
                        plans={this.props.plans}
                        current_plan={this.props.current_plan}
                    />
               )} 

                <div className="ui-show-tenant-license__container">
                    <div className="ui-show-tenant-license__container-logo-info_license">
                        <div className="card ui-show-tenant-license__container_logo_info">
                            <div className="card-body">
                                <div className="ui-show-tenant-license_container-logo">
                                {this.props.estados.editar && (
                                        <div className="ui-show-tenant-license-btn-edit-logo">
                                            <button className="btn-shadow btn ui-show-tenant-license-btn_edit" onClick={() => this.toogle("new")}>
                                                <i className="material-symbols-outlined">&#xe3c9;</i>
                                            </button>
                                        </div>
                                    )}
                                    <div className="ui-show-tenant-license-enterprise-logo">
                                        <img className="tenant-img ui-show-tenant-license-img-logo" src={this.props.data.logo.url ? this.props.data.logo.url : "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/1856/Mensaje_logo.png"} alt="" />
                                    </div>
                                </div>
                                <div className="ui-show-tenant-license_container-info">
                                    <div className="ui-show-tenant-license_name-enterprise">
                                        <span className="ui-show-tenanat-license-title-info">Nombre de la empresa</span>
                                        <span className='ui-show-tenant-license-data-dinamic'>{this.props.data.company_name}</span>
                                    </div>
                                    <div className="ui-show-tenant-license_admin-user-enterprise">
                                        <span className="ui-show-tenanat-license-title-info">Usuario administrador</span>
                                        <span className='ui-show-tenant-license-data-dinamic'>{this.props.data.admin ? this.props.data.admin.email : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card ui-show-tenant-license__container-info-license">
                            <div className="card-body">
                               {this.props.tenant.app_type == "Salud" ? (
                                    <div className="ui-show-tenant-license__container-data-info-salud">
                                        <div className="ui-show-tenant-license__container-type-plan">
                                            <span className="ui-show-tenanat-license-title-data-info">Tipo de plan</span>
                                            <span className='ui-show-tenant-license-data-info-dinamic'>{this.props.data.plan ? this.props.data.plan.name : ""}</span>
                                        </div>
                                        <div className="ui-show-tenant-license__container-time-susbcription">
                                            <span className="ui-show-tenanat-license-title-data-info">Tiempo suscripción</span>
                                            <span className='ui-show-tenant-license-data-info-dinamic'>{(this.state.days)+1} días</span>
                                        </div>
                                        <div className="ui-show-tenant-license__container-date-renovation">
                                            <span className="ui-show-tenanat-license-title-data-info">Fecha de renovación</span>
                                            <span className='ui-show-tenant-license-data-info-dinamic'>{this.props.data.end_demo}</span>
                                        </div>
                                        <div className="ui-show-tenant-license__container-date-renovation">
                                            <span className="ui-show-tenanat-license-title-data-info">Licencia Sanitaria</span>
                                            <span className='ui-show-tenant-license-data-info-dinamic'>{this.props.data.nit}</span>
                                        </div>
                                    
                                    </div>
                               ): (
                                    <div className="ui-show-tenant-license__container-data-info">
                                        <div className="ui-show-tenant-license__container-type-plan">
                                            <span className="ui-show-tenanat-license-title-data-info">Tipo de plan</span>
                                            <span className='ui-show-tenant-license-data-info-dinamic'>{this.props.data.plan ? this.props.data.plan.name : ""}</span>
                                        </div>
                                        <div className="ui-show-tenant-license__container-time-susbcription">
                                            <span className="ui-show-tenanat-license-title-data-info">Tiempo suscripción</span>
                                            <span className='ui-show-tenant-license-data-info-dinamic'>{(this.state.days)+1} días</span>
                                        </div>
                                        <div className="ui-show-tenant-license__container-date-renovation">
                                            <span className="ui-show-tenanat-license-title-data-info">Fecha de renovación</span>
                                            <span className='ui-show-tenant-license-data-info-dinamic'>{this.props.data.end_demo}</span>
                                        </div>
                                    </div>
                               )} 
                            </div>
                        </div>
                    </div>

                    <div className="ui-show-tenant-license__container-graphics-documents-info-license-changes">
                        <div className="ui-show-tenant-license__container-graphics-info-license-changes">
                            <div className="ui-show-tenant-license__container_cards-data-info-license-changes">
                                <div className="ui-show-tenant-license-info-license-changes-active-users">
                                    <div className="ui-show-tenant-license__container-icons-info-license-changes">
                                        <i className="material-symbols-outlined bw_icon_group">&#xf233;</i>
                                    </div>
                                    <div className="ui-show-tenant-license__container-user-active">
                                        <span className="ui-show-tenant-license-title-info-license-changes">Usuarios Activos</span>
                                        <span className="ui-show-tenant-license__dinamic-info-license-changes">{this.props.data.users}</span>
                                    </div>
                                </div>
                                <div className="ui-show-tenant-license-info-license-changes-formats">
                                    <div className="ui-show-tenant-license__container-icons-info-license-changes">
                                        <i className="material-symbols-outlined bw_icon_lab_profile">&#xe104;</i>
                                    </div>
                                    <div className="ui-show-tenant-license__container-user-active">
                                        <span className="ui-show-tenant-license-title-info-license-changes">Formatos</span>
                                        <span className="ui-show-tenant-license__dinamic-info-license-changes">{this.props.data.formats}</span>
                                    </div>
                                </div>
                                <div className="ui-show-tenant-license-info-license-changes-registers">
                                    <div className="ui-show-tenant-license__container-icons-info-license-changes">
                                        <i className="material-symbols-outlined bw_icon_feed">&#xf009;</i>
                                    </div>
                                    <div className="ui-show-tenant-license__container-user-active">
                                        <span className="ui-show-tenant-license-title-info-license-changes">Registros</span>
                                        <span className="ui-show-tenant-license__dinamic-info-license-changes">{this.props.data.records_count}</span>
                                    </div>
                                </div>
                                <div className="ui-show-tenant-license-info-license-changes-storage">
                                    <div className="ui-show-tenant-license__container-icons-info-license-changes">
                                        <i className="material-symbols-outlined bw_icon_cloud_sync">&#xeb5a;</i>
                                    </div>
                                    <div className="ui-show-tenant-license__container-user-active">
                                        <span className="ui-show-tenant-license-title-info-license-changes">Almacenamiento</span>
                                        <span className="ui-show-tenant-license__dinamic-info-license-changes">{this.props.data.upload} GB</span>
                                    </div>
                                </div>
                            </div>

                        
                            <div className="ui-show-tenant-license__container_two-graphics">
                                <div className="card ui-show-tenant-license__container_graphics">
                                    <div className="card-body">
                                        <div className="ui-show-tenant-license-graphics-title">
                                            <span className='ui-show-tenant-license-title-graphics'>Usuarios</span>
                                        </div>
                                        <div className="ui-show-tenant-license-graphics-data">
                                            <PieChart data={this.props.chart.data1} options={this.props.chart.options1} />
                                        </div>
                                    </div>
                                </div>

                                <div className="card ui-show-tenant-license__container_graphics">
                                    <div className="card-body">
                                        <div className="ui-show-tenant-license-graphics-title">
                                            <span className='ui-show-tenant-license-title-graphics'>Almacenamiento</span>
                                        </div>
                                        <div className="ui-show-tenant-license-graphics-data">
                                            <PieChart data={this.props.chart.data2} options={this.props.chart.options2} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ui-show-tenant-license__container_documents">
                           {this.props.data.plan.name != 'beefree' ? (
                            <React.Fragment>
                              {this.props.data.contract?.url ? (
                                <a className="card ui-show-tenant-license__container-card-documents" href={this.props.data.contract.url} target="_blank">
                                    <div className="card-body">
                                        <div className="ui-show-tenant-license-icon-document">
                                            <i className="material-symbols-outlined">&#xe8f7;</i>
                                        </div>
                                        <div className="ui-show-tenant-license-data-document">
                                            <span className='ui-show-tenant-license-data-document-name'>Contrato</span>
                                        </div>
                                        <div className="ui-show-tenant-license-icon-download">
                                            <i className="material-icons">&#xe2c0;</i>
                                        </div>
                                    </div>
                                </a>
                              ) :  (
                                <React.Fragment>
                                    <a className="card ui-show-tenant-license__container-card-documents" onClick={() => this.tooglePopover('new')} id='bw_popover-message_contract'>
                                        <div className="card-body">
                                            <div className="ui-show-tenant-license-icon-document">
                                                <i className="material-symbols-outlined">&#xe8f7;</i>
                                            </div>
                                            <div className="ui-show-tenant-license-data-document">
                                                <span className='ui-show-tenant-license-data-document-name'>Contrato</span>
                                            </div>
                                            <div className="ui-show-tenant-license-icon-download">
                                                <i className="material-icons">&#xe2c0;</i>
                                            </div>
                                        </div>
                                    </a>
                                    <UncontrolledPopover isOpen={this.state.popoverOpen} placement="left" target='bw_popover-message_contract'>
                                        <PopoverHeader>
                                            <div className='ui-show-tenanat-license-message_header__container'>
                                                <div className='ui-show-tenanat-license-message_header_text'>
                                                    <span className="material-icons">&#xe937; </span> {"Información Importante"} 
                                                </div> 
                                                <a className="close-popover" onClick={() => !this.tooglePopover()}> <span className="material-symbols-outlined">&#xe5cd; </span></a>
                                            </div>
                                        </PopoverHeader>
                                        <PopoverBody>
                                           <div className="ui-show-tenant-license-message-popover__container">
                                                <span className='ui-show-tenant-license-img-popover-text1'>El contrato estará disponible para su descarga en los próximos días.</span> 
                                                <img className='ui-show-tenant-license-img-popover-message' src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/161982/PopoverContract.jpg" alt="Img" />
                                                <span className='ui-show-tenant-license-img-popover-text2'>Lo podrás encontrar en este mismo módulo de licencia.</span>
                                           </div>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </React.Fragment>
                              ) } 
                                <a className="card ui-show-tenant-license__container-card-documents" href='/settings/certicate_license.pdf' target="_blank">
                                    <div className="card-body">
                                        <div className="ui-show-tenant-license-icon-document">
                                            <i className="material-symbols-outlined">&#xe865;</i>
                                        </div>
                                        <div className="ui-show-tenant-license-data-document">
                                            <span className='ui-show-tenant-license-data-document-name'>Certificado</span>
                                        </div>
                                        <div className="ui-show-tenant-license-icon-download">
                                            <i className="material-icons">&#xe2c0;</i>
                                        </div>
                                    </div>
                                </a>
                                <a className="card ui-show-tenant-license__container-card-documents" href='https://docs.google.com/viewerng/viewer?url=https://beewo.s3.amazonaws.com/uploads/document_management/document/attached/562/Protecci%C3%B3n_y_seguridad_de_la_informaci%C3%B3n_en_beewo.pdf' target="_blank">
                                    <div className="card-body">
                                        <div className="ui-show-tenant-license-icon-document">
                                            <i className="material-symbols-outlined">&#xeb7d;</i>
                                        </div>
                                        <div className="ui-show-tenant-license-data-document">
                                            <span className='ui-show-tenant-license-data-document-name'>Certificado Seguridad</span>
                                        </div>
                                        <div className="ui-show-tenant-license-icon-download">
                                            <i className="material-icons">&#xe2c0;</i>
                                        </div>
                                    </div>
                                </a>
                            </React.Fragment>
                        ): (
                            <React.Fragment>
                                <a className="card ui-show-tenant-license__container-card-documents" onClick={() => this.toogleUpgradePlan('new')}>
                                <div className="card-body">
                                    <div className="ui-show-tenant-license-icon-document">
                                        <i className="material-symbols-outlined">&#xe8f7;</i>
                                    </div>
                                    <div className="ui-show-tenant-license-data-document">
                                        <span className='ui-show-tenant-license-data-document-name'>Contrato</span>
                                    </div>
                                    <div className="ui-show-tenant-license-icon-download">
                                        <i className="material-icons">&#xe2c0;</i>
                                    </div>
                                </div>
                                </a>
                                <a className="card ui-show-tenant-license__container-card-documents" onClick={() => this.toogleUpgradePlan('new')}>
                                    <div className="card-body">
                                        <div className="ui-show-tenant-license-icon-document">
                                            <i className="material-symbols-outlined">&#xe865;</i>
                                        </div>
                                        <div className="ui-show-tenant-license-data-document">
                                            <span className='ui-show-tenant-license-data-document-name'>Certificado</span>
                                        </div>
                                        <div className="ui-show-tenant-license-icon-download">
                                            <i className="material-icons">&#xe2c0;</i>
                                        </div>
                                    </div>
                                </a>
                                <a className="card ui-show-tenant-license__container-card-documents" onClick={() => this.toogleUpgradePlan('new')}>
                                    <div className="card-body">
                                        <div className="ui-show-tenant-license-icon-document">
                                            <i className="material-symbols-outlined">&#xeb7d;</i>
                                        </div>
                                        <div className="ui-show-tenant-license-data-document">
                                            <span className='ui-show-tenant-license-data-document-name'>Certificado Seguridad</span>
                                        </div>
                                        <div className="ui-show-tenant-license-icon-download">
                                            <i className="material-icons">&#xe2c0;</i>
                                        </div>
                                    </div>
                                </a>
                            </React.Fragment>
                        )} 
                        </div>
                    </div>
                </div>                
            </React.Fragment>
        );
    }
}

export default Show;
