import React, { Component } from 'react';
import Show from '../components/formatos/Surveys/Show'  
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"

class SurveysShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            hide_show_ids:[]
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    }
    
    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    loadData = () => {
        fetch(`/formatos/get_surveys/${this.props.survey.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                data_all: data.data_all,
                dataAnswer: data.dataAnswer,
                subForm: data.subForm,
                isLoaded: false,
                hide_show_ids: data.hide_show_ids,
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.props.showHeader && (
                    <HeaderTitle
                        title={`${this.props.formats.name}`}
                        subTitle={`${this.props.format_category.name}`}
                        titleNew={"Nuevo formato"}
                        titleFilter={"Filtros"}
                        icon="fas fa-file-alt"
                        showNew={false}
                        showFilter={false}

                        is_index={true}
                        url={this.props.current_url}
                    />
                )}

                <Show
                    updateStateLoad={this.updateStateLoad}
                    data={this.state.data}
                    data_all={this.state.data_all}
                    dataAnswer={this.state.dataAnswer}
                    subForm={this.state.subForm}
                    loadData={this.loadData}
                    format={this.props.formats}
                    format_category={this.props.format_category}
                    user_signed={this.props.user_signed}
                    survey={this.props.survey}
                    showHeader={this.props.showHeader}
                    updateData={this.updateData}
                    users={this.props.users}
                    procesos={this.props.procesos}
                    positions={this.props.positions}
                    suervey_answers={this.props.suervey_answers}
                    type={this.props.type}
                    redirect={this.props.redirect}
                    custom_view_id={this.props.custom_view_id}
                    //count validation
                    surveyAnsweCount={this.props.surveyAnsweCount}
                    isLoaded={this.state.isLoaded}
                    hide_show_ids={this.state.hide_show_ids}
                    key_text={this.props.key_text}
                    imageUpload={this.props.imageUpload}
                    validate={this.props.validate}
                />
            </React.Fragment>
        );
    }
}


export default SurveysShow;
WebpackerReact.setup({ SurveysShow });

