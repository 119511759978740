import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import Preloader from '../../../../../GeneralComponents/LoadingComponen';
import FormFilter from './FormFilter';

const Index = (props) => {
    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(true);

    const [isLoadedFilter, setIsLoadedFilter] = useState(false);
    const [modalFormFilter, setModalFormFilter] = useState(false)

    const [totalData, setTotalData] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [countPage, setCountPage] = useState(10);

    const token = document.querySelector("[name='csrf-token']").content;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        fetch(`/formatos/get_admin_questions/${props.tenant.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data.filter((e) => e.questions.length >= 1));
            setTotalData(data.total_data.filter((e) => e.questions.length >= 1).length);
            setIsLoaded(false);
        });
    }

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const dateTime = (fecha) => {
        var d = new Date(fecha),
        months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + d.getDate() + " " + 'del' + " " + d.getFullYear() + " / " + d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes() );
    }

    const handlePageChange = (pageNumber) => {
        const filter_url = `/formatos/get_admin_questions/${props.tenant.id}&page=${pageNumber}&filter=${countPage}`
        setActivePage(pageNumber)

        fetch(filter_url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data.filter((e) => e.questions.length >= 1));
            setTotalData(data.total_data.filter((e) => e.questions.length >= 1).length);
        });
    }

    const toogleFilter = () => {
        setModalFormFilter(!modalFormFilter)
        
        if (modalFormFilter){
            loadData();
        }
    }

    const handleClickFilter = (form) => {
        setIsLoadedFilter(true)
        fetch(`/formatos/get_admin_questions/${props.tenant.id}?format_name=${form.format_name}&question_name=${form.question_name}&user_delete_id=${form.user_delete_id}&date1=${form.date1}&date2=${form.date2}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data.filter((e) => e.questions.length >= 1));
            setTotalData(data.total_data.filter((e) => e.questions.length >= 1).length);
            setIsLoadedFilter(false)
        });
    }

    const handleClickRestore = (question) => {
        fetch(`/formatos/get_admin_restore_question/${question.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(response => {
            updateData(response.register);
            //messageSuccess(response);
        });
    }

    const updateData = (register) => {
        setData(data.map(item => {
            if (item.id === register.format_id) {
                return {
                    ...item,
                    questions: item.questions.filter((e) => e.id != register.question_id)
                }
            }
            return item;
        }));
    }

    if (isLoaded) {
        return (
            <div className="card">
                <div className="card-body">
                    <Preloader />
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>

            {modalFormFilter && (
                <FormFilter
                    tenant={props.tenant}
                    toogleFilter={toogleFilter}
                    handleClickFilter={handleClickFilter}
                    isLoadedFilter={isLoadedFilter}
                />    
            )}

            {data.length >= 1 ? (
                <React.Fragment>

                    {!modalFormFilter && (
                        <div className="col-md-12 text-right pr-0 mt-3 mb-3">
                            <button
                                className='btn-shadow btn btn-info'
                                onClick={() => toogleFilter()}
                            >
                                Filtrar
                            </button>
                        </div>
                    )}


                    <table className="table table-hover table-striped table-bordered" id="sampleTable">
                        <thead>
                            <tr>
                                <th style={{ width: "21%" }}>Formato</th>
                                <th>Preguntas</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map(formato => (
                                <tr key={formato.id}>
                                    <td>{formato.name}</td>
                                    <td>  
                                        {formato.questions.length >= 1 ? (
                                            <table className='table table-hover table-striped table-bordered mb-0' style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th>Eliminada por</th>
                                                        <th>Fecha de eliminacion</th>
                                                        <th>Restaurar</th>
                                                    </tr>
                                                </thead>
                                                {formato.questions.map(question => (
                                                    <tr key={question.id}>
                                                        <td>{question.question}</td>
                                                        <td>{question.user_delete ? question.user_delete.name : null}</td>
                                                        <td>{dateTime(question.date_delete)}</td>
                                                        <td>
                                                            <button 
                                                                onClick={() => handleClickRestore(question)} 
                                                                className='btn-shadow btn btn-success'
                                                            >
                                                                {false ? "Cargando.." : "Restaurar"}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </table>
                                        ) : (
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <h4>No hay preguntas eliminadas</h4>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </React.Fragment>
            ) : (
                <div className="card">
                    <div className="card-body text-center">
                        <h5>No hay formatos con preguntas eliminadas</h5>
                    </div>
                </div>
            )}

            {true && (
                <div className="row mt-3">
                    <div className="col-md-3 text-left">
                        <p>Mostrando {data.length} de {totalData}</p>
                    </div>

                    <div className="col-md-9 pl-0">
                        <Pagination
                            hideNavigation
                            activePage={activePage}
                            itemsCountPerPage={countPage}
                            itemClass="page-item"
                            innerClass="pagination"
                            linkClass="page-link"
                            totalItemsCount={totalData}
                            pageRangeDisplayed={countPage}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}


export default Index;

