import React, { useState, useEffect } from 'react';
import WebpackerReact from 'webpacker-react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import ShowDriverReview from '../components/formatos/TrsDriverReviewResult/ShowDriverReview';

const TrsDriverReviewResult = (props) => {
    return (
        <React.Fragment>
            <HeaderTitle
                title={`Resultados de la busqueda`}
                subTitle={"Central de Riesgos"}
                titleNew={"Nuevo"}
                titleFilter={"Filtros"}
                icon="fas fa-toolbox"
                onClickNew={null}
                showNew={false}
                showFilter={false}
            />

            <div className='ui-driver_reportes__container-search'>
                <ShowDriverReview 
                    trs_driver_review={props.trs_driver_review}
                />
            </div>
        </React.Fragment>
    );
}

export default TrsDriverReviewResult;
WebpackerReact.setup({ TrsDriverReviewResult });