import React, { Component } from 'react';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreateFiles from "../../../GeneralComponents/FormGeneralFile";
import FormCreateReply from '../taskComment/CommentReply/FormCreate';
import { isMobile } from 'react-device-detect';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: true,
            isLoadedCreate: false,
            showSubmitComment: false,
            modeEditComment: false,
            modaFiles: false,

            formComment: {
                comment: "",
                module_name: "Tareas",
                notify: true,
                user_ids: [],
                module_comment_id: this.props.task.module_comment_id,
            },

            formReply: {
                comment: {},
            },

            formUpdateComment: {
                comment: "",
                user_ids: [],
            },

            formValuesReply: {
                id: "",
                reply: "",
            },

            id: "",
            module_file_id: "",
            data: [],
            sizeFile: 0,
        }
    }

    selectComment = (comment) => {
        if(comment.id == this.state.formReply.comment.id){
            this.setState({
                formReply: {
                    ...this.state.formReply,
                    comment: {}
                }
            })
        }else{
            this.setState({
                formReply: {
                    ...this.state.formReply,
                    comment: comment
                }
            })
        }

    }

    componentDidMount = () => {
        this.loadComments();
    }

    loadComments = () => {
        fetch(`/get_general_commets_indicators/${this.props.task.module_comment_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    isLoaded: false
                });
            });
    }


    HandleChange = (e) => {
        this.setState({
            formComment: {
                ...this.state.formComment,
                comment: (e.target.value.length + 0 > 500 ? this.state.formComment.comment : e.target.value),
            }
        });
    }

    HandleChangeUpdateComment = (e) => {
        this.setState({
            formUpdateComment: {
                ...this.state.formUpdateComment,
                comment: e.target.value,
            }
        });
    }

    editComment = (comentario) => {
        let arrayUsersEdit = []
        let arrayIds = []

        comentario.users.map((item) => (
            arrayUsersEdit.push({ label: `${item.first_name} ${item.first_last_name}`, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            formUpdateComment: {
                ...this.state.formUpdateComment,
                comment: comentario.comment,
                user_ids: arrayIds
            },

            id: comentario.id,
            editValuesUsers: arrayUsersEdit,
            showSubmitComment: false,
            modeEditComment: true,
        })
    }


    deleteComment = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/general_comments/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.loadComments();
                        this.props.loadData();
                        //this.props.updateItem(response.task)
                    });
            }
        });
    };

    clearValues = () => {
        this.setState({
            showSubmitComment: false,
            modeEditComment: false,
            isLoadedCreate: false,
            editValuesUsers: [],
            formComment: {
                ...this.state.formComment,
                comment: "",
                notify: true,
                user_ids: [],
            }
        })
    }

    updateDataComents = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    updateItemComents = comment => {
        this.setState({
            data: this.state.data.map(item => {
                if (comment.id === item.id) {
                    return {
                        ...item,
                        comment: comment.comment,
                        users: comment.users,
                    }
                }
                return item;
            })
        });
    }

    HandleClickComment = () => {
        this.setState({ isLoadedCreate: true })

        if (this.state.id != "") {
            fetch(`/general_comments/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formUpdateComment), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateItemComents(data.comment)
                    this.setState({ id: "", modeEditComment: false, editValuesUsers: [], isLoadedCreate: false })
                });
        } else {
            fetch(`/general_comments`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formComment), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    //if (this.props.updateItem != undefined) {
                        //this.props.updateItem(data.task)
                    //}
                    if(this.props.loadData){
                        this.props.loadData();
                    }
                    this.updateDataComents(data.comment)
                    this.clearValues();
                });
        }
    }

    getDateTime = (datetime) => {
        let date = new Date(datetime)
        let result = `${date.getFullYear()}-${(date.getMonth() < 10 ? 0 : "") + (date.getMonth() + 1)}-${(date.getDate() < 10 ? 0 : "") + date.getDate()}`
        return result
    }


    addFiles = (comment) => {
        this.setState({
            module_file_id: comment.module_file_id,
            modaFiles: true,
        })
    }


    //files 

    HandleChangeFiles = (e) => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                [e.target.name]: e.target.value
            }
        });
    }


    clearValuesFiles = () => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                fileName: "",
                file: {},
            },

            isLoadedFiles: false,
            isLoadedCreate: false,
            nameFile: "",
            file: null
        })
    }

    toogleFiles = (from) => {
        if (from == "new") {
            this.setState({ modaFiles: true })
        } else {
            this.setState({ modaFiles: false })
        }
    }

    HandleChangeCheckBox = (e) => {
        this.setState({
            formComment: {
                ...this.state.formComment,
                notify: e.target.checked,
            }
        });
    }

    clearValuesReply = () => {
        this.setState({
            formReply: {
                ...this.state.formReply,
                comment: {}
            },

            formValuesReply: {
                id: "",
                reply: "",
            },
        })
    }


    deleteReply = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/reply_comments/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(response => response.json())
                .then(response => {
                    this.loadComments();
                });
            }
        });
    }

    editReply = (reply, comment) => {
        this.setState({
            formValuesReply: {
                id: reply.id,
                reply: reply.reply,
            },
        
            formReply: {
                ...this.state.formReply,
                comment: comment
            },
        })
    }

    render() {
        const options = {

            buttons: {
                iconColor: "rgb(255, 255, 255)",
                showDownloadButton: false,
                showNextButton: false,
                showPrevButton: false,
                showAutoplayButton: false,
            },

            thumbnails: {
                showThumbnails: false,
            },

            caption: {
                captionFontFamily: "Raleway, sans-serif",
                captionFontWeight: "300",
                captionTextTransform: "uppercase",
            }

        };

        return (
            <React.Fragment>
                {this.state.modaFiles && (
                    <FormCreateFiles
                        //modal props

                        backdrop={"static"}
                        modal={this.state.modaFiles}
                        toggle={this.toogleFiles}
                        title={"Agregar Archivos"}

                        //form props

                        module_file_id={this.state.module_file_id}
                        formValues={this.state.formAddFiles}
                        onChangeForm={this.HandleChangeFiles}
                        submitForm={this.HandleClickFiles}

                        //table files 

                        dataFiles={this.state.dataUsersFiles}
                        handleFileChange={this.handleFile}

                        //load info

                        loadDataTable={this.loadComments}

                        module_id={"user_id"}
                        idGeneralFile={this.state.idGeneralFile}

                        isLoadedFiles={this.state.isLoadedFiles}

                        nameFile={this.state.nameFile}
                        sizeFile={this.state.sizeFile}
                    />
                )}

                <div className="col-md-12 mt-4">
                    <div className="row">
                        <div className='col-md-1 text-center'>
                            <i
                                className="fas fa-comments"
                                style={{ fontSize: "29px" }}
                            >
                            </i>
                        </div>

                        <div className='col-md-11'>
                            <h4>Seguimientos y/o comentarios</h4>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mt-4">
                    <div className="row">

                        <div className='col-md-1 text-center'>
                            <img
                                src={this.props.user.avatar} alt=""
                                className="img-user-task"
                            />
                        </div>

                        <div className='col-md-11'>
                            <b>{this.props.user.name}</b>

                            <div className="card">
                                <textarea
                                    type="text"
                                    className="form form-control"
                                    placeholder="Escriba un seguimiento y/o comentario..."
                                    value={this.state.formComment.comment}
                                    onChange={this.HandleChange}
                                    onClick={() => this.setState({ showSubmitComment: true, id: "" })}
                                    rows="4"
                                    style={{ resize: "none" }}
                                >
                                </textarea>
                            </div>
                            {this.state.showSubmitComment && (
                                <React.Fragment>
                                    <span className="ui-index-taskComment__container-count" style={{ color: (this.state.formComment.comment.length + 0 >= 500 ? "red" : "#3f6ad8") }}>
                                        Caracteres restantes: {500 - this.state.formComment.comment.length}
                                    </span>
                                    <div className="col-md-12 text-right pr-0 mt-2">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id={`customShowFilter`} checked={this.state.formComment.notify} onChange={this.HandleChangeCheckBox} />
                                            <label className="custom-control-label" htmlFor={`customShowFilter`}>Notificar a los interesados</label>
                                        </div>
                                    </div>

                                    <button 
                                        className="btn-shadow btn btn-success mt-3 mr-2" 
                                        disabled={(this.state.formComment.comment.length >= 500 || this.state.isLoadedCreate) ? true : false} 
                                        onClick={() => this.HandleClickComment()}
                                    >
                                        {this.state.isLoadedCreate ? "Cargando..." : "Crear" }
                                    </button>
                                    <button className="btn btn-outline-danger mt-3" onClick={() => this.clearValues()}>Cancelar</button>
                                </React.Fragment>
                            )}

                        </div>

                    </div>
                </div>

                {this.state.data.length >= 1 && (
                    <div className="col-md-12 mt-4">
                        <hr />
                        {this.state.data.map((commentario, index) => (
                            <div key={commentario.id} className={`row ${index >= 1 ? 'mt-3' : ""}`}>
                                <div className='col-md-1 text-center'>
                                    <SimpleReactLightbox>
                                        <SRLWrapper options={options}>
                                            <img
                                                src={commentario.user != null ? commentario.user.avatar : "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRuEG5mQKFGi9hNgrdBJnEyBfw2Kl0JNXwoHiXjVeKQemKOQqFe"}
                                                style={{ cursor: "pointer" }}
                                                className="img-user-task"
                                            />
                                        </SRLWrapper>
                                    </SimpleReactLightbox>
                                </div>

                                <div className='col-md-11'>
                                    <b>{commentario.user != null ? commentario.user.first_name : "Usuario"} {commentario.user != null ? commentario.user.first_last_name : ""}</b> <span style={{ color: "gray" }}>({this.getDateTime(commentario.created_at)})</span>
                                    <div className="card">
                                        <div className="card-body" style={{ padding: "10px" }}>
                                            {commentario.id == this.state.id ? (
                                                <React.Fragment>
                                                    <textarea
                                                        type="text"
                                                        className="form form-control"
                                                        placeholder="Escriba un seguimiento y/o comentario..."
                                                        value={this.state.formUpdateComment.comment}
                                                        onChange={this.HandleChangeUpdateComment}
                                                        rows="5"
                                                        style={{ resize: "none" }}
                                                    >
                                                    </textarea>

                                                    {false && (
                                                        <div className="mt-3">
                                                            <input
                                                                type="hidden"
                                                                name="user_ids"
                                                                value={this.state.selectedOptionComment.user_ids}
                                                            />

                                                            <Select
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                autoFocus={false}
                                                                className={`link-form`}
                                                                classNamePrefix="select"
                                                                placeholder="Seleccione"
                                                                name="user_ids"
                                                                onChange={this.handleChangeAutocompleteMulti}
                                                                options={this.state.users}
                                                                defaultValue={this.state.editValuesUsers}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <p className="mb-0">{commentario.comment}</p>
                                            )}

                                            <div className="collapse" id={`collapseExample${commentario.id}`}>
                                                {commentario.reply_comments.length >= 1 && (
                                                    <div className="w-100 p-0">
                                                        <hr />
                                                    </div>
                                                )}

                                                <div className="row">
                                                    <div className="col-md-1"></div>

                                                    <div className="col-md-10">
                                                        {commentario.reply_comments.map((reply, index) => (
                                                            <div className={`row ${index >= 1 ? 'mt-3' : ""}`} key={reply.id}>
                                                                <div className='col-md-2 text-center'>
                                                                    <SimpleReactLightbox>
                                                                        <SRLWrapper options={options}>
                                                                            <img
                                                                                src={reply.user.avatar.url}
                                                                                alt={reply.name}
                                                                                style={{ cursor: "pointer" }}
                                                                                className="img-user-task"
                                                                            />
                                                                        </SRLWrapper>
                                                                    </SimpleReactLightbox>
                                                                </div>

                                                                <div className='col-md-10 p-0'>
                                                                    <b>{reply.user.name}</b>
                                                                    <div className="card">
                                                                        <div className="card-body" style={{ padding: "10px" }}>
                                                                            {reply.id == this.state.formValuesReply.id ? (
                                                                                <React.Fragment>
                                                                                    <FormCreateReply
                                                                                        user={this.props.user}
                                                                                        modeEdit={true}
                                                                                        comment={this.state.formReply.comment}
                                                                                        formValuesReply={this.state.formValuesReply}
                                                                                        loadComments={this.loadComments}
                                                                                        clearValuesReply={this.clearValuesReply}
                                                                                    />
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <p className="mb-0">{reply.reply}</p>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    {(reply.user.id == this.props.user.id && this.props.task.state != "filed") && (
                                                                        <React.Fragment>
                                                                            <span className="action-comment mr-2" onClick={() => this.editReply(reply, commentario)}>Editar</span>
                                                                            <span className="action-comment" onClick={() => this.deleteReply(reply.id)}>Eliminar</span>
                                                                        </React.Fragment>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        {(commentario.id == this.state.id && this.state.id != "") && (
                                            <React.Fragment>
                                                <div className="card-footer">
                                                    <button className="btn-shadow btn btn-success mr-2" onClick={() => this.HandleClickComment()}>Actualizar</button>
                                                    <button className="btn btn-outline-danger" onClick={() => this.setState({ id: "", modeEditComment: false, editValuesUsers: [] })}>Cancelar</button>
                                                </div>
                                            </React.Fragment>
                                        )}

                                    </div>

                                    <div className="row">

                                        {(commentario.id == this.state.formReply.comment.id && this.state.formValuesReply.id == "") && (
                                            <React.Fragment>
                                                <div className="col-md-12 mt-2">
                                                    <div className="card">
                                                        <div className="card-body p-2">
                                                            <FormCreateReply
                                                                user={this.props.user}
                                                                modeEdit={false}
                                                                comment={this.state.formReply.comment}
                                                                loadComments={this.loadComments}
                                                                clearValuesReply={this.clearValuesReply}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}

                                        {(commentario.user.id == this.props.user.id && this.props.task.state != "filed") ? (
                                            <div className="col-md-6 mt-2 ui-taskComment-index-container-edit-delete">
                                                <div className="row ui-showTask-comment-row">
                                                    <div className="col-md-2 text-right ui-showTask-comment">
                                                        <span className="action-comment" onClick={() => this.editComment(commentario)}>Editar</span>
                                                    </div>

                                                    <div className="col-md-2 pr-0 ui-showTask-comment">
                                                        <span className="action-comment" onClick={() => this.deleteComment(commentario.id)}>Eliminar</span>
                                                    </div>

                                                    {commentario.reply_comments.length >= 1 && (
                                                        <span className="reply-link ml-5" data-toggle="collapse" href={`#collapseExample${commentario.id}`} role="button" aria-expanded="false" aria-controls="collapseExample">
                                                            {`Ver respuestas (${commentario.reply_comments.length})`}
                                                        </span>
                                                    )}
                                                    {isMobile && (
                                                        <React.Fragment>
                                                            {commentario.module_file_id != null && (
                                                                <div className={(commentario.user != null ? commentario.user.id : true) == this.props.user.id ? "col-md-6 mt-2 text-right ui-icon-index-count-adjuntos" : "col-md-6 mt-2 text-left"}>
                                                                    <i className="fas fa-paperclip ml-2" style={{ cursor: "pointer" }} onClick={() => this.addFiles(commentario)}></i> <span>{commentario.general_files}</span>
                                                                </div>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <div className="pl-3 mt-2">
                                                    {this.props.task.state != "filed" && (
                                                        <span className="mb-0 reply-link mr-2" onClick={() => this.selectComment(commentario)}>
                                                            {(commentario.id == this.state.formReply.comment.id && this.state.formValuesReply.id == "") ? "" : "Responder"}
                                                        </span>  
                                                    )}

                                                    {commentario.reply_comments.length >= 1 && (
                                                        <span className="reply-link" data-toggle="collapse" href={`#collapseExample${commentario.id}`} role="button" aria-expanded="false" aria-controls="collapseExample">
                                                            {`Ver respuestas (${commentario.reply_comments.length})`}
                                                        </span>
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        )}
                                        {commentario.module_file_id != null && (
                                            <div className={(commentario.user != null ? commentario.user.id : true) == this.props.user.id ? "col-md-6 mt-2 text-right ui-icon-index-count-adjuntos ui-mobile-adjuntos" : "col-md-6 mt-2 text-left"}>
                                                <i className="fas fa-paperclip ml-2" style={{ cursor: "pointer" }} onClick={() => this.addFiles(commentario)}></i> <span>{commentario.general_files}</span>
                                            </div>
                                        )}

                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Index;
