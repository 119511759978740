import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreateFiles from './FormGeneralFile'
import { isMobile } from 'react-device-detect';
import FormCreate from "../components/settings/GeneralComments/FormCreate";
import FormCreateReply from "../components/settings/CommentReplies/FormCreate";
import { array } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import "./style.css"

class ChatBoxModuleComment extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            module_file_id: "",
            data: [],   
            editValuesUsers: [],
            editValuesUsersReply: [],
            users: [],
            pdfs: [],
            modaFiles: false,
            modal: false,
            modeEdit: false,
            show_external_email: false,
            isPdfSelected: false,
            errorValues: true,
            
            form: {
                comment: "",
                module_name: this.props.nameModule != undefined ? this.props.nameModule : "",
                module_comment_id: this.props.module_comment,
                send_register: false,
                format_pdf_id: "",
                external_emails: "",
                url: this.props.url,
            },

            formCreateReply: {
                general_comment_id: "", 
                comment: "",
                comment_reply_id: "",
                user_ids: []
            },

            modeEditReply: false,
            modalReply: false,
            comment_reply_id: "",

            selectedOptionComment: {
                user_ids: [],
                label: "Responsables"
            },

            selectedOptionReply: {
                user_ids: [],
                label: "Responsables"
            },

            selectedOptionPdfs: {
                format_pdf_id: "",
                label: ""
            },

            formUpdate: {
                comment: "",
            }
        }
    }
    
    componentDidMount(){
        this.loadData();
    }


    messageSuccess = (response) => {
        Swal.fire({
          position: "center",
          type: `${response.type}`,
          title: `${response.success}`,
          showConfirmButton: false,
          timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            show_external_email: false,
            errorValues: true,
            form: {
              ...this.state.form,
              send_register: false,
              format_pdf_id: "",
              external_emails: "",
              comment: "",
              user_ids:[]
            },

            selectedOptionPdfs: {
                format_pdf_id: "",
                label: ""
            },
        });
    }
    

    validationForm = () => {
        if ( this.state.form.send_register &&
            this.state.isPdfSelected
        ) {
            this.setState({ ErrorValues: false})
            return false

        } else if( this.state.form.send_register && !this.state.isPdfSelected){
            this.setState({ ErrorValues: true })
            return true       

        } else if ( !this.state.form.send_register && !this.state.isPdfSelected){
            this.setState({ ErrorValues: false})
            return false
        }
    }

    HandleChangeCheckBox = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                format_pdf_id: "",
                send_register: e.target.checked,
            },

            selectedOptionPdfs: {
                format_pdf_id: "",
                label: ""
            },
        });
    }    

    HandleChange = (e) => {
        if(this.state.modeEdit){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    [e.target.name]: e.target.value
                }
            });
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    editComment = (comment) => {
        let arrayUsersEdit = []
        let arrayIds =[]

        console.log("comment", comment);

        comment.users.map((item) => (
            arrayUsersEdit.push({ label: `${item.first_name} ${item.first_last_name}`, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modeEdit: true,
            modal: true,
            id: comment.id,
            editValuesUsers: arrayUsersEdit,

            formUpdate: {
                comment: comment.comment,
                users_ids: arrayIds,
            }
        })
    
        
    }

    handleChangeAutocompleteMulti = selectedOption => {
        let array = []

        if (selectedOption){
            selectedOption.map((item) => (
                array.push(item.value)
            ))
        }

        if(this.state.modeEdit){
            this.setState({
                formUpdate: {
                    ...this.state.formUpdate,
                    user_ids: selectedOption ? array : [],
                }
            })
        }else{
            this.setState({
                form: {
                    ...this.state.form,
                    user_ids: selectedOption ? array : [],
                }
            })
        }
    }

    handleChangeAutocompleteMultiReply = selectedOptionReply => {
        let array = []

        if (selectedOptionReply){
            selectedOptionReply.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            formCreateReply: {
                ...this.state.formCreateReply,
                user_ids: selectedOptionReply ? array : [],
            }
        })
    }

    handleChangeCkaditor = (e) => {
        if(this.state.modeEdit){


            this.setState({
                formUpdate: {
                    ...this.state.form,
                    comment: e
                }
            })

        }else{

            this.setState({
                form: {
                    ...this.state.form,
                    comment: e
                }
            })

        }
    }

    HandleClick = () => {
        if (!this.validationForm()) {        
      
            if (this.state.modeEdit) {
                fetch(`/general_comments/${this.state.id}`, {
                    method: 'PATCH',
                    body: JSON.stringify(this.state.formUpdate),
                    headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateItem(data.comment);
                    this.messageSuccess(data);
                    this.setState({ id: "", modal: false, modeEdit: false });
            });
            } else {
            fetch(`/general_comments`, {
                method: 'POST',
                body: JSON.stringify(this.state.form),
                headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.updateData(data.comment);
                this.setState({ modal: false });
                this.clearValues();
                this.messageSuccess(data);
        
                if (this.props.updateStateCountComment != undefined) {
                this.props.updateStateCountComment(this.props.countComment + 1);
                }
            });
            }
        }
    }
      

    loadData = () => {
        fetch(`/get_general_commets_indicators/${this.props.module_comment}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
            });
        });
    }

    delete = id => {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009688",
          cancelButtonColor: "#d33",
          confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.value) {
            fetch(`/general_comments/${id}`, {
              method: "delete",
              headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(response => {
                this.loadData()
                this.messageSuccess(response);
                if(this.props.updateStateCountComment != undefined){
                    this.props.updateStateCountComment(this.props.countComment - 1)
                }
              });
          }
        });
    };

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })

    }

    //add update
    updateItem = comment => {
        this.setState({
            data: this.state.data.map(item => {
            if (comment.id === item.id) {
              return { ...item, 
                comment: comment.comment,
                users: comment.users,
              }
            }
            return item;
          })
        });
    }

    dateTime = (fecha) => {
        var d = new Date(fecha),
          months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + d.getDate() + " " + 'del' + " " + d.getFullYear()
    }

    //files 

    clearValuesFiles = () => {
        this.setState({
            formAddFiles: {
                ...this.state.formAddFiles,
                fileName: "",
                file: {},
            },

            isLoadedFiles: false,
            nameFile: "",
            file: null
        })
    }

    addFiles = (comment) => {
        this.setState({
            module_file_id: comment.module_file_id,
            modaFiles: true,
        })
    }

    HandleChangeFiles = (e) => {
        this.setState({
            formAddFiles: {
              ...this.state.formAddFiles,
              [e.target.name]: e.target.value
            }
        });
    }

    toogleFiles = (from) => {
        if(from == "new"){
            this.setState({ modaFiles: true})
            this.clearValues();
        }else{
            this.setState({ modaFiles: false})
        }
    }

    toogle = (from) => {
        if(from == "new"){
            this.setState({ modal: true})
        }else{
            this.setState({ modal: false, modeEdit: false, editValuesUsers: [] })
        }
    }

    HandleChangeCheckBoxEmail = (e) => {
        this.setState({
            show_external_email: e.target.checked
        });
    }
    
    
    handleChangeAutocompletePdfs = (selectedOptionPdfs) => {
        const isPdfSelected = !!selectedOptionPdfs;
        this.setState({
            selectedOptionPdfs,
                form: {
                    ...this.state.form,
                    format_pdf_id: selectedOptionPdfs ? selectedOptionPdfs.value : "",
                },
            isPdfSelected
        });
    };

    //reply commets functions

    replyComment = (comment) => {
        this.setState({
            formCreateReply: {
                ...this.state.formCreateReply,
                general_comment_id: comment.id, 
                comment: "",
                comment_reply_id: "",
            },

            modeEditReply: false,
            modalReply: true,
            comment_reply_id: "",
        })
    }

    replySubComment = (comment) => {
        this.setState({
            formCreateReply: {
                ...this.state.formCreateReply,
                general_comment_id: "", 
                comment: "",
                comment_reply_id: comment.id,
            },

            modeEditReply: false,
            modalReply: true,
            comment_reply_id: "",
        })
    }

    deleteReply = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
              fetch(`/settings/comment_replies/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
              })
                .then(response => response.json())
                .then(response => {
                    if (response.type != "error"){
                        this.loadData()
                        this.messageSuccess(response);
                    }else{
                        this.messageSuccess(response);
                    }
                });
            }
        });
    }

    toogleReply = (from) => {
        if(from == "new"){
            this.setState({ modalReply: true, editValuesUsersReply: [] })
        }else{
            this.setState({ modalReply: false, modeEditReply: false, editValuesUsersReply: [] })
        }
    }
    
    handleChangeCkaditorReply = (e) => {
        this.setState({
            formCreateReply: {
                ...this.state.formCreateReply,
                comment: e
            }
        })
    }

    HandleClickReply = () => {
        if(this.state.modeEditReply){
            fetch(`/settings/comment_replies/${this.state.comment_reply_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formCreateReply), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                if (data.type != "error"){
                    this.loadData();
                    this.messageSuccess(data);
                    this.setState({ modalReply: false, modeEditReply: false})
                }else{
                    this.messageSuccess(data);
                    this.setState({ modalReply: false, modeEditReply: false})
                }
            });
        }else{
            fetch(`/settings/comment_replies`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formCreateReply), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.loadData();
                this.messageSuccess(data);
                this.setState({ modalReply: false, modeEditReply: false})
            });
        }
    }


    editReply = (reply) => {
        let array = []
        
        if(reply.users && reply.users.length >= 1){
            reply.users.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            formCreateReply: {
                ...this.state.formCreateReply,
                general_comment_id: reply.general_comment_id, 
                comment: reply.comment,
                comment_reply_id: reply.comment_reply_id,
                user_ids: array
            },

            modeEditReply: true,
            modalReply: true,
            comment_reply_id: reply.id,
            editValuesUsersReply: reply.users && reply.users.length >= 1 ? reply.users : [],
        })
    }

    date = (fecha) => {
        var d = new Date(fecha),
        months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + d.getDate() + " " + 'del' + " " + d.getFullYear();
    }

    renderComments = (data) => {
        return (
            <React.Fragment>
                {data.map((reply, index) => (
                    <div className="comment" id="comment-2" key={reply.id}>
                        {reply.replies.length >= 1 && (
                            <a href="#comment-2" className="comment-border-link">
                                <span className="sr-only">Jump to comment-2</span>
                            </a>   
                        )}
                                                                        
                        <summary>
                            <div className="comment-heading">
                                <div className="comment-info">
                                    <img src={reply.user.avatar} className="avatar-reply" alt="" />
                                    <span href="#" className="comment-author"> {reply.user.name}</span>
                                </div>
                            </div>
                        </summary>
                                                        
                        <div className="comment-body">
                            <p className="comment-text">{ReactHtmlParser(reply.comment)}</p>

                            <span className="link-options" onClick={() => this.replySubComment(reply)}>Responder</span>
                            {this.props.usuario.id == reply.user.id && (
                                <React.Fragment>
                                    <i className="fas fa-pencil-alt ml-2 mr-2" style={{cursor: "pointer"}} onClick={() => this.editReply(reply)}></i>
                                    <i className="far fa-trash-alt" style={{cursor: "pointer"}} onClick={() => this.deleteReply(reply.id)}></i>
                                </React.Fragment>
                            )}
                        </div>

                        <div className="replies">
                            {this.renderComments(reply.replies)}
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }

    countReplies = (comment) => {
        let count = comment.replies.length;
    
        comment.replies.forEach(reply => {
            count += this.countReplies(reply);
        });
    
        return count;
    }
    
    renderCountComments = () => {
        let totalCommentCount = this.state.data.length;
    
        this.state.data.forEach(comment => {
            totalCommentCount += this.countReplies(comment);
        });
    
        console.log(totalCommentCount);
        return totalCommentCount;
    }

    render() {
        return (

            <div className="card-hover-shadow-2x card card-list"> 
                <div className={`card-header-tab card-header card-header-document`}>
                    <div className="w-100">
                        <div className="row">
                            <div className={`col-${isMobile ? "7" : "8"} text-left` }>
                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal ui-chatBoxModuleComment__container_header_card">
                                    <i className="header-icon far fa-comment"> </i>Comentarios 
                                    <span className='ml-2 ui-chatBoxModuleComment__container_length_comments'><i className="far fa-comments mr-2"></i>{this.state.data.length >= 1 ?  this.renderCountComments() : <i className="fas fa-ban"></i>}</span>
                                </div>
                            </div>
                            

                            <div className={`col-${isMobile ? "5" : "4"} text-right`}>
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.toogle("new")}
                                    style={{ fontSize: isMobile ? "8px" : "" }}
                                >
                                    Nuevo comentario
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.modaFiles && (
                    <FormCreateFiles
                        //modal props

                        backdrop={"static"}
                        modal={this.state.modaFiles}
                        toggle={this.toogleFiles}    
                        title={"Agregar Archivos"}

                        //form props

                        module_file_id={this.state.module_file_id}
                        formValues={this.state.formAddFiles}
                        onChangeForm={this.HandleChangeFiles}
                        submitForm={this.HandleClickFiles}

                        //table files 

                        dataFiles={this.state.dataUsersFiles}
                        handleFileChange={this.handleFile}

                        //load info

                        loadDataTable={this.loadData}

                        module_id={"user_id"}
                        idGeneralFile={this.state.idGeneralFile}     
                        
                        isLoadedFiles={this.state.isLoadedFiles}

                        nameFile={this.state.nameFile}
                        sizeFile={this.state.sizeFile}
                    />
                )}

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar comentario" : "Crear comentario"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                        //form props
                        formValues={this.state.modeEdit ? this.state.formUpdate : this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}

                        //select multi

                        HandleChangeCheckBox={this.HandleChangeCheckBox}
                        HandleChangeCheckBoxEmail={this.HandleChangeCheckBoxEmail}
                        selectedOptionPdfs={this.state.selectedOptionPdfs}
                        handleChangeAutocompletePdfs={this.handleChangeAutocompletePdfs}
                        pdfs={this.props.pdfs}
                        show_external_email={this.state.show_external_email}
                        handleChangeCkaditor={this.handleChangeCkaditor}

                        editValuesUsers={this.state.editValuesUsers}
                        users={this.props.users}
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}
                        selectedOptionComment={this.state.selectedOptionComment}
                        showConfig={this.props.showConfig}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                        isPdfSelected={this.state.isPdfSelected}
                        errorValues={this.state.errorValues}
                    />
                )}

                {this.state.modalReply && (
                    <FormCreateReply
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalReply}
                        toggle={this.toogleReply}
                        title={this.state.modeEditReply ? "Actualizar respuesta" : "Responder"}
                        nameBnt={this.state.modeEditReply ? "Actualizar" : "Crear"}

                        //form props
                        formValues={this.state.formCreateReply}
                        submitForm={this.HandleClickReply}

                        handleChangeCkaditor={this.handleChangeCkaditorReply}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}

                        users={this.props.users}
                        handleChangeAutocompleteMultiReply={this.handleChangeAutocompleteMultiReply}
                        editValuesUsersReply={this.state.editValuesUsersReply}
                        selectedOptionReply={this.state.selectedOptionReply}
                    />
                )}

                <div className="scroll-area-lg ui-chatBoxModuleComments__container_commets">
                    <div className="scrollbar-container">
                        <div className="p-2">
                            <div className="chat-wrapper p-1">
                                <div className="chat-box-wrapper">
                                    <div className={this.state.data.length >= 1 ? "" : "w-100"}>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map(comment => (
                                                <div className="comment-thread" key={comment.id}>
                                                    <div className="comment" id="comment-1">

                                                        {comment.replies.length >= 1 && (
                                                            <a href="#comment-1" className="comment-border-link">
                                                                <span className="sr-only">Jump to comment-1</span>
                                                            </a>
                                                        )}

                                                        <summary>
                                                            <div className="comment-heading"> 
                                                                <div className="comment-info">
                                                                    <img src={ comment.user.avatar ? comment.user.avatar : "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRuEG5mQKFGi9hNgrdBJnEyBfw2Kl0JNXwoHiXjVeKQemKOQqFe"} className="avatar-reply" alt="" />
                                                                    <b href="#" className="comment-author">{comment.user.first_name} {comment.user.first_last_name} ({this.date(comment.created_at)})</b>
                                                                </div>
                                                            </div>
                                                        </summary>
                                                
                                                        <div className="comment-body">
                                                            <p className="comment-text">
                                                                {ReactHtmlParser(comment.comment)}
                                                            </p>

                                                            <span className="link-options" onClick={() => this.replyComment(comment)}>Responder</span>
                                                            {this.props.usuario.id == comment.user.id && (
                                                                <React.Fragment>
                                                                    <i className="fas fa-pencil-alt ml-2 mr-2" style={{cursor: "pointer"}} onClick={() => this.editComment(comment)}></i>
                                                                    <i className="far fa-trash-alt" style={{cursor: "pointer"}} onClick={() => this.delete(comment.id)}></i>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                
                                                        <div className="replies">
                                                            {this.renderComments(comment.replies)}
                                                        </div>

                                                    </div>
                                                    
                                                </div>
                                            ))
                                        ) : (
                                            <React.Fragment>
                                                <div className="no-comments">
                                                    <i className="far fa-comments"></i><br/>
                                                    <h1>No hay comentarios</h1>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        );
    }
}

export default ChatBoxModuleComment;


