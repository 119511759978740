import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"
import Preloader from '../GeneralComponents/LoadingComponen'
import FroalaEditor from 'react-froala-wysiwyg';

import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

class DocumentEdit extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            formUpdate: {
                document: this.props.document.document,
            }
        }
    }


    handleChangeFroala = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                document: e
            }
        });
    }

    handleClickUpdate = () => {
        fetch(`/document_management/update_text/${this.props.document.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
           Turbolinks.visit(`/document_management/documents/${this.props.document.token}`);
        });
    }

    render() {
        const config = {
            language: 'es',
            quickInsertEnabled: false,
            colorsHEXInput: true,
            autoFocus: true,
            toolbartop: true,
            linkAlwaysBlank: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            htmlExecuteScripts: true,
            imageUploadToS3: this.props.imageUpload,
            key: this.props.id,
            iframe: true,
            tabSpaces: 4,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                    // Alignment of the group in the toolbar.
                    align: 'left',

                    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                    buttonsVisible: 12
                },


                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                    align: 'left',
                    buttonsVisible: 11
                },

                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR'],
                    align: 'left',
                    buttonsVisible: 10
                },

                moreMisc: {
                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'html', 'help'],
                    align: 'right',
                    buttonsVisible: 9
                }
            }
        }

        return (
            <React.Fragment>
                <HeaderTitle
                    title={this.props.document.name}
                    subTitle={"Gestión documental"}
                    titleNew={"Nuevo documento"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    showNew={false}
                    showFilter={false}
                />

                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-10">
                            <FroalaEditor
                                model={this.state.formUpdate.document}
                                config={config}
                                onModelChange={(e) => this.handleChangeFroala(e)}
                            />

                            <div className="card mt-3">
                                <div className="card-body">
                                    <div className="justify-content-md-center text-center">
                                        <button
                                            type="button"
                                            className="btn-shadow btn btn-outline-info mr-3"
                                            onClick={() => this.handleClickUpdate()}
                                        >
                                            Actualizar
                                        </button>

                                        <a
                                            type="button"
                                            href={`/document_management/documents/${this.props.document.token}`}
                                            data-turbolinks="true"
                                            className="btn-shadow btn btn-outline-info"
                                        >
                                            Volver
                                        </a>  
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default DocumentEdit;
WebpackerReact.setup({ DocumentEdit });

