import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CheckboxContainer from './CheckboxContainer'
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: true,
            categories: []
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    componentDidMount = () => {
        if(this.props.modeEdit){
            this.loadCategories();
        }
    }

    loadCategories = () => {
        fetch(`/formatos/get_all_categories`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.setState({
                categories: data.data,
                isLoaded: false
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">

                                <div className="col-md-12">
                                    <label>Nombre </label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={this.props.formValues.name}
                                        onChange={this.props.onChangeForm}
                                        className={`form form-control ${!this.props.errorValues && this.props.formValues.name == "" ? "error-class" : ""}`}
                                        placeholder="Nombre"
                                    />
                                </div>

                                {(this.props.modeEdit && !this.state.isLoaded) && (
                                    <div className="col-md-12 mt-3">
                                        <input
                                            type="hidden"
                                            name="format_category_id"
                                            value={this.props.selectedOptionFormatCategory.format_category_id}
                                        />
                                        <label>Categoría</label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteFormatCategory}
                                            options={this.state.categories}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionFormatCategory}
                                        />
                                    </div>
                                )}

                                {false && (
                                    <div className="col-md-12 mt-3">
                                        <input
                                            type="hidden"
                                            name="format_sub_category_id"
                                            value={this.props.selectedOptionSubCategory.format_sub_category_id}
                                        />
                                        <label>Sub Módulo</label>
                                        <Select
                                            onChange={this.props.handleChangeAutocompleteSubCategory}
                                            options={this.props.format_sub_categories}
                                            autoFocus={false}
                                            className={`link-form`}
                                            value={this.props.selectedOptionSubCategory}
                                        />
                                    </div>
                                )}

                                <div className="col-md-12 mt-2">
                                    <h5 className="text-center mb-2 mt-2 title-format">Agregar permisos al formato</h5>
                                    <CheckboxContainer
                                        checkedItems={this.props.checkedItems}
                                        handleChangeAccions={this.props.handleChangeAccions}
                                        checkboxes={this.props.checkboxes}
                                        modules={this.props.modules}
                                        handleChangeSelectAll={this.props.handleChangeSelectAll}
                                        modulo_ids={this.props.modulo_ids}
                                        formValues={this.props.formValues}
                                        errorValues={this.props.errorValues}
                                    />
                                </div>

                                {!this.props.errorValues && (
                                    <div className="col-md-12 mt-4">
                                        <div className="alert alert-danger" role="alert">
                                            <b>Debes de completar todos los campos requeridos</b>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                            <button 
                                className="btn-shadow btn btn-info" 
                                onClick={this.props.submitForm}
                                disabled={this.props.isLoaded}
                            >
                                {this.props.isLoaded ? "Cargando..." : this.props.nameBnt}
                            </button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;