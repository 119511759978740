import React, { Component } from 'react';
import { Button } from 'reactstrap';
// import WebpackerReact from 'webpacker-react';
import ReactHtmlParser from 'react-html-parser';
import ShowTask from '../../tasks/tasksCard/ShowTask';
import { isMobile } from 'react-device-detect';
import Preloader from '../../../GeneralComponents/LoadingComponen';

class NotificationTask extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            isLoadedData: false,
            dropdown: false,
            showBtn: false,
            modal: false,
            task: {},
        }
    }

    componentDidMount(){
        this.loadDataAnyNotifications();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add update
    updateItem = notification => {
        this.setState({
            data: this.state.data.map(item => {
                if (notification.id === item.id) {
                  return { ...item, 
                    date_create: notification.date_create,
                    date_view: notification.date_view,
                    description: notification.description,
                    state: notification.state,
                    task: notification.task,
                    title: notification.title,
                    user: notification.user,
                    user_create: notification.user_create,
                  }
                }
                return item;
            })
        }, () => {
            this.setState({ dropdown: this.state.data.filter(estado => estado.state == false).length >= 1 })
        });
    }

    loadDataAnyNotifications = () => {
        fetch(`/task/get_any_notifications`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                showBtn: data.data,
            });
        });
    }

    updateState = (notification_id, state) => {
        fetch(`/task/update_state_notification/${notification_id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.loadData();
        });
    }

    updateAll = () => {
        fetch(`/task/update_all_notification`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.loadData();
            this.setState({ dropdown: false })
        });
    }

    showTask = (notification) => {
        this.setState({
            modal: true,
            task: notification.task,
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false, task: {} })
        }
    }

    openDropdown = () => {
        this.setState({ dropdown: !this.state.dropdown, isLoadedData: true })
        this.loadData();
    }

    loadData = () => {
        fetch(`/task/get_notifications`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoadedData: false
            });
        });
    }


    render() {
        const styleButtom = {
            color: "#3f6ad8",
            cursor: "pointer"
        }

        return (
            <React.Fragment>
                {this.state.modal && (
                    <ShowTask
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Crear tarea"}

                        //form props
                        task={this.state.task}

                        users={this.props.users}

                        //load show
                        isLoadedShow={false}

                        //current_user
                        user={this.props.user}

                        //load tasks
                        loadData={this.props.loadData}

                        //test load data

                        updateItem={this.props.updateItem}
                        updateData={this.props.updateData}

                        //procesos 

                        procesos={this.props.procesos}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                    />
                )}

                <div className={`dropdown show`}>
                    {this.state.showBtn && (
                        <button type="button" className={`p-0 ${isMobile ? "ml-2" : "mr-2"} btn btn-link`} aria-haspopup="true" aria-expanded={this.state.dropdown} onClick={() => this.openDropdown()}>
                            <span className="icon-wrapper icon-wrapper-alt rounded-circle">
                                <span className="icon-wrapper-bg bg-danger"></span>
                                    <i className={`icon text-danger icon-anim-pulse ${this.state.dropdown ? "fas fa-times" : "fas fa-bell"}`}></i>
                                <span className="badge badge-dot badge-dot-sm badge-danger">10</span>
                            </span>
                        </button>
                    )}

                    <div 
                        tabIndex="-1" 
                        role="menu" 
                        aria-hidden="true" 
                        className={`dropdown-menu-xl rm-pointers test-dropdown dropdown-menu-right ${isMobile ? "test-dropdown-movil" : ""}`}
                        style={{ position: "absolute", top: "0px", left: "0px", transform: "translate3d(-358px, 46px, 0px)", display: this.state.dropdown ? "block" : "none", minWidth: "25rem" }}
                    
                    >                    
                        <div className="dropdown-menu-header mb-0">
                            <div className="dropdown-menu-header-inner bg-deep-blue">
                                <div className="menu-header-image opacity-1"></div>
                                <div className="menu-header-content text-dark">
                                    <h5 className="menu-header-title">Notificaciones de tareas</h5>
                                    <h6 className="menu-header-subtitle">Tienes <b>{this.state.data.filter(estado => estado.state == false).length}</b> notificación sin leer</h6>
                                </div>
                            </div>
                        </div>

                            <div className="tab-content">
                                <div className="tab-pane active" id="tab-messages-header" role="tabpanel">
                                    <div className="scroll-area-sm">
                                        <div className="scrollbar-container">
                                            <ul className="todo-list-wrapper list-group list-group-flush">
                                                {this.state.isLoadedData ? (
                                                    <div className="col-md-12 text-center mt-4">
                                                        <Preloader />
                                                    </div>
                                                ) : (
                                                    <React.Fragment>
                                                        {this.state.data.filter(estado => estado.state == false).length >= 1 ? (
                                                            <React.Fragment>
                                                                {this.state.data.filter(estado => estado.state == false).map(notification => (
                                                                    <li className="list-group-item" key={notification.id}>
                                                                        <div className="todo-indicator bg-success"></div>
                                                                        <div className="widget-content p-0">
                                                                            <div className="widget-content-wrapper">
                                                                                <div className="widget-content-left flex2">
                                                                                    <div className="widget-heading">{ReactHtmlParser(notification.title)}</div>
                                                                                    <div className="widget-subheading text-left" style={{ wordBreak: 'break-word'}}>
                                                                                        {ReactHtmlParser(notification.description)}
                                                                                    </div>
                                                                                    <a className="ml-1" style={styleButtom} onClick={() => this.showTask(notification)}>Ver tarea</a>
                                                                                </div>

                                                                                <div className="widget-content-right">
                                                                                    <button className="border-0 btn-transition btn btn-outline-success" onClick={() => this.updateState(notification.id, true)}>
                                                                                        <i className="fa fa-check"></i>
                                                                                    </button>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </React.Fragment>
                                                        ) : (
                                                            <div className="no-results pb-0 pt-0">
                                                                <div className="swal2-icon swal2-success swal2-animate-success-icon">
                                                                    <div className="swal2-success-circular-line-left" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
                                                                    <span className="swal2-success-line-tip"></span>
                                                                    <span className="swal2-success-line-long"></span>
                                                                    <div className="swal2-success-ring"></div>
                                                                    <div className="swal2-success-fix" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
                                                                    <div className="swal2-success-circular-line-right" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
                                                                </div>
                                                                <div className="results-subtitle">¡Estás al día con las notificaciones!</div>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/*<div className="tab-pane" id="tab-events-header" role="tabpanel">
                                    <div className="scroll-area-sm">
                                        <div className="scrollbar-container">
                                            <ul className="todo-list-wrapper list-group list-group-flush">
                                                {this.state.data.filter(estado => estado.state == true).length >= 1  ? (
                                                    <React.Fragment>
                                                        {this.state.data.filter(estado => estado.state == true).map(notification => (
                                                            <li className="list-group-item" key={notification.id}>
                                                                <div className="todo-indicator bg-success"></div>
                                                                <div className="widget-content p-0">
                                                                    <div className="widget-content-wrapper">
                                                                        <div className="widget-content-left flex2">
                                                                            <div className="widget-heading">{ReactHtmlParser(notification.title)}</div>
                                                                            <div className="widget-subheading text-left">{ReactHtmlParser(notification.description)}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </React.Fragment>
                                                ) : (
                                                    <div className="no-results pb-0 pt-0">
                                                        <div className="swal2-icon swal2-success swal2-animate-success-icon">
                                                            <div className="swal2-success-circular-line-left" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
                                                            <span className="swal2-success-line-tip"></span>
                                                            <span className="swal2-success-line-long"></span>
                                                            <div className="swal2-success-ring"></div>
                                                            <div className="swal2-success-fix" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
                                                            <div className="swal2-success-circular-line-right" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
                                                        </div>
                                                        <div className="results-subtitle">No hay notificaciones leidas!</div>
                                                    </div>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>*/}
                                
                            </div>

                            {true && (
                                <ul className="nav flex-column">
                                    <li className="nav-item-divider nav-item"></li>
                                    <li className="nav-item-btn text-center nav-item">
                                        {this.state.data.filter(estado => estado.state == false).length >= 1 && (
                                            <button className="btn-shadow btn-wide btn-pill btn btn-focus btn-sm" onClick={() => this.updateAll()}>Marcar todas como leídas</button>
                                        )}
                                        <button className="btn-shadow btn-wide btn-pill btn btn-danger btn-sm ml-3" onClick={() => this.setState({ dropdown: !this.state.dropdown })}>Cerrar</button>
                                    </li>
                                </ul>
                            )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default NotificationTask;
// WebpackerReact.setup({ NotificationTask });
