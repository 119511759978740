import React, { Component } from "react";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreateShow from "./FormCreateShow";
import Chart from "../../../GeneralComponents/Charts/ChartNormativity";
import HeaderTitle from "../../../GeneralComponents/HeaderTitle";
import Preloader from '../../../GeneralComponents/LoadingComponen'
import Comments from "../../../GeneralComponents/Comments"

class Show extends Component {
  constructor(props) {
    const date = new Date();
    super(props);
    this.fecha = `${date.getFullYear()}-${date.getMonth() < 10 ? 0 : ""}${date.getMonth()}-${date.getDate() < 10 ? 0 : ""}${date.getDate()}`
    this.token = document.querySelector("[name='csrf-token']").content;
    this.state = {
      data: this.props.califications,
      modal: false,
      modeEdit: false,
      accion: {},
      id: "",

      formValuesComments: {
        comment: "",
        module_name: this.props.nameModule,
        normativity_score_id: "",
      },

      formValues : {
        date_score: this.fecha,
        normativity_id: this.props.normativity.id,
        responsible_user_id: "",
        user_ids: []
      },

      selectedOption: {
        responsible_user_id: "",
        label: "Seleccionar"
      },

      users: [],
      dataCommets: [],
      editValues: [],
    };
    
  }

  componentDidMount(){
    setTimeout(() => {
      this.loadDataComments();
      this.setState({
        formValuesComments: {
          ...this.state.formValuesComments,
          normativity_score_id: this.props.data[0].id,
        },
      })
    }, 2000);
    let array = []

    this.props.users.map((item) => (
      array.push({label: item.name, value: item.id})
    ))

    this.setState({
        users: array
    })
  }

  handleChangeAutocomplete = selectedOption => {
    this.setState({
      selectedOption,
      formValues: {
        ...this.state.formValues,
        responsible_user_id: selectedOption.value
      }
    });
  };

  messageSuccess = (response) => {
    Swal.fire({
      position: "center",
      type: "success",
      title: `${response.success}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  clearValues = () => {
    this.setState({
      modal: false,
      modeEdit: false,
      id: "",
      formValues: {
        date_score: this.fecha,
        normativity_id: this.props.normativity.id,
        responsible_user_id: "",
        user_ids: []
      },

      selectedOption: {
        responsible_user_id: "",
        label: "Seleccionar"
      },

      editValues: [],

    });
  };

  onChangeForm = (e) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [e.target.name]: e.target.value,
      },
    });
  };


  toogle = (from) => {
    if(from == "new"){
      this.setState({ modal: true, titleForm: "Agregar Numeral",})
    }else{
      this.setState({ modal: false})
      this.clearValues()

    }
  }

  handleChangeAutocompleteUsers = selectedOption => {
    let array = []

    selectedOption.map((item) => (
      array.push(item.value)
    ))

    this.setState({
      formValues: {
        ...this.state.formValues,
        user_ids: array
      }
    })
  };

  HandleClick = (e) => {
    if (this.state.modeEdit == true) {
      fetch("/health/normativity_scores/" + this.state.id, {
        method: "PATCH", // or 'PUT'
        body: JSON.stringify(this.state.formValues), // data can be `string` or {object}!
        headers: {
          "X-CSRF-Token": this.token,
          "Content-Type": "application/json"
        },
      })
        .then((res) => res.json())
        .catch((error) => console.error("Error:", error))
        .then((response) => {
          this.messageSuccess(response);
          this.props.loadData();
          this.clearValues();
        });
    } else {
      this.props.updateStateLoad(true)
      fetch("/health/normativity_scores", {
        method: "POST", // or 'PUT'
        body: JSON.stringify(this.state.formValues), // data can be `string` or {object}!
        headers: {
          "X-CSRF-Token": this.token,
          "Content-Type": "application/json"
        },
      })
        .then((res) => res.json())
        .catch((error) => console.error("Error:", error))
        .then((response) => {
          this.messageSuccess(response);
          this.props.loadData();
          this.props.updateStateLoad(false)
          this.clearValues();
        });
    }
  };

  toggle = (show, register) => {
    if (show == "open") {
      this.setState({
        modal: true,
        modeEdit: true,
        accion: register,
        formValues: {
          date_score: this.fecha,
          normativity_id: this.props.normativity.id,
          responsible_user_id: "",
        },
      });
    } else {
      this.clearValues();
    }
  };

  editCalification = (calification) => {
    let arrayUsers = []

    calification.users.map((item) => (
      arrayUsers.push({label: `${item.first_name} ${item.first_last_name}`, value: item.id})
    ))
  
    this.setState({
      modal: true,
      id: calification.id,
      modeEdit: true,
      formValues: {
        ...this.state.formValues,
        responsible_user_id: calification.responsible_user_id,
        date_score: calification.date_score,
      },

      selectedOption: {
        responsible_user_id: calification.responsible_user_id,
        label: `${calification.responsible_user.first_name} ${calification.responsible_user.first_last_name}`
      },

      editValues: arrayUsers

    })
  }

  delete = id => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡El registro será eliminado para siempre!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009688",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        fetch(`/health/normativity_scores/${id}`, {
          method: "delete",
          headers: {
            "X-CSRF-Token": this.token,
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(response => {
            this.props.loadData();
            this.messageSuccess(response)
          });
      }
    });
  };

  getColor(value){
    let color = ""
    if (value < 6){
    color = "#ea4612"
    }else if (value >= 6 && value < 8){
        color = "orange";
    }
    else{
        color = "#30af28"
    }
    return color
  } 


  //cometarios

  loadDataComments = () => {
    fetch(`/get_general_commets_normativity/${this.props.data[0].id}`, {
        method: 'GET', // or 'PUT'
        headers: {
            "X-CSRF-Token": this.token,
            "Content-Type": "application/json"
        }
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        dataCommets: data.data,
      });
    });
  }

  HandleChangeComment = (e) => {
    this.setState({
        formValuesComments: {
          ...this.state.formValuesComments,
          comment: e.target.value
        }
    });
  }

  clearValuesComment = () => {
    this.setState({
        formValuesComments: {
          ...this.state.formValuesComments,
          comment: ""
        }
    });
  }


  getBack = (value) => {
    let url = "/health/normativity?type=" + this.props.normativity.type
    Turbolinks.visit(value, "_self")
}


getNameTitle = (name) => {
    if(name == "PCH" || name == "NMH"){
        return "Hospitales"
    }else if(name == "PCCAP"){
        return "Atención Primaria"
    }else if(name == "PCISO9001"){
        return "ISO 9001:2015 Salud"
    }else if(name == "PCISO15189"){
        return "ISO 15189:2012"
    }else if(name == "PCHEM"){
        return "Hemodiálisis"
    }else if(name == "NCON"){
        return "Consultorios"
    }else if(name == "NFAR"){
        return "Farmacias"
    }else if(name == "NNOM"){
        return "Normas Oficiales MXN"
    }
}

  render() {
    return (
      <React.Fragment>
        <FormCreateShow
          toggle={this.toggle}
          backdrop={"static"}
          modal={this.state.modal}
          onChangeForm={this.onChangeForm}
          formValues={this.state.formValues}
          submitForm={this.HandleClick}
          nameSubmit={
            this.state.modeEdit == true ? "Actualizar" : "Crear calificación"
          }

          title={this.state.modeEdit == true ? "Actualizar calificación" : "Crear Nueva calificación"}

          //autocomplete

          users={this.state.users}
          formAutocomplete={this.state.selectedOption}
          onChangeAutocomplete={this.handleChangeAutocomplete}

          //is_loading
          isLoaded={this.props.isLoaded}

          //autocomplete users
          handleChangeAutocompleteUsers={this.handleChangeAutocompleteUsers}
          editValues={this.state.editValues}

          showAddUsers={true}
          
        />

        <HeaderTitle 
            title={"Evaluaciones de " + this.props.normativity.name} 
            subTitle={this.props.normativity.description}
            titleNew={"Nueva evaluación"}
            titleFilter={"Filtros"}
            onClickNew={this.toogle}
            normativity={this.props.normativity}
            showNew={this.props.estados.crear}
            showFilter={false}
        />
      
        <nav className="" aria-label="breadcrumb">
          <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="javascript:void(0);" onClick={() => this.getBack(`/health/normativity?type=${this.props.normativity.type}`)}>{this.getNameTitle(this.props.normativity.type)}</a></li>
            <li className="breadcrumb-item"><a href="javascript:void(0);" onClick={() => this.getBack(`/health/normativity/${this.props.normativity.id}/numeral_parents`)}>Estándares y Guías</a></li>
            <li className="active breadcrumb-item" aria-current="page">Evaluaciones de {this.props.normativity.name}</li>
          </ol>
        </nav>

      {!this.props.isLoaded ? (
        <React.Fragment>
          {this.props.data.length >= 1 ? (
          <div className="container mb-5">
            <div className="row">
              <div className="col-md-12">
                <div className="mt-3">
              
                    <div>
                      <div className="main-calification mb-4">
                        {this.props.loadCalifications == true ? (
                          <Preloader />
                        ) : (
                            <div>
                              <div className="row">

                                <div className="col-md-4 pl-0">  
                                  <div className="card">

                                    <div className="card-header-tab card-header">
                                      <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                        <i className="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
                                        Última calificación ({this.props.data[0].date_score})
                                      </div>
                                    </div>
                                  
                                    <div className="no-gutters row">
                                      <div className="col-sm-12 col-md-12 col-xl-12">
                                        <div className="card no-shadow rm-border bg-transparent widget-chart text-left">

                                          <div className="">
                                            <div className="widget-subheading">Nota de cumplimiento</div>
                                            <div className="widget-numbers text-center" style={{ color: this.getColor(this.props.data[0].score != null ? this.props.data[0].score : 0) }}>{this.props.data[0].score != null ? this.props.data[0].score : 0}</div>
                                            <div className="widget-description opacity-8 text-focus">
                                              <u>
                                                {this.props.data[0].users.map((user, index) =>
                                                  <li>{user.first_name} {user.first_last_name}</li>
                                                )}
                                              </u>
                                              {/*this.props.data[0].responsible_user.first_name + " " + this.props.data[0].responsible_user.first_last_name*/}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="divider m-0 d-md-none d-sm-block"></div>
                                      </div>

                                    </div>

                                    <div className="card-footer" style={{ padding: "49px" }}>
                                      <div className="text-center process-score-trash" style={{ right: "inherit" }}>
                                        
                                        {(this.props.data[0].users.filter(user => user.id == this.props.current_user.id).length >= 1 || this.props.data[0].responsible_user_id == this.props.current_user.id) && (
                                          <a 
                                            href={`/health/normativity_scores/${this.props.data[0].id}`} 
                                            className="btn-pill btn-shadow btn-info fsize-1 btn btn-primary btn-lg"
                                            style={{marginRight: "12px" }}
                                            data-turbolinks="true"
                                          >

                                            <span className="mr-2 opacity-7">
                                              <i className="icon icon-anim-pulse ion-ios-analytics-outline"></i>
                                            </span>
                                            <span className="mr-1">Gestionar Evaluación</span>
                                          </a>
                                        )}

                                        {this.props.estados.editar && (
                                          <a
                                            onClick={() => this.editCalification(this.props.data[0])}
                                            style={{ cursor: "pointer", fontSize: "14px", marginRight: "12px" }}
                                          >
                                            <i className="fas fa-pencil-alt"></i>
                                          </a>
                                        )}

                                        {this.props.estados.eliminar && (
                                          <a
                                            onClick={() => this.delete(this.props.data[0].id)}
                                            style={{ cursor: "pointer", fontSize: "14px", marginRight: "12px" }}
                                          >
                                            <i className="fas fa-trash-alt"></i>
                                          </a>
                                        )}

                                      </div>
                                    </div>

                                  </div>
                                </div>

                                <div className="col-md-8">
                                  <Comments 
                                    nameModule={"hola"} 
                                    usuario={this.props.usuario} 
                                    dataCommets={this.state.dataCommets}
                                    loadComments={this.loadDataComments}
                                    formValuesComments={this.state.formValuesComments}
                                    HandleChangeComment={this.HandleChangeComment}
                                    clearValuesComment={this.clearValuesComment}
                                  />
                                </div>
                              </div>


                              <div className="card" style={{ display: "none" }}>
                                <div className="card-header">
                                  <h4>Última calificación({this.props.data[0].date_score}) </h4>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-4 text-center">
                                      <h2 style={{ color: this.getColor(this.props.data[0].score != null ? this.props.data[0].score : 0) }}>{this.props.data[0].score != null ? this.props.data[0].score : 0}</h2>
                                      <p>Nota de cumplimiento</p>
                                    </div>

                                    <div className="col-md-5">
                                      <p>Responsable: <b>{/*this.props.data[0].responsible_user.first_name} {this.props.data[0].responsible_user.first_last_name*/} </b></p>
                                    </div>
                                  </div>
                                </div>

                                <div className="card-footer">
                                  <div className="col-md-4">
                                    <a
                                      href={`/health/normativity_scores/${this.props.data[0].id}`}
                                      target="_blank"
                                      style={{ cursor: "pointer" }}
                                      data-turbolinks="true"
                                      className="btn-shadow btn btn-info"
                                    >
                                      Gestionar Evaluación
                                      </a>
                                  </div>
                                    
                                  {this.props.estados.editar || this.props.estados.eliminar  && (
                                    <div className="col-md-12 text-center">

                                      {this.props.estados.editar && (
                                        <a
                                          onClick={() => this.editCalification(this.props.data[0])}
                                          style={{ cursor: "pointer", fontSize: "14px", marginRight: "12px" }}
                                        >
                                          <i className="fas fa-pencil-alt"></i>
                                        </a>
                                      )}

                                      {this.props.estados.eliminar && (
                                        <a
                                          onClick={() => this.delete(this.props.data[0].id)}
                                          style={{ cursor: "pointer", fontSize: "14px", marginRight: "12px" }}
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                        </a>
                                      )}

                                    </div>
                                  )}
                                  
                                </div>
                              </div>
                            </div>
                          )}
                      </div>

                    </div>
                
                  {this.props.califications_date.length >= 2 ? (
                    <Chart
                      califications_date={this.props.califications_date}
                    />
                  ) : ""}
                </div>
              </div>
                
              <div className="col-md-12 mt-3">
                <h3>Historial</h3>
                <hr/>
              </div>
              <div className="col-md-12 ">
              <div className="row history mt-2">
            
                    {this.props.data.map((accion, index) =>
                      this.props.data[0].id != accion.id ? (
                        <div className="col-md-3">
                          <div className="card card-normas" key={accion.id}>

                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                  <p><b>{accion.date_score}</b></p>
                                  <p style={{ fontSize: "30px", marginBottom: "0px", color: this.getColor(accion.score != null ? accion.score : 0) }}>
                                    {accion.score != null ? accion.score : 0}
                                  </p>
                                  <p>Nota de cumplimiento </p>
                                  <div className="row ">

                                    <div className="col-md-6" style={{ textAlign: "left" }}>
                                      <a
                                        href={`/health/normativity_scores/${accion.id}`}
                                        target="_blank"
                                        style={{ cursor: "pointer" }}
                                        data-turbolinks="true"
                                        className="btn-shadow btn btn-info"
                                      >
                                        Ver
                                                    </a>
                                    </div>

                                    {this.props.estados.editar || this.props.estados.eliminar  && (
                                      <div className="col-md-6">

                                        {this.props.estados.eliminar && (
                                          <a
                                            onClick={() => this.editCalification(accion)}
                                            style={{ cursor: "pointer", fontSize: "18px", marginRight: "12px" }}
                                          >
                                            <i className="fas fa-pencil-alt"></i>
                                          </a>
                                        )}

                                        {this.props.estados.editar && (
                                          <a
                                            onClick={() => this.delete(accion.id)}
                                            style={{ cursor: "pointer", fontSize: "18px", marginRight: "6px" }}
                                          >
                                            <i className="fas fa-trash-alt icon-16"></i>
                                          </a>
                                        )}

                                      </div>
                                    )}

                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                          ""
                        )
                    )}
            
                </div>
              </div>
              
            </div>
          </div>
          ) : (
            <div className="container">
            <div className="card card-normas no-califications">
              <div className="card-body">
                <div className="row" style={{ textAlign: "center" }}>
                  <div className="col-md-12 text-center mb-2">
                    <img
                      style={{ width: "70px" }}
                      src="https://mybc1.s3.amazonaws.com/uploads/rseguimiento/evidencia/579/geekmejoraapp.png"
                    />
                  </div>
                  <div className="col-md-12 text-center mb-2">
                  <p>
                    No has realizado la primera calificación <br />{" "}
                    <span>¡ Hazlo ahora y mira como esta la norma !</span>
                  </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="col-md-12 text-center">
            <Preloader />
        </div>
      )}


      

        {/*<div className="container normas-header">
          <div className="row">

            <div className="col-md-9">
              <div className="card card-normas">
                <div className="card-body">
                    <h3 className="text-center mt-0">
                      {this.props.normativity.name}
                    </h3>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="content main-card card">
                <div className="card-body text-center">
                  <h3>Historial</h3>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-9">
              <div className="">
                <div className="mt-3">
                  {this.props.data.length >= 1 ? (
                    <div>
                      <div className="main-calification mb-4">
                        {this.props.loadCalifications == true ? (
                            <Preloader/>
                          ) : (
                            <div className="card">
                              <div className="card-header">
                                <h4>Última calificación({this.props.data[0].date_score}) </h4>
                              </div>
                              <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-4 text-center">
                                        <h2 style={{color: this.getColor(10 * 23) }}>{this.props.data[0].score != null ? this.props.data[0].score : 0}</h2>
                                        <p>Nota de cumplimiento</p>
                                    </div>

                                    <div className="col-md-5">
                                        <p>Responsable: <b>{this.props.data[0].responsible_user.first_name} {this.props.data[0].responsible_user.first_last_name} </b></p>
                                    </div>
                                  </div>
                              </div>

                              <div className="card-footer">
                                  <div className="col-md-4">
                                    <a
                                                        href={`/health/normativity_scores/${this.props.data[0].id}`}
                                                        target="_blank"
                                                        style={{ cursor: "pointer" }}
                                                        className="btn-shadow btn btn-info"
                                                    >
                                                        Gestionar Evaluación
                                    </a>
                                  </div>

                                  <div className="col-md-8 text-right">
                                      <a
                                        onClick={() => this.editCalification(this.props.data[0])}
                                        style={{ cursor: "pointer", fontSize: "24px", marginRight: "12px" }}
                                      >
                                        <i className="fas fa-pencil-alt"></i>
                                      </a>

                                      <a
                                        onClick={() => this.delete(this.props.data[0].id)}
                                        style={{ cursor: "pointer", fontSize: "24px", marginRight: "12px" }}
                                      >
                                        <i className="fas fa-trash-alt"></i>
                                      </a>
                                  </div>
                              </div>
                            </div>
                        )}
                      </div>
                      
                    </div>
                  ) : (
                    <div className="card card-normas no-califications">
                      <div className="card-body">
                        <div className="row" style={{ textAlign: "center" }}>
                          <div className="col-md-12 text-center mb-2">
                            <img
                              style={{ width: "70px" }}
                              src="https://mybc1.s3.amazonaws.com/uploads/rseguimiento/evidencia/579/geekmejoraapp.png"
                            />
                          </div>
                          <h3>
                            No has realizado la primera calificación <br />{" "}
                            <span>¡ Hazlo ahora y mira como esta la norma !</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                    {this.props.califications_date.length >= 2 ? (
                      <Chart
                        califications_date={this.props.califications_date}
                      />
                    ) : ""}
                </div>
              </div>
            </div>
            <div className="col-md-3 history mb-5">
              <div className="mt-3">
                <div className="row">
                  {this.props.data.map((accion, index) =>
                    this.props.data[0].id != accion.id ? (
                      <div className={`col-md-12 ${index != 1 ? "mt-3" : ""}`}>
                          <div className="card card-normas" key={accion.id}>

                              <div className="card-body">
                                  <div className="row">
                                      <div className="col-md-12" style={{ textAlign: "center"}}>
                                          <p><b>{accion.date_score}</b></p>
                                          <p style={{ fontSize: "30px", marginBottom: "0px", color: this.getColor(accion.score != null ? accion.score : 0) }}>
                                            {accion.score != null ? accion.score : 0}
                                          </p>
                                          <p>Nota de cumplimiento </p>
                                          <div className="row ">

                                              <div className="col-md-6" style={{ textAlign: "left"}}>
                                                  <a
                                                      href={`/health/normativity_scores/${accion.id}`}
                                                      target="_blank"
                                                      style={{ cursor: "pointer" }}
                                                      className="btn-shadow btn btn-info"
                                                  >
                                                    Gestionar
                                                  </a>
                                              </div>
                                              
                                              <div className="col-md-6">
                                                  <a
                                                    onClick={() => this.editCalification(accion)}
                                                    style={{ cursor: "pointer", fontSize: "24px", marginRight: "12px" }}
                                                  >
                                                    <i className="fas fa-pencil-alt"></i>
                                                  </a>

                                                  <a
                                                      onClick={() => this.delete(accion.id)}
                                                      style={{ cursor: "pointer", fontSize: "24px", marginRight: "6px" }}
                                                  >
                                                      <i className="fas fa-trash-alt icon-16"></i>
                                                  </a>
                                              </div>

                                          </div>
                                      </div>


                                  </div>
                              </div>
                          </div>
                      </div>
                    ) : (
                      ""
                    )
                    )}
                </div>
              </div>
            </div>
          </div>
                    </div>*/}
      </React.Fragment>
    );
  }
}

export default Show;

