import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class FormCreate extends Component {
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    {/*<ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>*/}
                    <ModalBody>
                        <div className="row">

                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <button className="btn-shadow btn btn-info" onClick={() => this.props.toggle()}>Cerrar</button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormCreate;