import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-3">

                        <div className="col-md-12 mb-3">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" onChange={(e) => this.props.onChangeForm({ target: { name: "type_search", value: !this.props.formValues.type_search } })} className="custom-control-input" id={`customShowTask`} checked={this.props.formValues.type_search} />
                                <label className="custom-control-label" htmlFor={`customShowTask`}>¿Es totalizador?</label>
                            </div>
                        </div>

                        <div className="col-md-6 mb-3">
                            <input
                                type="hidden"
                                name="question_asociation_id"
                                value={this.props.selectedOptionQuestion.question_compare_id}
                            />
                            <label> {this.props.formValues.type_search ? "N/A" : "Pregunta para comparar"}</label>
                            <Select
                                onChange={this.props.handleChangeAutocompleteQuestions}
                                options={this.props.questions}
                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.question_compare_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOptionQuestion}
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <input
                                type="hidden"
                                name="format_source_id"
                                value={this.props.selectedOption.format_source_id}
                            />
                            <label>Formato fuente</label>
                            <Select
                                onChange={this.props.handleChangeAutocomplete}
                                options={this.props.formatos}
                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.format_source_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOption}
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            {this.props.format_questions.length >= 1 && (
                                <React.Fragment>
                                    <input
                                        type="hidden"
                                        name="question_compare_source_id"
                                        value={this.props.selectedOptionQuestionCompare.question_compare_source_id}
                                    />
                                    <label> {this.props.formValues.type_search ? "N/A" : "Pregunta para comparar (fuente)"}</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteQuestionCompare}
                                        options={this.props.format_questions}
                                        autoFocus={false}
                                        className={`link-form ${!this.props.errorValues && this.props.formValues.question_compare_source_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionQuestionCompare}
                                    />
                                </React.Fragment>
                            )}
                        </div>

                        <div className="col-md-6 mb-3">
                            {this.props.format_questions.length >= 1 && (
                                <React.Fragment>
                                    <input
                                        type="hidden"
                                        name="question_to_get_id"
                                        value={this.props.selectedOptionQuestionGet.question_to_get_id}
                                    />
                                    <label>{this.props.formValues.type_search ? "Pregunta del Formato fuente para la Operación" : "Pregunta para traer (fuente) "}</label>
                                    <Select
                                        onChange={this.props.handleChangeAutocompleteQuestionGet}
                                        options={this.props.format_questions}
                                        autoFocus={false}
                                        className={`link-form ${!this.props.errorValues && this.props.formValues.question_to_get_id == "" ? "error-class" : ""}`}
                                        value={this.props.selectedOptionQuestionGet}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                        {this.props.formValues.type_search && (
                        <div className="col-md-6 mb-3">
                            {this.props.format_questions.length >= 1 && (
                                <React.Fragment>
                                    <label>Operacion</label>
                                    <select
                                        name="operation"
                                        value={this.props.formValues.operation}
                                        onChange={this.props.onChangeForm}
                                        className={`form-control`}
                                    >
                                            <option value="">Seleccione un valor</option>
                                            <option value="suma">Suma</option>
                                            <option value="promedio">Promedio</option>
                                            <option value="maximo">Maximo</option>
                                            <option value="minimo">Minimo</option>
                                            <option value="mismo">Mismo Valor</option>
                                            <option value="ultimo">Ultimo Valor</option>
                                            <option value="primero">Primer Valor</option>
                                            <option value="contador">contador</option>

                                       
                                    </select>
                                </React.Fragment>
                            )}
                        </div>
                         )}
                        
                        {!this.props.errorValues && (
                            <div className="col-md-12">
                                <div className="alert alert-warning text-center" role="alert">
                                    <b>Debes de completar todos los datos requeridos</b>
                                </div>
                            </div>
                        )}

                        <div className="col-md-12 text-right">
                            <button
                                className="btn btn-outline-danger mr-2"
                                onClick={() => this.props.toggle()}
                            >
                                Cancelar
                            </button>

                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.props.submitForm()}
                            >
                                {this.props.nameBnt}
                            </button>

                            <hr />
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;