import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FormCreatePayment = (props) => {
    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toogle={props.toogle} className="modal-dialog-centered" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>

                <form onSubmit={handleSubmit}></form>
                <ModalBody>
                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <label>Fecha de Pago</label>
                            <input 
                                type="date" 
                                name="payment_date"
                                value={props.formValues.payment_date}
                                onChange={props.onChangeForm}
                                className='form form-control'
                                id=""
                                // className={`form form-control ${props.errorValues == false &&  props.formValues.date_payment == "" ? "error-class" : ""}`} 
                            />
                        </div>
                        <div className="col-md-6">
                            <label>Valor</label>
                            <input 
                                type="number" 
                                name="amount"
                                value={props.formValues.amount}
                                onChange={props.onChangeForm} 
                                className='form form-control'
                                id=""
                                 />
                        </div>
                        <div className="col-md-6">
                            <label>Estado</label>
                            <input 
                                type="text"
                                name='payment_state'
                                value={props.formValues.payment_state}
                                onChange={props.onChangeForm}
                                className='form form-control'
                             />
                        </div>
                        <div className="col-md-6">
                            <label>Tipo de Pago</label>
                            <input 
                                type="text"
                                name='payment_type'
                                value={props.formValues.payment_type}
                                onChange={props.onChangeForm}
                                className='form form-control'
                             />
                        </div>
                        <div className="col-md-12" style={{display: 'flex', flexDirection: 'column'}}>
                            <label>Correo Electrónico</label>
                            <input 
                                type="email"
                                name="payment_email"
                                value={props.formValues.payment_email}
                                onChange={props.onChangeForm}
                                className='form form-control'
                                id="" />
                        </div>
                        <div className="col-md-6" style={{display: 'flex', flexDirection: 'column'}}>
                            <label>Seleccione el plan</label>
                            <input                                 
                                type="hidden"
                                name="plan_id"
                                value={props.selectedOption.plan_id}                                
                            /> 
                            <Select
                                onChange={props.handleChangeAutocomplete}
                                options={props.plans}
                                autoFocus={false}
                                className={`link-form`}
                                classNamePrefix="select"
                                placeholder="Seleccione"
                                value={props.selectedOption}                                
                            />
                        </div>
                        <div className="col-md-6">
                            <label>Tiempo Pago</label>
                            <input 
                                type="number" 
                                name="payment_time"
                                value={props.formValues.payment_time}
                                onChange={props.onChangeForm} 
                                className='form form-control'
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                    <button className="btn-shadow btn btn-info" 
                    onClick={props.submitForm}
                    >
                        {props.nameBnt}</button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}
export default FormCreatePayment;