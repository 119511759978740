import React from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_password: false,
      show_password_confirmation: false
    }
  }

  clean(){
    this.props.submit();
    this.setState({ show_password: false,  show_password_confirmation: false})
  }


  render() {
    return (

      <React.Fragment>
        <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-lg modal-dialog-centered" backdrop={this.props.backdrop}>
          <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.titulo}</ModalHeader>

          <form onSubmit={this.props.FormSubmit}>
            <ModalBody>
              <div className="row">

                <div className="col-md-12">
                  <div className="row">

                    <div className="col-md-6 mb-4">
                      <label>Nombre de usuario</label>
                        <input
                          type="text"
                          name="username"
                          value={this.props.formValues.username}
                          onChange={this.props.handleChangeUserName}
                          className={`form form-control ${this.props.errorValues == false && this.props.formValues.username == "" ? "error-class" : ""}`}
                          placeholder="Nombre completo"
                        />
                    </div>

                    <div className="col-md-6 mb-4">
                      <label>Correo electrónico</label>
                      <input
                        type="email"
                        name="second_email"
                        value={this.props.formValues.second_email}
                        onChange={this.props.onChangeForm}
                        className={`form form-control ${this.props.errorValues == false && this.props.formValues.second_email == "" ? "error-class" : ""}`}
                        placeholder="Correo"
                      />
                    </div>

                    <div className="col-md-6 mb-4">
                      <label>Primer Nombre</label>
                      <input
                        type="text"
                        name="first_name"
                        value={this.props.formValues.first_name}
                        onChange={this.props.onChangeForm}
                        className={`form form-control ${!this.props.errorValues && this.props.formValues.first_name == "" ? "error-class" : ""}`}
                        placeholder="Primer Nombre"
                      />
                    </div>

                    <div className="col-md-6 mb-4">
                      <label>Segundo Nombre <small className="validate-label">(Opcional)</small></label>
                      <input
                        type="text"
                        name="second_name"
                        value={this.props.formValues.second_name}
                        onChange={this.props.onChangeForm}
                        className={`form form-control`}
                        placeholder="Segundo Nombre"
                      />
                    </div>

                    <div className="col-md-6 mb-4">
                      <label>Primer Apellido </label>
                      <input
                        type="text"
                        name="first_last_name"
                        value={this.props.formValues.first_last_name}
                        onChange={this.props.onChangeForm}
                        className={`form form-control ${!this.props.errorValues && this.props.formValues.first_last_name == "" ? "error-class" : ""}`}
                        placeholder="Primer Apellido"
                      />
                    </div>

                    <div className="col-md-6 mb-4">
                      <label>Segundo Apellido <small className="validate-label">(Opcional)</small></label>
                      <input
                        type="text"
                        name="second_last_name"
                        value={this.props.formValues.second_last_name}
                        onChange={this.props.onChangeForm}
                        className={`form form-control`}
                        placeholder="Segundo Apellido"
                      />
                    </div>

                    {this.props.formAutocomplete.label != "Administrador Sistema" && (
                      <div className="col-md-6 mb-4">
                        <input
                          type="hidden"
                          name="position_id"
                          value={this.props.formAutocomplete.position_id}
                        />
                        <label>Cargos</label>
                        <Select
                          onChange={this.props.onChangeAutocomplete}
                          options={this.props.roles}
                          autoFocus={false}
                          className={`link-form ${this.props.errorValues == false && this.props.formValues.position_id == "" ? "error-class" : ""}`}
                          value={this.props.formAutocomplete}
                        />
                      </div>
                    )}


                  </div>
                </div>
                

                {this.props.editState && (
                  <div className="col-md-12 mt-4"> 
                    <a data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        <i className="fas fa-lock mr-2"></i> Actualizar contraseñas
                    </a>
                  </div>
                )} 

                {this.props.editState && (
                  <div className={`collapse ${this.props.editState == true ? "" : "show"}`} id="collapseExample" style={{ width: "100%" }}>
                    <div className="col-md-12 mt-4">
                      <div className="row">

                        <div className="col-md-6">
                            <label>Contraseña</label>
                            <input
                              type={this.state.show_password == false ? "password" : "text"}
                              name="password"
                              value={this.props.formValues.password}
                              onChange={this.props.handleChangePassword}
                              onBlur={this.props.onBlurPasswordConfirmation}
                              className={`form form-control ${this.props.errorValues == false && this.props.formValues.password == "" ? "error-class" : ""}`}
                              placeholder="Contraseña"
                              autocomplete="new-password"
                            />

                            {this.props.formValues.password.length >= 1 && (
                              <label className="btn-password" onClick={() => this.state.show_password == false  ? this.setState({ show_password: true }) : this.setState({ show_password: false }) }>{this.state.show_password == false ? <i className ="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i> }</label>
                            )}  

                        </div>

                        <br />

                        <div className="col-md-6">
                        <label>Confirmar contraseña</label>
                            <input
                              type={this.state.show_password_confirmation == false ? "password" : "text"}
                              name="password_confirmation"
                              value={this.props.formValues.password_confirmation}
                              onChange={this.props.handleChangePassword}
                              onBlur={this.props.onBlurPasswordConfirmation}
                              className={`form form-control ${this.props.errorValues == false && this.props.formValues.password_confirmation == "" ? "error-class" : ""}`}
                              placeholder="Confirmar contraseña"
                              autocomplete="new-password"
                            />

                            {this.props.formValues.password_confirmation.length >= 1 && (
                              <label className="btn-password" onClick={() => this.state.show_password_confirmation == false  ? this.setState({ show_password_confirmation: true }) : this.setState({ show_password_confirmation: false }) }>{this.state.show_password_confirmation == false ? <i className ="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}</label>
                            )}  
                        </div>

                      </div>
                    </div>
                  </div>
                  )} 

                  {(this.props.editState && this.props.formValues.password.length >= 1)&& (
                    <React.Fragment>

                        <div className="col-md-12 mt-1">
                          <div className="alert alert-danger" role="alert">
                          <p>Requerido de contraseña</p>

                            <div className="row">
                              <div className="col-md-6">
                                <ul>
                                  <li className={this.props.formValues.password.length >= 6 ? "color-password" : "color-falsePassword"}>Mínimo 6 caracteres</li>
                                  <li className={(/[A-Z]/).test(this.props.formValues.password) ? "color-password" : "color-falsePassword"}>Al menos una letra mayúscula</li>
                                  <li className={(/[a-z]/).test(this.props.formValues.password) ? "color-password" : "color-falsePassword"}>Una letra minúscula</li>
                                  <li className={(/[0-9]/).test(this.props.formValues.password) ? "color-password" : "color-falsePassword"}>Un número</li>
                                  <li className={this.props.formValues.password === this.props.formValues.password_confirmation  ? "color-password" : "color-falsePassword"}>Contraseñas iguales</li>
                                  {/*<li className={(/[@$!%*_?]/).test(this.props.formValues.password) ? "color-password" : "color-falsePassword"}>Un carácter especial(@$!%*_?)</li>*/}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                    </React.Fragment>
                  )}

                {this.props.errorValues == false && (
                  <div className="col-md-12 mt-4">
                    <div className="alert alert-danger" role="alert">
                      <b>Debes de completar todos los campos requeridos</b>
                    </div>
                  </div>
                )}

                {this.props.errorName != "" && (
                  <div className="col-md-12 mt-4">
                    <div className="alert alert-danger" role="alert">
                      <b>{this.props.errorName}</b>
                    </div>
                  </div>
                )}


              </div>

              
            </ModalBody>

        
          <ModalFooter>
                <label className="btn btn-light mt-2" onClick={this.props.toggle}>Cerrar</label>
                <button className="btn-shadow btn btn-info" onClick={() => this.props.submit()}>{this.props.nameSubmit}</button>
          </ModalFooter>

          </form>




        </Modal>
      </React.Fragment>
     
    );
  }
}

export default FormUser;

/*adsdasdasdasdasdasdasd*/