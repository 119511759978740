import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import MobileView from '../components/Beetree/pages/MobileView';
import HeaderMenu from '../components/Beetree/pages/HeaderMenu';
import HeaderInformation from '../components/Beetree/PageAppearance/HeaderInformation';
import ModalUploadImagen from '../components/Beetree/Setting/ModalUploadImagen';
import Select from "react-select";

const PageSettings = (props) => {
    const [form, setForm] = useState({ aditional_info: props.enterprise.aditional_info, email: props.enterprise.email, img_logo_company: props.enterprise.img_logo_company, name: props.enterprise.name });
    const [formUpdate, setFormUpdate] = useState({ aditional_info: props.enterprise.aditional_info, email: props.enterprise.email, img_logo_company: props.enterprise.img_logo_company, name: props.enterprise.name });
    const [disableInput, setDisableInput] = useState(true);
    const [loadView, setLoadView] = useState(false);
    const [modal, setModal] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [formUpdatePage, setFormUpdatePage] = useState({ private: props.page.private, position_ids: props.page.position_ids });

    const [default_values, setDefaultValues] = useState(props.page.positions);
    const [selectedOptionMulti, setSelectedOptionMulti] = useState({ position_ids: props.page.position_ids });

    const token = document.querySelector("[name='csrf-token']").content;

    const updateInfomation = (e) => {
        setLoadView(false)
        const formUpdateField = {
            name: (e.target.name == "name" ? e.target.value : formUpdate.name),
            aditional_info: (e.target.name == "aditional_info" ? e.target.value : formUpdate.aditional_info),
        }

        fetch(`/beetree/pages/${props.page.id}/enterprises/${props.enterprise.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(formUpdateField), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            setFormUpdate({ ...formUpdate, aditional_info: data.register.aditional_info, name: data.register.name })
            setLoadView(true);
        });
    }

    const handleChange = (e) => {
        setFormUpdate({ ...formUpdate, [e.target.name]: e.target.value })
    }

    const setDisableInputFunction = (state) => {
        setDisableInput(state)
    }

    const handleFile = (file) => {
        setLoadView(false);
        setIsLoaded(true);

        const formData = new FormData();
        formData.append("img_logo_company", file[0])
        fetch(`/beetree/pages/${props.page.id}/enterprises/${props.enterprise.id}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            setModal(false);
            setIsLoaded(false);
            setFormUpdate({ ...formUpdate, img_logo_company: data.register.img_logo_company });
            setLoadView(true);
        });
    }

    const handleChangeChecked = (e) => {
        console.log("handleChangeCheckedhandleChangeChecked");
        const isChecked = e.target.checked;

        const form = {
            private: isChecked,
            position_ids: formUpdatePage.position_ids,
        }

        fetch(`/beetree/update_page_position/${props.page.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            setFormUpdatePage({ private: data.register.private, position_ids: data.register.position_ids });
        });
    }

    const handleChangeAutocompleteMulti = (selectedOption) => {
        let array = [];

        if(selectedOption){
            selectedOption.map((item) => (
                array.push(item.value)
            ))
        }

        let form = {
            private: formUpdatePage.private,
            position_ids: selectedOption ? array : [],
        }

        fetch(`/beetree/update_page_position/${props.page.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            setFormUpdatePage({ private: data.register.private, position_ids: data.register.position_ids });
            setDefaultValues(data.register.positions);
        });
    }

    const toogle = (from) => {
        if (from == "new") {
            setModal(true);
        } else {
            setModal(false);
        }
    }

    return (
        <React.Fragment>
            <HeaderMenu
                current_page={"settings"}
                page={props.page}
            />


            {modal && (
                <ModalUploadImagen
                    backdrop={"static"}
                    modal={modal}
                    toggle={toogle}
                    title={"Seleccionar icon"}

                    handleFile={handleFile}
                    isLoaded={isLoaded}
                />
            )}

            <div className="ui-container-betreePage">
                <div className="card ui-btree-card-linkContainer pb-0">
                    <div className="card-body p-3 ui-btree-settings-container-card-body">
                        
                        <HeaderInformation
                            enterprise={formUpdate}
                            setDisableInputFunction={setDisableInputFunction}
                            disableInput={disableInput}
                        />

                        <div className="ui-btree-settings-container-edits">
                            <div className="ui-btree-info-company">
                                <div className="ui-btree-settings-name-company-container">
                                    <label>Nombre de la empresa</label>
                                    <input 
                                        disabled={disableInput} 
                                        onBlur={updateInfomation}
                                        onChange={handleChange}
                                        type="text" 
                                        name="name" 
                                        value={formUpdate.name} 
                                    />
                                </div>

                                {/*<div className="ui-btree-setting-email-corp-container">
                                    <label>Correo corporativo</label>
                                    <input 
                                        disabled={disableInput} 
                                        onBlur={updateInfomation}
                                        onChange={handleChange}
                                        type="email" 
                                        name="email" 
                                        value={formUpdate.email} 
                                    />
                                </div>*/}
                            </div>

                            <div className="ui-btree-complement-info-company">
                                <label>Información complementaria</label>
                                <textarea 
                                    className='ui-betree-info-complemnet-textarea' 
                                    disabled={disableInput}
                                    value={formUpdate.aditional_info} 
                                    name="aditional_info"
                                    onChange={handleChange}
                                    onBlur={updateInfomation}
                                />
                            </div>

                            <div className="ui-btree-settings-img-container">
                                <input type="image" src={formUpdate.img_logo_company ? formUpdate.img_logo_company : "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/990/3060465483633_a0137784f7832be35fdf_88.png"} alt=""/>
                                <div className="ui-btree-setting-btn-img-container">
                                    <button className="btn-shadow btn btn-info" onClick={() => toogle("new")} disabled={disableInput}>Actualizar</button>                                   
                                </div>
                            </div>

                            <div className="ui-btree-setting-favorite-private-date-container">
                                <div className="ui-btree-setting-container-fav-pri">
                                    <div className="ui-btree-settings-private-page">                                    
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" onChange={handleChangeChecked} className="custom-control-input" id={`customCheckboxDefaultValue`} value={!formUpdatePage.private} checked={formUpdatePage.private}/>
                                            <label className="custom-control-label" htmlFor={`customCheckboxDefaultValue`}>¿La página es privada?</label>
                                        </div>
                                    </div>                              
                                </div> 

                                <div className="ui-btree-date-active-inactive-page p-0">
                                    {formUpdatePage.private && (
                                        <div className="mt-3">
                                            <label>Seleccione los cargos que la pueden ver</label>
                                            <input
                                                type="hidden"
                                                name="position_ids"
                                                value={selectedOptionMulti.position_ids}
                                            />

                                            <Select
                                                onChange={handleChangeAutocompleteMulti}
                                                options={props.positions}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                autoFocus={false}
                                                className={`link-form`}
                                                classNamePrefix="select"
                                                placeholder="Seleccione"
                                                name="position_ids"
                                                defaultValue={default_values}
                                            />
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <MobileView 
                page_id={props.page.id}
                loadView={loadView}
            />
        </React.Fragment>
    );
}

export default PageSettings;
WebpackerReact.setup({ PageSettings });
