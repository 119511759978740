import React, { useState, useEffect, useMemo } from 'react';

import styled from 'styled-components'
import Task from './task'
import { Droppable } from 'react-beautiful-dnd'
import FormCreate from '../tasks/tasksCard/FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledTooltip } from 'reactstrap';
import IndexUpdateColumn from '../tasks/taskUpdateColumn/IndexUpdate';
import IndexColumnFilter from '../tasks/taskUpdateColumn/IndexColumnFilter';
import { isMobile } from 'react-device-detect';

//moment
import moment from 'moment';

//google icons
import GoogleFontLoader from 'react-google-font-loader';

const Title = styled.h3`
  padding: 8px;
`
const TaskList = styled.div`
  transition: background-color 0.2s ease;
  flex-grow: 1;
  min-height: 100px;
`
// style={{ height: "666px", marginBottom: "30px"}}

const column = (props) => {
  const token = document.querySelector("[name='csrf-token']").content;
  const [showForm, setShowForm] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [users, setUsers] = useState([]);
  const [form, setForm] = useState(
    {
      name: "",
      due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
      user_owner_id: "",
      notify: false,
      url_source: props.url_source !== undefined ? props.url_source : "",
      task_source_id: props.task_source_id !== undefined ? props.task_source_id : "",
      module_task_id: props.module_task !== undefined ? props.module_task : "",
      name_module: props.moduleName !== undefined ? props.moduleName : "",
      task_label_ids: props.from !== "TABLERO" ? [] : props.task_board.task_labels,
      column_id: props.column.column_id,
    }
  );

  const [selectedOption, setSelectedOption] = useState(
    {
      user_owner_id: "",
      label: "Responsable",
    }
  );

  const handleChangeAutocomplete = (selectedOption) => {
    setSelectedOption(selectedOption);
    setForm({
      ...form,
      user_owner_id: selectedOption.value,
    });
  };

  const clearValues = () => {
    setShowForm(false);
    setForm({
      name: "",
      due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
      user_owner_id: "",
      module_task_id: props.module_task !== undefined ? props.module_task : "",
      name_module: props.moduleName !== undefined ? props.moduleName : "",
      column_id: props.column.column_id,
    });
    setSelectedOption({
      user_owner_id: "",
      label: "Responsable",
    });
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      name: e.target.name === "name" ? (e.target.value.length + 0 > 200 ? form.name : e.target.value) : form.name,
      module_task_id: props.module_task !== undefined ? props.module_task : "",
      name_module: props.moduleName !== undefined ? props.moduleName : "",
      column_id: props.column.column_id,
    });
  };

  const messageSuccess = (response) => {
    Swal.fire({
      position: "center",
      type: `${response.type}`,
      title: `${response.success}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const handleChangeSwitch = (value, field) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const editState = (e, id, state) => {
    e.stopPropagation();
    fetch(`/task/update_state_filed_columns/${id}/${state}`, {
      method: 'PATCH',
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(data => {
        props.removeTask(data.register);
      });
  };


  const deleteTask = (e, task) => {
    e.stopPropagation();
    const task_id = task.task_id;

    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡El registro será eliminado para siempre!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009688",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then(result => {
      if (result.value) {
        fetch(`/task/delete_task/${task_id}`, {
          method: "PATCH",
          headers: {
            "X-CSRF-Token": token,
            "Content-Type": "application/json",
          },
        })
          .then(response => response.json())
          .then(response => {
            props.loadData(true, { type: "success", title: "¡La tarea fue eliminada con éxito!" });
            messageSuccess(response);
          });
      }
    });
  };

  const duplicateTask = (e, id) => {
    e.stopPropagation();
    fetch(`/task/duplicate_task/${id}`, {
      method: "post",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(response => {
        messageSuccess(response);
        if (props.isFiltering) {
          props.handleClickFilter();
        } else {
          props.loadData();
        }
      });
  };

  const updateStateLoad = (state) => {
    setIsLoaded(state);
  };


  const handleShowPopover = (from) => {
    if (from === 'new') {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  };

  const closePopoversColumn = () => {
    setShowPopover(false);
  };

  const getColorDate = (task) => {
        if (task.state == "done" && task.fulfilled == "no") {
            return "red"

        } else if (task.state == "done" && task.fulfilled == "si") {
            return "green"

        } else if (task.state == "filed" && task.fulfilled == "si") {
            return "green"

        } else if (task.state == "filed" && task.fulfilled == "no") {
            return "red"

        } else {
            let state = task.due_date
            let resta = new Date(state).getTime() - new Date().getTime()
            resta = Math.round((resta / (1000 * 24 * 60 * 60)));
            resta = resta + 1
            if (resta >= 5) {
                return "green"
            } else if (resta < 5 && resta >= 0) {
                return "orange"
            } else {
                return "red"
            }
        }
  };

  
  

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const fontConfig = useMemo(() => ({
    fonts: [
      {
        font: 'Material Symbols Outlined',
        weights: [400],
      },
    ],
    async: true,
    defer: true,
  }), []);

  const changeStyleHeaderTitle = (countTasks) => {
    if(countTasks >= 1) {
      return 'ui-columns-header';
    } else {
      return 'ui-columns-header_2-icons'
    }
  }
  
  const changeStyleIcons = (countTasks) => {    
    if(countTasks >= 1) {
      return 'ui-column-new-Tasks';
    } else {
      return 'ui-column-new-Tasks_2_icons'
    }
  }

  return (
    <React.Fragment>
      {useMemo(() => <GoogleFontLoader {...fontConfig} />, [fontConfig])}
      <div className={`col-md-${props.task_board.columns} ui-column-task`}>
        <div className="card card-list card-test-parent ui-column-TasksColumns">
          {showForm && (
            <FormCreate
              HandleChange={handleChange}
              form={form}
              clearValues={clearValues}
              updateStateLoad={props.updateStateLoad}
              isLoadedTask={props.isLoadedTask}

              //select autocomplete
              handleChangeAutocomplete={handleChangeAutocomplete}
              selectedOption={selectedOption}
              users={props.users}

              //test data
              updateData={props.updateData}
              loadData={props.loadData}
              column_id={props.column.column_id}
              handleChangeSwitch={handleChangeSwitch}
            />
          )}
          <div className="card-header p-0 ui-column-card-header__container" style={{ backgroundColor: props.column.color, color: props.column.color_text }}>
            <div className={`${changeStyleHeaderTitle(props.tasks.length)}`}>
              <div className="ui-title-column-task" title={props.column.title}>
                {props.column.title}
              </div>
              <div className={changeStyleIcons(props.tasks.length)}>
                <span className="badge badge-light float-right ui-columns-task-badge">{props.tasks.length}</span>
                {props.tasks.length >= 1 && (
                  <UncontrolledPopover isOpen={showPopover} placement="bottom" target={`PopoverChangeTasks${props.column.id}`}>
                    <PopoverHeader>Cambiar de columna todas estas tareas <a className="close-popover" onClick={() => handleShowPopover('')}> <i className="fas fa-times"></i></a></PopoverHeader>
                    <PopoverBody>
                      <IndexUpdateColumn
                        column={props.column}
                        messageSuccess={messageSuccess}
                        closePopoversColumn={closePopoversColumn}
                        task_board={props.task_board}
                        loadData={props.loadData}
                      />
                    </PopoverBody>
                  </UncontrolledPopover>
                )}

                {props.tasks.length >= 1 && (
                  <React.Fragment>
                    <UncontrolledTooltip target={`PopoverChangeTasks${props.column.id}`}>{'CAMBIAR DE COLUMNA TODAS LAS TAREAS'}</UncontrolledTooltip>
                    <i className="material-symbols-outlined ui-column-move-tasks" id={`PopoverChangeTasks${props.column.id}`} onClick={() => handleShowPopover('new')} style={{ color: props.column.color_text }}>&#xe985;</i>
                  </React.Fragment>
                )}

                {props.tasks.length >= 1 && (
                  <React.Fragment>
                    <UncontrolledTooltip target={`PopoverColumnFilter${props.column.id}`}>{'FILTRAR LAS TAREAS DE ESTA COLUMNA'}</UncontrolledTooltip>
                      <i className="material-symbols-outlined pr-1 ui-colum-new-task" id={`PopoverColumnFilter${props.column.id}`} onClick={() => props.closePopoversColumnFilter(props.column.id)} style={{ color: props.column.color_text }}>&#xef4f;</i>
                    <UncontrolledPopover isOpen={props.popoverColumnId == props.column.id} placement="bottom" target={`PopoverColumnFilter${props.column.id}`}>

                    <PopoverHeader>Filtrar las tareas de esta columna <a className="close-popover" onClick={() => props.closePopoversColumnFilter(null)}> <i className="fas fa-times"></i></a></PopoverHeader>
                    <PopoverBody>
                      <IndexColumnFilter
                        column={props.column}
                        messageSuccess={messageSuccess}
                        closePopoversColumnFilter={props.closePopoversColumnFilter}
                        task_board={props.task_board}
                        loadData={props.loadData}
                        updateTaskOrderColumn={props.updateTaskOrderColumn}
                      />
                    </PopoverBody>
                  </UncontrolledPopover>
                  </React.Fragment>
                )}

                {!props.task_board.delete_template && (
                  <React.Fragment>

                    {(props.isLoadedTask && props.current_column_id == props.column.column_id) ? (
                      <div className="loader-files"></div>
                    ) : (
                      <React.Fragment>
                        {props.estados.create && (
                          <React.Fragment>
                            <UncontrolledTooltip target={`createTask${props.column.id}`} placement='top'>{showForm ? ('Cerrar Crear Tarea') : ('Crear Tarea')}</UncontrolledTooltip>
                            <i className="material-symbols-outlined ui-colum-new-task" style={{ color: props.column.color_text }} onClick={toggleForm} id={`createTask${props.column.id}`}>
                              {showForm ? ('\ue5cd') : ('\uf23a')}
                            </i>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div className="card-body p-0 scroll-area-lg scroll-tasks-parent ui-column-task__container-Columnstask">


            <Droppable droppableId={props.column.id} type={`TASK`}>
              {(provided, snapshot) => (
                <TaskList
                  innerRef={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {props.tasks.map((task, index) => (
                    <Task
                      key={task.id}
                      task={task}
                      index={index}
                      handleClickShow={props.handleClickShow}
                      toogle={props.toogle}
                      delete={deleteTask}
                      editState={editState}
                      updateStateLoad={props.updateStateLoad}
                      duplicate={duplicateTask}
                      estados={props.estados}
                      get_colorDate={getColorDate}
                      removeTask={props.removeTask}
                      grag_task_id={props.grag_task_id}
                    />
                  ))}
                  {provided.placeholder}
                </TaskList>
              )}
            </Droppable>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};
export default column;
