import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
class TableIndexWeb extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="content main-card mb-3 card">
                    <div className="card-body">

                        <table className="table table-hover table-striped table-bordered table-fixed">
                        <thead>
                            <tr className="tr-table">
                            {/* <th width="200px">#</th> */}
                            <th>Fecha</th>
                            <th>Área específica</th>
                            <th>Paciente</th>
                            <th>Tipo de Evento</th>
                            <th>Evento relacionado</th>
                            <th width="250px">Descripción</th>

                            <th style={{width:"80px"}}className="text-center w-6" ></th>


                            </tr>
                        </thead>

                        <tbody>
                            {this.props.dataActions.length >= 1 ? (
                            this.props.dataActions.map(accion => (
                                <tr key={accion.id}>
                               {/*  <td >{accion.position}</td> */}
                                <td >{this.props.dateTime(accion.event_date)}</td>
                                <td >{accion.specific_area != undefined ? accion.specific_area.name : ""}</td>
                                <td >{accion.patient_name} <br /> <span className="patient-birthdate"><i className="far fa-calendar-alt"></i> {this.props.date(accion.birthdate)}</span></td>
                                <td >{accion.safety_related_event.name}</td>
                                <td >{accion.related_event != undefined ? accion.related_event.name : ""}</td>
                                <td >{accion.description}</td>


                                <td className="text-right" style={{ width: "10px" }}>
                                    {(this.props.estados.editar || this.props.estados.eliminar) && (
                                        <UncontrolledDropdown className='btn-group'>
                                            <DropdownToggle className='btn btn-info'>
                                                <i className="fas fa-bars"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                {this.props.estados.editar && (
                                                    <DropdownItem
                                                        href={`/health/event_notification/${accion.id}/edit`}
                                                        className="dropdown-item"
                                                        data-turbolinks='false'
                                                    >
                                                        Editar
                                                    </DropdownItem>
                                                )}

                                                {this.props.estados.eliminar && (
                                                    <DropdownItem
                                                        onClick={() => this.delete(accion.id)}
                                                        className="dropdown-item"
                                                    >
                                                        Eliminar
                                                    </DropdownItem>
                                                )}

                                                {true && (
                                                    <DropdownItem
                                                        href={`/health/event_notification/${accion.id}`}
                                                        data-turbolinks="true"
                                                        className="dropdown-item"
                                                    >
                                                        Más información
                                                    </DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                </td>
                                </tr>
                            ))
                            ) : (
                                <tr>
                                <td colSpan="7" className="text-center">
                                    <div className="text-center mt-4 mb-4">
                                    <h4>No hay registros</h4>
                                    {true && (
                                        <a href="/customers/new" className="btn btn-primary" style={{ display: "none" }}>Nueva notificación</a>
                                    )}
                                    </div>
                                </td>
                                </tr>
                            )}
                        </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TableIndexWeb;