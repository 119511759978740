import React, { Component } from 'react';
import IndexDashboard from '../components/formatos/Surveys/IndexDashboard'  
import WebpackerReact from 'webpacker-react';

class SurveyCustomDashboard extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            dataQuestion: [],
            surveys:[],
            isLoaded: true,
            paramas:{},
            filters:{},
            survey_total: 0,
            survey_total_filter: 0,
            formUpdateRepy:{},
            isFilter:false,
            charts: []
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }
    
    cleanFilters=()=>{
        this.setState({
            filters: {}
        })
    }

    loadData = () => {
        //OBTIENE EL PARAMETRO PARA SABER SI ES ACTIVE O INACTIVE
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        // OBTIENE EL PARAMATRO PARA ENVIARLO EL EL REQUEST
        let view = urlParams.get('view')
        fetch(`/formatos/get_surveys_index_dashboard/${this.props.formato.id}/${this.props.custom_view.id}/${this.props.survey_parent_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            data: data.data,
            dataQuestion: data.dataQuestion,
            isLoaded: false,
            survey_total: data.survey_total,
            filters: data.filters,
            isFilter:false,
            surveys: data.surveys,
            activePage: 1,
            charts: data.charts
          });
        });
    }


    loadDataFilter = (data) => {
        console.log("estoy en el data filter:",  data)
        this.setState({
            formUpdateRepy: data
        })
        console.log("Object keys:",Object.keys(data))
        console.log("Object keys:",data[Object.keys(data)[0]])
        let query = Object.keys(data)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
             .join('&');

        fetch(`/formatos/get_surveys_index_dashboard_filter/${this.props.formato.id}/${this.props.custom_view.id}/${this.props.survey_parent_id}/?${query}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log("hola")
          this.setState({
            data: data.data,
            dataQuestion: data.dataQuestion,
            isLoaded: false,
            filters: data.filters,
            survey_total_filter: data.survey_total,
            isFilter:true,
            charts: data.charts

          });
        });
    }




    render() {
        return (
            <React.Fragment>
                <IndexDashboard
                    updateStateLoad={this.updateStateLoad}
                    data={this.state.data}
                    dataQuestion={this.state.dataQuestion}
                    dataAnswers={this.state.dataAnswers}
                    surveys={this.state.surveys}
                    format={this.props.formato}
                    updateData={this.updateData}
                    loadData={this.loadData}
                    isLoaded={this.state.isLoaded}
                    users={this.props.company_users}
                    procesos={this.props.procesos}
                    positions={this.props.positions}
                    loadDataFilter={this.loadDataFilter}
                    filters={this.state.filters}
                    format_category={this.props.format_category}
                    tableWidth={this.state.tableWidth}
                    handlePageChange={this.handlePageChange}
                    domain={this.props.domain}

                    survey_total={this.state.survey_total}
                    countPage={this.state.countPage}

                    survey_total_filter={this.state.survey_total_filter}
                    user={this.props.user}



                    isFilter={this.state.isFilter}
                    cleanFilters={this.cleanFilters}
                    estados={this.props.estados}
                    format_exels={this.props.format_exels}
                    view={this.props.view}
                    format_indicators={this.props.format_indicators}
                    state_records={this.props.state_records}
                    survey_parent_id={this.props.survey_parent_id}
                    custom_view_id={this.props.custom_view.token}
                    charts={this.state.charts}
                    imageUpload={this.props.imageUpload}
                    key_text={this.props.key_text}
                />
            </React.Fragment>
        );
    }
}


export default SurveyCustomDashboard;
WebpackerReact.setup({ SurveyCustomDashboard });

