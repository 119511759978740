
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const Columnchart = (props) => {
    
    const [data, setData] = useState({
        options: {
            plotOptions: {
              bar: {
                  distributed: true
              }
            },
            chart: {
              toolbar: {
                show: true,
                tools: {
                  download: true,
                }
              },
              id: "Personal"
            },
            legend: {
              show: false,
            },
            colors: props.user_colors,

            xaxis: {
              categories: []
            }
          },
          series: [
            {
              name: "Rendimiento",
              data: []
            }
          ]
        })

    useEffect(() => {
        setData({
            options: {
                plotOptions: {
                  bar: {
                      distributed: true
                  }
                },
                chart: {
                  toolbar: {
                    show: true,
                    tools: {
                      download: true,
                    }
                  },
                  id: "Personal"
                },
                legend: {
                  show: false,
                },
                colors: props.user_colors,
                xaxis: {
                  categories: props.labels
                },
                  dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                      return Math.floor(val) + "%"
                    },
                  }
              },
              series: [
                {
                  name: "Rendimiento",
                  data: props.series
                }
              ]
            })
    }, [props]);


    return (
        <div>
            <ReactApexChart  height="500px" options={data.options} series={data.series} type="bar" />
        </div>
    );
}
export default Columnchart;