import React, { Component } from 'react';
import Preloader from '../../../../GeneralComponents/LoadingComponen'
import FormCreate from './FormCreate'
import FormCreateCkeckList from './FormCreateCkeckList'
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import { isMobile } from 'react-device-detect';
import FormFilterName from '../../../../GeneralComponents/FormFilterName';

class IndexLabel extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modalCkeckList: false,
            modeEdit: false,
            ErrorValues: true,
            id: "",
            label: {},

            form: {
                name: "",
                color: "",
                task_board_id: "",
            },

            selectedOption: {
                task_board_id: "",
                label: "Tablero de tarea"
            }
        }
    }

    clearValues = () => {
        this.setState({
            id: "",
            modeEdit: false,
            form: {
                name: "",
                color: "",
                task_board_id: "",
            },

            selectedOption: {
                task_board_id: "",
                label: "Tablero de tarea"
            }
        })
    }

    clearValuesChecklist = () => {
        this.setState({
            label_id: "",
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    toogleChecklist = (from, label) => {
        if (from == "new") {
            this.setState({ modalCkeckList: true, label: label })
        } else {
            this.setState({ modalCkeckList: false, label: "" })
            this.props.loadDataLabels()
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }


    HandleClick = () => {
        if(!this.state.modeEdit)
            fetch(`/task/task_labels`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.messageSuccess(data)
                this.setState({ modal: false })
                this.props.updateDataLabels(data.register)
                this.clearValues();
            });
        else{
            fetch(`/task/task_labels/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                console.log("data", data);
                this.props.messageSuccess(data);
                this.setState({ modal: false });
                this.props.updateItemLabel(data.register);
                this.clearValues();
            });
        }
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/task/task_labels/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.props.loadDataLabels()
                    this.props.messageSuccess(response)
                });
            }
        });
    };

    edit = (label) => {
        this.setState({
            modeEdit: true,
            modal: true,
            id: label.id,
            form: {
                name: label.name,
                color: label.color,
            }
        })
    }

    handleChangeComplete = (color) => {
        this.setState({ 
            form: {
                ...this.state.form,
                color: color.hex 
            }
        });
    };

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                task_board_id: selectedOption.value
            }
        });
    };

    render() {
        return (
            <React.Fragment>

                <FormFilterName
                    handleChangeFilter={this.props.handleChangeFilterLabel}
                    handleClickFilter={this.props.handleClickFilterLabel}
                    showbtnSubmit={this.props.showbtnSubmit}
                    clearValues={this.props.clearValuesLabel}
                    formFilter={this.props.formFilterLabel}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar etiqueta" : "Añadir etiqueta"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Añadir"}
                        
                        //colors 
                        onChangeFormColor={this.handleChangeComplete}

                        //select 
                        selectedOption={this.state.selectedOption}
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        task_boards={this.props.task_boards}
                        errorValues={this.state.ErrorValues}
                    />
                )}

                {this.state.modalCkeckList && (
                    <FormCreateCkeckList
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalCkeckList}
                        toggle={this.toogleChecklist}
                        title={"Crear Checklist"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        label={this.state.label}

                        //users 

                        users={this.props.users}
                        user={this.props.user}
                        loadDataLabels={this.props.loadDataLabels}

                        updateDataCheckLists={this.props.updateDataCheckLists}
                        deleteCheckLists={this.props.deleteCheckLists}
                    />
                )}

                {!this.props.isLoadedLabels ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 pr-3 pl-3 card">
                            <div className="card-header mb-3">
                                <div className="row w-100">
                                    <div className="col-md-9">
                                        Etiquetas
                                    </div>

                                    <div className="col-md-3 pr-0 text-right">
                                        <button
                                            className="btn-shadow btn btn-info"
                                            onClick={() => this.toogle("new")}
                                        >
                                            Añadir etiqueta
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable"> */}
                                    {/* <thead>
                                        <tr>
                                            <th style={{ width: "25%" }}>Nombre</th>
                                            <th style={{ width: "25%" }}>Tablero</th>
                                            <th>Checklists</th>
                                            <th style={{ width: "8%" }}></th>
                                        </tr>
                                    </thead> */}

                                    {/* <tbody> */}
                                        {this.props.dataLabels.length >= 1 ? (
                                            <div className="row">
                                                {this.props.dataLabels.map(label => (
                                                    <div className="col-md-4" key={label.id}>
                                                        <div className="card mb-2 mt-3">
                                                            <div 
                                                            className="card-header ui-indexLabel-cardHeader"
                                                            style={{ backgroundColor: label.color }}>
                                                                <span>{label.name}</span>
                                                            </div>
                                                            <div className="card-body p-2 ui-indexLabel-cardBody">
                                                                <div className="ui-indexLabel-boardTask mt-1 mb-2">
                                                                    Tablero<span>{label.task_board ? label.task_board.name : ""}</span>
                                                                </div>
                                                                
                                                                <button 
                                                                    className="btn btn-info mb-2"
                                                                    onClick={() => this.toogleChecklist("new", label)}
                                                                > 
                                                                    + Checklists
                                                                </button>                                                            
                                                                <div className="col-md-12" style={{ background: "#e6e6e6", height: "100px", borderRadius: "10px" }}>
                                                                    <div className="col-md-12 pt-3 pl-0 pr-0">
                                                                        {label.check_lists.length >= 1 ? (                                         
                                                                            <div className="ui-indexLabel-scrollHorizontal">
                                                                                <div className="row">
                                                                                    {label.check_lists.map((check_list, index) => (
                                                                                        <React.Fragment>
                                                                                            <div className="ui-indexLabel-cardChecklist">
                                                                                                <div className="card mb-2 mr-2 ml-2">
                                                                                                    <div className="card-body pt-1 pb-1 pr-2 ui-indexLabel-cardBodyChecklist">
                                                                                                        <div className="mt-1">
                                                                                                            <p className="ui-indexLabel-titleChecklist">{check_list.name}</p>
                                                                                                        </div> 
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>  
                                                                                        </React.Fragment>
                                                                                    ))}

                                                                                </div>
                                                                            </div> 
                                                                        ) : (
                                                                            <div className="col-md-12 card p-0">
                                                                                <div className="card-body text-center p-0 ui-indexLabel-messsageNoRegistro">
                                                                                    <div className="text-center mt-2 mb-2">
                                                                                        <h5>No se han creado checklist para esta etiqueta ({label.name})</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                    </div> 
                                                                </div>
                                                            </div>
                                                            <div className="card-footer ui-indexLabel-cardFooter">
                                                                <div className="btn-group">
                                                                    <div className="ui-indexLabel-icons">
                                                                        {true && (
                                                                            <a
                                                                            onClick={() => this.edit(label)}
                                                                            className="btn ui-indexLabel-iconEdit">
                                                                            <i className="far fa-edit"></i> 
                                                                            </a>
                                                                        )}

                                                                        {true && (
                                                                            <a
                                                                                onClick={() => this.delete(label.id)}
                                                                                className="btn ui-indexLabel-iconTrash"
                                                                            >
                                                                                <i className="far fa-trash-alt"></i>
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    // <tr key={label.id}>
                                                    //     <td>
                                                    //         <p className="items-colors" style={{ backgroundColor: label.color }}>{label.name}</p>
                                                    //     </td>

                                                    //     <td>
                                                    //         <p>{label.task_board ? label.task_board.name : ""}</p>
                                                    //     </td>


                                                    //     <td>
                                                    //         <p
                                                            
                                                    //         >
                                                    //         <span
                                                    //         className="link-line-dark"
                                                    //         onClick={() => this.toogleChecklist("new", label)}
                                                    //         > + Checklists</span>
                                                    //         </p>
                                                    //             <hr/>
                                                    //         <div className="col-md-12 pl-0 pb-2">
                                                    //             {label.check_lists.map((check_list, index) => (
                                                    //             <span>{index > 0 ? "," : "" } {check_list.name}</span>
                                                    //             ))}
                                                    //         </div>
                                                    //     </td>

                                                    //     <td className="text-right" style={{ width: "10px" }}>
                                                    //         <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    //             <div className="btn-group" role="group">
                                                    //                 <button
                                                    //                     className="btn-shadow btn btn-info"
                                                    //                     id="btnGroupDrop1"
                                                    //                     type="button"
                                                    //                     data-toggle="dropdown"
                                                    //                     aria-haspopup="true"
                                                    //                     aria-expanded="false"
                                                    //                 >
                                                    //                     <i className="fas fa-bars"></i>
                                                    //                 </button>

                                                    //                 <div className="dropdown-menu dropdown-menu-right">

                                                    //                     {true && (
                                                    //                         <button
                                                    //                             onClick={() => this.edit(label)}
                                                    //                             className="dropdown-item"
                                                    //                         >
                                                    //                             Editar
                                                    //                         </button>
                                                    //                     )}

                                                    //                     {true && (
                                                    //                         <button
                                                    //                             onClick={() => this.delete(label.id)}
                                                    //                             className="dropdown-item"
                                                    //                         >
                                                    //                             Eliminar
                                                    //                         </button>
                                                    //                     )}
                                                    //                 </div>
                                                    //             </div>
                                                    //         </div>
                                                    //     </td>
                                                        
                                                    // </tr>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="text-center">
                                                <div className="text-center mt-4 mb-4" style={{ width: '100%'}}>
                                                    <p className='ui-indexLabel-messageNoRecord'>Todavía no hay etiquetas, da clic en <a onClick={() => this.toogle('new')}  className="btn btn-info">Añadir etiqueta</a> y genera la primera etiqueta</p>
                                                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/7/noRecord_task.jpg" alt="" className="ui-indexLabel-imgNoRecord" />
                                                </div>
                                            </div>
                                        )}
                                    {/* </tbody>
                                </table>
                            </div> */}

                            <div className="col-md-12 mt-4">
                                <div className="row">
                                    <div className={`col-md-${isMobile ? "1" : "7"}`}>
                                        <p>
                                            Mostrando {this.props.dataLabels.length} de {this.props.labels_count}
                                        </p>
                                    </div>

                                    <div className="col-md-2 text-right">
                                        <Pagination
                                            hideNavigation
                                            activePage={this.props.activePage}
                                            itemsCountPerPage={this.props.countPage}
                                            itemClass="page-item"
                                            innerClass="pagination"
                                            linkClass="page-link"
                                            totalItemsCount={this.props.labels_count}
                                            pageRangeDisplayed={this.props.countPage}
                                            onChange={this.props.handlePageChange}
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>
                    </React.Fragment>
                ) : (
                    <div className="card">
                        <div className="card-body">
                            <Preloader/>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default IndexLabel;

