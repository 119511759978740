import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalUpdateFormatDate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            form: {
                custom_format: this.props.question.custom_format,
            }
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };
    
    handleChangeUpdate = (e) => {
        const form = { custom_format: e.target.value }
        fetch(`/formatos/questions/${this.props.question.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.update_question(data.register);
            this.setState({
                form: {
                    custom_format: data.register.custom_format
                }
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>
                        <div className='ui-modals-header-title__container'>
                            <span className='ui-titles_modals'>{this.props.title}</span>
                            <span className='ui-name-question-modals'>Pregunta {this.props.question.question}</span> 
                        </div>
                    </ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className={`col-md-12`}>
                                    <select
                                        name="value"
                                        className="form form-control"
                                        onChange={this.handleChangeUpdate}
                                        value={this.state.form.custom_format}
                                    >
                                        <option value="">Seleccione un valor</option>
                                        <option value="formato1">2022-03-14</option>
                                        <option value="formato2">2022/03/14</option>
                                        <option value="formato3">22/03/14</option>
                                        <option value="formato4">Mar 14, 2022</option>
                                        <option value="formato5">Marzo 14, 2022</option>
                                        <option value="formato6">Mie 14, Mar 2022</option>
                                        <option value="formato7">Mie 14, Marzo 2022</option>
                                        <option value="formato8">Miercoles 14, Mar 2022</option>
                                        <option value="formato9">Miercoles 14, Marzo 2022</option>
                                    </select>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <button className="btn btn-outline-danger" onClick={() => this.props.toggle()}>Cerrar</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalUpdateFormatDate;
