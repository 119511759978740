import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledCollapse } from 'reactstrap';
import Preloader from '../../../GeneralComponents/LoadingComponen';
import Swal from "sweetalert2/dist/sweetalert2.js";
import ModalQr from '../../formatos/Surveys/ModalQr';


const MobileView = (props) => {
    const token = document.querySelector("[name='csrf-token']").content;
    const [register, setRegister] = useState({});
    const [isLoaded, setIsLoaded] = useState(true);
    const [modalQr, setModalQr] = useState(false);

    useEffect(() => {
        getInfo();
    }, [props.loadView || props.page_id]);

    const getInfo = () => {
        fetch(`/beetree/get_register_page/${props.page_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                setRegister(data.register);
                setIsLoaded(false)
            });
    }

    const copyLink = () => {
        Swal.fire({
            position: "center",
            type: `success`,
            title: `Link copiado`,
            showConfirmButton: false,
            timer: 1500,
        });

        navigator.clipboard.writeText(register.share_link)
    }

    const typeOfUrl = (link) => {
        const youtube = 'www.youtube.com';

        if(link.url.includes(youtube)){
            return(
                <React.Fragment>
                    <div className="ui-btree-mobileView-iconSelect">
                        <i className={`mr-3 ${link.icon ? link.icon.html_name : 'fas fa-film'}`}></i>
                    </div>

                    <div className="ui-btree-mobileView-title_link">
                        <a style={{ color: link.button_style.color }} id={`collapseExample${link.id}`}>
                            {link.title_link == "" ? "YOUTUBE" : link.title_link}
                        </a>
                    </div>
                    <div>
                        <UncontrolledCollapse toggler={`#collapseExample${link.id}`}>
                            <iframe 
                                width="230" 
                                height="315" 
                                src={link.url}
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen
                            ></iframe> 
                        </UncontrolledCollapse>
                    </div>
                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    <div className="ui-btree-mobileView-iconSelect">
                        {link.icon ? <i className={link.icon.html_name}></i> : <i className="fab fa-reddit"></i>}
                    </div>

                    <div className="ui-btree-mobileView-title_link">
                        <a href={link.url} style={{ color: link.button_style.color }} target="_blank" >{link.title_link}</a>
                    </div>
                </React.Fragment>
            )
        }
    }

    const views = (class_name) => {
        return (
            <div className={`ui-btree-${class_name}-containerAll ui-unique_height`}>
                <div className="ui-btree-mobileView-container-logo">
                    <div className="ui-btree-mobileView-logo">
                        <div className="ui-btree-mobileView__container-img">
                            <img src={register.enterprise_img_logo_company} alt="Logo empresa" />
                        </div>
                    </div>
                </div>

                <div className="ui-btree-mobileView-container-textEnterprise" style={{ fontFamily: register.selected_template.background_style.fontFamily, color: register.selected_template.background_style.color }}>
                    <div className="ui-btree-mobileView-nameEnterprise">
                        <span>{register.enterprise_name}</span>
                    </div>

                    <div className="ui-btree-mobileView-infocomplementEnterprise text-center">
                        <span>{register.enterprise_aditional_info}</span>
                    </div>
                </div>

                {true ? (
                    <React.Fragment>
                        {register.links.map((link, index) => (
                            <div className={`ui-btree-${class_name}-containerCard-link`} style={link.button_style} key={link.id}>
                                {typeOfUrl(link)}
                            </div>
                        ))}
                    </React.Fragment>
                )
                    : (
                        <div className={`ui-btree-${class_name}-containers-links-scroll-overflow`}>
                            <div>
                                {register.links.map(link => (                                    
                                    <div className={`ui-btree-${class_name}-containerCard-link`} style={link.button_style} key={link.id}>
                                        <div className="ui-btree-mobileView-iconSelect">
                                            {link.icon ? <i className={link.icon.html_name}></i> : <i className="fab fa-reddit"></i>}
                                        </div>

                                        <div className="ui-btree-mobileView-title_link">
                                            <a href={link.url} style={{ color: link.button_style.color }} target="_blank" >{link.title_link}</a>
                                        </div>
                                    </div>                                    
                                ))}
                            </div>
                        </div>
                    )}


            </div>
        )
    }

    const toogleModalQr = (from) => {
        if (from == "new") {
            setModalQr(true);
        } else {
            setModalQr(false);
        }
    }

    return (
        <React.Fragment>

            {modalQr && (
                <ModalQr
                    //modal props
                    backdrop={"static"}
                    modal={modalQr}
                    toggle={toogleModalQr}
                    title={"Código QR"}
                    url={register.share_link}
                />
            )}

            <style>
                @import url('https://fonts.googleapis.com/css2?family=Arsenal&family=Cormorant+Garamond&family=Fira+Sans+Condensed&family=Handlee&family=Libre+Bodoni:ital@1&family=Macondo&family=Redressed&family=Roboto&family=Rubik&family=Ruluko&display=swap');
            </style>

            <div className="ui-theme-settings settings-open">
                <div className="theme-settings__inner">
                    <div className="scrollbar-container">
                        <div className="theme-settings__options-wrapper">
                            <div style={{ marginTop: "60px" }}>
                                <div>

                                    {!isLoaded ? (
                                        <React.Fragment>
                                            <div className="card ui-btree-mobileView-cardContainer">
                                                <div className="ui-btree-mobileView-myLink_title">
                                                    <span>Mi beetree: <a href={register.share_link} target="_blank" data-turbolinks="true">{register.share_link}</a></span>
                                                </div>

                                                <div className="iu-btree-mobileView-iconShare" id='bw-btree-icon_share'>
                                                <UncontrolledDropdown className='btn-group'>
                                                    <DropdownToggle className='btn btn-light' style={{color: '#17a3f2'}}>
                                                        <i className="fas fa-share-alt"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                        {true && (
                                                            <DropdownItem
                                                                onClick={() => copyLink()}
                                                                className="dropdown-item"
                                                            >
                                                                Compartir enlace
                                                            </DropdownItem>
                                                        )}

                                                        {true && (
                                                            <DropdownItem
                                                                onClick={() => toogleModalQr("new")}
                                                                className="dropdown-item"
                                                            >
                                                                Compartir codigo QR
                                                            </DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                </div>
                                            </div>

                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className='ui-btree-mobileView-title-tabs'>Cambiar vista a:</li>
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                                                        <i className="fas fa-mobile-alt"></i>
                                                    </a>
                                                </li>

                                                <li className="nav-item">
                                                    <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                                                        <i className="fas fa-tablet-alt"></i>
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <div className="smartphone" style={register.selected_template ? register.selected_template.background_style : null}>
                                                        <div className="content">

                                                            {views("mobileView")}

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                    {/* TABLET */}
                                                    <div className="tablet" style={register.selected_template ? register.selected_template.background_style : null}>
                                                        <div className="content">
                                                            {views("tabletView")}

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                                    <div className="laptop">
                                                        <div className="content">
                                                            <p>asdasd</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ) : (
                                        <Preloader />
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default MobileView;

