import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import Preloader from '../../../GeneralComponents/LoadingComponen';

const ModalUpdateDocuments = (props) => {
    const token = document.querySelector("[name='csrf-token']").content;

    const [isLoaded, setIsLoaded] = useState(false);
    const [procesos, setProcesos] = useState([])
    const [data, setData] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const updateItem = (register) => {
        setData(data.map(item => {
            if (item.id === register.id) {
                return {
                    ...item,
                    name: register.name,
                    state: register.state,
                    proceso: register.proceso
                }
            }
            return item;
        }));
    }

    const loadData = () => {
        fetch(`/document_management/get_documents_without_process/${props.document_type_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setIsLoaded(false)
        });
    }

    const handleChange = (document_id, e) => {
        const form = { id: document_id, proceso_id: e.target.value }
        fetch(`/document_management/update_document_proceso`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            updateItem(data.register);
        });
    }

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered modal-lg" backdrop={props.backdrop}>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12 mb-3 mt-3">
                            <div className="alert alert-primary text-center" role="alert">
                                <b>Para actualizar los códigos de los documentos asociados a este tipo de documento, es necesario realizar previamente la actualización de aquellos documentos que no tienen procesos relacionados.</b>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="content main-card mb-3 card card-style">
                                <div className="card-body ui-tables_layout-register-card-body">
                                    <div className="ui-tables_layout-table__container">
                                        {!isLoaded ? (
                                            <table className="table ui-tables_layout-table-header__container" id="sampleTable">
                                                <thead className='ui-tables_layout-table-header'>
                                                    <tr className='ui-tables_layout-__container-title-table'>
                                                        <th>Documento</th>
                                                        <th>Estado</th>
                                                        <th>Proceso</th>
                                                        <th style={{ width: 245 }}>Procesos</th>
                                                    </tr>
                                                </thead>

                                                <tbody className='ui-tables_layout-data__container'>
                                                    {data.map(document => (
                                                        <tr key={document.id}>
                                                            <td>{document.name}</td>
                                                            <td>{document.state}</td>
                                                            <td>{document.proceso ? document.proceso.name : ""}</td>
                                                            <td>
                                                                <select 
                                                                    name="position" 
                                                                    className="form form-control"
                                                                    value={document.proceso ? document.proceso.id : null}
                                                                    onChange={(e) => handleChange(document.id, e)}
                                                                >
                                                                    <option value="">Seleccione la posición </option>
                                                                    {props.procesos.map(proceso => (
                                                                        <option value={proceso.id}>{proceso.name}</option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="card-body">
                                                <Preloader />
                                            </div>
                                        )} 
                                    </div>       
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}


export default ModalUpdateDocuments;

