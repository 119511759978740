import React, { Component } from 'react';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            options_operation: ["Contiene", "Igual", "Diferente"],
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="row">
                    {(this.props.question_style_conditional_length >= 1 && this.props.index_style_conditional >= 1) &&(
                        <div className="col-md-12">
                            <select 
                                name="conditional"
                                value={this.props.formValues.conditional}
                                onChange={this.props.onChangeForm}
                                className={`form form-control`}
                            >
                                <option value="">Condicional</option>
                                <option value="Y">Y</option>
                                <option value="O">O</option>
                            </select>
                        </div>
                    )}

                    {this.props.question.question_type == "dynamic_field" ? (
                        <div className={`col-md-12 ${this.props.question_style_conditional_length >= 1 ? "mt-3" : ""}`}>
                            <input
                                type="hidden"
                                name="question_id"
                                value={this.props.selectedOption.question_id}
                            />
                            <Select
                                onChange={this.props.handleChangeAutocomplete}
                                options={this.props.questions}
                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.question_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOption}
                            />
                        </div>
                    ) : (
                        <div className={`col-md-12 ${this.props.question_style_conditional_length >= 1 ? "mt-3" : ""}`}>
                            <input
                                type="text"
                                name="value"
                                value={this.props.question.question}
                                disabled={true}
                                className={`form form-control`}
                            />
                        </div>
                    )}

                    <div className="col-md-12 mb-3 mt-3">
                        <select 
                            name="operation"
                            value={this.props.formValues.operation}
                            onChange={this.props.onChangeForm}
                            className={`form form-control ${!this.props.errorValues && this.props.formValues.operation == "" ? "error-class" : ""}`}
                        >
                            <option value="">Operación..</option>
                            {this.props.options_operation.map((item, index) => (
                                <option value={item}>{item}</option>
                            ))}
                        </select>
                    </div>

                    {/*<div className="col-md-12">
                        <input
                            type="text"
                            name="value"
                            value={this.props.formValues.value}
                            onChange={this.props.onChangeForm}
                            className={`form form-control ${!this.props.errorValues && this.props.formValues.value == "" ? "error-class" : ""}`}
                            placeholder="Valor"
                        />
                    </div>*/}

                    <div className="col-md-12 mt-2 mb-2">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => this.props.handleChangeCheckBoxQuestionToCompareShow(e, "question_to_compare_show")} className="custom-control-input" id={`customShowTable`} checked={this.props.formValues.question_to_compare_show} />
                            <label className="custom-control-label" htmlFor={`customShowTable`}>¿Comparar con pregunta?</label>
                        </div>
                    </div>
                                            

                    <div className={`col-md-12`}>
                        {this.props.options.length >= 1 ? (
                            <React.Fragment>
                                <select 
                                    name="value" 
                                    onChange={this.props.onChangeForm}
                                    value={this.props.formValues.value}
                                    className="form form-control"
                                >
                                      <option value="">Seleccionar</option>
                                    {this.props.options.map((item, index) => (
                                        <option value={item.name}>{item.name}</option>
                                    ))}
                                </select>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {(this.props.type_question != "answer_user_id" && this.props.type_question != "proceso_id" && this.props.type_question != "position_id") && (
                                    <input 
                                        type={this.props.type_question == "answer_date" ? "date" : "text"} 
                                        disabled={this.props.type_question == "answer_user_id" || this.props.type_question == "proceso_id" || this.props.type_question == "position_id" || this.props.formValues.question_to_compare_show ? true : false}
                                        name="value"
                                        onChange={this.props.onChangeForm}
                                        value={this.props.formValues.value}
                                        className={`form form-control ${!this.props.errorValues && this.props.formValues.value == "" ? "error-class" : ""}`}
                                        placeholder="Valor"
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </div>

                    {(this.props.type_question == "answer_user_id" || this.props.type_question == "proceso_id" || this.props.type_question == "position_id") && (
                        <div className={`col-md-12`}>
                            <input
                                type="hidden"
                                name="value"
                                value={this.props.selectedOptionSelect.value}
                            />
                            <Select
                                onChange={this.props.handleChangeAutocompleteSelect}
                                options={this.props.values}
                                autoFocus={false}
                                className={`link-form`}
                                value={this.props.selectedOptionSelect}
                            />
                        </div>
                    )}

                    {this.props.formValues.question_to_compare_show && (
                        <div className="col-md-12 mt-3">
                            <input
                                type="hidden"
                                name="question_to_compare_id"
                                value={this.props.selectedOptionQuestionToCompare.question_to_compare_id}
                            />
                            <label>Pregunta a comparar</label>
                            <Select
                                onChange={this.props.handleChangeAutocompleteQuestionToCompare}
                                options={this.props.questions}
                                autoFocus={false}
                                className={`link-form`}
                                value={this.props.selectedOptionQuestionToCompare}
                            />
                        </div>
                    )}


                    <div className="col-md-12 mt-3 text-center">
                        <button
                            className="btn-shadow btn btn-info"
                            onClick={this.props.handleClick}
                        >
                            {this.props.modeEdit ? "Actualizar" : "Crear"}
                        </button>

                        <button
                            className="btn-shadow btn btn-danger ml-2"
                            onClick={this.props.clearValues}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FormCreate;
