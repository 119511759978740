import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ShowTask from './ShowTask'
import IndexLabels from '../tasksLabels/IndexLabels'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

class TaskList extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            showPopoverLabels: false,
            isLoadedShow: true,
            id: "",
            task: {},
            dataShow: {},
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false, task: {} })
        }
    }

    handleClickShow = (id) => {
        fetch(`/task/get_show_task/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                task: data.task,
                modal: true,
            })
        });
    }

    editState = (id, state) => {
        fetch(`/task/update_state/${id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.props.updateItem(data.task);
            });
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
          text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                this.props.updateStateLoad(true)
                fetch(`/task/tasks/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        console.log("response", response);
                        if(this.props.isFiltering){
                            this.props.handleClickFilter();
                        }else{
                            this.props.loadData()    
                        }
                        this.props.messageSuccess(response)
                    });
            }
        });
    };

    loadDataShow = (id) => {
        fetch(`/task/get_show_taks/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataShow: data.data,
                    isLoadedShow: false
                });
            });
    }

    get_color = (state) => {
        if (state == "create") {
            return "rgb(59, 174, 247)"
        } else if (state == "doing") {
            return "rgb(255, 159, 26)"
        } else if (state == "made") {
            return "rgb(97, 189, 79)"
        }
    }

    get_colorDate = (state) => {
        let resta = new Date(state).getTime() - new Date().getTime()
        resta = Math.round((resta / (1000 * 24 * 60 * 60)));
        resta = resta + 1
        if (resta >= 5) {
            return "green"
        } else if (resta < 5 && resta >= 0) {
            return "orange"
        } else {
            return "red"
        }
    }

    render() {
        const task = this.props.task;
        return (
            <React.Fragment>

                {this.state.modal && (
                    <ShowTask
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Crear tarea"}

                        //form props
                        task={this.state.task}

                        loadTasks={this.loadDataShow}
                        users={this.props.users}

                        //load show
                        isLoadedShow={false}

                        //current_user
                        user={this.props.user}

                        //load tasks
                        loadData={this.props.loadData}

                        //test load data

                        updateItem={this.props.updateItem}
                        updateData={this.props.updateData}

                        //procesos 

                        procesos={this.props.procesos}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                    />
                )}

                <ul className="todo-list-wrapper list-group list-group-flush" onClick={() => this.handleClickShow(task.id)}>
                    <li className="list-group-item">
                        <div className="todo-indicator " style={{ background: this.get_colorDate(task.due_date) }}></div>
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper" style={this.props.module_task === 'tasks' ? { justifyContent: 'space-between', alignItems: 'flex-start' } : {}}>
                                <div className="widget-content-left">
                                    <div className='widget-heading task-count'>
                                         {task.check_list_item != null ? <React.Fragment><span>{`T${task.count != 0 ? task.count : ""}`}</span>  <a className="link-task-show" onClick={() => this.editTask(task.check_list_item.task)}>{`-> T${task.check_list_item.task.count != 0 ? task.check_list_item.task.count : ""}`}</a></React.Fragment> : `T${task.count != 0 ? task.count : ""}`}                                         
                                    </div>                                   
                                    <div className="widget-heading" style={{ wordBreak: "break-all" }}>{task.name}</div>
                                    {task.task_labels.map((estados, index) => (
                                        <span key={index} style={{ background: estados.color }} className={`badge badge-secondary mt-2 ${index >= 0 ? "mr-2" : ""}`}>
                                            {estados.name}
                                        </span>
                                    ))}
                                </div>
                                {this.props.module_task === 'tasks' && (
                                    <div className='ui-taskList__container-icon-board'>
                                        <UncontrolledTooltip target={`tasklistBoard${task.count}`} placement='top'>Tablero: {task.task_board.name}</UncontrolledTooltip>
                                        <i className="fas fa-chalkboard-teacher" id={`tasklistBoard${task.count}`}></i>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-md-12 pl-0 mt-2">
                            <div className="task-aditional-info mb-1">
                                <div className="pl-0 ">
                                    <i className="far fa-calendar-alt mr-2"></i>{task.due_date}
                                </div>
                                <div className="text-left pl-0">
                                     {task.task_source != null && task.task_source != "" && (
                                        <React.Fragment>
                                           <a href={task.url_source} data-turbolinks="true" target="_blank">{task.task_source}</a> 
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>

                            <div className="task-info-icons-parent">

                                <div className="task-info-icons">                                    
                                    {task.general_files >= 1 && (
                                        <div className="text-left  mr-3">

                                            <React.Fragment>
                                                <i className="fas fa-paperclip mt-2" style={{ fontSize: "17px" }}></i>
                                                <b className="ml-1">{task.general_files}</b>
                                            </React.Fragment>

                                        </div>
                                    )}

                                    {task.general_comments >= 1 && (
                                        <div className="text-left p-0 mr-3 msg-count">

                                            <React.Fragment>
                                                <i className="far fa-comments mt-2" style={{ fontSize: "17px" }}></i>
                                                <b className="ml-1">{task.general_comments}</b>
                                         </React.Fragment>

                                        </div>
                                    )}

                                    {task.checklist != null && (
                                        <div className="text-left p-0 msg-count">
                                            <React.Fragment>
                                                <i className="fas fa-check-double mt-2" style={{ fontSize: "17px" }}></i>
                                                <b className="ml-1">{task.checklist}</b>
                                            </React.Fragment>
                                        </div>
                                    )}
                                </div>



                                <div className="row">
                                    <div className="p-0 mr-2">
                                        <div
                                            className="icon-show-user"
                                            id={`PopoverLegacy${task.id}`}
                                            onClick={() => this.setState({ editFileState: true })}
                                        >
                                            {task.user.first_name.charAt(0)} {task.user.first_last_name.charAt(0)}
                                        </div>
                                    </div>

                                    <div className="p-0">
                                        <div
                                            className="icon-show-user-responsible"
                                            id={`PopoverOwner${task.id}`}
                                            onClick={() => this.setState({ editFileState: true })}
                                        >
                                            {task.user_owner != null ? task.user_owner.first_name.charAt(0) : ""} {task.user_owner != null ? task.user_owner.first_last_name.charAt(0) : ""}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <UncontrolledPopover trigger="legacy" placement="right" target={`PopoverLegacy${task.id}`}>
                                <PopoverBody>
                                    <div className="col-md-12" style={{ width: "245px", height: "72px" }}>
                                        <div className="row">
                                            <div className="col-md-3 p-0">
                                                <div className="icon-user-task mt-2">
                                                    <b>{task.user.first_name.charAt(0)} {task.user.first_last_name.charAt(0)}</b>
                                                </div>
                                            </div>

                                            <div className="col-md-8">
                                                <b>{task.user.first_name} {task.user.first_last_name}</b>
                                                <p>{task.user.second_email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </PopoverBody>
                            </UncontrolledPopover>

                            <UncontrolledPopover trigger="legacy" placement="right" target={`PopoverOwner${task.id}`}>
                                <PopoverBody>
                                    <div className="col-md-12" style={{ width: "245px", height: "72px" }}>
                                        <div className="row">
                                            <div className="col-md-3 p-0">
                                                <div className="icon-show-user-responsible mt-2">

                                                    <b>  {task.user_owner != null ? task.user_owner.first_name.charAt(0) : ""} {task.user_owner != null ? task.user_owner.first_last_name.charAt(0) : ""}</b>
                                                </div>
                                            </div>

                                            <div className="col-md-8">
                                                <b>{task.user_owner != null ? task.user_owner.first_name : ""} {task.user_owner != null ? task.user_owner.first_last_name : ""}</b>
                                                <p>{task.user_owner != null ? task.user_owner.second_email : ""}</p>
                                            </div>
                                        </div>
                                    </div>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>

                    </li>
                </ul >
            </React.Fragment >
        );
    }
}

export default TaskList;
