import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import Preloader from '../GeneralComponents/LoadingComponen';
import FormCreate from '../components/settings/BasicTutorials/FormCreate';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

class BasicTutorialAdmin extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            modeEdit: false,
            
            basic_tutorial_id: "",
            nameFile: "",
            form: {
                description: "",
                file: {},
                image: "",
                name: "",
                tutorial_type: "file", 
                video_link: ""
            },

            formUpdatePosition: {
                id: "",
                position: "",
            }
        }
    }

    componentDidMount(){
        this.loadData();
    }

    handleChangeTypeTutorial = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
                file: {},
                video_link: "",
            }
        });
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    handleFile = (archivo) => {
        archivo.map(field => (
            this.setState({
                form: {
                    ...this.state.form,
                    file: field.size >= 11485760 ? {} : field
                },
  
                nameFile: field.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : field.path,
            })
        ));
    };

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (basic_tutorial) => {
        this.setState({
            data: this.state.data.map(item => {
                if (basic_tutorial.id === item.id) {
                    return {
                        ...item,
                        name: basic_tutorial.name,
                        description: basic_tutorial.description,
                        image: basic_tutorial.image,
                        file: basic_tutorial.file,
                        tutorial_type: basic_tutorial.tutorial_type, 
                        video_link: basic_tutorial.video_link
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            basic_tutorial_id: "",
            ErrorValues: true,

            form: {
                description: "",
                file: {},
                image: "",
                name: "",
                tutorial_type: "file", 
                video_link: ""
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/basic_tutorials/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                });
            }
        });
    };

    validationForm = () => {
        if (this.state.form.format_select_id != "" &&
            this.state.form.question_asociation_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (true) {
            const formData = new FormData();
            formData.append("description", this.state.form.description)
            formData.append("file", this.state.form.file)
            formData.append("image", this.state.form.image)
            formData.append("name", this.state.form.name)
            formData.append("tutorial_type", this.state.form.tutorial_type)
            formData.append("video_link", this.state.form.video_link)

            if (this.state.modeEdit) {
                fetch(`/settings/basic_tutorials/${this.state.basic_tutorial_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            } else {
                fetch(`/settings/basic_tutorials`, {
                    method: 'POST', // or 'PUT'
                    body: formData, // data can be `string` or {object}!
                    headers: {}
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateData(data.register)
                    this.clearValues();
                    this.setState({ modal: false })
                });
            }
        }
    }

    edit = (basic_tutorial) => {
        this.setState({
            modal: true,
            modeEdit: true,
            basic_tutorial_id: basic_tutorial.id,

            form: {
                description: basic_tutorial.description,
                file: basic_tutorial.file,
                image: basic_tutorial.image,
                name: basic_tutorial.name,
                tutorial_type: basic_tutorial.tutorial_type, 
                video_link: basic_tutorial.video_link
            },
        })
    }

    loadData = () => {
        fetch("/settings/get_basic_tutorials", {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    clearValuesPosition = () => {
        this.setState({
            formUpdatePosition: {
                id: "",
                position: "",
            }
        })
    }

    updatePosition = (e) => {
        fetch(`/settings/update_position_basic_tutorial/${this.state.formUpdatePosition.id}/${e.target.value}/${this.state.formUpdatePosition.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({ data: data.data });
            this.clearValuesPosition();
        });
    }

    updateValues = (basic_tutorial) => {
        this.setState({
            formUpdatePosition: {
                id: basic_tutorial.id,
                position: basic_tutorial.position,
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Tutoriales basicos`}
                    subTitle={"Comparte tus formatos con otras empresas"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />


                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar tutorial" : "Crear tutorial"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}

                        handleFile={this.handleFile}
                        nameFile={this.state.nameFile}
                        handleChangeTypeTutorial={this.handleChangeTypeTutorial}
                    />
                )}

                {!this.state.isLoaded ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Posición</th>
                                            <th>Nombre</th>
                                            <th>Descripción</th>
                                            <th>Archivo</th>
                                            <th>Imagen</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map(basic_tutorial => (
                                                <tr key={basic_tutorial.id}>
                                                    <td>
                                                        {this.state.formUpdatePosition.id == basic_tutorial.id ? (
                                                            <React.Fragment>
                                                                <select
                                                                    className="form form-control"
                                                                    value={basic_tutorial.position}
                                                                    onChange={(e) => this.updatePosition(e)}
                                                                >
                                                                    {this.state.data.map(item => (
                                                                        <React.Fragment>
                                                                            <option value={item.position}>{item.position}</option>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </select>

                                                                <i className="fas fa-times" style={{ cursor: "pointer", color: "red", fontSize: "19px", float: "right" }} onClick={() => this.clearValuesPosition()}></i>
                                                            </React.Fragment>
                                                        ) : (
                                                            <p onClick={() => this.updateValues(basic_tutorial)}>{basic_tutorial.position}</p>
                                                        )}
                                                    </td>
                                                    <td>{basic_tutorial.name}</td>
                                                    <td>{ReactHtmlParser(basic_tutorial.description)}</td>
                                                    <td>
                                                        {basic_tutorial.file.url ? (
                                                            <React.Fragment>
                                                                <a target="_blank" href={basic_tutorial.file.url}>Ver archivo</a>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <span>No tiene archivo</span>
                                                            </React.Fragment>
                                                        )}
                                                    </td>

                                                    <td style={{display: 'flex', jutifyContent: 'center'}}>
                                                        <img src={basic_tutorial.image} alt="Imagen" className="img-thumbnail" style={{width: '150px'}} />
                                                    </td>

                                                    <td className="text-right">
                                                        {true && (
                                                            <UncontrolledDropdown direction="left" className='btn-group'>
                                                                <DropdownToggle className='btn-shadow btn btn-info'>
                                                                    <i className="fas fa-bars"></i>
                                                                </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(basic_tutorial)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(basic_tutorial.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                    </React.Fragment>
                ) : (
                    <Preloader />
                )}
            </React.Fragment>
        );
    }
}


export default BasicTutorialAdmin;
WebpackerReact.setup({ BasicTutorialAdmin });