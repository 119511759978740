import React, { Component } from 'react';
import Select from "react-select";

class FormCreate extends Component {
    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <input
                            type="hidden"
                            name="question_select_id"
                            value={this.props.selectedOptionQuestionSelect.question_select_id}
                        />

                        <Select
                            onChange={this.props.handleChangeAutocompleteQuestionSelect}
                            options={this.props.questions}
                            autoFocus={false}
                            className={`link-form ${!this.props.errorValues && this.props.formValues.question_select_id == "" ? "error-class" : ""}`}
                            value={this.props.selectedOptionQuestionSelect}
                        />
                    </div>                   

                    <div className="col-md-12 mt-3">
                        <select 
                            name="question_action" 
                            value={this.props.formValues.question_action}
                            onChange={this.props.onChangeForm}
                            className={`form form-control ${!this.props.errorValues && this.props.formValues.question_action == "" ? "error-class" : ""}`}
                        >
                            <option value="">Seleccione</option>
                            <option value="esconder">Esconder</option>
                           
                        </select>
                    </div>

                    <div className="col-md-12 mt-3 text-center">
                        <button
                            className="btn-shadow btn btn-info"
                            onClick={this.props.handleClick}
                        >
                            {this.props.modeEdit ? "Actualizar" : "Crear"}
                        </button>

                        <button
                            className="btn-shadow btn btn-danger ml-2"
                            onClick={this.props.clearValues}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FormCreate;
