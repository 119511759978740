import React, { Component } from 'react';
import Select from "react-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ModalConfigurationQuestion from './ModalConfigurationQuestion';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormatConfigurationCommentsTemplateIndex from './FormatConfigurationCommentsTemplate/Index';
import ConfigurationEmail from './ConfigurationEmail';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';
import Preloader from '../../../GeneralComponents/LoadingComponen';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showInfo: false,
            showInfoExtern: false,
            mailActive: false,
            modalConfiguration: false,
            isLoaded: true,
            editValues: [],
            cargos: [],
            pdfs: [],
            questions: [],
            showAlertClipboard: false,
            current_tab: "general",
            configuration_format: {
                has_taks: this.props.configuration_format.has_taks,
                has_comments: this.props.configuration_format.has_comments,
                has_cause_analisis: this.props.configuration_format.has_cause_analisis,
                active_external_link: this.props.configuration_format.active_external_link,
                send_notification: this.props.configuration_format.send_notification,
                email_content: this.props.configuration_format.email_content,
                is_test: this.props.configuration_format.is_test,
                result_question: this.props.configuration_format.result_question,
                is_external_index: this.props.configuration_format.is_external_index,
                columns_format_number: this.props.configuration_format.columns_format_number,
                positions: this.props.configuration_format.positions,
                download_pdf: this.props.configuration_format.download_pdf,
                send_email: this.props.configuration_format.send_email,
                format_pdf: this.props.configuration_format.format_pdf ? this.props.configuration_format.format_pdf.value : {},
                url_redirect: this.props.configuration_format.url_redirect,
                redirect_external: this.props.configuration_format.redirect_external,
                custom_message: this.props.configuration_format.custom_message,
                quantity_to_show: this.props.configuration_format.quantity_to_show,
                has_flow_chart: this.props.configuration_format.has_flow_chart,
                is_chache: this.props.configuration_format.is_chache
            },

            selectedOption: {
                label: this.props.configuration_format.result_question ? this.props.configuration_format.result_question.question : "",
                result_question_id: this.props.configuration_format.result_question ? this.props.configuration_format.result_question.id : "",
            },

            selectedOptionCargo: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionPdf: {
                format_pdf_id: this.props.configuration_format.format_pdf ? this.props.configuration_format.format_pdf.value : "",
                label: this.props.configuration_format.format_pdf ? this.props.configuration_format.format_pdf.label : "",
            },

            form: {
                format_alias: this.props.format.format_alias,
            },

        }
    }

    componentDidMount = () => {
        this.configSelect();
        this.loadData();
        this.loadQuestion();
    }

    loadQuestion = () => {
        fetch(`/formatos/get_questions/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            let array = [];

            data.register.filter((e) => e.question_type == "answer_integer").map((item) => (
                array.push({ label: item.question, value: item.id })
            ))

            this.setState({
                questions: array
            })
        });
    }

    updateConfigurationFormatState = (data) => {
        this.setState({
            configuration_format: {
                has_taks: data.configuration_format.has_taks,
                has_comments: data.configuration_format.has_comments,
                has_cause_analisis: data.configuration_format.has_cause_analisis,
                active_external_link: data.configuration_format.active_external_link,
                send_notification: data.configuration_format.send_notification,
                email_content: data.configuration_format.email_content,
                is_test: data.configuration_format.is_test,
                result_question: data.configuration_format.result_question,
                columns_format_number: data.configuration_format.columns_format_number,
                positions: data.configuration_format.positions,
                url_redirect: data.configuration_format.url_redirect,
                redirect_external: data.configuration_format.redirect_external,
                is_external_index: data.configuration_format.is_external_index,
                custom_message: data.configuration_format.custom_message,
                download_pdf: data.configuration_format.download_pdf,
                quantity_to_show: data.configuration_format.quantity_to_show,
                send_email: data.configuration_format.send_email,
                has_flow_chart: data.configuration_format.has_flow_chart,
                format_pdf: data.configuration_format.format_pdf ? data.configuration_format.format_pdf : {},
            },

            selectedOption: {
                label: data.configuration_format.result_question ? data.configuration_format.result_question.question : "",
                result_question_id: data.configuration_format.result_question ? data.configuration_format.result_question.id : "",
            },

            selectedOptionPdf: {
                format_pdf_id: data.configuration_format.format_pdf ? data.configuration_format.format_pdf.value : "",
                label: data.configuration_format.format_pdf ? data.configuration_format.format_pdf.label : "Seleccione el pdf",
            },
        });
    }

    loadData = () => {
        fetch(`/formatos/get_configuration/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.updateConfigurationFormatState(data);
                this.setState({ isLoaded: false });
            });
    }

    configSelect = () => {
        let arrayCargos = [];
        let arrayCargosEdit = [];
        let arrayPdfs = [];

        this.props.cargos.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))

        this.props.configuration_format.positions.map((item) => (
            arrayCargosEdit.push({ label: item.name, value: item.id })
        ))

        this.props.pdfs.map((item) => (
            arrayPdfs.push({ label: item.name, value: item.id })
        ))

        this.setState({
            cargos: arrayCargos,
            editValues: arrayCargosEdit,
            pdfs: arrayPdfs,
        });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    updateStateFormat = (state, countryside) => {
        fetch(`/formatos/update_state_format/${this.props.format.id}/${state}/${countryside}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.loadQuestion();
                this.props.updateIsTest(data.configuration_format.is_test);
                this.updateConfigurationFormatState(data);
            });
    }

    handleClickUpdateConfiguration = () => {
        fetch(`/formatos/configuration_formats/${this.props.configuration_format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.configuration_format), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                const arrayPositonEdit = []

                //this.messageSuccess(data)

                data.positions.map((item) => (
                    arrayPositonEdit.push({ label: item.name, value: item.id })
                ))

                this.setState({
                    showInfo: false,
                    showInfoExtern: false,
                    mailActive: false,
                    editValues: arrayPositonEdit,
                    configuration_format: {
                        ...this.state.configuration_format,
                        positions: data.positions,
                        email_content: data.configuration_format.email_content,
                        url_redirect: data.configuration_format.url_redirect,
                        custom_message: data.configuration_format.custom_message,
                    }
                })
            });
    }

    handleChangeQuantityToShow = (e) => {
        const configuration_format = {
            quantity_to_show: e.target.value
        }

        fetch(`/formatos/configuration_formats/${this.props.configuration_format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(configuration_format), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                console.log("data", data);
                this.setState({
                    configuration_format: {
                        ...this.state.configuration_format,
                        quantity_to_show: data.configuration_format.quantity_to_show,
                    }
                })
            });
    }


    handleChangeAutocomplete = (selectedOption) => {
        fetch(`/formatos/update_result_question_format/${this.props.format.id}/${selectedOption.value}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({
                    selectedOption,
                    configuration_format: {
                        ...this.state.configuration_format,
                        result_question: data.configuration_format.result_question,
                    }
                });
            });
    }

    handleChangeAutocompletePdf = (selectedOptionPdf) => {
        const formUpdate = { format_pdf_id: selectedOptionPdf.value }
        fetch(`/formatos/configuration_formats/${this.props.configuration_format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data);
                this.setState({
                    configuration_format: {
                        ...this.state.configuration_format,
                        format_pdf: data.configuration_format.format_pdf,
                    },

                    selectedOptionPdf: {
                        format_pdf_id: data.configuration_format.format_pdf.value,
                        label: data.configuration_format.format_pdf.label,
                    },
                })
            });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
        }
    }

    toogleConfiguration = (from) => {
        if (from == "new") {
            this.setState({ modalConfiguration: true })
        } else {
            this.setState({ modalConfiguration: false })
        }
    }

    updateAlias = () => {
        fetch(`/formatos/format_categories/${this.props.format.format_category_id}/formats/${this.props.format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {

            });
    }

    HandleChangeALias = (e) => {
        this.setState({
            form: {
                format_alias: e.target.value,
            },
        })
    }

    handleValidateUrl = (e) => {
        function isUrl(v) {
            var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
            return regexp.test(v);
        }
        if (isUrl(e.target.value)) {
            document.getElementById("true-url").innerHTML = ""
            this.setState({
                configuration_format: {
                    ...this.state.configuration_format,
                    url_redirect: e.target.value,
                },
            }, () => {
                this.handleClickUpdateConfiguration();
            });
        } else {
            console.log('Ingresa una URL valida')
            this.setState({
                configuration_format: {
                    ...this.state.configuration_format,
                    url_redirect: "",
                },
            })
            document.getElementById("true-url").innerHTML = "<p>Ingresa una URL valida</p>"
        }
    }

    handleChangeUrl = (e) => {
        this.setState({
            configuration_format: {
                ...this.state.configuration_format,
                url_redirect: e.target.value,
            },
        })
        document.getElementById("true-url").innerHTML = ""
    }

    handleChangeFroala = (e) => {
        this.setState({
            configuration_format: {
                ...this.state.configuration_format,
                custom_message: e,
            },
        }, () => {
            this.handleClickUpdateConfiguration();
        });
    }

    setLazy = () => {

        fetch(`/formatos/toogle_lazy/${this.props.format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify({}), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                console.log("data", data);
                this.setState({
                    configuration_format: {
                        ...this.state.configuration_format,
                        quantity_to_show: data.configuration_format.quantity_to_show,
                    }
                })
            });

    }

    clipboardValueIDFormat = () => {
        navigator.clipboard.writeText(this.props.format.id);
        this.setState({ showAlertClipboard: true });

        setTimeout(() => {
            this.setState({ showAlertClipboard: false });

        }, 3000);
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={`${this.state.mailActive ? 'ui-index-configFormats-modal-resize modal-dialog-centered modal-lg' : 'modal-dialog-centered modal-lg'}`} backdrop={this.props.backdrop} >
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    {this.state.modalConfiguration && (
                        <ModalConfigurationQuestion
                            //modal props
                            backdrop={"static"}
                            modal={this.state.modalConfiguration}
                            toggle={this.toogleConfiguration}
                            title={"Configuración de preguntas"}

                            formato={this.props.format}
                        />
                    )}


                    <div className="col-md-12 mb-3 mt-3">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className={`nav-link ${this.state.current_tab == "general" ? "active" : ""}`} onClick={() => this.setState({ mailActive: false, current_tab: "general" })}>
                                    General
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className={`nav-link ${this.state.current_tab == "correo" ? "active" : ""}`} onClick={() => this.setState({ mailActive: true, current_tab: "correo" })}>
                                    Correo-e
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className={`nav-link ${this.state.current_tab == "configuration" ? "active" : ""}`} onClick={() => this.setState({ mailActive: false, current_tab: "configuration" })}>
                                    Configuración comentarios
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content" id="myTabContent">
                            {this.state.current_tab == "general" && (
                                <div className="row p-2">
                                    <div className="w-100" id="collapseExample">
                                        <div className="col-md-12">
                                            <h5 className='ui-index-configurationFormat-subtitle-tab'>Configuración General</h5>
                                            <div className="row mt-3">
                                                <div className="col-md-6 mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.has_taks, "show_task")} className="custom-control-input" id={`customShowTask`} checked={this.state.configuration_format.has_taks} />
                                                        <label className="custom-control-label" htmlFor={`customShowTask`}>Mostrar tareas</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.has_comments, "show_coment")} className="custom-control-input" id={`customShowCommets`} checked={this.state.configuration_format.has_comments} />
                                                        <label className="custom-control-label" htmlFor={`customShowCommets`}>Mostrar comentarios</label>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-6 mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.has_cause_analisis, "cause_analisis")} className="custom-control-input" id={`customHasCauseAnalisis`} checked={this.state.configuration_format.has_cause_analisis} />
                                                        <label className="custom-control-label" htmlFor={`customHasCauseAnalisis`}>Tiene Método de Análisis</label>
                                                    </div>
                                                </div> */}

                                                <div className="col-md-6 mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.active_external_link, "active_external_link")} className="custom-control-input" id={`customActiveExternalLink`} checked={this.state.configuration_format.active_external_link} />
                                                        <label className="custom-control-label" htmlFor={`customActiveExternalLink`}>Desactivar ingreso de registros externos</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.is_test, "is_test")} className="custom-control-input" id={`customActiveTest`} checked={this.state.configuration_format.is_test} />
                                                        <label className="custom-control-label" htmlFor={`customActiveTest`}>Es una prueba</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.has_flow_chart, "has_flow_chart")} className="custom-control-input" id={`customActiveFlowChart`} checked={this.state.configuration_format.has_flow_chart} />
                                                        <label className="custom-control-label" htmlFor={`customActiveFlowChart`}>Tiene arbol de fallos</label>
                                                    </div>
                                                </div>
                                                {this.state.configuration_format.is_test && (
                                                    <div className="col-md-6 mb-2">
                                                        <button
                                                            className="btn-shadow btn btn-info btn-block"
                                                            onClick={() => this.toogleConfiguration("new")}
                                                        >
                                                            Configurar valores
                                                        </button>
                                                    </div>
                                                )}

                                                {this.state.configuration_format.is_test && (
                                                    <React.Fragment>
                                                        <div className="col-md-12 mt-3">
                                                            <hr />
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>Pregunta en la que se va a guardar el resultado</label>
                                                            <input
                                                                type="hidden"
                                                                name="result_question_id"
                                                                value={this.state.selectedOption.result_question_id}
                                                            />
                                                            <Select
                                                                onChange={this.handleChangeAutocomplete}
                                                                options={this.state.questions}
                                                                autoFocus={false}
                                                                className={`link-form`}
                                                                value={this.state.selectedOption}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                )}

                                                <div className="col-md-12 mt-3">
                                                    <hr />
                                                    <label className='mt-2'><b>Al completar el formulario</b></label>
                                                </div>
                                                <div className="col-md-6 mb-2 mt-1">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.redirect_external, "redirect_external")} className="custom-control-input" id={`customRedirectExternal`} checked={this.state.configuration_format.redirect_external} />
                                                        <label className="custom-control-label" htmlFor={`customRedirectExternal`}>Redireccionamiento diferente</label>
                                                    </div>
                                                </div>

                                                {this.state.configuration_format.redirect_external && (
                                                    <div className="col-md-6 mb-2">
                                                        <React.Fragment>
                                                            <label>Url de redireccionamiento</label>
                                                            <input
                                                                type="text"
                                                                className='form form-control'
                                                                name="url_redirect"
                                                                value={this.state.configuration_format.url_redirect}
                                                                onBlur={(e) => this.handleValidateUrl(e)}
                                                                onChange={(e) => this.handleChangeUrl(e)}
                                                            />
                                                            <div id="true-url"></div>
                                                        </React.Fragment>
                                                    </div>
                                                )}

                                                {this.state.pdfs.length === 0 && (
                                                    <div className="col-md-12 mt-3">
                                                        <div className="alert alert-danger text-center" role="alert">
                                                            <b>Para enviar registros por correo-e o imprimir/descargar registros, es necesario crear primero un archivo PDF; para hacerlo, dirígete a la opción 'Documentos PDF'.  </b>
                                                        </div>
                                                    </div>
                                                )}

                                                {(this.state.pdfs.length >= 1) && (
                                                    <React.Fragment>
                                                        <div className="col-md-12 mt-3">
                                                            <hr />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label>Seleccione la plantilla del pdf</label>
                                                            <input
                                                                type="hidden"
                                                                name="format_pdf_id"
                                                                value={this.state.selectedOptionPdf.format_pdf_id}
                                                            />
                                                            <Select
                                                                onChange={this.handleChangeAutocompletePdf}
                                                                options={this.state.pdfs}
                                                                autoFocus={false}
                                                                className={`link-form`}
                                                                value={this.state.selectedOptionPdf}
                                                            />
                                                        </div>

                                                        {(this.state.pdfs.length >= 1 && this.state.selectedOptionPdf.format_pdf_id) && (
                                                            <div className='col-md-12 mt-3 mb-2 ui-index-configurationFormat__container_pdf' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className="col-md-6 p-0">
                                                                    <div className="custom-control custom-switch">
                                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.send_email, "send_email")} className="custom-control-input" id={`customSendEmail`} checked={this.state.configuration_format.send_email} />
                                                                        <label className="custom-control-label" htmlFor={`customSendEmail`}>Enviar registros por correo-e</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 p-0">
                                                                    <div className="custom-control custom-switch">
                                                                        <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.download_pdf, "download_pdf")} className="custom-control-input" id={`customDownloadPdf`} checked={this.state.configuration_format.download_pdf} />
                                                                        <label className="custom-control-label" htmlFor={`customDownloadPdf`}>Imprimir / Descargar registros</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}


                                                        {Object.keys(this.state.configuration_format.format_pdf).length == 0 && (
                                                            <div className="col-md-12 mt-3">
                                                                <div className="alert alert-danger text-center" role="alert">
                                                                    <b>Tienes que seleccionar un pdf, para poder activar Imprimir / Descargar registros </b>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}


                                                <div className="col-md-12 mt-1">
                                                    <hr />
                                                </div>

                                                <div className="ui-index-configurationFormats__container_columns-countsPage">
                                                    <div className="ui-index-configuration_format-columns">
                                                        <label>Columnas del formulario</label>
                                                        <select
                                                            value={this.state.configuration_format.columns_format_number}
                                                            onChange={this.HandleChangeUpdateSelect}
                                                            className="form form-control"
                                                            name="columns_format_number"
                                                        >
                                                            <option value="1">1 columna </option>
                                                            <option value="2">2 columnas </option>
                                                        </select>
                                                    </div>

                                                    <div className="ui-index-configuration_format-count-page">
                                                        <label>Cantidad de registros por pagina</label>
                                                        <select
                                                            value={this.state.configuration_format.quantity_to_show}
                                                            onChange={this.handleChangeQuantityToShow}
                                                            className="form form-control"
                                                            name="quantity_to_show"
                                                        >
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                            <option value="60">60</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-3">
                                                    <label>Alias del formulario</label>
                                                    <input
                                                        value={this.state.form.format_alias}
                                                        onChange={this.HandleChangeALias}
                                                        onBlur={this.updateAlias}
                                                        className="form form-control"
                                                        name="format_alias"
                                                    />
                                                </div>

                                                <div className="col-md-12 mt-3">
                                                    <label>Mensage al terminal de llenar el formulario</label>
                                                    <FroalaEditor
                                                        model={this.state.configuration_format.custom_message}
                                                        config={this.props.config}
                                                        onModelChange={(e) => this.handleChangeFroala(e)}
                                                    />
                                                </div>

                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className="col-md-2 mt-3">
                                                        <button className='btn btn-primary mr-2' onClick={() => this.setLazy()}>Lazy</button>
                                                        {this.props.configuration_format.is_chache ? "Si" : "No"}
                                                    </div>

                                                    <div className="col-md-3 mt-3">
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" onChange={(e) => this.updateStateFormat(!this.state.configuration_format.is_external_index, "is_external_index")} className="custom-control-input" id={`customExternalIndex`} checked={this.state.configuration_format.is_external_index} />
                                                            <label className="custom-control-label" htmlFor={`customExternalIndex`}>is_external_index</label>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-6 mt-3 text-right mr-3'>
                                                        ID Formato  <i className="far fa-clone ml-2" onClick={this.clipboardValueIDFormat}></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    {this.state.showAlertClipboard && (
                                                        <span className="alert alert-success ui-message-alert-ids">
                                                            El ID ha sido copiado al portapapeles
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.current_tab == "correo" && (
                                <ConfigurationEmail
                                    configuration_format={this.props.configuration_format}
                                    config={this.props.config}

                                    //email conditional
                                    formato={this.props.format}
                                    questions={this.props.questions}


                                    cargos={this.state.cargos}
                                    procesos={this.props.procesos}
                                    users={this.props.users}
                                />
                            )}

                            {this.state.current_tab == "configuration" && (
                                <FormatConfigurationCommentsTemplateIndex
                                    configuration_format={this.props.configuration_format}
                                    config={this.props.config}
                                />
                            )}
                        </div>
                    </div>

                    <ModalFooter>
                        <button className="btn btn-outline-danger" onClick={() => this.props.toggle()}>Cerrar</button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;
