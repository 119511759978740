import React, { Component } from 'react';
import Select from "react-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Preloader from '../../../GeneralComponents/LoadingComponen';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],

            questions_target: [],
            questions_source: [],

            isLoaded: true,
            isLoadedCreate: false,

            ErrorValues: true,
            modeEdit: false,

            question_format_input_id: "",

            formCreate: {
                question_id: this.props.question.id,
                question_target_id: "",
                question_source_id: "",
            },

            formUpdate: {
                question_id: this.props.question.id,
                question_target_id: "",
                question_source_id: "",
            },

            selectedOptionQuestionTarget: {
                question_target_id: "",
                label: "Pregunta"
            },

            selectedOptionQuestionSource: {
                question_source_id: "",
                label: "Pregunta"
            },

            selectedOptionQuestionTargetUpdate: {
                question_target_id: "",
                label: "Pregunta"
            },

            selectedOptionQuestionSourceUpdate: {
                question_source_id: "",
                label: "Pregunta"
            },
        }
    }

    componentDidMount = () => {
        this.loadData();
        this.loadDataQuestionSource();
    }

    loadData = () => {
        //const questionFilter =  ["format_id", "answer_matrix", "answer_file", "pdf_viewer", "answer_subform"]

        fetch(`/formatos/get_question_format_inputs/${this.props.question.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                questions_target: data.questions.filter((e) => e.type != "format_id" && e.type != "answer_matrix" && e.type != "answer_file" && e.type != "pdf_viewer" && e.type != "answer_subform"),
                isLoaded: false
            });
        });
    }

    loadDataQuestionSource = () => {
        fetch(`/formatos/get_questions/${this.props.question.format_relation_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            let array = [];

            data.register.map((item) => (
                array.push({ label: item.question, value: item.id, type: item.question_type, options_values: item.options })
            ))

            this.setState({
                questions_source: array
            })
        });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = config => {
        this.setState({
            data: this.state.data.map(item => {
                if (config.id === item.id) {
                    return {
                        ...item,
                        question: config.question,
                        question_source: config.question_source,
                        question_target: config.question_target,
                    }
                }
                return item;
            })
        });
    }

    HandleClickUpdate = () => {
        fetch(`/formatos/question_format_inputs/${this.state.question_format_input_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({ questions_target: data.questions })
            this.clearValues();
            this.updateItem(data.register)
            this.messageSuccess(data);
        });
    }

    HandleClick = () => {
        if (this.validationForm()) {
            this.setState({ isLoadedCreate: true })

            if (this.state.modeEdit) {
                fetch(`/formatos/question_format_inputs/${this.state.question_format_input_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.updateItem(data.register)
                        this.setState({ questions_target: data.questions, isLoadedCreate: false });
                        this.messageSuccess(data);
                    });
            } else {
                fetch(`/formatos/question_format_inputs`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreate), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.updateData(data.register);
                        this.setState({ questions_target: data.questions, isLoadedCreate: false });
                        this.messageSuccess(data);
                    });
            }
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/question_format_inputs/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    edit = (question_format_input) => {
        this.setState({
            question_format_input_id: question_format_input.id,
            modeEdit: true,
            formUpdate: {
                ...this.state.formUpdate,
                question_target_id: question_format_input.question_target.id,
                question_source_id: question_format_input.question_source.id,
            },

            selectedOptionQuestionTargetUpdate: {
                question_target_id: question_format_input.question_target.id,
                label: question_format_input.question_target.question,
            },

            selectedOptionQuestionSourceUpdate: {
                question_source_id: question_format_input.question_source.id,
                label: question_format_input.question_source.question,
            },
        })
    }

    validationForm = () => {
        if (this.state.formCreate.question_target_id != "" && 
            this.state.formCreate.question_source_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    clearValues = () => {

        this.setState({
            ErrorValues: true,
            modeEdit: false,
            question_format_input_id: "",

            formCreate: {
                ...this.state.formCreate,
                question_target_id: "",
                question_source_id: "",
            },

            formUpdate: {
                ...this.state.formUpdate,
                question_target_id: "",
                question_source_id: "",
            },

            selectedOptionQuestionTarget: {
                question_target_id: "",
                label: "Pregunta"
            },

            selectedOptionQuestionSource: {
                question_source_id: "",
                label: "Pregunta"
            },


            selectedOptionQuestionTargetUpdate: {
                question_target_id: "",
                label: "Pregunta"
            },

            selectedOptionQuestionSourceUpdate: {
                question_source_id: "",
                label: "Pregunta"
            },

        })
    }


    handleChangeAutocompleteQuestionTarget = selectedOptionQuestionTarget => {
        if (this.state.question_format_input_id) {
            const selectedOptionQuestionTargetUpdate = selectedOptionQuestionTarget

            this.setState({
                selectedOptionQuestionTargetUpdate,
                formUpdate: {
                    ...this.state.formUpdate,
                    question_target_id: selectedOptionQuestionTargetUpdate.value,
                },
            });
        } else {
            this.setState({
                selectedOptionQuestionTarget,
                formCreate: {
                    ...this.state.formCreate,
                    question_target_id: selectedOptionQuestionTarget.value,
                },
            });
        }
    };

    handleChangeAutocompleteQuestionSource = selectedOptionQuestionSource => {
        if (this.state.question_format_input_id) {
            const selectedOptionQuestionSourceUpdate = selectedOptionQuestionSource

            this.setState({
                selectedOptionQuestionSourceUpdate,
                formUpdate: {
                    ...this.state.formUpdate,
                    question_source_id: selectedOptionQuestionSourceUpdate.value,
                },
            });
        } else {
            this.setState({
                selectedOptionQuestionSource,
                formCreate: {
                    ...this.state.formCreate,
                    question_source_id: selectedOptionQuestionSource.value,
                },
            });
        }
    };
    
    render() {
        return (
            <React.Fragment>
                <div className="card mb-2 mb-3">
                    <div className="card-body p-2">
                        <div className="row">

                            <div className={`col-md-6`}>
                                <label>Pregunta Fuente</label>
                                <input
                                    type="hidden"
                                    name="question_source_id"
                                    value={this.state.selectedOptionQuestionSource.question_source_id}
                                />

                                <Select
                                    onChange={this.handleChangeAutocompleteQuestionSource}
                                    options={this.state.questions_source}
                                    isDisabled={this.state.isLoaded}
                                    autoFocus={false}
                                    className={`link-form ${!this.state.ErrorValues && this.state.formCreate.question_source_id == "" ? "error-class" : ""}`}
                                    value={this.state.selectedOptionQuestionSource}
                                />
                            </div>

                            <div className={`col-md-6`}>
                                <label>Pregunta Objetivo</label>
                                <input
                                    type="hidden"
                                    name="question_target_id"
                                    value={this.state.selectedOptionQuestionTarget.question_target_id}
                                />
                                <Select
                                    onChange={this.handleChangeAutocompleteQuestionTarget}
                                    options={this.state.questions_target}
                                    autoFocus={false}
                                    isDisabled={this.state.isLoaded}
                                    className={`link-form ${!this.state.ErrorValues && this.state.formCreate.question_target_id == "" ? "error-class" : ""}`}
                                    value={this.state.selectedOptionQuestionTarget}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="card-footer">
                        <button
                            className="btn-shadow btn btn-info mr-2"
                            disabled={this.state.isLoaded || this.state.isLoadedCreate}
                            onClick={() => this.HandleClick()}
                        >
                            {this.state.isLoadedCreate ? "Guardando..." : "Guardar" }
                        </button>

                        <button
                            className="btn btn-outline-danger"
                            disabled={this.state.isLoaded}
                            onClick={() => this.clearValues()}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>

                <div className="content main-card mb-3 card">
                    <div className="card-body p-1">

                        {!this.state.isLoaded ? (
                            <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                <thead>
                                    <tr>
                                        <th style={{ width: "26%" }}>Pregunta fuente</th>
                                        <th style={{ width: "26%" }}>Pregunta objetivo</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.state.data.length >= 1 ? (
                                        this.state.data.map((question_format_input, index) => (
                                            <tr key={question_format_input.id}>
                                                <td>
                                                    {question_format_input.id == this.state.question_format_input_id ? (
                                                        <React.Fragment>
                                                            <input
                                                                type="hidden"
                                                                name="question_source_id"
                                                                value={this.state.selectedOptionQuestionSourceUpdate.question_source_id}
                                                            />
                                                            <Select
                                                                onChange={this.handleChangeAutocompleteQuestionSource}
                                                                options={this.state.questions_source}
                                                                autoFocus={false}
                                                                className={`link-form`}
                                                                value={this.state.selectedOptionQuestionSourceUpdate}
                                                            />
                                                        </React.Fragment>
                                                    ) : (
                                                            <React.Fragment>
                                                                {question_format_input.question_source.question}
                                                            </React.Fragment>
                                                        )}
                                                </td>
                                                <td>
                                                    {(question_format_input.id == this.state.question_format_input_id) ? (
                                                        <React.Fragment>
                                                            <input
                                                                type="hidden"
                                                                name="question_target_id"
                                                                value={this.state.selectedOptionQuestionTargetUpdate.question_target_id}
                                                            />
                                                            <Select
                                                                onChange={this.handleChangeAutocompleteQuestionTarget}
                                                                options={this.state.questions_target}
                                                                autoFocus={false}
                                                                className={`link-form`}
                                                                value={this.state.selectedOptionQuestionTargetUpdate}
                                                            />
                                                        </React.Fragment>
                                                    ) : (
                                                            <React.Fragment>
                                                                {question_format_input.question_target.question}
                                                            </React.Fragment>
                                                        )}
                                                </td>


                                                <td className="text-right" style={{ width: "11%" }}>
                                                    {question_format_input.id == this.state.question_format_input_id ? (
                                                        <React.Fragment>
                                                            <i className="fas fa-check-circle update-section" onClick={() => this.HandleClickUpdate()}></i>
                                                            <i className="fas fa-times-circle update-false" onClick={() => this.clearValues()}></i>
                                                        </React.Fragment>
                                                    ) : (
                                                        <UncontrolledDropdown className='btn-group'>
                                                            <DropdownToggle className='btn btn-info'>
                                                                <i className="fas fa-bars"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                {true && (
                                                                    <DropdownItem
                                                                        onClick={() => this.edit(question_format_input)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Editar
                                                                    </DropdownItem>
                                                                )}

                                                                {true && (
                                                                    <DropdownItem
                                                                        onClick={() => this.delete(question_format_input.id)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        Eliminar
                                                                    </DropdownItem>
                                                                )}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    )}
                                                </td>


                                            </tr>
                                        ))
                                    ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        ) : (
                            <Preloader />
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
