import React, { Component } from 'react';
import Table from '../components/formatos/FormatCategories/FormatCategoriesIndex'
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle';

class FormatCategories extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            data: [],
            isLoaded: true,
            isFormFilterVisible: false,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format_category => {
        this.setState({
            data: this.state.data.map(item => {
                if (format_category.id === item.id) {
                    return { ...item, 
                        name: format_category.name,
                        position_creates: format_category.position_creates,
                        positions: format_category.positions,
                        position_fordatas: format_category.position_fordatas,
                        icon_id: format_category.icon_id,
                        level: format_category.level,
                        format_category_id: format_category.format_category_id,
                        position: format_category.position,
                        show_index: format_category.show_index,
                    }
                }
                return item;
            })
        });
    }

    updateDelete = (format_category) => {
        this.setState({
            data: this.state.data.filter( item => item.id != format_category.id ) 
        })
    }

    loadData = () => {
        fetch(`/formatos/get_format_categories`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }


    /*
    updateDataColumns = (format_category, register, from) => {
        this.setState({
            data: this.state.data.map(item => {
                if (format_category.id === item.id) {
                    return {
                        ...item,
                        categories: (from == "categories" ? [...item.categories, register] : item.categories),
                        format_custom_views: (from == "format_custom_views" ? [...item.format_custom_views, register] : item.format_custom_views),
                        formatos: (from == "formatos" ? [...item.formatos, register] : item.formatos),
                    }
                }
                return item;
            })
        });
    }
*/

    toggleFormFilterVisibility = () => {
        this.setState({ isFormFilterVisible: !this.state.isFormFilterVisible });
    };

    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={`Módulos`}
                    subTitle={"Crea y gestiona tus módulos y formatos"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={() => this.child.current.toogleCategories("new", true)}
                    showNew={true}
                    showFilter={true}
                    onClickFilter={this.toggleFormFilterVisibility}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                <Table
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    estados={this.props.estados}
                    positions={this.props.positions}
                    permissions={this.props.permissions}
                    formats_permissions_all={this.props.formats_permissions_all}
                    format_sub_categories={this.props.format_sub_categories}
                    icons={this.props.icons}
                    visibleFilter={this.state.isFormFilterVisible}
                    categories={this.props.categories}
                    formatos={this.props.formatos}
                    ref={this.child}
                    updateDataColumns={this.updateDataColumns}
                    updateDelete={this.updateDelete}

                    section_custom_views={this.props.section_custom_views}
                    custom_view_permissions={this.props.custom_view_permissions}

                    default_data={this.props.default_data}
                    select_category_list_id={this.props.select_category_list_id}
                    current_category={this.props.current_category}
                    current_position={this.props.current_position}
                />
            </React.Fragment>
        );
    }
}


export default FormatCategories;
WebpackerReact.setup({ FormatCategories });


