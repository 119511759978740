import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import Preloader from '../../../GeneralComponents/LoadingComponen';

const ModalDocumentSelect = (props) => {
    const token = document.querySelector("[name='csrf-token']").content;
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            {props.isLoaded ? (
                                <React.Fragment>
                                    <input
                                        type="hidden"
                                        name="document_id"
                                        value={props.selectedOptionDocument.document_id}
                                    />
                                    <Select
                                        onChange={props.handleChangeAutocompleteDocument}
                                        options={props.documents}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={props.selectedOptionDocument}
                                    />                                
                                </React.Fragment>
                            ) : (
                                <Preloader/>
                            )}
                        </div>

                        {/*<div className="col-md-12 mt-3">
                            <div className="alert alert-info" role="alert">
                                <b>Selecciona el formato de beewo y la URL se guardará automáticamente</b>
                            </div>
                        </div>*/}

                    </div>
                </ModalBody>

                <ModalFooter>
                    <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}


export default ModalDocumentSelect;

