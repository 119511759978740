import React, { useState, useEffect } from 'react';
import FormCreate from './FormCreate';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';

const Index = (props) => {
    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(true);
    const token = document.querySelector("[name='csrf-token']").content;
    const [id, setId] = useState("");
    const [form, setForm] = useState({ name: "", content: "", subject: "", configuration_format_id: props.configuration_format.id });

    //modal state
    const [modal, setModal] = useState(false);
    const [modeEdit, setModeEdit] = useState(false);

    const [errorValues, setErrorValues] = useState(true);
  
    useEffect(() => {
        loadData();
    }, []);

    const messageSuccess = (response) => {
        console.log("response", response);
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const updateItem = (page) => {
        setData(data.map(item => {
          if (item.id === page.id) {
            return { ...item, 
                name: page.name,
                content: page.content,
                subject: page.subject,
            }
          }
          return item;
        }));
    }

    const clearValues = () => {
        setForm({ name: "", content: "", subject: "", configuration_format_id: props.configuration_format.id })
        setModeEdit(false);
        setId("");
    }

    const updateData = (new_item) => {
        setData([...data, new_item])
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const toogle = (from) => {
        if (from == "new") {
            setModal(true);
        } else {
            setModal(false);
            clearValues();
        }
    }

    const validationForm = () => {
        if (form.end_date != "" &&
            form.start_date != "" &&
            form.cost_center_id != "" &&
            form.user_responsible_id != "" 
        ) {
            setErrorValues(true)
            return true
        }else{
            setErrorValues(false)
            return false
        }
    }

    const handleClick = () => {
        if(validationForm()){
            if (!modeEdit)
                fetch(`/formatos/format_configuration_comments_templates`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type == "success"){
                        messageSuccess(data);
                        updateData(data.register);
                        clearValues();
                    }else{
                        messageSuccess(data);
                        clearValues();
                    }
                });
            else {
                fetch(`/formatos/format_configuration_comments_templates/${id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type == "success"){
                        messageSuccess(data);
                        updateItem(data.register);
                        clearValues();
                    }else{
                        messageSuccess(data);
                        clearValues();
                    }
                });
            }
        }
    }

    const destroy = (page_id) => {
        Swal.fire({
            title: 'Estas seguro?',
            text: "El registro sera eliminado para siempre!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.value) {
                fetch(`/formatos/format_configuration_comments_templates/${page_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    messageSuccess(response);
                    loadData()
                });
            }
        })
    };

    const loadData = () => {
        fetch(`/formatos/get_format_configuration_comments_templates/${props.configuration_format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setIsLoaded(false);
            setData(data.data);
        });
    }

    const edit = (format_configuration_comments_template) => {
        setForm({ name: format_configuration_comments_template.name, content: format_configuration_comments_template.content, subject: format_configuration_comments_template.subject, configuration_format_id: props.configuration_format.id })
        setModal(true);
        setModeEdit(true);
        setId(format_configuration_comments_template.id);
    }

    return (
        <React.Fragment>
            <div className="">
                <div className="tile">

                    <div className="row">
                        <div className="col-md-12">
                            {true && (
                                <FormCreate
                                    backdrop={"static"}
                                    modal={modal}
                                    toggle={toogle}
                                    title={modeEdit ? "Actualizar" : "Crear"}
                                    nameBnt={modeEdit ? "Actualizar" : "Crear"}

                                    //form props
                                    formValues={form}
                                    onChangeForm={handleChange}
                                    submitForm={handleClick}
                                    errorValues={errorValues}
                                    config={props.config}
                                />
                            )}
                        </div>
                    </div>

                    <div className="ui-index-configurationFormats__container-table">
                        {!isLoaded ? (
                            <table className="table table-hover table-striped table-bordered">
                                <thead>
                                    <tr className="tr-title">
                                        <th>Nombre</th>
                                        <th>Asunto</th>
                                        <th>Contenido</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data.length >= 1 ? (
                                        data.map(format_configuration_comments_template => (
                                            <tr key={format_configuration_comments_template.id}>
                                                <td>{format_configuration_comments_template.name}</td>
                                                <td>{format_configuration_comments_template.subject}</td>
                                                <td>{ReactHtmlParser(format_configuration_comments_template.content)}</td>
                                                {(true || true) && (
                                                    <td className="text-right" style={{ width: "10px"}}>          
                                                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                            <div className="btn-group" role="group">
                                                                <button className="btn-shadow btn btn-info" id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="fas fa-bars"></i>
                                                                </button>
                                                                            
                                                                <div className="dropdown-menu dropdown-menu-right">

                                                                    {true && (
                                                                        <button onClick={() => edit(format_configuration_comments_template)} className="dropdown-item">
                                                                            Editar
                                                                        </button>
                                                                    )}

                                                                    {true && (
                                                                        <button onClick={() => destroy(format_configuration_comments_template.id)} className="dropdown-item">
                                                                            Eliminar
                                                                        </button>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        ))
                                    ) : (
                                        <td colSpan="13" className="text-center">
                                            <div className="text-center mt-4 mb-4">
                                                <h4>No hay registros</h4>
                                            </div>
                                        </td>
                                    )}
                                </tbody>
                            </table>
                                 
                        ) : (
                            <div className="card">
                                <div className="card-body">
                                    <p>Cargando informacion...</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Index;
