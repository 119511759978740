import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Select from "react-select";
import ModalIndex from './ConfigurationFormatCondition/ModalIndex';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

const ConfigurationEmail = (props) => {
    const token = document.querySelector("[name='csrf-token']").content;
    const [showInfo, setShowInfo] = useState(false);
    const [showInfoExtern, setShowInfoExtern] = useState(false);
    const [selectedOptionCargo, setSelectedOptionCargo] = useState({ label: "Cargos que pueden acceder", position_ids: [] });
    const [selectedOptionQuestion, setSelectedOptionQuestion] = useState({ label: "Pregunta", question_to_send_extern_id: "" });
    const [typeEmail, setTypeEmail] = useState("");

    const [configuration_format, setConfigurationFormat] = useState({ 
        send_notification: props.configuration_format.send_notification,
        email_content: props.configuration_format.email_content,
        positions: props.configuration_format.positions,
        subject_email_intern: props.configuration_format.subject_email_intern,
        send_email_to_extern: props.configuration_format.send_email_to_extern,
        subject_email_extern: props.configuration_format.subject_email_extern,
        email_content_extern: props.configuration_format.email_content_extern,
        question_to_send_extern: props.configuration_format.question_to_send_extern,
        question_to_send_extern_id: props.configuration_format.question_to_send_extern_id,
    });
1
    //modal state
    const [modal, setModal] = useState(false);

  
    useEffect(() => {
        loadData();
    }, []);

    const updateConfigurationState = (register) => {
        setConfigurationFormat({ 
            ...configuration_format, 
            send_notification: register.send_notification,
            email_content: register.email_content,
            positions: register.positions,
            subject_email_intern: register.subject_email_intern,
            send_email_to_extern: register.send_email_to_extern,
            email_content_extern: register.email_content_extern,
            question_to_send_extern: register.question_to_send_extern,
            subject_email_extern: register.subject_email_extern,
            question_to_send_extern_id: register.question_to_send_extern_id,

        });

        setSelectedOptionQuestion({ label: (register.question_to_send_extern ? register.question_to_send_extern.label : "Pregunta"),  question_to_send_extern_id: (register.question_to_send_extern ? register.question_to_send_extern.value : "") })
    }

    const loadData = () => {
        fetch(`/formatos/get_configuration/${props.formato.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            updateConfigurationState(data.configuration_format);
        });
    }

    const updateStateFormat = (state, countryside) => {
        fetch(`/formatos/update_state_format/${props.formato.id}/${state}/${countryside}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            setConfigurationFormat({ ...configuration_format, [countryside]: state })
        });
    }

    const handleChangeAutocompleteQuestion = (selectedOptionQuestion) => {
        setSelectedOptionQuestion(selectedOptionQuestion);
        setConfigurationFormat({ ...configuration_format, ["question_to_send_extern_id"]: selectedOptionQuestion.value })
    }

    const handleChangeAutocompleteCargo = selectedOption => {
        let array = []

        if (selectedOption != null) {
            selectedOption.map((item) => (
                array.push(item.value)
            ))
            
            setConfigurationFormat({ ...configuration_format, ["position_ids"]: array })
        } else {
            setConfigurationFormat({ ...configuration_format, ["position_ids"]: [] })
        }
    }

    const handleChangeUpdateConfiguration = (e, field) => {
        setConfigurationFormat({ ...configuration_format, [field]: e })
    }

    const handleClickUpdateConfiguration = () => {
        fetch(`/formatos/configuration_formats/${props.configuration_format.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(configuration_format), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            setShowInfo(false);
            setShowInfoExtern(false);
            updateConfigurationState(data.configuration_format);
        });
    }

    const toogle = (from, type_email) => {
        if (from == "new") {
            setModal(true);
            setTypeEmail(type_email);
        } else {
            setModal(false);
            setTypeEmail("");
        }
    }

    const cancelUpdate = () => {
        setShowInfo(false);
        setShowInfoExtern(false);
        updateConfigurationState(props.configuration_format);
    }


    return (
        <React.Fragment>
            {modal && (
                <ModalIndex
                    //modal props
                    backdrop={"static"}
                    modal={modal}
                    toggle={toogle}
                    title={"Configuración de email"}

                    configuration_format={props.configuration_format}
                    formato={props.formato}

                    cargos={props.cargos}
                    procesos={props.procesos}
                    users={props.users}
                    type_email={typeEmail}
                />
            )}
                                
            <div className="row ui-configurationEmail__container">
                <div className="col-md-6 pr-0">

                    <div className="col-md-12">
                        <h5 className='ui-index-configurationFormat-subtitle-tab'>Configuración Correo-e Interno</h5>
                        <div className="custom-control custom-switch">
                            <input type="checkbox" onChange={(e) => updateStateFormat(!configuration_format.send_notification, "send_notification")} className="custom-control-input" id={`customShowNotifications`} checked={configuration_format.send_notification} />
                            <label className="custom-control-label" htmlFor={`customShowNotifications`}>Enviar notificación</label>
                            <p className="font-12 pt-1">Envía una notificación cada que generen un registro en este formato abajo puedes configurar el mensaje</p>
                        </div>

                        <div className="col-md-12">
                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => toogle("new", "internos")}
                            >
                                Condiciones
                            </button>
                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="card">
                            <div className="card-body">
                                {showInfo ? (
                                    <React.Fragment>
                                        {configuration_format.send_notification && (
                                            <React.Fragment>
                                                <div className="mb-3">
                                                    <label>Asunto del correo-e</label>
                                                    <input 
                                                        type="text" 
                                                        className='form form-control' 
                                                        value={configuration_format.subject_email_intern}
                                                        onChange={(e) => handleChangeUpdateConfiguration(e.target.value, "subject_email_intern")}
                                                    />
                                                </div>
                                                                        
                                                <div className="mb-3 mt-3">
                                                    <label>Cargos que pueden acceder</label>
                                                    <input
                                                        type="hidden"
                                                        name="position_ids"
                                                        value={selectedOptionCargo.position_ids}
                                                    />
                                                    <Select
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        autoFocus={false}
                                                        className={`link-form`}
                                                        classNamePrefix="select"
                                                        placeholder="Seleccione"
                                                        name="position_ids"
                                                        onChange={handleChangeAutocompleteCargo}
                                                        options={props.cargos}
                                                        defaultValue={configuration_format.positions}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        )}

                                        <FroalaEditor
                                            className='ui-pruebas'
                                            model={configuration_format.email_content}
                                            config={props.config}
                                            onModelChange={(e) => handleChangeUpdateConfiguration(e, "email_content")}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className="col-md-12 p-0">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <b>Configuración del Email</b>
                                                </div>

                                                <div className="col-md-3">

                                                </div>
                                            </div>
                                            <hr />
                                        </div>

                                        <p><b>Asunto: </b> {configuration_format.subject_email_intern} </p>
                                        <br />
                                        <b>Cargos</b>
                                        <ul>
                                            {configuration_format.positions.map((cargo, index) => (
                                                <React.Fragment>
                                                    <li>{cargo.label}</li>
                                                </React.Fragment>
                                            ))}
                                        </ul>

                                        <div className="pb-2"><b>Contenido del email</b></div>
                                        <p style={{ fontSize: "12px" }}>(Debajo del mensaje siempre aparecera el link para ingresar al listado)</p>
                                        <div className="send-format-email mt-2">
                                            {ReactHtmlParser(configuration_format.email_content)}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>

                            <div className="card-footer">
                                {showInfo ? (
                                    <React.Fragment>
                                        <button
                                            className="btn btn-shadow btn-info"
                                            onClick={() => handleClickUpdateConfiguration()}
                                        >
                                            Actualizar
                                        </button>

                                        <button
                                            className="btn btn-shadow btn-danger ml-2"
                                            onClick={() => cancelUpdate()}
                                        >
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <button
                                            className="btn btn-shadow btn-light"
                                            onClick={() => setShowInfo(true)}
                                        >
                                            <i className="fas fa-pencil-alt"></i>
                                        </button>
                                    </React.Fragment>
                                )}
                            </div>

                        </div>
                    </div>
                </div>

                                    <div className="col-md-6 pl-0">
                                        <div className="col-md-12">
                                            <h5 className='ui-index-configurationFormat-subtitle-tab'>Configuración Correo-e Externos</h5>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" onChange={(e) => updateStateFormat(!configuration_format.send_email_to_extern, "send_email_to_extern")} className="custom-control-input" id={`customShowNotificationsExtern`} checked={configuration_format.send_email_to_extern} />
                                                <label className="custom-control-label" htmlFor={`customShowNotificationsExtern`}>Enviar notificación</label>
                                                <p className="font-12 pt-1">Envía una notificación cada que generen un registro en este formato abajo puedes configurar el mensaje</p>
                                            </div>

                                            {true && (
                                                <div className="col-md-12">
                                                    <button
                                                        className="btn-shadow btn btn-info"
                                                        onClick={() => toogle("new", "externos")}
                                                    >
                                                        Condiciones
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-md-12 mt-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    {showInfoExtern ? (
                                                        <React.Fragment>
                                                            <div className="mb-3">
                                                                <label>Asunto de correo</label>
                                                                <input 
                                                                    type="text" 
                                                                    className='form form-control' 
                                                                    value={configuration_format.subject_email_extern}
                                                                    onChange={(e) => handleChangeUpdateConfiguration(e.target.value, "subject_email_extern")}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <input
                                                                    type="hidden"
                                                                    name="question_to_send_extern_id"
                                                                    value={selectedOptionQuestion.question_to_send_extern_id}
                                                                />
                                                                <label>Pregunta</label>
                                                                <Select
                                                                    onChange={handleChangeAutocompleteQuestion}
                                                                    options={props.questions}
                                                                    autoFocus={false}
                                                                    className={`link-form`}
                                                                    value={selectedOptionQuestion}
                                                                />
                                                            </div>

                                                            <FroalaEditor
                                                                model={configuration_format.email_content_extern}
                                                                config={props.config}
                                                                onModelChange={(e) => handleChangeUpdateConfiguration(e, "email_content_extern")}
                                                            />
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <div className="col-md-12 p-0">
                                                                <div className="row">
                                                                    <div className="col-md-8">
                                                                        <b>Configuración del Email</b>
                                                                    </div>

                                                                    <div className="col-md-3">

                                                                    </div>
                                                                </div>
                                                                <hr />
                                                            </div>

                                                            <p><b>Asunto: </b> {configuration_format.subject_email_extern} </p>
                                                            
                                                            <p><b>Pregunta: </b> {configuration_format.question_to_send_extern ? configuration_format.question_to_send_extern.label : null} </p>

                                                            <div className="pb-2"><b>Contenido del email</b></div>
                                                               {/*  <p style={{ fontSize: "12px" }}>(Debajo del mensaje siempre aparecera el link para ingresar al listado)</p> */}
                                                                <div className="send-format-email mt-2">
                                                                    {ReactHtmlParser(configuration_format.email_content_extern)}
                                                                </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>

                                                <div className="card-footer">
                                                    {showInfoExtern ? (
                                                        <React.Fragment>
                                                            <button
                                                                className="btn btn-shadow btn-info"
                                                                onClick={() => handleClickUpdateConfiguration()}
                                                            >
                                                                Actualizar
                                                            </button>

                                                            <button
                                                                className="btn btn-shadow btn-danger ml-2"
                                                                onClick={() => cancelUpdate()}
                                                            >
                                                                <i className="fas fa-times"></i>
                                                            </button>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <button
                                                                className="btn btn-shadow btn-light"
                                                                onClick={() => setShowInfoExtern(true)}
                                                            >
                                                                <i className="fas fa-pencil-alt"></i>
                                                            </button>
                                                        </React.Fragment>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
            </div>
        </React.Fragment>
    );
}

export default ConfigurationEmail;
