import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle';
import Loading from '../../../GeneralComponents/LoadingComponen';
import FormCreate from './FormCreate';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import IndexMobile from "./Mobile/index";
import { isMobile } from 'react-device-detect';

//moment
import moment from 'moment';

class Index extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            ErrorValues: true,
            modeEdit: false,
            isLoaded: false,
            id: "",

            form: {
                title: "",
                date: isMobile ? moment().format('YYYY-MM-DD') : '',
                responsable_id: "",
                description: "",
                position_ids: [],
            },

            selectedOption: {
                responsable_id: "",
                label: "Responsable"
            },

            selectedOptionMulti: {
                position_ids: [],
                label: "Responsable"
            },

            selectedOptionTaskBoard: {
                task_board_id: "",
                label: "Plantilla de tarea"
            },

            users: [],
            positions: [],
            editValues: [],
            task_boards: [],
        }
    }

    handleChangeAutocompleteMulti = selectedOptionMulti => {
        let array = []

        selectedOptionMulti.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            form: {
                ...this.state.form,
                position_ids: array
            }
        })
    }

    clearValues = () => {
        this.setState({
            ErrorValues: true,
            modeEdit: false,
            
            form: {
                title: "",
                date: isMobile ? moment().format('YYYY-MM-DD') : '',
                responsable_id: "",
                description: "",
                position_ids: [],
                task_board_id: "",
            },

            selectedOption: {
                responsable_id: "",
                label: "Responsable"
            },

            selectedOptionTaskBoard: {
                task_board_id: "",
                label: "Plantilla de tarea"
            },

            editValues: [],
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    validationForm = () => {
        if (this.state.form.title != "" &&  
                this.state.form.date != "" && 
                this.state.form.task_board_id != "" && 
                this.state.form.responsable_id != ""
            ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    //selects Config

    componentDidMount = () => {
        this.configSelect();
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                responsable_id: selectedOption.value
            }
        });
    };


    configSelect = () => {
        let array = [];
        let arrayPositions = [];
        let arrayTaskBoards = [];

        this.props.users.map((item) => (
            array.push({label: item.name, value: item.id})
        ))

        this.props.positions.map((item) => (
            arrayPositions.push({ label: item.name, value: item.id })
        ))

        this.props.task_board_templates.map((item) => (
            arrayTaskBoards.push({ label: item.name, value: item.id })
        ))
        
        this.setState({
            users: array,
            positions: arrayPositions,
            task_boards: arrayTaskBoards
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }    

    HandleClick = () => {
        if(this.validationForm() == true){
            this.setState({ isLoaded: true })
            if (this.state.modeEdit == true) {
                fetch(`/dofa/dofas/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if (data.type != "error"){
                        this.clearValues();
                        this.setState({ modal: false, isLoaded: false })
                        this.props.updateItem(data.register)
                        this.messageSuccess(data);
                    }else{
                        this.clearValues();
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                    }
                });
            }else{
                fetch(`/dofa/dofas`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if (data.type != "error"){
                        this.clearValues();
                        this.setState({ modal: false, isLoaded: false })
                        this.props.updateData(data.register)
                        this.messageSuccess(data);
                    }else{
                        this.clearValues();
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                    }
                });
            }
        }
    }

    handleChangeAutocompleteTaskBoard = selectedOptionTaskBoard => {
        this.setState({
            selectedOptionTaskBoard,
                form: {
                    ...this.state.form,
                    task_board_id: selectedOptionTaskBoard.value
                }
        });
    };

    edit = (foda) => {
        let array = []
        let arrayIds = []

        foda.positions.map((item) => (
            array.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            modal: true,
            modeEdit: true, 
            id: foda.token,

            form: {
                title: foda.title,
                date: foda.date,
                responsable_id: foda.responsable_id,
                description: foda.description,
                position_ids: arrayIds,
                task_board_id: foda.task_board ? foda.task_board.id : "",
            },

            selectedOptionTaskBoard: {
                task_board_id: foda.task_board ? foda.task_board.id : "",
                label: foda.task_board ? foda.task_board.name : "Plantilla de tarea"
            },

            selectedOption: {
                responsable_id: foda.responsable_id,
                label: `${foda.responsable.first_name} ${foda.responsable.first_last_name}`
            },

            editValues: array
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/dofa/dofas/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    if (response.type != "error"){
                        this.props.loadData()
                        this.messageSuccess(response)
                    }else{
                        this.messageSuccess(response);
                    }
                });
            }
        });
    };

    redirectTaks = (foda) => {
        Turbolinks.visit( `/dofa/dofas/${foda.token}`);
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={"Lista de análisis FODA"}
                    subTitle={""}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Editar Dofa" : "Nuevo"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}
                        isLoaded={this.state.isLoaded}
                        
                        //select values

                        //select task_board
                        handleChangeAutocompleteTaskBoard={this.handleChangeAutocompleteTaskBoard}
                        selectedOptionTaskBoard={this.state.selectedOptionTaskBoard}
                        task_boards={this.state.task_boards}
                        
                        //select user
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOption={this.state.selectedOption}
                        users={this.state.users}

                        //select positions
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}
                        selectedOptionMulti={this.state.selectedOptionMulti}
                        positions={this.state.positions}
                        editValues={this.state.editValues}
                    
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        {!isMobile ? (
                            <div className="content main-card mb-3 card card-style">
                                <div className="card-body ui-tables_layout-register-card-body">
                                    <div className="ui-tables_layout-table__container">
                                        <table className="table ui-tables_layout-table-header__container" id="sampleTable">
                                            {this.props.data.length >= 1 ? (
                                                <React.Fragment>
                                                    <thead className='ui-tables_layout-table-header'>
                                                        <tr className='ui-tables_layout-__container-title-table'>
                                                            <th>Título</th>
                                                            <th>Fecha</th>
                                                            <th>Responsable</th>
                                                            <th>Descripción</th>
                                                            {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                                                <th className="text-center">Opciones</th>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody className='ui-tables_layout-data__container'>
                                                        {this.props.data.map(foda => (
                                                            <tr key={foda.id}>
                                                                <td>{foda.title}</td>
                                                                <td>{foda.date}</td>
                                                                <td>{foda.responsable != undefined ? foda.responsable.first_name : ""} {foda.responsable != undefined ? foda.responsable.first_last_name : ""}</td>
                                                                <td>{foda.description != '' ? foda.description : 'Esta FODA no tiene descripción'}</td>
                                                                {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                                                    <td className="text-center" style={{ width: "10px" }}>
                                                                        <UncontrolledDropdown className="btn-group ui-tableIndexWeb-positions-dropdown__container">
                                                                            <DropdownToggle>
                                                                                <i className="fas fa-bars"></i>                                                                            
                                                                            </DropdownToggle>
                                                                            <DropdownMenu className="ui-tableIndexWeb-positions-dropdown_header dropdown-menu-right">
                                                                                {this.props.estados.gestionar && (
                                                                                    <DropdownItem
                                                                                        onClick={() =>  this.redirectTaks(foda)}
                                                                                        data-turbolinks="true"
                                                                                        className="dropdown-item"
                                                                                    >
                                                                                        Gestionar
                                                                                    </DropdownItem>
                                                                                )}
                                                                                {this.props.estados.editar && (
                                                                                    <DropdownItem
                                                                                        onClick={() => this.edit(foda)}
                                                                                        className="dropdown-item"
                                                                                    >
                                                                                        Editar
                                                                                    </DropdownItem>
                                                                                )}
                                                                                {this.props.estados.eliminar && (
                                                                                    <DropdownItem
                                                                                        onClick={() => this.delete(foda.id)}
                                                                                        className="dropdown-item"
                                                                                    >
                                                                                        Eliminar
                                                                                    </DropdownItem>
                                                                                )}
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </React.Fragment>
                                            ) : (
                                                <div className='card col-md-12'>
                                                    <div className="text-center">
                                                        <div className="text-center mt-4 mb-4" style={{width: '100%'}}>
                                                            <p className='ui-dofas-messageNoRecord'>Todavía no hay registros creados, da clic en <a onClick={() => this.toogle('new')}  className=" btn btn-info"><i className='fas fa-toolbox'></i> Nuevo</a> y genera el primer registro.</p>
                                                            <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/8/noRecord_strategy.jpg" alt="" className="ui-dofas-imgNoRecord" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ):(
                            <IndexMobile
                                data={this.props.data}
                                redirectTaks={this.redirectTaks}
                                edit={this.edit}
                                delete={this.delete}
                                estados={this.props.estados}
                            />
                        )}

                        {true && (
                            <div className="row mt-3">

                                <div className="col-md-2 text-left">
                                    <p>
                                        Mostrando {this.props.data.length} de {this.props.totalData}
                                    </p>
                                </div>

                                <div className="col-md-10 pl-0">
                                    <Pagination
                                        hideNavigation
                                        activePage={this.props.activePage}
                                        itemsCountPerPage={this.props.countPage}
                                        itemClass="page-item"
                                        innerClass="pagination"
                                        linkClass="page-link"
                                        totalItemsCount={this.props.totalData}
                                        pageRangeDisplayed={this.props.countPage}
                                        onChange={this.props.handlePageChange}
                                    />
                                </div>

                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

export default Index;