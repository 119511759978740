import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import FormCreate from '../Failure/FormCreate'
import FormCreateCause from '../Causes/FormCreate'
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";

class Show extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            showPopover: false,
            isLoaded: true,
            editFailure: false,
            showCreate: false,
            failure_id: "",
            id: "",
            causa_id: "",
            dataFailure: [],
        }
    }

    componentDidMount = () => {
        this.getDataFailure()
    }

    updateData = (data) => {
        this.setState({
            dataFailure: [...this.state.dataFailure, data],
        })
    }

    updateShowCrate = () => {
        this.setState({
            showCreate: false,
        })
    }

    //add update
    updateItem = failure => {
        this.setState({
            dataFailure: this.state.dataFailure.map(item => {
                if (failure.id === item.id) {
                    return {
                        ...item,
                        mode: failure.mode,
                        effect: failure.effect,
                        severity: failure.severity,
                    }
                }
                return item;
            })
        });
    }

    updateState = (state) => {
        this.setState({
            showPopover: state,
        })
    }

    clearValues = () => {
        this.setState({
            failure_id: "",
            causa_id: "",
            id: "",
        })
    }

    getDataFailure = () => {
        fetch(`/failure/get_failures/${this.props.activity.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataFailure: data.data,
                    showCreate: data.last.fallo,
                    isLoaded: false
                });
            });
    }

    deleteFailure = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/failure/failures/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.getDataFailure();
                        this.props.loadData();
                    });
            }
        });
    };

    deleteCause = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/failure/causes/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.getDataFailure();
                        this.props.loadData();
                    });
            }
        });
    };

    render() {
        const styeleIcons = {
            fontSize: "16px",
            color: "#3f69d8",
            cursor: "pointer",
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-lg modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="fas fa-file-signature"></i> {this.props.title}</ModalHeader>

                    <ModalBody>
                        <div className="row modal-fail-container">

                            <div className="col-md-12 mb-3">
                                <div className="card">
                                    <div className="card-body fail-name">
                                        <h5 className="text-left">{this.props.activity.name}</h5>
                                        <div className="fail-name-button">
                                            <button
                                                id="PopoverLabels"
                                                type="button"
                                                className="btn-shadow btn btn-primary text-center"
                                                disabled={!this.state.showCreate}
                                                onClick={() => this.setState({ showPopover: true })}
                                            >
                                                Agregar modo de fallo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`col-md-12 ${!this.state.showCreate ? "" : "mt-3"}`}>
                                {this.state.dataFailure.length >= 1 ? (
                                    this.state.dataFailure.map((failure, index) => (
                                        <div className={`card ${index > 0 ? "mt-3" : ""}`}>
                                            <div className="card-body">
                                                <div className="row">

                                                    <div className="col-md-5">

                                                        <p><b>Modo:</b> {failure.mode}</p>

                                                    </div>
                                                    <div className="col-md-5">
                                                        <p className="mb-0"><b>Efecto:</b> {failure.effect}</p>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <p><b>Severidad:</b> {failure.severity}</p>
                                                    </div>

                                                    <div className="col-md-12 text-right">
                                            
                                                        <button
                                                            type="button"
                                                            className="btn-shadow btn btn-primary text-center mr-3"
                                                            id={`PopoverLegacy${failure.id}`}
                                                            onClick={() => this.setState({ id: failure.id })}
                                                        >
                                                           Agregar Causa
                                                         </button>

                                                        <span
                                                            className="mr-3"
                                                            onClick={() => this.setState({ failure_id: failure.id })}
                                                            id={`Popover${failure.id}`}
                                                        >
                                                            <i
                                                                className="fas fa-pencil-alt"
                                                                style={styeleIcons}
                                                            >
                                                            </i>
                                                        </span>

                                                        <i
                                                            className="fas fa-trash-alt mr-3"
                                                            style={styeleIcons}
                                                            onClick={() => this.deleteFailure(failure.id)}
                                                        >

                                                        </i>

                                                   
                                                    </div>
                                                 

                                                    {failure.causes.length >= 1 && (
                                                        <React.Fragment>
                                                            <div className="col-md-12"><hr/></div>
                                                            <div className="col-md-12">
                                                            <a data-toggle="collapse" href={`#collapseExample${failure.id}`} role="button" aria-expanded="false" aria-controls="collapseExample">
                                                                <i
                                                                    className="fa fa-angle-down ml-2 opacity-8"
                                                                    style={styeleIcons}
                                                                >

                                                                </i>
                                                            </a>
                                                            </div><br/>
                                                            <div className="collapse show color-childrens" id={`collapseExample${failure.id}`}>
                                                                <div className="col-md-12" style={{background:"#efefef", padding:"10px 20px"}}>
                                                                   
                                                                        {failure.causes.map((causa, index) => (
                                                                            <React.Fragment>
                                                                                <div className="row">
                                                                                    <div className={`col-md-6 mb-3 ${index > 0 ? "mt-3" : ""}`}>
                                                                                       <b> Causa: </b>{causa.cause}

                                                                                    </div>
                                                                                    <div className={`col-md-6 mb-3 ${index > 0 ? "mt-3" : ""}`}>
                                                                                    <b>  Control:</b> {causa.control}

                                                                                    </div>
                                                                                    <div className={`col-md-3 ${index > 0 ? "mt-3" : ""}`}>
                                                                                    <b>Ocurrencia: </b> {causa.ocurrency_score_first}
                                                                                      
                                                                                    </div>
                                                                                    <div className={`col-md-3 ${index > 0 ? "mt-3" : ""}`}>
                                                                                    <b> Detectabilidad:</b> {causa.detectability_score_first}
                                                                                    </div>

                                                                                    <div className={`col-md-3 ${index > 0 ? "mt-3" : ""}`}>
                                                                                        <b> NPR:</b> {causa.npr_score_first}
                                                                                    </div>

                                                                                    <div className="col-md-3 text-right">
                                                                                        <i
                                                                                            className="fas fa-pencil-alt"
                                                                                            style={styeleIcons}
                                                                                            id={`PopoverCause${causa.id}`}
                                                                                            onClick={() => this.setState({ causa_id: causa.id})}
                                                                                        >

                                                                                        </i>
                                                                                        
                                                                                        {this.state.causa_id == causa.id && (
                                                                                            <UncontrolledPopover isOpen={this.state.causa_id == causa.id}  placement="bottom" target={`PopoverCause${causa.id}`}>
                                                                                                <PopoverHeader>Editar Causa <a className="close-popover" onClick={() => this.setState({ causa_id: "" })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                                                                <PopoverBody>
                                                                                                    <FormCreateCause
                                                                                                        formValues={causa}
                                                                                                        clearValues={this.clearValues}
                                                                                                        loadData={this.getDataFailure}
                                                                                                        user={this.props.user}
                                                                                                        modeEdit={true}

                                                                                                    />
                                                                                                </PopoverBody>
                                                                                            </UncontrolledPopover>
                                                                                        )}

                                                                                        <i
                                                                                            className="fas fa-trash-alt ml-2"
                                                                                            style={styeleIcons}
                                                                                            onClick={() => this.deleteCause(causa.id)}
                                                                                        >

                                                                                        </i>

                                                                                    </div>
                                                                                    <div className="col-md-12"><hr/></div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )}

                                                    {this.state.failure_id == failure.id && (
                                                        <UncontrolledPopover isOpen={this.state.failure_id == failure.id} placement="bottom" target={`Popover${failure.id}`}>
                                                            <PopoverHeader>Editar Fallo <a className="close-popover" onClick={() => this.setState({ failure_id: "" })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                            <PopoverBody>
                                                                <FormCreate
                                                                    updateData={this.updateData}
                                                                    activity={this.props.activity}
                                                                    clearValues={this.clearValues}
                                                                    formValues={failure}
                                                                    modeEdit={true}
                                                                    updateItem={this.updateItem}
                                                                    user={this.props.user}
                                                                    type_count={this.props.type_count}
                                                                />
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    )}

                                                    {this.state.id == failure.id && (
                                                        <UncontrolledPopover isOpen={this.state.id == failure.id} placement="bottom" target={`PopoverLegacy${failure.id}`}>
                                                            <PopoverHeader>Agregar Causa <a className="close-popover" onClick={() => this.setState({ id: "" })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                            <PopoverBody>
                                                                <FormCreateCause
                                                                    formValues={failure}
                                                                    loadData={this.getDataFailure}
                                                                    clearValues={this.clearValues}
                                                                    id={this.state.id}
                                                                    loadDataActivity={this.props.loadData}
                                                                    modeEdit={false}
                                                                    user={this.props.user}
                                                                    type_count={this.props.type_count}
                                                                />
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    )}


                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="text-center">No hay fallos</h4>
                                            </div>
                                        </div>
                                    )}
                            </div>

                            <div className="col-md-3 mt-4">


                                <UncontrolledPopover isOpen={this.state.showPopover} placement="bottom" target="PopoverLabels">
                                    <PopoverHeader>Nuevo<a className="close-popover" onClick={() => this.setState({ showPopover: (!this.state.showPopover) })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                    <PopoverBody>
                                        <FormCreate
                                            updateData={this.updateData}
                                            updateShowCrate={this.updateShowCrate}
                                            activity={this.props.activity}
                                            updateState={this.updateState}
                                            clearValues={this.clearValues}
                                            loadDataActivity={this.props.loadData}
                                            modeEdit={false}
                                            user={this.props.user}
                                            type_count={this.props.type_count}
                                        />
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>


                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                    </ModalFooter>

                </Modal>
            </React.Fragment>
        );
    }
}

export default Show;