import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CheckboxContainer from '../FormatCategories/CheckboxContainer'

class FormUpdatePermisionFormat extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                <div className="col-md-12 mt-4">
                                    <CheckboxContainer
                                        checkedItems={this.props.checkedItems}
                                        handleChangeAccions={this.props.handleChangeAccions}
                                        checkboxes={this.props.checkboxes}
                                        modules={this.props.modules}
                                        handleChangeSelectAll={this.props.handleChangeSelectAll}
                                        modulo_ids={this.props.modulo_ids}
                                        formValues={this.props.formValues}
                                        position={this.props.position}
                                    />
                                </div>


                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBnt != undefined ? this.props.nameBnt : "Crear"}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormUpdatePermisionFormat;