import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FormFilter = (props) => {
    
    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <div className="card mb-3">
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-5">
                                <label>Nombre de la empresa</label>
                                <input 
                                    type="text" 
                                    name="company_name"
                                    className='form form-control'
                                    value={props.form.company_name}
                                    onChange={props.onChangeForm}
                                />
                            </div>

                            <div className="col-md-5">
                                <label>Nombre del formato</label>
                                <input 
                                    type="text" 
                                    name="format_name"
                                    className='form form-control'
                                    value={props.form.format_name}
                                    onChange={props.onChangeForm}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className="btn-shadow btn btn-info mr-2" onClick={props.handleClickFilter}>Aplicar filtro</button>  
                        <label className="btn-shadow btn btn-danger mt-2" onClick={props.handleClickCancel}>Cerrar</label>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}


export default FormFilter;

