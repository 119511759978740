import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FormAddNit = (props) => {

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                    <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>

                        <form onSubmit={handleSubmit}>
                            <ModalBody>
                                <label>Nit Empresa</label>
                                <input 
                                    type="text"
                                    name='nit'
                                    value={props.formValues.nit}
                                    onChange={props.onChangeForm}
                                    placeholder="NIT Empresa"
                                    className="form form-control" 
                                />

                            </ModalBody>

                            <ModalFooter>
                                <button 
                                    className="btn-shadow btn btn-info" 
                                    onClick={props.submitForm}                                    
                                >                                   
                                  Agregar  
                                </button>
                                <a className="btn btn-outline-danger mr-2" onClick={() => props.toogle()}>Cancelar</a>
                            </ModalFooter>
                        </form>
                </Modal>
        </React.Fragment>
    );
} 

export default FormAddNit;