import React, { useState, useEffect } from 'react';
import FormCreatePayment from './FormCreatePayment';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { options } from 'preact';
import { array } from 'prop-types';

const PaymentTenantInfo = (props) => {
    const [data, setData] = useState([]);
    const [form, setForm] = useState({ payment_date: "", payment_email: "", amount: "", payment_state: "", payment_type: "", tenant_id: props.tenant.id, plan_id: "", payment_time: "" });
    const [id, setId] = useState("");

    const [modal, setModal] = useState(false);
    const [modeEdit, setModeEdit] = useState(false);
    const [errorValues, setErrorValues] = useState(true);

    const [selectedOption, setSelectedOption] = useState({plan_id: "", label: ""});
    const [plan, setPlan] = useState(0);

    const token = document.querySelector("[name='csrf-token']").content;

      
    useEffect(() => {
        loadData();
        configSelect();
    }, []);

    

    const loadData = () => {
        fetch(`/settings/get_tenant_payments/${props.tenant.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            // setIsLoaded(false);
        });
    }    

    const configSelect = () => {
        const array = [];
        props.plan.map((item) => (
            array.push({label: item.name, value: item.id})
         ));

         setPlan(array);
         return(setPlan);
       
    }

    const handleChangeAutocomplete = selectedOption => {
        setSelectedOption(selectedOption)
        // console.log(selectedOption)
        setForm({ ...form, plan_id: selectedOption.value}) 
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })        
    }

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const updateItem = (tenant_payment) => {
        setData(data.map(item => {
          if (item.id === tenant_payment.id) {
            return { ...item,                 
                amount: tenant_payment.amount,
                payment_date: tenant_payment.payment_date,
                payment_email: tenant_payment.payment_email,
                payment_state: tenant_payment.payment_state,
                payment_type: tenant_payment.payment_type,
                plan_id: tenant_payment.plan_id,
                payment_time: tenant_payment.payment_time,
            }
          }
          return item;
        }));
    }

    const updateData = (new_item) => {
        setData([...data, new_item])
    }

    const handleClick = () => {
        if(true){
            if (!modeEdit)
                fetch(`/settings/tenant_payments`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type == "success"){
                        setModal(false);
                        messageSuccess(data);
                        updateData(data.register);
                        clearValues();
                    }else{
                        messageSuccess(data);
                        clearValues();
                    }
                });
            else {
                fetch(`/settings/tenant_payments/${id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type == "success"){
                        setModal(false);
                        messageSuccess(data);
                        updateItem(data.register);
                        clearValues();
                    }else{
                        messageSuccess(data);
                        clearValues();
                    }
                });
            }
        }
    }

    const edit = (tenant_payment) => {
        setForm({ payment_date: tenant_payment.payment_date, payment_email: tenant_payment.payment_email, amount: tenant_payment.amount, payment_state: tenant_payment.payment_state, payment_type: tenant_payment.payment_type, tenant_id: props.tenant.id, plan_id: tenant_payment.plan_id, payment_time: tenant_payment.payment_time  });
        setId(tenant_payment.id);
        setModal(true);
        setModeEdit(true);
        setSelectedOption({plan_id: tenant_payment.plan_id, label: tenant_payment.plan.name});
    }

    const deleteTenantPayment = (tenant_payment_id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/tenant_payments/${tenant_payment_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    loadData()
                });
            }
        });
    };

    const clearValues = () => {
        setModeEdit(false);
        setForm({payment_date: "", payment_email: "", amount: "", payment_state: "", payment_type: "", tenant_id: props.tenant.id, plan_id: "", payment_time: "" });
        setErrorValues(true);
        setSelectedOption({plan_id: "", label: ""});
    }

    const toogle = (from) => {
        if (from == "new") {
            setModal(true);
        } else {
            setModal(false);
            clearValues();
        }
    }   

    return (
            <div className="card ui-tenant_admin-container-payment">
                <div className="ui-tenant_admin-button-create-data-payment">
                    <button className="ui-tenant_admin-button-create btn btn-info btn-shadown" onClick={() => toogle("new")}>Nuevo Pago</button>
                    {modal && (
                        <FormCreatePayment
                            backdrop={"static"}
                            modal={modal}
                            toggle={toogle}
                            title={modeEdit ? "Actualizar Pago" : "Crear Pago"}
                            nameBnt={modeEdit ? "Actualizar" : "Crear"}
                            
                            formValues={form}
                            onChangeForm={handleChange}
                            submitForm={handleClick}

                            handleChangeAutocomplete={handleChangeAutocomplete}
                            selectedOption={selectedOption} 
                            plans={plan} 
                            
                        />
                    )}
                </div>
                <table className='ui-tenant_admin_tablet_payment'>
                    {data.length < 1 ? (
                        <div className="card-body text-center">
                            <h4>No hay pagos creados</h4>
                        </div>
                     ):(
                    <React.Fragment>
                        <tr className='ui-tenant_admin_subtitle__container'>
                            <th>Id</th>
                            <th>Correo Electrónico</th>
                            <th>Fecha de Pago</th>
                            <th>Tiempo Pago</th>
                            <th>Valor</th>
                            <th>Plan</th>
                            <th>Tipo de Pago</th>
                            <th>Estado</th>
                            <th>Opciones</th>
                        </tr>
                        {data.map((tenant_payment, index) => (                         
                            <div className="card ui-tenant_admin_data">
                                <div className="card-body">                            
                                    <tr className='ui-tenant_admin_data__container'>
                                        <td>
                                        {tenant_payment.id}
                                        </td>
                                        <td>{tenant_payment.payment_email}</td>
                                        <td>{tenant_payment.payment_date}</td>
                                        <td>{tenant_payment.payment_time}</td>
                                        <td>$ {tenant_payment.amount}</td>
                                        <td>{tenant_payment.plan?.name}</td>
                                        <td>{tenant_payment.payment_type}</td>
                                        <td>{tenant_payment.payment_state}</td>
                                        <td>
                                            <UncontrolledDropdown className='btn-group' direction="left">
                                                <DropdownToggle className='btn-shadown btn btn-info'>
                                                    <i className="fas fa-bars"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className='dropdown-menu'>
                                                    <DropdownItem onClick={() => edit(tenant_payment)}>
                                                        Editar
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => deleteTenantPayment(tenant_payment.id)}>
                                                        Eliminar
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                        </td>
                                    </tr>
                                </div>
                            </div>
                        ))}
                    </React.Fragment>
                    )}
                </table>
            </div>
    )

}

export default PaymentTenantInfo;
