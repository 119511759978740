import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import TableTask from '../components/tasks/taskBoard/TableTask';
import FormFilter from '../components/tasks/tasksCard/FormFilter';
import HeaderTitle from '../GeneralComponents/HeaderTitle'

class IndexTasksTable extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            data: [], //
            urlFilter: "",
            isLoaded: true,
            showFilter: false,
            isFiltering: false,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data].reverse(),
            isLoaded: false,
            isFiltering: false,
        })
    }

    //add update
    updateItem = task => {
        console.log("updateItemupdateItemupdateItem")
        this.setState({
            data: this.state.data.map(item => {
            if (task.id === item.id) {
              return { ...item, 
                description: task.description,
                due_date: task.due_date,
                name: task.name,
                state: task.state,
                user_owner: task.user_owner,
                general_comments: task.general_comments.length,
                general_files: task.general_files.length,
                task_labels: task.task_labels,
                users: task.task_labels,
                checklist: task.checklist,
                url_source: task.url_source,
                task_source: task.task_source,
              }
            }
            return item;
          })
        });
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    handleClickShowFilter = () => {
        this.setState({
            showFilter: (this.state.showFilter == false ? true : false)
        })
    }

    handleClickCancel = () => {
        this.setState({ showFilter: false, isFiltering: false })
        this.loadData();
    }
    

    loadData = () => {
        fetch(this.props.url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }


    loadDataFilter = () => {
        fetch(this.state.urlFilter, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.child.current.updateState(data.data)
        });
    }

    getNameTitle = (name) => {
        if (name == "ALL") {
            return `Todas las tareas`
        } else if (name == "ASSIGNED") {
            return `Tareas asignadas por mi`
        } else if (name == "MY") {
            return `Todas mis tareas`
        } else if (name == "GESTOR") {
            return `Tareas compartidas para mi`
        } else if (name == "FINISHED") {
            return `Tareas finalizadas`
        } else if (name == "TABLERO"){
            return `Tareas`
        }
    }

    updateDataFilter = (data) => {
        this.child.current.updateState(data.data)
    }

    updateStates = (state_is_filtering, state_is_loaded, url) => {
        this.setState({
            urlFilter: url,
            isFiltering: state_is_filtering,
            isLoaded: state_is_loaded,
        })
    }

    updateStateIsLoaded = (state) => {
        this.setState({
            isLoaded: state,
        })
    }

    cancelFilter = () => {
        this.loadData();
        this.setState({
            isFiltering: false,
            isLoaded: false,
            showFilter: false,
        })
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={this.getNameTitle(this.props.from)}
                    subTitle={"Gestión de tareas"}
                    titleNew={""}
                    titleFilter={"Filtros"}
                    onClickNew={""}
                    onClickFilter={this.handleClickShowFilter}
                    showNew={false}
                    showFilter={false}
                    taskHistory={this.props.from != "TABLERO" ? this.props.from : undefined}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.showFilter && (
                    <FormFilter 
                        handleClickFilter={this.HandleClickFilter}
                        handleClickCancel={this.handleClickCancel}
                        handleChangeFilter={this.handleChangeFilter}
                        from={this.props.from}
                        index={true}
                        url={this.state.url}

                        //values data
                        users={this.props.users}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        procesos={this.props.procesos}
                        task_sources={this.props.task_sources}

                        //update states
                        updateStateIsLoaded={this.updateStateIsLoaded}
                        updateStates={this.updateStates}
                        updateDataFilter={this.updateDataFilter}
                        cancelFilter={this.cancelFilter}

                        //states
                        isFiltering={this.state.isFiltering}
                    />
                )}

                {this.props.from == "TABLERO" && (
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a
                                    href={`/task/task_boards`}
                                    data-turbolinks="true"
                                >
                                    {"Volver a los tableros"}
                                </a>
                            </li>
                        </ol>
                    </nav>
                )}


                <TableTask
                    url_table={this.props.url}
                    user={this.props.user} 
                    users={this.props.users}
                    estados={this.props.estados}
                    task_board={this.props.task_board}
                    procesos={this.props.procesos}
                    columns={this.props.columns}
                    labels={this.props.labels}
                    tasks={this.props.tasks}
                    task_sources={this.props.task_sources}
                    task_boards={this.props.task_boards}
                    company={this.props.company}
                    imageUpload={this.props.imageUpload}
                    key_text={this.props.key_text}
                    task={this.props.task_id}

                    showNew={true}
                    showOptions={true}
                />
            </React.Fragment>
        );
    }
}


export default IndexTasksTable;
WebpackerReact.setup({ IndexTasksTable });

