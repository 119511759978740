import React, { Component } from 'react';
import Show from '../components/dofa/Dofa/Show'
import WebpackerReact from 'webpacker-react';

class ShowDofa extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataFactors: [],
            dataStrategic: [],
            dataObjetives: [],
            isLoaded: true,
            showFilter: false,

            formFilter: {
                strategic_objective_id: "",
                description: "",
            },

        }
    }

    componentDidMount(){
        this.loadDataFactors();
        this.loadDataStrategic();
    }

    HandleChangeFilter = (e) => {
        this.setState({
            formFilter: {
                ...this.state.formFilter,
                [e.target.name]: e.target.value
            }
        })
    }

    clearValues = () => {
        this.setState({
            formFilter: {
                strategic_objective_id: "",
                description: "",
            },
        })
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateDataFactors = (data) => {
        this.setState({
            dataFactors: [...this.state.dataFactors, data],
        })
    }

    updateDataStrategic = (data) => {
        this.setState({
            dataStrategic: [...this.state.dataStrategic, data],
        })
    }

    //add update
    updateItemFactors = factor => {
        this.setState({
            dataFactors: this.state.dataFactors.map(item => {
            if (factor.id === item.id) {
              return { ...item, 
                description: factor.description,
              }
            }
            return item;
          })
        });
    }

    updateItemStrategic = strategic => {
        this.setState({
            dataStrategic: this.state.dataStrategic.map(item => {
            if (strategic.id === item.id) {
              return { ...item, 
                name: strategic.name,
                description: strategic.description,
                factors: strategic.factors,
                company_objetives: strategic.company_objetives,
              }
            }
            return item;
          })
        });
    }

    loadDataFactors = () => {
        fetch(`/dofa/get_factors/${this.props.dofa.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataFactors: data.data,
            dataObjetives: data.company_objetives,
            isLoaded: false
          });
        });
    }

    loadDataStrategic = () => {
        fetch(`/dofa/get_strategic_objetives/${this.props.dofa.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                dataStrategic: data.data,
                isLoaded: false
            });
        });
    }

    changeState = (state) => {
        this.clearValues()
        this.setState({ showFilter: state })
        if(!state){
            this.loadDataStrategic()
        }
    }


    HandleClickFilter = () => {
        fetch(`/dofa/get_strategic_objetives/${this.props.dofa.id}?strategic_objective_id=${this.state.formFilter.strategic_objective_id}&description=${this.state.formFilter.description}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataStrategic: data.data,
          });
        });
    }


    render() {
        return (
            <React.Fragment>
                <Show
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                    dofa={this.props.dofa}

                    columns={this.props.columns}
                    labels={this.props.labels}
                    tasks={this.props.tasks}
                    task_sources={this.props.task_sources}
                    company={this.props.company}
                    imageUpload={this.props.imageUpload}

                    //Factors 

                    loadDataFactors={this.loadDataFactors}
                    dataFactors={this.state.dataFactors}
                    updateDataFactors={this.updateDataFactors}
                    updateItemFactors={this.updateItemFactors}
                    
                    //Strategic 
                    
                    loadDataStrategic={this.loadDataStrategic}
                    dataStrategic={this.state.dataStrategic}
                    updateDataStrategic={this.updateDataStrategic}
                    updateItemStrategic={this.updateItemStrategic}

                    //objetives 

                    dataObjetives={this.state.dataObjetives}

                    user={this.props.user}
                    users={this.props.users}
                    procesos={this.props.procesos}
                    current_url={this.props.current_url}

                    //filter 

                    showFilter={this.state.showFilter}
                    HandleChangeFilter={this.HandleChangeFilter}
                    HandleClickFilter={this.HandleClickFilter}
                    formValuesFilter={this.state.formFilter}
                    changeState={this.changeState}
                    strategic_objetives={this.props.strategic_objetives}

                    task_source={this.props.task_source}
                    category_indicators={this.props.category_indicators}

                />
            </React.Fragment>
        );
    }
}


export default ShowDofa;
WebpackerReact.setup({ ShowDofa });
