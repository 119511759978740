import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"
import GeneralComments from '../GeneralComponents/ChatBoxModuleComment'
import Loading from '../GeneralComponents/LoadingComponen';

//views
import DocumentState from '../components/documentManagement/Document/DocumentState';
import Document from '../components/documentManagement/Document/Document';

class DocumentShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: true,
            current_tab: "document"
        }
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.setState({ isLoaded: false })
        }, 1000);
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Gestión del documento`}
                    subTitle={"Gestión documental"}
                    titleNew={"Nuevo documento"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={() => this.child.current.toogle("new")}
                    showNew={false}
                    showFilter={false}

                    is_index={true}
                    url={this.props.current_url}
                />


                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className={`nav-link ${this.state.current_tab == "document" ? "active" : ""}`} onClick={() => this.setState({ current_tab: "document" })}>Documento</a>
                    </li>

                    <li className="nav-item">
                        <a className={`nav-link ${this.state.current_tab == "document_state" ? "active" : ""}`} onClick={() => this.setState({ current_tab: "document_state" })}>Estado del documento</a>
                    </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                    {this.state.current_tab == "document" && (
                        <Document
                            document={this.props.document}
                            user={this.props.user}
                            company={this.props.company}
                        />
                    )}

                    {this.state.current_tab == "document_state" && (
                        <DocumentState
                            document={this.props.document}
                            user={this.props.user}
                            notifications={this.props.notifications}

                            module_comment={this.props.document.module_comment_id}
                            url={this.props.url}
                            users={this.props.users}
                            company={this.props.company}
                            imageUpload={this.props.imageUpload}
                            key_text={this.props.key_text}
                            has_parent={this.props.has_parent}
                            user_state_firm={this.props.user_state_firm}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }
}


export default DocumentShow;
WebpackerReact.setup({ DocumentShow });

