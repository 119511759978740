import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle';
import FormCreateFactor from '../Factors/FormCreate'
import FormCreateStrategic from '../StrategicObjectives/FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';
import TasksItems from '../../../components/tasks/tasksNumeralChildScore/TasksItems'
import TaskFormCreate from '../../../components/tasks/tasksNumeralChildScore/FormCreate'
import FormFilter from './FormFilter'
import IndexTaskModule from '../../tasks/taskBoard/IndexTaskModule'

class Show extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.url_source = `/dofa/dofas/${this.props.dofa.id}`,
            this.state = {
                modalFactor: false,
                modalStrategic: false,
                modeEditObjetives: false,
                modeEditFactor: false,
                ErrorValuesEstrategia: true,
                ErrorValuesFortaleza: true,
                countMax: 3,
                count: 0,
                checkedItems: new Map(),
                checkedItemsObjetives: new Map(),
                factors: ["Fortalezas", "Oportunidades", "Debilidades", "Amenazas"],
                objetivos: ["Estrategias Defensivas", "Estrategias Ofensivas", "Estrategias Supervivencias", "Estrategias Adaptativas"],
                showTask: false,
                register: {},
                idTask: "",

                formFactor: {
                    dofa_id: this.props.dofa.id,
                    factor_type: "",
                    description: "",
                },

                formStrategic: {
                    dofa_id: this.props.dofa.id,
                    name: "",
                    description: "",
                    strategic_objective_type: "",
                    category_indicator_id: "",
                    factor_ids: [],
                    indicator_ids: [],
                    company_objetive_ids: [],
                },

                selectedOptionCategory: {
                    category_indicator_id: "",
                    label: ""
                },

                selectedOptionSubCategory: {
                    sub_category_indicator_id: "",
                    label: ""
                },

                selectedOptionMulti: {
                    indicator_ids: [],
                    label: ""
                },

                category_indicators: [],
                sub_category_indicators: [],
                indicators: [],
                editValues: [],
            }
    }

    validationFormEstrategia = () => {
        if (this.state.formStrategic.name != "" &&
            this.state.formStrategic.description != ""
        ) {
            this.setState({ ErrorValuesEstrategia: true })
            return true
        } else {
            this.setState({ ErrorValuesEstrategia: false })
            return false

        }
    }

    validationFormFortaleza = () => {
        if (this.state.formFactor.description != "") {
            this.setState({ ErrorValuesFortaleza: true })
            return true
        } else {
            this.setState({ ErrorValuesFortaleza: false })
            return false

        }
    }

    componentDidMount = () => {
        this.configSelect();
    }

    configSelect = () => {
        let array = [];

        this.props.category_indicators.map((item) => (
            array.push({ label: item.name, sub_category_indicators: item.sub_category_indicators, value: item.id })
        ))

        this.setState({
            category_indicators: array,
        })
    }



    getColorFactors = (name) => {
        if (name == "Fortalezas") {
            return "#ecd931"
        } else if (name == "Oportunidades") {
            return "#3d8835"
        } else if (name == "Debilidades") {
            return "#116e9c"
        } else if (name == "Amenazas") {
            return "#b52c29"
        }
    }

    getColorObjetives = (name) => {
        if (name == "Estrategias Defensivas") {
            return "#613a10"
        } else if (name == "Estrategias Ofensivas") {
            return "#5c1622"
        } else if (name == "Estrategias Supervivencias") {
            return "#635110"
        } else if (name == "Estrategias Adaptativas") {
            return "#1c441b"
        }
    }

    HandleChangeFactors = (e) => {
        this.setState({
            formFactor: {
                ...this.state.formFactor,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChangeObjetives = (e) => {
        this.setState({
            formStrategic: {
                ...this.state.formStrategic,
                [e.target.name]: e.target.value
            }
        })
    }

    clearValuesFactor = () => {
        this.setState({
            formFactor: {
                ...this.state.formFactor,
                factor_type: "",
                description: ""
            },

            ErrorValuesFortaleza: true,
            modeEditFactor: false,
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValuesObjetives = () => {
        this.setState({
            formStrategic: {
                ...this.state.formStrategic,
                name: "",
                description: "",
                strategic_objective_type: "",
                factor_ids: [],
                indicator_ids: [],
                category_indicator_id: "",
                company_objetive_ids: [],
            },

            selectedOptionCategory: {
                category_indicator_id: "",
                label: ""
            },

            selectedOptionSubCategory: {
                sub_category_indicator_id: "",
                label: ""
            },

            selectedOptionMulti: {
                indicator_ids: [],
                label: ""
            },

            sub_category_indicators: [],
            indicators: [],
            editValues: [],

            ErrorValuesEstrategia: true,
            modeEditObjetives: false,
            checkedItems: new Map(),
            checkedItemsObjetives: new Map(),
        })
    }

    HandleClickFactor = () => {
        if (this.validationFormFortaleza() == true) {
            if (this.state.modeEditFactor == true) {

                fetch(`/dofa/factors/${this.state.formFactor.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formFactor), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValuesFactor();
                        this.setState({ modalFactor: false })
                        this.props.updateItemFactors(data.register)
                        this.messageSuccess(data);
                    });

            } else {

                fetch(`/dofa/factors`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formFactor), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValuesFactor();
                        this.setState({ modalFactor: false })
                        this.props.updateDataFactors(data.register)
                        this.messageSuccess(data);
                    });

            }
        }
    }

    HandleClickObjetives = () => {
        if (this.validationFormEstrategia() == true) {
            if (this.state.modeEditObjetives == true) {

                fetch(`/dofa/objetives/${this.state.formStrategic.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formStrategic), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValuesObjetives();
                        this.setState({ modalStrategic: false })
                        this.props.updateItemStrategic(data.register)
                        this.messageSuccess(data);
                    });

            } else {

                fetch(`/dofa/objetives`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formStrategic), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValuesObjetives();
                        this.setState({ modalStrategic: false })
                        this.props.updateDataStrategic(data.register)
                        this.messageSuccess(data);
                    });

            }
        }
    }

    toogleFactor = (from, type) => {
        console.log(type)
        if (from == "new") {
            this.setState({
                formFactor: {
                    ...this.state.formFactor,
                    factor_type: type,
                    description: ""
                },

                modalFactor: true
            })
        } else {
            this.clearValuesFactor();
            this.setState({ modalFactor: false })
        }
    }

    toogleStrategic = (from, type) => {
        if (from == "new") {
            this.setState({
                formStrategic: {
                    ...this.state.formStrategic,
                    name: "",
                    description: "",
                    strategic_objective_type: type
                },

                modalStrategic: true
            })
        } else {
            this.clearValuesObjetives();
            this.setState({ modalStrategic: false })
        }
    }

    handleChangeChecked = e => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        const array = this.state.formStrategic.factor_ids

        this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, isChecked)
        }));

        if (isChecked) {
            array.push(parseInt(item))
            this.setState({
                formStrategic: {
                    ...this.state.formStrategic,
                    factor_ids: array
                },
            })
        } else {
            var arr = array
            var index = array.indexOf(parseInt(item))
            delete arr[index]

            this.setState({
                formStrategic: {
                    ...this.state.formStrategic,
                    factor_ids: arr.filter(item => item != null),
                },
            })
        }
    };

    handleChangeCheckedObjetives = e => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        const array = this.state.formStrategic.company_objetive_ids

        this.setState(prevState => ({
            checkedItemsObjetives: prevState.checkedItemsObjetives.set(item, isChecked)
        }));

        if (isChecked) {
            array.push(parseInt(item))
            this.setState({
                formStrategic: {
                    ...this.state.formStrategic,
                    company_objetive_ids: array
                },
            })
        } else {
            var arr = array
            var index = array.indexOf(parseInt(item))
            delete arr[index]

            this.setState({
                formStrategic: {
                    ...this.state.formStrategic,
                    company_objetive_ids: arr.filter(item => item != null),
                },
            })
        }
    };

    editStrategic = (strategic) => {
        const array = strategic.factors
        const arrayNew = []
        const arrayObjetives = strategic.company_objetives
        const arrayNewObjetives = []
        const arrayIndicators = []
        const arrayIndicatorsIds = []


        array.map((factor, index) => (
            arrayNew.push(factor.id)
        ));

        arrayObjetives.map((factor, index) => (
            arrayNewObjetives.push(factor.id)
        ));

        strategic.indicators.map((item) => (
            arrayIndicators.push({ label: item.name, value: item.id }),
            arrayIndicatorsIds.push(item.id)
        ))



        this.setState({
            formStrategic: {
                ...this.state.formStrategic,
                name: strategic.name,
                description: strategic.description,
                strategic_objective_type: strategic.strategic_objective_type,
                factor_ids: arrayNew,
                indicator_ids: arrayIndicatorsIds,
                category_indicator_id: `${strategic.category_indicator != null ? strategic.category_indicator.id : ""}`,
                company_objetive_ids: arrayNewObjetives,
                id: strategic.id,
            },

            selectedOptionCategory: {
                category_indicator_id: `${strategic.category_indicator != null ? strategic.category_indicator.id : ""}`,
                label: `${strategic.category_indicator != null ? strategic.category_indicator.name : ""}`,
            },

            selectedOptionMulti: {
                indicator_ids: arrayIndicatorsIds,
                label: ""
            },

            indicators: arrayIndicators,
            editValues: arrayIndicators,

            modeEditObjetives: true,
            modalStrategic: true
        })
    }

    updateMap = (map) => {
        this.setState({
            checkedItems: map
        })
    }

    updateMapObjetivos = (map) => {
        this.setState({
            checkedItemsObjetives: map
        })
    }

    EditFactor = (factor) => {
        this.setState({
            formFactor: {
                ...this.state.formFactor,
                factor_type: factor.factor_type,
                description: factor.description,
                id: factor.id,
            },

            modeEditFactor: true,
            modalFactor: true,
        })
    }

    deleteFactor = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/dofa/factors/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadDataFactors()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/dofa/objetives/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadDataStrategic()
                        this.messageSuccess(response)
                    });
            }
        });
    };


    closeTask = () => {
        this.setState({
            idTask: "",
            showTask: false,
        })
    }

    //selects

    handleChangeAutocompleteCategory = selectedOptionCategory => {
        let array = [];

        selectedOptionCategory.sub_category_indicators.map((item) => (
            array.push({ label: item.name, indicators: item.indicators, value: item.id })
        ))

        this.setState({
            selectedOptionCategory,
            formStrategic: {
                ...this.state.formStrategic,
                category_indicator_id: selectedOptionCategory.value
            },

            sub_category_indicators: array
        });
    }

    handleChangeAutocompleteSubCategory = selectedOptionSubCategory => {
        let array = [];

        selectedOptionSubCategory.indicators.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            selectedOptionSubCategory,
            indicators: array,
        });
    }


    handleChangeAutocompleteMulti = selectedOptionMulti => {
        let array = []

        selectedOptionMulti.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            formStrategic: {
                ...this.state.formStrategic,
                indicator_ids: array
            }
        })
    }


    getDataState = (strategic) => {
        if (strategic.strategic_objective_type == "Estrategias Defensivas") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header" style={{ color: "#ffff", backgroundColor: this.getColorFactors("Fortalezas"), height: "1.5rem" }}>
                                    Fortalezas
                                </div>
                                <div className="card-body">
                                    <ul className="pl-2">
                                        {strategic.factors.filter(factor => factor.factor_type == "Fortalezas").map((data, index) => (
                                            <li>{data.description}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header" style={{ color: "#ffff", backgroundColor: this.getColorFactors("Amenazas"), height: "1.5rem" }}>
                                    Amenazas
                                </div>

                                <div className="card-body">
                                    <ul className="pl-2">
                                        {strategic.factors.filter(factor => factor.factor_type == "Amenazas").map((data, index) => (
                                            <li>{data.description}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else if (strategic.strategic_objective_type == "Estrategias Ofensivas") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header" style={{ color: "#ffff", backgroundColor: this.getColorFactors("Fortalezas"), height: "1.5rem" }}>
                                    Fortalezas
                                </div>
                                <div className="card-body">
                                    <ul className="pl-2">
                                        {strategic.factors.filter(factor => factor.factor_type == "Fortalezas").map((data, index) => (
                                            <li>{data.description}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header" style={{ color: "#ffff", backgroundColor: this.getColorFactors("Oportunidades"), height: "1.5rem" }}>
                                    Oportunidades
                                </div>

                                <div className="card-body">
                                    <ul className="pl-2">
                                        {strategic.factors.filter(factor => factor.factor_type == "Oportunidades").map((data, index) => (
                                            <li>{data.description}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else if (strategic.strategic_objective_type == "Estrategias Supervivencias") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header" style={{ color: "#ffff", backgroundColor: this.getColorFactors("Debilidades"), height: "1.5rem" }}>
                                    Debilidades
                                </div>
                                <div className="card-body">
                                    <ul className="pl-2">
                                        {strategic.factors.filter(factor => factor.factor_type == "Debilidades").map((data, index) => (
                                            <li>{data.description}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header" style={{ color: "#ffff", backgroundColor: this.getColorFactors("Amenazas"), height: "1.5rem" }}>
                                    Amenazas
                                </div>

                                <div className="card-body">
                                    <ul className="pl-2">
                                        {strategic.factors.filter(factor => factor.factor_type == "Amenazas").map((data, index) => (
                                            <li>{data.description}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else if (strategic.strategic_objective_type == "Estrategias Adaptativas") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header" style={{ color: "#ffff", backgroundColor: this.getColorFactors("Debilidades"), height: "1.5rem" }}>
                                    Debilidades
                                </div>
                                <div className="card-body">
                                    <ul className="pl-2">
                                        {strategic.factors.filter(factor => factor.factor_type == "Debilidades").map((data, index) => (
                                            <li>{data.description}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header" style={{ color: "#ffff", backgroundColor: this.getColorFactors("Oportunidades"), height: "1.5rem" }}>
                                    Oportunidades
                                </div>

                                <div className="card-body">
                                    <ul className="pl-2">
                                        {strategic.factors.filter(factor => factor.factor_type == "Oportunidades").map((data, index) => (
                                            <li>{data.description}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    getStrategiName = (name) => {
        console.log(name)
        switch (name) {
            case "Estrategias Defensivas":
                return "ESTRATEGIA DEFENSIVA"
                break;
            case "Estrategias Ofensivas":
                return "ESTRATEGIA OFENSIVA"
                break;
            case "Estrategias Supervivencias":
                return "ESTRATEGIA SUPERVIVENCIA"
                break;
            case "Estrategias Adaptativas":
                return "ESTRATEGIA ADAPTATIVA"
                break;
            default:
            //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
        }
    }

    stepCount = (current_number, update_number) => {
        this.setState({
            count: current_number
        })
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.dofa.title}`}
                    subTitle={"Es aconsejable que tanto en el análisis interno como en el externo sea realista y trate de reflejar la realidad de la empresa o del proyecto empresarial, para poder establecer una buena estrategia de actuación"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.modalFactor && (
                    <FormCreateFactor
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalFactor}
                        toggle={this.toogleFactor}
                        title={this.state.modeEdit == true ? "Editar foda" : "Nuevo"}

                        //form props
                        formValues={this.state.formFactor}
                        onChangeForm={this.HandleChangeFactors}
                        submitForm={this.HandleClickFactor}
                        nameSubmit={this.state.modeEditFactor == true ? "Actualizar" : "Crear"}
                        ErrorValuesFortaleza={this.state.ErrorValuesFortaleza}

                        getColorFactors={this.getColorFactors}
                    />
                )}

                {this.state.modalStrategic && (
                    <FormCreateStrategic
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalStrategic}
                        toggle={this.toogleStrategic}
                        title={this.state.modeEdit == true ? "Editar foda" : "Nuevo"}

                        //form props
                        formValues={this.state.formStrategic}
                        onChangeForm={this.HandleChangeObjetives}
                        submitForm={this.HandleClickObjetives}
                        nameSubmit={this.state.modeEditObjetives == true ? "Actualizar" : "Crear"}
                        ErrorValuesEstrategia={this.state.ErrorValuesEstrategia}

                        getColorObjetives={this.getColorObjetives}
                        getColorFactors={this.getColorFactors}
                        dataFactors={this.props.dataFactors}
                        handleChangeChecked={this.handleChangeChecked}
                        checkedItems={this.state.checkedItems}
                        modeEditObjetives={this.state.modeEditObjetives}
                        updateMap={this.updateMap}
                        count={this.state.count}

                        //objetives
                        dataObjetives={this.props.dataObjetives}
                        handleChangeCheckedObjetives={this.handleChangeCheckedObjetives}
                        checkedItemsObjetives={this.state.checkedItemsObjetives}
                        updateMapObjetivos={this.updateMapObjetivos}

                        //selects 

                        //select category indicators
                        handleChangeAutocompleteCategory={this.handleChangeAutocompleteCategory}
                        selectedOptionCategory={this.state.selectedOptionCategory}
                        category_indicators={this.state.category_indicators}

                        //select sub category indicators
                        handleChangeAutocompleteSubCategory={this.handleChangeAutocompleteSubCategory}
                        selectedOptionSubCategory={this.state.selectedOptionSubCategory}
                        sub_category_indicators={this.state.sub_category_indicators}

                        //select indicators
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}
                        selectedOptionMulti={this.state.selectedOptionMulti}
                        indicators={this.state.indicators}
                        editValues={this.state.editValues}
                    />
                )}

                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <p>{this.props.dofa.description}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <ul className="nav nav-tabs ui-navTab-Tabs" id="myTab" role="tablist">
                            <li className="nav-item" onClick={() => { this.setState({ count: 1 }); this.props.changeState(false) }}>
                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Análisis</a>
                            </li>

                            <li className="nav-item" onClick={() => { this.setState({ count: 2 }); this.props.changeState(false) }}>
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Estrategias</a>
                            </li>

                            {this.props.dataStrategic.length >= 1 && (
                                <li className="nav-item" onClick={() => this.setState({ count: 3 })}>
                                    <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Objetivos estratégicos</a>
                                </li>
                            )}

                            <li className="nav-item" onClick={() => { this.setState({ count: 2 }); this.props.changeState(false) }}>
                                <a className="nav-link" id="tasks-tab" data-toggle="tab" href="#tasks" role="tab" aria-controls="tasks" aria-selected="false">Tareas</a>
                            </li>
                        </ul>
                    </div>

                    {this.props.showFilter && (
                        <FormFilter
                            HandleChangeFilter={this.props.HandleChangeFilter}
                            formValuesFilter={this.props.formValuesFilter}
                            changeState={this.props.changeState}
                            dataStrategic={this.props.dataStrategic}
                            HandleClickFilter={this.props.HandleClickFilter}
                            showFilter={this.props.showFilter}
                            strategic_objetives={this.props.strategic_objetives}
                        />
                    )}

                    {this.state.count == this.state.countMax && !this.props.showFilter && (
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-10"></div>
                                <div className="col-md-2 text-right">
                                    <button
                                        className="btn-shadow btn btn-info"
                                        onClick={() => this.props.changeState(!this.props.showFilter)}
                                    >
                                        <i className="fas fa-filter pr-1"></i>Filtrar 
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="tab-content w-100" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="col-md-12">
                                <div className="row">
                                    {this.state.factors.map((factor, index) => (
                                        <div className={`col-md-6 mb-3`}>
                                            <div className="card">
                                                <div className="card-header" style={{ color: "#ffff", backgroundColor: this.getColorFactors(factor) }}>
                                                    {factor}
                                                </div>

                                                <div className="card-body pr-0 pl-0 pt-0">
                                                    <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                                        <tbody>
                                                            {this.props.dataFactors.filter(data => data.factor_type == factor).map((data, index) => (
                                                                <tr key={data.id}>
                                                                    <td><i className="fas fa-signal mr-2"></i> {data.description}</td>
                                                                    <td className="text-right" style={{ width: "10px" }}>
                                                                        <UncontrolledDropdown className='btn-group'>
                                                                        <DropdownToggle className='btn-shadow btn btn-info'>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.EditFactor(data)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.deleteFactor(data.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="card-footer">
                                                    <button
                                                        className="btn"
                                                        style={{ color: "#ffff", backgroundColor: this.getColorFactors(factor) }}
                                                        onClick={() => this.toogleFactor("new", factor)}
                                                    >
                                                        Añadir {factor}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="col-md-12">
                                <div className="row">
                                    {this.state.objetivos.map((objetivo, index) => (
                                        <div className={`col-md-6 mb-3`}>
                                            <div className="card">
                                                <div className="card-header" style={{ color: "#ffff", backgroundColor: this.getColorObjetives(objetivo) }}>
                                                    {objetivo}
                                                </div>

                                                <div className="card-body pr-0 pl-0 pt-0">
                                                    <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                                        <tbody>
                                                            {this.props.dataStrategic.filter(data => data.strategic_objective_type == objetivo).map((data, index) => (
                                                                <tr key={data.id}>
                                                                    <td><i className="fas fa-signal mr-2"></i> {data.name}</td>
                                                                    <td className="text-right" style={{ width: "10px" }}>
                                                                        <UncontrolledDropdown className='btn-group'>
                                                                            <DropdownToggle className='btn-shadow btn btn-info'>
                                                                                <i className="fas fa-bars"></i>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                                {true && (
                                                                                    <DropdownItem
                                                                                        onClick={() => this.editStrategic(data)}
                                                                                        className="dropdown-item"
                                                                                    >
                                                                                        Editar
                                                                                    </DropdownItem>
                                                                                )}

                                                                                {true && (
                                                                                    <DropdownItem
                                                                                        onClick={() => this.delete(data.id)}
                                                                                        className="dropdown-item"
                                                                                    >
                                                                                        Eliminar
                                                                                    </DropdownItem>
                                                                                )}
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="card-footer">
                                                    <button
                                                        className="btn"
                                                        style={{ color: "#ffff", backgroundColor: this.getColorObjetives(objetivo) }}
                                                        onClick={() => this.toogleStrategic("new", objetivo)}
                                                    >
                                                        Añadir {objetivo}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <div className="col-md-12">
                                <div className="row">
                                    {this.props.dataStrategic.map((data, index) => (
                                        <div className={`col-md-12 ${index > 0 ? "mt-3" : ""}`}>
                                            <div className="card">
                                                <div className="card-header">
                                                    <b className="mr-2" style={{ color: this.getColorObjetives(data.strategic_objective_type) }}>{this.getStrategiName(data.strategic_objective_type)}</b>
                                                    {data.category_indicator != null && (
                                                        <b>{data.category_indicator.name ? " (":  ""} {data.category_indicator.name } {data.category_indicator.name ? ")" :  ""}</b>
                                                    )}
                                                </div>

                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <p style={{ fontSize: "20px" }}>{data.name}</p>


                                                            {data.company_objetives.length >= 1 && (
                                                                <React.Fragment>
                                                                    <hr />
                                                                    <p className="mb-2"><strong>Objetivos a los que apunta</strong></p>

                                                                    <ul>

                                                                        {data.company_objetives.map(obj => (
                                                                            <li>{
                                                                                obj.name
                                                                            }</li>
                                                                        ))}

                                                                    </ul>
                                                                </React.Fragment>
                                                            )}

                                                            <hr />
                                                            {data.factors.length >= 1 && (
                                                                <React.Fragment>
                                                                    {this.getDataState(data)}
                                                                </React.Fragment>
                                                            )}
                                                            <hr />


                                                            {data.indicators.length >= 1 && (
                                                                <React.Fragment>
                                                                    <b><i className="metismenu-icon fas fa-chart-line"></i> Indicadores: </b>
                                                                    {data.indicators.map(indicator => (
                                                                        <span key={indicator.id} className="ml-2 badge badge-dark">
                                                                            <a
                                                                                href={`/indicator/category_indicators/${indicator.category_indicator_token}/indicators/${indicator.token}`}
                                                                                data-turbolinks="true"
                                                                                target="_blank"
                                                                                style={{ color: "#ffff" }}
                                                                            >
                                                                                {indicator.name}
                                                                            </a>
                                                                        </span>
                                                                    ))}
                                                                </React.Fragment>
                                                            )}

                                                        </div>

                                                        <div className="col-md-6 gm-dofa-acciones">
                                                            <p>Acciones</p>
                                                            <i
                                                                id={`PopoverLabels${data.id}`}
                                                                onClick={() => this.setState({ idTask: data.id })}
                                                                className="fas fa-thumbtack float-right icon-add icon-task-popover"
                                                            >

                                                            </i>

                                                            <UncontrolledPopover isOpen={this.state.idTask == data.id} placement="bottom" target={`PopoverLabels${data.id}`} className="popover-column">
                                                                <PopoverHeader>Crear tarea <a className="close-popover" onClick={() => this.setState({ showTask: false, idTask: "", register: {} })}> <i className="fas fa-times"></i></a></PopoverHeader>
                                                                <PopoverBody>
                                                                    <TaskFormCreate
                                                                        numeral_child_score={data.module_task_id}
                                                                        loadTasks={this.props.loadDataStrategic}
                                                                        columns={this.props.columns}
                                                                        users={this.props.users}
                                                                        closeTask={this.closeTask}
                                                                        url_source={this.url_source}
                                                                        task_source_id={this.props.task_source.id}
                                                                    />
                                                                </PopoverBody>
                                                            </UncontrolledPopover>

                                                            <ul>
                                                                {data.tasks.map((task, index) => (
                                                                    <TasksItems
                                                                        task={task}
                                                                        user={this.props.user}
                                                                        users={this.props.users}
                                                                        loadData={this.props.loadDataStrategic}
                                                                        procesos={this.props.procesos}
                                                                        company={this.props.company}
                                                                    />
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>



                        <div className="tab-pane fade" id="tasks" role="tabpanel" aria-labelledby="tasks-tab">
                            <div className="col-md-12">
                                <IndexTaskModule
                                    task_board={this.props.dofa.task_board}

                                    //task props
                                    user={this.props.user}
                                    users={this.props.users}
                                    estados={this.props.estados}
                                    procesos={this.props.procesos}

                                    columns={this.props.columns}
                                    labels={this.props.labels}
                                    tasks={this.props.tasks}
                                    task_sources={this.props.task_sources}
                                    company={this.props.company}
                                    imageUpload={this.props.imageUpload}
                                />
                            </div>
                        </div>

                    </div>
                </div>


            </React.Fragment>
        );
    }
}

export default Show;
