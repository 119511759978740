import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './FormCreate';
import FormCreateCategories from './FormCreateCategories'
import FormCreateSubCategories from './SubModules/FormCreate'
import ModalShowCategory from '../FormatCategories/ModalShowCategory'
import Preloader from '../../../GeneralComponents/LoadingComponen'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Scroll from 'react-scroll';

import FormCreateCustomView from './FormatCustomView/FormCreate'

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            modal: false,
            modalCategories: false,
            modalSubCategories: false,
            modalCategoriesShow: false,
            modeEdit: false,
            modeEditSubCategories: false,
            modeEditFormat: false,
            modeEditFormatCustomView: false,

            current_format_category: this.props.current_category,

            current_category: this.props.current_category,
            select_category_list_id: this.props.select_category_list_id,
            data: this.props.default_categories,
            
            title: "",
            formato_id: "",
            format_category: {},
            format_category_state: [],
            category: {},
            format_sub_category_id: "",
            modulo_ids: [],
            created_format: false,

            //format_custom_view
            modalCustomView: false,

            formCreateFormatCustomView: {
                format_id: "",
                name: "",
                format_category_id: "",
                icon_id: "",
            },

            selectedOptionFormat: {
                format_id: "",
                label: "Formatos"
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionFormatCategory: {
                format_category_id: "",
                label: "Categoría"
            },


            selectedOptionSubCategory: {
                format_sub_category_id: "",
                label: "Sub Módulo"
            },

            form: {
                name: "",
                icon_id: "",
                format_category_id: "",
                is_parent: false,
                show_index: true,
                position_ids: [],
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: "",
                format_sub_category_id: "",
            },

            formCreateSubCategories: {
                name: "",
                format_category_id: "",
                position_ids: [],
            },

            checkedItems: new Map(),
            oldcheckedItems: new Map(),

            checkboxes: [],
            dataProcesosEdit: [],
            cargos: [],
            sub_categories: [],
            modulos: [],

            categories: [],
            format_sub_categories: [],
            dataList: [],
            formatos: [],
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            id: "",
            created_format: false,
            modeEdit: false,
            modeEditFormat: false,
            modeEditSubCategories: false,
            modulo_ids: [],
            modulos: [],
            format_custom_view_id: "",

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionFormatCategory: {
                format_category_id: "",
                label: "Categoría"
            },

            selectedOptionSubCategory: {
                format_sub_category_id: "",
                label: "Sub Módulo"
            },

            form: {
                name: "",
                format_category_id: "",
                is_parent: false,
                position_ids: [],
            },

            formCreateFormat: {
                name: "",
                formats_permission_ids: [],
                format_category_id: "",
                format_sub_category_id: "",
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            checkedItems: new Map(),
            checkboxes: [],
            dataProcesosEdit: [],
            format_sub_categories: [],
            current_format_category: {},
        })
    }

    clearValuesSubCategory = () => {
        this.setState({
            formCreateSubCategories: {
                name: "",
                format_category_id: "",
                position_ids: [],
            },
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    subCategories = (from, category) => {
        if (from == "new") {
            this.setState({
                formCreateSubCategories: {
                    ...this.state.formCreateSubCategories,
                    format_category_id: category.id,
                },

                modalSubCategories: true,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        } else {
            this.clearValuesSubCategory();
            this.setState({
                modalSubCategories: false,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        }
    }

    toogleCategories = (from, state) => {
        if (from == "new") {
            this.setState({
                modalCategories: true,
                form: {
                    ...this.state.form,
                    show_index: state,
                    is_parent: state,
                }
            })
        } else {
            this.setState({ modalCategories: false })
            this.clearValues();
        }
    }

    toogleCategoriesShow = (from, format_category) => {
        if (from == "new") {
            this.setState({ modalCategoriesShow: true, format_category: format_category })
        } else {
            this.setState({ modalCategoriesShow: false, format_category: {} })
        }
    }

    componentDidMount = () => {


        Events.scrollEvent.register('begin', function () {
            console.log("begin");
        });
      
        Events.scrollEvent.register('end', function () {
            console.log("end");
        });
      
        scrollSpy.update();
    }

    scrollToTop() {
        scroll.scrollToTop();  
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

















    createColumn = (format_category, status) => {
        fetch(`/formatos/get_format_category_show_left/true/${format_category.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                const format_category_find = data.register;

                if (status) {
                    /*             this.setState({
                                    current_format_category: {},
                                    data: this.state.data 
                                }) */
                } else {

                    if (this.state.current_format_category.id != format_category_find.id && Object.keys(this.state.current_format_category).length != 0) {
                        // el current_format_category es diferente que el actual item en el que le doy click
                        console.log("el current_format_category es diferente que el actual item en el que le doy click");
                        let array = [];
                        this.state.data.filter(value => value.level < format_category_find.level).map(value => {
                            array.push(value);
                        })
                        this.setState({
                            data: array,
                        }, () => {
                            this.setState({
                                current_format_category: format_category_find,
                                data: this.state.current_format_category.id != format_category_find.id ? [...this.state.data, format_category_find] : array,
                            })
                        })
                    } else {
                        console.log("añadiendo nuevo item");
                        this.setState({
                            current_format_category: format_category_find,
                            data: [...this.state.data, format_category_find],
                        })
                    }
                }
            });
    }





    //format_custom_views





    render() {
        const styleIcons = {
            cursor: "pointer",
            color: "#3f69d8",
        }


        const styleHeight = {
            height: 'calc(100vh - 290px)'
        }

        return (
            <React.Fragment>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {/*<li className="breadcrumb-item" key={1}> {this.props.format_category.name}</li>*/}
                        {this.state.data.length >= 1 && (
                            <React.Fragment>
                                {this.state.data.map((format_category, index) => (
                                    <li className="breadcrumb-item" key={format_category.id + 1}>
                                        <a
                                            data-turbolinks="true"
                                        >
                                            {format_category.name}
                                        </a>
                                    </li>
                                ))}
                            </React.Fragment>
                        )}
                    </ol>
                </nav>
                
                <div className="ui-show-formatCategories-contianer-scroll-notOverflow">
                {!this.props.isLoaded ? (
                    <React.Fragment>
                        <div className="row">
                        <div className='ui-show-formatCategories-container-columns-scroll' style={{width: '100%'}}>


                            {this.state.data.map((format_category, index) => (
                                <div className={`col-md-3 ${index == 0 ? "pl-0" : ""}`} key={format_category.id}>
                                    <div className="card card-height-navigation">
                                        <div className="card-body pb-0 p-2" id={`containerElement${format_category.id}`} style={{ background: "#ffffff" }}>
                                            <div className="scroll-area-lg scroll-format-categories" style={styleHeight}> 
                                                <div className="scrollbar-container">
                                                    {format_category.categories.length >= 1 || format_category.formatos.filter(formatFilter => formatFilter.show == true).length >= 1 || format_category.format_custom_views.filter(formatFilter => formatFilter.show == true).length >= 1  ? (
                                                        <React.Fragment>
                                                            <Element name={`up${format_category.id}`} />
                                                                {(format_category.categories.length >= 1 && format_category.show_all || format_category.show_categories) && (
                                                                    <React.Fragment>
                                                                        <ul className="list-group">
                                                                            {format_category.categories.map((category, index) => (
                                                                                <li className={`list-group-item justify-content-between align-items-center mb-1 ${this.state.data.filter((e) => e.id == category.id).length > 0 ? "list-group-item-primary" : "item-background"} ui-card-form-formatCategoriesIndex -show`} key={category.id} onClick={() => this.createColumn(category, this.state.data.filter((e) => e.id == category.id).length >= 1)}>
                                                                                    <div className="container-form-formatCategoriesIndex">
                                                                                        <i className={`${this.state.data.filter((e) => e.id == category.id).length > 0 ? "fas fa-folder-open" : "fas fa-folder"} mr-3`} style={{ fontSize: "17px" }}></i><div className="ui-text-formatCategoriesIndex-Show">{category.name}</div>
                                                                                    </div>
                                                                                    <i className="fas fa-chevron-right float-right mt-1" style={{ cursor: "pointer" }}></i>
                                                                                </li>
                                                                            ))}
                                                                        </ul>

                                                                        {/* <li className={`link-categories ${this.state.data.filter((e) => e.id == category.id).length > 0 ? "active" : ""}`} onClick={() => this.createColumn(category, this.state.data.filter((e) => e.id == category.id).length >= 1)}>{category.name}</li> */}
                                                                    </React.Fragment>
                                                                )}

                                                                {(format_category.formatos.filter(formatFilter => formatFilter.show == true).length >= 1 && format_category.show_all || format_category.show_formats) && (
                                                                    <React.Fragment>
                                                                        <ul className="list-group">
                                                                            {format_category.formatos.filter(formatFilter => formatFilter.show == true).map((formato, index) => (
                                                                                <li className={`list-group-item justify-content-between align-items-center item-background mb-1 ${index == 0 && !format_category.show_formats && format_category.categories.length >= 1 ? "mt-3" : ""} ui-card-form-formatCategoriesIndex`} key={formato.id} style={{background: "#d3e3ce", cursor:"pointer"}}>
                                                                                    <div className="container-form-formatCategoriesIndex-Show">
                                                                                        <i className={`fas fa-file-alt mr-3`} style={{ fontSize: "17px" }}></i>
                                                                                        <a
                                                                                            href={`/formatos/format_categories/${formato.format_category.token}/formats/${formato.token}/surveys?view=ACTIVE`}
                                                                                            className="mr-2 ui-text-format-form-categoriesIndex-Show"
                                                                                            data-turbolinks="true"
                                                                                        >
                                                                                            {formato.name}
                                                                                        </a>
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </React.Fragment>
                                                                )}

                                                                {(format_category.format_custom_views.filter(formatFilter => formatFilter.show == true).length >= 1 && format_category.show_all || format_category.show_custom_views) && (
                                                                    <React.Fragment>
                                                                        <ul className="list-group">
                                                                            {format_category.format_custom_views.filter(formatFilter => formatFilter.show == true).map((format_custom_view, index) => (
                                                                                <li className={`list-group-item justify-content-between align-items-center item-background mb-1 ${index == 0 && !format_category.show_custom_views && format_category.categories.length >= 1 ? "mt-3" : ""} ui-card-view-formatCategoriesIndex`} key={format_custom_view.id}>
                                                                                    <div className="container-view-formatCategoriesIndex-Show">                                                                 
                                                                                        <i className={`${format_custom_view.icon ? format_custom_view.icon.html_name : "far fa-list-alt"} mr-3`} style={{ fontSize: "17px" }}></i>
                                                                                        <a
                                                                                            href={`/formatos/${format_custom_view.type_view == "custom_view" ? "custom_view" : "custom_dashboard"}/${format_custom_view.id}`}
                                                                                            className="mr-2 ui-text-view-formatCategoriesIndex-Show"
                                                                                            data-turbolinks="true"
                                                                                            
                                                                                        >
                                                                                            {format_custom_view.name}
                                                                                        </a>
                                                                                    </div>

         
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </React.Fragment>
                                                                )}
                                                            <Element name={`down${format_category.id}`} />
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <div className="text-center ui-formatCategoriesIndex-messageNoRecordContainer">
                                                                <div className="text-center">
                                                                    <p className='ui-formatCategoriesIndex-messageNoRecord' style={{marginLeft: '5px'}}>Aún no tienes habilitados los permisos para acceder a este módulo</p>
                                                                    <img className='ui-formatCategoriesIndex-moduleImage-messageNoRecord' src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/6/noRecord_fordata.jpg" alt=""/>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                        </div>
                    </React.Fragment>
                ) : (
                        <div className="card">
                            <div className="card-body">
                                <Preloader />
                            </div>
                        </div>
                )}
                </div>

            </React.Fragment>
        );
    }
}

export default Index;
