import React, { useState, useEffect } from 'react';
import Select from "react-select";
import Preloader from '../../../../GeneralComponents/LoadingComponen';

const FormConfigurationValue = (props) => {
    const [form, setForm] = useState({ question_to_set_id: (props.questionMatrix.question_to_set ? props.questionMatrix.question_to_set.id : ""), value_to_set: (props.questionMatrix.value_to_set ? props.questionMatrix.value_to_set : "") });
    const [questions, setQuestions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({ question_to_set_id: (props.questionMatrix.question_to_set ? props.questionMatrix.question_to_set.id : ""), label: (props.questionMatrix.question_to_set ? props.questionMatrix.question_to_set.question : "Seleccione pregunta") });
    const [isLoaded, setIsLoaded] = useState(true);
    const token = document.querySelector("[name='csrf-token']").content;

    useEffect(() => {
        loadQuestion();
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
        let formUpdate = { value_to_set: e.target.value }
        handleClickUpdate(formUpdate)
    }

    const handleChangeAutocomplete = selectedOption => {
        setSelectedOption(selectedOption)
        let formUpdate = { question_to_set_id: selectedOption.value }
        handleClickUpdate(formUpdate)
    }

    const loadQuestion = () => {
        fetch(`/formatos/get_questions/${props.question.format_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            let array = [];

            data.register.map((item) => (
                array.push({ label: item.question, value: item.id })
            ))

            setQuestions(array);
            setIsLoaded(false);
        });
    }

    const handleClickUpdate = (formUpdate) => {
        fetch(`/formatos/question_matrices/${props.questionMatrix.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            props.updateQuestionMatrix(props.question.step_id, props.question.id, data.register);
        });
    }

    return (
        <React.Fragment>
                <form onSubmit={handleSubmit}>
                    <div className='row mt-3' style={{display: 'flex'}}>
                        <div className={`col-md-3`}>
                            {!isLoaded ? (
                                <React.Fragment>
                                    {props.questionMatrix.columns.length <= 4 && (
                                        <label>Pregunta</label>
                                    )}
                                    
                                    <input
                                        type="hidden"
                                        name="question_to_set_id"
                                        value={selectedOption.question_to_set_id}
                                    />

                                    <Select
                                        onChange={handleChangeAutocomplete}
                                        options={questions}
                                        autoFocus={false}
                                        className={`link-form`}
                                        value={selectedOption}
                                    />

                                    {props.questionMatrix.columns.length == 5 && (
                                        <label>Pregunta</label>
                                    )}
                                </React.Fragment>
                            ) : (
                                <Preloader/>
                            )}
                        </div>

                        <div className={`col-md-3`}>
                            {props.questionMatrix.columns.length <= 4 && (
                                <label>Valor</label>
                            )}
                            <select 
                                name="value_to_set"
                                className={`form form-control`}
                                value={form.value_to_set}
                                onChange={handleChange}
                            >
                                <option value="">Seleccione una opcion</option>
                                <option value="sum">Sum</option>
                                <option value="max">Max</option>
                                <option value="min">Min</option>
                            </select>
                            

                            {props.questionMatrix.columns.length == 5 && (
                                <label>Valor</label>
                            )}
                        </div>       
                    </div>
                </form>
        </React.Fragment>
    );
}


export default FormConfigurationValue;

