import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CustomAutocomplete from '../../../GeneralComponents/CustomAutocomplete';
import NumberFormat from 'react-number-format';
import Ckeditor from '../../formatos/Surveys/Ckeditor';
import Dropzone from "react-dropzone";
import Select from "react-select";
import SignaturePad from 'react-signature-canvas'
import { isMobile } from 'react-device-detect';
import TimeField from "react-simple-timefield";
import ReactHtmlParser from 'react-html-parser';
import CreatableSelect from 'react-select/creatable';
import LoadingComponen from '../../../GeneralComponents/LoadingComponen';
import PreloaderMini from '../../../GeneralComponents/Preloaders';
import FroalaEditor from 'react-froala-wysiwyg';

class ModalSubForm extends Component {
    constructor(props) {
        super(props);
        this.sigPad = {}
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            sigPad: [],
            data: [],
            isLoaded: true,
            isLoadedCreate: false,
            countMax: 0,
            count: 1,
            completed: false,
            survey_answer_id: "",
            nameFile: "",
            arrayHidden: this.props.hide_show_ids,
            errorUploadFile: {
                question_id: "",
                message: ""
            },

            formUpdateRepy1: {

            },
            formUpdateRepy2: {

            },

            selectCustomAutocomplete: {
                name: "",
                label: "Buscar de registro",
            },
            formatValues: {

            },

            selectedOptionCe10: {
                ce_10: "",
                label: "Buscar de registro",
            },

            ce_10s: [],
            question_id: "",

            //GER VALUES TO ANSWER TYPE FORMAT
            format_field_loading: false,
            format_field_current: false,
            formatValuesFormat: {

            },



        }
    }



    //********************* GET VALUES FROM OTHER FORMATS TO AUTOCOMPLET ANSERS TYPE FORMAT ID  KEY=FORMATVALUES *********************/


    // UPDATE VALUES WHEN A VALUE DEPEND FRON OTHER VALUE ////
    updateDynamicQuestions = (data) => {
        console.log(data, "holaaaaaaaa");
        if (data.survey_relation_state) {
            data.survey_relation_parent.map((value) => {
                this.setState({
                    formatValues: {
                        ...this.state.formatValues,
                        [value.id]: [value.format_id, value.survey_relation]
                    },
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [value.id]: { ...this.state.formUpdateRepy1[value.id], value: [{ label: "Seleccione una opción", value: "bw-na" }] }
                    }
                })
            })
        }



        if (data.data.length > 0) {
            data.data.map((value) => {
                console.log(value.question)
                console.log(value.value)
                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [value.question]: { ...this.state.formUpdateRepy1[value.question], value: [value.value] }
                    }
                })
            })
        }

    }

    updateDynamicQuestionsLoad = (data) => {
        console.log(data, "holaaaaaaaa");
        if (data.survey_relation_state) {
            data.survey_relation_parent.map((value) => {
                this.setState({
                    formatValues: {
                        ...this.state.formatValues,
                        [value.id]: [value.format_id, value.survey_relation]
                    },

                })
            })
        }
    }

    getFilterFormatValues = (question_id, val) => {
        fetch(`/formatos/get_filter_format_values/${question_id}/${val}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                console.log(data, "hola mundoalli")
                this.updateDynamicQuestionsLoad(data);
                // SI ES EL ULTIMO STEP REDIRECCIONA A EL INDEX O AL LA VISTA DEPENTDIENDO DE DONDE VENGA
            });
    }




    // GET FORMAT VALUES WHEN IS A INPUT
    handleChangeAutocompleteSelectInput = (selectedOption, question) => {
        var selectedOptionGlobal = selectedOption;


        this.setState({
            formUpdateRepy2: {
                ...this.state.formUpdateRepy2,
                [question.id]: { ...this.state.formUpdateRepy2[question.id], value: [{ label: selectedOptionGlobal.target.value }] }
            }
        }, () => {

            if (this.state.formUpdateRepy2[question.id].value[0].label.length >= question.decimal_count_show) {

                let format_filter = []
                if (this.state.formatValues[question.id]) {
                    format_filter = this.state.formatValues[question.id]
                } else {
                    format_filter = [0, 0]
                }

                fetch(`/formatos/get_formats_values_format/${question.id}/${this.state.formUpdateRepy2[question.id].value[0].label}/${format_filter[0]}/${format_filter[1]}`, {
                    method: 'GET', // or 'PUT'
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.setState({
                            formatValuesFormat: {
                                ...this.state.formatValuesFormat,
                                [question.id]: data,

                            },

                        }, (selectedOptionGlobal) => {
                            console.log("hla afads")
                            let formatValues = this.state.formatValuesFormat[question.id] ? this.state.formatValuesFormat[question.id].data : []
                            let val = this.state.formUpdateRepy2[question.id].value[0].label;
                            let totalvalues = formatValues.filter(value => value.label == val && val).length
                            if (totalvalues > 0) {
                                let valuelast = formatValues.find(value => value.label == val)
                                let value = this.state.formUpdateRepy1[question.id]
                                value["value"] = [valuelast]

                                this.setState({
                                    formUpdateRepy1: {
                                        ...this.state.formUpdateRepy1,
                                        [question.id]: value
                                    }
                                }, () => {
                                    if (question.question_type == "format_id") {
                                        this.updateValuesFormat("format", question.id);
                                    }

                                })
                            }
                        })
                        console.log(data)
                    });
            }
        })


    };




    /// GET FORMAT VALUES WHEN WHIRETE MORE THAN 3 LETTERS ON SELECT FORMAT_ID
    handleInputChangeSelect = (value, e) => {
        if (value.length >= 3) {
            this.getFormatsValuesFormat(value, e.id)
        }
        console.log(value)
        console.log(e)
    }

    // GET FORMAT VALUES WHEN CLICK ON SELECT FORMAT_ID
    handleInputOnFocusSelect = (value, e) => {


        this.getFormatsValuesFormat("bw-blank", e.id)
        console.log(value, "value")
        console.log(e, "e")
    }


    // METHOD TO GET VALUES ON SELECT FORMAT_ID
    getFormatsValuesFormat = (value, question_id) => {

        // WHEN IS SEARCHING IT SHOW A LOADING
        this.setState({
            format_field_current: question_id,
            format_field_loading: true,
        })


        let format_filter = []
        if (this.state.formatValues[question_id]) {
            format_filter = this.state.formatValues[question_id]
        } else {
            format_filter = [0, 0]
        }



        fetch(`/formatos/get_formats_values_format/${question_id}/${value}/${format_filter[0]}/${format_filter[1]}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    format_field_current: 0,
                    format_field_loading: false,
                    formatValuesFormat: {
                        ...this.state.formatValuesFormat,
                        [question_id]: data,

                    }
                })
                console.log(data)
            });
    }


    //*********************END GET VALUES FROM OTHER FORMATS TO AUTOCOMPLET ANSERS TYPE FORMAT ID  KEY=FORMATVALUES *********************/





    componentDidMount = () => {
        console.log("hola")
        this.setState({
            isLoaded: false,
            formUpdateRepy1: this.props.dataAnswer,
        })


        let array = [];
        let arrayCargos = [];
        let arrayProcesos = [];

        this.props.users.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.positions.map((item) => (
            arrayCargos.push({ label: item.name, value: item.id })
        ))

        this.props.procesos.map((item) => (
            arrayProcesos.push({ label: item.name, value: item.id })
        ))

        this.setState({
            options: array,
            cargos: arrayCargos,
            procesos: arrayProcesos,
        })



    }



    componentWillReceiveProps = (nexProps) => {
        console.log(nexProps, "hola mundo")
        this.setState({
            formUpdateRepy1: nexProps.dataAnswer,
            arrayHidden: nexProps.hide_show_ids
        })



        Object.entries(nexProps.dataAnswer)
            .filter(([key, value]) => value.type === 'format_id')
            .forEach(([key, value]) => {
                console.log("key", key)
                console.log("fad", value)
                let value_final = ""
                value_final = value.value[0] ? value.value[0].value : "";
                this.getFilterFormatValues(key, value_final);
            });

        /*       nexProps.data_all.filter(value => value.question_type == "answer_signature").map((item) => {
                  console.log("jejejesiseñores")
                  this.setState({
                      sigPad: {
                          ...this.state.sigPad,
                          [item.id]: {}
                      }
                  })
      
              })
       */

    }

    clear = (question) => {
        this.state.sigPad[question.id].clear()
    }

    trim = (question) => {
        const value = this.state.sigPad[question.id].getTrimmedCanvas().toDataURL('image/png')
        this.setState({
            trimmedDataURL: value,
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: { value: [value], type: "answer_signature", step: this.state.formUpdateRepy1[question.id].step, is_mandatory: false, is_active: true }
            },
        })
    }

    /*     getFormatsValues = (datas) => {
            fetch(`/formatos/get_formats_values/${datas}`, {
                method: 'GET', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        formatValues: {
                            ...this.state.formatValues,
                            [datas]: data
                        }
                    })
                });
        }
    
        loadValues = () => {
    
            this.props.data.filter(value => value.question_type == "format_id").map((question, index) => {
                this.getFormatsValues(question.id)
            })
        } */



    updateCount = (count) => {
        this.setState({
            count: count
        })
    }

    changevalue = (target) => {
        if (target.dataset.type == "answer_integer" || target.dataset.type == "answer_radio" || target.dataset.type == "answer_select") {
            //this.hasFormule(target.value);
        }
    }

    setValueSelect = (e, survey_answer) => {
        const { target } = e;
        var name = survey_answer.id
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [name]: { value: [e.target.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[name].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);
            this.hideSHowQuestions(name, target.value)
        })
        /* 
                setTimeout(() => {
                    this.updateRepy()
                }, 1000); */
    }


    hideSHowQuestions = (question_id, value) => {
        fetch(`/formatos/get_hide_show_questions/${question_id}/${value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    arrayHidden: { ...this.state.arrayHidden, ...data.ids },
                })
            });
    }


    HandleChangeRepyRadio = (e, id) => {
        const { target } = e;
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [e.target.value, ""], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            },
        }, () => {
            this.hideSHowQuestions(id, target.value)
            //this.changevalue(target);

        });
    }


    HandleChangeRepy = (e) => {
        const { target } = e;

        console.log("------------------------ haasdasd")
        console.log(e.target.value)

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [e.target.value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);
        });
    }

    HandleChangeCkaditor = (e, question) => {
        let val = []
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: { value: [e], type: question.question_type, step: this.state.formUpdateRepy1[question.id].step, is_mandatory: question.is_mandatory, is_active: question.is_active }
            }
        })
    }

    HandleChangeRepyNumber = (e) => {
        const { target } = e;
        const value = e.target.value.replace("$", "").replace(",", "").replace(",", "").replace(",", "").replace(",", "")

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [e.target.dataset.question]: { value: [value], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
            }
        }, () => {
            this.changevalue(target);

        });
    }

    handleChangeCustomAutocompleteMultiple = (selectCustomAutocomplete, question) => {
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []



            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }

    handleChangeCustomAutocompleteMultipleSelect = (selectCustomAutocomplete, question) => {
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []
            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]
            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }



    handleChangeAutocompleteSelect = (selectedOption, question) => {

        //this.updateValuesFormat("basic_field");
        console.log(question)
        console.log("afasddsasdf")
        let value = this.state.formUpdateRepy1[question.id]
        value["value"] = [selectedOption]

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: value
            }
        }, () => {
            if (question.question_type == "format_id") {
                this.updateValuesFormat("format", question.id);
            }

        })
    };



    handleChangeCustomAutocomplete = (selectCustomAutocomplete, question) => {
        let value = this.state.formUpdateRepy1[question]
        value["value"] = [selectCustomAutocomplete]


        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question]: value
            }
        })
    }

    handleChangeCustomAutocompleteSingleFormat = (selectCustomAutocomplete, question) => {
        let value = this.state.formUpdateRepy1[question.id]
        value["value"] = [selectCustomAutocomplete]


        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: value
            }
        })
    }


    handleChangeCustomAutocompleteCargos = (selectCustomAutocomplete, question) => {
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []

            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }

    handleChangeCustomAutocompleteProcesos = (selectCustomAutocomplete, question) => {
        if (selectCustomAutocomplete.length >= 1 && selectCustomAutocomplete != null) {
            let array = []
            let value = this.state.formUpdateRepy1[question]
            value["value"] = [selectCustomAutocomplete]


            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: value
                }
            })

        } else {
            console.log(question)
            console.log("--------")

            if (question != undefined) {

                let value = this.state.formUpdateRepy1[question]
                value["value"] = [[]]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: value
                    }
                })
            }
        }
    }


    getDateTime = (datetime) => {
        let time = new Date(datetime)
        let result = time.getFullYear() + "-" + (time.getMonth() < 10 ? `0${time.getMonth()}` : time.getMonth()) + "-" + (time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()) + "T" + (time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()) + ":" + (time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes())
        return result
    }

    HandleChangeRepyArray = (e, question) => {
        let array = this.state.formUpdateRepy1[e.target.dataset.question].value[0];
        const value = e.target.value != "on" ? e.target.value : ""

        if (e.target.checked) {
            array.push(value)

            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [e.target.dataset.question]: { value: [array], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
                },


            })

        } else {


            this.setState({

                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [e.target.dataset.question]: { value: [array.filter(item => item != e.target.value)], type: e.target.dataset.type, step: this.state.formUpdateRepy1[e.target.dataset.question].step, is_mandatory: e.target.dataset.mandatory, is_active: e.target.dataset.active }
                },
            })

        }
    }


    HandleChangeFile = (archivo, id, is_mandatory, is_active, question) => {
        question = question.id;

        if (archivo[0].size >= 11485760) {
            this.setState({
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question]: { value: [{ name: undefined, value: undefined }], type: "answer_file", step: this.state.formUpdateRepy1[question].step, is_mandatory: is_mandatory, is_active: is_active }
                },

                errorUploadFile: {
                    question_id: question,
                    message: "El archivo excede el límite de 10 MB permitido. Te recomendamos utilizar una pregunta de tipo link para este tipo de archivos"
                }
            })
        } else {
            archivo.map(file => (
                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question]: { value: [file], type: "answer_file", step: this.state.formUpdateRepy1[question].step, is_mandatory: is_mandatory, is_active: is_active }
                    },

                    errorUploadFile: {
                        question_id: "",
                        message: ""
                    },

                    survey_answer_id: id,
                    nameFile: file.path
                })
            ));
        }
    }

    validateEmail(email) {
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        console.log(emailRegex.test(email))
        //Se muestra un texto a modo de ejemplo, luego va a ser un icono
        if (emailRegex.test(email)) {
            console.log(emailRegex.test(email))
            return true
        } else {
            return false
        }
    }

    updateValues = () => {
        const formData = new FormData();
        let dataArray = [];

        let state = true;
        // SETEA EN FALSE EL ERROR STATE
        this.setState({
            errorValues: false,
        })
        //RECORRE CADA UNO DE LAS PREGUNTAS PARA VALIDAR QUE SEA DEL MISMO STEP ACTUAL Y SI EL CAMPO ES OBLIGATORIA Y LE PREGUNTA ESTA VACIA NO DEJA SEGUIR 
        Object.keys(this.state.formUpdateRepy1).forEach(key => {
            if ((this.state.formUpdateRepy1[key].is_mandatory == "true" || this.state.formUpdateRepy1[key].is_mandatory == true) && (this.state.formUpdateRepy1[key].value[0] === "" || this.state.formUpdateRepy1[key].value[0] == null || this.state.formUpdateRepy1[key].value[0].length == 0 || (this.state.formUpdateRepy1[key].type == "answer_file" ? this.state.formUpdateRepy1[key].value[0].name == undefined : false))) {
                state = false
            }
            if (this.state.formUpdateRepy1[key].type == "email") {

                if (this.state.formUpdateRepy1[key].value.length > 0 && (this.state.formUpdateRepy1[key].is_mandatory == "true" || this.state.formUpdateRepy1[key].is_mandatory == true)) {
                    state = this.validateEmail(this.state.formUpdateRepy1[key].value[0]) && this.state.formUpdateRepy1[key].value[0] != ""

                }

            }
        });
        if (state) {
            Object.keys(this.state.formUpdateRepy1).forEach(key => {


                var rt = this.state.formUpdateRepy1;
                var v1 = this.state.formUpdateRepy1[key].step;
                var v2 = this.state.nexStep;
                dataArray.push(this.state.formUpdateRepy1[key].type)
            });

            Object.keys(this.state.formUpdateRepy1).forEach(key => {
                if (this.state.formUpdateRepy1[key].type) {
                    if (this.state.formUpdateRepy1[key].type.includes("answer_user_id") || this.state.formUpdateRepy1[key].type.includes("proceso_id") || this.state.formUpdateRepy1[key].type.includes("position_id") || this.state.formUpdateRepy1[key].type.includes("format_id")) {
                        let array = this.state.formUpdateRepy1[key].value.length > 0 ? this.state.formUpdateRepy1[key].value[0] : []
                        let final_array = []

                        if (array.length >= 1) {
                            array.map((value) => {
                                final_array.push(value.value)
                            })
                        } else {
                            final_array.push(array.value)
                        }
                        formData.append(key, final_array);
                    } else {
                        let array = this.state.formUpdateRepy1[key].value.length > 0 ? this.state.formUpdateRepy1[key].value[0] : []
                        let final_array = []
                        final_array.push(array)
                        formData.append(key, array);
                    }
                }

            });

            this.setState({ isLoadedCreate: true })

            fetch(`/formatos/update_ansewers/${this.props.surveySubform.id}/0/${this.props.data[0].step_id}`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.toggle();
                    this.props.loadData();
                    this.props.changeSubFormValid();
                    this.setState({
                        isLoadedCreate: false
                    })
                    /*                 if ((this.state.nexStep + 1) == this.state.maxStep) {
                                        this.redirectSurvey()
                                    } else {
                                        window.scrollTo(0, 0)
                                        this.setState((prevState, props) => ({
                                            errorValues: true,
                                            completed: false
                                        }));
                                    } */
                });

        } else {
            this.setState({
                completed: true
            })
        }
    }

    updateValuesFormat = (type, question_id = 0) => {
        const formData = new FormData();
        let dataArray = [];

        Object.keys(this.state.formUpdateRepy1).forEach(key => {


            var rt = this.state.formUpdateRepy1;
            var v1 = this.state.formUpdateRepy1[key].step;
            var v2 = this.state.nexStep;
            dataArray.push(this.state.formUpdateRepy1[key].type)
        });

        Object.keys(this.state.formUpdateRepy1).forEach(key => {
            if (this.state.formUpdateRepy1[key].type.includes("answer_user_id") || this.state.formUpdateRepy1[key].type.includes("proceso_id") || this.state.formUpdateRepy1[key].type.includes("position_id") || this.state.formUpdateRepy1[key].type.includes("format_id")) {
                let array = this.state.formUpdateRepy1[key].value.length > 0 ? this.state.formUpdateRepy1[key].value[0] : []
                let final_array = []

                if (array.length >= 1) {
                    array.map((value) => {
                        final_array.push(value.value)
                    })
                } else {
                    final_array.push(array.value)
                }
                formData.append(key, final_array);
            } else {
                let array = this.state.formUpdateRepy1[key].value.length > 0 ? this.state.formUpdateRepy1[key].value[0] : []
                let final_array = []
                final_array.push(array)
                formData.append(key, array);
            }

        });




        if (type == "format") {


            fetch(`/formatos/update_ansewers_format/${this.props.surveySubform.id}/${type}?question_id=${question_id}`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    console.log(data)
                    this.updateDynamicQuestions(data);
                    // SI ES EL ULTIMO STEP REDIRECCIONA A EL INDEX O AL LA VISTA DEPENTDIENDO DE DONDE VENGA
                });
        }
        else if (type == "integer") {
            fetch(`/formatos/update_ansewers_integer/${this.props.surveySubform.id}/${type}`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateDynamicQuestions(data);
                    // SI ES EL ULTIMO STEP REDIRECCIONA A EL INDEX O AL LA VISTA DEPENTDIENDO DE DONDE VENGA
                });
        }
        else {
            fetch(`/formatos/update_ansewers_subform/${this.props.surveySubform.id}/${type}`, {
                method: 'PATCH', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {}
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateDynamicQuestions(data);
                    // SI ES EL ULTIMO STEP REDIRECCIONA A EL INDEX O AL LA VISTA DEPENTDIENDO DE DONDE VENGA
                });
        }
    }
    /* 
        updateDynamicQuestions = (data) => {
            console.log(data, "hola");
            if (data.survey_relation_state) {
                data.survey_relation_parent.map((value) => {
                    this.setState({
                        formatValues: {
                            ...this.state.formatValues,
                            [value.id]: { data: value.data }
                        }
                    })
                })
            }
            data.data.map((value) => {
                console.log(value.question)
                console.log(value.value)
                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [value.question]: { ...this.state.formUpdateRepy1[value.question], value: [value.value] }
                    }
                })
            })
        } */

    clearValue = (question) => {

        //this.updateValuesFormat("basic_field");
        console.log(question.id)
        console.log("clear", this.state.formUpdateRepy1[question.id].value)
        let value = this.state.formUpdateRepy1[question.id]
        let value_final = [{ label: "seleccione una opción", value: `${value.value[0].value}_delete` }]
        value["value"] = [{ label: 'Seleccione una opción', value: `${this.state.formUpdateRepy1[question.id].value[0].value}_delete` }]
        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: value
            }
        }, () => {
            if (question.question_type == "format_id") {
                this.updateValuesFormat("format", question.id);
            }

        })
    }


    updateStateCe10 = (question) => {
        let value = this.state.formUpdateRepy1[question.id]
        value["is_ce10"] = !value["is_ce10"]

        this.setState({
            formUpdateRepy1: {
                ...this.state.formUpdateRepy1,
                [question.id]: value
            }
        })
    }

    handleChangeAutocompleteCe10 = (question, selectedOptionCe10) => {
        if (selectedOptionCe10.__isNew__ && selectedOptionCe10.label.length >= 3) {
            const form = { search: selectedOptionCe10.value }
            let value = this.state.formUpdateRepy1[question.id]
            value["is_ce10"] = true

            this.setState({
                question_id: question.id,
                formUpdateRepy1: {
                    ...this.state.formUpdateRepy1,
                    [question.id]: value
                },
            })

            fetch(`/settings/search_health_codes`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    console.log("hola mundo")
                    let value = this.state.formUpdateRepy1[question.id]
                    value["is_ce10"] = false
                    value["value"] = [selectedOptionCe10.label]

                    this.setState({
                        formUpdateRepy1: {
                            ...this.state.formUpdateRepy1,
                            [question.id]: value
                        },

                        ce_10s: data.data
                    })
                });
        } else {
            console.log("test")
            if (this.state.ce_10s.length >= 1 && this.state.question_id == question.id) {
                let value = this.state.formUpdateRepy1[question.id]
                value["value"] = [selectedOptionCe10.label]

                this.setState({
                    formUpdateRepy1: {
                        ...this.state.formUpdateRepy1,
                        [question.id]: value
                    },
                })
            }
        }
    }



    getTypeQuestion = (question) => {
        if (question.question_type == "answer_string") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        name="answer_string"
                        className="form form-control imput-options-disabled"
                        data-id={question.id}
                        data-question={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                        disabled={!question.is_enable}
                    //disabled={this.props.survey.state}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_integer") {

            return (
                <React.Fragment>
                    {question.is_currency ? (
                        <NumberFormat
                            name="answer_integer"
                            thousandSeparator={true}
                            prefix={'$'}
                            className={`form form-control`}
                            disabled={!question.is_enable}
                            data-id={question.id}
                            data-question={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            onBlur={() => this.updateValuesFormat("integer")}
                            value={this.state.formUpdateRepy1[question.id].value.toString()}
                            onChange={this.HandleChangeRepyNumber}

                        />

                    ) : (

                        <input
                            type="number"
                            className="form form-control imput-options-disabled"
                            name="answer_integer"
                            data-id={question.id}
                            disabled={!question.is_enable}
                            data-question={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            onBlur={() => this.updateValuesFormat("integer")}
/*                                 disabled={this.props.survey.state}
 */                             value={this.state.formUpdateRepy1[question.id].value}
                            onChange={this.HandleChangeRepy}
                        />
                    )}
                </React.Fragment>
            )

        } else if (question.question_type == "code_format") {
            console.log("sñladjflfdjfljalñsfjkfkldsajfdlsjflsffaslñdj")
            return (
                <React.Fragment>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        name="answer_string"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder="El código se generará automáticamente cuando guardes la información"
                        disabled={true}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_user_id") {

            if (question.is_multiple) {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="answer_user_id"
                            value={this.state.formUpdateRepy1[question.id].value}
                        />

                        <Select
                            onChange={(e) => this.handleChangeCustomAutocompleteMultipleSelect(e, question.id)}
                            options={this.state.options}
                            isMulti
                            closeMenuOnSelect={false}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una o varias opciones"
                            isDisabled={!question.is_enable}
                            name="position_ids"
                            /*  onBlur={() => this.updateValuesFormat("basic_field")} */
                            defaultValue={[
                                this.state.formUpdateRepy1[question.id].value[0],
                                this.state.formUpdateRepy1[question.id].value[1],
                                this.state.formUpdateRepy1[question.id].value[2],
                                this.state.formUpdateRepy1[question.id].value[3],
                                this.state.formUpdateRepy1[question.id].value[4],
                                this.state.formUpdateRepy1[question.id].value[5]
                            ]}
                        />
                    </React.Fragment>
                )

            } else {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="answer_user_id"
                            value={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0].value : ""}
                        />

                        <Select
                            onChange={(e) => this.handleChangeAutocompleteSelect(e, question.id)}
                            options={this.state.options}
                            isDisabled={!question.is_enable}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una opción"
                            value={this.state.formUpdateRepy1[question.id].value[0]}
                        />
                    </React.Fragment>
                )
            }

        } else if (question.question_type == "position_id") {

            if (question.is_multiple) {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="position_id"
                            value={this.state.formUpdateRepy1[question.id].value}
                        />

                        <Select
                            onChange={(e) => this.handleChangeCustomAutocompleteMultipleSelect(e, question.id)}
                            options={this.state.cargos}
                            isMulti
                            closeMenuOnSelect={false}
                            autoFocus={false}
                            isDisabled={!question.is_enable}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una o varias opciones"
                            /* onBlur={() => this.updateValuesFormat("basic_field")} */

                            name="position_id"
                            defaultValue={[
                                this.state.formUpdateRepy1[question.id].value[0],
                                this.state.formUpdateRepy1[question.id].value[1],
                                this.state.formUpdateRepy1[question.id].value[2],
                                this.state.formUpdateRepy1[question.id].value[3],
                                this.state.formUpdateRepy1[question.id].value[4],
                                this.state.formUpdateRepy1[question.id].value[5]
                            ]}
                        />
                    </React.Fragment>
                )

            } else {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="position_id"
                            value={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0].value : ""}
                        />

                        <Select
                            onChange={(e) => this.handleChangeAutocompleteSelect(e, question.id)}
                            options={this.state.cargos}
                            isDisabled={!question.is_enable}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una opción"
                            /*  onBlur={() => this.updateValuesFormat("basic_field")} */

                            value={this.state.formUpdateRepy1[question.id].value[0]}
                        />
                    </React.Fragment>
                )
            }

        } else if (question.question_type == "proceso_id") {

            if (question.is_multiple) {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="proceso_id"
                            value={this.state.formUpdateRepy1[question.id].value}
                        />

                        <Select
                            onChange={(e) => this.handleChangeCustomAutocompleteMultipleSelect(e, question.id)}
                            options={this.state.procesos}
                            isMulti
                            isDisabled={!question.is_enable}
                            closeMenuOnSelect={false}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una o varias opciones"
                            /*  onBlur={() => this.updateValuesFormat("basic_field")} */


                            name="proceso_id"
                            defaultValue={[
                                this.state.formUpdateRepy1[question.id].value[0],
                                this.state.formUpdateRepy1[question.id].value[1],
                                this.state.formUpdateRepy1[question.id].value[2],
                                this.state.formUpdateRepy1[question.id].value[3],
                                this.state.formUpdateRepy1[question.id].value[4],
                                this.state.formUpdateRepy1[question.id].value[5]
                            ]}
                        />
                    </React.Fragment>
                )

            } else {

                return (
                    <React.Fragment>
                        <input
                            type="hidden"
                            name="proceso_id"
                            value={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0].value : ""}
                        />

                        <Select
                            onChange={(e) => this.handleChangeAutocompleteSelect(e, question.id)}
                            options={this.state.procesos}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            placeholder="Seleccione una opción"
                            isDisabled={!question.is_enable}
                            /*  onBlur={() => this.updateValuesFormat("basic_field")} */

                            value={this.state.formUpdateRepy1[question.id].value[0]}
                        />
                    </React.Fragment>
                )
            }

        }

        else if (question.question_type == "answer_text") {
            return (

                <React.Fragment>
                    {question.is_enable ? (
                        <React.Fragment>
                            <FroalaEditor
                                model={this.state.formUpdateRepy1[question.id].value[0]}
                                config={{
                                    language: 'es',
                                    quickInsertEnabled: false,
                                    colorsHEXInput: true,
                                    autoFocus: true,
                                    toolbartop: true,
                                    linkAlwaysBlank: true,
                                    fontFamilySelection: true,
                                    fontSizeSelection: true,
                                    paragraphFormatSelection: true,
                                    htmlExecuteScripts: true,
                                    key: this.props.key_text,
                                    iframe: true,
                                    tabSpaces: 4,
                                    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'save', 'wordPaste'],
                                    toolbarButtons: {
                                        moreText: {
                                            buttons: ['bold', 'italic', 'underline', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'clearFormatting'],

                                            // Alignment of the group in the toolbar.
                                            align: 'left',

                                            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                                            buttonsVisible: 12
                                        },


                                        moreParagraph: {
                                            buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphStyle', 'lineHeight', 'outdent', 'indent'],
                                            align: 'left',
                                            buttonsVisible: 11
                                        },

                                        // moreRich: {
                                        //     buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                                        //     align: 'left',
                                        //     buttonsVisible: 10
                                        // },

                                        moreMisc: {
                                            buttons: ['undo',
                                                'redo',
                                                //   'fullscreen',
                                                //   'print', 
                                                //   'getPDF', 
                                                //   'spellChecker', 
                                                //   'selectAll', 
                                                //   'html', 
                                                //   'help'
                                            ],
                                            align: 'left',
                                            buttonsVisible: 5
                                        }
                                    }
                                }}
                                question={question}
                                onModelChange={(e) => this.HandleChangeCkaditor(e, question)}
                            /*  onBlur={() => this.updateValuesFormat("basic_field")} */

                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className='answer-text-container'>{ReactHtmlParser(this.state.formUpdateRepy1[question.id].value[0])}</div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )

        } else if (question.question_type == "answer_date") {
            return (
                <React.Fragment>
                    <input
                        type="date"
                        className="form form-control imput-options-disabled"
                        name="answer_date"
                        data-id={question.id}
                        data-question={question.id}

                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.state.formUpdateRepy1[question.id] ? this.state.formUpdateRepy1[question.id].value : ""}
                        onChange={this.HandleChangeRepy}
                        disabled={question.is_currency}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_datetime") {
            return (
                <React.Fragment>

                    <input
                        type="datetime-local"
                        className="form form-control imput-options-disabled"
                        name="answer_time"
                        data-id={question.id}
                        data-question={question.id}

                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        value={this.getDateTime(this.state.formUpdateRepy1[question.id].value)}
                        onChange={this.HandleChangeRepy}
                    //disabled={this.props.survey.state}
                    />

                </React.Fragment>
            )

        } else if (question.question_type == "answer_file") {
            return (
                <React.Fragment>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="mt-3 ui-modalSubForm_input_file__container">
                                {question.is_enable ? (
                                    <React.Fragment>
                                        <Dropzone
                                            onDrop={(files) => this.HandleChangeFile(files, question.id, question.is_mandatory, question.is_active, question)}
                                        //disabled={this.props.survey.state}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div
                                                    {...getRootProps({
                                                        className: 'dropzone ui-modalSubForm_input_file-dropzone',
                                                    })}
                                                >
                                                    <input className='ui-modalSubForm_input_file' {...getInputProps()} />
                                                    <p style={{ color: this.state.formUpdateRepy1[question.id].value[0].name != undefined ? "#0094ff" : "#a5a5a5" }}>{`${(this.state.formUpdateRepy1[question.id].value[0].name != undefined ? this.state.formUpdateRepy1[question.id].value[0].name : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                </div>
                                            )}
                                        </Dropzone>

                                        {(this.state.errorUploadFile.question_id == question.id && this.state.errorUploadFile.message != "") ? (
                                            <div className="alert alert-primary mt-3" role="alert">
                                                <b>{this.state.errorUploadFile.message}</b>
                                            </div>
                                        ) : (
                                            <p className="mb-0 mt-2"><b>Archivo: </b>
                                                <a
                                                    href={this.state.formUpdateRepy1[question.id].value[0].url}
                                                    target="_blanck"
                                                >
                                                    {this.state.formUpdateRepy1[question.id].value[0].name}


                                                </a>
                                            </p>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <div
                                        className="text-center"
                                        style={{ padding: "24px", background: "#f2f2f2" }}
                                    >
                                        <h5>Pregunta inactiva</h5>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (question.question_type == "answer_select") {

            return (
                <select
                    name="answer_select"
                    className="form form-control"
                    onChange={(e) => this.setValueSelect(e, question)}
                    //disabled={this.props.survey.state}
                    data-id={question.id}
                    data-type={question.question_type}
                    data-mandatory={question.is_mandatory}
                    data-active={question.is_active}
                    data-question={question.id}
                    disabled={!question.is_enable}
                    value={this.state.formUpdateRepy1[question.id].value}
                >
                    <option value="">Seleccione una opción</option>
                    {question.options.map((name, index) => (
                        <option value={name.name}>{name.name}</option>
                    ))}

                </select>

            )

        } else if (question.question_type == "answer_localization") {
            this.getGeolocalizacion(question)
            return (
                <div>
                    <input
                        type="text"
                        disabled={false}
                        value={this.state.formUpdateRepy1[question.id].value.toString()}
                        className={`form form-control mt-4`}
                    />
                </div>
            )

        } else if (question.question_type == "answer_autdatetime") {
            return (
                <input
                    type="text"
                    className="form form-control"
                    disabled={true}
                    value={this.state.formUpdateRepy1[question.id].value}
                />
            )

        } else if (question.question_type == "answer_radio") {
            return (
                <div className="row mt-3 ux">
                    {question.align_item == "vertical" ? (
                        question.options.map((name, index) => (
                            <div className="col-md-12 ui-showSurvey-inputRadio-input-vertical-group">
                                <input
                                    id={name.name}
                                    onChange={(e) => this.HandleChangeRepyRadio(e, question.id)}
                                    type="radio"
                                    value={name.name}
                                    data-id={question.id}
                                    data-question={question.id}

                                    data-type={question.question_type}
                                    disabled={this.props.survey.state || !question.is_enable}
                                    data-mandatory={question.is_mandatory}
                                    data-active={question.is_active}
                                    checked={this.state.formUpdateRepy1[question.id].value[0] == name.name ? true : false}
                                    className="mr-2"
                                />
                                <label htmlFor={name.name}> {name.name} </label>
                                {name.name == "Otra.." && (
                                    <input
                                        type="text"
                                        className="imput-options remove-select ml-3"
                                        name=""
                                        style={{ width: "50%" }}
                                        value={this.state.formUpdateRepy1[question.id].value[1]}
                                        onChange={(e) => this.HandleChangeRepyRadioInput(e, question)}
                                    />
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="ui-showSurvey-inputRadioHorizontal">
                            {question.options.map((name, index) => (
                                <div className="ui-showSurvey-inputRadio-input-group group-subform" key={index}>
                                    <label className='ui-showSurvey-inputRadio-label label-subform_input' htmlFor={name.name}> {name.name} </label>
                                    <input
                                        id={name.name}
                                        onChange={(e) => this.HandleChangeRepyRadio(e, question.id)}
                                        type="radio"
                                        value={name.name}
                                        data-id={question.id}
                                        data-question={question.id}

                                        data-type={question.question_type}
                                        disabled={this.props.survey.state || !question.is_enable}
                                        data-mandatory={question.is_mandatory}
                                        data-active={question.is_active}
                                        checked={this.state.formUpdateRepy1[question.id].value[0] == name.name ? true : false}
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                </div>
            )

        } else if (question.question_type == "answer_checkbox") {
            return (
                <div className="row mt-3">
                    {question.align_item == "vertical" ? (
                        question.options.map((name, index) => (
                            <div className="col-md-12 ui-showSurvey-inputcheck-input-vertical-group" key={index}>
                                <input
                                    name="answer_checkbox"
                                    onChange={(e) => this.HandleChangeRepyArray(e, question)}
                                    value={name.name}
                                    data-id={question.id}
                                    data-type={question.question_type}
                                    disabled={this.props.survey.state || !question.is_enable}
                                    data-question={question.id}

                                    data-mandatory={question.is_mandatory}
                                    data-active={question.is_active}
                                    checked={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0].includes(name.name) : this.state.formUpdateRepy1[question.id].value[0]}
                                    type="checkbox"
                                    className="mr-2"
                                />
                                <label htmlFor={name.name}> {name.name}</label>
                            </div>
                        ))
                    ) : (
                        <div className="ui-showSurvey-inputCheckHorizontal">
                            {question.options.map((name, index) => (
                                <div className="ui-showSurvey-inputCheck-input-group group-subform" key={index}>
                                    <input
                                        name="answer_checkbox"
                                        onChange={(e) => this.HandleChangeRepyArray(e, question)}
                                        value={name.name}
                                        data-id={question.id}
                                        data-type={question.question_type}
                                        disabled={this.props.survey.state || !question.is_enable}
                                        data-question={question.id}

                                        data-mandatory={question.is_mandatory}
                                        data-active={question.is_active}
                                        checked={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0].includes(name.name) : this.state.formUpdateRepy1[question.id].value[0]}
                                        type="checkbox"
                                        className="mr-2"
                                    />
                                    <label className='ui-showSurvey-inputCheck-label label-subform_input' htmlFor={name.name}> {name.name}</label>
                                </div>
                            ))}
                        </div>
                    )}

                </div>
            )
        } else if (question.question_type == "answer_formula") {
            return (
                <React.Fragment>
                    {question.is_currency ? (
                        <NumberFormat
                            name="answer_formula"
                            thousandSeparator={true}
                            prefix={'$'}
                            className={`form form-control`}

                            data-id={question.id}
                            data-question={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}

                            disabled={true}
                            value={this.state.formUpdateRepy1[question.id].value.toString()}
                            onChange={this.HandleChangeRepyNumber}

                        />

                    ) : (
                        <input
                            type="text"
                            className="form form-control imput-options-disabled"
                            name="answer_formula"
                            data-id={question.id}
                            data-type={question.question_type}
                            data-mandatory={question.is_mandatory}
                            data-active={question.is_active}
                            disabled={true}
                            value={this.state.formUpdateRepy1[question.id].value}
                            onChange={this.HandleChangeRepy}
                        />
                    )}
                </React.Fragment>
            )

        }
        else if (question.question_type == "format_id") {

            if (question.is_multiple) {

                return (
                    <React.Fragment>
                        {/*                         <input
                            type="hidden"
                            name="formats_id"
                            value={this.state.formUpdateRepy1[question.id].value}
                        />

                        <Select
                            onChange={(e) => this.handleChangeCustomAutocompleteMultipleSelect(e, question.id)}
                            options={this.state.formatValues[question.id] ? this.state.formatValues[question.id].data : ""}
                            isMulti
                            closeMenuOnSelect={false}
                            autoFocus={false}
                            className={`link-form`}
                            classNamePrefix="select"
                            name="formats_id"
                            defaultValue={[
                                this.state.formUpdateRepy1[question.id].value[0],
                                this.state.formUpdateRepy1[question.id].value[1],
                                this.state.formUpdateRepy1[question.id].value[2],
                                this.state.formUpdateRepy1[question.id].value[3],
                                this.state.formUpdateRepy1[question.id].value[4],
                                this.state.formUpdateRepy1[question.id].value[5]
                            ]}
                        /> */}
                    </React.Fragment>
                )

            } else {

                return (

                    <React.Fragment>
                        <span style={{ cursor: "pointer", color: "gray", float: "right", paddingRight: "12px" }} onClick={() => this.clearValue(question)}>Limpiar</span><br />
                        <div className="question-format-id">
                            {(this.state.format_field_current == question.id && this.state.format_field_loading) && (
                                <div className='question-format-id__loading'>  <PreloaderMini /></div>
                            )}

                            <input
                                type="hidden"
                                name="formats_id"
                                value={this.state.formUpdateRepy1[question.id].value[0] ? this.state.formUpdateRepy1[question.id].value[0].value : ""}
                            />

                            <div style={{ display: question.is_currency ? "none" : "", width: "100%" }}>

                                <Select
                                    onChange={(e) => this.handleChangeAutocompleteSelect(e, question)}
                                    onInputChange={(e) => this.handleInputChangeSelect(e, question)}
                                    onFocus={(e) => this.handleInputOnFocusSelect(e, question)}
                                    options={this.state.formatValuesFormat[question.id] ? this.state.formatValuesFormat[question.id].data : []}
                                    autoFocus={false}
                                    className={`link-form`}
                                    placeholder="Seleccione una opción"
                                    isDisabled={!question.is_enable}
                                    value={this.state.formUpdateRepy1[question.id].value[0]}
                                />
                            </div>

                            <input
                                style={{ display: !question.is_currency ? "none" : "" }}
                                type="text"
                                className="form form-control"
                                name="formats_id"
                                onChange={(e) => this.handleChangeAutocompleteSelectInput(e, question)}
                                value={this.state.formUpdateRepy2[question.id] && this.state.formUpdateRepy2[question.id].value[0] ? this.state.formUpdateRepy2[question.id].value[0].label : ""}
                            />
                            {question.add_new && (
                                <div> {/* <i className="fas fa-plus float-right icon-add mr-2 ml-2" onClick={() => this.getSubform(question.id, "fm")}></i> */}</div>
                            )}

                        </div>
                    </React.Fragment>
                )
            }


        } else if (question.question_type == "dynamic_field") {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        className="form form-control imput-options-disabled"
                        name="answer_string"
                        data-question={question.id}
                        data-id={question.id}

                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder="El código se generará automáticamente cuando guardes la información"
                        disabled={true}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "email") {
            return (
                <React.Fragment>
                    <input
                        type="email"
                        className="form form-control imput-options-disabled"
                        name="email"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder=""
                        disabled={!question.is_enable}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "url") {
            return (
                <React.Fragment>
                    <input
                        type="url"
                        className="form form-control imput-options-disabled"
                        name="url"
                        data-question={question.id}
                        data-id={question.id}
                        data-type={question.question_type}
                        data-mandatory={question.is_mandatory}
                        data-active={question.is_active}
                        placeholder=""
                        disabled={!question.is_enable}
                        value={this.state.formUpdateRepy1[question.id].value}
                        onChange={this.HandleChangeRepy}
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "answer_subform") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.getSubform(question.id)}
                            >
                                Nuevo
                            </button>

                            <hr />
                        </div>

                        <div className="col-md-12">
                            <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                <thead>
                                    <tr>
                                        <th>Prueba</th>
                                        <th>Prueba</th>
                                        <th>Prueba</th>
                                        <th>Prueba</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Hola</td>
                                        <td>Hola</td>
                                        <td>Hola</td>
                                        <td>Hola</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else if (question.question_type == "answer_signature") {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${isMobile ? "canvas-container" : ""}`}>
                                <SignaturePad canvasProps={{ className: `sigPad_generate sigPad_generate_${isMobile ? "290" : "505"}`, width: isMobile ? 290 : 505, height: 150 }} ref={(ref) => { this.state.sigPad[question.id] = ref }} />


                            </div>
                        </div>

                        <div className="col-md-12 mt-4">
                            {this.state.formUpdateRepy1[question.id] != undefined && (
                                <React.Fragment>

                                    <img style={{ width: "200px" }} className={"sigImage"} src={this.state.formUpdateRepy1[question.id].value[0]} />
                                </React.Fragment>
                            )}
                        </div>

                        <div className="col-md-12 mt-4">
                            <button className={"btn-shadow btn btn-info mr-2"} onClick={() => this.clear(question)}>
                                <i className="fas fa-trash-alt"></i> Limpiar
                            </button>

                            <button className={"btn-shadow btn btn-info"} onClick={() => this.trim(question)}>
                                <i className="fas fa-camera"></i> Confirmar
                            </button>
                        </div>
                    </div>



                </React.Fragment>
            )
        } else if (question.question_type == "answer_hour") {
            return (
                <React.Fragment>
                    <TimeField
                        onChange={this.HandleChangeRepy}
                        value={this.state.formUpdateRepy1[question.id].value}
                        input={
                            <input
                                type="text"
                                name="answer_hour"
                                className="form-control"
                                data-id={question.id}
                                data-question={question.id}
                                data-type={question.question_type}
                                data-mandatory={question.is_mandatory}
                                data-active={question.is_active}
                                disabled={!question.is_enable}
                                autoComplete="off"

                            />
                        }
                    />
                </React.Fragment>
            )

        } else if (question.question_type == "ce_10") {
            return (
                <React.Fragment>
                    <input
                        type="hidden"
                        name="ce_10"
                        onChange={(e) => console.log(e)}
                        value={this.state.formUpdateRepy1[question.id].value}
                    />

                    <CreatableSelect
                        isClearable
                        onChange={(e) => this.handleChangeAutocompleteCe10(question, e)}
                        options={this.state.question_id == question.id ? this.state.ce_10s : []}
                        autoFocus={false}
                        className={`link-form`}
                        value={{ label: this.state.formUpdateRepy1[question.id].value, value: this.state.formUpdateRepy1[question.id].value }}
                    />

                    {this.state.formUpdateRepy1[question.id].is_ce10 && (
                        <LoadingComponen />
                    )}
                </React.Fragment>
            )
        }
    }

    isDisplayed = question => {

        let display = false
        let display_next = false

        Object.entries(this.state.arrayHidden).forEach(([key, value]) => {
            display_next = value.find(value => value == question.id)
            display = display_next || display ? true : false
        })

        return display

    }


    render() {
        if (this.state.isLoaded) {
            return (
                <p>Cargando..</p>
            )
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>
                    <ModalBody style={{ background: "#f8f9fa" }}>

                        {/*                         <ul className="nav nav-tabs mb-0" id="myTab" role="tablist">
                            {this.props.data.map((step, index) => (
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${this.state.count == (index + 1) ? "active" : ""}`}
                                        onClick={() => { this.updateCount(index + 1) }}
                                        id="home-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="home"
                                        aria-selected="true"
                                    >
                                        {step.name == "Formulario sin título" ? "Sección" : step.name}
                                    </a>
                                </li>
                            ))}
                        </ul> */}



                        {this.props.data.filter(question_filter => question_filter.state_db == true).map((question, index) => (
                            <div style={{ display: this.isDisplayed(question) ? "none" : "block" }}
                                className="card mt-3 border-questions"
                                key={question.id}
                            >
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-10">
                                            <h5>{index + 1}) {question.question}</h5>
                                            <p>{ReactHtmlParser(question.description)}</p>

                                            {question.file.url && (
                                                <div className="mb-3">
                                                    <img src={question.file.url} alt="" className="img-thumbnail" style={{ height: "192px" }} />
                                                </div>
                                            )}

                                        </div>

                                        <div className="col-md-12">
                                            {this.getTypeQuestion(question)}
                                            <div>
                                                {this.state.formUpdateRepy1 != undefined ? ((this.state.formUpdateRepy1[question.id].value[0] == null || this.state.formUpdateRepy1[question.id].value[0].length == 0 || (this.state.formUpdateRepy1[question.id].type == "answer_file" ? this.state.formUpdateRepy1[question.id].value[0].name == undefined : false)) && this.state.errorValues == false && (this.state.formUpdateRepy1[question.id].is_mandatory == "true" || this.state.formUpdateRepy1[question.id].is_mandatory == true) ? <b style={{ color: "#ff0000" }}>Esta pregunta es obligatoria</b> : "") : ""}
                                            </div>
                                            {(question.question_type == "email" && this.state.formUpdateRepy1[question.id].value.length > 0) && (
                                                <div>
                                                    {!this.validateEmail(this.state.formUpdateRepy1[question.id].value[0]) && this.state.formUpdateRepy1[question.id].value[0] != "" && this.state.errorValues == false ? (<b style={{ color: "#ff0000" }}>Email no valido</b>) : ""}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {this.state.completed && (
                            <div className="alert alert-danger mt-3">
                                Hay campos incompletos o invalidos
                            </div>
                        )}
                    </ModalBody>

                    <ModalFooter>

                        <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                        <button className="btn-shadow btn btn-info" disabled={this.state.isLoadedCreate} onClick={() => this.updateValues()}>{this.state.isLoadedCreate ? "Guardando..." : "Guardar"}</button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalSubForm;

