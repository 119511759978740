import React, { Component } from 'react';
import HeaderTitle from "../../../GeneralComponents/HeaderTitle"
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './FormCreate';
import FormCreateSubCategories from '../../formatos/FormatCategories/SubModules/FormCreate'
import Pagination from "react-js-pagination";
import Preloader from '../../../GeneralComponents/LoadingComponen';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            id: "",
            modal: false,
            modalSubCategories: false,
            ErrorValues: true,
            ErrorValuesSubModule: true,
            modeEdit: false,
            modeEditChildren: false,
            modeEditSubCategories: false,
            isLoaded: false,
            title: "",
            sub_category_indicator_id: "",
            formato_id: "",
            category_indicator_id: "",

            form: {
                name: "",
                order_number: "",
                format_id: "",
                section_name: "",
                position_ids: [],
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionFormat: {
                format_id: "",
                label: "Formato"
            },

            selectedOptionTaskBoard: {
                task_board_id: "",
                label: "Plantilla de tarea"
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            formCreateFormat: {
                name: "",
                format_category_id: "",
            },

            formUpdatePositionCategory: {
                position: 0,
            },

            formCreateSubCategories: {
                name: "",
                category_indicator_id: "",
                position_ids: [],
            },

            dataProcesosEdit: [],
            cargos: [],
            formatos: [],
            task_boards: [],
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            id: "",
            modeEdit: false,
            ErrorValues: true,

            form: {
                name: "",
                order_number: "",
                section_name: "",
                format_id: "",
                position_ids: [],
                task_board_id: "",
            },

            formCreateFormat: {
                name: "",
                format_category_id: "",
            },

            selectedOptionTaskBoard: {
                task_board_id: "",
                label: "Plantilla de tarea"
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            formUpdate: {
                name: "",
                section_name: "",
                order_number: "",
            },

            dataProcesosEdit: [],
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true, title: "Nueva categoría" })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    componentDidMount = () => {
        this.defaultValues();
    }

    defaultValues = () => {
        let array = [];
        let arrayFormat = [];
        let arrayTaskBoards = [];

        this.props.positions.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.props.formatos.map((item) => (
            arrayFormat.push({ label: item.name, value: item.id })
        ))

        this.props.task_board_templates.map((item) => (
            arrayTaskBoards.push({ label: item.name, value: item.id })
        ))

        this.setState({
            cargos: array,
            formatos: arrayFormat,
            task_boards: arrayTaskBoards
        })
    }

    handleChangeAutocomplete = selectedOption => {
        let array = []
        
        selectedOption.map((item) => (
            array.push(item.value)
        ))
    
        this.setState({
            form: {
                ...this.state.form,
                position_ids: array
            }
        })
    }

    validationForm = () => {
        if (this.state.form.name != "" &&
            this.state.form.task_board_id != "" 
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    validationFormSubCategory = () => {
        if (this.state.formCreateSubCategories.name != "" &&
            this.state.formCreateSubCategories.position_ids.length >= 1 
        ) {
            this.setState({ ErrorValuesSubModule: true })
            return true
        } else {
            this.setState({ ErrorValuesSubModule: false })
            return false
        }
    }

    HandleClickSubCategory = () => {
        if(this.validationFormSubCategory() == true){
            if (!this.state.modeEditSubCategories)
                fetch(`/indicator/sub_category_indicators`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formCreateSubCategories), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        Turbolinks.visit(`/indicator/category_indicators`);
                        this.clearValuesSubCategory();
                        this.setState({ modalSubCategories: false })
                        this.messageSuccess(data);
                    });
            else {
                fetch(`/indicator/sub_category_indicators/${this.state.sub_category_indicator_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formCreateSubCategories), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modalSubCategories: false })
                        this.messageSuccess(data);
                        this.clearValuesSubCategory();
                        Turbolinks.visit(`/indicator/category_indicators`);
                    });
            }
        }
    }

    HandleClick = () => {
        if (this.validationForm() == true){
            this.setState({ isLoaded: true })
            if (!this.state.modeEdit)
                fetch(`/indicator/category_indicators`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.clearValues();
                        this.props.updateData(data.register)
                        this.setState({ modal: false, title: "", isLoaded: false })
                        this.messageSuccess(data);
                    });
            else {
                fetch(`/indicator/category_indicators/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.setState({ modal: false, isLoaded: false })
                        this.messageSuccess(data);
                        this.clearValues();
                        this.props.updateItem(data.register)
                    });
            }
        }
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                ...this.state.formUpdate,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    edit = (config) => {
        let arrayPositonEdit = []

        config.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id })
        ))

        this.setState({
            id: config.id,
            modeEdit: true,
            dataProcesosEdit: arrayPositonEdit,
            modal: true,

            selectedOptionFormat: {
                format_id: config.have_format ? config.format.id : "",
                label: config.have_format ? config.format.name : "Formato"
            },

            selectedOptionTaskBoard: {
                task_board_id: "",
                label: "Plantilla de tarea"
            },
            
            form: {
                name: config.name,
                section_name: config.section_name,
                order_number: config.order_number,
                format_id: config.have_format ? config.format.id : "",
                task_board_id: config.task_board_id,
            }
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/indicator/category_indicators/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };


    handleChangeAutocompleteFormat = selectedOptionFormat => {
        this.setState({
            selectedOptionFormat,
                form: {
                    ...this.state.form,
                    format_id: selectedOptionFormat.value
                }
        });
    };

    handleChangeAutocompleteTaskBoard = selectedOptionTaskBoard => {
        this.setState({
            selectedOptionTaskBoard,
                form: {
                    ...this.state.form,
                    task_board_id: selectedOptionTaskBoard.value
                }
        });
    };

    handleChangeAutocompleteSubCategoryMulti = selectedOption => {
        let array = []

        if(selectedOption){
            selectedOption.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                position_ids: selectedOption ? array : []
            }
        })
    }

    clearValuesSubCategory = () => {
        this.setState({
            dataProcesosEdit: [],

            formCreateSubCategories: {
                name: "",
                category_indicator_id: "",
                position_ids: [],
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },
        })
    }

    subCategories = (from, category) => {
        if (from == "new") {
            this.setState({
                formCreateSubCategories: {
                    ...this.state.formCreateSubCategories,
                    category_indicator_id: category.id,
                },

                modalSubCategories: true,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        } else {
            this.clearValuesSubCategory();
            this.setState({
                modalSubCategories: false,
                modeEditSubCategories: false,
                dataProcesosEdit: [],
            })
        }
    }

    HandleChangeSubCategory = (e) => {
        this.setState({
            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                [e.target.name]: e.target.value
            }
        })
    }

    deleteSubCategory = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/indicator/sub_category_indicators/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                       Turbolinks.visit(`/indicator/category_indicators`);
                        this.messageSuccess(response)
                    });
            }
        });
    };

    editSubCategory = (category_indicator, sub_category_indicator) => {
        let arrayPositonEdit = []
        let arrayIds = []

        sub_category_indicator.positions.map((item) => (
            arrayPositonEdit.push({ label: item.name, value: item.id }),
            arrayIds.push(item.id)
        ))

        this.setState({
            sub_category_indicator_id: sub_category_indicator.id,
            modeEditSubCategories: true,
            dataProcesosEdit: arrayPositonEdit,
            modalSubCategories: true,

            formCreateSubCategories: {
                ...this.state.formCreateSubCategories,
                category_indicator_id: category_indicator.id,
                name: sub_category_indicator.name,
                position_ids: arrayIds
            }
        })
    }

    clearValuesPosition = () => {
        this.setState({
            category_indicator_id: "",
            formUpdatePositionCategory: {
                position: 0,
            },
        })
    }

    updateValues = (category_indicator) => {
        this.setState({
            category_indicator_id: category_indicator.id,
            formUpdatePositionCategory: {
                position: category_indicator.position,
            },
        })
    }

    updatePosition = (e) => {
        fetch(`/indicator/update_position/${this.state.category_indicator_id}/${e.target.value}/${this.state.formUpdatePositionCategory.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.clearValuesPosition();
        });
    }


    render() {
        const styleIcons = {
            cursor: "pointer",
            color: "#3f69d8",
        }

        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Categoría de indicadores`}
                    subTitle={"Crea y gestiona las categorías de tus Indicadores"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={this.props.estados.crear}
                    showFilter={false}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar categoría" : "Añadir categoría"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}
                        isLoaded={this.state.isLoaded}

                        // select 

                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        editValuesProcesos={this.state.dataProcesosEdit}
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}
                        errorValues={this.state.ErrorValues}

                        //select task_board
                        handleChangeAutocompleteTaskBoard={this.handleChangeAutocompleteTaskBoard}
                        selectedOptionTaskBoard={this.state.selectedOptionTaskBoard}
                        task_boards={this.state.task_boards}

                        //select formato

                        selectedOptionFormat={this.state.selectedOptionFormat}
                        handleChangeAutocompleteFormat={this.handleChangeAutocompleteFormat}
                        formatos={this.state.formatos}
                    />
                )}

                {this.state.modalSubCategories && (
                    <FormCreateSubCategories
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalSubCategories}
                        toggle={this.subCategories}
                        title={this.state.modeEditSubCategories ? "Actualizar Sub Módulo" : "Crear Sub Módulo"}

                        //form props
                        formValues={this.state.formCreateSubCategories}
                        submitForm={this.HandleClickSubCategory}
                        onChangeForm={this.HandleChangeSubCategory}
                        nameSubmit={this.state.modeEditSubCategories ? "Actualizar" : "Crear"}
                        errorValues={this.state.ErrorValuesSubModule}

                        // select 

                        handleChangeAutocomplete={this.handleChangeAutocompleteSubCategoryMulti}
                        editValuesProcesos={this.state.dataProcesosEdit}
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}
                    />
                )}

                {/* <div className="content main-card mb-3 card">
                    <div className="card-body">
                        <table className="table table-hover table-striped table-bordered" id="sampleTable"> */}
                            {/* <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Sub categorías</th>
                                    <th>Accesos</th>
                                    <th>Posición</th> */}
                                 {/*    <th style={{ width: "15%" }}>Nombre estrategico</th>
                                    <th style={{ width: "15%" }}>Numero de orden</th> */}

                                    {/* {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                        <th style={{ width: "60px" }}></th>
                                    )}
                                </tr>
                            </thead> */}

                            {/* <tbody> */}
                            {!this.props.isLoaded ? (
                                 <React.Fragment>
                                {this.props.data.length >= 1 ? (
                                    <div className="row">
                                        {this.props.data.map(config => (
                                            <div className="col-md-4 mb-4" key={config.id}>
                                                <div className="card">
                                                    <div className="card-header ui-indexCategoryIndicators-titleCategory" style={{ background: '#3f6ad8', color: '#fff'}}>
                                                        <span>{config.name}</span>
                                                    </div>
                                                    <div className="card-body p-2 ui-indexCategoryIndicators-cardBody">
                                                        <div className="ui-indexCategoryIndicators-btnSubCategoty">
                                                            <button className='btn btn-info mb-2' onClick={() => this.subCategories("new", config)}>
                                                                + Nueva Subcategoría
                                                            </button>
                                                        </div>
                                                        <div className="col-md-12" style={{ background: "#e6e6e6", height: "143px", borderRadius: "10px" }}>
                                                            <div className="col-md-12 pt-3 pl-0 pr-0">  
                                                                {config.sub_category_indicators.length >= 1 ? (                                         
                                                                    <div className="ui-indexCategoryIndicators-scrollHorizontal">
                                                                        <div className="row">
                                                                            {config.sub_category_indicators.map(sub_category => (
                                                                                <React.Fragment>
                                                                                    <div className="ui-indexCategoryIndicators-cardsSubcategory">
                                                                                        <div className="card mb-2 mr-2 ml-2">
                                                                                            <div className="card-body pt-1 pb-1 pr-2 ui-indexCategoryIndicators-cardBodySubcategory">
                                                                                                <a className="float-right btn ui-indexCategoryIndicators-cardsSubcategory-iconTrash" style={{ color: "#848484", cursor: "pointer" }} onClick={() => this.deleteSubCategory(sub_category.id)} >
                                                                                                    <i className="far fa-trash-alt"></i>
                                                                                                </a>
                                                                                                <a className="float-right btn ui-indexCategoryIndicators-cardsSubcategory-iconEdit" style={{ color: "#848484", cursor: "pointer" }} onClick={() => this.editSubCategory(config, sub_category)}>
                                                                                                    <i className="fas fa-pencil-alt"></i>
                                                                                                </a>
                                                                                                <div className="mt-5">
                                                                                                    <p className="ui-indexCategoryIndicators-titleSubcategory">{sub_category.name}</p>
                                                                                                </div> 
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>  
                                                                                </React.Fragment>
                                                                            ))} 
                                                                        </div>                                                                                                                                      
                                                                    </div> 
                                                                    
                                                                ) : (
                                                                    <div className="col-md-12 card p-0">
                                                                        <div className="card-body text-center ui-indexCategoryIndicators-messageNoRegistro">
                                                                            <div className="text-center mt-2 mb-3">
                                                                                <p>No hay subcategorías creadas</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer ui-indexCategoryIndicators-cardFooter">
                                                        <div className="row">
                                                            <div className="ui-indexCategoryIndicators-icons">
                                                                <div className="btn-group">
                                                                    {this.props.estados.editar && (
                                                                        <a
                                                                        onClick={() => this.edit(config)}
                                                                        className="btn ui-indexCategoryIndicators-iconEdit"
                                                                    >
                                                                         <i className="far fa-edit"></i> 
                                                                    </a>
                                                                    )}

                                                                    {this.props.estados.eliminar && (
                                                                        <a
                                                                            onClick={() => this.delete(config.id)}
                                                                            className="btn ui-indexCategoryIndicators-iconTrash"
                                                                        >
                                                                            <i className="far fa-trash-alt"></i>
                                                                    </a>
                                                                    )}
                                                                                
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    	     // <tr key={config.id}>
                                            //     <td>{config.name}</td>
                                            //     <td>
                                            //         <p
                                            //             className="link-line-dark"
                                            //             onClick={() => this.subCategories("new", config)}
                                            //         >
                                            //             + Nuevo Sub Categorías
                                            //         </p>
                                            //         <hr />

                                            //         <div>
                                            //             {config.sub_category_indicators.map(sub_category => (
                                            //                 <React.Fragment>
                                            //                     <div className="gm-link-formatos">{sub_category.name} <i className="ml-2 fas fa-pencil-alt" style={styleIcons} onClick={() => this.editSubCategory(config, sub_category)}></i> <i className="fas fa-trash-alt" onClick={() => this.deleteSubCategory(sub_category.id)} style={styleIcons}></i></div>
                                            //                 </React.Fragment>
                                            //             ))}
                                            //         </div>
                                            //     </td>

                                            //     <td>
                                            //         <ul>
                                            //             {config.positions.map(cargo => (
                                            //                 <li key={cargo.id}>{cargo.name}</li>
                                            //             ))}
                                            //         </ul>
                                            //     </td>

                                            //     <td>
                                            //         {this.state.category_indicator_id == config.id ? (
                                            //             <React.Fragment>
                                            //                 <select
                                            //                     className="form form-control"
                                            //                     value={config.position}
                                            //                     onChange={(e) => this.updatePosition(e)}
                                            //                 >
                                            //                     {this.props.data.map(item => (
                                            //                         <React.Fragment>
                                            //                             <option value={item.position}>{item.position}</option>
                                            //                         </React.Fragment>
                                            //                     ))}
                                            //                 </select>

                                            //                 <i className="fas fa-times" style={{ cursor: "pointer", color: "red", fontSize: "19px", float: "right" }} onClick={() => this.clearValuesPosition()}></i>
                                            //             </React.Fragment>
                                            //         ) : (
                                            //             <p onClick={() => this.updateValues(config)}>{config.position}</p>
                                            //         )}
                                            //     </td>

                                            //     {(this.props.estados.editar || this.props.estados.eliminar || this.props.estados.gestionar) && (
                                            //         <td className="text-right" style={{ width: "10px" }}>
                                            //                     <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            //                         <div className="btn-group" role="group">
                                            //                             <button
                                            //                                 className="btn-shadow btn btn-info"
                                            //                                 id="btnGroupDrop1"
                                            //                                 type="button"
                                            //                                 data-toggle="dropdown"
                                            //                                 aria-haspopup="true"
                                            //                                 aria-expanded="false"
                                            //                             >
                                            //                                 <i className="fas fa-bars"></i>
                                            //                             </button>

                                            //                             <div className="dropdown-menu dropdown-menu-right">

                                            //                                {/*  {true && (
                                            //                                     <a
                                            //                                         href={`/indicator/category_indicators/${config.id}/indicators?type=${config.category_type}`}
                                            //                                         data-turbolinks="true"
                                            //                                         className="dropdown-item"
                                            //                                     >
                                            //                                         Gestionar
                                            //                                 </a>
                                            //                                 )} */}

                                            //                                 {this.props.estados.editar && (
                                            //                                     <button
                                            //                                         onClick={() => this.edit(config)}
                                            //                                         className="dropdown-item"
                                            //                                     >
                                            //                                         Editar
                                            //                                 </button>
                                            //                                 )}

                                            //                                 {this.props.estados.eliminar && (
                                            //                                     <button
                                            //                                         onClick={() => this.delete(config.id)}
                                            //                                         className="dropdown-item"
                                            //                                     >
                                            //                                         Eliminar
                                            //                                 </button>
                                            //                                 )}
                                            //                             </div>
                                            //                         </div>
                                            //                     </div>
                                            //         </td>
                                            //     )}
                                            // </tr>
                                ) : (
                                    <div className='card col-md-12'>
                                        <div className="text-center">
                                            <div className="text-center mt-4 mb-4" style={{width: '100%'}}>
                                                <p className='ui-indexCategoryIndicators-messageNoRecord'>Todavía no hay registros creados, da clic en<a onClick={() => this.toogle('new')} className="btn btn-info"><i className='fas fa-toolbox'></i>Nuevo</a> y genera el primer registro.</p>
                                                <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/8/noRecord_strategy.jpg" alt="" className="ui-indexCategoryIndicators-imgNoRecord" />
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="card">
                                        <div className="card-body">
                                            <Preloader />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                                    
                            {/* </tbody> */}
                        {/* </table>
                    </div>
                </div> */}

                          
                        {true && (
                            <div className="row mt-3">

                                <div className="col-md-2 text-left">
                                    <p>
                                        Mostrando {this.props.data.length} de {this.props.totalData}
                                    </p>
                                </div>

                                <div className="col-md-10 pl-0">
                                    <Pagination
                                        hideNavigation
                                        activePage={this.props.activePage}
                                        itemsCountPerPage={this.props.countPage}
                                        itemClass="page-item"
                                        innerClass="pagination"
                                        linkClass="page-link"
                                        totalItemsCount={this.props.totalData}
                                        pageRangeDisplayed={this.props.countPage}
                                        onChange={this.props.handlePageChange}
                                    />
                                </div>

                            </div>
                        )}
            </React.Fragment>
        );
    }
}

export default Index;
