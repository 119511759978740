import React, { Component } from 'react';
import Preloader from '../../../GeneralComponents/LoadingComponen';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import ModalActions from './ModalActions';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Pagination from "react-js-pagination";

class IndexTemplate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modeEdit: false,
            modalActions: false,
            id: "",
            form: {
                name: "",
                is_template: true,
                position_ids: [],
                task_label_ids: [],
                user_ids: [],
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionUsers: {
                user_ids: "",
                label: "Usuarios"
            },

            task_board: {},
            editValuesPosition: [],
            editValuesUsers: [],
            selectLabels: [],
            cargos: [],
            users: [],
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true, showBtn: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }


    toogleActions = (from, task_board) => {
        if (from == "new") {
            this.setState({ modalActions: true, task_board: task_board })
        } else {
            this.setState({ modalActions: false, task_board: {} })
        }
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            id: "",
            task_board: {},
            editValuesPosition: [],
            selectLabels: [],
            editValuesUsers: [],

            form: {
                name: "",
                is_template: true,
                position_ids: [],
                task_label_ids: [],
                user_ids: [],
            },

            selectedOption: {
                position_ids: "",
                label: "Cargos"
            },

            selectedOptionUsers: {
                user_ids: "",
                label: "Usuarios"
            },
        })
    }

    componentDidMount = () => {
        this.configSelect();
    }

    configSelect = () => {
        let arrayCargos = []
        let arrayUsers = []

        this.props.positions.map((item) => (
            arrayCargos.push({label: item.name, value: item.id})
        ))

        this.props.users.map((item) => (
            arrayUsers.push({label: item.name, value: item.id})
        ))

        this.setState({
            cargos: arrayCargos,
            users: arrayUsers,
        })
    }

    handleChangeAutocomplete = selectedOption => {
        let array = []

        selectedOption.map((item) => (
          array.push(item.value)
        ))
    
        this.setState({
            form: {
                ...this.state.form,
                position_ids: array
            }
        })
    }

    handleChangeAutocompleteUsers = selectedOptionUsers => {
        let array = []

        selectedOptionUsers.map((item) => (
          array.push(item.value)
        ))
    
        this.setState({
            form: {
                ...this.state.form,
                user_ids: array
            }
        })
    }

    HandleChange = e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    };


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/task/task_boards/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    HandleClick = () => {
        if (!this.state.modeEdit) {
            if(this.validationForm()){
                fetch(`/task/task_boards`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    // this.clearValues();
                    this.setState({ modeEdit: false, task_board: data.register, showBtn: false })
                    this.props.updateData(data.register)
                    this.props.messageSuccess(data);
                });
            }

        } else {

            fetch(`/task/task_boards/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.setState({ modal: false, modeEdit: false })
                this.props.updateItem(data.register)
                //this.props.messageSuccess(data);
            });
        }
    }

    addLabel = (label, state) => {
        const selectLabels = this.state.selectLabels

        if (!state){
            selectLabels.push(label.id)

            this.setState({
                selectLabels: selectLabels,
                form: {
                    ...this.state.form,
                    task_label_ids: selectLabels,
                },
            })
        }else {
            var arr = selectLabels
            var index = selectLabels.indexOf(parseInt(label.id))
            delete arr[index]

            this.setState({
                selectLabels: arr.filter(item => item != null),
                form: {
                    ...this.state.form,
                    task_label_ids: arr.filter(item => item != null),
                },
            })
        }
    }

    edit = (task_board) => {
        const position = []
        const task_labels = []
        const arrayEdit = []
        const arrayEditUsers = []

        /*task_board.positions.map((item) => (
            position.push(item.id)
        ))*/

        /*task_board.task_labels.map((item) => (
            task_labels.push(item.id)
        ))*/

        /*task_board.positions.map((item) => (
            arrayEdit.push({label: item.name, value: item.id})
        ))*/

        task_board.users.map((item) => (
            arrayEditUsers.push({label: `${item.first_name} ${item.first_last_name}`, value: item.id})
        ))

        this.setState({
            form: {
                name: task_board.name,
                position_ids: position,
                task_label_ids: task_labels,
                user_ids: arrayEditUsers,
                is_template: true,
            },

            id: task_board.id,
            selectLabels: task_labels,
            editValuesPosition: arrayEdit,
            editValuesUsers: arrayEditUsers,
            task_board: task_board,
            modal: true,
            showBtn: true,
            modeEdit: true,
        })
    }

    delete = (task_board_id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/task/destroy_task_board/${task_board_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.props.loadData()
                    this.messageSuccess(response)
                });
            }
        });
    };


    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Editar plantilla" : "Nueva plantilla"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        errorValues={this.state.ErrorValues}
                        nameSubmit={this.state.modeEdit ? "Actualizar plantilla" : "Siguiente"}
                        labels={this.props.labels}
                        modeEdit={this.state.modeEdit}

                        addLabel={this.addLabel}
                        selectLabels={this.state.selectLabels}
                        editValuesPosition={this.state.editValuesPosition}
                        showBtn={this.state.showBtn}

                        //select config 
                        selectedOption={this.state.selectedOption}
                        cargos={this.state.cargos}
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        is_template={false}

                        //select user
                        handleChangeAutocompleteUsers={this.handleChangeAutocompleteUsers}
                        users={this.props.users}
                        selectedOptionUsers={this.state.selectedOptionUsers}
                        editValuesUsers={this.state.editValuesUsers}
                        task_board={this.state.task_board}
                        updateColumnCount={this.props.updateColumnCount}

                    />
                )}

                {this.state.modalActions && (
                    <ModalActions
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalActions}
                        toggle={this.toogleActions}

                        task_board={this.state.task_board}
                        loadData={this.props.loadData}
                    />
                )}

                {!this.props.isLoaded ? (
                    <React.Fragment>
                        {this.props.data.length >= 1 ? (
                            <div className="row ui-boars-index-container">

                                {this.props.data.map(task_board => (
                                    <div className="col-md-4 mb-4 ui-boards-index" key={task_board.id}>
                                        <div className="card ui-boards-index">
                                            <div className="card-header" style={{ borderTopLeftRadius: "10px" }}>
                                                {task_board.name}
                                            </div>

                                            {true && (
                                                <div className="card-footer border-bottom-card">
                                                    <div className="row" style={{ width: "111%" }}>

                                                        {task_board.columns >= 1 ? (
                                                            <React.Fragment>
                                                                <div className="col-md-5 pr-0 ui-indextemplate-date-creation">
                                                                    {true && (
                                                                        <a
                                                                            data-turbolinks="true"
                                                                            style={{ color: "#1aa8fa" }}
                                                                            className="btn"
                                                                        >
                                                                            {task_board.created_at}
                                                                        </a>
                                                                    )}
                                                                </div>

                                                                <div className="col-md-4 ui-board-index-colums pr-0">
                                                                    {true && (
                                                                        <a
                                                                            className="btn"
                                                                        >
                                                                            Columnas {task_board.columns}
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            <div className="col-md-8">
                                                                <span className="badge badge-info badge-columns">No hay columnas</span>
                                                            </div>
                                                        )}

                                             
                                                        <div className="col-md-3 pl-0 text-right">
                                                            {(this.props.estados.edit && this.props.user.id == task_board.user.id) && (
                                                                <a
                                                                    onClick={() => this.edit(task_board)}
                                                                    className="btn"
                                                                >
                                                                    <i className="far fa-edit"></i>   
                                                                </a>
                                                            )}

                                                            {(this.props.estados.delete && this.props.user.id == task_board.user.id) && (
                                                                <a
                                                                    onClick={() => this.delete(task_board.id)}
                                                                    style={{ color: "red" }}
                                                                    className="btn"
                                                                >
                                                                    <i className="far fa-trash-alt"></i>
                                                                </a>
                                                            )}
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className='card col-md-12'>
                            <div className="text-center">
                                <div className="text-center mt-4 mb-4" style={{width: '100%'}}>
                                    <p className='ui-indextemplate-messageNoRecord'>Todavía no hay plantillas creadas, da clic en <a onClick={() => this.toogle('new')}  className="btn btn-info"><i className='fas fa-chalkboard-teacher'></i>Nueva plantilla</a> y genera la primera plantilla</p>
                                    <img className='ui-indextemplate-imgNoRecord' src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/7/noRecord_task.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                            )
                        }
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <Preloader />
                            </div>
                        </div>
                    </React.Fragment>
                )}



                {/*<div className="row">

                    <div className="col-md-2 text-left">
                        <p>
                            Mostrando {this.props.data.length} de {this.props.total_data}
                        </p>
                    </div>

                    <div className="col-md-10 pl-0">
                        <Pagination
                            hideNavigation
                            activePage={this.props.activePage}
                            itemsCountPerPage={this.props.countPage}
                            itemClass="page-item"
                            innerClass="pagination"
                            linkClass="page-link"
                            totalItemsCount={this.props.total_data}
                            pageRangeDisplayed={this.props.countPage}
                            onChange={this.props.handlePageChange}
                        />
                    </div>
                </div>*/}

            </React.Fragment>
        );
    }
}

export default IndexTemplate;
