import React, { Component } from 'react';
import Preloader from '../../../../GeneralComponents/LoadingComponen'
import FormCreate from './FormCreate'
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

class IndexColumn extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modeEdit: false,
            ErrorValues: true,
            isLoaded: true,
            id: "",
            data: [],
            form: {
                title: "",
                position: "",
                task_board_id: this.props.task_board.id,
                color: "#3f6ad8",
                color_text: "#ffffff",
                type_column: "",
            }
        }
    }

    clearValues = () => {
        this.setState({
            id: "",
            modeEdit: false,
            ErrorValues: true,
            form: {
                ...this.state.form,
                title: "",
                position: "",
                color: "#3f6ad8",
                color_text: "#ffffff",
                type_column: "",
                task_board_id: this.props.task_board.id,
            }
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/task/get_columns/${this.props.task_board.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                isLoaded: false,
                data: data.data,
            });
        });
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (column) => {
        this.setState({
            data: this.state.data.map(item => {
            if (column.id === item.id) {
              return { ...item, 
                title: column.title,
                position: column.position,
                color: column.color,
                type_column: column.type_column,
                color_text: column.color_text,
              }
            }
            return item;
          })
        });
    }

    HandleClick = () => {
        if(this.validationForm()){
            if(!this.state.modeEdit)
                fetch(`/task/columns`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modal: false, isLoaded: false });
                    this.updateData(data.register);
                    this.props.updateColumnCount(this.props.task_board.id, data.columns);
                    this.clearValues();
                });
            else{
                fetch(`/task/columns/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modal: false, isLoaded: false });
                    this.updateItem(data.register);
                    this.clearValues();
                });
            }
        }
    }


    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                //this.props.updateStateLoadColors(true)
                fetch(`/task/delete_column/${id}/${this.props.task_board.id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                    this.props.updateColumnCount(this.props.task_board.id, response.columns);
                    this.messageSuccess(response)
                });
            }
        });
    };


    updatePosition = (e) => {
        fetch(`/task/update_position_columns/${this.state.id}/${e.target.value}/${this.state.form.position}/${this.props.task_board.id}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.loadData();
            this.clearValues();
        });
    }

    edit = (column) => {
        this.setState({
            modeEdit: true,
            modal: false,
            ErrorValues: true,
            id: column.id,
            form: {
                title: column.title,
                position: column.position,
                color: column.color,
                color_text: column.color_text,
                type_column: column.type_column,
                task_board_id: this.props.task_board.id,
            }
        })
    }


    duplicateColor = (column_id) => {
        fetch(`/task/update_colors/${column_id}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({
                data: data.data
            })
        });
    }


    handleChangeComplete = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    validationForm = () => {
        if (this.state.form.title != "" && 
            this.state.form.type_column != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="card mb-2 mt-2">
                    <div className="card-header">
                        <div className="row w-100">
                            <div className="col-md-8">
                                Columnas de tareas
                            </div>

                            <div className="col-md-4 pr-0 text-right">
                                {!this.props.task_board.delete_template && (
                                    <React.Fragment>
                                        <button
                                            className="btn-shadow btn btn-info"
                                            id={`PopoverCreate`}
                                            onClick={() => this.setState({ modal: true })}
                                        >
                                            <i className="fas fa-plus"></i> Añadir columna
                                        </button>

                                        <UncontrolledPopover isOpen={this.state.modal} trigger="legacy" placement="left" target={`PopoverCreate`}>
                                            <PopoverBody className="popover-column">
                                                <div className="col-md-12 p-0" style={{ width: "294px", height: "auto" }}>
                                                    <FormCreate
                                                        //form props
                                                        toogle={this.toogle}
                                                        formValues={this.state.form}
                                                        onChangeForm={this.handleChangeComplete}
                                                        submitForm={this.HandleClick}
                                                        modeEdit={this.state.modeEdit}
                                                        errorValues={this.state.ErrorValues}
                                                        nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear columna"}
                                                    />
                                                </div>
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="card-body p-2">
                        <div className="col-md-12" style={{ background: "#e6e6e6", height: "140px", borderRadius: "10px" }}>
                            {!this.state.isLoaded ? (
                                <div className="col-md-12 pt-3 pl-0 pr-0">
                                    {this.state.data.length >= 1 ? (
                                        <div className="ui-scroll-horizontal-task colums-tasks" style={{overflow: 'hidden', overflowX: 'auto'}}>
                                                {this.state.data.map((column, index) => (
                                                    <React.Fragment>
                                                        <div className="col-md-3 ui-indexColumn-task-columns">
                                                            <div className={`card mb-2 ui-task-column-index column`}>
                                                                <div className="card-header pl-0" style={{ backgroundColor: column.color }}>
                                                                    {!this.props.task_board.delete_template && (
                                                                        <button className="float-right btn" style={{ color: column.color_text, cursor: "pointer" }} onClick={() => this.delete(column.id)}>
                                                                            <i className="far fa-trash-alt"></i>
                                                                        </button>
                                                                    )}

                                                                    {!this.props.task_board.delete_template && (
                                                                        <React.Fragment>
                                                                            <button 
                                                                                className="float-right btn ui-icon-indexcolum-edit" 
                                                                                style={{ color: column.color_text, cursor: "pointer" }} 
                                                                                onClick={() => this.edit(column)}
                                                                                id={`PopoverCreate${column.id}`}
                                                                            >
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </button>


                                                                            <UncontrolledPopover isOpen={this.state.id == column.id} trigger="legacy" placement="left" target={`PopoverCreate${column.id}`} className="popover-column">
                                                                                <PopoverBody>
                                                                                    <div className="col-md-12 p-0" style={{ width: "294px", height: "auto" }}>
                                                                                        <FormCreate
                                                                                            //form props
                                                                                            toogle={this.toogle}
                                                                                            formValues={this.state.form}
                                                                                            onChangeForm={this.handleChangeComplete}
                                                                                            submitForm={this.HandleClick}
                                                                                            modeEdit={this.state.modeEdit}
                                                                                            nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear columna"}
                                                                                            updatePosition={this.updatePosition}
                                                                                            data={this.state.data}
                                                                                            errorValues={this.state.ErrorValues}
                                                                                        />
                                                                                    </div>
                                                                                </PopoverBody>
                                                                            </UncontrolledPopover>
                                                                        </React.Fragment>
                                                                    )}
                                                                    
                                                                    <UncontrolledTooltip target={`btn-info${column.id}`}>{"Duplicar color de fondo y color de texto a todas las otras columnas"}</UncontrolledTooltip>
                                                                    <button 
                                                                        className="float-right btn" 
                                                                        id={`btn-info${column.id}`}
                                                                        onClick={() => this.duplicateColor(column.id)}
                                                                        style={{ color: "#ffff", cursor: "pointer" }}
                                                                    >
                                                                        <i className="fas fa-gear"></i>
                                                                    </button>
                                                                </div>
                                                                <div className="card-body p-2 ui-icon-indexcolum-trash">
                                                                    <span className="ui-task-column-index name-column" title={column.title}>{column.title}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    ) : (
                                        <div className="col-md-12 p-4 text-center ui-indexcolumn-textInfo">
                                            <p style={{ fontSize: "29px" }}>No se han creado columnas</p>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="col-md-12 text-center">
                                    <Preloader />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default IndexColumn;
