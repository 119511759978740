import React, { Component } from 'react';
import HeaderTitle from '../../../GeneralComponents/HeaderTitle';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from '../../formatos/Formats/FormCreate';
import ShowModal from '../Efects/EfectActivity/Show';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Show extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            modalShow: false,
            id: "",

            form: {
                name: "",
                efect_id: this.props.efect.id,
            },

            formUpdate: {
                name: "",
            },

            activity: {},
        }
    }

    validationForm = () => {
        if (this.state.form.name != "") {
            console.log("los campos estan llenos")
            this.setState({ ErrorValues: true })
            return true
        } else {
            console.log("los campos no se han llenado")
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }


    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            id: "",
            form: {
                ...this.state.form,
                name: "",
            }
        })
    }

    HandleClick = () => {
        fetch(`/failure/efects/${this.props.efect.id}/efect_activities`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(this.state.form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.props.updateData(data.register)
                this.props.updateStateCreate(data.last.fallo)
                this.setState({ modal: false })
                this.messageSuccess(data);
            });
    }

    HandleClickUpdate = () => {
        fetch(`/failure/efects/${this.props.efect.id}/efect_activities/${this.state.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdate), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ id: "", formUpdate: { name: "", } })
                this.props.updateItem(data.register)
            });
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/failure/efects/${this.props.efect.id}/efect_activities/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    edit = (formato) => {
        this.setState({
            id: formato.id,
            formUpdate: {
                name: formato.name
            }
        })
    }

    HandleChangeUpdate = (e) => {
        this.setState({
            formUpdate: {
                name: e.target.value

            }
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    toggleShow = (from, activity) => {
        if (from == "new") {
            this.setState({ modalShow: true, activity: activity })
        } else {
            this.setState({ modalShow: false, activity: {} })
        }
    }

    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={"Actividad"}
                    subTitle={"Gestión de actividad"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={this.props.showCreate}
                    showFilter={false}
                />

                {this.props.showCreate == false && (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-info">
                                <b>No puedes crear actividades, porque no has completado la información de la última</b>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Crear actividad"}

                        //form props
                        formValues={this.state.form}
                        onChangeForm={this.HandleChange}
                        submitForm={this.HandleClick}
                        type_count={this.props.type_count}
                    />
                )}

                {this.state.modalShow && (
                    <ShowModal
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalShow}
                        toggle={this.toggleShow}
                        title={"Gestión de actividad"}
                        activity={this.state.activity}
                        loadData={this.props.loadData}
                        user={this.props.user}
                        type_count={this.props.type_count}
                    />
                )}

                <div className="content main-card mb-3 card">
                    <div className="card-body" style={{ display: "grid" }}>
                        <div className="content-table">
                            <table className="table table-hover table-bordered-gray" id="sampleTable" style={{ width: "2000px" }}>
                                <thead>
                                    <tr className="tr-color1">
                                        <th></th>
                                        <th
                                            className="text-center"
                                            style={{ width: "300px"}}
                                        >
                                            ¿Que hace en la realidad el participante clave en el proceso?
                                        </th>

                                        <th
                                            className="text-center"
                                            style={{ width: "300px" }}
                                        >
                                            ¿Que fallo(s) puede tener?
                                        </th>

                                        <th
                                            className="text-center"
                                            style={{ width: "400px" }}
                                        >
                                            Consecuencia del fallo
                                        </th>


                                        <th
                                            className="text-center"
                                            style={{ width: "50px" }}
                                        >
                                            Impacto del efecto
                                        </th>

                                        <th
                                            className="text-center"
                                            style={{ width: "300px" }}
                                        >
                                            ¿Por que puede presentarse el fallo?
                                        </th>

                                        <th
                                            className="text-center"
                                            style={{ width: "50px" }}
                                        >
                                            ¿Que tan frecuente puede ocurrir el modo de fallo?
                                        </th>

                                        <th
                                            className="text-center"
                                            style={{ width: "300px" }}
                                        >
                                            ¿Existen alguna manera que detecta la causa con el proposito de que no se presente ese modo de fallo?
                                        </th>

                                        <th
                                            className="text-center"
                                            style={{ width: "50px" }}
                                        >
                                            Si existe, ¿Que tan bueno es?
                                        </th>

                                        <th
                                            className="text-center"
                                            style={{ width: "50px" }}
                                        >
                                            Ponderación multiplicadas de la severidad, ocurrencia y detectabilidad
                                        </th>
                                        <th
                                            className="text-center"
                                            style={{ width: "50px" }}
                                        >
                                        
                                        </th>

                                    </tr>
                                </thead>

                                <thead>
                                    <tr className="tr-color2">
                                        <th className="text-center"></th>
                                        <th className="text-center">ACTIVIDAD</th>
                                        <th className="text-center">MODO DE FALLO</th>
                                        <th className="text-center">EFECTO DE FALLO</th>
                                        <th className="text-center">SEVERIDAD</th>
                                        <th className="text-center">CAUSA DE FALLO</th>
                                        <th className="text-center">OCURRENCIA</th>
                                        <th className="text-center">CONTROL</th>
                                        <th className="text-center">DETECTABILIDAD</th>
                                        <th className="text-center">NÚMERO PRIORITARIO DE RIESGO (NPR)</th>
                                        <th className="text-center"></th>

                                    </tr>
                                </thead>

                                <tbody>
                                    {this.props.data.length >= 1 ? (
                                        this.props.data.map(activity => (
                                            activity.failures.length >= 1 ? (
                                                activity.failures[0].get_causes.length >= 1 ? (
                                                    <React.Fragment>
                                                        <tr key={activity.id}>
                                                            {true && (
                                                                <td className="text-right select-border-bottom border-left-gray" style={{ width: "10px" }} rowSpan={activity.get_causes_count}>
                                                                    <UncontrolledDropdown className='btn-group'>
                                                                        <DropdownToggle className='btn btn-info'>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.toggleShow("new", activity)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Gestionar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {false && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.edit(activity)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.delete(activity.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                    
                                                                </td>
                                                            )}

                                                            <td
                                                                className="text-center select-border-bottom"
                                                                rowSpan={activity.get_causes_count}
                                                            >
                                                                {activity.name}
                                                            </td>

                                                            {activity.failures.length > 0 && (
                                                                <React.Fragment>
                                                                    <td className="select-border-bottom" rowSpan={activity.failures[0].get_causes.length}>
                                                                        {activity.failures[0].mode}
                                                                    </td >
                                                                    <td className="select-border-bottom" rowSpan={activity.failures[0].get_causes.length}>
                                                                        {activity.failures[0].effect}
                                                                    </td>
                                                                    <td  className="text-center select-border-bottom" rowSpan={activity.failures[0].get_causes.length}>
                                                                        {activity.failures[0].severity}
                                                                    </td>
                                                                    {activity.failures[0].get_causes.length > 0 && (
                                                                        <React.Fragment>
                                                                            <td className="select-border-bottom">{activity.failures[0].get_causes[0].cause} </td>
                                                                            <td  className="text-center select-border-bottom">{activity.failures[0].get_causes[0].ocurrency_score_first}</td>
                                                                            <td className="select-border-bottom">{activity.failures[0].get_causes[0].control}</td>
                                                                            <td  className="text-center select-border-bottom">{activity.failures[0].get_causes[0].detectability_score_first}</td>
                                                                            <td  className="text-center select-border-bottom" style={{background: activity.failures[0].get_causes[0].npr_score_first > 64 ? "red" : "white", color: activity.failures[0].get_causes[0].npr_score_first > 64 ? "white" : "gray"}}> {activity.failures[0].get_causes[0].npr_score_first}</td>
                                                                            <td  className="text-center select-border-bottom border-right-gray"><a  className="float-right" href={`/failure/causes/${activity.failures[0].get_causes[0].id}`} target="_blank" data-turbolinks="true">Gestionar</a></td>
                                                                        </React.Fragment>
                                                                    )}
                                                                </React.Fragment>
                                                            )}

                                                        </tr>

                                                        <React.Fragment>
                                                            {activity.failures.length > 0 && (
                                                                activity.failures[0].get_causes.map((cause, index) => (
                                                                    index > 0 && (
                                                                        <tr>
                                                                            <td>{cause.cause}</td>
                                                                            <td  className="text-center">{cause.ocurrency_score_first}</td>
                                                                            <td>{cause.control}</td>
                                                                            <td  className="text-center">{cause.detectability_score_first}</td>
                                                                            <td  className="text-center" style={{background: cause.npr_score_first > 64 ? "red" : "white", color: cause.npr_score_first > 64 ? "white" : "gray"}}>{cause.npr_score_first}</td>
                                                                            <td  className="text-center border-right-gray"><a  className="float-right" href={`/failure/causes/${cause.id}`} target="_blank" data-turbolinks="true">Gestionar</a></td>


                                                                        </tr>
                                                                    )
                                                                )))}
                                                        </React.Fragment>
                                                        {activity.failures.length > 0 && (
                                                            activity.failures.map((failure, index) => (
                                                                index > 0 && (
                                                                    <React.Fragment>
                                                                        <tr>
                                                                            <td rowSpan={failure.get_causes.length}>
                                                                                {failure.mode}
                                                                            </td >
                                                                            <td rowSpan={failure.get_causes.length}>
                                                                                {failure.effect}
                                                                            </td>
                                                                            <td  className="text-center" rowSpan={failure.get_causes.length}>
                                                                                {failure.severity}
                                                                            </td>
                                                                            {failure.get_causes.length > 0 && (
                                                                                <React.Fragment>
                                                                                    <td>{failure.get_causes[0].cause}</td>
                                                                                    <td  className="text-center">{failure.get_causes[0].ocurrency_score_first}</td>
                                                                                    <td>{failure.get_causes[0].control}</td>
                                                                                    <td  className="text-center">{failure.get_causes[0].detectability_score_first}</td>
                                                                                    <td  className="text-center" style={{background: failure.get_causes[0].npr_score_first > 64 ? "red" : "white",color: failure.get_causes[0].npr_score_first > 64 ? "white" : "gray"}}>{failure.get_causes[0].npr_score_first}</td>
                                                                                    <td  className="text-center border-right-gray"><a  className="float-right" href={`/failure/causes/${failure.get_causes[0].id}`} target="_blank" data-turbolinks="true">Gestionar</a></td>

                                                                                </React.Fragment>
                                                                            )}
                                                                        </tr>
                                                                        {failure.get_causes.map((cause, index) => (
                                                                            index > 0 && (
                                                                                <tr>
                                                                                    <td>{cause.cause}</td>
                                                                                    <td  className="text-center">{cause.ocurrency_score_first}</td>
                                                                                    <td>{cause.control}</td>
                                                                                    <td  className="text-center">{cause.detectability_score_first}</td>
                                                                                    <td  className="text-center" style={{background: cause.npr_score_first > 64 ? "red" : "white", color: cause.npr_score_first > 64 ? "white" : "gray"}}>{cause.npr_score_first}</td>
                                                                                    <td  className="text-center border-right-gray"><a  className="float-right" href={`/failure/causes/${cause.id}`} target="_blank" data-turbolinks="true">Gestionar</a></td>

                                                                                </tr>
                                                                            )
                                                                        ))}

                                                                    </React.Fragment>
                                                                )
                                                            )))}





                                                    </React.Fragment>

                                                ) : (
                                                        <React.Fragment>
                                                                
                                                                <tr>
                                                            {true && (
                                                                <td className="text-right border-left-gray" style={{ width: "10px" }} >
                                                                    <UncontrolledDropdown className='btn-group'>
                                                                        <DropdownToggle className='btn btn-info'>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.toggleShow("new", activity)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Gestionar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {false && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.edit(activity)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.delete(activity.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            )}
                                                            <td>{activity.name}</td>
                                                            <td>{activity.failures[0].mode}</td>
                                                            <td>{activity.failures[0].effect}</td>
                                                            <td>{activity.failures[0].severity}</td>
                                                            <td colSpan="5"></td>
                                                        </tr>


                                                        </React.Fragment>
                                                    )
                                            ) : (
                                                    <React.Fragment>
                                                        <tr>
                                                            {true && (
                                                                <td className="text-right" style={{ width: "10px" }} >
                                                                    <UncontrolledDropdown className='btn-group'>
                                                                        <DropdownToggle className='btn btn-info'>
                                                                            <i className="fas fa-bars"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.toggleShow("new", activity)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Gestionar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {false && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.edit(activity)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                    Editar
                                                                                </DropdownItem>
                                                                            )}

                                                                            {true && (
                                                                                <DropdownItem
                                                                                    onClick={() => this.delete(activity.id)}
                                                                                    className="dropdown-item"
                                                                                >
                                                                                   Eliminar
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                    
                                                                </td>
                                                            )}
                                                            <td colSpan="9">{activity.name}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                        ))
                                    ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </React.Fragment >
        );
    }
}

export default Show;


/*
<p>{cause.cause} <a href={`/failure/causes/${cause.id}`} target="_blank" data-turbolinks="true">show</a></p>


{failure.get_causes.map(cause => (
                                                            <p>{cause.cause} <a href={`/failure/causes/${cause.id}`} target="_blank" data-turbolinks="true">show</a></p>
                                                        ))}



                                                {activity.failures.map(failure => (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td>MODO DE FALLO</td>
                                                            <td>MODO DE FALLO</td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}








                                                 {true && (
                                                    <td className="text-right" style={{ width: "10px" }}>
                                                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                            <div className="btn-group" role="group">
                                                                <button
                                                                    className="btn-shadow btn btn-info"
                                                                    id="btnGroupDrop1"
                                                                    type="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i className="fas fa-bars"></i>
                                                                </button>

                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    {true && (
                                                                        <button
                                                                            onClick={() => this.toggleShow("new", activity)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Gestionar
                                                                        </button>
                                                                    )}

                                                                    {false && (
                                                                        <button
                                                                            onClick={() => this.edit(activity)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Editar
                                                                            </button>
                                                                    )}

                                                                    {true && (
                                                                        <button
                                                                            onClick={() => this.delete(activity.id)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Eliminar
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                )}
*/



