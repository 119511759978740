import React, { Component } from "react";
import Select from "react-select"
import { isMobile } from 'react-device-detect';

class FormFilter extends Component {
  constructor(props){
    super(props)
    this.state = {
      show_btn: false
    }
  }

  handleSubmit = (e) => {
      e.preventDefault();
      this.setState({
        show_btn: true
      })
  }

  close = () =>{
    this.props.closeFilter(true)
    this.setState({
      show_btn: false
    })
  }

  render() {
    return (
      <div className="content main-card mb-3 card">
        <div className="col-md-12 mt-3">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                <div className={`col-md-3 imput-filter ${isMobile ? "mb-3" : ""}`}>
                  <input
                    type="hidden"
                    name="specific_area_id"
                    value={this.props.selectedSpecificAreas.specific_area_id}
                  />
                  <label>Lugar</label>
                  <Select
                    onChange={this.props.handleChangeAutocompleteSpecificAreas}
                    isMulti
                    closeMenuOnSelect={false}
                    name="specific_area_id"
                    options={this.props.dataSpecificAreas}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Seleccione"
                  />

                  {/*<label>Área específica</label>
                    <select name="specific_area_id" 
                        className="form form-control"
                        onChange={this.props.onChangeFilter}
                        value={this.props.formValuesFilter.specific_area_id}
                      >
                        <option value="">Seleccione</option>
                        {
                          this.props.areas.map(item => (
                              <React.Fragment>
                                  <option value={item.id}>{item.name}</option>
                              </React.Fragment>
                          ))
                        }
                    </select>
                  */}
                </div>



                <div className={`col-md-3 ${isMobile ? "mb-3" : ""}`}>
                  <input
                    type="hidden"
                    name="related_event_id"
                    value={this.props.selectedOptionRelatedEvent.related_event_id}
                  />
                  <label>Evento</label>
                  <Select
                    onChange={this.props.handleChangeAutocompleteRelatedEvent}
                    isMulti
                    closeMenuOnSelect={false}
                    name="related_event_id"
                    options={this.props.dataRelatedEvent}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Seleccione"
                  />

                  {/*<label>Evento relacionado</label>
                    <select name="related_event_id" 
                        className="form form-control"
                        onChange={this.props.onChangeFilter}
                        value={this.props.formValuesFilter.related_event_id}
                      >
                        <option value="">Seleccione</option>
                        {
                          this.props.events.map(item => (
                              <React.Fragment>
                                  <option value={item.id}>{item.name}</option>
                              </React.Fragment>
                          ))
                        }
                    </select>
                  */}
                </div>
                <div className={`col-md-3 ${isMobile ? "mb-3" : ""}`}>
                    <label>Fecha desde</label>
                      <input
                        className="form form-control"
                        type="date"
                        name="event_date_first"
                        onChange={this.props.onChangeFilter}
                        value={this.props.formValuesFilter.event_date_first}
                      />
                  </div>

                  <div className="col-md-3">
                    <label>Fecha hasta</label>
                      <input
                        className="form form-control"
                        type="date"
                        name="event_date_second"
                        onChange={this.props.onChangeFilter}
                        value={this.props.formValuesFilter.event_date_second}
                      />
                  </div>

                </div>
                <hr/>
                <div className="row">

             
                {/*<div className="col-md-6 mt-3 mb-3">
                   <label>Descripción</label>
                    <input
                      className="form form-control"
                      type="text"
                      name="description"
                      placeholder="Descripción"
                      onChange={this.props.onChangeFilter}
                      value={this.props.formValuesFilter.description}
                    />
                      </div>*/}

                <div className="col-md-12 mt-4 pl-0">
                    <div className="row">
                      <div className="col-md-12 text-right pl-0">
                        
                        <label onClick={this.close} className="btn btn-light float-right mr-2">Cerrar filtros</label>
                        <button onClick={this.props.onClick} className="btn btn-info float-right mr-3">Aplicar Filtro</button>
                      </div>
                    </div>
                </div>

                </div>
              </form>
          </div>
      </div>
    );
  }
}

export default FormFilter;
