import React, { Component } from 'react';
import LineChartIndicator from "../../../../GeneralComponents/Charts/LineChartIndicator"
import LineChartIndicatorMonth from "../../../../GeneralComponents/Charts/LineChartIndicatorMonth"
import NumberFormat from "react-number-format";

class layoutGerenciaRecaudo extends Component {

    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            formUpdate: [],
            formUpdateMonth: [],
            formUpdateHorizontal: [],
            formUpdateHorizontalMonth: [],
            matrix: [],
            index: 500,
            id: "",
            indicator_id: "",
            indexMonth: 500,
            indexHorizontal: 500,
            indexHorizontalMonth: 500,
            dataLine: [],
            dataLineAccumulated: [],
            dataLineMonth: [],
            dataLineMonthAccumulated: [],
            dataToday: [],
            dataMonth: [],
            todayMonth: "",
            todayDay: this.date()
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log("recive procps loayot")
        if (this.props !== nextProps) {
            this.renderAgain(nextProps)
        }

    }

    renderAgain = (nextProps) => {

        this.dataLine(nextProps.data)
        this.dataLineAccumulated(nextProps.data)
        this.dataLineMonth(nextProps.data)
        this.dataLineMonthAccumulated(nextProps.data)

        this.setState({
            data: nextProps.data,
            matrix: this.setMatrix(nextProps),
            todayMonth: this.getMonthText(nextProps.month),
            dataMonth: [
                nextProps.data[0].data_indicators[nextProps.month - 1].real,
                nextProps.data[1].data_indicators[nextProps.month - 1 ].real,
                nextProps.data[2].data_indicators[nextProps.month - 1].real,
                nextProps.data[2].data_indicators[nextProps.month - 1].accumulated,
                nextProps.data[2].data_indicators[nextProps.month - 1].target,
                nextProps.data[2].data_indicators[nextProps.month - 1].percentege

            ]

        })
    }

    componentDidMount = () => {
        this.renderAgain(this.props)
    }

    date = (fecha) => {
        let day = ((new Date().getDate()) - 1);
        let month = (new Date().getMonth());
        let year = (new Date().getFullYear());
        var d = new Date(year, month, day),
            months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[month] + " " + day + " " + 'del' + " " + year;
    }

    getMonthText = (fecha) => {
        let months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
        return months[fecha]
    }


    getPercentage = (val1, val2) => {
        let percentege = 0
        if (val1 != 0) {
            return percentege = val2 = !0 ? Math.round((val1 / val2) * 100, 0) : 0

        }
        else {
            return 0
        }

    }
    setMatrix = (nextProps) => {

        let matrix = []
        let vector = []
        nextProps.data.map((data) => {
            data.data_indicators[this.props.month].data.data.map((data, index) => {
                vector.push(data)
            })
            matrix.push(vector)
            vector = []
        })
        return matrix
    }


    setHorizontal = (index) => {

        let array = []
        this.state.data.filter((data) => data.total_column === false).map(data => {

            let getMonth = data.data_indicators[this.props.month].data.data[index]
            array.push(getMonth)


        })

        return array

    }

    setMonth = (index) => {

        console.log(index)
        let array = []
        console.log(this.state.data[index].data_indicators)
        let getMonth = [...this.state.data[index].data_indicators]
        getMonth.map(data => {

            let value = { real: data.real }
            array.push(value)


        })
        console.log(this.state.data[index].data_indicators)
        console.log(array)
        return array


    }
    setHorizontalMonth = (index) => {

        let array = []
        this.state.data.filter((data) => data.total_column === false).map(data => {

            let getMonth = { real: data.data_indicators[index].real, first_variable: data.data_indicators[index].first_variable, second_variable: data.data_indicators[index].second_variable }
            array.push(getMonth)


        })

        return array


    }



    updateFiels = (index, id, indicator_id) => {
        this.setState({
            index: index,
            indexHorizontal: 200,
            indexMonth: 200,
            indexHorizontalMonth: 200,
            id: id,
            indicator_id: indicator_id,
            formUpdate: this.state.matrix[index]
        })
    }

    updateFielsHorizontal = (index, id, indicator_id) => {
        this.setState({
            indexHorizontal: index,
            index: 200,
            indexMonth: 200,
            indexHorizontalMonth: 200,
            id: id,
            indicator_id: indicator_id,
            formUpdateHorizontal: this.setHorizontal(index)
        })
    }

    updateFielsMonth = (index, id, indicator_id) => {
        console.log("indexMOnth")
        this.setState({
            indexMonth: index,
            index: 200,
            indexHorizontal: 200,
            indexHorizontalMonth: 200,
            id: id,
            formUpdateMonth: this.setMonth(index)

        })
    }
    updateFielsHorizontalMonth = (index, id, indicator_id) => {
        this.setState({
            indexHorizontalMonth: index,
            index: 200,
            indexHorizontal: 200,
            indexMonth: 200,
            id: id,
            formUpdateHorizontalMonth: this.setHorizontalMonth(index)

        })
    }




    HandleChangeUpdate = value => (e) => {
        const values = this.state.formUpdate;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }


    HandleChangeUpdateHorizontal = value => (e) => {
        const values = this.state.formUpdateHorizontal;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }

    HandleChangeUpdateMonth = value => (e) => {
        const values = this.state.formUpdateMonth;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }

    HandleChangeUpdateHorizontalMonth = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].real = e.target.value;
        this.setState({
            values
        });
    }
    HandleChangeUpdateHorizontalMonthFirstVariable = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].first_variable = e.target.value;
        values[value].real = parseInt(e.target.value) + parseInt(values[value].second_variable);
        this.setState({
            values
        });
    }

    HandleChangeUpdateHorizontalMonthSecondVariable = value => (e) => {
        const values = this.state.formUpdateHorizontalMonth;
        values[value].second_variable = e.target.value;
        values[value].real = parseInt(e.target.value) + parseInt(values[value].first_variable);;
        this.setState({
            values
        });
    }






    HandleClickUpdate = () => {
        let data = { data: this.state.formUpdate }
        fetch(`/indicator/update_json_new/${this.state.id}/0/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260
                })
                /*               this.messageSuccess(data); */
            });
    }

    HandleClickUpdateHorizontal = () => {
        let data = { data: this.state.formUpdateHorizontal }
        fetch(`/indicator/update_json_horizontal/${this.state.data[0].indicator_id}/${this.state.indexHorizontal}/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260
                })
                /*               this.messageSuccess(data); */
            });
    }

    HandleClickUpdateMonth = () => {
        let data = { data: this.state.formUpdateMonth }
        fetch(`/indicator/update_json_month/${this.state.id}/0/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260,
                    indexMonth: 260

                })
                /*               this.messageSuccess(data); */
            });
    }


    HandleClickUpdateHorizontalMonth = () => {
        let data = { data: this.state.formUpdateHorizontalMonth }
        fetch(`/indicator/update_json_month_horizontal_recaudo/${this.state.data[0].indicator_id}/${this.state.indexHorizontalMonth}/${this.props.month + 1}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
                console.log("lñaskfjlañsfjs");
                this.setState({
                    index: 260,
                    indexHorizontal: 260,
                    indexMonth: 260,
                    indexHorizontalMonth: 260

                })
                /*               this.messageSuccess(data); */
            });
    }


    dataLine = (nextProps) => {


        let value = this.props.month
        let target = 0
        let array = [['x', 'datos'], [0, 0]]

        nextProps[2].data_indicators[value].data.data.map((data, index) => {

            let data_percent = data.real
            let data_percent_num = data.real
            if (data.real == 0) {
                data_percent = ""
            }
            if (data.real == 0) {
                data_percent_num = null
            }

            array.push([index + 1, data_percent_num])



        })


        this.setState((state, props) => ({
            dataLine: array
        }));
    }

    dataLineAccumulated = (nextProps) => {

        let value = this.props.month
        let target = nextProps[2].data_indicators[value].target

        let array = [['x', 'datos', '%'], [0, 0, target]]



        nextProps[2].data_indicators[value].data.data.map((data, index) => {

            let data_percent = data.accumulated
            let data_percent_num = data.accumulated
            if (data.real == 0) {
                data_percent = ""
            }
            if (data.real == 0) {
                data_percent_num = null
            }

            array.push([index + 1, data_percent_num, target])



        })


        this.setState((state, props) => ({
            dataLineAccumulated: array
        }));
    }

    dataLineMonth = (nextProps) => {

        let target = nextProps.target
        let array = [['x', 'datos', { role: "annotation", type: "string" }]]


        nextProps[2].data_indicators.map((data, index) => {

            let data_percent = this.numberToCurrency(data.real)
            let data_percent_num = data.real

            if (data.real == 0) {
                data_percent = ""
            }
            if (data.real == 0 && data.month_text != "ENE") {
                data_percent_num = null
            }

            array.push([data.month_text, data_percent_num, data_percent])



        })





        this.setState((state, props) => ({
            dataLineMonth: array
        }));
    }

    dataLineMonthAccumulated = (nextProps) => {

        let target = nextProps[2].target_year
        console.log(nextProps)
        console.log("nextProps")

        let array = [['x', 'datos', { role: "annotation", type: "string" }, '%']]



        nextProps[2].data_indicators.map((data, index) => {

            let data_percent = data.percentege + "%"
            let data_percent_num = data.percentege

            if (data.real == 0) {
                data_percent = ""
            }
            if (data.real == 0 && data.month_text != "ENE") {
                data_percent_num = null
            }

            array.push([data.month_text, data_percent_num, data_percent, data.target])



        })



        this.setState((state, props) => ({
            dataLineMonthAccumulated: array
        }));
    }


    numberToCurrency = (amount) => {
        amount = amount ? amount : 0
        var thousandsSeparator = ","
        var currencyNum = "";
        var amountString = amount.toString();
        var digits = amountString.split("");

        var countDigits = digits.length;
        var revDigits = digits.reverse();

        for (var i = 0; i < countDigits; i++) {
            if ((i % 3 == 0) && (i != 0)) {
                currencyNum += thousandsSeparator + revDigits[i];
            } else {
                currencyNum += digits[i];
            }
        };

        var revCurrency = currencyNum.split("").reverse().join("");

        var finalCurrency = "$" + revCurrency;

        return finalCurrency;
    }

    getInput = (indexP, index, data) => {

        if (indexP == this.state.index) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdate[index].real}
                    onChange={this.HandleChangeUpdate(index)}
                    className="form form-control"
                />)
        }
        else if (index == this.state.indexHorizontal) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdateHorizontal[indexP].real}
                    onChange={this.HandleChangeUpdateHorizontal(indexP)}
                    className="form form-control"
                />)
        }
        else {
            return (
                <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data} /></p>
            )

        }
    }

    getInputMonth = (indexP, index, data) => {
        console.log(index)
        console.log(indexP)
        if (indexP == this.state.indexMonth) {
            return (
                <input
                    type="text"
                    name="real"
                    value={this.state.formUpdateMonth[index].real}
                    onChange={this.HandleChangeUpdateMonth(index)}
                    className="form form-control"
                />)
        }
        else if (index == this.state.indexHorizontalMonth) {
            return (
                <React.Fragment>
                    <input
                        type="text"
                        name="real"
                        value={this.state.formUpdateHorizontalMonth[indexP].real}
                        onChange={this.HandleChangeUpdateHorizontalMonth(indexP)}
                        className="form form-control"

                    />
                    {/*                     <input
                        type="text"
                        name="first_variable"
                        value={this.state.formUpdateHorizontalMonth[indexP].first_variable}
                        onChange={this.HandleChangeUpdateHorizontalMonthFirstVariable(indexP)}
                        className="form form-control"
                    />
                    <input
                        type="text"
                        name="second_variable"
                        value={this.state.formUpdateHorizontalMonth[indexP].second_variable}
                        onChange={this.HandleChangeUpdateHorizontalMonthSecondVariable(indexP)}
                        className="form form-control"
                    /> */}
                </React.Fragment>
            )
        }
        else {
            return (
                <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data} /></p>
            )

        }
    }


    render() {
        console.log("entre en layou")
        console.log(this.state.data.length)
        console.log(this.props.data)
        if (this.props.data.length == 0) {
            return (
                <div></div>
            );
        }
        return (
            <React.Fragment>
                <div className="row indicators">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body show-values-indicator-ventas">
                                <h4> {this.state.todayMonth}<span style={{ fontSize: "12px", marginLeft: "5px" }}></span> </h4>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4">Recuperación</div>
                                    <div className="col-md-4">Cuentas por cobrar</div>
                                    <div className="col-md-4">Recuperación Total</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 value-venta"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataMonth[0]} /></div>
                                    <div className="col-md-4 value-venta"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataMonth[1]} /></div>
                                    <div className="col-md-4 value-venta"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.state.dataMonth[3]} /></div>
                                </div>
                                <hr />
                                <div className="row">

                                    <div className="col-md-6 ">Presupuestado mes Actual</div>
                                    <div className="col-md-6"> Cumplimiento mes Actual</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 value-venta">{this.state.dataMonth[4]}%</div>
                                    <div className="col-md-6 value-venta">{this.state.dataMonth[5]}%</div>

                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="card">
                            <div className="card-body">

                                <div className="table-vertical-container">
                                    <div className="day">
                                        <div className="tr">MES</div>
                                        {this.props.data[0].data_indicators.map((data, index) => (

                                            <div style={{ background: index == (new Date().getMonth()) ? "#3e69d8" : "whitesmoke" }} className="td td-info">
                                                <div style={{ background: index - 1 == (new Date().getMonth() -  1 ) ? "#3e69d8" : "whitesmoke", color: index == (new Date().getMonth()) ? "white" : "#1ca7f9" }} className="link-line" onClick={() => this.updateFielsHorizontalMonth(index, data.id, data.indicator_id)} >{data.month_text}</div>
                                                {index == this.state.indexHorizontalMonth && (
                                                    <div className="indicators-check-vertical">
                                                        <React.Fragment>
                                                            <i
                                                                className="fas fa-check-circle true-btn ml-1"
                                                                onClick={() => this.HandleClickUpdateHorizontalMonth()}
                                                                style={{ marginRight: "5px" }}
                                                            >
                                                            </i>
                                                            <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexHorizontalMonth: 260 })}></i>
                                                        </React.Fragment>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        {/* <div className="indicator-subtotal">Total</div> */}

                                    </div>
                                    {this.props.data.filter((data) => data.total_column === false).map((data, indexPM) => (
                                        <div className="day">
                                            {indexPM == this.state.indexMonth && (
                                                <div className="indicators-checks">

                                                    <React.Fragment>
                                                        <i
                                                            className="fas fa-check-circle true-btn ml-1"
                                                            onClick={() => this.HandleClickUpdateMonth()}
                                                            style={{ marginRight: "5px" }}
                                                        >
                                                        </i>
                                                        <i className="fas fa-times-circle false-btn" onClick={() => this.setState({ indexMonth: 260 })}></i>
                                                    </React.Fragment>
                                                </div>
                                            )}
                                            <div className="tr" /* onClick={() => this.updateFielsMonth(indexPM, data.id, data.indicator_id)} */>{data.name}</div>
                                            {data.data_indicators.map((data, indexPMC) => (
                                                <div className="td">
                                                    {this.getInputMonth(indexPM, indexPMC, data.real)}

                                                </div>
                                            ))}
                                            {/*                                                 <div className="indicator-subtotal"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.accumulated} /></div>
 */}                                            </div>
                                    ))}

                                    <div className="day">
                                        <div className="tr">ACUMULADO</div>
                                        {this.props.data.filter((data) => data.total_column === true)[0].data_indicators.map((data, index) => (
                                            <div className="td">

                                                <p><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={data.real != 0 ? data.accumulated : 0} /></p>

                                            </div>
                                        ))}
                                        {/*                                             <div className="indicator-subtotal"><NumberFormat displayType={"text"} thousandSeparator={true} prefix={'$'} value={this.props.data.filter((data) => data.total_column === true)[0].accumulated} />
                                            </div> */}
                                    </div>
                                    <div className="day">
                                        <div className="tr" >% RECAUDO</div>
                                        {this.props.data.filter((data) => data.total_column === true)[0].data_indicators.map((data, index) => (
                                            <div className="td">
                                                <p>{data.percentege}%</p>


                                            </div>
                                        ))}
                                        {/*                                         <div className="indicator-subtotal">{this.props.data.filter((data) => data.total_column === true)[0].percentage}%</div>
 */}                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h5 style={{ color: "#3e68d8" }}>Comportamiento % de recaudo mensual</h5>
                                <hr />
                                <LineChartIndicatorMonth data={this.state.dataLineMonthAccumulated} />
                            </div>
                        </div>
                        <br />
                        <div className="card">
                            <div className="card-body">
                                <h5 style={{ color: "#3e68d8" }}>Comportamiento de recaudo</h5>
                                <hr />
                                <LineChartIndicatorMonth data={this.state.dataLineMonth} />
                            </div>
                        </div>

                    </div>
                </div>



            </React.Fragment>
        );
    }
}

export default layoutGerenciaRecaudo;



