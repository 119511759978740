import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

class UploadFile extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            isLoaded: false,
            nameFile: "",
            form: {
                file: {}
            }
        }
    }

    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    file: file
                },
  
                nameFile: file.path,
            })
        ));
    };


    //import
    HandleClickFile = () => {
        this.setState({ isLoaded: true })
        const formData = new FormData();
        formData.append("file", this.state.form.file)

        fetch(`/formatos/format_categories/${this.props.format_category.id}/formats/${this.props.format.id}/upload_file/${this.props.survey_parent_id}`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadData();
            this.props.toggle();
            this.props.msgUpload(data.message);
            setTimeout(()=>{
              this.props.closeMsgUpload();
            }, 4000)
            this.setState({ 
                form: {
                    file: {},
                },

                isLoaded: false,
                nameFile: "",
            })
        });
    }


    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
                                <div className="alert alert-info col-md-12" role="alert">
                                 IMPORTANTE: Antes de realizar la importación de registros desde Excel, es fundamental que descargues la plantilla correspondiente. Puedes encontrar la opción de descargar la plantilla en el menú de importar/exportar.
                                </div>

                                            <div className="col-md-12">
                                                <Dropzone onDrop={(files) => this.handleFile(files)}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <div
                                                            {...getRootProps({
                                                                className: 'dropzone',
                                                            })}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <p>{`${(this.state.nameFile != "" ? this.state.nameFile : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                            </div>

                                </div>

                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button 
                                    className="btn-shadow btn btn-info" 
                                    onClick={() => this.HandleClickFile()}
                                    disabled={this.state.isLoaded}
                                >
                                    
                                    {this.state.isLoaded ? (
                                        <div className="loader-files"></div>
                                    ) : (
                                        <React.Fragment>
                                            Subir archivo
                                        </React.Fragment>
                                    )}
                                </button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default UploadFile;