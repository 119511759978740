import React, { Component } from 'react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';
import FormCreateCustomView from '../FormatCategories/FormatCustomView/FormCreate';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            ErrorValues: true,
            format_custom_view_id: "",
            format_custom_view: {},

            //custom view checkList
            checkedItemsCustomView: new Map(),
            checkboxesCustomView: [],
            modulo_custom_view_ids: [],
            modulos_custom_view: [],

            form: {
                format_id: this.props.format.id,
                name: "",
                format_category_id: this.props.format_category.id,
                icon_id: "",
                type_view: "dashboard",
                custom_view_permission_ids: [],
            },
        }
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                format_target_id: selectedOption.value
            }
        });
    };

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format_custom_view => {
        this.setState({
            data: this.state.data.map(item => {
                if (format_custom_view.id === item.id) {
                    return {
                        ...item,
                        custom_view_permissions: format_custom_view.custom_view_permissions, 
                        name: format_custom_view.name, 
                    }
                }
                return item;
            })
        });
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,
            format_custom_view_id: "",

            //custom view checkList
            checkedItemsCustomView: new Map(),
            checkboxesCustomView: [],
            modulo_custom_view_ids: [],
            modulos_custom_view: [],

            form: {
                ...this.state.form,
                name: "",
                icon_id: "",
                custom_view_permission_ids: [],
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_custom_views/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.setState({ data: this.state.data.filter((e) => e.id != id) })
                    this.messageSuccess(response)
                });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/formatos/get_format_custom_views/${this.props.format.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    validationFormatCustomView = () => {
        if (this.state.form.name != "" &&
            this.state.form.custom_view_permission_ids.length >= 1 &&
            this.state.form.format_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.validationFormatCustomView() == true) {
            const form = {
                format_id: this.state.form.format_id,
                name: this.state.form.name,
                format_category_id: this.state.form.format_category_id,
                icon_id: this.state.form.icon_id,
                type_view: this.state.form.type_view,
                custom_view_permission_ids: this.state.form.custom_view_permission_ids,
                header: this.props.categories,
            }

            if (!this.state.modeEdit)
                fetch(`/formatos/format_custom_views`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false });
                    this.updateData(data.register);
                    this.messageSuccess(data);
                });
            else {
                fetch(`/formatos/format_custom_views/${this.state.format_custom_view_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.clearValues();
                    this.setState({ modal: false });
                    this.updateItem(data.register);
                    this.messageSuccess(data);
                });
            }
        }
    }


    edit = (format_custom_view) => {
        this.setState({
            modal: true,
            modeEdit: true,
            format_custom_view_id: format_custom_view.id,
            form: {
                ...this.state.form,
                name: "",
                icon_id: "",
                custom_view_permission_ids: [],
            },
        })
    }

    handleChangeAccionsCustomView = (e) => {
        const item = e.target.value;
        const isChecked = e.target.checked;
        const array = this.state.form.custom_view_permission_ids

        this.setState(prevState => ({
            checkedItemsCustomView: prevState.checkedItemsCustomView.set(item, isChecked)
        }));

        console.log("items", item)

        if (isChecked) {

            array.push(parseInt(item))
            this.setState({
                form: {
                    ...this.state.form,
                    custom_view_permission_ids: array
                },
            })

            console.log("añadiendo items")
        } else {
            console.log("quitando items")

            var arr = array
            var index = array.indexOf(parseInt(item))
            delete arr[index]

            console.log("delete array")
            console.log(arr.filter(item => item != null))

            this.setState({
                form: {
                    ...this.state.form,
                    custom_view_permission_ids: arr.filter(item => item != null),
                },
            })
        }
    };

    handleChangeSelectAllCustomView = (e, modulo) => {
        const checkedItems = this.state.checkedItemsCustomView;
        const isChecked = e.target.checked;

        const modulesIds = this.state.modulo_custom_view_ids;
        const defaultValues = this.state.form.custom_view_permission_ids

        if (isChecked) {

            this.setState(
                {

                    modulo_custom_view_ids: [...this.state.modulo_custom_view_ids, modulo.id],
                    modulos_custom_view: [...this.state.modulos_custom_view.filter(item => item.id != modulo.id), modulo],
                }

                , function () {
                    var jeje = this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions
                    this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions.map(value => {
                        checkedItems.set(`${value.id}`, true)
                    })
                    this.setState(
                        {
                            checkedItemsCustomView: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(Number(key));
                                }
                            }
                            this.setState(
                                {
                                    form: {
                                        ...this.state.form,
                                        custom_view_permission_ids: array_final,
                                    },

                                })
                        }
                    )
                });




        } else {
            const newSelectCheckedItems = new Map();
            var arr = modulesIds
            var index = modulesIds.indexOf(parseInt(modulo.id))
            delete arr[index]

            var arrModulos = this.state.modulos_custom_view
            var indexModulos = 0

            /* 
                        this.state.modulos.filter(lista => lista.id != modulo.id).map((modulo) => (
                            modulo.formats_permissions.filter(value => value.name != "Editar todos" && value.name != "Eliminar todos").map((item) => (
                                newSelectCheckedItems.set(`${item.id}`, true) //true this.state.checkedItems.has(item.id)
                            ))
                        )) */

            this.setState(
                {

                    modulo_custom_view_ids: arr.filter(item => item != null),
                    modulos_custom_view: arrModulos.filter(item => item != null)
                }

                , function () {
                    var jeje = this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions
                    this.state.modulos_custom_view.filter(lista => lista.id == modulo.id)[0].custom_view_permissions.map(value => {
                        checkedItems.set(`${value.id}`, false)
                    })
                    this.setState(
                        {
                            checkedItemsCustomView: checkedItems

                        }, function () {
                            var array_final = []
                            for (const [key, value] of checkedItems.entries()) {
                                if (value === true) {
                                    array_final.push(key);
                                }
                            }
                            this.setState(
                                {
                                    form: {
                                        ...this.state.form,
                                        custom_view_permission_ids: array_final,
                                    },

                                })
                        }
                    )

                });

        }
    };

    edit = (format_custom_view, format_category) => {
        const arrayPermissions = format_custom_view.custom_view_permissions;
        const arrayNewPermissions = [];
        const array = [];
        let map = new Map();

        arrayPermissions.map((permission, index) => (
            arrayNewPermissions.push(permission.id)
        ));

        this.props.custom_view_permissions.forEach(element => {
            arrayPermissions.forEach(element_accion => {
                if (element.id == element_accion.id) {
                    map.set(element_accion.id.toString(), true)
                }
            });
        })

        this.setState({
            checkedItemsCustomView: map,
            format_custom_view_id: format_custom_view.id,
            current_format_category: format_category,
            modeEdit: true,
            modal: true,

            last_category_id: format_category.id,

            form: {
                ...this.state.form,
                name: format_custom_view.name,
                custom_view_permission_ids: arrayNewPermissions,
                icon_id: format_custom_view.icon_id,
            },
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <ModalBody>

                        {this.state.modal && (
                            <FormCreateCustomView
                                //modal props
                                backdrop={"static"}
                                modal={this.state.modal}
                                toggle={this.toogle}
                                title={this.state.modeEdit ? `Actualizar estadística` : `Crear estadística`}

                                //form props
                                formValues={this.state.form}
                                submitForm={this.HandleClick}
                                onChangeForm={this.HandleChange}
                                nameSubmit={this.state.modeEdit ? "Actualizar" : "Crear"}
                                errorValues={this.state.ErrorValues}

                                // select 

                                handleChangeAutocompleteFormat={this.handleChangeAutocompleteFormat}
                                formatos={this.state.formatos}
                                selectedOption={this.state.selectedOptionFormat}

                                icons={this.props.icons}
                                modeEdit={this.state.modeEdit}
                                setIconForm={this.setIconFormatCustomView}

                                //select format category
                                selectedOptionFormatCategory={this.state.selectedOptionFormatCustomView}
                                handleChangeAutocompleteFormatCategory={this.handleChangeAutocompleteFormatCustomView}
                                categories={this.state.categories}

                                section_custom_views={this.props.section_custom_views}

                                //ckeckcontainer

                                checkedItems={this.state.checkedItemsCustomView}
                                handleChangeAccions={this.handleChangeAccionsCustomView}
                                checkboxes={this.state.checkboxesCustomView}
                                // section_custom_views={this.props.section_custom_views}
                                handleChangeSelectAll={this.handleChangeSelectAllCustomView}
                                modulo_ids={this.state.modulo_custom_view_ids}
                                position={this.props.position}
                            />
                        )}

                        <div className="col-md-12 mb-3 text-right pr-0">
                            {!this.state.modal && (
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.toogle("new")}
                                >
                                    Abrir formulario
                                </button>
                            )}
                        </div>

                        <div className="card ui-index-configCharts__container-card">                                                    
                            <div className="background-gray ui-index-configCharts__container_gray">
                                {this.state.data.length >= 1 ? (
                                <div className="ui-index-configCharts__container-charts-info">
                                    {this.state.data.map((format_custom_view, index) => (
                                        <React.Fragment>
                                            <div key={format_custom_view.id}  className="ui-index-configCharts__container_cards_charts">
                                                <div className='ui-index-configCharts-cardinfo-charts'>
                                                    <div className='ui-index-configCharts-options'>
                                                        <UncontrolledTooltip target={`bw_edit_Chart${format_custom_view.id}`}>{'Editar'}</UncontrolledTooltip>
                                                        <i className="fas fa-pencil-alt" id={`bw_edit_Chart${format_custom_view.id}`} onClick={() => this.edit(format_custom_view, format_custom_view.format_category)}></i>
                                                        <UncontrolledTooltip target={`bw_delete_Chart${format_custom_view.id}`}>{'Eliminar'}</UncontrolledTooltip>
                                                        <i className="fas fa-trash-alt" id={`bw_delete_Chart${format_custom_view.id}`} onClick={() => this.delete(format_custom_view.id)}></i>
                                                    </div>
                                                    <a className='ui-index-configCharts-cardinfo-text__container'
                                                        href={`/formatos/format_custom_views/${format_custom_view.token}/format_category/${format_custom_view.format_category.token}`}
                                                        data-turbolinks="true"
                                                        target="_blank">
                                                            <UncontrolledTooltip target={`bw_config_Chart${format_custom_view.id}`}>{'Ir a la configuración'}</UncontrolledTooltip>
                                                            <i className="fas fa-chart-pie" id={`bw_config_Chart${format_custom_view.id}`}></i>
                                                            <UncontrolledTooltip target={`na${format_custom_view.id}`} placement='top'>{format_custom_view.name}</UncontrolledTooltip>
                                                            <span id={`na${format_custom_view.id}`}>{format_custom_view.name}</span>
                                                    </a>
                                                               
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                                        ) : (
                                            <div className="ui-index-configCharts_no_Charts__container">
                                                <div className="ui-index-configCharts_noCharts_image">
                                                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/48968/pie-chart.png" alt="No hay Gráficas" />
                                                </div>
                                                <div className="ui-index-configCharts_text_no_charts">
                                                    No hay Indicadores de gráficas creados
                                                </div>
                                            </div>
                                        )}
                                    
                            </div>
                        </div>


                    </ModalBody>

                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;
