import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';
import NotificationDocument from '../components/settings/Tenants/NotificationDocument';
import NotificationTask from '../components/settings/Tenants/NotificationTask';
import TaskIndexCard from './TaskIndexCard';
import SoftwareChangeIndex from './SoftwareChangeIndex';
import {isMobile} from 'react-device-detect';
// import QrReader from '../GeneralComponents/QrReader/QrReader';
import FormUpgradePlan from '../components/settings/Users/FormUpgradePlan';


import { element } from 'prop-types';

const HeaderMenu = (props) => {
    const [modal, setModal] = useState(false);
    const [changeActive, setChangeActive] = useState(false);
    const [favorite, setFavorite] = useState(false);
    const [icons, setIcons] = useState(false);
    const [hasNewFeature, setHasNewFeature] = useState(props.user.has_new_feature);

    const token = document.querySelector("[name='csrf-token']").content;
    
    const showIcon = () => {
        const icon = document.getElementById("image-user");
        icon.style.display = block;
    }

    const toogle = (from) => {
        if (from == "new") {
            setModal(true);
        } else {
            setModal(false);
        }
    } 

       
    const activeChanges = (changes, tasks) => {
        setChangeActive(changes);
        setFavorite(tasks);
        
        changesIcons();
    }

    const changesIcons = () => {
        if (icons == true){
            setIcons(false)
        } else{
            setIcons(true);
        }
    }
    
    const updateHasNewFeature = () => {
        fetch(`/update_user_has_new_feature`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setHasNewFeature(data.register);
        });
    }

    return (
        <React.Fragment>
            <div className='row'>
                <div className="app-header-left">
                    <ul className="header-megamenu nav">
                        <li className="nav-item ui-btn-Empresa_header" id="step6" data-step='6'>
                            {props.authorization_company && (
                                <a href={`/settings/tenant`}
                                    className="nav-link" data-turbolinks='true'>
                                    <i className="far fa-building"></i> Licencia
                                </a>
                            )}
                        </li>

                        <li className="nav-item ui-btn-Fordata_header" id="step7" data-step='7'>
                            {props.authorization_formatos && props.show_module_fordata && (
                                <a href={`${props.path_formatos}`}
                                    className="nav-link" data-turbolinks='true'>
                                    <i className="metismenu-icon far fa-lightbulb"></i> Fordata
                                </a>
                            )}
                        </li>

                        {props.plan_name == "beefree" && (
                            <li className="nav-item ui-btn-Fordata_header" style={{display:"flex"}}>
                                <button className="nav-link demo-container-upgrade" onClick={() => toogle("new")}><i className="fas fa-arrow-circle-up mr-1"style={{color: 'white'}}></i>Mejora tu plan</button>
                            </li>
                        )}

                        {props.current_user_tenant.tenant_state == "demo" && props.current_user_tenant.end_demo != null && (
                            <li className="nav-item ui-btn-Fordata_header" style={{display:"flex"}}>
                                <div className="nav-link demo-container" onClick={() => toogle("new")}><i className='fas fa-user-clock mr-1' style={{color: 'white'}}></i>Tu demo terminará en {props.demo_end}</div>
                                <button className="nav-link demo-container-upgrade" onClick={() => toogle("new")}><i className="fas fa-arrow-circle-up mr-1"style={{color: 'white'}}></i>Mejora tu plan</button>
                            </li>
                        )}

                        {props.current_user_tenant.tenant_state == "alert" && props.current_user_tenant.end_demo != null && (
                            <li className="nav-item ui-btn-Fordata_header" style={{display:"flex"}}>
                                <div className="nav-link demo-container-alert" onClick={() => toogle("new")}><i className="fas fa-ban mr-1" style={{color: 'white'}}></i>Plan vencido <span style={{fontSize:"10px", marginLeft:"5px"}} > (En {props.demo_end + 15} días pasara a beefree</span>)</div>
                                <button className="nav-link demo-container-upgrade" onClick={() => toogle("new")}><i className="fas fa-arrow-circle-up mr-1"style={{color: 'white'}}></i>Mejora tu plan</button>
                            </li>

                        )}

                        {modal && (
                            <FormUpgradePlan
                                backdrop={"static"}
                                modal={modal}
                                toggle={toogle}
                                title="Mejorar tu Plan"
                                messageModal={[<span className='ui_formUpgrade_Plan_message_first'>Mejora tu plan actual</span>," selecciona un plan más avanzado"]}
                                plans={props.plans}
                                current_plan={props.current_plan}
                            />
                        )}
                
                    </ul>
                </div>

                <div className="app-header-right ui-headerMenu-container-dropdowns">
                    
                        <NotificationTask
                            estados={props.estados}
                            users={props.users}
                            user={props.user}
                            procesos={props.procesos}
                            company={props.company}
                        />

                        <NotificationDocument />

                    <div className="logo-bottom ui-headerMenu-logo-bottom">
                        <React.Fragment>

                            {!isMobile && (
                                <React.Fragment>
                                    <UncontrolledTooltip target='TooltipDemo' placement='left'>Novedades</UncontrolledTooltip>
                                    <button type="button" id="TooltipDemo" data-toggle="tooltip" data-trigger="hover" data-placement="left" className="btn-open-options btn-shadow btn btn-link ui-headerMenu-btn-bullhorn" onClick={() => { activeChanges(true, false); updateHasNewFeature(); } }>
                                        <span className={`${hasNewFeature && 'icon-wrapper icon-wrapper-alt rounded-circle'}`}>
                                            <span className={`${hasNewFeature && 'icon-wrapper-bg bg-danger'}`}></span>
                                                <i className={`fas fa-bullhorn ${hasNewFeature ? 'ui-headerMenu-icon-changesoftware-notification icon text-danger icon-anim-pulse ': 'ui-headerMenu-icon-changesoftware'}`}></i>
                                                {hasNewFeature && (
                                                    <span className="badge badge-dot badge-dot-sm badge-danger">10</span>
                                                )}
                                        </span>
                                    </button>
                                </React.Fragment>
                            )}

                            <UncontrolledDropdown className='btn-group ui-headerMenu-help_center-dropdown' id="step8" data-step='8'>
                                <DropdownToggle className="btn btn-link ui-headerMenu-help_center" id='bw-help-center-button'>
                                <UncontrolledTooltip target='bw-help-center-button' placement='left'>Centro de Ayudas</UncontrolledTooltip>
                                    <i className="far fa-question-circle icon-format"></i>
                                </DropdownToggle>
                                <DropdownMenu className='dropdown-menu dropdown-menu-right dp-pdf' id='down-header_centerHelp'>
                                    <DropdownItem
                                        className="centerHelp-header"
                                        data-turbolinks="true"
                                        href={props.current_plan.name == "beefree" ? "https://bee-wo.com/formatos/generate_questions/UaqQhCJpWLgNMPq3hnbMtPng/0/0" : "https://bee-wo.com/formatos/generate_questions/gr4Ryw1GMwMbfq8J5FUuKrKr"}
                                        target="_blank"
                                    >
                                        Ticket de soporte
                                    </DropdownItem>
                                    <DropdownItem
                                        className="centerHelp-header"
                                        data-turbolinks="true"
                                        href="/settings/tutorials"
                                        target="_blank"
                                    >
                                        Tutoriales
                                    </DropdownItem>

                                </DropdownMenu>
                            </UncontrolledDropdown>

                            {props.a || props.b ? (
                                <React.Fragment>

                                    {props.authorization_company && (
                                        <a href={`/settings/tenants/${props.current_user_tenant.token}`}> <i className="far fa-building icon-format ml-3"></i></a>
                                    )}
                                    
                                    {props.authorization_formatos && (
                                        <a href="/formatos/format_categories/"><i className="far fa-lightbulb icon-format ml-3"></i></a>
                                    )}

                                    {props.plan_name == "beefree" && (
                                        <li className="nav-item ui-btn-Fordata_header" style={{display:"flex", marginTop: '0.5rem'}}>
                                            <button className="nav-link demo-container-upgrade" onClick={() => toogle("new")}><i className="fas fa-arrow-circle-up"></i></button>
                                        </li>
                                    )}
                                    {props.current_user_tenant.tenant_state == "demo" && props.current_user_tenant.end_demo != null && (
                                        <li className="nav-item ui-btn-Fordata_header" style={{display:"flex", marginTop: '0.5rem'}}>
                                            <div className="nav-link demo-container" onClick={() => toogle("new")}><i className='fas fa-user-clock mr-1'></i>{props.demo_end}</div>
                                            <button className="nav-link demo-container-upgrade" onClick={() => toogle("new")}><i className="fas fa-arrow-circle-up"></i></button>
                                        </li>
                                    )}
                                    {props.current_user_tenant.tenant_state == "alert" && props.current_user_tenant.end_demo != null && (
                                        <li className="nav-item ui-btn-Fordata_header" style={{display:"flex", marginTop: '0.5rem'}}>
                                            <div className="nav-link demo-container-alert" onClick={() => toogle("new")}><i className="fas fa-ban"></i></div>
                                            <button className="nav-link demo-container-upgrade" onClick={() => toogle("new")}><i className="fas fa-arrow-circle-up"></i></button>
                                        </li>
                
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <UncontrolledTooltip target='step9'>Logo</UncontrolledTooltip>
                                    <a
                                        href={`/settings/tenants/${props.current_user_tenant.token}`}
                                        data-turbolinks="true"
                                        id="step9" data-step='9'
                                        className='ui-headerMenu-img_logo_users'
                                    >
                                        <img className='ml-3' src={`${props.current_user_tenant.logo.url !== null ? props.current_user_tenant.logo.url : 'https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/1856/Mensaje_logo.png'} `} />
                                    </a>
                                </React.Fragment>
                            )}
                        </React.Fragment>

                        <div className="header-btn-lg pr-0" id="step10" data-step='10'>
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                        <UncontrolledDropdown className='btn-group'>
                                            <DropdownToggle>
                                                <UncontrolledTooltip target='bw-user-profiler_tooltip'>Perfil de Usuario</UncontrolledTooltip>
                                                <img width='42' id='bw-user-profiler_tooltip' className='rounded-circle' src={`${props.current_user.avatar.url != null ? props.current_user.avatar.url : "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRuEG5mQKFGi9hNgrdBJnEyBfw2Kl0JNXwoHiXjVeKQemKOQqFe"}`} style={{ height: '44px' }} />
                                                <i className="fa fa-angle-down ml-2 opacity-8" id='bw-user-profiler_tooltip'></i>
                                            </DropdownToggle>
                                            <DropdownMenu className='dropdown-menu-header' style={{ padding: '0px', width: '350px' }}>
                                                <div className="dropdown-menu-header-inner bg-info" style={{ padding: '2rem 0.5rem' }}>
                                                    <div className="menu-header-image opacity-10" style={{ backgroundImage: "url('assets/images/dropdown-header/city3.jpg')", left: '7px', top: '12px' }}>
                                                        <div className="menu-header-content text-left">
                                                            <div className="widget-content p-0">
                                                                <div className="widget-content-wrapper">
                                                                    <div className="widget-content-left" style={{ display: 'flex' }}>
                                                                        <img width='42'
                                                                            className='rounded-circle'
                                                                            style={{ height: '44px' }}
                                                                            src={`${props.current_user.avatar.url != null ? props.current_user.avatar.url : "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRuEG5mQKFGi9hNgrdBJnEyBfw2Kl0JNXwoHiXjVeKQemKOQqFe"}`}
                                                                            alt=""
                                                                            onMouseOver={() => showIcon} />

                                                                        <label htmlFor="file" id='icon-select'>
                                                                            <i className="fas fa-pencil-alt icon-imagen"></i>
                                                                        </label>

                                                                        <div className="widget-content-left">
                                                                            <div className="widget-heading ml-4" style={{ textTransform: 'uppercase', maxWidth: '250px', wordBreak: 'break-all'  }}>
                                                                                {props.current_user.username}
                                                                            </div>
                                                                            <div className="widget-subheading opacity-8 ml-4">
                                                                                {props.user_position_name}
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="widget-content-right ml-5">
                                                                            <a href={props.destroy_user_session_path} className="btn-pill btn-shadow btn-shine btn btn-focus">Salir</a>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="scroll-area-xs" style={{ minHeight: '255px' }}>
                                                    <div className="scrollbar-container ps">
                                                        <ul className="nav flex-column">
                                                            <DropdownItem>
                                                                <li className="nav-item">
                                                                    <a href={props.edit_password} className="nav-link" data-turbolinks='true'>
                                                                        Editar Contraseña
                                                                    </a>
                                                                </li>
                                                            </DropdownItem>
                                                            <DropdownItem>
                                                                <li className="nav-item" style={{ width: '315px' }}>
                                                                    <a href={`${props.task_tasks_path}?task=MY`} className="nav-link" data-turbolinks='true'>
                                                                        Mis Tareas
                                                                        <div className="ml-auto badge badge-pill badge-info" style={{ marginRight: '-29px' }}>
                                                                            {props.task_count}
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </DropdownItem>

                                                            <DropdownItem>
                                                                <li className="nav-item" style={{ width: '315px' }}>
                                                                    <a href={`/users/update_firm`} className="nav-link" data-turbolinks='true'>
                                                                        Actualizar firma
                                                                    </a>
                                                                </li>
                                                            </DropdownItem>

                                                            <DropdownItem>
                                                                <li className="nav-item">
                                                                    <a href={props.root_path} className="nav-link">
                                                                        Mis Favoritos
                                                                    </a>
                                                                </li>
                                                            </DropdownItem>
                                                            <DropdownItem className='ui-headerMenu-item_exit'>
                                                                <a href={props.destroy_user_session_path} style={{width: '100%', height: '100%' }} className='ui-headerMenu-item_exit-link'>
                                                                    <li className='nav-item'>
                                                                            Salir <i className="fas fa-sign-out-alt" style={{ fontSize: '19px'}}></i>
                                                                    </li>
                                                                </a>
                                                            </DropdownItem>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {!isMobile && 
                <div className="ui-theme-settings">
                    <UncontrolledTooltip target='ui-favorite' placement='left'>{icons ? ('Cerrar'):('Ver Favoritos')}</UncontrolledTooltip>
                    <button type="button" id="ui-favorite" data-toggle="tooltip" data-trigger="hover" data-placement="left" className="btn-open-options btn-shadow btn btn-primary" onClick={() => activeChanges(false, !favorite)}>
                        <i className={`${icons ? ('fas fa-times'):('fas fa-star')} fa-w-16 fa-2x`} style={{marginTop: `${icons ? ('14px') : ('12px')}`}} id="favorite"></i>                
                    </button>
                    
                    <div className="theme-settings__inner">
                        <div className="scrollbar-container">
                            <div className="theme-settings__options-wrapper">
                                <div className="p-3">
                                    {(favorite && !changeActive) && (
                                        <TaskIndexCard
                                            users={props.users}
                                            user={props.user}
                                            procesos={props.procesos}
                                            task_boards={props.task_boards}
                                            favorite={favorite}
                                            changeActive={changeActive}

                                            company={props.company}
                                            imageUpload={props.imageUpload}
                                            key_text={props.key_text}
                                        />
                                    )}

                                    {(!favorite && changeActive) && (
                                        <SoftwareChangeIndex 
                                            changes={props.changes}
                                            date={props.date}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            } 
        </React.Fragment>
    )

}

export default HeaderMenu;
WebpackerReact.setup({ HeaderMenu });