import React, { Component } from 'react';
import Calendar from '../components/tasks/tasksCard/Calendar';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"
import FormFilter from '../components/tasks/tasksCard/FormFilter';

class CalendarTask extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            showFilter: false,
            isFiltering: false,
            urlFilter: "",
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    handleClickShowFilter = () => {
        this.setState({
            showFilter: (this.state.showFilter == false ? true : false)
        })
    }

    handleClickCancel = () => {
        this.setState({ showFilter: false })
        this.loadData();
    }


    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
            if (format.id === item.id) {
              return { ...item, 
                name: format.name,
                section_name: format.section_name,
                order_number: format.order_number,
                positions: format.positions
              }
            }
            return item;
          })
        });
    }

    loadData = () => {
        fetch(`/task/get_tasks_test/${this.props.from}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.data)
            const array = []

            data.data.map((item) => (
                array.push({ title: `${item.name}`, start: new Date(item.due_date), id: item.id  })
            ))
    
            this.setState({
                data: array
            })
        });
    }

    updateDataFilter = (data) => {
        const array = []

        data.data.map((item) => (
            array.push({ title: `${item.name}`, start: new Date(item.due_date), id: item.id  })
        ))

        this.setState({
            data: array
        })
    }

    updateStates = (state_is_filtering, state_is_loaded, url) => {
        this.setState({
            urlFilter: url,
            isFiltering: state_is_filtering,
            isLoaded: state_is_loaded,
        })
    }

    updateStateIsLoaded = (state) => {
        this.setState({
            isLoaded: state,
        })
    }



    loadDataFilter = () => {
        fetch(this.state.urlFilter, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data.reverse(),
                isLoaded: false
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle 
                    title={'Calendario de las tareas'} 
                    subTitle={`Gestión de tareas`}
                    titleNew={"Nuevo módulo"}
                    titleFilter={"Filtros"}
                    icon={"fas fa-calendar-alt"}
                    onClickNew={""}
                    showNew={false}
                    onClickFilter={this.handleClickShowFilter}
                    showFilter={true}
                />

                {this.state.showFilter && (
                    <FormFilter 
                        handleClickFilter={this.HandleClickFilter}
                        handleClickCancel={this.handleClickCancel}
                        handleChangeFilter={this.handleChangeFilter}
                        from={this.props.from}
                        calendar={true}
                        url={`/task/get_tasks_test/${this.props.from}`}

                        //values data
                        users={this.props.users}
                        labels={this.props.labels}
                        tasks={this.props.tasks}
                        procesos={this.props.procesos}
                        task_sources={this.props.task_sources}
                        cancelFilter={this.handleClickCancel}

                        //update states
                        updateStateIsLoaded={this.updateStateIsLoaded}
                        updateStates={this.updateStates}
                        updateDataFilter={this.updateDataFilter}

                        //states
                        isFiltering={this.state.isFiltering}
                    />
                )}

                <Calendar
                    updateStateLoad={this.updateStateLoad}
                    loadData={this.loadData}
                    data={this.state.data}
                    isLoaded={this.state.isLoaded}
                    updateItem={this.updateItem}
                    updateData={this.updateData}
                    estados={this.props.estados}

                    users={this.props.users}
                    user={this.props.user}
                    positions={this.props.positions}
                    procesos={this.props.procesos}
                    task_boards={this.props.task_boards}
                    imageUpload={this.props.imageUpload}
                    key_text={this.props.key_text}
                />
            </React.Fragment>
        );
    }
}


export default CalendarTask;
WebpackerReact.setup({ CalendarTask });

