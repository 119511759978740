import React from "react";
import FormatPermision from './FormatPermision'

class CheckboxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {
    const estados = {
      crear: true,
      createFormat: true,
      deleteFormat: true,
      editFormat: true,
      editar: true,
      eliminar: true,
      is_admin: true,
      showFormat: true,
    }

    return (
      <React.Fragment>
        <div className="row">
          {this.props.modules.map(modulex => (
            <React.Fragment>
              <div className="col-md-3" style={{marginBottom:"30px"}}>
                <h5 key={modulex.id}>{modulex.name} {/*<input type="checkbox" className="float-right" onChange={(e) => this.props.handleChangeAll(e, this.props.modules)}/>*/}</h5>

                {modulex.section_accions.map(item => (
                  <div>
                    <label key={item.id}>
                  
                      <input
                        style={{marginRight:"10px"}}
                        type="checkbox"
                        value={item.id}
                        checked={this.props.checkedItems.get(item.id.toString())}
                        onChange={this.props.handleChangeAccions}
                      />  {item.name}
                    </label>
                  </div>
                ))}
              </div>


              {modulex.name == "Fordata" && (
                <div className="col-md-12">
                  
                  <ul className="list-group mb-3">
                    <div className="alert alert-primary text-center" role="alert">
                    <b >Activa los módulos y submódulos correspondientes a este cargo, para permitir el acceso al ingresar al constructor de FORDATA. (Al seleccionar el módulo, guardará automáticamente)</b>
                    </div>

                    <div className="row">
                      {this.props.format_categories.map((category, index) => (
                        <div className="col-md-3">
                          <li className={`list-group-item justify-content-between align-items-center mb-1 item-background ui-card-list-formatCategoriesIndex`} key={category.id} onClick={() => this.createColumn(category, this.state.data.filter((e) => e.id == category.id).length >= 1)}>
                              
                              <div className="ui-card-iconText-formatCategoriesIndex">
                                  <i className={`fas fa-folder mr-3 ui-card-icon-folder-formatCategoriesIndex`} style={{ fontSize: "17px" }}></i><div className="ui-text-formarCategoriesIndex">{category.name}</div> 
                              </div>

                              <div className="ui-icons-formatCategoriesIndex">
                                  <input 
                                    type="checkbox" 
                                    name="" 
                                    value={category.id}
                                    checked={this.props.checkedItemParentFormatCategory.get(category.id.toString())}
                                    onChange={(e) => this.props.handleChangeAccionParentFormatCategory(e)}
                                  />
                              </div>
                          </li>
                        </div>
                      ))}
                    </div>
                  </ul>
                  <br />
                  <hr />
                  <br />
                  <div className="alert alert-primary text-center" role="alert">                      
                      <b>Activa los módulos correspondientes a este cargo, para permitir acceso, visualización y gestión en el menú izquierdo. (Al seleccionar el módulo, guardará automáticamente)</b>
                  </div>
                  
                  <FormatPermision
                      data={this.props.format_categories}
                      estados={estados}

                      checkedItemFormData={this.props.checkedItemFormData}
                      checkboxesFormData={this.props.checkboxesFormData}
                      handleChangeAccionFormat={this.props.handleChangeAccionFormat}
                      formats_permissions_all={this.props.formats_permissions_all}
                      permissions={this.props.permissions}
                      position={this.props.position}
                      section_custom_views={this.props.section_custom_views}
                      custom_view_permissions={this.props.custom_view_permissions}
                  />

                </div>
              )}

            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default CheckboxContainer;
