import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class ModalEditPosition extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">

                                    <div className="col-md-12 mb-3">
                                        <input
                                            type="hidden"
                                            name="position_ids"
                                            value={this.props.selectedOptionMulti.position_ids}
                                        />

                                        <Select
                                            onChange={this.props.handleChangeAutocompleteMulti}
                                            options={this.props.positions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            autoFocus={false}
                                            className={`link-form`}
                                            classNamePrefix="select"
                                            placeholder="Seleccione"
                                            name="position_ids"
                                            defaultValue={this.props.editValues}
                                        />
                                    </div>

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                    <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{"Actualizar cargos"}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalEditPosition;
