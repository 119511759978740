import React, { Component } from 'react';
import Show from '../components/formatos/FormatCategories/Show'
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle';

class FormatCategoryShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.child = React.createRef();
        this.state = {
            data: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        //this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }



    render() {
        return (
            <React.Fragment>

                <HeaderTitle
                    title={`${this.props.format_category.name}`}
                    subTitle={"Crea y gestiona tus módulos y formatos"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={() => this.child.current.toogleCategories("new", true)}
                    showNew={false}
                    showFilter={false}

                    is_index={true}
                    url={this.props.current_url}
                />

                <Show
                    data={this.state.data}
                    estados={this.props.estados}
                    positions={this.props.positions}
                
                  /*   categories={this.props.categories} */
                    /* formatos={this.props.formatos} */
                    ref={this.child}
                   
                    
                    default_categories={this.props.default_categories}
                    select_category_list_id={this.props.select_category_list_id}
                    current_category={this.props.current_category}
                />
            </React.Fragment>
        );
    }
}


export default FormatCategoryShow;
WebpackerReact.setup({ FormatCategoryShow });


