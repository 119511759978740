import React, { Component } from 'react';
import Select from "react-select";

class FormFilter extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="card mb-3">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-md-4">
                                <input
                                    type="hidden"
                                    name="years"
                                    value={this.props.selectedOptionMulti.years}
                                />
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    autoFocus={false}
                                    className={`link-form`}
                                    classNamePrefix="select"
                                    placeholder="Seleccione"
                                    name="years"
                                    onChange={this.props.handleChangeAutocompleteMulti}
                                    options={this.props.years}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="card-footer">
                        <button
                            className="btn-shadow btn btn-info mr-3"
                            onClick={() => this.props.handleClickFilter()}
                        >
                            Aplicar Filtro
                        </button>
                        
                        <button
                            className="btn btn-outline-danger"
                            onClick={() => this.props.toogleFilter(!this.props.showFilter)}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FormFilter;
