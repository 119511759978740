import React, { useState, useEffect } from 'react';

const FormUpdateColumn = (props) => {
    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <div style={{ width: "289px" }}>
                <form onSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-md-12">
                            <input 
                                type="text" 
                                name="name"
                                placeholder='Columnas'
                                className={`form form-control ${!props.errorValueOptionUpdateColumn && props.formUpdateColumn.name == "" ? "error-class" : ""}`}
                                value={props.formUpdateColumn.name}
                                onChange={props.handleChangeUpdateColumn}
                            />
                        </div>

                        {!props.errorValueOptionUpdateColumn && (
                            <div className="col-md-12 mt-3">
                                <div class="alert alert-danger text-center" role="alert">
                                    <b>{props.formUpdateColumn.name == "" ? "El campo no puede estar vacio" : "No se permiten columnas con el mismo nombre"}</b>
                                </div>
                            </div>   
                        )}

                        <div className="col-md-12 mt-2">
                            <button
                                className='btn-shadow btn btn-info btn-block'
                                onClick={props.handleClickUpdateColumn}
                            >
                                Actualizar
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}


export default FormUpdateColumn;

