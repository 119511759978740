import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalUpdateQuestionType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [
                {name: "Básica", options: ["Respuesta breve", "Casillas de verificación", "Párrafo", "Campo numérico", "Opción múltiple", "Link"]},
                {name: "Selección", options: ["Lista desplegable"]},
            ]
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className={`col-md-12`}>
                                    {this.state.data.map((values, index) => (
                                        <React.Fragment>
                                            {index >= 1 ? <hr /> : null}
                                            <b className="color-title">{values.name}</b>
                                            <hr />

                                            <div className="row">
                                                {values.options.map((type, index) => (
                                                    <div className="col-md-6">
                                                        <p className={`list-format ${this.props.nameBtn == type ? "border-active" : ""}`} onClick={() => this.props.setName(type)}>{this.props.getIcon(type)} {type}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <button className="btn btn-outline-danger" onClick={() => this.props.toggle()}>Cerrar</button>
                            {/*<button className="btn-shadow btn btn-info" onClick={() => this.props.toggle()}>{"Confirmar"}</button>*/}
                        </ModalFooter>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalUpdateQuestionType;
