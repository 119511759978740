import React from 'react'
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

export default function CardData(props) {
  return (
    <div className='my-dashboard-data-item' style={{ borderBlockColor: props.color, color: props.color, display: 'flex' }}>
      <h4 className='my-dashboard-data-text'>{props.title}       <i
        id={`PopoverLabels${props.idCard}`}
        className="fas fa-info-circle float-right icon-add icon-task-popover"
        style={{
          color: "#1bd0ec",
          fontSize: "20px", position: "relative", top: "10px", left: "10px"
        }}
      >

      </i></h4>


      <UncontrolledPopover trigger="legacy" placement="bottom" target={`PopoverLabels${props.idCard}`}>
        <PopoverBody>
          <div className="dashboard-poppover" style={props.text2}>
            <img src={props.text1} alt="" />
          </div>
        </PopoverBody>
      </UncontrolledPopover>
      {props.circle ?
        <p className='my-dashboard-data-value percentage-value'>{props.value}%</p>
        :
        <p className='my-dashboard-data-value'>{props.value}</p>
      }
    </div>
  )
}
