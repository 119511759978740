import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

class FormCreate extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {

        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-md-4 mb-3">
                            <label>Nombre</label>
                            <input
                                type="text"
                                name="name"
                                value={this.props.formValues.name}
                                onChange={this.props.onChangeForm}
                                className={`form form-control ${this.props.errorValues == false && this.props.formValues.name == "" ? "error-class" : ""}`}
                            />
                        </div>

                        <div className="col-md-4 mb-3">
                            <input
                                type="hidden"
                                name="format_target_id"
                                value={this.props.selectedOption.format_target_id}
                            />
                            <label>Formato Objetivo <small className="validate-label">(opcional)</small></label>
                            <Select
                                onChange={this.props.handleChangeAutocomplete}
                                options={this.props.formatos}
                                autoFocus={false}
                                className={`link-form ${!this.props.errorValues && this.props.formValues.format_target_id == "" ? "error-class" : ""}`}
                                value={this.props.selectedOption}
                            />
                        </div>

                      <div className="col-md-4 mb-3">
                            <label>Tipo de respuesta</label>
                            <select
                                name="type_to_answer"
                                value={this.props.formValues.type_to_answer}
                                onChange={this.props.onChangeForm}
                                className={`form form-control ${this.props.errorValues == false && this.props.formValues.type_to_answer == "" ? "error-class" : ""}`}
                            >
                                <option value="">Seleccione una opción</option>
                                <option value="show">Mostrar información</option>
                                <option value="save">Guardar registro</option>
                            </select>
                        </div> 

                        <div className="col-md-12 mb-3">
                        <label>Mensaje que sale cuando generan un registro </label>
                            <textarea
                                name="message"
                                value={this.props.formValues.message}
                                onChange={this.props.onChangeForm}
                                className={`form form-control`}
                                rows="3"
                            />
                        </div>

                        <div className="col-md-12 text-right">
                            <button
                                className="btn btn-outline-danger mr-2"
                                onClick={() => this.props.toggle()}
                            >
                                Cancelar
                            </button>

                            <button
                                className="btn-shadow btn btn-info"
                                onClick={() => this.props.submitForm()}
                            >
                                {this.props.nameBnt}
                            </button>

                            <hr />
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default FormCreate;