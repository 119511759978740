import React, { Component } from 'react';
import Dropzone from "react-dropzone";
import ModalShow from './ShowCount'
import AddonIndex from '../Addons/ModalIndex'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';
import UploadFile from '../../../components/documentManagement/Document/UploadFile';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormAddNit from './FormAddNit';
import FormUpdateUrl from './FormUpdateUrl';
import FormUpdateFooter from './FormUpdateFooter'
import IndexRecords from './FordataRecords/Index';
import Pagination from "react-js-pagination";

class Table extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            nameFile: "",
            nameFileUpdate: "",
            numberNitEnterprise: "",
            id: "",
            tenant_id: "",
            sizeFile: 0,
            isLoaded: true,
            modal: false,
            modalAddon: false,
            modalAddNit: false,
            modalUploadMenuLogo: false,
            modalUrlBeetree: false,
            modalUpdateFooter: false,
            modalIndexRecords: false,
            tenant: {},


            modalUploadFile: false,
            tenant_upload_file_id: "",
            tenant_nit_id: "",

            form: {
                contract: {},
                menu_logo: {},
                plan_id: "",
                app_type: "",
                tenant_state: "",
                base_model_id: "",
                start_demo: "",
                end_demo: "",
                nit: ""
            },

            formUpdateUrl: {
                id: "",
                url_name: "",
            }
        }
    }

    clearValues = () => {
        this.setState({
            form: {
                contract: {},
                plan_id: "",
                app_type: "",
                base_model_id: "",
                start_demo: "",
                end_demo: "",
                nit: "",
            },

            formUpdateUrl: {
                id: "",
                url_name: "",
            },

            nameFile: "",
            nameFileUpdate: "",
            numberNitEnterprise: "",
            id: "",
            tenant_id: "",
        })
    }

    handleFileUpdate = (archivo) => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    contract: file
                },

                nameFileUpdate: file.path,
                sizeFile: file.size
            })
        ));
    };

    handleChangeNit = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                nit: e.target.value
            },
        })
    };

    handleChangeUpdate = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            },
        })
    };

    handleChangeUpdateUrlBeetree = (e) => {
        this.setState({
            formUpdateUrl: {
                ...this.state.formUpdateUrl,
                [e.target.name]: e.target.value
            },
        })
    };


    HandleClick = () => {
        const formData = new FormData();
        formData.append("contract", this.state.form.contract)
        formData.append("plan_id", this.state.form.plan_id)
        formData.append("app_type", this.state.form.app_type)
        formData.append("base_model_id", this.state.form.base_model_id)
        formData.append("tenant_state", this.state.form.tenant_state)
        formData.append("start_demo", this.state.form.start_demo)
        formData.append("end_demo", this.state.form.end_demo)
        formData.append("nit", this.state.form.nit)

        fetch(`/settings/update_file/${this.state.tenant_id}`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateItem(data.register)
                this.clearValues();
            });
    }

    update_state = (e, tenant_id) => {
        fetch(`/settings/update_state_tenant/${tenant_id}/${e.target.checked}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.updateItem(data.register)
            });
    }

    edit = (tenant) => {
        this.setState({
            form: {
                ...this.state.form,
                plan_id: tenant.plan_id,
                app_type: tenant.app_type,
                base_model_id: tenant.base_model_id,
                tenant_state: tenant.tenant_state,
                start_demo: tenant.start_demo,
                end_demo: tenant.end_demo,
                nit: tenant.nit,
            },

            tenant_id: tenant.id
        })
    }


    toogle = (from, register) => {
        if (from == "new") {
            this.setState({ modal: true, tenant: register })
        } else {
            this.setState({ modal: false, tenant: {} })
        }
    }

    toogleAddon = (from, register) => {
        if (from == "new") {
            this.setState({ modalAddon: true, tenant: register })
        } else {
            this.setState({ modalAddon: false, tenant: {} })
        }
    }

    toogleUrlBeetree = (from) => {
        if (from == "new") {
            this.setState({ modalUrlBeetree: true })
        } else {
            this.setState({ modalUrlBeetree: false })
            this.clearValues();
        }
    }

    uploadContract = (tenant_id) => {
        this.setState({
            modalUploadFile: true,
            isLoaded: false,
            tenant_upload_file_id: tenant_id
        })
    }

    AddNit = (tenant_id) => {
        this.setState({
            modalAddNit: true,
            isLoaded: false,
            tenant_nit_id: tenant_id
        })
    }

    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    contract: file.size >= 11485760 ? {} : file
                },

                nameFile: file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
                sizeFile: file.size,
            })
        ));
    };

    handleFileUploadLogo = (archivo) => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    menu_logo: file.size >= 11485760 ? {} : file
                },

                nameFile: file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
                sizeFile: file.size,
            })
        ));
    };

    toogleUploadFile = (from) => {
        if (from == "new") {
            this.setState({ modalUploadFile: true })
        } else {
            this.clearValuesUploadFile();
        }
    }

    toogleUpdateFooter = (from, register) => {
        if (from == "new") {
            this.setState({ modalUpdateFooter: true, tenant: register })
        } else {
            this.setState({ modalUpdateFooter: false, tenant: {} })
        }
    }


    toogleIndexRecords = (from, register) => {
        if (from == "new") {
            this.setState({ modalIndexRecords: true, tenant: register })
        } else {
            this.setState({ modalIndexRecords: false, tenant: {} })
        }
    }


    toogleAddNit = (from) => {
        if (from == "new") {
            this.setState({ modalAddNit: true })
        } else {
            this.clearValuesAddNit();
        }
    }

    toogleUploadMenuLogo = (from, tenant_id) => {
        if (from == "new") {
            this.setState({ modalUploadMenuLogo: true, isLoaded: false, tenant_id: tenant_id })
        } else {
            this.setState({ modalUploadMenuLogo: false, isLoaded: false, tenant_id: ""})
        }
    }

    handleClickUploadLogo = () => {
        const formData = new FormData();
        formData.append("menu_logo", this.state.form.menu_logo)

        fetch(`/settings/update_file/${this.state.tenant_id}`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.updateItem(data.register);
            this.messageSuccess(data);
            this.setState({ modalUploadMenuLogo: false, isLoaded: false, tenant_id: "" })
        });
    }

    clearValuesUploadFile = () => {
        this.setState({
            modalUploadFile: false,
            isLoaded: false,
            nameFile: "",
            sizeFile: 0,
            tenant_upload_file_id: "",
        })
    }

    clearValuesAddNit = () => {
        this.setState({
            modalAddNit: false,
            isLoaded: false,
            tenant_nit_id: "",
        })
    }


    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    handleClickUploadFile = () => {
        this.setState({ isLoaded: true })
        const formData = new FormData();
        formData.append("contract", this.state.form.contract)

        fetch(`/settings/update_file/${this.state.tenant_upload_file_id}`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data);
                this.props.updateItem(data.register)
                this.clearValuesUploadFile();
            });
    }

    handleClickAddNit = () => {
        this.setState({ isLoaded: true })
        const formData = new FormData();
        formData.append("nit", this.state.form.nit)

        fetch(`/settings/update_file/${this.state.tenant_nit_id}`, {
            method: 'POST', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.messageSuccess(data);
                this.props.updateItem(data.register)
                this.clearValuesAddNit();
            });
    }

    editTenantUrl = (tenant) => {
        this.setState({
            modalUrlBeetree: true,
            formUpdateUrl: {
                id: tenant.id,
                url_name: tenant.url_name
            }
        })
    }

    handleClickUpdateUrlBeetree = () => {
        fetch(`/settings/tenants/${this.state.formUpdateUrl.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(this.state.formUpdateUrl), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.clearValues();
                this.setState({ modalUrlBeetree: false });
                this.props.updateItem(data.register);
                this.messageSuccess(data);
            });
    }

    updateState = (tenant) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: `¡La empresa ${tenant.company_name} será ${tenant.is_active ? "desactivada" : "activada"}!`,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: `Confirmar`,
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/update_tenant_is_active/${tenant.id}/${!tenant.is_active}`, {
                    method: 'PATCH', // or 'PUT'
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.removeTenant(tenant.id);
                });
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                {false && (
                    <div className="ui-table_tenant_client-button-config">
                        <a className='btn btn-info' href="./plan_categories">Configurar Categorias</a>
                    </div>
                )}

                {this.state.modal && (
                    <ModalShow
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        tenant={this.state.tenant}
                        title={"Información de la empresa"}
                    />
                )}

                {this.state.modalAddon && (
                    <AddonIndex
                        backdrop={"static"}
                        modal={this.state.modalAddon}
                        tenant={this.state.tenant}
                        toggle={this.toogleAddon}
                        title={"Adiccion de la empresa"}
                        loadData={this.props.loadData}
                    />
                )}

                {this.state.modalUploadFile && (
                    <UploadFile
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalUploadFile}
                        toggle={this.toogleUploadFile}
                        title={"Subir contrato"}

                        handleClick={this.handleClickUploadFile}
                        isLoaded={this.state.isLoaded}
                        nameFile={this.state.nameFile}
                        handleFile={this.handleFile}
                        sizeFile={this.state.sizeFile}
                    />
                )}


                {this.state.modalUploadMenuLogo && (
                    <UploadFile
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modalUploadMenuLogo}
                        toggle={this.toogleUploadMenuLogo}
                        title={"Subir logo"}

                        handleClick={this.handleClickUploadLogo}
                        isLoaded={this.state.isLoaded}
                        nameFile={this.state.nameFile}
                        handleFile={this.handleFileUploadLogo}
                        sizeFile={this.state.sizeFile}
                    />
                )}

                {this.state.modalAddNit && (
                    <FormAddNit
                        backdrop={"static"}
                        modal={this.state.modalAddNit}
                        toggle={this.toogleAddNit}
                        title={"Agregar Nit"}

                        submitForm={this.handleClickAddNit}
                        onChangeForm={this.handleChangeUpdate}
                        formValues={this.state.form}
                        isLoaded={this.state.isLoaded}
                    />
                )}

                {this.state.modalUrlBeetree && (
                    <FormUpdateUrl
                        backdrop={"static"}
                        modal={this.state.modalUrlBeetree}
                        toggle={this.toogleUrlBeetree}
                        title={"Actualizar url"}

                        submitForm={this.handleClickUpdateUrlBeetree}
                        onChangeForm={this.handleChangeUpdateUrlBeetree}
                        formValues={this.state.formUpdateUrl}
                    />
                )}

                {this.state.modalUpdateFooter && (
                    <FormUpdateFooter
                        backdrop={"static"}
                        modal={this.state.modalUpdateFooter}
                        toggle={this.toogleUpdateFooter}
                        title={`Actualizar texto de pie de pagina para la empresa ${this.state.tenant.company_name}`}
                        tenant={this.state.tenant}

                        messageSuccess={this.messageSuccess}
                        updateItem={this.props.updateItem}
                        key_text={this.props.key_text}
                        imageUpload={this.props.imageUpload}
                    />
                )}

                {this.state.modalIndexRecords && (
                    <IndexRecords
                        backdrop={"static"}
                        modal={this.state.modalIndexRecords}
                        toggle={this.toogleIndexRecords}
                        tenant={this.state.tenant}
                        title={`Registros eliminados para la empresa ${this.state.tenant.company_name}`}
                    />
                )}

                {this.props.plan_category_id && this.props.plan_id ? (
                    <div className="ui-table_tenant_client-card__container content main-card mb-0 card mt-3 card-style">
                        <div className="ui-table_tenant_client-card-body card-body" style={{ display: "grid" }}>
                            <div className="ui-table_tenant_client-options__container">
                                <div className="ui-table_tenant_client-count-register">
                                    <span>{this.props.data.length} Registros</span>
                                </div>
                                <div className="ui-table_tenant_client-export-option">
                                    <a
                                        href={`/settings/download_file/${this.props.plan_id}?company_name=${this.props.form.company_name}&username=${this.props.form.username}&email=${this.props.form.email}&state_records=${this.props.form.state_records}&tenant_state=${this.props.form.tenant_state}&start_date=${this.props.form.start_date}&end_date=${this.props.form.end_date}&country_field=${this.props.form.country_field}&state_field=${this.props.form.state_field}&city_field=${this.props.form.city_field}&expiration_start_date=${this.props.form.expiration_start_date}&expiration_end_date=${this.props.form.expiration_end_date}`}
                                        target="_blank"
                                        className="ml-3"
                                    >
                                        <i className="fas fa-file-export mr-2"></i>Exportar registros
                                    </a>
                                </div>
                            </div>
                            <div className="ui-table_tenant_client-table__container">
                                <div className="content-table-tenant">
                                    <table className="ui-table_tenant_client-table-header__container table-fixed table-width" id="sampleTable" style={{ tableLayout: "fixed" }}>
                                        <thead className='ui-table_tenant_client-table-header'>
                                            <tr className='ui-table-tenant__container-title-table'>
                                                <th className='ui-table_tenant_client-column-id'>ID</th>
                                                <th className='ui-table_tenant_client-column-options'></th>
                                                <th className='ui-table_tenant_client-column-semaphore-license'></th>
                                                <th className='ui-table_tenant_client-column-semaphore-dates'></th>


                                                <th className='ui-table_tenant_client-column-name'>Nombre</th>
                                                <th className='ui-table_tenant_client-column-countries'>Nit</th>
                                                <th className='ui-table_tenant_client-column-user-admin'>Nombre Usuario</th>
                                                <th className='ui-table_tenant_client-column-email'>Correo Electrónico</th>
                                                <th className='ui-table_tenant_client-column-email'>Número Contacto</th>

                                                <th className='ui-table_tenant_client-column-countries'>País</th>
                                                <th className='ui-table_tenant_client-column-states'>Depto</th>
                                                <th className='ui-table_tenant_client-column-cities'>Ciudad</th>

                                                <th className='ui-table_tenant_client-column-count-users'>Usuarios</th>
                                                <th className='ui-table_tenant_client-column-size'>Almacenamiento</th>
                                                <th className='ui-table_tenant_client-column-registers'>Registros</th>
                                                <th className='ui-table_tenant_client-column-boards'>Tableros</th>
                                                <th className='ui-table_tenant_client-column-formats'>Formatos</th>
                                                <th className='ui-table_tenant_client-column-beetree'>Beetree</th>

                                                <th className='ui-table_tenant_client-column-states-registers'>Est. Registros</th>
                                                <th className='ui-table_tenant_client-column-states-plans'>Estado Plan</th>
                                                <th className='ui-table_tenant_client-column-date-start'>Fecha Inicio</th>
                                                <th className='ui-table_tenant_client-column-date-end'>Fecha Fin</th>
                                                <th className='ui-table_tenant_client-column-plans'>Plan</th>
                                                <th className='ui-table_tenant_client-column-type-license'>Tipo Usuario</th>
                                                <th className='ui-table_tenant_client-column-base'>Base</th>
                                                <th className='ui-table_tenant_client-column-aditions'>Adicion</th>
                                                <th className='ui-table_tenant_client-column-aditions'>Mensaje</th>
                                                <th className='ui-table-tenant-client-column-contract'>Contrato</th>
                                                <th className='ui-table-tenant-client-column-contract'>Url beetree</th>
                                            </tr>
                                        </thead>
                                        <tbody className='center ui-table_tenant_client-data__container'>
                                            {this.props.data.length >= 1 ? (
                                                this.props.data.map(tenant => (
                                                    <tr key={tenant.id} style={{ backgroundColor: tenant.is_base ? "#d0e6f3" : "" }}>
                                                        <td className='text-center'>{tenant.id}</td>
                                                        <td className="text-center ui-table_tenant_client-td-options__container">
                                                            {this.state.tenant_id == tenant.id ? (
                                                                <React.Fragment>
                                                                    <i className="fas fa-check-circle update-section" onClick={() => this.HandleClick()}></i>
                                                                    <i className="fas fa-times-circle update-false" onClick={() => this.setState({ tenant_id: "" })}></i>
                                                                </React.Fragment>
                                                            ) : (
                                                                <UncontrolledDropdown className='btn-group ui-table_tenant_client-dropdown__container'>
                                                                    <DropdownToggle>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className='ui-table_tenant_client-dropdown_header'>
                                                                        {true && (
                                                                            <DropdownItem
                                                                                href={`/settings/tenant_admin_show/${tenant.token}`}
                                                                                data-turbolinks="true"
                                                                                className="dropdown-item"
                                                                            >
                                                                                Ver
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                data-turbolinks="true"
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(tenant)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                data-turbolinks="true"
                                                                                className="dropdown-item"
                                                                                onClick={() => this.uploadContract(tenant.id)}
                                                                            >
                                                                                Subir contrato
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                data-turbolinks="true"
                                                                                className="dropdown-item"
                                                                                onClick={() => this.AddNit(tenant.id)}
                                                                            >
                                                                                Agregar Nit
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                data-turbolinks="true"
                                                                                className="dropdown-item"
                                                                                onClick={() => this.toogleUploadMenuLogo("new", tenant.id)}
                                                                            >
                                                                                Subir Logo
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                data-turbolinks="true"
                                                                                className="dropdown-item"
                                                                                onClick={() => this.toogleIndexRecords("new", tenant)}
                                                                            >
                                                                                Registros
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                data-turbolinks="true"
                                                                                className="dropdown-item"
                                                                                onClick={() => this.updateState(tenant)}
                                                                            >
                                                                                {tenant.is_active ? "Desactivar empresa" : "Activar empresa"}
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <React.Fragment>
                                                                <UncontrolledTooltip target={`count${tenant.id}`}>
                                                                    {(tenant.count_days <= 31 && tenant.count_days >= 0) && ('Licencia próxima a vencer')}
                                                                    {(tenant.count_days < 0) && ('Licencia vencida')}
                                                                    {(tenant.count_days > 31) && ('Licencia sin novedades')}
                                                                </UncontrolledTooltip>
                                                                <div className={`ui-table_tenant_client-semaphore_date ${tenant.count_days <= 0 ? 'ui-color-red' : 'ui-color-green'}`} id={`count${tenant.id}`}></div>

                                                            </React.Fragment>
                                                        </td>
                                                        <td>
                                                            <React.Fragment>
                                                                {(tenant.info_license_change.size_current >= tenant.info_license_change.total_size - 10) && (
                                                                    <UncontrolledTooltip target={`change${tenant.id}`}>{'Licencia próxima a alcanzar el almacenamiento máximo'}</UncontrolledTooltip>
                                                                )}
                                                                {(tenant.info_license_change.size_current == tenant.info_license_change.total_size) && (tenant.info_license_change.users_current <= tenant.info_license_change.total_users) && (
                                                                    <UncontrolledTooltip target={`change${tenant.id}`}>{'Licencia con limite de almacenamiento alcanzado'}</UncontrolledTooltip>
                                                                )}
                                                                {(tenant.info_license_change.users_current >= tenant.info_license_change.total_users - 2) && (tenant.info_license_change.users_current <= tenant.info_license_change.total_users) && (
                                                                    <UncontrolledTooltip target={`change${tenant.id}`}>{'Licencia próxima a alcanzar la cantidad de usuarios máximos'}</UncontrolledTooltip>
                                                                )}
                                                                {(tenant.info_license_change.users_current == tenant.info_license_change.total_users) && (
                                                                    <UncontrolledTooltip target={`change${tenant.id}`}>{'Licencia con limite de usuarios alcanzado'}</UncontrolledTooltip>
                                                                )}
                                                                {(tenant.state_records != 'active') && (
                                                                    <UncontrolledTooltip target={`change${tenant.id}`}>{'Estado de Registros a cambiado de activo'}</UncontrolledTooltip>
                                                                )}
                                                                {(tenant.info_license_change.size_current < tenant.info_license_change.total_size - 10) && (tenant.info_license_change.users_current < tenant.info_license_change.total_users - 2) && (
                                                                    <UncontrolledTooltip target={`change${tenant.id}`}>{'Licencia sin novedades en almacenamiento, usuarios y Estado Registros'}</UncontrolledTooltip>
                                                                )}
                                                                <div
                                                                    id={`change${tenant.id}`}
                                                                    className={`ui-table_tenant_client-semaphore-license
                                                                ${((tenant.info_license_change.size_current >= tenant.info_license_change.total_size - 10) ||
                                                                            (tenant.info_license_change.users_current >= tenant.info_license_change.total_users - 2)) ||
                                                                            (tenant.state_records != 'active') ||
                                                                            ((tenant.info_license_change.size_current >= tenant.info_license_change.total_size - 10) &&
                                                                                (tenant.info_license_change.users_current >= tenant.info_license_change.total_users - 2)) ?
                                                                            ('ui-color-red') : ('ui-color-green')}`}>
                                                                </div>
                                                            </React.Fragment>
                                                        </td>
                                                        <td className="text-left ui-table_tenant_cliente_td_data_company-name">
                                                            {tenant.company_name}
                                                        </td>
                                                        <td className="text-left">
                                                            {this.state.tenant_id == tenant.id ? (
                                                                <input
                                                                    name="nit"
                                                                    type="number"
                                                                    value={this.state.form.nit}
                                                                    onChange={this.handleChangeUpdate}
                                                                    className="form form-control"
                                                                />
                                                            ) : (
                                                                <React.Fragment>
                                                                    {tenant.nit}
                                                                </React.Fragment>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {tenant.admin.name}
                                                        </td>
                                                        <td className="text-left">{tenant.admin.email}</td>

                                                        <td className="text-left">{tenant.admin.contact_number}</td>

                                                        <td className="text-left">{tenant.admin.country}</td>
                                                        <td className="text-left">{tenant.admin.state_deparment}</td>
                                                        <td className="text-left">{tenant.admin.city}</td>

                                                        <td className={`text-center ${(tenant.info_license_change.users_current >= tenant.info_license_change.total_users - 2) ? ('ui-table_tenant_client-fill-change-color ui-color-red') : ('ui-table_tenant_client-fill-change-color')}`}>{tenant.users}</td>
                                                        <td className={`text-center ${(tenant.info_license_change.size_current >= tenant.info_license_change.total_size - 10) ? ('ui-table_tenant_client-fill-change-color ui-color-red') : ('ui-table_tenant_client-fill-change-color')}`}>{tenant.upload} GB</td>
                                                        <td className="text-center">{tenant.records_count}</td>

                                                        <td className="text-center">{tenant.task_board_count}</td>
                                                        <td className="text-center">{tenant.formats}</td>
                                                        <td className="text-center">{tenant.pages_count}</td>

                                                        <td className="text-center">{tenant.state_records}</td>
                                                        <td className="text-center">
                                                            {this.state.tenant_id == tenant.id ? (
                                                                <select
                                                                    name="tenant_state"
                                                                    value={this.state.form.tenant_state}
                                                                    onChange={this.handleChangeUpdate}
                                                                    className="form form-control"
                                                                >
                                                                    <option value="">Seleccione un plan</option>
                                                                    <option value="demo">demo</option>
                                                                    <option value="activo">activo</option>
                                                                    <option value="inactivo">inactivo</option>
                                                                    <option value="beewoker">beewoker</option>
                                                                    <option value="alert">alert</option>
                                                                    <option value="beefree">beefree</option>

                                                                </select>
                                                            ) : (
                                                                <span>{tenant.tenant_state}</span>
                                                            )}
                                                        </td>
                                                        <td className="text-left">
                                                            {this.state.tenant_id == tenant.id ? (
                                                                <input
                                                                    name="start_demo"
                                                                    type="date"
                                                                    value={this.state.form.start_demo}
                                                                    onChange={this.handleChangeUpdate}
                                                                    className="form form-control"
                                                                />
                                                            ) : (
                                                                <span>{tenant.start_demo}</span>
                                                            )}
                                                        </td>
                                                        <td className="text-left">
                                                            {this.state.tenant_id == tenant.id ? (
                                                                <input
                                                                    name="end_demo"
                                                                    type="date"
                                                                    value={this.state.form.end_demo}
                                                                    onChange={this.handleChangeUpdate}
                                                                    className="form form-control"
                                                                />
                                                            ) : (
                                                                <span className={(tenant.count_days <= 31) ? ('ui-table_tenant_client-fill-change-color ui-color-red') : ('ui-table_tenant_client-fill-change-color')}>{tenant.end_demo}</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {this.state.tenant_id == tenant.id ? (
                                                                <select
                                                                    name="plan_id"
                                                                    value={this.state.form.plan_id}
                                                                    onChange={this.handleChangeUpdate}
                                                                    className="form form-control"
                                                                >
                                                                    <option value="">Seleccione un plan</option>
                                                                    {this.props.plans.map(plan => (
                                                                        <option value={plan.id}>{plan.name}</option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                <span>{tenant.plan.name}</span>
                                                            )}
                                                        </td>
                                                        <td>{tenant.type_user}</td>
                                                        <td className="text-center">
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => this.update_state(e, tenant.id)}
                                                                value={tenant.is_base}
                                                                checked={tenant.is_base}
                                                            />
                                                        </td>
                                                        <td className="text-center ui-table_tenant_client-fill-adition">
                                                            <button
                                                                className="btn"
                                                                onClick={() => this.toogleAddon("new", tenant)}
                                                            >
                                                                <i className="fas fa-ellipsis-h"></i>
                                                            </button>
                                                        </td>

                                                        <td className='ui-table_tenant_client-fill-contract'>
                                                            <button
                                                                className='btn-shadow btn btn-primary'
                                                                onClick={() => this.toogleUpdateFooter("new", tenant)}
                                                            >
                                                                Abrir
                                                            </button>
                                                        </td>

                                                        <td className='ui-table_tenant_client-fill-contract'>
                                                            {tenant.contract.url ? <a href={tenant.contract.url} target="_blank">Descargar contrato </a> : null}
                                                        </td>

                                                        <td className='ui-table_tenant_client-fill-contract'>
                                                            {tenant.url_name} <i onClick={() => this.editTenantUrl(tenant)} className="fas fa-pencil-alt"></i>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="14" className="text-center">
                                                        <div className="text-center mt-4 mb-4">
                                                            <h4>No hay registros</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {true && (
                                        <div className="row mt-4">

                                            <div className="col-md-2 text-left">
                                                <p>
                                                    Mostrando {this.props.data.length} de {this.props.totalData}
                                                </p>
                                            </div>

                                            <div className="col-md-10 pl-0">
                                                <Pagination
                                                    hideNavigation
                                                    activePage={this.props.activePage}
                                                    itemsCountPerPage={this.props.countPage}
                                                    itemClass="page-item"
                                                    innerClass="pagination"
                                                    linkClass="page-link"
                                                    totalItemsCount={this.props.totalData}
                                                    pageRangeDisplayed={this.props.countPage}
                                                    onChange={this.props.handlePageChange}
                                                />
                                            </div>

                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="card">
                        <div className="card-body text-center">
                            <h4>Seleccione una categoria del plan y luego seleccione el plan para poder filtrar</h4>
                        </div>
                    </div>
                )}


            </React.Fragment>
        );
    }
}

export default Table;
