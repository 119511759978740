import React, { Component } from 'react';
import FormCreate from './FormCreate'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            ErrorValues: true,
            format_hook_id: "",

            form: {
                format_id: this.props.format_id,
                url: "",
                payload: "",
            },
        }
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.clearValues();
            this.setState({ modal: false })
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = format => {
        this.setState({
            data: this.state.data.map(item => {
                if (format.id === item.id) {
                    return {
                        ...item,
                        url: format.url,
                        payload: format.payload,
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            form: {
                ...this.state.form,
                url: "",
                payload: "",
            },
        })
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_hooks/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                        this.messageSuccess(response)
                    });
            }
        });
    };

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        fetch(`/formatos/get_format_hooks/${this.props.format_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    validationForm = () => {
        if (this.state.form.url != "" &&
            this.state.form.payload != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (this.validationForm()) {
            if (this.state.modeEdit) {
                fetch(`/formatos/format_hooks/${this.state.format_hook_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            } else {
                fetch(`/formatos/format_hooks`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateData(data.register)
                        this.clearValues();
                        this.setState({ modal: false, showTable: true })
                    });
            }
        }
    }


    edit = (format_hook) => {
        this.setState({
            modal: true,
            modeEdit: true,
            format_hook_id: format_hook.id,

            form: {
                ...this.state.form,
                url: format_hook.url,
                payload: format_hook.payload,
            },
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered modal-lg" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>

                    <ModalBody>
                        {this.state.modal && (
                            <FormCreate
                                backdrop={"static"}
                                modal={this.state.modal}
                                toggle={this.toogle}
                                title={this.state.modeEdit ? "Actualizar" : "Crear"}
                                nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}
                                modeEdit={this.state.modeEdit}

                                //form props
                                form={this.state.form}
                                onChangeForm={this.HandleChange}
                                submitForm={this.HandleClick}
                                errorValues={this.state.ErrorValues}
                            />
                        )}

                        <div className="col-md-12 mb-3 text-right pr-0">
                            {!this.state.modal && (
                                <button
                                    className="btn-shadow btn btn-info"
                                    onClick={() => this.toogle("new")}
                                >
                                    Abrir formulario
                                </button>
                            )}
                        </div>

                        <div className="card">
                            <div className="background-gray">
                                <table className="table " id="sampleTable">
                                    <thead style={{ borderTop: "hidden", borderBottom: "hidden", color: "gray" }}>
                                        <tr>
                                            <th>Url</th>
                                            <th>Payload</th>
                                            <th className="text-right">Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map((format_hook, index) => (
                                                <React.Fragment  key={format_hook.id}>
                                                    {index >= 1 ? <div className="mb-2"></div> : null}
                                                    <tr className="tr-table-task" style={{ borderLeft: `4px solid green` }}>
                                                        <td>{format_hook.url}</td>
                                                        <td>{format_hook.payload}</td>
                                                        <td className="text-right">
                                                            {true && (
                                                                <UncontrolledDropdown className='btn-group'>
                                                                    <DropdownToggle className='btn-shadow btn btn-info'>
                                                                        <i className="fas fa-bars"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(format_hook)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}
                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(format_hook.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Index;
