import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const FormCreate = (props) => {
    const [value, onChange] = useState(new Date());

    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Pais</label>
                                <input
                                    type="hidden"
                                    name="country"
                                    value={props.selectedOption.country}
                                />

                                <Select
                                    onChange={props.handleChangeAutocomplete}
                                    options={props.countries}
                                    autoFocus={false}
                                    className={`link-form`}
                                    value={props.selectedOption}
                                />
                            </div>

                            <div className="col-md-6">
                                <label>Año</label>
                                <select
                                    name="year"
                                    className='form form-control'
                                    value={props.formValues.year}
                                    onChange={props.onChangeForm}
                                >
                                    <option value="">Seleccione una opcion</option>
                                    {
                                        Array.from({length: 2064 - 2010 + 1}, (v, k) => 2010 + k).map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="col-md-12 mt-4">
                                <Calendar onChange={(e) => { props.handleChangeCalendar(e), onChange(e) }} value={value} />
                            </div>

                            {props.formValues.days.length >= 1 && (
                                <React.Fragment>
                                    <div className="col-md-12 text-center mt-3">
                                        <h4>
                                            Dias seleccionados
                                            <button
                                                onClick={() => props.removeAll()}
                                                className="btn-shadow btn btn-danger float-right"
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </h4>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        {props.formValues.days.map((item, index) => (
                                            <span className="badge-calendar mr-2">{item} <i className="fas fa-trash-alt" style={{ cursor: "pointer" }} onClick={() => props.removeArray(index)}></i></span>
                                        ))}
                                    </div>
                                </React.Fragment>
                            )}

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                        <button className="btn-shadow btn btn-info" onClick={props.submitForm}>{props.nameBnt}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </React.Fragment>
    )

}
export default FormCreate;