import React, { useState, useEffect } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { ChromePicker } from 'react-color'
import Swal from "sweetalert2/dist/sweetalert2.js";

const IndexEditLabel = (props) => {
    const [label, setLabel] = useState(props.question.label);
    const token = document.querySelector("[name='csrf-token']").content;

    const handleChangeColor = (color) => {
        setLabel({ ...label, style: {...label.style, color: color.hex } })
    }

    const handleChangeBackgroundColor = (color) => {
        setLabel({ ...label, style: {...label.style, backgroundColor: color.hex } })
    }

    const handleChangeText = (e) => {
        setLabel({ ...label, [e.target.name]: e.target.value })
    }

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const updateLabel = () => {
        fetch(`/formatos/update_question_label/${props.question.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(label), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            messageSuccess(data);
            props.update_question(data.register);
        });
    }

    return (
        <React.Fragment>
            <UncontrolledPopover trigger="legacy" placement="right" target={`PopoverEditLabel${props.question.id}`} onClick={(e) => e.stopPropagation()}>
                <PopoverHeader>
                    <div className='ui-modals-header-title__container'>
                        <span className='ui-titles_modals'>
                            {"Editar Nota"}
                            {/*<a className="close-popover" onClick={(e) => props.toogleLabelQuestion("close", e, null)}> <i className="fas fa-times"></i></a>*/}
                        </span> 
                        <span className='ui-name-question-modals ui-icon-in-title ml-0'>{props.question.question}</span> 
                    </div>
                </PopoverHeader>
                
                <PopoverBody className="popover-column">
                    <div className="col-md-12 p-0" style={{ width: "294px", height: "auto" }}>
                        <div className="row">

                            <div className="col-md-12 mb-2">
                                <input 
                                    type="text" 
                                    className='form form-control' 
                                    placeholder='Nombre de la etiqueta'
                                    name='text'
                                    value={label.text}
                                    onChange={handleChangeText}
                                />
                            </div>

                            <div className={`col-md-12 ${label.text ? null : "mb-4"}`}>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Color del texto</a>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Color de fondo</a>
                                    </li>
                                </ul>

                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <ChromePicker 
                                            color={label.style.color} 
                                            onChange={handleChangeColor} 
                                        />
                                    </div>

                                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <ChromePicker 
                                            color={label.style.backgroundColor} 
                                            onChange={handleChangeBackgroundColor} 
                                        />
                                    </div>
                                </div>
                            </div>

                            {label.text && (
                                <div className="col-md-12 mb-2 mt-2">
                                    <div>
                                        <div style={{...label.style, padding: 5 }}>{label.text}</div>
                                    </div>
                                </div>
                            )}

                            <div className="col-md-12">
                                <button
                                    className='btn-shadow btn btn-info btn-block'
                                    onClick={() => updateLabel()}
                                >
                                    Actualizar 
                                </button>
                            </div>
                        </div>
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </React.Fragment>
    );
}

export default IndexEditLabel;

