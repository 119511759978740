import React, { Component } from 'react';
import Select from "react-select";
import { isMobile } from 'react-device-detect';
import ReactHtmlParser from 'react-html-parser';

class PopoverUpdateColumn extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            task_boards: this.props.task_boards,
            columns: [],

            selectedOption: {
                task_board_id: this.props.task.task_board.id,
                label: ReactHtmlParser(this.props.task.task_board.name),
            },

            selectedOptionColumn: {
                column_id: this.props.task.columna.column_id,
                label: ReactHtmlParser(this.props.task.columna.label),
            },
        }
    }

    componentDidMount(){
        if(this.props.task.task_board){
            this.loadColumns(this.props.task.task_board.id)
        }else{
            this.loadTaskBoards();
        }
    }

    loadTaskBoards = () => {
            fetch(`/task/get_task_boards_helper`, {
                method: 'GET', // or 'PUT'
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })
    
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ task_boards: data.data })
            });
    }

    clearValues = () => {
        this.setState({
            selectedOption: {
                task_board_id: this.props.task_board.id,
                label: ReactHtmlParser(this.props.task_board.name),
            },

            selectedOptionColumn: {
                column_id: this.props.task.columna.column_id,
                label: ReactHtmlParser(this.props.task.columna.label),
            },
        })
    }

    handleChangeAutocomplete = selectedOption => {
        this.loadColumns(selectedOption.value)
        this.setState({
            selectedOption,
        });
    };

    handleChangeAutocompleteColumn = selectedOptionColumn => {
        this.props.updateColumn(selectedOptionColumn.value)
    };

    loadColumns = (task_board_id) => {
        fetch(`/task/get_columns_helper/${task_board_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.setState({ columns: data.data })
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">

                    {this.props.task_boards && (
                        <div className="col-md-12" style={{ width: isMobile ? "" : "313px" }}>
                            <input
                                type="hidden"
                                name="task_board_id"
                                value={this.state.selectedOption.task_board_id}
                            />
                            <label>Tablero de tarea</label>
                            <Select
                                onChange={this.handleChangeAutocomplete}
                                options={this.state.task_boards}
                                autoFocus={false}
                                className={`link-form`}
                                value={this.state.selectedOption}
                            />
                            <hr/>
                        </div>  
                    )}

                    {this.state.columns.length >= 1 && (
                        <div className="col-md-12 mb-3" style={{ width: this.props.task_boards ? "" : "313px" }}>
                            <input
                                type="hidden"
                                name="column_id"
                                value={this.state.selectedOptionColumn.column_id}
                            />
                            <label>Columna</label>
                            <Select
                                onChange={this.handleChangeAutocompleteColumn}
                                options={this.state.columns}
                                autoFocus={false}
                                className={`link-form`}
                                value={this.state.selectedOptionColumn}
                            />
                        </div>
                    )}

                    <div className="col-md-12">
                        <button type="button" onClick={() => this.props.cancelPopoverUpdateColumn()} className="btn btn-outline-danger btn-shadow btn-lg btn-block">Cancelar</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PopoverUpdateColumn;

