import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Dropdown from './dropdown';

const Container = styled.div``;

export default class Task extends React.Component {

    asdasdads = () => {
        console.log("asdasdasdasdasdasd asd sad ");
    }
    
  render() {
      const task = this.props.task
    return (
      <Draggable
        draggableId={this.props.task.id}
        index={this.props.index}
      >
        {(provided, snapshot) => (
          <React.Fragment>     
              <Container
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                innerRef={provided.innerRef}
                isDragging={snapshot.isDragging}
              >
                <ul 
                    className="todo-list-wrapper list-group list-group-flush ui-table-task"
                    onClick={() => this.props.estados.gestionar ? this.props.handleClickShow(task.task_id) : null }
                  >
                    <li className="list-group-item">
                        <div className="todo-indicator " style={{ background: this.props.get_colorDate(task) }}></div>
                        <div className="widget-content p-0">

                            <div className="ui-container-optionsTaskcount-task">
                                <div className="widget-heading task-count ui-task-count"  style={{ letterSpacing:".15vw"}}>    
                                    {task.check_list_item != null ? <React.Fragment><span>{`T${task.count != 0 ? task.count : ""}`}</span>  <a className="link-task-show" onClick={() => this.editTask(task.check_list_item.task)}>{`-> T${task.check_list_item.task.count != 0 ? task.check_list_item.task.count : ""}`}</a></React.Fragment> : `T${task.count != 0 ? task.count : ""}`}
                                </div>
                                <div className="ui-icons-option-task">


                                    {this.props.grag_task_id == task.task_id ? (
                                        <React.Fragment>
                                            {"Cargando..."}
                                        </React.Fragment>                
                                    ) : (
                                        <React.Fragment>
                                            {this.props.estados.delete && (
                                                <a className="ui-delete-icon-task" onClick={(e) => this.props.delete(e, task)}>
                                                    <i className="far fa-trash-alt" id='deletes'></i>
                                                    <UncontrolledTooltip placement="top" target="deletes">
                                                        {"Eliminar"}
                                                    </UncontrolledTooltip>
                                                </a>
                                            )}

                                            {true && (
                                                <a className="ui-delete-icon-task" onClick={(e) => this.props.duplicate(e, task.task_id)}>
                                                    <i className="far fa-clone" id='duplicate'></i>
                                                    <UncontrolledTooltip placement="top" target="duplicate">
                                                        {"Duplicar tarea"}
                                                    </UncontrolledTooltip>
                                                </a>
                                            )}

                                            {true && (
                                                <a className="ui-history-icon-task" onClick={(e) => this.props.editState(e, task.task_id, "filed")}>
                                                    <i className="fas fa-archive" id="archive"></i>
                                                    <UncontrolledTooltip placement="top" target="archive">
                                                        {"Archivar"}
                                                    </UncontrolledTooltip>
                                                </a>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>

                            <div className="widget-content-wrapper">
                                <div className="widget-content-left">
                                    <div className="widget-heading ui-text-title-task">{task.name}</div>
                                    {task.task_labels.map((estados, index) => (
                                        <span style={{ background: estados.color }} className={`badge badge-secondary mt-2 ${index >= 0 ? "mr-2" : ""}`} key={index}>
                                            {estados.name}
                                        </span>
                                    ))}
                                </div>
                                
                            </div>
                        </div>

                        <div className="col-md-12"></div>

                        <div className="col-md-12 pl-0 mt-2">
                            <div className="task-aditional-info mb-1">
                                <div className="pl-0 ">
                                    <i className="far fa-calendar-alt mr-2"></i>{task.due_date}
                                </div>
                                <br />

                                <div className="text-left pl-0">
                                     {task.task_source != null && task.task_source != "" && (
                                        <React.Fragment>
                                           <a href={task.url_source} data-turbolinks="true" target="_blank">{task.task_source}</a> 
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                            <div className="task-aditional-info mb-1" style={{justifyContent: "flex-start" , gap:"5px"}} >
                                {task.state == "done" && (
                                    <React.Fragment>
                                        <b>Cumplio: </b> ({task.fulfilled.charAt(0).toUpperCase() + task.fulfilled.slice(1) }) {task.end_date}
                                    </React.Fragment>
                                )}
    
                            </div>

                            <div className="task-info-icons-parent ui-task-info-icons-parent">

                                <div className="task-info-icons" style={{ color: "#b0b0b0", fontWeight: 100, marginTop:"12px"}}>
                                    {task.general_comments >= 1 && (
                                        <div className="text-left p-0 mr-3 msg-count">

                                            <React.Fragment>
                                                <i className="far fa-comments mt-2" style={{ fontSize: "17px" }}></i>
                                                <b className="ml-1">{task.general_comments}</b>
                                            </React.Fragment>

                                        </div>
                                    )}


                                    {task.checklist != null && (
                                        <div className="text-left p-0 mr-3 msg-count" >
                                            <React.Fragment>
                                                <i className="fas fa-check-double mt-2" style={{ fontSize: "17px" }}></i>
                                                <b className="ml-1">{task.checklist}</b>
                                            </React.Fragment>
                                        </div>
                                    )}




                                    {task.general_files >= 1 && (
                                        <div className="text-left  mr-3">

                                            <React.Fragment>
                                                <i className="fas fa-paperclip mt-2" style={{ fontSize: "17px" }}></i>
                                                <b className="ml-1">{task.general_files}</b>
                                            </React.Fragment>

                                        </div>
                                    )}
                                    
                                    {task.notification_task_count >= 1 && (
                                        <div className="text-left  mr-3">

                                            <React.Fragment>
                                                <i className="fas fa-sync-alt  mt-2" style={{ fontSize: "17px" }}></i>
                                                <b className="ml-1">{task.notification_task_count}</b>
                                            </React.Fragment>

                                        </div>
                                    )}

                                    

                                </div>

                                <div className="row ui-task-info-icons-taskUser-row">
                                    <div className="p-0 mr-2">
                                        <div
                                            className="icon-show-user ui-task-user"
                                            id={`PopoverLegacy${task.id}`}
                                            onClick={() => this.setState({ editFileState: true })}
                                            style={{fontWeight:700 }}
                                        >
                                            {task.user.first_name.charAt(0)} {task.user.first_last_name.charAt(0)}
                                        </div>
                                    </div>

                                    <div className="p-0">
                                        <div
                                            className="icon-show-user-responsible ui-task-user-responsible"
                                            id={`PopoverOwner${task.id}`}
                                            onClick={() => this.setState({ editFileState: true })}
                                            style={{fontWeight:700 }}>
                                            {task.user_owner != null ? task.user_owner.first_name.charAt(0) : ""} {task.user_owner != null ? task.user_owner.first_last_name.charAt(0) : ""}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <UncontrolledPopover trigger="legacy" placement="right" target={`PopoverLegacy${task.id}`}>
                                <PopoverBody>
                                    <div className="col-md-12" style={{ width: "245px", height: "72px" }}>
                                        <div className="row">
                                            <div className="col-md-3 p-0">
                                                <div className="icon-user-task mt-2">
                                                    <b>{task.user.first_name.charAt(0)} {task.user.first_last_name.charAt(0)}</b>
                                                </div>
                                            </div>

                                            <div className="col-md-8">
                                                <b>{task.user.first_name} {task.user.first_last_name}</b>
                                                <p>{task.user.second_email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </PopoverBody>
                            </UncontrolledPopover>

                            <UncontrolledPopover trigger="legacy" placement="right" target={`PopoverOwner${task.id}`}>
                                <PopoverBody>
                                    <div className="col-md-12" style={{ width: "245px", height: "72px" }}>
                                        <div className="row">
                                            <div className="col-md-3 p-0">
                                                <div className="icon-show-user-responsible mt-2">

                                                    <b>  {task.user_owner != null ? task.user_owner.first_name.charAt(0) : ""} {task.user_owner != null ? task.user_owner.first_last_name.charAt(0) : ""}</b>
                                                </div>
                                            </div>

                                            <div className="col-md-8">
                                                <b>{task.user_owner != null ? task.user_owner.first_name : ""} {task.user_owner != null ? task.user_owner.first_last_name : ""}</b>
                                                <p>{task.user_owner != null ? task.user_owner.second_email : ""}</p>
                                            </div>
                                        </div>
                                    </div>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>

                    </li>
                </ul >
              </Container>
          </React.Fragment>

        
        )}
      </Draggable>
    )
  }
}


/*


<Container
{...provided.draggableProps}
{...provided.dragHandleProps}
innerRef={provided.innerRef}
isDragging={snapshot.isDragging}
>
{this.props.task.content}
</Container>

*/