import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import Preloader from '../GeneralComponents/LoadingComponen';
import FormCreate from '../components/settings/BasicTutorials/FormCreate';
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactHtmlParser from 'react-html-parser';

class BasicTutorialIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            modeEdit: false,
            
            basic_tutorial_id: "",
            nameFile: "",
            form: {
                description: "",
                file: {},
                image: "",
                name: "",
            },
        }
    }

    componentDidMount(){
        this.loadData();
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    handleFile = (archivo) => {
        archivo.map(file => (
            this.setState({
                form: {
                    ...this.state.form,
                    file: file.size >= 11485760 ? {} : file
                },
  
                nameFile: file.size >= 11485760 ? "¡Intenta de nuevo!. Arrastra y suelta el archivo aquí o haz clic para subir el archivo" : file.path,
            })
        ));
    };

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (basic_tutorial) => {
        this.setState({
            data: this.state.data.map(item => {
                if (basic_tutorial.id === item.id) {
                    return {
                        ...item,
                        name: basic_tutorial.name,
                        description: basic_tutorial.description,
                        image: basic_tutorial.image,
                        file: basic_tutorial.file,
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            basic_tutorial_id: "",
            ErrorValues: true,

            form: {
                description: "",
                file: {},
                image: "",
                name: "",
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/basic_tutorials/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                });
            }
        });
    };

    validationForm = () => {
        if (this.state.form.format_select_id != "" &&
            this.state.form.question_asociation_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (true) {
            if (this.state.modeEdit) {
                fetch(`/settings/basic_tutorials/${this.state.basic_tutorial_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            } else {
                fetch(`/settings/basic_tutorials`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateData(data.register)
                    this.clearValues();
                    this.setState({ modal: false })
                });
            }
        }
    }

    edit = (basic_tutorial) => {
        this.setState({
            modal: true,
            modeEdit: true,
            basic_tutorial_id: basic_tutorial.id,

            form: {
                description: basic_tutorial.description,
                file: basic_tutorial.file,
                image: basic_tutorial.image,
                name: basic_tutorial.name,
            },
        })
    }

    loadData = () => {
        fetch("/settings/get_basic_tutorials", {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Tutoriales beewo`}
                    subTitle={"Centro de ayudas"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-info-circle icon-gradient bg-mean-fruit"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}
                />


                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar tutorial" : "Crear tutorial"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}

                        handleFile={this.handleFile}
                        nameFile={this.state.nameFile}
                    />
                )}

                {!this.state.isLoaded ? (
                    <React.Fragment>
                        <div className="card ui-knowledge_base-indexContainer--all">
                            <div className="ui-knowledge_base-indexHeader">
                                <div className="ui-knowledge_base-indexHeader-left">
                                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/1656/Question_2.png" alt="Knowlege_img_1" />
                                </div>

                                <div className="ui-knowledge_base-indexHeader-center">
                                    <h3>Centro de Ayuda</h3>
                                    <h4>¿Cómo podemos ayudarte?</h4>
                                </div>

                                <div className="ui-knowledge_base-indexHeader-right">
                                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/1655/Question_1.png" alt="Knowlege_img_2" />

                                </div>
                            </div>

                            <div className="card-body ui-knowledge_base-indexBody--container">
                                {this.state.data.length >= 1 ? (
                                    this.state.data.map(basic_tutorial => (
                                        <div className="ui-knowledge_base-indexBody-items-left" key={basic_tutorial.id}>
                                            <a 
                                                href={`/settings/tutorials/${basic_tutorial.token}`} 
                                                className='ui-knowledge_base-indexBody-items-link'
                                                data-turbolinks="true"
                                                target="_blank"
                                            >
                                                <div className="ui-knowledge_base-indexBody-items-icon">
                                                    <img src={basic_tutorial.image} alt="Imagen identificativa" />
                                                </div>

                                                <div className="ui-knowledge_base-indexBody-items-title">
                                                    <h5>{basic_tutorial.name}</h5>
                                                </div>

                                                <div className="ui-knowledge_base-indexBody-items-description">
                                                    {ReactHtmlParser(basic_tutorial.description)}
                                                </div>
                                            </a>
                                        </div>
                                    ))
                                ) : (
                                    <p>asdasd</p>
                                )}
                            </div>
                        </div>                      
                    </React.Fragment>
                ) : (
                    <Preloader />
                )}
            </React.Fragment>
        );
    }
}


export default BasicTutorialIndex;
WebpackerReact.setup({ BasicTutorialIndex });