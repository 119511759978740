import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';

const ButtonFavorite = (props) => {
    const [hasNewFeature, setHasNewFeature] = useState(props.user.has_new_feature);
    const [changeIcon, setChangeIcon] = useState(false);

    const token = document.querySelector("[name='csrf-token']").content;
    

    const updateHasNewFeature = () => {
        fetch(`/update_user_has_new_feature`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setHasNewFeature(data.register);
            if (changeIcon == false) {
                setChangeIcon(true);
            } else {
                setChangeIcon(false);
            }
        });
    }

    

    return(
        <div className="ui-beewo-float-buttom" style={{zIndex: 999}}>
            <button id="TooltipDemo" className={`btn-open-options btn-shadow ${hasNewFeature ? 'btn btn-danger':'btn btn-primary'}`} onClick={() => updateHasNewFeature()}>
                <i className={`fas ${hasNewFeature ? 'fa-bullhorn': changeIcon ? 'fa-times' : 'fa-star'}`}></i>
            </button>
        </div>
    )
}

export default ButtonFavorite;
WebpackerReact.setup({ ButtonFavorite });
