import React, { useState, useEffect } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/languages/es';

import FroalaEditor from 'react-froala-wysiwyg';

const FormCreateChange = (props) => {
    const handleSubmit = e => {
        e.preventDefault();
    };

    const config = {
        language: 'es',
        quickInsertEnabled: false,
        colorsHEXInput: true,
        autoFocus: true,
        toolbartop: true,
        linkAlwaysBlank: true,
        fontFamilySelection: true,
        fontSizeSelection: true,
        paragraphFormatSelection: true,
        htmlExecuteScripts: true,
        // key: this.props.key_text,
        // imageUploadToS3: this.props.imageUpload,
        iframe: true,
        tabSpaces: 4,
        pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
        toolbarButtons: {
            moreText: {
                buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                // Alignment of the group in the toolbar.
                align: 'left',

                // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                buttonsVisible: 12
            },


            moreParagraph: {
                buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                align: 'left',
                buttonsVisible: 11
            },

            moreRich: {
                buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                align: 'left',
                buttonsVisible: 10
            },

            moreMisc: {
                buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                align: 'right',
                buttonsVisible: 9
            }
        }
    }

    const typeChangeFile = (type_file) => {
        if(type_file === 'image'){
            return(
                <Dropzone 
                    onDrop={(files) => props.handleFile(files)}
                >
                    {({getRootProps, getInputProps}) => (
                        <div
                            {...getRootProps({
                                className: 'dropzone',
                            })}
                        >
                            <input {...getInputProps()} />
                                <p>{`${props.nameFile ? props.nameFile :  ("Arrastre y suelte la imagen aquí, o haga clic para seleccionar la imagen" )}`}</p>
                        </div>
                    )}
                </Dropzone>
            );
        } else if (type_file === 'file') {
            return(
                <Dropzone 
                    onDrop={(files) => props.handleFile(files)}
                >
                    {({getRootProps, getInputProps}) => (
                        <div
                            {...getRootProps({
                                className: 'dropzone',
                            })}
                        >
                            <input {...getInputProps()} />
                                <p>{`${props.nameFile ? props.nameFile :  ("Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo" )}`}</p>
                        </div>
                    )}
                </Dropzone>
            );
        } else {
           return(
            <React.Fragment>
                <label>Link Video</label>
                <input
                    type="text"
                    name="link_video"
                    value={props.formValues.link_video}
                    onChange={props.onChangeForm}
                    placeholder="Enlace video"
                    className={`form form-control`}
                />
            </React.Fragment>
           ); 
        }
    }

    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered modal-lg" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <div className="ui-formCreate-change-container ">
                            <div className="ui-formCreate-change-input-title">
                                <label>Título Cambio</label>
                                <input 
                                    type="text" 
                                    name='title' 
                                    className={`form form-control`}
                                    value={props.formValues.title}
                                    onChange={props.onChangeForm} 
                                />
                            </div>
                            <div className="ui-formCreate-change-input-description">
                                <label>Descripción</label>
                                <FroalaEditor
                                    model={props.formValues.description}
                                    config={config}
                                    onModelChange={(e) => props.onChangeForm({ target: { name: "description", value: e } } )}
                                />
                            </div>
                            <div className="ui-formCreate-change__container-inputs">
                                <div className="ui-formCreate-change-input-type">
                                    <label>Tipo de Cambio </label>
                                    <select
                                        type="text"
                                        name="type_change"
                                        value={props.formValues.type_change}
                                        onChange={props.onChangeForm}
                                        className={`form form-control`}
                                    >
                                        <option>Seleccione</option>
                                        <option value="mejoras">Mejora</option>
                                        <option value="funcionalidad">Nueva Funcionalidad</option>
                                        <option value="news">Anuncio</option>
                                    </select>
                                </div>
                                <div className="ui-formCreate-change-input-date">
                                    <label>Fecha</label>
                                    <input 
                                        type="date" 
                                        name="date" 
                                        value={props.formValues.date}
                                        onChange={props.onChangeForm} 
                                        className={`form form-control`} 
                                    />
                                </div>
                            </div>
                            <div className="ui-formCreate-change-input-file">
                            <div className="ui-formCreate-change-input-file_type">
                                        <label>Tipo de tutorial</label>
                                        <select
                                            ype="text"
                                            name="type_file"
                                            value={props.formValues.type_file}
                                            onChange={props.handleChangeTypeFile }
                                            className={`form form-control`}
                                        >
                                            <option value="">Seleccione</option>
                                            <option value="image">Imagen</option>
                                            <option value="video">Video</option>
                                            <option value="file">Archivo PDF</option>

                                        </select>
                                    </div>
                                <div className="ui-formCreate-change-input-file_image_video">
                                    {typeChangeFile(props.formValues.type_file)}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                        <button className="btn-shadow btn btn-info" onClick={props.submitForm}>{props.nameBnt}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </React.Fragment>
      )

}
export default FormCreateChange;