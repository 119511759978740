import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ConfigurationWatermark extends Component {

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-dialog-centered" backdrop={this.props.backdrop}>
                    <ModalHeader toggle={this.props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {this.props.title}</ModalHeader>

                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" onChange={(e) => this.props.handleChangeCheckbox(e)} className="custom-control-input" id={`customGestion`} checked={this.props.formValues.has_watermark_demo} />
                                            <label className="custom-control-label" htmlFor={`customGestion`}>¿Tiene marca de agua?</label>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        {this.props.formValues.has_watermark_demo && (
                                            <React.Fragment>
                                                <label>Url de la imagen de la marca de agua</label>
                                                <input
                                                    type="text"
                                                    name="watermark"
                                                    value={this.props.formValues.watermark}
                                                    onChange={this.props.onChangeForm}
                                                    className={`form form-control`}
                                                />
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <label className="btn btn-light mt-2" onClick={() => this.props.toggle()}>Cerrar</label>
                                <button className="btn-shadow btn btn-info" onClick={this.props.submitForm}>{this.props.nameBtn}</button>
                            </ModalFooter>
                        </form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ConfigurationWatermark;