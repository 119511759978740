import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import Preloader from '../GeneralComponents/LoadingComponen';
import FormCreate from '../components/settings/PlanCategories/FormCreate';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class PlanCategoryIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            modeEdit: false,
            plan_category_id: "",
            
            form: {
                name: "",
                plan_ids: [],
            },

            selectedOption: {
                label: "Selecione un formato",
                plan_ids: [],
            },

            editValues: []
        }
    }

    componentDidMount(){
        this.loadData();
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (plan_category) => {
        this.setState({
            data: this.state.data.map(item => {
                if (plan_category.id === item.id) {
                    return {
                        ...item,
                        name: plan_category.name,
                        plans: plan_category.plans
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            ErrorValues: true,

            form: {
                name: "",
                plan_ids: [],
            },

            selectedOption: {
                label: "Selecione un formato",
                plan_ids: [],
            },

            editValues: []
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/plan_categories/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.loadData()
                    });
            }
        });
    };

    validationForm = () => {
        if (this.state.form.format_select_id != "" &&
            this.state.form.question_asociation_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (true) {
            if (this.state.modeEdit) {
                fetch(`/settings/plan_categories/${this.state.plan_category_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register);
                        this.messageSuccess(data);
                        this.setState({ modal: false });
                        this.clearValues();
                    });
            } else {
                fetch(`/settings/plan_categories`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateData(data.register)
                    this.messageSuccess(data);
                    this.setState({ modal: false });
                    this.clearValues();
                });
            }
        }
    }

    edit = (plan_category) => {
        let array = []

        plan_category.plans.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            modal: true,
            modeEdit: true,
            plan_category_id: plan_category.id,

            form: {
                name: plan_category.name,
                plan_ids: array,
            },

            selectedOption: {
                label: "Selecione un formato",
                plan_ids: array,
            },

            editValues: plan_category.plans
        })
    }

    handleChangeAutocompleteMulti = selectedOptionMulti => {
        let array = []

        if(selectedOptionMulti){
            selectedOptionMulti.map((item) => (
                array.push(item.value)
            ))
        }

        this.setState({
            form: {
                ...this.state.form,
                plan_ids: selectedOptionMulti ? array : [],
            }
        })
    }

    loadData = () => {
        fetch("/settings/get_plan_categories", {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Categoria de planes`}
                    subTitle={"Comparte tus formatos con otras empresas"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />


                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar categoria" : "Crear categoria"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}

                        //select tenant
                        handleChangeAutocompleteMulti={this.handleChangeAutocompleteMulti}
                        selectedOption={this.state.selectedOption}
                        plans={this.props.plans}
                        editValues={this.state.editValues}
                    />
                )}

                {!this.state.isLoaded ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card">
                            <div className="card-body">
                            <div className='mb-3'>
                                <a href="../settings/tenants" className='btn btn-outline-info'>Ir a Clientes</a>
                            </div>
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Planes</th>
                                            <th style={{ width: "4%" }}>Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map(plan_category => (
                                                <tr key={plan_category.id}>
                                                    <td>{plan_category.name}</td>
                                                    <td>
                                                        {plan_category.plans.map(plan => (
                                                            <span className="badge badge-primary mr-2">{plan.label}</span>
                                                        ))}
                                                    </td>

                                                    <td className="text-right">
                                                        {true && (
                                                            <UncontrolledDropdown className='btn-group'>
                                                                <DropdownToggle className='btn-shadow btn btn-info'>
                                                                    <i className="fas fa-bars"></i>
                                                                </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                        {true && (
                                                                            <DropdownItem
                                                                                className="dropdown-item"
                                                                                onClick={() => this.edit(plan_category)}
                                                                            >
                                                                                Editar
                                                                            </DropdownItem>
                                                                        )}

                                                                        {true && (
                                                                            <DropdownItem
                                                                                onClick={() => this.delete(plan_category.id)}
                                                                                className="dropdown-item"
                                                                            >
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                    </React.Fragment>
                ) : (
                    <Preloader />
                )}
            </React.Fragment>
        );
    }
}


export default PlanCategoryIndex;
WebpackerReact.setup({ PlanCategoryIndex });