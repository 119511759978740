import React, { Component } from 'react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './FormCreate';
import FormRecommended from './FormRecommendedChart';
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

class Index extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            id: "",
            data: [],
            isLoaded: true,
            modeEdit: false,
            showForm: false,
            ErrorValues: true,

            form: {
                format_custom_view_id: this.props.format_custom_view.id,
                question_one_variable_id: "",
                question_date_id: "",
                question_x_id: "",
                result_type: "",
                chart_type: "",
                name: "",
                question_ids: []
            },

            selectedOption: {
                question_ids: [],
                label: "Pregunta question_ids"
            },

            selectOptionQuestionOneVariable: {
                question_one_variable_id: "",
                label: "Pregunta"
            },

            selectedOptionQuestionX: {
                question_x_id: "",
                label: ""
            },

            selectedOptionQuestionDate: {
                question_date_id: "",
                label: ""
            },

            questions_date: [],
            questions_number: [],
            editValues: [],
        }
    }

    componentDidMount() {
        this.loadData();
        this.loadFormatCustomView();
    }

    loadFormatCustomView = () => {
        fetch(`/formatos/format_custom_views/${this.props.format_custom_view.id}/get_format_custom_view_format_values`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    questions_date: data.questions_date,
                    questions_number: data.questions_number,
                });
            });
    }

    loadData = () => {
        fetch(`/formatos/format_custom_views/${this.props.format_custom_view.id}/get_format_custom_charts`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(response => response.json())
            .then(data => {
                this.setState({
                    data: data.data,
                    isLoaded: false
                });
            });
    }

    clearValues = () => {
        this.setState({
            showForm: false,
            modeEdit: false,
            ErrorValues: true,
            id: "",
            editValues: [],

            form: {
                ...this.state.form,
                question_one_variable_id: "",
                result_type: "",
                chart_type: "",
                name: "",
                question_ids: []
            },

            selectOptionQuestionOneVariable: {
                question_one_variable_id: "",
                label: "Pregunta"
            },

            selectedOption: {
                question_id: "",
                label: "Pregunta"
            },
        })
    }

    updateItem = custom_view_filter => {
        this.setState({
            data: this.state.data.map(item => {
                if (custom_view_filter.id === item.id) {
                    return {
                        ...item,
                        questions: custom_view_filter.questions,
                        question_one_variable_id: custom_view_filter.question_one_variable_id,
                        question_one_variable: custom_view_filter.question_one_variable,
                        result_type: custom_view_filter.result_type,
                        chart_type: custom_view_filter.chart_type,
                        name: custom_view_filter.name,

                        question_x_id: custom_view_filter.question_x_id,
                        question_x: custom_view_filter.question_x,
                        question_date_id: custom_view_filter.question_date_id,
                        question_date: custom_view_filter.question_date,
                    }
                }
                return item;
            })
        });
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/formatos/format_custom_views/${this.props.format_custom_view.id}/format_custom_charts/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.setState({
                        data: this.state.data.filter((e) => e.id != id)
                    })
                });
            }
        });
    };

    validationForm = () => {
        if (this.state.form.name != "" &&
            this.state.form.chart_type != "" &&
            this.state.form.result_type != "" &&

            this.state.form.question_one_variable_id != ((this.state.form.chart_type == "pie" || this.state.form.chart_type == "donut") ? "" : "*") &&
            this.state.form.question_date_id != ((this.state.form.chart_type == "linea_mensual" || this.state.form.chart_type == "bar_mensual") ? "" : "*") &&
            this.state.form.question_x_id != ((this.state.form.chart_type == "bar" || this.state.form.chart_type == "linea") ? "" : "*") &&
            this.state.form.question_ids != ((this.state.form.chart_type == "bar" || this.state.form.chart_type == "linea_mensual" || this.state.form.chart_type == "bar_mensual" || this.state.form.chart_type == "linea") ? [] : "*")
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false

        }
    }

    HandleClick = () => {
        if (this.validationForm()) {
            if (!this.state.modeEdit)
                fetch(`/formatos/format_custom_views/${this.props.format_custom_view.id}/format_custom_charts`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.messageSuccess(data);
                        this.setState({ modal: false})
                        this.updateData(data.register);
                        this.clearValues();
                    });
            else {
                fetch(`/formatos/format_custom_views/${this.props.format_custom_view.id}/format_custom_charts/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.messageSuccess(data);
                        this.setState({ modal: false})
                        this.updateItem(data.register);
                        this.clearValues();
                    });
            }
        }
    }

    toogleForm = (type) => {
        if (type == "new")
            this.setState({ showForm: true })
        else {
            this.setState({ showForm: false })
            this.clearValues();
        }
    }

    toogleRecommendedChart = (from) => {
        if (from == "new")
            this.setState({ modal: true })
        else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }
    

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    handleChangeAutocompleteQuestionOneVariable = selectOptionQuestionOneVariable => {
        this.setState({
            selectOptionQuestionOneVariable,
            form: {
                ...this.state.form,
                question_one_variable_id: selectOptionQuestionOneVariable.value
            },
        });
    };

    handleChangeAutocompleteQuestionDate = selectedOptionQuestionDate => {
        this.setState({
            selectedOptionQuestionDate,
            form: {
                ...this.state.form,
                question_date_id: selectedOptionQuestionDate.value
            },
        });
    }

    handleChangeAutocompleteQuestionX = selectedOptionQuestionX => {
        this.setState({
            selectedOptionQuestionX,
            form: {
                ...this.state.form,
                question_x_id: selectedOptionQuestionX.value
            },
        });
    }

    handleChangeAutocomplete = selectedOption => {
        let array = []

        selectedOption.map((item) => (
            array.push(item.value)
        ))

        this.setState({
            form: {
                ...this.state.form,
                question_ids: array
            }
        })
    }

    selectChartType = (chart_type) => {
        this.setState({
            ErrorValues: true,
            form: {
                ...this.state.form,
                chart_type: chart_type,
                question_one_variable_id: "",
                question_date_id: "",
                question_x_id: "",
                result_type: "",
                question_ids: []
            },

            selectedOption: {
                question_ids: [],
                label: "Pregunta question_ids"
            },

            selectOptionQuestionOneVariable: {
                question_one_variable_id: "",
                label: "Pregunta"
            },

            selectedOptionQuestionX: {
                question_x_id: "",
                label: ""
            },

            selectedOptionQuestionDate: {
                question_date_id: "",
                label: ""
            },
        })
    }

    edit = (format_custom_chart) => {
        const array = [];
        const arrayQuestion = [];

        format_custom_chart.questions.map((item) => (
            arrayQuestion.push({ label: item.question, value: item.id }),
            array.push(item.id)
        ))

        this.setState({
            modeEdit: true,
            showForm: true,
            id: format_custom_chart.id,
            editValues: arrayQuestion,

            form: {
                ...this.state.form,
                question_one_variable_id: format_custom_chart.question_one_variable_id,
                question_date_id: format_custom_chart.question_date_id,
                question_x_id: format_custom_chart.question_x_id,
                result_type: format_custom_chart.result_type,
                chart_type: format_custom_chart.chart_type,
                name: format_custom_chart.name,
                question_ids: array
            },

            selectedOption: {
                question_ids: array,
                label: "Pregunta"
            },

            selectOptionQuestionOneVariable: {
                question_one_variable_id: format_custom_chart.question_one_variable_id,
                label: format_custom_chart.question_one_variable ? format_custom_chart.question_one_variable.question : "",
            },

            selectedOptionQuestionX: {
                question_x_id: format_custom_chart.question_x_id,
                label: format_custom_chart.question_x ? format_custom_chart.question_x.question : "",
            },

            selectedOptionQuestionDate: {
                question_date_id: format_custom_chart.question_date_id,
                label: format_custom_chart.question_date ? format_custom_chart.question_date.question : "",
            },
        })
    }

    getImagenIndicator = (format_custom_chart) => {
        if (format_custom_chart.chart_type == "linea") {

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/47656/Linea-Apex-1.jpg"

        } else if (format_custom_chart.chart_type == "linea_mensual") {

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/48947/apexcharts-line.jpg"


        } else if (format_custom_chart.chart_type == "bar") {

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/48948/apexcharts-bar.jpg"

        } else if (format_custom_chart.chart_type == "bar_mensual") {

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/47637/Barras-Apex-2.jpg"

        } else if (format_custom_chart.chart_type == "pie") {

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/47638/apexcharts-Pie-2.jpg"

        } else if (format_custom_chart.chart_type == "donut") {

            return "https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/48946/apexcharts-Donuts-1.jpg"

        }
    }

    getItemsIndicator = (format_custom_chart) => {
        if (format_custom_chart.chart_type == "linea" || format_custom_chart.chart_type == "bar") {

            return (
                <React.Fragment>
                    <p className='ui-indexChart-ejeX'>Eje x <span>{format_custom_chart.question_x ? format_custom_chart.question_x.question : ""}</span></p>
                    <div className="ui-indexChart-containerY">
                        <p className='ui-indexChart-ejeY'>
                            Eje y
                        </p>
                        <span className='ui-indexChart-span'>
                            {format_custom_chart.questions.map((question, index) => (
                                <span className="badge badge-primary mr-2 mb-2 ui-indexChart-spanBadge" key={question.id}>{question.question}</span>
                            ))}
                        </span>
                    </div>
                    <p>Tipo de resultado <span>{format_custom_chart.result_type}</span></p>
                </React.Fragment>
            )

        } else if (format_custom_chart.chart_type == "linea_mensual" || format_custom_chart.chart_type == "bar_mensual") {

            return (
                <React.Fragment>
                    <p>Eje x (Fecha) <span>{format_custom_chart.question_date ? format_custom_chart.question_date.question : ""}</span></p>
                    <div className="ui-indexChart-containerY">
                        <p className='ui-indexChart-ejeY'>
                            Eje y
                        </p>
                        <span className='ui-indexChart-span'>
                            {format_custom_chart.questions.map((question, index) => (
                                <span className="badge badge-primary mr-2 mb-2 ui-indexChart-spanBadge" key={question.id}>{question.question}</span>
                            ))}
                        </span>
                    </div>
                    <p>Tipo de resultado <span>{format_custom_chart.result_type}</span></p>
                </React.Fragment>
            )

        } else if (format_custom_chart.chart_type == "pie" || format_custom_chart.chart_type == "donut") {

            return (
                <React.Fragment>
                    <p className='ui-indexChart-CampoMostrar'>Campo a mostrar <span>{format_custom_chart.question_one_variable ? format_custom_chart.question_one_variable.question : ""}</span></p>
                    <p>Tipo de resultado <span>{format_custom_chart.result_type}</span></p>
                </React.Fragment>
            )

        }
    }

    generateChart = (chart) =>{
        fetch(`/formatos/format_custom_views/${this.props.format_custom_view.id}/create_recommended_chart`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(chart), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        
        })
        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.updateData(data.register);
            this.messageSuccess(data);
           /*  this.setState({ modal: false}); */
        });
    }


    render() {
        return (
            <React.Fragment>
                {/* <div className="card mb-3">
                    <div className="card-header">
                        GRAFICAS RECOMENDADAS
                    </div>
                    <div className="card-body recommended-chart-container">
                        {this.props.recommended_charts_api.response.map(chart => (
                            <div className="recommended-chart-container__item">
                                <div>Nombre: {chart.name}</div>
                                <div>tipo de dato: {chart.chart_type}</div>
                                <div>tipo de grafica: {chart.result_type}</div>
                                <button onClick={() => this.generateChart(chart)}>Generar</button>
                            </div>
                        ))}
                    </div>
                </div> */}
                <div className="card mb-3">
                    <div className="card-header" style={{ display: "grid" }}>
                        <div className="row">
                            <div className="col-md-4">
                                Estadísticas
                            </div>

                            <div className="col-md-8 text-right ui-index-customChart__conatiner-buttons">
                            {/* git */}
                                <button
                                    className="btn-shadow btn btn-primary ml-2"
                                    onClick={() => this.toogleForm("new")}
                                >
                                    <i className="fas fa-plus mr-2"></i> Agregar
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        {this.state.modal && (
                            <FormRecommended
                                backdrop={"static"}
                                modal={this.state.modal}
                                toggle={this.toogleRecommendedChart}
                                title={'Estadísticas Recomendadas'}
                                nameBnt={"Crear Estadística"}

                                generateChart={this.generateChart}
                                format_custom_view={this.props.format_custom_view}
                                data={this.props.recommended_charts_api.response}

                            />
                        )}


                        {this.state.showForm && (
                            <FormCreate
                                backdrop={"static"}
                                modal={this.state.showForm}
                                toggle={this.toogleForm}
                                title={this.state.modeEdit ? "Actualizar indicador" : "Crear indicador"}
                                nameBnt={this.state.modeEdit ? "Actualizar" : "Crear"}

                                formValues={this.state.form}
                                HandleChange={this.HandleChange}
                                data={this.state.data}
                                errorValues={this.state.ErrorValues}
                                handleClick={this.HandleClick}
                                selectChartType={this.selectChartType}

                                selectOptionQuestionOneVariable={this.state.selectOptionQuestionOneVariable}
                                handleChangeAutocompleteQuestionOneVariable={this.handleChangeAutocompleteQuestionOneVariable}

                                selectedOption={this.state.selectedOption}
                                handleChangeAutocomplete={this.handleChangeAutocomplete}
                                questions={this.props.questions}

                                handleChangeAutocompleteQuestionDate={this.handleChangeAutocompleteQuestionDate}
                                selectedOptionQuestionDate={this.state.selectedOptionQuestionDate}
                                questions_date={this.state.questions_date}

                                handleChangeAutocompleteQuestionX={this.handleChangeAutocompleteQuestionX}
                                selectedOptionQuestionX={this.state.selectedOptionQuestionX}
                                questions_number={this.state.questions_date}

                                editValues={this.state.editValues}
                            />
                        )}
                        <div className="ui-indexChart-containerRow">
                            <div className="ui-indexChart-Wrap">
                                {this.state.data.length >= 1 ? (
                                    this.state.data.map((format_custom_chart, index) => (
                                        <React.Fragment>
                                            {index >= 1 ? <div className="mb-2"></div> : null}
                                            <div className="ui-indexChart-ContainerCards">
                                                <div className="ui-indexChart-cardImage">
                                                    <img src={this.getImagenIndicator(format_custom_chart)} alt="" className="ui-indexChart-imgChart" />
                                                </div>

                                                <div className="ui-indexChart-cardInfo">
                                                    <div className="ui-indexChart-iconsOption">
                                                        {true && (
                                                            <React.Fragment>
                                                                <button
                                                                    onClick={(e) => this.edit(format_custom_chart)}
                                                                    className="btn"
                                                                    style={{ color: "green" }}
                                                                    id={`filed${format_custom_chart.id}`}
                                                                >
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>

                                                                <UncontrolledTooltip placement="top" target={`filed${format_custom_chart.id}`}>
                                                                    {"Editar"}
                                                                </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        )}

                                                        {true && (
                                                            <React.Fragment>
                                                                <button
                                                                    onClick={(e) => this.delete(format_custom_chart.id)}
                                                                    className="btn"
                                                                    style={{ color: "red" }}
                                                                    id={`delete${format_custom_chart.id}`}
                                                                >
                                                                    <i className="far fa-trash-alt"></i>
                                                                </button>

                                                                <UncontrolledTooltip placement="top" target={`delete${format_custom_chart.id}`}>
                                                                    {"Eliminar"}
                                                                </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        )}
                                                    </div>

                                                    <h4>{format_custom_chart.name}</h4>

                                                    <div className="ui-indexChart-cardInfo-indvInfo">
                                                        {this.getItemsIndicator(format_custom_chart)}
                                                    </div>
                                                </div>

                                            </div>

                                        </React.Fragment>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            <div className="text-center mt-4 mb-4">
                                                <h4>No hay registros</h4>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </div>
                        </div>

                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default Index;