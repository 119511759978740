
import React, { Component } from 'react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import Loading from '../GeneralComponents/LoadingComponen';
import Swal from "sweetalert2/dist/sweetalert2.js";
import WebpackerReact from 'webpacker-react';
import FormCreate from '../components/settings/Users/FormCreate';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class IndexAdminGestor extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            modal: false,
            modeEdit: false,
            isLoaded: true,

            password1: false,
            password2: false,
            id: "",

            form: {
                username: "",
                second_email: "",
                first_name: "",
                second_name: "",
                first_last_name: "",
                second_last_name: "",
                tenant_id: "",
                password: "",
                password_confirmation: "",
                user_state: true

            },

            selectedOption: {
                tenant_id: "",
                label: "Empresas",
            },

            companies: [],
        }
    }

    changePasswordView = (field, state) => {
        this.setState({
            password1: field == "password" ? state : this.state.password1,
            password2: field == "password_confirmation" ? state : this.state.password2,
        })
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
        }
    }

    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (user) => {
        this.setState({
            data: this.state.data.map(item => {
                if (user.id === item.id) {
                    return {
                        ...item,
                        username: user.username,
                        second_email: user.second_email,
                        first_name: user.first_name,
                        second_name: user.second_name,
                        first_last_name: user.first_last_name,
                        second_last_name: user.second_last_name,
                        position: user.position,
                        tenant: user.tenant,
                    }
                }
                return item;
            })
        });
    }

    componentDidMount = () => {
        this.defaultValues();
        this.loadData();
    }

    loadData = () => {
        fetch(`/settings/get_users_gestores`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false,
            });
        });
    }

    defaultValues = () => {
        let array = []

        this.props.companies.map((item) => (
            array.push({ label: item.name, value: item.id })
        ))

        this.setState({
            companies: array,
        })
    }

    // select tenant 

    handleChangeAutocomplete = selectedOption => {
        this.setState({
            selectedOption,
            form: {
                ...this.state.form,
                tenant_id: selectedOption.value
            }
        });
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/users/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(response => response.json())
                .then(response => {
                    this.loadData();
                    this.messageSuccess(response);
                });
            }
        });
    };

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    handleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    handleChangePassword = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                password: e.target.value,
                password_confirmation: e.target.value
            }
        })
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            password1: false,
            password2: false,
            id: "",

            form: {
                username: "",
                second_email: "",
                first_name: "",
                second_name: "",
                first_last_name: "",
                second_last_name: "",
                tenant_id: "",
                password: "",
                password_confirmation: "",
                user_state: true
            },

            selectedOption: {
                tenant_id: "",
                label: "Empresas",
            },
        })
    }

    edit = (user) => {
        this.setState({
            modeEdit: true,
            modal: true,
            id: user.id,

            form: {
                username: user.username,
                second_email: user.second_email,
                first_name: user.first_name,
                second_name: user.second_name,
                first_last_name: user.first_last_name,
                second_last_name: user.second_last_name,
                tenant_id: user.tenant.id,
                password: "",
                password_confirmation: "",
                user_state: true
            },

            selectedOption: {
                tenant_id: user.tenant.id,
                label: user.tenant.company_name,
            },
        })
    }

    HandleClick = () => {
        if (!this.state.modeEdit)
            fetch(`/settings/users`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false })
                this.messageSuccess(data);
                this.updateData(data.register);
                this.clearValues();
            });
        else {
            fetch(`/settings/users/${this.state.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.setState({ modal: false })
                this.messageSuccess(data);
                this.updateItem(data.register);
                this.clearValues();
            });
        }
    }


    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Usuarios gestores`}
                    subTitle={"Comparte tus formatos con otras empresas"}
                    titleNew={"Nuevo usuario"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />


                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar usuario" : "Crear usuario"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.handleChange}
                        handleChangePassword={this.handleChangePassword}
                        errorValues={true}

                        changePasswordView={this.changePasswordView}
                        password1={this.state.password1}
                        password2={this.state.password2}

                        //select tenant
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOption={this.state.selectedOption}
                        companies={this.state.companies}
                    />
                )}

                {!this.state.isLoaded ? (
                    <React.Fragment>
                        <div className="content main-card mb-3 card ls">
                            <div className="card-body">
                                <table className="table table-hover table-striped table-bordered" id="sampleTable">
                                    <thead>
                                        <tr>
                                            <th>Usuario</th>
                                            <th>Nombre de usuario</th>
                                            <th>Correo electrónico</th>
                                            <th>Nombre</th>
                                            <th>Cargo</th>
                                            <th>Empresa</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.length >= 1 ? (
                                            this.state.data.map((user, index) => (
                                                <tr key={user.id}>
                                                    <td>{user.username}</td>
                                                    <td>{user.id}</td>
                                                    <td>{user.second_email}</td>
                                                    <td>{user.id}</td>
                                                    <td>{user.position.name}</td>
                                                    <td>{user.tenant.company_name}</td>

                                                    {true && (
                                                        <td className="text-right" style={{ width: "10px" }}>
                                                            <UncontrolledDropdown className='btn-group' direction="left">
                                                                <DropdownToggle className='btn-shadow btn btn-info'>
                                                                    <i className="fas fa-bars"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => this.edit(user)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Editar
                                                                        </DropdownItem>
                                                                    )}

                                                                    {true && (
                                                                        <DropdownItem
                                                                            onClick={() => this.delete(user.id)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            Eliminar
                                                                        </DropdownItem>
                                                                    )}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    <div className="text-center mt-4 mb-4">
                                                        <h4>No hay registros</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

export default IndexAdminGestor;
WebpackerReact.setup({ IndexAdminGestor });