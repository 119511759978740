import React, { Component } from 'react';
import WebpackerReact from 'webpacker-react';
import HeaderTitle from '../GeneralComponents/HeaderTitle'
import Preloader from '../GeneralComponents/LoadingComponen';
import FormCreate from '../components/settings/OnBoardingSteps/FormCreate';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

class OnBoardingStepIndex extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            modeEdit: false,
            on_boarding_step_id: "",

            form: {
                element: "", 
                title: "", 
                intro: "", 
                position: ""
            },
        }
    }

    componentDidMount(){
        this.loadData();
    }

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        });
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
            this.clearValues();
        } else {
            this.setState({ modal: false })
            this.clearValues();
        }
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
        })
    }

    //add update
    updateItem = (on_boarding_step) => {
        this.setState({
            data: this.state.data.map(item => {
                if (on_boarding_step.id === item.id) {
                    return {
                        ...item,
                        element: on_boarding_step.element, 
                        title: on_boarding_step.title, 
                        intro: on_boarding_step.intro, 
                        position: on_boarding_step.position, 
                    }
                }
                return item;
            })
        });
    }

    clearValues = () => {
        this.setState({
            modeEdit: false,
            on_boarding_step_id: "",

            form: {
                element: "", 
                title: "", 
                intro: "", 
                position: ""
            },
        })
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/on_boarding_steps/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    this.loadData()
                });
            }
        });
    };

    validationForm = () => {
        if (this.state.form.format_select_id != "" &&
            this.state.form.question_asociation_id != ""
        ) {
            this.setState({ ErrorValues: true })
            return true
        } else {
            this.setState({ ErrorValues: false })
            return false
        }
    }

    HandleClick = () => {
        if (true) {
            if (this.state.modeEdit) {
                fetch(`/settings/on_boarding_steps/${this.state.on_boarding_step_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        this.updateItem(data.register)
                        this.clearValues();
                        this.setState({ modal: false })
                    });
            } else {
                fetch(`/settings/on_boarding_steps`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.updateData(data.register)
                    this.clearValues();
                    this.setState({ modal: false })
                });
            }
        }
    }

    edit = (on_boarding_step) => {
        this.setState({
            modal: true,
            modeEdit: true,
            on_boarding_step_id: on_boarding_step.id,

            form: {
                element: on_boarding_step.element, 
                title: on_boarding_step.title, 
                intro: on_boarding_step.intro, 
                position: on_boarding_step.position, 
            },
        })
    }

    loadData = () => {
        fetch("/settings/get_on_boarding_steps", {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`Guia de pasos`}
                    subTitle={"Comparte tus formatos con otras empresas"}
                    titleNew={"Nuevo"}
                    titleFilter={"Filtros"}
                    icon="fas fa-toolbox"
                    onClickNew={this.toogle}
                    showNew={true}
                    showFilter={false}
                />


                {this.state.modal && (
                    <FormCreate
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar paso" : "Crear paso"}
                        nameBnt={this.state.modeEdit ? "Actualizar" : "Añadir"}

                        //form props
                        formValues={this.state.form}
                        submitForm={this.HandleClick}
                        onChangeForm={this.HandleChange}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                    />
                )}

                {!this.state.isLoaded ? (
                    <React.Fragment>
                        <div className="row">
                            {this.state.data.length >= 1 ? (
                                this.state.data.map(on_boarding_step => (
                                    <div className="col-md-3 mb-3" key={on_boarding_step.id}>
                                        <div className="card">
                                            <div className="card-header">
                                                {on_boarding_step.title}
                                            </div>

                                            <div className="card-body">
                                                {ReactHtmlParser(on_boarding_step.intro)}
                                            </div>

                                            <div className="card-footer">
                                                <button
                                                    className="btn mr-2"
                                                    onClick={() => this.edit(on_boarding_step)}
                                                >
                                                    <i className="fas fa-pencil-alt"></i>
                                                </button>

                                                <button
                                                    className="btn"
                                                    onClick={() => this.delete(on_boarding_step.id)}
                                                >
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="text-center">No hay registros</h5>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                ) : (
                    <Preloader />
                )}
            </React.Fragment>
        );
    }
}


export default OnBoardingStepIndex;
WebpackerReact.setup({ OnBoardingStepIndex });