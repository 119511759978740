import React, { Component } from 'react';
import Preloader from '../../../GeneralComponents/LoadingComponen'

class IndexList extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            dataModules: [],
            isLoaded: true,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    loadData = () => {
        fetch(`/settings/get_favorite_modules`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log("Trago datos de favorritos");
            this.setState({
                dataModules: data.dataModules,
                isLoaded: false
            });
        });
    }

    render() {
        if(this.state.isLoaded){
            return (
                <div className="col-md-12 text-center mt-4">
                    <Preloader />
                </div>
            );
        }
        
        return (
            <React.Fragment>
                {this.state.dataModules.map((modulo, index) => (
                    <div className="card mb-2" key={modulo.id} style={{ borderLeft: `6px solid ${modulo.color}`, borderTop: `3px solid ${modulo.color}`, borderRight: `3px solid ${modulo.color}`, borderBottom: `3px solid ${modulo.color}`, borderRadius: "8px" }}>
                        <div className="card-body">
                            <a 
                                href={modulo.url} 
                                data-turbolinks="true"
                                style={{ 
                                    color: modulo.color, 
                                    textDecoration: "none" 
                                }}
                            >
                                <i className={modulo.icon ? modulo.icon.html_name : "far fa-sticky-note"}></i> {modulo.name}
                            </a>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    }
}



export default IndexList;
