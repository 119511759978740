import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import Preloader from '../../../../../GeneralComponents/LoadingComponen';
import FormFilter from '../FormatCategory/FormFilter';

const Index = (props) => {
    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isLoadedFilter, setIsLoadedFilter] = useState(false);
    const [modalFormFilter, setModalFormFilter] = useState(false)
    const [form, setForm] = useState({ name: "", user_delete_id: "", date1: "", date2: "" });

    const [totalData, setTotalData] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [countPage, setCountPage] = useState(10);

    const token = document.querySelector("[name='csrf-token']").content;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        fetch(`/formatos/get_admin_format_categories/${props.tenant.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setTotalData(data.total_data);
            setIsLoaded(false);
            setActivePage(1);
        });
    }

    const handlePageChange = (pageNumber) => {
        const filter_url = `/formatos/get_admin_format_categories/${props.tenant.id}?name=${form.name}&user_delete_id=${form.user_delete_id}&date1=${form.date1}&date2=${form.date2}&page=${pageNumber}&filter=${countPage}`
        setActivePage(pageNumber)

        fetch(filter_url, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setTotalData(data.total_data);
        });
    }

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const dateTime = (fecha) => {
        var d = new Date(fecha),
        months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'jun', 'jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic'];
        return months[d.getMonth()] + " " + d.getDate() + " " + 'del' + " " + d.getFullYear() + " / " + d.getHours() + ":" + (d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes() );
    }

    if (isLoaded) {
        return (
            <div className="card">
                <div className="card-body">
                    <Preloader />
                </div>
            </div>
        )
    }

    const clearFormValue = () => {
        setForm({ name: "", user_delete_id: "", date1: "", date2: "" });
    }

    const toogleFilter = () => {
        setModalFormFilter(!modalFormFilter)
        
        if (modalFormFilter){
            loadData();
            clearFormValue();
        }
    }

    const handleClickFilter = (form) => {
        setIsLoadedFilter(true);
        setForm(form);
        fetch(`/formatos/get_admin_format_categories/${props.tenant.id}?name=${form.name}&user_delete_id=${form.user_delete_id}&date1=${form.date1}&date2=${form.date2}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setTotalData(data.total_data);
            setIsLoadedFilter(false);
            setActivePage(1);
        });
    }

    const handleClickGenerateXml = (format_category) => {
        fetch(`/formatos/generate_xml/${format_category.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            messageSuccess(data);
        });
    }

    const handleClickRestore = (format_category) => {
        fetch(`/formatos/get_admin_restore_format_category/${format_category.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(response => {
            setData(data.filter((e) => e.id != format_category.id))
            messageSuccess(response);
        });
    }

    return (
        <React.Fragment>

            {modalFormFilter && (
                <FormFilter
                    tenant={props.tenant}
                    toogleFilter={toogleFilter}
                    handleClickFilter={handleClickFilter}
                    isLoadedFilter={isLoadedFilter}
                />    
            )}

            {data.length >= 1 ? (
                <React.Fragment>

                    {!modalFormFilter && (
                        <div className="col-md-12 text-right pr-0 mt-3 mb-3">
                            <button
                                className='btn-shadow btn btn-info'
                                onClick={() => toogleFilter()}
                            >
                                Filtrar
                            </button>
                        </div>
                    )}

                    <table className="table table-hover table-striped table-bordered" id="sampleTable">
                        <thead>
                            <tr>
                                <th>¿Esta elimnado?</th>
                                <th>Nombre</th>
                                <th>Modulo eliminado por</th>
                                <th>Fecha de eliminacion</th>
                                <th>Generar xml</th>
                                <th>Restaurar</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map(format_category => (
                                <tr key={format_category.id}>
                                    <td>{!format_category.state_db ? "Si" : "No"}</td>
                                    <td>{format_category.name}</td>
                                    <td>{!format_category.state_db ? format_category.user_delete.name : null}</td>
                                    <td>{!format_category.state_db ? dateTime(format_category.date_delete) : null}</td>
                                    <td>
                                        <a
                                            href={`/formatos/generate_xml/${format_category.id}`} 
                                            className='btn-shadow btn btn-info'
                                        >
                                            {false ? "Cargando.." : "Generar"}
                                        </a>
                                    </td>

                                    <td>
                                        {!format_category.state_db && (
                                            <button 
                                                onClick={() => handleClickRestore(format_category)} 
                                                className='btn-shadow btn btn-success'
                                            >
                                                {false ? "Cargando.." : "Restaurar"}
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </React.Fragment>
            ) : (
                <div className="card">
                    <div className="card-body text-center">
                        <h4>No hay modulos eliminados</h4>
                    </div>
                </div>
            )}

            {true && (
                <div className="row mt-4">
                    <div className="col-md-3 text-left">
                        <p>Mostrando {data.length} de {totalData}</p>
                    </div>

                    <div className="col-md-9 pl-0">
                        <Pagination
                            hideNavigation
                            activePage={activePage}
                            itemsCountPerPage={countPage}
                            itemClass="page-item"
                            innerClass="pagination"
                            linkClass="page-link"
                            totalItemsCount={totalData}
                            pageRangeDisplayed={countPage}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}


export default Index;

