import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from "../../../GeneralComponents/LoadingComponen";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class IndexTypes extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modeEdit: "",
            ErrorValues: true,
            isLoaded: true,
            id: "",

            formValues: {
                name: "",
                description: "",
                task_id: "",
            },

            dataTasksTypes: [],
        }
    }

    HandleChange = (e) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [e.target.name]: e.target.value
            }
        });
    }

    componentDidMount(){
        this.loadTasksTypes();
    }

    loadTasksTypes = () =>{
        fetch(`/task/get_task_types`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            dataTasksTypes: data.data,
            isLoaded: false,
          });
        });
    }


    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    HandleClick = () => {
        this.setState({ isLoaded: true })
        if(this.validationForm() == true){
            if(this.state.modeEdit == "new")
                fetch(`/task/task_types`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(this.state.formValues), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.setState({ modeEdit: "" })
                    this.loadTasksTypes();
                });
            else{
                fetch(`/task/task_types/${this.state.id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(this.state.formValues), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    this.props.loadDataTaskShow(this.props.task.id);
                    this.setState({ modeEdit: "", id: "" })
                    this.loadTasksTypes();
                });
            }
        }
    }

    clearValues = () => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                name: "",
                description: "",
            },

            modeEdit: "",
            id: "",
            ErrorValues: true,
        });
    }

    updateValues = () => {
        this.setState({
            modeEdit: "new",
            formValues: {
                ...this.state.formValues,
                name: "",
                description: "",
            }
        })
    }

    editLabel = (label) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                name: label.name,
                description: label.description
            },

            modeEdit: "edit",
            id: label.id
        })
    }

    validationForm = () => {
        if (this.state.formValues.name != ""
            ) {
            this.setState({ ErrorValues: true })
            return true
        }else{
            this.setState({ ErrorValues: false })
            return false
        }
    }

    updateState = (label, state) => {
        this.setState({ isLoaded: true })
        fetch(`/task/type_update_state/${label.id}/${this.props.task.id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadDataTaskShow(this.props.task.id);
            this.loadTasksTypes();
            this.setState({ modeEdit: "", id: "" })
        });
    };


    delete = id => {
        this.setState({ isLoaded: true })
        fetch(`/task/task_types/${id}`, {
            method: 'delete', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            this.props.loadDataTaskShow(this.props.task != undefined ? this.props.task.id : "");
            this.loadTasksTypes();
            this.setState({ modeEdit: "", id: "" })
        });
    };

    getIcon = (label) => {
        if(label.tasks.length >= 1){
            if(label.tasks[0].id == this.props.task.id){
                return <i className="fas fa-check icon-show"></i>
            }
        }
    }

    render() {
        return (
            <React.Fragment>
            {this.state.isLoaded ?(
                <div className="col-md-12 text-center" style={{ width: "314px", height: "143px"}}>
                    <Preloader />
                    <p>Cargando información...</p>
                </div>
            ):(
            <React.Fragment>
                {(this.state.modeEdit == "new" || this.state.modeEdit == "edit" )  ?(
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md-12" style={{ width: "289px" }}>
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={this.state.formValues.name}
                                    onChange={this.HandleChange}
                                    className={`form form-control ${this.state.ErrorValues == false && this.state.formValues.name == "" ? "error-class" : ""}`}
                                    placeholder="Nombre"
                                />
                            </div>

                            <div className="col-md-12 mt-4">
                                <textarea 
                                    name="description" 
                                    value={this.state.formValues.description}
                                    onChange={this.HandleChange}
                                    className={`form form-control`}
                                    rows="4"
                                >
                                    
                                </textarea>
                            </div>

                            <div className="col-md-12 text-center mt-2">
                                <hr/>
                                <a
                                    className="btn-shadow btn btn-success mr-3"
                                    style={{ color: "#ffff"}}
                                    onClick={() => this.HandleClick()}
                                >
                                    Agregar 
                                </a>

                                <a
                                    className="btn btn-outline-danger"
                                    onClick={() => this.clearValues()}
                                >
                                    Cancelar 
                                </a>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {this.props.task != undefined ? (
                            <React.Fragment>
                                {this.props.task.task_types != undefined &&(
                                    this.state.dataTasksTypes.map((estados, index) => (
                                        <div className="row mb-2" key={estados.id}>
                                            <div className="col-md-9" style={{ width: "221px" }}>
                                                <div className="card text-center">
                                                    <label style={{ cursor: "pointer" }} onClick={() => this.updateState(estados, (estados.state == true ? false : true) )}>
                                                        {estados.name}
                                                        {this.getIcon(estados)}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <UncontrolledDropdown className='btn-group'>
                                                    <DropdownToggle className='btn btn-info'>
                                                        <i className="fas fa-bars"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu dropdown-menu-right">
                                                        {true && (
                                                            <DropdownItem
                                                                onClick={() => this.editLabel(estados)}
                                                                className="dropdown-item"
                                                            >
                                                                Editar
                                                            </DropdownItem>
                                                        )}

                                                        {true && (  
                                                            <DropdownItem
                                                                onClick={() => this.delete(estados.id)}
                                                                className="dropdown-item"
                                                            >
                                                                Eliminar
                                                            </DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    ))
                                )}

                                <div className="col-md-12 mt-2 p-0">
                                    <button className="btn-shadow btn btn-light btn-block"   style={{ color: "#1ba8fa"}} onClick={() => this.updateValues()}>
                                    Crear mas
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="col-md-12 mt-5">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h6>No hay tareas</h6>
                                    </div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default IndexTypes;
