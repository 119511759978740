import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

const ModalFormatSelect = (props) => {
    return (
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                {/*<ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.title}</ModalHeader>*/}
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <input
                                    type="hidden"
                                    name="format_id"
                                    value={props.selectedOption.format_id}
                                />
                                <Select
                                    onChange={props.handleChangeAutocomplete}
                                    options={props.formats}
                                    autoFocus={false}
                                    className={`link-form`}
                                    value={props.selectedOption}
                                />
                            </div>

                            <div className="col-md-12 mt-3">
                                <div className="alert alert-info" role="alert">
                                    <b>Selecciona el formato de beewo y la URL se guardará automáticamente</b>
                                </div>
                            </div>

                        </div>
                    </ModalBody>

                <ModalFooter>
                    <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}


export default ModalFormatSelect;

