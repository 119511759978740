import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FormReadFlowItem = (props) => {
    return(
        <React.Fragment>
            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered" backdrop={props.backdrop}>
                <ModalHeader toggle={props.toggle}><i className="app-menu__icon fa fa-user mr-2"></i> {props.read.title}</ModalHeader>                   
                    <ModalBody>
                        <div>
                           {props.read.description} 
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <label className="btn btn-light mt-2" onClick={() => props.toggle()}>Cerrar</label>
                    </ModalFooter>                    
            </Modal>
        </React.Fragment>
    );
} 

export default FormReadFlowItem;