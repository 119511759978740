import React, { Component } from 'react';

class FormCreate extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            formReply: {
                general_comment_id: this.props.comment.id,
                reply: this.props.modeEdit ? this.props.formValuesReply.reply : "",
            },
        }
    }

    HandleChange = (e) => {
        this.setState({
            formReply: {
                ...this.state.formReply,
                reply: (e.target.value.length + 0 > 500 ? this.state.formReply.reply : e.target.value),
            }
        });
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.HandleClickComment();
        }
    }

    HandleClickComment = () => {
        if (this.props.modeEdit) {
            fetch(`/reply_comments/${this.props.formValuesReply.id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(this.state.formReply), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.clearValuesReply();
                this.props.loadComments();
            });
        } else {
            fetch(`/reply_comments`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(this.state.formReply), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": this.token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.clearValuesReply();
                this.props.loadComments();
            });
        }
    }


    render() {
        return (
            <React.Fragment>
                <textarea
                    className="form form-control"
                    placeholder={`Responder a ${this.props.comment.user.first_name} ${this.props.comment.user.first_last_name}`}
                    value={this.state.formReply.reply}
                    onChange={this.HandleChange}
                    rows="4"
                    style={{ resize: "none" }}
                >

                </textarea>

                <div className="col-md-12 mt-2 pl-0">
                    <button
                        className="btn-shadow btn btn-success mr-2"
                        onClick={() => this.HandleClickComment()}
                    >
                        Responder
                    </button>

                    <button
                        className="btn btn-outline-danger"
                        onClick={() => this.props.clearValuesReply()}
                    >
                        Cancelar
                    </button>
                </div>
                <span className="span-count" style={{ color: (this.state.formReply.reply.length + 0 >= 500 ? "red" : "#3f6ad8"), right: "37px" }}>
                    {500 - this.state.formReply.reply.length}
                </span>
            </React.Fragment>
        );
    }
}

export default FormCreate;
