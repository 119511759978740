import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TaskList from './TaskList'
import FormCreate from './Form';
import Preloader from '../../../GeneralComponents/LoadingComponen';

//moment
import moment from 'moment';

class TaskIndexCard extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            showCard: false,
            isLoadedShow: true,
            isLoadedCommets: true,
            isLoadedFiles: true,
            isLoaded: true,
            id: "",

            form: {
                name: "",
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                user_owner_id: "",
                notify: false,
                column_id: "",
                task_board_id: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            selectedOptionColumnTable: {
                column_id: "",
                label: "Columna"
            },

            selectedOptionColumn: {
                column_id: "",
                label: "Seleccione columna"
            },

            selectedOptionTaskBoard: {
                task_board_id: "",
                label: "Seleccione tablero"
            },

            columns: [],
            data: [],
        }
    }

    messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    clearValues = () => {
        this.setState({
            form: {
                name: "",
                due_date: isMobile ? moment().format('YYYY-MM-DD') : '',
                user_owner_id: "",
                notify: false,
                column_id: "",
                task_board_id: "",
            },

            selectedOption: {
                user_owner_id: "",
                label: "Responsable"
            },

            selectedOptionColumnTable: {
                column_id: "",
                label: "Columna"
            },

            selectedOptionColumn: {
                column_id: "",
                label: "Seleccione columna"
            },

            selectedOptionTaskBoard: {
                task_board_id: "",
                label: "Seleccione tablero"
            },

            columns: [],
            showCard: false,
        })
    }



    componentDidMount(){
        this.loadData();
    }

    loadData = () => {
        fetch(`/task/get_data_tasks_index/MY`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                isLoaded: false,
            });
        });
    }

    handleChangeAutocomplete = selectedOption => {
        this.setState({
          selectedOption,
          form: {
             ...this.state.form,
             user_owner_id: selectedOption.value
          }
        });
    };

    HandleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
                name: e.target.name == "name" ? (e.target.value.length + 0 > 200 ? this.state.form.name : e.target.value) : this.state.form.name,
            }
        });
    }

    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data].reverse(),
        })
    }

    updateItem = task => {
        this.setState({
            data: this.state.data.map(item => {
                if (task.id === item.id) {
                    return {
                        ...item,
                        description: task.description,
                        due_date: task.due_date,
                        name: task.name,
                        columna: task.columna,
                        state: task.state,
                        user_owner: task.user_owner,
                        general_comments: task.general_comments,
                        general_files: task.general_files,
                        task_labels: task.task_labels,
                        users: task.task_labels,
                        checklist: task.checklist,
                        url_source: task.url_source,
                        task_source: task.task_source,
                        task_board_id: task.task_board_id,
                    }
                }
                return item;
            })
        });
    }


    handleChangeAutocompleteColumn = (selectedOptionColumn) => {
        this.setState({
            selectedOptionColumn,
            form: {
                ...this.state.form,
                column_id: selectedOptionColumn.value
            }
        });
    }

    handleChangeAutocompleteTaskBoard = selectedOptionTaskBoard => {
        fetch(`/task/get_task_board_columns/${selectedOptionTaskBoard.value}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

        .then(response => response.json())
        .then(data => {
            const arrayColumns = [];

            data.columns.map((item) => (
                arrayColumns.push({ label: item.title, value: item.id })
            ))

            this.setState({
                selectedOptionTaskBoard,
                form: {
                    ...this.state.form,
                    task_board_id: selectedOptionTaskBoard.value
                },
                columns: arrayColumns,
            });
        });
    }

    handleChangeSwitch = (value, field) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            }
        });
    }

    render() {
        return (
            <React.Fragment>

                {!this.state.showCard && (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-8">

                                </div>
                                <div className="col-md-4">
                                    <i className="fas fa-plus float-right icon-add-card" onClick={() => this.setState({ showCard: true })}></i>
                                </div>
                                <div className="col-md-12"><hr/></div>
                            </div>
                        </div>
                    </div>  
                )}
                    
                {this.state.showCard &&(
                    <FormCreate
                        HandleChange={this.HandleChange}
                        form={this.state.form}
                        clearValues={this.clearValues}
                        updateData={this.updateData}
                        updateStateLoad={this.props.updateStateLoad}

                        //select autocomplete
                        handleChangeAutocomplete={this.handleChangeAutocomplete}
                        selectedOption={this.state.selectedOption}
                        // users={this.state.users}
                        ErrorValues={this.state.ErrorValues}

                        //select autocomplete
                        // handleChangeAutocomplete={this.handleChangeAutocomplete}
                        // selectedOption={this.state.selectedOption}
                        users={this.props.users}

                        //select autocomplete columns
                        handleChangeAutocompleteColumn={this.handleChangeAutocompleteColumn}
                        selectedOptionColumn={this.state.selectedOptionColumn}
                        columns={this.state.columns}

                        handleChangeAutocompleteTaskBoard={this.handleChangeAutocompleteTaskBoard}
                        selectedOptionTaskBoard={this.state.selectedOptionTaskBoard}
                        task_boards={this.props.task_boards}
                        handleChangeSwitch={this.handleChangeSwitch}
                    />
                )}


                {this.state.isLoaded ? (
                       <div className="col-md-12 text-center mt-4">
                            <Preloader />
                       </div>
                ) : (
                    <React.Fragment>
                        {this.state.data.length >= 1 ? (
                            this.state.data.map(task => (
                                <div className="card mt-3" key={task.id}>
                                    <TaskList 
                                        task={task} 
                                        user={this.props.user} 
                                        users={this.props.users}
                                        showOptions={false} 
                                        loadData={this.loadData}
                                        estados={this.props.estados}
                                        updateStateLoad={this.updateStateLoad}

                                        updateItem={this.updateItem}
                                        procesos={this.props.procesos}
                                        messageSuccess={this.messageSuccess}

                                        company={this.props.company}
                                        imageUpload={this.props.imageUpload}
                                        key_text={this.props.key_text}

                                        //module
                                        module_task={this.props.module_task}
                                    />
                                </div>
                            ))
                        ) : (
                            <div className="col-md-12 mt-2 p-0">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h6>No hay tareas generales</h6>
                                    </div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default TaskIndexCard;