import React, { Component } from 'react';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ShowTask from '../tasksCard/ShowTask'

class TasksItems extends Component {
    constructor(props){
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            showPopoverLabels: false,
            isLoadedShow: true,
            id: "",
            task: {},
            dataShow: {},
        }
    }

    toogle = (from) => {
        console.log("abriendo el módulos de tareas toogletoogletoogle")
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false, task: {} })
        }
    }


    editState = (id, state) => {
        fetch(`/task/update_state/${id}/${state}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.props.loadData();
        });
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/task/tasks/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                    });
            }
        });
    };


    loadDataShow = (id) => {
        fetch(`/task/get_show_taks/${id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dataShow: data.data,
                    isLoadedShow: false
                });
            });
    }


    editTask = (tarea) => {
        this.setState({
            modal: true,
            task: tarea
        })
    }
    

    render() {
        const task = this.props.task;
        return (
            <React.Fragment>
                {this.state.modal && (
                    <ShowTask
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={"Crear tarea"}

                        //form props
                        task={this.state.task}

                        loadTasks={this.loadDataShow}
                        users={this.props.users}

                        //load show
                        isLoadedShow={false}

                        //current_user
                        user={this.props.user}

                        //load tasks
                        loadData={this.props.loadData}

                        //test load data

                        updateItem={this.props.updateItem}
                        procesos={this.props.procesos}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        key_text={this.props.key_text}
                    />
                )}

                <li>
                    <span className="link-line taks-item" onClick={() => this.editTask(task)}>
                        {task.name} <i className="fas fa-lock-open"></i>
                    </span>
                    <i style={{ color: "red", cursor: "pointer" }} onClick={() => this.delete(task.id)} className="fas fa-trash-alt ml-2"></i>
                </li>
            </React.Fragment>
        );
    }
}

export default TasksItems;
