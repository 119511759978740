import React, { Component } from 'react';
import Show from '../components/formatos/Formats/Show'  
import WebpackerReact from 'webpacker-react';
import HeaderTitle from "../GeneralComponents/HeaderTitle"
import Preloader from '../GeneralComponents/LoadingComponen'

class FormatsShow extends Component {
    constructor(props) {
        super(props);
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            data: [],
            isLoaded: true,
            countMax: 0,
            count: 1,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    updateStateLoad = (state) => {
        this.setState({ isLoaded: state })
    } 

    updateCount = (count) => {
        this.setState({
            count: count
        })
    }

    reduceCountMax = () => {
        this.setState({
            countMax: this.state.countMax - 1
        })
    }

    sumCountMax = () => {
        this.setState({
            countMax: this.state.countMax + 1
        })
    }

    //add items
    updateData = (data) => {
        this.setState({
            data: [...this.state.data, data],
            isLoaded: false,
        })
    }

    reduceState = () => {
        this.setState({
            count: this.state.count - 1,
        })
    }

    sumState = () => {
        this.setState({
            count: this.state.count + 1,
        })
    }

    //add update
    updateItem = step => {
        this.setState({
            data: this.state.data.map(item => {
            if (step.id === item.id) {
              return { ...item, 
                name: step.name,
                description: step.description,
                is_private: step.is_private,
                positions: step.positions,
              }
            }
            return item;
          })
        });
    }

    updateStepQuestionsData = (step_id, questions) => {
        this.setState({
            data: this.state.data.map(item => {
                if (step_id === item.id) {
                    return {
                        ...item,
                        questions: questions
                    }
                }
                return item;
            })
        });
    }

    updateQuestionOption = (step_id, question_id, options) => {
        this.setState({
            data: this.state.data.map(item => {
                if (step_id === item.id) {
                    return {
                        ...item,
                        questions: item.questions.map(question => {
                            if (question_id === question.id) {
                                return {
                                    ...question,
                                    options: options,
                                }
                            }
                            return question;
                        })
                    }
                }
                return item;
            })
        });
    }

    update_question = (register) => {
        this.setState({
            data: this.state.data.map(item => {
                if (register.step_id === item.id) {
                    return {
                        ...item,
                        description: item.description,
                        is_private: item.is_private,
                        name: item.name,
                        positions: item.positions,
                        step_position: item.step_position,

                        questions: item.questions.map(question => {
                            if (register.id === question.id) {
                                return {
                                    ...question,
                                    answer_formula: register.answer_formula,
                                    code_format: register.code_format,
                                    configuration_dynamic_field: register.configuration_dynamic_field,
                                    configuration_question_date: register.configuration_question_date,
                                    decimal_count: register.decimal_count,
                                    file: register.file,
                                    question_alias: register.question_alias,
                                    decimal_count_show: register.decimal_count_show,
                                    description: register.description,
                                    format_id: register.format_id,
                                    format_relation: register.format_relation,
                                    format_relation_id: register.format_relation_id,
                                    formatos: register.formatos,
                                    is_active: register.is_active,
                                    is_currency: register.is_currency,
                                    is_header: register.is_header,
                                    is_mandatory: register.is_mandatory,
                                    is_multiple: register.is_multiple,
                                    is_private: register.is_private,
                                    last_position: register.last_position,
                                    has_default_value: register.has_default_value,
                                    default_value_text: register.default_value_text,
                                    default_value_number: register.default_value_number,
                                    options: register.options,
                                    position: register.position,
                                    positions: register.positions,
                                    question: register.question,
                                    align_item: register.align_item,
                                    question_format: register.question_format,
                                    question_format_id: register.question_format_id,
                                    question_type: register.question_type,
                                    show_filter: register.show_filter,
                                    show_in_gestion: register.show_in_gestion,
                                    show_table: register.show_table,
                                    state_db: register.state_db,
                                    step_id: register.step_id,
                                    custom_format: register.custom_format,
                                    format_pdf: register.format_pdf,
                                    special_value: register.special_value,
                                    label: register.label
                                }
                            }
                            return question;
                        })
                    }
                }
                return item;
            })
        });
    }

    updateQuestionMatrix = (step_id, question_id, new_question_matrix) => {
        this.setState({
            data: this.state.data.map(item => {
                if (step_id === item.id) {
                    return {
                        ...item,
                        questions: item.questions.map(question => {
                            if (question_id === question.id) {
                                return {
                                    ...question,
                                    question_matrix: new_question_matrix,
                                }
                            }
                            return question;
                        })
                    }
                }
                return item;
            })
        });
    }

    loadData = () => {
        fetch(`/formatos/get_steps/${this.props.formats.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: data.data,
                countMax: data.data.length,
                isLoaded: false
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle
                    title={`${this.props.formats.name}`}
                    subTitle={"ForData"}
                    titleNew={"Nuevo formato"}
                    titleFilter={"Filtros"}
                    icon="fas fa-file-alt"
                    onClickNew={this.toogle}
                    showNew={false}
                    showFilter={false}

                    //favorite module params
                    is_index={true}
                    url={this.props.current_url}
                />

                {this.state.isLoaded ? (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <Preloader/>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <Show
                        updateStateLoad={this.updateStateLoad}
                        loadData={this.loadData}
                        data={this.state.data}
                        isLoaded={this.state.isLoaded}
                        updateItem={this.updateItem}
                        updateData={this.updateData}
                        format={this.props.formats}
                        format_category={this.props.format_category}
                        countMax={this.state.countMax} 
                        count={this.state.count}

                        reduceState={this.reduceState}
                        sumState={this.sumState}
                        formatos={this.props.formatos}

                        reduceCountMax={this.reduceCountMax}
                        sumCountMax={this.sumCountMax}
                        updateCount={this.updateCount}

                        configuration_format={this.props.configuration_format}
                        positions={this.props.positions}
                        steps_questions={this.props.steps_questions}

                        format_resposabilities={this.props.format_resposabilities} 
                        questions_users={this.props.questions_users} 
                        all_formats={this.props.all_formats}
                        format_associations={this.props.format_associations}
                        update_question={this.update_question}

                        users={this.props.users}
                        procesos={this.props.procesos}
                        cargos={this.props.positions}
                        task_board_templates={this.props.task_board_templates}
                        company={this.props.company}
                        imageUpload={this.props.imageUpload}
                        updateStepQuestionsData={this.updateStepQuestionsData}
                        pdfs={this.props.pdfs}
                        key_text={this.props.key_text}

                        section_custom_views={this.props.section_custom_views}
                        icons={this.props.icons}
                        custom_view_permissions={this.props.custom_view_permissions}
                        format_pdfs={this.props.format_pdfs}
                        updateQuestionOption={this.updateQuestionOption}
                        updateQuestionMatrix={this.updateQuestionMatrix}
                    />
                )}
            </React.Fragment>
        );
    }
}


export default FormatsShow;
WebpackerReact.setup({ FormatsShow });

