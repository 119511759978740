import React, { Component } from 'react';

class dropdown extends Component {
    render() {
        return (
            <div className="d-inline-block">
                <button type="button" disabled="" tabIndex="-1" className="dropdown-item" onClick={() => this.props.handleClickShow()}>Gestionar</button>

                <button type="button" data-toggle="dropdown" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" className="border-0 btn-transition btn btn-link">
                    <i className="fa fa-ellipsis-h"></i>
                </button>

                <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right" style={{ paddingTop: "0px" }}>
                    {true && (
                        <React.Fragment>
                            <div className="col-md-12 text-center background-blue-dark-app">
                                Acciones de tarea
                            </div>
                        </React.Fragment>
                    )}

                    
                    <button type="button" disabled="" tabIndex="-1" className="dropdown-item"  onClick={() => this.props.handleClickShow()}>Gestionar</button>
                    <button type="button" tabIndex="0" className="dropdown-item">Eliminar Tarea</button>
                

                    {true && (
                        <React.Fragment>
                            <div className="col-md-12 text-center  background-blue-dark-app">
                                Estados
                            </div>

                            {true && (
                                <button
                                    className="dropdown-item"
                                >
                                    Archivar
                                </button>
                            )}
                        </React.Fragment>
                    )}

                </div>
            </div>
        );
    }
}

export default dropdown;
